<template v-if="actualformular">
        <!-- tabindex="3" -->
<!-- @blur = "looseFocus($event)" -->


    <input
        
        v-if="formularInsert!=undefined"

		class="textfeld"
		:id="[daten.einsatz]"
		v-model="formularInsert.text" 
		style="width:100%" 
        @focus="setFocus($event)"
        :disabled="formularInsert.isDisabled==true || formularInsert.isDisabled=='true'"

        @input="wennInput"

		:style="{'font-size': formularInsert.size + 'px',
                'text-align': formularInsert.schriftausrichtung + 'px',
                'font-weight': formularInsert.schriftart + 'px', }"
        @keypress="isNumber($event)"
		type="text"/>
        
         								<!-- style=\"font-size:".$schriftgroesse."px !important;
										text-align:".$schriftausrichtung." !important;
										font-weight:".$schriftart." !important\"  -->


</template>


<script>

    export default {

        //   mixins: [formularMixin],

        props: {
            daten: Object
            },

        components: {
            
        },

        name: "FormularInput",


        data(){
            return{

            }
        },

        computed: {

        showFontPopover:
		{
			get()
			{
				return this.$store.getters.showFontPopover
			},
			set(value)
			{
				this.$store.commit('setshowFontPopover', value);
			}
        },
        

        getNameOfActualFormular(){

            var returnValue = "";

            if(this.actualformular){
                    for (var prop in this.actualformular.formularContent) 
                            returnValue = prop;
            }

            return returnValue;

        },
        
            formularInsert(){

                // if(this.actualformular.formularContent[this.getNameOfActualFormular][this.daten.einsatz].welchemethod=='')
                // this.actualformular.formularContent[this.getNameOfActualFormular][this.daten.einsatz].welchemethod="leer";
                

                return this.actualformular.formularContent[this.getNameOfActualFormular][this.daten.einsatz];
            },

            actualformular:
            {
                get()
                {
                    return this.$store.getters.actualformular
                },
                set(value)
                {
                    this.$store.commit('setactualformular', value);
                }
            },
            
            projectDaten(){
                return this.$store.getters.actualProject;
            },
        
          store() {
            return this.$store.state
		    },
        },

        methods: {

             wennInput(){
                this.$root.$emit(this.formularInsert.welchemethod);
                
                //this[this.formularInsert.welchemethod]()

            }, 

        leer(){

        },

        setFocus(einsatz){
			console.log(einsatz.target.id)
			this.showFontPopover = '';
			this.showFontPopover = einsatz.target.id;
		},
		
		looseFocus(){
			// setTimeout(()=>{
			// this.showFontPopover = '';
			// },10000);
        },
        

        isNumber: function(evt) {
            if(this.daten.onylNumber==true){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
            }
            else
             return true;
            },


            

        },

	created(){

    }

    }
</script>

