<template>
    <div>
      <b-row @click="loadProject(source.projectID, 'projekte')" style="height:60px">
      <!-- {{source}} -->
        <!-- :class="{'highlightThis': highlightThis}" -->
        <b-col :style="{minWidth:option.width+'px'}" :key="option.key" v-for="option in welcheFelder" class="card_new"    >
          <!-- {{option}} -->

          <span v-if="option.label=='Bauherr'">{{for_kontakt(option.name)}}</span>
          <span v-else-if="option.label=='Status'">{{for_bv_status_key(option.name)}}</span>
          <span v-else-if="option.label=='Typ'">{{for_bv_typ_options_key(option.name)}}</span>
          <span v-else-if="option.label=='Kategorie'">{{for_projekt_typ_options(option.name)}}</span>
          <span v-else-if="option.label=='Verantwortl.'">{{for_Verantwortl(option.name)}}</span>
          <span v-else-if="option.label.includes('LP ')">{{for_Planer(option.name)}}</span>
          <span v-else-if="option.label.includes('löschen')">X</span>

          <span v-else> {{anzeigeNamekurz(option.name, 25)}}</span>

        </b-col>
      </b-row>
    </div>

</template>

<script>



  import { projectMixin } from '../mixins/projectMixin.js'
  import { kontaktMixin } from '../mixins/kontaktMixins.js'
  import { VueOfflineMixin } from 'vue-offline'

export default {

  mixins: [kontaktMixin, VueOfflineMixin, projectMixin],

  name: 'ItemProject',

 props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      },
      otherPropValue: String // here is: 'The Progressive JavaScript Framework'
    },


  data () {
    return {

      borderColor: "0.5px solid #cccccc",
      onlineStatus:0,

    }
  },

  computed: {

    welcheFelder(){

        var leererArray=[];
  
        // var obj = {a:{b:{etc:5}}};

        // console.log('a.b.etc'.split('.').reduce(this.index, obj))

        if(this.projektEinstellungen.main.myFields.length>0){
  
              for(var value of this.projektEinstellungen.main.myFields){
             //      console.log(value)
                 // var index = this.possibleFields.id.indexOf(value)
                var	pos = this.possibleFields.map(function(e) { return e.id; }).indexOf(value);
  
                  if(pos>=0 && this.source!=undefined)
                  leererArray.push({ label:this.possibleFields[pos].label, name: this.possibleFields[pos].key.split('.').reduce(this.indexer, this.source), width:this.possibleFields[pos].width } )

//                  leererArray.push({ label:this.possibleFields[pos].label, name: this.byString(this.source, this.possibleFields[pos].key), width:this.possibleFields[pos].width } )
                  // console.log(returnValue)
              }
        }
      //  leererArray = this.projektEinstellungen.main.myFields
      console.log(leererArray)
         return leererArray
      },

		projektEinstellungen:
		{
			get()
			{
				return this.$store.getters.projektEinstellungen
			},
			set(value)
			{
				this.$store.commit('setprojektEinstellungen', value);
			}
        },

		possibleFields:
		{
			get()
			{
				return this.$store.getters.possibleFields
			},
			set(value)
			{
				this.$store.commit('setpossibleFields', value);
			}
        },


    admin:
      {
        get()
        {
          return this.$store.getters.admin
        },
        set(value)
        {
          this.$store.commit('setadmin', value);
        }
      },
      



      itemStyle () {
        return {
          height: `60px`,

        }
      }
  },

  mounted(){
    console.log(this.source)
  },

  methods: {

      anzeigeNamekurz(name, anzahl=50){
        var returnValue=""

      console.log(name + " " + name.length)

        if(name)
        {
            if(name.length>anzahl)
            {
              var anfag = name.indexOf(" ", anzahl-10)
              console.log(anfag)

              if(anfag==-1)
              anfag=anzahl-10

              returnValue= name.substring(0, anfag)+'...';
            }
            else
            returnValue= name

        }

        return returnValue;

      },

        highlightThis(){

      // if(this.kontaktHighlight==this.source.projectID)
      //   return true;
      // else
        return false;

    },
  }
}
</script>

<style>



</style>
<style scoped lang="less">


  .mehrfachMarkierung:hover {

    background-color: #cccccc;

  }

  .mehrfachMarkierung{

      position: relative;
      border: 0.5px solid #bfffe1;
      height: 100%;
      width: 60px !important;

      padding:5px;
      display: inline-block;
      cursor: pointer;
  }

  .closeSelect {

    position: absolute;
    left: 12px;
    bottom: 11px;
    width: 60px;
    height: 60px;
    opacity: 0.3;

  }
  .closeSelect:hover {
    opacity: 1;
  }
  .closeSelect:before, .closeSelect:after {

    position: absolute;
    left: 15px;
    content: ' ';
    height: 83px;
    width: 2px;
    background-color: #333;

  }

  .closeSelect:before {

    transform: rotate(45deg);

  }

  .closeSelect:after {

    transform: rotate(-45deg);

  }
  
  .marked{

      border: 2px solid #0804f1 !important;
      background-color: #c3d3ff;

  }

	.list-enter-active, .list-leave-active {

		transition: all 1s;

	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {

		opacity: 0;
		transform: translateY(30px);

  }
  
.highlightThis{

     border: 2px solid #3c39ff !important;
}

.item_new{

  width:100%;

}

.row:hover{

  background-color: #cccccc;
  cursor: pointer;

}



.card_details{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    //margin-left: 15px;
    padding:20px;
    display: inline-block;
    //width:245px;

}


.card_new span{
        vertical-align:bottom;
}

</style>
