<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(24.8703589) + 'px'}">
													Bauantrag / Antrag auf Vorbescheid vom
													</div>
<b-popover 
												v-if="formular.bauantragVom"
												:show="formular.bauantragVom.showPop==true"
												
												ref='bauantragVom' 
												target='bauantragVom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVom.size}}</span>
												<b-button @click='plus("bauantragVom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVom" type="text" 
												v-if="formular.bauantragVom!=undefined" 
												class='textfeld' v-model='formular.bauantragVom.text' 
												style='width:100%' 
												@focus='formular.bauantragVom.showPop=true'
												@blur ='formular.bauantragVom.showPop=false'
												:disabled='formular.bauantragVom.isDisabled==true || formular.bauantragVom.isDisabled==true' 
												@keypress="isNumber($event, formular.bauantragVom.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6161783) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(89.5733912) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(25.1045638) + 'px', 
		
														'font-size': formular.bauantragVom.size + 'px', 
														'text-align': formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': formular.bauantragVom.letterSpacing + 'px',
														'font-weight': formular.bauantragVom.schriftart }" 
												/>

<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(118.8136963) + 'px', 
														top: gib_hoehe_in_mm(22.8634959) + 'px'}">
													Betriebsbeschreibung für land und
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(124.8237043) + 'px', 
														top: gib_hoehe_in_mm(27.7307559) + 'px'}">
													forstwirtschaftliche Vorhaben
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(163.8993373) + 'px', 
														top: gib_hoehe_in_mm(11.6070754) + 'px'}">
													Anlage I/9 zur VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(194.6265613) + 'px', 
														top: gib_hoehe_in_mm(14.9083474) + 'px'}">
													Blatt 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(37.3220797) + 'px'}">
													Bauherrschaft
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(84.9288778) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(20.0890866) + 'px',
										
										 top: gib_hoehe_in_mm(40.3208115) + 'px'}"><b-popover 
												v-if="formular.bauherrschaft"
												:show="formular.bauherrschaft.showPop==true"
												
												ref='bauherrschaft' 
												target='bauherrschaft'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherrschaft.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherrschaft.size}}</span>
												<b-button @click='plus("bauherrschaft", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherrschaft", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherrschaft!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherrschaft.text"
												
												@focus='formular.bauherrschaft.showPop=true'
												@blur ='formular.bauherrschaft.showPop=false'
												
												:disabled="formular.bauherrschaft.isDisabled==true || formular.bauherrschaft.isDisabled=='true'"
												:style="{'font-size': formular.bauherrschaft.size + 'px',
														'height': formular.bauherrschaft.height + 'px',
														'text-align': formular.bauherrschaft.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherrschaft.letterSpacing + 'px',
														'font-weight': formular.bauherrschaft.schriftart + 'px', }"

												id='bauherrschaft'>
										</textarea>
	

										 
										 
										 </span>
									

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eigentuemer!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(108.3942329) + 'px',top: gib_hoehe_in_mm(42.0772575) + 'px'}"
								v-model="formular.eigentuemer.text" 
								value='1' 
								unchecked-value='0'> Eigentümerin / Eigentümer
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.paechter!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(108.3942329) + 'px',top: gib_hoehe_in_mm(52.1926935) + 'px'}"
								v-model="formular.paechter.text" 
								value='1' 
								unchecked-value='0'> Pächterin / Pächter
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(61.9123237) + 'px'}">
													Grundstück (Ort, Straße, Hausnummer)
													</div>
<b-popover 
												v-if="formular.baugrund_strasse"
												:show="formular.baugrund_strasse.showPop==true"
												
												ref='baugrund_strasse' 
												target='baugrund_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
												<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrund_strasse" type="text" 
												v-if="formular.baugrund_strasse!=undefined" 
												class='textfeld' v-model='formular.baugrund_strasse.text' 
												style='width:100%' 
												@focus='formular.baugrund_strasse.showPop=true'
												@blur ='formular.baugrund_strasse.showPop=false'
												:disabled='formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrund_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(180.8728007) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.3390866) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(65.1610555) + 'px', 
		
														'font-size': formular.baugrund_strasse.size + 'px', 
														'text-align': formular.baugrund_strasse.schriftausrichtung, 
														'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrund_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(72.7589068) + 'px'}">
													Genaue Bezeichnung des beantragten Vorhabens
													</div>
<b-popover 
												v-if="formular.bezeichnung_bauvorhaben"
												:show="formular.bezeichnung_bauvorhaben.showPop==true"
												
												ref='bezeichnung_bauvorhaben' 
												target='bezeichnung_bauvorhaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
												<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bezeichnung_bauvorhaben" type="text" 
												v-if="formular.bezeichnung_bauvorhaben!=undefined" 
												class='textfeld' v-model='formular.bezeichnung_bauvorhaben.text' 
												style='width:100%' 
												@focus='formular.bezeichnung_bauvorhaben.showPop=true'
												@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
												:disabled='formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled==true' 
												@keypress="isNumber($event, formular.bezeichnung_bauvorhaben.onylNumber) " 
												:style="{'width': spalte_breit_mm(103.9337646) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(97.2781227) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(72.7204746) + 'px', 
		
														'font-size': formular.bezeichnung_bauvorhaben.size + 'px', 
														'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung, 
														'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': formular.bezeichnung_bauvorhaben.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebsgebaude1"
												:show="formular.betriebsgebaude1.showPop==true"
												
												ref='betriebsgebaude1' 
												target='betriebsgebaude1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebsgebaude1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebsgebaude1.size}}</span>
												<b-button @click='plus("betriebsgebaude1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebsgebaude1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebsgebaude1" type="text" 
												v-if="formular.betriebsgebaude1!=undefined" 
												class='textfeld' v-model='formular.betriebsgebaude1.text' 
												style='width:100%' 
												@focus='formular.betriebsgebaude1.showPop=true'
												@blur ='formular.betriebsgebaude1.showPop=false'
												:disabled='formular.betriebsgebaude1.isDisabled==true || formular.betriebsgebaude1.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebsgebaude1.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(80.1906606) + 'px', 
		
														'font-size': formular.betriebsgebaude1.size + 'px', 
														'text-align': formular.betriebsgebaude1.schriftausrichtung, 
														'letter-spacing': formular.betriebsgebaude1.letterSpacing + 'px',
														'font-weight': formular.betriebsgebaude1.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebsgebaude2"
												:show="formular.betriebsgebaude2.showPop==true"
												
												ref='betriebsgebaude2' 
												target='betriebsgebaude2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebsgebaude2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebsgebaude2.size}}</span>
												<b-button @click='plus("betriebsgebaude2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebsgebaude2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebsgebaude2" type="text" 
												v-if="formular.betriebsgebaude2!=undefined" 
												class='textfeld' v-model='formular.betriebsgebaude2.text' 
												style='width:100%' 
												@focus='formular.betriebsgebaude2.showPop=true'
												@blur ='formular.betriebsgebaude2.showPop=false'
												:disabled='formular.betriebsgebaude2.isDisabled==true || formular.betriebsgebaude2.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebsgebaude2.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(87.5973606) + 'px', 
		
														'font-size': formular.betriebsgebaude2.size + 'px', 
														'text-align': formular.betriebsgebaude2.schriftausrichtung, 
														'letter-spacing': formular.betriebsgebaude2.letterSpacing + 'px',
														'font-weight': formular.betriebsgebaude2.schriftart }" 
												/>

<b-popover 
												v-if="formular.lagergebaude1"
												:show="formular.lagergebaude1.showPop==true"
												
												ref='lagergebaude1' 
												target='lagergebaude1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lagergebaude1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lagergebaude1.size}}</span>
												<b-button @click='plus("lagergebaude1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lagergebaude1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="lagergebaude1" type="text" 
												v-if="formular.lagergebaude1!=undefined" 
												class='textfeld' v-model='formular.lagergebaude1.text' 
												style='width:100%' 
												@focus='formular.lagergebaude1.showPop=true'
												@blur ='formular.lagergebaude1.showPop=false'
												:disabled='formular.lagergebaude1.isDisabled==true || formular.lagergebaude1.isDisabled==true' 
												@keypress="isNumber($event, formular.lagergebaude1.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(94.9638528) + 'px', 
		
														'font-size': formular.lagergebaude1.size + 'px', 
														'text-align': formular.lagergebaude1.schriftausrichtung, 
														'letter-spacing': formular.lagergebaude1.letterSpacing + 'px',
														'font-weight': formular.lagergebaude1.schriftart }" 
												/>

<b-popover 
												v-if="formular.lagergebaude2"
												:show="formular.lagergebaude2.showPop==true"
												
												ref='lagergebaude2' 
												target='lagergebaude2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lagergebaude2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lagergebaude2.size}}</span>
												<b-button @click='plus("lagergebaude2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lagergebaude2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="lagergebaude2" type="text" 
												v-if="formular.lagergebaude2!=undefined" 
												class='textfeld' v-model='formular.lagergebaude2.text' 
												style='width:100%' 
												@focus='formular.lagergebaude2.showPop=true'
												@blur ='formular.lagergebaude2.showPop=false'
												:disabled='formular.lagergebaude2.isDisabled==true || formular.lagergebaude2.isDisabled==true' 
												@keypress="isNumber($event, formular.lagergebaude2.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(102.2940169) + 'px', 
		
														'font-size': formular.lagergebaude2.size + 'px', 
														'text-align': formular.lagergebaude2.schriftausrichtung, 
														'letter-spacing': formular.lagergebaude2.letterSpacing + 'px',
														'font-weight': formular.lagergebaude2.schriftart }" 
												/>

<b-popover 
												v-if="formular.stallgebaude1"
												:show="formular.stallgebaude1.showPop==true"
												
												ref='stallgebaude1' 
												target='stallgebaude1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stallgebaude1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stallgebaude1.size}}</span>
												<b-button @click='plus("stallgebaude1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stallgebaude1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="stallgebaude1" type="text" 
												v-if="formular.stallgebaude1!=undefined" 
												class='textfeld' v-model='formular.stallgebaude1.text' 
												style='width:100%' 
												@focus='formular.stallgebaude1.showPop=true'
												@blur ='formular.stallgebaude1.showPop=false'
												:disabled='formular.stallgebaude1.isDisabled==true || formular.stallgebaude1.isDisabled==true' 
												@keypress="isNumber($event, formular.stallgebaude1.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(109.7433936) + 'px', 
		
														'font-size': formular.stallgebaude1.size + 'px', 
														'text-align': formular.stallgebaude1.schriftausrichtung, 
														'letter-spacing': formular.stallgebaude1.letterSpacing + 'px',
														'font-weight': formular.stallgebaude1.schriftart }" 
												/>

<b-popover 
												v-if="formular.stallgebaude2"
												:show="formular.stallgebaude2.showPop==true"
												
												ref='stallgebaude2' 
												target='stallgebaude2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stallgebaude2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stallgebaude2.size}}</span>
												<b-button @click='plus("stallgebaude2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stallgebaude2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="stallgebaude2" type="text" 
												v-if="formular.stallgebaude2!=undefined" 
												class='textfeld' v-model='formular.stallgebaude2.text' 
												style='width:100%' 
												@focus='formular.stallgebaude2.showPop=true'
												@blur ='formular.stallgebaude2.showPop=false'
												:disabled='formular.stallgebaude2.isDisabled==true || formular.stallgebaude2.isDisabled==true' 
												@keypress="isNumber($event, formular.stallgebaude2.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(117.1497409) + 'px', 
		
														'font-size': formular.stallgebaude2.size + 'px', 
														'text-align': formular.stallgebaude2.schriftausrichtung, 
														'letter-spacing': formular.stallgebaude2.letterSpacing + 'px',
														'font-weight': formular.stallgebaude2.schriftart }" 
												/>

<b-popover 
												v-if="formular.wohngebaude1"
												:show="formular.wohngebaude1.showPop==true"
												
												ref='wohngebaude1' 
												target='wohngebaude1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.wohngebaude1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.wohngebaude1.size}}</span>
												<b-button @click='plus("wohngebaude1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("wohngebaude1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="wohngebaude1" type="text" 
												v-if="formular.wohngebaude1!=undefined" 
												class='textfeld' v-model='formular.wohngebaude1.text' 
												style='width:100%' 
												@focus='formular.wohngebaude1.showPop=true'
												@blur ='formular.wohngebaude1.showPop=false'
												:disabled='formular.wohngebaude1.isDisabled==true || formular.wohngebaude1.isDisabled==true' 
												@keypress="isNumber($event, formular.wohngebaude1.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(124.5571463) + 'px', 
		
														'font-size': formular.wohngebaude1.size + 'px', 
														'text-align': formular.wohngebaude1.schriftausrichtung, 
														'letter-spacing': formular.wohngebaude1.letterSpacing + 'px',
														'font-weight': formular.wohngebaude1.schriftart }" 
												/>

<b-popover 
												v-if="formular.wohngebaude2"
												:show="formular.wohngebaude2.showPop==true"
												
												ref='wohngebaude2' 
												target='wohngebaude2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.wohngebaude2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.wohngebaude2.size}}</span>
												<b-button @click='plus("wohngebaude2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("wohngebaude2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="wohngebaude2" type="text" 
												v-if="formular.wohngebaude2!=undefined" 
												class='textfeld' v-model='formular.wohngebaude2.text' 
												style='width:100%' 
												@focus='formular.wohngebaude2.showPop=true'
												@blur ='formular.wohngebaude2.showPop=false'
												:disabled='formular.wohngebaude2.isDisabled==true || formular.wohngebaude2.isDisabled==true' 
												@keypress="isNumber($event, formular.wohngebaude2.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(131.9215223) + 'px', 
		
														'font-size': formular.wohngebaude2.size + 'px', 
														'text-align': formular.wohngebaude2.schriftausrichtung, 
														'letter-spacing': formular.wohngebaude2.letterSpacing + 'px',
														'font-weight': formular.wohngebaude2.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstgebaude1"
												:show="formular.sonstgebaude1.showPop==true"
												
												ref='sonstgebaude1' 
												target='sonstgebaude1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstgebaude1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstgebaude1.size}}</span>
												<b-button @click='plus("sonstgebaude1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstgebaude1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="sonstgebaude1" type="text" 
												v-if="formular.sonstgebaude1!=undefined" 
												class='textfeld' v-model='formular.sonstgebaude1.text' 
												style='width:100%' 
												@focus='formular.sonstgebaude1.showPop=true'
												@blur ='formular.sonstgebaude1.showPop=false'
												:disabled='formular.sonstgebaude1.isDisabled==true || formular.sonstgebaude1.isDisabled==true' 
												@keypress="isNumber($event, formular.sonstgebaude1.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(139.3278696) + 'px', 
		
														'font-size': formular.sonstgebaude1.size + 'px', 
														'text-align': formular.sonstgebaude1.schriftausrichtung, 
														'letter-spacing': formular.sonstgebaude1.letterSpacing + 'px',
														'font-weight': formular.sonstgebaude1.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstgebaude2"
												:show="formular.sonstgebaude2.showPop==true"
												
												ref='sonstgebaude2' 
												target='sonstgebaude2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstgebaude2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstgebaude2.size}}</span>
												<b-button @click='plus("sonstgebaude2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstgebaude2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="sonstgebaude2" type="text" 
												v-if="formular.sonstgebaude2!=undefined" 
												class='textfeld' v-model='formular.sonstgebaude2.text' 
												style='width:100%' 
												@focus='formular.sonstgebaude2.showPop=true'
												@blur ='formular.sonstgebaude2.showPop=false'
												:disabled='formular.sonstgebaude2.isDisabled==true || formular.sonstgebaude2.isDisabled==true' 
												@keypress="isNumber($event, formular.sonstgebaude2.onylNumber) " 
												:style="{'width': spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(146.6922456) + 'px', 
		
														'font-size': formular.sonstgebaude2.size + 'px', 
														'text-align': formular.sonstgebaude2.schriftausrichtung, 
														'letter-spacing': formular.sonstgebaude2.letterSpacing + 'px',
														'font-weight': formular.sonstgebaude2.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(83.8904715) + 'px'}">
													Betriebsgebäude
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(98.6721285) + 'px'}">
													Lagergebäude
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(113.4432045) + 'px'}">
													Stallgebäude
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(128.2142805) + 'px'}">
													Wohngebäude
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(141.1654245) + 'px'}">
													sonstige Gebäude /
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(144.8052885) + 'px'}">
													Anlagen
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(154.9746876) + 'px'}">
													1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(153.1431165) + 'px'}">
													Betriebsflächen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(156.7829805) + 'px'}">
													(ha)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(163.4349025) + 'px'}">
													Ackerland
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(170.0374465) + 'px'}">
													Grünland
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(176.6399905) + 'px'}">
													sonstige landwirt
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(180.2798545) + 'px'}">
													schaftliche Nutzflä
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(183.9620425) + 'px'}">
													che
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(190.2259945) + 'px'}">
													Summe landwirt
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(193.9081825) + 'px'}">
													schaftliche Nutzflä
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(197.5480465) + 'px'}">
													che
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(203.5580545) + 'px'}">
													Forstwirtschaftliche
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(207.2402425) + 'px'}">
													Nutzfläche
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(213.2502505) + 'px'}">
													Sonstige Flächen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(220.0644145) + 'px'}">
													Summe
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.8643874) + 'px', 
														top: gib_hoehe_in_mm(223.7042785) + 'px'}">
													Betriebsfläche
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(81.8154663) + 'px', 
														top: gib_hoehe_in_mm(153.2147146) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(139.5101323) + 'px', 
														top: gib_hoehe_in_mm(153.2147146) + 'px'}">
													Ziel
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(173.7608293) + 'px', 
														top: gib_hoehe_in_mm(153.0468294) + 'px'}">
													Prüfvermerke
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(57.2566126) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Eigentum
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(71.9112976) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Zupacht
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(86.0478663) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Verpacht.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(100.4380263) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Bewirtsch.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(115.7663683) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Eigentum
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.4262483) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Zupacht
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.6047883) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Verpacht.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(160.1748253) + 'px', 
														top: gib_hoehe_in_mm(158.7605694) + 'px'}">
													Bewirtsch.
													</div>
<b-popover 
												v-if="formular.acker1"
												:show="formular.acker1.showPop==true"
												
												ref='acker1' 
												target='acker1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker1.size}}</span>
												<b-button @click='plus("acker1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker1" 
																				v-bind:precision="parseInt(formular.acker1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker1!=undefined" 
												class='textfeld' v-model='formular.acker1.text' 
												style='width:100%' 
												@focus='formular.acker1.showPop=true'
												@blur ='formular.acker1.showPop=false'
												:disabled='formular.acker1.isDisabled==true || formular.acker1.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker1.size + 'px', 
														'text-align': formular.acker1.schriftausrichtung, 
														'letter-spacing': formular.acker1.letterSpacing + 'px',
														'font-weight': formular.acker1.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker2"
												:show="formular.acker2.showPop==true"
												
												ref='acker2' 
												target='acker2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker2.size}}</span>
												<b-button @click='plus("acker2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker2" 
																				v-bind:precision="parseInt(formular.acker2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker2!=undefined" 
												class='textfeld' v-model='formular.acker2.text' 
												style='width:100%' 
												@focus='formular.acker2.showPop=true'
												@blur ='formular.acker2.showPop=false'
												:disabled='formular.acker2.isDisabled==true || formular.acker2.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker2.size + 'px', 
														'text-align': formular.acker2.schriftausrichtung, 
														'letter-spacing': formular.acker2.letterSpacing + 'px',
														'font-weight': formular.acker2.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker3"
												:show="formular.acker3.showPop==true"
												
												ref='acker3' 
												target='acker3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker3.size}}</span>
												<b-button @click='plus("acker3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker3" 
																				v-bind:precision="parseInt(formular.acker3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker3!=undefined" 
												class='textfeld' v-model='formular.acker3.text' 
												style='width:100%' 
												@focus='formular.acker3.showPop=true'
												@blur ='formular.acker3.showPop=false'
												:disabled='formular.acker3.isDisabled==true || formular.acker3.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker3.size + 'px', 
														'text-align': formular.acker3.schriftausrichtung, 
														'letter-spacing': formular.acker3.letterSpacing + 'px',
														'font-weight': formular.acker3.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker4"
												:show="formular.acker4.showPop==true"
												
												ref='acker4' 
												target='acker4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker4.size}}</span>
												<b-button @click='plus("acker4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker4" 
																				v-bind:precision="parseInt(formular.acker4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker4!=undefined" 
												class='textfeld' v-model='formular.acker4.text' 
												style='width:100%' 
												@focus='formular.acker4.showPop=true'
												@blur ='formular.acker4.showPop=false'
												:disabled='formular.acker4.isDisabled==true || formular.acker4.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker4.size + 'px', 
														'text-align': formular.acker4.schriftausrichtung, 
														'letter-spacing': formular.acker4.letterSpacing + 'px',
														'font-weight': formular.acker4.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker5"
												:show="formular.acker5.showPop==true"
												
												ref='acker5' 
												target='acker5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker5.size}}</span>
												<b-button @click='plus("acker5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker5" 
																				v-bind:precision="parseInt(formular.acker5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker5!=undefined" 
												class='textfeld' v-model='formular.acker5.text' 
												style='width:100%' 
												@focus='formular.acker5.showPop=true'
												@blur ='formular.acker5.showPop=false'
												:disabled='formular.acker5.isDisabled==true || formular.acker5.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker5.size + 'px', 
														'text-align': formular.acker5.schriftausrichtung, 
														'letter-spacing': formular.acker5.letterSpacing + 'px',
														'font-weight': formular.acker5.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker6"
												:show="formular.acker6.showPop==true"
												
												ref='acker6' 
												target='acker6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker6.size}}</span>
												<b-button @click='plus("acker6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker6" 
																				v-bind:precision="parseInt(formular.acker6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker6!=undefined" 
												class='textfeld' v-model='formular.acker6.text' 
												style='width:100%' 
												@focus='formular.acker6.showPop=true'
												@blur ='formular.acker6.showPop=false'
												:disabled='formular.acker6.isDisabled==true || formular.acker6.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker6.size + 'px', 
														'text-align': formular.acker6.schriftausrichtung, 
														'letter-spacing': formular.acker6.letterSpacing + 'px',
														'font-weight': formular.acker6.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker7"
												:show="formular.acker7.showPop==true"
												
												ref='acker7' 
												target='acker7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker7.size}}</span>
												<b-button @click='plus("acker7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker7" 
																				v-bind:precision="parseInt(formular.acker7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker7!=undefined" 
												class='textfeld' v-model='formular.acker7.text' 
												style='width:100%' 
												@focus='formular.acker7.showPop=true'
												@blur ='formular.acker7.showPop=false'
												:disabled='formular.acker7.isDisabled==true || formular.acker7.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker7.size + 'px', 
														'text-align': formular.acker7.schriftausrichtung, 
														'letter-spacing': formular.acker7.letterSpacing + 'px',
														'font-weight': formular.acker7.schriftart }" 
												/>

<b-popover 
												v-if="formular.acker8"
												:show="formular.acker8.showPop==true"
												
												ref='acker8' 
												target='acker8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.acker8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.acker8.size}}</span>
												<b-button @click='plus("acker8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("acker8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="acker8" 
																				v-bind:precision="parseInt(formular.acker8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.acker8!=undefined" 
												class='textfeld' v-model='formular.acker8.text' 
												style='width:100%' 
												@focus='formular.acker8.showPop=true'
												@blur ='formular.acker8.showPop=false'
												:disabled='formular.acker8.isDisabled==true || formular.acker8.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.acker8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': formular.acker8.size + 'px', 
														'text-align': formular.acker8.schriftausrichtung, 
														'letter-spacing': formular.acker8.letterSpacing + 'px',
														'font-weight': formular.acker8.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland1"
												:show="formular.gruenland1.showPop==true"
												
												ref='gruenland1' 
												target='gruenland1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland1.size}}</span>
												<b-button @click='plus("gruenland1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland1" 
																				v-bind:precision="parseInt(formular.gruenland1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland1!=undefined" 
												class='textfeld' v-model='formular.gruenland1.text' 
												style='width:100%' 
												@focus='formular.gruenland1.showPop=true'
												@blur ='formular.gruenland1.showPop=false'
												:disabled='formular.gruenland1.isDisabled==true || formular.gruenland1.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland1.size + 'px', 
														'text-align': formular.gruenland1.schriftausrichtung, 
														'letter-spacing': formular.gruenland1.letterSpacing + 'px',
														'font-weight': formular.gruenland1.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland2"
												:show="formular.gruenland2.showPop==true"
												
												ref='gruenland2' 
												target='gruenland2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland2.size}}</span>
												<b-button @click='plus("gruenland2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland2" 
																				v-bind:precision="parseInt(formular.gruenland2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland2!=undefined" 
												class='textfeld' v-model='formular.gruenland2.text' 
												style='width:100%' 
												@focus='formular.gruenland2.showPop=true'
												@blur ='formular.gruenland2.showPop=false'
												:disabled='formular.gruenland2.isDisabled==true || formular.gruenland2.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland2.size + 'px', 
														'text-align': formular.gruenland2.schriftausrichtung, 
														'letter-spacing': formular.gruenland2.letterSpacing + 'px',
														'font-weight': formular.gruenland2.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland3"
												:show="formular.gruenland3.showPop==true"
												
												ref='gruenland3' 
												target='gruenland3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland3.size}}</span>
												<b-button @click='plus("gruenland3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland3" 
																				v-bind:precision="parseInt(formular.gruenland3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland3!=undefined" 
												class='textfeld' v-model='formular.gruenland3.text' 
												style='width:100%' 
												@focus='formular.gruenland3.showPop=true'
												@blur ='formular.gruenland3.showPop=false'
												:disabled='formular.gruenland3.isDisabled==true || formular.gruenland3.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland3.size + 'px', 
														'text-align': formular.gruenland3.schriftausrichtung, 
														'letter-spacing': formular.gruenland3.letterSpacing + 'px',
														'font-weight': formular.gruenland3.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland4"
												:show="formular.gruenland4.showPop==true"
												
												ref='gruenland4' 
												target='gruenland4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland4.size}}</span>
												<b-button @click='plus("gruenland4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland4" 
																				v-bind:precision="parseInt(formular.gruenland4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland4!=undefined" 
												class='textfeld' v-model='formular.gruenland4.text' 
												style='width:100%' 
												@focus='formular.gruenland4.showPop=true'
												@blur ='formular.gruenland4.showPop=false'
												:disabled='formular.gruenland4.isDisabled==true || formular.gruenland4.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland4.size + 'px', 
														'text-align': formular.gruenland4.schriftausrichtung, 
														'letter-spacing': formular.gruenland4.letterSpacing + 'px',
														'font-weight': formular.gruenland4.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland5"
												:show="formular.gruenland5.showPop==true"
												
												ref='gruenland5' 
												target='gruenland5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland5.size}}</span>
												<b-button @click='plus("gruenland5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland5" 
																				v-bind:precision="parseInt(formular.gruenland5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland5!=undefined" 
												class='textfeld' v-model='formular.gruenland5.text' 
												style='width:100%' 
												@focus='formular.gruenland5.showPop=true'
												@blur ='formular.gruenland5.showPop=false'
												:disabled='formular.gruenland5.isDisabled==true || formular.gruenland5.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland5.size + 'px', 
														'text-align': formular.gruenland5.schriftausrichtung, 
														'letter-spacing': formular.gruenland5.letterSpacing + 'px',
														'font-weight': formular.gruenland5.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland6"
												:show="formular.gruenland6.showPop==true"
												
												ref='gruenland6' 
												target='gruenland6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland6.size}}</span>
												<b-button @click='plus("gruenland6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland6" 
																				v-bind:precision="parseInt(formular.gruenland6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland6!=undefined" 
												class='textfeld' v-model='formular.gruenland6.text' 
												style='width:100%' 
												@focus='formular.gruenland6.showPop=true'
												@blur ='formular.gruenland6.showPop=false'
												:disabled='formular.gruenland6.isDisabled==true || formular.gruenland6.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland6.size + 'px', 
														'text-align': formular.gruenland6.schriftausrichtung, 
														'letter-spacing': formular.gruenland6.letterSpacing + 'px',
														'font-weight': formular.gruenland6.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland7"
												:show="formular.gruenland7.showPop==true"
												
												ref='gruenland7' 
												target='gruenland7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland7.size}}</span>
												<b-button @click='plus("gruenland7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland7" 
																				v-bind:precision="parseInt(formular.gruenland7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland7!=undefined" 
												class='textfeld' v-model='formular.gruenland7.text' 
												style='width:100%' 
												@focus='formular.gruenland7.showPop=true'
												@blur ='formular.gruenland7.showPop=false'
												:disabled='formular.gruenland7.isDisabled==true || formular.gruenland7.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland7.size + 'px', 
														'text-align': formular.gruenland7.schriftausrichtung, 
														'letter-spacing': formular.gruenland7.letterSpacing + 'px',
														'font-weight': formular.gruenland7.schriftart }" 
												/>

<b-popover 
												v-if="formular.gruenland8"
												:show="formular.gruenland8.showPop==true"
												
												ref='gruenland8' 
												target='gruenland8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenland8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenland8.size}}</span>
												<b-button @click='plus("gruenland8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenland8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gruenland8" 
																				v-bind:precision="parseInt(formular.gruenland8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gruenland8!=undefined" 
												class='textfeld' v-model='formular.gruenland8.text' 
												style='width:100%' 
												@focus='formular.gruenland8.showPop=true'
												@blur ='formular.gruenland8.showPop=false'
												:disabled='formular.gruenland8.isDisabled==true || formular.gruenland8.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.gruenland8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': formular.gruenland8.size + 'px', 
														'text-align': formular.gruenland8.schriftausrichtung, 
														'letter-spacing': formular.gruenland8.letterSpacing + 'px',
														'font-weight': formular.gruenland8.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche1"
												:show="formular.sonstlwflaeche1.showPop==true"
												
												ref='sonstlwflaeche1' 
												target='sonstlwflaeche1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche1.size}}</span>
												<b-button @click='plus("sonstlwflaeche1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche1" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche1!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche1.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche1.showPop=true'
												@blur ='formular.sonstlwflaeche1.showPop=false'
												:disabled='formular.sonstlwflaeche1.isDisabled==true || formular.sonstlwflaeche1.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche1.size + 'px', 
														'text-align': formular.sonstlwflaeche1.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche1.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche1.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche2"
												:show="formular.sonstlwflaeche2.showPop==true"
												
												ref='sonstlwflaeche2' 
												target='sonstlwflaeche2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche2.size}}</span>
												<b-button @click='plus("sonstlwflaeche2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche2" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche2!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche2.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche2.showPop=true'
												@blur ='formular.sonstlwflaeche2.showPop=false'
												:disabled='formular.sonstlwflaeche2.isDisabled==true || formular.sonstlwflaeche2.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche2.size + 'px', 
														'text-align': formular.sonstlwflaeche2.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche2.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche2.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche3"
												:show="formular.sonstlwflaeche3.showPop==true"
												
												ref='sonstlwflaeche3' 
												target='sonstlwflaeche3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche3.size}}</span>
												<b-button @click='plus("sonstlwflaeche3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche3" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche3!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche3.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche3.showPop=true'
												@blur ='formular.sonstlwflaeche3.showPop=false'
												:disabled='formular.sonstlwflaeche3.isDisabled==true || formular.sonstlwflaeche3.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche3.size + 'px', 
														'text-align': formular.sonstlwflaeche3.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche3.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche3.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche4"
												:show="formular.sonstlwflaeche4.showPop==true"
												
												ref='sonstlwflaeche4' 
												target='sonstlwflaeche4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche4.size}}</span>
												<b-button @click='plus("sonstlwflaeche4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche4" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche4!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche4.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche4.showPop=true'
												@blur ='formular.sonstlwflaeche4.showPop=false'
												:disabled='formular.sonstlwflaeche4.isDisabled==true || formular.sonstlwflaeche4.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche4.size + 'px', 
														'text-align': formular.sonstlwflaeche4.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche4.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche4.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche5"
												:show="formular.sonstlwflaeche5.showPop==true"
												
												ref='sonstlwflaeche5' 
												target='sonstlwflaeche5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche5.size}}</span>
												<b-button @click='plus("sonstlwflaeche5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche5" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche5!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche5.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche5.showPop=true'
												@blur ='formular.sonstlwflaeche5.showPop=false'
												:disabled='formular.sonstlwflaeche5.isDisabled==true || formular.sonstlwflaeche5.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche5.size + 'px', 
														'text-align': formular.sonstlwflaeche5.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche5.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche5.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche6"
												:show="formular.sonstlwflaeche6.showPop==true"
												
												ref='sonstlwflaeche6' 
												target='sonstlwflaeche6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche6.size}}</span>
												<b-button @click='plus("sonstlwflaeche6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche6" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche6!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche6.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche6.showPop=true'
												@blur ='formular.sonstlwflaeche6.showPop=false'
												:disabled='formular.sonstlwflaeche6.isDisabled==true || formular.sonstlwflaeche6.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche6.size + 'px', 
														'text-align': formular.sonstlwflaeche6.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche6.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche6.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche7"
												:show="formular.sonstlwflaeche7.showPop==true"
												
												ref='sonstlwflaeche7' 
												target='sonstlwflaeche7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche7.size}}</span>
												<b-button @click='plus("sonstlwflaeche7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche7" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche7!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche7.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche7.showPop=true'
												@blur ='formular.sonstlwflaeche7.showPop=false'
												:disabled='formular.sonstlwflaeche7.isDisabled==true || formular.sonstlwflaeche7.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche7.size + 'px', 
														'text-align': formular.sonstlwflaeche7.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche7.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche7.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstlwflaeche8"
												:show="formular.sonstlwflaeche8.showPop==true"
												
												ref='sonstlwflaeche8' 
												target='sonstlwflaeche8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlwflaeche8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlwflaeche8.size}}</span>
												<b-button @click='plus("sonstlwflaeche8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlwflaeche8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstlwflaeche8" 
																				v-bind:precision="parseInt(formular.sonstlwflaeche8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstlwflaeche8!=undefined" 
												class='textfeld' v-model='formular.sonstlwflaeche8.text' 
												style='width:100%' 
												@focus='formular.sonstlwflaeche8.showPop=true'
												@blur ='formular.sonstlwflaeche8.showPop=false'
												:disabled='formular.sonstlwflaeche8.isDisabled==true || formular.sonstlwflaeche8.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstlwflaeche8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': formular.sonstlwflaeche8.size + 'px', 
														'text-align': formular.sonstlwflaeche8.schriftausrichtung, 
														'letter-spacing': formular.sonstlwflaeche8.letterSpacing + 'px',
														'font-weight': formular.sonstlwflaeche8.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche1"
												:show="formular.summelwflaeche1.showPop==true"
												
												ref='summelwflaeche1' 
												target='summelwflaeche1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche1.size}}</span>
												<b-button @click='plus("summelwflaeche1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche1" 
																				v-bind:precision="parseInt(formular.summelwflaeche1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche1!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche1.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche1.showPop=true'
												@blur ='formular.summelwflaeche1.showPop=false'
												:disabled='formular.summelwflaeche1.isDisabled==true || formular.summelwflaeche1.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche1.size + 'px', 
														'text-align': formular.summelwflaeche1.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche1.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche1.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche2"
												:show="formular.summelwflaeche2.showPop==true"
												
												ref='summelwflaeche2' 
												target='summelwflaeche2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche2.size}}</span>
												<b-button @click='plus("summelwflaeche2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche2" 
																				v-bind:precision="parseInt(formular.summelwflaeche2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche2!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche2.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche2.showPop=true'
												@blur ='formular.summelwflaeche2.showPop=false'
												:disabled='formular.summelwflaeche2.isDisabled==true || formular.summelwflaeche2.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche2.size + 'px', 
														'text-align': formular.summelwflaeche2.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche2.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche2.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche3"
												:show="formular.summelwflaeche3.showPop==true"
												
												ref='summelwflaeche3' 
												target='summelwflaeche3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche3.size}}</span>
												<b-button @click='plus("summelwflaeche3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche3" 
																				v-bind:precision="parseInt(formular.summelwflaeche3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche3!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche3.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche3.showPop=true'
												@blur ='formular.summelwflaeche3.showPop=false'
												:disabled='formular.summelwflaeche3.isDisabled==true || formular.summelwflaeche3.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche3.size + 'px', 
														'text-align': formular.summelwflaeche3.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche3.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche3.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche4"
												:show="formular.summelwflaeche4.showPop==true"
												
												ref='summelwflaeche4' 
												target='summelwflaeche4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche4.size}}</span>
												<b-button @click='plus("summelwflaeche4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche4" 
																				v-bind:precision="parseInt(formular.summelwflaeche4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche4!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche4.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche4.showPop=true'
												@blur ='formular.summelwflaeche4.showPop=false'
												:disabled='formular.summelwflaeche4.isDisabled==true || formular.summelwflaeche4.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche4.size + 'px', 
														'text-align': formular.summelwflaeche4.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche4.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche4.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche5"
												:show="formular.summelwflaeche5.showPop==true"
												
												ref='summelwflaeche5' 
												target='summelwflaeche5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche5.size}}</span>
												<b-button @click='plus("summelwflaeche5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche5" 
																				v-bind:precision="parseInt(formular.summelwflaeche5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche5!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche5.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche5.showPop=true'
												@blur ='formular.summelwflaeche5.showPop=false'
												:disabled='formular.summelwflaeche5.isDisabled==true || formular.summelwflaeche5.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche5.size + 'px', 
														'text-align': formular.summelwflaeche5.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche5.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche5.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche6"
												:show="formular.summelwflaeche6.showPop==true"
												
												ref='summelwflaeche6' 
												target='summelwflaeche6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche6.size}}</span>
												<b-button @click='plus("summelwflaeche6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche6" 
																				v-bind:precision="parseInt(formular.summelwflaeche6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche6!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche6.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche6.showPop=true'
												@blur ='formular.summelwflaeche6.showPop=false'
												:disabled='formular.summelwflaeche6.isDisabled==true || formular.summelwflaeche6.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche6.size + 'px', 
														'text-align': formular.summelwflaeche6.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche6.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche6.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche7"
												:show="formular.summelwflaeche7.showPop==true"
												
												ref='summelwflaeche7' 
												target='summelwflaeche7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche7.size}}</span>
												<b-button @click='plus("summelwflaeche7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche7" 
																				v-bind:precision="parseInt(formular.summelwflaeche7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche7!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche7.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche7.showPop=true'
												@blur ='formular.summelwflaeche7.showPop=false'
												:disabled='formular.summelwflaeche7.isDisabled==true || formular.summelwflaeche7.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche7.size + 'px', 
														'text-align': formular.summelwflaeche7.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche7.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche7.schriftart }" 
												/>

<b-popover 
												v-if="formular.summelwflaeche8"
												:show="formular.summelwflaeche8.showPop==true"
												
												ref='summelwflaeche8' 
												target='summelwflaeche8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summelwflaeche8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summelwflaeche8.size}}</span>
												<b-button @click='plus("summelwflaeche8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summelwflaeche8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summelwflaeche8" 
																				v-bind:precision="parseInt(formular.summelwflaeche8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summelwflaeche8!=undefined" 
												class='textfeld' v-model='formular.summelwflaeche8.text' 
												style='width:100%' 
												@focus='formular.summelwflaeche8.showPop=true'
												@blur ='formular.summelwflaeche8.showPop=false'
												:disabled='formular.summelwflaeche8.isDisabled==true || formular.summelwflaeche8.isDisabled==true' 
												@keypress="isNumber($event, formular.summelwflaeche8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': formular.summelwflaeche8.size + 'px', 
														'text-align': formular.summelwflaeche8.schriftausrichtung, 
														'letter-spacing': formular.summelwflaeche8.letterSpacing + 'px',
														'font-weight': formular.summelwflaeche8.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche1"
												:show="formular.fwflaeche1.showPop==true"
												
												ref='fwflaeche1' 
												target='fwflaeche1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche1.size}}</span>
												<b-button @click='plus("fwflaeche1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche1" 
																				v-bind:precision="parseInt(formular.fwflaeche1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche1!=undefined" 
												class='textfeld' v-model='formular.fwflaeche1.text' 
												style='width:100%' 
												@focus='formular.fwflaeche1.showPop=true'
												@blur ='formular.fwflaeche1.showPop=false'
												:disabled='formular.fwflaeche1.isDisabled==true || formular.fwflaeche1.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche1.size + 'px', 
														'text-align': formular.fwflaeche1.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche1.letterSpacing + 'px',
														'font-weight': formular.fwflaeche1.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche2"
												:show="formular.fwflaeche2.showPop==true"
												
												ref='fwflaeche2' 
												target='fwflaeche2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche2.size}}</span>
												<b-button @click='plus("fwflaeche2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche2" 
																				v-bind:precision="parseInt(formular.fwflaeche2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche2!=undefined" 
												class='textfeld' v-model='formular.fwflaeche2.text' 
												style='width:100%' 
												@focus='formular.fwflaeche2.showPop=true'
												@blur ='formular.fwflaeche2.showPop=false'
												:disabled='formular.fwflaeche2.isDisabled==true || formular.fwflaeche2.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche2.size + 'px', 
														'text-align': formular.fwflaeche2.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche2.letterSpacing + 'px',
														'font-weight': formular.fwflaeche2.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche3"
												:show="formular.fwflaeche3.showPop==true"
												
												ref='fwflaeche3' 
												target='fwflaeche3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche3.size}}</span>
												<b-button @click='plus("fwflaeche3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche3" 
																				v-bind:precision="parseInt(formular.fwflaeche3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche3!=undefined" 
												class='textfeld' v-model='formular.fwflaeche3.text' 
												style='width:100%' 
												@focus='formular.fwflaeche3.showPop=true'
												@blur ='formular.fwflaeche3.showPop=false'
												:disabled='formular.fwflaeche3.isDisabled==true || formular.fwflaeche3.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche3.size + 'px', 
														'text-align': formular.fwflaeche3.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche3.letterSpacing + 'px',
														'font-weight': formular.fwflaeche3.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche4"
												:show="formular.fwflaeche4.showPop==true"
												
												ref='fwflaeche4' 
												target='fwflaeche4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche4.size}}</span>
												<b-button @click='plus("fwflaeche4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche4" 
																				v-bind:precision="parseInt(formular.fwflaeche4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche4!=undefined" 
												class='textfeld' v-model='formular.fwflaeche4.text' 
												style='width:100%' 
												@focus='formular.fwflaeche4.showPop=true'
												@blur ='formular.fwflaeche4.showPop=false'
												:disabled='formular.fwflaeche4.isDisabled==true || formular.fwflaeche4.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche4.size + 'px', 
														'text-align': formular.fwflaeche4.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche4.letterSpacing + 'px',
														'font-weight': formular.fwflaeche4.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche5"
												:show="formular.fwflaeche5.showPop==true"
												
												ref='fwflaeche5' 
												target='fwflaeche5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche5.size}}</span>
												<b-button @click='plus("fwflaeche5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche5" 
																				v-bind:precision="parseInt(formular.fwflaeche5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche5!=undefined" 
												class='textfeld' v-model='formular.fwflaeche5.text' 
												style='width:100%' 
												@focus='formular.fwflaeche5.showPop=true'
												@blur ='formular.fwflaeche5.showPop=false'
												:disabled='formular.fwflaeche5.isDisabled==true || formular.fwflaeche5.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche5.size + 'px', 
														'text-align': formular.fwflaeche5.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche5.letterSpacing + 'px',
														'font-weight': formular.fwflaeche5.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche6"
												:show="formular.fwflaeche6.showPop==true"
												
												ref='fwflaeche6' 
												target='fwflaeche6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche6.size}}</span>
												<b-button @click='plus("fwflaeche6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche6" 
																				v-bind:precision="parseInt(formular.fwflaeche6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche6!=undefined" 
												class='textfeld' v-model='formular.fwflaeche6.text' 
												style='width:100%' 
												@focus='formular.fwflaeche6.showPop=true'
												@blur ='formular.fwflaeche6.showPop=false'
												:disabled='formular.fwflaeche6.isDisabled==true || formular.fwflaeche6.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche6.size + 'px', 
														'text-align': formular.fwflaeche6.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche6.letterSpacing + 'px',
														'font-weight': formular.fwflaeche6.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche7"
												:show="formular.fwflaeche7.showPop==true"
												
												ref='fwflaeche7' 
												target='fwflaeche7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche7.size}}</span>
												<b-button @click='plus("fwflaeche7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche7" 
																				v-bind:precision="parseInt(formular.fwflaeche7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche7!=undefined" 
												class='textfeld' v-model='formular.fwflaeche7.text' 
												style='width:100%' 
												@focus='formular.fwflaeche7.showPop=true'
												@blur ='formular.fwflaeche7.showPop=false'
												:disabled='formular.fwflaeche7.isDisabled==true || formular.fwflaeche7.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche7.size + 'px', 
														'text-align': formular.fwflaeche7.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche7.letterSpacing + 'px',
														'font-weight': formular.fwflaeche7.schriftart }" 
												/>

<b-popover 
												v-if="formular.fwflaeche8"
												:show="formular.fwflaeche8.showPop==true"
												
												ref='fwflaeche8' 
												target='fwflaeche8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fwflaeche8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fwflaeche8.size}}</span>
												<b-button @click='plus("fwflaeche8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fwflaeche8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fwflaeche8" 
																				v-bind:precision="parseInt(formular.fwflaeche8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fwflaeche8!=undefined" 
												class='textfeld' v-model='formular.fwflaeche8.text' 
												style='width:100%' 
												@focus='formular.fwflaeche8.showPop=true'
												@blur ='formular.fwflaeche8.showPop=false'
												:disabled='formular.fwflaeche8.isDisabled==true || formular.fwflaeche8.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.fwflaeche8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': formular.fwflaeche8.size + 'px', 
														'text-align': formular.fwflaeche8.schriftausrichtung, 
														'letter-spacing': formular.fwflaeche8.letterSpacing + 'px',
														'font-weight': formular.fwflaeche8.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche1"
												:show="formular.sonstflaeche1.showPop==true"
												
												ref='sonstflaeche1' 
												target='sonstflaeche1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche1.size}}</span>
												<b-button @click='plus("sonstflaeche1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche1" 
																				v-bind:precision="parseInt(formular.sonstflaeche1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche1!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche1.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche1.showPop=true'
												@blur ='formular.sonstflaeche1.showPop=false'
												:disabled='formular.sonstflaeche1.isDisabled==true || formular.sonstflaeche1.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche1.size + 'px', 
														'text-align': formular.sonstflaeche1.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche1.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche1.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche2"
												:show="formular.sonstflaeche2.showPop==true"
												
												ref='sonstflaeche2' 
												target='sonstflaeche2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche2.size}}</span>
												<b-button @click='plus("sonstflaeche2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche2" 
																				v-bind:precision="parseInt(formular.sonstflaeche2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche2!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche2.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche2.showPop=true'
												@blur ='formular.sonstflaeche2.showPop=false'
												:disabled='formular.sonstflaeche2.isDisabled==true || formular.sonstflaeche2.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche2.size + 'px', 
														'text-align': formular.sonstflaeche2.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche2.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche2.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche3"
												:show="formular.sonstflaeche3.showPop==true"
												
												ref='sonstflaeche3' 
												target='sonstflaeche3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche3.size}}</span>
												<b-button @click='plus("sonstflaeche3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche3" 
																				v-bind:precision="parseInt(formular.sonstflaeche3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche3!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche3.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche3.showPop=true'
												@blur ='formular.sonstflaeche3.showPop=false'
												:disabled='formular.sonstflaeche3.isDisabled==true || formular.sonstflaeche3.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche3.size + 'px', 
														'text-align': formular.sonstflaeche3.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche3.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche3.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche4"
												:show="formular.sonstflaeche4.showPop==true"
												
												ref='sonstflaeche4' 
												target='sonstflaeche4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche4.size}}</span>
												<b-button @click='plus("sonstflaeche4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche4" 
																				v-bind:precision="parseInt(formular.sonstflaeche4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche4!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche4.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche4.showPop=true'
												@blur ='formular.sonstflaeche4.showPop=false'
												:disabled='formular.sonstflaeche4.isDisabled==true || formular.sonstflaeche4.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche4.size + 'px', 
														'text-align': formular.sonstflaeche4.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche4.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche4.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche5"
												:show="formular.sonstflaeche5.showPop==true"
												
												ref='sonstflaeche5' 
												target='sonstflaeche5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche5.size}}</span>
												<b-button @click='plus("sonstflaeche5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche5" 
																				v-bind:precision="parseInt(formular.sonstflaeche5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche5!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche5.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche5.showPop=true'
												@blur ='formular.sonstflaeche5.showPop=false'
												:disabled='formular.sonstflaeche5.isDisabled==true || formular.sonstflaeche5.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche5.size + 'px', 
														'text-align': formular.sonstflaeche5.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche5.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche5.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche6"
												:show="formular.sonstflaeche6.showPop==true"
												
												ref='sonstflaeche6' 
												target='sonstflaeche6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche6.size}}</span>
												<b-button @click='plus("sonstflaeche6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche6" 
																				v-bind:precision="parseInt(formular.sonstflaeche6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche6!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche6.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche6.showPop=true'
												@blur ='formular.sonstflaeche6.showPop=false'
												:disabled='formular.sonstflaeche6.isDisabled==true || formular.sonstflaeche6.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche6.size + 'px', 
														'text-align': formular.sonstflaeche6.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche6.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche6.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche7"
												:show="formular.sonstflaeche7.showPop==true"
												
												ref='sonstflaeche7' 
												target='sonstflaeche7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche7.size}}</span>
												<b-button @click='plus("sonstflaeche7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche7" 
																				v-bind:precision="parseInt(formular.sonstflaeche7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche7!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche7.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche7.showPop=true'
												@blur ='formular.sonstflaeche7.showPop=false'
												:disabled='formular.sonstflaeche7.isDisabled==true || formular.sonstflaeche7.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche7.size + 'px', 
														'text-align': formular.sonstflaeche7.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche7.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche7.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonstflaeche8"
												:show="formular.sonstflaeche8.showPop==true"
												
												ref='sonstflaeche8' 
												target='sonstflaeche8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstflaeche8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstflaeche8.size}}</span>
												<b-button @click='plus("sonstflaeche8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstflaeche8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonstflaeche8" 
																				v-bind:precision="parseInt(formular.sonstflaeche8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonstflaeche8!=undefined" 
												class='textfeld' v-model='formular.sonstflaeche8.text' 
												style='width:100%' 
												@focus='formular.sonstflaeche8.showPop=true'
												@blur ='formular.sonstflaeche8.showPop=false'
												:disabled='formular.sonstflaeche8.isDisabled==true || formular.sonstflaeche8.isDisabled==true' @input="betriebsfl_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonstflaeche8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': formular.sonstflaeche8.size + 'px', 
														'text-align': formular.sonstflaeche8.schriftausrichtung, 
														'letter-spacing': formular.sonstflaeche8.letterSpacing + 'px',
														'font-weight': formular.sonstflaeche8.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche1"
												:show="formular.Betriebsflaeche1.showPop==true"
												
												ref='Betriebsflaeche1' 
												target='Betriebsflaeche1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche1.size}}</span>
												<b-button @click='plus("Betriebsflaeche1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche1" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche1!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche1.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche1.showPop=true'
												@blur ='formular.Betriebsflaeche1.showPop=false'
												:disabled='formular.Betriebsflaeche1.isDisabled==true || formular.Betriebsflaeche1.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche1.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche1.size + 'px', 
														'text-align': formular.Betriebsflaeche1.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche1.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche1.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche3"
												:show="formular.Betriebsflaeche3.showPop==true"
												
												ref='Betriebsflaeche3' 
												target='Betriebsflaeche3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche3.size}}</span>
												<b-button @click='plus("Betriebsflaeche3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche3" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche3!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche3.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche3.showPop=true'
												@blur ='formular.Betriebsflaeche3.showPop=false'
												:disabled='formular.Betriebsflaeche3.isDisabled==true || formular.Betriebsflaeche3.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche3.size + 'px', 
														'text-align': formular.Betriebsflaeche3.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche3.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche3.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche5"
												:show="formular.Betriebsflaeche5.showPop==true"
												
												ref='Betriebsflaeche5' 
												target='Betriebsflaeche5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche5.size}}</span>
												<b-button @click='plus("Betriebsflaeche5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche5" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche5!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche5.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche5.showPop=true'
												@blur ='formular.Betriebsflaeche5.showPop=false'
												:disabled='formular.Betriebsflaeche5.isDisabled==true || formular.Betriebsflaeche5.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche5.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche5.size + 'px', 
														'text-align': formular.Betriebsflaeche5.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche5.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche5.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche7"
												:show="formular.Betriebsflaeche7.showPop==true"
												
												ref='Betriebsflaeche7' 
												target='Betriebsflaeche7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche7.size}}</span>
												<b-button @click='plus("Betriebsflaeche7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche7" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche7!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche7.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche7.showPop=true'
												@blur ='formular.Betriebsflaeche7.showPop=false'
												:disabled='formular.Betriebsflaeche7.isDisabled==true || formular.Betriebsflaeche7.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche7.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche7.size + 'px', 
														'text-align': formular.Betriebsflaeche7.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche7.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche7.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche2"
												:show="formular.Betriebsflaeche2.showPop==true"
												
												ref='Betriebsflaeche2' 
												target='Betriebsflaeche2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche2.size}}</span>
												<b-button @click='plus("Betriebsflaeche2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche2" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche2!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche2.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche2.showPop=true'
												@blur ='formular.Betriebsflaeche2.showPop=false'
												:disabled='formular.Betriebsflaeche2.isDisabled==true || formular.Betriebsflaeche2.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche2.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche2.size + 'px', 
														'text-align': formular.Betriebsflaeche2.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche2.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche2.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche4"
												:show="formular.Betriebsflaeche4.showPop==true"
												
												ref='Betriebsflaeche4' 
												target='Betriebsflaeche4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche4.size}}</span>
												<b-button @click='plus("Betriebsflaeche4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche4" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche4!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche4.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche4.showPop=true'
												@blur ='formular.Betriebsflaeche4.showPop=false'
												:disabled='formular.Betriebsflaeche4.isDisabled==true || formular.Betriebsflaeche4.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche4.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche4.size + 'px', 
														'text-align': formular.Betriebsflaeche4.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche4.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche4.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche6"
												:show="formular.Betriebsflaeche6.showPop==true"
												
												ref='Betriebsflaeche6' 
												target='Betriebsflaeche6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche6.size}}</span>
												<b-button @click='plus("Betriebsflaeche6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche6" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche6!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche6.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche6.showPop=true'
												@blur ='formular.Betriebsflaeche6.showPop=false'
												:disabled='formular.Betriebsflaeche6.isDisabled==true || formular.Betriebsflaeche6.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche6.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche6.size + 'px', 
														'text-align': formular.Betriebsflaeche6.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche6.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche6.schriftart }" 
												/>

<b-popover 
												v-if="formular.Betriebsflaeche8"
												:show="formular.Betriebsflaeche8.showPop==true"
												
												ref='Betriebsflaeche8' 
												target='Betriebsflaeche8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Betriebsflaeche8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Betriebsflaeche8.size}}</span>
												<b-button @click='plus("Betriebsflaeche8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Betriebsflaeche8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Betriebsflaeche8" 
																				v-bind:precision="parseInt(formular.Betriebsflaeche8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Betriebsflaeche8!=undefined" 
												class='textfeld' v-model='formular.Betriebsflaeche8.text' 
												style='width:100%' 
												@focus='formular.Betriebsflaeche8.showPop=true'
												@blur ='formular.Betriebsflaeche8.showPop=false'
												:disabled='formular.Betriebsflaeche8.isDisabled==true || formular.Betriebsflaeche8.isDisabled==true' 
												@keypress="isNumber($event, formular.Betriebsflaeche8.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': formular.Betriebsflaeche8.size + 'px', 
														'text-align': formular.Betriebsflaeche8.schriftausrichtung, 
														'letter-spacing': formular.Betriebsflaeche8.letterSpacing + 'px',
														'font-weight': formular.Betriebsflaeche8.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.0890866) + 'px', 
														top: gib_hoehe_in_mm(234.7190995) + 'px'}">
													Pachtdauer
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(56.3678086) + 'px', 
														top: gib_hoehe_in_mm(233.7590501) + 'px'}">
													bis 18 Jahre
													</div>
<b-popover 
												v-if="formular.Pachtdauer1"
												:show="formular.Pachtdauer1.showPop==true"
												
												ref='Pachtdauer1' 
												target='Pachtdauer1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer1.size}}</span>
												<b-button @click='plus("Pachtdauer1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer1" type="text" 
												v-if="formular.Pachtdauer1!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer1.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer1.showPop=true'
												@blur ='formular.Pachtdauer1.showPop=false'
												:disabled='formular.Pachtdauer1.isDisabled==true || formular.Pachtdauer1.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer1.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(234.2761204) + 'px', 
		
														'font-size': formular.Pachtdauer1.size + 'px', 
														'text-align': formular.Pachtdauer1.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer1.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer1.schriftart }" 
												/>

<b-popover 
												v-if="formular.Pachtdauer2"
												:show="formular.Pachtdauer2.showPop==true"
												
												ref='Pachtdauer2' 
												target='Pachtdauer2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer2.size}}</span>
												<b-button @click='plus("Pachtdauer2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer2" type="text" 
												v-if="formular.Pachtdauer2!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer2.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer2.showPop=true'
												@blur ='formular.Pachtdauer2.showPop=false'
												:disabled='formular.Pachtdauer2.isDisabled==true || formular.Pachtdauer2.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer2.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(234.2761204) + 'px', 
		
														'font-size': formular.Pachtdauer2.size + 'px', 
														'text-align': formular.Pachtdauer2.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer2.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer2.schriftart }" 
												/>

<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.0986703) + 'px', 
														top: gib_hoehe_in_mm(234.0595505) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(115.2461358) + 'px', 
														top: gib_hoehe_in_mm(233.7590501) + 'px'}">
													bis 18 Jahre
													</div>
<b-popover 
												v-if="formular.Pachtdauer5"
												:show="formular.Pachtdauer5.showPop==true"
												
												ref='Pachtdauer5' 
												target='Pachtdauer5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer5.size}}</span>
												<b-button @click='plus("Pachtdauer5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer5", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer5" type="text" 
												v-if="formular.Pachtdauer5!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer5.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer5.showPop=true'
												@blur ='formular.Pachtdauer5.showPop=false'
												:disabled='formular.Pachtdauer5.isDisabled==true || formular.Pachtdauer5.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer5.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(243.4181044) + 'px', 
		
														'font-size': formular.Pachtdauer5.size + 'px', 
														'text-align': formular.Pachtdauer5.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer5.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer5.schriftart }" 
												/>

<b-popover 
												v-if="formular.Pachtdauer3"
												:show="formular.Pachtdauer3.showPop==true"
												
												ref='Pachtdauer3' 
												target='Pachtdauer3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer3.size}}</span>
												<b-button @click='plus("Pachtdauer3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer3" type="text" 
												v-if="formular.Pachtdauer3!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer3.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer3.showPop=true'
												@blur ='formular.Pachtdauer3.showPop=false'
												:disabled='formular.Pachtdauer3.isDisabled==true || formular.Pachtdauer3.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer3.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(243.4181044) + 'px', 
		
														'font-size': formular.Pachtdauer3.size + 'px', 
														'text-align': formular.Pachtdauer3.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer3.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer3.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(56.3678086) + 'px', 
														top: gib_hoehe_in_mm(242.9010341) + 'px'}">
													über 18 Jahre
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(56.3678086) + 'px', 
														top: gib_hoehe_in_mm(247.9799141) + 'px'}">
													Verwandschafts
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(56.3678086) + 'px', 
														top: gib_hoehe_in_mm(252.5932301) + 'px'}">
													pacht
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.0986703) + 'px', 
														top: gib_hoehe_in_mm(243.2015345) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(115.2461358) + 'px', 
														top: gib_hoehe_in_mm(242.9010341) + 'px'}">
													über 18 Jahre
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(113.8617883) + 'px', 
														top: gib_hoehe_in_mm(247.9799141) + 'px'}">
													Verwandschafts
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.3949383) + 'px', 
														top: gib_hoehe_in_mm(252.8937305) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(113.8617883) + 'px', 
														top: gib_hoehe_in_mm(252.5932301) + 'px'}">
													pacht
													</div>
<b-popover 
												v-if="formular.Pachtdauer6"
												:show="formular.Pachtdauer6.showPop==true"
												
												ref='Pachtdauer6' 
												target='Pachtdauer6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer6.size}}</span>
												<b-button @click='plus("Pachtdauer6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer6", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer6" type="text" 
												v-if="formular.Pachtdauer6!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer6.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer6.showPop=true'
												@blur ='formular.Pachtdauer6.showPop=false'
												:disabled='formular.Pachtdauer6.isDisabled==true || formular.Pachtdauer6.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer6.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(252.5812504) + 'px', 
		
														'font-size': formular.Pachtdauer6.size + 'px', 
														'text-align': formular.Pachtdauer6.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer6.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer6.schriftart }" 
												/>

<b-popover 
												v-if="formular.Pachtdauer4"
												:show="formular.Pachtdauer4.showPop==true"
												
												ref='Pachtdauer4' 
												target='Pachtdauer4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Pachtdauer4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Pachtdauer4.size}}</span>
												<b-button @click='plus("Pachtdauer4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Pachtdauer4", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Pachtdauer4" type="text" 
												v-if="formular.Pachtdauer4!=undefined" 
												class='textfeld' v-model='formular.Pachtdauer4.text' 
												style='width:100%' 
												@focus='formular.Pachtdauer4.showPop=true'
												@blur ='formular.Pachtdauer4.showPop=false'
												:disabled='formular.Pachtdauer4.isDisabled==true || formular.Pachtdauer4.isDisabled==true' 
												@keypress="isNumber($event, formular.Pachtdauer4.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(252.5812504) + 'px', 
		
														'font-size': formular.Pachtdauer4.size + 'px', 
														'text-align': formular.Pachtdauer4.schriftausrichtung, 
														'letter-spacing': formular.Pachtdauer4.letterSpacing + 'px',
														'font-weight': formular.Pachtdauer4.schriftart }" 
												/>

<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.9358933) + 'px', 
														top: gib_hoehe_in_mm(234.0595505) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.9358933) + 'px', 
														top: gib_hoehe_in_mm(243.2015345) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.9358933) + 'px', 
														top: gib_hoehe_in_mm(252.8937305) + 'px'}">
													ha
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(172.6604053) + 'px', 
														top: gib_hoehe_in_mm(257.8706802) + 'px'}">
													Fortsetzung Blatt 2
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(202.7594706) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(244.3877794) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(170.2475846) + 'px', 
													width: spalte_breit_mm(32.511886) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(263.1206961) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(170.2475846) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.8567694) + 'px', 
													top: gib_hoehe_in_mm(257.3639267) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(257.3639267) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(238.63101) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(37.1253495) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(61.7155935) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(71.2811702) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(78.7725182) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(147.576734) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(86.1788655) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.5453577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(147.576734) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(100.8762272) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.3245458) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(147.576734) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.7319512) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(123.1382985) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(147.576734) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(130.5026745) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(137.9093745) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(147.576734) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(145.2737505) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(152.8500992) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1404126) + 'px', 
													width: spalte_breit_mm(117.4346393) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(162.6678564) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(169.3211892) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(175.9237332) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(189.5520612) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(202.8421499) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(212.5339932) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(219.4324525) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9463386) + 'px', 
													width: spalte_breit_mm(153.6287133) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(229.3810614) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1404126) + 'px', 
													width: spalte_breit_mm(117.4346393) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(238.5311575) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1404126) + 'px', 
													width: spalte_breit_mm(117.4346393) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(247.7161709) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.8285976) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(18.3924328) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.8285976) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(24.690244) + 'px', 
													top: gib_hoehe_in_mm(37.1253495) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(55.1827366) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(178.6935247) + 'px', 
													top: gib_hoehe_in_mm(78.7725182) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.3137576) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(112.6343923) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(104.6159437) + 'px', 
													top: gib_hoehe_in_mm(152.8500992) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.3137576) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(83.2118056) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.0675296) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(127.8710323) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(142.7267563) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.6353853) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(70.9172222) + 'px', 
													top: gib_hoehe_in_mm(158.5638392) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(172.5750519) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(104.6138275) + 'px', 
													top: gib_hoehe_in_mm(152.8500992) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(205.4558621) + 'px', 
													width: spalte_breit_mm(4.6260132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(210.0818753) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(2.6750627) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4910691) + 'px', 
														top: gib_hoehe_in_mm(19.0091903) + 'px'}">
													Betriebsbeschreibung Blatt 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(60.8594431) + 'px', 
														top: gib_hoehe_in_mm(19.0229456) + 'px'}">
													Bauherrschaft
													</div>
<b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name" type="text" 
												v-if="formular.bauherr_name!=undefined" 
												class='textfeld' v-model='formular.bauherr_name.text' 
												style='width:100%' 
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												:disabled='formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_name.onylNumber) " 
												:style="{'width': spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': formular.bauherr_name.size + 'px', 
														'text-align': formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.2930438) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(23.1420578) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													2 Bodennutzung (ha)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(85.4606208) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													Ziel
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													Bodennutzung (ha)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(137.1452788) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(158.5718038) + 'px', 
														top: gib_hoehe_in_mm(29.199046) + 'px'}">
													Ziel
													</div>
<b-popover 
												v-if="formular.getreideIst"
												:show="formular.getreideIst.showPop==true"
												
												ref='getreideIst' 
												target='getreideIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.getreideIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.getreideIst.size}}</span>
												<b-button @click='plus("getreideIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("getreideIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="getreideIst" 
																				v-bind:precision="parseInt(formular.getreideIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.getreideIst!=undefined" 
												class='textfeld' v-model='formular.getreideIst.text' 
												style='width:100%' 
												@focus='formular.getreideIst.showPop=true'
												@blur ='formular.getreideIst.showPop=false'
												:disabled='formular.getreideIst.isDisabled==true || formular.getreideIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.getreideIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': formular.getreideIst.size + 'px', 
														'text-align': formular.getreideIst.schriftausrichtung, 
														'letter-spacing': formular.getreideIst.letterSpacing + 'px',
														'font-weight': formular.getreideIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.getreideZiel"
												:show="formular.getreideZiel.showPop==true"
												
												ref='getreideZiel' 
												target='getreideZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.getreideZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.getreideZiel.size}}</span>
												<b-button @click='plus("getreideZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("getreideZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="getreideZiel" 
																				v-bind:precision="parseInt(formular.getreideZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.getreideZiel!=undefined" 
												class='textfeld' v-model='formular.getreideZiel.text' 
												style='width:100%' 
												@focus='formular.getreideZiel.showPop=true'
												@blur ='formular.getreideZiel.showPop=false'
												:disabled='formular.getreideZiel.isDisabled==true || formular.getreideZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.getreideZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': formular.getreideZiel.size + 'px', 
														'text-align': formular.getreideZiel.schriftausrichtung, 
														'letter-spacing': formular.getreideZiel.letterSpacing + 'px',
														'font-weight': formular.getreideZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.obstIst"
												:show="formular.obstIst.showPop==true"
												
												ref='obstIst' 
												target='obstIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.obstIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.obstIst.size}}</span>
												<b-button @click='plus("obstIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("obstIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="obstIst" 
																				v-bind:precision="parseInt(formular.obstIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.obstIst!=undefined" 
												class='textfeld' v-model='formular.obstIst.text' 
												style='width:100%' 
												@focus='formular.obstIst.showPop=true'
												@blur ='formular.obstIst.showPop=false'
												:disabled='formular.obstIst.isDisabled==true || formular.obstIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.obstIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': formular.obstIst.size + 'px', 
														'text-align': formular.obstIst.schriftausrichtung, 
														'letter-spacing': formular.obstIst.letterSpacing + 'px',
														'font-weight': formular.obstIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.obstZiel"
												:show="formular.obstZiel.showPop==true"
												
												ref='obstZiel' 
												target='obstZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.obstZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.obstZiel.size}}</span>
												<b-button @click='plus("obstZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("obstZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="obstZiel" 
																				v-bind:precision="parseInt(formular.obstZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.obstZiel!=undefined" 
												class='textfeld' v-model='formular.obstZiel.text' 
												style='width:100%' 
												@focus='formular.obstZiel.showPop=true'
												@blur ='formular.obstZiel.showPop=false'
												:disabled='formular.obstZiel.isDisabled==true || formular.obstZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.obstZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': formular.obstZiel.size + 'px', 
														'text-align': formular.obstZiel.schriftausrichtung, 
														'letter-spacing': formular.obstZiel.letterSpacing + 'px',
														'font-weight': formular.obstZiel.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(166.4020965) + 'px', 
														top: gib_hoehe_in_mm(9.1857899) + 'px'}">
													Anlage I/9 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(196.0288965) + 'px', 
														top: gib_hoehe_in_mm(12.4870619) + 'px'}">
													Blatt 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.2901365) + 'px', 
														top: gib_hoehe_in_mm(19.0229456) + 'px'}">
													Antrag vom
													</div>
<b-popover 
												v-if="formular.bauantragVom"
												:show="formular.bauantragVom.showPop==true"
												
												ref='bauantragVom' 
												target='bauantragVom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVom.size}}</span>
												<b-button @click='plus("bauantragVom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVom" type="text" 
												v-if="formular.bauantragVom!=undefined" 
												class='textfeld' v-model='formular.bauantragVom.text' 
												style='width:100%' 
												@focus='formular.bauantragVom.showPop=true'
												@blur ='formular.bauantragVom.showPop=false'
												:disabled='formular.bauantragVom.isDisabled==true || formular.bauantragVom.isDisabled==true' 
												@keypress="isNumber($event, formular.bauantragVom.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.3340259) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(175.5404892) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': formular.bauantragVom.size + 'px', 
														'text-align': formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': formular.bauantragVom.letterSpacing + 'px',
														'font-weight': formular.bauantragVom.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.2901365) + 'px', 
														top: gib_hoehe_in_mm(27.7836609) + 'px'}">
													Prüfvermerke
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(35.9289147) + 'px'}">
													Obst, Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(43.2509667) + 'px'}">
													Gemüse, Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(50.5730187) + 'px'}">
													Sonstige, Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(57.894718) + 'px'}">
													Sonstige, Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(65.3864187) + 'px'}">
													Summe der
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(69.0262827) + 'px'}">
													Bodennutzung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(74.612698) + 'px'}">
													davon unter Glas
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(87.6491954) + 'px'}">
													Tierhaltung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(91.2890594) + 'px'}">
													(Anzahl)
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(96.6455143) + 'px'}">
													Mastgeflügel, Art
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(105.1526383) + 'px'}">
													davon Käfighaltung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(111.2472943) + 'px'}">
													Legehennen in
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(114.5062423) + 'px'}">
													Käfighaltung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(119.4158263) + 'px'}">
													Legehennen in
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(122.6747743) + 'px'}">
													Bodenhaltung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(128.8114016) + 'px'}">
													Legehennen freilaufen
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(134.9060576) + 'px'}">
													Sonstige Tiere, Art
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(142.2284623) + 'px'}">
													Sonstige Tiere, Art
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(149.7617816) + 'px'}">
													Hauptfutterfläche
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(157.3381303) + 'px'}">
													Zusatzfutterfläche
													</div>
<b-popover 
												v-if="formular.OelfruechteIst"
												:show="formular.OelfruechteIst.showPop==true"
												
												ref='OelfruechteIst' 
												target='OelfruechteIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.OelfruechteIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.OelfruechteIst.size}}</span>
												<b-button @click='plus("OelfruechteIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("OelfruechteIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="OelfruechteIst" 
																				v-bind:precision="parseInt(formular.OelfruechteIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.OelfruechteIst!=undefined" 
												class='textfeld' v-model='formular.OelfruechteIst.text' 
												style='width:100%' 
												@focus='formular.OelfruechteIst.showPop=true'
												@blur ='formular.OelfruechteIst.showPop=false'
												:disabled='formular.OelfruechteIst.isDisabled==true || formular.OelfruechteIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.OelfruechteIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': formular.OelfruechteIst.size + 'px', 
														'text-align': formular.OelfruechteIst.schriftausrichtung, 
														'letter-spacing': formular.OelfruechteIst.letterSpacing + 'px',
														'font-weight': formular.OelfruechteIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.OelfruechteZiel"
												:show="formular.OelfruechteZiel.showPop==true"
												
												ref='OelfruechteZiel' 
												target='OelfruechteZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.OelfruechteZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.OelfruechteZiel.size}}</span>
												<b-button @click='plus("OelfruechteZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("OelfruechteZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="OelfruechteZiel" 
																				v-bind:precision="parseInt(formular.OelfruechteZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.OelfruechteZiel!=undefined" 
												class='textfeld' v-model='formular.OelfruechteZiel.text' 
												style='width:100%' 
												@focus='formular.OelfruechteZiel.showPop=true'
												@blur ='formular.OelfruechteZiel.showPop=false'
												:disabled='formular.OelfruechteZiel.isDisabled==true || formular.OelfruechteZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.OelfruechteZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': formular.OelfruechteZiel.size + 'px', 
														'text-align': formular.OelfruechteZiel.schriftausrichtung, 
														'letter-spacing': formular.OelfruechteZiel.letterSpacing + 'px',
														'font-weight': formular.OelfruechteZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.gemuseIst"
												:show="formular.gemuseIst.showPop==true"
												
												ref='gemuseIst' 
												target='gemuseIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemuseIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemuseIst.size}}</span>
												<b-button @click='plus("gemuseIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemuseIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gemuseIst" 
																				v-bind:precision="parseInt(formular.gemuseIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gemuseIst!=undefined" 
												class='textfeld' v-model='formular.gemuseIst.text' 
												style='width:100%' 
												@focus='formular.gemuseIst.showPop=true'
												@blur ='formular.gemuseIst.showPop=false'
												:disabled='formular.gemuseIst.isDisabled==true || formular.gemuseIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.gemuseIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': formular.gemuseIst.size + 'px', 
														'text-align': formular.gemuseIst.schriftausrichtung, 
														'letter-spacing': formular.gemuseIst.letterSpacing + 'px',
														'font-weight': formular.gemuseIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.gemuseZiel"
												:show="formular.gemuseZiel.showPop==true"
												
												ref='gemuseZiel' 
												target='gemuseZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemuseZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemuseZiel.size}}</span>
												<b-button @click='plus("gemuseZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemuseZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="gemuseZiel" 
																				v-bind:precision="parseInt(formular.gemuseZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.gemuseZiel!=undefined" 
												class='textfeld' v-model='formular.gemuseZiel.text' 
												style='width:100%' 
												@focus='formular.gemuseZiel.showPop=true'
												@blur ='formular.gemuseZiel.showPop=false'
												:disabled='formular.gemuseZiel.isDisabled==true || formular.gemuseZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.gemuseZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': formular.gemuseZiel.size + 'px', 
														'text-align': formular.gemuseZiel.schriftausrichtung, 
														'letter-spacing': formular.gemuseZiel.letterSpacing + 'px',
														'font-weight': formular.gemuseZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.kartoffelnIst"
												:show="formular.kartoffelnIst.showPop==true"
												
												ref='kartoffelnIst' 
												target='kartoffelnIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kartoffelnIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kartoffelnIst.size}}</span>
												<b-button @click='plus("kartoffelnIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kartoffelnIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="kartoffelnIst" 
																				v-bind:precision="parseInt(formular.kartoffelnIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.kartoffelnIst!=undefined" 
												class='textfeld' v-model='formular.kartoffelnIst.text' 
												style='width:100%' 
												@focus='formular.kartoffelnIst.showPop=true'
												@blur ='formular.kartoffelnIst.showPop=false'
												:disabled='formular.kartoffelnIst.isDisabled==true || formular.kartoffelnIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.kartoffelnIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': formular.kartoffelnIst.size + 'px', 
														'text-align': formular.kartoffelnIst.schriftausrichtung, 
														'letter-spacing': formular.kartoffelnIst.letterSpacing + 'px',
														'font-weight': formular.kartoffelnIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.kartoffelnZiel"
												:show="formular.kartoffelnZiel.showPop==true"
												
												ref='kartoffelnZiel' 
												target='kartoffelnZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kartoffelnZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kartoffelnZiel.size}}</span>
												<b-button @click='plus("kartoffelnZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kartoffelnZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="kartoffelnZiel" 
																				v-bind:precision="parseInt(formular.kartoffelnZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.kartoffelnZiel!=undefined" 
												class='textfeld' v-model='formular.kartoffelnZiel.text' 
												style='width:100%' 
												@focus='formular.kartoffelnZiel.showPop=true'
												@blur ='formular.kartoffelnZiel.showPop=false'
												:disabled='formular.kartoffelnZiel.isDisabled==true || formular.kartoffelnZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.kartoffelnZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': formular.kartoffelnZiel.size + 'px', 
														'text-align': formular.kartoffelnZiel.schriftausrichtung, 
														'letter-spacing': formular.kartoffelnZiel.letterSpacing + 'px',
														'font-weight': formular.kartoffelnZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonst1Ist"
												:show="formular.sonst1Ist.showPop==true"
												
												ref='sonst1Ist' 
												target='sonst1Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonst1Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonst1Ist.size}}</span>
												<b-button @click='plus("sonst1Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonst1Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonst1Ist" 
																				v-bind:precision="parseInt(formular.sonst1Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonst1Ist!=undefined" 
												class='textfeld' v-model='formular.sonst1Ist.text' 
												style='width:100%' 
												@focus='formular.sonst1Ist.showPop=true'
												@blur ='formular.sonst1Ist.showPop=false'
												:disabled='formular.sonst1Ist.isDisabled==true || formular.sonst1Ist.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonst1Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': formular.sonst1Ist.size + 'px', 
														'text-align': formular.sonst1Ist.schriftausrichtung, 
														'letter-spacing': formular.sonst1Ist.letterSpacing + 'px',
														'font-weight': formular.sonst1Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonst1Ziel"
												:show="formular.sonst1Ziel.showPop==true"
												
												ref='sonst1Ziel' 
												target='sonst1Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonst1Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonst1Ziel.size}}</span>
												<b-button @click='plus("sonst1Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonst1Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonst1Ziel" 
																				v-bind:precision="parseInt(formular.sonst1Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonst1Ziel!=undefined" 
												class='textfeld' v-model='formular.sonst1Ziel.text' 
												style='width:100%' 
												@focus='formular.sonst1Ziel.showPop=true'
												@blur ='formular.sonst1Ziel.showPop=false'
												:disabled='formular.sonst1Ziel.isDisabled==true || formular.sonst1Ziel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonst1Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': formular.sonst1Ziel.size + 'px', 
														'text-align': formular.sonst1Ziel.schriftausrichtung, 
														'letter-spacing': formular.sonst1Ziel.letterSpacing + 'px',
														'font-weight': formular.sonst1Ziel.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuckIst"
												:show="formular.zuckIst.showPop==true"
												
												ref='zuckIst' 
												target='zuckIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuckIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuckIst.size}}</span>
												<b-button @click='plus("zuckIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuckIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="zuckIst" 
																				v-bind:precision="parseInt(formular.zuckIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.zuckIst!=undefined" 
												class='textfeld' v-model='formular.zuckIst.text' 
												style='width:100%' 
												@focus='formular.zuckIst.showPop=true'
												@blur ='formular.zuckIst.showPop=false'
												:disabled='formular.zuckIst.isDisabled==true || formular.zuckIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.zuckIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': formular.zuckIst.size + 'px', 
														'text-align': formular.zuckIst.schriftausrichtung, 
														'letter-spacing': formular.zuckIst.letterSpacing + 'px',
														'font-weight': formular.zuckIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuckZiel"
												:show="formular.zuckZiel.showPop==true"
												
												ref='zuckZiel' 
												target='zuckZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuckZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuckZiel.size}}</span>
												<b-button @click='plus("zuckZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuckZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="zuckZiel" 
																				v-bind:precision="parseInt(formular.zuckZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.zuckZiel!=undefined" 
												class='textfeld' v-model='formular.zuckZiel.text' 
												style='width:100%' 
												@focus='formular.zuckZiel.showPop=true'
												@blur ='formular.zuckZiel.showPop=false'
												:disabled='formular.zuckZiel.isDisabled==true || formular.zuckZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.zuckZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': formular.zuckZiel.size + 'px', 
														'text-align': formular.zuckZiel.schriftausrichtung, 
														'letter-spacing': formular.zuckZiel.letterSpacing + 'px',
														'font-weight': formular.zuckZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonst2Ist"
												:show="formular.sonst2Ist.showPop==true"
												
												ref='sonst2Ist' 
												target='sonst2Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonst2Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonst2Ist.size}}</span>
												<b-button @click='plus("sonst2Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonst2Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonst2Ist" 
																				v-bind:precision="parseInt(formular.sonst2Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonst2Ist!=undefined" 
												class='textfeld' v-model='formular.sonst2Ist.text' 
												style='width:100%' 
												@focus='formular.sonst2Ist.showPop=true'
												@blur ='formular.sonst2Ist.showPop=false'
												:disabled='formular.sonst2Ist.isDisabled==true || formular.sonst2Ist.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonst2Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': formular.sonst2Ist.size + 'px', 
														'text-align': formular.sonst2Ist.schriftausrichtung, 
														'letter-spacing': formular.sonst2Ist.letterSpacing + 'px',
														'font-weight': formular.sonst2Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.sonst2Ziel"
												:show="formular.sonst2Ziel.showPop==true"
												
												ref='sonst2Ziel' 
												target='sonst2Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonst2Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonst2Ziel.size}}</span>
												<b-button @click='plus("sonst2Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonst2Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="sonst2Ziel" 
																				v-bind:precision="parseInt(formular.sonst2Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.sonst2Ziel!=undefined" 
												class='textfeld' v-model='formular.sonst2Ziel.text' 
												style='width:100%' 
												@focus='formular.sonst2Ziel.showPop=true'
												@blur ='formular.sonst2Ziel.showPop=false'
												:disabled='formular.sonst2Ziel.isDisabled==true || formular.sonst2Ziel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.sonst2Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': formular.sonst2Ziel.size + 'px', 
														'text-align': formular.sonst2Ziel.schriftausrichtung, 
														'letter-spacing': formular.sonst2Ziel.letterSpacing + 'px',
														'font-weight': formular.sonst2Ziel.schriftart }" 
												/>

<b-popover 
												v-if="formular.akfuIst"
												:show="formular.akfuIst.showPop==true"
												
												ref='akfuIst' 
												target='akfuIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.akfuIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.akfuIst.size}}</span>
												<b-button @click='plus("akfuIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("akfuIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="akfuIst" 
																				v-bind:precision="parseInt(formular.akfuIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.akfuIst!=undefined" 
												class='textfeld' v-model='formular.akfuIst.text' 
												style='width:100%' 
												@focus='formular.akfuIst.showPop=true'
												@blur ='formular.akfuIst.showPop=false'
												:disabled='formular.akfuIst.isDisabled==true || formular.akfuIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.akfuIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': formular.akfuIst.size + 'px', 
														'text-align': formular.akfuIst.schriftausrichtung, 
														'letter-spacing': formular.akfuIst.letterSpacing + 'px',
														'font-weight': formular.akfuIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.akfuZiel"
												:show="formular.akfuZiel.showPop==true"
												
												ref='akfuZiel' 
												target='akfuZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.akfuZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.akfuZiel.size}}</span>
												<b-button @click='plus("akfuZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("akfuZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="akfuZiel" 
																				v-bind:precision="parseInt(formular.akfuZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.akfuZiel!=undefined" 
												class='textfeld' v-model='formular.akfuZiel.text' 
												style='width:100%' 
												@focus='formular.akfuZiel.showPop=true'
												@blur ='formular.akfuZiel.showPop=false'
												:disabled='formular.akfuZiel.isDisabled==true || formular.akfuZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.akfuZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': formular.akfuZiel.size + 'px', 
														'text-align': formular.akfuZiel.schriftausrichtung, 
														'letter-spacing': formular.akfuZiel.letterSpacing + 'px',
														'font-weight': formular.akfuZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.summeBodennutzungIst"
												:show="formular.summeBodennutzungIst.showPop==true"
												
												ref='summeBodennutzungIst' 
												target='summeBodennutzungIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summeBodennutzungIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summeBodennutzungIst.size}}</span>
												<b-button @click='plus("summeBodennutzungIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summeBodennutzungIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summeBodennutzungIst" 
																				v-bind:precision="parseInt(formular.summeBodennutzungIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summeBodennutzungIst!=undefined" 
												class='textfeld' v-model='formular.summeBodennutzungIst.text' 
												style='width:100%' 
												@focus='formular.summeBodennutzungIst.showPop=true'
												@blur ='formular.summeBodennutzungIst.showPop=false'
												:disabled='formular.summeBodennutzungIst.isDisabled==true || formular.summeBodennutzungIst.isDisabled==true' 
												@keypress="isNumber($event, formular.summeBodennutzungIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': formular.summeBodennutzungIst.size + 'px', 
														'text-align': formular.summeBodennutzungIst.schriftausrichtung, 
														'letter-spacing': formular.summeBodennutzungIst.letterSpacing + 'px',
														'font-weight': formular.summeBodennutzungIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.summeBodennutzungZiel"
												:show="formular.summeBodennutzungZiel.showPop==true"
												
												ref='summeBodennutzungZiel' 
												target='summeBodennutzungZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summeBodennutzungZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summeBodennutzungZiel.size}}</span>
												<b-button @click='plus("summeBodennutzungZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summeBodennutzungZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summeBodennutzungZiel" 
																				v-bind:precision="parseInt(formular.summeBodennutzungZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summeBodennutzungZiel!=undefined" 
												class='textfeld' v-model='formular.summeBodennutzungZiel.text' 
												style='width:100%' 
												@focus='formular.summeBodennutzungZiel.showPop=true'
												@blur ='formular.summeBodennutzungZiel.showPop=false'
												:disabled='formular.summeBodennutzungZiel.isDisabled==true || formular.summeBodennutzungZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.summeBodennutzungZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': formular.summeBodennutzungZiel.size + 'px', 
														'text-align': formular.summeBodennutzungZiel.schriftausrichtung, 
														'letter-spacing': formular.summeBodennutzungZiel.letterSpacing + 'px',
														'font-weight': formular.summeBodennutzungZiel.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								@input="bodennut_selbst"
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.BodennutzungSelbstIst_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(130.7123835) + 'px',top: gib_hoehe_in_mm(69.8721337) + 'px'}"
								v-model="formular.BodennutzungSelbstIst_check.text" 
								value='1' 
								unchecked-value='0'> selbst eing.
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								@input="bodennut_selbst"
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.BodennutzungSelbstZiel_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(149.7680591) + 'px',top: gib_hoehe_in_mm(69.8721337) + 'px'}"
								v-model="formular.BodennutzungSelbstZiel_check.text" 
								value='1' 
								unchecked-value='0'> selbst eing.
								</b-form-checkbox>
<b-popover 
												v-if="formular.weideIst"
												:show="formular.weideIst.showPop==true"
												
												ref='weideIst' 
												target='weideIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.weideIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.weideIst.size}}</span>
												<b-button @click='plus("weideIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("weideIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="weideIst" 
																				v-bind:precision="parseInt(formular.weideIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.weideIst!=undefined" 
												class='textfeld' v-model='formular.weideIst.text' 
												style='width:100%' 
												@focus='formular.weideIst.showPop=true'
												@blur ='formular.weideIst.showPop=false'
												:disabled='formular.weideIst.isDisabled==true || formular.weideIst.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.weideIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': formular.weideIst.size + 'px', 
														'text-align': formular.weideIst.schriftausrichtung, 
														'letter-spacing': formular.weideIst.letterSpacing + 'px',
														'font-weight': formular.weideIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.weideZiel"
												:show="formular.weideZiel.showPop==true"
												
												ref='weideZiel' 
												target='weideZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.weideZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.weideZiel.size}}</span>
												<b-button @click='plus("weideZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("weideZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="weideZiel" 
																				v-bind:precision="parseInt(formular.weideZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.weideZiel!=undefined" 
												class='textfeld' v-model='formular.weideZiel.text' 
												style='width:100%' 
												@focus='formular.weideZiel.showPop=true'
												@blur ='formular.weideZiel.showPop=false'
												:disabled='formular.weideZiel.isDisabled==true || formular.weideZiel.isDisabled==true' @input="bodennut_neu_zaehlen"  
												@keypress="isNumber($event, formular.weideZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': formular.weideZiel.size + 'px', 
														'text-align': formular.weideZiel.schriftausrichtung, 
														'letter-spacing': formular.weideZiel.letterSpacing + 'px',
														'font-weight': formular.weideZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.summeGlasIst"
												:show="formular.summeGlasIst.showPop==true"
												
												ref='summeGlasIst' 
												target='summeGlasIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summeGlasIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summeGlasIst.size}}</span>
												<b-button @click='plus("summeGlasIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summeGlasIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summeGlasIst" 
																				v-bind:precision="parseInt(formular.summeGlasIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summeGlasIst!=undefined" 
												class='textfeld' v-model='formular.summeGlasIst.text' 
												style='width:100%' 
												@focus='formular.summeGlasIst.showPop=true'
												@blur ='formular.summeGlasIst.showPop=false'
												:disabled='formular.summeGlasIst.isDisabled==true || formular.summeGlasIst.isDisabled==true' 
												@keypress="isNumber($event, formular.summeGlasIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': formular.summeGlasIst.size + 'px', 
														'text-align': formular.summeGlasIst.schriftausrichtung, 
														'letter-spacing': formular.summeGlasIst.letterSpacing + 'px',
														'font-weight': formular.summeGlasIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.summeGlasZiel"
												:show="formular.summeGlasZiel.showPop==true"
												
												ref='summeGlasZiel' 
												target='summeGlasZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.summeGlasZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.summeGlasZiel.size}}</span>
												<b-button @click='plus("summeGlasZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("summeGlasZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="summeGlasZiel" 
																				v-bind:precision="parseInt(formular.summeGlasZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.summeGlasZiel!=undefined" 
												class='textfeld' v-model='formular.summeGlasZiel.text' 
												style='width:100%' 
												@focus='formular.summeGlasZiel.showPop=true'
												@blur ='formular.summeGlasZiel.showPop=false'
												:disabled='formular.summeGlasZiel.isDisabled==true || formular.summeGlasZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.summeGlasZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': formular.summeGlasZiel.size + 'px', 
														'text-align': formular.summeGlasZiel.schriftausrichtung, 
														'letter-spacing': formular.summeGlasZiel.letterSpacing + 'px',
														'font-weight': formular.summeGlasZiel.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.2930438) + 'px', 
														top: gib_hoehe_in_mm(89.5110987) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(85.4606208) + 'px', 
														top: gib_hoehe_in_mm(89.5110987) + 'px'}">
													Ziel
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(137.1452788) + 'px', 
														top: gib_hoehe_in_mm(89.5110987) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(158.5718038) + 'px', 
														top: gib_hoehe_in_mm(89.5110987) + 'px'}">
													Ziel
													</div>
<b-popover 
												v-if="formular.MiKuIst"
												:show="formular.MiKuIst.showPop==true"
												
												ref='MiKuIst' 
												target='MiKuIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MiKuIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MiKuIst.size}}</span>
												<b-button @click='plus("MiKuIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MiKuIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MiKuIst" 
																				v-bind:precision="parseInt(formular.MiKuIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MiKuIst!=undefined" 
												class='textfeld' v-model='formular.MiKuIst.text' 
												style='width:100%' 
												@focus='formular.MiKuIst.showPop=true'
												@blur ='formular.MiKuIst.showPop=false'
												:disabled='formular.MiKuIst.isDisabled==true || formular.MiKuIst.isDisabled==true' 
												@keypress="isNumber($event, formular.MiKuIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': formular.MiKuIst.size + 'px', 
														'text-align': formular.MiKuIst.schriftausrichtung, 
														'letter-spacing': formular.MiKuIst.letterSpacing + 'px',
														'font-weight': formular.MiKuIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.MiKuZiel"
												:show="formular.MiKuZiel.showPop==true"
												
												ref='MiKuZiel' 
												target='MiKuZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MiKuZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MiKuZiel.size}}</span>
												<b-button @click='plus("MiKuZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MiKuZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MiKuZiel" 
																				v-bind:precision="parseInt(formular.MiKuZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MiKuZiel!=undefined" 
												class='textfeld' v-model='formular.MiKuZiel.text' 
												style='width:100%' 
												@focus='formular.MiKuZiel.showPop=true'
												@blur ='formular.MiKuZiel.showPop=false'
												:disabled='formular.MiKuZiel.isDisabled==true || formular.MiKuZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.MiKuZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': formular.MiKuZiel.size + 'px', 
														'text-align': formular.MiKuZiel.schriftausrichtung, 
														'letter-spacing': formular.MiKuZiel.letterSpacing + 'px',
														'font-weight': formular.MiKuZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.GeflIst"
												:show="formular.GeflIst.showPop==true"
												
												ref='GeflIst' 
												target='GeflIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.GeflIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.GeflIst.size}}</span>
												<b-button @click='plus("GeflIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("GeflIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="GeflIst" 
																				v-bind:precision="parseInt(formular.GeflIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.GeflIst!=undefined" 
												class='textfeld' v-model='formular.GeflIst.text' 
												style='width:100%' 
												@focus='formular.GeflIst.showPop=true'
												@blur ='formular.GeflIst.showPop=false'
												:disabled='formular.GeflIst.isDisabled==true || formular.GeflIst.isDisabled==true' 
												@keypress="isNumber($event, formular.GeflIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': formular.GeflIst.size + 'px', 
														'text-align': formular.GeflIst.schriftausrichtung, 
														'letter-spacing': formular.GeflIst.letterSpacing + 'px',
														'font-weight': formular.GeflIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.GeflZiel"
												:show="formular.GeflZiel.showPop==true"
												
												ref='GeflZiel' 
												target='GeflZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.GeflZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.GeflZiel.size}}</span>
												<b-button @click='plus("GeflZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("GeflZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="GeflZiel" 
																				v-bind:precision="parseInt(formular.GeflZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.GeflZiel!=undefined" 
												class='textfeld' v-model='formular.GeflZiel.text' 
												style='width:100%' 
												@focus='formular.GeflZiel.showPop=true'
												@blur ='formular.GeflZiel.showPop=false'
												:disabled='formular.GeflZiel.isDisabled==true || formular.GeflZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.GeflZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': formular.GeflZiel.size + 'px', 
														'text-align': formular.GeflZiel.schriftausrichtung, 
														'letter-spacing': formular.GeflZiel.letterSpacing + 'px',
														'font-weight': formular.GeflZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.RindIst"
												:show="formular.RindIst.showPop==true"
												
												ref='RindIst' 
												target='RindIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.RindIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.RindIst.size}}</span>
												<b-button @click='plus("RindIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("RindIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="RindIst" 
																				v-bind:precision="parseInt(formular.RindIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.RindIst!=undefined" 
												class='textfeld' v-model='formular.RindIst.text' 
												style='width:100%' 
												@focus='formular.RindIst.showPop=true'
												@blur ='formular.RindIst.showPop=false'
												:disabled='formular.RindIst.isDisabled==true || formular.RindIst.isDisabled==true' 
												@keypress="isNumber($event, formular.RindIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': formular.RindIst.size + 'px', 
														'text-align': formular.RindIst.schriftausrichtung, 
														'letter-spacing': formular.RindIst.letterSpacing + 'px',
														'font-weight': formular.RindIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.RindZiel"
												:show="formular.RindZiel.showPop==true"
												
												ref='RindZiel' 
												target='RindZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.RindZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.RindZiel.size}}</span>
												<b-button @click='plus("RindZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("RindZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="RindZiel" 
																				v-bind:precision="parseInt(formular.RindZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.RindZiel!=undefined" 
												class='textfeld' v-model='formular.RindZiel.text' 
												style='width:100%' 
												@focus='formular.RindZiel.showPop=true'
												@blur ='formular.RindZiel.showPop=false'
												:disabled='formular.RindZiel.isDisabled==true || formular.RindZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.RindZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': formular.RindZiel.size + 'px', 
														'text-align': formular.RindZiel.schriftausrichtung, 
														'letter-spacing': formular.RindZiel.letterSpacing + 'px',
														'font-weight': formular.RindZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.KaefigIst"
												:show="formular.KaefigIst.showPop==true"
												
												ref='KaefigIst' 
												target='KaefigIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.KaefigIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.KaefigIst.size}}</span>
												<b-button @click='plus("KaefigIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("KaefigIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="KaefigIst" 
																				v-bind:precision="parseInt(formular.KaefigIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.KaefigIst!=undefined" 
												class='textfeld' v-model='formular.KaefigIst.text' 
												style='width:100%' 
												@focus='formular.KaefigIst.showPop=true'
												@blur ='formular.KaefigIst.showPop=false'
												:disabled='formular.KaefigIst.isDisabled==true || formular.KaefigIst.isDisabled==true' 
												@keypress="isNumber($event, formular.KaefigIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': formular.KaefigIst.size + 'px', 
														'text-align': formular.KaefigIst.schriftausrichtung, 
														'letter-spacing': formular.KaefigIst.letterSpacing + 'px',
														'font-weight': formular.KaefigIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.KaefigZiel"
												:show="formular.KaefigZiel.showPop==true"
												
												ref='KaefigZiel' 
												target='KaefigZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.KaefigZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.KaefigZiel.size}}</span>
												<b-button @click='plus("KaefigZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("KaefigZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="KaefigZiel" 
																				v-bind:precision="parseInt(formular.KaefigZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.KaefigZiel!=undefined" 
												class='textfeld' v-model='formular.KaefigZiel.text' 
												style='width:100%' 
												@focus='formular.KaefigZiel.showPop=true'
												@blur ='formular.KaefigZiel.showPop=false'
												:disabled='formular.KaefigZiel.isDisabled==true || formular.KaefigZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.KaefigZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': formular.KaefigZiel.size + 'px', 
														'text-align': formular.KaefigZiel.schriftausrichtung, 
														'letter-spacing': formular.KaefigZiel.letterSpacing + 'px',
														'font-weight': formular.KaefigZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.MiKuIst"
												:show="formular.MiKuIst.showPop==true"
												
												ref='MiKuIst' 
												target='MiKuIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MiKuIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MiKuIst.size}}</span>
												<b-button @click='plus("MiKuIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MiKuIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MiKuIst" 
																				v-bind:precision="parseInt(formular.MiKuIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MiKuIst!=undefined" 
												class='textfeld' v-model='formular.MiKuIst.text' 
												style='width:100%' 
												@focus='formular.MiKuIst.showPop=true'
												@blur ='formular.MiKuIst.showPop=false'
												:disabled='formular.MiKuIst.isDisabled==true || formular.MiKuIst.isDisabled==true' 
												@keypress="isNumber($event, formular.MiKuIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': formular.MiKuIst.size + 'px', 
														'text-align': formular.MiKuIst.schriftausrichtung, 
														'letter-spacing': formular.MiKuIst.letterSpacing + 'px',
														'font-weight': formular.MiKuIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.MiKuZiel"
												:show="formular.MiKuZiel.showPop==true"
												
												ref='MiKuZiel' 
												target='MiKuZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MiKuZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MiKuZiel.size}}</span>
												<b-button @click='plus("MiKuZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MiKuZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MiKuZiel" 
																				v-bind:precision="parseInt(formular.MiKuZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MiKuZiel!=undefined" 
												class='textfeld' v-model='formular.MiKuZiel.text' 
												style='width:100%' 
												@focus='formular.MiKuZiel.showPop=true'
												@blur ='formular.MiKuZiel.showPop=false'
												:disabled='formular.MiKuZiel.isDisabled==true || formular.MiKuZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.MiKuZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': formular.MiKuZiel.size + 'px', 
														'text-align': formular.MiKuZiel.schriftausrichtung, 
														'letter-spacing': formular.MiKuZiel.letterSpacing + 'px',
														'font-weight': formular.MiKuZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeKaeIst"
												:show="formular.LegeKaeIst.showPop==true"
												
												ref='LegeKaeIst' 
												target='LegeKaeIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeKaeIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeKaeIst.size}}</span>
												<b-button @click='plus("LegeKaeIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeKaeIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeKaeIst" 
																				v-bind:precision="parseInt(formular.LegeKaeIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeKaeIst!=undefined" 
												class='textfeld' v-model='formular.LegeKaeIst.text' 
												style='width:100%' 
												@focus='formular.LegeKaeIst.showPop=true'
												@blur ='formular.LegeKaeIst.showPop=false'
												:disabled='formular.LegeKaeIst.isDisabled==true || formular.LegeKaeIst.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeKaeIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': formular.LegeKaeIst.size + 'px', 
														'text-align': formular.LegeKaeIst.schriftausrichtung, 
														'letter-spacing': formular.LegeKaeIst.letterSpacing + 'px',
														'font-weight': formular.LegeKaeIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeKaeZiel"
												:show="formular.LegeKaeZiel.showPop==true"
												
												ref='LegeKaeZiel' 
												target='LegeKaeZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeKaeZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeKaeZiel.size}}</span>
												<b-button @click='plus("LegeKaeZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeKaeZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeKaeZiel" 
																				v-bind:precision="parseInt(formular.LegeKaeZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeKaeZiel!=undefined" 
												class='textfeld' v-model='formular.LegeKaeZiel.text' 
												style='width:100%' 
												@focus='formular.LegeKaeZiel.showPop=true'
												@blur ='formular.LegeKaeZiel.showPop=false'
												:disabled='formular.LegeKaeZiel.isDisabled==true || formular.LegeKaeZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeKaeZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': formular.LegeKaeZiel.size + 'px', 
														'text-align': formular.LegeKaeZiel.schriftausrichtung, 
														'letter-spacing': formular.LegeKaeZiel.letterSpacing + 'px',
														'font-weight': formular.LegeKaeZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.MastIst"
												:show="formular.MastIst.showPop==true"
												
												ref='MastIst' 
												target='MastIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MastIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MastIst.size}}</span>
												<b-button @click='plus("MastIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MastIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MastIst" 
																				v-bind:precision="parseInt(formular.MastIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MastIst!=undefined" 
												class='textfeld' v-model='formular.MastIst.text' 
												style='width:100%' 
												@focus='formular.MastIst.showPop=true'
												@blur ='formular.MastIst.showPop=false'
												:disabled='formular.MastIst.isDisabled==true || formular.MastIst.isDisabled==true' 
												@keypress="isNumber($event, formular.MastIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': formular.MastIst.size + 'px', 
														'text-align': formular.MastIst.schriftausrichtung, 
														'letter-spacing': formular.MastIst.letterSpacing + 'px',
														'font-weight': formular.MastIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.MastZiel"
												:show="formular.MastZiel.showPop==true"
												
												ref='MastZiel' 
												target='MastZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MastZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MastZiel.size}}</span>
												<b-button @click='plus("MastZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MastZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MastZiel" 
																				v-bind:precision="parseInt(formular.MastZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MastZiel!=undefined" 
												class='textfeld' v-model='formular.MastZiel.text' 
												style='width:100%' 
												@focus='formular.MastZiel.showPop=true'
												@blur ='formular.MastZiel.showPop=false'
												:disabled='formular.MastZiel.isDisabled==true || formular.MastZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.MastZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': formular.MastZiel.size + 'px', 
														'text-align': formular.MastZiel.schriftausrichtung, 
														'letter-spacing': formular.MastZiel.letterSpacing + 'px',
														'font-weight': formular.MastZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeBodIst"
												:show="formular.LegeBodIst.showPop==true"
												
												ref='LegeBodIst' 
												target='LegeBodIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeBodIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeBodIst.size}}</span>
												<b-button @click='plus("LegeBodIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeBodIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeBodIst" 
																				v-bind:precision="parseInt(formular.LegeBodIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeBodIst!=undefined" 
												class='textfeld' v-model='formular.LegeBodIst.text' 
												style='width:100%' 
												@focus='formular.LegeBodIst.showPop=true'
												@blur ='formular.LegeBodIst.showPop=false'
												:disabled='formular.LegeBodIst.isDisabled==true || formular.LegeBodIst.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeBodIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': formular.LegeBodIst.size + 'px', 
														'text-align': formular.LegeBodIst.schriftausrichtung, 
														'letter-spacing': formular.LegeBodIst.letterSpacing + 'px',
														'font-weight': formular.LegeBodIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeBodZiel"
												:show="formular.LegeBodZiel.showPop==true"
												
												ref='LegeBodZiel' 
												target='LegeBodZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeBodZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeBodZiel.size}}</span>
												<b-button @click='plus("LegeBodZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeBodZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeBodZiel" 
																				v-bind:precision="parseInt(formular.LegeBodZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeBodZiel!=undefined" 
												class='textfeld' v-model='formular.LegeBodZiel.text' 
												style='width:100%' 
												@focus='formular.LegeBodZiel.showPop=true'
												@blur ='formular.LegeBodZiel.showPop=false'
												:disabled='formular.LegeBodZiel.isDisabled==true || formular.LegeBodZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeBodZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': formular.LegeBodZiel.size + 'px', 
														'text-align': formular.LegeBodZiel.schriftausrichtung, 
														'letter-spacing': formular.LegeBodZiel.letterSpacing + 'px',
														'font-weight': formular.LegeBodZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZiSIst"
												:show="formular.ZiSIst.showPop==true"
												
												ref='ZiSIst' 
												target='ZiSIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZiSIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZiSIst.size}}</span>
												<b-button @click='plus("ZiSIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZiSIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZiSIst" 
																				v-bind:precision="parseInt(formular.ZiSIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZiSIst!=undefined" 
												class='textfeld' v-model='formular.ZiSIst.text' 
												style='width:100%' 
												@focus='formular.ZiSIst.showPop=true'
												@blur ='formular.ZiSIst.showPop=false'
												:disabled='formular.ZiSIst.isDisabled==true || formular.ZiSIst.isDisabled==true' 
												@keypress="isNumber($event, formular.ZiSIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': formular.ZiSIst.size + 'px', 
														'text-align': formular.ZiSIst.schriftausrichtung, 
														'letter-spacing': formular.ZiSIst.letterSpacing + 'px',
														'font-weight': formular.ZiSIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZiSZiel"
												:show="formular.ZiSZiel.showPop==true"
												
												ref='ZiSZiel' 
												target='ZiSZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZiSZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZiSZiel.size}}</span>
												<b-button @click='plus("ZiSZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZiSZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZiSZiel" 
																				v-bind:precision="parseInt(formular.ZiSZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZiSZiel!=undefined" 
												class='textfeld' v-model='formular.ZiSZiel.text' 
												style='width:100%' 
												@focus='formular.ZiSZiel.showPop=true'
												@blur ='formular.ZiSZiel.showPop=false'
												:disabled='formular.ZiSZiel.isDisabled==true || formular.ZiSZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.ZiSZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': formular.ZiSZiel.size + 'px', 
														'text-align': formular.ZiSZiel.schriftausrichtung, 
														'letter-spacing': formular.ZiSZiel.letterSpacing + 'px',
														'font-weight': formular.ZiSZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeFreiIst"
												:show="formular.LegeFreiIst.showPop==true"
												
												ref='LegeFreiIst' 
												target='LegeFreiIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeFreiIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeFreiIst.size}}</span>
												<b-button @click='plus("LegeFreiIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeFreiIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeFreiIst" 
																				v-bind:precision="parseInt(formular.LegeFreiIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeFreiIst!=undefined" 
												class='textfeld' v-model='formular.LegeFreiIst.text' 
												style='width:100%' 
												@focus='formular.LegeFreiIst.showPop=true'
												@blur ='formular.LegeFreiIst.showPop=false'
												:disabled='formular.LegeFreiIst.isDisabled==true || formular.LegeFreiIst.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeFreiIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': formular.LegeFreiIst.size + 'px', 
														'text-align': formular.LegeFreiIst.schriftausrichtung, 
														'letter-spacing': formular.LegeFreiIst.letterSpacing + 'px',
														'font-weight': formular.LegeFreiIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.LegeFreiZiel"
												:show="formular.LegeFreiZiel.showPop==true"
												
												ref='LegeFreiZiel' 
												target='LegeFreiZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.LegeFreiZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.LegeFreiZiel.size}}</span>
												<b-button @click='plus("LegeFreiZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("LegeFreiZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="LegeFreiZiel" 
																				v-bind:precision="parseInt(formular.LegeFreiZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.LegeFreiZiel!=undefined" 
												class='textfeld' v-model='formular.LegeFreiZiel.text' 
												style='width:100%' 
												@focus='formular.LegeFreiZiel.showPop=true'
												@blur ='formular.LegeFreiZiel.showPop=false'
												:disabled='formular.LegeFreiZiel.isDisabled==true || formular.LegeFreiZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.LegeFreiZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': formular.LegeFreiZiel.size + 'px', 
														'text-align': formular.LegeFreiZiel.schriftausrichtung, 
														'letter-spacing': formular.LegeFreiZiel.letterSpacing + 'px',
														'font-weight': formular.LegeFreiZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.ArbeitIst"
												:show="formular.ArbeitIst.showPop==true"
												
												ref='ArbeitIst' 
												target='ArbeitIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ArbeitIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ArbeitIst.size}}</span>
												<b-button @click='plus("ArbeitIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ArbeitIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ArbeitIst" 
																				v-bind:precision="parseInt(formular.ArbeitIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ArbeitIst!=undefined" 
												class='textfeld' v-model='formular.ArbeitIst.text' 
												style='width:100%' 
												@focus='formular.ArbeitIst.showPop=true'
												@blur ='formular.ArbeitIst.showPop=false'
												:disabled='formular.ArbeitIst.isDisabled==true || formular.ArbeitIst.isDisabled==true' 
												@keypress="isNumber($event, formular.ArbeitIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': formular.ArbeitIst.size + 'px', 
														'text-align': formular.ArbeitIst.schriftausrichtung, 
														'letter-spacing': formular.ArbeitIst.letterSpacing + 'px',
														'font-weight': formular.ArbeitIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.ArbeitZiel"
												:show="formular.ArbeitZiel.showPop==true"
												
												ref='ArbeitZiel' 
												target='ArbeitZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ArbeitZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ArbeitZiel.size}}</span>
												<b-button @click='plus("ArbeitZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ArbeitZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ArbeitZiel" 
																				v-bind:precision="parseInt(formular.ArbeitZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ArbeitZiel!=undefined" 
												class='textfeld' v-model='formular.ArbeitZiel.text' 
												style='width:100%' 
												@focus='formular.ArbeitZiel.showPop=true'
												@blur ='formular.ArbeitZiel.showPop=false'
												:disabled='formular.ArbeitZiel.isDisabled==true || formular.ArbeitZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.ArbeitZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': formular.ArbeitZiel.size + 'px', 
														'text-align': formular.ArbeitZiel.schriftausrichtung, 
														'letter-spacing': formular.ArbeitZiel.letterSpacing + 'px',
														'font-weight': formular.ArbeitZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.SonstTier1Ist"
												:show="formular.SonstTier1Ist.showPop==true"
												
												ref='SonstTier1Ist' 
												target='SonstTier1Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.SonstTier1Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.SonstTier1Ist.size}}</span>
												<b-button @click='plus("SonstTier1Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("SonstTier1Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="SonstTier1Ist" 
																				v-bind:precision="parseInt(formular.SonstTier1Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.SonstTier1Ist!=undefined" 
												class='textfeld' v-model='formular.SonstTier1Ist.text' 
												style='width:100%' 
												@focus='formular.SonstTier1Ist.showPop=true'
												@blur ='formular.SonstTier1Ist.showPop=false'
												:disabled='formular.SonstTier1Ist.isDisabled==true || formular.SonstTier1Ist.isDisabled==true' 
												@keypress="isNumber($event, formular.SonstTier1Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': formular.SonstTier1Ist.size + 'px', 
														'text-align': formular.SonstTier1Ist.schriftausrichtung, 
														'letter-spacing': formular.SonstTier1Ist.letterSpacing + 'px',
														'font-weight': formular.SonstTier1Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.SonstTier1Ziel"
												:show="formular.SonstTier1Ziel.showPop==true"
												
												ref='SonstTier1Ziel' 
												target='SonstTier1Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.SonstTier1Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.SonstTier1Ziel.size}}</span>
												<b-button @click='plus("SonstTier1Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("SonstTier1Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="SonstTier1Ziel" 
																				v-bind:precision="parseInt(formular.SonstTier1Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.SonstTier1Ziel!=undefined" 
												class='textfeld' v-model='formular.SonstTier1Ziel.text' 
												style='width:100%' 
												@focus='formular.SonstTier1Ziel.showPop=true'
												@blur ='formular.SonstTier1Ziel.showPop=false'
												:disabled='formular.SonstTier1Ziel.isDisabled==true || formular.SonstTier1Ziel.isDisabled==true' 
												@keypress="isNumber($event, formular.SonstTier1Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': formular.SonstTier1Ziel.size + 'px', 
														'text-align': formular.SonstTier1Ziel.schriftausrichtung, 
														'letter-spacing': formular.SonstTier1Ziel.letterSpacing + 'px',
														'font-weight': formular.SonstTier1Ziel.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZuchtIst"
												:show="formular.ZuchtIst.showPop==true"
												
												ref='ZuchtIst' 
												target='ZuchtIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZuchtIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZuchtIst.size}}</span>
												<b-button @click='plus("ZuchtIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZuchtIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZuchtIst" 
																				v-bind:precision="parseInt(formular.ZuchtIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZuchtIst!=undefined" 
												class='textfeld' v-model='formular.ZuchtIst.text' 
												style='width:100%' 
												@focus='formular.ZuchtIst.showPop=true'
												@blur ='formular.ZuchtIst.showPop=false'
												:disabled='formular.ZuchtIst.isDisabled==true || formular.ZuchtIst.isDisabled==true' 
												@keypress="isNumber($event, formular.ZuchtIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': formular.ZuchtIst.size + 'px', 
														'text-align': formular.ZuchtIst.schriftausrichtung, 
														'letter-spacing': formular.ZuchtIst.letterSpacing + 'px',
														'font-weight': formular.ZuchtIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZuchtZiel"
												:show="formular.ZuchtZiel.showPop==true"
												
												ref='ZuchtZiel' 
												target='ZuchtZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZuchtZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZuchtZiel.size}}</span>
												<b-button @click='plus("ZuchtZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZuchtZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZuchtZiel" 
																				v-bind:precision="parseInt(formular.ZuchtZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZuchtZiel!=undefined" 
												class='textfeld' v-model='formular.ZuchtZiel.text' 
												style='width:100%' 
												@focus='formular.ZuchtZiel.showPop=true'
												@blur ='formular.ZuchtZiel.showPop=false'
												:disabled='formular.ZuchtZiel.isDisabled==true || formular.ZuchtZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.ZuchtZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': formular.ZuchtZiel.size + 'px', 
														'text-align': formular.ZuchtZiel.schriftausrichtung, 
														'letter-spacing': formular.ZuchtZiel.letterSpacing + 'px',
														'font-weight': formular.ZuchtZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.SonstTier2Ist"
												:show="formular.SonstTier2Ist.showPop==true"
												
												ref='SonstTier2Ist' 
												target='SonstTier2Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.SonstTier2Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.SonstTier2Ist.size}}</span>
												<b-button @click='plus("SonstTier2Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("SonstTier2Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="SonstTier2Ist" 
																				v-bind:precision="parseInt(formular.SonstTier2Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.SonstTier2Ist!=undefined" 
												class='textfeld' v-model='formular.SonstTier2Ist.text' 
												style='width:100%' 
												@focus='formular.SonstTier2Ist.showPop=true'
												@blur ='formular.SonstTier2Ist.showPop=false'
												:disabled='formular.SonstTier2Ist.isDisabled==true || formular.SonstTier2Ist.isDisabled==true' 
												@keypress="isNumber($event, formular.SonstTier2Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': formular.SonstTier2Ist.size + 'px', 
														'text-align': formular.SonstTier2Ist.schriftausrichtung, 
														'letter-spacing': formular.SonstTier2Ist.letterSpacing + 'px',
														'font-weight': formular.SonstTier2Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.SonstTier2Ziel"
												:show="formular.SonstTier2Ziel.showPop==true"
												
												ref='SonstTier2Ziel' 
												target='SonstTier2Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.SonstTier2Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.SonstTier2Ziel.size}}</span>
												<b-button @click='plus("SonstTier2Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("SonstTier2Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="SonstTier2Ziel" 
																				v-bind:precision="parseInt(formular.SonstTier2Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.SonstTier2Ziel!=undefined" 
												class='textfeld' v-model='formular.SonstTier2Ziel.text' 
												style='width:100%' 
												@focus='formular.SonstTier2Ziel.showPop=true'
												@blur ='formular.SonstTier2Ziel.showPop=false'
												:disabled='formular.SonstTier2Ziel.isDisabled==true || formular.SonstTier2Ziel.isDisabled==true' 
												@keypress="isNumber($event, formular.SonstTier2Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': formular.SonstTier2Ziel.size + 'px', 
														'text-align': formular.SonstTier2Ziel.schriftausrichtung, 
														'letter-spacing': formular.SonstTier2Ziel.letterSpacing + 'px',
														'font-weight': formular.SonstTier2Ziel.schriftart }" 
												/>

<b-popover 
												v-if="formular.ReitIst"
												:show="formular.ReitIst.showPop==true"
												
												ref='ReitIst' 
												target='ReitIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ReitIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ReitIst.size}}</span>
												<b-button @click='plus("ReitIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ReitIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ReitIst" 
																				v-bind:precision="parseInt(formular.ReitIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ReitIst!=undefined" 
												class='textfeld' v-model='formular.ReitIst.text' 
												style='width:100%' 
												@focus='formular.ReitIst.showPop=true'
												@blur ='formular.ReitIst.showPop=false'
												:disabled='formular.ReitIst.isDisabled==true || formular.ReitIst.isDisabled==true' 
												@keypress="isNumber($event, formular.ReitIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': formular.ReitIst.size + 'px', 
														'text-align': formular.ReitIst.schriftausrichtung, 
														'letter-spacing': formular.ReitIst.letterSpacing + 'px',
														'font-weight': formular.ReitIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.ReitZiel"
												:show="formular.ReitZiel.showPop==true"
												
												ref='ReitZiel' 
												target='ReitZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ReitZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ReitZiel.size}}</span>
												<b-button @click='plus("ReitZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ReitZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ReitZiel" 
																				v-bind:precision="parseInt(formular.ReitZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ReitZiel!=undefined" 
												class='textfeld' v-model='formular.ReitZiel.text' 
												style='width:100%' 
												@focus='formular.ReitZiel.showPop=true'
												@blur ='formular.ReitZiel.showPop=false'
												:disabled='formular.ReitZiel.isDisabled==true || formular.ReitZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.ReitZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': formular.ReitZiel.size + 'px', 
														'text-align': formular.ReitZiel.schriftausrichtung, 
														'letter-spacing': formular.ReitZiel.letterSpacing + 'px',
														'font-weight': formular.ReitZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.HauptfutterIst"
												:show="formular.HauptfutterIst.showPop==true"
												
												ref='HauptfutterIst' 
												target='HauptfutterIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.HauptfutterIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.HauptfutterIst.size}}</span>
												<b-button @click='plus("HauptfutterIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("HauptfutterIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="HauptfutterIst" 
																				v-bind:precision="parseInt(formular.HauptfutterIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.HauptfutterIst!=undefined" 
												class='textfeld' v-model='formular.HauptfutterIst.text' 
												style='width:100%' 
												@focus='formular.HauptfutterIst.showPop=true'
												@blur ='formular.HauptfutterIst.showPop=false'
												:disabled='formular.HauptfutterIst.isDisabled==true || formular.HauptfutterIst.isDisabled==true' 
												@keypress="isNumber($event, formular.HauptfutterIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': formular.HauptfutterIst.size + 'px', 
														'text-align': formular.HauptfutterIst.schriftausrichtung, 
														'letter-spacing': formular.HauptfutterIst.letterSpacing + 'px',
														'font-weight': formular.HauptfutterIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.HauptfutterZiel"
												:show="formular.HauptfutterZiel.showPop==true"
												
												ref='HauptfutterZiel' 
												target='HauptfutterZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.HauptfutterZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.HauptfutterZiel.size}}</span>
												<b-button @click='plus("HauptfutterZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("HauptfutterZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="HauptfutterZiel" 
																				v-bind:precision="parseInt(formular.HauptfutterZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.HauptfutterZiel!=undefined" 
												class='textfeld' v-model='formular.HauptfutterZiel.text' 
												style='width:100%' 
												@focus='formular.HauptfutterZiel.showPop=true'
												@blur ='formular.HauptfutterZiel.showPop=false'
												:disabled='formular.HauptfutterZiel.isDisabled==true || formular.HauptfutterZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.HauptfutterZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': formular.HauptfutterZiel.size + 'px', 
														'text-align': formular.HauptfutterZiel.schriftausrichtung, 
														'letter-spacing': formular.HauptfutterZiel.letterSpacing + 'px',
														'font-weight': formular.HauptfutterZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.PenIst"
												:show="formular.PenIst.showPop==true"
												
												ref='PenIst' 
												target='PenIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.PenIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.PenIst.size}}</span>
												<b-button @click='plus("PenIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("PenIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="PenIst" 
																				v-bind:precision="parseInt(formular.PenIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.PenIst!=undefined" 
												class='textfeld' v-model='formular.PenIst.text' 
												style='width:100%' 
												@focus='formular.PenIst.showPop=true'
												@blur ='formular.PenIst.showPop=false'
												:disabled='formular.PenIst.isDisabled==true || formular.PenIst.isDisabled==true' 
												@keypress="isNumber($event, formular.PenIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': formular.PenIst.size + 'px', 
														'text-align': formular.PenIst.schriftausrichtung, 
														'letter-spacing': formular.PenIst.letterSpacing + 'px',
														'font-weight': formular.PenIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.PenZiel"
												:show="formular.PenZiel.showPop==true"
												
												ref='PenZiel' 
												target='PenZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.PenZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.PenZiel.size}}</span>
												<b-button @click='plus("PenZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("PenZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="PenZiel" 
																				v-bind:precision="parseInt(formular.PenZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.PenZiel!=undefined" 
												class='textfeld' v-model='formular.PenZiel.text' 
												style='width:100%' 
												@focus='formular.PenZiel.showPop=true'
												@blur ='formular.PenZiel.showPop=false'
												:disabled='formular.PenZiel.isDisabled==true || formular.PenZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.PenZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': formular.PenZiel.size + 'px', 
														'text-align': formular.PenZiel.schriftausrichtung, 
														'letter-spacing': formular.PenZiel.letterSpacing + 'px',
														'font-weight': formular.PenZiel.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZusatzfutterIst"
												:show="formular.ZusatzfutterIst.showPop==true"
												
												ref='ZusatzfutterIst' 
												target='ZusatzfutterIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZusatzfutterIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZusatzfutterIst.size}}</span>
												<b-button @click='plus("ZusatzfutterIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZusatzfutterIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZusatzfutterIst" 
																				v-bind:precision="parseInt(formular.ZusatzfutterIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZusatzfutterIst!=undefined" 
												class='textfeld' v-model='formular.ZusatzfutterIst.text' 
												style='width:100%' 
												@focus='formular.ZusatzfutterIst.showPop=true'
												@blur ='formular.ZusatzfutterIst.showPop=false'
												:disabled='formular.ZusatzfutterIst.isDisabled==true || formular.ZusatzfutterIst.isDisabled==true' 
												@keypress="isNumber($event, formular.ZusatzfutterIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': formular.ZusatzfutterIst.size + 'px', 
														'text-align': formular.ZusatzfutterIst.schriftausrichtung, 
														'letter-spacing': formular.ZusatzfutterIst.letterSpacing + 'px',
														'font-weight': formular.ZusatzfutterIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.ZusatzfutterZiel"
												:show="formular.ZusatzfutterZiel.showPop==true"
												
												ref='ZusatzfutterZiel' 
												target='ZusatzfutterZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ZusatzfutterZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ZusatzfutterZiel.size}}</span>
												<b-button @click='plus("ZusatzfutterZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ZusatzfutterZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ZusatzfutterZiel" 
																				v-bind:precision="parseInt(formular.ZusatzfutterZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ZusatzfutterZiel!=undefined" 
												class='textfeld' v-model='formular.ZusatzfutterZiel.text' 
												style='width:100%' 
												@focus='formular.ZusatzfutterZiel.showPop=true'
												@blur ='formular.ZusatzfutterZiel.showPop=false'
												:disabled='formular.ZusatzfutterZiel.isDisabled==true || formular.ZusatzfutterZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.ZusatzfutterZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': formular.ZusatzfutterZiel.size + 'px', 
														'text-align': formular.ZusatzfutterZiel.schriftausrichtung, 
														'letter-spacing': formular.ZusatzfutterZiel.letterSpacing + 'px',
														'font-weight': formular.ZusatzfutterZiel.schriftart }" 
												/>

<div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.7159311) + 'px', 
														top: gib_hoehe_in_mm(165.7722454) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(86.0954808) + 'px', 
														top: gib_hoehe_in_mm(165.7722454) + 'px'}">
													Ziel
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(165.8099843) + 'px'}">
													Fischzucht
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(137.5688715) + 'px', 
														top: gib_hoehe_in_mm(165.7722454) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(159.1646925) + 'px', 
														top: gib_hoehe_in_mm(165.7722454) + 'px'}">
													Ziel
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(16.4161974) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(60.8597958) + 'px',
										
										 top: gib_hoehe_in_mm(173.7962468) + 'px'}"><b-popover 
												v-if="formular.fisch1ArtIst"
												:show="formular.fisch1ArtIst.showPop==true"
												
												ref='fisch1ArtIst' 
												target='fisch1ArtIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fisch1ArtIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fisch1ArtIst.size}}</span>
												<b-button @click='plus("fisch1ArtIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fisch1ArtIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.fisch1ArtIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.fisch1ArtIst.text"
												
												@focus='formular.fisch1ArtIst.showPop=true'
												@blur ='formular.fisch1ArtIst.showPop=false'
												
												:disabled="formular.fisch1ArtIst.isDisabled==true || formular.fisch1ArtIst.isDisabled=='true'"
												:style="{'font-size': formular.fisch1ArtIst.size + 'px',
														'height': formular.fisch1ArtIst.height + 'px',
														'text-align': formular.fisch1ArtIst.schriftausrichtung + 'px',
														'letter-spacing': formular.fisch1ArtIst.letterSpacing + 'px',
														'font-weight': formular.fisch1ArtIst.schriftart + 'px', }"

												id='fisch1ArtIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(16.4165501) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(79.9158241) + 'px',
										
										 top: gib_hoehe_in_mm(173.7962468) + 'px'}"><b-popover 
												v-if="formular.fisch1ArtZiel"
												:show="formular.fisch1ArtZiel.showPop==true"
												
												ref='fisch1ArtZiel' 
												target='fisch1ArtZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fisch1ArtZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fisch1ArtZiel.size}}</span>
												<b-button @click='plus("fisch1ArtZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fisch1ArtZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.fisch1ArtZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.fisch1ArtZiel.text"
												
												@focus='formular.fisch1ArtZiel.showPop=true'
												@blur ='formular.fisch1ArtZiel.showPop=false'
												
												:disabled="formular.fisch1ArtZiel.isDisabled==true || formular.fisch1ArtZiel.isDisabled=='true'"
												:style="{'font-size': formular.fisch1ArtZiel.size + 'px',
														'height': formular.fisch1ArtZiel.height + 'px',
														'text-align': formular.fisch1ArtZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.fisch1ArtZiel.letterSpacing + 'px',
														'font-weight': formular.fisch1ArtZiel.schriftart + 'px', }"

												id='fisch1ArtZiel'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(16.4161974) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(130.7123835) + 'px',
										
										 top: gib_hoehe_in_mm(173.7962468) + 'px'}"><b-popover 
												v-if="formular.fisch2ArtIst"
												:show="formular.fisch2ArtIst.showPop==true"
												
												ref='fisch2ArtIst' 
												target='fisch2ArtIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fisch2ArtIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fisch2ArtIst.size}}</span>
												<b-button @click='plus("fisch2ArtIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fisch2ArtIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.fisch2ArtIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.fisch2ArtIst.text"
												
												@focus='formular.fisch2ArtIst.showPop=true'
												@blur ='formular.fisch2ArtIst.showPop=false'
												
												:disabled="formular.fisch2ArtIst.isDisabled==true || formular.fisch2ArtIst.isDisabled=='true'"
												:style="{'font-size': formular.fisch2ArtIst.size + 'px',
														'height': formular.fisch2ArtIst.height + 'px',
														'text-align': formular.fisch2ArtIst.schriftausrichtung + 'px',
														'letter-spacing': formular.fisch2ArtIst.letterSpacing + 'px',
														'font-weight': formular.fisch2ArtIst.schriftart + 'px', }"

												id='fisch2ArtIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(22.8790722) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(149.7680591) + 'px',
										
										 top: gib_hoehe_in_mm(173.7962468) + 'px'}"><b-popover 
												v-if="formular.fisch2ArtZiel"
												:show="formular.fisch2ArtZiel.showPop==true"
												
												ref='fisch2ArtZiel' 
												target='fisch2ArtZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fisch2ArtZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fisch2ArtZiel.size}}</span>
												<b-button @click='plus("fisch2ArtZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fisch2ArtZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.fisch2ArtZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.fisch2ArtZiel.text"
												
												@focus='formular.fisch2ArtZiel.showPop=true'
												@blur ='formular.fisch2ArtZiel.showPop=false'
												
												:disabled="formular.fisch2ArtZiel.isDisabled==true || formular.fisch2ArtZiel.isDisabled=='true'"
												:style="{'font-size': formular.fisch2ArtZiel.size + 'px',
														'height': formular.fisch2ArtZiel.height + 'px',
														'text-align': formular.fisch2ArtZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.fisch2ArtZiel.letterSpacing + 'px',
														'font-weight': formular.fisch2ArtZiel.schriftart + 'px', }"

												id='fisch2ArtZiel'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(173.132389) + 'px'}">
													Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8454371) + 'px', 
														top: gib_hoehe_in_mm(87.6491954) + 'px'}">
													3 Tierhaltung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(91.2890594) + 'px'}">
													(Anzahl)
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(96.6455143) + 'px'}">
													Milchkühe
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(103.9675663) + 'px'}">
													Mastrinder, bullen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(35.9289147) + 'px'}">
													Getreide
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(43.2509667) + 'px'}">
													Ölfrüchte
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(50.5730187) + 'px'}">
													Kartoffeln
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(57.894718) + 'px'}">
													Zuckerrüben
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(65.4710667) + 'px'}">
													Ackerfutter
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(74.697346) + 'px'}">
													Weide
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(111.2472943) + 'px'}">
													Zuchtsauen
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(119.4158263) + 'px'}">
													Mastschweine
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(127.5840056) + 'px'}">
													Ziegen, Schafe
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(134.9060576) + 'px'}">
													Arbeitspferde
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(142.2284623) + 'px'}">
													Zuchtpferde
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(149.8041056) + 'px'}">
													Reitpferde
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(157.3381303) + 'px'}">
													davon
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(160.5970783) + 'px'}">
													Pensionstiere
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(165.8099843) + 'px'}">
													Fischzucht
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(173.132389) + 'px'}">
													Art
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8454371) + 'px', 
														top: gib_hoehe_in_mm(196.5781215) + 'px'}">
													4 Tierische Abgänge
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(205.3406003) + 'px'}">
													Festmist, Jahresmenge
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(213.5514563) + 'px'}">
													Lagerart
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(221.7199883) + 'px'}">
													Lagerkapazität
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(229.8991013) + 'px'}">
													Art der Verbringung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(246.278842) + 'px'}">
													Lagerart
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(254.4470213) + 'px'}">
													Lagerkapazität
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(262.6564665) + 'px'}">
													Art der Verbringung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.0227866) + 'px', 
														top: gib_hoehe_in_mm(184.67626) + 'px'}">
													Jahresproduktion
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(72.8480688) + 'px', 
														top: gib_hoehe_in_mm(185.0719894) + 'px'}">
													kg
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(92.0635175) + 'px', 
														top: gib_hoehe_in_mm(185.0719894) + 'px'}">
													kg
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(98.9623295) + 'px', 
														top: gib_hoehe_in_mm(184.67626) + 'px'}">
													Jahresproduktion
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(142.5207795) + 'px', 
														top: gib_hoehe_in_mm(185.0719894) + 'px'}">
													kg
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.6449158) + 'px', 
														top: gib_hoehe_in_mm(185.0719894) + 'px'}">
													kg
													</div>
<b-popover 
												v-if="formular.Fisch1Ist"
												:show="formular.Fisch1Ist.showPop==true"
												
												ref='Fisch1Ist' 
												target='Fisch1Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Fisch1Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Fisch1Ist.size}}</span>
												<b-button @click='plus("Fisch1Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Fisch1Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Fisch1Ist" 
																				v-bind:precision="parseInt(formular.Fisch1Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Fisch1Ist!=undefined" 
												class='textfeld' v-model='formular.Fisch1Ist.text' 
												style='width:100%' 
												@focus='formular.Fisch1Ist.showPop=true'
												@blur ='formular.Fisch1Ist.showPop=false'
												:disabled='formular.Fisch1Ist.isDisabled==true || formular.Fisch1Ist.isDisabled==true' 
												@keypress="isNumber($event, formular.Fisch1Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.6939926) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': formular.Fisch1Ist.size + 'px', 
														'text-align': formular.Fisch1Ist.schriftausrichtung, 
														'letter-spacing': formular.Fisch1Ist.letterSpacing + 'px',
														'font-weight': formular.Fisch1Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.Fisch1Ziel"
												:show="formular.Fisch1Ziel.showPop==true"
												
												ref='Fisch1Ziel' 
												target='Fisch1Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Fisch1Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Fisch1Ziel.size}}</span>
												<b-button @click='plus("Fisch1Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Fisch1Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Fisch1Ziel" 
																				v-bind:precision="parseInt(formular.Fisch1Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Fisch1Ziel!=undefined" 
												class='textfeld' v-model='formular.Fisch1Ziel.text' 
												style='width:100%' 
												@focus='formular.Fisch1Ziel.showPop=true'
												@blur ='formular.Fisch1Ziel.showPop=false'
												:disabled='formular.Fisch1Ziel.isDisabled==true || formular.Fisch1Ziel.isDisabled==true' 
												@keypress="isNumber($event, formular.Fisch1Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.6939926) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.0822342) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': formular.Fisch1Ziel.size + 'px', 
														'text-align': formular.Fisch1Ziel.schriftausrichtung, 
														'letter-spacing': formular.Fisch1Ziel.letterSpacing + 'px',
														'font-weight': formular.Fisch1Ziel.schriftart }" 
												/>

<b-popover 
												v-if="formular.Fisch2Ist"
												:show="formular.Fisch2Ist.showPop==true"
												
												ref='Fisch2Ist' 
												target='Fisch2Ist'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Fisch2Ist.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Fisch2Ist.size}}</span>
												<b-button @click='plus("Fisch2Ist", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Fisch2Ist", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Fisch2Ist" 
																				v-bind:precision="parseInt(formular.Fisch2Ist.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Fisch2Ist!=undefined" 
												class='textfeld' v-model='formular.Fisch2Ist.text' 
												style='width:100%' 
												@focus='formular.Fisch2Ist.showPop=true'
												@blur ='formular.Fisch2Ist.showPop=false'
												:disabled='formular.Fisch2Ist.isDisabled==true || formular.Fisch2Ist.isDisabled==true' 
												@keypress="isNumber($event, formular.Fisch2Ist.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.6936399) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': formular.Fisch2Ist.size + 'px', 
														'text-align': formular.Fisch2Ist.schriftausrichtung, 
														'letter-spacing': formular.Fisch2Ist.letterSpacing + 'px',
														'font-weight': formular.Fisch2Ist.schriftart }" 
												/>

<b-popover 
												v-if="formular.Fisch2Ziel"
												:show="formular.Fisch2Ziel.showPop==true"
												
												ref='Fisch2Ziel' 
												target='Fisch2Ziel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Fisch2Ziel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Fisch2Ziel.size}}</span>
												<b-button @click='plus("Fisch2Ziel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Fisch2Ziel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="Fisch2Ziel" 
																				v-bind:precision="parseInt(formular.Fisch2Ziel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.Fisch2Ziel!=undefined" 
												class='textfeld' v-model='formular.Fisch2Ziel.text' 
												style='width:100%' 
												@focus='formular.Fisch2Ziel.showPop=true'
												@blur ='formular.Fisch2Ziel.showPop=false'
												:disabled='formular.Fisch2Ziel.isDisabled==true || formular.Fisch2Ziel.isDisabled==true' 
												@keypress="isNumber($event, formular.Fisch2Ziel.onylNumber) " 
												:style="{'width': spalte_breit_mm(17.3420349) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.9341165) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': formular.Fisch2Ziel.size + 'px', 
														'text-align': formular.Fisch2Ziel.schriftausrichtung, 
														'letter-spacing': formular.Fisch2Ziel.letterSpacing + 'px',
														'font-weight': formular.Fisch2Ziel.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.1182066) + 'px', 
														top: gib_hoehe_in_mm(238.1099573) + 'px'}">
													Flüssigmist, Jahresmenge
													</div>
<b-popover 
												v-if="formular.FlMistIst"
												:show="formular.FlMistIst.showPop==true"
												
												ref='FlMistIst' 
												target='FlMistIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistIst.size}}</span>
												<b-button @click='plus("FlMistIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="FlMistIst" 
																				v-bind:precision="parseInt(formular.FlMistIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.FlMistIst!=undefined" 
												class='textfeld' v-model='formular.FlMistIst.text' 
												style='width:100%' 
												@focus='formular.FlMistIst.showPop=true'
												@blur ='formular.FlMistIst.showPop=false'
												:disabled='formular.FlMistIst.isDisabled==true || formular.FlMistIst.isDisabled==true' 
												@keypress="isNumber($event, formular.FlMistIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(238.8933161) + 'px', 
		
														'font-size': formular.FlMistIst.size + 'px', 
														'text-align': formular.FlMistIst.schriftausrichtung, 
														'letter-spacing': formular.FlMistIst.letterSpacing + 'px',
														'font-weight': formular.FlMistIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.FlMistZiel"
												:show="formular.FlMistZiel.showPop==true"
												
												ref='FlMistZiel' 
												target='FlMistZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistZiel.size}}</span>
												<b-button @click='plus("FlMistZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="FlMistZiel" 
																				v-bind:precision="parseInt(formular.FlMistZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.FlMistZiel!=undefined" 
												class='textfeld' v-model='formular.FlMistZiel.text' 
												style='width:100%' 
												@focus='formular.FlMistZiel.showPop=true'
												@blur ='formular.FlMistZiel.showPop=false'
												:disabled='formular.FlMistZiel.isDisabled==true || formular.FlMistZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.FlMistZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(238.8933161) + 'px', 
		
														'font-size': formular.FlMistZiel.size + 'px', 
														'text-align': formular.FlMistZiel.schriftausrichtung, 
														'letter-spacing': formular.FlMistZiel.letterSpacing + 'px',
														'font-weight': formular.FlMistZiel.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.9251788) + 'px', 
														top: gib_hoehe_in_mm(196.9893697) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(152.2126228) + 'px', 
														top: gib_hoehe_in_mm(196.9893697) + 'px'}">
													Ziel
													</div>
<b-popover 
												v-if="formular.MistIst"
												:show="formular.MistIst.showPop==true"
												
												ref='MistIst' 
												target='MistIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistIst.size}}</span>
												<b-button @click='plus("MistIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MistIst" 
																				v-bind:precision="parseInt(formular.MistIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MistIst!=undefined" 
												class='textfeld' v-model='formular.MistIst.text' 
												style='width:100%' 
												@focus='formular.MistIst.showPop=true'
												@blur ='formular.MistIst.showPop=false'
												:disabled='formular.MistIst.isDisabled==true || formular.MistIst.isDisabled==true' 
												@keypress="isNumber($event, formular.MistIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(206.1624034) + 'px', 
		
														'font-size': formular.MistIst.size + 'px', 
														'text-align': formular.MistIst.schriftausrichtung, 
														'letter-spacing': formular.MistIst.letterSpacing + 'px',
														'font-weight': formular.MistIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.MistZiel"
												:show="formular.MistZiel.showPop==true"
												
												ref='MistZiel' 
												target='MistZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistZiel.size}}</span>
												<b-button @click='plus("MistZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MistZiel" 
																				v-bind:precision="parseInt(formular.MistZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MistZiel!=undefined" 
												class='textfeld' v-model='formular.MistZiel.text' 
												style='width:100%' 
												@focus='formular.MistZiel.showPop=true'
												@blur ='formular.MistZiel.showPop=false'
												:disabled='formular.MistZiel.isDisabled==true || formular.MistZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.MistZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(206.1624034) + 'px', 
		
														'font-size': formular.MistZiel.size + 'px', 
														'text-align': formular.MistZiel.schriftausrichtung, 
														'letter-spacing': formular.MistZiel.letterSpacing + 'px',
														'font-weight': formular.MistZiel.schriftart }" 
												/>


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(98.6639453) + 'px',
										
										 top: gib_hoehe_in_mm(212.0278687) + 'px'}"><b-popover 
												v-if="formular.MistLagerArtIst"
												:show="formular.MistLagerArtIst.showPop==true"
												
												ref='MistLagerArtIst' 
												target='MistLagerArtIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistLagerArtIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistLagerArtIst.size}}</span>
												<b-button @click='plus("MistLagerArtIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistLagerArtIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.MistLagerArtIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.MistLagerArtIst.text"
												
												@focus='formular.MistLagerArtIst.showPop=true'
												@blur ='formular.MistLagerArtIst.showPop=false'
												
												:disabled="formular.MistLagerArtIst.isDisabled==true || formular.MistLagerArtIst.isDisabled=='true'"
												:style="{'font-size': formular.MistLagerArtIst.size + 'px',
														'height': formular.MistLagerArtIst.height + 'px',
														'text-align': formular.MistLagerArtIst.schriftausrichtung + 'px',
														'letter-spacing': formular.MistLagerArtIst.letterSpacing + 'px',
														'font-weight': formular.MistLagerArtIst.schriftart + 'px', }"

												id='MistLagerArtIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(136.9280156) + 'px',
										
										 top: gib_hoehe_in_mm(212.0278687) + 'px'}"><b-popover 
												v-if="formular.MistLagerArtZiel"
												:show="formular.MistLagerArtZiel.showPop==true"
												
												ref='MistLagerArtZiel' 
												target='MistLagerArtZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistLagerArtZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistLagerArtZiel.size}}</span>
												<b-button @click='plus("MistLagerArtZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistLagerArtZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.MistLagerArtZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.MistLagerArtZiel.text"
												
												@focus='formular.MistLagerArtZiel.showPop=true'
												@blur ='formular.MistLagerArtZiel.showPop=false'
												
												:disabled="formular.MistLagerArtZiel.isDisabled==true || formular.MistLagerArtZiel.isDisabled=='true'"
												:style="{'font-size': formular.MistLagerArtZiel.size + 'px',
														'height': formular.MistLagerArtZiel.height + 'px',
														'text-align': formular.MistLagerArtZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.MistLagerArtZiel.letterSpacing + 'px',
														'font-weight': formular.MistLagerArtZiel.schriftart + 'px', }"

												id='MistLagerArtZiel'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.MistLagerKapIst"
												:show="formular.MistLagerKapIst.showPop==true"
												
												ref='MistLagerKapIst' 
												target='MistLagerKapIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistLagerKapIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistLagerKapIst.size}}</span>
												<b-button @click='plus("MistLagerKapIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistLagerKapIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MistLagerKapIst" 
																				v-bind:precision="parseInt(formular.MistLagerKapIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MistLagerKapIst!=undefined" 
												class='textfeld' v-model='formular.MistLagerKapIst.text' 
												style='width:100%' 
												@focus='formular.MistLagerKapIst.showPop=true'
												@blur ='formular.MistLagerKapIst.showPop=false'
												:disabled='formular.MistLagerKapIst.isDisabled==true || formular.MistLagerKapIst.isDisabled==true' 
												@keypress="isNumber($event, formular.MistLagerKapIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(222.5417914) + 'px', 
		
														'font-size': formular.MistLagerKapIst.size + 'px', 
														'text-align': formular.MistLagerKapIst.schriftausrichtung, 
														'letter-spacing': formular.MistLagerKapIst.letterSpacing + 'px',
														'font-weight': formular.MistLagerKapIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.MistLagerKapZiel"
												:show="formular.MistLagerKapZiel.showPop==true"
												
												ref='MistLagerKapZiel' 
												target='MistLagerKapZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistLagerKapZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistLagerKapZiel.size}}</span>
												<b-button @click='plus("MistLagerKapZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistLagerKapZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="MistLagerKapZiel" 
																				v-bind:precision="parseInt(formular.MistLagerKapZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.MistLagerKapZiel!=undefined" 
												class='textfeld' v-model='formular.MistLagerKapZiel.text' 
												style='width:100%' 
												@focus='formular.MistLagerKapZiel.showPop=true'
												@blur ='formular.MistLagerKapZiel.showPop=false'
												:disabled='formular.MistLagerKapZiel.isDisabled==true || formular.MistLagerKapZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.MistLagerKapZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(222.5417914) + 'px', 
		
														'font-size': formular.MistLagerKapZiel.size + 'px', 
														'text-align': formular.MistLagerKapZiel.schriftausrichtung, 
														'letter-spacing': formular.MistLagerKapZiel.letterSpacing + 'px',
														'font-weight': formular.MistLagerKapZiel.schriftart }" 
												/>


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(98.6639453) + 'px',
										
										 top: gib_hoehe_in_mm(228.3335424) + 'px'}"><b-popover 
												v-if="formular.MistVerbrinIst"
												:show="formular.MistVerbrinIst.showPop==true"
												
												ref='MistVerbrinIst' 
												target='MistVerbrinIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistVerbrinIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistVerbrinIst.size}}</span>
												<b-button @click='plus("MistVerbrinIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistVerbrinIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.MistVerbrinIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.MistVerbrinIst.text"
												
												@focus='formular.MistVerbrinIst.showPop=true'
												@blur ='formular.MistVerbrinIst.showPop=false'
												
												:disabled="formular.MistVerbrinIst.isDisabled==true || formular.MistVerbrinIst.isDisabled=='true'"
												:style="{'font-size': formular.MistVerbrinIst.size + 'px',
														'height': formular.MistVerbrinIst.height + 'px',
														'text-align': formular.MistVerbrinIst.schriftausrichtung + 'px',
														'letter-spacing': formular.MistVerbrinIst.letterSpacing + 'px',
														'font-weight': formular.MistVerbrinIst.schriftart + 'px', }"

												id='MistVerbrinIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(136.9280156) + 'px',
										
										 top: gib_hoehe_in_mm(228.3335424) + 'px'}"><b-popover 
												v-if="formular.MistVerbrinIstZiel"
												:show="formular.MistVerbrinIstZiel.showPop==true"
												
												ref='MistVerbrinIstZiel' 
												target='MistVerbrinIstZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.MistVerbrinIstZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.MistVerbrinIstZiel.size}}</span>
												<b-button @click='plus("MistVerbrinIstZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("MistVerbrinIstZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.MistVerbrinIstZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.MistVerbrinIstZiel.text"
												
												@focus='formular.MistVerbrinIstZiel.showPop=true'
												@blur ='formular.MistVerbrinIstZiel.showPop=false'
												
												:disabled="formular.MistVerbrinIstZiel.isDisabled==true || formular.MistVerbrinIstZiel.isDisabled=='true'"
												:style="{'font-size': formular.MistVerbrinIstZiel.size + 'px',
														'height': formular.MistVerbrinIstZiel.height + 'px',
														'text-align': formular.MistVerbrinIstZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.MistVerbrinIstZiel.letterSpacing + 'px',
														'font-weight': formular.MistVerbrinIstZiel.schriftart + 'px', }"

												id='MistVerbrinIstZiel'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(98.6639453) + 'px',
										
										 top: gib_hoehe_in_mm(244.8007527) + 'px'}"><b-popover 
												v-if="formular.FlMistLagerIst"
												:show="formular.FlMistLagerIst.showPop==true"
												
												ref='FlMistLagerIst' 
												target='FlMistLagerIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistLagerIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistLagerIst.size}}</span>
												<b-button @click='plus("FlMistLagerIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistLagerIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.FlMistLagerIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.FlMistLagerIst.text"
												
												@focus='formular.FlMistLagerIst.showPop=true'
												@blur ='formular.FlMistLagerIst.showPop=false'
												
												:disabled="formular.FlMistLagerIst.isDisabled==true || formular.FlMistLagerIst.isDisabled=='true'"
												:style="{'font-size': formular.FlMistLagerIst.size + 'px',
														'height': formular.FlMistLagerIst.height + 'px',
														'text-align': formular.FlMistLagerIst.schriftausrichtung + 'px',
														'letter-spacing': formular.FlMistLagerIst.letterSpacing + 'px',
														'font-weight': formular.FlMistLagerIst.schriftart + 'px', }"

												id='FlMistLagerIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(136.9280156) + 'px',
										
										 top: gib_hoehe_in_mm(244.8007527) + 'px'}"><b-popover 
												v-if="formular.FlMistLagerZiel"
												:show="formular.FlMistLagerZiel.showPop==true"
												
												ref='FlMistLagerZiel' 
												target='FlMistLagerZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistLagerZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistLagerZiel.size}}</span>
												<b-button @click='plus("FlMistLagerZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistLagerZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.FlMistLagerZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.FlMistLagerZiel.text"
												
												@focus='formular.FlMistLagerZiel.showPop=true'
												@blur ='formular.FlMistLagerZiel.showPop=false'
												
												:disabled="formular.FlMistLagerZiel.isDisabled==true || formular.FlMistLagerZiel.isDisabled=='true'"
												:style="{'font-size': formular.FlMistLagerZiel.size + 'px',
														'height': formular.FlMistLagerZiel.height + 'px',
														'text-align': formular.FlMistLagerZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.FlMistLagerZiel.letterSpacing + 'px',
														'font-weight': formular.FlMistLagerZiel.schriftart + 'px', }"

												id='FlMistLagerZiel'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.FlMistKapIst"
												:show="formular.FlMistKapIst.showPop==true"
												
												ref='FlMistKapIst' 
												target='FlMistKapIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistKapIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistKapIst.size}}</span>
												<b-button @click='plus("FlMistKapIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistKapIst", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="FlMistKapIst" 
																				v-bind:precision="parseInt(formular.FlMistKapIst.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.FlMistKapIst!=undefined" 
												class='textfeld' v-model='formular.FlMistKapIst.text' 
												style='width:100%' 
												@focus='formular.FlMistKapIst.showPop=true'
												@blur ='formular.FlMistKapIst.showPop=false'
												:disabled='formular.FlMistKapIst.isDisabled==true || formular.FlMistKapIst.isDisabled==true' 
												@keypress="isNumber($event, formular.FlMistKapIst.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(255.2684717) + 'px', 
		
														'font-size': formular.FlMistKapIst.size + 'px', 
														'text-align': formular.FlMistKapIst.schriftausrichtung, 
														'letter-spacing': formular.FlMistKapIst.letterSpacing + 'px',
														'font-weight': formular.FlMistKapIst.schriftart }" 
												/>

<b-popover 
												v-if="formular.FlMistKapZiel"
												:show="formular.FlMistKapZiel.showPop==true"
												
												ref='FlMistKapZiel' 
												target='FlMistKapZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistKapZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistKapZiel.size}}</span>
												<b-button @click='plus("FlMistKapZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistKapZiel", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="FlMistKapZiel" 
																				v-bind:precision="parseInt(formular.FlMistKapZiel.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.FlMistKapZiel!=undefined" 
												class='textfeld' v-model='formular.FlMistKapZiel.text' 
												style='width:100%' 
												@focus='formular.FlMistKapZiel.showPop=true'
												@blur ='formular.FlMistKapZiel.showPop=false'
												:disabled='formular.FlMistKapZiel.isDisabled==true || formular.FlMistKapZiel.isDisabled==true' 
												@keypress="isNumber($event, formular.FlMistKapZiel.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(255.2684717) + 'px', 
		
														'font-size': formular.FlMistKapZiel.size + 'px', 
														'text-align': formular.FlMistKapZiel.schriftausrichtung, 
														'letter-spacing': formular.FlMistKapZiel.letterSpacing + 'px',
														'font-weight': formular.FlMistKapZiel.schriftart }" 
												/>


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(98.6639453) + 'px',
										
										 top: gib_hoehe_in_mm(261.2192904) + 'px'}"><b-popover 
												v-if="formular.FlMistVerbringungIst"
												:show="formular.FlMistVerbringungIst.showPop==true"
												
												ref='FlMistVerbringungIst' 
												target='FlMistVerbringungIst'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistVerbringungIst.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistVerbringungIst.size}}</span>
												<b-button @click='plus("FlMistVerbringungIst", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistVerbringungIst", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.FlMistVerbringungIst!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.FlMistVerbringungIst.text"
												
												@focus='formular.FlMistVerbringungIst.showPop=true'
												@blur ='formular.FlMistVerbringungIst.showPop=false'
												
												:disabled="formular.FlMistVerbringungIst.isDisabled==true || formular.FlMistVerbringungIst.isDisabled=='true'"
												:style="{'font-size': formular.FlMistVerbringungIst.size + 'px',
														'height': formular.FlMistVerbringungIst.height + 'px',
														'text-align': formular.FlMistVerbringungIst.schriftausrichtung + 'px',
														'letter-spacing': formular.FlMistVerbringungIst.letterSpacing + 'px',
														'font-weight': formular.FlMistVerbringungIst.schriftart + 'px', }"

												id='FlMistVerbringungIst'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(136.9280156) + 'px',
										
										 top: gib_hoehe_in_mm(261.2192904) + 'px'}"><b-popover 
												v-if="formular.FlMistVerbringungZiel"
												:show="formular.FlMistVerbringungZiel.showPop==true"
												
												ref='FlMistVerbringungZiel' 
												target='FlMistVerbringungZiel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FlMistVerbringungZiel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FlMistVerbringungZiel.size}}</span>
												<b-button @click='plus("FlMistVerbringungZiel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FlMistVerbringungZiel", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.FlMistVerbringungZiel!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.FlMistVerbringungZiel.text"
												
												@focus='formular.FlMistVerbringungZiel.showPop=true'
												@blur ='formular.FlMistVerbringungZiel.showPop=false'
												
												:disabled="formular.FlMistVerbringungZiel.isDisabled==true || formular.FlMistVerbringungZiel.isDisabled=='true'"
												:style="{'font-size': formular.FlMistVerbringungZiel.size + 'px',
														'height': formular.FlMistVerbringungZiel.height + 'px',
														'text-align': formular.FlMistVerbringungZiel.schriftausrichtung + 'px',
														'letter-spacing': formular.FlMistVerbringungZiel.letterSpacing + 'px',
														'font-weight': formular.FlMistVerbringungZiel.schriftart + 'px', }"

												id='FlMistVerbringungZiel'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.6858355) + 'px', 
														top: gib_hoehe_in_mm(205.9377214) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.6858355) + 'px', 
														top: gib_hoehe_in_mm(222.3167567) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.6858355) + 'px', 
														top: gib_hoehe_in_mm(238.6644017) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.6858355) + 'px', 
														top: gib_hoehe_in_mm(255.0441424) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(169.9146358) + 'px', 
														top: gib_hoehe_in_mm(205.9377214) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(169.9146358) + 'px', 
														top: gib_hoehe_in_mm(222.3167567) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(169.9146358) + 'px', 
														top: gib_hoehe_in_mm(238.6644017) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(169.9146358) + 'px', 
														top: gib_hoehe_in_mm(255.0441424) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.0627405) + 'px', 
														top: gib_hoehe_in_mm(269.2459606) + 'px'}">
													Fortsetzung Blatt 3
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(183.809605) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(183.5595407) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(27.5904577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(35.5508967) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.8729487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(50.2020547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(57.3054327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.7121327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.9846157) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(81.4759637) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(96.1623917) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.5768511) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(110.8170767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(119.0702567) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(127.1506137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.5580191) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(141.7982447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(149.204592) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(156.69594) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(165.2072964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(172.701466) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.7743827) + 'px', 
													width: spalte_breit_mm(38.8968141) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(184.0820369) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8619055) + 'px', 
													width: spalte_breit_mm(31.661879) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(184.2421627) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(189.5439491) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(195.2576891) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(203.3867187) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(211.5520764) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(219.7202557) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(227.9314644) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(236.0255767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(244.2829891) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(252.4931397) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.7493367) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(260.6584974) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.5990908) + 'px', 
													width: spalte_breit_mm(183.559188) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(268.9116774) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6502725) + 'px', 
													width: spalte_breit_mm(32.257942) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(274.2445014) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(250.1787607) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6711968) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(102.4542454) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6711968) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(62.743047) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.7279305) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(53.985506) + 'px', 
													top: gib_hoehe_in_mm(27.5904577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.6926095) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(53.985506) + 'px', 
													top: gib_hoehe_in_mm(27.5904577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(129.5237845) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(53.985506) + 'px', 
													top: gib_hoehe_in_mm(27.5904577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.4845838) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(53.985506) + 'px', 
													top: gib_hoehe_in_mm(27.5904577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(174.0980105) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(250.1787607) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.9082145) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(255.5115847) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6502725) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.4289443) + 'px', 
													top: gib_hoehe_in_mm(268.9155571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(135.9993565) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(73.7511667) + 'px', 
													top: gib_hoehe_in_mm(195.2605107) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7349335) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(73.7511667) + 'px', 
													top: gib_hoehe_in_mm(195.2605107) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(27.9317238) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.2290296) + 'px', 
													top: gib_hoehe_in_mm(195.2576891) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.7279305) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(102.4542454) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8619055) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(102.4542454) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(129.5237845) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(102.4542454) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.6574068) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(102.4542454) + 'px', 
													top: gib_hoehe_in_mm(87.1897037) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(19.0162443) + 'px'}">
													Betriebsbeschreibung Blatt 3
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(60.8597958) + 'px', 
														top: gib_hoehe_in_mm(19.0296469) + 'px'}">
													Bauherrschaft
													</div>
<b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name" type="text" 
												v-if="formular.bauherr_name!=undefined" 
												class='textfeld' v-model='formular.bauherr_name.text' 
												style='width:100%' 
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												:disabled='formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_name.onylNumber) " 
												:style="{'width': spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2783787) + 'px', 
		
														'font-size': formular.bauherr_name.size + 'px', 
														'text-align': formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8457898) + 'px', 
														top: gib_hoehe_in_mm(28.4982311) + 'px'}">
													5 Gefährliche Stoffe Art und Menge
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.3109295) + 'px', 
														top: gib_hoehe_in_mm(28.909832) + 'px'}">
													Ort der Lagerung und Schutzvorkehrungen
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(166.4020965) + 'px', 
														top: gib_hoehe_in_mm(9.1928439) + 'px'}">
													Anlage I/9 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(196.0288965) + 'px', 
														top: gib_hoehe_in_mm(12.4941159) + 'px'}">
													Blatt 3
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.1631645) + 'px', 
														top: gib_hoehe_in_mm(19.0296469) + 'px'}">
													Antrag vom
													</div>
<b-popover 
												v-if="formular.bauantragVom"
												:show="formular.bauantragVom.showPop==true"
												
												ref='bauantragVom' 
												target='bauantragVom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVom.size}}</span>
												<b-button @click='plus("bauantragVom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVom" type="text" 
												v-if="formular.bauantragVom!=undefined" 
												class='textfeld' v-model='formular.bauantragVom.text' 
												style='width:100%' 
												@focus='formular.bauantragVom.showPop=true'
												@blur ='formular.bauantragVom.showPop=false'
												:disabled='formular.bauantragVom.isDisabled==true || formular.bauantragVom.isDisabled==true' 
												@keypress="isNumber($event, formular.bauantragVom.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.3347313) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(175.5436635) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': formular.bauantragVom.size + 'px', 
														'text-align': formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': formular.bauantragVom.letterSpacing + 'px',
														'font-weight': formular.bauantragVom.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.1631645) + 'px', 
														top: gib_hoehe_in_mm(27.7907149) + 'px'}">
													Prüfvermerke
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(39.8005026) + 'px'}">
													5.1 Düngemittel
													</div>
<b-popover 
												v-if="formular.duenger2Art"
												:show="formular.duenger2Art.showPop==true"
												
												ref='duenger2Art' 
												target='duenger2Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger2Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger2Art.size}}</span>
												<b-button @click='plus("duenger2Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger2Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger2Art" type="text" 
												v-if="formular.duenger2Art!=undefined" 
												class='textfeld' v-model='formular.duenger2Art.text' 
												style='width:100%' 
												@focus='formular.duenger2Art.showPop=true'
												@blur ='formular.duenger2Art.showPop=false'
												:disabled='formular.duenger2Art.isDisabled==true || formular.duenger2Art.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger2Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(43.8100083) + 'px', 
		
														'font-size': formular.duenger2Art.size + 'px', 
														'text-align': formular.duenger2Art.schriftausrichtung, 
														'letter-spacing': formular.duenger2Art.letterSpacing + 'px',
														'font-weight': formular.duenger2Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger2Lager"
												:show="formular.duenger2Lager.showPop==true"
												
												ref='duenger2Lager' 
												target='duenger2Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger2Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger2Lager.size}}</span>
												<b-button @click='plus("duenger2Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger2Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger2Lager" type="text" 
												v-if="formular.duenger2Lager!=undefined" 
												class='textfeld' v-model='formular.duenger2Lager.text' 
												style='width:100%' 
												@focus='formular.duenger2Lager.showPop=true'
												@blur ='formular.duenger2Lager.showPop=false'
												:disabled='formular.duenger2Lager.isDisabled==true || formular.duenger2Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger2Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(43.8100083) + 'px', 
		
														'font-size': formular.duenger2Lager.size + 'px', 
														'text-align': formular.duenger2Lager.schriftausrichtung, 
														'letter-spacing': formular.duenger2Lager.letterSpacing + 'px',
														'font-weight': formular.duenger2Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger1Art"
												:show="formular.duenger1Art.showPop==true"
												
												ref='duenger1Art' 
												target='duenger1Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger1Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger1Art.size}}</span>
												<b-button @click='plus("duenger1Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger1Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger1Art" type="text" 
												v-if="formular.duenger1Art!=undefined" 
												class='textfeld' v-model='formular.duenger1Art.text' 
												style='width:100%' 
												@focus='formular.duenger1Art.showPop=true'
												@blur ='formular.duenger1Art.showPop=false'
												:disabled='formular.duenger1Art.isDisabled==true || formular.duenger1Art.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger1Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(36.488309) + 'px', 
		
														'font-size': formular.duenger1Art.size + 'px', 
														'text-align': formular.duenger1Art.schriftausrichtung, 
														'letter-spacing': formular.duenger1Art.letterSpacing + 'px',
														'font-weight': formular.duenger1Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger1Lager"
												:show="formular.duenger1Lager.showPop==true"
												
												ref='duenger1Lager' 
												target='duenger1Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger1Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger1Lager.size}}</span>
												<b-button @click='plus("duenger1Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger1Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger1Lager" type="text" 
												v-if="formular.duenger1Lager!=undefined" 
												class='textfeld' v-model='formular.duenger1Lager.text' 
												style='width:100%' 
												@focus='formular.duenger1Lager.showPop=true'
												@blur ='formular.duenger1Lager.showPop=false'
												:disabled='formular.duenger1Lager.isDisabled==true || formular.duenger1Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger1Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(36.488309) + 'px', 
		
														'font-size': formular.duenger1Lager.size + 'px', 
														'text-align': formular.duenger1Lager.schriftausrichtung, 
														'letter-spacing': formular.duenger1Lager.letterSpacing + 'px',
														'font-weight': formular.duenger1Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger3Art"
												:show="formular.duenger3Art.showPop==true"
												
												ref='duenger3Art' 
												target='duenger3Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger3Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger3Art.size}}</span>
												<b-button @click='plus("duenger3Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger3Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger3Art" type="text" 
												v-if="formular.duenger3Art!=undefined" 
												class='textfeld' v-model='formular.duenger3Art.text' 
												style='width:100%' 
												@focus='formular.duenger3Art.showPop=true'
												@blur ='formular.duenger3Art.showPop=false'
												:disabled='formular.duenger3Art.isDisabled==true || formular.duenger3Art.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger3Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(51.132413) + 'px', 
		
														'font-size': formular.duenger3Art.size + 'px', 
														'text-align': formular.duenger3Art.schriftausrichtung, 
														'letter-spacing': formular.duenger3Art.letterSpacing + 'px',
														'font-weight': formular.duenger3Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger3Lager"
												:show="formular.duenger3Lager.showPop==true"
												
												ref='duenger3Lager' 
												target='duenger3Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger3Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger3Lager.size}}</span>
												<b-button @click='plus("duenger3Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger3Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger3Lager" type="text" 
												v-if="formular.duenger3Lager!=undefined" 
												class='textfeld' v-model='formular.duenger3Lager.text' 
												style='width:100%' 
												@focus='formular.duenger3Lager.showPop=true'
												@blur ='formular.duenger3Lager.showPop=false'
												:disabled='formular.duenger3Lager.isDisabled==true || formular.duenger3Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger3Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(51.132413) + 'px', 
		
														'font-size': formular.duenger3Lager.size + 'px', 
														'text-align': formular.duenger3Lager.schriftausrichtung, 
														'letter-spacing': formular.duenger3Lager.letterSpacing + 'px',
														'font-weight': formular.duenger3Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger4Art"
												:show="formular.duenger4Art.showPop==true"
												
												ref='duenger4Art' 
												target='duenger4Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger4Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger4Art.size}}</span>
												<b-button @click='plus("duenger4Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger4Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger4Art" type="text" 
												v-if="formular.duenger4Art!=undefined" 
												class='textfeld' v-model='formular.duenger4Art.text' 
												style='width:100%' 
												@focus='formular.duenger4Art.showPop=true'
												@blur ='formular.duenger4Art.showPop=false'
												:disabled='formular.duenger4Art.isDisabled==true || formular.duenger4Art.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger4Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(58.454465) + 'px', 
		
														'font-size': formular.duenger4Art.size + 'px', 
														'text-align': formular.duenger4Art.schriftausrichtung, 
														'letter-spacing': formular.duenger4Art.letterSpacing + 'px',
														'font-weight': formular.duenger4Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.duenger4Lager"
												:show="formular.duenger4Lager.showPop==true"
												
												ref='duenger4Lager' 
												target='duenger4Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.duenger4Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.duenger4Lager.size}}</span>
												<b-button @click='plus("duenger4Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("duenger4Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="duenger4Lager" type="text" 
												v-if="formular.duenger4Lager!=undefined" 
												class='textfeld' v-model='formular.duenger4Lager.text' 
												style='width:100%' 
												@focus='formular.duenger4Lager.showPop=true'
												@blur ='formular.duenger4Lager.showPop=false'
												:disabled='formular.duenger4Lager.isDisabled==true || formular.duenger4Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.duenger4Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(58.454465) + 'px', 
		
														'font-size': formular.duenger4Lager.size + 'px', 
														'text-align': formular.duenger4Lager.schriftausrichtung, 
														'letter-spacing': formular.duenger4Lager.letterSpacing + 'px',
														'font-weight': formular.duenger4Lager.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(68.4115266) + 'px'}">
													5.2 Pflanzenschutzmittel,
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(71.6281506) + 'px'}">
													Gifte o.ä.
													</div>
<b-popover 
												v-if="formular.pflaSchu1Art"
												:show="formular.pflaSchu1Art.showPop==true"
												
												ref='pflaSchu1Art' 
												target='pflaSchu1Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu1Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu1Art.size}}</span>
												<b-button @click='plus("pflaSchu1Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu1Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu1Art" type="text" 
												v-if="formular.pflaSchu1Art!=undefined" 
												class='textfeld' v-model='formular.pflaSchu1Art.text' 
												style='width:100%' 
												@focus='formular.pflaSchu1Art.showPop=true'
												@blur ='formular.pflaSchu1Art.showPop=false'
												:disabled='formular.pflaSchu1Art.isDisabled==true || formular.pflaSchu1Art.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu1Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(65.861165) + 'px', 
		
														'font-size': formular.pflaSchu1Art.size + 'px', 
														'text-align': formular.pflaSchu1Art.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu1Art.letterSpacing + 'px',
														'font-weight': formular.pflaSchu1Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu1Lager"
												:show="formular.pflaSchu1Lager.showPop==true"
												
												ref='pflaSchu1Lager' 
												target='pflaSchu1Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu1Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu1Lager.size}}</span>
												<b-button @click='plus("pflaSchu1Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu1Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu1Lager" type="text" 
												v-if="formular.pflaSchu1Lager!=undefined" 
												class='textfeld' v-model='formular.pflaSchu1Lager.text' 
												style='width:100%' 
												@focus='formular.pflaSchu1Lager.showPop=true'
												@blur ='formular.pflaSchu1Lager.showPop=false'
												:disabled='formular.pflaSchu1Lager.isDisabled==true || formular.pflaSchu1Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu1Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(65.861165) + 'px', 
		
														'font-size': formular.pflaSchu1Lager.size + 'px', 
														'text-align': formular.pflaSchu1Lager.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu1Lager.letterSpacing + 'px',
														'font-weight': formular.pflaSchu1Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu2Art"
												:show="formular.pflaSchu2Art.showPop==true"
												
												ref='pflaSchu2Art' 
												target='pflaSchu2Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu2Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu2Art.size}}</span>
												<b-button @click='plus("pflaSchu2Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu2Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu2Art" type="text" 
												v-if="formular.pflaSchu2Art!=undefined" 
												class='textfeld' v-model='formular.pflaSchu2Art.text' 
												style='width:100%' 
												@focus='formular.pflaSchu2Art.showPop=true'
												@blur ='formular.pflaSchu2Art.showPop=false'
												:disabled='formular.pflaSchu2Art.isDisabled==true || formular.pflaSchu2Art.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu2Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(73.183217) + 'px', 
		
														'font-size': formular.pflaSchu2Art.size + 'px', 
														'text-align': formular.pflaSchu2Art.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu2Art.letterSpacing + 'px',
														'font-weight': formular.pflaSchu2Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu2Lager"
												:show="formular.pflaSchu2Lager.showPop==true"
												
												ref='pflaSchu2Lager' 
												target='pflaSchu2Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu2Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu2Lager.size}}</span>
												<b-button @click='plus("pflaSchu2Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu2Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu2Lager" type="text" 
												v-if="formular.pflaSchu2Lager!=undefined" 
												class='textfeld' v-model='formular.pflaSchu2Lager.text' 
												style='width:100%' 
												@focus='formular.pflaSchu2Lager.showPop=true'
												@blur ='formular.pflaSchu2Lager.showPop=false'
												:disabled='formular.pflaSchu2Lager.isDisabled==true || formular.pflaSchu2Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu2Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(73.183217) + 'px', 
		
														'font-size': formular.pflaSchu2Lager.size + 'px', 
														'text-align': formular.pflaSchu2Lager.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu2Lager.letterSpacing + 'px',
														'font-weight': formular.pflaSchu2Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu3Art"
												:show="formular.pflaSchu3Art.showPop==true"
												
												ref='pflaSchu3Art' 
												target='pflaSchu3Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu3Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu3Art.size}}</span>
												<b-button @click='plus("pflaSchu3Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu3Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu3Art" type="text" 
												v-if="formular.pflaSchu3Art!=undefined" 
												class='textfeld' v-model='formular.pflaSchu3Art.text' 
												style='width:100%' 
												@focus='formular.pflaSchu3Art.showPop=true'
												@blur ='formular.pflaSchu3Art.showPop=false'
												:disabled='formular.pflaSchu3Art.isDisabled==true || formular.pflaSchu3Art.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu3Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(80.505269) + 'px', 
		
														'font-size': formular.pflaSchu3Art.size + 'px', 
														'text-align': formular.pflaSchu3Art.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu3Art.letterSpacing + 'px',
														'font-weight': formular.pflaSchu3Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu3Lager"
												:show="formular.pflaSchu3Lager.showPop==true"
												
												ref='pflaSchu3Lager' 
												target='pflaSchu3Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu3Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu3Lager.size}}</span>
												<b-button @click='plus("pflaSchu3Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu3Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu3Lager" type="text" 
												v-if="formular.pflaSchu3Lager!=undefined" 
												class='textfeld' v-model='formular.pflaSchu3Lager.text' 
												style='width:100%' 
												@focus='formular.pflaSchu3Lager.showPop=true'
												@blur ='formular.pflaSchu3Lager.showPop=false'
												:disabled='formular.pflaSchu3Lager.isDisabled==true || formular.pflaSchu3Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu3Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(80.505269) + 'px', 
		
														'font-size': formular.pflaSchu3Lager.size + 'px', 
														'text-align': formular.pflaSchu3Lager.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu3Lager.letterSpacing + 'px',
														'font-weight': formular.pflaSchu3Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu4Art"
												:show="formular.pflaSchu4Art.showPop==true"
												
												ref='pflaSchu4Art' 
												target='pflaSchu4Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu4Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu4Art.size}}</span>
												<b-button @click='plus("pflaSchu4Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu4Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu4Art" type="text" 
												v-if="formular.pflaSchu4Art!=undefined" 
												class='textfeld' v-model='formular.pflaSchu4Art.text' 
												style='width:100%' 
												@focus='formular.pflaSchu4Art.showPop=true'
												@blur ='formular.pflaSchu4Art.showPop=false'
												:disabled='formular.pflaSchu4Art.isDisabled==true || formular.pflaSchu4Art.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu4Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(87.827321) + 'px', 
		
														'font-size': formular.pflaSchu4Art.size + 'px', 
														'text-align': formular.pflaSchu4Art.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu4Art.letterSpacing + 'px',
														'font-weight': formular.pflaSchu4Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.pflaSchu4Lager"
												:show="formular.pflaSchu4Lager.showPop==true"
												
												ref='pflaSchu4Lager' 
												target='pflaSchu4Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pflaSchu4Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pflaSchu4Lager.size}}</span>
												<b-button @click='plus("pflaSchu4Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pflaSchu4Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pflaSchu4Lager" type="text" 
												v-if="formular.pflaSchu4Lager!=undefined" 
												class='textfeld' v-model='formular.pflaSchu4Lager.text' 
												style='width:100%' 
												@focus='formular.pflaSchu4Lager.showPop=true'
												@blur ='formular.pflaSchu4Lager.showPop=false'
												:disabled='formular.pflaSchu4Lager.isDisabled==true || formular.pflaSchu4Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.pflaSchu4Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(87.827321) + 'px', 
		
														'font-size': formular.pflaSchu4Lager.size + 'px', 
														'text-align': formular.pflaSchu4Lager.schriftausrichtung, 
														'letter-spacing': formular.pflaSchu4Lager.letterSpacing + 'px',
														'font-weight': formular.pflaSchu4Lager.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(98.3874996) + 'px'}">
													5.3 Kraft, Betriebsstoffe
													</div>
<b-popover 
												v-if="formular.kraftstoff2Art"
												:show="formular.kraftstoff2Art.showPop==true"
												
												ref='kraftstoff2Art' 
												target='kraftstoff2Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff2Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff2Art.size}}</span>
												<b-button @click='plus("kraftstoff2Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff2Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff2Art" type="text" 
												v-if="formular.kraftstoff2Art!=undefined" 
												class='textfeld' v-model='formular.kraftstoff2Art.text' 
												style='width:100%' 
												@focus='formular.kraftstoff2Art.showPop=true'
												@blur ='formular.kraftstoff2Art.showPop=false'
												:disabled='formular.kraftstoff2Art.isDisabled==true || formular.kraftstoff2Art.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff2Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(102.429101) + 'px', 
		
														'font-size': formular.kraftstoff2Art.size + 'px', 
														'text-align': formular.kraftstoff2Art.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff2Art.letterSpacing + 'px',
														'font-weight': formular.kraftstoff2Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff2Lager"
												:show="formular.kraftstoff2Lager.showPop==true"
												
												ref='kraftstoff2Lager' 
												target='kraftstoff2Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff2Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff2Lager.size}}</span>
												<b-button @click='plus("kraftstoff2Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff2Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff2Lager" type="text" 
												v-if="formular.kraftstoff2Lager!=undefined" 
												class='textfeld' v-model='formular.kraftstoff2Lager.text' 
												style='width:100%' 
												@focus='formular.kraftstoff2Lager.showPop=true'
												@blur ='formular.kraftstoff2Lager.showPop=false'
												:disabled='formular.kraftstoff2Lager.isDisabled==true || formular.kraftstoff2Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff2Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(102.429101) + 'px', 
		
														'font-size': formular.kraftstoff2Lager.size + 'px', 
														'text-align': formular.kraftstoff2Lager.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff2Lager.letterSpacing + 'px',
														'font-weight': formular.kraftstoff2Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff1Art"
												:show="formular.kraftstoff1Art.showPop==true"
												
												ref='kraftstoff1Art' 
												target='kraftstoff1Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff1Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff1Art.size}}</span>
												<b-button @click='plus("kraftstoff1Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff1Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff1Art" type="text" 
												v-if="formular.kraftstoff1Art!=undefined" 
												class='textfeld' v-model='formular.kraftstoff1Art.text' 
												style='width:100%' 
												@focus='formular.kraftstoff1Art.showPop=true'
												@blur ='formular.kraftstoff1Art.showPop=false'
												:disabled='formular.kraftstoff1Art.isDisabled==true || formular.kraftstoff1Art.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff1Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(95.107049) + 'px', 
		
														'font-size': formular.kraftstoff1Art.size + 'px', 
														'text-align': formular.kraftstoff1Art.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff1Art.letterSpacing + 'px',
														'font-weight': formular.kraftstoff1Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff1Lager"
												:show="formular.kraftstoff1Lager.showPop==true"
												
												ref='kraftstoff1Lager' 
												target='kraftstoff1Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff1Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff1Lager.size}}</span>
												<b-button @click='plus("kraftstoff1Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff1Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff1Lager" type="text" 
												v-if="formular.kraftstoff1Lager!=undefined" 
												class='textfeld' v-model='formular.kraftstoff1Lager.text' 
												style='width:100%' 
												@focus='formular.kraftstoff1Lager.showPop=true'
												@blur ='formular.kraftstoff1Lager.showPop=false'
												:disabled='formular.kraftstoff1Lager.isDisabled==true || formular.kraftstoff1Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff1Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(95.107049) + 'px', 
		
														'font-size': formular.kraftstoff1Lager.size + 'px', 
														'text-align': formular.kraftstoff1Lager.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff1Lager.letterSpacing + 'px',
														'font-weight': formular.kraftstoff1Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff3Art"
												:show="formular.kraftstoff3Art.showPop==true"
												
												ref='kraftstoff3Art' 
												target='kraftstoff3Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff3Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff3Art.size}}</span>
												<b-button @click='plus("kraftstoff3Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff3Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff3Art" type="text" 
												v-if="formular.kraftstoff3Art!=undefined" 
												class='textfeld' v-model='formular.kraftstoff3Art.text' 
												style='width:100%' 
												@focus='formular.kraftstoff3Art.showPop=true'
												@blur ='formular.kraftstoff3Art.showPop=false'
												:disabled='formular.kraftstoff3Art.isDisabled==true || formular.kraftstoff3Art.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff3Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(109.7515057) + 'px', 
		
														'font-size': formular.kraftstoff3Art.size + 'px', 
														'text-align': formular.kraftstoff3Art.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff3Art.letterSpacing + 'px',
														'font-weight': formular.kraftstoff3Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff3Lager"
												:show="formular.kraftstoff3Lager.showPop==true"
												
												ref='kraftstoff3Lager' 
												target='kraftstoff3Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff3Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff3Lager.size}}</span>
												<b-button @click='plus("kraftstoff3Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff3Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff3Lager" type="text" 
												v-if="formular.kraftstoff3Lager!=undefined" 
												class='textfeld' v-model='formular.kraftstoff3Lager.text' 
												style='width:100%' 
												@focus='formular.kraftstoff3Lager.showPop=true'
												@blur ='formular.kraftstoff3Lager.showPop=false'
												:disabled='formular.kraftstoff3Lager.isDisabled==true || formular.kraftstoff3Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff3Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(109.7515057) + 'px', 
		
														'font-size': formular.kraftstoff3Lager.size + 'px', 
														'text-align': formular.kraftstoff3Lager.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff3Lager.letterSpacing + 'px',
														'font-weight': formular.kraftstoff3Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff4Art"
												:show="formular.kraftstoff4Art.showPop==true"
												
												ref='kraftstoff4Art' 
												target='kraftstoff4Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff4Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff4Art.size}}</span>
												<b-button @click='plus("kraftstoff4Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff4Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff4Art" type="text" 
												v-if="formular.kraftstoff4Art!=undefined" 
												class='textfeld' v-model='formular.kraftstoff4Art.text' 
												style='width:100%' 
												@focus='formular.kraftstoff4Art.showPop=true'
												@blur ='formular.kraftstoff4Art.showPop=false'
												:disabled='formular.kraftstoff4Art.isDisabled==true || formular.kraftstoff4Art.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff4Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(117.0728523) + 'px', 
		
														'font-size': formular.kraftstoff4Art.size + 'px', 
														'text-align': formular.kraftstoff4Art.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff4Art.letterSpacing + 'px',
														'font-weight': formular.kraftstoff4Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.kraftstoff4Lager"
												:show="formular.kraftstoff4Lager.showPop==true"
												
												ref='kraftstoff4Lager' 
												target='kraftstoff4Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kraftstoff4Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kraftstoff4Lager.size}}</span>
												<b-button @click='plus("kraftstoff4Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kraftstoff4Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kraftstoff4Lager" type="text" 
												v-if="formular.kraftstoff4Lager!=undefined" 
												class='textfeld' v-model='formular.kraftstoff4Lager.text' 
												style='width:100%' 
												@focus='formular.kraftstoff4Lager.showPop=true'
												@blur ='formular.kraftstoff4Lager.showPop=false'
												:disabled='formular.kraftstoff4Lager.isDisabled==true || formular.kraftstoff4Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.kraftstoff4Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(117.0728523) + 'px', 
		
														'font-size': formular.kraftstoff4Lager.size + 'px', 
														'text-align': formular.kraftstoff4Lager.schriftausrichtung, 
														'letter-spacing': formular.kraftstoff4Lager.letterSpacing + 'px',
														'font-weight': formular.kraftstoff4Lager.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(127.6333836) + 'px'}">
													5.4 Abfallstoffe
													</div>
<b-popover 
												v-if="formular.abfall1Art"
												:show="formular.abfall1Art.showPop==true"
												
												ref='abfall1Art' 
												target='abfall1Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall1Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall1Art.size}}</span>
												<b-button @click='plus("abfall1Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall1Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall1Art" type="text" 
												v-if="formular.abfall1Art!=undefined" 
												class='textfeld' v-model='formular.abfall1Art.text' 
												style='width:100%' 
												@focus='formular.abfall1Art.showPop=true'
												@blur ='formular.abfall1Art.showPop=false'
												:disabled='formular.abfall1Art.isDisabled==true || formular.abfall1Art.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall1Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(124.4061907) + 'px', 
		
														'font-size': formular.abfall1Art.size + 'px', 
														'text-align': formular.abfall1Art.schriftausrichtung, 
														'letter-spacing': formular.abfall1Art.letterSpacing + 'px',
														'font-weight': formular.abfall1Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall1Lager"
												:show="formular.abfall1Lager.showPop==true"
												
												ref='abfall1Lager' 
												target='abfall1Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall1Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall1Lager.size}}</span>
												<b-button @click='plus("abfall1Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall1Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall1Lager" type="text" 
												v-if="formular.abfall1Lager!=undefined" 
												class='textfeld' v-model='formular.abfall1Lager.text' 
												style='width:100%' 
												@focus='formular.abfall1Lager.showPop=true'
												@blur ='formular.abfall1Lager.showPop=false'
												:disabled='formular.abfall1Lager.isDisabled==true || formular.abfall1Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall1Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(124.4061907) + 'px', 
		
														'font-size': formular.abfall1Lager.size + 'px', 
														'text-align': formular.abfall1Lager.schriftausrichtung, 
														'letter-spacing': formular.abfall1Lager.letterSpacing + 'px',
														'font-weight': formular.abfall1Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall2Art"
												:show="formular.abfall2Art.showPop==true"
												
												ref='abfall2Art' 
												target='abfall2Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall2Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall2Art.size}}</span>
												<b-button @click='plus("abfall2Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall2Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall2Art" type="text" 
												v-if="formular.abfall2Art!=undefined" 
												class='textfeld' v-model='formular.abfall2Art.text' 
												style='width:100%' 
												@focus='formular.abfall2Art.showPop=true'
												@blur ='formular.abfall2Art.showPop=false'
												:disabled='formular.abfall2Art.isDisabled==true || formular.abfall2Art.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall2Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(131.7275373) + 'px', 
		
														'font-size': formular.abfall2Art.size + 'px', 
														'text-align': formular.abfall2Art.schriftausrichtung, 
														'letter-spacing': formular.abfall2Art.letterSpacing + 'px',
														'font-weight': formular.abfall2Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall2Lager"
												:show="formular.abfall2Lager.showPop==true"
												
												ref='abfall2Lager' 
												target='abfall2Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall2Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall2Lager.size}}</span>
												<b-button @click='plus("abfall2Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall2Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall2Lager" type="text" 
												v-if="formular.abfall2Lager!=undefined" 
												class='textfeld' v-model='formular.abfall2Lager.text' 
												style='width:100%' 
												@focus='formular.abfall2Lager.showPop=true'
												@blur ='formular.abfall2Lager.showPop=false'
												:disabled='formular.abfall2Lager.isDisabled==true || formular.abfall2Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall2Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(131.7275373) + 'px', 
		
														'font-size': formular.abfall2Lager.size + 'px', 
														'text-align': formular.abfall2Lager.schriftausrichtung, 
														'letter-spacing': formular.abfall2Lager.letterSpacing + 'px',
														'font-weight': formular.abfall2Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall3Art"
												:show="formular.abfall3Art.showPop==true"
												
												ref='abfall3Art' 
												target='abfall3Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall3Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall3Art.size}}</span>
												<b-button @click='plus("abfall3Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall3Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall3Art" type="text" 
												v-if="formular.abfall3Art!=undefined" 
												class='textfeld' v-model='formular.abfall3Art.text' 
												style='width:100%' 
												@focus='formular.abfall3Art.showPop=true'
												@blur ='formular.abfall3Art.showPop=false'
												:disabled='formular.abfall3Art.isDisabled==true || formular.abfall3Art.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall3Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(139.049942) + 'px', 
		
														'font-size': formular.abfall3Art.size + 'px', 
														'text-align': formular.abfall3Art.schriftausrichtung, 
														'letter-spacing': formular.abfall3Art.letterSpacing + 'px',
														'font-weight': formular.abfall3Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall3Lager"
												:show="formular.abfall3Lager.showPop==true"
												
												ref='abfall3Lager' 
												target='abfall3Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall3Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall3Lager.size}}</span>
												<b-button @click='plus("abfall3Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall3Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall3Lager" type="text" 
												v-if="formular.abfall3Lager!=undefined" 
												class='textfeld' v-model='formular.abfall3Lager.text' 
												style='width:100%' 
												@focus='formular.abfall3Lager.showPop=true'
												@blur ='formular.abfall3Lager.showPop=false'
												:disabled='formular.abfall3Lager.isDisabled==true || formular.abfall3Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall3Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(139.049942) + 'px', 
		
														'font-size': formular.abfall3Lager.size + 'px', 
														'text-align': formular.abfall3Lager.schriftausrichtung, 
														'letter-spacing': formular.abfall3Lager.letterSpacing + 'px',
														'font-weight': formular.abfall3Lager.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall4Art"
												:show="formular.abfall4Art.showPop==true"
												
												ref='abfall4Art' 
												target='abfall4Art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall4Art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall4Art.size}}</span>
												<b-button @click='plus("abfall4Art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall4Art", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall4Art" type="text" 
												v-if="formular.abfall4Art!=undefined" 
												class='textfeld' v-model='formular.abfall4Art.text' 
												style='width:100%' 
												@focus='formular.abfall4Art.showPop=true'
												@blur ='formular.abfall4Art.showPop=false'
												:disabled='formular.abfall4Art.isDisabled==true || formular.abfall4Art.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall4Art.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(146.3723467) + 'px', 
		
														'font-size': formular.abfall4Art.size + 'px', 
														'text-align': formular.abfall4Art.schriftausrichtung, 
														'letter-spacing': formular.abfall4Art.letterSpacing + 'px',
														'font-weight': formular.abfall4Art.schriftart }" 
												/>

<b-popover 
												v-if="formular.abfall4Lager"
												:show="formular.abfall4Lager.showPop==true"
												
												ref='abfall4Lager' 
												target='abfall4Lager'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall4Lager.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall4Lager.size}}</span>
												<b-button @click='plus("abfall4Lager", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall4Lager", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="abfall4Lager" type="text" 
												v-if="formular.abfall4Lager!=undefined" 
												class='textfeld' v-model='formular.abfall4Lager.text' 
												style='width:100%' 
												@focus='formular.abfall4Lager.showPop=true'
												@blur ='formular.abfall4Lager.showPop=false'
												:disabled='formular.abfall4Lager.isDisabled==true || formular.abfall4Lager.isDisabled==true' 
												@keypress="isNumber($event, formular.abfall4Lager.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(146.3723467) + 'px', 
		
														'font-size': formular.abfall4Lager.size + 'px', 
														'text-align': formular.abfall4Lager.schriftausrichtung, 
														'letter-spacing': formular.abfall4Lager.letterSpacing + 'px',
														'font-weight': formular.abfall4Lager.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(157.1755356) + 'px'}">
													Art der Beseitigung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4914218) + 'px', 
														top: gib_hoehe_in_mm(170.8532416) + 'px'}">
													5.5 Besonders zu behan
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(174.1125423) + 'px'}">
													delnde Abwässer
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(178.0486743) + 'px'}">
													Art, Menge pro
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(181.3076223) + 'px'}">
													Zeiteinheit
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(200.9036343) + 'px'}">
													Art und Ort der
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(204.1202583) + 'px'}">
													Behandlung
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(230.1600993) + 'px'}">
													Verbleib der
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.8484866) + 'px', 
														top: gib_hoehe_in_mm(233.4190473) + 'px'}">
													Rückstände
													</div>
<b-popover 
												v-if="formular.artBeseitigung1"
												:show="formular.artBeseitigung1.showPop==true"
												
												ref='artBeseitigung1' 
												target='artBeseitigung1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.artBeseitigung1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.artBeseitigung1.size}}</span>
												<b-button @click='plus("artBeseitigung1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("artBeseitigung1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="artBeseitigung1" type="text" 
												v-if="formular.artBeseitigung1!=undefined" 
												class='textfeld' v-model='formular.artBeseitigung1.text' 
												style='width:100%' 
												@focus='formular.artBeseitigung1.showPop=true'
												@blur ='formular.artBeseitigung1.showPop=false'
												:disabled='formular.artBeseitigung1.isDisabled==true || formular.artBeseitigung1.isDisabled==true' 
												@keypress="isNumber($event, formular.artBeseitigung1.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(153.6936933) + 'px', 
		
														'font-size': formular.artBeseitigung1.size + 'px', 
														'text-align': formular.artBeseitigung1.schriftausrichtung, 
														'letter-spacing': formular.artBeseitigung1.letterSpacing + 'px',
														'font-weight': formular.artBeseitigung1.schriftart }" 
												/>

<b-popover 
												v-if="formular.artBeseitigung1"
												:show="formular.artBeseitigung1.showPop==true"
												
												ref='artBeseitigung1' 
												target='artBeseitigung1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.artBeseitigung1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.artBeseitigung1.size}}</span>
												<b-button @click='plus("artBeseitigung1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("artBeseitigung1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="artBeseitigung1" type="text" 
												v-if="formular.artBeseitigung1!=undefined" 
												class='textfeld' v-model='formular.artBeseitigung1.text' 
												style='width:100%' 
												@focus='formular.artBeseitigung1.showPop=true'
												@blur ='formular.artBeseitigung1.showPop=false'
												:disabled='formular.artBeseitigung1.isDisabled==true || formular.artBeseitigung1.isDisabled==true' 
												@keypress="isNumber($event, formular.artBeseitigung1.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(161.0993352) + 'px', 
		
														'font-size': formular.artBeseitigung1.size + 'px', 
														'text-align': formular.artBeseitigung1.schriftausrichtung, 
														'letter-spacing': formular.artBeseitigung1.letterSpacing + 'px',
														'font-weight': formular.artBeseitigung1.schriftart }" 
												/>

<b-popover 
												v-if="formular.behAbwasser1"
												:show="formular.behAbwasser1.showPop==true"
												
												ref='behAbwasser1' 
												target='behAbwasser1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.behAbwasser1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.behAbwasser1.size}}</span>
												<b-button @click='plus("behAbwasser1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("behAbwasser1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="behAbwasser1" type="text" 
												v-if="formular.behAbwasser1!=undefined" 
												class='textfeld' v-model='formular.behAbwasser1.text' 
												style='width:100%' 
												@focus='formular.behAbwasser1.showPop=true'
												@blur ='formular.behAbwasser1.showPop=false'
												:disabled='formular.behAbwasser1.isDisabled==true || formular.behAbwasser1.isDisabled==true' 
												@keypress="isNumber($event, formular.behAbwasser1.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(168.4294993) + 'px', 
		
														'font-size': formular.behAbwasser1.size + 'px', 
														'text-align': formular.behAbwasser1.schriftausrichtung, 
														'letter-spacing': formular.behAbwasser1.letterSpacing + 'px',
														'font-weight': formular.behAbwasser1.schriftart }" 
												/>

<b-popover 
												v-if="formular.behAbwasser2"
												:show="formular.behAbwasser2.showPop==true"
												
												ref='behAbwasser2' 
												target='behAbwasser2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.behAbwasser2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.behAbwasser2.size}}</span>
												<b-button @click='plus("behAbwasser2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("behAbwasser2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="behAbwasser2" type="text" 
												v-if="formular.behAbwasser2!=undefined" 
												class='textfeld' v-model='formular.behAbwasser2.text' 
												style='width:100%' 
												@focus='formular.behAbwasser2.showPop=true'
												@blur ='formular.behAbwasser2.showPop=false'
												:disabled='formular.behAbwasser2.isDisabled==true || formular.behAbwasser2.isDisabled==true' 
												@keypress="isNumber($event, formular.behAbwasser2.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(175.5829607) + 'px', 
		
														'font-size': formular.behAbwasser2.size + 'px', 
														'text-align': formular.behAbwasser2.schriftausrichtung, 
														'letter-spacing': formular.behAbwasser2.letterSpacing + 'px',
														'font-weight': formular.behAbwasser2.schriftart }" 
												/>

<b-popover 
												v-if="formular.behAbwasser3"
												:show="formular.behAbwasser3.showPop==true"
												
												ref='behAbwasser3' 
												target='behAbwasser3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.behAbwasser3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.behAbwasser3.size}}</span>
												<b-button @click='plus("behAbwasser3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("behAbwasser3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="behAbwasser3" type="text" 
												v-if="formular.behAbwasser3!=undefined" 
												class='textfeld' v-model='formular.behAbwasser3.text' 
												style='width:100%' 
												@focus='formular.behAbwasser3.showPop=true'
												@blur ='formular.behAbwasser3.showPop=false'
												:disabled='formular.behAbwasser3.isDisabled==true || formular.behAbwasser3.isDisabled==true' 
												@keypress="isNumber($event, formular.behAbwasser3.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(183.0312793) + 'px', 
		
														'font-size': formular.behAbwasser3.size + 'px', 
														'text-align': formular.behAbwasser3.schriftausrichtung, 
														'letter-spacing': formular.behAbwasser3.letterSpacing + 'px',
														'font-weight': formular.behAbwasser3.schriftart }" 
												/>

<b-popover 
												v-if="formular.behAbwasser4"
												:show="formular.behAbwasser4.showPop==true"
												
												ref='behAbwasser4' 
												target='behAbwasser4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.behAbwasser4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.behAbwasser4.size}}</span>
												<b-button @click='plus("behAbwasser4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("behAbwasser4", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="behAbwasser4" type="text" 
												v-if="formular.behAbwasser4!=undefined" 
												class='textfeld' v-model='formular.behAbwasser4.text' 
												style='width:100%' 
												@focus='formular.behAbwasser4.showPop=true'
												@blur ='formular.behAbwasser4.showPop=false'
												:disabled='formular.behAbwasser4.isDisabled==true || formular.behAbwasser4.isDisabled==true' 
												@keypress="isNumber($event, formular.behAbwasser4.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(190.1847407) + 'px', 
		
														'font-size': formular.behAbwasser4.size + 'px', 
														'text-align': formular.behAbwasser4.schriftausrichtung, 
														'letter-spacing': formular.behAbwasser4.letterSpacing + 'px',
														'font-weight': formular.behAbwasser4.schriftart }" 
												/>

<b-popover 
												v-if="formular.ortBehanlAbw1"
												:show="formular.ortBehanlAbw1.showPop==true"
												
												ref='ortBehanlAbw1' 
												target='ortBehanlAbw1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ortBehanlAbw1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ortBehanlAbw1.size}}</span>
												<b-button @click='plus("ortBehanlAbw1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ortBehanlAbw1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ortBehanlAbw1" type="text" 
												v-if="formular.ortBehanlAbw1!=undefined" 
												class='textfeld' v-model='formular.ortBehanlAbw1.text' 
												style='width:100%' 
												@focus='formular.ortBehanlAbw1.showPop=true'
												@blur ='formular.ortBehanlAbw1.showPop=false'
												:disabled='formular.ortBehanlAbw1.isDisabled==true || formular.ortBehanlAbw1.isDisabled==true' 
												@keypress="isNumber($event, formular.ortBehanlAbw1.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(197.5060873) + 'px', 
		
														'font-size': formular.ortBehanlAbw1.size + 'px', 
														'text-align': formular.ortBehanlAbw1.schriftausrichtung, 
														'letter-spacing': formular.ortBehanlAbw1.letterSpacing + 'px',
														'font-weight': formular.ortBehanlAbw1.schriftart }" 
												/>

<b-popover 
												v-if="formular.ortBehanlAbw2"
												:show="formular.ortBehanlAbw2.showPop==true"
												
												ref='ortBehanlAbw2' 
												target='ortBehanlAbw2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ortBehanlAbw2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ortBehanlAbw2.size}}</span>
												<b-button @click='plus("ortBehanlAbw2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ortBehanlAbw2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ortBehanlAbw2" type="text" 
												v-if="formular.ortBehanlAbw2!=undefined" 
												class='textfeld' v-model='formular.ortBehanlAbw2.text' 
												style='width:100%' 
												@focus='formular.ortBehanlAbw2.showPop=true'
												@blur ='formular.ortBehanlAbw2.showPop=false'
												:disabled='formular.ortBehanlAbw2.isDisabled==true || formular.ortBehanlAbw2.isDisabled==true' 
												@keypress="isNumber($event, formular.ortBehanlAbw2.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(204.6595487) + 'px', 
		
														'font-size': formular.ortBehanlAbw2.size + 'px', 
														'text-align': formular.ortBehanlAbw2.schriftausrichtung, 
														'letter-spacing': formular.ortBehanlAbw2.letterSpacing + 'px',
														'font-weight': formular.ortBehanlAbw2.schriftart }" 
												/>

<b-popover 
												v-if="formular.ortBehanlAbw3"
												:show="formular.ortBehanlAbw3.showPop==true"
												
												ref='ortBehanlAbw3' 
												target='ortBehanlAbw3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ortBehanlAbw3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ortBehanlAbw3.size}}</span>
												<b-button @click='plus("ortBehanlAbw3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ortBehanlAbw3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ortBehanlAbw3" type="text" 
												v-if="formular.ortBehanlAbw3!=undefined" 
												class='textfeld' v-model='formular.ortBehanlAbw3.text' 
												style='width:100%' 
												@focus='formular.ortBehanlAbw3.showPop=true'
												@blur ='formular.ortBehanlAbw3.showPop=false'
												:disabled='formular.ortBehanlAbw3.isDisabled==true || formular.ortBehanlAbw3.isDisabled==true' 
												@keypress="isNumber($event, formular.ortBehanlAbw3.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(212.1078673) + 'px', 
		
														'font-size': formular.ortBehanlAbw3.size + 'px', 
														'text-align': formular.ortBehanlAbw3.schriftausrichtung, 
														'letter-spacing': formular.ortBehanlAbw3.letterSpacing + 'px',
														'font-weight': formular.ortBehanlAbw3.schriftart }" 
												/>

<b-popover 
												v-if="formular.ortBehanlAbw4"
												:show="formular.ortBehanlAbw4.showPop==true"
												
												ref='ortBehanlAbw4' 
												target='ortBehanlAbw4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ortBehanlAbw4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ortBehanlAbw4.size}}</span>
												<b-button @click='plus("ortBehanlAbw4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ortBehanlAbw4", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ortBehanlAbw4" type="text" 
												v-if="formular.ortBehanlAbw4!=undefined" 
												class='textfeld' v-model='formular.ortBehanlAbw4.text' 
												style='width:100%' 
												@focus='formular.ortBehanlAbw4.showPop=true'
												@blur ='formular.ortBehanlAbw4.showPop=false'
												:disabled='formular.ortBehanlAbw4.isDisabled==true || formular.ortBehanlAbw4.isDisabled==true' 
												@keypress="isNumber($event, formular.ortBehanlAbw4.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(219.2613287) + 'px', 
		
														'font-size': formular.ortBehanlAbw4.size + 'px', 
														'text-align': formular.ortBehanlAbw4.schriftausrichtung, 
														'letter-spacing': formular.ortBehanlAbw4.letterSpacing + 'px',
														'font-weight': formular.ortBehanlAbw4.schriftart }" 
												/>

<b-popover 
												v-if="formular.verbleRuecksAbw1"
												:show="formular.verbleRuecksAbw1.showPop==true"
												
												ref='verbleRuecksAbw1' 
												target='verbleRuecksAbw1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.verbleRuecksAbw1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.verbleRuecksAbw1.size}}</span>
												<b-button @click='plus("verbleRuecksAbw1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("verbleRuecksAbw1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="verbleRuecksAbw1" type="text" 
												v-if="formular.verbleRuecksAbw1!=undefined" 
												class='textfeld' v-model='formular.verbleRuecksAbw1.text' 
												style='width:100%' 
												@focus='formular.verbleRuecksAbw1.showPop=true'
												@blur ='formular.verbleRuecksAbw1.showPop=false'
												:disabled='formular.verbleRuecksAbw1.isDisabled==true || formular.verbleRuecksAbw1.isDisabled==true' 
												@keypress="isNumber($event, formular.verbleRuecksAbw1.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(226.794648) + 'px', 
		
														'font-size': formular.verbleRuecksAbw1.size + 'px', 
														'text-align': formular.verbleRuecksAbw1.schriftausrichtung, 
														'letter-spacing': formular.verbleRuecksAbw1.letterSpacing + 'px',
														'font-weight': formular.verbleRuecksAbw1.schriftart }" 
												/>

<b-popover 
												v-if="formular.verbleRuecksAbw2"
												:show="formular.verbleRuecksAbw2.showPop==true"
												
												ref='verbleRuecksAbw2' 
												target='verbleRuecksAbw2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.verbleRuecksAbw2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.verbleRuecksAbw2.size}}</span>
												<b-button @click='plus("verbleRuecksAbw2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("verbleRuecksAbw2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="verbleRuecksAbw2" type="text" 
												v-if="formular.verbleRuecksAbw2!=undefined" 
												class='textfeld' v-model='formular.verbleRuecksAbw2.text' 
												style='width:100%' 
												@focus='formular.verbleRuecksAbw2.showPop=true'
												@blur ='formular.verbleRuecksAbw2.showPop=false'
												:disabled='formular.verbleRuecksAbw2.isDisabled==true || formular.verbleRuecksAbw2.isDisabled==true' 
												@keypress="isNumber($event, formular.verbleRuecksAbw2.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(233.947404) + 'px', 
		
														'font-size': formular.verbleRuecksAbw2.size + 'px', 
														'text-align': formular.verbleRuecksAbw2.schriftausrichtung, 
														'letter-spacing': formular.verbleRuecksAbw2.letterSpacing + 'px',
														'font-weight': formular.verbleRuecksAbw2.schriftart }" 
												/>

<b-popover 
												v-if="formular.verbleRuecksAbw3"
												:show="formular.verbleRuecksAbw3.showPop==true"
												
												ref='verbleRuecksAbw3' 
												target='verbleRuecksAbw3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.verbleRuecksAbw3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.verbleRuecksAbw3.size}}</span>
												<b-button @click='plus("verbleRuecksAbw3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("verbleRuecksAbw3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="verbleRuecksAbw3" type="text" 
												v-if="formular.verbleRuecksAbw3!=undefined" 
												class='textfeld' v-model='formular.verbleRuecksAbw3.text' 
												style='width:100%' 
												@focus='formular.verbleRuecksAbw3.showPop=true'
												@blur ='formular.verbleRuecksAbw3.showPop=false'
												:disabled='formular.verbleRuecksAbw3.isDisabled==true || formular.verbleRuecksAbw3.isDisabled==true' 
												@keypress="isNumber($event, formular.verbleRuecksAbw3.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(241.396428) + 'px', 
		
														'font-size': formular.verbleRuecksAbw3.size + 'px', 
														'text-align': formular.verbleRuecksAbw3.schriftausrichtung, 
														'letter-spacing': formular.verbleRuecksAbw3.letterSpacing + 'px',
														'font-weight': formular.verbleRuecksAbw3.schriftart }" 
												/>

<b-popover 
												v-if="formular.verbleRuecksAbw4"
												:show="formular.verbleRuecksAbw4.showPop==true"
												
												ref='verbleRuecksAbw4' 
												target='verbleRuecksAbw4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.verbleRuecksAbw4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.verbleRuecksAbw4.size}}</span>
												<b-button @click='plus("verbleRuecksAbw4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("verbleRuecksAbw4", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="verbleRuecksAbw4" type="text" 
												v-if="formular.verbleRuecksAbw4!=undefined" 
												class='textfeld' v-model='formular.verbleRuecksAbw4.text' 
												style='width:100%' 
												@focus='formular.verbleRuecksAbw4.showPop=true'
												@blur ='formular.verbleRuecksAbw4.showPop=false'
												:disabled='formular.verbleRuecksAbw4.isDisabled==true || formular.verbleRuecksAbw4.isDisabled==true' 
												@keypress="isNumber($event, formular.verbleRuecksAbw4.onylNumber) " 
												:style="{'width': spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(248.549184) + 'px', 
		
														'font-size': formular.verbleRuecksAbw4.size + 'px', 
														'text-align': formular.verbleRuecksAbw4.schriftausrichtung, 
														'letter-spacing': formular.verbleRuecksAbw4.letterSpacing + 'px',
														'font-weight': formular.verbleRuecksAbw4.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.7762526) + 'px', 
														top: gib_hoehe_in_mm(255.0335614) + 'px'}">
													Fortsetzung Blatt 4
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(183.5595407) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(183.5595407) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(27.5925739) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(34.8384427) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.1604947) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(49.4825467) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(56.8045987) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.2112987) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(71.4487027) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(78.7707547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(86.1774547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.4571827) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(100.7901684) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.0279251) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.3492717) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(122.7566771) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(129.9940811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(137.3154277) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(144.6378324) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(151.9602371) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(159.3655263) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(166.6960431) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(173.8484464) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(181.2978231) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(188.4502264) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(195.7726311) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(203.0939777) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(210.4163824) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(217.7387871) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(153.4181514) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(225.0601337) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(232.3934721) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(239.7148187) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(114.3456927) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(247.0372234) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(183.5595407) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(254.5268079) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(174.0207692) + 'px', 
													width: spalte_breit_mm(29.8874453) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(260.2405479) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(204.1621585) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(241.5076312) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.6026178) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(235.7938912) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.0623715) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(124.5406721) + 'px', 
													top: gib_hoehe_in_mm(27.519565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(174.0207692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(242.8902152) + 'px', 
													top: gib_hoehe_in_mm(17.4503327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6750765) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(235.7956547) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(55.1574133) + 'px'}">
													mithelfende Familienangehörige
													</div>
<b-popover 
												v-if="formular.AK1_4"
												:show="formular.AK1_4.showPop==true"
												
												ref='AK1_4' 
												target='AK1_4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_4.size}}</span>
												<b-button @click='plus("AK1_4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_4" 
																				v-bind:precision="parseInt(formular.AK1_4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_4!=undefined" 
												class='textfeld' v-model='formular.AK1_4.text' 
												style='width:100%' 
												@focus='formular.AK1_4.showPop=true'
												@blur ='formular.AK1_4.showPop=false'
												:disabled='formular.AK1_4.isDisabled==true || formular.AK1_4.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_4.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': formular.AK1_4.size + 'px', 
														'text-align': formular.AK1_4.schriftausrichtung, 
														'letter-spacing': formular.AK1_4.letterSpacing + 'px',
														'font-weight': formular.AK1_4.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK1_4_Prozent"
												:show="formular.AK1_4_Prozent.showPop==true"
												
												ref='AK1_4_Prozent' 
												target='AK1_4_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_4_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_4_Prozent.size}}</span>
												<b-button @click='plus("AK1_4_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_4_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_4_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_4_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_4_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_4_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_4_Prozent.showPop=true'
												@blur ='formular.AK1_4_Prozent.showPop=false'
												:disabled='formular.AK1_4_Prozent.isDisabled==true || formular.AK1_4_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_4_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': formular.AK1_4_Prozent.size + 'px', 
														'text-align': formular.AK1_4_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_4_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_4_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_4"
												:show="formular.AK2_4.showPop==true"
												
												ref='AK2_4' 
												target='AK2_4'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_4.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_4.size}}</span>
												<b-button @click='plus("AK2_4", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_4", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_4" 
																				v-bind:precision="parseInt(formular.AK2_4.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_4!=undefined" 
												class='textfeld' v-model='formular.AK2_4.text' 
												style='width:100%' 
												@focus='formular.AK2_4.showPop=true'
												@blur ='formular.AK2_4.showPop=false'
												:disabled='formular.AK2_4.isDisabled==true || formular.AK2_4.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_4.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': formular.AK2_4.size + 'px', 
														'text-align': formular.AK2_4.schriftausrichtung, 
														'letter-spacing': formular.AK2_4.letterSpacing + 'px',
														'font-weight': formular.AK2_4.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_4_Prozent"
												:show="formular.AK2_4_Prozent.showPop==true"
												
												ref='AK2_4_Prozent' 
												target='AK2_4_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_4_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_4_Prozent.size}}</span>
												<b-button @click='plus("AK2_4_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_4_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_4_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_4_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_4_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_4_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_4_Prozent.showPop=true'
												@blur ='formular.AK2_4_Prozent.showPop=false'
												:disabled='formular.AK2_4_Prozent.isDisabled==true || formular.AK2_4_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_4_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': formular.AK2_4_Prozent.size + 'px', 
														'text-align': formular.AK2_4_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_4_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_4_Prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.1832521) + 'px', 
														top: gib_hoehe_in_mm(55.5552589) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(55.5552589) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(151.4084668) + 'px', 
														top: gib_hoehe_in_mm(55.5552589) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4910691) + 'px', 
														top: gib_hoehe_in_mm(19.0091903) + 'px'}">
													Betriebsbeschreibung Blatt 4
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(60.8594431) + 'px', 
														top: gib_hoehe_in_mm(19.0229456) + 'px'}">
													Bauherrschaft
													</div>
<b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name" type="text" 
												v-if="formular.bauherr_name!=undefined" 
												class='textfeld' v-model='formular.bauherr_name.text' 
												style='width:100%' 
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												:disabled='formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_name.onylNumber) " 
												:style="{'width': spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': formular.bauherr_name.size + 'px', 
														'text-align': formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8454371) + 'px', 
														top: gib_hoehe_in_mm(27.8873547) + 'px'}">
													6 Arbeitskräfte
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(54.4685191) + 'px', 
														top: gib_hoehe_in_mm(27.8873547) + 'px'}">
													Ausbildung als
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(35.3494286) + 'px'}">
													Betriebsleiter(in)
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(41.9523253) + 'px'}">
													Ehegatte
													</div>
<b-popover 
												v-if="formular.betriebsleiter"
												:show="formular.betriebsleiter.showPop==true"
												
												ref='betriebsleiter' 
												target='betriebsleiter'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebsleiter.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebsleiter.size}}</span>
												<b-button @click='plus("betriebsleiter", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebsleiter", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebsleiter" type="text" 
												v-if="formular.betriebsleiter!=undefined" 
												class='textfeld' v-model='formular.betriebsleiter.text' 
												style='width:100%' 
												@focus='formular.betriebsleiter.showPop=true'
												@blur ='formular.betriebsleiter.showPop=false'
												:disabled='formular.betriebsleiter.isDisabled==true || formular.betriebsleiter.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebsleiter.onylNumber) " 
												:style="{'width': spalte_breit_mm(26.5390401) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(54.287167) + 'px', 
														'height': 24.5 + 'px', 
														'top': gib_hoehe_in_mm(34.9868651) + 'px', 
		
														'font-size': formular.betriebsleiter.size + 'px', 
														'text-align': formular.betriebsleiter.schriftausrichtung, 
														'letter-spacing': formular.betriebsleiter.letterSpacing + 'px',
														'font-weight': formular.betriebsleiter.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebsleiterEhegatte"
												:show="formular.betriebsleiterEhegatte.showPop==true"
												
												ref='betriebsleiterEhegatte' 
												target='betriebsleiterEhegatte'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebsleiterEhegatte.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebsleiterEhegatte.size}}</span>
												<b-button @click='plus("betriebsleiterEhegatte", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebsleiterEhegatte", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebsleiterEhegatte" type="text" 
												v-if="formular.betriebsleiterEhegatte!=undefined" 
												class='textfeld' v-model='formular.betriebsleiterEhegatte.text' 
												style='width:100%' 
												@focus='formular.betriebsleiterEhegatte.showPop=true'
												@blur ='formular.betriebsleiterEhegatte.showPop=false'
												:disabled='formular.betriebsleiterEhegatte.isDisabled==true || formular.betriebsleiterEhegatte.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebsleiterEhegatte.onylNumber) " 
												:style="{'width': spalte_breit_mm(26.5390401) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(54.287167) + 'px', 
														'height': 24.5 + 'px', 
														'top': gib_hoehe_in_mm(41.5823551) + 'px', 
		
														'font-size': formular.betriebsleiterEhegatte.size + 'px', 
														'text-align': formular.betriebsleiterEhegatte.schriftausrichtung, 
														'letter-spacing': formular.betriebsleiterEhegatte.letterSpacing + 'px',
														'font-weight': formular.betriebsleiterEhegatte.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4294215) + 'px', 
														top: gib_hoehe_in_mm(48.9527149) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.5983648) + 'px', 
														top: gib_hoehe_in_mm(55.5552589) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4294215) + 'px', 
														top: gib_hoehe_in_mm(62.2001269) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4294215) + 'px', 
														top: gib_hoehe_in_mm(68.8033763) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4294215) + 'px', 
														top: gib_hoehe_in_mm(75.4052149) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4294215) + 'px', 
														top: gib_hoehe_in_mm(84.9707916) + 'px'}">
													Anzahl
													</div>
<b-popover 
												v-if="formular.fremdarbeiten1"
												:show="formular.fremdarbeiten1.showPop==true"
												
												ref='fremdarbeiten1' 
												target='fremdarbeiten1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fremdarbeiten1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fremdarbeiten1.size}}</span>
												<b-button @click='plus("fremdarbeiten1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fremdarbeiten1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="fremdarbeiten1" type="text" 
												v-if="formular.fremdarbeiten1!=undefined" 
												class='textfeld' v-model='formular.fremdarbeiten1.text' 
												style='width:100%' 
												@focus='formular.fremdarbeiten1.showPop=true'
												@blur ='formular.fremdarbeiten1.showPop=false'
												:disabled='formular.fremdarbeiten1.isDisabled==true || formular.fremdarbeiten1.isDisabled==true' 
												@keypress="isNumber($event, formular.fremdarbeiten1.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.9903138) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(82.8121322) + 'px', 
														'height': 27.5 + 'px', 
														'top': gib_hoehe_in_mm(100.4783173) + 'px', 
		
														'font-size': formular.fremdarbeiten1.size + 'px', 
														'text-align': formular.fremdarbeiten1.schriftausrichtung, 
														'letter-spacing': formular.fremdarbeiten1.letterSpacing + 'px',
														'font-weight': formular.fremdarbeiten1.schriftart }" 
												/>

<b-popover 
												v-if="formular.fremdarbeiten2"
												:show="formular.fremdarbeiten2.showPop==true"
												
												ref='fremdarbeiten2' 
												target='fremdarbeiten2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fremdarbeiten2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fremdarbeiten2.size}}</span>
												<b-button @click='plus("fremdarbeiten2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fremdarbeiten2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="fremdarbeiten2" type="text" 
												v-if="formular.fremdarbeiten2!=undefined" 
												class='textfeld' v-model='formular.fremdarbeiten2.text' 
												style='width:100%' 
												@focus='formular.fremdarbeiten2.showPop=true'
												@blur ='formular.fremdarbeiten2.showPop=false'
												:disabled='formular.fremdarbeiten2.isDisabled==true || formular.fremdarbeiten2.isDisabled==true' 
												@keypress="isNumber($event, formular.fremdarbeiten2.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.9903138) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(82.8121322) + 'px', 
														'height': 27.5 + 'px', 
														'top': gib_hoehe_in_mm(107.3647848) + 'px', 
		
														'font-size': formular.fremdarbeiten2.size + 'px', 
														'text-align': formular.fremdarbeiten2.schriftausrichtung, 
														'letter-spacing': formular.fremdarbeiten2.letterSpacing + 'px',
														'font-weight': formular.fremdarbeiten2.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(68.4048253) + 'px'}">
													ständige Arbeitnehmer
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(76.4883566) + 'px'}">
													Teilzeitkräfte
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(103.3746776) + 'px'}">
													Arbeiten, die fremd vergeben werden
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(107.3111623) + 'px'}">
													(z. B. Lohnarbeit)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(103.7449415) + 'px', 
														top: gib_hoehe_in_mm(28.564186) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.1832521) + 'px', 
														top: gib_hoehe_in_mm(35.7476269) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.1832521) + 'px', 
														top: gib_hoehe_in_mm(42.3508763) + 'px'}">
													Anteil je
													</div>
<b-popover 
												v-if="formular.AK1_1_Prozent"
												:show="formular.AK1_1_Prozent.showPop==true"
												
												ref='AK1_1_Prozent' 
												target='AK1_1_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_1_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_1_Prozent.size}}</span>
												<b-button @click='plus("AK1_1_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_1_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_1_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_1_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_1_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_1_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_1_Prozent.showPop=true'
												@blur ='formular.AK1_1_Prozent.showPop=false'
												:disabled='formular.AK1_1_Prozent.isDisabled==true || formular.AK1_1_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_1_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(35.9567901) + 'px', 
		
														'font-size': formular.AK1_1_Prozent.size + 'px', 
														'text-align': formular.AK1_1_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_1_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_1_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_1_Prozent"
												:show="formular.AK2_1_Prozent.showPop==true"
												
												ref='AK2_1_Prozent' 
												target='AK2_1_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_1_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_1_Prozent.size}}</span>
												<b-button @click='plus("AK2_1_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_1_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_1_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_1_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_1_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_1_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_1_Prozent.showPop=true'
												@blur ='formular.AK2_1_Prozent.showPop=false'
												:disabled='formular.AK2_1_Prozent.isDisabled==true || formular.AK2_1_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_1_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(35.9567901) + 'px', 
		
														'font-size': formular.AK2_1_Prozent.size + 'px', 
														'text-align': formular.AK2_1_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_1_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_1_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK1_2_Prozent"
												:show="formular.AK1_2_Prozent.showPop==true"
												
												ref='AK1_2_Prozent' 
												target='AK1_2_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_2_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_2_Prozent.size}}</span>
												<b-button @click='plus("AK1_2_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_2_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_2_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_2_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_2_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_2_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_2_Prozent.showPop=true'
												@blur ='formular.AK1_2_Prozent.showPop=false'
												:disabled='formular.AK1_2_Prozent.isDisabled==true || formular.AK1_2_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_2_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(42.5522801) + 'px', 
		
														'font-size': formular.AK1_2_Prozent.size + 'px', 
														'text-align': formular.AK1_2_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_2_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_2_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_2_Prozent"
												:show="formular.AK2_2_Prozent.showPop==true"
												
												ref='AK2_2_Prozent' 
												target='AK2_2_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_2_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_2_Prozent.size}}</span>
												<b-button @click='plus("AK2_2_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_2_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_2_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_2_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_2_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_2_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_2_Prozent.showPop=true'
												@blur ='formular.AK2_2_Prozent.showPop=false'
												:disabled='formular.AK2_2_Prozent.isDisabled==true || formular.AK2_2_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_2_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(42.5522801) + 'px', 
		
														'font-size': formular.AK2_2_Prozent.size + 'px', 
														'text-align': formular.AK2_2_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_2_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_2_Prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(148.9117035) + 'px', 
														top: gib_hoehe_in_mm(28.564186) + 'px'}">
													Ziel
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(151.4084668) + 'px', 
														top: gib_hoehe_in_mm(35.7476269) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(151.4084668) + 'px', 
														top: gib_hoehe_in_mm(42.3508763) + 'px'}">
													Anteil je
													</div>
<b-popover 
												v-if="formular.AK1_3"
												:show="formular.AK1_3.showPop==true"
												
												ref='AK1_3' 
												target='AK1_3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_3.size}}</span>
												<b-button @click='plus("AK1_3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_3" 
																				v-bind:precision="parseInt(formular.AK1_3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_3!=undefined" 
												class='textfeld' v-model='formular.AK1_3.text' 
												style='width:100%' 
												@focus='formular.AK1_3.showPop=true'
												@blur ='formular.AK1_3.showPop=false'
												:disabled='formular.AK1_3.isDisabled==true || formular.AK1_3.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_3.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': formular.AK1_3.size + 'px', 
														'text-align': formular.AK1_3.schriftausrichtung, 
														'letter-spacing': formular.AK1_3.letterSpacing + 'px',
														'font-weight': formular.AK1_3.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK1_3_Prozent"
												:show="formular.AK1_3_Prozent.showPop==true"
												
												ref='AK1_3_Prozent' 
												target='AK1_3_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_3_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_3_Prozent.size}}</span>
												<b-button @click='plus("AK1_3_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_3_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_3_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_3_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_3_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_3_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_3_Prozent.showPop=true'
												@blur ='formular.AK1_3_Prozent.showPop=false'
												:disabled='formular.AK1_3_Prozent.isDisabled==true || formular.AK1_3_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_3_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': formular.AK1_3_Prozent.size + 'px', 
														'text-align': formular.AK1_3_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_3_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_3_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_3"
												:show="formular.AK2_3.showPop==true"
												
												ref='AK2_3' 
												target='AK2_3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_3.size}}</span>
												<b-button @click='plus("AK2_3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_3", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_3" 
																				v-bind:precision="parseInt(formular.AK2_3.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_3!=undefined" 
												class='textfeld' v-model='formular.AK2_3.text' 
												style='width:100%' 
												@focus='formular.AK2_3.showPop=true'
												@blur ='formular.AK2_3.showPop=false'
												:disabled='formular.AK2_3.isDisabled==true || formular.AK2_3.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_3.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': formular.AK2_3.size + 'px', 
														'text-align': formular.AK2_3.schriftausrichtung, 
														'letter-spacing': formular.AK2_3.letterSpacing + 'px',
														'font-weight': formular.AK2_3.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_3_Prozent"
												:show="formular.AK2_3_Prozent.showPop==true"
												
												ref='AK2_3_Prozent' 
												target='AK2_3_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_3_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_3_Prozent.size}}</span>
												<b-button @click='plus("AK2_3_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_3_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_3_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_3_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_3_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_3_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_3_Prozent.showPop=true'
												@blur ='formular.AK2_3_Prozent.showPop=false'
												:disabled='formular.AK2_3_Prozent.isDisabled==true || formular.AK2_3_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_3_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': formular.AK2_3_Prozent.size + 'px', 
														'text-align': formular.AK2_3_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_3_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_3_Prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.1832521) + 'px', 
														top: gib_hoehe_in_mm(48.9527149) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(48.9527149) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(151.4084668) + 'px', 
														top: gib_hoehe_in_mm(48.9527149) + 'px'}">
													Anteil je
													</div>
<b-popover 
												v-if="formular.AK1_5"
												:show="formular.AK1_5.showPop==true"
												
												ref='AK1_5' 
												target='AK1_5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_5.size}}</span>
												<b-button @click='plus("AK1_5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_5" 
																				v-bind:precision="parseInt(formular.AK1_5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_5!=undefined" 
												class='textfeld' v-model='formular.AK1_5.text' 
												style='width:100%' 
												@focus='formular.AK1_5.showPop=true'
												@blur ='formular.AK1_5.showPop=false'
												:disabled='formular.AK1_5.isDisabled==true || formular.AK1_5.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_5.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': formular.AK1_5.size + 'px', 
														'text-align': formular.AK1_5.schriftausrichtung, 
														'letter-spacing': formular.AK1_5.letterSpacing + 'px',
														'font-weight': formular.AK1_5.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_5"
												:show="formular.AK2_5.showPop==true"
												
												ref='AK2_5' 
												target='AK2_5'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_5.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_5.size}}</span>
												<b-button @click='plus("AK2_5", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_5", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_5" 
																				v-bind:precision="parseInt(formular.AK2_5.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_5!=undefined" 
												class='textfeld' v-model='formular.AK2_5.text' 
												style='width:100%' 
												@focus='formular.AK2_5.showPop=true'
												@blur ='formular.AK2_5.showPop=false'
												:disabled='formular.AK2_5.isDisabled==true || formular.AK2_5.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_5.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': formular.AK2_5.size + 'px', 
														'text-align': formular.AK2_5.schriftausrichtung, 
														'letter-spacing': formular.AK2_5.letterSpacing + 'px',
														'font-weight': formular.AK2_5.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.1832521) + 'px', 
														top: gib_hoehe_in_mm(62.2001269) + 'px'}">
													Anteil je
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(62.2001269) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(151.4084668) + 'px', 
														top: gib_hoehe_in_mm(62.2001269) + 'px'}">
													Anteil je
													</div>
<b-popover 
												v-if="formular.AK1_5_Prozent"
												:show="formular.AK1_5_Prozent.showPop==true"
												
												ref='AK1_5_Prozent' 
												target='AK1_5_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_5_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_5_Prozent.size}}</span>
												<b-button @click='plus("AK1_5_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_5_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_5_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_5_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_5_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_5_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_5_Prozent.showPop=true'
												@blur ='formular.AK1_5_Prozent.showPop=false'
												:disabled='formular.AK1_5_Prozent.isDisabled==true || formular.AK1_5_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_5_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': formular.AK1_5_Prozent.size + 'px', 
														'text-align': formular.AK1_5_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_5_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_5_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_5_Prozent"
												:show="formular.AK2_5_Prozent.showPop==true"
												
												ref='AK2_5_Prozent' 
												target='AK2_5_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_5_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_5_Prozent.size}}</span>
												<b-button @click='plus("AK2_5_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_5_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_5_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_5_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_5_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_5_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_5_Prozent.showPop=true'
												@blur ='formular.AK2_5_Prozent.showPop=false'
												:disabled='formular.AK2_5_Prozent.isDisabled==true || formular.AK2_5_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_5_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': formular.AK2_5_Prozent.size + 'px', 
														'text-align': formular.AK2_5_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_5_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_5_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK1_6"
												:show="formular.AK1_6.showPop==true"
												
												ref='AK1_6' 
												target='AK1_6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_6.size}}</span>
												<b-button @click='plus("AK1_6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_6" 
																				v-bind:precision="parseInt(formular.AK1_6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_6!=undefined" 
												class='textfeld' v-model='formular.AK1_6.text' 
												style='width:100%' 
												@focus='formular.AK1_6.showPop=true'
												@blur ='formular.AK1_6.showPop=false'
												:disabled='formular.AK1_6.isDisabled==true || formular.AK1_6.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_6.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(69.0047801) + 'px', 
		
														'font-size': formular.AK1_6.size + 'px', 
														'text-align': formular.AK1_6.schriftausrichtung, 
														'letter-spacing': formular.AK1_6.letterSpacing + 'px',
														'font-weight': formular.AK1_6.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_6"
												:show="formular.AK2_6.showPop==true"
												
												ref='AK2_6' 
												target='AK2_6'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_6.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_6.size}}</span>
												<b-button @click='plus("AK2_6", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_6", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_6" 
																				v-bind:precision="parseInt(formular.AK2_6.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_6!=undefined" 
												class='textfeld' v-model='formular.AK2_6.text' 
												style='width:100%' 
												@focus='formular.AK2_6.showPop=true'
												@blur ='formular.AK2_6.showPop=false'
												:disabled='formular.AK2_6.isDisabled==true || formular.AK2_6.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_6.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(69.0047801) + 'px', 
		
														'font-size': formular.AK2_6.size + 'px', 
														'text-align': formular.AK2_6.schriftausrichtung, 
														'letter-spacing': formular.AK2_6.letterSpacing + 'px',
														'font-weight': formular.AK2_6.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK1_7"
												:show="formular.AK1_7.showPop==true"
												
												ref='AK1_7' 
												target='AK1_7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_7.size}}</span>
												<b-button @click='plus("AK1_7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_7" 
																				v-bind:precision="parseInt(formular.AK1_7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_7!=undefined" 
												class='textfeld' v-model='formular.AK1_7.text' 
												style='width:100%' 
												@focus='formular.AK1_7.showPop=true'
												@blur ='formular.AK1_7.showPop=false'
												:disabled='formular.AK1_7.isDisabled==true || formular.AK1_7.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_7.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(75.6365982) + 'px', 
		
														'font-size': formular.AK1_7.size + 'px', 
														'text-align': formular.AK1_7.schriftausrichtung, 
														'letter-spacing': formular.AK1_7.letterSpacing + 'px',
														'font-weight': formular.AK1_7.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_7"
												:show="formular.AK2_7.showPop==true"
												
												ref='AK2_7' 
												target='AK2_7'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_7.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_7.size}}</span>
												<b-button @click='plus("AK2_7", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_7", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_7" 
																				v-bind:precision="parseInt(formular.AK2_7.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_7!=undefined" 
												class='textfeld' v-model='formular.AK2_7.text' 
												style='width:100%' 
												@focus='formular.AK2_7.showPop=true'
												@blur ='formular.AK2_7.showPop=false'
												:disabled='formular.AK2_7.isDisabled==true || formular.AK2_7.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_7.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(75.6365982) + 'px', 
		
														'font-size': formular.AK2_7.size + 'px', 
														'text-align': formular.AK2_7.schriftausrichtung, 
														'letter-spacing': formular.AK2_7.letterSpacing + 'px',
														'font-weight': formular.AK2_7.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.6810735) + 'px', 
														top: gib_hoehe_in_mm(74.0938763) + 'px'}">
													Jahresarbeitsstd.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(153.6096675) + 'px', 
														top: gib_hoehe_in_mm(74.0938763) + 'px'}">
													Jahresarbeitsstd.
													</div>
<b-popover 
												v-if="formular.AK1_6_Prozent"
												:show="formular.AK1_6_Prozent.showPop==true"
												
												ref='AK1_6_Prozent' 
												target='AK1_6_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_6_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_6_Prozent.size}}</span>
												<b-button @click='plus("AK1_6_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_6_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_6_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_6_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_6_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_6_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_6_Prozent.showPop=true'
												@blur ='formular.AK1_6_Prozent.showPop=false'
												:disabled='formular.AK1_6_Prozent.isDisabled==true || formular.AK1_6_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_6_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(14.6876147) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.8112879) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(78.5700041) + 'px', 
		
														'font-size': formular.AK1_6_Prozent.size + 'px', 
														'text-align': formular.AK1_6_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_6_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_6_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_6_Prozent"
												:show="formular.AK2_6_Prozent.showPop==true"
												
												ref='AK2_6_Prozent' 
												target='AK2_6_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_6_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_6_Prozent.size}}</span>
												<b-button @click='plus("AK2_6_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_6_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_6_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_6_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_6_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_6_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_6_Prozent.showPop=true'
												@blur ='formular.AK2_6_Prozent.showPop=false'
												:disabled='formular.AK2_6_Prozent.isDisabled==true || formular.AK2_6_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_6_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(13.7917567) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.2111846) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(78.5700041) + 'px', 
		
														'font-size': formular.AK2_6_Prozent.size + 'px', 
														'text-align': formular.AK2_6_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_6_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_6_Prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(68.8033763) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(75.4052149) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.3045668) + 'px', 
														top: gib_hoehe_in_mm(84.9707916) + 'px'}">
													Anzahl
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(86.0535806) + 'px'}">
													nicht ständige Arbeitnehmer (z. B. Saison)
													</div>
<b-popover 
												v-if="formular.AK1_8"
												:show="formular.AK1_8.showPop==true"
												
												ref='AK1_8' 
												target='AK1_8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_8.size}}</span>
												<b-button @click='plus("AK1_8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_8" 
																				v-bind:precision="parseInt(formular.AK1_8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_8!=undefined" 
												class='textfeld' v-model='formular.AK1_8.text' 
												style='width:100%' 
												@focus='formular.AK1_8.showPop=true'
												@blur ='formular.AK1_8.showPop=false'
												:disabled='formular.AK1_8.isDisabled==true || formular.AK1_8.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK1_8.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(85.2018222) + 'px', 
		
														'font-size': formular.AK1_8.size + 'px', 
														'text-align': formular.AK1_8.schriftausrichtung, 
														'letter-spacing': formular.AK1_8.letterSpacing + 'px',
														'font-weight': formular.AK1_8.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_8"
												:show="formular.AK2_8.showPop==true"
												
												ref='AK2_8' 
												target='AK2_8'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_8.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_8.size}}</span>
												<b-button @click='plus("AK2_8", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_8", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_8" 
																				v-bind:precision="parseInt(formular.AK2_8.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_8!=undefined" 
												class='textfeld' v-model='formular.AK2_8.text' 
												style='width:100%' 
												@focus='formular.AK2_8.showPop=true'
												@blur ='formular.AK2_8.showPop=false'
												:disabled='formular.AK2_8.isDisabled==true || formular.AK2_8.isDisabled==true' @input="AK_neu_zaehlen"  
												@keypress="isNumber($event, formular.AK2_8.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(85.2018222) + 'px', 
		
														'font-size': formular.AK2_8.size + 'px', 
														'text-align': formular.AK2_8.schriftausrichtung, 
														'letter-spacing': formular.AK2_8.letterSpacing + 'px',
														'font-weight': formular.AK2_8.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.6810735) + 'px', 
														top: gib_hoehe_in_mm(83.659453) + 'px'}">
													Jahresarbeitsstd.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(153.6096675) + 'px', 
														top: gib_hoehe_in_mm(83.659453) + 'px'}">
													Jahresarbeitsstd.
													</div>
<b-popover 
												v-if="formular.AK1_7_Prozent"
												:show="formular.AK1_7_Prozent.showPop==true"
												
												ref='AK1_7_Prozent' 
												target='AK1_7_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_7_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_7_Prozent.size}}</span>
												<b-button @click='plus("AK1_7_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_7_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_7_Prozent" 
																				v-bind:precision="parseInt(formular.AK1_7_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_7_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK1_7_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK1_7_Prozent.showPop=true'
												@blur ='formular.AK1_7_Prozent.showPop=false'
												:disabled='formular.AK1_7_Prozent.isDisabled==true || formular.AK1_7_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_7_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(14.6876147) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(113.8112879) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(88.1352281) + 'px', 
		
														'font-size': formular.AK1_7_Prozent.size + 'px', 
														'text-align': formular.AK1_7_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK1_7_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK1_7_Prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.AK2_7_Prozent"
												:show="formular.AK2_7_Prozent.showPop==true"
												
												ref='AK2_7_Prozent' 
												target='AK2_7_Prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_7_Prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_7_Prozent.size}}</span>
												<b-button @click='plus("AK2_7_Prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_7_Prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_7_Prozent" 
																				v-bind:precision="parseInt(formular.AK2_7_Prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_7_Prozent!=undefined" 
												class='textfeld' v-model='formular.AK2_7_Prozent.text' 
												style='width:100%' 
												@focus='formular.AK2_7_Prozent.showPop=true'
												@blur ='formular.AK2_7_Prozent.showPop=false'
												:disabled='formular.AK2_7_Prozent.isDisabled==true || formular.AK2_7_Prozent.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_7_Prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(13.7917567) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.2111846) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(88.1352281) + 'px', 
		
														'font-size': formular.AK2_7_Prozent.size + 'px', 
														'text-align': formular.AK2_7_Prozent.schriftausrichtung, 
														'letter-spacing': formular.AK2_7_Prozent.letterSpacing + 'px',
														'font-weight': formular.AK2_7_Prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.4144746) + 'px', 
														top: gib_hoehe_in_mm(94.3917613) + 'px'}">
													Anzahl der Arbeitskräfte insgesamt
													</div>
<b-popover 
												v-if="formular.AK1_summe"
												:show="formular.AK1_summe.showPop==true"
												
												ref='AK1_summe' 
												target='AK1_summe'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK1_summe.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK1_summe.size}}</span>
												<b-button @click='plus("AK1_summe", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK1_summe", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK1_summe" 
																				v-bind:precision="parseInt(formular.AK1_summe.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK1_summe!=undefined" 
												class='textfeld' v-model='formular.AK1_summe.text' 
												style='width:100%' 
												@focus='formular.AK1_summe.showPop=true'
												@blur ='formular.AK1_summe.showPop=false'
												:disabled='formular.AK1_summe.isDisabled==true || formular.AK1_summe.isDisabled==true' 
												@keypress="isNumber($event, formular.AK1_summe.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(95.0142889) + 'px', 
		
														'font-size': formular.AK1_summe.size + 'px', 
														'text-align': formular.AK1_summe.schriftausrichtung, 
														'letter-spacing': formular.AK1_summe.letterSpacing + 'px',
														'font-weight': formular.AK1_summe.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.AKSelbstIst_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(105.5430061) + 'px',top: gib_hoehe_in_mm(94.7642889) + 'px'}"
								v-model="formular.AKSelbstIst_check.text" 
								value='1' 
								unchecked-value='0'> selbst eing.
								</b-form-checkbox>
<b-popover 
												v-if="formular.AK2_summe"
												:show="formular.AK2_summe.showPop==true"
												
												ref='AK2_summe' 
												target='AK2_summe'  
												
												>
												<template slot='title'>
													<b-button @click="formular.AK2_summe.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.AK2_summe.size}}</span>
												<b-button @click='plus("AK2_summe", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("AK2_summe", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="AK2_summe" 
																				v-bind:precision="parseInt(formular.AK2_summe.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.AK2_summe!=undefined" 
												class='textfeld' v-model='formular.AK2_summe.text' 
												style='width:100%' 
												@focus='formular.AK2_summe.showPop=true'
												@blur ='formular.AK2_summe.showPop=false'
												:disabled='formular.AK2_summe.isDisabled==true || formular.AK2_summe.isDisabled==true' 
												@keypress="isNumber($event, formular.AK2_summe.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(95.0142889) + 'px', 
		
														'font-size': formular.AK2_summe.size + 'px', 
														'text-align': formular.AK2_summe.schriftausrichtung, 
														'letter-spacing': formular.AK2_summe.letterSpacing + 'px',
														'font-weight': formular.AK2_summe.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.AKSelbstSoll_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(152.8795785) + 'px',top: gib_hoehe_in_mm(94.7642889) + 'px'}"
								v-model="formular.AKSelbstSoll_check.text" 
								value='1' 
								unchecked-value='0'> selbst eing.
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8454371) + 'px', 
														top: gib_hoehe_in_mm(119.9223035) + 'px'}">
													7 Betriebsform
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(126.2719616) + 'px'}">
													Vollerwerbsbetrieb
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(103.7449415) + 'px', 
														top: gib_hoehe_in_mm(120.3758757) + 'px'}">
													Ist
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(148.9117035) + 'px', 
														top: gib_hoehe_in_mm(120.3758757) + 'px'}">
													Ziel
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.vollerwerb_IST!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.4294215) + 'px',top: gib_hoehe_in_mm(127.1223977) + 'px'}"
								v-model="formular.vollerwerb_IST.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.vollerwerb_SOLL!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(131.0474485) + 'px',top: gib_hoehe_in_mm(127.1223977) + 'px'}"
								v-model="formular.vollerwerb_SOLL.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(132.8745056) + 'px'}">
													mit Zuerwerb aus
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(136.1338063) + 'px'}">
													(z. B. Fremdenzimmer, Lohnunterneh
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(139.3924016) + 'px'}">
													men, landwirtschaftliche Werkstätten,
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(142.6090256) + 'px'}">
													Handel mit Fremderzeugnissen)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.0481528) + 'px', 
														top: gib_hoehe_in_mm(132.8074926) + 'px'}">
													Art der Tätigkeit
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(130.6700595) + 'px', 
														top: gib_hoehe_in_mm(132.8074926) + 'px'}">
													Art der Tätigkeit
													</div>
<b-popover 
												v-if="formular.zuerwerbIST"
												:show="formular.zuerwerbIST.showPop==true"
												
												ref='zuerwerbIST' 
												target='zuerwerbIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerbIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerbIST.size}}</span>
												<b-button @click='plus("zuerwerbIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerbIST", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="zuerwerbIST" type="text" 
												v-if="formular.zuerwerbIST!=undefined" 
												class='textfeld' v-model='formular.zuerwerbIST.text' 
												style='width:100%' 
												@focus='formular.zuerwerbIST.showPop=true'
												@blur ='formular.zuerwerbIST.showPop=false'
												:disabled='formular.zuerwerbIST.isDisabled==true || formular.zuerwerbIST.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerbIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(136.7446948) + 'px', 
		
														'font-size': formular.zuerwerbIST.size + 'px', 
														'text-align': formular.zuerwerbIST.schriftausrichtung, 
														'letter-spacing': formular.zuerwerbIST.letterSpacing + 'px',
														'font-weight': formular.zuerwerbIST.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuerwerbSOLL"
												:show="formular.zuerwerbSOLL.showPop==true"
												
												ref='zuerwerbSOLL' 
												target='zuerwerbSOLL'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerbSOLL.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerbSOLL.size}}</span>
												<b-button @click='plus("zuerwerbSOLL", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerbSOLL", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="zuerwerbSOLL" type="text" 
												v-if="formular.zuerwerbSOLL!=undefined" 
												class='textfeld' v-model='formular.zuerwerbSOLL.text' 
												style='width:100%' 
												@focus='formular.zuerwerbSOLL.showPop=true'
												@blur ='formular.zuerwerbSOLL.showPop=false'
												:disabled='formular.zuerwerbSOLL.isDisabled==true || formular.zuerwerbSOLL.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerbSOLL.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(136.7446948) + 'px', 
		
														'font-size': formular.zuerwerbSOLL.size + 'px', 
														'text-align': formular.zuerwerbSOLL.schriftausrichtung, 
														'letter-spacing': formular.zuerwerbSOLL.letterSpacing + 'px',
														'font-weight': formular.zuerwerbSOLL.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuerwerbIST2"
												:show="formular.zuerwerbIST2.showPop==true"
												
												ref='zuerwerbIST2' 
												target='zuerwerbIST2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerbIST2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerbIST2.size}}</span>
												<b-button @click='plus("zuerwerbIST2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerbIST2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="zuerwerbIST2" type="text" 
												v-if="formular.zuerwerbIST2!=undefined" 
												class='textfeld' v-model='formular.zuerwerbIST2.text' 
												style='width:100%' 
												@focus='formular.zuerwerbIST2.showPop=true'
												@blur ='formular.zuerwerbIST2.showPop=false'
												:disabled='formular.zuerwerbIST2.isDisabled==true || formular.zuerwerbIST2.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerbIST2.onylNumber) " 
												:style="{'width': spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(143.7221589) + 'px', 
		
														'font-size': formular.zuerwerbIST2.size + 'px', 
														'text-align': formular.zuerwerbIST2.schriftausrichtung, 
														'letter-spacing': formular.zuerwerbIST2.letterSpacing + 'px',
														'font-weight': formular.zuerwerbIST2.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuerwerbSOLL2"
												:show="formular.zuerwerbSOLL2.showPop==true"
												
												ref='zuerwerbSOLL2' 
												target='zuerwerbSOLL2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerbSOLL2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerbSOLL2.size}}</span>
												<b-button @click='plus("zuerwerbSOLL2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerbSOLL2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="zuerwerbSOLL2" type="text" 
												v-if="formular.zuerwerbSOLL2!=undefined" 
												class='textfeld' v-model='formular.zuerwerbSOLL2.text' 
												style='width:100%' 
												@focus='formular.zuerwerbSOLL2.showPop=true'
												@blur ='formular.zuerwerbSOLL2.showPop=false'
												:disabled='formular.zuerwerbSOLL2.isDisabled==true || formular.zuerwerbSOLL2.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerbSOLL2.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(143.7221589) + 'px', 
		
														'font-size': formular.zuerwerbSOLL2.size + 'px', 
														'text-align': formular.zuerwerbSOLL2.schriftausrichtung, 
														'letter-spacing': formular.zuerwerbSOLL2.letterSpacing + 'px',
														'font-weight': formular.zuerwerbSOLL2.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.6684186) + 'px', 
														top: gib_hoehe_in_mm(149.7617816) + 'px'}">
													Anteil des Zuerwerbs am Gesamtbetrieb
													</div>
<b-popover 
												v-if="formular.zuerwerGesamtProzIST"
												:show="formular.zuerwerGesamtProzIST.showPop==true"
												
												ref='zuerwerGesamtProzIST' 
												target='zuerwerGesamtProzIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerGesamtProzIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerGesamtProzIST.size}}</span>
												<b-button @click='plus("zuerwerGesamtProzIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerGesamtProzIST", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="zuerwerGesamtProzIST" 
																				v-bind:precision="parseInt(formular.zuerwerGesamtProzIST.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.zuerwerGesamtProzIST!=undefined" 
												class='textfeld' v-model='formular.zuerwerGesamtProzIST.text' 
												style='width:100%' 
												@focus='formular.zuerwerGesamtProzIST.showPop=true'
												@blur ='formular.zuerwerGesamtProzIST.showPop=false'
												:disabled='formular.zuerwerGesamtProzIST.isDisabled==true || formular.zuerwerGesamtProzIST.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerGesamtProzIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4097164) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(117.907193) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.9902478) + 'px', 
		
														'font-size': formular.zuerwerGesamtProzIST.size + 'px', 
														'text-align': formular.zuerwerGesamtProzIST.schriftausrichtung, 
														'letter-spacing': formular.zuerwerGesamtProzIST.letterSpacing + 'px',
														'font-weight': formular.zuerwerGesamtProzIST.schriftart }" 
												/>

<b-popover 
												v-if="formular.zuerwerGesamtProzIST"
												:show="formular.zuerwerGesamtProzIST.showPop==true"
												
												ref='zuerwerGesamtProzIST' 
												target='zuerwerGesamtProzIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.zuerwerGesamtProzIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.zuerwerGesamtProzIST.size}}</span>
												<b-button @click='plus("zuerwerGesamtProzIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("zuerwerGesamtProzIST", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="zuerwerGesamtProzIST" 
																				v-bind:precision="parseInt(formular.zuerwerGesamtProzIST.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.zuerwerGesamtProzIST!=undefined" 
												class='textfeld' v-model='formular.zuerwerGesamtProzIST.text' 
												style='width:100%' 
												@focus='formular.zuerwerGesamtProzIST.showPop=true'
												@blur ='formular.zuerwerGesamtProzIST.showPop=false'
												:disabled='formular.zuerwerGesamtProzIST.isDisabled==true || formular.zuerwerGesamtProzIST.isDisabled==true' 
												@keypress="isNumber($event, formular.zuerwerGesamtProzIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4086583) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(162.4115844) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(150.9902478) + 'px', 
		
														'font-size': formular.zuerwerGesamtProzIST.size + 'px', 
														'text-align': formular.zuerwerGesamtProzIST.schriftausrichtung, 
														'letter-spacing': formular.zuerwerGesamtProzIST.letterSpacing + 'px',
														'font-weight': formular.zuerwerGesamtProzIST.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(156.4066496) + 'px'}">
													Nebenerwerbsbetrieb
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(159.6236263) + 'px'}">
													(ankreuzen ob IST oder ZIEL)
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(164.582941) + 'px'}">
													Art des Haupterwerbs
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nebenerwerb_IST!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.4294215) + 'px',top: gib_hoehe_in_mm(157.2468574) + 'px'}"
								v-model="formular.nebenerwerb_IST.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nebenerwerb_SOLL!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(131.0474485) + 'px',top: gib_hoehe_in_mm(157.2468574) + 'px'}"
								v-model="formular.nebenerwerb_SOLL.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-popover 
												v-if="formular.artHauptIST"
												:show="formular.artHauptIST.showPop==true"
												
												ref='artHauptIST' 
												target='artHauptIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.artHauptIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.artHauptIST.size}}</span>
												<b-button @click='plus("artHauptIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("artHauptIST", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="artHauptIST" type="text" 
												v-if="formular.artHauptIST!=undefined" 
												class='textfeld' v-model='formular.artHauptIST.text' 
												style='width:100%' 
												@focus='formular.artHauptIST.showPop=true'
												@blur ='formular.artHauptIST.showPop=false'
												:disabled='formular.artHauptIST.isDisabled==true || formular.artHauptIST.isDisabled==true' 
												@keypress="isNumber($event, formular.artHauptIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(165.4862178) + 'px', 
		
														'font-size': formular.artHauptIST.size + 'px', 
														'text-align': formular.artHauptIST.schriftausrichtung, 
														'letter-spacing': formular.artHauptIST.letterSpacing + 'px',
														'font-weight': formular.artHauptIST.schriftart }" 
												/>

<b-popover 
												v-if="formular.artHauptSOLL"
												:show="formular.artHauptSOLL.showPop==true"
												
												ref='artHauptSOLL' 
												target='artHauptSOLL'  
												
												>
												<template slot='title'>
													<b-button @click="formular.artHauptSOLL.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.artHauptSOLL.size}}</span>
												<b-button @click='plus("artHauptSOLL", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("artHauptSOLL", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="artHauptSOLL" type="text" 
												v-if="formular.artHauptSOLL!=undefined" 
												class='textfeld' v-model='formular.artHauptSOLL.text' 
												style='width:100%' 
												@focus='formular.artHauptSOLL.showPop=true'
												@blur ='formular.artHauptSOLL.showPop=false'
												:disabled='formular.artHauptSOLL.isDisabled==true || formular.artHauptSOLL.isDisabled==true' 
												@keypress="isNumber($event, formular.artHauptSOLL.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(165.4862178) + 'px', 
		
														'font-size': formular.artHauptSOLL.size + 'px', 
														'text-align': formular.artHauptSOLL.schriftausrichtung, 
														'letter-spacing': formular.artHauptSOLL.letterSpacing + 'px',
														'font-weight': formular.artHauptSOLL.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(171.185485) + 'px'}">
													Jahreseinkünfte aus Haupterwerb
													</div>
<b-popover 
												v-if="formular.einkueftHauptIST"
												:show="formular.einkueftHauptIST.showPop==true"
												
												ref='einkueftHauptIST' 
												target='einkueftHauptIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.einkueftHauptIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.einkueftHauptIST.size}}</span>
												<b-button @click='plus("einkueftHauptIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("einkueftHauptIST", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="einkueftHauptIST" 
																				v-bind:precision="parseInt(formular.einkueftHauptIST.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.einkueftHauptIST!=undefined" 
												class='textfeld' v-model='formular.einkueftHauptIST.text' 
												style='width:100%' 
												@focus='formular.einkueftHauptIST.showPop=true'
												@blur ='formular.einkueftHauptIST.showPop=false'
												:disabled='formular.einkueftHauptIST.isDisabled==true || formular.einkueftHauptIST.isDisabled==true' 
												@keypress="isNumber($event, formular.einkueftHauptIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(20.3082419) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(83.2981528) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(172.8724612) + 'px', 
		
														'font-size': formular.einkueftHauptIST.size + 'px', 
														'text-align': formular.einkueftHauptIST.schriftausrichtung, 
														'letter-spacing': formular.einkueftHauptIST.letterSpacing + 'px',
														'font-weight': formular.einkueftHauptIST.schriftart }" 
												/>

<b-popover 
												v-if="formular.einkueftHauptSOLL"
												:show="formular.einkueftHauptSOLL.showPop==true"
												
												ref='einkueftHauptSOLL' 
												target='einkueftHauptSOLL'  
												
												>
												<template slot='title'>
													<b-button @click="formular.einkueftHauptSOLL.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.einkueftHauptSOLL.size}}</span>
												<b-button @click='plus("einkueftHauptSOLL", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("einkueftHauptSOLL", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="einkueftHauptSOLL" 
																				v-bind:precision="parseInt(formular.einkueftHauptSOLL.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.einkueftHauptSOLL!=undefined" 
												class='textfeld' v-model='formular.einkueftHauptSOLL.text' 
												style='width:100%' 
												@focus='formular.einkueftHauptSOLL.showPop=true'
												@blur ='formular.einkueftHauptSOLL.showPop=false'
												:disabled='formular.einkueftHauptSOLL.isDisabled==true || formular.einkueftHauptSOLL.isDisabled==true' 
												@keypress="isNumber($event, formular.einkueftHauptSOLL.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.7463246) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9204122) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(172.8724612) + 'px', 
		
														'font-size': formular.einkueftHauptSOLL.size + 'px', 
														'text-align': formular.einkueftHauptSOLL.schriftausrichtung, 
														'letter-spacing': formular.einkueftHauptSOLL.letterSpacing + 'px',
														'font-weight': formular.einkueftHauptSOLL.schriftart }" 
												/>

<div :style="{
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(104.639036) + 'px', 
														top: gib_hoehe_in_mm(172.5193964) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(104.639036) + 'px', 
														top: gib_hoehe_in_mm(179.1173553) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.0457295) + 'px', 
														top: gib_hoehe_in_mm(172.5193964) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.0457295) + 'px', 
														top: gib_hoehe_in_mm(179.1173553) + 'px'}">
													EUR
													</div>
<b-popover 
												v-if="formular.einkueftNebenIST"
												:show="formular.einkueftNebenIST.showPop==true"
												
												ref='einkueftNebenIST' 
												target='einkueftNebenIST'  
												
												>
												<template slot='title'>
													<b-button @click="formular.einkueftNebenIST.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.einkueftNebenIST.size}}</span>
												<b-button @click='plus("einkueftNebenIST", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("einkueftNebenIST", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="einkueftNebenIST" 
																				v-bind:precision="parseInt(formular.einkueftNebenIST.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.einkueftNebenIST!=undefined" 
												class='textfeld' v-model='formular.einkueftNebenIST.text' 
												style='width:100%' 
												@focus='formular.einkueftNebenIST.showPop=true'
												@blur ='formular.einkueftNebenIST.showPop=false'
												:disabled='formular.einkueftNebenIST.isDisabled==true || formular.einkueftNebenIST.isDisabled==true' 
												@keypress="isNumber($event, formular.einkueftNebenIST.onylNumber) " 
												:style="{'width': spalte_breit_mm(20.3082419) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(83.2981528) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(179.2372854) + 'px', 
		
														'font-size': formular.einkueftNebenIST.size + 'px', 
														'text-align': formular.einkueftNebenIST.schriftausrichtung, 
														'letter-spacing': formular.einkueftNebenIST.letterSpacing + 'px',
														'font-weight': formular.einkueftNebenIST.schriftart }" 
												/>

<b-popover 
												v-if="formular.einkueftNebenSOLL"
												:show="formular.einkueftNebenSOLL.showPop==true"
												
												ref='einkueftNebenSOLL' 
												target='einkueftNebenSOLL'  
												
												>
												<template slot='title'>
													<b-button @click="formular.einkueftNebenSOLL.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.einkueftNebenSOLL.size}}</span>
												<b-button @click='plus("einkueftNebenSOLL", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("einkueftNebenSOLL", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="einkueftNebenSOLL" 
																				v-bind:precision="parseInt(formular.einkueftNebenSOLL.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.einkueftNebenSOLL!=undefined" 
												class='textfeld' v-model='formular.einkueftNebenSOLL.text' 
												style='width:100%' 
												@focus='formular.einkueftNebenSOLL.showPop=true'
												@blur ='formular.einkueftNebenSOLL.showPop=false'
												:disabled='formular.einkueftNebenSOLL.isDisabled==true || formular.einkueftNebenSOLL.isDisabled==true' 
												@keypress="isNumber($event, formular.einkueftNebenSOLL.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.7463246) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(130.9204122) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(179.2372854) + 'px', 
		
														'font-size': formular.einkueftNebenSOLL.size + 'px', 
														'text-align': formular.einkueftNebenSOLL.schriftausrichtung, 
														'letter-spacing': formular.einkueftNebenSOLL.letterSpacing + 'px',
														'font-weight': formular.einkueftNebenSOLL.schriftart }" 
												/>

<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(177.788029) + 'px'}">
													Jahreseinkünfte aus
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(181.004653) + 'px'}">
													Nebenerwerbsbetrieb
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(185.9562083) + 'px'}">
													Wirtschaftlichkeitsrechnung
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wibebeig!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.4294215) + 'px',top: gib_hoehe_in_mm(186.795358) + 'px'}"
								v-model="formular.wibebeig.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wibenbeig!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(112.0016485) + 'px',top: gib_hoehe_in_mm(186.795358) + 'px'}"
								v-model="formular.wibenbeig.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-popover 
												v-if="formular.wiBeText"
												:show="formular.wiBeText.showPop==true"
												
												ref='wiBeText' 
												target='wiBeText'  
												
												>
												<template slot='title'>
													<b-button @click="formular.wiBeText.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.wiBeText.size}}</span>
												<b-button @click='plus("wiBeText", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("wiBeText", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="wiBeText" type="text" 
												v-if="formular.wiBeText!=undefined" 
												class='textfeld' v-model='formular.wiBeText.text' 
												style='width:100%' 
												@focus='formular.wiBeText.showPop=true'
												@blur ='formular.wiBeText.showPop=false'
												:disabled='formular.wiBeText.isDisabled==true || formular.wiBeText.isDisabled==true' 
												@keypress="isNumber($event, formular.wiBeText.onylNumber) " 
												:style="{'width': spalte_breit_mm(29.1744145) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(144.01052) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(187.1455248) + 'px', 
		
														'font-size': formular.wiBeText.size + 'px', 
														'text-align': formular.wiBeText.schriftausrichtung, 
														'letter-spacing': formular.wiBeText.letterSpacing + 'px',
														'font-weight': formular.wiBeText.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(87.6618215) + 'px', 
														top: gib_hoehe_in_mm(186.905324) + 'px'}">
													beigefügt
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(116.2372228) + 'px', 
														top: gib_hoehe_in_mm(186.905324) + 'px'}">
													nicht beigefügt
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(192.5587523) + 'px'}">
													Fortbestand des Betriebes gesichert
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.fortbestandErb!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.4294215) + 'px',top: gib_hoehe_in_mm(193.397902) + 'px'}"
								v-model="formular.fortbestandErb.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(87.6618215) + 'px', 
														top: gib_hoehe_in_mm(193.507868) + 'px'}">
													durch Erbfolge
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.fortbestandX!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(112.0016485) + 'px',top: gib_hoehe_in_mm(193.6980497) + 'px'}"
								v-model="formular.fortbestandX.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-popover 
												v-if="formular.fortbestandText"
												:show="formular.fortbestandText.showPop==true"
												
												ref='fortbestandText' 
												target='fortbestandText'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fortbestandText.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fortbestandText.size}}</span>
												<b-button @click='plus("fortbestandText", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fortbestandText", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="fortbestandText" type="text" 
												v-if="formular.fortbestandText!=undefined" 
												class='textfeld' v-model='formular.fortbestandText.text' 
												style='width:100%' 
												@focus='formular.fortbestandText.showPop=true'
												@blur ='formular.fortbestandText.showPop=false'
												:disabled='formular.fortbestandText.isDisabled==true || formular.fortbestandText.isDisabled==true' 
												@keypress="isNumber($event, formular.fortbestandText.onylNumber) " 
												:style="{'width': spalte_breit_mm(50.0168683) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(123.1680662) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(193.4560332) + 'px', 
		
														'font-size': formular.fortbestandText.size + 'px', 
														'text-align': formular.fortbestandText.schriftausrichtung, 
														'letter-spacing': formular.fortbestandText.letterSpacing + 'px',
														'font-weight': formular.fortbestandText.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(116.2372228) + 'px', 
														top: gib_hoehe_in_mm(193.804136) + 'px'}">
													durch
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(199.1612963) + 'px'}">
													Betriebsnachfolger, Name und
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.5834179) + 'px', 
														top: gib_hoehe_in_mm(202.4202443) + 'px'}">
													Ausbildung als
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nachfolgerAusbil!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.4294215) + 'px',top: gib_hoehe_in_mm(201.6133431) + 'px'}"
								v-model="formular.nachfolgerAusbil.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(87.6618215) + 'px', 
														top: gib_hoehe_in_mm(201.718724) + 'px'}">
													Ausbildung ist
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(88.1697095) + 'px', 
														top: gib_hoehe_in_mm(204.3847833) + 'px'}">
													abgeschlossen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(111.6172055) + 'px', 
														top: gib_hoehe_in_mm(199.094636) + 'px'}">
													Ausbildung wird abgeschlossen am
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nachfolgerAusbilAm!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(112.0016485) + 'px',top: gib_hoehe_in_mm(204.1485507) + 'px'}"
								v-model="formular.nachfolgerAusbilAm.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-popover 
												v-if="formular.nachfolgerAusbilText"
												:show="formular.nachfolgerAusbilText.showPop==true"
												
												ref='nachfolgerAusbilText' 
												target='nachfolgerAusbilText'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nachfolgerAusbilText.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nachfolgerAusbilText.size}}</span>
												<b-button @click='plus("nachfolgerAusbilText", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nachfolgerAusbilText", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="nachfolgerAusbilText" type="text" 
												v-if="formular.nachfolgerAusbilText!=undefined" 
												class='textfeld' v-model='formular.nachfolgerAusbilText.text' 
												style='width:100%' 
												@focus='formular.nachfolgerAusbilText.showPop=true'
												@blur ='formular.nachfolgerAusbilText.showPop=false'
												:disabled='formular.nachfolgerAusbilText.isDisabled==true || formular.nachfolgerAusbilText.isDisabled==true' 
												@keypress="isNumber($event, formular.nachfolgerAusbilText.onylNumber) " 
												:style="{'width': spalte_breit_mm(50.0168683) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(123.1680662) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(203.4540201) + 'px', 
		
														'font-size': formular.nachfolgerAusbilText.size + 'px', 
														'text-align': formular.nachfolgerAusbilText.schriftausrichtung, 
														'letter-spacing': formular.nachfolgerAusbilText.letterSpacing + 'px',
														'font-weight': formular.nachfolgerAusbilText.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.8454371) + 'px', 
														top: gib_hoehe_in_mm(214.3118775) + 'px'}">
													8 Sonstiges
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.0335586) + 'px', 
														top: gib_hoehe_in_mm(225.1235433) + 'px'}">
													Angaben und Hinweise, die zur
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.0335586) + 'px', 
														top: gib_hoehe_in_mm(227.5360113) + 'px'}">
													Beurteilung des Vorhabens
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.0335586) + 'px', 
														top: gib_hoehe_in_mm(230.0013843) + 'px'}">
													notwendig sind
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(109.8453693) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(63.295542) + 'px',
										
										 top: gib_hoehe_in_mm(220.6919442) + 'px'}"><b-popover 
												v-if="formular.sonstiges1"
												:show="formular.sonstiges1.showPop==true"
												
												ref='sonstiges1' 
												target='sonstiges1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstiges1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstiges1.size}}</span>
												<b-button @click='plus("sonstiges1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstiges1", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.sonstiges1!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.sonstiges1.text"
												
												@focus='formular.sonstiges1.showPop=true'
												@blur ='formular.sonstiges1.showPop=false'
												
												:disabled="formular.sonstiges1.isDisabled==true || formular.sonstiges1.isDisabled=='true'"
												:style="{'font-size': formular.sonstiges1.size + 'px',
														'height': formular.sonstiges1.height + 'px',
														'text-align': formular.sonstiges1.schriftausrichtung + 'px',
														'letter-spacing': formular.sonstiges1.letterSpacing + 'px',
														'font-weight': formular.sonstiges1.schriftart + 'px', }"

												id='sonstiges1'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(109.8453693) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(63.295542) + 'px',
										
										 top: gib_hoehe_in_mm(229.7461059) + 'px'}"><b-popover 
												v-if="formular.sonstiges2"
												:show="formular.sonstiges2.showPop==true"
												
												ref='sonstiges2' 
												target='sonstiges2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstiges2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstiges2.size}}</span>
												<b-button @click='plus("sonstiges2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstiges2", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.sonstiges2!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.sonstiges2.text"
												
												@focus='formular.sonstiges2.showPop=true'
												@blur ='formular.sonstiges2.showPop=false'
												
												:disabled="formular.sonstiges2.isDisabled==true || formular.sonstiges2.isDisabled=='true'"
												:style="{'font-size': formular.sonstiges2.size + 'px',
														'height': formular.sonstiges2.height + 'px',
														'text-align': formular.sonstiges2.schriftausrichtung + 'px',
														'letter-spacing': formular.sonstiges2.letterSpacing + 'px',
														'font-weight': formular.sonstiges2.schriftart + 'px', }"

												id='sonstiges2'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(166.4020965) + 'px', 
														top: gib_hoehe_in_mm(9.1857899) + 'px'}">
													Anlage I/9 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(196.0288965) + 'px', 
														top: gib_hoehe_in_mm(12.4870619) + 'px'}">
													Blatt 4
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.1624591) + 'px', 
														top: gib_hoehe_in_mm(19.0229456) + 'px'}">
													Antrag vom
													</div>
<b-popover 
												v-if="formular.bauantragVom"
												:show="formular.bauantragVom.showPop==true"
												
												ref='bauantragVom' 
												target='bauantragVom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVom.size}}</span>
												<b-button @click='plus("bauantragVom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVom" type="text" 
												v-if="formular.bauantragVom!=undefined" 
												class='textfeld' v-model='formular.bauantragVom.text' 
												style='width:100%' 
												@focus='formular.bauantragVom.showPop=true'
												@blur ='formular.bauantragVom.showPop=false'
												:disabled='formular.bauantragVom.isDisabled==true || formular.bauantragVom.isDisabled==true' 
												@keypress="isNumber($event, formular.bauantragVom.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.3340259) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(175.5404892) + 'px', 
														'height': 21.5 + 'px', 
														'top': gib_hoehe_in_mm(22.2752044) + 'px', 
		
														'font-size': formular.bauantragVom.size + 'px', 
														'text-align': formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': formular.bauantragVom.letterSpacing + 'px',
														'font-weight': formular.bauantragVom.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.1624591) + 'px', 
														top: gib_hoehe_in_mm(27.7836609) + 'px'}">
													Prüfvermerke
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.0831971) + 'px', 
														top: gib_hoehe_in_mm(240.0744963) + 'px'}">
													Fortsetzung Blatt 5
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(21.3796159) + 'px', 
													width: spalte_breit_mm(182.7786629) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(183.809605) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(27.5975117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(34.0413407) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(40.6791547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.7630963) + 'px', 
													width: spalte_breit_mm(153.2118219) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.2746447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(92.1185387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.8807157) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(92.1185387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(60.4797327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(67.1316547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.7271447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(83.2923687) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(92.8575927) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(99.7775667) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(92.1185387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(106.6340547) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(114.1155271) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(119.4483511) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(125.8389224) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(132.4414664) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(92.1185387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(142.0063377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(149.3287424) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(155.9312864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(164.1065197) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(170.7520931) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(177.3546371) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(185.5228164) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(185.5228164) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(192.1253604) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(198.7279044) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(208.4627771) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(213.7956011) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(153.6262444) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(220.1893467) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(62.8031728) + 'px', 
													width: spalte_breit_mm(111.1717454) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(229.2431557) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(183.8314724) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(238.4842484) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6714345) + 'px', 
													width: spalte_breit_mm(32.5087117) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(244.2420759) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3486738) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(219.7513317) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.6676698) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.850487) + 'px', 
													top: gib_hoehe_in_mm(18.8364437) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.2696918) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(19.777133) + 'px', 
													top: gib_hoehe_in_mm(27.5975117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(86.6180154) + 'px', 
													top: gib_hoehe_in_mm(27.5975117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.1256958) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(58.916252) + 'px', 
													top: gib_hoehe_in_mm(34.0413407) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(129.4705268) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(72.7001207) + 'px', 
													top: gib_hoehe_in_mm(27.5975117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(149.4622682) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(58.916252) + 'px', 
													top: gib_hoehe_in_mm(34.0413407) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(173.9749182) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(219.7478047) + 'px', 
													top: gib_hoehe_in_mm(18.8364437) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6714345) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.8098603) + 'px', 
													top: gib_hoehe_in_mm(238.5322156) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(204.1801462) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(225.5091592) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.8563795) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(89.114426) + 'px', 
													top: gib_hoehe_in_mm(119.4483511) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(110.6017822) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(22.8639634) + 'px', 
													top: gib_hoehe_in_mm(185.6988137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(129.4818132) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(66.1744653) + 'px', 
													top: gib_hoehe_in_mm(119.4483511) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(116.7733268) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.529721) + 'px', 
													top: gib_hoehe_in_mm(149.5015654) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(161.2318672) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.529721) + 'px', 
													top: gib_hoehe_in_mm(149.5015654) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(143.2254741) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.5265467) + 'px', 
													top: gib_hoehe_in_mm(185.6988137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(62.7925918) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(18.2291327) + 'px', 
													top: gib_hoehe_in_mm(220.3551157) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(27.8858728) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.4937456) + 'px', 
													top: gib_hoehe_in_mm(213.7956011) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(167.1889702) + 'px', 
														top: gib_hoehe_in_mm(9.1780305) + 'px'}">
													Anlage I/9 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(196.9695474) + 'px', 
														top: gib_hoehe_in_mm(13.6559097) + 'px'}">
													Blatt 5
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(139.9428952) + 'px', 
														top: gib_hoehe_in_mm(19.5255431) + 'px'}">
													Genehmigungsvermerk
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.4896583) + 'px', 
														top: gib_hoehe_in_mm(19.4267871) + 'px'}">
													Die/Der Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'13.47px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8529393) + 'px', 
														top: gib_hoehe_in_mm(26.5393353) + 'px'}">
													Name, Vorname, Büro
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(-0.5) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm() + 'px',
										
										 top: gib_hoehe_in_mm() + 'px'}"><b-popover 
												v-if="formular.antragsteller_name"
												:show="formular.antragsteller_name.showPop==true"
												
												ref='antragsteller_name' 
												target='antragsteller_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_name.size}}</span>
												<b-button @click='plus("antragsteller_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_name.text"
												
												@focus='formular.antragsteller_name.showPop=true'
												@blur ='formular.antragsteller_name.showPop=false'
												
												:disabled="formular.antragsteller_name.isDisabled==true || formular.antragsteller_name.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_name.size + 'px',
														'height': formular.antragsteller_name.height + 'px',
														'text-align': formular.antragsteller_name.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_name.letterSpacing + 'px',
														'font-weight': formular.antragsteller_name.schriftart + 'px', }"

												id='antragsteller_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.47px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.5679577) + 'px', 
														top: gib_hoehe_in_mm(47.3070167) + 'px'}">
													Straße, Hausnummer
													</div>
<b-popover 
												v-if="formular.antragsteller_strasse"
												:show="formular.antragsteller_strasse.showPop==true"
												
												ref='antragsteller_strasse' 
												target='antragsteller_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
												<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_strasse" type="text" 
												v-if="formular.antragsteller_strasse!=undefined" 
												class='textfeld' v-model='formular.antragsteller_strasse.text' 
												style='width:100%' 
												@focus='formular.antragsteller_strasse.showPop=true'
												@blur ='formular.antragsteller_strasse.showPop=false'
												:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(-0.5) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(0.25) + 'px', 
														'height': 0.5 + 'px', 
														'top': gib_hoehe_in_mm(0.25) + 'px', 
		
														'font-size': formular.antragsteller_strasse.size + 'px', 
														'text-align': formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': formular.antragsteller_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'13.467px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.7337267) + 'px', 
														top: gib_hoehe_in_mm(59.7432187) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.antragsteller_ort"
												:show="formular.antragsteller_ort.showPop==true"
												
												ref='antragsteller_ort' 
												target='antragsteller_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
												<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_ort" type="text" 
												v-if="formular.antragsteller_ort!=undefined" 
												class='textfeld' v-model='formular.antragsteller_ort.text' 
												style='width:100%' 
												@focus='formular.antragsteller_ort.showPop=true'
												@blur ='formular.antragsteller_ort.showPop=false'
												:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(-0.5) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(0.25) + 'px', 
														'height': 0.5 + 'px', 
														'top': gib_hoehe_in_mm(0.25) + 'px', 
		
														'font-size': formular.antragsteller_ort.size + 'px', 
														'text-align': formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': formular.antragsteller_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'13.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6667137) + 'px', 
														top: gib_hoehe_in_mm(73.3623765) + 'px'}">
													Datum, Unterschrift 
													</div>
<div :style="{
														fontSize:'13.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0158867) + 'px', 
														top: gib_hoehe_in_mm(83.0319997) + 'px'}">
													 für elektronische Verfahren gelten die jeweiligen Bestimmungen
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(183.767281) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(118.2137536) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(25.6936371) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(118.2137536) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.0781908) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(118.2137536) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(59.3027728) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(118.2137536) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(71.5404047) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(183.767281) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.4818641) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(204.1621585) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(63.7489474) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.6086311) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(63.2287149) + 'px', 
													top: gib_hoehe_in_mm(19.1034376) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.3948775) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(63.7489474) + 'px', 
													top: gib_hoehe_in_mm(18.8329167) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "bauantragVom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "12",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 89.3233912,
            "top": 24.854563799999994,
            "right": 106.43956950000002,
            "bottom": 29.77296530000001,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherrschaft": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName<nl>bauherr.adresse.strasse<nl>bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 20.0890866,
            "top": 40.32081149999999,
            "right": 105.51796440000001,
            "bottom": 60.7040499,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "eigentuemer": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 108.3942329,
            "top": 42.0772575,
            "right": 111.23064630000002,
            "bottom": 44.912965499999984,
            "typ": "checkbox",
            "pageNo": "1"
        }
    },
    "paechter": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 108.3942329,
            "top": 52.19269349999999,
            "right": 111.23064630000002,
            "bottom": 55.0284015,
            "typ": "checkbox",
            "pageNo": "1"
        }
    },
    "baugrund_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse<&>baugrundstueck.plz<&>baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 20.0890866,
            "top": 64.9110555,
            "right": 201.4618873,
            "bottom": 69.84462309999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bezeichnung_bauvorhaben": {
        "text": "",
        "datenuebernehmen": "bezeichnung_bauvorhabe",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 97.0281227,
            "top": 72.47047459999999,
            "right": 201.4618873,
            "bottom": 77.40404219999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "betriebsgebaude1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 79.94066059999997,
            "right": 201.4618873,
            "bottom": 84.8738755,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "betriebsgebaude2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 87.34736059999997,
            "right": 201.4618873,
            "bottom": 92.2805755,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "lagergebaude1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 94.71385280000001,
            "right": 201.4618873,
            "bottom": 99.64742039999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "lagergebaude2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 102.04401689999997,
            "right": 201.4618873,
            "bottom": 106.97793719999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "stallgebaude1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 109.49339360000002,
            "right": 201.4618873,
            "bottom": 114.4262558,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "stallgebaude2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 116.89974090000001,
            "right": 201.4618873,
            "bottom": 121.8336612,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "wohngebaude1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 124.3071463,
            "right": 201.4618873,
            "bottom": 129.2400085,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "wohngebaude2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 131.6715223,
            "right": 201.4618873,
            "bottom": 136.6043845,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstgebaude1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 139.07786959999999,
            "right": 201.4618873,
            "bottom": 144.01073179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstgebaude2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 146.44224559999998,
            "right": 201.4618873,
            "bottom": 151.3751078,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 163.5605401,
            "right": 67.08918320000001,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 163.5605401,
            "right": 80.2202042,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 163.5605401,
            "right": 95.1182522,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 163.5605401,
            "right": 109.9739762,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 163.5605401,
            "right": 124.54083890000001,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 163.5605401,
            "right": 139.7774789,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 163.5605401,
            "right": 154.63320290000001,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "acker8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 163.5605401,
            "right": 169.5418319,
            "bottom": 168.49340229999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 170.2131675,
            "right": 67.08918320000001,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 170.2131675,
            "right": 80.2202042,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 170.2131675,
            "right": 95.1182522,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 170.2131675,
            "right": 109.9739762,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 170.2131675,
            "right": 124.54083890000001,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 170.2131675,
            "right": 139.7774789,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 170.2131675,
            "right": 154.63320290000001,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gruenland8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 170.2131675,
            "right": 169.5418319,
            "bottom": 175.1470878,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 176.8157115,
            "right": 67.08918320000001,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 176.8157115,
            "right": 80.2202042,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 176.8157115,
            "right": 95.1182522,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 176.8157115,
            "right": 109.9739762,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 176.8157115,
            "right": 124.54083890000001,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 176.8157115,
            "right": 139.7774789,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 176.8157115,
            "right": 154.63320290000001,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstlwflaeche8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 176.8157115,
            "right": 169.5418319,
            "bottom": 181.74963179999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 190.4948283,
            "right": 67.08918320000001,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 190.4948283,
            "right": 80.2202042,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 190.4948283,
            "right": 95.1182522,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 190.4948283,
            "right": 109.9739762,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 190.4948283,
            "right": 124.54083890000001,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 190.4948283,
            "right": 139.7774789,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 190.4948283,
            "right": 154.63320290000001,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "summelwflaeche8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 190.4948283,
            "right": 169.5418319,
            "bottom": 195.4287486,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 204.0296908,
            "right": 67.08918320000001,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 204.0296908,
            "right": 80.2202042,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 204.0296908,
            "right": 95.1182522,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 204.0296908,
            "right": 109.9739762,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 204.0296908,
            "right": 124.54083890000001,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 204.0296908,
            "right": 139.7774789,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 204.0296908,
            "right": 154.63320290000001,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "fwflaeche8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 204.0296908,
            "right": 169.5418319,
            "bottom": 208.96361109999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 213.4686482,
            "right": 67.08918320000001,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 213.4686482,
            "right": 80.2202042,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 213.4686482,
            "right": 95.1182522,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 213.4686482,
            "right": 109.9739762,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 213.4686482,
            "right": 124.54083890000001,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 213.4686482,
            "right": 139.7774789,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 213.4686482,
            "right": 154.63320290000001,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "sonstflaeche8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "betriebsfl_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 213.4686482,
            "right": 169.5418319,
            "bottom": 218.4025685,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 56.461274100000004,
            "top": 220.3681656,
            "right": 67.08918320000001,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 84.4903431,
            "top": 220.3681656,
            "right": 95.1182522,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.9129298,
            "top": 220.3681656,
            "right": 124.54083890000001,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 144.0045884,
            "top": 220.3681656,
            "right": 154.63320290000001,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 69.5922951,
            "top": 220.3681656,
            "right": 80.2202042,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 99.3460671,
            "top": 220.3681656,
            "right": 109.9739762,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 129.1488644,
            "top": 220.3681656,
            "right": 139.7774789,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Betriebsflaeche8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 158.9132174,
            "top": 220.3681656,
            "right": 169.5418319,
            "bottom": 225.30102779999999,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 89.97870780000001,
            "top": 234.0261204,
            "right": 107.1283926,
            "bottom": 237.4593022,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 150.5200155,
            "top": 234.0261204,
            "right": 167.6693476,
            "bottom": 237.4593022,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 89.97870780000001,
            "top": 243.1681044,
            "right": 107.1283926,
            "bottom": 246.6012862,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 150.5200155,
            "top": 243.1681044,
            "right": 167.6693476,
            "bottom": 246.6012862,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 89.97870780000001,
            "top": 252.3312504,
            "right": 107.1283926,
            "bottom": 255.7644322,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "Pachtdauer4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 150.5200155,
            "top": 252.3312504,
            "right": 167.6693476,
            "bottom": 255.7644322,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_name": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 22.021677399999987,
            "right": 173.40707120000002,
            "bottom": 26.481568899999957,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "getreideIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 36.4580411,
            "right": 77.7759932,
            "bottom": 39.87711489999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "getreideZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 36.4580411,
            "right": 96.8323742,
            "bottom": 39.87711489999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "obstIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 36.4580411,
            "right": 147.6285809,
            "bottom": 39.87711489999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "obstZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 36.4580411,
            "right": 173.1471313,
            "bottom": 39.87711489999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "OelfruechteIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 43.73953259999999,
            "right": 77.7759932,
            "bottom": 47.15860639999997,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "OelfruechteZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 43.73953259999999,
            "right": 96.8323742,
            "bottom": 47.15860639999997,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "gemuseIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 43.73953259999999,
            "right": 147.6285809,
            "bottom": 47.15860639999997,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "gemuseZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 43.73953259999999,
            "right": 173.1471313,
            "bottom": 47.15860639999997,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "kartoffelnIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 50.89052509999999,
            "right": 77.7759932,
            "bottom": 54.3095989,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "kartoffelnZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 50.89052509999999,
            "right": 96.8323742,
            "bottom": 54.3095989,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "sonst1Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 50.89052509999999,
            "right": 147.6285809,
            "bottom": 54.3095989,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "sonst1Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 50.89052509999999,
            "right": 173.1471313,
            "bottom": 54.3095989,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "zuckIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 58.04151760000002,
            "right": 77.7759932,
            "bottom": 61.4605914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "zuckZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 58.04151760000002,
            "right": 96.8323742,
            "bottom": 61.4605914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "sonst2Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 58.04151760000002,
            "right": 147.6285809,
            "bottom": 61.4605914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "sonst2Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 58.04151760000002,
            "right": 173.1471313,
            "bottom": 61.4605914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "akfuIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 65.44821760000002,
            "right": 77.7759932,
            "bottom": 68.8672914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "akfuZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 65.44821760000002,
            "right": 96.8323742,
            "bottom": 68.8672914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "summeBodennutzungIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 65.44821760000002,
            "right": 147.6285809,
            "bottom": 68.8672914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "summeBodennutzungZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 65.44821760000002,
            "right": 173.1471313,
            "bottom": 68.8672914,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "BodennutzungSelbstIst_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "false",
        "coords": {
            "left": 130.71238350000002,
            "top": 69.87213369999998,
            "right": 133.54773880000002,
            "bottom": 72.70784169999999,
            "typ": "checkbox",
            "pageNo": "2"
        }
    },
    "BodennutzungSelbstZiel_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "false",
        "coords": {
            "left": 149.7680591,
            "top": 69.87213369999998,
            "right": 152.6044725,
            "bottom": 72.70784169999999,
            "typ": "checkbox",
            "pageNo": "2"
        }
    },
    "weideIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 74.72070059999999,
            "right": 77.7759932,
            "bottom": 78.1397744,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "weideZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "bodennut_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 74.72070059999999,
            "right": 96.8323742,
            "bottom": 78.1397744,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "summeGlasIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 74.72070059999999,
            "right": 147.6285809,
            "bottom": 78.1397744,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "summeGlasZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 74.72070059999999,
            "right": 173.1471313,
            "bottom": 78.1397744,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MiKuIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 97.08082249999998,
            "right": 77.7759932,
            "bottom": 100.49954360000001,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MiKuZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 97.08082249999998,
            "right": 96.8323742,
            "bottom": 100.49954360000001,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "GeflIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 97.08082249999998,
            "right": 147.6285809,
            "bottom": 100.49954360000001,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "GeflZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 97.08082249999998,
            "right": 166.6846092,
            "bottom": 100.49954360000001,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "RindIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 104.49457650000002,
            "right": 77.7759932,
            "bottom": 107.9136503,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "RindZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 104.49457650000002,
            "right": 96.8323742,
            "bottom": 107.9136503,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "KaefigIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 104.49457650000002,
            "right": 147.6285809,
            "bottom": 107.9136503,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "KaefigZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 104.49457650000002,
            "right": 166.6846092,
            "bottom": 107.9136503,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeKaeIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 111.73480209999997,
            "right": 147.6285809,
            "bottom": 115.15422859999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeKaeZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 111.73480209999997,
            "right": 166.6846092,
            "bottom": 115.15422859999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MastIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 119.98798209999998,
            "right": 77.7759932,
            "bottom": 123.4074086,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MastZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 119.98798209999998,
            "right": 96.8323742,
            "bottom": 123.4074086,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeBodIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 119.98798209999998,
            "right": 147.6285809,
            "bottom": 123.4074086,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeBodZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 119.98798209999998,
            "right": 166.6846092,
            "bottom": 123.4074086,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZiSIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 128.0693972,
            "right": 77.7759932,
            "bottom": 131.4877656,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZiSZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 128.0693972,
            "right": 96.8323742,
            "bottom": 131.4877656,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeFreiIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 128.0693972,
            "right": 147.6285809,
            "bottom": 131.4877656,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "LegeFreiZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 128.0693972,
            "right": 166.6846092,
            "bottom": 131.4877656,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ArbeitIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 135.4757445,
            "right": 77.7759932,
            "bottom": 138.8948183,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ArbeitZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 135.4757445,
            "right": 96.8323742,
            "bottom": 138.8948183,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "SonstTier1Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 135.4757445,
            "right": 147.6285809,
            "bottom": 138.8948183,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "SonstTier1Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 135.4757445,
            "right": 166.6846092,
            "bottom": 138.8948183,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZuchtIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 142.7163228,
            "right": 77.7759932,
            "bottom": 146.13539659999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZuchtZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 142.7163228,
            "right": 96.8323742,
            "bottom": 146.13539659999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "SonstTier2Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 142.7163228,
            "right": 147.6285809,
            "bottom": 146.13539659999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "SonstTier2Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 142.7163228,
            "right": 166.6846092,
            "bottom": 146.13539659999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ReitIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 150.12337549999998,
            "right": 77.7759932,
            "bottom": 153.542802,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ReitZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 150.12337549999998,
            "right": 96.8323742,
            "bottom": 153.542802,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "HauptfutterIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 150.12337549999998,
            "right": 147.6285809,
            "bottom": 153.542802,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "HauptfutterZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 150.12337549999998,
            "right": 166.6846092,
            "bottom": 153.542802,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "PenIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 157.6147235,
            "right": 77.7759932,
            "bottom": 161.03414999999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "PenZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.91582410000001,
            "top": 157.6147235,
            "right": 96.8323742,
            "bottom": 161.03414999999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZusatzfutterIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 157.6147235,
            "right": 147.6285809,
            "bottom": 161.03414999999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "ZusatzfutterZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.7680591,
            "top": 157.6147235,
            "right": 166.6846092,
            "bottom": 161.03414999999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "fisch1ArtIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 60.8597958,
            "top": 173.7962468,
            "right": 77.7759932,
            "bottom": 182.2564617,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "fisch1ArtZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 79.91582410000001,
            "top": 173.7962468,
            "right": 96.8323742,
            "bottom": 182.2564617,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "fisch2ArtIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 130.71238350000002,
            "top": 173.7962468,
            "right": 147.6285809,
            "bottom": 182.2564617,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "fisch2ArtZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 149.7680591,
            "top": 173.7962468,
            "right": 173.1471313,
            "bottom": 182.2564617,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "Fisch1Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 60.8597958,
            "top": 185.0029366,
            "right": 72.0537884,
            "bottom": 188.42201039999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "Fisch1Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 79.8322342,
            "top": 185.0029366,
            "right": 91.0262268,
            "bottom": 188.42201039999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "Fisch2Ist": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.71238350000002,
            "top": 185.0029366,
            "right": 141.9060234,
            "bottom": 188.42201039999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "Fisch2Ziel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 149.6841165,
            "top": 185.0029366,
            "right": 167.5261514,
            "bottom": 188.42201039999998,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.36624479999999,
            "top": 238.6433161,
            "right": 130.28244220000002,
            "bottom": 242.0623899,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 146.28902630000002,
            "top": 238.6433161,
            "right": 163.2052237,
            "bottom": 242.0623899,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.36624479999999,
            "top": 205.91240340000002,
            "right": 130.28244220000002,
            "bottom": 209.3314772,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 146.28902630000002,
            "top": 205.91240340000002,
            "right": 163.2052237,
            "bottom": 209.3314772,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistLagerArtIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 98.6639453,
            "top": 212.0278687,
            "right": 134.5144895,
            "bottom": 219.3975352,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistLagerArtZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 136.9280156,
            "top": 212.0278687,
            "right": 172.7792652,
            "bottom": 219.3975352,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistLagerKapIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.36624479999999,
            "top": 222.2917914,
            "right": 130.28244220000002,
            "bottom": 225.7108652,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistLagerKapZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 146.28902630000002,
            "top": 222.2917914,
            "right": 163.2052237,
            "bottom": 225.7108652,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistVerbrinIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 98.6639453,
            "top": 228.3335424,
            "right": 134.5144895,
            "bottom": 235.70285619999999,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "MistVerbrinIstZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 136.9280156,
            "top": 228.3335424,
            "right": 172.7792652,
            "bottom": 235.70285619999999,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistLagerIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 98.6639453,
            "top": 244.8007527,
            "right": 134.5144895,
            "bottom": 252.1700665,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistLagerZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 136.9280156,
            "top": 244.8007527,
            "right": 172.7792652,
            "bottom": 252.1700665,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistKapIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.36624479999999,
            "top": 255.0184717,
            "right": 130.28244220000002,
            "bottom": 258.4378982,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistKapZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 146.28902630000002,
            "top": 255.0184717,
            "right": 163.2052237,
            "bottom": 258.4378982,
            "typ": "text",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistVerbringungIst": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 98.6639453,
            "top": 261.2192904,
            "right": 134.5144895,
            "bottom": 268.5886042,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "FlMistVerbringungZiel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 136.9280156,
            "top": 261.2192904,
            "right": 172.7792652,
            "bottom": 268.5886042,
            "typ": "textarea",
            "pageNo": "2"
        },
        "showPop": false
    },
    "duenger2Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 43.56000829999999,
            "right": 102.3905735,
            "bottom": 48.0202525,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger2Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 43.56000829999999,
            "right": 173.40707120000002,
            "bottom": 48.0202525,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger1Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 36.23830899999996,
            "right": 102.3905735,
            "bottom": 40.698200499999984,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger1Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 36.23830899999996,
            "right": 173.40707120000002,
            "bottom": 40.698200499999984,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger3Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 50.882413000000014,
            "right": 102.3905735,
            "bottom": 55.34230450000001,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger3Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 50.882413000000014,
            "right": 173.40707120000002,
            "bottom": 55.34230450000001,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger4Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 58.204465,
            "right": 102.3905735,
            "bottom": 62.6643565,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "duenger4Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 58.204465,
            "right": 173.40707120000002,
            "bottom": 62.6643565,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu1Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 65.611165,
            "right": 102.3905735,
            "bottom": 70.0710565,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu1Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 65.611165,
            "right": 173.40707120000002,
            "bottom": 70.0710565,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu2Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 72.93321700000001,
            "right": 102.3905735,
            "bottom": 77.39310850000001,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu2Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 72.93321700000001,
            "right": 173.40707120000002,
            "bottom": 77.39310850000001,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu3Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 80.255269,
            "right": 102.3905735,
            "bottom": 84.7151605,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu3Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 80.255269,
            "right": 173.40707120000002,
            "bottom": 84.7151605,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu4Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 87.57732100000001,
            "right": 102.3905735,
            "bottom": 92.03721249999998,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "pflaSchu4Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 87.57732100000001,
            "right": 173.40707120000002,
            "bottom": 92.03721249999998,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff2Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 102.179101,
            "right": 102.3905735,
            "bottom": 106.63899249999997,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff2Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 102.179101,
            "right": 173.40707120000002,
            "bottom": 106.63899249999997,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff1Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 94.85704899999999,
            "right": 102.3905735,
            "bottom": 99.31694049999999,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff1Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 94.85704899999999,
            "right": 173.40707120000002,
            "bottom": 99.31694049999999,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff3Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 109.50150569999997,
            "right": 102.3905735,
            "bottom": 113.96139719999996,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff3Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 109.50150569999997,
            "right": 173.40707120000002,
            "bottom": 113.96139719999996,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff4Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 116.8228523,
            "right": 102.3905735,
            "bottom": 121.28274379999999,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "kraftstoff4Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 116.8228523,
            "right": 173.40707120000002,
            "bottom": 121.28274379999999,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall1Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 124.1561907,
            "right": 102.3905735,
            "bottom": 128.6160822,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall1Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 124.1561907,
            "right": 173.40707120000002,
            "bottom": 128.6160822,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall2Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 131.4775373,
            "right": 102.3905735,
            "bottom": 135.9377815,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall2Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 131.4775373,
            "right": 173.40707120000002,
            "bottom": 135.9377815,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall3Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 138.799942,
            "right": 102.3905735,
            "bottom": 143.2601862,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall3Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 138.799942,
            "right": 173.40707120000002,
            "bottom": 143.2601862,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall4Art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 146.1223467,
            "right": 102.3905735,
            "bottom": 150.5825909,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "abfall4Lager": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 105.46188509999999,
            "top": 146.1223467,
            "right": 173.40707120000002,
            "bottom": 150.5825909,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "artBeseitigung1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 153.44369329999998,
            "right": 173.40707120000002,
            "bottom": 157.90393749999998,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "behAbwasser1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 168.1794993,
            "right": 173.40707120000002,
            "bottom": 172.63974349999998,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "behAbwasser2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 175.3329607,
            "right": 173.40707120000002,
            "bottom": 179.7932049,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "behAbwasser3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 182.7812793,
            "right": 173.40707120000002,
            "bottom": 187.2415235,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "behAbwasser4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 189.9347407,
            "right": 173.40707120000002,
            "bottom": 194.3949849,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "ortBehanlAbw1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 197.2560873,
            "right": 173.40707120000002,
            "bottom": 201.71633150000002,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "ortBehanlAbw2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 204.4095487,
            "right": 173.40707120000002,
            "bottom": 208.8697929,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "ortBehanlAbw3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 211.8578673,
            "right": 173.40707120000002,
            "bottom": 216.3181115,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "ortBehanlAbw4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 219.01132869999998,
            "right": 173.40707120000002,
            "bottom": 223.4715729,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "verbleRuecksAbw1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 226.544648,
            "right": 173.40707120000002,
            "bottom": 231.0048922,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "verbleRuecksAbw2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 233.697404,
            "right": 173.40707120000002,
            "bottom": 238.1572955,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "verbleRuecksAbw3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 241.146428,
            "right": 173.40707120000002,
            "bottom": 245.6066722,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "verbleRuecksAbw4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 61.07459010000001,
            "top": 248.299184,
            "right": 173.40707120000002,
            "bottom": 252.7590755,
            "typ": "text",
            "pageNo": "3"
        },
        "showPop": false
    },
    "AK1_4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 55.50736809999998,
            "right": 101.404777,
            "bottom": 58.926441899999986,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_4_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 118.3939833,
            "top": 55.50736809999998,
            "right": 128.7489026,
            "bottom": 58.926441899999986,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_4": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 55.50736809999998,
            "right": 148.7402913,
            "bottom": 58.926441899999986,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_4_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 162.8983747,
            "top": 55.50736809999998,
            "right": 173.2529413,
            "bottom": 58.926441899999986,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "betriebsleiter": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 54.037167000000004,
            "top": 34.73686509999999,
            "right": 81.0762071,
            "bottom": 40.17902609999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "betriebsleiterEhegatte": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 54.037167000000004,
            "top": 41.3323551,
            "right": 81.0762071,
            "bottom": 46.7745161,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "fremdarbeiten1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 82.56213220000001,
            "top": 100.22831730000001,
            "right": 171.052446,
            "bottom": 106.3741148,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "fremdarbeiten2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 82.56213220000001,
            "top": 107.1147848,
            "right": 171.052446,
            "bottom": 113.2616404,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_1_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 118.3939833,
            "top": 35.70679009999998,
            "right": 128.7489026,
            "bottom": 39.125863899999956,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_1_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 162.8983747,
            "top": 35.70679009999998,
            "right": 173.2529413,
            "bottom": 39.125863899999956,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_2_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 118.3939833,
            "top": 42.30228010000002,
            "right": 128.7489026,
            "bottom": 45.7213539,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_2_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 162.8983747,
            "top": 42.30228010000002,
            "right": 173.2529413,
            "bottom": 45.7213539,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 48.908351099999976,
            "right": 101.404777,
            "bottom": 52.32742490000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_3_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 118.3939833,
            "top": 48.908351099999976,
            "right": 128.7489026,
            "bottom": 52.32742490000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 48.908351099999976,
            "right": 148.7402913,
            "bottom": 52.32742490000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_3_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 162.8983747,
            "top": 48.908351099999976,
            "right": 173.2529413,
            "bottom": 52.32742490000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 62.15929009999999,
            "right": 101.404777,
            "bottom": 65.57836389999997,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_5": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 62.15929009999999,
            "right": 148.7402913,
            "bottom": 65.57836389999997,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_5_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 118.3939833,
            "top": 62.15929009999999,
            "right": 128.7489026,
            "bottom": 65.57836389999997,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_5_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 162.8983747,
            "top": 62.15929009999999,
            "right": 173.2529413,
            "bottom": 65.57836389999997,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 68.7547801,
            "right": 101.404777,
            "bottom": 72.17385389999998,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_6": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 68.7547801,
            "right": 148.7402913,
            "bottom": 72.17385389999998,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 75.38659820000001,
            "right": 101.404777,
            "bottom": 78.80567199999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_7": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 75.38659820000001,
            "right": 148.7402913,
            "bottom": 78.80567199999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_6_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.5612879,
            "top": 78.32000409999998,
            "right": 128.7489026,
            "bottom": 81.73907790000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_6_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 158.9611846,
            "top": 78.32000409999998,
            "right": 173.2529413,
            "bottom": 81.73907790000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 84.95182219999998,
            "right": 101.404777,
            "bottom": 88.37089599999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_8": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "AK_neu_zaehlen",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 84.95182219999998,
            "right": 148.7402913,
            "bottom": 88.37089599999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_7_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 113.5612879,
            "top": 87.88522809999998,
            "right": 128.7489026,
            "bottom": 91.30430190000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK2_7_Prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 158.9611846,
            "top": 87.88522809999998,
            "right": 173.2529413,
            "bottom": 91.30430190000001,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AK1_summe": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 91.049505,
            "top": 94.76428889999997,
            "right": 101.404777,
            "bottom": 98.1833627,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AKSelbstIst_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "false",
        "coords": {
            "left": 105.5430061,
            "top": 94.76428889999997,
            "right": 108.37941950000001,
            "bottom": 97.59999690000001,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "AK2_summe": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 138.3857247,
            "top": 94.76428889999997,
            "right": 148.7402913,
            "bottom": 98.1833627,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "AKSelbstSoll_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "false",
        "coords": {
            "left": 152.8795785,
            "top": 94.76428889999997,
            "right": 155.7149338,
            "bottom": 97.59999690000001,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "vollerwerb_IST": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.4294215,
            "top": 127.1223977,
            "right": 86.2651295,
            "bottom": 129.9581057,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "vollerwerb_SOLL": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 131.0474485,
            "top": 127.1223977,
            "right": 133.8828038,
            "bottom": 129.9581057,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "zuerwerbIST": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 83.0481528,
            "top": 136.4946948,
            "right": 128.56690940000001,
            "bottom": 140.7655391,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "zuerwerbSOLL": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.67041220000002,
            "top": 136.4946948,
            "right": 173.3919051,
            "bottom": 140.7655391,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "zuerwerbIST2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 83.0481528,
            "top": 143.47215889999998,
            "right": 128.56690940000001,
            "bottom": 147.74300319999998,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "zuerwerbSOLL2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.67041220000002,
            "top": 143.47215889999998,
            "right": 173.3919051,
            "bottom": 147.74300319999998,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "zuerwerGesamtProzIST": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 117.65719299999999,
            "top": 150.7402478,
            "right": 128.56690940000001,
            "bottom": 154.1596743,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "nebenerwerb_IST": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.4294215,
            "top": 157.24685739999998,
            "right": 86.2651295,
            "bottom": 160.08221269999999,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "nebenerwerb_SOLL": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 131.0474485,
            "top": 157.24685739999998,
            "right": 133.8828038,
            "bottom": 160.08221269999999,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "artHauptIST": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 83.0481528,
            "top": 165.2362178,
            "right": 128.56690940000001,
            "bottom": 169.5074148,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "artHauptSOLL": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.67041220000002,
            "top": 165.2362178,
            "right": 173.3919051,
            "bottom": 169.5074148,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "einkueftHauptIST": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 83.0481528,
            "top": 172.62246119999998,
            "right": 103.85639470000001,
            "bottom": 176.0408296,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "einkueftHauptSOLL": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.67041220000002,
            "top": 172.62246119999998,
            "right": 147.91673680000002,
            "bottom": 176.0408296,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "einkueftNebenIST": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 83.0481528,
            "top": 178.9872854,
            "right": 103.85639470000001,
            "bottom": 182.4063592,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "einkueftNebenSOLL": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 130.67041220000002,
            "top": 178.9872854,
            "right": 147.91673680000002,
            "bottom": 182.4063592,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "wibebeig": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.4294215,
            "top": 186.79535800000002,
            "right": 86.2651295,
            "bottom": 189.6317714,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "wibenbeig": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 112.0016485,
            "top": 186.79535800000002,
            "right": 114.8370038,
            "bottom": 189.6317714,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "wiBeText": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 143.76052,
            "top": 186.8955248,
            "right": 173.43493450000003,
            "bottom": 191.1667218,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "fortbestandErb": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.4294215,
            "top": 193.397902,
            "right": 86.2651295,
            "bottom": 196.2343154,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "fortbestandX": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 112.0016485,
            "top": 193.6980497,
            "right": 114.8370038,
            "bottom": 196.53446309999998,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "fortbestandText": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 122.9180662,
            "top": 193.20603319999998,
            "right": 173.43493450000003,
            "bottom": 197.47617209999999,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "nachfolgerAusbil": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.4294215,
            "top": 201.6133431,
            "right": 86.2651295,
            "bottom": 204.4486984,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "nachfolgerAusbilAm": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 112.0016485,
            "top": 204.1485507,
            "right": 114.8370038,
            "bottom": 206.9842587,
            "typ": "checkbox",
            "pageNo": "4"
        }
    },
    "nachfolgerAusbilText": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 122.9180662,
            "top": 203.20402009999998,
            "right": 173.43493450000003,
            "bottom": 207.4748644,
            "typ": "text",
            "pageNo": "4"
        },
        "showPop": false
    },
    "sonstiges1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 63.295542000000005,
            "top": 220.6919442,
            "right": 173.6409113,
            "bottom": 228.75149190000002,
            "typ": "textarea",
            "pageNo": "4"
        },
        "showPop": false
    },
    "sonstiges2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 63.295542000000005,
            "top": 229.7461059,
            "right": 173.6409113,
            "bottom": 237.8053009,
            "typ": "textarea",
            "pageNo": "4"
        },
        "showPop": false
    },
    "antragsteller_name": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": "",
            "top": "",
            "right": "",
            "bottom": "",
            "typ": "textarea",
            "pageNo": "5"
        },
        "showPop": false
    },
    "antragsteller_strasse": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": "",
            "top": "",
            "right": "",
            "bottom": "",
            "typ": "text",
            "pageNo": "5"
        },
        "showPop": false
    },
    "antragsteller_ort": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": "",
            "top": "",
            "right": "",
            "bottom": "",
            "typ": "text",
            "pageNo": "5"
        },
        "showPop": false
    }
}
var formulaName = "NRWBetriebsbeschreibungLandw"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		wennZahl(zahl){
			
			var dieZahl = this.formular[zahl].text
		
			if(parseInt(dieZahl)>0)
			return parseInt(dieZahl)
			else
			return 0
		},
		
		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
 
	betriebsfl_neu_zaehlen(){

			var s = this
			
			s.formular.summelwflaeche1.text = s.wennZahl("acker1") + s.wennZahl("gruenland1") + s.wennZahl("sonstlwflaeche1" )
			if(s.formular.summelwflaeche1.text==0) s.formular.summelwflaeche1.text = "";
			

			
			
		},
		
		AK_neu_zaehlen(){
			var s = this
			if(this.formular.AKSelbstIst_check.text!=1)
			{

				this.formular.AK1_summe.text =  s.wennZahl(AK1_3) + s.wennZahl(AK1_4)+ s.wennZahl(AK1_5)+ s.wennZahl(AK1_6)+ s.wennZahl(AK1_7)+ s.wennZahl(AK1_8)
				if(s.formular.AK1_summe.text==0) s.formular.AK1_summe.text = "";
			
			}	
			
			if(this.formular.AKSelbstSoll_check.text!=1)
			{
				this.formular.AK2_summe.text = s.wennZahl(AK2_3) + s.wennZahl(AK2_4)+ s.wennZahl(AK2_5)+ s.wennZahl(AK2_6)+ s.wennZahl(AK2_7)+ s.wennZahl(AK2_8)
				if(s.formular.AK2_summe.text==0) s.formular.AK2_summe.text = "";
			}
		},
		
		
		bodennut_neu_zaehlen(){
			var s = this
			if(this.formular.BodennutzungSelbstIst_check.text!=1)
			{

				this.formular.summeBodennutzungIst.text = s.wennZahl(getreideIst) + s.wennZahl(OelfruechteIst) + s.wennZahl(kartoffelnIst)+ s.wennZahl(zuckIst)+ s.wennZahl(akfuIst)+ s.wennZahl(weideIst)+ s.wennZahl(obstIst)+ s.wennZahl(gemuseIst)+ s.wennZahl(sonst1Ist)+ s.wennZahl(sonst2Ist)
				if(s.formular.summeBodennutzungIst.text==0) s.formular.summeBodennutzungIst.text = "";
			
			}	
			
			if(this.formular.BodennutzungSelbstZiel_check.text!=1)
			{
				this.formular.summeBodennutzungZiel.text = s.wennZahl(getreideZiel) + s.wennZahl(OelfruechteZiel) + s.wennZahl(kartoffelnZiel)+ s.wennZahl(zuckZiel)+ s.wennZahl(akfuZiel)+ s.wennZahl(weideZiel)+ s.wennZahl(obstZiel)+ s.wennZahl(gemuseZiel)+ s.wennZahl(sonst1Ziel)+ s.wennZahl(sonst2Ziel)
				if(s.formular.summeBodennutzungZiel.text==0) s.formular.summeBodennutzungZiel.text = "";
			}
		},
		
		
		bodennut_selbst(){
			//alert("bodennut_selbst");
			
		},
		
		},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>