<template>
  <div>
    <b-card bg-variant="light">
    <b-form-group
      label-cols-lg="2"
      label="neuer Benutzer"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
    

      <b-form-group
      
        label="Benutzername:"
      >
        <b-form-input v-model="userName"></b-form-input>

      </b-form-group>
      <b-form-group
        label="Passwort:"
      >
        <b-form-input v-model="password"></b-form-input>
      </b-form-group>


  <b-button @click="createUser"  variant="outline-primary">neuen Benutzer anlegen</b-button>

    </b-form-group>
  </b-card>
  </div>
</template>

<script>



// Just an helper to generate random usernames


export default {
  name: 'example',
  components: {

  },
  data () {
    return {
      userName:null,
      password:null,
    }
  },
  	computed: {



    },

    	mounted() {


        },

  	methods: {

  createUser(){

              this.axios.post(this.$store.state.url_benutzer, {
                      besonders: this.$store.state.dev_sub,
                      what:'createUser',
                      userName:this.userName,
                      password:this.password,
                    
                      }).then((response) => {
                              console.log(response.data)
          
                      })

  }
//url_benutzer
      
    },
}
</script>