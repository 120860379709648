<template>
	<div>
       <div class="kontainer">

			
              	<b-card class="anchor my-4 mx-4" id="allgemeines" title="Allgemeines">

					<div class="p-4">
							<!-- {{userSelbst}} -->
					</div>

                </b-card>

              	<b-card class="anchor my-4 mx-4" id="allgemeines" title="meine Projekte">

<!-- <croud-gantt :events="events"></croud-gantt> -->

					<div class="p-4">

						<b-row v-for="(projekt , index) in userSelbst.projekte" :key="index" class="mb-1">
							<b-col class="ml-1" >
								{{projekt[1].projectName}}
							</b-col>
							
							<b-col style="padding-right:15px" class="text-right" cols="2">
								<b-btn
									size="sm" ID richtg
									@click="loadProject(projekt[0].projectID, 'projekte')"
									variant="primary"
									v-b-tooltip.hover 
									title="klicken Sie, um zu diesem Projekt zu wechseln" 
								>
									<b-icon icon="box-arrow-up-right"></b-icon>
								</b-btn>
							</b-col>

						</b-row>

					</div>

                </b-card>

              	<b-card class="anchor my-4 mx-4" id="allgemeines" title="meine Aufgaben">

					<div class="p-4">

					</div>

                </b-card>

              	<b-card class="anchor my-4 mx-4" id="allgemeines" title="Nachrichten">

					<div class="p-4">

					</div>

                </b-card>

        </div>
	</div>
</template>

<script>

	import { projectMixin } from '../mixins/projectMixin.js'

export default {

	mixins: [projectMixin],
    data(){
        return{

    events: [
        {
            title: 'Test',
            offset: 1,
            duration: 4,
            status: 'in_progress',
        },
        {
            title: 'Another',
            offset: 5,
            duration: 3,
            status: 'active',
            dependencies: [0],
        }
    ],

        }
    },
	methods:{

			checkSubNav(){
				this.axios.get(this.$store.state.url_subnav,
					{
					params: {
						besonders: this.$store.state.dev_sub,
					}
					})
					.then((response) => {
						this.meineNavigation = response.data;
					
				})
			},


	},
	computed:{

		
		meineNavigation:
		{
			get()
			{
				return this.$store.getters.meineNavigation
			},
			set(value)
			{
				this.$store.commit('setmeineNavigation', value);
			}
        },


	},

    mounted() {

	this.checkSubNav();

    if(Object.keys(this.alleProjekte).length ===0){
        // console.log("updateAlleProjekte length");
        this.updateAlleProjekte();
    }     

    if(Object.keys(this.Addressbook).length ===0)
        this.updateAdressbook();
       
    if(Object.keys(this.Papierkorb).length ===0)
        this.updatePapierkorb();
	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
