<template>
	<div v-if="logObject.loggedIn === true">
        <div class="subnav" v-if="!itsMobile">
					<b-row >
						<div style="width:100%;" class="d-none d-lg-flex">
							
						<b-col style="min-width:70px;max-width:70px;" cols="1">
								<b-button 
									class="mr-2 btn-nav" 
									@click="handleDateClick" 
									v-b-tooltip.hover 
									title="neuer Termin" 
									variant="info" >
									<span class="fa fa-plus"></span>
								</b-button>	
							</b-col>

							<b-col  >
								<b-button-group>
									<b-button variant="outline-primary" @click="prev">zurück</b-button>
									<b-button variant="outline-primary" @click="next">vor</b-button>
									<b-button variant="success" @click="today">heute</b-button>
								</b-button-group>
							</b-col>

							<b-col cols="1">
								<b-button v-b-tooltip.hover title="manuell synchronieren" variant="primary" @click="updateCalendar">
									<b-icon icon="arrow-repeat"></b-icon>
								</b-button>
							</b-col>

							<b-col >
								<b-button-group>
									<b-button variant="primary" @click="dayGridMonth">Monat</b-button>
									<b-button variant="primary" @click="timeGridWeek">Woche</b-button>
									<b-button variant="primary" @click="timeGridDay">Tag</b-button>
									<b-button variant="primary" @click="listWeek">Liste</b-button>
								</b-button-group>
							</b-col>

							<b-col >
								<b-button-group>
									<b-button v-if="eventSources.length>1" @click="chooseCalendar">Kalender wählen</b-button>
									<b-button @click="releaseCalendar">Kalender freigeben</b-button>
								</b-button-group>
							</b-col>
					</div>

					<div style="width:100%;" class="d-none d-md-flex d-lg-none">

							<b-col cols="3">
								<b-button-group>
									<b-button variant="outline-primary" @click="prev">zur.</b-button>
									<b-button variant="outline-primary" @click="next">vor</b-button>
									<b-button variant="success" @click="today">heute</b-button>
								</b-button-group>
							</b-col>

							<b-col cols="4">
								<b-button-group>
									<b-button variant="primary" @click="dayGridMonth">Mo</b-button>
									<b-button variant="primary" @click="timeGridWeek">Wo</b-button>
									<b-button variant="primary" @click="timeGridDay">Ta</b-button>
									<b-button variant="primary" @click="listWeek">Li</b-button>
								</b-button-group>
							</b-col>

							<b-col cols="5">
								<b-button-group>
									<b-button  @click="handleDateClick">+ Termin</b-button>
									<b-button v-if="eventSources.length>1" @click="chooseCalendar">Kal wählen</b-button>
									<b-button @click="releaseCalendar">Kal freig.</b-button>
								</b-button-group>
							</b-col>
						</div>

						<div style="width:100%;" class="d-flex d-md-none">

							<b-col  class="abstand_rechts">

										<b-button-group>
											<b-button variant="outline-primary" @click="prev"><span class="fa fa-angle-left"></span></b-button>
											<b-button variant="outline-primary" @click="next"><span class="fa fa-angle-right"></span></b-button>
										<b-button variant="outline-success" @click="today">h</b-button>
										</b-button-group>

								  <!-- <b-button v-b-toggle.collapse-1 variant="primary">Nav</b-button>
									
									<b-collapse id="collapse-1" class="mt-2">
										<b-button-group>
											<b-button variant="outline-primary" @click="prev">z</b-button>
											<b-button variant="outline-primary" @click="next">v</b-button>
										<b-button variant="success" @click="today">h</b-button>
										</b-button-group>
									</b-collapse> -->

							</b-col>

							<b-col>
								<b-button v-b-toggle.collapse-1 variant="primary"><span class="fa fa-eye"></span></b-button>
								
								<b-collapse id="collapse-1" class="mt-2">
									<b-button-group>
										<b-button variant="primary" @click="dayGridMonth">Monat</b-button>
										<b-button variant="primary" @click="timeGridWeek">Woche</b-button>
										<b-button variant="primary" @click="timeGridDay">Tag</b-button>
										<b-button variant="primary" @click="listWeek">Liste</b-button>
									</b-button-group>
								</b-collapse>
							</b-col>

							<b-col>
								<b-button-group>
									<b-button @click="handleDateClick">+</b-button>
									<b-button v-if="eventSources.length>1"  @click="chooseCalendar">K</b-button>
									<b-button @click="releaseCalendar"><span class="fa fa-exchange"></span></b-button>
								</b-button-group>
							</b-col>
						</div>

					 </b-row>

				</div>



						<!-- v-show="eventSources.length>1" -->
				<div v-if="!itsMobile" class="subnav_shadow">
											<b-row >
						<div style="width:100%;" class="d-none d-lg-flex">
							
						<b-col style="min-width:70px;max-width:70px;" cols="1">
								<b-button 
									class="mr-2 btn-nav" 
									

									variant="info" >
									<span class="fa fa-plus"></span>
								</b-button>	
							</b-col>

							<b-col cols >
								<b-button-group>
									<b-button variant="outline-primary" >zurück</b-button>
									<b-button variant="outline-primary" >vor</b-button>
									<b-button variant="success" >heute</b-button>
								</b-button-group>
							</b-col>

							<b-col cols>
								<b-button  variant="primary" >
									<b-icon icon="arrow-repeat"></b-icon>
								</b-button>
							</b-col>

							<b-col cols>
								<b-button-group>
									<b-button variant="primary" >Monat</b-button>
									<b-button variant="primary" >Woche</b-button>
									<b-button variant="primary">Tag</b-button>
									<b-button variant="primary" >Liste</b-button>
								</b-button-group>
							</b-col>
							<b-col cols="6">
								<b-button-group>
									<b-button v-if="eventSources.length>1">Kalender wählen</b-button>
									<b-button >Kalender freigeben</b-button>
								</b-button-group>
							</b-col>
					</div>

					<div style="width:100%;" class="d-none d-md-flex d-lg-none">

							<b-col cols="3">
								<b-button-group>
									<b-button variant="outline-primary" >zur.</b-button>
									<b-button variant="outline-primary" >vor</b-button>
									<b-button variant="success" >heute</b-button>
								</b-button-group>
							</b-col>

							<b-col cols="4">
								<b-button-group>
									<b-button variant="primary" >Mo</b-button>
									<b-button variant="primary" >Wo</b-button>
									<b-button variant="primary" >Ta</b-button>
									<b-button variant="primary" >Li</b-button>
								</b-button-group>
							</b-col>

							<b-col cols="5">
								<b-button-group>
									<b-button  >+ Termin</b-button>
									<b-button v-if="eventSources.length>1" >Kal wählen</b-button>
									<b-button >Kal freig.</b-button>
								</b-button-group>
							</b-col>
						</div>

						<div style="width:100%;" class="d-flex d-md-none">

							<b-col  class="abstand_rechts">

										<b-button-group>
											<b-button variant="outline-primary" ><span class="fa fa-angle-left"></span></b-button>
											<b-button variant="outline-primary" ><span class="fa fa-angle-right"></span></b-button>
										<b-button variant="outline-success" >h</b-button>
										</b-button-group>



							</b-col>

							<b-col>
								<b-button v-b-toggle.collapse-1 variant="primary"><span class="fa fa-eye"></span></b-button>
								
								<b-collapse id="collapse-1" class="mt-2">
									<b-button-group>
										<b-button variant="primary" >Monat</b-button>
										<b-button variant="primary" >Woche</b-button>
										<b-button variant="primary" >Tag</b-button>
										<b-button variant="primary" >Liste</b-button>
									</b-button-group>
								</b-collapse>
							</b-col>

							<b-col>
								<b-button-group>
									<b-button >+</b-button>
									<b-button v-if="eventSources.length>1" >K</b-button>
									<b-button ><span class="fa fa-exchange"></span></b-button>
								</b-button-group>
							</b-col>
						</div>

					 </b-row>
       
				</div>


		<div v-if="itsMobile" class="subnav">
			<b-row >				
			<b-col style="min-width:70px;max-width:70px;" cols="1">
					<b-button 
						class="mr-2 btn-nav" 
						@click="handleDateClick" 
						v-b-tooltip.hover 
						title="neuer Termin" 
						variant="info" >
						<span class="fa fa-plus"></span>
					</b-button>	
				</b-col>
			</b-row>
		</div>

		<div v-if="itsMobile" class="subnav_shadow">
				<b-row >				
			<b-col style="min-width:70px;max-width:70px;" cols="1">
					<b-button 
						class="mr-2 btn-nav" 
						

						variant="info" >
						<span class="fa fa-plus"></span>
					</b-button>	
				</b-col>
			</b-row>
		</div>

        <div class="kontainer">

        <b-container class="abstand_nach_unten_50" fluid>

<!-- <pulse-loader :loading="loader_loading" :color="loader_color" :size="loader_size"></pulse-loader> -->


				<!-- //:locale="deLocale" -->
<!-- :editable=true -->
<!-- 
{{filteredEventSources[0]}} 

-->
<!-- 
{{EventSources}} -->
<!-- {{filteredEventSources}} -->
							
<!-- {{this.activeEvent}} -->

<!-- :eventSources="filteredEventSources"  -->
  <!-- :events="eventsloca" 
  timeZone= 'UTC'
   -->

<!-- {{filterText}} -->
<!-- {{filteredEventSources}} -->

<span v-touch:swipe.left="swipeHandlerleft" v-touch:swipe.right="swipeHandlerright"> <!-- vue2-touch-events -->

			<FullCalendar 
				ref="fullCalendar"
				:eventTimeFormat= "{ hour: '2-digit', minute: '2-digit', meridiem: false}"
			  	:eventSources="filteredEventSources" 
				defaultView="timeGridWeek"
				:plugins="calendarPlugins"
				:height= "calHeight"
				:locale= de
				
				timeZone = 'local'
				:weekNumbers = true
				:header="{
					left: '',
					center: 'title',
					right: ''
      			}"

				editable="true"

				@eventClick="handleEventClick"
				@dateClick="handleDateClick"

 			

				@eventResize="eventDrop"
				@eventDrop="eventDrop"
			/>
								<!-- 	@eventDragStart="eventDragStart"-->
</span>

  <b-modal @shown="focusMyElement" no-close-on-esc no-close-on-backdrop v-if="activeEvent" transition="nice-modal-fade" size="lg" ref="neuer_kalender_eintrag" id="neuer_kalender_eintrag" scrollable>

		<b-container slot="modal-header">
				<b-row>
					<b-col cols="3">
							<div class="ueberschrift">
					
								<span v-if="activeEvent.href"> Eintrag bearbeiten </span>
								<span v-else> neuer Eintrag </span>
							</div>
					</b-col>
<!-- 
					<b-col cols="2">
							<b-form-checkbox v-if="getShareStatusCalender" :disabled="hinzufuegenOffen" v-model="bearbeiten">bearbeiten</b-form-checkbox>
					</b-col> -->
					<b-col>
						<span style="float:right; display:block">

						<!-- {{event_loeschen_exit}} -->

								<b-button id="event-loeschen" 
									v-show="getShareStatusCalender  && activeEvent.href" 
									variant="warning" class="mr-1" 
									@click="EventShowLoeschen = !EventShowLoeschen">löschen</b-button>

									<!-- status{{EventShowLoeschen}} -->

								<b-button variant="info" class="mr-1" @click="openEventCancel">
									<span v-if="getShareStatusCalender">
										abbrechen
									</span>
									<span v-else>
										schließen
									</span>

								</b-button>
								
								<b-button v-if="getShareStatusCalender " variant="primary" @click="event_speichern()">speichern</b-button>
						</span>
					</b-col>
				</b-row>
			</b-container>
				<b-popover
				
					target="event-loeschen"
					triggers="manual"
					:show.sync="EventShowLoeschen"
					placement="auto"
					container="my-container"
					ref="event-loeschen"
					>
					
					<template v-slot:title>
						Bitte bestätigen.
					</template>

					<div>
						Wollen Sie diesen Eintrag wirklich löschen?
						<div class="mt-3">
						<b-input-group size="lg">
							<b-button style="width:80px;margin-right:20px;" @click="EventShowLoeschen = !EventShowLoeschen"  >nein</b-button>
							<b-button style="width:80px;" @click="event_loeschen" variant="outline-primary">ja</b-button>
						</b-input-group>
						</div>
					</div>
				</b-popover>
				

	
<div style="min-height:300px;" class="modal-body_custom">
											
	<b-card class="container down-space-5" >

			<b-container>
				<b-row>
					<b-col cols="2">Titel:</b-col>
					
					<b-col cols="10">
					    <b-form-input 
						v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"
						:disabled="!getShareStatusCalender || !bearbeiten"
								@focus.native="$event.target.select()"
								v-model="activeEvent.title"
								ref="focusThis" 
								type="text" >
						</b-form-input>
					</b-col>
				</b-row>
			</b-container>

			<b-container>
				<b-row>
					<b-col cols="2">Ort:</b-col>
					
					<b-col cols="10">
					    <b-form-input v-model="activeEvent.ort"
						:disabled="!getShareStatusCalender || !bearbeiten"
						v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"
						type="text" placeholder="Ort"></b-form-input>
					</b-col>
				</b-row>
			</b-container>


			<b-container>
				<b-row>
						<!-- <b-col cols="2">
							Kategorie: 
						</b-col>
						
						<b-col cols="4">
							<b-form-select v-model="activeEvent.kategorie" :options="options" class="mb-3" />
						</b-col> -->

						<b-col v-if="eventSources.length>1" cols="2">
							Kalender:
						</b-col>

						<b-col v-if="eventSources.length>1" cols="4">
							<b-form-select 
									:disabled="!getShareStatusCalender"
									v-bind:class="{ 'select-ohne-rand': !bearbeiten, 'form-control-archiapp': bearbeiten }" 
									v-model="activeEvent.calendarID" :options="CalenderList" class="mb-3" />
						</b-col>

					</b-row>
			</b-container>

		<span v-if="!wiederholtSich">
			<b-container>
				<b-row>
						<b-col cols="2"></b-col>
						<b-col cols="10"><b-form-checkbox :disabled="!getShareStatusCalender || !bearbeiten"  @input="toogleAllDay" v-model="activeEvent.allDay">ganztägig</b-form-checkbox></b-col>
				</b-row>
			</b-container>

			<b-container>
				<b-row>
					<b-col cols="2">beginnt:</b-col>
					<b-col cols="4"><date-picker :disabled="!getShareStatusCalender || !bearbeiten" :lang="lang" :format="format_date" v-model="activeEvent.start" type="date" :width="165" :time-picker-options="timePickerOptions"></date-picker></b-col>
					<b-col cols="1"></b-col>
					<b-col v-if="!activeEvent.allDay" cols="1">um:</b-col>
					<b-col v-if="!activeEvent.allDay" cols="4"><date-picker  :disabled="activeEvent.allDay" :lang="lang" :format="format_zeit" v-model="activeEvent.start" type="time" :width="165" :time-picker-options="timePickerOptions"></date-picker></b-col>
				</b-row>
			</b-container>

			<b-container>
				<b-row v-if="!activeEvent.allDay">
					<b-col cols="2">endet:</b-col>
					<b-col cols="4"><date-picker :disabled="!getShareStatusCalender || !bearbeiten"  :lang="lang" :format="format_date" v-model="activeEvent.end" type="date" :width="165" :time-picker-options="timePickerOptions"></date-picker></b-col>
					<b-col cols="1"></b-col>
					<b-col cols="1">um:</b-col>
					<b-col cols="4"><date-picker :disabled="!getShareStatusCalender || !bearbeiten" :lang="lang" :format="format_zeit" v-model="activeEvent.end" type="time" :width="165" :time-picker-options="timePickerOptions"></date-picker></b-col>
				</b-row>
				<b-row v-else>
					<b-col cols="2">
						<label>endet:</label><!--  {{ganzTagEnde}}  -->
						</b-col>
					<b-col cols="4">
						<date-picker :disabled="!getShareStatusCalender || !bearbeiten" v-on:input="updateganzTagDauer" :lang="lang" :format="format_date" v-model="ganzTagEnde" type="date" :width="165" :time-picker-options="timePickerOptions"></date-picker></b-col>

					<!-- <b-col cols="3">
						<b-form-input v-on:input="updateganzTagDauer" v-model="ganzTagDauer" id="dauer" min=1 type="number"></b-form-input> 
					</b-col> -->
					<b-col cols="2">Tag(e)</b-col>
					<b-col cols="5"></b-col>

				</b-row>

			</b-container>
</span>

			<!-- <b-container>
				<b-row>
					<b-col cols="2">Erinnerung</b-col>
					<b-col cols="10"></b-col>
				</b-row>
			</b-container> -->

			<b-container>
				<b-row>
					<b-col cols>
						<b-form-checkbox :disabled="!getShareStatusCalender || !bearbeiten" v-on:input="changewiederholtSich" v-model="wiederholtSich" name="check-button" switch>
							wiederholt sich
						</b-form-checkbox>

					</b-col>
				</b-row>
			</b-container>
				
			<b-container v-if="wiederholtSich">

					<!-- @change="rruleChanged" -->
					<Recurrence ref="recu" v-if="activeEvent.rrule"></Recurrence>

			</b-container>
						
		</b-card>

	</div>

	<div slot="modal-footer" class="w-100"></div>

 </b-modal>

  	<b-modal transition="nice-modal-fade" size="lg" ref="kalender_freigeben" id="kalender_freigeben" scrollable>

		<b-container  v-if="sharedCalendars" slot="modal-header">
				<b-row>
					<b-col cols="3">
						<div class="ueberschrift">
							<div class="ueberschrift">Kalender freigeben</div>
						</div>
					</b-col>


					<b-col cols>
						<span style="float:right; display:block">
						<b-button variant="info" class="mr-1" @click="cancelShareCal">abbrechen</b-button>
						<b-button v-if="sharedCalendars.length>0" variant="primary" @click="saveShareCal">speichern</b-button>
						</span>
					</b-col>

				</b-row>
		</b-container>


		<div style="min-height:300px;" class="modal-body_custom">
											
			<b-card v-if="sharedCalendars" class="container down-space-5" >

							Hier können Sie Ihren Kalender für andere Benutzer freigeben. Bei der Freigabe wird zwischen "nur lesen" und "lesen und schreiben" unterschieden.
<!-- {{sharedCalendars}} -->
							<table v-if="sharedCalendars.length>0" class="table">

								<thead>
									<tr>
									<th scope="col">Benutzer</th>
									<th scope="col">nur lesen</th>
									<th scope="col">lesen und schreiben</th>
									</tr>
								</thead>

								<tbody >
									<tr :key="share.id" v-for="(share, nummer) in sharedCalendars">
										<td>{{share.ganzerName}}</td>
										<td>
											<b-form-checkbox @change="changeReadRight(share, nummer)" v-model="share.read"></b-form-checkbox>
											<!-- <b-form-checkbox v-model="filterText" :value="events.id"></b-form-checkbox> -->
										</td>
										<td>
											<b-form-checkbox @change="changeWriteRight(share, nummer)" v-model="share.write"></b-form-checkbox>
										</td>
									</tr>
								</tbody>
								
						</table>
						<div class="mt-2" v-else ><br><h4>Es gibt keine weiteren Benutzer.</h4></div>
				</b-card>
			</div>
		
		<div slot="modal-footer" class="w-100"></div>

	</b-modal>

		<modal name="kalender_anzeigen" >

					<div class="modal-header">
						<div class="ueberschrift">Kalender wählen</div>
						<span style="float:right;">

							
							<b-button variant="primary" @click="closeChooseClaendar">fertig</b-button>

						</span>
					</div>

					<div class="modal-body" >
						<b-container>
							<b-row :key="calendar.id"  v-for="calendar in eventSources">
									<b-col cols="6">
										<b-form-checkbox v-model="filterText" :value="calendar.id">
											{{calendar.DisplayName}}
										</b-form-checkbox>
									</b-col>

									<b-col cols="6">
										Freigabeart: {{freigabearten[calendar.shareStatus.access]}}
									</b-col>

							</b-row>
						</b-container>
					</div>
		</modal>



		</b-container>

		</div>

    </div>
</template>

<script>
	import FullCalendar from '@fullcalendar/vue'
	import dayGridPlugin from '@fullcalendar/daygrid'

	import timeGridPlugin from '@fullcalendar/timegrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import listPlugin from '@fullcalendar/list'

	import deLocale from '@fullcalendar/core/locales/de';

	import rrulePlugin from '@fullcalendar/rrule';

	import { RRule, RRuleSet, rrulestr } from 'rrule'

	// import ColorPicker from 'vue-color-picker-wheel';
	// import Datepicker from 'vuejs-datepicker';
	import DatePicker from 'vue2-datepicker'
	import PulseLoader from 'vue-spinner/src/PulseLoader'

	import moment from 'moment'

	import Recurrence from './Recurrence.vue'

	//import { kontaktMixin } from '../mixins/kontaktMixins.js'
	import { davMixin } from '../mixins/davMixin.js'

export default {


mixins: [davMixin],
  components: {
		PulseLoader,
		Recurrence,
		// ColorPicker,
		FullCalendar,
		DatePicker,
		// Datepicker
  },

	
	data(){
		return{
		
		EventShowLoeschen:false,
		freigabearten:{1:'eigener Kalender', 2:"nur lesen", 3:"lesen und schreiben"},
		ganzTagEnde:null,
		ganzTagDauer:1,
		wiederholtSich:false,
		ownCal:null,
		sharedCalendars:null,

		de: {
        code: "de",
        week: {
            dow: 1,
            doy: 4 // The week that contains Jan 4th is the first week of the year.
        },
        buttonText: {
            prev: "Zurück",
            next: "Vor",
            today: "Heute",
            year: "Jahr",
            month: "Monat",
            week: "Woche",
            day: "Tag",
            list: "Terminübersicht"
        },
        weekLabel: "KW",
        allDayText: "Ganztägig",
        eventLimitText: function (n) {
            return "+ weitere " + n;
        },
        noEventsMessage: "Keine Ereignisse anzuzeigen"
    },

		vergleichOpenEvent:null,
		saveOrUpdate:null,
		idOffenesEvent:null,
		hinzufuegenOffen:false,

      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
		interactionPlugin, // needed for dateClick
		listPlugin,
		rrulePlugin

      ],

			lang:'de',
			// events:[],

			format_date:"DD.MM.YYYY",
			format_zeit:"HH:mm",
			shortcuts: [
				{
				text: 'Heute',
				onClick: () => {
					this.time3 = [ new Date(), new Date() ]
				}
				}
			],

			timePickerOptions:{
				start: '00:00',
				step: '00:30',
				end: '23:30'
			},

			config: {
				weekends: true,
				weekNumbers: true,
				minTime: "06:00:00",
				firstDay:1,
				weekNumberTitle:"KW",
				locale:"de",
			},
			
			dav:{},

			filterText:[],

			selected: null,
      options: [
        { value: '1', text: 'Anruf' },
        { value: '2', text: 'zwei' },
        { value: 3, text: 'drei' },
        { value: 4, text: 'vier' },
        { value: 5, text: 'fünf' }
			],
			// actualCalID:null,

	  
		}
	},


computed:{

		event_loeschen_exit(){


			//if(document.getElementById('event-loeschen')==null)
			if(this.getShareStatusCalender  && this.activeEvent.href)
				return true
			else 
				return false


		},

		calHeight() {
			var hoehe = this.window.height - 220;
			return hoehe;
		},

		calendarApi() {
			return this.$refs.fullCalendar.getApi();
		},

		store() {
			return this.$store.state;
		},

		neuerEintrag(){
				if(this.activeEvent.title==='Neuer Titel')
				return true
				else
				return false
		},

		CalenderList(){
			let Liste = this.eventSources;
			let zuruck = [];
			var valueListe, textListe;
			// //console.log(Liste);
			for (var b in Liste){

				valueListe = Liste[b].id;
				textListe = Liste[b].DisplayName;

				zuruck.push({ value: valueListe, text: textListe });
			}
			return zuruck;
		},

		logObject(){
			return this.$store.getters.logObject;
		},

		filteredEventSources: function() {
// besser mit filter

			let zuruck;


			if (this.filterText.length>0) {
				
				//zuruck = this.eventSources.filter(el => this.findCommonElements(el.id, this.filterText));
				zuruck = this.eventSources.filter(el => this.has(el.id, this.filterText) );
				
			}
			else
			zuruck = []

			return zuruck;

		},


	},
	

methods:{

	swipeHandlerleft(){
		if(this.itsMobile)
		this.next();

	},

	swipeHandlerright(){
		if(this.itsMobile)
		this.prev();
	},


	eventDragStart(info){
		//this.loadEventFromSource(info)
	},

	updateganzTagDauer(val){
		this.activeEvent.end = moment(this.ganzTagEnde).add(1, 'd').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
	},

	changewiederholtSich(val){

				if(this.$refs.recu)
					this.$refs.recu.makeStringRrule();

		if(val===true){

			if(!this.activeEvent.rrule){

			 this.activeEvent.rrule = 'DTSTART:' + moment(this.activeEvent.start).format("YYYYMMDDTHHmmss") + 'Z\nRRULE:FREQ=WEEKLY';
			
					   this.activeEvent.dtend = this.activeEvent.end;
					   this.activeEvent.dtstart = this.activeEvent.start;		
			}
			
		}
		else
		{
				this.activeEvent.pureRule = null;
				this.activeEvent.rrule = null;
				this.activeEvent.end = this.activeEvent.dtend;
				this.activeEvent.start = this.activeEvent.dtstart;	
		}
			

	},
	

	changeWriteRight(user, nummer){

		if(user.write===false)
		this.sharedCalendars[nummer].read=true;

	},
	
	changeReadRight(user, nummer){

		if(user.read===true)
		this.sharedCalendars[nummer].write=false;

	},


	setWelcherCalendar(welcheCalendar){


			  this.axios.post(this.$store.state.url_get_post_userID_abfrage, {
					besonders : this.$store.state.dev_sub,
					setWelcherCalendar: 'ja',
					CalIDs:welcheCalendar,
        }).then((response) => {

		})

	},

	closeChooseClaendar(){
		//this.setWelcherCalendar(this.filterText);
		
		this.getSelectCalReturn('SetselCal').then(data => {
        	this.hideModal('kalender_anzeigen');
		})
			
	},

	showBModal(welches) {
      this.$refs[welches].show()
  },

	hideBModal(welches) {
		this.$root.$emit('bv::hide::modal', welches);
		this.wiederholtSich = false;
	},

		rruleChanged(value) {
				//console.log(value)
		},

		focusMyElement: function () {
			// //console.log(this.$refs.focusThis);
				this.$refs.focusThis.focus()
		},

		selectTime: function(info) {

		},



	change_calendar_remove_event(){
		var dasEvent = this.calendarApi.getEventById(this.activeEvent.id);

		if(dasEvent)
		dasEvent.remove()
	},

	change_calendar_date_event(was, firstArg, secondArg){
		
		var dasEvent = this.calendarApi.getEventById(this.activeEvent.id);
		dasEvent[was](firstArg);
		
	},

	change_calendar_prop_event(was, firstArg, secondArg){
		//console.log(was, firstArg, secondArg);
		//console.log(this.activeEvent.id);	

		var dasEvent = this.calendarApi.getEventById(this.activeEvent.id);
		//console.log(dasEvent);
	dasEvent.setProp(was, firstArg);

	},

	openEventCancel() {

	var kanngeschlossenwerden = false;

	kanngeschlossenwerden=true;

	if(kanngeschlossenwerden===true){

			// if(this.saveOrUpdate === "save")
			// this.change_calendar_remove_event();

			this.hideBModal('neuer_kalender_eintrag')
			//this.activeCalendarEventLeeren();
	}

	},

		indexOfCal(ID) {
			var pos
			pos = this.eventSources.map(function(e) { return e.id; }).indexOf(ID);
			return pos;
		},

		indexOfID(CalIndex, ID) {
			var pos
			pos = this.eventSources[CalIndex].events.map(function(e) { return e.id; }).indexOf(ID);
			return pos;
		},


	loadEventFromSource(info){

			this.saveOrUpdate = "update";

				this.activeCalendarEventLeeren();
					var uid = info.event.id;

					var CalIndex = this.indexOfCal(info.event.extendedProps.calendarID);


					var UID = this.indexOfID(CalIndex, uid);


			if(UID>=0){
					this.activeEvent.title=this.eventSources[CalIndex].events[UID].title;
					this.activeEvent.start=this.eventSources[CalIndex].events[UID].start;
					this.activeEvent.end=this.eventSources[CalIndex].events[UID].end;

					this.ganzTagEnde = moment(this.activeEvent.end).subtract(1, 'd').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');


					this.activeEvent.calendarID=info.event.extendedProps.calendarID;
					this.activeEvent.allDay=this.eventSources[CalIndex].events[UID].allDay;
					this.activeEvent.id=this.eventSources[CalIndex].events[UID].id;
					this.activeEvent.href=this.eventSources[CalIndex].events[UID].href;
					this.activeEvent.etag=this.eventSources[CalIndex].events[UID].etag;
					this.activeEvent.rrule=this.eventSources[CalIndex].events[UID].rrule;
					this.activeEvent.duration=this.eventSources[CalIndex].events[UID].duration;
					this.activeEvent.dtstart=this.eventSources[CalIndex].events[UID].dtstart;
					this.activeEvent.dtend=this.eventSources[CalIndex].events[UID].dtend;
					this.activeEvent.shareStatus=this.eventSources[CalIndex].events[UID].shareStatus;
					if(this.activeEvent.rrule)
					this.wiederholtSich=true;

			}

	},

	handleEventClick(info) {
			this.bearbeiten=true;
				this.loadEventFromSource(info);
				this.showBModal('neuer_kalender_eintrag');

				this.vergleichOpenEvent = JSON.parse(JSON.stringify(this.activeEvent));

	},

	toogleAllDay(value){

				if(value===true){

					this.activeEvent.start = moment(this.activeEvent.start).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
					this.activeEvent.end = moment(this.activeEvent.start).add(1, 'd').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
					this.ganzTagEnde = moment(this.activeEvent.end).subtract(1, 'd').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');

				}
				else{
					
					this.activeEvent.start = moment(this.activeEvent.start).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
					
					if(this.ganzTagEnde)
					this.activeEvent.end = this.ganzTagEnde;
				

				}



	},

	handleDateClick(info) {

			this.bearbeiten=true;
				this.saveOrUpdate = "save";

				this.activeCalendarEventLeeren(info);
				var title, start, end, date, ID, allDay, UID;

				date = Date.now();

				title = "neuer Termin";

				ID = randomString(8, '0123456789abcdefghijklmnopqrstuvwxyz');
				UID = ID + '-';
				ID = randomString(4, '0123456789abcdefghijklmnopqrstuvwxyz');
				UID += ID + '-';
				ID = randomString(4, '0123456789abcdefghijklmnopqrstuvwxyz');
				UID += ID + '-';
				ID = randomString(8, '0123456789abcdefghijklmnopqrstuvwxyz');
				UID += ID;



					if(info.allDay)
						allDay = info.allDay;
					else
						allDay = false;


				if(info.dateStr){

						if(allDay===true){

							start = moment(info.dateStr).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
							end = moment(info.dateStr).add(1, 'd').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
							
							this.ganzTagEnde = moment(info.dateStr).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
						}

						else{

							start = moment(info.dateStr).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
							end = moment(start).add(1, 'hours').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');

						}

				}

				else
				//	start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
					{
								start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
								end = moment(start).add(1, 'hours').format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
					}

					this.activeEvent.id=UID;
					this.activeEvent.title=title;

					this.activeEvent.start=start;
					this.activeEvent.end=end;
					
					this.activeEvent.dtstart=start;
					this.activeEvent.dtend=end;

					this.activeEvent.allDay=allDay;
					this.activeEvent.calendarID=this.meineKalenderID ;
					

					//this.activeEvent.shareStatus= ;

					// this.$store.commit('changeBearbeiten', true);

					this.showBModal('neuer_kalender_eintrag');

					this.vergleichOpenEvent = JSON.parse(JSON.stringify(this.activeEvent));

		},
		
		event_loeschen () {

					// var result = confirm("Wollen Sie dieses Ereignis wirklich löschen?");

					// if (result) {
						const self = this
						this.axios.post(this.$store.state.url_calObject, {

							
							user: this.zugangsdaten.userName,
							pass: this.zugangsdaten.password,
							kundenID: this.zugangsdaten.kundenID,
							saveOrUpdate: "delete",
							event: this.activeEvent,
						}).then((response) => {

							if(response.data.success===true)
								this.$toasted.show("Der Eintrag wurde erfolgreich gelöscht.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})

								self.hideBModal('neuer_kalender_eintrag');

								self.change_calendar_remove_event();

								var CalIndex = self.indexOfCal(self.activeEvent.calendarID);

								var UID = self.indexOfID(CalIndex, self.activeEvent.id);

								self.eventSources[CalIndex].events.splice(UID,1);


						})
							.catch(function (error) {
								this.$toasted.show("Der Eintrag konnte nicht gelöscht werden.", { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})

							// handle error
							console.log(error);
						})



					// }

		},


		eventDrop: function(event){

			//this.saveOrUpdate="update";

				this.loadEventFromSource(event)

				console.log(event.event.start)
				console.log(event.event.end)

				this.activeEvent.start = moment(event.event.start).format('YYYY-MM-DDTHH:mm:ssZ');

				this.activeEvent.end = moment(event.event.end).format('YYYY-MM-DDTHH:mm:ssZ');

			// this.activeEvent.start = event.event.range.start;
			// this.activeEvent.end = event.event.range.end;
			this.event_speichern("ohnePrüfung");
		},


		event_speichern (pruefung) {
			var result;
			var Warnhinweis = "Startdatum muss vor Enddatum liegen.";
			
			if(this.$refs.recu)
			this.$refs.recu.makeStringRrule();
			   
			//   console.log(this.activeEvent);

			if(!pruefung && !this.activeEvent.rrule && moment(this.activeEvent.start).isBefore(this.activeEvent.end)===false){
				result = false;
				alert(Warnhinweis);
			}
			else
				result=true;
			
			if (result) {

					this.activeEvent.start = moment(this.activeEvent.start).format('YYYY-MM-DDTHH:mm:ss');
					this.activeEvent.end = moment(this.activeEvent.end).format('YYYY-MM-DDTHH:mm:ss');

					//  if(this.saveOrUpdate==='save'){
							 
					// 		var neuesEvent = 	this.calendarApi.addEvent({
					// 			id: this.activeEvent.id,
					// 			title: this.activeEvent.title,
					// 			start: moment(this.activeEvent.start).format('YYYY-MM-DDTHH:mm:ss'),
					// 			end: moment(this.activeEvent.end).format('YYYY-MM-DDTHH:mm:ss'),
					// 			// start: this.activeEvent.start,
					// 			// end: this.activeEvent.end,
					// 			allDay: this.activeEvent.allDay,
					// 			calendarID:this.activeEvent.calendarID,
					// 			rrule:this.activeEvent.rrule,
					// 			dtstart:this.activeEvent.dtstart,
					// 			dtend:this.activeEvent.dtend

					// 		}, this.activeEvent.calendarID);
					 	// }

						//  if( this.saveOrUpdate==='update'){

						//  	var dasEvent = this.calendarApi.getEventById(this.activeEvent.id);
						// 	console.log(dasEvent);

						//  	dasEvent.remove();

						// 	console.log(moment(this.activeEvent.start).format('YYYY-MM-DDTHH:mm:ss'));

						//  		var neuesEvent = 	this.calendarApi.addEvent({
						// 			id: this.activeEvent.id, 
						// 			title: this.activeEvent.title, 

						// 			start: moment(this.activeEvent.start).format('YYYY-MM-DDTHH:mm:ss'),

						// 			//start: moment(this.activeEvent.start).format('YYYYMMDDTHHmmssZ'),
						// 			 end: moment(this.activeEvent.end).format('YYYY-MM-DDTHH:mm:ss'),

						// 			//end: this.activeEvent.end,
						// 			allDay: this.activeEvent.allDay,
						// 			calendarID:this.activeEvent.calendarID,
						// 			rrule:this.activeEvent.rrule,
						// 			dtstart:moment(this.activeEvent.start).format('YYYY-MM-DDTHH:mm:ss'),
						// 			dtend:this.activeEvent.dtend,
						// 			duration:this.activeEvent.duration
						// 		 }, this.activeEvent.calendarID);
						// 		 console.log(neuesEvent);

						// 		// neuesEvent.setStart('2020-01-01T12:30:00');


						//  }




								this.axios.post(this.$store.state.url_calObject, {
									user: this.zugangsdaten.userName,
									pass: this.zugangsdaten.password,
									kundenID: this.zugangsdaten.kundenID,
									saveOrUpdate: this.saveOrUpdate,
									event: this.activeEvent,
								}).then((response) => {

								if(response.data.success===true){
											this.activeEvent.href = response.data.href;
											this.activeEvent.etag = response.data.etag;

									var CalIndex = this.indexOfCal(this.activeEvent.calendarID);

									if(this.saveOrUpdate==="update" && CalIndex>= 0){

											var UID = this.indexOfID(CalIndex, this.activeEvent.id);

											this.eventSources[CalIndex].events.splice(UID,1,this.activeEvent);

									}
									
									else if(this.saveOrUpdate==="save")
										this.eventSources[CalIndex].events.push(this.activeEvent);
									

								if(!pruefung)
									this.$toasted.show("Der Eintrag wurde erfolgreich gespeichert.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})
								}

								else if(response.data.noAccess===true)
									this.$toasted.show("Sie haben keine Berechtigung, diesen Eintrag zu ändern.", { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})

							})


							this.hideBModal('neuer_kalender_eintrag');

				}
		},

		onColorChange(color) {
			//console.log('Color has changed to: ', color);
		},

    	showModal (welches) {
			this.$modal.show(welches);
		},

		hideModal (welches) {
			this.$modal.hide(welches);
			this.activeCalendarEventLeeren();
		},

		chooseCalendar() {
			this.showModal('kalender_anzeigen');
		},

		abmelden(){
			//console.log("abmelden geklickt")
			this.axios.post("/php/logout.php").then((response) => {
				if(response.data.logout===true){
					this.checkloggin();
					this.checkSubNav();
					this.$toasted.show("Sie haben sich erfolgreich abgemeldet.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})
					this.$router.push('/');
				}
			
			})
		},
	
		cancelShareCal() {

		this.hideBModal('kalender_freigeben');

		},

		saveShareCal() {

			this.axios.post(this.$store.state.url_cal_dav,
					
			{besonders: this.$store.state.dev_sub,
			setShared:true,
			sharedCalendars: this.sharedCalendars,
			ownCal:this.ownCal,
			}
			
			).then((response) => {

			if(response.data.success===true)
			this.$toasted.show("Änderung erfolgreich gespeichert.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})

			this.hideBModal('kalender_freigeben');

			})

		},

		releaseCalendar() {
			this.getShared();
			this.showBModal('kalender_freigeben');
		},

    	dayGridMonth() {
			this.calendarApi.changeView('dayGridMonth');
		},

		timeGridWeek() {
			this.calendarApi.changeView('timeGridWeek');
		},

		timeGridDay() {
			this.calendarApi.changeView('timeGridDay');
		},

		listWeek() {
			this.calendarApi.changeView('listWeek');
		},

		today() {
			this.calendarApi.today();
		},

		next() {
			this.calendarApi.next();
		},

		prev() {
				this.calendarApi.prev();
		},



		activeCalendarEventLeeren: function(){
			this.activeEvent = {
			kategorie: '',
			calendarID: '',
			id : '',
			title : '',
			start : '',
			allDay : '',
			end : '',
			rrule : null,
			dtstart : '',
			duration : '',
			activeEnd:null,
			shareStatus:''
		  };
		},



	eventClick: function(info) {
			this.activeEvent = info.event;
			this.showBModal("neuer_kalender_eintrag");
	},


	getShared(){

		this.axios.post(this.$store.state.url_cal_dav,
				
		{besonders: this.$store.state.dev_sub,
		getShared:true,
		}
		
		).then((response) => {

			if(response.data.logout===true)
			this.abmelden();
			else if(response.data.length>0){
				this.sharedCalendars = response.data;
				this.ownCal = response.data[0].calendarID;
				console.log(this.sharedCalendars);
			}
			else this.sharedCalendars=[];
			
		})
	},

	updateCalendar(){

	this.loader_loading = true;

console.log(this.zugangsdaten);

		if(this.zugangsdaten){

			this.eventSources=[];
			this.axios.post(this.$store.state.url_calObject, {

					user: this.zugangsdaten.userName,
					pass: this.zugangsdaten.password,
					kundenID: this.zugangsdaten.kundenID,
					besonders : this.$store.state.dev_sub,
					listCalendars: true,
				}).then((response) => {
							this.events = response.data;
					//	console.log(this.events);
								var zahl=1;
								
								for (var prop in this.events) {

								
								var num = 'ff' + (Math.floor(Math.random() * (9-0)) + 0) + (Math.floor(Math.random() * (9-0)) + 0) + (Math.floor(Math.random() * (9-0)) + 0) + (Math.floor(Math.random() * (9-0)) + 0);

									for(var n in this.events[prop].events){

										if(this.events[prop].events[n].rrule){

											this.events[prop].events[n].rrule = this.events[prop].events[n].rrule.replace(/\\n/g, '\n');
										}

									}
									
									this.eventSources.push({events:this.events[prop].events, shareStatus:this.events[prop].shareStatus, DisplayName:this.events[prop].DisplayName, id:this.events[prop].CalendarID, color:'#' + num})
								
								} // Ende Schleife jeder Kalender
								

									
							this.loader_loading = false;
						});


			}
	},


	getSelectCalReturn(was){
        console.log("getDAVReturn");
        return this.axios.post(this.$store.state.url_getdav, {
		   was:was,
		   aktuellerFilter:this.filterText,
           besonders: this.$store.state.dev_sub,
           }).then((response) => {
      
           // returning the data here allows the caller to get it through another .then(...)
           return response.data
         })
      
		 },


	},


	created(){

		this.getSelectCalReturn('selCal').then(data => {
        	this.filterText = data;
		})
	

		if(this.eventSources.length==0 ){

			if(this.zugangsdaten)
			{
				this.updateCalendar();
			}
			else
			{
			    this.getDAVReturn().then(data => {
					this.zugangsdaten = data;
					this.updateCalendar();
				})
			}


	
		}
		

	


	} // Ende created
	
} //ende export default

function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}


</script>




<style scoped>


@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';


.container {
margin-bottom:10px;
}


.table td {
padding:2px;
}

.text_zentriert {
	text-align:center !important;

}

.v--modal-overlay {

  z-index: 100000;
}

</style>

