<template>

	<b-modal transition="nice-modal-fade" size="lg" ref="ZugriffrechteEinzel" id="ZugriffrechteEinzel" scrollable>

<!-- {{KontaktDetail}} -->
<!-- {{zugriffVorlagen}} -->
		<b-container v-if="KontaktDetail.vollerName" slot="modal-header">
			<b-row>
				<b-col cols="8">
						<div class="ueberschrift">
							Zugriffsrechte von {{KontaktDetail.vollerName.anzeigeName}} bearbeiten
							<!-- {{KontaktDetail.basic.benutzerID}} -->
						</div>
				</b-col>

				<b-col>
					<span style="float:right; display:block">
							<b-button class="mr-1" variant="info" @click="closeEditZugriff">abbrechen</b-button>
							<b-button variant="primary" @click="save_Zugriff_kontakt(KontaktDetail.basic.benutzerID, KontaktDetail.zugriffrechte)">speichern</b-button>
					</span>
				</b-col>

			</b-row>

			<b-row class="mt-4">
				<b-col>
						Der Benutzer {{KontaktDetail.vollerName.anzeigeName}} erhält Zugriff auf die Menüpunkte mit einen Häkchen.
						Die Menüpunkte repräsentieren die Haupt- und Unternavigation in ArchiApp.<br>
				</b-col>
					<!-- {{KontaktDetail.zugriffrechte}} -->

			</b-row>
		<!-- v-model="gewaehlteVorlage" -->
			<b-row class="mt-4">
	        	<b-form-select @input="uebernehmeVorlage" :options="vorlagenListe"></b-form-select>
			</b-row>

		</b-container>

	<span v-if="KontaktDetail.zugriffrechte">
		<div v-if="KontaktDetail.vollerName" style="min-height:300px;" class="modal-body_custom">

			<div v-for="option in navigation" :key="option.navID">

				<b-form-checkbox
					v-model="KontaktDetail.zugriffrechte"
					:value="option.navID"
				>
					<span class="text-uppercase font-weight-bold" >{{option.ordnerName}}</span>
				</b-form-checkbox>

				<div v-for="suboption in option['subnav']" :key="suboption.navID">

					<b-form-checkbox
					v-model="KontaktDetail.zugriffrechte"
					:value="suboption.navID"
					>
					<span class="pl-4" >{{suboption.unterordnerName}}</span>
				</b-form-checkbox>

				</div>

			</div>

		</div>
	</span>
				<template v-slot:modal-footer>
					<div class="w-100">
					</div>
				</template>

			
		</b-modal>
	
</template>

<script>

    import { kontaktMixin } from '../../mixins/kontaktMixins.js'
	import { einstellungenMixin } from '../../mixins/einstellungenMixin.js'
		
    export default {

        mixins: [kontaktMixin, einstellungenMixin],

        name: "modalZugriffrechte",


        data(){
            return{

            }
        },
	computed:{

	navigation:
		{
			get()
			{
				return this.$store.getters.navigation
			},
			set(value)
			{
				this.$store.commit('setnavigation', value);
			}
        },

//KontaktDetail.zugriffrechte


        meineNavigation:
		{
			get()
			{
				return this.$store.getters.meineNavigation
			},
			set(value)
			{
				this.$store.commit('setmeineNavigation', value);
			}
        },
		
		zugriffVorlagen:
			{
				get()
				{
					return this.$store.getters.zugriffVorlagen
				},
				set(value)
				{
					this.$store.commit('setzugriffVorlagen', value);
				}
			},

		vorlagenListe(){
			var returnValue=[]

			var keyValue = Object.keys(this.zugriffVorlagen);

			for(var prop of keyValue)
			{
			//console.log(prop)

			returnValue.push({ value: prop, text: this.zugriffVorlagen[prop].zugriffrechteName });

			}
			return returnValue
		},

	},

  methods: {
			uebernehmeVorlage(value){
				console.log(value);
				console.log(this.zugriffVorlagen);
				this.KontaktDetail.zugriffrechte = this.zugriffVorlagen[value].navigation;
				//console.log(value);
					
			},

		closeEditZugriff(row){
			this.hideBModal('ZugriffrechteEinzel');
        },

        showBModal(welches) {
    	    this.$refs[welches].show()
        },

        hideBModal(welches) {
			this.$refs[welches].hide()

        },
    
        
    },

	mounted() {
				this.$root.$on('zugriffsrechteOeffnen', kontaktID => {
				this.showBModal('ZugriffrechteEinzel');
			})
			

	},

	created(){
	
			if(this.zugriffVorlagen.length===0)
			this.getBerechtigungenVorlage()

	},

    }

</script>
<style scoped>
.liste_fett{
	font-weight: 700;
}
.eingerueckt{
padding-left:30px;

}
</style>