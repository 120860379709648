<template>
	<div>
        <div class="kontainer">
			<h1>Honorar</h1>
			<p>Text</p>
		</div>
	</div>
</template>

<script>



export default {
	
    data(){
        return{
        }
    },
	methods:{

	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
