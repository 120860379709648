<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'18.66px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.7368869) + 'px', 
														top: gib_hoehe_in_mm(19.982995) + 'px'}">
													Vollmacht zum Antrag auf Akteneinsicht bzw. Kopien aus Bauakten
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(43.7542696) + 'px'}">
													Grundstück
													</div>
<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(52.9752584) + 'px'}">
													Ort, Straße, Hausnummer
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(157.5152432) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(38.2877012) + 'px',
										
										 top: gib_hoehe_in_mm(57.6888176) + 'px'}"><b-popover 
												v-if="formular.baugrund_strasse"
												:show="formular.baugrund_strasse.showPop==true"
												
												ref='baugrund_strasse' 
												target='baugrund_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
												<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.baugrund_strasse!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.baugrund_strasse.text"
												
												@focus='formular.baugrund_strasse.showPop=true'
												@blur ='formular.baugrund_strasse.showPop=false'
												
												:disabled="formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled=='true'"
												:style="{'font-size': formular.baugrund_strasse.size + 'px',
														'height': formular.baugrund_strasse.height + 'px',
														'text-align': formular.baugrund_strasse.schriftausrichtung + 'px',
														'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrund_strasse.schriftart + 'px', }"

												id='baugrund_strasse'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(85.3809817) + 'px'}">
													Eigentümer/in
													</div>
<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(94.4351434) + 'px'}">
													Name, Vorname
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(157.5152432) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(38.2877012) + 'px',
										
										 top: gib_hoehe_in_mm(98.8714804) + 'px'}"><b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_name!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_name.text"
												
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												
												:disabled="formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_name.size + 'px',
														'height': formular.bauherr_name.height + 'px',
														'text-align': formular.bauherr_name.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart + 'px', }"

												id='bauherr_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(144.3471312) + 'px'}">
													Bevollmächtigte/r
													</div>
<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(153.3998821) + 'px'}">
													Name, Vorname
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(157.3364243) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(38.2877012) + 'px',
										
										 top: gib_hoehe_in_mm(158.3165965) + 'px'}"><b-popover 
												v-if="formular.antragsteller_vorlagenberechtigt"
												:show="formular.antragsteller_vorlagenberechtigt.showPop==true"
												
												ref='antragsteller_vorlagenberechtigt' 
												target='antragsteller_vorlagenberechtigt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_vorlagenberechtigt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_vorlagenberechtigt.size}}</span>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_vorlagenberechtigt!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_vorlagenberechtigt.text"
												
												@focus='formular.antragsteller_vorlagenberechtigt.showPop=true'
												@blur ='formular.antragsteller_vorlagenberechtigt.showPop=false'
												
												:disabled="formular.antragsteller_vorlagenberechtigt.isDisabled==true || formular.antragsteller_vorlagenberechtigt.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_vorlagenberechtigt.size + 'px',
														'height': formular.antragsteller_vorlagenberechtigt.height + 'px',
														'text-align': formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
														'font-weight': formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }"

												id='antragsteller_vorlagenberechtigt'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(115.2246922) + 'px'}">
													Straße, Hausnummer
													</div>
<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(119.5254449) + 'px', 
														top: gib_hoehe_in_mm(115.2246922) + 'px'}">
													Postleitzahl, Ort
													</div>
<b-popover 
												v-if="formular.bauherr_strasse"
												:show="formular.bauherr_strasse.showPop==true"
												
												ref='bauherr_strasse' 
												target='bauherr_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_strasse.size}}</span>
												<b-button @click='plus("bauherr_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_strasse" type="text" 
												v-if="formular.bauherr_strasse!=undefined" 
												class='textfeld' v-model='formular.bauherr_strasse.text' 
												style='width:100%' 
												@focus='formular.bauherr_strasse.showPop=true'
												@blur ='formular.bauherr_strasse.showPop=false'
												:disabled='formular.bauherr_strasse.isDisabled==true || formular.bauherr_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(68.7237236) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(38.5377012) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(120.392112) + 'px', 
		
														'font-size': formular.bauherr_strasse.size + 'px', 
														'text-align': formular.bauherr_strasse.schriftausrichtung, 
														'letter-spacing': formular.bauherr_strasse.letterSpacing + 'px',
														'font-weight': formular.bauherr_strasse.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_ort"
												:show="formular.bauherr_ort.showPop==true"
												
												ref='bauherr_ort' 
												target='bauherr_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_ort.size}}</span>
												<b-button @click='plus("bauherr_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_ort" type="text" 
												v-if="formular.bauherr_ort!=undefined" 
												class='textfeld' v-model='formular.bauherr_ort.text' 
												style='width:100%' 
												@focus='formular.bauherr_ort.showPop=true'
												@blur ='formular.bauherr_ort.showPop=false'
												:disabled='formular.bauherr_ort.isDisabled==true || formular.bauherr_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(76.0133272) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(120.0396172) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(120.392112) + 'px', 
		
														'font-size': formular.bauherr_ort.size + 'px', 
														'text-align': formular.bauherr_ort.schriftausrichtung, 
														'letter-spacing': formular.bauherr_ort.letterSpacing + 'px',
														'font-weight': formular.bauherr_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(174.0444712) + 'px'}">
													Straße, Hausnummer
													</div>
<b-popover 
												v-if="formular.antragsteller_strasse"
												:show="formular.antragsteller_strasse.showPop==true"
												
												ref='antragsteller_strasse' 
												target='antragsteller_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
												<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_strasse" type="text" 
												v-if="formular.antragsteller_strasse!=undefined" 
												class='textfeld' v-model='formular.antragsteller_strasse.text' 
												style='width:100%' 
												@focus='formular.antragsteller_strasse.showPop=true'
												@blur ='formular.antragsteller_strasse.showPop=false'
												:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(68.7237236) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(38.5377012) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(179.2111856) + 'px', 
		
														'font-size': formular.antragsteller_strasse.size + 'px', 
														'text-align': formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': formular.antragsteller_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(119.5254449) + 'px', 
														top: gib_hoehe_in_mm(174.0444712) + 'px'}">
													Postleitzahl, Ort
													</div>
<b-popover 
												v-if="formular.antragsteller_ort"
												:show="formular.antragsteller_ort.showPop==true"
												
												ref='antragsteller_ort' 
												target='antragsteller_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
												<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_ort" type="text" 
												v-if="formular.antragsteller_ort!=undefined" 
												class='textfeld' v-model='formular.antragsteller_ort.text' 
												style='width:100%' 
												@focus='formular.antragsteller_ort.showPop=true'
												@blur ='formular.antragsteller_ort.showPop=false'
												:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(76.0143853) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(119.8597402) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(179.2111856) + 'px', 
		
														'font-size': formular.antragsteller_ort.size + 'px', 
														'text-align': formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': formular.antragsteller_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(206.7566908) + 'px'}">
													Als Eigentümer/in des o. a. Grundstückes bevollmächtige ich hiermit die genannte Bevollmächtigte / den
													</div>
<div :style="{
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(210.3887954) + 'px'}">
													genannten Bevollmächtigten in die Bauakte des o. a. Grundstücks einzusehen bzw. Kopien von den vorhandenen
													</div>
<div :style="{
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(214.0212527) + 'px'}">
													Unterlagen fertigen zu lassen.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(224.6438713) + 'px'}">
													Unterschrift Eigentümer/in
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(38.2877012) + 'px', 
														top: gib_hoehe_in_mm(233.2603323) + 'px'}">
													Datum, Unterschrift(en)
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9553178) + 'px', 
													width: spalte_breit_mm(182.6742637) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.6295815) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(281.2562158) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9553178) + 'px', 
													width: spalte_breit_mm(182.6742637) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(289.7068694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9553178) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(281.2562158) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.3058387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(220.9458882) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.7736079) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(32.9959763) + 'px', 
													top: gib_hoehe_in_mm(220.9458882) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.3058387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(253.8418645) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(32.9959763) + 'px', 
													top: gib_hoehe_in_mm(220.9458882) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(190.7685235) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(17.4531927) + 'px', 
													top: gib_hoehe_in_mm(173.4153308) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(173.4153308) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(17.4531927) + 'px', 
													top: gib_hoehe_in_mm(173.4153308) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(173.4153308) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(20.8465194) + 'px', 
													top: gib_hoehe_in_mm(152.6688114) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(152.6688114) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(20.8465194) + 'px', 
													top: gib_hoehe_in_mm(152.6688114) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(152.6688114) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.2032532) + 'px', 
													top: gib_hoehe_in_mm(140.5655582) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(140.5655582) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.2032532) + 'px', 
													top: gib_hoehe_in_mm(140.5655582) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(131.8044902) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(17.4112214) + 'px', 
													top: gib_hoehe_in_mm(114.4932688) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.3058387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(114.4932688) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(114.4932688) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(38.2004175) + 'px', 
													top: gib_hoehe_in_mm(93.7040727) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.7040727) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(20.8891961) + 'px', 
													top: gib_hoehe_in_mm(93.7040727) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.7040727) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.2067802) + 'px', 
													top: gib_hoehe_in_mm(81.5972925) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(81.5972925) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.2067802) + 'px', 
													top: gib_hoehe_in_mm(81.5972925) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(72.8362245) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(20.83876) + 'px', 
													top: gib_hoehe_in_mm(52.0974645) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(20.83876) + 'px', 
													top: gib_hoehe_in_mm(52.0974645) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(52.0974645) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.246988) + 'px', 
													top: gib_hoehe_in_mm(39.9504765) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.474782) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.9504765) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.9425512) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.246988) + 'px', 
													top: gib_hoehe_in_mm(39.9504765) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4677692) + 'px', 
													width: spalte_breit_mm(162.3058387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(233.0494941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(117.7051602) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(17.4112214) + 'px', 
													top: gib_hoehe_in_mm(114.4932688) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(117.7051602) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(17.4531927) + 'px', 
													top: gib_hoehe_in_mm(173.4153308) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "baugrund_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.plz<&>baugrundstueck.ort<&>baugrundstueck.strasse",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 38.2877012,
            "top": 57.68881760000002,
            "right": 196.3029444,
            "bottom": 71.8021081,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_name": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 38.2877012,
            "top": 98.87148039999997,
            "right": 196.3029444,
            "bottom": 113.26058229999998,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_vorlagenberechtigt": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 38.2877012,
            "top": 158.3165965,
            "right": 196.12412550000002,
            "bottom": 164.63027920000002,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_strasse": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 38.2877012,
            "top": 120.142112,
            "right": 107.51142480000001,
            "bottom": 123.7671626,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_ort": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 119.78961720000001,
            "top": 120.142112,
            "right": 196.3029444,
            "bottom": 123.7671626,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_strasse": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 38.2877012,
            "top": 178.9611856,
            "right": 107.51142480000001,
            "bottom": 182.5865889,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_ort": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 119.60974019999999,
            "top": 178.9611856,
            "right": 196.12412550000002,
            "bottom": 182.5865889,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    }
}
var formulaName = "vollmachtWeb"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>