<template>
  <div >

	
<!-- {{hereHeight}} -->
		<div style="margin-bottom:200px !important" :class="{'kontainer':!itsMobile}" :style="{ height: hereHeight }">
		
			<p v-if="!showJitsi" class="text-justify mb-2 m-1 h4">Willkommen zum Online Meeting</p>

					<b-row v-if="itsMobile && !showJitsi" class="m-1 mb-2">
					<b-col>
					<label style="font-size:1.1em" for="input-default">Ihr Name:</label>
					</b-col>
				</b-row>

				<b-row v-if="itsMobile" class="m-1 mb-2">
					<b-col cols="6">
						 <b-form-input
						v-model="meinName"
						@input="hideName(meinName)" 
						@blur="changeName(meinName)"
						@keyup.enter="startMeeting"
						:state="nameState"
						id="input-default"
						placeholder="Ihr Name"
						trim
						></b-form-input>

					<!-- <b-form-input @input="hideName(meinName)" @blur="changeName(meinName)" v-model="meinName" id="input-default" placeholder="Ihr Name"></b-form-input> -->
					</b-col>
					<b-col cols="6" v-if="meinName">
						<b-button  
								variant="outline-primary"
								@click="showJitsi = !showJitsi">

									<span v-if="showJitsi">Meeting beenden</span>
									<span v-if="! showJitsi">Meeting starten</span>

						</b-button>
					</b-col>

					</b-row>

					<!-- <b-row v-if="itsMobile && meinName" class="mb-2">


				</b-row> -->

		<b-card v-if="!itsMobile" class="mb-2 mt-1">
			
				
<!-- {{$browserDetect}} -->

				<div class="mt-1 h5" v-if="!showJitsi && $browserDetect.isChrome!=true && $browserDetect.isEdge!=true">

						ArchiAPP empfiehlt für Online-Meetings den Browser Chrome oder Edge, da in diesen die zugrunde liegenden Technologie am Besten umgesetzt wurde.
						Sie nutzen aktuell: {{$browserDetect.meta.name}}.

						<span v-if="$browserDetect.isChrome==true && $browserDetect.meta.version<72">
								Bitte bringen Sie Ihren Browser Chrome auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
						</span> 
						<span v-if="$browserDetect.isEdge==true && $browserDetect.meta.version<79">
								Bitte bringen Sie Ihren Browser Edge auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
						</span>
				</div>




				<b-row class="my-1 mb-2">
					<b-col cols="2">
					<label style="font-family: 'SourceSansPro'; font-size:1.5em" for="input-default">Ihr Name:</label>
					</b-col>
					<b-col cols="6">
						 <b-form-input
						v-model="meinName"
						@input="hideName(meinName)" 
						@blur="changeName(meinName)"
						:state="nameState"
						aria-describedby="input-live-help input-live-feedback"
						placeholder="Bitte geben Sie Ihren Namen hier ein"
						trim
						></b-form-input>

					<!-- <b-form-input @input="hideName(meinName)" @blur="changeName(meinName)" v-model="meinName" id="input-default" placeholder="Ihr Name"></b-form-input> -->
					</b-col>
					<b-col v-if="meinName" cols="4">
						<b-button  
								variant="outline-primary"
								@click="showJitsi = !showJitsi">

									<span v-if="showJitsi">Meeting beenden</span>
									<span v-if="! showJitsi">Meeting starten</span>

						</b-button>
					</b-col>

				</b-row>

				<p v-if="showName" class="h5">Bitte geben Sie zunächst Ihren Vor- und Nachnamen ein, damit Sie für andere Meeting-Teilnehmer identifizierbar sind.</p>
				<p v-if="meinName && !showJitsi" class="h5">Klicken Sie nun auf "Meeting starten"</p>

			</b-card>
			<b-card class="mb-2 mt-1" v-if="meinName && showJitsi && hinweise">
				<p class="h5 font-weight-bold">Wichtige Hinweise</p>
				<ul>
					<li>
						- Wenn das Meeting noch nicht begonnen hat, erscheint ein Feld "Warten auf den Organisator". Bitte gedulden Sie sich in dem Fall, bis der Meeting-Initiator das Meeting startet.
					</li>
					<li>
						- Wenn das Feld "Konferenz beitreten" erscheint, klicken Sie auf "Beitritt anfragen". Der Initator des Meetings wird Sie darauf hin freischalten.
					</li>

					<li>
						- Der Browser fragt Sie, ob Sie den Zugriff auf Mikrofon und/oder Kamera zulassen möchten. Bitte lassen Sie dies zu. 
						Sowohl Mikrofon als auch Kamera bleiben <b>zunächst trotzdem deaktiviert</b>. Diese können Sie einzeln im unteren Bereich mittig, links und rechts neben dem roten Hörer, an- und ausschalten.
					</li>
					<li>
						- Wenn Sie an einem Video-Meeting teilnehmen möchten, benötigen eine Webkamera, ein Mikrofon und einen Lautsprecher / Kopfhörer.
					</li>
</ul>

					<b-button  
								variant="outline-primary"
								@click="hinweise = false">
								Ich habe die Hinweise gelesen.
					</b-button>

			</b-card>

				<vue-jitsi-meet
					v-if="showJitsi"
					ref="jitsiRef"
					domain="jitsi.archi-app.de"
					:options="jitsiOptions"
					
				></vue-jitsi-meet>

		</div>
    </div>
</template>

<script>

import { JitsiMeet } from '@mycure/vue-jitsi-meet';
import { forAllMixin } from '../../mixins/forAllMixin.js'

export default {

  mixins: [forAllMixin],

  components: {
    VueJitsiMeet: JitsiMeet
  },

    data(){
        return{
			  raum: this.$route.params.raum,
			  showJitsi:false,
			  meinName:'',
			  showName:true,
			  hinweise:true,
			

	  	}
    },

  mounted() {

  },


  computed: {
	
	hereHeight() {
		  var hierHeight = "500 px";

		  if(this.itsMobile)
		  hierHeight = this.window.height - 50 + 'px'
		  else
		  hierHeight = this.window.height - 170 + 'px'

		  return hierHeight;
 	},

 	nameState() {
        return this.meinName.length > 0 ? true : false
      },

    jitsiOptions () {
      return {
        roomName: this.raum,
        noSSL: false,
        userInfo: {
          email: 'user@email.com',
          displayName: this.meinName,
        },
        configOverwrite: {
          enableNoisyMicDetection: false,
		startWithVideoMuted:true,
          startWithAudioMuted:true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false
        },
        onload: this.onIFrameLoad
      };
    },
  },


  methods: {

startMeeting(){

if(!this.showJitsi)
this.showJitsi = true
},
	  hideName(name){
		  if(name.length>0)
			this.showName = false
			else
			this.showName = true
	  },

	  changeName(name){

		  if( this.$refs.jitsiRef!=undefined)
      this.$refs.jitsiRef.executeCommand('displayName', name);

	  },

    onIFrameLoad () {

    },
  },
};
</script>

<style scoped>

.card-body {padding: 5px 1.25rem }

ul {
    list-style-type: disc !important;
    padding-left:1em !important;
    margin-left:1em;
}

</style>