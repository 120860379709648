<template>
			<div v-if="formular!=undefined" >

				{{ formular }}
		<br><br>
		{{ actualformular }}
		<br><br>
		{{ options_formulare_neu }}

			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(24.8703589) + 'px'}">
													Bauantrag / Antrag auf Vorbescheid vom
													</div>
<b-popover 
												v-if="formular.antrag_vom"
												:show="formular.antrag_vom.showPop==true"
												
												ref='antrag_vom' 
												target='antrag_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antrag_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antrag_vom.size}}</span>
												<b-button @click='plus("antrag_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antrag_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antrag_vom" type="text" 
												v-if="formular.antrag_vom!=undefined" 
												class='textfeld' v-model='formular.antrag_vom.text' 
												style='width:100%' 
												@focus='formular.antrag_vom.showPop=true'
												@blur ='formular.antrag_vom.showPop=false'
												:disabled='formular.antrag_vom.isDisabled==true || formular.antrag_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.antrag_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(20.6891579) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(89.9105724) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(25.7101497) + 'px', 
		
														'font-size': formular.antrag_vom.size + 'px', 
														'text-align': formular.antrag_vom.schriftausrichtung, 
														'letter-spacing': formular.antrag_vom.letterSpacing + 'px',
														'font-weight': formular.antrag_vom.schriftart }" 
												/>

<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(118.3587133) + 'px', 
														top: gib_hoehe_in_mm(22.8599689) + 'px'}">
													Betriebsbeschreibung für gewerbliche
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(148.6203733) + 'px', 
														top: gib_hoehe_in_mm(27.7272289) + 'px'}">
													Anlagen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(35.1988257) + 'px'}">
													Bauherrschaft
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(88.4114376) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(21.3905496) + 'px',
										
										 top: gib_hoehe_in_mm(38.1975575) + 'px'}"><b-popover 
												v-if="formular.bauherrschaft"
												:show="formular.bauherrschaft.showPop==true"
												
												ref='bauherrschaft' 
												target='bauherrschaft'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherrschaft.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherrschaft.size}}</span>
												<b-button @click='plus("bauherrschaft", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherrschaft", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherrschaft!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherrschaft.text"
												
												@focus='formular.bauherrschaft.showPop=true'
												@blur ='formular.bauherrschaft.showPop=false'
												
												:disabled="formular.bauherrschaft.isDisabled==true || formular.bauherrschaft.isDisabled=='true'"
												:style="{'font-size': formular.bauherrschaft.size + 'px',
														'height': formular.bauherrschaft.height + 'px',
														'text-align': formular.bauherrschaft.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherrschaft.letterSpacing + 'px',
														'font-weight': formular.bauherrschaft.schriftart + 'px', }"

												id='bauherrschaft'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(112.5991223) + 'px', 
														top: gib_hoehe_in_mm(35.1988257) + 'px'}">
													Betreibende
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(88.4114376) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(112.599475) + 'px',
										
										 top: gib_hoehe_in_mm(38.1975575) + 'px'}"><b-popover 
												v-if="formular.betreibende"
												:show="formular.betreibende.showPop==true"
												
												ref='betreibende' 
												target='betreibende'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betreibende.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betreibende.size}}</span>
												<b-button @click='plus("betreibende", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betreibende", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.betreibende!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.betreibende.text"
												
												@focus='formular.betreibende.showPop=true'
												@blur ='formular.betreibende.showPop=false'
												
												:disabled="formular.betreibende.isDisabled==true || formular.betreibende.isDisabled=='true'"
												:style="{'font-size': formular.betreibende.size + 'px',
														'height': formular.betreibende.height + 'px',
														'text-align': formular.betreibende.schriftausrichtung + 'px',
														'letter-spacing': formular.betreibende.letterSpacing + 'px',
														'font-weight': formular.betreibende.schriftart + 'px', }"

												id='betreibende'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(163.8993373) + 'px', 
														top: gib_hoehe_in_mm(11.609897) + 'px'}">
													Anlage I/8 zur VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(194.6230343) + 'px', 
														top: gib_hoehe_in_mm(14.907642) + 'px'}">
													Blatt 1
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(92.6680453) + 'px', 
														top: gib_hoehe_in_mm(107.385582) + 'px'}">
													an Werktagen
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.1451453) + 'px', 
														top: gib_hoehe_in_mm(111.4924208) + 'px'}">
													von
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.8881453) + 'px', 
														top: gib_hoehe_in_mm(111.4924208) + 'px'}">
													bis
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(152.0909413) + 'px', 
														top: gib_hoehe_in_mm(107.385582) + 'px'}">
													an Sonn und Feiertagen
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(179.7708373) + 'px', 
														top: gib_hoehe_in_mm(111.4924208) + 'px'}">
													bis
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(144.4267703) + 'px', 
														top: gib_hoehe_in_mm(111.4924208) + 'px'}">
													von
													</div>
<b-popover 
												v-if="formular.betriebszeit_werkt_von"
												:show="formular.betriebszeit_werkt_von.showPop==true"
												
												ref='betriebszeit_werkt_von' 
												target='betriebszeit_werkt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebszeit_werkt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebszeit_werkt_von.size}}</span>
												<b-button @click='plus("betriebszeit_werkt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebszeit_werkt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebszeit_werkt_von" type="text" 
												v-if="formular.betriebszeit_werkt_von!=undefined" 
												class='textfeld' v-model='formular.betriebszeit_werkt_von.text' 
												style='width:100%' 
												@focus='formular.betriebszeit_werkt_von.showPop=true'
												@blur ='formular.betriebszeit_werkt_von.showPop=false'
												:disabled='formular.betriebszeit_werkt_von.isDisabled==true || formular.betriebszeit_werkt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebszeit_werkt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.536123) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(71.375482) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(116.4189465) + 'px', 
		
														'font-size': formular.betriebszeit_werkt_von.size + 'px', 
														'text-align': formular.betriebszeit_werkt_von.schriftausrichtung, 
														'letter-spacing': formular.betriebszeit_werkt_von.letterSpacing + 'px',
														'font-weight': formular.betriebszeit_werkt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebszeit_werkt_bis"
												:show="formular.betriebszeit_werkt_bis.showPop==true"
												
												ref='betriebszeit_werkt_bis' 
												target='betriebszeit_werkt_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebszeit_werkt_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebszeit_werkt_bis.size}}</span>
												<b-button @click='plus("betriebszeit_werkt_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebszeit_werkt_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebszeit_werkt_bis" type="text" 
												v-if="formular.betriebszeit_werkt_bis!=undefined" 
												class='textfeld' v-model='formular.betriebszeit_werkt_bis.text' 
												style='width:100%' 
												@focus='formular.betriebszeit_werkt_bis.showPop=true'
												@blur ='formular.betriebszeit_werkt_bis.showPop=false'
												:disabled='formular.betriebszeit_werkt_bis.isDisabled==true || formular.betriebszeit_werkt_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebszeit_werkt_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(30.280129) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(101.467846) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(116.4189465) + 'px', 
		
														'font-size': formular.betriebszeit_werkt_bis.size + 'px', 
														'text-align': formular.betriebszeit_werkt_bis.schriftausrichtung, 
														'letter-spacing': formular.betriebszeit_werkt_bis.letterSpacing + 'px',
														'font-weight': formular.betriebszeit_werkt_bis.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebszeit_sonn_von"
												:show="formular.betriebszeit_sonn_von.showPop==true"
												
												ref='betriebszeit_sonn_von' 
												target='betriebszeit_sonn_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebszeit_sonn_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebszeit_sonn_von.size}}</span>
												<b-button @click='plus("betriebszeit_sonn_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebszeit_sonn_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebszeit_sonn_von" type="text" 
												v-if="formular.betriebszeit_sonn_von!=undefined" 
												class='textfeld' v-model='formular.betriebszeit_sonn_von.text' 
												style='width:100%' 
												@focus='formular.betriebszeit_sonn_von.showPop=true'
												@blur ='formular.betriebszeit_sonn_von.showPop=false'
												:disabled='formular.betriebszeit_sonn_von.isDisabled==true || formular.betriebszeit_sonn_von.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebszeit_sonn_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(24.9423672) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(133.9917238) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(116.4189465) + 'px', 
		
														'font-size': formular.betriebszeit_sonn_von.size + 'px', 
														'text-align': formular.betriebszeit_sonn_von.schriftausrichtung, 
														'letter-spacing': formular.betriebszeit_sonn_von.letterSpacing + 'px',
														'font-weight': formular.betriebszeit_sonn_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.betriebszeit_sonn_bis"
												:show="formular.betriebszeit_sonn_bis.showPop==true"
												
												ref='betriebszeit_sonn_bis' 
												target='betriebszeit_sonn_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.betriebszeit_sonn_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.betriebszeit_sonn_bis.size}}</span>
												<b-button @click='plus("betriebszeit_sonn_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("betriebszeit_sonn_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="betriebszeit_sonn_bis" type="text" 
												v-if="formular.betriebszeit_sonn_bis!=undefined" 
												class='textfeld' v-model='formular.betriebszeit_sonn_bis.text' 
												style='width:100%' 
												@focus='formular.betriebszeit_sonn_bis.showPop=true'
												@blur ='formular.betriebszeit_sonn_bis.showPop=false'
												:disabled='formular.betriebszeit_sonn_bis.isDisabled==true || formular.betriebszeit_sonn_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.betriebszeit_sonn_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.3059792) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(161.1686696) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(116.4189465) + 'px', 
		
														'font-size': formular.betriebszeit_sonn_bis.size + 'px', 
														'text-align': formular.betriebszeit_sonn_bis.schriftausrichtung, 
														'letter-spacing': formular.betriebszeit_sonn_bis.letterSpacing + 'px',
														'font-weight': formular.betriebszeit_sonn_bis.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(50.0510227) + 'px'}">
													Grundstück (Ort, Straße, Hausnummer)
													</div>
<b-popover 
												v-if="formular.baugrundstueck"
												:show="formular.baugrundstueck.showPop==true"
												
												ref='baugrundstueck' 
												target='baugrundstueck'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck.size}}</span>
												<b-button @click='plus("baugrundstueck", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck" type="text" 
												v-if="formular.baugrundstueck!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck.showPop=true'
												@blur ='formular.baugrundstueck.showPop=false'
												:disabled='formular.baugrundstueck.isDisabled==true || formular.baugrundstueck.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck.onylNumber) " 
												:style="{'width': spalte_breit_mm(179.620363) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(21.6405496) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(53.4810423) + 'px', 
		
														'font-size': formular.baugrundstueck.size + 'px', 
														'text-align': formular.baugrundstueck.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck.schriftart }" 
												/>

<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(59.8264559) + 'px'}">
													1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(59.7160608) + 'px'}">
													Art des Betriebes
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(63.3559248) + 'px'}">
													oder der Anlage
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(71.0130418) + 'px'}">
													Erzeugnisse
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(82.1481335) + 'px'}">
													Dienstleistung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(93.2334945) + 'px'}">
													Rohstoffe, Materialien,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(96.9192095) + 'px'}">
													Betriebsstoffe, Reststoffe,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(100.5132225) + 'px'}">
													Waren
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(105.8495735) + 'px'}">
													Betriebszeit
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(105.9596159) + 'px'}">
													2
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(122.2966799) + 'px'}">
													3
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(130.5533869) + 'px'}">
													4
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(122.1866375) + 'px'}">
													Gesamtbeschäftigte am
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(125.8652985) + 'px'}">
													Betriebsort
													</div>
<b-popover 
												v-if="formular.gesamtbeschaeftigte"
												:show="formular.gesamtbeschaeftigte.showPop==true"
												
												ref='gesamtbeschaeftigte' 
												target='gesamtbeschaeftigte'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gesamtbeschaeftigte.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gesamtbeschaeftigte.size}}</span>
												<b-button @click='plus("gesamtbeschaeftigte", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gesamtbeschaeftigte", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gesamtbeschaeftigte" type="text" 
												v-if="formular.gesamtbeschaeftigte!=undefined" 
												class='textfeld' v-model='formular.gesamtbeschaeftigte.text' 
												style='width:100%' 
												@focus='formular.gesamtbeschaeftigte.showPop=true'
												@blur ='formular.gesamtbeschaeftigte.showPop=false'
												:disabled='formular.gesamtbeschaeftigte.isDisabled==true || formular.gesamtbeschaeftigte.isDisabled==true' 
												@keypress="isNumber($event, formular.gesamtbeschaeftigte.onylNumber) " 
												:style="{'width': spalte_breit_mm(130.0991668) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(71.375482) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(124.3663356) + 'px', 
		
														'font-size': formular.gesamtbeschaeftigte.size + 'px', 
														'text-align': formular.gesamtbeschaeftigte.schriftausrichtung, 
														'letter-spacing': formular.gesamtbeschaeftigte.letterSpacing + 'px',
														'font-weight': formular.gesamtbeschaeftigte.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(130.6514375) + 'px'}">
													Immissionsschutz
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(135.996606) + 'px'}">
													4.1 Luftverunreinigung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(176.03511) + 'px'}">
													4.2 Geräusche
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(140.3743184) + 'px'}">
													(z. B. durch Rauch, Ruß, Staub, Gase,
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(142.8291104) + 'px'}">
													Aerosole, Dämpfe, Geruchsstoffe)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(145.988597) + 'px'}">
													Art der Verunreinigung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(150.7553375) + 'px'}">
													Lage der
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(154.4339985) + 'px'}">
													Emissionsöffnungen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(158.6582864) + 'px'}">
													(Grundriss und Höhenangaben)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(163.4913345) + 'px'}">
													Maßnahmen zur
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(167.1311985) + 'px'}">
													Vermeidung schädlicher
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(170.7710625) + 'px'}">
													Luftverunreinigungen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(180.4128224) + 'px'}">
													(z.B. durch Anlagen, Tätigkeiten,
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(182.8217634) + 'px'}">
													Fahrzeugverkehr auf dem Grundstück)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(186.0077025) + 'px'}">
													Ursache, Dauer,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(189.6475665) + 'px'}">
													Häufigkeit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(195.8268705) + 'px'}">
													Lage der Geräuschquellen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(200.1322794) + 'px'}">
													(Austrittsöffnungen, ggf.
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(202.5482744) + 'px'}">
													Richtungsangaben)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(207.4271735) + 'px'}">
													Maßnahmen zur
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(211.0670375) + 'px'}">
													Vermeidung schädlicher
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(214.7456985) + 'px'}">
													Geräusche
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.3905496) + 'px', 
														top: gib_hoehe_in_mm(220.9412267) + 'px'}">
													4.3 Erschütterungen,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(224.6918385) + 'px'}">
													mechanische
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(228.3317025) + 'px'}">
													Schwingungen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(236.3309385) + 'px'}">
													Art, Ursache, Dauer und
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(240.0131265) + 'px'}">
													Häufigkeit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(245.4309512) + 'px'}">
													Lage der Erschütterungs
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(249.0708152) + 'px'}">
													oder Schwingungsquellen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(256.9889302) + 'px'}">
													Maßnahmen zur
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(260.6675912) + 'px'}">
													Vermeidung schädlicher
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(264.3074552) + 'px'}">
													Erschütterungen oder
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.8854246) + 'px', 
														top: gib_hoehe_in_mm(267.9085222) + 'px'}">
													Schwingungen
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(59.9309315) + 'px'}"><b-popover 
												v-if="formular.art_des_betriebes"
												:show="formular.art_des_betriebes.showPop==true"
												
												ref='art_des_betriebes' 
												target='art_des_betriebes'  
												
												>
												<template slot='title'>
													<b-button @click="formular.art_des_betriebes.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.art_des_betriebes.size}}</span>
												<b-button @click='plus("art_des_betriebes", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("art_des_betriebes", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.art_des_betriebes!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.art_des_betriebes.text"
												
												@focus='formular.art_des_betriebes.showPop=true'
												@blur ='formular.art_des_betriebes.showPop=false'
												
												:disabled="formular.art_des_betriebes.isDisabled==true || formular.art_des_betriebes.isDisabled=='true'"
												:style="{'font-size': formular.art_des_betriebes.size + 'px',
														'height': formular.art_des_betriebes.height + 'px',
														'text-align': formular.art_des_betriebes.schriftausrichtung + 'px',
														'letter-spacing': formular.art_des_betriebes.letterSpacing + 'px',
														'font-weight': formular.art_des_betriebes.schriftart + 'px', }"

												id='art_des_betriebes'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(71.1929952) + 'px'}"><b-popover 
												v-if="formular.erzeugnisse"
												:show="formular.erzeugnisse.showPop==true"
												
												ref='erzeugnisse' 
												target='erzeugnisse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.erzeugnisse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.erzeugnisse.size}}</span>
												<b-button @click='plus("erzeugnisse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("erzeugnisse", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.erzeugnisse!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.erzeugnisse.text"
												
												@focus='formular.erzeugnisse.showPop=true'
												@blur ='formular.erzeugnisse.showPop=false'
												
												:disabled="formular.erzeugnisse.isDisabled==true || formular.erzeugnisse.isDisabled=='true'"
												:style="{'font-size': formular.erzeugnisse.size + 'px',
														'height': formular.erzeugnisse.height + 'px',
														'text-align': formular.erzeugnisse.schriftausrichtung + 'px',
														'letter-spacing': formular.erzeugnisse.letterSpacing + 'px',
														'font-weight': formular.erzeugnisse.schriftart + 'px', }"

												id='erzeugnisse'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(82.2402646) + 'px'}"><b-popover 
												v-if="formular.dienstleistung"
												:show="formular.dienstleistung.showPop==true"
												
												ref='dienstleistung' 
												target='dienstleistung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.dienstleistung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.dienstleistung.size}}</span>
												<b-button @click='plus("dienstleistung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("dienstleistung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.dienstleistung!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.dienstleistung.text"
												
												@focus='formular.dienstleistung.showPop=true'
												@blur ='formular.dienstleistung.showPop=false'
												
												:disabled="formular.dienstleistung.isDisabled==true || formular.dienstleistung.isDisabled=='true'"
												:style="{'font-size': formular.dienstleistung.size + 'px',
														'height': formular.dienstleistung.height + 'px',
														'text-align': formular.dienstleistung.schriftausrichtung + 'px',
														'letter-spacing': formular.dienstleistung.letterSpacing + 'px',
														'font-weight': formular.dienstleistung.schriftart + 'px', }"

												id='dienstleistung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(93.4931581) + 'px'}"><b-popover 
												v-if="formular.rohstoffe_materialien"
												:show="formular.rohstoffe_materialien.showPop==true"
												
												ref='rohstoffe_materialien' 
												target='rohstoffe_materialien'  
												
												>
												<template slot='title'>
													<b-button @click="formular.rohstoffe_materialien.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.rohstoffe_materialien.size}}</span>
												<b-button @click='plus("rohstoffe_materialien", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("rohstoffe_materialien", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.rohstoffe_materialien!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.rohstoffe_materialien.text"
												
												@focus='formular.rohstoffe_materialien.showPop=true'
												@blur ='formular.rohstoffe_materialien.showPop=false'
												
												:disabled="formular.rohstoffe_materialien.isDisabled==true || formular.rohstoffe_materialien.isDisabled=='true'"
												:style="{'font-size': formular.rohstoffe_materialien.size + 'px',
														'height': formular.rohstoffe_materialien.height + 'px',
														'text-align': formular.rohstoffe_materialien.schriftausrichtung + 'px',
														'letter-spacing': formular.rohstoffe_materialien.letterSpacing + 'px',
														'font-weight': formular.rohstoffe_materialien.schriftart + 'px', }"

												id='rohstoffe_materialien'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(136.334569) + 'px'}"><b-popover 
												v-if="formular.luftverunreinigung"
												:show="formular.luftverunreinigung.showPop==true"
												
												ref='luftverunreinigung' 
												target='luftverunreinigung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.luftverunreinigung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.luftverunreinigung.size}}</span>
												<b-button @click='plus("luftverunreinigung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("luftverunreinigung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.luftverunreinigung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.luftverunreinigung.text"
												
												@focus='formular.luftverunreinigung.showPop=true'
												@blur ='formular.luftverunreinigung.showPop=false'
												
												:disabled="formular.luftverunreinigung.isDisabled==true || formular.luftverunreinigung.isDisabled=='true'"
												:style="{'font-size': formular.luftverunreinigung.size + 'px',
														'height': formular.luftverunreinigung.height + 'px',
														'text-align': formular.luftverunreinigung.schriftausrichtung + 'px',
														'letter-spacing': formular.luftverunreinigung.letterSpacing + 'px',
														'font-weight': formular.luftverunreinigung.schriftart + 'px', }"

												id='luftverunreinigung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(150.7963271) + 'px'}"><b-popover 
												v-if="formular.lage_emissionsoeffnungen"
												:show="formular.lage_emissionsoeffnungen.showPop==true"
												
												ref='lage_emissionsoeffnungen' 
												target='lage_emissionsoeffnungen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lage_emissionsoeffnungen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lage_emissionsoeffnungen.size}}</span>
												<b-button @click='plus("lage_emissionsoeffnungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lage_emissionsoeffnungen", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.lage_emissionsoeffnungen!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.lage_emissionsoeffnungen.text"
												
												@focus='formular.lage_emissionsoeffnungen.showPop=true'
												@blur ='formular.lage_emissionsoeffnungen.showPop=false'
												
												:disabled="formular.lage_emissionsoeffnungen.isDisabled==true || formular.lage_emissionsoeffnungen.isDisabled=='true'"
												:style="{'font-size': formular.lage_emissionsoeffnungen.size + 'px',
														'height': formular.lage_emissionsoeffnungen.height + 'px',
														'text-align': formular.lage_emissionsoeffnungen.schriftausrichtung + 'px',
														'letter-spacing': formular.lage_emissionsoeffnungen.letterSpacing + 'px',
														'font-weight': formular.lage_emissionsoeffnungen.schriftart + 'px', }"

												id='lage_emissionsoeffnungen'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(163.4067629) + 'px'}"><b-popover 
												v-if="formular.vermeidung_luftverunrein"
												:show="formular.vermeidung_luftverunrein.showPop==true"
												
												ref='vermeidung_luftverunrein' 
												target='vermeidung_luftverunrein'  
												
												>
												<template slot='title'>
													<b-button @click="formular.vermeidung_luftverunrein.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.vermeidung_luftverunrein.size}}</span>
												<b-button @click='plus("vermeidung_luftverunrein", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("vermeidung_luftverunrein", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.vermeidung_luftverunrein!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.vermeidung_luftverunrein.text"
												
												@focus='formular.vermeidung_luftverunrein.showPop=true'
												@blur ='formular.vermeidung_luftverunrein.showPop=false'
												
												:disabled="formular.vermeidung_luftverunrein.isDisabled==true || formular.vermeidung_luftverunrein.isDisabled=='true'"
												:style="{'font-size': formular.vermeidung_luftverunrein.size + 'px',
														'height': formular.vermeidung_luftverunrein.height + 'px',
														'text-align': formular.vermeidung_luftverunrein.schriftausrichtung + 'px',
														'letter-spacing': formular.vermeidung_luftverunrein.letterSpacing + 'px',
														'font-weight': formular.vermeidung_luftverunrein.schriftart + 'px', }"

												id='vermeidung_luftverunrein'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(63.0660629) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(176.0179041) + 'px'}"><b-popover 
												v-if="formular.gerauesche"
												:show="formular.gerauesche.showPop==true"
												
												ref='gerauesche' 
												target='gerauesche'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gerauesche.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gerauesche.size}}</span>
												<b-button @click='plus("gerauesche", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gerauesche", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.gerauesche!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.gerauesche.text"
												
												@focus='formular.gerauesche.showPop=true'
												@blur ='formular.gerauesche.showPop=false'
												
												:disabled="formular.gerauesche.isDisabled==true || formular.gerauesche.isDisabled=='true'"
												:style="{'font-size': formular.gerauesche.size + 'px',
														'height': formular.gerauesche.height + 'px',
														'text-align': formular.gerauesche.schriftausrichtung + 'px',
														'letter-spacing': formular.gerauesche.letterSpacing + 'px',
														'font-weight': formular.gerauesche.schriftart + 'px', }"

												id='gerauesche'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(195.8364698) + 'px'}"><b-popover 
												v-if="formular.lage_gerauschquellen"
												:show="formular.lage_gerauschquellen.showPop==true"
												
												ref='lage_gerauschquellen' 
												target='lage_gerauschquellen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lage_gerauschquellen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lage_gerauschquellen.size}}</span>
												<b-button @click='plus("lage_gerauschquellen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lage_gerauschquellen", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.lage_gerauschquellen!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.lage_gerauschquellen.text"
												
												@focus='formular.lage_gerauschquellen.showPop=true'
												@blur ='formular.lage_gerauschquellen.showPop=false'
												
												:disabled="formular.lage_gerauschquellen.isDisabled==true || formular.lage_gerauschquellen.isDisabled=='true'"
												:style="{'font-size': formular.lage_gerauschquellen.size + 'px',
														'height': formular.lage_gerauschquellen.height + 'px',
														'text-align': formular.lage_gerauschquellen.schriftausrichtung + 'px',
														'letter-spacing': formular.lage_gerauschquellen.letterSpacing + 'px',
														'font-weight': formular.lage_gerauschquellen.schriftart + 'px', }"

												id='lage_gerauschquellen'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(208.1509903) + 'px'}"><b-popover 
												v-if="formular.vermeidung_geraeusche"
												:show="formular.vermeidung_geraeusche.showPop==true"
												
												ref='vermeidung_geraeusche' 
												target='vermeidung_geraeusche'  
												
												>
												<template slot='title'>
													<b-button @click="formular.vermeidung_geraeusche.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.vermeidung_geraeusche.size}}</span>
												<b-button @click='plus("vermeidung_geraeusche", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("vermeidung_geraeusche", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.vermeidung_geraeusche!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.vermeidung_geraeusche.text"
												
												@focus='formular.vermeidung_geraeusche.showPop=true'
												@blur ='formular.vermeidung_geraeusche.showPop=false'
												
												:disabled="formular.vermeidung_geraeusche.isDisabled==true || formular.vermeidung_geraeusche.isDisabled=='true'"
												:style="{'font-size': formular.vermeidung_geraeusche.size + 'px',
														'height': formular.vermeidung_geraeusche.height + 'px',
														'text-align': formular.vermeidung_geraeusche.schriftausrichtung + 'px',
														'letter-spacing': formular.vermeidung_geraeusche.letterSpacing + 'px',
														'font-weight': formular.vermeidung_geraeusche.schriftart + 'px', }"

												id='vermeidung_geraeusche'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(59.1990601) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(221.2319279) + 'px'}"><b-popover 
												v-if="formular.erschuetterungen"
												:show="formular.erschuetterungen.showPop==true"
												
												ref='erschuetterungen' 
												target='erschuetterungen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.erschuetterungen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.erschuetterungen.size}}</span>
												<b-button @click='plus("erschuetterungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("erschuetterungen", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.erschuetterungen!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.erschuetterungen.text"
												
												@focus='formular.erschuetterungen.showPop=true'
												@blur ='formular.erschuetterungen.showPop=false'
												
												:disabled="formular.erschuetterungen.isDisabled==true || formular.erschuetterungen.isDisabled=='true'"
												:style="{'font-size': formular.erschuetterungen.size + 'px',
														'height': formular.erschuetterungen.height + 'px',
														'text-align': formular.erschuetterungen.schriftausrichtung + 'px',
														'letter-spacing': formular.erschuetterungen.letterSpacing + 'px',
														'font-weight': formular.erschuetterungen.schriftart + 'px', }"

												id='erschuetterungen'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(245.3495539) + 'px'}"><b-popover 
												v-if="formular.lage_erschuetterung"
												:show="formular.lage_erschuetterung.showPop==true"
												
												ref='lage_erschuetterung' 
												target='lage_erschuetterung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lage_erschuetterung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lage_erschuetterung.size}}</span>
												<b-button @click='plus("lage_erschuetterung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lage_erschuetterung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.lage_erschuetterung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.lage_erschuetterung.text"
												
												@focus='formular.lage_erschuetterung.showPop=true'
												@blur ='formular.lage_erschuetterung.showPop=false'
												
												:disabled="formular.lage_erschuetterung.isDisabled==true || formular.lage_erschuetterung.isDisabled=='true'"
												:style="{'font-size': formular.lage_erschuetterung.size + 'px',
														'height': formular.lage_erschuetterung.height + 'px',
														'text-align': formular.lage_erschuetterung.schriftausrichtung + 'px',
														'letter-spacing': formular.lage_erschuetterung.letterSpacing + 'px',
														'font-weight': formular.lage_erschuetterung.schriftart + 'px', }"

												id='lage_erschuetterung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6483207) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(70.3625919) + 'px',
										
										 top: gib_hoehe_in_mm(257.2880962) + 'px'}"><b-popover 
												v-if="formular.massnahmen_erschuetterung"
												:show="formular.massnahmen_erschuetterung.showPop==true"
												
												ref='massnahmen_erschuetterung' 
												target='massnahmen_erschuetterung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.massnahmen_erschuetterung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.massnahmen_erschuetterung.size}}</span>
												<b-button @click='plus("massnahmen_erschuetterung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("massnahmen_erschuetterung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.massnahmen_erschuetterung!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.massnahmen_erschuetterung.text"
												
												@focus='formular.massnahmen_erschuetterung.showPop=true'
												@blur ='formular.massnahmen_erschuetterung.showPop=false'
												
												:disabled="formular.massnahmen_erschuetterung.isDisabled==true || formular.massnahmen_erschuetterung.isDisabled=='true'"
												:style="{'font-size': formular.massnahmen_erschuetterung.size + 'px',
														'height': formular.massnahmen_erschuetterung.height + 'px',
														'text-align': formular.massnahmen_erschuetterung.schriftausrichtung + 'px',
														'letter-spacing': formular.massnahmen_erschuetterung.letterSpacing + 'px',
														'font-weight': formular.massnahmen_erschuetterung.schriftart + 'px', }"

												id='massnahmen_erschuetterung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(142.4410693) + 'px', 
														top: gib_hoehe_in_mm(176.123285) + 'px'}">
													Tageszeit
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(142.8184583) + 'px', 
														top: gib_hoehe_in_mm(180.059417) + 'px'}">
													von bis
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(176.8504813) + 'px', 
														top: gib_hoehe_in_mm(176.123285) + 'px'}">
													Nachtzeit
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(173.9265983) + 'px', 
														top: gib_hoehe_in_mm(180.059417) + 'px'}">
													(22.00 6.00)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(177.3971663) + 'px', 
														top: gib_hoehe_in_mm(184.034346) + 'px'}">
													von bis
													</div>
<b-popover 
												v-if="formular.gerauesch_tageszeit_von_bis"
												:show="formular.gerauesch_tageszeit_von_bis.showPop==true"
												
												ref='gerauesch_tageszeit_von_bis' 
												target='gerauesch_tageszeit_von_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gerauesch_tageszeit_von_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gerauesch_tageszeit_von_bis.size}}</span>
												<b-button @click='plus("gerauesch_tageszeit_von_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gerauesch_tageszeit_von_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gerauesch_tageszeit_von_bis" type="text" 
												v-if="formular.gerauesch_tageszeit_von_bis!=undefined" 
												class='textfeld' v-model='formular.gerauesch_tageszeit_von_bis.text' 
												style='width:100%' 
												@focus='formular.gerauesch_tageszeit_von_bis.showPop=true'
												@blur ='formular.gerauesch_tageszeit_von_bis.showPop=false'
												:disabled='formular.gerauesch_tageszeit_von_bis.isDisabled==true || formular.gerauesch_tageszeit_von_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.gerauesch_tageszeit_von_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.5357703) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(134.8184526) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(190.2418781) + 'px', 
		
														'font-size': formular.gerauesch_tageszeit_von_bis.size + 'px', 
														'text-align': formular.gerauesch_tageszeit_von_bis.schriftausrichtung, 
														'letter-spacing': formular.gerauesch_tageszeit_von_bis.letterSpacing + 'px',
														'font-weight': formular.gerauesch_tageszeit_von_bis.schriftart }" 
												/>

<b-popover 
												v-if="formular.gerauesch_nachtzeit_von_bis"
												:show="formular.gerauesch_nachtzeit_von_bis.showPop==true"
												
												ref='gerauesch_nachtzeit_von_bis' 
												target='gerauesch_nachtzeit_von_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gerauesch_nachtzeit_von_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gerauesch_nachtzeit_von_bis.size}}</span>
												<b-button @click='plus("gerauesch_nachtzeit_von_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gerauesch_nachtzeit_von_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gerauesch_nachtzeit_von_bis" type="text" 
												v-if="formular.gerauesch_nachtzeit_von_bis!=undefined" 
												class='textfeld' v-model='formular.gerauesch_nachtzeit_von_bis.text' 
												style='width:100%' 
												@focus='formular.gerauesch_nachtzeit_von_bis.showPop=true'
												@blur ='formular.gerauesch_nachtzeit_von_bis.showPop=false'
												:disabled='formular.gerauesch_nachtzeit_von_bis.isDisabled==true || formular.gerauesch_nachtzeit_von_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.gerauesch_nachtzeit_von_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(38.7234143) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.3882107) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(190.2418781) + 'px', 
		
														'font-size': formular.gerauesch_nachtzeit_von_bis.size + 'px', 
														'text-align': formular.gerauesch_nachtzeit_von_bis.schriftausrichtung, 
														'letter-spacing': formular.gerauesch_nachtzeit_von_bis.letterSpacing + 'px',
														'font-weight': formular.gerauesch_nachtzeit_von_bis.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(139.6441583) + 'px', 
														top: gib_hoehe_in_mm(223.2986735) + 'px'}">
													Tageszeit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(140.1943703) + 'px', 
														top: gib_hoehe_in_mm(227.6121945) + 'px'}">
													von bis
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(176.0040013) + 'px', 
														top: gib_hoehe_in_mm(221.0942985) + 'px'}">
													Nachtzeit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(172.7415263) + 'px', 
														top: gib_hoehe_in_mm(225.4536705) + 'px'}">
													(22.00 6.00)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(176.6353343) + 'px', 
														top: gib_hoehe_in_mm(229.8165695) + 'px'}">
													von bis
													</div>
<b-popover 
												v-if="formular.erschuetterungen_tageszeit_von_bis"
												:show="formular.erschuetterungen_tageszeit_von_bis.showPop==true"
												
												ref='erschuetterungen_tageszeit_von_bis' 
												target='erschuetterungen_tageszeit_von_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.erschuetterungen_tageszeit_von_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.erschuetterungen_tageszeit_von_bis.size}}</span>
												<b-button @click='plus("erschuetterungen_tageszeit_von_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("erschuetterungen_tageszeit_von_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="erschuetterungen_tageszeit_von_bis" type="text" 
												v-if="formular.erschuetterungen_tageszeit_von_bis!=undefined" 
												class='textfeld' v-model='formular.erschuetterungen_tageszeit_von_bis.text' 
												style='width:100%' 
												@focus='formular.erschuetterungen_tageszeit_von_bis.showPop=true'
												@blur ='formular.erschuetterungen_tageszeit_von_bis.showPop=false'
												:disabled='formular.erschuetterungen_tageszeit_von_bis.isDisabled==true || formular.erschuetterungen_tageszeit_von_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.erschuetterungen_tageszeit_von_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(30.675153) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(131.2843986) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(237.8204027) + 'px', 
		
														'font-size': formular.erschuetterungen_tageszeit_von_bis.size + 'px', 
														'text-align': formular.erschuetterungen_tageszeit_von_bis.schriftausrichtung, 
														'letter-spacing': formular.erschuetterungen_tageszeit_von_bis.letterSpacing + 'px',
														'font-weight': formular.erschuetterungen_tageszeit_von_bis.schriftart }" 
												/>

<b-popover 
												v-if="formular.erschuetterungen_nachtzeit_von_bis"
												:show="formular.erschuetterungen_nachtzeit_von_bis.showPop==true"
												
												ref='erschuetterungen_nachtzeit_von_bis' 
												target='erschuetterungen_nachtzeit_von_bis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.erschuetterungen_nachtzeit_von_bis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.erschuetterungen_nachtzeit_von_bis.size}}</span>
												<b-button @click='plus("erschuetterungen_nachtzeit_von_bis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("erschuetterungen_nachtzeit_von_bis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="erschuetterungen_nachtzeit_von_bis" type="text" 
												v-if="formular.erschuetterungen_nachtzeit_von_bis!=undefined" 
												class='textfeld' v-model='formular.erschuetterungen_nachtzeit_von_bis.text' 
												style='width:100%' 
												@focus='formular.erschuetterungen_nachtzeit_von_bis.showPop=true'
												@blur ='formular.erschuetterungen_nachtzeit_von_bis.showPop=false'
												:disabled='formular.erschuetterungen_nachtzeit_von_bis.isDisabled==true || formular.erschuetterungen_nachtzeit_von_bis.isDisabled==true' 
												@keypress="isNumber($event, formular.erschuetterungen_nachtzeit_von_bis.onylNumber) " 
												:style="{'width': spalte_breit_mm(38.7234143) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.3882107) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(237.8204027) + 'px', 
		
														'font-size': formular.erschuetterungen_nachtzeit_von_bis.size + 'px', 
														'text-align': formular.erschuetterungen_nachtzeit_von_bis.schriftausrichtung, 
														'letter-spacing': formular.erschuetterungen_nachtzeit_von_bis.letterSpacing + 'px',
														'font-weight': formular.erschuetterungen_nachtzeit_von_bis.schriftart }" 
												/>

<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(175.8347053) + 'px', 
														top: gib_hoehe_in_mm(275.4968627) + 'px'}">
													Fortsetzung Blatt 2
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.0573773) + 'px', 
													width: spalte_breit_mm(2.024498) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.12359249999997) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(210.0818753) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(2.0335014) + 'px', 
													top: gib_hoehe_in_mm(0.12359249999997) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.747916) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(35.000332) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(49.8461804) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(59.333105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(70.6812275) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(81.7665885) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(92.9013275) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(105.4701447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(121.806856) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(130.0589779) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(135.7296885) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(150.4164692) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(163.1136692) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(175.724105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(195.5394964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(207.8099294) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(220.6302217) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(245.0917302) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(256.6433606) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(182.5102582) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(274.7643812) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(170.1156748) + 'px', 
													width: spalte_breit_mm(32.5221143) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(280.5299681) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(170.1156748) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.7386149) + 'px', 
													top: gib_hoehe_in_mm(274.8913532) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(202.6377891) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(261.8820521) + 'px', 
													top: gib_hoehe_in_mm(18.747916) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(256.1164652) + 'px', 
													top: gib_hoehe_in_mm(18.747916) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.1139384) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(215.4081839) + 'px', 
													top: gib_hoehe_in_mm(59.333105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(111.329755) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(31.1640525) + 'px', 
													top: gib_hoehe_in_mm(18.747916) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(132.7298275) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(16.3115028) + 'px', 
													top: gib_hoehe_in_mm(105.5953532) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.0679572) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.7039255) + 'px', 
													top: gib_hoehe_in_mm(111.2029305) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(100.1170693) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.7039255) + 'px', 
													top: gib_hoehe_in_mm(111.2029305) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.1139384) + 'px', 
													width: spalte_breit_mm(132.5238507) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.201167) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.1139384) + 'px', 
													width: spalte_breit_mm(132.5238507) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(114.8572552) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(134.2725373) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(19.8705985) + 'px', 
													top: gib_hoehe_in_mm(175.724105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.8422954) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(19.8705985) + 'px', 
													top: gib_hoehe_in_mm(175.724105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(130.414352) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(24.4835618) + 'px', 
													top: gib_hoehe_in_mm(220.6302217) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.6313808) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(24.4835618) + 'px', 
													top: gib_hoehe_in_mm(220.6302217) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(130.3364053) + 'px', 
													width: spalte_breit_mm(72.3013838) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(233.9083186) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(134.184715) + 'px', 
													width: spalte_breit_mm(68.4530741) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(187.7871504) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(5.6530756) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.1919968) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.7806065) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(35.5653958) + 'px', 
													top: gib_hoehe_in_mm(105.4701447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.8316156) + 'px', 
													width: spalte_breit_mm(5.9489909) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(140.9993792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.7806065) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.3358315) + 'px', 
													top: gib_hoehe_in_mm(175.724105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(5.6530756) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(180.9599365) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.7806065) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.3827406) + 'px', 
													top: gib_hoehe_in_mm(220.6302217) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(5.6530756) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(225.9129623) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.7806065) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.8193832) + 'px', 
													top: gib_hoehe_in_mm(59.333105) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.1275309) + 'px', 
													width: spalte_breit_mm(5.6530756) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(65.0524882) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(164.9962343) + 'px', 
														top: gib_hoehe_in_mm(11.5711) + 'px'}">
													Anlage I/8 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(194.6230343) + 'px', 
														top: gib_hoehe_in_mm(14.868845) + 'px'}">
													Blatt 2
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(21.7091852) + 'px'}"><b-popover 
												v-if="formular.abfallstoffe_art"
												:show="formular.abfallstoffe_art.showPop==true"
												
												ref='abfallstoffe_art' 
												target='abfallstoffe_art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfallstoffe_art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfallstoffe_art.size}}</span>
												<b-button @click='plus("abfallstoffe_art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfallstoffe_art", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abfallstoffe_art!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abfallstoffe_art.text"
												
												@focus='formular.abfallstoffe_art.showPop=true'
												@blur ='formular.abfallstoffe_art.showPop=false'
												
												:disabled="formular.abfallstoffe_art.isDisabled==true || formular.abfallstoffe_art.isDisabled=='true'"
												:style="{'font-size': formular.abfallstoffe_art.size + 'px',
														'height': formular.abfallstoffe_art.height + 'px',
														'text-align': formular.abfallstoffe_art.schriftausrichtung + 'px',
														'letter-spacing': formular.abfallstoffe_art.letterSpacing + 'px',
														'font-weight': formular.abfallstoffe_art.schriftart + 'px', }"

												id='abfallstoffe_art'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(36.1173329) + 'px'}"><b-popover 
												v-if="formular.abfall_zwischenlagerung"
												:show="formular.abfall_zwischenlagerung.showPop==true"
												
												ref='abfall_zwischenlagerung' 
												target='abfall_zwischenlagerung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall_zwischenlagerung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall_zwischenlagerung.size}}</span>
												<b-button @click='plus("abfall_zwischenlagerung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall_zwischenlagerung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abfall_zwischenlagerung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abfall_zwischenlagerung.text"
												
												@focus='formular.abfall_zwischenlagerung.showPop=true'
												@blur ='formular.abfall_zwischenlagerung.showPop=false'
												
												:disabled="formular.abfall_zwischenlagerung.isDisabled==true || formular.abfall_zwischenlagerung.isDisabled=='true'"
												:style="{'font-size': formular.abfall_zwischenlagerung.size + 'px',
														'height': formular.abfall_zwischenlagerung.height + 'px',
														'text-align': formular.abfall_zwischenlagerung.schriftausrichtung + 'px',
														'letter-spacing': formular.abfall_zwischenlagerung.letterSpacing + 'px',
														'font-weight': formular.abfall_zwischenlagerung.schriftart + 'px', }"

												id='abfall_zwischenlagerung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(50.5769748) + 'px'}"><b-popover 
												v-if="formular.abfall_beseitigung"
												:show="formular.abfall_beseitigung.showPop==true"
												
												ref='abfall_beseitigung' 
												target='abfall_beseitigung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abfall_beseitigung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abfall_beseitigung.size}}</span>
												<b-button @click='plus("abfall_beseitigung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abfall_beseitigung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abfall_beseitigung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abfall_beseitigung.text"
												
												@focus='formular.abfall_beseitigung.showPop=true'
												@blur ='formular.abfall_beseitigung.showPop=false'
												
												:disabled="formular.abfall_beseitigung.isDisabled==true || formular.abfall_beseitigung.isDisabled=='true'"
												:style="{'font-size': formular.abfall_beseitigung.size + 'px',
														'height': formular.abfall_beseitigung.height + 'px',
														'text-align': formular.abfall_beseitigung.schriftausrichtung + 'px',
														'letter-spacing': formular.abfall_beseitigung.letterSpacing + 'px',
														'font-weight': formular.abfall_beseitigung.schriftart + 'px', }"

												id='abfall_beseitigung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(64.9343337) + 'px'}"><b-popover 
												v-if="formular.abwasser_art"
												:show="formular.abwasser_art.showPop==true"
												
												ref='abwasser_art' 
												target='abwasser_art'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abwasser_art.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abwasser_art.size}}</span>
												<b-button @click='plus("abwasser_art", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abwasser_art", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abwasser_art!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abwasser_art.text"
												
												@focus='formular.abwasser_art.showPop=true'
												@blur ='formular.abwasser_art.showPop=false'
												
												:disabled="formular.abwasser_art.isDisabled==true || formular.abwasser_art.isDisabled=='true'"
												:style="{'font-size': formular.abwasser_art.size + 'px',
														'height': formular.abwasser_art.height + 'px',
														'text-align': formular.abwasser_art.schriftausrichtung + 'px',
														'letter-spacing': formular.abwasser_art.letterSpacing + 'px',
														'font-weight': formular.abwasser_art.schriftart + 'px', }"

												id='abwasser_art'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(79.0388067) + 'px'}"><b-popover 
												v-if="formular.abwasser_behandlung"
												:show="formular.abwasser_behandlung.showPop==true"
												
												ref='abwasser_behandlung' 
												target='abwasser_behandlung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abwasser_behandlung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abwasser_behandlung.size}}</span>
												<b-button @click='plus("abwasser_behandlung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abwasser_behandlung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abwasser_behandlung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abwasser_behandlung.text"
												
												@focus='formular.abwasser_behandlung.showPop=true'
												@blur ='formular.abwasser_behandlung.showPop=false'
												
												:disabled="formular.abwasser_behandlung.isDisabled==true || formular.abwasser_behandlung.isDisabled=='true'"
												:style="{'font-size': formular.abwasser_behandlung.size + 'px',
														'height': formular.abwasser_behandlung.height + 'px',
														'text-align': formular.abwasser_behandlung.schriftausrichtung + 'px',
														'letter-spacing': formular.abwasser_behandlung.letterSpacing + 'px',
														'font-weight': formular.abwasser_behandlung.schriftart + 'px', }"

												id='abwasser_behandlung'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(93.9703612) + 'px'}"><b-popover 
												v-if="formular.abwasser_rueckstaende"
												:show="formular.abwasser_rueckstaende.showPop==true"
												
												ref='abwasser_rueckstaende' 
												target='abwasser_rueckstaende'  
												
												>
												<template slot='title'>
													<b-button @click="formular.abwasser_rueckstaende.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.abwasser_rueckstaende.size}}</span>
												<b-button @click='plus("abwasser_rueckstaende", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("abwasser_rueckstaende", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.abwasser_rueckstaende!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.abwasser_rueckstaende.text"
												
												@focus='formular.abwasser_rueckstaende.showPop=true'
												@blur ='formular.abwasser_rueckstaende.showPop=false'
												
												:disabled="formular.abwasser_rueckstaende.isDisabled==true || formular.abwasser_rueckstaende.isDisabled=='true'"
												:style="{'font-size': formular.abwasser_rueckstaende.size + 'px',
														'height': formular.abwasser_rueckstaende.height + 'px',
														'text-align': formular.abwasser_rueckstaende.schriftausrichtung + 'px',
														'letter-spacing': formular.abwasser_rueckstaende.letterSpacing + 'px',
														'font-weight': formular.abwasser_rueckstaende.schriftart + 'px', }"

												id='abwasser_rueckstaende'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(130.6472626) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(108.83455) + 'px'}"><b-popover 
												v-if="formular.verfahren_andere_vorschriften"
												:show="formular.verfahren_andere_vorschriften.showPop==true"
												
												ref='verfahren_andere_vorschriften' 
												target='verfahren_andere_vorschriften'  
												
												>
												<template slot='title'>
													<b-button @click="formular.verfahren_andere_vorschriften.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.verfahren_andere_vorschriften.size}}</span>
												<b-button @click='plus("verfahren_andere_vorschriften", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("verfahren_andere_vorschriften", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.verfahren_andere_vorschriften!=undefined"
												
												rows='5'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.verfahren_andere_vorschriften.text"
												
												@focus='formular.verfahren_andere_vorschriften.showPop=true'
												@blur ='formular.verfahren_andere_vorschriften.showPop=false'
												
												:disabled="formular.verfahren_andere_vorschriften.isDisabled==true || formular.verfahren_andere_vorschriften.isDisabled=='true'"
												:style="{'font-size': formular.verfahren_andere_vorschriften.size + 'px',
														'height': formular.verfahren_andere_vorschriften.height + 'px',
														'text-align': formular.verfahren_andere_vorschriften.schriftausrichtung + 'px',
														'letter-spacing': formular.verfahren_andere_vorschriften.letterSpacing + 'px',
														'font-weight': formular.verfahren_andere_vorschriften.schriftart + 'px', }"

												id='verfahren_andere_vorschriften'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(21.471389) + 'px'}">
													4.4 Abfallstoffe
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(26.5798958) + 'px'}">
													Art, Menge pro Zeiteinheit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(35.7606768) + 'px'}">
													Zwischenlagerung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(40.1200488) + 'px'}">
													Art, Ort und Menge
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(50.0273918) + 'px'}">
													Art der Beseitigung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(64.68772) + 'px'}">
													4.5 Besonders zu
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(68.3924808) + 'px'}">
													behandelnde Abwässer
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(73.4748878) + 'px'}">
													Art, Menge pro Zeiteinheit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(78.6348888) + 'px'}">
													Art und Ort der
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(82.2751055) + 'px'}">
													Behandlung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(93.7061125) + 'px'}">
													Verbleib der Rückstände
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(108.5484339) + 'px'}">
													5
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(108.4313375) + 'px'}">
													Verfahren nach anderen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(112.1135255) + 'px'}">
													Rechtsvorschriften
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(116.446445) + 'px'}">
													(z.B. Genehmigung, Erlaubnis,
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(119.6390854) + 'px'}">
													Eignungsfeststellung nach Wasser,
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(122.0515534) + 'px'}">
													Gewerbe, Immissionsschutzrecht)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(125.2798165) + 'px'}">
													Art des Verfahrens,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(128.9620045) + 'px'}">
													Gegenstand,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(132.5595445) + 'px'}">
													Antragsdatum
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(143.2424748) + 'px'}">
													(Ergänzung zu Nummer 5 des
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(26.5540776) + 'px', 
														top: gib_hoehe_in_mm(146.0746558) + 'px'}">
													Bauantrags)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(156.2151335) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.ort_datum"
												:show="formular.ort_datum.showPop==true"
												
												ref='ort_datum' 
												target='ort_datum'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum.size}}</span>
												<b-button @click='plus("ort_datum", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum" type="text" 
												v-if="formular.ort_datum!=undefined" 
												class='textfeld' v-model='formular.ort_datum.text' 
												style='width:100%' 
												@focus='formular.ort_datum.showPop=true'
												@blur ='formular.ort_datum.showPop=false'
												:disabled='formular.ort_datum.isDisabled==true || formular.ort_datum.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum.onylNumber) " 
												:style="{'width': spalte_breit_mm(84.4449734) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.7827832) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(161.051368) + 'px', 
		
														'font-size': formular.ort_datum.size + 'px', 
														'text-align': formular.ort_datum.schriftausrichtung, 
														'letter-spacing': formular.ort_datum.letterSpacing + 'px',
														'font-weight': formular.ort_datum.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(166.4998655) + 'px'}">
													Die/Der Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1243566) + 'px', 
														top: gib_hoehe_in_mm(196.8884975) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(69.0050496) + 'px', 
														top: gib_hoehe_in_mm(137.7826788) + 'px'}">
													Bescheid(e) vom durch
													</div>
<div :style="{
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.1886023) + 'px', 
														top: gib_hoehe_in_mm(137.7826788) + 'px'}">
													Aktenzeichen
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(19.9876376) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(68.4989251) + 'px',
										
										 top: gib_hoehe_in_mm(143.2090447) + 'px'}"><b-popover 
												v-if="formular.bescheide_vom"
												:show="formular.bescheide_vom.showPop==true"
												
												ref='bescheide_vom' 
												target='bescheide_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bescheide_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bescheide_vom.size}}</span>
												<b-button @click='plus("bescheide_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bescheide_vom", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bescheide_vom!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bescheide_vom.text"
												
												@focus='formular.bescheide_vom.showPop=true'
												@blur ='formular.bescheide_vom.showPop=false'
												
												:disabled="formular.bescheide_vom.isDisabled==true || formular.bescheide_vom.isDisabled=='true'"
												:style="{'font-size': formular.bescheide_vom.size + 'px',
														'height': formular.bescheide_vom.height + 'px',
														'text-align': formular.bescheide_vom.schriftausrichtung + 'px',
														'letter-spacing': formular.bescheide_vom.letterSpacing + 'px',
														'font-weight': formular.bescheide_vom.schriftart + 'px', }"

												id='bescheide_vom'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(51.6428153) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(90.7190251) + 'px',
										
										 top: gib_hoehe_in_mm(143.2090447) + 'px'}"><b-popover 
												v-if="formular.bescheid_durch"
												:show="formular.bescheid_durch.showPop==true"
												
												ref='bescheid_durch' 
												target='bescheid_durch'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bescheid_durch.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bescheid_durch.size}}</span>
												<b-button @click='plus("bescheid_durch", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bescheid_durch", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bescheid_durch!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bescheid_durch.text"
												
												@focus='formular.bescheid_durch.showPop=true'
												@blur ='formular.bescheid_durch.showPop=false'
												
												:disabled="formular.bescheid_durch.isDisabled==true || formular.bescheid_durch.isDisabled=='true'"
												:style="{'font-size': formular.bescheid_durch.size + 'px',
														'height': formular.bescheid_durch.height + 'px',
														'text-align': formular.bescheid_durch.schriftausrichtung + 'px',
														'letter-spacing': formular.bescheid_durch.letterSpacing + 'px',
														'font-weight': formular.bescheid_durch.schriftart + 'px', }"

												id='bescheid_durch'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(56.2427287) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(144.949119) + 'px',
										
										 top: gib_hoehe_in_mm(143.2090447) + 'px'}"><b-popover 
												v-if="formular.bescheid_aktenzeichen"
												:show="formular.bescheid_aktenzeichen.showPop==true"
												
												ref='bescheid_aktenzeichen' 
												target='bescheid_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bescheid_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bescheid_aktenzeichen.size}}</span>
												<b-button @click='plus("bescheid_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bescheid_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bescheid_aktenzeichen!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bescheid_aktenzeichen.text"
												
												@focus='formular.bescheid_aktenzeichen.showPop=true'
												@blur ='formular.bescheid_aktenzeichen.showPop=false'
												
												:disabled="formular.bescheid_aktenzeichen.isDisabled==true || formular.bescheid_aktenzeichen.isDisabled=='true'"
												:style="{'font-size': formular.bescheid_aktenzeichen.size + 'px',
														'height': formular.bescheid_aktenzeichen.height + 'px',
														'text-align': formular.bescheid_aktenzeichen.schriftausrichtung + 'px',
														'letter-spacing': formular.bescheid_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bescheid_aktenzeichen.schriftart + 'px', }"

												id='bescheid_aktenzeichen'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.0281303) + 'px', 
														top: gib_hoehe_in_mm(156.2151335) + 'px'}">
													Genehmigungsvermerk
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.5063644) + 'px', 
													width: spalte_breit_mm(1.5755109) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(210.0818753) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1482244) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8444083) + 'px', 
													width: spalte_breit_mm(183.7891484) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(21.1194708) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(202.6335567) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(180.7328361) + 'px', 
													top: gib_hoehe_in_mm(21.1194708) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8560474) + 'px', 
													width: spalte_breit_mm(183.7775093) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(201.7523069) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.3266816) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.3143168) + 'px', 
													top: gib_hoehe_in_mm(64.376715) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8475826) + 'px', 
													width: spalte_breit_mm(6.479099) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(69.5910318) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(25.2938805) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.8176197) + 'px', 
													top: gib_hoehe_in_mm(108.019813) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8504042) + 'px', 
													width: spalte_breit_mm(6.4434763) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.7374327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8539312) + 'px', 
													width: spalte_breit_mm(87.8656821) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(166.1641715) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.7196133) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(35.571039) + 'px', 
													top: gib_hoehe_in_mm(166.1641715) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.7196133) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.4228236) + 'px', 
													top: gib_hoehe_in_mm(155.8413479) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8532258) + 'px', 
													width: spalte_breit_mm(183.7803309) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(155.8413479) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8500515) + 'px', 
													width: spalte_breit_mm(183.7835052) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.1806442) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8472299) + 'px', 
													width: spalte_breit_mm(183.7863268) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.5019235) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8461718) + 'px', 
													width: spalte_breit_mm(183.7873849) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(49.7731715) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8451137) + 'px', 
													width: spalte_breit_mm(183.788443) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(35.5099835) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(67.6506816) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(134.8218771) + 'px', 
													top: gib_hoehe_in_mm(21.1194708) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(67.6506816) + 'px', 
													width: spalte_breit_mm(134.9828751) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(137.5573799) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(67.6506816) + 'px', 
													width: spalte_breit_mm(134.9828751) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(142.3823159) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(143.834587) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(18.1257916) + 'px', 
													top: gib_hoehe_in_mm(137.5573799) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8707816) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(18.383968) + 'px', 
													top: gib_hoehe_in_mm(137.5573799) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8479353) + 'px', 
													width: spalte_breit_mm(183.7856214) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(78.3841955) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.8489934) + 'px', 
													width: spalte_breit_mm(183.7845633) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.4518922) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "antrag_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherrschaft": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName<nl>bauherr.adresse.strasse<nl>bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betreibende": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betriebszeit_werkt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betriebszeit_werkt_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betriebszeit_sonn_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betriebszeit_sonn_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.plz<&>baugrundstueck.ort<&>baugrundstueck.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gesamtbeschaeftigte": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "art_des_betriebes": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "erzeugnisse": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "dienstleistung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "rohstoffe_materialien": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "luftverunreinigung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lage_emissionsoeffnungen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "vermeidung_luftverunrein": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gerauesche": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lage_gerauschquellen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "vermeidung_geraeusche": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "erschuetterungen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lage_erschuetterung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "massnahmen_erschuetterung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gerauesch_tageszeit_von_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gerauesch_nachtzeit_von_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "erschuetterungen_tageszeit_von_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "erschuetterungen_nachtzeit_von_bis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abfallstoffe_art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abfall_zwischenlagerung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abfall_beseitigung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abwasser_art": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abwasser_behandlung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "abwasser_rueckstaende": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "verfahren_andere_vorschriften": {
        "text": "",
        "datenuebernehmen": null,
        "height": 85,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ort_datum": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bescheide_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bescheid_durch": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bescheid_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    }
}
var formulaName = "NRWBetriebsbeschreibungGew"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>