<template>
	<div>
        <div v-if="projectDaten" class="kontainer">

				
			<handwerker/>
		
		</div>
	</div>
</template>


<script>

	import { projectMixin } from '../../mixins/projectMixin.js'
	import handwerker from './comp_handwerker.vue';


export default {

	mixins: [projectMixin],

	components: {
		'handwerker':handwerker,
	},

    data(){
        return{

        }
	},
	
	methods:{

	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
