<template>
	<div>
        <div v-if="logObject.loggedIn == true || logObject.loggedIn == 'true'" class="kontainer">

                    <h1>Einstellungen</h1>
                    <p>Text</p>

	   </div>
    </div>
</template>

<script>

export default {

		computed:{
			logObject(){
			return this.$store.state.logObject;
			},
		},
		
    data(){
        return{
        }
    },
	methods:{

	},
	created(){

	}
	
	//
}
</script>


<style>

</style>
