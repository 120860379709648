<template>
	<div v-if="actualformular.formularContent.baubeschreibung!=undefined">

		<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

			<!-- oben rechts -->
			<div style="left:688px;top:31px;" class="t size_12_fett">Anlage I/7 zu VV BauPrüfVO </div>
			<div style="left:810px;top:45px;" class="t size_12">Blatt 1 </div>


				<div class="seitenabstand" :style="{ height: seitenrand_oben + 'px' }"></div>

			<div class="rahmen" 
				:style="{
						top: gib_top(1) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(1) + 'px', 
						width: linke_spalte_breit + 'px'
					}"
					>
					<span class="size_11_fett">
						<b-row>
							<b-col cols="6">
								Bauantrag / Antrag auf Vorbescheid vom
							</b-col>

							<b-col cols="6">
								<popover-font-size ref="antrag_vom" url="antrag_vom"/>
								<formular-input v-bind:daten="{einsatz: 'antrag_vom' }"/>
							</b-col>
						</b-row>
					</span>

						<b-row style=" position:absolute; bottom:0; left:0;"
						class="size_12">
							<b-col>
								Im einfachen Baugenehmigungsverfahren sind Angaben zu den 
								gekennzeichneten Ziffern 7 bis 9 nicht erforderlich.
							</b-col>
						</b-row>

			</div>

			<div class="rahmen bg-light rechter_rand rechte_breite  titel" 
				:style="{height: gib_hoehe(1) + 'px', 
						top: gib_top(1) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_18_fett ">Baubeschreibung</div>


			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(2) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(2) + 'px', 
						width: spalte_bindungen_breit_5_com - 1 + 'px'
					}"
					>

				<div class="size_9">Bauherrschaft</div>

				<popover-font-size ref="bauherrschaft" url="bauherrschaft"/>
				<formular-textarea v-bind:daten="{
										hoehe: '50',
										einsatz: 'bauherrschaft'
									}" />

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(3) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(3) + 'px', 
						width: spalte_bindungen_breit(3) - 1 + 'px'
					}"
					>

				<div class="size_9">Grundstück (Ort, Straße, Haus-Nr.)</div>

				<popover-font-size ref="baugrundstueck_seite1" url=""/>
				<formular-input v-bind:daten="{einsatz: 'baugrundstueck_seite1' }"/>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(4) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(4) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">1. Bezeichnung des Vorhabens </div>


			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(4) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) -1 + 'px',
						height: gib_hoehe(4) + 'px', 
						width:  spalte_bindungen_breit(2) + 'px'
					}"
					>

				<popover-font-size ref="bezeichnung_bauvorhaben" url="bezeichnung_bauvorhaben"/>
				<formular-textarea v-bind:daten="{
										hoehe: '',
										einsatz: 'bezeichnung_bauvorhaben'
									}" />

			</div>
		

			<div class="rahmen" 
				:style="{
						top: gib_top(5) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(5) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">2. Art der Nutzung</div>

					<b-form-checkbox 
						class="size_12 "
						v-model="actualformular.formularContent.baubeschreibung.checkbox_betriebsbeschreibung.text"
						value="1"
						unchecked-value="">
						Betriebsbeschreibung ist beigefügt
					</b-form-checkbox> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(5) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) -1 + 'px',
						height: gib_hoehe(5) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>

				<popover-font-size ref="art_der_nutzung" url="art_der_nutzung"/>
				<formular-textarea v-bind:daten="{
										hoehe: '',
										einsatz: 'art_der_nutzung'
									}" />

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(6) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(6) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">3. Angaben zum Grundstück  </div>
				<div class="size_9">geschützer Baumbestand  </div>
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(6) + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(6)/2 + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(6) + gib_hoehe(6)/2 + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(6)/2 + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
					<div style="float:left">

					<b-form-checkbox 
					class="size_12 "

							v-model="actualformular.formularContent.baubeschreibung.geschuetzer_baumbestand_ckeck.text"
							
							value="1"
							unchecked-value="">
ja
					</b-form-checkbox>  
					</div>

					<div style="float:left">
						<popover-font-size ref="geschuetzer_baumbestand_text" url="geschuetzer_baumbestand_text"/>
						<formular-input v-bind:daten="{einsatz: 'geschuetzer_baumbestand_text' }"/>
					</div>
					<span style="clear: both;"></span>
				</div>




			<div class="rahmen" 
				:style="{
						top: gib_top(7) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(7) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_10">Trinkwasserversorgung</div>

			</div>
			
			<div class="rahmen" 
				:style="{
						top: gib_top(7) + 'px',
						left: spalte_bindungen_breit(1) + seitenrand_links + 'px',
						height: gib_hoehe(7) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>


					<b-form-checkbox 
						class="size_12 "
						v-model="actualformular.formularContent.baubeschreibung.zentrale_wasserversorgung_check.text"
						value="1"
						unchecked-value="">
						durch zentrale Wasserversorgung
					</b-form-checkbox> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(7) + 'px',
						left: spalte_bindungen_breit(4) + spalte_bindungen_breit(1) -1 + seitenrand_links + 'px',
						height: gib_hoehe(7) + 'px', 
						width: spalte_bindungen_breit(5) -1+ 'px'
					}"
					>

					<b-form-checkbox 
						class="size_12 "
						v-model="actualformular.formularContent.baubeschreibung.durch_Brunnen_check.text"
						value="1"
						unchecked-value="">
						durch Brunnen
					</b-form-checkbox> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(8) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(8) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_10">Löschwasserversorgung</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(8) + 'px',
						left: spalte_bindungen_breit(1) -1 + seitenrand_links + 'px',
						height: gib_hoehe(8) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
				<popover-font-size ref="loeschwasserversorgung" url="loeschwasserversorgung"/>
				<formular-textarea v-bind:daten="{
										hoehe: '',
										einsatz: 'loeschwasserversorgung'
									}" />

			</div>



			<div class="rahmen" 
				:style="{
						top: gib_top(9) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(9) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_10">Grundstücksentwässerung</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3 +1+ 'px', 
						width: spalte_bindungen_breit(4) + 'px'
					}"
					>
						<b-form-checkbox 
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.durch_oeffentliche_sammelkanalisation.text"
						value="1" unchecked-value="">
						 durch öffentliche Sammelkanalisation
						</b-form-checkbox> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) + 'px',
						left: spalte_bindungen_breit(1) +spalte_bindungen_breit(4) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3 +1+ 'px', 
						width: spalte_bindungen_breit(5) -1+ 'px'
					}"
					>
					
						<b-form-checkbox 
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.sammelkanalisation_vorhanden.text"
						value="1" unchecked-value="">
						 vorhanden
						</b-form-checkbox> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) + gib_hoehe(9)/3 + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3 + 'px', 
						width: spalte_breit_mm(61.88) + 'px'
					}"
					>
						<b-form-checkbox 
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.durch_kleinklaeranlage.text"
						value="1" unchecked-value="">
						 durch Kleinkläranlage 
						</b-form-checkbox> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) + (gib_hoehe(9)/3)  + 'px',
						left: spalte_bindungen_breit(1) + spalte_breit_mm(61.88) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3 + 'px', 
						width: spalte_breit_mm(72.89) + 'px'
					}"
					>
						<b-form-checkbox 
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.kleinklaeranlage_fertiggestellt_bis_zum.text"
						value="1" unchecked-value="">
						 fertiggestellt bis zum 
						</b-form-checkbox> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) +2*(gib_hoehe(9)/3)-1+ 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3+1 + 'px', 
						width: spalte_breit_mm(61.88) + 'px'
					}"
					>
						<b-form-checkbox 
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.durch_sonstige_entwaesserung_anlage_check.text"
						value="1" unchecked-value="">
						 durch sonstige Anlage; Art:  
						</b-form-checkbox> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(9) +2*(gib_hoehe(9)/3)-1+ 'px',
						left: spalte_bindungen_breit(1) + spalte_breit_mm(61.88) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(9)/3+1 + 'px', 
						width: spalte_breit_mm(72.89) + 'px'
					}"
					>

						<popover-font-size ref="durch_sonstige_entwaesserung_anlage_text" url="durch_sonstige_entwaesserung_anlage_text"/>
						<formular-input v-bind:daten="{einsatz: 'durch_sonstige_entwaesserung_anlage_text' }"/>

			</div>

				<div class="rahmen" 
				:style="{
						top: gib_top(10) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(10) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_10">sonstiges</div>


			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(10) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) -1 + 'px',
						height: gib_hoehe(10) + 'px', 
						width:  spalte_bindungen_breit(2) + 'px'
					}"
					>
						<popover-font-size ref="angaben_grundstueck_sonstiges" url="angaben_grundstueck_sonstiges"/>
						<formular-input v-bind:daten="{einsatz: 'angaben_grundstueck_sonstiges' }"/>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(11) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(11) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">4. Barrierefreies Bauen </div>


			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(11) + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(11)*(2/5) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_eingehalten_bei.text"
						value="1" unchecked-value="">
						 eingehalten bei: 
						</b-form-checkbox> 
						<b-form-checkbox size="sm"
						style="margin-left:25px;font-size: 0.7em !important; min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_gebaeudeklasse_3_5.text"
						value="1" unchecked-value="">
						 Gebäudeklasse 3 bis 5 mit Wohnungen  
						</b-form-checkbox> 
						<b-form-checkbox size="sm"
						style="margin-left:25px;font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_oeffentlich_anlagen.text"
						value="1" unchecked-value="">
						 öffentlich zugänglichen baulichen Anlagen  
						</b-form-checkbox> 
			</div>

						<div class="rahmen" 
				:style="{
						top: gib_top(11) + (gib_hoehe(11)*(2/5))  + 'px',
						left: spalte_bindungen_breit(1) - 1 + seitenrand_links + 'px',
						height: gib_hoehe(11)*(3/5) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_unverhaeltnismaessiger_mehraufwand.text"
						value="1" unchecked-value="">
						 unverhältnismäßiger Mehraufwand aufgrund von:  
						</b-form-checkbox> 

						<b-form-checkbox size="sm"
						style="margin-left:25px;font-size: 0.7em !important; min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_unverhaeltnismaessiger_mehraufwand_gelaende.text"
						value="1" unchecked-value="">
						 schwierigen Geländeverhältnissen oder  
						</b-form-checkbox> 
						<b-form-checkbox size="sm"
						style="margin-left:25px;font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.barrierefrei_unverhaeltnismaessiger_mehraufwand_vorh_bebauung.text"
						value="1" unchecked-value="">
						 ungünstiger vorhandener Bebauung (Nachweis ist beigefügt)  
						</b-form-checkbox> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(12) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(12) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">5. Anzahl der notwendigen Stellplätze</div>
				<div class="size_10">Bedarfsermittlung gegebenenfalls als Beiblatt</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(12)  + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + 'px',
						height: gib_hoehe(12)*(7/8) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
				<span class="size_13">
					<b-row>
						<b-col cols="10">
							insgesamt auf 
						</b-col>

						<b-col cols="2" class="linie_links">
						</b-col>

					</b-row> 
					<b-row>
						<b-col cols="4">
 							dem Baugrundstück:
						</b-col>

						<b-col cols="1">
							<popover-font-size ref="stellplaetze_garage" url="stellplaetze_garage"/>
							<formular-input v-bind:daten="{einsatz: 'stellplaetze_garage', onylNumber:true }"/>
						</b-col>

						<b-col style="padding-left:0;" cols="2">
 						in Garagen +
						</b-col>

						<b-col cols="1">
						<popover-font-size ref="stellplaetze_im_freien" url="stellplaetze_im_freien"/>
						<formular-input v-bind:daten="{einsatz: 'stellplaetze_im_freien', onylNumber:true }"/>
						</b-col>

						<b-col cols="2">
 						im Freien  =
						</b-col>

						<b-col cols="2" class="linie_links">
							{{pkw_stellplaetze_gesamt}}
						</b-col>

					</b-row> 

					<b-row>
						<b-col cols="7">
 							fremden Grundstück mit Baulast:
						</b-col>

						<b-col cols="3">
 						  =
						</b-col>

						<b-col cols="2" class="linie_links">
							<popover-font-size ref="stellplaetze_baulast" url="stellplaetze_baulast"/>
							<formular-input v-bind:daten="{einsatz: 'stellplaetze_baulast', onylNumber:true }"/>
						</b-col>
					</b-row> 

					<b-row>
						<b-col cols="7">
 							durch Ablösung
						</b-col>

						<b-col cols="3">
 						  =
						</b-col>

						<b-col cols="2" class="linie_links">
							<popover-font-size ref="stellplaetze_abloesung" url="stellplaetze_abloesung"/>
							<formular-input v-bind:daten="{einsatz: 'stellplaetze_abloesung', onylNumber:true }"/>
						</b-col>

					</b-row> 
					<b-row>
						<b-col cols="7">
						</b-col>

						<b-col cols="3">
 						  Summe:
						</b-col>

						<b-col cols="2" class="linie_links">
							{{pkw_stellplaetze_insgesamt}}

						</b-col>

					</b-row> 
				</span>
			</div>

			<div class="rahmen align-middle" 
				:style="{
						top: gib_top(12)+gib_hoehe(12) *(7/8) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + 'px',
						height: gib_hoehe(12)*(1/8) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
					<span class="size_13">

					<b-row>
						<b-col cols="7">
							davon für Menschen mit Behinderungen: 
						</b-col>

						<b-col cols="3">
 						<popover-font-size ref="stellplaetze_fuer_menschen_behinderungen" url="stellplaetze_fuer_menschen_behinderungen"/>
							<formular-input v-bind:daten="{einsatz: 'stellplaetze_fuer_menschen_behinderungen' , onylNumber:true}"/>
						</b-col>

						<b-col cols="2" class="linie_links">
							</b-col>
					</b-row> 

					</span>
			</div>


	<div class="rahmen" 
				:style="{
						top: gib_top(13) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(13) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">6. Anzahl der notwendigen Fahrradstellplätze</div>
				<div class="size_10">Bedarfsermittlung gegebenenfalls als Beiblatt</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(13)  + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + 'px',
						height: gib_hoehe(13)*(7/8) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
				<span class="size_13">
					<b-row>
						<b-col cols="10">
							insgesamt auf 
						</b-col>

						<b-col cols="2" class="linie_links">
						</b-col>

					</b-row> 
					<b-row>
						<b-col cols="4">
 							dem Baugrundstück:
						</b-col>

						<b-col cols="1">
							<popover-font-size ref="fahrrad_stellplaetze_garage" url="fahrrad_stellplaetze_garage"/>
							<formular-input v-bind:daten="{einsatz: 'fahrrad_stellplaetze_garage', onylNumber:true }"/>
						</b-col>

						<b-col style="padding-left:0;" cols="2">
 						in Garagen +
						</b-col>

						<b-col cols="1">
						<popover-font-size ref="fahrrad_stellplaetze_freien" url="fahrrad_stellplaetze_freien"/>
						<formular-input v-bind:daten="{einsatz: 'fahrrad_stellplaetze_freien', onylNumber:true }"/>
						</b-col>

						<b-col cols="2">
 						im Freien  =
						</b-col>

						<b-col cols="2" class="linie_links">
							{{fahrrad_stellplaetze_gesamt}}
						</b-col>

					</b-row> 

					<b-row>
						<b-col cols="7">
 							fremden Grundstück mit Baulast:
						</b-col>

						<b-col cols="3">
 						  =
						</b-col>

						<b-col cols="2" class="linie_links">
							<popover-font-size ref="fahrrad_stellplaetze_baulast" url="fahrrad_stellplaetze_baulast"/>
							<formular-input v-bind:daten="{einsatz: 'fahrrad_stellplaetze_baulast', onylNumber:true }"/>
						</b-col>
					</b-row> 

					<b-row>
						<b-col cols="7">
 							durch Ablösung
						</b-col>

						<b-col cols="3">
 						  =
						</b-col>

						<b-col cols="2" class="linie_links">
							<popover-font-size ref="fahrrad_stellplaetze_abloesung" url="fahrrad_stellplaetze_abloesung"/>
							<formular-input v-bind:daten="{einsatz: 'fahrrad_stellplaetze_abloesung', onylNumber:true }"/>
						</b-col>

					</b-row> 
					<b-row>
						<b-col cols="7">
						</b-col>

						<b-col cols="3">
 						  Summe:
						</b-col>

						<b-col cols="2" class="linie_links">
							{{fahrrad_stellplaetze_insgesamt}}

						</b-col>

					</b-row> 
				</span>
			</div>

			<div class="rahmen align-middle" 
				:style="{
						top: gib_top(13)+gib_hoehe(13) *(7/8) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + 'px',
						height: gib_hoehe(13)*(1/8) + 'px', 
						width: spalte_bindungen_breit(2) + 'px'
					}"
					>
					<span class="size_13">

					<b-row>
						<b-col cols="7">
							davon für Menschen mit Behinderungen: 
						</b-col>

						<b-col cols="3">
 						<popover-font-size ref="fahrrad_stellplaetze_behinderung" url="fahrrad_stellplaetze_behinderung"/>
							<formular-input v-bind:daten="{einsatz: 'fahrrad_stellplaetze_behinderung' , onylNumber:true}"/>
						</b-col>

						<b-col cols="2" class="linie_links">
							</b-col>
					</b-row> 

					</span>
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(14) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(4) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">7. Schutz gegen schädliche Einflüsse  </div>


			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top(14) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) -1 + 'px',
						height: gib_hoehe(4) + 'px', 
						width:  spalte_bindungen_breit(2) + 'px'
					}"
					>

				<popover-font-size ref="schutz_gegen_schaedliche_einfluesse" url="schutz_gegen_schaedliche_einfluesse"/>
				<formular-textarea v-bind:daten="{
										hoehe: '',
										einsatz: 'schutz_gegen_schaedliche_einfluesse'
									}" />

			</div>




<!-- 
			<div class="rahmen" 
				:style="{
						top: gib_top(8) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe(8)/3 + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_10">Grundstücksentwässerung</div>

			</div> -->



		</div>

		<!-- Begin output for page 2-->
		<div style="height: 20px;"> </div>

		<div id="page2" style="border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

				<!-- oben rechts -->
				<div style="left:688px;top:31px;" class="t size_12_fett">Anlage I/7 zu VV BauPrüfVO </div>
				<div style="left:810px;top:45px;" class="t size_12">Blatt 2 </div>
				

				<div class="seitenabstand" :style="{ height: seitenrand_oben + 'px' }"></div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(1) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(1) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">Baubeschreibung Blatt 2</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(1) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) -1 + 'px',
						height: gib_hoehe_2(1) + 'px', 
						width:  spalte_breit_mm(92.49) + 'px'
					}"
					>
					<span class="size_10">
						<b-row>
							<b-col>
								Bauherrschaft:
							</b-col>
						</b-row> 

						<b-row>
							<b-col>
								<popover-font-size ref="bauherrschaft_seite2" url="bauherrschaft_seite2"/>
								<formular-input v-bind:daten="{einsatz: 'bauherrschaft_seite2' }"/>
							</b-col>
						</b-row> 
						</span>

			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(1) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(92.49) - 1 + 'px',
						height: gib_hoehe_2(1) + 'px', 
						width:  spalte_breit_mm(42.41) + 'px'
					}"
					>
					<span class="size_10">
						<b-row>
							<b-col>
								Bauantrag vom:
							</b-col>
						</b-row> 

						<b-row>
							<b-col>
								<popover-font-size ref="antrag_vom" url="antrag_vom"/>
								<formular-input v-bind:daten="{einsatz: 'antrag_vom' }"/>
							</b-col>
						</b-row> 

						<!-- <b-row>
							<b-col>
								<popover-font-size ref="bauantrag_vom_seite2" url="bauantrag_vom_seite2"/>
								<formular-input v-bind:daten="{einsatz: 'bauantrag_vom_seite2' }"/>
							</b-col>
						</b-row>  -->
					</span>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(2) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">8. Angaben zur Aufstellung von Feuerstätten</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width: spalte_breit_mm(92.49)  + 'px'
					}"
					>

				<div class="size_12">Gesamt-Nennwärmeleistung: </div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(2)  + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(92.49) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width:  spalte_breit_mm(42.41)  + 'px'
					}"
					>
						<b-row>
							<b-col>
								<popover-font-size ref="gesamt_nennwaermeleistung" url="gesamt_nennwaermeleistung"/>
									<formular-input v-bind:daten="{einsatz: 'gesamt_nennwaermeleistung' }"/>
							</b-col>

							<b-col>							
								kW
							</b-col>

						</b-row> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + gib_hoehe_2(2)/3 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width: spalte_breit_mm(92.49)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.heizraum_check.text"
						value="1" unchecked-value="">
						Heizraum 
						</b-form-checkbox> 
			

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + gib_hoehe_2(2)*(1/3) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(92.49) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width:  spalte_breit_mm(42.41)  + 'px'
					}"
					>
			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + gib_hoehe_2(2)*(2/3) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width: spalte_breit_mm(92.49)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.aufstellraum_check.text"
						value="1" unchecked-value="">
						Aufstellraum 
						</b-form-checkbox> 
				

			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(2) + gib_hoehe_2(2)*(2/3) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(92.49) - 1 + 'px',
						height: gib_hoehe_2(2)/3 + 'px', 
						width:  spalte_breit_mm(42.41)  + 'px'
					}"
					>
			</div>


	

			<div class="rahmen" 
				:style="{
						top: gib_top_2(3) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(3) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">Angaben zur Brennstofflagerung </div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(3)  + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28 + 'px', 
						width: spalte_breit_mm(30.94)  + 'px'
					}"
					>
					
					<b-form-checkbox size="sm"
					style="font-size: 0.7em !important;min-height:1rem !important"
					class="size_10 "
					v-model="actualformular.formularContent.baubeschreibung.fester_brennstoff.text"
					value="1" unchecked-value="">
					fester Brennstoff 
					</b-form-checkbox> 
			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(3) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28 + 'px', 
						width:  spalte_breit_mm(54.77)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.heizoel_check.text"
						value="1" unchecked-value="">
						Heizöl 
						</b-form-checkbox> 
			</div>


			<div class="rahmen" 
				:style="{
						padding:'0px',
						top: gib_top_2(3)   + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) +spalte_breit_mm(54.77) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28 + 'px', 
						width:  spalte_breit_mm(49.19)  + 'px'
					}"
					>
						<b-row>
							<b-col>
								<popover-font-size ref="heizoel_m3" url="heizoel_m3"/>
									<formular-input v-bind:daten="{einsatz: 'heizoel_m3' }"/>
							</b-col>

							<b-col>							
								m³
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(3)  + gib_hoehe_2(3)*0.28+ 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28 + 'px', 
						width: spalte_breit_mm(30.94)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.gas.text"
						value="1" unchecked-value="">
						Gas 
						</b-form-checkbox> 
				

			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(3) + gib_hoehe_2(3)*0.28+ 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28 + 'px', 
						width:  spalte_breit_mm(54.77)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.fluessiggas.text"
						value="1" unchecked-value="">
						Flüssiggas 
						</b-form-checkbox> 

					
			</div>

			<div class="rahmen" 
				:style="{
					padding:'0px',

						top: gib_top_2(3) + gib_hoehe_2(3)*0.28 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) +spalte_breit_mm(54.77) - 1 + 'px',
						height: gib_hoehe_2(3)*0.28  + 'px', 
						width:  spalte_breit_mm(49.19)  + 'px'
					}"
					>
						<b-row>
							<b-col >
								<popover-font-size ref="fluessiggas_m3" url="fluessiggas_m3"/>
									<formular-input v-bind:daten="{einsatz: 'fluessiggas_m3' }"/>
							</b-col>

							<b-col>							
								m³
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(3)  + gib_hoehe_2(3)*0.56+ 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) - 1 + 'px',
						height: gib_hoehe_2(3)*0.44 + 'px', 
						width: spalte_breit_mm(30.94)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.unterirdischer_behaelter.text"
						value="1" unchecked-value="">
						  unterirdischer Lagerbehälter 
						</b-form-checkbox> 

			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(3) + gib_hoehe_2(3)*0.56+ 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) - 1 + 'px',
						height: gib_hoehe_2(3)*0.44 + 'px', 
						width:  spalte_breit_mm(54.77)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.lagerraum.text"
						value="1" unchecked-value="">
						  Lagerraum 
						</b-form-checkbox> 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(3) + gib_hoehe_2(3)*0.56 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) + spalte_breit_mm(30.94) +spalte_breit_mm(54.77) - 1 + 'px',
						height: gib_hoehe_2(3)*0.44  + 'px', 
						width:  spalte_breit_mm(49.19)  + 'px'
					}"
					>

						<b-row>
							<b-col cols="4">
								<b-form-checkbox size="sm"
								style="font-size: 0.7em !important;min-height:1rem !important"
								class="size_10 "
								v-model="actualformular.formularContent.baubeschreibung.sonstiger_raum.text"
								value="1" unchecked-value="">
								 sonstiger Raum:  
								</b-form-checkbox>
							</b-col>

							<b-col style="margin-top:-2px" cols="8">							
								<popover-font-size ref="brennstoff_sonstiger_raum" url="brennstoff_sonstiger_raum"/>
								<formular-textarea v-bind:daten="{
											hoehe: '37',
											einsatz: 'brennstoff_sonstiger_raum'
										}" />

							</b-col>
						</b-row> 


			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(4) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(4) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">9. Lüftung </div>
				<div class="size_10">Lüftungsanlage für Mittel- oder Großgarage  </div>
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(4) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1)  - 1 + 'px',
						height: gib_hoehe_2(4)*0.28 + 'px', 
						width:  spalte_breit_mm(134.91)  + 'px'
					}"
					>
					
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(4)+ gib_hoehe_2(4)*0.28 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1)  - 1 + 'px',
						height: gib_hoehe_2(4)*0.72 + 'px', 
						width:  spalte_breit_mm(12.61)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.lueftung_ja_check.text"
						value="1" unchecked-value="">
						  ja 
						</b-form-checkbox> 
						
			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(4)+ gib_hoehe_2(4)*0.28 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) +spalte_breit_mm(12.61) - 1 + 'px',
						height: gib_hoehe_2(4)*0.72 + 'px', 
						width:  spalte_breit_mm(25.4)  + 'px'
					}"
					>
					<div class="size_10">Art der Anlage:</div>
			</div>
			<div class="rahmen" 
				:style="{
						top: gib_top_2(4)+ gib_hoehe_2(4)*0.28 + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) +spalte_breit_mm(12.61) +spalte_breit_mm(25.4) - 1 + 'px',
						height: gib_hoehe_2(4)*0.72 + 'px', 
						width:  spalte_breit_mm(96.77)  + 'px'
					}"
					>
						<b-row>
							<b-col  >							
								<popover-font-size ref="lueftung_art_text" url="lueftung_art_text"/>
								<formular-textarea v-bind:daten="{
											hoehe: '30',
											einsatz: 'lueftung_art_text'
										}" />
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(5) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(5) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>
				<div class="size_10">sonstige genehmigungspflichtige Lüftungsanlage </div>
			</div>



			<div class="rahmen" 
				:style="{
						top: gib_top_2(5) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1)  - 1 + 'px',
						height: gib_hoehe_2(5) + 'px', 
						width:  spalte_breit_mm(12.61)  + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.lueftung_ja_sonstige_check.text"
						value="1" unchecked-value="">
						  ja 
						</b-form-checkbox> 
			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(5) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) +spalte_breit_mm(12.61) - 1 + 'px',
						height: gib_hoehe_2(5) + 'px', 
						width:  spalte_breit_mm(25.4)  + 'px'
					}"
					>
						<div class="size_10">Art der Anlage:</div>
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(5) + 'px',
						left: seitenrand_links + spalte_bindungen_breit(1) +spalte_breit_mm(12.61) +spalte_breit_mm(25.4) - 1 + 'px',
						height: gib_hoehe_2(5) + 'px', 
						width:  spalte_breit_mm(96.77)  + 'px'
					}"
					>
					
						<b-row>
							<b-col  >							
								<popover-font-size ref="lueftung_art_sonstige_text" url="lueftung_art_sonstige_text"/>
								<formular-textarea v-bind:daten="{
											hoehe: '',
											einsatz: 'lueftung_art_sonstige_text'
										}" />
							</b-col>
						</b-row> 


			</div>


			<div class="rahmen" 
				:style="{
						top: gib_top_2(6) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(6) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>



			</div>

				<div class="rahmen" 
				:style="{
						top: gib_top_2(6) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(6) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-form-checkbox size="sm"
						style="font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.lueftung_ueberbrueckt_check.text"
						value="1" unchecked-value="">
						Lüftungsanlage überbrückt Gebäudetrennwände oder Geschossdecken: 

						</b-form-checkbox> 

						<b-form-checkbox size="sm"
						style="margin-left:15px; font-size: 0.7em !important;min-height:1rem !important"
						class="size_10 "
						v-model="actualformular.formularContent.baubeschreibung.lueftung_schematische_darstellung.text"
						value="1" unchecked-value="">
						Schematische Darstellung entsprechend den Bildern der Lüftungsanlagenrichtlinie 
						und Beschreibung der Lüftungsanlagen mit Angabe der Feuerwiderstandsdauer und 
						Baustoffklasse der Bauteile und Lüftungsabschnitte ist beigefügt. 

						</b-form-checkbox> 



			</div>


<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(7) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(7) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">10. weitere Angaben, </div>
				<div class="size_10">sofern wegen Ortsatzungen oder Denkmalschutz erforderlich</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(7) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(7) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>

						<b-row>
							<b-col  >							
								<popover-font-size ref="weitere_angaben" url="weitere_angaben"/>
								<formular-textarea v-bind:daten="{
											hoehe: '55',
											einsatz: 'weitere_angaben'
										}" />
							</b-col>
						</b-row> 

			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(8) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(8) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">äußere Gestaltung </div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(8) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(8)/3 + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-row>
							<b-col>	
							<div class="size_10">Wände</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col  >							
								<popover-font-size ref="auessere_gestaltung_waende" url="auessere_gestaltung_waende"/>
								<formular-textarea v-bind:daten="{
											hoehe: '',
											einsatz: 'auessere_gestaltung_waende'
										}" />
							</b-col>
						</b-row> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(8) +gib_hoehe_2(8)/3+ 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(8)/3 + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-row>
							<b-col>	
							<div class="size_10">Dach</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col  >							
								<popover-font-size ref="auessere_gestaltung_dach" url="auessere_gestaltung_dach"/>
								<formular-textarea v-bind:daten="{
											hoehe: '',
											einsatz: 'auessere_gestaltung_dach'
										}" />
							</b-col>
						</b-row> 

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(8) +gib_hoehe_2(8)/3+gib_hoehe_2(8)/3+ 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(8)/3 + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>

						<b-row>
							<b-col>	
							<div class="size_10">Türen und Fenster</div>
							</b-col>
						</b-row>
						 
						<b-row>
							<b-col  >							
								<popover-font-size ref="auessere_gestaltung_tueren" url="auessere_gestaltung_tueren"/>
								<formular-textarea v-bind:daten="{
											hoehe: '',
											einsatz: 'auessere_gestaltung_tueren'
										}" />
							</b-col>
						</b-row> 


			</div>


	<!-- neue Zeile -->		

			<div class="rahmen" 
				:style="{
						top: gib_top_2(9) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(9) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">Spielplatz für Kleinkinder</div>
				<div class="size_10">(Größe und Ausstattung)</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(9) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(9) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-row>
							<b-col  >							
								<popover-font-size ref="spielplatz_fuer_kleinkinder" url="spielplatz_fuer_kleinkinder"/>
								<formular-textarea v-bind:daten="{
											hoehe: '45',
											einsatz: 'spielplatz_fuer_kleinkinder'
										}" />
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->

			<div class="rahmen" 
				:style="{
						top: gib_top_2(10) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(10) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">Befestigung, Gestaltung 
				und Eingrünung
				-  der Zufahrten
				-  der Stellplätze im Freien</div>

			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(10) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(10) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-row>
							<b-col  >							
								<popover-font-size ref="befestigung_gestaltung" url="befestigung_gestaltung"/>
								<formular-textarea v-bind:daten="{
											hoehe: '55',
											einsatz: 'befestigung_gestaltung'
										}" />
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->


			<div class="rahmen" 
				:style="{
						top: gib_top_2(11) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(11) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">Gestaltung und Bepflanzung der nicht überbauten Flächen </div>

			</div>

						<div class="rahmen" 
				:style="{
						top: gib_top_2(11) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(11) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>
						<b-row>
							<b-col  >							
								<popover-font-size ref="gestaltung_nicht_ueberbaut" url="gestaltung_nicht_ueberbaut"/>
								<formular-textarea v-bind:daten="{
											hoehe: '40',
											einsatz: 'gestaltung_nicht_ueberbaut'
										}" />
							</b-col>
						</b-row> 
			</div>

<!-- neue Zeile -->



			<div class="rahmen" 
				:style="{
						top: gib_top_2(12) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(12) + 'px', 
						width: spalte_bindungen_breit(1) + 'px'
					}"
					>

				<div class="size_12">11. Sonstiges </div>
			</div>

						<div class="rahmen" 
				:style="{
						top: gib_top_2(12) + 'px',
						left: seitenrand_links +spalte_bindungen_breit(1)+ 'px',
						height: gib_hoehe_2(12) + 'px', 
						width: spalte_breit_mm(134.91) + 'px'
					}"
					>

						<b-row>
							<b-col  >							
								<popover-font-size ref="sonstiges" url="sonstiges"/>
								<formular-textarea v-bind:daten="{
											hoehe: '',
											einsatz: 'sonstiges'
										}" />
							</b-col>
						</b-row> 

			</div>

<!-- neue Zeile -->



			<div class="rahmen" 
				:style="{
						top: gib_top_2(13) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(13) + 'px', 
						width: spalte_breit_mm(87.92) + 'px'
					}"
					>
						<b-row>
							<b-col>
								Ort, Datum
							</b-col>

							<b-col>							
								<popover-font-size ref="ort_datum" url="ort_datum"/>
								<formular-input v-bind:daten="{einsatz: 'ort_datum' }"/>
							</b-col>
						</b-row> 

				
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(14) + 'px',
						left: seitenrand_links + 'px',
						height: gib_hoehe_2(14) + 'px', 
						width: spalte_breit_mm(87.92) + 'px'
					}"
					>
				Die/Der Entwurfsverfassende: 
			</div>

			<div class="rahmen" 
				:style="{
						top: gib_top_2(13) + 'px',
						left: seitenrand_links + spalte_breit_mm(87.92) + 'px',
						height: gib_hoehe_2(13) + gib_hoehe_2(14) + 'px', 
						width: spalte_breit_mm(95.83) + 'px'
					}"
					>
				Genehmigungsvermerk
			</div>


		</div>
	</div>
</template>

<script>

import { formularMixin } from '../../../mixins/formularMixin.js'

export default {

	mixins: [formularMixin],

	name: "baubeschreibung",


    data(){
        return{

			linkes_feld_breite_mm : 111.38,
			rechtes_feld_breite_mm : 72.43,

			spalte : {1:49.02, 2:134.78, 3:183.81, 4:61.88, 5:72.89},

			spalte_bindungen_breit_1: 63.84,
			spalte_bindungen_breit_2: 24.45,
			spalte_bindungen_breit_3: 45.05,
			spalte_bindungen_breit_4: 50.05,
			spalte_bindungen_breit_5: 183.81,


			// Höhen in Millimetern



			hoehen_mm:[
			19.685,
			14.852,
			9.571,
			11.472,
			13.631,
			11.303,
			5.63,
			9.105,
			15.702,
			5.715,
			35.902,
			41.106,
			43.684,
			17.614,

			],
			hoehen_mm_page2:[
			8.763,
			17.014,
			19.644,
			14.564,
			12.532,
			17.649,
			16.258,
			44.997,
			11.305,
			16.217,
			12.571,
			11.469,
			10.331,
			40.811
			],
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	
	
    computed: {
		
		pkw_stellplaetze_gesamt(){

			var summe = ''

			var stellplaetze_garage = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text);


			var stellplaetze_im_freien = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text);


			summe = stellplaetze_garage + stellplaetze_im_freien

			if(!this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text && !this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text)
			summe = ''

			this.actualformular.formularContent.baubeschreibung.stellplaetze_gesamt.text = summe;
			return summe;

		},

		pkw_stellplaetze_insgesamt(){
			var summe = ''
			var stellplaetze_garage = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text);

			var stellplaetze_im_freien = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text);

			var stellplaetze_baulast = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_baulast.text)>0)
			stellplaetze_baulast = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_baulast.text);

			var stellplaetze_abloesung = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_abloesung.text)>0)
			stellplaetze_abloesung = parseInt(this.actualformular.formularContent.baubeschreibung.stellplaetze_abloesung.text);

			summe =  stellplaetze_garage
						 + stellplaetze_im_freien
						 + stellplaetze_baulast
						 + stellplaetze_abloesung;

			if(!this.actualformular.formularContent.baubeschreibung.stellplaetze_garage.text 
			&& !this.actualformular.formularContent.baubeschreibung.stellplaetze_im_freien.text
			&& !this.actualformular.formularContent.baubeschreibung.stellplaetze_baulast.text
			&& !this.actualformular.formularContent.baubeschreibung.stellplaetze_abloesung.text)
			summe = ''

			this.actualformular.formularContent.baubeschreibung.stellplaetze_insgesamt.text = summe;
			return summe;

		},

	fahrrad_stellplaetze_gesamt(){

			var summe = ''

			var stellplaetze_garage = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text);

			var stellplaetze_im_freien = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text);


			summe = stellplaetze_garage + stellplaetze_im_freien

			if(!this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text 
			&& !this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text)
			summe = ''

			this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_gesamt.text = summe;
			return summe;

		},

		fahrrad_stellplaetze_insgesamt(){
			var summe = ''
			var stellplaetze_garage = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text);

			var stellplaetze_im_freien = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text);

			var stellplaetze_baulast = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_baulast.text)>0)
			stellplaetze_baulast = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_baulast.text);

			var stellplaetze_abloesung = 0;
			if(parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_abloesung.text)>0)
			stellplaetze_abloesung = parseInt(this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_abloesung.text);

			summe =  stellplaetze_garage
						 + stellplaetze_im_freien
						 + stellplaetze_baulast
						 + stellplaetze_abloesung;

			if(!this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_garage.text 
			&& !this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_freien.text
			&& !this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_baulast.text
			&& !this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_abloesung.text)
			summe = ''

			this.actualformular.formularContent.baubeschreibung.fahrrad_stellplaetze_insgesamt.text = summe;
			return summe;

		}




	},
	
	methods:{

	},

	created(){

	}
	
}
</script>

<style>
.col{

	margin-top:-2px;
}
</style>