<template>
	<div>
       <div class="kontainer">

				<b-card style="margin-bottom: 2rem;">	
					<h1>Kalender</h1>
					<p>Sie können Ihren Kalender, sowie alle für Sie freigegebenen Kalender auch mittels Programmen von Drittanbietern nutzen. Zum Beispiel Microsoft Outlook, Thunderbird, Apple Calendar, Android Calendar</p>
					<p>Das Austauschformat ist "caldav"</p>
					<p>Hier finden Sie weitere Erklärungen und eine vollständige Liste mit Clients: <a href="https://de.wikipedia.org/wiki/CalDAV#Client">CalDAV</a></p>
				</b-card>


				<b-card v-if="zugangsdaten.userName" style="margin-bottom: 2rem;">	
					<h2>Zugangsdaten:</h2>
                    
					<p>
                        <label class="eingabe-label">Benutzername: </label>
						<div class="col-md-6 col-xs-12">{{zugangsdaten.userName}}</div>
               
                
					<p>
                        <label class="eingabe-label">Passwort: </label>
						<div class="col-md-6 col-xs-12">{{zugangsdaten.password}}</div>
                  
            
					<p>
                            <label class="eingabe-label">Adresse: </label>
							<div class="col-md-6 col-xs-12">{{zugangsdaten.url}}
                            </div>
                   
				</b-card>
				
				<b-card style="margin-bottom: 2rem;">	
					<b-button @click="passwortaendern = !passwortaendern"  variant="success">Passwort ändern</b-button>
						<div v-if="passwortaendern" class="col-xs-12 col-sm-12 col-md-12 col abstand_nach_unten">
							<label class="eingabe-label"> neues Passwort eingeben </label>
							<b-alert show>Hinweis: Sie ändern hiermit das Passwort zu Ihrem Kalender und Adressbuch. Nicht zu ArchiApp. Wenn Sie bereits den Kalender in Benutzung haben, müssen Sie auf allen Anwendungen das Passwort anpasssen. Bei Fragen wenden Sie sich bitte an Ihren Administrator.</b-alert>
								<b-row class="mb-2">
									<b-col>
										<input placeholder="neues Passwort" class="form-control" v-model="neuesPasswort.erstes"/>
									</b-col>
									<b-col>
										<input placeholder="neues Passwort wiederholen" class="form-control" v-model="neuesPasswort.zweites"/>
									</b-col>
								</b-row>
							<b-button @click="passwortSpeichern" variant="primary">speichern</b-button>
						</div>
				</b-card>

        </div>
	</div>
</template>

<script>

 import { davMixin } from '../../mixins/davMixin.js'
	
export default {

mixins: [davMixin],

    data(){
        return{
		kalender:{},
		passwortaendern:false,
		neuesPasswort:{erstes:'', zweites:''},
        }
	},
	
	methods:{
		passwortSpeichern: function(){

				if(!this.neuesPasswort.erstes)
				{
					this.$toasted.show('Bitte geben Sie ein Passwort ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else if(this.neuesPasswort.erstes!==this.neuesPasswort.zweites)
				{
					this.$toasted.show('Die Passwörter stimmen nicht überein. Bitte geben Sie zwei identische Passwörter ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else
				{

			this.axios.post(this.$store.state.url_writeDav, {
				was: 'updatePasswordSelf',
				neuesPasswort: this.neuesPasswort,
				besonders : this.$store.state.dev_sub
				}).then((response) => {
							
					this.getDAV();
				
				this.$toasted.show('Das Passwort für Ihren Kalender wurde erfolgreich geändert.', {className: 'toastvorne', position: 'top-center', duration:2000, type:'info'});

				})
			}
		},
	


	},

	computed:{


},

	created(){
		
		this.getDAV();

	}
	
	//
}
</script>


<style>

.toastvorne{
z-index: 100000;
}

</style>
