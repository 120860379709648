<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(15.3947207) + 'px'}">
													An die Gemeinde
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1536318) + 'px', 
														top: gib_hoehe_in_mm(15.3947207) + 'px'}">
													Eingangsstempel der Gemeinde
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(15.3947207) + 'px'}">
													An die untere Bauaufsichtsbehörde
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(159.7604028) + 'px', 
														top: gib_hoehe_in_mm(15.3947207) + 'px'}">
													Eingangsstempel der Bauaufsichtsbehörde
													</div>
<div :style="{
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(163.7071158) + 'px', 
														top: gib_hoehe_in_mm(11.5679257) + 'px'}">
													Anlage I/10 zur VV BauPrüfVO
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(43.7617338) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(22.0980658) + 'px',
										
										 top: gib_hoehe_in_mm(18.903104) + 'px'}"><b-popover 
												v-if="formular.gemeinde"
												:show="formular.gemeinde.showPop==true"
												
												ref='gemeinde' 
												target='gemeinde'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemeinde.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemeinde.size}}</span>
												<b-button @click='plus("gemeinde", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemeinde", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.gemeinde!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.gemeinde.text"
												
												@focus='formular.gemeinde.showPop=true'
												@blur ='formular.gemeinde.showPop=false'
												
												:disabled="formular.gemeinde.isDisabled==true || formular.gemeinde.isDisabled=='true'"
												:style="{'font-size': formular.gemeinde.size + 'px',
														'height': formular.gemeinde.height + 'px',
														'text-align': formular.gemeinde.schriftausrichtung + 'px',
														'letter-spacing': formular.gemeinde.letterSpacing + 'px',
														'font-weight': formular.gemeinde.schriftart + 'px', }"

												id='gemeinde'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(43.2813564) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(114.2550488) + 'px',
										
										 top: gib_hoehe_in_mm(18.903104) + 'px'}"><b-popover 
												v-if="formular.bauaufsicht"
												:show="formular.bauaufsicht.showPop==true"
												
												ref='bauaufsicht' 
												target='bauaufsicht'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht.size}}</span>
												<b-button @click='plus("bauaufsicht", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauaufsicht!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauaufsicht.text"
												
												@focus='formular.bauaufsicht.showPop=true'
												@blur ='formular.bauaufsicht.showPop=false'
												
												:disabled="formular.bauaufsicht.isDisabled==true || formular.bauaufsicht.isDisabled=='true'"
												:style="{'font-size': formular.bauaufsicht.size + 'px',
														'height': formular.bauaufsicht.height + 'px',
														'text-align': formular.bauaufsicht.schriftausrichtung + 'px',
														'letter-spacing': formular.bauaufsicht.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht.schriftart + 'px', }"

												id='bauaufsicht'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(40.3729347) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.gemeinde_plz"
												:show="formular.gemeinde_plz.showPop==true"
												
												ref='gemeinde_plz' 
												target='gemeinde_plz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemeinde_plz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemeinde_plz.size}}</span>
												<b-button @click='plus("gemeinde_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemeinde_plz", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gemeinde_plz" type="text" 
												v-if="formular.gemeinde_plz!=undefined" 
												class='textfeld' v-model='formular.gemeinde_plz.text' 
												style='width:100%' 
												@focus='formular.gemeinde_plz.showPop=true'
												@blur ='formular.gemeinde_plz.showPop=false'
												:disabled='formular.gemeinde_plz.isDisabled==true || formular.gemeinde_plz.isDisabled==true' 
												@keypress="isNumber($event, formular.gemeinde_plz.onylNumber) " 
												:style="{'width': spalte_breit_mm(88.6664397) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3561779) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(44.2455928) + 'px', 
		
														'font-size': formular.gemeinde_plz.size + 'px', 
														'text-align': formular.gemeinde_plz.schriftausrichtung, 
														'letter-spacing': formular.gemeinde_plz.letterSpacing + 'px',
														'font-weight': formular.gemeinde_plz.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(48.7954107) + 'px'}">
													Aktenzeichen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(40.3729347) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.bauaufsicht_plz"
												:show="formular.bauaufsicht_plz.showPop==true"
												
												ref='bauaufsicht_plz' 
												target='bauaufsicht_plz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht_plz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht_plz.size}}</span>
												<b-button @click='plus("bauaufsicht_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht_plz", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauaufsicht_plz" type="text" 
												v-if="formular.bauaufsicht_plz!=undefined" 
												class='textfeld' v-model='formular.bauaufsicht_plz.text' 
												style='width:100%' 
												@focus='formular.bauaufsicht_plz.showPop=true'
												@blur ='formular.bauaufsicht_plz.showPop=false'
												:disabled='formular.bauaufsicht_plz.isDisabled==true || formular.bauaufsicht_plz.isDisabled==true' 
												@keypress="isNumber($event, formular.bauaufsicht_plz.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.4235249) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(44.2455928) + 'px', 
		
														'font-size': formular.bauaufsicht_plz.size + 'px', 
														'text-align': formular.bauaufsicht_plz.schriftausrichtung, 
														'letter-spacing': formular.bauaufsicht_plz.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht_plz.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(48.7954107) + 'px'}">
													Aktenzeichen
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(58.0456736) + 'px'}">
													Antrag auf Abweichung, Ausnahme und
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(29.0053426) + 'px', 
														top: gib_hoehe_in_mm(62.9125809) + 'px'}">
													Befreiung 69 BauO NRW 2018
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(124.5468348) + 'px', 
														top: gib_hoehe_in_mm(58.0456736) + 'px'}">
													Genehmigungsfreie Bauvorhaben
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(137.2440348) + 'px', 
														top: gib_hoehe_in_mm(62.9125809) + 'px'}">
													 62 BauO NRW 2018
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(74.5827185) + 'px'}">
													Bauherrschaft ( 53 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(82.9092601) + 'px'}">
													Name, Vorname, Firma
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(88.6745518) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(22.0980658) + 'px',
										
										 top: gib_hoehe_in_mm(86.5255696) + 'px'}"><b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_name.text"
												
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												
												:disabled="formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_name.size + 'px',
														'height': formular.bauherr_name.height + 'px',
														'text-align': formular.bauherr_name.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart + 'px', }"

												id='bauherr_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(74.5827185) + 'px'}">
													Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(78.6031458) + 'px'}">
													( 54 Absatz 1 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(82.9092601) + 'px'}">
													Name, Vorname, Büro
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(87.4235249) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(114.2550488) + 'px',
										
										 top: gib_hoehe_in_mm(86.1936789) + 'px'}"><b-popover 
												v-if="formular.antragsteller_name"
												:show="formular.antragsteller_name.showPop==true"
												
												ref='antragsteller_name' 
												target='antragsteller_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_name.size}}</span>
												<b-button @click='plus("antragsteller_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_name.text"
												
												@focus='formular.antragsteller_name.showPop=true'
												@blur ='formular.antragsteller_name.showPop=false'
												
												:disabled="formular.antragsteller_name.isDisabled==true || formular.antragsteller_name.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_name.size + 'px',
														'height': formular.antragsteller_name.height + 'px',
														'text-align': formular.antragsteller_name.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_name.letterSpacing + 'px',
														'font-weight': formular.antragsteller_name.schriftart + 'px', }"

												id='antragsteller_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(103.7855731) + 'px'}">
													Straße, Hausnummer
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(103.7855731) + 'px'}">
													Straße, Hausnummer
													</div>
<b-popover 
												v-if="formular.bauherr_strasse"
												:show="formular.bauherr_strasse.showPop==true"
												
												ref='bauherr_strasse' 
												target='bauherr_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_strasse.size}}</span>
												<b-button @click='plus("bauherr_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_strasse" type="text" 
												v-if="formular.bauherr_strasse!=undefined" 
												class='textfeld' v-model='formular.bauherr_strasse.text' 
												style='width:100%' 
												@focus='formular.bauherr_strasse.showPop=true'
												@blur ='formular.bauherr_strasse.showPop=false'
												:disabled='formular.bauherr_strasse.isDisabled==true || formular.bauherr_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(88.6664397) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3561779) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(107.3891211) + 'px', 
		
														'font-size': formular.bauherr_strasse.size + 'px', 
														'text-align': formular.bauherr_strasse.schriftausrichtung, 
														'letter-spacing': formular.bauherr_strasse.letterSpacing + 'px',
														'font-weight': formular.bauherr_strasse.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_strasse"
												:show="formular.antragsteller_strasse.showPop==true"
												
												ref='antragsteller_strasse' 
												target='antragsteller_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
												<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_strasse" type="text" 
												v-if="formular.antragsteller_strasse!=undefined" 
												class='textfeld' v-model='formular.antragsteller_strasse.text' 
												style='width:100%' 
												@focus='formular.antragsteller_strasse.showPop=true'
												@blur ='formular.antragsteller_strasse.showPop=false'
												:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.4235249) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 19.5 + 'px', 
														'top': gib_hoehe_in_mm(107.3891211) + 'px', 
		
														'font-size': formular.antragsteller_strasse.size + 'px', 
														'text-align': formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': formular.antragsteller_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(115.8479131) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.bauherr_ort"
												:show="formular.bauherr_ort.showPop==true"
												
												ref='bauherr_ort' 
												target='bauherr_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_ort.size}}</span>
												<b-button @click='plus("bauherr_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_ort" type="text" 
												v-if="formular.bauherr_ort!=undefined" 
												class='textfeld' v-model='formular.bauherr_ort.text' 
												style='width:100%' 
												@focus='formular.bauherr_ort.showPop=true'
												@blur ='formular.bauherr_ort.showPop=false'
												:disabled='formular.bauherr_ort.isDisabled==true || formular.bauherr_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(88.6664397) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3561779) + 'px', 
														'height': 17.5 + 'px', 
														'top': gib_hoehe_in_mm(119.6313381) + 'px', 
		
														'font-size': formular.bauherr_ort.size + 'px', 
														'text-align': formular.bauherr_ort.schriftausrichtung, 
														'letter-spacing': formular.bauherr_ort.letterSpacing + 'px',
														'font-weight': formular.bauherr_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(127.9349421) + 'px'}">
													vertreten durch ( 53 Absatz 3 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(131.5074404) + 'px'}">
													Name, Vorname, Anschrift
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(88.6745518) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(22.0980658) + 'px',
										
										 top: gib_hoehe_in_mm(135.6446878) + 'px'}"><b-popover 
												v-if="formular.bauherr_vertreter"
												:show="formular.bauherr_vertreter.showPop==true"
												
												ref='bauherr_vertreter' 
												target='bauherr_vertreter'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_vertreter.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_vertreter.size}}</span>
												<b-button @click='plus("bauherr_vertreter", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_vertreter", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_vertreter!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_vertreter.text"
												
												@focus='formular.bauherr_vertreter.showPop=true'
												@blur ='formular.bauherr_vertreter.showPop=false'
												
												:disabled="formular.bauherr_vertreter.isDisabled==true || formular.bauherr_vertreter.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_vertreter.size + 'px',
														'height': formular.bauherr_vertreter.height + 'px',
														'text-align': formular.bauherr_vertreter.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_vertreter.letterSpacing + 'px',
														'font-weight': formular.bauherr_vertreter.schriftart + 'px', }"

												id='bauherr_vertreter'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(127.9349421) + 'px'}">
													bauvorlageberechtigt ( 67 Absatz 3 BauO NRW 2018) Name, Vorname
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(87.4235249) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(114.2550488) + 'px',
										
										 top: gib_hoehe_in_mm(132.1564848) + 'px'}"><b-popover 
												v-if="formular.antragsteller_vorlagenberechtigt"
												:show="formular.antragsteller_vorlagenberechtigt.showPop==true"
												
												ref='antragsteller_vorlagenberechtigt' 
												target='antragsteller_vorlagenberechtigt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_vorlagenberechtigt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_vorlagenberechtigt.size}}</span>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_vorlagenberechtigt!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_vorlagenberechtigt.text"
												
												@focus='formular.antragsteller_vorlagenberechtigt.showPop=true'
												@blur ='formular.antragsteller_vorlagenberechtigt.showPop=false'
												
												:disabled="formular.antragsteller_vorlagenberechtigt.isDisabled==true || formular.antragsteller_vorlagenberechtigt.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_vorlagenberechtigt.size + 'px',
														'height': formular.antragsteller_vorlagenberechtigt.height + 'px',
														'text-align': formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
														'font-weight': formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }"

												id='antragsteller_vorlagenberechtigt'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(142.3004131) + 'px'}">
													Mitgliedsnummer der Architekten oder der Ingenieurkammer des Landes
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(115.8479131) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.antragsteller_ort"
												:show="formular.antragsteller_ort.showPop==true"
												
												ref='antragsteller_ort' 
												target='antragsteller_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
												<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_ort" type="text" 
												v-if="formular.antragsteller_ort!=undefined" 
												class='textfeld' v-model='formular.antragsteller_ort.text' 
												style='width:100%' 
												@focus='formular.antragsteller_ort.showPop=true'
												@blur ='formular.antragsteller_ort.showPop=false'
												:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.4235249) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 17.5 + 'px', 
														'top': gib_hoehe_in_mm(119.6313381) + 'px', 
		
														'font-size': formular.antragsteller_ort.size + 'px', 
														'text-align': formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': formular.antragsteller_ort.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_mitgliedsnummer"
												:show="formular.antragsteller_mitgliedsnummer.showPop==true"
												
												ref='antragsteller_mitgliedsnummer' 
												target='antragsteller_mitgliedsnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_mitgliedsnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_mitgliedsnummer.size}}</span>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_mitgliedsnummer" type="text" 
												v-if="formular.antragsteller_mitgliedsnummer!=undefined" 
												class='textfeld' v-model='formular.antragsteller_mitgliedsnummer.text' 
												style='width:100%' 
												@focus='formular.antragsteller_mitgliedsnummer.showPop=true'
												@blur ='formular.antragsteller_mitgliedsnummer.showPop=false'
												:disabled='formular.antragsteller_mitgliedsnummer.isDisabled==true || formular.antragsteller_mitgliedsnummer.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_mitgliedsnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.4235249) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 14.5 + 'px', 
														'top': gib_hoehe_in_mm(147.4872314) + 'px', 
		
														'font-size': formular.antragsteller_mitgliedsnummer.size + 'px', 
														'text-align': formular.antragsteller_mitgliedsnummer.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_mitgliedsnummer.letterSpacing + 'px',
														'font-weight': formular.antragsteller_mitgliedsnummer.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(163.5467084) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.antragsteller_email"
												:show="formular.antragsteller_email.showPop==true"
												
												ref='antragsteller_email' 
												target='antragsteller_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_email.size}}</span>
												<b-button @click='plus("antragsteller_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_email" type="text" 
												v-if="formular.antragsteller_email!=undefined" 
												class='textfeld' v-model='formular.antragsteller_email.text' 
												style='width:100%' 
												@focus='formular.antragsteller_email.showPop=true'
												@blur ='formular.antragsteller_email.showPop=false'
												:disabled='formular.antragsteller_email.isDisabled==true || formular.antragsteller_email.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.4235249) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(166.885026) + 'px', 
		
														'font-size': formular.antragsteller_email.size + 'px', 
														'text-align': formular.antragsteller_email.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_email.letterSpacing + 'px',
														'font-weight': formular.antragsteller_email.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(156.7325444) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<b-popover 
												v-if="formular.bauherr_tel"
												:show="formular.bauherr_tel.showPop==true"
												
												ref='bauherr_tel' 
												target='bauherr_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_tel.size}}</span>
												<b-button @click='plus("bauherr_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_tel" type="text" 
												v-if="formular.bauherr_tel!=undefined" 
												class='textfeld' v-model='formular.bauherr_tel.text' 
												style='width:100%' 
												@focus='formular.bauherr_tel.showPop=true'
												@blur ='formular.bauherr_tel.showPop=false'
												:disabled='formular.bauherr_tel.isDisabled==true || formular.bauherr_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.8196721) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3561779) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(159.7474361) + 'px', 
		
														'font-size': formular.bauherr_tel.size + 'px', 
														'text-align': formular.bauherr_tel.schriftausrichtung, 
														'letter-spacing': formular.bauherr_tel.letterSpacing + 'px',
														'font-weight': formular.bauherr_tel.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1536318) + 'px', 
														top: gib_hoehe_in_mm(156.7325444) + 'px'}">
													Telefax
													</div>
<b-popover 
												v-if="formular.bauherr_fax"
												:show="formular.bauherr_fax.showPop==true"
												
												ref='bauherr_fax' 
												target='bauherr_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_fax.size}}</span>
												<b-button @click='plus("bauherr_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_fax" type="text" 
												v-if="formular.bauherr_fax!=undefined" 
												class='textfeld' v-model='formular.bauherr_fax.text' 
												style='width:100%' 
												@focus='formular.bauherr_fax.showPop=true'
												@blur ='formular.bauherr_fax.showPop=false'
												:disabled='formular.bauherr_fax.isDisabled==true || formular.bauherr_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.6189858) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(68.4036318) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(159.7474361) + 'px', 
		
														'font-size': formular.bauherr_fax.size + 'px', 
														'text-align': formular.bauherr_fax.schriftausrichtung, 
														'letter-spacing': formular.bauherr_fax.letterSpacing + 'px',
														'font-weight': formular.bauherr_fax.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(156.7325444) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<b-popover 
												v-if="formular.antragsteller_tel"
												:show="formular.antragsteller_tel.showPop==true"
												
												ref='antragsteller_tel' 
												target='antragsteller_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_tel.size}}</span>
												<b-button @click='plus("antragsteller_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_tel" type="text" 
												v-if="formular.antragsteller_tel!=undefined" 
												class='textfeld' v-model='formular.antragsteller_tel.text' 
												style='width:100%' 
												@focus='formular.antragsteller_tel.showPop=true'
												@blur ='formular.antragsteller_tel.showPop=false'
												:disabled='formular.antragsteller_tel.isDisabled==true || formular.antragsteller_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.4994205) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(114.5050488) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(159.7474361) + 'px', 
		
														'font-size': formular.antragsteller_tel.size + 'px', 
														'text-align': formular.antragsteller_tel.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_tel.letterSpacing + 'px',
														'font-weight': formular.antragsteller_tel.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(160.3106148) + 'px', 
														top: gib_hoehe_in_mm(156.7325444) + 'px'}">
													Telef ax
													</div>
<b-popover 
												v-if="formular.antragsteller_fax"
												:show="formular.antragsteller_fax.showPop==true"
												
												ref='antragsteller_fax' 
												target='antragsteller_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_fax.size}}</span>
												<b-button @click='plus("antragsteller_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_fax" type="text" 
												v-if="formular.antragsteller_fax!=undefined" 
												class='textfeld' v-model='formular.antragsteller_fax.text' 
												style='width:100%' 
												@focus='formular.antragsteller_fax.showPop=true'
												@blur ='formular.antragsteller_fax.showPop=false'
												:disabled='formular.antragsteller_fax.isDisabled==true || formular.antragsteller_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(41.2836636) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(160.6449101) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(159.7474361) + 'px', 
		
														'font-size': formular.antragsteller_fax.size + 'px', 
														'text-align': formular.antragsteller_fax.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_fax.letterSpacing + 'px',
														'font-weight': formular.antragsteller_fax.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ausnahme!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(22.4789818) + 'px',top: gib_hoehe_in_mm(171.4409162) + 'px'}"
								v-model="formular.ausnahme.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.befreiung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.5281775) + 'px',top: gib_hoehe_in_mm(171.4409162) + 'px'}"
								v-model="formular.befreiung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.abweichung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(144.6052365) + 'px',top: gib_hoehe_in_mm(171.4409162) + 'px'}"
								v-model="formular.abweichung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(88.1838175) + 'px', 
														top: gib_hoehe_in_mm(171.0147782) + 'px'}">
													Befreiung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.2644035) + 'px', 
														top: gib_hoehe_in_mm(171.0147782) + 'px'}">
													Ausnahme
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(163.5467084) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.bauherr_email"
												:show="formular.bauherr_email.showPop==true"
												
												ref='bauherr_email' 
												target='bauherr_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_email.size}}</span>
												<b-button @click='plus("bauherr_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_email" type="text" 
												v-if="formular.bauherr_email!=undefined" 
												class='textfeld' v-model='formular.bauherr_email.text' 
												style='width:100%' 
												@focus='formular.bauherr_email.showPop=true'
												@blur ='formular.bauherr_email.showPop=false'
												:disabled='formular.bauherr_email.isDisabled==true || formular.bauherr_email.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(88.6664397) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3561779) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(166.885026) + 'px', 
		
														'font-size': formular.bauherr_email.size + 'px', 
														'text-align': formular.bauherr_email.schriftausrichtung, 
														'letter-spacing': formular.bauherr_email.letterSpacing + 'px',
														'font-weight': formular.bauherr_email.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(27.1430866) + 'px', 
														top: gib_hoehe_in_mm(171.0147782) + 'px'}">
													Abweichung
													</div>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(175.7843403) + 'px'}">
													Begründung
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(179.5805079) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(22.0980658) + 'px',
										
										 top: gib_hoehe_in_mm(181.10278) + 'px'}"><b-popover 
												v-if="formular.begruendung"
												:show="formular.begruendung.showPop==true"
												
												ref='begruendung' 
												target='begruendung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.begruendung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.begruendung.size}}</span>
												<b-button @click='plus("begruendung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("begruendung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.begruendung!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.begruendung.text"
												
												@focus='formular.begruendung.showPop=true'
												@blur ='formular.begruendung.showPop=false'
												
												:disabled="formular.begruendung.isDisabled==true || formular.begruendung.isDisabled=='true'"
												:style="{'font-size': formular.begruendung.size + 'px',
														'height': formular.begruendung.height + 'px',
														'text-align': formular.begruendung.schriftausrichtung + 'px',
														'letter-spacing': formular.begruendung.letterSpacing + 'px',
														'font-weight': formular.begruendung.schriftart + 'px', }"

												id='begruendung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(206.8208822) + 'px'}">
													Baugrundstück
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(211.1682624) + 'px'}">
													Ort, Straße, Hausnummer, gegebenenfalls Ortsteil
													</div>
<b-popover 
												v-if="formular.baugrund_strasse"
												:show="formular.baugrund_strasse.showPop==true"
												
												ref='baugrund_strasse' 
												target='baugrund_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
												<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrund_strasse" type="text" 
												v-if="formular.baugrund_strasse!=undefined" 
												class='textfeld' v-model='formular.baugrund_strasse.text' 
												style='width:100%' 
												@focus='formular.baugrund_strasse.showPop=true'
												@blur ='formular.baugrund_strasse.showPop=false'
												:disabled='formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrund_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(179.5727485) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3480658) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(214.7615821) + 'px', 
		
														'font-size': formular.baugrund_strasse.size + 'px', 
														'text-align': formular.baugrund_strasse.schriftausrichtung, 
														'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrund_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(218.8289064) + 'px'}">
													Gemarkung(en)
													</div>
<b-popover 
												v-if="formular.gemarkungen"
												:show="formular.gemarkungen.showPop==true"
												
												ref='gemarkungen' 
												target='gemarkungen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemarkungen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemarkungen.size}}</span>
												<b-button @click='plus("gemarkungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemarkungen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gemarkungen" type="text" 
												v-if="formular.gemarkungen!=undefined" 
												class='textfeld' v-model='formular.gemarkungen.text' 
												style='width:100%' 
												@focus='formular.gemarkungen.showPop=true'
												@blur ='formular.gemarkungen.showPop=false'
												:disabled='formular.gemarkungen.isDisabled==true || formular.gemarkungen.isDisabled==true' 
												@keypress="isNumber($event, formular.gemarkungen.onylNumber) " 
												:style="{'width': spalte_breit_mm(77.048149) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.3480658) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(222.424695) + 'px', 
		
														'font-size': formular.gemarkungen.size + 'px', 
														'text-align': formular.gemarkungen.schriftausrichtung, 
														'letter-spacing': formular.gemarkungen.letterSpacing + 'px',
														'font-weight': formular.gemarkungen.schriftart }" 
												/>

<b-popover 
												v-if="formular.flure"
												:show="formular.flure.showPop==true"
												
												ref='flure' 
												target='flure'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flure.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flure.size}}</span>
												<b-button @click='plus("flure", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flure", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flure" type="text" 
												v-if="formular.flure!=undefined" 
												class='textfeld' v-model='formular.flure.text' 
												style='width:100%' 
												@focus='formular.flure.showPop=true'
												@blur ='formular.flure.showPop=false'
												:disabled='formular.flure.isDisabled==true || formular.flure.isDisabled==true' 
												@keypress="isNumber($event, formular.flure.onylNumber) " 
												:style="{'width': spalte_breit_mm(28.6584144) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(101.7239062) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(222.424695) + 'px', 
		
														'font-size': formular.flure.size + 'px', 
														'text-align': formular.flure.schriftausrichtung, 
														'letter-spacing': formular.flure.letterSpacing + 'px',
														'font-weight': formular.flure.schriftart }" 
												/>

<b-popover 
												v-if="formular.flurstuecke"
												:show="formular.flurstuecke.showPop==true"
												
												ref='flurstuecke' 
												target='flurstuecke'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flurstuecke.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flurstuecke.size}}</span>
												<b-button @click='plus("flurstuecke", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flurstuecke", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flurstuecke" type="text" 
												v-if="formular.flurstuecke!=undefined" 
												class='textfeld' v-model='formular.flurstuecke.text' 
												style='width:100%' 
												@focus='formular.flurstuecke.showPop=true'
												@blur ='formular.flurstuecke.showPop=false'
												:disabled='formular.flurstuecke.isDisabled==true || formular.flurstuecke.isDisabled==true' 
												@keypress="isNumber($event, formular.flurstuecke.onylNumber) " 
												:style="{'width': spalte_breit_mm(69.4076089) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(132.5132054) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(222.424695) + 'px', 
		
														'font-size': formular.flurstuecke.size + 'px', 
														'text-align': formular.flurstuecke.schriftausrichtung, 
														'letter-spacing': formular.flurstuecke.letterSpacing + 'px',
														'font-weight': formular.flurstuecke.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(101.4735535) + 'px', 
														top: gib_hoehe_in_mm(218.8289064) + 'px'}">
													Flur(e)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(132.3348035) + 'px', 
														top: gib_hoehe_in_mm(218.8289064) + 'px'}">
													Flurstück(e)
													</div>
<div :style="{
														fontSize:'12.8295px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(226.9618157) + 'px'}">
													Für die Bauherrschaft
													</div>
<div :style="{
														fontSize:'12.8295px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(226.9618157) + 'px'}">
													Die/Der bauvorlageberechtigte Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'12.8295px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.0980658) + 'px', 
														top: gib_hoehe_in_mm(245.9335487) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'12.8295px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.2550488) + 'px', 
														top: gib_hoehe_in_mm(245.9335487) + 'px'}">
													Unterschrift
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9517908) + 'px', 
													width: spalte_breit_mm(183.2103677) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(15.5401095) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9553178) + 'px', 
													width: spalte_breit_mm(182.956071) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(250.3466075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(113.1123008) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(155.280946) + 'px', 
													top: gib_hoehe_in_mm(15.5401095) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(158.5721565) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(25.078214) + 'px', 
													top: gib_hoehe_in_mm(15.5401095) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(159.0832188) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.9145167) + 'px', 
													top: gib_hoehe_in_mm(156.8740535) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.9227088) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.9145167) + 'px', 
													top: gib_hoehe_in_mm(156.8740535) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.9586625) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.133029) + 'px', 
													top: gib_hoehe_in_mm(170.7249352) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(143.0008042) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.133029) + 'px', 
													top: gib_hoehe_in_mm(170.7249352) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9521435) + 'px', 
													width: spalte_breit_mm(183.1832098) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(40.5183235) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9524962) + 'px', 
													width: spalte_breit_mm(183.1736869) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(48.8984755) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9524962) + 'px', 
													width: spalte_breit_mm(183.1687491) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(54.0196795) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9528489) + 'px', 
													width: spalte_breit_mm(183.146529) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(74.5891435) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9528489) + 'px', 
													width: spalte_breit_mm(183.1373588) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(83.0539435) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9532016) + 'px', 
													width: spalte_breit_mm(183.1151387) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.9306092) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9535543) + 'px', 
													width: spalte_breit_mm(183.1017361) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.9929492) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9535543) + 'px', 
													width: spalte_breit_mm(183.0886862) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(128.0094382) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.953907) + 'px', 
													width: spalte_breit_mm(183.0572959) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(156.8740535) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9542597) + 'px', 
													width: spalte_breit_mm(183.0498892) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(163.6885702) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9542597) + 'px', 
													width: spalte_breit_mm(183.0421298) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(170.7249352) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9542597) + 'px', 
													width: spalte_breit_mm(183.037192) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(175.7579642) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9549651) + 'px', 
													width: spalte_breit_mm(183.0033328) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(206.8692785) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9549651) + 'px', 
													width: spalte_breit_mm(182.998395) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(211.3171782) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9549651) + 'px', 
													width: spalte_breit_mm(182.9902829) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(218.9704155) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(20.9549651) + 'px', 
													width: spalte_breit_mm(182.9825235) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(226.7657909) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(113.0213042) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(23.6808166) + 'px', 
													top: gib_hoehe_in_mm(226.7657909) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(131.4001485) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.8943173) + 'px', 
													top: gib_hoehe_in_mm(218.9714736) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "gemeinde": {
        "text": "",
        "datenuebernehmen": "gemeinde.anzeigeName<nl>gemeinde.adresse.strasse",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 22.0980658,
            "top": 18.903103999999985,
            "right": 66.3597996,
            "bottom": 39.28634239999997,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauaufsicht": {
        "text": "",
        "datenuebernehmen": "bauamt.anzeigeName<nl>bauamt.adresse.strasse",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 114.25504880000001,
            "top": 18.903103999999985,
            "right": 158.03640520000002,
            "bottom": 39.28634239999997,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gemeinde_plz": {
        "text": "",
        "datenuebernehmen": "gemeinde.adresse.plz<&>gemeinde.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.106177900000002,
            "top": 43.9955928,
            "right": 111.2726176,
            "bottom": 47.620643400000006,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauaufsicht_plz": {
        "text": "",
        "datenuebernehmen": "bauamt.adresse.plz<&>bauamt.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 43.9955928,
            "right": 202.17857370000002,
            "bottom": 47.620643400000006,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_name": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 22.0980658,
            "top": 86.52556960000001,
            "right": 111.2726176,
            "bottom": 102.34592809999998,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_name": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 114.25504880000001,
            "top": 86.19367890000001,
            "right": 202.17857370000002,
            "bottom": 102.89296579999998,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_strasse": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.106177900000002,
            "top": 107.13912109999998,
            "right": 111.2726176,
            "bottom": 111.58913699999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_strasse": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 107.13912109999998,
            "right": 202.17857370000002,
            "bottom": 111.58913699999997,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_ort": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.106177900000002,
            "top": 119.3813381,
            "right": 111.2726176,
            "bottom": 123.4204585,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_vertreter": {
        "text": "",
        "datenuebernehmen": "bauherr.vertreter.anzeigeName<nl>bauherr.vertreter.adresse.strasse<nl>bauherr.vertreter.adresse.plz<&>bauherr.vertreter.adresse.ort",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 22.0980658,
            "top": 135.64468779999999,
            "right": 111.2726176,
            "bottom": 155.4734818,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_vorlagenberechtigt": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 114.25504880000001,
            "top": 132.15648480000002,
            "right": 202.17857370000002,
            "bottom": 141.95731239999998,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_ort": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 119.3813381,
            "right": 202.17857370000002,
            "bottom": 123.4204585,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_mitgliedsnummer": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.AK_Mitgliedsnummer",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 147.23723139999998,
            "right": 202.17857370000002,
            "bottom": 150.45244459999998,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_email": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 166.63502599999998,
            "right": 202.17857370000002,
            "bottom": 170.2600766,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_tel": {
        "text": "",
        "datenuebernehmen": "bauherr.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.106177900000002,
            "top": 159.4974361,
            "right": 65.42585,
            "bottom": 163.1228394,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bauherr_fax": {
        "text": "",
        "datenuebernehmen": "bauherr.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 68.1536318,
            "top": 159.4974361,
            "right": 111.2726176,
            "bottom": 163.1228394,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_tel": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 114.25504880000001,
            "top": 159.4974361,
            "right": 157.2544693,
            "bottom": 163.1228394,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "antragsteller_fax": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 160.3949101,
            "top": 159.4974361,
            "right": 202.17857370000002,
            "bottom": 163.1228394,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "ausnahme": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 22.478981800000003,
            "top": 171.4409162,
            "right": 25.737929800000003,
            "bottom": 174.7002169,
            "typ": "checkbox",
            "pageNo": "1"
        }
    },
    "befreiung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 83.5281775,
            "top": 171.4409162,
            "right": 86.7871255,
            "bottom": 174.7002169,
            "typ": "checkbox",
            "pageNo": "1"
        }
    },
    "abweichung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true",
        "coords": {
            "left": 144.60523650000002,
            "top": 171.4409162,
            "right": 147.8645372,
            "bottom": 174.7002169,
            "typ": "checkbox",
            "pageNo": "1"
        }
    },
    "bauherr_email": {
        "text": "",
        "datenuebernehmen": "bauherr.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.106177900000002,
            "top": 166.63502599999998,
            "right": 111.2726176,
            "bottom": 170.2600766,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "begruendung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 22.0980658,
            "top": 181.10278,
            "right": 202.17857370000002,
            "bottom": 205.7134806,
            "typ": "textarea",
            "pageNo": "1"
        },
        "showPop": false
    },
    "baugrund_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse<&>baugrundstueck.plz<&>baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.0980658,
            "top": 214.5115821,
            "right": 202.1708143,
            "bottom": 218.1366327,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gemarkungen": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.gemarkung",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 22.0980658,
            "top": 222.17469499999999,
            "right": 99.64621480000001,
            "bottom": 225.8000983,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "flure": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flur",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 101.47390620000002,
            "top": 222.17469499999999,
            "right": 130.6323206,
            "bottom": 225.8000983,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    },
    "flurstuecke": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flurstueck",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 132.2632054,
            "top": 222.17469499999999,
            "right": 202.1708143,
            "bottom": 225.8000983,
            "typ": "text",
            "pageNo": "1"
        },
        "showPop": false
    }
}
var formulaName = "NRWAntragaufAbweichung"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>