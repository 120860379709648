import { forAllMixin } from './forAllMixin.js'

export const einstellungenMixin = {

    mixins: [forAllMixin],

    data() {
        return {


			
        }
    },

    methods: {

        save_Zugriff_kontakt(benutzerID, zugriffrechte){

            // var kontaktID = this.KontaktDetail.basic.kontaktID;

            this.axios.post(this.$store.state.url_einstellungen_mitarbeiter, {
                    berechtigungen: zugriffrechte,
                    benutzerID:benutzerID,
                    was:'SetZugriffsrechte',
                    besonders: this.$store.state.dev_sub

                    }).then((response) => {

                        
                console.log(response.data);
                
                if(response.data.success===true){
                        this.$toasted.show('Die Berechtigungen wurden erfolgreich geändert.', {
                        position: 'top-center',
                        type:'success',
                        duration:1500,
                        containerClass:'ganzVorne'})

                        this.hideBModal('ZugriffrechteEinzel');
                        }
                        else{
                        this.$toasted.show('Beim Speichern der Berechtigungen ist etwas schief gelaufen.', {
                        position: 'top-center',
                        type:'error',
                        duration:1500,
                        containerClass:'ganzVorne'})
                        }

                })


    },
        getBerechtigungenVorlage(){

            this.axios.post(this.$store.state.url_getEinstellungen, {
                    besonders: this.$store.state.dev_sub,
                    was:'getVorlagen'
                    }).then((response) => {
                  //          console.log(response.data)
                        this.zugriffVorlagen = response.data;
                    })
            },

    },

    computed:{

		zugriffVorlagen:
		{
			get()
			{
				return this.$store.getters.zugriffVorlagen
			},
			set(value)
			{
				this.$store.commit('setzugriffVorlagen', value);
			}
        },
        

    

        
    },

    created(){

       console.log("hello from mixin einstellungen")
    },

}