<template>

  <!-- <div class="item_new" :style="itemStyle"> -->
    <div>
    <b-row v-if="source.basic" :style="itemStyle">
      
      <div class="card_new" @click="clickOnNamebauherr(source)" :class="{'highlightThis': projectDaten.ProjektBeteiligte[1]==source.basic.kontaktID}" v-if="source.vollerName"  >
        <label>
          <span >{{ source.vollerName.anzeigeName }}</span>
        </label>
      </div>
    </b-row>


</div>
  <!-- </div> -->

</template>

<script>

 import { kontaktMixin } from '../mixins/kontaktMixins.js'

export default {

   mixins: [kontaktMixin],

  name: 'ItemFor',

 props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      },
      otherPropValue: String // here is: 'The Progressive JavaScript Framework'
    },


  data () {
    return {

    }
  },

  computed: {

			projectDaten:
			{
				get()
				{
					return this.$store.getters.actualProject
				},
				set(value)
				{
					this.$store.commit('setActualProject', value);
				}
			},

    Mehrfachauswahl:
    {
        get()
        {
            return this.$store.getters.Mehrfachauswahl
        },
        set(value)
        {
            this.$store.commit('setMehrfachauswahl', value);
        }
    }, 

    highlightThis(){

      if(this.kontaktHighlight==this.selected)
        return true;
      else
        return false;

    },

    kontaktHighlight:
    {
        get()
        {
            return this.$store.getters.kontaktHighlight
        },
        set(value)
        {
            this.$store.commit('setkontaktHighlight', value);
        }
    }, 

    itemStyle () {
      return {
        height: `60px`,
        width:'280px'

      }
    }
  },

  methods: {

    clickOnNamebauherr(kontakt){
      this.projectDaten.ProjektBeteiligte[1] = kontakt.basic.kontaktID
      this.kontaktHighlight = this.source.basic.kontaktID;

    },


  }
}
</script>

<style scoped lang="less">


	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
  }
  
.closeSelect {
  position: absolute;
  left: 12px;
  bottom: 11px;
  width: 60px;
  height: 60px;
  opacity: 0.3;

}
.closeSelect:hover {
  opacity: 1;
}
.closeSelect:before, .closeSelect:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 83px;
  width: 2px;
  background-color: #333;
}
.closeSelect:before {
  transform: rotate(45deg);
}
.closeSelect:after {
  transform: rotate(-45deg);
}


.highlightThis{
     border: 2px solid #3c39ff !important;
}

.marked{
     border: 2px solid #0804f1 !important;
       background-color: #c3d3ff;
}

.item_new{
  width:100%;
}

.card_new:hover{
  background-color: #cccccc;
  cursor: pointer;
}

.mehrfachMarkierung:hover {
  background-color: #cccccc;
}

.mehrfachMarkierung{
    position: relative;
    border: 0.5px solid #bfffe1;
    height: 100%;
    width: 60px !important;

    padding:5px;
    display: inline-block;
    cursor: pointer;
}

.card_details{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new span{
        vertical-align:bottom;
}
// .item {
//   box-sizing: border-box;
//   display: flex;
//   @media (max-width: 640px) {
//     -webkit-user-select: none;
//     user-select: none;
//   }
  // &:hover {
  //   background-color: #f0f8ff;
  // }
//   .index {
//     flex: 1;
//     text-align: left;
//   }
//   .card {
//     position: relative;
//     // flex: 4;
//     // display: flex;
//     // align-items: left;
//     // border-bottom: 1px dashed #cecece;
//     @media (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
//       border-bottom: 0.5px solid #cccccc;
//     }
//     &-avatar {
//       width: 40px;
//       height: 40px;
//       background: #efefef;
//       color: #4169e1;
//       border-radius: 50%;
//       text-align: left;
//       line-height: 40px;
//       &.no-avatar {
//         background: #ff6347;
//         color: #ffffff;
//       }
//       &-img {
//         display: block;
//         width: 100%;
//         height: 100%;
//         border-radius: 50%;
//       }
//     }
//     &-source {
//       display: flex;
//       flex-direction: row;
//       // height: 100%;
//       padding-left: 40px;
//       @media (max-width: 640px) {
//         padding-left: 20px;
//       }
//       &-item {
//         flex: 1;
//         height: 50%;
//         line-height: 1;
//         position: relative;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         max-width: 300px;
//         overflow: hidden;
//         @media (max-width: 640px) {
//           max-width: 180px;
//         }
//         &.name {
//           padding-bottom: 3px;
//         }
//         &.time {
//           padding-top: 3px;
//           color: #a9a9a9;
//         }
//       }
//     }
//     &-height {
//       position: absolute;
//       right: 30px;
//       font-style: italic;
//       color: #999;
//       font-weight: 100;
//       font-family: sans-serif;
//       font-size: 12px;
//       @media (max-width: 375px) {
//         right: 10px;
//       }
//     }
//   }
// }
</style>
