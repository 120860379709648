<template>


  <!-- <div>{{ index }} - {{ source.vollerName.anzeigeName }} </div> -->

    <div>
    <b-row v-if="source.basic" style="height:60px">

      <div class="card_new" @click="clickOnName(source.basic.kontaktID)" :class="{'highlightThis': highlightThis}" v-if="source.vollerName"  >
        <label>
          <span >{{ anzeigeNamekurz }}  <b-badge  
                                            v-b-tooltip.hover 
                                            :title= onlineStatus.lastSeen
                                            v-if="$route.fullPath == '/einstellungen/mitarbeiter' && admin==true" 
                                            :variant="onlineVariant"> 
                                            <span v-if="onlineVariant=='success'">online</span>
                                            <span v-else>offline</span>
                                          </b-badge>
          </span>

        </label>
      </div>

      <div class="mehrfachMarkierung" :class="{'marked': source.markiert}" @click="markMe" 
        v-if="Mehrfachauswahl">
        <span v-if="source.markiert" class="closeSelect"/>
      </div>


								<div class="mehrfachMarkierung" 
                v-if="kontaktEinstellungen.showList && kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe]!=undefined"

								v-b-tooltip.hover.right 
               
								title="klicken Sie, um zu diesen Kontakt der Liste hinzuzufügen oder zu entfernen" 

									:class="{'marked': kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == source.basic.kontaktID) >=0}" 
									@click="partetMe(source.basic.kontaktID)" 
									>
									<span v-if="kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == source.basic.kontaktID) >=0" class="closeSelect"/>
								</div>


      <!-- <div class="mehrfachMarkierung" :class="{'marked': source.markiert}" @click="markMe" 
        v-if="kontaktEinstellungen.showList">
        <span v-if="source.markiert" class="closeSelect"/>
      </div> -->




    </b-row>
 
    <b-row v-if="highlightThis && itsMobile">
      <div class="card_details">

         <div>
            <div class="mb-2" v-for="(tel, telindex) in KontaktDetail.telefone" :key="telindex" >
              <a  :href="giveNumber(tel.telefonNummer)">{{tel.telefonNummer}}</a>
            </div>
        </div>
         <div>
          <div class="mb-2"  v-for="(mail, index) in KontaktDetail.emails" :key="index">
            <b-link v-if="bearbeiten===false" target="_blank" :href="'mailto:'+mail.emailAdresse">{{mail.emailAdresse}}</b-link>
          </div>
        </div>

        </div>  
      </b-row>
     
</div>

</template>

<script>

 import { kontaktMixin } from '../mixins/kontaktMixins.js'
 import { VueOfflineMixin } from 'vue-offline'

export default {

   mixins: [kontaktMixin, VueOfflineMixin],

  name: 'ItemFor',

 props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      },
      otherPropValue: String // here is: 'The Progressive JavaScript Framework'
    },

  // props: {

    // height: {
    //   type: Number,
    //   default: 0
    // },
    // index: {
    //   type: Number,
    //   default: 0
    // },
    // variable: Boolean,
    // source: {
    //   type: Object,

    // }

  // },

  data () {
    return {
isOnline:true,
      avatar:null,
      borderColor: "0.5px solid #cccccc",
      onlineStatus:0,

    }
  },

  computed: {

		kontaktEinstellungen:
		{
			get()
			{
				return this.$store.getters.kontaktEinstellungen
			},
			set(value)
			{
				this.$store.commit('setkontaktEinstellungen', value);
			}
    },
    
  onlineVariant(){

    var returnValue = ""
    if(this.onlineStatus){
      if(this.onlineStatus.online==1)
      returnValue = "success"
      else
      returnValue = "danger"
    }

    return returnValue;

  },

	admin:
		{
			get()
			{
				return this.$store.getters.admin
			},
			set(value)
			{
				this.$store.commit('setadmin', value);
			}
    },
    
    anzeigeNamekurz(){
      var returnValue=""

      if(this.source.vollerName.anzeigeName)
      {
          if(this.source.vollerName.anzeigeName.length>50)
          {
            var anfag = this.source.vollerName.anzeigeName.indexOf(" ", 40)
            console.log(anfag)
            if(anfag==-1)
            anfag=40

            returnValue= this.source.vollerName.anzeigeName.substring(0, anfag)+'...';
          }
          else
          returnValue= this.source.vollerName.anzeigeName

      }

      return returnValue;

    },

    selectedAddressbook:
    {
        get()
        {
            return this.$store.getters.selectedAddressbook
        },
        set(value)
        {
            this.$store.commit('setselectedAddressbook', value);
        }
    }, 
    Mehrfachauswahl:
    {
        get()
        {
            return this.$store.getters.Mehrfachauswahl
        },
        set(value)
        {
            this.$store.commit('setMehrfachauswahl', value);
        }
    }, 

    highlightThis(){

      if(this.kontaktHighlight==this.source.basic.kontaktID)
        return true;
      else
        return false;

    },

    kontaktHighlight:
    {
        get()
        {
            return this.$store.getters.kontaktHighlight
        },
        set(value)
        {
            this.$store.commit('setkontaktHighlight', value);
        }
    }, 
    
        KontaktDetail:
    {
        get()
        {
            return this.$store.getters.KontaktDetail
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail', value);
        }
    },
    
    itemStyle () {
      return {
        height: `60px`,

      }
    }
  },

  mounted(){

    // console.log(this.$route.fullPath);
    //   console.log(this.source.basic.benutzerID);


  if(this.$route.fullPath == "/einstellungen/mitarbeiter" && this.admin==true && this.isOnline)
  {
    this.checkOnlineStatus();
  }


  },
  methods: {

	// partetMe(kontaktID){
	// 	console.log(this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe])

	// 		var index_trgKontakt = this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == kontaktID)
      
  //     this.kontaktEinstellungen.backupListen = this.kontaktEinstellungen.listen;
      
  //     if(index_trgKontakt>=0)
	// 			this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].splice(index_trgKontakt, 1);
	// 		else
  //       this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].push({"kontaktID":kontaktID})
        
  //       this.listeSpeichern();
	// },

  
    checkOnlineStatus(){

  
        this.axios.post(this.$store.state.url_getloggin, {			
            besonders: this.$store.state.dev_sub,
              andereBenutzerID:this.source.basic.benutzerID,
              was:'fremdesLogin',
            }).then((response) => {
              console.log(response.data);

              this.onlineStatus = response.data;
              //  this.kontaktArt = response.data;

            })
            .catch(function (error) {
                        // handle error
                        // console.log(error);
            })





    },


    giveNumber(TelNumber){

      var TelNumber = TelNumber.replace(/[^\d+]/g, "");

      if(TelNumber.substr(0,3)=='+49')
        return 'tel:' + TelNumber
      else if(TelNumber.substr(0,4)=='0049')
        return "tel:+49" + TelNumber.slice(4)
      else if(TelNumber.substr(0,1)=='0')
        return "tel:+49" + TelNumber.slice(1)
      else
       return "tel:" + TelNumber
    },

    markMe(){

      if(this.showInaktivTabelle==false)
      {
          var pos = this.Addressbook.map(function(e) { return e.basic.kontaktID; }).indexOf(this.source.basic.kontaktID);

          if(this.Addressbook && pos>=0){
              this.Addressbook[pos].markiert = !this.Addressbook[pos].markiert;

              this.selectedAddressbook = [];

              for(var i = 0; i < this.Addressbook.length; i++) {
                if (this.Addressbook[i].markiert==true) 
                  this.selectedAddressbook.push({"kontaktID":this.Addressbook[i].basic.kontaktID, "aktiv":this.Addressbook[i].aktiv});
              }
          }

      }

      else if(this.showInaktivTabelle==true)
      {
          var pos = this.Papierkorb.map(function(e) { return e.basic.kontaktID; }).indexOf(this.source.basic.kontaktID);

          if(this.Papierkorb && pos>=0){
            this.Papierkorb[pos].markiert = !this.Papierkorb[pos].markiert;

            this.selectedTrash = [];
            for(var i = 0; i < this.Papierkorb.length; i++) {
              if (this.Papierkorb[i].markiert==true) 
                this.selectedTrash.push({"kontaktID":this.Papierkorb[i].basic.kontaktID, "aktiv":this.Papierkorb[i].aktiv});
            }
          }
      }		

    },

    editSelection(param){
      this.source.markiert = param.target.checked;
    },

    getAbbrName (name) {
      const arr = name.split(' ')
      if (arr.length > 1) {
        return arr[0][0] + arr[1][0]
      } else {
        return name.substr(0, 2)
      }
    }
  }
}
</script>

<style>



</style>
<style scoped lang="less">



  .mehrfachMarkierung:hover {
    background-color: #cccccc;
  }

  .mehrfachMarkierung{
      position: relative;
      border: 0.5px solid #bfffe1;
      height: 100%;
      width: 60px !important;

      padding:5px;
      display: inline-block;
      cursor: pointer;
  }

  .closeSelect {
    position: absolute;
    left: 12px;
    bottom: 11px;
    width: 60px;
    height: 60px;
    opacity: 0.3;

  }
  .closeSelect:hover {
    opacity: 1;
  }
  .closeSelect:before, .closeSelect:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 83px;
    width: 2px;
    background-color: #333;
  }
  .closeSelect:before {
    transform: rotate(45deg);
  }
  .closeSelect:after {
    transform: rotate(-45deg);
  }
  
  .marked{
      border: 2px solid #0804f1 !important;
        background-color: #c3d3ff;
  }

	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
  }
  
.highlightThis{
     border: 2px solid #3c39ff !important;
}


.item_new{
  width:100%;
}

.card_new:hover{
  background-color: #cccccc;
  cursor: pointer;
}


.card_details{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new span{
        vertical-align:bottom;
}
// .item {
//   box-sizing: border-box;
//   display: flex;
//   @media (max-width: 640px) {
//     -webkit-user-select: none;
//     user-select: none;
//   }
  // &:hover {
  //   background-color: #f0f8ff;
  // }
//   .index {
//     flex: 1;
//     text-align: left;
//   }
//   .card {
//     position: relative;
//     // flex: 4;
//     // display: flex;
//     // align-items: left;
//     // border-bottom: 1px dashed #cecece;
//     @media (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
//       border-bottom: 0.5px solid #cccccc;
//     }
//     &-avatar {
//       width: 40px;
//       height: 40px;
//       background: #efefef;
//       color: #4169e1;
//       border-radius: 50%;
//       text-align: left;
//       line-height: 40px;
//       &.no-avatar {
//         background: #ff6347;
//         color: #ffffff;
//       }
//       &-img {
//         display: block;
//         width: 100%;
//         height: 100%;
//         border-radius: 50%;
//       }
//     }
//     &-source {
//       display: flex;
//       flex-direction: row;
//       // height: 100%;
//       padding-left: 40px;
//       @media (max-width: 640px) {
//         padding-left: 20px;
//       }
//       &-item {
//         flex: 1;
//         height: 50%;
//         line-height: 1;
//         position: relative;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         max-width: 300px;
//         overflow: hidden;
//         @media (max-width: 640px) {
//           max-width: 180px;
//         }
//         &.name {
//           padding-bottom: 3px;
//         }
//         &.time {
//           padding-top: 3px;
//           color: #a9a9a9;
//         }
//       }
//     }
//     &-height {
//       position: absolute;
//       right: 30px;
//       font-style: italic;
//       color: #999;
//       font-weight: 100;
//       font-family: sans-serif;
//       font-size: 12px;
//       @media (max-width: 375px) {
//         right: 10px;
//       }
//     }
//   }
// }
</style>
