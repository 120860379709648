<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(20.1057346) + 'px'}">
													Bauantrag / Antrag auf Vorbescheid vom
													</div>
<b-popover 
												v-if="formular.bauantragVom"
												:show="formular.bauantragVom.showPop==true"
												
												ref='bauantragVom' 
												target='bauantragVom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVom.size}}</span>
												<b-button @click='plus("bauantragVom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVom" type="text" 
												v-if="formular.bauantragVom!=undefined" 
												class='textfeld' v-model='formular.bauantragVom.text' 
												style='width:100%' 
												@focus='formular.bauantragVom.showPop=true'
												@blur ='formular.bauantragVom.showPop=false'
												:disabled='formular.bauantragVom.isDisabled==true || formular.bauantragVom.isDisabled==true' @input="datumUpdate"  
												@keypress="isNumber($event, formular.bauantragVom.onylNumber) " 
												:style="{'width': spalte_breit_mm(34.0310935) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(87.0223121) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(20.1367843) + 'px', 
		
														'font-size': formular.bauantragVom.size + 'px', 
														'text-align': formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': formular.bauantragVom.letterSpacing + 'px',
														'font-weight': formular.bauantragVom.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(27.9684757) + 'px'}">
													Im einfachen Baugenehmigungsverfahren sind Angaben zu den
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(31.6083397) + 'px'}">
													gekennzeichneten Ziffern 7 bis 9 nicht erforderlich.
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(38.1569206) + 'px'}">
													 Bauherrschaft
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(180.9652081) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(19.2147433) + 'px',
										
										 top: gib_hoehe_in_mm(41.1552997) + 'px'}"><b-popover 
												v-if="formular.bauherrschaft"
												:show="formular.bauherrschaft.showPop==true"
												
												ref='bauherrschaft' 
												target='bauherrschaft'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherrschaft.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherrschaft.size}}</span>
												<b-button @click='plus("bauherrschaft", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherrschaft", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherrschaft!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherrschaft.text"
												
												@focus='formular.bauherrschaft.showPop=true'
												@blur ='formular.bauherrschaft.showPop=false'
												
												:disabled="formular.bauherrschaft.isDisabled==true || formular.bauherrschaft.isDisabled=='true'"
												:style="{'font-size': formular.bauherrschaft.size + 'px',
														'height': formular.bauherrschaft.height + 'px',
														'text-align': formular.bauherrschaft.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherrschaft.letterSpacing + 'px',
														'font-weight': formular.bauherrschaft.schriftart + 'px', }"

												id='bauherrschaft'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(164.6594058) + 'px', 
														top: gib_hoehe_in_mm(11.0547472) + 'px'}">
													Anlage I/7 zur VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.759272) + 'px', 
														top: gib_hoehe_in_mm(14.3560192) + 'px'}">
													Blatt 1
													</div>
<div :style="{
														fontSize:'17.484px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(147.626112) + 'px', 
														top: gib_hoehe_in_mm(24.5508127) + 'px'}">
													Baubeschreibung
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(53.0126446) + 'px'}">
													Grundstück (Ort, Straße, HausNr.)
													</div>
<b-popover 
												v-if="formular.baugrundstueck"
												:show="formular.baugrundstueck.showPop==true"
												
												ref='baugrundstueck' 
												target='baugrundstueck'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck.size}}</span>
												<b-button @click='plus("baugrundstueck", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck" type="text" 
												v-if="formular.baugrundstueck!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck.showPop=true'
												@blur ='formular.baugrundstueck.showPop=false'
												:disabled='formular.baugrundstueck.isDisabled==true || formular.baugrundstueck.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck.onylNumber) " 
												:style="{'width': spalte_breit_mm(180.9652081) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(19.4647433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(57.2997252) + 'px', 
		
														'font-size': formular.baugrundstueck.size + 'px', 
														'text-align': formular.baugrundstueck.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck.schriftart }" 
												/>

<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(62.7683266) + 'px'}">
													1 Bezeichnung des
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9801685) + 'px', 
														top: gib_hoehe_in_mm(66.4081906) + 'px'}">
													Vorhabens
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(74.2381306) + 'px'}">
													2 Art der Nutzung
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.betriebsbeschreibung_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'20px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(23.3201713) + 'px',top: gib_hoehe_in_mm(79.9875697) + 'px'}"
								v-model="formular.betriebsbeschreibung_check.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.2770833) + 'px', 
														top: gib_hoehe_in_mm(79.4926552) + 'px'}">
													Betriebsbeschreibung
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.2770833) + 'px', 
														top: gib_hoehe_in_mm(82.8785752) + 'px'}">
													ist beigefügt
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(87.8664586) + 'px'}">
													3 Angaben zum Grundstück
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(93.5283517) + 'px'}">
													geschützter Baumbestand
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(99.1574437) + 'px'}">
													Trinkwasserversorgung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(104.7865357) + 'px'}">
													Löschwasserversorgung
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(109.6844806) + 'px'}">
													(Art und Entfernung zur Entnahmestelle)
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(113.8861957) + 'px'}">
													Grundstücksentwässerung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(129.5887524) + 'px'}">
													Sonstiges
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(135.3116626) + 'px'}">
													4 Barrierefreies Bauen
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.4862404) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.693711) + 'px',
										
										 top: gib_hoehe_in_mm(62.9461638) + 'px'}"><b-popover 
												v-if="formular.bezeichnung_bauvorhaben"
												:show="formular.bezeichnung_bauvorhaben.showPop==true"
												
												ref='bezeichnung_bauvorhaben' 
												target='bezeichnung_bauvorhaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
												<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bezeichnung_bauvorhaben!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bezeichnung_bauvorhaben.text"
												
												@focus='formular.bezeichnung_bauvorhaben.showPop=true'
												@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
												
												:disabled="formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled=='true'"
												:style="{'font-size': formular.bezeichnung_bauvorhaben.size + 'px',
														'height': formular.bezeichnung_bauvorhaben.height + 'px',
														'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung + 'px',
														'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': formular.bezeichnung_bauvorhaben.schriftart + 'px', }"

												id='bezeichnung_bauvorhaben'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.4862404) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.693711) + 'px',
										
										 top: gib_hoehe_in_mm(74.4159678) + 'px'}"><b-popover 
												v-if="formular.art_nutzung"
												:show="formular.art_nutzung.showPop==true"
												
												ref='art_nutzung' 
												target='art_nutzung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.art_nutzung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.art_nutzung.size}}</span>
												<b-button @click='plus("art_nutzung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("art_nutzung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.art_nutzung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.art_nutzung.text"
												
												@focus='formular.art_nutzung.showPop=true'
												@blur ='formular.art_nutzung.showPop=false'
												
												:disabled="formular.art_nutzung.isDisabled==true || formular.art_nutzung.isDisabled=='true'"
												:style="{'font-size': formular.art_nutzung.size + 'px',
														'height': formular.art_nutzung.height + 'px',
														'text-align': formular.art_nutzung.schriftausrichtung + 'px',
														'letter-spacing': formular.art_nutzung.letterSpacing + 'px',
														'font-weight': formular.art_nutzung.schriftart + 'px', }"

												id='art_nutzung'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.geschuetzerBaumbestand"
												:show="formular.geschuetzerBaumbestand.showPop==true"
												
												ref='geschuetzerBaumbestand' 
												target='geschuetzerBaumbestand'  
												
												>
												<template slot='title'>
													<b-button @click="formular.geschuetzerBaumbestand.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.geschuetzerBaumbestand.size}}</span>
												<b-button @click='plus("geschuetzerBaumbestand", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("geschuetzerBaumbestand", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="geschuetzerBaumbestand" type="text" 
												v-if="formular.geschuetzerBaumbestand!=undefined" 
												class='textfeld' v-model='formular.geschuetzerBaumbestand.text' 
												style='width:100%' 
												@focus='formular.geschuetzerBaumbestand.showPop=true'
												@blur ='formular.geschuetzerBaumbestand.showPop=false'
												:disabled='formular.geschuetzerBaumbestand.isDisabled==true || formular.geschuetzerBaumbestand.isDisabled==true' 
												@keypress="isNumber($event, formular.geschuetzerBaumbestand.onylNumber) " 
												:style="{'width': spalte_breit_mm(132.4862404) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(67.943711) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(88.7355235) + 'px', 
		
														'font-size': formular.geschuetzerBaumbestand.size + 'px', 
														'text-align': formular.geschuetzerBaumbestand.schriftausrichtung, 
														'letter-spacing': formular.geschuetzerBaumbestand.letterSpacing + 'px',
														'font-weight': formular.geschuetzerBaumbestand.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.baumbestand_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(94.2084337) + 'px'}"
								v-model="formular.baumbestand_check.text" 
								value='1' 
								unchecked-value='0'> ja
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.4862404) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.693711) + 'px',
										
										 top: gib_hoehe_in_mm(104.8776087) + 'px'}"><b-popover 
												v-if="formular.loeschwasser"
												:show="formular.loeschwasser.showPop==true"
												
												ref='loeschwasser' 
												target='loeschwasser'  
												
												>
												<template slot='title'>
													<b-button @click="formular.loeschwasser.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.loeschwasser.size}}</span>
												<b-button @click='plus("loeschwasser", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("loeschwasser", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.loeschwasser!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.loeschwasser.text"
												
												@focus='formular.loeschwasser.showPop=true'
												@blur ='formular.loeschwasser.showPop=false'
												
												:disabled="formular.loeschwasser.isDisabled==true || formular.loeschwasser.isDisabled=='true'"
												:style="{'font-size': formular.loeschwasser.size + 'px',
														'height': formular.loeschwasser.height + 'px',
														'text-align': formular.loeschwasser.schriftausrichtung + 'px',
														'letter-spacing': formular.loeschwasser.letterSpacing + 'px',
														'font-weight': formular.loeschwasser.schriftart + 'px', }"

												id='loeschwasser'>
										</textarea>
	

										 
										 
										 </span>
									

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.sonstigeKlaer_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(125.019953) + 'px'}"
								v-model="formular.sonstigeKlaer_check.text" 
								value='1' 
								unchecked-value='0'>   durch sonstige Anlage;
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiEingehalten_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(135.9825744) + 'px'}"
								v-model="formular.barrirefreiEingehalten_check.text" 
								value='1' 
								unchecked-value='0'>  eingehalten bei:
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.zentrWasserversorgung_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(99.8375257) + 'px'}"
								v-model="formular.zentrWasserversorgung_check.text" 
								value='1' 
								unchecked-value='0'>   durch zentrale Wasserversorgung
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.brunnenWasserversorgung_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(130.4005967) + 'px',top: gib_hoehe_in_mm(99.8375257) + 'px'}"
								v-model="formular.brunnenWasserversorgung_check.text" 
								value='1' 
								unchecked-value='0'>    durch Brunnen
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.oeffSammelKanal_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(114.5662777) + 'px'}"
								v-model="formular.oeffSammelKanal_check.text" 
								value='1' 
								unchecked-value='0'>  durch öffentliche Sammelkanalisation
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.vorhKlaer_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(130.823484) + 'px',top: gib_hoehe_in_mm(114.5662777) + 'px'}"
								v-model="formular.vorhKlaer_check.text" 
								value='1' 
								unchecked-value='0'>  vorhanden
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.kleinKlaer_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(119.814101) + 'px'}"
								v-model="formular.kleinKlaer_check.text" 
								value='1' 
								unchecked-value='0'>   durch Kleinkläranlage
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.fertigBisKlaer_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(130.823484) + 'px',top: gib_hoehe_in_mm(119.814101) + 'px'}"
								v-model="formular.fertigBisKlaer_check.text" 
								value='1' 
								unchecked-value='0'>  fertiggestellt bis zum
								</b-form-checkbox>
<b-popover 
												v-if="formular.KlaerFertigBis"
												:show="formular.KlaerFertigBis.showPop==true"
												
												ref='KlaerFertigBis' 
												target='KlaerFertigBis'  
												
												>
												<template slot='title'>
													<b-button @click="formular.KlaerFertigBis.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.KlaerFertigBis.size}}</span>
												<b-button @click='plus("KlaerFertigBis", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("KlaerFertigBis", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="KlaerFertigBis" type="text" 
												v-if="formular.KlaerFertigBis!=undefined" 
												class='textfeld' v-model='formular.KlaerFertigBis.text' 
												style='width:100%' 
												@focus='formular.KlaerFertigBis.showPop=true'
												@blur ='formular.KlaerFertigBis.showPop=false'
												:disabled='formular.KlaerFertigBis.isDisabled==true || formular.KlaerFertigBis.isDisabled==true' 
												@keypress="isNumber($event, formular.KlaerFertigBis.onylNumber) " 
												:style="{'width': spalte_breit_mm(32.8805861) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(167.5493653) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(119.7322103) + 'px', 
		
														'font-size': formular.KlaerFertigBis.size + 'px', 
														'text-align': formular.KlaerFertigBis.schriftausrichtung, 
														'letter-spacing': formular.KlaerFertigBis.letterSpacing + 'px',
														'font-weight': formular.KlaerFertigBis.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(104.4115445) + 'px', 
														top: gib_hoehe_in_mm(124.2559284) + 'px'}">
													 Art
													</div>
<b-popover 
												v-if="formular.artKlaer"
												:show="formular.artKlaer.showPop==true"
												
												ref='artKlaer' 
												target='artKlaer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.artKlaer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.artKlaer.size}}</span>
												<b-button @click='plus("artKlaer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("artKlaer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="artKlaer" type="text" 
												v-if="formular.artKlaer!=undefined" 
												class='textfeld' v-model='formular.artKlaer.text' 
												style='width:100%' 
												@focus='formular.artKlaer.showPop=true'
												@blur ='formular.artKlaer.showPop=false'
												:disabled='formular.artKlaer.isDisabled==true || formular.artKlaer.isDisabled==true' 
												@keypress="isNumber($event, formular.artKlaer.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.2892254) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(111.7635117) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(124.9401785) + 'px', 
		
														'font-size': formular.artKlaer.size + 'px', 
														'text-align': formular.artKlaer.schriftausrichtung, 
														'letter-spacing': formular.artKlaer.letterSpacing + 'px',
														'font-weight': formular.artKlaer.schriftart }" 
												/>

<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(171.2027673) + 'px'}">
													5 Anzahl der notwendigen
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(171.1932444) + 'px'}">
													insgesamt auf
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(174.8426313) + 'px'}">
													Stellplätze
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(179.8432119) + 'px'}">
													Bedarfsermittlung gegebenenfalls
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(183.0598359) + 'px'}">
													als Beiblatt
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(86.7642) + 'px', 
														top: gib_hoehe_in_mm(165.8604204) + 'px'}">
													(Nachweis ist beigefügt)
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(176.2298004) + 'px'}">
													dem Baugrundstück
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(115.5448727) + 'px', 
														top: gib_hoehe_in_mm(176.2298004) + 'px'}">
													in Garagen 
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.4887207) + 'px', 
														top: gib_hoehe_in_mm(176.2298004) + 'px'}">
													im Freien
													</div>
<b-popover 
												v-if="formular.stellplaetze_garage"
												:show="formular.stellplaetze_garage.showPop==true"
												
												ref='stellplaetze_garage' 
												target='stellplaetze_garage'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_garage.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_garage.size}}</span>
												<b-button @click='plus("stellplaetze_garage", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_garage", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="stellplaetze_garage" 
																				v-bind:precision="parseInt(formular.stellplaetze_garage.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.stellplaetze_garage!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_garage.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_garage.showPop=true'
												@blur ='formular.stellplaetze_garage.showPop=false'
												:disabled='formular.stellplaetze_garage.isDisabled==true || formular.stellplaetze_garage.isDisabled==true' @input="pkw_neu_zaehlen"  
												@keypress="isNumber($event, formular.stellplaetze_garage.onylNumber) " 
												:style="{'width': spalte_breit_mm(11.5633981) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(102.7555537) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0276199) + 'px', 
		
														'font-size': formular.stellplaetze_garage.size + 'px', 
														'text-align': formular.stellplaetze_garage.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_garage.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_garage.schriftart }" 
												/>

<b-popover 
												v-if="formular.stellplaetze_im_freien"
												:show="formular.stellplaetze_im_freien.showPop==true"
												
												ref='stellplaetze_im_freien' 
												target='stellplaetze_im_freien'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_im_freien.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_im_freien.size}}</span>
												<b-button @click='plus("stellplaetze_im_freien", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_im_freien", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="stellplaetze_im_freien" 
																				v-bind:precision="parseInt(formular.stellplaetze_im_freien.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.stellplaetze_im_freien!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_im_freien.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_im_freien.showPop=true'
												@blur ='formular.stellplaetze_im_freien.showPop=false'
												:disabled='formular.stellplaetze_im_freien.isDisabled==true || formular.stellplaetze_im_freien.isDisabled==true' @input="pkw_neu_zaehlen"  
												@keypress="isNumber($event, formular.stellplaetze_im_freien.onylNumber) " 
												:style="{'width': spalte_breit_mm(11.5633981) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(136.071243) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0276199) + 'px', 
		
														'font-size': formular.stellplaetze_im_freien.size + 'px', 
														'text-align': formular.stellplaetze_im_freien.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_im_freien.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_im_freien.schriftart }" 
												/>

<b-popover 
												v-if="formular.stellplaetze_gesamt"
												:show="formular.stellplaetze_gesamt.showPop==true"
												
												ref='stellplaetze_gesamt' 
												target='stellplaetze_gesamt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_gesamt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_gesamt.size}}</span>
												<b-button @click='plus("stellplaetze_gesamt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_gesamt", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="stellplaetze_gesamt" 
																				v-bind:precision="parseInt(formular.stellplaetze_gesamt.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.stellplaetze_gesamt!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_gesamt.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_gesamt.showPop=true'
												@blur ='formular.stellplaetze_gesamt.showPop=false'
												:disabled='formular.stellplaetze_gesamt.isDisabled==true || formular.stellplaetze_gesamt.isDisabled==true' 
												@keypress="isNumber($event, formular.stellplaetze_gesamt.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(177.0276199) + 'px', 
		
														'font-size': formular.stellplaetze_gesamt.size + 'px', 
														'text-align': formular.stellplaetze_gesamt.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_gesamt.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_gesamt.schriftart }" 
												/>

<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(212.2993713) + 'px'}">
													6 Anzahl der notwendigen
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(215.9392353) + 'px'}">
													Fahrradstellplätze
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(220.9398159) + 'px'}">
													Bedarfsermittlung gegebenenfalls
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(224.1564399) + 'px'}">
													als Beiblatt
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(206.9570244) + 'px'}">
													davon für Menschen mit Behinderungen
													</div>
<b-popover 
												v-if="formular.fahrrad_stellplaetze_garage"
												:show="formular.fahrrad_stellplaetze_garage.showPop==true"
												
												ref='fahrrad_stellplaetze_garage' 
												target='fahrrad_stellplaetze_garage'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_garage.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_garage.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_garage", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_garage", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_garage" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_garage.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_garage!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_garage.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_garage.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_garage.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_garage.isDisabled==true || formular.fahrrad_stellplaetze_garage.isDisabled==true' @input="fahrrad_neu_zaehlen"  
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_garage.onylNumber) " 
												:style="{'width': spalte_breit_mm(11.5633981) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(102.7555537) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(218.1425643) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_garage.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_garage.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_garage.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_garage.schriftart }" 
												/>

<b-popover 
												v-if="formular.fahrrad_stellplaetze_freien"
												:show="formular.fahrrad_stellplaetze_freien.showPop==true"
												
												ref='fahrrad_stellplaetze_freien' 
												target='fahrrad_stellplaetze_freien'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_freien.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_freien.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_freien", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_freien", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_freien" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_freien.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_freien!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_freien.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_freien.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_freien.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_freien.isDisabled==true || formular.fahrrad_stellplaetze_freien.isDisabled==true' @input="fahrrad_neu_zaehlen"  
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_freien.onylNumber) " 
												:style="{'width': spalte_breit_mm(11.5633981) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(136.071243) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(218.1425643) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_freien.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_freien.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_freien.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_freien.schriftart }" 
												/>

<b-popover 
												v-if="formular.fahrrad_stellplaetze_gesamt"
												:show="formular.fahrrad_stellplaetze_gesamt.showPop==true"
												
												ref='fahrrad_stellplaetze_gesamt' 
												target='fahrrad_stellplaetze_gesamt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_gesamt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_gesamt.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_gesamt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_gesamt", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_gesamt" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_gesamt.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_gesamt!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_gesamt.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_gesamt.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_gesamt.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_gesamt.isDisabled==true || formular.fahrrad_stellplaetze_gesamt.isDisabled==true' 
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_gesamt.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(218.1425643) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_gesamt.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_gesamt.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_gesamt.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_gesamt.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(115.5448727) + 'px', 
														top: gib_hoehe_in_mm(217.3687284) + 'px'}">
													in Garagen 
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.4887207) + 'px', 
														top: gib_hoehe_in_mm(217.3687284) + 'px'}">
													im Freien
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiMehraufwand_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.4798793) + 'px',top: gib_hoehe_in_mm(151.3461864) + 'px'}"
								v-model="formular.barrirefreiMehraufwand_check.text" 
								value='1' 
								unchecked-value='0'>  unverhältnismäßiger Mehraufwand aufgrund von
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiGKlasse_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(79.823064) + 'px',top: gib_hoehe_in_mm(141.0614544) + 'px'}"
								v-model="formular.barrirefreiGKlasse_check.text" 
								value='1' 
								unchecked-value='0'>  Gebäudeklasse 3 bis 5 mit Wohnungen
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiOeffentl_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(79.823064) + 'px',top: gib_hoehe_in_mm(146.1403344) + 'px'}"
								v-model="formular.barrirefreiOeffentl_check.text" 
								value='1' 
								unchecked-value='0'>  öffentlich zugänglichen baulichen Anlagen
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiSchwierigGelaende_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(79.9073593) + 'px',top: gib_hoehe_in_mm(156.4250664) + 'px'}"
								v-model="formular.barrirefreiSchwierigGelaende_check.text" 
								value='1' 
								unchecked-value='0'>  schwierigen Geländeverhältnissen oder
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.barrirefreiUnguenstigeBebauung_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(79.9073593) + 'px',top: gib_hoehe_in_mm(161.460917) + 'px'}"
								v-model="formular.barrirefreiUnguenstigeBebauung_check.text" 
								value='1' 
								unchecked-value='0'>  ungünstiger vorhandener Bebauung
								</b-form-checkbox>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(186.3875604) + 'px'}">
													fremden Grundstück
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(189.9851004) + 'px'}">
													mit Baulast
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.1412873) + 'px', 
														top: gib_hoehe_in_mm(196.5029964) + 'px'}">
													durch Ablösung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(213.4936135) + 'px'}">
													insgesamt auf
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(217.3687284) + 'px'}">
													dem Baugrundstück
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(227.4841644) + 'px'}">
													fremden Grundstück
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(231.1240284) + 'px'}">
													mit Baulast
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(237.5572764) + 'px'}">
													durch Ablösung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(67.9719913) + 'px', 
														top: gib_hoehe_in_mm(249.3656724) + 'px'}">
													davon für Menschen mit Behinderungen
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.2147433) + 'px', 
														top: gib_hoehe_in_mm(255.9354153) + 'px'}">
													7 Schutz gegen
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.9815793) + 'px', 
														top: gib_hoehe_in_mm(260.3371113) + 'px'}">
													schädliche Einflüsse
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.4862404) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.693711) + 'px',
										
										 top: gib_hoehe_in_mm(256.1132525) + 'px'}"><b-popover 
												v-if="formular.schutzSchaedlicheEinfluesse"
												:show="formular.schutzSchaedlicheEinfluesse.showPop==true"
												
												ref='schutzSchaedlicheEinfluesse' 
												target='schutzSchaedlicheEinfluesse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.schutzSchaedlicheEinfluesse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.schutzSchaedlicheEinfluesse.size}}</span>
												<b-button @click='plus("schutzSchaedlicheEinfluesse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("schutzSchaedlicheEinfluesse", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.schutzSchaedlicheEinfluesse!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.schutzSchaedlicheEinfluesse.text"
												
												@focus='formular.schutzSchaedlicheEinfluesse.showPop=true'
												@blur ='formular.schutzSchaedlicheEinfluesse.showPop=false'
												
												:disabled="formular.schutzSchaedlicheEinfluesse.isDisabled==true || formular.schutzSchaedlicheEinfluesse.isDisabled=='true'"
												:style="{'font-size': formular.schutzSchaedlicheEinfluesse.size + 'px',
														'height': formular.schutzSchaedlicheEinfluesse.height + 'px',
														'text-align': formular.schutzSchaedlicheEinfluesse.schriftausrichtung + 'px',
														'letter-spacing': formular.schutzSchaedlicheEinfluesse.letterSpacing + 'px',
														'font-weight': formular.schutzSchaedlicheEinfluesse.schriftart + 'px', }"

												id='schutzSchaedlicheEinfluesse'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.stellplaetze_baulast"
												:show="formular.stellplaetze_baulast.showPop==true"
												
												ref='stellplaetze_baulast' 
												target='stellplaetze_baulast'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_baulast.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_baulast.size}}</span>
												<b-button @click='plus("stellplaetze_baulast", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_baulast", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="stellplaetze_baulast" 
																				v-bind:precision="parseInt(formular.stellplaetze_baulast.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.stellplaetze_baulast!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_baulast.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_baulast.showPop=true'
												@blur ='formular.stellplaetze_baulast.showPop=false'
												:disabled='formular.stellplaetze_baulast.isDisabled==true || formular.stellplaetze_baulast.isDisabled==true' @input="pkw_neu_zaehlen"  
												@keypress="isNumber($event, formular.stellplaetze_baulast.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(192.198305) + 'px', 
		
														'font-size': formular.stellplaetze_baulast.size + 'px', 
														'text-align': formular.stellplaetze_baulast.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_baulast.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_baulast.schriftart }" 
												/>

<b-popover 
												v-if="formular.stellplaetze_abloesung"
												:show="formular.stellplaetze_abloesung.showPop==true"
												
												ref='stellplaetze_abloesung' 
												target='stellplaetze_abloesung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_abloesung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_abloesung.size}}</span>
												<b-button @click='plus("stellplaetze_abloesung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_abloesung", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="stellplaetze_abloesung" 
																				v-bind:precision="parseInt(formular.stellplaetze_abloesung.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.stellplaetze_abloesung!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_abloesung.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_abloesung.showPop=true'
												@blur ='formular.stellplaetze_abloesung.showPop=false'
												:disabled='formular.stellplaetze_abloesung.isDisabled==true || formular.stellplaetze_abloesung.isDisabled==true' @input="pkw_neu_zaehlen"  
												@keypress="isNumber($event, formular.stellplaetze_abloesung.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(197.277185) + 'px', 
		
														'font-size': formular.stellplaetze_abloesung.size + 'px', 
														'text-align': formular.stellplaetze_abloesung.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_abloesung.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_abloesung.schriftart }" 
												/>

<b-popover 
												v-if="formular.stellplaetze_insgesamt"
												:show="formular.stellplaetze_insgesamt.showPop==true"
												
												ref='stellplaetze_insgesamt' 
												target='stellplaetze_insgesamt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.stellplaetze_insgesamt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.stellplaetze_insgesamt.size}}</span>
												<b-button @click='plus("stellplaetze_insgesamt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("stellplaetze_insgesamt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="stellplaetze_insgesamt" type="text" 
												v-if="formular.stellplaetze_insgesamt!=undefined" 
												class='textfeld' v-model='formular.stellplaetze_insgesamt.text' 
												style='width:100%' 
												@focus='formular.stellplaetze_insgesamt.showPop=true'
												@blur ='formular.stellplaetze_insgesamt.showPop=false'
												:disabled='formular.stellplaetze_insgesamt.isDisabled==true || formular.stellplaetze_insgesamt.isDisabled==true' 
												@keypress="isNumber($event, formular.stellplaetze_insgesamt.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(202.4502359) + 'px', 
		
														'font-size': formular.stellplaetze_insgesamt.size + 'px', 
														'text-align': formular.stellplaetze_insgesamt.schriftausrichtung, 
														'letter-spacing': formular.stellplaetze_insgesamt.letterSpacing + 'px',
														'font-weight': formular.stellplaetze_insgesamt.schriftart }" 
												/>

<b-popover 
												v-if="formular.pkwBehind"
												:show="formular.pkwBehind.showPop==true"
												
												ref='pkwBehind' 
												target='pkwBehind'  
												
												>
												<template slot='title'>
													<b-button @click="formular.pkwBehind.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.pkwBehind.size}}</span>
												<b-button @click='plus("pkwBehind", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("pkwBehind", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="pkwBehind" type="text" 
												v-if="formular.pkwBehind!=undefined" 
												class='textfeld' v-model='formular.pkwBehind.text' 
												style='width:100%' 
												@focus='formular.pkwBehind.showPop=true'
												@blur ='formular.pkwBehind.showPop=false'
												:disabled='formular.pkwBehind.isDisabled==true || formular.pkwBehind.isDisabled==true' 
												@keypress="isNumber($event, formular.pkwBehind.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(207.7308603) + 'px', 
		
														'font-size': formular.pkwBehind.size + 'px', 
														'text-align': formular.pkwBehind.schriftausrichtung, 
														'letter-spacing': formular.pkwBehind.letterSpacing + 'px',
														'font-weight': formular.pkwBehind.schriftart }" 
												/>

<b-popover 
												v-if="formular.fahrrad_stellplaetze_baulast"
												:show="formular.fahrrad_stellplaetze_baulast.showPop==true"
												
												ref='fahrrad_stellplaetze_baulast' 
												target='fahrrad_stellplaetze_baulast'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_baulast.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_baulast.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_baulast", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_baulast", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_baulast" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_baulast.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_baulast!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_baulast.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_baulast.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_baulast.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_baulast.isDisabled==true || formular.fahrrad_stellplaetze_baulast.isDisabled==true' @input="fahrrad_neu_zaehlen"  
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_baulast.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(233.2952617) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_baulast.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_baulast.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_baulast.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_baulast.schriftart }" 
												/>

<b-popover 
												v-if="formular.fahrrad_stellplaetze_abloesung"
												:show="formular.fahrrad_stellplaetze_abloesung.showPop==true"
												
												ref='fahrrad_stellplaetze_abloesung' 
												target='fahrrad_stellplaetze_abloesung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_abloesung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_abloesung.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_abloesung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_abloesung", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_abloesung" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_abloesung.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_abloesung!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_abloesung.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_abloesung.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_abloesung.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_abloesung.isDisabled==true || formular.fahrrad_stellplaetze_abloesung.isDisabled==true' @input="fahrrad_neu_zaehlen"  
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_abloesung.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(238.3311123) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_abloesung.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_abloesung.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_abloesung.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_abloesung.schriftart }" 
												/>

<b-popover 
												v-if="formular.fahrrad_stellplaetze_insgesamt"
												:show="formular.fahrrad_stellplaetze_insgesamt.showPop==true"
												
												ref='fahrrad_stellplaetze_insgesamt' 
												target='fahrrad_stellplaetze_insgesamt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.fahrrad_stellplaetze_insgesamt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.fahrrad_stellplaetze_insgesamt.size}}</span>
												<b-button @click='plus("fahrrad_stellplaetze_insgesamt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("fahrrad_stellplaetze_insgesamt", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="fahrrad_stellplaetze_insgesamt" 
																				v-bind:precision="parseInt(formular.fahrrad_stellplaetze_insgesamt.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.fahrrad_stellplaetze_insgesamt!=undefined" 
												class='textfeld' v-model='formular.fahrrad_stellplaetze_insgesamt.text' 
												style='width:100%' 
												@focus='formular.fahrrad_stellplaetze_insgesamt.showPop=true'
												@blur ='formular.fahrrad_stellplaetze_insgesamt.showPop=false'
												:disabled='formular.fahrrad_stellplaetze_insgesamt.isDisabled==true || formular.fahrrad_stellplaetze_insgesamt.isDisabled==true' 
												@keypress="isNumber($event, formular.fahrrad_stellplaetze_insgesamt.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(243.5471926) + 'px', 
		
														'font-size': formular.fahrrad_stellplaetze_insgesamt.size + 'px', 
														'text-align': formular.fahrrad_stellplaetze_insgesamt.schriftausrichtung, 
														'letter-spacing': formular.fahrrad_stellplaetze_insgesamt.letterSpacing + 'px',
														'font-weight': formular.fahrrad_stellplaetze_insgesamt.schriftart }" 
												/>

<b-popover 
												v-if="formular.FahrradBehind"
												:show="formular.FahrradBehind.showPop==true"
												
												ref='FahrradBehind' 
												target='FahrradBehind'  
												
												>
												<template slot='title'>
													<b-button @click="formular.FahrradBehind.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.FahrradBehind.size}}</span>
												<b-button @click='plus("FahrradBehind", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("FahrradBehind", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="FahrradBehind" type="text" 
												v-if="formular.FahrradBehind!=undefined" 
												class='textfeld' v-model='formular.FahrradBehind.text' 
												style='width:100%' 
												@focus='formular.FahrradBehind.showPop=true'
												@blur ='formular.FahrradBehind.showPop=false'
												:disabled='formular.FahrradBehind.isDisabled==true || formular.FahrradBehind.isDisabled==true' 
												@keypress="isNumber($event, formular.FahrradBehind.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.3990768) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.0575181) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(250.5535781) + 'px', 
		
														'font-size': formular.FahrradBehind.size + 'px', 
														'text-align': formular.FahrradBehind.schriftausrichtung, 
														'letter-spacing': formular.FahrradBehind.letterSpacing + 'px',
														'font-weight': formular.FahrradBehind.schriftart }" 
												/>

<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(149.488368) + 'px', 
														top: gib_hoehe_in_mm(201.7183713) + 'px'}">
													Summe
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(150.504144) + 'px', 
														top: gib_hoehe_in_mm(242.7208044) + 'px'}">
													Summe 
													</div>
<div :style="{
														fontSize:'11.7px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.967416) + 'px', 
														top: gib_hoehe_in_mm(273.7174912) + 'px'}">
													Fortsetzung Blatt 2
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.0943153) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.5305512) + 'px', 
													top: gib_hoehe_in_mm(0.074919899999941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.2791777) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.5888593) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(260.6892207) + 'px', 
													top: gib_hoehe_in_mm(18.2791777) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.581496) + 'px', 
													width: spalte_breit_mm(30.0073633) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(278.8683984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.581496) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.559796) + 'px', 
													top: gib_hoehe_in_mm(273.4086024) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.2836153) + 'px', 
													width: spalte_breit_mm(183.305244) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(273.4086024) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.8296904) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(255.2294247) + 'px', 
													top: gib_hoehe_in_mm(18.2791777) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(37.9598377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(62.3807857) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.8505897) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(87.4789177) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7869193) + 'px', 
													width: spalte_breit_mm(134.80194) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.1080097) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(98.7794257) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(104.323517) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.4235297) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(129.1260864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.7757273) + 'px', 
													width: spalte_breit_mm(183.813132) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.923769) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.744948) + 'px', 
													width: spalte_breit_mm(134.8439113) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(150.287381) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.8180513) + 'px', 
													width: spalte_breit_mm(183.770808) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(170.8148737) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.744948) + 'px', 
													width: spalte_breit_mm(134.8439113) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(206.5790064) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.8180513) + 'px', 
													width: spalte_breit_mm(183.770808) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(211.9118304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.744948) + 'px', 
													width: spalte_breit_mm(134.8439113) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(248.9880071) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.8180513) + 'px', 
													width: spalte_breit_mm(183.770808) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(255.5475217) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7869193) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(210.8738727) + 'px', 
													top: gib_hoehe_in_mm(62.3807857) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.6184047) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(84.832648) + 'px', 
													top: gib_hoehe_in_mm(170.8148737) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7869193) + 'px', 
													width: spalte_breit_mm(134.80194) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(118.6293817) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7869193) + 'px', 
													width: spalte_breit_mm(134.80194) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(123.877205) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(129.1308767) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(15.6325553) + 'px', 
													top: gib_hoehe_in_mm(113.5085304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(166.6715593) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.17888) + 'px', 
													top: gib_hoehe_in_mm(118.7143824) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'11.7px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(18.5489168) + 'px'}">
													Baubeschreibung Blatt 2
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.0573447) + 'px', 
														top: gib_hoehe_in_mm(18.4374636) + 'px'}">
													Bauherrschaft
													</div>
<b-popover 
												v-if="formular.bauherrschaftSeiteZwei"
												:show="formular.bauherrschaftSeiteZwei.showPop==true"
												
												ref='bauherrschaftSeiteZwei' 
												target='bauherrschaftSeiteZwei'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherrschaftSeiteZwei.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherrschaftSeiteZwei.size}}</span>
												<b-button @click='plus("bauherrschaftSeiteZwei", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherrschaftSeiteZwei", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherrschaftSeiteZwei" type="text" 
												v-if="formular.bauherrschaftSeiteZwei!=undefined" 
												class='textfeld' v-model='formular.bauherrschaftSeiteZwei.text' 
												style='width:100%' 
												@focus='formular.bauherrschaftSeiteZwei.showPop=true'
												@blur ='formular.bauherrschaftSeiteZwei.showPop=false'
												:disabled='formular.bauherrschaftSeiteZwei.isDisabled==true || formular.bauherrschaftSeiteZwei.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherrschaftSeiteZwei.onylNumber) " 
												:style="{'width': spalte_breit_mm(89.3069429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(68.3073447) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(21.6858427) + 'px', 
		
														'font-size': formular.bauherrschaftSeiteZwei.size + 'px', 
														'text-align': formular.bauherrschaftSeiteZwei.schriftausrichtung, 
														'letter-spacing': formular.bauherrschaftSeiteZwei.letterSpacing + 'px',
														'font-weight': formular.bauherrschaftSeiteZwei.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauantragVomSeiteZwei"
												:show="formular.bauantragVomSeiteZwei.showPop==true"
												
												ref='bauantragVomSeiteZwei' 
												target='bauantragVomSeiteZwei'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauantragVomSeiteZwei.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauantragVomSeiteZwei.size}}</span>
												<b-button @click='plus("bauantragVomSeiteZwei", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauantragVomSeiteZwei", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauantragVomSeiteZwei" type="text" 
												v-if="formular.bauantragVomSeiteZwei!=undefined" 
												class='textfeld' v-model='formular.bauantragVomSeiteZwei.text' 
												style='width:100%' 
												@focus='formular.bauantragVomSeiteZwei.showPop=true'
												@blur ='formular.bauantragVomSeiteZwei.showPop=false'
												:disabled='formular.bauantragVomSeiteZwei.isDisabled==true || formular.bauantragVomSeiteZwei.isDisabled==true' @input="datumZweiUpdate"  
												@keypress="isNumber($event, formular.bauantragVomSeiteZwei.onylNumber) " 
												:style="{'width': spalte_breit_mm(38.0885543) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(161.973531) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(21.6858427) + 'px', 
		
														'font-size': formular.bauantragVomSeiteZwei.size + 'px', 
														'text-align': formular.bauantragVomSeiteZwei.schriftausrichtung, 
														'letter-spacing': formular.bauantragVomSeiteZwei.letterSpacing + 'px',
														'font-weight': formular.bauantragVomSeiteZwei.schriftart }" 
												/>

<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(165.5619651) + 'px', 
														top: gib_hoehe_in_mm(11.0159502) + 'px'}">
													Anlage I/7 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.7176534) + 'px', 
														top: gib_hoehe_in_mm(14.3172222) + 'px'}">
													Blatt 2
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(162.0596541) + 'px', 
														top: gib_hoehe_in_mm(18.4374636) + 'px'}">
													Bauantrag vom
													</div>
<b-popover 
												v-if="formular.kWHeizraum"
												:show="formular.kWHeizraum.showPop==true"
												
												ref='kWHeizraum' 
												target='kWHeizraum'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kWHeizraum.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kWHeizraum.size}}</span>
												<b-button @click='plus("kWHeizraum", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kWHeizraum", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="kWHeizraum" 
																				v-bind:precision="parseInt(formular.kWHeizraum.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.kWHeizraum!=undefined" 
												class='textfeld' v-model='formular.kWHeizraum.text' 
												style='width:100%' 
												@focus='formular.kWHeizraum.showPop=true'
												@blur ='formular.kWHeizraum.showPop=false'
												:disabled='formular.kWHeizraum.isDisabled==true || formular.kWHeizraum.isDisabled==true' 
												@keypress="isNumber($event, formular.kWHeizraum.onylNumber) " 
												:style="{'width': spalte_breit_mm(15.2106688) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(177.1911252) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(27.5590031) + 'px', 
		
														'font-size': formular.kWHeizraum.size + 'px', 
														'text-align': formular.kWHeizraum.schriftausrichtung, 
														'letter-spacing': formular.kWHeizraum.letterSpacing + 'px',
														'font-weight': formular.kWHeizraum.schriftart }" 
												/>

<b-popover 
												v-if="formular.cbm1"
												:show="formular.cbm1.showPop==true"
												
												ref='cbm1' 
												target='cbm1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.cbm1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.cbm1.size}}</span>
												<b-button @click='plus("cbm1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("cbm1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="cbm1" 
																				v-bind:precision="parseInt(formular.cbm1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.cbm1!=undefined" 
												class='textfeld' v-model='formular.cbm1.text' 
												style='width:100%' 
												@focus='formular.cbm1.showPop=true'
												@blur ='formular.cbm1.showPop=false'
												:disabled='formular.cbm1.isDisabled==true || formular.cbm1.isDisabled==true' 
												@keypress="isNumber($event, formular.cbm1.onylNumber) " 
												:style="{'width': spalte_breit_mm(15.2106688) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(177.1911252) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(44.5732511) + 'px', 
		
														'font-size': formular.cbm1.size + 'px', 
														'text-align': formular.cbm1.schriftausrichtung, 
														'letter-spacing': formular.cbm1.letterSpacing + 'px',
														'font-weight': formular.cbm1.schriftart }" 
												/>

<b-popover 
												v-if="formular.cbm2"
												:show="formular.cbm2.showPop==true"
												
												ref='cbm2' 
												target='cbm2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.cbm2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.cbm2.size}}</span>
												<b-button @click='plus("cbm2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("cbm2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="cbm2" 
																				v-bind:precision="parseInt(formular.cbm2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.cbm2!=undefined" 
												class='textfeld' v-model='formular.cbm2.text' 
												style='width:100%' 
												@focus='formular.cbm2.showPop=true'
												@blur ='formular.cbm2.showPop=false'
												:disabled='formular.cbm2.isDisabled==true || formular.cbm2.isDisabled==true' 
												@keypress="isNumber($event, formular.cbm2.onylNumber) " 
												:style="{'width': spalte_breit_mm(15.2106688) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(177.1911252) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(49.9060751) + 'px', 
		
														'font-size': formular.cbm2.size + 'px', 
														'text-align': formular.cbm2.schriftausrichtung, 
														'letter-spacing': formular.cbm2.letterSpacing + 'px',
														'font-weight': formular.cbm2.schriftart }" 
												/>

<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.8030068) + 'px', 
														top: gib_hoehe_in_mm(27.3889896) + 'px'}">
													kW
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(194.6911054) + 'px', 
														top: gib_hoehe_in_mm(44.4032376) + 'px'}">
													m
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.8026541) + 'px', 
														top: gib_hoehe_in_mm(49.7360616) + 'px'}">
													 m
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.unterirdisch_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(55.7394447) + 'px'}"
								v-model="formular.unterirdisch_check.text" 
								value='1' 
								unchecked-value='0'>  unterirdischer
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lagerraum_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(99.3351334) + 'px',top: gib_hoehe_in_mm(55.7394447) + 'px'}"
								v-model="formular.lagerraum_check.text" 
								value='1' 
								unchecked-value='0'>  Lagerraum
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.sonstRaum_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(125.7872807) + 'px',top: gib_hoehe_in_mm(55.7394447) + 'px'}"
								v-model="formular.sonstRaum_check.text" 
								value='1' 
								unchecked-value='0'>  sonstiger Raum:
								</b-form-checkbox>
<b-popover 
												v-if="formular.sonstigerRaum"
												:show="formular.sonstigerRaum.showPop==true"
												
												ref='sonstigerRaum' 
												target='sonstigerRaum'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstigerRaum.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstigerRaum.size}}</span>
												<b-button @click='plus("sonstigerRaum", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstigerRaum", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="sonstigerRaum" type="text" 
												v-if="formular.sonstigerRaum!=undefined" 
												class='textfeld' v-model='formular.sonstigerRaum.text' 
												style='width:100%' 
												@focus='formular.sonstigerRaum.showPop=true'
												@blur ='formular.sonstigerRaum.showPop=false'
												:disabled='formular.sonstigerRaum.isDisabled==true || formular.sonstigerRaum.isDisabled==true' 
												@keypress="isNumber($event, formular.sonstigerRaum.onylNumber) " 
												:style="{'width': spalte_breit_mm(31.9180678) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(167.440381) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(55.5073038) + 'px', 
		
														'font-size': formular.sonstigerRaum.size + 'px', 
														'text-align': formular.sonstigerRaum.schriftausrichtung, 
														'letter-spacing': formular.sonstigerRaum.letterSpacing + 'px',
														'font-weight': formular.sonstigerRaum.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lueftungUeberbruekct_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(91.7571687) + 'px'}"
								v-model="formular.lueftungUeberbruekct_check.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(76.6914407) + 'px', 
														top: gib_hoehe_in_mm(90.9924387) + 'px'}">
													Lüftungsanlage überbrückt Gebäudetrennwände oder Geschossdecken
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.schematische_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(77.0303854) + 'px',top: gib_hoehe_in_mm(96.8360487) + 'px'}"
								v-model="formular.schematische_check.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(81.6856727) + 'px', 
														top: gib_hoehe_in_mm(96.0713187) + 'px'}">
													Schematische Darstellung entsprechend den Bildern der Lüftungsanlagenrichtlinie
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(81.6856727) + 'px', 
														top: gib_hoehe_in_mm(99.8381547) + 'px'}">
													und Beschreibung der Lüftungsanlagen mit Angabe der Feuerwiderstandsdauer und
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(81.6856727) + 'px', 
														top: gib_hoehe_in_mm(103.4356947) + 'px'}">
													Baustoffklasse der Bauteile und Lüftungsabschnitte ist beigefügt.
													</div>
<div :style="{
														fontSize:'14.511px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(27.51314) + 'px'}">
													8
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(27.3889896) + 'px'}">
													Angaben zur Aufstellung
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(31.0288536) + 'px'}">
													von Feuerstätten
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.0576974) + 'px', 
														top: gib_hoehe_in_mm(27.3794667) + 'px'}">
													GesamtNennwärmeleistung
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(44.4032376) + 'px'}">
													Angaben zur
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(48.0431016) + 'px'}">
													Brennstofflagerung
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.festerBrennstoff_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(45.0737967) + 'px'}"
								v-model="formular.festerBrennstoff_check.text" 
								value='1' 
								unchecked-value='0'>  fester Brennstoff
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.heizoel_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(99.3351334) + 'px',top: gib_hoehe_in_mm(45.0737967) + 'px'}"
								v-model="formular.heizoel_check.text" 
								value='1' 
								unchecked-value='0'>  Heizöl
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.gas_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(50.4066207) + 'px'}"
								v-model="formular.gas_check.text" 
								value='1' 
								unchecked-value='0'>  Gas
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.fluessiggas_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(99.3351334) + 'px',top: gib_hoehe_in_mm(50.4066207) + 'px'}"
								v-model="formular.fluessiggas_check.text" 
								value='1' 
								unchecked-value='0'>  Flüssiggas
								</b-form-checkbox>
<div :style="{
														fontSize:'14.511px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(64.165724) + 'px'}">
													9
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(64.0415736) + 'px'}">
													Lüftung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(69.6611427) + 'px'}">
													Lüftungsanlage für Mittel
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(73.3010067) + 'px'}">
													oder Großgarage
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(78.5491827) + 'px'}">
													sonstige
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(82.2313707) + 'px'}">
													genehmigungspflichtige
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(85.8289107) + 'px'}">
													Lüftungsanlage
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(73.1785487) + 'px', 
														top: gib_hoehe_in_mm(58.6992267) + 'px'}">
													Lagerbehälter
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lueftungMittel_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(70.3412247) + 'px'}"
								v-model="formular.lueftungMittel_check.text" 
								value='1' 
								unchecked-value='0'> ja
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(94.8930055) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(105.1948912) + 'px',
										
										 top: gib_hoehe_in_mm(69.6054925) + 'px'}"><b-popover 
												v-if="formular.lueftungArtAnlage"
												:show="formular.lueftungArtAnlage.showPop==true"
												
												ref='lueftungArtAnlage' 
												target='lueftungArtAnlage'  
												
												>
												<template slot='title'>
													<b-button @click="formular.lueftungArtAnlage.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.lueftungArtAnlage.size}}</span>
												<b-button @click='plus("lueftungArtAnlage", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("lueftungArtAnlage", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.lueftungArtAnlage!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.lueftungArtAnlage.text"
												
												@focus='formular.lueftungArtAnlage.showPop=true'
												@blur ='formular.lueftungArtAnlage.showPop=false'
												
												:disabled="formular.lueftungArtAnlage.isDisabled==true || formular.lueftungArtAnlage.isDisabled=='true'"
												:style="{'font-size': formular.lueftungArtAnlage.size + 'px',
														'height': formular.lueftungArtAnlage.height + 'px',
														'text-align': formular.lueftungArtAnlage.schriftausrichtung + 'px',
														'letter-spacing': formular.lueftungArtAnlage.letterSpacing + 'px',
														'font-weight': formular.lueftungArtAnlage.schriftart + 'px', }"

												id='lueftungArtAnlage'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(80.7531339) + 'px', 
														top: gib_hoehe_in_mm(69.6611427) + 'px'}">
													Art der Anlage
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lueftunSonst_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(79.2292647) + 'px'}"
								v-model="formular.lueftunSonst_check.text" 
								value='1' 
								unchecked-value='0'> ja
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(94.8930055) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(105.1948912) + 'px',
										
										 top: gib_hoehe_in_mm(78.4681381) + 'px'}"><b-popover 
												v-if="formular.sonstlueftungArtAnlage"
												:show="formular.sonstlueftungArtAnlage.showPop==true"
												
												ref='sonstlueftungArtAnlage' 
												target='sonstlueftungArtAnlage'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstlueftungArtAnlage.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstlueftungArtAnlage.size}}</span>
												<b-button @click='plus("sonstlueftungArtAnlage", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstlueftungArtAnlage", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.sonstlueftungArtAnlage!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.sonstlueftungArtAnlage.text"
												
												@focus='formular.sonstlueftungArtAnlage.showPop=true'
												@blur ='formular.sonstlueftungArtAnlage.showPop=false'
												
												:disabled="formular.sonstlueftungArtAnlage.isDisabled==true || formular.sonstlueftungArtAnlage.isDisabled=='true'"
												:style="{'font-size': formular.sonstlueftungArtAnlage.size + 'px',
														'height': formular.sonstlueftungArtAnlage.height + 'px',
														'text-align': formular.sonstlueftungArtAnlage.schriftausrichtung + 'px',
														'letter-spacing': formular.sonstlueftungArtAnlage.letterSpacing + 'px',
														'font-weight': formular.sonstlueftungArtAnlage.schriftart + 'px', }"

												id='sonstlueftungArtAnlage'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(80.7531339) + 'px', 
														top: gib_hoehe_in_mm(78.5491827) + 'px'}">
													Art der Anlage
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(108.7780416) + 'px'}">
													10 weitere Angaben,
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(112.4507067) + 'px'}">
													sofern wegen Ortsatzungen
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(116.0905707) + 'px'}">
													oder Denkmalschutz
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(119.7304347) + 'px'}">
													erforderlich
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(125.0636114) + 'px'}">
													äußere Gestaltung
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.0573447) + 'px', 
														top: gib_hoehe_in_mm(124.8826763) + 'px'}">
													Wände
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(127.8214491) + 'px'}"><b-popover 
												v-if="formular.waende"
												:show="formular.waende.showPop==true"
												
												ref='waende' 
												target='waende'  
												
												>
												<template slot='title'>
													<b-button @click="formular.waende.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.waende.size}}</span>
												<b-button @click='plus("waende", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("waende", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.waende!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.waende.text"
												
												@focus='formular.waende.showPop=true'
												@blur ='formular.waende.showPop=false'
												
												:disabled="formular.waende.isDisabled==true || formular.waende.isDisabled=='true'"
												:style="{'font-size': formular.waende.size + 'px',
														'height': formular.waende.height + 'px',
														'text-align': formular.waende.schriftausrichtung + 'px',
														'letter-spacing': formular.waende.letterSpacing + 'px',
														'font-weight': formular.waende.schriftart + 'px', }"

												id='waende'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.0573447) + 'px', 
														top: gib_hoehe_in_mm(139.8230483) + 'px'}">
													Dachflächen und Dachaufbauten
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(68.0573447) + 'px', 
														top: gib_hoehe_in_mm(154.7634203) + 'px'}">
													Türen und Fenster
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.heizraum_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(33.688288) + 'px'}"
								v-model="formular.heizraum_check.text" 
								value='1' 
								unchecked-value='0'>  Heizraum
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.aufstellungsraum_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(68.3962894) + 'px',top: gib_hoehe_in_mm(39.3600567) + 'px'}"
								v-model="formular.aufstellungsraum_check.text" 
								value='1' 
								unchecked-value='0'> Aufstellraum
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(109.0616888) + 'px'}"><b-popover 
												v-if="formular.weitereAngaben"
												:show="formular.weitereAngaben.showPop==true"
												
												ref='weitereAngaben' 
												target='weitereAngaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.weitereAngaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.weitereAngaben.size}}</span>
												<b-button @click='plus("weitereAngaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("weitereAngaben", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.weitereAngaben!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.weitereAngaben.text"
												
												@focus='formular.weitereAngaben.showPop=true'
												@blur ='formular.weitereAngaben.showPop=false'
												
												:disabled="formular.weitereAngaben.isDisabled==true || formular.weitereAngaben.isDisabled=='true'"
												:style="{'font-size': formular.weitereAngaben.size + 'px',
														'height': formular.weitereAngaben.height + 'px',
														'text-align': formular.weitereAngaben.schriftausrichtung + 'px',
														'letter-spacing': formular.weitereAngaben.letterSpacing + 'px',
														'font-weight': formular.weitereAngaben.schriftart + 'px', }"

												id='weitereAngaben'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(142.8041451) + 'px'}"><b-popover 
												v-if="formular.dach"
												:show="formular.dach.showPop==true"
												
												ref='dach' 
												target='dach'  
												
												>
												<template slot='title'>
													<b-button @click="formular.dach.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.dach.size}}</span>
												<b-button @click='plus("dach", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("dach", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.dach!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.dach.text"
												
												@focus='formular.dach.showPop=true'
												@blur ='formular.dach.showPop=false'
												
												:disabled="formular.dach.isDisabled==true || formular.dach.isDisabled=='true'"
												:style="{'font-size': formular.dach.size + 'px',
														'height': formular.dach.height + 'px',
														'text-align': formular.dach.schriftausrichtung + 'px',
														'letter-spacing': formular.dach.letterSpacing + 'px',
														'font-weight': formular.dach.schriftart + 'px', }"

												id='dach'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(158.0178596) + 'px'}"><b-popover 
												v-if="formular.tueren"
												:show="formular.tueren.showPop==true"
												
												ref='tueren' 
												target='tueren'  
												
												>
												<template slot='title'>
													<b-button @click="formular.tueren.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.tueren.size}}</span>
												<b-button @click='plus("tueren", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("tueren", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.tueren!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.tueren.text"
												
												@focus='formular.tueren.showPop=true'
												@blur ='formular.tueren.showPop=false'
												
												:disabled="formular.tueren.isDisabled==true || formular.tueren.isDisabled=='true'"
												:style="{'font-size': formular.tueren.size + 'px',
														'height': formular.tueren.height + 'px',
														'text-align': formular.tueren.schriftausrichtung + 'px',
														'letter-spacing': formular.tueren.letterSpacing + 'px',
														'font-weight': formular.tueren.schriftart + 'px', }"

												id='tueren'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(170.4921532) + 'px'}"><b-popover 
												v-if="formular.spielplatz"
												:show="formular.spielplatz.showPop==true"
												
												ref='spielplatz' 
												target='spielplatz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.spielplatz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.spielplatz.size}}</span>
												<b-button @click='plus("spielplatz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("spielplatz", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.spielplatz!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.spielplatz.text"
												
												@focus='formular.spielplatz.showPop=true'
												@blur ='formular.spielplatz.showPop=false'
												
												:disabled="formular.spielplatz.isDisabled==true || formular.spielplatz.isDisabled=='true'"
												:style="{'font-size': formular.spielplatz.size + 'px',
														'height': formular.spielplatz.height + 'px',
														'text-align': formular.spielplatz.schriftausrichtung + 'px',
														'letter-spacing': formular.spielplatz.letterSpacing + 'px',
														'font-weight': formular.spielplatz.schriftart + 'px', }"

												id='spielplatz'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(181.670627) + 'px'}"><b-popover 
												v-if="formular.gruenanlagen"
												:show="formular.gruenanlagen.showPop==true"
												
												ref='gruenanlagen' 
												target='gruenanlagen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gruenanlagen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gruenanlagen.size}}</span>
												<b-button @click='plus("gruenanlagen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gruenanlagen", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.gruenanlagen!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.gruenanlagen.text"
												
												@focus='formular.gruenanlagen.showPop=true'
												@blur ='formular.gruenanlagen.showPop=false'
												
												:disabled="formular.gruenanlagen.isDisabled==true || formular.gruenanlagen.isDisabled=='true'"
												:style="{'font-size': formular.gruenanlagen.size + 'px',
														'height': formular.gruenanlagen.height + 'px',
														'text-align': formular.gruenanlagen.schriftausrichtung + 'px',
														'letter-spacing': formular.gruenanlagen.letterSpacing + 'px',
														'font-weight': formular.gruenanlagen.schriftart + 'px', }"

												id='gruenanlagen'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(197.6602815) + 'px'}"><b-popover 
												v-if="formular.nichtUeberbaut"
												:show="formular.nichtUeberbaut.showPop==true"
												
												ref='nichtUeberbaut' 
												target='nichtUeberbaut'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nichtUeberbaut.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nichtUeberbaut.size}}</span>
												<b-button @click='plus("nichtUeberbaut", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nichtUeberbaut", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.nichtUeberbaut!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.nichtUeberbaut.text"
												
												@focus='formular.nichtUeberbaut.showPop=true'
												@blur ='formular.nichtUeberbaut.showPop=false'
												
												:disabled="formular.nichtUeberbaut.isDisabled==true || formular.nichtUeberbaut.isDisabled=='true'"
												:style="{'font-size': formular.nichtUeberbaut.size + 'px',
														'height': formular.nichtUeberbaut.height + 'px',
														'text-align': formular.nichtUeberbaut.schriftausrichtung + 'px',
														'letter-spacing': formular.nichtUeberbaut.letterSpacing + 'px',
														'font-weight': formular.nichtUeberbaut.schriftart + 'px', }"

												id='nichtUeberbaut'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(132.3130647) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(67.774832) + 'px',
										
										 top: gib_hoehe_in_mm(210.2022935) + 'px'}"><b-popover 
												v-if="formular.sonstige11"
												:show="formular.sonstige11.showPop==true"
												
												ref='sonstige11' 
												target='sonstige11'  
												
												>
												<template slot='title'>
													<b-button @click="formular.sonstige11.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.sonstige11.size}}</span>
												<b-button @click='plus("sonstige11", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("sonstige11", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.sonstige11!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.sonstige11.text"
												
												@focus='formular.sonstige11.showPop=true'
												@blur ='formular.sonstige11.showPop=false'
												
												:disabled="formular.sonstige11.isDisabled==true || formular.sonstige11.isDisabled=='true'"
												:style="{'font-size': formular.sonstige11.size + 'px',
														'height': formular.sonstige11.height + 'px',
														'text-align': formular.sonstige11.schriftausrichtung + 'px',
														'letter-spacing': formular.sonstige11.letterSpacing + 'px',
														'font-weight': formular.sonstige11.schriftart + 'px', }"

												id='sonstige11'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(169.3345154) + 'px'}">
													Spielplatz für Kleinkinder
													</div>
<div :style="{
														fontSize:'8.9985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(172.7934443) + 'px'}">
													(Größe und Ausstattung)
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(181.2698834) + 'px'}">
													Befestigung, Gestaltung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(184.9520714) + 'px'}">
													und Eingrünung
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.7275767) + 'px', 
														top: gib_hoehe_in_mm(188.5496114) + 'px'}">
													der Zufahrten
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.7275767) + 'px', 
														top: gib_hoehe_in_mm(192.1471514) + 'px'}">
													der Stellplätze im Freien
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(197.4799754) + 'px'}">
													Gestaltung und
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(201.1621634) + 'px'}">
													Bepflanzung der nicht
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.6063727) + 'px', 
														top: gib_hoehe_in_mm(204.7597034) + 'px'}">
													überbauten Flächen
													</div>
<div :style="{
														fontSize:'13.113px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(210.1020503) + 'px'}">
													11 Sonstiges
													</div>
<div :style="{
														fontSize:'12.0435px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(221.4512309) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.OrtDatum"
												:show="formular.OrtDatum.showPop==true"
												
												ref='OrtDatum' 
												target='OrtDatum'  
												
												>
												<template slot='title'>
													<b-button @click="formular.OrtDatum.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.OrtDatum.size}}</span>
												<b-button @click='plus("OrtDatum", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("OrtDatum", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="OrtDatum" type="text" 
												v-if="formular.OrtDatum!=undefined" 
												class='textfeld' v-model='formular.OrtDatum.text' 
												style='width:100%' 
												@focus='formular.OrtDatum.showPop=true'
												@blur ='formular.OrtDatum.showPop=false'
												:disabled='formular.OrtDatum.isDisabled==true || formular.OrtDatum.isDisabled==true' 
												@keypress="isNumber($event, formular.OrtDatum.onylNumber) " 
												:style="{'width': spalte_breit_mm(84.3306986) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(19.5433954) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(225.9439356) + 'px', 
		
														'font-size': formular.OrtDatum.size + 'px', 
														'text-align': formular.OrtDatum.schriftausrichtung, 
														'letter-spacing': formular.OrtDatum.letterSpacing + 'px',
														'font-weight': formular.OrtDatum.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(231.9317114) + 'px'}">
													Die/Der Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(19.1731247) + 'px', 
														top: gib_hoehe_in_mm(267.3145754) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'13.4985px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.1227494) + 'px', 
														top: gib_hoehe_in_mm(221.5623314) + 'px'}">
													Genehmigungsvermerk
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(18.2403807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.5479461) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(253.8754094) + 'px', 
													top: gib_hoehe_in_mm(18.2403807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(272.0157901) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7033294) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(202.789636) + 'px', 
													top: gib_hoehe_in_mm(18.2403807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.3578527) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(21.346648) + 'px', 
													top: gib_hoehe_in_mm(69.2831247) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.7519) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(21.346648) + 'px', 
													top: gib_hoehe_in_mm(69.2831247) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.6421734) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(19.484392) + 'px', 
													top: gib_hoehe_in_mm(44.0156967) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.4517534) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.46938) + 'px', 
													top: gib_hoehe_in_mm(44.0156967) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(124.0946734) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.861068) + 'px', 
													top: gib_hoehe_in_mm(54.6390207) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(159.2232407) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(25.621372) + 'px', 
													top: gib_hoehe_in_mm(18.2403807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(27.0014487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7033294) + 'px', 
													width: spalte_breit_mm(134.8446167) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(32.4612447) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(43.9141191) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(63.6540327) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.6606527) + 'px', 
													width: spalte_breit_mm(134.8872934) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(69.2831247) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(78.1711647) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(90.6990687) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.3905007) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(124.6432694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.6606527) + 'px', 
													width: spalte_breit_mm(134.8872934) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(139.6259654) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.6606527) + 'px', 
													width: spalte_breit_mm(134.8872934) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(154.565632) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(169.6333287) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(180.8918654) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(197.101252) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(209.7145094) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(183.5175694) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(221.1839607) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.7257047) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(50.6218061) + 'px', 
													top: gib_hoehe_in_mm(221.1839607) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.7257047) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(0.41002330000001) + 'px', 
													top: gib_hoehe_in_mm(271.7057668) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(87.695328) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(231.5536934) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7033294) + 'px', 
													width: spalte_breit_mm(134.8446167) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(49.178872) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7033294) + 'px', 
													width: spalte_breit_mm(134.8446167) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(54.3850767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.7033294) + 'px', 
													width: spalte_breit_mm(134.8446167) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(38.0903367) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.0303767) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(253.8754094) + 'px', 
													top: gib_hoehe_in_mm(18.2403807) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "bauantragVom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "datumUpdate",
        "showPop": false
    },
    "bauherrschaft": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName<nl>bauherr.adresse.strasse<nl>bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.plz<&>baugrundstueck.ort<&>baugrundstueck.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "betriebsbeschreibung_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bezeichnung_bauvorhaben": {
        "text": "",
        "datenuebernehmen": "bezeichnung_bauvorhaben",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "art_nutzung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "geschuetzerBaumbestand": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baumbestand_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "loeschwasser": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "sonstigeKlaer_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "barrirefreiEingehalten_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "zentrWasserversorgung_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "brunnenWasserversorgung_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "oeffSammelKanal_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "vorhKlaer_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "kleinKlaer_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "fertigBisKlaer_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "KlaerFertigBis": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "artKlaer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "stellplaetze_garage": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "pkw_neu_zaehlen",
        "showPop": false
    },
    "stellplaetze_im_freien": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "pkw_neu_zaehlen",
        "showPop": false
    },
    "stellplaetze_gesamt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "fahrrad_stellplaetze_garage": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "fahrrad_neu_zaehlen",
        "showPop": false
    },
    "fahrrad_stellplaetze_freien": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "fahrrad_neu_zaehlen",
        "showPop": false
    },
    "fahrrad_stellplaetze_gesamt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "barrirefreiMehraufwand_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "barrirefreiGKlasse_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "barrirefreiOeffentl_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "barrirefreiSchwierigGelaende_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "barrirefreiUnguenstigeBebauung_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "schutzSchaedlicheEinfluesse": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "stellplaetze_baulast": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "pkw_neu_zaehlen",
        "showPop": false
    },
    "stellplaetze_abloesung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "pkw_neu_zaehlen",
        "showPop": false
    },
    "stellplaetze_insgesamt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "pkwBehind": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "fahrrad_stellplaetze_baulast": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "fahrrad_neu_zaehlen",
        "showPop": false
    },
    "fahrrad_stellplaetze_abloesung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "fahrrad_neu_zaehlen",
        "showPop": false
    },
    "fahrrad_stellplaetze_insgesamt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "FahrradBehind": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherrschaftSeiteZwei": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauantragVomSeiteZwei": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "datumZweiUpdate",
        "showPop": false
    },
    "kWHeizraum": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "cbm1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "cbm2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "showPop": false
    },
    "unterirdisch_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lagerraum_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonstRaum_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonstigerRaum": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lueftungUeberbruekct_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "schematische_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "festerBrennstoff_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "heizoel_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "gas_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "fluessiggas_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lueftungMittel_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lueftungArtAnlage": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lueftunSonst_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonstlueftungArtAnlage": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "waende": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "heizraum_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "aufstellungsraum_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "weitereAngaben": {
        "text": "",
        "datenuebernehmen": null,
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "dach": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "tueren": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "spielplatz": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gruenanlagen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "nichtUeberbaut": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "sonstige11": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "OrtDatum": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    }
}
var formulaName = "NRWbaubeschreibung"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
 pkw_neu_zaehlen(){
	
			var stellplaetze_garage = 0;
			if(parseInt(this.formular.stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.formular.stellplaetze_garage.text);


			var stellplaetze_im_freien = 0;
			if(parseInt(this.formular.stellplaetze_im_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.formular.stellplaetze_im_freien.text);
			
			var stellplaetze_baulast = 0;
			if(parseInt(this.formular.stellplaetze_baulast.text)>0)
			stellplaetze_baulast = parseInt(this.formular.stellplaetze_baulast.text);

			var stellplaetze_abloesung = 0;
			if(parseInt(this.formular.stellplaetze_abloesung.text)>0)
			stellplaetze_abloesung = parseInt(this.formular.stellplaetze_abloesung.text);
			
			this.formular.stellplaetze_gesamt.text = stellplaetze_garage + stellplaetze_im_freien;
			this.formular.stellplaetze_insgesamt.text = stellplaetze_garage + stellplaetze_im_freien + stellplaetze_baulast + stellplaetze_abloesung;
			
			if(this.formular.stellplaetze_gesamt.text==0)
				this.formular.stellplaetze_gesamt.text = '';
			if(this.formular.stellplaetze_insgesamt.text==0)
				this.formular.stellplaetze_insgesamt.text = '';
				
},

fahrrad_neu_zaehlen(){
	
			var stellplaetze_garage = 0;
			if(parseInt(this.formular.fahrrad_stellplaetze_garage.text)>0)
			stellplaetze_garage = parseInt(this.formular.fahrrad_stellplaetze_garage.text);


			var stellplaetze_im_freien = 0;
			if(parseInt(this.formular.fahrrad_stellplaetze_freien.text)>0)
			stellplaetze_im_freien = parseInt(this.formular.fahrrad_stellplaetze_freien.text);
			
			var stellplaetze_baulast = 0;
			if(parseInt(this.formular.fahrrad_stellplaetze_baulast.text)>0)
			stellplaetze_baulast = parseInt(this.formular.fahrrad_stellplaetze_baulast.text);

			var stellplaetze_abloesung = 0;
			if(parseInt(this.formular.fahrrad_stellplaetze_abloesung.text)>0)
			stellplaetze_abloesung = parseInt(this.formular.fahrrad_stellplaetze_abloesung.text);
			
			this.formular.fahrrad_stellplaetze_gesamt.text = stellplaetze_garage + stellplaetze_im_freien;
			this.formular.fahrrad_stellplaetze_insgesamt.text = stellplaetze_garage + stellplaetze_im_freien + stellplaetze_baulast + stellplaetze_abloesung;
			
			if(this.formular.fahrrad_stellplaetze_gesamt.text==0)
				this.formular.fahrrad_stellplaetze_gesamt.text = '';
			if(this.formular.fahrrad_stellplaetze_insgesamt.text==0)
				this.formular.fahrrad_stellplaetze_insgesamt.text = '';
			

			
			
			
},

datumUpdate(){
	
	this.formular.bauantragVomSeiteZwei.text = this.formular.bauantragVom.text; 
	
				if(this.formular.bauantragVomSeiteZwei.text==0)
				this.formular.bauantragVomSeiteZwei.text = '';
				
				if(this.formular.bauantragVom.text==0)
				this.formular.bauantragVom.text = '';
				
				
	
},
datumZweiUpdate(){
	
	this.formular.bauantragVom.text = this.formular.bauantragVomSeiteZwei.text; 
	
				if(this.formular.bauantragVomSeiteZwei.text==0)
				this.formular.bauantragVomSeiteZwei.text = '';
				
				if(this.formular.bauantragVom.text==0)
				this.formular.bauantragVom.text = '';
				
	
},



	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>