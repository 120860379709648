
<template>
	<div >

		<span v-if="KontaktDetail.length==0 && !$route.params">
		<b-card class="container mb-3">
			<h4>Dieser Kontakt existiert nicht oder kann nicht angezeigt werden.</h4>
		</b-card>
		</span>

		<span v-if="KontaktDetail.basic">
		<b-card v-if="KontaktDetail.aktiv==0" class="container down-space-5">
			<h4>Dies ist ein gelöschter Kontakt!</h4>
		</b-card>

		<b-card class="container mb-3" >

				<b-button 
				:disabled="!KontaktDetail.basic" 
				v-if="KontaktDetail.aktiv==1" 
				class="mr-2 btn-nav" 
				@click="eintragBearbeiten" 
				v-b-tooltip.hover title="Eintrag bearbeiten" 
				variant="info" >
				<span class="fa fa-edit"></span>
				</b-button>			
				
				<b-button :disabled="!KontaktDetail.basic" 
					v-if="KontaktDetail.aktiv==0"
					 class="mr-2 btn-nav" 
					@click="inaktive_person_de_aktivieren(KontaktDetail.basic.kontaktID)" 
					v-b-tooltip.hover title="wiederherstellen" 
					variant="info" >
						<span class="fa fa-undo"></span>
				</b-button>			

				
				<b-button 
					:disabled="!KontaktDetail.basic" 
					id="popover-loeschen" 
					v-if="KontaktDetail.aktiv==1" 
					class="mr-2 btn-nav" 
					@click="eintragLoeschen"
					@dblclick="inaktive_person_de_aktivieren(KontaktDetail.basic.kontaktID)"
					v-b-tooltip.hover
					title="Eintrag löschen" 
					variant="info" >
					<span style="color:red;font-weight:800;">X</span>
				</b-button>			
				
				<!-- ODER -->

				<!-- <b-button 
					:disabled="!KontaktDetail.basic" 
					v-else 
					class="mr-2 btn-nav" 
					id="popover-loeschen-endgueltig" 
					v-b-tooltip.hover 
					title="Eintrag endgültig löschen" 
					variant="info" >
					<span style="color:red;font-weight:800;">X</span>
				</b-button>		 -->



				<b-button 
					v-if="KontaktDetail.aktiv==1 && $route.path.includes('/mitarbeiter') && (admin==1 || admin==true)"
					:disabled="!KontaktDetail.basic" 
					class="mr-2 btn-nav" 
					v-b-tooltip.hover 
					@click="zugriffsrechteOeffnen"
					title="Zugriffsrechte bearbeiten" 
					variant="info" >
					<span style="color:red;font-weight:800;">?</span>
				</b-button>	

<!-- && loeschButtonID -->
				<b-popover
					v-if="KontaktDetail.aktiv==1 "
					target="popover-loeschen"
					triggers="manual"
					:show.sync="popoverShowLoeschen"
					placement="auto"
					container="my-container"
					ref="popoverWirkichLoeschen"
					>
					
					<template v-slot:title>
						Bitte bestätigen.
					</template>

					<div>
						Wollen Sie diesen Kontakt wirklich löschen?
						<div class="mt-3">
						<b-input-group size="lg">
							<b-button style="width:80px;margin-right:20px;" @click="popoverShowLoeschen = !popoverShowLoeschen"  >nein</b-button>
							<b-button style="width:80px;" @click="inaktive_person_de_aktivieren(KontaktDetail.basic.kontaktID)" variant="outline-primary">ja</b-button>
						</b-input-group>
						</div>
					</div>
				</b-popover>


	</b-card>

		<b-card class="container mb-3" >


					<b-row v-if="KontaktDetail.basic.kind>0" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Art d. Eintrags </label>
						</b-col>
							
						<b-col cols="12" md="8">

							{{optionen_art_eintrag[KontaktDetail.basic.kind]}}

						</b-col>
					</b-row>


					<b-row v-if="KontaktDetail.status>0" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Status </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{statusListeKey[KontaktDetail.status]}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.art.summary.length>0" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Kategorie </label>
						</b-col>
							<!-- {{KontaktDetail.art.details}}
							{{auswahlListen}} -->
							<!-- {{auswahlListen}} -->
						<b-col v-if="auswahlListen.art_key!=undefined" cols="12" md="8">
                                <span v-for="(detail, index) in KontaktDetail.art.summary" :key="index">
                                  {{auswahlListen.art_key[detail]}}<span v-if="index < (KontaktDetail.art.summary.length-1)">, </span>
                               </span>
						</b-col>
					</b-row>


					<b-row v-if="KontaktDetail.art.summary.includes('10') && KontaktDetail.vollerName.AK_Mitgliedsnummer" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Mitgliedsnummer AK </label>
						</b-col>
							
						<b-col cols="12" md="8">
							<span v-if="KontaktDetail.vollerName.AK_Mitgliedsnummer">
								{{KontaktDetail.vollerName.AK_Mitgliedsnummer}}
							</span>
							<span v-else>keine vorhanden</span>
						</b-col>
					</b-row>

<!-- {{KontaktDetail.taetigkeit}} -->

					<b-row v-if="auswahlListen.alle_taetigkeiten_key!=undefined && ( KontaktDetail.art.summary.includes('3') || KontaktDetail.art.summary.includes('5') ) " class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Tätigkeit </label>
						</b-col>
							
						<b-col cols="12" md="8">

                            <span v-for="(detail, index) in KontaktDetail.taetigkeit.summary" :key="index">
                                    {{auswahlListen.alle_taetigkeiten_key[detail]}}<span v-if="index < (KontaktDetail.taetigkeit.summary.length-1)">, </span>
                            </span>
						</b-col>
					</b-row>
		</b-card>
	


	<!-- <transition name="fade"> -->
		<b-card v-show="KontaktDetail.basic.kind" class="container mb-3" >
					<b-row class="down-space-5">
						<b-col cols="12" sm="10">
							<div class="ueberschrift einzug_links">Kontakt</div>
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.basic.kind=='2' && KontaktDetail.vollerName.anzeigeName" class="down-space-5">
						<b-col cols="12" md="4">					
							<label class="eingabe-label" style="margin-right:10px;"> Anzeigename </label>
						</b-col>

						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.anzeigeName}}
							<span v-if="!itsMobile && kontaktEinstellungen.showList && KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined">
									| <b-form-select  style="width:40%;float:right;"
										@input="speicher_kontakt_einzeln(KontaktDetail, 'html anzeigename / KontaktDetail')"
								v-b-tooltip.hover 
								title="Die hier ausgwählte Anrede wird in der aktuell aktiven Liste als Anrede genutzt."
									v-model="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe].anrede" 
									v-bind:class="{ 'form-control': bearbeiten }"  
									:options="anreden" />	
							</span>

						</b-col>



					</b-row>	

				 <span v-if="KontaktDetail.basic.kind=='3' || KontaktDetail.basic.kind=='1'"> 	<!-- Einzelperson -->
					
					<b-row v-if="KontaktDetail.vollerName.anzeigeName" class="down-space-5">
						<b-col cols="12" md="4">					
							<label class="eingabe-label" style="margin-right:10px;"> Anzeigename </label>  
						</b-col>
						<b-col cols="12" md="8">
							<span class="float-left"> {{KontaktDetail.vollerName.anzeigeName}}</span>  
							
							<b-form-checkbox  
									v-if="!itsMobile && kontaktEinstellungen.showList && KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined"

								v-b-tooltip.hover 
								title="Wenn der Schieber auf 'eingeschaltet' steht, wird der Anzeigename in der aktuellen Liste verwendet. Andernfalls der Nachname, Vorname, etc."
								class="float-left ml-2"
								name="check-button"
								switch
								@input="speicher_kontakt_einzeln(KontaktDetail, 'html anzeigename / KontaktDetail')"
								v-model="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe].welcherName" 
							>
								<p class="text-danger">f. Liste</p>

							</b-form-checkbox>
	
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.anrede" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Anrede </label>
						</b-col>
						<b-col cols="12" md="2">
							{{KontaktDetail.vollerName.anrede}}
						</b-col>
						<b-col cols="12" md="4">
							<span v-if="!itsMobile && kontaktEinstellungen.showList && KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined">
									| <b-form-select  style="width:90%;float:right;"
										@input="speicher_kontakt_einzeln(KontaktDetail, 'html anrede / KontaktDetail')"
								v-b-tooltip.hover 
								title="Die hier ausgwählte Anrede wird in der aktuell aktiven Liste als Anrede genutzt."
									v-model="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe].anrede" 
									v-bind:class="{ 'form-control': bearbeiten }"  
									:options="anreden" />	
							</span>
						</b-col>
						<b-col style="padding-left:0px;padding-top:5px"  cols="12" md="2">
							<span
							  
							v-if="!itsMobile && kontaktEinstellungen.showList && KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined">
							<p class="text-danger">f. Liste</p>
								
							</span>
						</b-col>


					</b-row>
					
					<b-row v-if="KontaktDetail.vollerName.titel" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Titel </label>
						</b-col>
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.titel}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.vorname" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Vorname </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.vorname}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.mittelname" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> 2. Vorname </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.mittelname}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.nachname" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Nachname </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.nachname}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.anhang" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Namenszusatz </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.anhang}}
						</b-col>
					</b-row>


					<b-row v-if="KontaktDetail.vollerName.spitzname" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Spitzname </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.spitzname}}
						</b-col>
					</b-row>

					<b-row v-if="KontaktDetail.vollerName.funktion" class="down-space-5">
						<b-col cols="12" md="4">	
							<label class="eingabe-label"> Funktion </label>
						</b-col>
							
						<b-col cols="12" md="8">
							{{KontaktDetail.vollerName.funktion}}
						</b-col>
					</b-row>
				</span>
		</b-card>


		<b-card v-if="woherKommstDu!='mitarbeiter' && kontaktGruppe.length>0" 
			class="container mb-3" >
<!-- {{KontaktDetail.gruppe}}	
    {{kontaktGruppe}} -->

            <!-- kontaktBeteiligte() -->

					
					
				<b-row class="down-space-5">
					<b-col cols="12" sm="10">	
						<div class="ueberschrift einzug_links">verbunden mit</div>
					</b-col>
				</b-row>
			
     			<span v-for="(beteiligter, indexG) in kontaktGruppe" :key="indexG">
					<b-row style="margin-left:0px !important; margin-top:5px; overflow:hidden">

						<b-col cols="8">
							<span style="min-width:200px;display:inline-block">
								{{beteiligter.vollerName.anzeigeName}}
							</span>
								<!-- v-b-tooltip.hover 
								title="Details anzeigen" -->
								<!-- {{beteiligter.telefone}} -->
							<b-btn
								size="sm"
								class="ml-1"
								v-if="beteiligter.telefone.length>0 || beteiligter.emails.length>0"
								:id="'popover-'+indexG"
								>
								<b-icon icon="eye"></b-icon>
							</b-btn>
						</b-col>      

						<b-col 
								v-if="!itsMobile && kontaktEinstellungen.showList && KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined"
								v-b-tooltip.hover 
								title="Wenn das Häkchen gesetzt ist, wird dieser Kontaktname in der aktuell aktiven Liste als erste Zeile genutzt."
								
								cols="2">
							<b-form-checkbox
								id="checkbox-1"
								v-model="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe].verbundenMit" 
								name="verbundenMit"
								:value="beteiligter.vollerName.anzeigeName"
								:unchecked-value="null"
								@input="speicher_kontakt_einzeln(KontaktDetail, 'verbundenMit / kontakDetail')"
								>
								<!-- value="accepted"
								unchecked-value="not_accepted" -->
								<p class="text-danger">f. Liste</p>

							</b-form-checkbox>
						</b-col>   

         
						<b-col style="padding-right:15px" class="text-right" cols="2">
							<b-btn
								size="sm"
								@click="clickOnName(beteiligter.basic.kontaktID)" 
								variant="primary"
								v-b-tooltip.hover 
								title="klicken Sie, um zu diesem Kontakt zu wechseln" 
							>
								<b-icon icon="box-arrow-up-right"></b-icon>
							</b-btn>
						</b-col>

						

					</b-row>
						
						<hr class="my-1">

						<b-popover
							v-if="searchID(indexG)"
							:target="'popover-'+indexG"
							triggers="click blur"
							placement="auto"
							container="my-container"
							:ref="'popover-'+indexG"
						>	

						<div>
							<div v-if="beteiligter.telefone.length>0">
								<span v-for="(tel , index) in beteiligter.telefone" :key="index">
									{{tel.telefonNummer}}<br>
								</span>

								<hr v-if="beteiligter.emails.length>0" class="my-1">

							</div>

							<div v-if="beteiligter.emails.length>0">
								<span v-for="(email , index) in beteiligter.emails" :key="index">
									{{email.emailAdresse}}<br>
								</span>
							</div>

						</div>
					</b-popover>

				</span>
			
		</b-card>
		
<!-- wieder einkommenteren -->

	<b-card class="container mb-3" v-if="KontaktDetail.telefone.length>0 || KontaktDetail.emails.length>0" v-show="KontaktDetail.basic.kind">

                <b-col cols="12" sm="10">			
                    <b-row class="down-space-5">
                        <div id="kommunikation" class="ueberschrift einzug_links">Kommunikation</div>
                    </b-row>
                </b-col>


                <b-col v-if="KontaktDetail.telefone.length>0" cols="12" sm="10">	

                        <b-row class="down-space-5">
                            <label class="eingabe-label down-space-5"> Rufnummern </label>
                                <b-list-group class="w-100">

                            <transition-group name="list" tag="span">

                                <b-input-group class="mb-1" v-for="(tel, index) in KontaktDetail.telefone" :key="index">

                                    <b-input-group-text class="lessPadding" slot="prepend">

                                        <span v-if="tel.berufOprivat=='2'" class="mr-1">
											<b-icon v-b-tooltip.hover title="privat" icon="house"></b-icon>
										</span>

                                        <span v-if="tel.berufOprivat=='1'" class="mr-1">
											<span v-b-tooltip.hover title="beruflich" class="fa fa-building"></span>
										</span>

                                        <span v-if="tel.telefonArt=='1'" class="mr-1">
											<span v-b-tooltip.hover title="Festnetz" class="fa fa-phone"></span>
										</span>
                                       
									    <span v-if="tel.telefonArt=='2'" class="mr-1">
											<b-icon v-b-tooltip.hover title="Mobil" icon="phone"></b-icon>
										</span>												
                                        
										<span v-if="tel.telefonArt=='3'" class="mr-1">
											<span v-b-tooltip.hover title="Fax" class="fa fa-fax"></span>
											
										</span>		

                                    </b-input-group-text>
									<span style="padding:5px">{{tel.telefonNummer}}</span>
                                  



                                </b-input-group>
                            </transition-group>

                            </b-list-group>
                        </b-row>
                </b-col>

<!-- wieder einkommenteren -->
<!-- move alte E-Mail-->
<!-- Telefon Ende -->
<!-- Trenner -->

  <hr v-if="KontaktDetail.emails.length>0" class="my-2">

<!-- E-Mail Anfang -->

<!-- wieder einkommenteren -->

		<b-col v-if="KontaktDetail.emails.length>0" class="down-space-5" cols="12">	
                <b-row class="down-space-5">
                    <label class="eingabe-label down-space-5"> E-Mail Adressen </label>
                        <b-list-group class="w-100">

                    <transition-group name="list" tag="span">

                        <b-row style="margin-left:0px !important; margin-top:5px;" v-for="(mail, index) in KontaktDetail.emails" :key="index">
                            <b-col cols="1">
                                <span v-b-tooltip.hover title="privat" v-if="mail.berufOprivat=='2'" class="fa fa-home abstand_rechts"></span>
                                <span v-b-tooltip.hover title="beruflich" v-if="mail.berufOprivat=='1'" class="fa fa-building abstand_rechts"></span>
                          	</b-col>
						   	<b-col>

								{{mail.emailAdresse}}
							</b-col>                            
							<b-col style="padding-right:15px; max-width:50px;  min-width:50px; " class="text-right" cols="2">
								<b-btn
								size="sm"
									target="_blank"
									:href="'mailto:'+mail.emailAdresse"
									v-b-tooltip.hover 
									title="Rufen Sie diese E-Mail Adresse auf." 
									variant="primary">
									<b-icon icon="box-arrow-up-right"></b-icon>
								</b-btn>

							</b-col>


                        </b-row>
                    </transition-group>

                    </b-list-group>
                </b-row>


<!-- wieder einkommenteren -->


			<!-- move alte E-Mail-->


			</b-col>


	
  <hr v-if="KontaktDetail.www.length>0" class="my-2">
<!-- E-Mail fertig -->


<!-- www Anfang -->

<!-- {{KontaktDetail}} -->
		<b-col v-if="KontaktDetail.www.length>0" class="down-space-5" cols="12">	
                <b-row class="down-space-5">
                    <label class="eingabe-label down-space-5"> Internet Adressen </label>
                        <b-list-group class="w-100">

                    <transition-group name="list" tag="span">


      					<b-row style="margin-left:0px !important; margin-top:5px;" v-for="(seite, index) in KontaktDetail.www" :key="index">
                            <b-col cols="1">
                                <span v-b-tooltip.hover title="privat" v-if="seite.berufOprivat=='2'" class="fa fa-home abstand_rechts"></span>
                                <span v-b-tooltip.hover title="beruflich" v-if="seite.berufOprivat=='1'" class="fa fa-building abstand_rechts"></span>
                          	</b-col>
						   	<b-col>
								{{seite.wwwAdresse}}
							</b-col>                            
							<b-col style="padding-right:15px" class="text-right" cols="2">
								<b-btn
								size="sm"
									target="_blank"
									:href="seite.wwwAdresse"
									v-b-tooltip.hover 
									title="Rufen Sie diese Internet Adresse auf." 
									variant="primary">
									<b-icon icon="box-arrow-up-right"></b-icon>
								</b-btn>
							</b-col>
                        </b-row>



                    </transition-group>

                    </b-list-group>
                </b-row>

			</b-col>


		</b-card>

	

<!-- www fertig -->

<!-- Trenner -->

	<b-card v-if="KontaktDetail.basic.kind==2 && kontaktBeteiligte.length>0" 
		class="container mb-3">

				<b-row class="down-space-5">
					<b-col class="down-space-5" cols="12">	
						<div class="ueberschrift einzug_links">dazugehörige Personen</div>
					</b-col>
				</b-row>

      			<span v-for="(beteiligter, indexB) in kontaktBeteiligte" :key="indexB">

					<b-row v-if="beteiligter.aktiv!=0" style="margin-left:0px !important; margin-top:5px; overflow:hidden">
						
						<b-col>
							<span style="min-width:200px;display:inline-block">
								<span style="color:grey" v-if="beteiligter.aktuell==2">
									{{beteiligter.vollerName.anzeigeName}}
								</span>

								<span v-else>
									{{beteiligter.vollerName.anzeigeName}}
								</span>

							</span>
								<!-- v-b-tooltip.hover 
								title="Details anzeigen" -->
							<b-btn
								size="sm"
								v-if="beteiligter.telefone.length>0 || beteiligter.emails.length>0"
								:id="'popover-'+indexB"
								>
								<b-icon icon="eye"></b-icon>

							</b-btn>

							<span class="ml-2" v-if="beteiligter.aktuell==2">
								<b-icon
								v-b-tooltip.hover 
								title="Dieser Kontakt arbeitet nicht mehr für diese Firma."
								icon="person-dash"></b-icon>
							</span>
						</b-col>  

						<b-col style="padding-right:15px" class="text-right" cols="2">
							<b-btn
								size="sm"
								v-b-tooltip.hover 
								title="klicken Sie, um zu diesem Kontakt zu wechseln" 
								@click="clickOnName(beteiligter.basic.kontaktID)" 
								variant="primary">
								<b-icon icon="box-arrow-up-right"></b-icon>
							</b-btn>



						</b-col>

						<b-col v-if="kontaktEinstellungen.showList && kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe]!=undefined" style="padding-right:15px" class="text-right" cols="2">

								<div class="mehrfachMarkierung" 
								v-b-tooltip.hover.left
								title="klicken Sie, um zu diesen Kontakt der Liste hinzuzufügen" 

									:class="{'marked': kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == beteiligter.basic.kontaktID) >=0}" 
									@click="partetMe(beteiligter.basic.kontaktID)" 
									>
									
									<span v-if="kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == beteiligter.basic.kontaktID) >=0" class="closeSelect"/>
								</div>
						</b-col>


						

					</b-row>
							 <hr class="my-1">

						<b-popover
						v-if="searchID(indexB)"

							:target="'popover-'+indexB"
							triggers="click blur"
							placement="auto"
							container="my-container"
							:ref="'popover-'+indexB"
						>	

						<!-- <template v-slot:title>
							Adressbuch filtern
						</template>
						-->
						<div>

							<div v-if="beteiligter.telefone.length>0">
								<span v-for="(tel , index) in beteiligter.telefone" :key="index">
									{{tel.telefonNummer}}<br>
								</span>

								<hr v-if="beteiligter.emails.length>0" class="my-1">

							</div>

							<div v-if="beteiligter.emails.length>0">
								<span v-for="(email , index) in beteiligter.emails" :key="index">
									{{email.emailAdresse}}<br>
								</span>
							</div>

						<!--  -->

						<!-- <b-button @click="onClose" size="sm" variant="primary">Auswahl übernehmen</b-button> -->

						</div>
					</b-popover>

				</span>

	</b-card>



<!-- {{alleAdressenBeteiligte}} -->

			<b-card v-if="alleAdressenBeteiligte.length>0" 
				class="container mb-3">

				<b-row class="down-space-5">
					<b-col cols="12" sm="10">
							<div ref="adressenID" class="ueberschrift einzug_links">Anschrift(en)</div>
					</b-col>
				</b-row>
<!-- {{KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]}} -->

				<b-row class="down-space-5 mb-5">
					<b-col cols="12">	

						<b-table-lite
							v-if="alleAdressenBeteiligte.length>0"
							id="table-transition-example"
							:fields="Addressfields"
							:items="alleAdressenBeteiligte"
							:tbody-transition-props="transProps"
							striped
							stacked="lg"
						>

						<template v-slot:cell(berufOprivat)="data">

							<span  v-if="data.value=='2'" class="mr-1">
								<span v-b-tooltip.hover title="privat" class="fa fa-home"></span>
							</span>
							<span v-if="data.value=='1'" class="mr-1">
								<span v-b-tooltip.hover title="beruflich" class="fa fa-building"></span>
							</span>
								
						</template>

					<template #head(mitgabeAdresse)="data">
						<span v-b-tooltip.hover title="die hier ausgewählte Adresse, wird in der aktiven Liste als Adresse genutzt." class="text-danger">{{ data.label }}</span>
					</template>

						<!-- <b-form-radio v-if="KontaktDetail.adresse.length>1" v-model="KontaktDetail.adressePreferred" name="raio-adresses" :value="data.item.id"></b-form-radio> -->
						<template v-slot:cell(mitgabeAdresse)="data" >
							<!-- {{data.item.identifier}} -->
							<b-form-radio 
								name="radioListe"
								v-if="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe]!=undefined"
								@input="speicher_kontakt_einzeln(KontaktDetail, 'radioListe / kontakDetail')"
								v-model="KontaktDetail.mitgabe[kontaktEinstellungen.aktuelleListe].adresse" 
								:value="data.item.identifier">
							</b-form-radio>
					</template>

						</b-table-lite>
					</b-col>
				</b-row>

			</b-card>


		<!-- Projekte Anfang -->
			<b-card v-if="KontaktDetail.projekte!=undefined && KontaktDetail.projekte.length>0" 
				class="container down-space-10 mb-4">

				<b-row class="down-space-5">
					<b-col cols="12" sm="10">
							<div ref="adressenID" class="ueberschrift einzug_links">Projekte</div>
					</b-col>
				</b-row>

				<b-row v-for="(projekt , index) in KontaktDetail.projekte" :key="index" class="mb-1">
					<b-col class="ml-1" >
						{{projekt[1].projectName}}
					</b-col>
					
					<b-col style="padding-right:15px" class="text-right" cols="2">
						<b-btn
							size="sm" ID richtg
							@click="loadProject(projekt[0].projectID, 'projekte')"
							variant="primary"
							v-b-tooltip.hover 
							title="klicken Sie, um zu diesem Projekt zu wechseln" 
						>
							<b-icon icon="box-arrow-up-right"></b-icon>
						</b-btn>
					</b-col>

				</b-row>
			</b-card>



		</span>
	</div>

</template>


<script>

 	import { kontaktMixin } from '../mixins/kontaktMixins.js'
	import { projectMixin } from '../mixins/projectMixin.js'
    export default {

   	mixins: [kontaktMixin, projectMixin],

	props: {
		woherKommstDu: String
	},

	name: "kontaktDetail",

//urlKontaktID
data(){
    return{
		

		optionen_art_eintrag : { 1:'Einzelperson', 2:'Gruppe von Personen', 3:'Einzelperson'},

		popoverShowLoeschen: false,

		transProps: {
				// Transition name
				name: 'list'
            },
            
	    //TELEFON   	
	
		telefonWas: [
			{ value: '1', text: 'Festnetz' },
			{ value: '2', text: 'Mobil' },
			{ value: '3', text: 'Fax' },
		],
            

        telefonWo: [
            { value: '2', text: 'privat' },
            { value: '1', text: 'beruflich' },
        ],

    //ADRESSE  
    
            adresseWo: [
                { value: '2', text: 'privat' },
                { value: '1', text: 'beruflich' },
            ],

            addressHinzufuegen:false,

			neueAdresseAllIncl:{'berufOprivat':'privat oder beruflich', 
								'bezeichnung':'Bezeichnung (z.B. Private Adresse)',
								'strasse':'Straße und Hausnummer ', 
								'plz':'Postleitzahl', 
								'ort':'Ort', 
								'postfach':'Postfach', 
								'postfach_plz':'PLZ Postfach', 													
								'bundesland':'Bundesland (z.B. NRW)', 
								'land':'Land (z.B. Deutschland)',
								},


			adresseloeschen:null,
			
				


            preferred:null,
            speichernDisabled:'',
            disabledAbbrechenAdresse: true,

            }
        },

computed: {

    kontaktArt:
    {
        get()
        {
            return this.$store.getters.kontaktArt
        },
        set(value)
        {
            this.$store.commit('setkontaktArt', value);
        }
    },

		alleAdressenBeteiligte(){
		var leerArray = [];
		
			for(var single of this.KontaktDetail.adresse){
				single.identifier= this.KontaktDetail.basic.kontaktID+"_"+single.id;
				leerArray.push(JSON.parse(JSON.stringify(single)));
			}
			

			for(var prop of this.kontaktGruppe){
					for(var single of prop.adresse){
					single = JSON.parse(JSON.stringify(single))
					// console.log(single)
					single.herkunft= 'Gruppe';
					single.identifier= prop.basic.kontaktID+"_"+single.id;
					leerArray.push(single);
					}
					
			}

			if(this.KontaktDetail.adresse.length==0){
				for(var prop of this.kontaktBeteiligte){

						for(var single of prop.adresse){

							single.herkunft= 'Einzelkontakt';
							single.identifier= prop.basic.kontaktID+"_"+single.id;
							leerArray.push(JSON.parse(JSON.stringify(single)));

						}
				}
			}


			return leerArray
		},

		loeschButtonID(){
			if(document.getElementById('popover-loeschen')==null)
			return false
			else
			return true
		},



			Addressfields(){
	
				var adressenFeld=[];

				if(this.alleAdressenBeteiligte.length>0){


				try {

					if(this.alleAdressenBeteiligte.findIndex(item=>item.berufOprivat.length>0)>=0){
						adressenFeld.push({
							key: "berufOprivat",
							label: "Art",
							colType: "wo"
							})
					}

				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}

				try {

					if(this.KontaktDetail.adresse.findIndex(item=>item.bezeichnung.length>0)>=0){
						adressenFeld.push({
							key: "bezeichnung",
							label: "Bez.",
							colType: "idk"
							})
					}

				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {
				if(this.alleAdressenBeteiligte.findIndex(item=>item.bezeichnung.length>0)>=0){

					adressenFeld.push({
                        key: "bezeichnung",
                        label: "Bez.",
                        colType: "idk"
                    })
				}
				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {
				if(this.alleAdressenBeteiligte.findIndex(item=>item.strasse.length>0)>=0){

					adressenFeld.push({
                        key: "strasse",
                        label: "Straße",
                        colType: "idk"
                    })
				}
				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {

				if(this.alleAdressenBeteiligte.findIndex(item=>item.plz.length>0)>=0){

					adressenFeld.push({
                        key: "plz",
                        label: "PLZ",
                        colType: "idk"
                    })
				}
				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {
				if(this.alleAdressenBeteiligte.findIndex(item=>item.ort.length>0)>=0){

					adressenFeld.push({
                        key: "ort",
                        label: "Ort",
                        colType: "idk"
                    })
				}

								} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {
				if(this.alleAdressenBeteiligte.findIndex(item=>item.postfach_plz.length>0)>=0){

					adressenFeld.push({
   						key: "postfach_plz",
                        label: "PLZ Postf.",
                        colType: "button"
                    })
				}
				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}


				try {
				if(this.alleAdressenBeteiligte.findIndex(item=>item.postfach.length>0)>=0){

					adressenFeld.push({
   						key: "postfach",
                        label: "Postf.",
                        colType: "button"
                    })
				}
								} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}

				try {

					if(this.alleAdressenBeteiligte.findIndex(item=>item.herkunft.length>0)>=0){
						adressenFeld.push({
							key: "herkunft",
							label: "von anderem Kontakt",
							colType: "wo"
							})
					}

				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}

				try {

					if(this.kontaktEinstellungen.aktuelleListe && this.kontaktEinstellungen.showList){
						adressenFeld.push({
							key: "mitgabeAdresse",
							label: "für Liste",
							colType: "wo"
							})
					}

				} 
				catch (e) {
				// Anweisungen für jeden Fehler
				}



			} // if adresse ist array

				return adressenFeld;
			},
				
	statusListeKey() {
		return this.$store.state.statusListeKey
	},
	
	statusListe() {
		return this.$store.state.statusListe
	},



    KontaktDetail:
    {
        get()
        {
            return this.$store.getters.KontaktDetail
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail', value);
        }
    },

    KontaktDetail_backup:
    {
        get()
        {
            return this.$store.getters.KontaktDetail_backup
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail_backup', value);
        }
	},
	
    kontaktHighlight:
    {
        get()
        {
            return this.$store.getters.kontaktHighlight
        },
        set(value)
        {
            this.$store.commit('setkontaktHighlight', value);
        }
    }, 
	

	auswahlListen:
    {
        get()
        {
            return this.$store.getters.auswahlListen
        },
        set(value)
        {
            this.$store.commit('setauswahlListen', value);
        }
	},
	
			kontaktEinstellungen:
		{
			get()
			{
				return this.$store.getters.kontaktEinstellungen
			},
			set(value)
			{
				this.$store.commit('setkontaktEinstellungen', value);
			}
		},


}, // computed

watch: {
    "$route.params.kontaktid": {
      handler: function(value) {
        console.log(value);
		         
// einkommentieren  
        this.updateKontaktDetail(value);
	            
// einkommentieren  
      },
      deep: true,
      immediate: true,
    },
  },

methods: {

updateKontaktDetail(val){
        console.log("updateKontaktDetail: " + val);

        this.KontaktDetail = []	
        
        var pos = this.Addressbook.findIndex(kontakt => kontakt.basic.kontaktID==val);

        if(pos>=0){
            this.KontaktDetail = this.Addressbook[pos]
			//this.$root.$emit('scrollToAddress', pos);
		}


        var posTrash = this.Papierkorb.findIndex(kontakt => kontakt.basic.kontaktID==val);

        if(posTrash>=0)
            this.KontaktDetail = this.Papierkorb[posTrash]
        //  console.log(this.KontaktDetail);


        if(this.KontaktDetail.adresse==undefined)
        this.$set(this.KontaktDetail, "adresse", [])

        if(this.KontaktDetail.telefone==undefined)
        this.$set(this.KontaktDetail, "telefone", [])

        if(this.KontaktDetail.emails==undefined)
        this.$set(this.KontaktDetail, "emails", [])

        if(this.KontaktDetail.beteiligte==undefined)
        this.$set(this.KontaktDetail, "beteiligte", [])

        if(this.KontaktDetail.gruppe==undefined)
        this.$set(this.KontaktDetail, "gruppe", [])

        if(this.KontaktDetail.projekte==undefined)
        this.$set(this.KontaktDetail, "projekte", [])

        if(this.KontaktDetail.status==undefined)
        this.$set(this.KontaktDetail, "status", 1)

        this.KontaktDetail_backup = JSON.parse(JSON.stringify(this.KontaktDetail));
        

    },



	searchID(id){
		if(document.getElementById('popover-'+id)==null)
		return false
		else
		return true
	},
	
    eintragBearbeiten(){
		console.log("eintragBearbeiten in kontakDetail")
		this.backUpContacts=[]
		this.toSaveArray=[]
		
		this.searchKontakInProjects(this.KontaktDetail.basic.kontaktID);
					

        for(var param of this.kontaktBeteiligte)
        {
            // console.log(param)
            if(!param.aktuell)
            param.aktuell = 1

            if(!param.aktiv)
			param.aktiv = 1
			
			var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == param.basic.kontaktID)
			
            if(index_trgKontakt>=0){
                if(this.backUpContacts.findIndex(item=>item.basic.kontaktID == this.Addressbook[index_trgKontakt].basic.kontaktID)==-1) // suche im backUpContacts Array, ob mutterKarte schon vorhanden
                this.backUpContacts.push(JSON.parse(JSON.stringify(this.Addressbook[index_trgKontakt]))); // falls nicht, wird die Mutterkarte zum backUpContacts Array hinzugefügt
            }

        }

        for(var param of this.kontaktGruppe)
        {
            // console.log(param)
            if(!param.aktuell)
            param.aktuell = 1

            if(!param.aktiv)
			param.aktiv = 1

			var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == param.basic.kontaktID)
			
            if(index_trgKontakt>=0){
                if(this.backUpContacts.findIndex(item=>item.basic.kontaktID == this.Addressbook[index_trgKontakt].basic.kontaktID)==-1) // suche im backUpContacts Array, ob mutterKarte schon vorhanden
                this.backUpContacts.push(JSON.parse(JSON.stringify(this.Addressbook[index_trgKontakt]))); // falls nicht, wird die Mutterkarte zum backUpContacts Array hinzugefügt
            }
// JSON.parse(JSON.stringify(row.item))
		}
		

		        this.KontaktDetail_backup = JSON.parse(JSON.stringify(this.KontaktDetail));


		// this.bearbeiten = true;
		if(this.woherKommstDu=="adressBuch"){
			console.log("call 'openKontaktModalinAdressBuch'");
			this.$root.$emit('openKontaktModalinAdressBuch');
		}
		else if(this.woherKommstDu=="mitarbeiter"){
			console.log("call 'openKontaktModalinMitarbeiter'");
			this.$root.$emit('openKontaktModalinMitarbeiter');
		}
		else if(this.woherKommstDu=="unternehmen"){
			console.log("call 'openKontaktModalinUnternehmen'");
			this.$root.$emit('openKontaktModalinUnternehmen');
		}

		

		//console.log(this.$refs);


	},
	
	zugriffsrechteOeffnen()
	{
			var benutzerID = this.$route.params.kontaktid;

			this.axios.post(this.$store.state.url_einstellungen_mitarbeiter, {
					besonders: this.$store.state.dev_sub,
					benutzerID: benutzerID,
					was:'zugriffrechte_user'
					}).then((response) => {
						this.KontaktDetail.zugriffrechte = response.data;
						this.$root.$emit('zugriffsrechteOeffnen');
					})
					
	},

	eintragLoeschen(){

		setTimeout(()=>{
			this.popoverShowLoeschen=true;
		},250);

	},
		

	getkontaktArt(){
        
    this.axios.post(this.$store.state.url_get_kontakte, {			
        besonders: this.$store.state.dev_sub,
        welche:'kontaktArtListe',
        }).then((response) => {

            this.kontaktArt = response.data;

        })
        .catch(function (error) {
                    // handle error
                    // console.log(error);
        })
    },

	
}, // methods


mounted() {
		


},

created(){

    if(this.kontaktArt.length==0)
    this.getkontaktArt();

	// if(this.aktuelleKontaktIDurl){
	// 	console.log("id bei created")
	// 	this.updateKontaktDetail(this.aktuelleKontaktIDurl);
	// 	this.kontaktHighlight = this.aktuelleKontaktIDurl;	
	// }
	

}, // created


}

</script>

<style scoped>


  .mehrfachMarkierung:hover {
    background-color: #cccccc;
  }

  .mehrfachMarkierung{
      position: relative;
      border: 0.5px solid #58db9e;
      height: 30px;
      width: 30px !important;

      padding:5px;
      display: inline-block;
      cursor: pointer;
  }

  .closeSelect {
    position: relative;

    width: 25px;
    height: 25px;
    opacity: 0.3;

  }

  .closeSelect:hover {
    opacity: 1;
  }

  .closeSelect:before, .closeSelect:after {
    position: absolute;
    left: -9px;
	top:4px;
    content: ' ';
    height: 42px;
    width: 2px;
    background-color: #333;
  }

  .closeSelect:before {
    transform: rotate(45deg);
  }

  .closeSelect:after {
    transform: rotate(-45deg);
  }
  
  .marked{
      border: 2px solid #0804f1 !important;
        background-color: #c3d3ff;
  }

	.pointer{
		cursor: pointer;
	}
	.pointer:hover{
		background-color: #F2F2F2;
	}

	.select-ohne-rand{
		padding-left: 0rem !important;
	}

	.form-control:read-only{
		background-color: white !important;
			border:0;
			cursor: default;
	}
	.form-control:read-only:focus {
		outline:0px !important;
		-webkit-appearance:none;
		box-shadow: none !important;
		border:0;
	}

	.input-group-text{
			background-color: white !important;
			border:0;
			cursor: default;
			width:60px;
	}

.card-body{
	padding:0.25rem;
}
.ueberschrift{
	font-size:1em;
}
</style>