<template>
	<div>
       <div class="kontainer">
				<!-- {{meineFirma}} -->
<!-- {{KontaktDetail}} -->
			<keep-alive><!-- my-component will be intentionally kept in memory even when removed -->
				<kontakt-detail woherKommstDu="unternehmen"></kontakt-detail>
			</keep-alive>

		<keep-alive>				
			<kontaktModal woherKommstDu="unternehmen" ref="kontaktModalUnternehmen" ></kontaktModal>
		</keep-alive>

        </div>
	</div>
</template>

<script>

	import { einstellungenMixin } from '../../mixins/einstellungenMixin.js'
	import kontaktModal from '../kontaktModal.vue'
	import kontaktDetail from '../kontaktDetail.vue'
	// import { updateKontaktMixin } from '../../mixins/updateKontaktMixins.js'
export default {

	mixins: [einstellungenMixin],

  	components: {
		'kontakt-detail':kontaktDetail,
		kontaktModal
	},

    data(){
        return{

        }
	},
	
	methods:{

	},


	mounted() {
			this.$root.$on('openKontaktModalinUnternehmen', data => {
				console.log("openKontaktModalinUnternehmen")

				try {
					this.$refs.kontaktModalUnternehmen.$refs.neuer_adressbuch_eintrag.show()
				}
				catch (e) {
				// Anweisungen für jeden Fehler
				}


			})
	},

    computed:{

	Addressbook:
  	{
      get()
      {
          return this.$store.getters.Addressbook
      },
      set(value)
      {
          this.$store.commit('setAddressbook', value);
      }
  	}, 

	KontaktDetail:
    {
        get()
        {
            return this.$store.getters.KontaktDetail
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail', value);
        }
    },

    KontaktDetail_backup:
    {
        get()
        {
            return this.$store.getters.KontaktDetail_backup
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail_backup', value);
        }
    },



	},

	created(){

		if(this.Addressbook.length==0)
        this.updateAdressbook();
       
        if(this.Papierkorb.length==0)
		this.updatePapierkorb();



			if(this.meineFirma>0)
			this.$router.push({ name: 'unternehmen', params: { kontaktid: this.meineFirma } });
			
		
	

//tis.meineFirma		
		// if(this.meineFirma){
		// 	this.KontaktDetail  = this.meineFirma
		// 	this.KontaktDetail_backup  = this.meineFirma
		// }
		this.bearbeiten = false;
		

	}
	
	//
}
</script>


<style>


</style>
