<template>

  <b-modal class="wider" transition="nice-modal-fade" size="xl" ref="neuer_adressbuch_eintrag" id="neuer_adressbuch_eintrag" scrollable>

		<b-container v-if="KontaktDetail.basic" slot="modal-header">
				<b-row align-h="between">
					<b-col cols="8">
							<div class="ueberschrift">
								<span v-if="KontaktDetail.basic.kontaktID"> Eintrag bearbeiten </span>
								<span v-else> neuer Eintrag </span>
								<span class="nurBreit" v-if="anzeigeNamegekurzt">- {{anzeigeNamegekurzt}}</span>
							
							</div>

						</b-col>

						<!-- <b-col>
							<b-form-checkbox :disabled="hinzufuegenOffen" v-model="bearbeiten">bearbeiten</b-form-checkbox>
						</b-col> -->


						<b-col >
								<b-input-group id="tooltip-button-disable">
									<b-button style="width:105px;   position: absolute; right: 120px;" 
									id="popover-kontakt_speichern" :disabled="hinzufuegenOffen" 
									variant="info" class="abstand_rechts" 
									 @click="speicher_neuen_kontakt_abbruch()">
										<span v-if="changeHappend">abbrechen</span><span v-else>schließen</span>
									</b-button>
								
										<!-- 
									<b-button  style="width:105px; position: absolute; right: 5px;" 
									 :disabled="!anzeigeNameExistiert" variant="primary" 
									  @click="speicher_kontakt">speichern</b-button> -->
									


									<span style="position: absolute; right: 5px;" id="disabled-wrapper"  tabindex="0">
									<b-button  style="width:105px;" variant="primary" @click="speicher_kontakt" :disabled="!anzeigeNameExistiert">speichern</b-button>
								</span>
								<b-tooltip v-if="!anzeigeNameExistiert" target="disabled-wrapper">Bitte geben Sie einen Anzeigenname ein.</b-tooltip>


								</b-input-group>

									<b-popover
									target="popover-kontakt_speichern"
									triggers="manual"
									:show.sync="popoverShowSpeichern"
									placement="auto"
									container="my-container"
									ref="popover"
									>
										<!-- 
										triggers="click"
										@show="onShow"
										@shown="onShown"
										@hidden="onHidden"
										-->

									<template v-slot:title>
										Änderungen speichern?
									</template>

									<div>
										Nicht gespeicherte Änderungen gehen verloren.
										<b-button @click="zurueckZumFormular" size="sm" class="mb-2" variant="danger">Oh nein. Bitte zurück zum geöffneten Kontakt.</b-button>
										<b-button @click="trotzAenderungSchliessen" size="sm" variant="primary">Alles klar. Ich möchte die Änderungen nicht speichern.</b-button>
									</div>
									</b-popover>

								<!-- <button :disabled="hinzufuegenOffen" class="btn btn-primary" @click=" orgChange(); speicher_neuen_kontakt();saveLocal();">speichern</button> -->
							<!-- </span> -->


							      <b-tooltip placement="topleft" :disabled.sync="disabledAbbrechenAdresse" ref="tooltip" target="tooltip-button-disable">
									Bitte zuerst ADRESSEN Bearbeitungsfeld schließen! Dafür auf 'abbrechen' oder 'fertig' klicken.
								</b-tooltip>
						</b-col>

				</b-row>
		</b-container>

		<div v-if="KontaktDetail.basic" style="min-height:300px;" class="modal-body_custom">


			<b-card class="container down-space-5" >
	
		<span v-if="$route.path.includes('/mitarbeiter') && (admin==1 || admin==true)">
	
			<b-card>
			<b-row class="mb-2">
						<b-col>
							<label class="eingabe-label"> Benutzername </label>
						</b-col>
						<b-col>
							<b-input name="mitarbeiterPasswort1" v-model="KontaktDetail.basic.userName"></b-input>
						</b-col>
			</b-row>

			<b-row class="mb-2">
						<b-col>
							<label class="eingabe-label"> neues ArchiApp Passwort eingeben </label>
						</b-col>
			</b-row>

			<b-row class="mb-2">
				{{KontaktDetail}}
					<b-col>
						<b-input autocomplete="off" name="mitarbeiterPasswort1" v-model="neuesAApasswort1" placeholder="neues Passwort"></b-input>
					</b-col>
					<b-col>
						<b-input autocomplete="off" name="mitarbeiterPasswort2" v-model="neuesAApasswort2" placeholder="neues Passwort wiederholen"></b-input>
					</b-col>

					<b-col>
						<b-button @click="saveNewPassword" variant="outline-primary">neues Passwort speichern</b-button>
					</b-col>
			</b-row>

  <hr class="my-3">

			<b-row class="mb-2">
				<b-col>
					<label class="eingabe-label"> neues DAV Passwort eingeben </label>
				</b-col>
			</b-row>
			<b-row class="mb-2">
				<b-col>
					<b-input autocomplete="off" name="DAVneuesPasswort1" v-model="neuesDAVpasswort1" placeholder="neues Passwort"></b-input>
				</b-col>
				<b-col>
					<b-input autocomplete="off" name="DAVneuesPasswort2" v-model="neuesDAVpasswort2" placeholder="neues Passwort wiederholen"></b-input>
				</b-col>
				<b-col>
					<b-button @click="davpasswortSpeichern" variant="outline-primary">neues Passwort speichern</b-button>
				</b-col>
			</b-row>
		</b-card>

	</span> 

                <b-row v-if="woherKommstDu!='mitarbeiter'">
                    <b-col cols="12">
                        <label class="eingabe-label"> Art des Eintrags </label>
					</b-col>
                </b-row>

                <b-row v-if="woherKommstDu!='mitarbeiter' && !KontaktDetail.basic.kontaktID">
                    <b-col cols="6">
                            <b-form-select 

								v-model="KontaktDetail.basic.kind" 
								v-bind:class="{ 
									'select-ohne-rand': KontaktDetail.basic.kontaktID, 
									'readonlytext':KontaktDetail.basic.kontaktID, 
									'form-control-plaintext': KontaktDetail.basic.kontaktID, 
									'form-control': !KontaktDetail.basic.kontaktID 
								}">
                            
								<option :value="null">Bitte zuerst wählen</option>
								<option value="2">Gruppe von Personen (z.B. Unternehmen, Ämter, Bauherrengemeinschaften)</option>
								<option value="1">Einzelperson</option>
                            </b-form-select>
					</b-col>
                </b-row>

				 <b-row v-if="woherKommstDu!='mitarbeiter' && KontaktDetail.basic.kontaktID">
                    <b-col v-if="kontaktBeteiligte.length>0 || kontaktGruppe.length>0" 
						cols="12">
							
							<b-alert  show >Die Art der Eintrags kann nur geändert werden, wenn dieser Kontakt mit KEINER Gruppe bzw. KEINER Einzelperson verbunden ist.</b-alert>
					</b-col>

					<b-col v-else col sm="12" lg="8" xl="6">
								<div v-if="kontaktBeteiligte.length==0 && kontaktGruppe.length==0 && $route.path.includes('/adressbuch')">

								<!-- <b-alert show >Ändern Sie die <b>Art der Eintrags</b> nur, wenn Sie  sicher sind, was Sie tun. 
								Es kann sonst zu Fehlern kommen.</b-alert> -->
									<b-form-select v-model="KontaktDetail.basic.kind" class="form-control">
										<option value="2">Gruppe von Personen (z.B. Unternehmen, Ämter, Bauherrengemeinschaften)</option>
										<option value="1">Einzelperson</option>
									</b-form-select>
								</div>
								<div v-else class="inhalt">
									{{optionen_art_eintrag[KontaktDetail.basic.kind]}}
                            	</div>
					</b-col>
                </b-row>



                <b-row v-if="KontaktDetail.art.details">
                    <b-col cols="12">
                        <label class="eingabe-label"> Status </label>
                    </b-col>
				</b-row>
				<b-row class="my-2" v-if="KontaktDetail.status">
                	<b-col col sm="12" lg="8" xl="6">
						<b-form-select v-model="KontaktDetail.status" 

						class="form-control"
						:options="statusListe" />
				    </b-col>
				</b-row>


                <b-row v-if="KontaktDetail.art.details">
                    <b-col cols="12">
                        <label class="eingabe-label"> Kategorie des Eintrags </label>
                    </b-col>
				</b-row>

				<b-row class="my-2" v-if="KontaktDetail.art.details">
                	<b-col cols="12" sm="10">

							<div  >
								<b-form-checkbox-group
									style="height:auto !important"
									v-model="KontaktDetail.art.summary"
									:options="art"

									 class="form-control">
								</b-form-checkbox-group>
 							</div>



                    </b-col>

                    <b-col v-if="KontaktDetail.art.summary.includes('10')" cols="12" sm="6">

                    <label class="eingabe-label" style="margin-right:10px;"> Mitgliedsnummer Architektenkammer </label>
                    <input 
						class="form-control"
						v-model="KontaktDetail.vollerName.AK_Mitgliedsnummer"/>

                    </b-col>

                </b-row>

                <b-row v-if="KontaktDetail.art.summary.includes('3') || KontaktDetail.art.summary.includes('5')">
                   
				    <b-col cols="12" >
                        <label class="eingabe-label"> Tätigkeit </label>
                    </b-col>

                    <b-col cols="12" sm="10">
                        
                        <div class="inhalt">
                            <span v-for="(detail, index) in KontaktDetail.taetigkeit.details" :key="index">
                                    {{detail.taetigkeit}}<span v-if="index < (KontaktDetail.taetigkeit.details.length-1)">, </span>
                            </span>
                        </div>
<!-- {{	KontaktDetail.taetigkeit.summary}} -->

						<b-form-checkbox-group
							v-if=" KontaktDetail.art.summary.includes('3')"
							style="height:auto !important"
							v-model="KontaktDetail.taetigkeit.summary"
							:options="taetigkeiten"
							class="form-control">
						</b-form-checkbox-group>

						<b-form-checkbox-group
							v-if="KontaktDetail.art.summary.includes('5')"
							style="height:auto !important"
							v-model="KontaktDetail.taetigkeit.summary"
							:options="taetigkeiten_fachplaner"
							class="form-control">
						</b-form-checkbox-group>


                    </b-col>
				</b-row>
		</b-card>



	<!-- <transition name="fade"> -->
		<b-card v-show="KontaktDetail.basic.kind" class="container" style="margin-bottom: 2rem;">

						<b-col cols="12" sm="10">			
							<b-row class="down-space-15">
								<div class="ueberschrift einzug_links">Kontakt</div>
							</b-row>
						</b-col>

                <span v-if="KontaktDetail.basic.kind=='2'">
                                <b-col cols="12" sm="10">					
                                    <b-row class="down-space-5">
                                        <label class="eingabe-label" style="margin-right:10px;"> Anzeigename </label>
                                        <input 
										class="form-control"
                                          v-model="KontaktDetail.vollerName.anzeigeName"/>
                                    </b-row>					
                                </b-col>
                </span>

				 <span v-if="KontaktDetail.basic.kind=='3' || KontaktDetail.basic.kind=='1'"> 	<!-- Einzelperson -->

						<b-col  cols="12" sm="10">					
							<b-row class="down-space-5">
							
							
									<label class="eingabe-label" style="margin-right:10px;"> Anzeigename </label>  
									<b-form-checkbox  v-model="formattedNameChanged">  selbst festlegen </b-form-checkbox>
									
									<b-icon v-b-tooltip.hover title="Der Anzeigename wird automatisch aus Vor- und Nachnamen erzeugt. Wenn Sie den Anzeigenamem manuell eingeben möchten, machen Sie hier ein Häkchen. Für jeden Kontakt MUSS der Anzeigename vorhanden sein. Andernfalls kann der Kontakt nicht gespeichert werden." class="ml-2" font-scale="1.5" variant="primary" icon="question-circle"></b-icon>
									
								<input :readonly=" formattedNameChanged===false" 
								
								v-on:change="formattedNameChanged=true"
								class="form-control"
								v-model="KontaktDetail.vollerName.anzeigeName"/>
							</b-row>					
						</b-col>

						<b-col  cols="12" sm="10">
							<b-row class="down-space-5">
								<label class="eingabe-label"> Titel </label>
								<input 
								class="form-control"
								v-model="KontaktDetail.vollerName.titel"/>
							</b-row>
						</b-col>

						<b-col  cols="12" sm="10">
							<b-row class="down-space-5">
								<label class="eingabe-label"> Anrede </label>
									<b-form-select v-model="KontaktDetail.vollerName.anrede" 
									class="form-control"
									:options="anreden" />
							</b-row>
						</b-col>

						<b-col cols="12" sm="10">
							<b-row class="down-space-5">
								<label class="eingabe-label"> Vorname </label>
								<input 
								

								v-on:blur="checkFormattedNameBeteiligter(); " 
								class="form-control"
								v-model="KontaktDetail.vollerName.vorname"/>
							</b-row>
						</b-col>

						<b-col cols="12" sm="10">					
							<b-row class="down-space-5">
								<label class="eingabe-label"> zweiter Vorname </label>
								<input  
								class="form-control"
								v-model="KontaktDetail.vollerName.mittelname"/>
							</b-row>					
						</b-col>

						<b-col  cols="12" sm="10">
							<b-row class="down-space-5">
								<label class="eingabe-label"> Nachname </label>

								<input 
								@input="checkFormattedNameBeteiligter"
								v-on:blur="checkFormattedNameBeteiligter(); "  
								class="form-control"
								v-model="KontaktDetail.vollerName.nachname"/>
							</b-row>
						</b-col>

						<b-col cols="12" sm="10">					
							<b-row class="down-space-5">
								<label class="eingabe-label"> Namenszusatz (z.B. jun.) </label>
								<input class="form-control"
								v-model="KontaktDetail.vollerName.anhang"/>
							</b-row>					
						</b-col>

						<b-col cols="12" sm="10">					
							<b-row class="down-space-5">
								<label class="eingabe-label"> Spitzname </label>
								<input class="form-control"
								v-model="KontaktDetail.vollerName.spitzname"/>
							</b-row>					
						</b-col>

						<b-col cols="12" sm="10">					
							<b-row class="down-space-5">
								<label class="eingabe-label"> Funktion </label>
								<input class="form-control"
								v-model="KontaktDetail.vollerName.funktion"/>
							</b-row>					
						</b-col>

					</span>
		</b-card>



<!-- {{KontaktDetail.gruppe}}	 -->

			<!-- <br>backUpContacts: {{backUpContacts}}
				<br>
				<br>toSaveArray: {{toSaveArray}}  -->

<b-card v-if="KontaktDetail.basic.kind==1" class="container" style="margin-bottom: 2rem;">

				<b-row class="down-space-5">
					<div class="ueberschrift einzug_links">verbunden mit</div>
				</b-row>

				<b-row class="down-space-5">
					<b-button  @click="personenHinzufuegen = !personenHinzufuegen" variant="outline-primary">		
						<span v-if="!personenHinzufuegen && kontaktGruppe.length>0">diesen Kontakt einer weiteren Gruppe hinzufügen</span>
						<span v-else-if="!personenHinzufuegen">diesen Kontakt einer Gruppe hinzufügen</span>
						<span v-else>verfügbare Gruppen schließen</span>
					</b-button>
				</b-row>

				<b-row class="down-space-5">
					<b-col style="max-width:330px" v-if="personenHinzufuegen">
						<div class="kontaktListe">

							<div class="zeile_oben">
								<span style="display:inline-block; min-width:245px; font-weight:700">
									<span>verfügbare Gruppen</span>
									<b-icon v-b-tooltip.hover title="Klicken Sie auf eine Gruppe aus der unten stehenden Liste, um diese mit der geöffneten Person zu verknüpfen." class="ml-2" font-scale="1.5" variant="primary" icon="question-circle"></b-icon>

								</span>
<!-- prepend="Suche" -->
								<b-input-group>
									<b-form-input
										style="min-width:245px;max-width:245px;margin-bottom:5px;margin-top:5px"
										v-model="searchGroup"
										type="text"
										placeholder="Gruppe suchen">
									</b-form-input>

									<b-input-group-append>
										<b-btn v-show="searchGroup" :pressed="false" @click="searchGroup=''" variant="warning">X</b-btn>
									</b-input-group-append>
								</b-input-group>

							</div>


							<virtual-list
								class="list"    
								:style="{ height: window.height - 600 + 'px' }"
								:data-key="'uid'"
								:data-sources="filterverfuegbareGruppen"
								:data-component="itemComponent"
								:extra-props="{ variable: 'addGroup' }"
								:estimate-size="filterverfuegbareGruppen.length"
							/>


						</div>
					</b-col>
						
					<b-col style="max-width:700px">
<!-- {{KontaktDetail.gruppe}} -->
						<b-table
							v-if="kontaktGruppe"
								show-empty
								:items="kontaktGruppe"
								:fields="dazugehoerigeFirmen"
							>

							<template v-slot:empty="">
								<h4>Es sind noch keine Einträge vorhanden.</h4>
							</template>

							<!-- <template v-slot:cell(preferred)="data">
									<b-form-radio  v-model="preferredBeteiligte" @input="setOtherRadioToNull(data.item)" :value="data.item.basic.kontaktID"></b-form-radio>
							</template> -->

							<template v-slot:cell(löschen)="data">
								<b-button 
								@click="doSomeWithX(data.item, 'gruppe', 'delete')"
								
									v-b-tooltip.hover title="Verbindung zu dieser Gruppe aufheben. Meistens ist es besser, die Aktivität auf 'vormals' zu setzen, anstatt die Verbidnung aufzuheben." 
									variant="info" >
									<span style="color:red;font-weight:800;">X</span>
								</b-button>	
							</template>
<!-- 																
 -->
						<template v-slot:head(aktiv)="data">
							
							<span>{{ data.label.toLowerCase() }}</span>
							<b-icon v-b-tooltip.hover title="Ist dieser Kontakt aktuell noch bei dieser Gruppe aktiv? 
							Falls nicht (Firma gewechselt, in Rente gegangen, o.ä) wählen Sie 'vormals' aus. Dadurch kommt es zu keinen Komplikation, falls Sie diesen Kontakt an einer anderen Stelle verknüpft haben." class="ml-2" font-scale="1.5" variant="primary" icon="question-circle"></b-icon>
						</template>

						<template v-slot:head(löschen)="data">
							<span>{{ data.label.toLowerCase() }}</span>
						</template>

							<template v-slot:cell(aktiv)="data">

								<b-button variant="outline-primary" @click="activierKontaktID(data.item.basic.kontaktID)">
									<span v-if="kontaktBeiAktiv(data.item.basic.kontaktID)">ja</span>
									<span v-else>nein</span>
								</b-button>

<!-- {{KontaktDetail.deaktiv}} -->
						

							<!-- <b-form-select 

								@input="doSomeWithX(data.item, 'gruppe', 'aktivitaet', $event)"

								v-model="data.item.aktuell" >
								<option value="1">aktuell</option>
								<option value="2">vormals</option>

                            </b-form-select> -->

							</template>

						</b-table>
					</b-col>
				</b-row>
	
	</b-card>
	
<!-- wieder einkommenteren -->

	<b-card v-show="KontaktDetail.basic.kind" class="container" style="margin-bottom: 2rem;">

                <b-col cols="12" sm="10">			
                    <b-row class="down-space-15">
                        <div id="kommunikation" class="ueberschrift einzug_links">Kommunikation</div>
                    </b-row>
                </b-col>


                <b-col cols="12" sm="10">	

                        <b-row class="down-space-5">
                            <label class="eingabe-label down-space-5"> Rufnummern </label>
                                <b-list-group class="w-100">

                            <transition-group name="list" tag="span">

                                <b-input-group class="mb-1" v-for="(tel, index) in KontaktDetail.telefone" :key="index">

                                    <b-input-group-text class="lessPadding" slot="prepend">


                                    <b-form-select 
										style="width: 100px;" 
										
										size="sm" 
										class=" mr-1" 
										 
										v-model="KontaktDetail.telefone[index].berufOprivat" 
										:options="telefonWo" />


									<!-- @input="onBeGruppeChange" -->
                                    <b-form-select 
										style="width: 100px;" 
										size="sm" 
										class="select-xs mr-1" 
										v-model="KontaktDetail.telefone[index].telefonArt" 
										:options="telefonWas" />


									<b-form-radio v-if="KontaktDetail.telefone.length>1" 
										class="ml-2"
										name="rufnummer-radios" 
										v-b-tooltip.hover 
										title="bevorzugt"
										v-model="KontaktDetail.rufnummerPreferred" 
										:value="tel.id">
									</b-form-radio>

                                    </b-input-group-text>

                                    <b-form-input  v-model="tel.telefonNummer"/>


                                    <b-input-group-append>

                                        <b-btn v-if="nummerloeschen!=index" @click="nummerloeschen=index" variant="danger">X</b-btn>
                                        
                                        <b-input-group-text v-if="nummerloeschen===index">sicher?</b-input-group-text>

                                        <b-btn v-if="nummerloeschen===index" @click="nummerloeschen=null" variant="outline-secondary">nein</b-btn>
                                        <b-btn v-if="nummerloeschen===index" @click="loescheNummer(index)" variant="outline-secondary">ja</b-btn>

                                </b-input-group-append>

                                </b-input-group>
                            </transition-group>

                            </b-list-group>
                        </b-row>

                        <b-row class="down-space-5">

                            <b-button v-if="!phoneHinzufuegen" v-on:click="plusPhone" variant="outline-primary">
                                <span v-if="KontaktDetail.telefone.length > 0">weitere Nummer hinzufügen</span>	
                                <span v-else>Nummer hinzufügen</span>	

                            </b-button>
                        </b-row>
<!-- wieder einkommenteren -->


						<!-- move alte E-Mail-->


<!-- wieder einkommenteren -->

						<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.telefone.length>0">
							vorherige Telefonnummer zu aktuellem Kontakt verschieben
						</b-row>
						<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.telefone.length>0">
							
							<b-list-group class="w-100">

								<transition-group name="list" tag="span">

										<b-input-group class="mb-1" v-for="(telefon, index) in KontaktDetail.telefone" :key="index">
										
										<b-input-group-text slot="prepend">
											<span v-b-tooltip.hover title="privat" v-if="telefon.berufOprivat=='2'" class="fa fa-home abstand_rechts"></span>
											<span v-b-tooltip.hover title="beruflich" v-if="telefon.berufOprivat=='1'" class="fa fa-building abstand_rechts"></span>
										</b-input-group-text>
										<span class="px-3">{{telefon.telefonNummer}}</span>

												<b-btn @click="moveItem(telefon, index, 'telefone')" variant="danger">hinzufügen</b-btn>
									</b-input-group>
								</transition-group>

								</b-list-group>
						</b-row> 
                </b-col>

<!-- wieder einkommenteren -->
						<!-- move alte E-Mail-->


								
<!-- Telefon Ende -->
<!-- Trenner -->

  <hr class="my-4">

<!-- E-Mail Anfang -->

<!-- wieder einkommenteren -->

<!-- {{KontaktDetail.emails}} -->

		<b-col class="down-space-5" cols="12" sm="10">	
                <b-row class="down-space-5">
                    <label class="eingabe-label down-space-5"> E-Mail Adressen </label>
                        <b-list-group class="w-100">

                    <transition-group name="list" tag="span">

                            <b-input-group class="mb-1 lessPadding"  v-for="(mail, index) in KontaktDetail.emails" :key="index">
                            <!-- @input="onBeGruppeChange"  -->
                            <b-input-group-text slot="prepend">
                                <b-form-select 
								
								size="sm" 
								class="select-xs" 
								v-model="KontaktDetail.emails[index].berufOprivat" 
								id="inputHorizontalWo"
								:options="adresseWo" />
							
								<b-form-radio v-if="KontaktDetail.emails.length>1" 
									class="ml-2"
									name="emails-radios" 
									v-b-tooltip.hover 
									title="bevorzugt"
									v-model="KontaktDetail.emailPreferred" 
									:value="mail.id">
								</b-form-radio>

							</b-input-group-text>

                            <b-form-input :ref='"email_input_" + index' v-model="mail.emailAdresse"/>
                            


                            <b-input-group-append>
                                    <b-btn v-if="Emailloeschen!=index" @click="Emailloeschen=index" variant="danger">X</b-btn>
                                    <b-input-group-text v-if="Emailloeschen===index">sicher?</b-input-group-text>

                                    <b-btn v-if="Emailloeschen===index" @click="Emailloeschen=null" variant="outline-secondary">nein</b-btn>
                                    <b-btn v-if="Emailloeschen===index" @click="loescheEmail(index)" variant="outline-secondary">ja</b-btn>

                            </b-input-group-append>

                        </b-input-group>
                    </transition-group>

                    </b-list-group>
                </b-row>

                <b-row class="down-space-5">
                    <b-button v-if="!emailHinzufuegen" v-on:click="plusEmail" variant="outline-primary">
                        <span v-if="KontaktDetail.emails.length > 0">weitere Mail Adresse hinzufügen</span>	
                        <span v-else>Mail Adresse hinzufügen</span>		
                    </b-button>
                </b-row>

<!-- wieder einkommenteren -->


			<!-- move alte E-Mail-->
			<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.emails.length>0">
				vorherige E-Mail verschieben
			</b-row>
			<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.emails.length>0">
				
				<b-list-group class="w-100">

					<transition-group name="list" tag="span">

							<b-input-group class="mb-1" v-for="(mail, index) in KontaktDetail.emails" :key="index">
							
							<b-input-group-text slot="prepend">
								<span v-b-tooltip.hover title="privat" v-if="mail.berufOprivat=='2' " class="fa fa-home abstand_rechts"></span>
								<span v-b-tooltip.hover title="beruflich" v-if="mail.berufOprivat=='1' " class="fa fa-building abstand_rechts"></span>
							</b-input-group-text>
							<span class="px-3">{{mail.emailAdresse}}</span>

									<b-btn @click="moveItem(mail, index, 'emails')" variant="danger">hinzufügen</b-btn>
						</b-input-group>
					</transition-group>

					</b-list-group>
			</b-row>
			<!-- move alte E-Mail-->

			</b-col>

  <hr class="my-4">
	

<!-- E-Mail fertig -->


<!-- www Anfang -->

<!-- {{KontaktDetail}} -->
		<b-col class="down-space-5" cols="12" sm="10">	
                <b-row class="down-space-5">
                    <label class="eingabe-label down-space-5"> Internet Adressen </label>
                        <b-list-group class="w-100">

                    <transition-group name="list" tag="span">

                            <b-input-group class="mb-1" v-for="(seite, index) in KontaktDetail.www" :key="index">
                            
                            <b-input-group-text slot="prepend">

                                <b-form-select size="sm" class="select-xs" v-model="KontaktDetail.www[index].berufOprivat" id="inputHorizontalWo"  :options="adresseWo" />

                                <span v-b-tooltip.hover title="privat" v-if="seite.berufOprivat=='2' " class="fa fa-home abstand_rechts"></span>
                                <span v-b-tooltip.hover title="beruflich" v-if="seite.berufOprivat=='1' " class="fa fa-building abstand_rechts"></span>
                            </b-input-group-text>

                            <b-form-input :ref='"www_input_" + index'  v-model="seite.wwwAdresse"/>


                            <b-input-group-append>
                                     <b-btn v-if="wwwloeschen!=index" @click="wwwloeschen=index" variant="danger">X</b-btn>
                                    <b-input-group-text v-if="wwwloeschen===index">sicher?</b-input-group-text>

                                    <b-btn v-if="wwwloeschen===index" @click="wwwloeschen=null" variant="outline-secondary">nein</b-btn>
                                    <b-btn v-if="wwwloeschen===index" @click="loescheWWW(index)" variant="outline-secondary">ja</b-btn>

                            </b-input-group-append>

                        </b-input-group>
                    </transition-group>

                    </b-list-group>
                </b-row>

                <b-row class="down-space-5">
                    <b-button v-if="!wwwHinzufuegen" v-on:click="neuWWW" variant="outline-primary">
                        <span v-if="KontaktDetail.www.length > 0">weitere Internet Adresse hinzufügen</span>	
                        <span v-else>Internet Adresse hinzufügen</span>		
                    </b-button>
                </b-row>

						<!-- move alte E-Mail-->
				<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.www.length>0">
					vorherige Internet Adresse zu aktuellem Kontakt verschieben
				</b-row>
				<b-row class="down-space-5 mt-3" v-if="KontaktDetail.basic.kind==3 && KontaktDetail.www.length>0">
					
					<b-list-group class="w-100">

						<transition-group name="list" tag="span">

								<b-input-group class="mb-1" v-for="(seite, index) in KontaktDetail.www" :key="index">
								
								<b-input-group-text slot="prepend">
									<span v-b-tooltip.hover title="privat" v-if="seite.berufOprivat=='2'" class="fa fa-home abstand_rechts"></span>
									<span v-b-tooltip.hover title="beruflich" v-if="seite.berufOprivat=='1'" class="fa fa-building abstand_rechts"></span>
								</b-input-group-text>
								<span class="px-3">{{seite.wwwAdresse}}</span>

										<b-btn  @click="moveItem(seite, index, 'www')" variant="danger">hinzufügen</b-btn>
							</b-input-group>
						</transition-group>

						</b-list-group>
				</b-row>
			</b-col>
		</b-card>

				<!-- {{KontaktDetail.beteiligte}}
				<br> -->
			<!-- <br>backUpContacts: {{backUpContacts}}
				<br>
				<br>toSaveArray: {{toSaveArray}}  -->

	<b-card v-if="KontaktDetail.basic.kind==2" class="container" style="margin-bottom: 2rem;">

				<b-row class="down-space-5">
					<div class="ueberschrift einzug_links">dazugehörige Personen</div>
				</b-row>

				<b-row class="down-space-5">
					<b-button  @click="personenHinzufuegen = !personenHinzufuegen" variant="outline-primary">		
						<span v-if="!personenHinzufuegen && kontaktBeteiligte.length==0">Personen zu diesem Kontakt hinzufügen</span>
						<span v-else-if="!personenHinzufuegen">weitere Personen zu diesem Kontakt hinzufügen</span>
						<span v-else>verfügbare Personen schließen</span>
					</b-button>
				</b-row>

				<b-row class="down-space-5">
					<b-col style="max-width:400px" v-if="personenHinzufuegen">
						<div class="kontaktListe">

						<div class="zeile_oben">
							<span style="display:inline-block; min-width:245px; font-weight:700">
								<span>verfügbare Personen</span>
								<b-icon v-b-tooltip.hover title="Klicken Sie auf eine Person aus der unten stehenden Liste, um diese mit der geöffneten Gruppe zu verknüpfen." class="ml-2" font-scale="1.5" variant="primary" icon="question-circle"></b-icon>
						
							</span>
<!-- prepend="Suche" -->
							<b-input-group >
								<b-form-input
									style="min-width:245px;max-width:245px;margin-bottom:5px;margin-top:5px"
									v-model="searchMember"
									type="text"
									placeholder="Person suchen">
								</b-form-input>

								<b-input-group-append>
									<b-btn  
									v-b-tooltip.hover 
									title="Sucheingabe löschen" 
									v-show="searchMember" 
									:pressed="false" 
									@click="searchMember=''" 
									variant="warning">
									X
									</b-btn>
								</b-input-group-append>
							</b-input-group>

						</div>
<!-- {{kontaktBeteiligte}} -->
						<virtual-list
							class="list"    
							:style="{ height: window.height - 600 + 'px' }"
							:data-key="'uid'"
							:data-sources="filterverfuegbareMitarbeiter"
							:data-component="itemComponent"
							:extra-props="{ variable: '' }"
							:estimate-size="filterverfuegbareMitarbeiter.length"
						/>
	

					</div>
					</b-col>


					<b-col style="max-width:700px">
					
<!-- {{KontaktDetail.beteiligterPreferred}} -->

						<b-table
							v-if="kontaktBeteiligte"
								show-empty
								:items="kontaktBeteiligte"
								:fields="dazugehoerigePersonen"
							>

							<template v-slot:empty="scope">
								<h5>Es sind noch keine Einträge vorhanden.</h5>
							</template>

							<template v-slot:cell(preferred)="data">
									<b-form-radio name="some-radios" v-model="KontaktDetail.beteiligterPreferred" :value="data.item.basic.kontaktID"></b-form-radio>
							</template>

						<template v-slot:head(löschen)="data">
							<span>{{ data.label.toLowerCase() }}</span>
						</template>

							<template v-slot:cell(löschen)="data">
								<b-button 
									@click="doSomeWithX(data.item, 'beteiligte', 'delete')"

									v-b-tooltip.hover title="Verbindung zu dieser Person aufheben. Meistens ist es besser, die Aktivität auf 'vormals' zu setzen, anstatt die Verbidnung aufzuheben." 
									variant="info" >
									<span style="color:red;font-weight:800;">X</span>
								</b-button>	
							</template>

						<template v-slot:head(aktiv)="data">
							<span>{{ data.label.toLowerCase() }}</span>
							<b-icon v-b-tooltip.hover title="Ist dieser Kontakt aktuell noch bei dieser Gruppe aktiv? 
							Falls nicht (Firma gewechselt, in Rente gegangen, o.ä) wählen Sie 'vormals' aus. Dadurch kommt es zu keinen Komplikation, falls Sie diesen Kontakt an einer anderen Stelle verknüpft haben." class="ml-2" font-scale="1.5" variant="primary" icon="question-circle"></b-icon>
						
						</template>

							<template v-slot:cell(aktiv)="data">

								<b-button variant="outline-primary" @click="activierKontaktID(data.item.basic.kontaktID)">
									<span v-if="kontaktBeiAktiv(data.item.basic.kontaktID)">ja</span>
									<span v-else>nein</span>
								</b-button>

							<!-- {{KontaktDetail.deaktiv}} -->

							<!-- <b-form-select 
								@input="doSomeWithX(data.item, 'beteiligte', 'aktivitaet', $event)"
								v-model="data.item.aktuell" >
								<option value="1">aktuell</option>
								<option value="2">vormals</option>

                            </b-form-select> -->

							</template>

						</b-table>
					</b-col>
				</b-row>
	
	</b-card>

<!-- Postadressen Anfang -->
	<b-card v-show="KontaktDetail.basic.kind" class="container" style="margin-bottom: 2rem;">



		<b-row class="down-space-15">
			<b-col cols="12" sm="10">
					<div ref="adressenID" class="ueberschrift einzug_links">Anschrift(en)</div>
			</b-col>
		</b-row>

		<div v-bind:class="{ 'roterRahmen':addressHinzufuegen }" >

			<span cols="12" >	

                    <b-row class="down-space-5">
                            <b-col cols="12">	
                                <!-- {{KontaktDetail.adresse}} -->

                            <b-table
							tbody-tr-class="space"
                                    v-if="KontaktDetail.adresse.length>0"
                                    id="table-transition-example"
                                    :fields="Addressfields"
                                    :items="KontaktDetail.adresse"
                                    :tbody-transition-props="transProps"
                                    striped
									stacked
									small 
                                >

								<template v-slot:cell(berufOprivat)="data">
	                                <b-form-select  v-model="data.item.berufOprivat" id="inputHorizontalWo"  :options="adresseWo" />
									
								</template>

								<template v-slot:cell()="data">
									<b-form-input v-model="data.item[data.field.key]"/>
								</template>


								<template  v-slot:cell(preferred)="data">

									<b-form-radio v-if="KontaktDetail.adresse.length>1" v-model="KontaktDetail.adressePreferred" name="raio-adresses" :value="data.item.id"></b-form-radio>

								</template>

								<template v-slot:cell(edit)="data">

									        <b-button-group>
                                                <b-btn 
												v-b-tooltip.hover 
                        						title="diese Adresse löschen" 
												v-if=" adresseloeschen!=data.index" 
												:pressed="false" 
												@click="adresseloeschen=data.index" 
												variant="danger">X</b-btn>

                                                <b-input-group-text v-if="adresseloeschen===data.index">sicher?</b-input-group-text>

                                                <b-btn v-if="adresseloeschen===data.index" @click="adresseloeschen=null" variant="outline-secondary">nein</b-btn>
                                                <b-btn v-if="adresseloeschen===data.index" @click="deleteAddress(data.index)" variant="outline-secondary">ja</b-btn>
                                            
                                            </b-button-group>

									</template>

                                </b-table>
                            </b-col>
                    </b-row>
<!-- {{KontaktDetail.adresse}} -->


                    <b-row ref="adressenADD" class="down-space-5">
                        <b-button v-if=" !addressHinzufuegen" v-on:click="AdresseNeu" variant="outline-primary">		
                            <span v-if="KontaktDetail.adresse.length > 0">weitere Adresse hinzufügen</span>	
                            <span v-else>Adresse hinzufügen</span>	
                        </b-button>
                    </b-row>
			</span>


		</div>
	</b-card>


	</div>
		<div slot="modal-footer" class="w-100"></div>

  </b-modal>


</template>


<script>

import { kontaktMixin } from '../mixins/kontaktMixins.js'


import ItemFor from './BeteiligteItem.vue'

import DatePicker from 'vue2-datepicker'
import moment from 'moment'

const remain = 6
const itemSize = 60
const userInfoList = []

    export default {

        mixins: [kontaktMixin],

        props: {
        woherKommstDu: String
        },

        components: {
			DatePicker,
			moment,
			'item-for':ItemFor,
        },


        name: "kontaktModal",


data(){
    return{
			itemComponent:ItemFor,
selected: '',
		array_1 : ['beteiligte', 'stammdaten'],
		array_2 : ['adressBuch', 'mitarbeiter', 'unternehmen'],

		start: 0,
		remain,
		size: itemSize,
		
		personenHinzufuegen:false,


		neuesDAVpasswort1:'',
		neuesDAVpasswort2:'',
		neuesAApasswort1:'',
		neuesAApasswort2:'',
		hinzufuegenOffen:false,
		interneKarte : false,
		showAusgewaehltePersonen:false,
		popoverShowSpeichern:false,
		neuerAdrressEintrag:false,

		format_date:"DD.MM.YYYY",
		format_zeit:"HH:mm",
		lang:'de',
		timePickerOptions:{
			start: '00:00',
			step: '00:30',
			end: '23:30'
		},

		neuesUnternehmen:null,
		mitarbeiterAnzeigen:null,
		UnternehmenAnzeigen:null,
		highlightCloseButton:false,
		highlightAddButton:false,
		formattedNameChanged:false,

		transProps: {
				// Transition name
				name: 'list'
            },
            
    //E-Mail
            
		emailWo: [
            { value: '2', text: 'privat' },
            { value: '1', text: 'beruflich' },
		],
        
        Emailaendern:null,
		Emailloeschen:null,
		WWWaendern:null,
		wwwloeschen:null,
        mehrEMail:null,
		emailHinzufuegen:false,
		wwwHinzufuegen:false,
        neueEmail : {'wo':'home', 'emailAddress':''},



	    //TELEFON   	
	
		telefonWas: [
			{ value: '1', text: 'Festnetz' },
			{ value: '2', text: 'Mobil' },
			{ value: '3', text: 'Fax' },
		],
            


        telefonWo: [
            { value: '2', text: 'privat' },
            { value: '1', text: 'beruflich' },
        ],
        nummeraendern:null,
        nummerloeschen:null,
        phoneHinzufuegen:false,
        mehrTelefon:null,
        neuesTelefon:{'berufOprivat':'', 'telefonArt':'', 'telefonNummer':'', 'preferred':''},


        searchMember:'',



        possibleMembers:'',
        possibleRelated:'',
        searchGroup:'',
        searchRelated:'',


			mitarbeiterBearbeiten:false,
			neuerEintragGespeichertState:false,
			neuerEintragState:false,



            
			indexAB:0,
			rowCardIndex:null,

		



    //ADRESSE  
    
            adresseWo: [
                { value: '2', text: 'privat' },
                { value: '1', text: 'beruflich' },
            ],

            addressHinzufuegen:false,

			neueAdresseAllIncl:{'berufOprivat':'privat oder beruflich', 
								'bezeichnung':'Bezeichnung (z.B. Private Adresse)',
								'strasse':'Straße und Hausnummer ', 
								'plz':'Postleitzahl', 
								'ort':'Ort', 
								'postfach':'Postfach', 
								'postfach_plz':'PLZ Postfach', 													
								'bundesland':'Bundesland (z.B. NRW)', 
								'land':'Land (z.B. Deutschland)',
								},


			adresseloeschen:null,
			
				Addressfields_verschieben: [
                    {
                        key: "berufOprivat",
                        label: "wo",
                        colType: "wo"
                    }, {
                        key: "bezeichnung",
                        label: "Bez.",
                        colType: "input"
                    }, {
                        key: "strasse",
                        label: "Straße",
                        colType: "idk"
                    }, {
                        key: "plz",
                        label: "PLZ",
                        colType: "idk"
                    }, {
                        key: "ort",
                        label: "Ort",
                        colType: "idk"
                    },  {
                        key: "postfach",
                        label: "Postf.",
                        colType: "button"
                    },{
                        key: "postfach_plz",
                        label: "PLZ Postf.",
                        colType: "button"
                    },{
                        key: "edit",
                        label: "hinzufügen",
                        colType: "edit"
                    }
        
				],
				dazugehoerigeFirmen: [
				
                    {
                        key: "vollerName.anzeigeName",
                        label: "Name",
					},                    
					"löschen",
					"aktiv"
                        

					],				
				dazugehoerigePersonen: [
				
                    {
                        key: "vollerName.anzeigeName",
                        label: "Name",
					},
                    {
                        key: "preferred",
                        label: "Ansprechpartner",
					},
                    
					"löschen",
					"aktiv"
                        

					],

			Addressfields: [
				
                    {
                        key: "berufOprivat",
                        label: "wo",
                        colType: "wo"
                    }, {
                        key: "bezeichnung",
                        label: "Bez.",
                        colType: "input"
                    }, {
                        key: "strasse",
                        label: "Straße",
                        colType: "idk"
                    }, {
                        key: "plz",
                        label: "PLZ",
                        colType: "idk"
                    }, {
                        key: "ort",
                        label: "Ort",
                        colType: "idk"
                    },  {
                        key: "postfach",
                        label: "Postf.",
                        colType: "button"
                    },{
                        key: "postfach_plz",
                        label: "PLZ Postf.",
                        colType: "button"
                    },{
                        key: "preferred",
                        label: "bevorzugt"
					},
					{
                        key: "edit",
                        label: "löschen",
                        colType: "edit"
                    }
        
                ],

            preferred:null,
            speichernDisabled:'',
            disabledAbbrechenAdresse: true,

            }
        },

computed: {

	anzeigeNameExistiert(){
		if(this.KontaktDetail.vollerName.anzeigeName)
		return true
		else
		return false
	},
	
	admin:
		{
			get()
			{
				return this.$store.getters.admin
			},
			set(value)
			{
				this.$store.commit('setadmin', value);
			}
		},


	changeHappend(){
		if(Object.compareObjects(this.KontaktDetail, this.KontaktDetail_backup)===true)
			return false;
		else
			return true;
	},



 	art(){
        if(this.$store.state.auswahlListen.art)
        return this.$store.state.auswahlListen.art;
        else
        return []
    },

    taetigkeiten(){
        return this.$store.state.auswahlListen.taetigkeiten;
	},
	
	taetigkeiten_fachplaner(){
        return this.$store.state.auswahlListen.taetigkeiten_fachplaner;
	},
	
    filterverfuegbareMitarbeiter() {

        const search = this.searchMember.toLowerCase().trim();

		if(search || this.KontaktDetail.beteiligte.length>0)
			return this.verfuegbareEinzelpersonen.filter(c => c.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1 && this.KontaktDetail.beteiligte.indexOf(c.basic.kontaktID)<0);
		else
			return this.verfuegbareEinzelpersonen

    },

    filterverfuegbareGruppen() {

            const search = this.searchGroup.toLowerCase().trim();

		if(search || this.kontaktGruppe.length>0)
			return this.verfuegbareGruppen.filter(c => c.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1 && this.kontaktGruppe.indexOf(c.basic.kontaktID)<0);
		else
			return this.verfuegbareGruppen

    },
    // filterverfuegbareGruppen() {

    //         const search = this.searchGroup.toLowerCase().trim();

    //         if (!search) 
	// 			return this.verfuegbareGruppen;
			

    //         return this.verfuegbareGruppen.filter(c => c.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1);

    // },

    blancAddress(){
        var blanci = { 'preferred':'0', 'berufOprivat':'1', 'bezeichnung':'', 'strasse':'', 'plz':'', 'ort':'', 'land':'','bundesland':'',  'postfach':'',  'postfach_plz':'', 'kreis':'','gemeindeteil':'', 'gemarkung':'', 'flur':'' , 'flurstueck':''}
        return blanci;
	},
	
	tooltipPhone() {
			var zuruk = "";

			if(this.neuesTelefon.nummer)
				zuruk = "";
				else
			zuruk = "Bitte Nummer eintragen.";	
				return zuruk;
	},

	tooltipEmail() {

			var zuruk = "";

			if(this.neueEmail.emailAddress==="")
				zuruk = "Bitte E-Mailadresse eintragen.";
				else
				zuruk = " "
				return zuruk;
	},

	store() {
		return this.$store.state
	},

	statusListe() {
		return this.$store.state.statusListe
	},


}, // computed

// WEITER - läuft noch nicht rund

methods: {
	
	activierKontaktID(kontaktID){

		if(this.KontaktDetail.deaktiv==undefined)
		this.$set(this.KontaktDetail, 'deaktiv', []);


		var ind = this.KontaktDetail.deaktiv.findIndex(item=>item == kontaktID);

		if(ind>=0)
			this.KontaktDetail.deaktiv.splice(ind, 1)
		else
		this.KontaktDetail.deaktiv.push(kontaktID)


	},

	kontaktBeiAktiv(kontaktID){

		if(this.KontaktDetail.deaktiv==undefined)
		this.$set(this.KontaktDetail, 'deaktiv', []);

		var ind = this.KontaktDetail.deaktiv.findIndex(item=>item == kontaktID);
		
		if(ind>=0)
		return false
		else
		return true
	},
	
	//{{KontaktDetail.deaktiv}}



	// onBeGruppeChange() // wenn sich der AnzeigeName, die Telefonnummer oder die E-Mail ändert, wird das auf BeGruppe übertragen
	// {
	// 	 console.log("fire onBeGruppeChange")
	// 	if(this.KontaktDetail.basic.kind==1){ // offene Karte ist eine Einzelperson
	// 		if(this.KontaktDetail.gruppe.length>0){
	// 			for(var param of this.KontaktDetail.gruppe){
	// 				var srcKontakt = this.KontaktDetail;
	// 				var trgKontakt = param;

	// 				this.editTargetKontakt(srcKontakt, trgKontakt, 'beteiligte')
					
	// 			}
	// 		}
	// 	}
	// 	else if(this.KontaktDetail.basic.kind==2) // offene Karte ist eine Gruppe
	// 	{
	// 		if(this.KontaktDetail.beteiligte.length>0){
	// 			for(var param of this.KontaktDetail.beteiligte){
					
	// 				var srcKontakt = this.KontaktDetail;
	// 				var trgKontakt = param;

	// 				this.editTargetKontakt(srcKontakt, trgKontakt, 'gruppe')
					
	// 			}	
	// 		}
	// 	}		
	// },

	editTargetKontakt(srcKontakt, trgKontakt, einsatz){ // ändert im Zielkontakt die Änderungen des offenen Kontaktes

		var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == trgKontakt.basic.kontaktID)
		// console.log(trgKontakt.vollerName.anzeigeName)
		// console.log(index_trgKontakt)

		if(index_trgKontakt>=0){

			var indexBeGruppe = this.Addressbook[index_trgKontakt][einsatz].findIndex(item=>item.basic.kontaktID == srcKontakt.basic.kontaktID)
			
			// console.log(indexBeGruppe)
			// console.log(this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe])
			if(indexBeGruppe>=0){
				this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe].vollerName = srcKontakt.vollerName
				this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe].telefone = srcKontakt.telefone
				this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe].emails = srcKontakt.emails
				this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe].aktiv = srcKontakt.aktiv
			}

			// console.log(this.Addressbook[index_trgKontakt][einsatz][indexBeGruppe])

		this.updateToSaveArray(this.Addressbook[index_trgKontakt]);

		}


		// }


	},

	toSaveArraySpeichern(neuekontaktID){

 console.log("toSaveArraySpeichern")
//  console.log(this.toSaveArray)
//   console.log(this.neuekontaktID)
		if(this.toSaveArray.length>0)
		{
			for (var element of this.toSaveArray) {
				
				// console.log(neuekontaktID)
				// console.log(element)
				var kontaktOhneIDBet = element.beteiligte.findIndex(item=>item == "")

				if(kontaktOhneIDBet>=0 && neuekontaktID){
					//element.beteiligte[kontaktOhneIDBet].uid = neuekontaktID;
					//element.beteiligte[kontaktOhneIDBet].basic.kontaktID = neuekontaktID;
					element.beteiligte[kontaktOhneIDBet]= neuekontaktID;
				}
				

				var kontaktOhneIDGruppe = element.gruppe.findIndex(item=>item == "")

				if(kontaktOhneIDGruppe>=0 && neuekontaktID){
					//element.gruppe[kontaktOhneIDGruppe].uid = neuekontaktID;
					//element.gruppe[kontaktOhneIDGruppe].basic.kontaktID = neuekontaktID;
					element.gruppe[kontaktOhneIDGruppe] = neuekontaktID;
				}

						this.speicher_kontakt_einzeln(element, 'toSaveArraySpeichern / kontaktModal').then(data => {

						})
			}
		}
	},

	speicher_kontakt(){

	console.log("speicher_kontakt")

		if(this.changeHappend==true){

				this.speicher_kontakt_einzeln(this.KontaktDetail, 'toSaveArraySpeichern / kontaktModal').then(data => {
						
						// console.log(data)

						// if(this.array_2.findIndex(item=>item==this.woherKommstDu)>=0)
						// this.bearbeiten=false;

					if(data.update===true){ // update bestehenden Kontakt
					// console.log("data.update===true")
						this.toSaveArraySpeichern();
						this.closeThisModal();
					
					} // neuer Kontakt
					else if(data.newContact===true && data.newKontaktID){
						//console.log("neuer Kontakt")
						this.toSaveArraySpeichern(data.newKontaktID)
						this.KontaktDetail.basic.kontaktID = data.newKontaktID;
						this.KontaktDetail.uid = data.newKontaktID.toString();
						this.Addressbook.unshift(this.KontaktDetail);

						// array_1 : ['beteiligte', 'stammdaten'],
						// array_2 : ['adressBuch', 'mitarbeiter', 'unternehmen'],

						if(this.array_2.findIndex(item=>item==this.woherKommstDu)>=0)
						this.clickOnName(data.newKontaktID);

						this.closeThisModal();
							
					}
			   
							
				})

		}
			
		else{
			//this.toSaveArraySpeichern();
			// this.bearbeiten=false;
			this.closeThisModal();
		}
			

	},


		 

	doSomeWithX(data, wer, was, newValue){ // lösche Beteiligte oder Gruppe in der offenen Karte
	console.log('doSomeWithX')
	// data ist das Objekt, auf das geklickt wurde
		//  console.log(data)
		//  console.log(wer)
		//  console.log(was)
		//  console.log(newValue)
	
      if(wer==='gruppe'){
        var einsatz1 = "gruppe";
        var einsatz2 = "beteiligte";
      }
      else{ // add beteilgter
        var einsatz1 = "beteiligte";
        var einsatz2 = "gruppe";
	  }


		var indexBeGruppeOffeneKarte = this.KontaktDetail[einsatz1].findIndex(item=>item == data.basic.kontaktID)
		// findet den Index des Beteiligten oder Gruppe in der offenen Karte
		// console.log(indexBeGruppeOffeneKarte);

		// console.log(this.KontaktDetail[einsatz1][indexBeGruppeOffeneKarte])

		var indexKarteGegenseite = -1; // 

		if(indexBeGruppeOffeneKarte>=0){ //prüfen, ob es den zu löschenden BeGruppe gibt

			if(was=='delete'){

				if(this.KontaktDetail[einsatz1][indexBeGruppeOffeneKarte] == this.KontaktDetail.beteiligterPreferred && wer=='beteiligte')
				this.KontaktDetail.beteiligterPreferred='';
				
				this.KontaktDetail[einsatz1].splice(indexBeGruppeOffeneKarte, 1); // BeGruppe wird in offener Karte gelöscht


			}

				indexKarteGegenseite = this.Addressbook.findIndex(item=>item.basic.kontaktID == data.basic.kontaktID) 
				// Index von BeGruppe in Adressbuch suchen -> Karte von BeGruppe
				// console.log(indexKarteGegenseite);

				
		}
		
		var indexBeGruppeKarteGegenseite = -1;

		if(indexKarteGegenseite>=0){// prüfen ob es Karte des BeGruppe in Adressbuch gibt
		var KarteGegenseite = JSON.parse(JSON.stringify(this.Addressbook[indexKarteGegenseite]))

		indexBeGruppeKarteGegenseite = KarteGegenseite[einsatz2].findIndex(item=>item == this.KontaktDetail.basic.kontaktID)
		// Index der Karte von BeGruppe die gelöscht wird, in dessen Beteiligte / Gruppe suchen - als Gegenstück | Beteiligter <-> Gruppe

		}


		if(indexBeGruppeKarteGegenseite>=0)
		{
			// console.log(this.backUpContacts.findIndex(item=>item.basic.kontaktID == KarteGegenseite.basic.kontaktID))

            if(this.backUpContacts.findIndex(item=>item.basic.kontaktID == KarteGegenseite.basic.kontaktID)==-1) // suche im backUpContacts Array, ob mutterKarte schon vorhanden
			this.backUpContacts.push(KarteGegenseite); // falls nicht, wird die Mutterkarte zum backUpContacts Array hinzugefügt
			
			// console.log(was)

			if(was=='delete')
			this.Addressbook[indexKarteGegenseite][einsatz2].splice(indexBeGruppeKarteGegenseite, 1); // Gegenstück wird gelöscht
			
			// if(was=='aktivitaet' && newValue){
			// 	this.Addressbook[indexKarteGegenseite][einsatz2][indexBeGruppeKarteGegenseite].aktuell = newValue; // Gegenstück wird aktiv gesetzt
			// }


			this.updateToSaveArray(this.Addressbook[indexKarteGegenseite]);

		}


	},


	saveNewPassword(){

				if(!this.neuesAApasswort1)
				{
					this.$toasted.show('Bitte geben Sie ein Passwort ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else if(this.neuesAApasswort1!==this.neuesAApasswort2)
				{
					this.$toasted.show('Die Passwörter stimmen nicht überein. Bitte geben Sie zwei identische Passwörter ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else{
					this.axios.post(this.$store.state.url_set_kontakt, {
						neuesPasswort: this.neuesAApasswort1,
						benutzerID:this.KontaktDetail.basic.benutzerID,
						what:'updatePasswordOther',
						besonders: this.$store.state.dev_sub
						}).then((response) => {
						if(response.data.success===true){
							this.$toasted.show('Das Passwort wurde erfolgreich geändert.', {
							position: 'top-center',
							type:'success',
							duration:1500,
							containerClass:'ganzVorne'})

							this.neuesAApasswort1=null;
							this.neuesAApasswort2=null;

						}
						else
							this.$toasted.show('Das Passwort konnte nicht geändert werden.', {
							position: 'top-center',
							type:'error',
							duration:1500,
							containerClass:'ganzVorne'})
						})
				}
	},

	davpasswortSpeichern() {
	

				if(!this.neuesDAVpasswort1)
				{
					this.$toasted.show('Bitte geben Sie ein Passwort ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else if(this.neuesDAVpasswort1!==this.neuesDAVpasswort2)
				{
					this.$toasted.show('Die Passwörter stimmen nicht überein. Bitte geben Sie zwei identische Passwörter ein.', {    
					action : [
						{
							text : 'Okay',
							onClick : (e, toastObject) => {
								toastObject.goAway(0);
							}
						},

					],
					position: 'top-center',
					type:'error',
					duration:5000,
					containerClass:'ganzVorne'})
				}
				else
				{

				this.axios.post(this.$store.state.url_writeDav, {
				benutzer:this.KontaktDetail,
				neuesPasswort: this.neuesDAVpasswort1,
				was:"updatePasswordOther",
				besonders : this.$store.state.dev_sub
				}).then((response) => {

					if(response.data.success===true)
						this.$toasted.show('Das Passwort für diesen Kalender wurde erfolgreich geändert.', {className: 'toastvorne', position: 'top-center', duration:2000, type:'success'});
					else
						this.$toasted.show('Das Passwort für diesen Kalender konnte nicht geändert werden.', {className: 'toastvorne', position: 'top-center', duration:2000, type:'error'});

				})
			}
		},
		
	moveItem(row, index, was){

		this.KontaktDetail[was].push(this.KontaktDetail[was][index]);
		this.KontaktDetail[was].splice(index, 1)

	},


	loesche_kontakt(row){
		this.$root.$emit('closeModal');
		var daten ={"item" : row};
		this.$root.$emit('loescheEintragFromModal', daten);
	},
	
	onCellClickBeteiligter(row){

		this.interneKarte = true;
		//this.offeneKarteIndex = null;
		this.$store.commit('setKontaktDetail', null);
		this.$store.commit('setKontaktDetail', JSON.parse(JSON.stringify(row)));
		this.$store.commit('setKontaktDetail_backup', null);
		this.$store.commit('setKontaktDetail_backup', JSON.parse(JSON.stringify(row)));

		//      this.$store.commit('addCoords', this.getMousePos(event));

	},

	onchange(){

		//      this.$store.commit('addCoords', this.getMousePos(event));

	},


	checkFormattedNameBeteiligter(){
		console.log("fire")
		if(this.formattedNameChanged===false){
			
			if(this.KontaktDetail.vollerName.vorname && this.KontaktDetail.vollerName.nachname)
				var name = this.KontaktDetail.vollerName.nachname + ", " + this.KontaktDetail.vollerName.vorname;
			else if(this.KontaktDetail.vollerName.nachname)
                name = this.KontaktDetail.vollerName.nachname
			else
                name = "";

			this.KontaktDetail.vollerName.anzeigeName = name;

		}
	},

	checkFormattedName(){
		if(this.formattedNameChanged===false)
		this.KontaktDetail.daten.formattedName = this.KontaktDetail.daten.firstName + " " + this.KontaktDetail.daten.lastName;
	},
        
    
	changeTaetigkeiten(what){
		console.log(what);

		this.KontaktDetail.taetigkeit.details=null;
		this.KontaktDetail.taetigkeit.details=[];
		//console.log(this.KontaktDetail.art.details);

		for(var i=0; i<what.length; i++)
		{
			var id = what[i];
				
			//const targetIndex = this.art.findIndex(item => item.value === id);
			var targetValue = this.taetigkeiten.find(item => item.value === id);

			if(targetValue)
			this.KontaktDetail.taetigkeit.details.push({"taetigkeit":targetValue.text, "taetigkeitID":targetValue.value})

		}
	},

	changeArt(what){
		console.log(what);

		this.KontaktDetail.art.details=null;
		this.KontaktDetail.art.details=[];
		//console.log(this.KontaktDetail.art.details);

		for(var i=0; i<what.length; i++)
		{
			var id = what[i];
			//const targetIndex = this.art.findIndex(item => item.value === id);
			var targetValue = this.art.find(item => item.value === id);

			if(targetValue)
			this.KontaktDetail.art.details.push({"art":targetValue.text})

		}
	},




	AdresseNeu(){


	
//, duration, options
		var neueAdresse = JSON.parse(JSON.stringify(this.blancAddress))
	

		var neueID
		if(this.KontaktDetail.adresse.length==0)
		neueID = 0;
		else{
			var i
			for (i = 1; i < 100000; i++) {
				if(this.KontaktDetail.adresse.findIndex(item=>item.id==i)==-1){
					neueID = i;
					break;
				}
			} 
		}
		neueAdresse.id = neueID;

		this.KontaktDetail.adresse.push(neueAdresse);

			var ziel = this.$refs.adressenADD;

			setTimeout(()=>{
			ziel.scrollIntoView({ block: 'start',  behavior: 'smooth' });
			},50);


	},

	AdresseEdit(offeneAdresse, neuerAdrressEintrag, index){
		// hier WEITER
		

		if(neuerAdrressEintrag===true){
			this.neuerAdrressEintrag = true;
			this.backUpOffeneAdresse = JSON.parse(JSON.stringify(this.blancAddress));
			this.offeneAdresse = JSON.parse(JSON.stringify(this.blancAddress));
			console.log(this.offeneAdresse);
			//this.KontaktDetail.adresse.unshift(neueAdresse);
			//this.offeneAdresse = null;
		}
		else{ // vorhandene Adresse ändern
			this.offeneAdresse = JSON.parse(JSON.stringify(offeneAdresse));
			this.offeneAdresse.index = index;

			console.log(this.offeneAdresse);
			this.backUpOffeneAdresse = JSON.parse(JSON.stringify(offeneAdresse));
		}

			this.addressHinzufuegen = true;

			var ziel = this.$refs.adressenID;

			setTimeout(()=>{
			ziel.scrollIntoView({ block: 'start',  behavior: 'smooth' });
			},50);

	},


	returnFormattedNameForID(id){

	},

	WWWAendernAn(index){

		this.WWWaendern=index; 
		this.$refs["www_input_" + index][0].focus();

	},

	EmailAendernAn(index){

		this.Emailaendern=index; 
		this.$refs["email_input_" + index][0].focus();

	},

	nummerAendernAn(index){

		this.nummeraendern=index; 
		this.$refs["telefon_input_" + index][0].focus();

	},

	plusEmail(){

		var neueID
		if(this.KontaktDetail.emails.length==0)
		neueID = 0;
		else{
			var i
			for (i = 1; i < 100000; i++) {
				if(this.KontaktDetail.emails.findIndex(item=>item.id==i)==-1){
					neueID = i;
					break;
				}
			} 
		}

		console.log("plusEmail");
		var neueEmail = {'berufOprivat':'1', 'emailAdresse':'', 'id':neueID};
		this.KontaktDetail.emails.push(neueEmail);
	},
	
	neuWWW(){
		console.log("plusWWW");
		var neuWWW = {'berufOprivat':'1', 'wwwAdresse':''};
		this.KontaktDetail.www.push(neuWWW);
	},

	loescheEmail(index){
		console.log("loescheEmail");
		this.Emailloeschen=null;

		if(this.KontaktDetail.emails[index].id == this.KontaktDetail.emailPreferred)
		this.KontaktDetail.emailPreferred = '';

		this.KontaktDetail.emails.splice(index, 1);
		//this.onBeGruppeChange();
	},

	loescheWWW(index){
		console.log("wwwloeschen");
		this.wwwloeschen=null;
		this.KontaktDetail.www.splice(index, 1);

	},

	loescheNummer(index){
		console.log("loescheNummer");
		this.nummerloeschen=null

		if(this.KontaktDetail.telefone[index].id == this.KontaktDetail.rufnummerPreferred)
		this.KontaktDetail.rufnummerPreferred = '';

		this.KontaktDetail.telefone.splice(index, 1);
		//this.onBeGruppeChange();
    },
    
    plusPhone(){
		// noch nicht vorhandene id
		var neueID
		if(this.KontaktDetail.telefone.length==0)
		neueID = 0;
		else{
			var i
			for (i = 1; i < 100000; i++) {
				if(this.KontaktDetail.telefone.findIndex(item=>item.id==i)==-1){
					neueID = i;
					break;
				}
			} 
		}
        var neuesTelefon = {'berufOprivat':'1', 'telefonArt':'1', 'telefonNummer':'', 'id':neueID};
        this.KontaktDetail.telefone.push(neuesTelefon);
    },

    addPhone(){
        this.KontaktDetail.telefone.unshift(this.neuesTelefon);
        this.neuesTelefon = {'berufOprivat':'', 'telefonArt':'', 'telefonNummer':'', 'preferred':''};
        this.phoneHinzufuegen=true;
        this.$refs.welcheNummer.focus();
	},
	
	
	modifyAddress(index){

		console.log("modifyAddress");
		console.log(index);

		this.KontaktDetail.adresse.splice(index, 1, this.offeneAdresse);
		delete this.KontaktDetail.adresse[index].index;

		this.offeneAdresse = null;
		this.addressHinzufuegen=false;
	
	},

	addAddress(){

		console.log("addAddress");
		this.offeneAdresse.grundstueckID="neu";
		this.KontaktDetail.adresse.unshift(this.offeneAdresse);
		this.offeneAdresse = JSON.parse(JSON.stringify(this.blancAddress));
		this.addressHinzufuegen=true;

	},

	plusAddress(){
		this.addressHinzufuegen=true;
		this.neueAdresse={'type':'home', 'label':'', 'street':'', 'stateProvince':'', 'postalCode':'', 'countryRegion':''};


		var ziel = this.$refs.adressenID;
		setTimeout(()=>{
		ziel.scrollIntoView({ block: 'start',  behavior: 'smooth' });
		},50);
	},



	addEmail(){
		this.KontaktDetail.daten.emails.unshift(this.neueEmail);
		this.neueEmail = {'wo':'home', 'emailAddress':''};
		this.emailHinzufuegen=true;
	},


	speicher_neuen_kontakt_abbruch(){
			console.log("speicher_neuen_kontakt_abbruch");
			console.log(this.woherKommstDu);

		// array_1 : ['beteiligte', 'stammdaten'],
		// array_2 : ['adressBuch', 'mitarbeiter', 'unternehmen'],

					if(this.changeHappend === false){

					//console.log(this.array_2.findIndex(item=>item==this.woherKommstDu));

						// if(this.array_2.findIndex(item=>item==this.woherKommstDu)>=0)
						// this.bearbeiten=false;

						this.closeThisModal();

					}
						
					else
						this.popoverShowSpeichern = true;

	},


    zurueckZumFormular(){
		this.popoverShowSpeichern = false;
	},

    trotzAenderungSchliessen(){

		//console.log(this.backUpContacts);

		if(this.backUpContacts.length>0){

			for (var element of  this.backUpContacts) {
				
					var ID = element.basic.kontaktID;
					
					var pos = this.Addressbook.map(function(e) { return e.basic.kontaktID; }).indexOf(ID)

					if(pos>=0)
					this.Addressbook.splice(pos,1,element)
			}

		}

		this.backUpContacts=[];
		//y.filter(e => x.indexOf(e)===-1)

		this.KontaktDetail = JSON.parse(JSON.stringify(this.KontaktDetail_backup));

		this.closeThisModal();

	},	

    deleteAddress(zeile){
		console.log("deleteAddress")
		console.log(zeile)


		if(this.KontaktDetail.adresse[zeile].id == this.KontaktDetail.adressePreferred)
		this.KontaktDetail.adressePreferred = '';

		this.KontaktDetail.adresse.splice(zeile, 1);
		this.adresseloeschen=null;
	},
	

    setOtherToNull(){

        for(var i=0; i<this.KontaktDetail.adresse.length; i++)
        {
            this.KontaktDetail.adresse[i].preferred=0;
        }
        
        this.KontaktDetail.adresse[this.preferred].preferred=1;

	},

	// getVerbundenMit(kontaktID, mitWas){

	// 	this.axios.post(this.$store.state.url_get_kontakte, {
			
	// 		besonders: this.$store.state.dev_sub,
	// 		welche:mitWas,
	// 		kontaktID:kontaktID,
	// 		}).then((response) => {
	// 			if(mitWas=='verbundeneGruppen' && this.KontaktDetail)
	// 			this.KontaktDetail.gruppe = response.data;
				
	// 			if(mitWas=='verbundenePersonen' && this.KontaktDetail)
	// 			this.KontaktDetail.beteiligte = response.data;
	// 		})
	// 		.catch(function (error) {
	// 					// handle error
	// 					console.log(error);
	// 		})

	// },

	closeThisModal(){
		if(this.$refs.neuer_adressbuch_eintrag)
		this.$refs.neuer_adressbuch_eintrag.hide()

	},

	openThisModal(){
		if(this.$refs.neuer_adressbuch_eintrag){
			this.backUpContacts=[];
			this.toSaveArray=[];
		this.$refs.neuer_adressbuch_eintrag.show()

		}
	},

	
}, // methods


  mounted() {

	  console.log("Kontak Modal öffnen");
	  
		this.$root.$on('closeKontaktModal', bvEvent => {
				// this.bearbeiten = false
				this.closeThisModal();
		})

		this.$root.$on('openKontaktModal', bvEvent => {
			
				console.log(this.woherKommstDu)
				console.log('openKontaktModal')
				this.openThisModal();		
		})	

    this.$root.$on('bv::tooltip::show', bvEvent => {
			// console.log('bvEvent:', bvEvent.target.id)
			if(bvEvent.target.id==="speichernDisabled"){

				var welcher = "";
				
				if(this.emailHinzufuegen)
					welcher ="emailSave";
				if(this.phoneHinzufuegen)
				welcher ="phoneSave";
				if(this.addressHinzufuegen)
				welcher ="AddressSave";

				var ziel = this.$refs[welcher];

				if(welcher){
					ziel.scrollIntoView({ block: 'end',  behavior: 'smooth' });
						setTimeout(()=>{
							this.highlightCloseButton=true;
						},150);		
				}
			}
	
		})

		this.$root.$on('bv::tooltip::hide', bvEvent => {
			setTimeout(()=>{
				this.highlightCloseButton=false;
			},1000);

		})

		this.$root.$on('getFrischeListen', kontaktID => {

			//this.getListen();

		})

		//  this.$root.$on('getVerbundenMit', params => {
		// 	//this.KontaktDetail.gruppe=[];
			
		//  	this.getVerbundenMit(params.kontaktID, params.mitWas);

		//  })

},

created(){


}, // created


}

</script>

<style>

.space{
	margin-bottom:15px;
}

.dg-btn--ok {
    border-color: green !important;
  }
  
.dg-btn--cancel {
    border-color: red !important;
	background-color: none !important;
	color:black !important;
  }


</style>

<style scoped>

.lessPadding{

	padding:0px 5px;
}

@media  (max-width: 992px) {
	.nurBreit{
		display: none;
	}
}
.kontaktListe{
	width: 285px;
	overflow-x: hidden;
}


.wider{
	width: 1000px !important;
}

 .modal-dialog {
    width: 1000px !important;
    margin: 30px auto;
  }
  .modal-xl{
	max-width: 1000px !important;
	width: 1000px !important;
}



</style>