import { forAllMixin } from './forAllMixin.js'
import { kontaktMixin } from './kontaktMixins.js'
import SimpleCrypto from "simple-crypto-js"

import { VueOfflineMixin } from 'vue-offline'
import { VueOfflineStorage } from 'vue-offline'

//console.log(SimpleCrypto)

const secretKey = "ArchiAPP" // späterändern in username

const simpleCrypto = new SimpleCrypto(secretKey)

const remain = 6
const itemSize = 60



export const projectMixin = {



    mixins: [forAllMixin, kontaktMixin, VueOfflineMixin],

    data() {
        return {
            isOnline:true,
            result: [],
            delay: 400,
            clicks: 0,
            timer: null,
                  
            projekt_typ_options: [
                { value: null, text: 'bitte wählen' },

      
                { value: 1, text: 'Neubau' },
                { value: 7, text: 'Sanierung' },
                { value:2,text:'Nutzungsänderung'},
                { value:8,text:'Umbau'},
                { value:9,text:'Anbau'},
                { value:10,text:'Aufstockung'},
                { value:11,text:'Abriss'},

      
      
              ],

            bv_typ_options: [
                { value: null, text: 'bitte wählen' },
      
                {
                  label: 'Wohnen',
                  options: [
                    { value: 1, text: 'Wohngebäude' },
                    { value: 2, text: 'Heimbauten' }
                  ]
                },
                {
                  label: 'Bildung',
                  options: [
                    { value: 3, text: 'Schulbauten, Kinderhorte' },
                    { value: 4, text: 'Hochschulen' },
                    { value: 5, text: 'Forschungsbauten' }
                  ]
                },
      
                { value: 6, text: 'Sakralbauten, Kirchliche Bauten, Friedhofswesen' },
                { value: 7, text: 'Gesundheitsbauten' },
                  {value:8,text:'Bauten für Kultur'},
                  {value:9,text:'Sportbauten, Spiel'},
                  {value:10,text:'Gastronomie- und Vergnügungsbauten'},
                  {value:11,text:'Bauten zur Beherbergung'},
                  {value:12,text:'Öffentliche und private Verwaltungsgebäude'},
                  {value:13,text:'Bauten für die öffentliche Sicherheit'},
                  {value:14,text:'Bauwerke für Handel oder Gewerbe'},
                  {value:15,text:'Bauten und Einrichtungen des Geld- und Zahlungsverkehrs'},
                  {value:16,text:'Verkehrsbauwerke'},
                  {value:17,text:'Bauwerke in Land- und Forstwirtschaft, Veterinärwesen und Tierpflege'},
                  {value:18,text:'Industriebauwerke'},
                  {value:19,text:'Versorgungsbauwerke und Entsorgungsbauwerke'},
                  {value:20,text:'Wasserbau'},
      
      
              ],

              bv_typ_suboptions: {
    

                '1' : [{
                  label: 'freistehend',
                  options: [
                    { value:'freistehend1', text: 'Kate (Hütte)' },
                    { value:'freistehend2', text: 'Gartenhaus' },
                    { value:'freistehend3', text: 'Gästehaus' },
                    { value:'freistehend4', text: 'Wohnhaus' },
                    { value:'freistehend5', text: 'Einfamilienwohnhaus' },
                    { value:'freistehend6', text: 'Atriumhaus' },
                    { value:'freistehend7', text: 'Hanghaus' },
                    { value:'freistehend8', text: 'Haus mit Einliegerwohnung' },
                    { value:'freistehend9', text: 'Haus mit Berufsbereich' },
                    { value:'freistehend10', text: 'Villa' }, 
                    { value:'freistehend11', text: 'Schloss' }, 
                ]

                },
                {
                    label: 'verdichtet',
                    options: [
                        { value:12, text: 'Doppelhaus' }, 
                        { value:13, text: 'Reihenhaus' }, 
                        { value:14, text: 'Gruppenhaus' }, 
                        { value:15, text: 'Geschosswohnungsbau'},  
                        { value:16, text: 'Wohn- und Geschäftsgebäude' }, 
                  ]
                    

                  }

                ],

                '2' : [{
                    label: 'Einzelbauten',
                    options: [
                      { value:17, text: 'Kinderheim' },
                      { value:18, text: 'Jugendheim' },
                      { value:19, text: 'Studentenwohnheim' },
                      { value:20, text: 'Internat' },
                      { value:21, text: 'Altenheim' },
                      { value:22, text: 'Pflegeheim' },

                  ]
                  
                  },
                  {
                      label: 'Anlagen',
                      options: [

                          { value:23, text: 'Kinderdorf' }, 
                          { value:24, text: 'Jugenddorf' }, 
                          { value:25, text: ' Kinder- und Jugenddorf' }, 
                          { value:26, text: 'Alterssiedlung'},  
                    ]
                      
                    }
  
                  ],

                '3' : [
                    {
                  label: 'Kinderhorte',
                  options: [
                    { value:27, text: 'Kindergarten' },
                    { value:28, text: 'Kindertagesstätte' },
                    { value:29, text: 'Internat' },
                ]
                    
                },
                {
                    label: 'Regelschulen',
                    options: [
                      { value:30, text: 'Grundschule' },
                      { value:31, text: 'Hauptschule' },
                      { value:32, text: 'Grund- und Hauptschule' },
                      { value:33, text: 'Realschule' },
                      { value:34, text: 'Gesamtschule' },
                      { value:35, text: 'Gymnasium' },
                      { value:36, text: 'Schulzentrum' },
                      { value:37, text: 'Schuldorf' },
                      { value:38, text: 'Ganztagsschule' },
                  ]
                      
                  },
                  { value:39, text: 'Sonderschule' },
                  { value:40, text: 'Spezialschule' },
                  {
                        label: 'Ausbildungsschulen',
                        options: [
                        { value:41, text: 'Berufsschule' },
                        { value:42, text: 'Handelsschule' },
                        { value:43, text: 'Fachschule' },
                        { value:44, text: 'Schwesternschule' },

                    ]
                      
                  
                  },

                  
                //   Fachhochschule
                //   Pädagogische Hochschule
                //   Musikhochschule
                //   Ingenieursschule
                //   Technische Hochschule
                //   Akademie
                //   Universität
                //   Hörsaalgebäude
                //   Auditorium maximum
                //  Fallturm
                //   Forschungsinstitut
                //   Forschungsreaktor
                //   Labor
                //   Messturm
                //   Radioteleskop
                //   Rechenzentrum
                //   Sternwarte
                //   Teilchenbeschleuniger
                //   Windkanal
                //  Volkshochschule
                 
                 
                //  Sonderschule
                //  Spezialschule
                 
                //  Seminargebäude

                 
            ],

      
            },

            start: 0,
			remain,
            size: itemSize,
            
            // possibleFields:
            // [
            //     {
            //         key: "projectContent.stammdaten.allgemein.bv_name",
            //         label: "Name",
            //         sortable: true,
            //         id:0
            //     }, 
            //     {
            //         key: "projectContent.ProjektBeteiligte[1]",
            //         label: "Bauherr",
            //         sortable: true,
            //         formatter: 'for_bauherren',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:1
            //     },
            //     {
            //         key: "projectContent.ProjektBeteiligte[5]",
            //         label: "Gemeinde",
            //         sortable: true,
            //         formatter: 'for_gemeinde',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:1
            //     },
            //     {
            //         key: "projectContent.stammdaten.allgemein.bv_typ",
            //         label: "Typ",
            //         sortable: true,
            //         formatter: 'for_bv_typ_options_key',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:2
            //     }, 
            //     {
            //         key: "projectContent.stammdaten.allgemein.bv_status",
            //         label: "Status",
            //         sortable: true,
            //         formatter: 'for_bv_status_key',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:3
            //     }, 
            //     {
            //         key: "projectContent.stammdaten.allgemein.projekt_kat",
            //         label: "Kategorie",
            //         sortable: true,
            //         formatter: 'for_projekt_typ_options',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:4
            //     }, 

            //     {
            //         key: "projectContent.stammdaten.adresse.ort",
            //         label: "Ort",
            //         sortable: true,
            //         id:16
            //     }, 
 
            //     {
            //         key: "projectContent.stammdaten.adresse.plz",
            //         label: "PLZ",
            //         sortable: true,
            //         id:17
            //     }, 

            //     {
            //         key: "projectContent.stammdaten.adresse.strasse",
            //         label: "Straße",
            //         sortable: true,
            //         id:18
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.10",
            //         label: "Verantwortl.",
            //         sortable: true,
            //         formatter: 'for_Verantwortl',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:5
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.11",
            //         label: "LP 1",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:6
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.12",
            //         label: "LP 2",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:7
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.13",
            //         label: "LP 3",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:8
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.14",
            //         label: "LP 4",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:9
            //     }, 
            //     {
            //         key: "projectContent.ProjektBeteiligte.15",
            //         label: "LP 5",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:11
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.16",
            //         label: "LP 6",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:12
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.17",
            //         label: "LP 7",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:13
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.18",
            //         label: "LP 8",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:14
            //     }, 

            //     {
            //         key: "projectContent.ProjektBeteiligte.19",
            //         label: "LP 9",
            //         sortable: true,
            //         formatter: 'for_Planer',
            //         sortByFormatted: true,
            //         filterByFormatted: true,
            //         id:15
            //     }, 

            //     {
            //     key: "delete",
            //     label: "löschen",
            //     id:1000
            //     }
            // ],
            
            searchTermBauherr:'',
            nextObj : null,
            blancProject : { 
                                "aufgaben":"",
                                "ausschreibung":"",
                                "bauablaufplan":"",
                                "bautagebuch":"",
                                "berechnungen":"",
                                "ProjektBeteiligte":{"handwerker":[], "fachplaner":[],"entwurfsverfasser":"","1":"0","2":"","3":"0","4":"","5":"0","6":"0","7":"0","8":"0","9":"0","10":"0","11":[],"12":[],"13":[],"14":[],"15":[],"16":[],"17":[],"18":[],"19":[]},
                                "briefe":"",
                                "dateien":"",
                                "formular":[],
                                "fotos":"",
                                "gewerke": "",
                                "honorar": "",
                                "kosten": "",
                                "ordnerruecken":"",
                                "stammdaten": 
                                { "allgemein": 
                                    { "projekt_kat": "", "aktenz": "", "bv_status": "", "bv_typ": "", "bv_typ_sub": "", "bv_name": "", "bv_titel": "", "bv_anmerkungen": "" }, 
                                "bauherr": 
                                    { "id": "", "name": "" }, 
                                "adresse": 
                                    { "grundstueckID": "", "strasse": "", "plz": "", "ort": "", "kreis": "", "gemeindeteil": "", "gemarkung": "", "flur": "", "flurstueck": "" } 
                                },
                               
                           },
        }
    },


methods: {

    indexer(obj,i) {return obj[i]},

    byString(obj, is) {


        if (typeof is == 'string')
        return this.byString(obj,is.split('.'));

    else if (is.length==0)
        return obj;
    else
        return this.byString(obj[is[0]],is.slice(1));

        // return s.split('.').reduce((o,i)=> o[i], obj)

        // s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        // s = s.replace(/^\./, '');           // strip a leading dot
        // var a = s.split('.');
        // console.log(a)

        // for (var i = 0, n = a.length; i < n; ++i) {
        //     var k = a[i];
        //     if (k in o) {
        //         o = o[k];
        //     } else {
        //         return;
        //     }
        // }
        // return o;
    },

    findID(){
        var checkID = 0;

        if(this.projectDaten.formular.length>0){
            for(var index in this.projectDaten.formular)
            {
                if(this.projectDaten.formular[index].formularID>=checkID)
                checkID = parseInt(this.projectDaten.formular[index].formularID) + 1; //weiter number
                checkID = checkID.toString();
            }
        }
        else
        checkID = '1';

        return checkID
        
    },

    getKeyByValue(object, value) {
        // console.log("object ")
        // console.log(object)
        // console.log("value " + value)
        if(object)
        return Object.keys(object).find(key => object[key] === value);
    },
    
    searchAlleProjectForContacts(){

        for(var projekt of this.alleProjekte)
        {
            console.log(projekt.bv_name)
        }
    },

    searchKontaksInProject(aktPro){
         console.log(aktPro)
        var sammelArray = [];

        if(aktPro.ProjektBeteiligte){
            var beteiligte = aktPro.ProjektBeteiligte

            for (var bet in beteiligte) {

                // console.log(beteiligte[bet])

                if(Array.isArray(beteiligte[bet])){
                    // console.log("Array")
                    //WEITER auch ohne KontkaktID
                        for (var be of beteiligte[bet]){
                            
                            if(be.kontaktID){
                                if(sammelArray.includes(be.kontaktID)==false)
                                sammelArray.push({kontaktID:be.kontaktID, typ:bet})
                                // console.log(be.kontaktID)
                                // console.log("Array Zahl")
                            }
                           
                        }
                }
                else if(beteiligte[bet].length>0){

                    if(sammelArray.includes(beteiligte[bet])==false)
                    sammelArray.push({kontaktID:beteiligte[bet], typ:bet})
                }
                
                // console.log("Zahl")
            }

            

            for (var kont of sammelArray){
                
            var aktKont = this.getKontaktByID(kont.kontaktID)

                if(aktKont.projekte==undefined)
                aktKont.projekte=[];

            if(aktKont.projekte.includes(aktPro.projektID)==false)
                aktKont.projekte.push(aktPro.projektID)
                // console.log(kont)
                // console.log(aktKont.projekte)
                // console.log(aktPro.projektID)

            }
        }
        

    },

    searchKontakInProjects(kontaktID){
      console.log("searchKontakInProjects")
    //   console.log("kontaktID " + kontaktID)

        if(kontaktID){
            var zielkarte=null;
            var zielkarteIndex =  this.Addressbook.findIndex(item=>item.basic.kontaktID == kontaktID)
            var letztesI; 
            var insertObject;   
            var zwischenspeicher= [];    
            var folgendesI         
            if(zielkarteIndex>=0)
            zielkarte = this.Addressbook[zielkarteIndex]
            console.log(zielkarte)
                if(zielkarte){

                    zielkarte.projekte=[];

                    //this.speicher_kontakt_einzeln(zielkarte, 'searchKontakInProjects 300 / projektMixin')

                    for (var i=0; i < this.alleProjekte.length; i++) {

                        if(this.getKeyByValue(this.alleProjekte[i].projectContent.ProjektBeteiligte, kontaktID)){

                            for (let key in this.alleProjekte[i].projectContent.ProjektBeteiligte) {
                                if(this.alleProjekte[i].projectContent.ProjektBeteiligte[key]==kontaktID){

                                    zwischenspeicher.push(key);
                                    folgendesI = i+1
                                    letztesI = i;
                                }
                                
                            }

                            if(zwischenspeicher.length>0){
                                insertObject = [
                                    {"projectID":this.alleProjekte[i].projectID}, 
                                    {"projectName":this.alleProjekte[i].projectContent.stammdaten.allgemein.bv_name},
                                    {"was":zwischenspeicher},
                                ];  

                                zielkarte.projekte.push(insertObject)
                                
                               
                                insertObject = [];
                            }
                        }
                        zwischenspeicher = [];
                        
                    }

                   if(zielkarte.projekte.length>0)
                    this.speicher_kontakt_einzeln(zielkarte, 'searchKontakInProjects 336 / projektMixin')

                    
                }
                
                
        }
    },

    wechsel_zu_bauherr(){
        if(this.changeSingleProjectHappend)
        this.speicherAktuellesProjekt();
        
        this.clickOnName(this.projectDaten.ProjektBeteiligte[1])
    },
    
    legeNeuenKontaktan(woher) {
        if(woher){
        console.log("legeNeuenKontaktan in stammdaten")

        this.bearbeiten=true;

        this.KontaktDetail = JSON.parse(JSON.stringify(this.blancVcard));
        this.KontaktDetail_backup = JSON.parse(JSON.stringify(this.blancVcard));
        
        if(woher=="beteiligte")
            this.$root.$emit('openKontaktModalinBeteiligte');
        else if(woher=="stammdaten")
            this.$root.$emit('openKontaktModalinStammdaten');
        }
        //this.$root.$emit('legeNeuenKontaktan');
    },

    loadProject(projektID, woher){

         console.log("loadProject Nr. "+ projektID);


        if(projektID>=0 && projektID!=this.$route.params.id){


            if(this.alleProjekte.length>0){
                var pos = this.alleProjekte.findIndex(item=>item.projectID==projektID)

                if(pos>=0)
                this.projectDaten = this.alleProjekte[pos].projectContent;
                else
                this.projectDaten = JSON.parse(JSON.stringify(this.blancProject)); 


                if(!this.projectDaten.stammdaten.allgemein.bv_typ_sub)
                this.projectDaten.stammdaten.allgemein.bv_typ_sub = "";

                this.projectDaten.projektID = projektID;
                // console.log(this.projectDaten.ProjektBeteiligte);

                this.projektBeteiligteOnOpen = JSON.parse(JSON.stringify(this.projectDaten.ProjektBeteiligte));

                this.projectDatenBackup =  JSON.parse(JSON.stringify(this.projectDaten));

                //this.searchKontaksInProject(this.projectDaten);
                

                 if(woher==="projekte")
                    this.$router.push({ name: 'einzelprojekte', params: { id: projektID } });

                    

            }
        }
        else{
            this.projectDaten = JSON.parse(JSON.stringify(this.blancProject)); 
            this.projectDatenBackup =  JSON.parse(JSON.stringify(this.projectDaten));
        }


    },

    selectSpalten(){
         console.log("selectSpalten")
        
         var retunrnValue = []
         var actualIndex="";

                    if(this.projektEinstellungen.main.myFields){
        

                        this.projektEinstellungen.main.myFields.sort((a, b) => a - b);
                        // console.log(this.projektEinstellungen.main.myFields)

                        for (let i of this.projektEinstellungen.main.myFields) {
        
                            //  console.log(i)
                            //   console.log(this.possibleFields)
                                actualIndex = this.possibleFields.findIndex(item=>item.id==i);
                                // console.log("index" + actualIndex)
                                // console.log(retunrnValue)
                                if(actualIndex>=0)
                                    retunrnValue.push(this.possibleFields[actualIndex])
                                
                            }

                            // console.log(retunrnValue)
                            return retunrnValue
                           
        
                        }
        
        
                },

             setProjektEinstellungenMeth (projektEinstellungen) {
                this.axios.post(this.$store.state.url_projektEinstellungen, {			
                    besonders: this.$store.state.dev_sub,
                    was:'setProjektEinstellungen',
                    projektEinstellungen:projektEinstellungen,
    
                    }).then((response) => {
    
                    // response.data
    
                    })
                    .catch(function (error) {
                        // console.log("error")
                                // handle error
                                // console.log(error);
                    })
        },
    
        updateprojektEinstellungen () {
    

        if (this.isOnline) {

            return this.axios.post(this.$store.state.url_projektEinstellungen, {			
            besonders: this.$store.state.dev_sub,
            was:'getProjektEinstellungen',

            }).then((response) => {

                return response.data;

                var verschluesselt = simpleCrypto.encrypt(response.data)
                VueOfflineStorage.set('projektEinstellungen', verschluesselt)

            })
            .catch(function (error) {
                // console.log("error")
                        // handle error
                        // console.log(error);
            })
        }
        
        else 
        {
            if(VueOfflineStorage.get('projektEinstellungen') && VueOfflineStorage.get('onceTrueLogin')==true){

                const entschluesselt = simpleCrypto.decrypt(VueOfflineStorage.get('projektEinstellungen'))
                this.projektEinstellungen = entschluesselt;

               
            }
               
            else
                this.projektEinstellungen = []
        }
    
    },
    
    
    updateAlleProjekte () {
        console.log("updateAlleProjekte")
    //    console.log(this.isOnline)
      
        if (this.isOnline) {

            this.axios.post(this.$store.state.url_getProject, {			
            besonders: this.$store.state.dev_sub,
            was:'alle',

            }).then((response) => {
                
                this.alleProjekte = response.data;

                setTimeout(()=>{
                    this.projectsLoaded = true;
                },500);

            
                //console.log(document.cookie)

                var verschluesselt = simpleCrypto.encrypt(response.data)

                VueOfflineStorage.set('alleProjekte', verschluesselt)

                if(!this.projectDaten.projektID && this.$route.params.id && this.alleProjekte.length>0){
                    this.loadProject(this.$route.params.id);
                }

            })
            .catch(function (error) {
                // console.log("error")
                        // handle error
                        // console.log(error);
            })
        }
        
        else 
        {
            if(VueOfflineStorage.get('alleProjekte') && VueOfflineStorage.get('onceTrueLogin')==true){

                const entschluesselt = simpleCrypto.decrypt(VueOfflineStorage.get('alleProjekte'))
                this.alleProjekte = entschluesselt;

               
            }
               
            else
                this.alleProjekte = []
        }

    },

    speicherAktuellesProjekt(next, woher){

        console.log("speicherAktuellesProjekt");
        
        // console.log(this.projectDaten.ProjektBeteiligte);

        if(this.projectDaten.ProjektBeteiligte && this.$route.path.includes('/beteiligte')){
            var doubleArray = [];

            // console.log(this.projektBeteiligteOnOpen);

            for(var i in this.projektBeteiligteOnOpen)
            {
                // console.log(i)
                if(doubleArray.findIndex(was => was==this.projektBeteiligteOnOpen[i])==-1 && this.projektBeteiligteOnOpen[i]>=0)
                {
                    this.searchKontakInProjects(this.projektBeteiligteOnOpen[i]);
                }

                doubleArray.push(this.projektBeteiligteOnOpen[i])
            }

            for(var i in this.projectDaten.ProjektBeteiligte)
            {
                
               

                if(doubleArray.findIndex(was => was==this.projectDaten.ProjektBeteiligte[i])==-1 && this.projectDaten.ProjektBeteiligte[i]>=0)
                {
                    this.searchKontakInProjects(this.projectDaten.ProjektBeteiligte[i]);
                }

                doubleArray.push(this.projectDaten.ProjektBeteiligte[i])
            }

            this.projektBeteiligteOnOpen = JSON.parse(JSON.stringify(this.projectDaten.ProjektBeteiligte));


        }

        if(this.actualformular!=undefined && woher!="deleteForm"){

            // console.log(Array.isArray(this.projectDaten.formular));
            // console.log(this.projectDaten.formular);
                //findID
                if(this.actualformular.formularID==null ){

                    var newID = this.findID();
                    this.actualformular.formularID = newID;
                    this.projectDaten.formular.push(this.actualformular);

                }
                else if(this.actualformular.formularID>0 && Array.isArray(this.projectDaten.formular)){
                    console.log("prüfen ob es formularID schon in Projekt gibt")
                    var pos = ""

                    pos = this.projectDaten.formular.map(function(e) { return e.formularID; }).indexOf(this.actualformular.formularID);
                    
                    if(pos==-1){
                        var newID = this.findID();
                        this.actualformular.formularID = newID;
                        this.projectDaten.formular.push(this.actualformular);
                    }
                   

                }

                this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(this.actualformular));
                    
        } 



        this.loader_loading = true;

      
        if(!this.projectDaten.stammdaten.adresse.lat && (this.projectDaten.stammdaten.adresse.strasse || this.projectDaten.stammdaten.adresse.ort || this.projectDaten.stammdaten.adresse.plz)){

            var self = this
            this.getLanLng("de", this.projectDaten.stammdaten.adresse.strasse, this.projectDaten.stammdaten.adresse.plz, this.projectDaten.stammdaten.adresse.ort)
            
            .then(function (results) {
                // console.log(results)
                if(results.length!=[]){
                if(results[0].lat!=undefined)
                self.projectDaten.stammdaten.adresse.lat = results[0].lat
                if(results[0].lon!=undefined)
                self.projectDaten.stammdaten.adresse.lon = results[0].lon

                
                }

                self.saveNow(woher)
            });

        }
        else
        this.saveNow(woher, next)

    },

    saveNow(woher, next){

        console.log(this.projectDaten)
      if(this.projectDaten.stammdaten.allgemein.bv_name){
            this.axios.post(this.$store.state.url_set_project, {
                besonders: this.$store.state.dev_sub,
                projektID: this.$route.params.id,
                save:true,
                //aktuellesProjekt: stringProject
                aktuellesProjekt: this.projectDaten
                }).then((response) => {
                    this.loader_loading = false;

                    if(response.data.success===true )
                    {
                    // console.log("bis hier stimmt")
                        this.$root.$emit('bv::hide::tooltip')
                        if(woher!="deleteForm")
                        this.$toasted.show('Erfolgreich gespeichert.', {
                            position: 'top-center',
                            type:'success',
                            duration:1500,
                            containerClass:'ganzVorne'})
                        }
                        else
                        return true

                })

            this.projectDatenBackup = JSON.parse(JSON.stringify(this.projectDaten));
        }
        else{

            this.loader_loading = false;

            this.$toasted.show('Es muss ein Projekttitel eingegeben sein.', {
                position: 'top-center',
                type:'error',
                duration:1500,
                containerClass:'ganzVorne'})
                
        }




        if(woher==="seitenwechsel")
        next();


    },

    getLanLng(land="de", strasse, plz, ort){

        const ENDPOINT = `https://nominatim.openstreetmap.org/search`;
        const FORMAT = `json`;
       // const FORMAT = `jsonv2`;
        return this.axios.get("https://nominatim.openstreetmap.org/search",
            {
                  params: {
                    format:"json",
                    street:strasse,
                    city:ort,
                    country:land,
                    postalcode:plz,
                  }
              }).then((response) => {
            
                return response.data;
      
          })
          .catch(function (error) {
            return error;
        })

  
    },


    for_kontakt(value) {

            if(this.getKontaktByID(value)){
                return this.getKontaktByID(value).vollerName.anzeigeName
            }
                
            else
                return ""
        },

    for_bv_status_key(value) {

        if(this.bv_status_key[value])
            return `${this.bv_status_key[value]}`
        else
            return ""
    },


    for_bv_typ_options_key(value) {

        if(this.bv_typ_options_key[value])
            return `${this.bv_typ_options_key[value]}`
        else
            return ""
    },

    for_projekt_typ_options(value) {
        // console.log(this.projekt_typ_options_key[value])

        if(this.projekt_typ_options_key[value])
            return `${this.projekt_typ_options_key[value]}`
        else
            return ""
    },

    for_Verantwortl(value) {
        if(this.bauvorlageberechtigteKeys[value])
            return `${this.bauvorlageberechtigteKeys[value]}`
        else
            return ""
    },


    for_bauherren(value) {
        if(this.bauherrenKeys[value])
            return `${this.bauherrenKeys[value]}`
        else
            return ""
    },

    for_Planer(value) {
        if(this.planerKeys[value])
            return `${this.planerKeys[value]}`
        else
            return ""
    },


},


beforeRouteLeave (to, from, next) {
	// If the form is not dirty or the user confirmed they want to lose unsaved changes,
	// continue to next view
    let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'zurück',
        cancelText: 'okay, NICHT speichern und weiter',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'basic', // coming soon: 'soft', 'hard'
        verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: true, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '' // Custom class to be injected into the parent node for the current dialog instance
    };

    let message = {
        title: 'Ungespeicherte Änderungen',
        body: 'Wenn Sie die Änderungen nicht speichen, gehen diese verloren. Wollen Sie diese Seite wirklich verlassen, ohne zu speichern?'
      };

	if (this.changeSingleProjectHappend===true  && this.$route.params.id){

        var self = this;

        // this.$dialog
        // .confirm(message, options)
        // .then(function() {
        //    // console.log("speichern");
        //    // self.speicherAktuellesProjekt(next, 'seitenwechsel');
            
        // })
        // .catch(function() {
        //     self.projectDaten = JSON.parse(JSON.stringify(self.projectDatenBackup));
        //     next();
        // });

        next(); //darüber ein kommentieren, dass auskommentieren
		
	} else {
		// Cancel navigation
       // this.popoverShowProSpeichern=true;
        next()
		
	}
},

computed:{




    possibleFields:
    {
        get()
        {
            return this.$store.getters.possibleFields
        },
        set(value)
        {
            this.$store.commit('setpossibleFields', value);
        }
    },

    projektEinstellungen:
    {
        get()
        {
            return this.$store.getters.projektEinstellungen
        },
        set(value)
        {
            this.$store.commit('setprojektEinstellungen', value);
        }
    },



    projectsLoaded:
    {
        get()
        {
            return this.$store.getters.projectsLoaded
        },
        set(value)
        {
            this.$store.commit('setprojectsLoaded', value);
        }
    },

    statusArray:
    {
        get()
        {
            return this.$store.getters.statusArray
        },
        set(value)
        {
            this.$store.commit('setstatusArray', value);
        }
    },

    bv_typArray:
    {
        get()
        {
            return this.$store.getters.bv_typArray
        },
        set(value)
        {
            this.$store.commit('setbv_typArray', value);
        }
    },

    actualformular:
    {
        get()
        {
            return this.$store.getters.actualformular
        },
        set(value)
        {
            this.$store.commit('setactualformular', value);
        }
    },

    bv_status_key(){
        var returnValue ={};

            for (var item in this.auswahlListen.bv_status) {

                if(typeof parseInt(this.auswahlListen.bv_status[item].value) == 'number')
                returnValue[this.auswahlListen.bv_status[item].value] = this.auswahlListen.bv_status[item].text
            }

        // }
        return returnValue


    },

    

    bv_typ_options_key(){
        var returnValue ={};

        //for(var arrayIndex in this.projekt_typ_options)
        //console.log("bv_typ_options_key")


        for (var item in this.bv_typ_options) {
            // console.log(this.bv_typ_options[item]);

            if(this.bv_typ_options[item].options)
            {
                for (var itemsub in this.bv_typ_options[item].options) {
                    // console.log(itemsub);

                    if(typeof this.bv_typ_options[item].options[itemsub].value == 'number')
                    returnValue[this.bv_typ_options[item].options[itemsub].value] = this.bv_typ_options[item].options[itemsub].text
                }
            }
            else if(this.bv_typ_options[item].value)
            {
                if(typeof this.bv_typ_options[item].value == 'number')
                returnValue[this.bv_typ_options[item].value] = this.bv_typ_options[item].text
            }

        }

        return returnValue

        // for (const [key, value] of Object.entries(this.projekt_typ_options[0])) {
        //     console.log(`${key}: ${value}`);
        //   }
    },
    


    projekt_typ_options_key(){
        var returnValue ={};

        //for(var arrayIndex in this.projekt_typ_options)
        // console.log("projekt_typ_options_key")

        for (var item in this.projekt_typ_options) {
            // console.log(item);
             if(typeof this.projekt_typ_options[item].value == 'number')
             returnValue[this.projekt_typ_options[item].value] = this.projekt_typ_options[item].text
        }
        return returnValue

        // for (const [key, value] of Object.entries(this.projekt_typ_options[0])) {
        //     console.log(`${key}: ${value}`);
        //   }
    },

    bauvorlageberechtigteListe(){

        var returnValue=[]

        for(var prop of this.bauvorlageberechtigte){
            returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
        }
        return returnValue
            
                
    },

    
    entwurfsverfasserListe(){

        var returnValue=[]

        for(var prop of this.entwurfsverfasser){
            returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
        }
        return returnValue
            
                
    },

    BauleiterUndPlanerListe(){

        var returnValue=[]

        for(var prop of this.BauleiterUndPlaner){
            returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
        }
        return returnValue
        
    },


    planerKeys(){
        var returnValue ={};
        var name
        if(this.BauleiterUndPlaner){
            for (var varia of this.BauleiterUndPlaner) {

                if(varia.vollerName.vorname && varia.vollerName.nachname)
                    name = varia.vollerName.nachname + ", " + varia.vollerName.vorname.substring(0, 2) +'.';
                else if(varia.vollerName.nachname)
                    name = varia.vollerName.nachname
                else if(varia.vollerName.anzeigeName)
                    name = varia.vollerName.anzeigeName
                else
                    name = "";

                returnValue[varia.basic.kontaktID] = name
                
              }
        }

        return returnValue;
    },

    bauherrenGefiltert() {

        const search = this.searchTermBauherr.toLowerCase().trim();

        if (!search) 
            return this.bauherren;
        
        return this.bauherren.filter(c => c.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1);


    },

    
    projektBeteiligteOnOpen:
    {
        get()
        {
            return this.$store.getters.projektBeteiligteOnOpen
        },
        set(value)
        {
            this.$store.commit('setprojektBeteiligteOnOpen', value);
        }
    }, 
    Addressbook:
    {
        get()
        {
            return this.$store.getters.Addressbook
        },
        set(value)
        {
            this.$store.commit('setAddressbook', value);
        }
    }, 

    projectDaten:
    {
        get()
        {
            return this.$store.getters.actualProject
        },
        set(value)
        {
            this.$store.commit('setActualProject', value);
        }
    },

    bauaufsichten(){
        var returnValue=[];
        if(Array.isArray(this.Addressbook)==true)
        return this.Addressbook.filter(el => this.findCommonElements(el.art.summary, ["4"]));
        return returnValue;
    },

    bauherren(){
        var returnValue=[];

        if(Array.isArray(this.Addressbook)==true)
        returnValue = this.Addressbook.filter(el => this.findCommonElements(el.art.summary, ["1","2"]));
       
        return returnValue;
    },


    bauherrenKeys(){
        var returnValue ={};

        if(this.bauherren){
            for (var varia of this.bauherren) {
            //    console.log(varia.basic.kontaktID)
                returnValue[varia.basic.kontaktID] = varia.vollerName.anzeigeName
                
              }
        }

        return returnValue;
    },

    entwurfsverfasser(){
        var returnValue=[];
        if(Array.isArray(this.Addressbook)==true)
        returnValue = this.Addressbook.filter(el => this.findCommonElements(el.art.summary, ["11"]));
        return returnValue;
    },

    bauvorlageberechtigte(){
        var returnValue=[];
        if(Array.isArray(this.Addressbook)==true)
        returnValue = this.Addressbook.filter(el => this.findCommonElements(el.art.summary, ["10"]));
        return returnValue;
    },

    bauvorlageberechtigteKeys(){
        var returnValue ={};
        var name
        if(this.bauvorlageberechtigte){
            for (var varia of this.bauvorlageberechtigte) {
            //    console.log(varia.basic.kontaktID)

                if(varia.vollerName.vorname && varia.vollerName.nachname)
                    name = varia.vollerName.nachname + ", " + varia.vollerName.vorname.substring(0, 2) +'.';
                else if(varia.vollerName.nachname)
                    name = varia.vollerName.nachname
                else if(varia.vollerName.anzeigeName)
                    name = varia.vollerName.anzeigeName
                else
                    name = "";

                returnValue[varia.basic.kontaktID] = name


              }
        }

        return returnValue;
    },



	logObject(){
		return this.$store.getters.logObject;
	},


	changeSingleProjectHappend(){

        if(JSON.stringify(this.projectDaten) === JSON.stringify(this.projectDatenBackup)===true)
			return false;
		else
			return true;
    },
    


    projectDatenBackup:
    {
        get()
        {
            return this.$store.getters.projectDatenBackup
        },
        set(value)
        {
            this.$store.commit('setprojectDatenBackup', value);
        }
    },

    popoverShowProSpeichern: {
            get() {
                return this.$store.getters.popoverShowProSpeichern;
            },
            set(values) {
                this.$store.commit('setpopoverShowProSpeichern', values);
            }
        },



		alleProjekte:
		{
			get()
			{
				return this.$store.getters.alleProjekte
			},
			set(value)
			{
				this.$store.commit('setalleProjekte', value);
			}
        },



        glowSpeichern:
        {
            get()
            {
                return this.$store.getters.glowSpeichern
            },
            set(value)
            {
                this.$store.commit('setglowSpeichern', value);
            }
        }, 

},

mounted() {




    if(Object.keys(this.alleProjekte).length ===0){
        // console.log("updateAlleProjekte length");
        this.updateAlleProjekte();
    }     

    if(Object.keys(this.Addressbook).length ===0)
        this.updateAdressbook();
       
    if(Object.keys(this.Papierkorb).length ===0)
        this.updatePapierkorb();

    // window.addEventListener('beforeunload', (event) => {
       
    //     if (this.changeSingleProjectHappend===true){
    //         // Cancel the event as stated by the standard.
    //         event.preventDefault();
    //         // Chrome requires returnValue to be set.
    //         event.returnValue = '';
    //         this.glowSpeichern=true;

    //         setTimeout(()=>{
    //             this.glowSpeichern=false;
    //             },15000);

           
    //     }
    //   });

},

created(){


      


},



}




