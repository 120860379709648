
	import popoverFontSize from './../components/projekte/formulare/popoverFontSize.vue'
 	import FormularTextarea from './../components/projekte/formulare/FormularTextarea.vue'
 	import FormularInput from './../components/projekte/formulare/FormularInput.vue'
 	import { forAllMixin } from './forAllMixin.js'

export const formularMixin = {

    components: {
        popoverFontSize,
        FormularTextarea,
		FormularInput
	},
	mixins: [forAllMixin],

    data() {
        return {

			seitenrand_oben_mm : 16.6,
			seitenrand_links_mm : 16.75,
			bis: null,

        }
    },

    methods: {

		plus(was, richtung){

			setTimeout(()=>{
				this.actualformular.formularContent[this.actualformular.formularArt][was].showPop = true;	
			   },200);	

			if(richtung=='+')
			if(this.actualformular.formularContent[this.actualformular.formularArt][was].size<20)
			this.actualformular.formularContent[this.actualformular.formularArt][was].size++;

			if(richtung=='-')
			if(this.actualformular.formularContent[this.actualformular.formularArt][was].size>7)
			this.actualformular.formularContent[this.actualformular.formularArt][was].size--;

			this.bis = Math.trunc(((new Date()).getTime() + 1400)),
				
		   setTimeout(()=>{

			// console.log(this.bis)
			// console.log(Math.trunc((new Date()).getTime()))


			if(Math.trunc((new Date()).getTime()) >= this.bis){

				this.actualformular.formularContent[this.actualformular.formularArt][was].showPop = false;	

			}

		   },1500);

	   },


		pkwNeurechnen(){
			console.log("pkwNeurechnen")
		},
		
		pushBlancForm(neuesFormular)
		{
			// console.log("pushBlancForm")
			// console.log(neuesFormular)

			this.actualformular = neuesFormular;
			this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(neuesFormular));
		},

		gib_hoehe_in_mm(mm){
			return Math.round( (mm / this.gesamthoehe_mm) * this.gesamthoehe_px) + 1;
		},

        gib_breite_mm(breite){
			return Math.round( (breite / this.gesamtbreite_mm) * this.gesamtbreite_px);
		},

		gib_hoehe(nummer){
			nummer = nummer - 1;
			return Math.round( (this.hoehen_mm[nummer] / this.gesamthoehe_mm) * this.gesamthoehe_px) + 1;
		},
				
		gib_top(nummer){
			nummer = nummer - 1;
			let abstandoben= Math.round((this.seitenrand_oben_mm / this.gesamthoehe_mm) * this.gesamthoehe_px);
			var i;
			for (i = 0; i < nummer; i++) {
				abstandoben += (this.hoehen_mm[i] / this.gesamthoehe_mm) * this.gesamthoehe_px;
			}
			abstandoben = Math.round(abstandoben);

			return abstandoben;
		},

		gib_hoehe_2(nummer){
			nummer = nummer - 1;
			return Math.round( (this.hoehen_mm_page2[nummer] / this.gesamthoehe_mm) * this.gesamthoehe_px) + 1;
		},


		gib_top_2(nummer){
			nummer = nummer - 1;
			let abstandoben= Math.round((this.seitenrand_oben_mm / this.gesamthoehe_mm) * this.gesamthoehe_px);
			var i;
			for (i = 0; i < nummer; i++) {
				abstandoben += (this.hoehen_mm_page2[i] / this.gesamthoehe_mm) * this.gesamthoehe_px;
			}
			abstandoben = Math.round(abstandoben);

			return abstandoben;
        },
        
        gib_hoehe_und_top(nummer){
			console.log(nummer);

			nummer = nummer - 1;
			let hoehe=0
			let abstandoben= Math.round((this.seitenrand_oben_mm / this.gesamthoehe_mm) * this.gesamthoehe_px);
			console.log(abstandoben);


			var i;

			for (i = 0; i < nummer; i++) {
				console.log(i);
				abstandoben += (this.hoehen_mm[i] / this.gesamthoehe_mm) * this.gesamthoehe_px;

			}

			abstandoben = Math.round(abstandoben);

			hoehe = (this.hoehen_mm[nummer] / this.gesamthoehe_mm) * this.gesamthoehe_px;

			return {hoehe:hoehe.toFixed(0), abstandoben:abstandoben.toFixed(0) };


        },
        spalte_breit_mm(mm) {
            return this.gib_breite_mm(mm);
		},
		
        spalte_bindungen_breit(nr) {
            return this.gib_breite_mm(this.spalte[nr]);
        },
        
        
    },

    computed:{

		
		options_formulare_neu: {
			get() {
				return this.$store.getters.options_formulare_neu;
			},
			set(values) {
				this.$store.commit('setoptions_formulare_neu', values);
			}
		},


	currentTabComponent:
	{
		get()
		{
			return this.$store.getters.currentTabComponent
		},
		set(value)
		{
			this.$store.commit('setcurrentTabComponent', value);
		}
	},
		showFontPopover:
		{
			get()
			{
				return this.$store.getters.showFontPopover
			},
			set(value)
			{
				this.$store.commit('setshowFontPopover', value);
			}
		},


        // getNameOfActualFormular(){

        //     if(this.actualformular){
        //             for (var prop in this.actualformular.formularContent) 
        //                     return prop;
        //     }
        // },

			actualformular: {
				get() {
					return this.$store.getters.actualformular;
				},
				set(values) {
					this.$store.commit('setactualformular', values);
				}
			},
        

        
		spalte_bindungen_breit_1_com() {
            return this.gib_breite_mm(this.spalte_bindungen_breit_1);
		},
		spalte_bindungen_breit_2_com() {
            return this.gib_breite_mm(this.spalte_bindungen_breit_2);
		},
		spalte_bindungen_breit_3_com() {
            return this.gib_breite_mm(this.spalte_bindungen_breit_3);
		},
		spalte_bindungen_breit_4_com() {
            return this.gib_breite_mm(this.spalte_bindungen_breit_4);
		},
		spalte_bindungen_breit_5_com() {
            return this.gib_breite_mm(this.spalte_bindungen_breit_5);
        },
        		
		// Breiten
		seitenrand_links(){
			return Math.round((this.seitenrand_links_mm / this.gesamtbreite_mm) * this.gesamtbreite_px); 
		},

		linke_spalte_breit(){
			return Math.round((this.linkes_feld_breite_mm / this.gesamtbreite_mm) * this.gesamtbreite_px); 
		},

		mittellinie(){
			return Math.round(this.seitenrand_links + this.linke_spalte_breit -1); 
		},

		rechte_spalte_breit(){
			return Math.round((this.rechtes_feld_breite_mm / this.gesamtbreite_mm) * this.gesamtbreite_px); 
		},

		// Höhen
		seitenrand_oben(){
			return Math.round((this.seitenrand_oben_mm / this.gesamthoehe_mm) * this.gesamthoehe_px); 
        },
        




        
    },

    created(){



    },

}