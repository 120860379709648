<template>
  <div>
    <div class="container">

        <p class="mt-5">
          <b-button class="mr-2"  @click="startCapture">starte Bildschirm teilen</b-button>
          <b-button  @click="stopCapture">stoppe Bildschirm teilen</b-button>
        </p>

        <video id="video" autoplay></video>
        <br>

    </div>

  </div>
</template>

<script>


export default {

  data () {
    return {
      videoElem:'',
      displayMediaOptions : {
        video: {
          cursor: "always"
        },
        audio: false
      }

    }
  },

	mounted() {
    this.videoElem = document.getElementById("video");
  },

  methods: {

    async startCapture() {

      console.log(this.videoElem);

      try {
        this.videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(this.displayMediaOptions);
        this.dumpOptionsInfo();
      } catch(err) {
        console.error("Error: " + err);
      }
    },

    dumpOptionsInfo() {
      const videoTrack = this.videoElem.srcObject.getVideoTracks()[0];
    
      console.info("Track settings:");
      console.info(JSON.stringify(videoTrack.getSettings(), null, 2));
      console.info("Track constraints:");
      console.info(JSON.stringify(videoTrack.getConstraints(), null, 2));
    },

    stopCapture(evt) {
      let tracks = this.videoElem.srcObject.getTracks();

      tracks.forEach(track => track.stop());
      this.videoElem.srcObject = null;
    }
  }
}
</script>

<style scoped >

#video {
  border: 1px solid #999;
  width: 98%;
  max-width: 860px;
}

.error {
  color: red;
}

.warn {
  color: orange;
}

.info {
  color: darkgreen;
}

</style>
