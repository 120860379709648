<template>
	<div>
		<div class="kontainer">

			<div class='welcome mb-4'> <h5>ArchiApp kann...</h5></div>

<div class="start-cards">
  <!-- <b-card-group deck> -->

    <b-card
      header-tag="header"
	  class="mb-4"
    >
	  <template v-slot:header>
        <h5 class="mb-0">... für alle</h5>
      </template>

      <b-card-text>
			<b-list-group>
				<b-list-group-item>Adressbuch</b-list-group-item>
				<b-list-group-item>Kalendar</b-list-group-item>
				<b-list-group-item>Online-Kommunikation</b-list-group-item>
				<b-list-group-item>Dateien mit anderen teilen</b-list-group-item>
			</b-list-group>
		  </b-card-text>
    </b-card>


    <b-card
	class="mb-4"
      header-tag="header"
    >
	  <template v-slot:header>
        <h5 class="mb-0">... für Architektur- und Ingeniuerbüros</h5>
      </template>

      <b-card-text>
			<b-list-group>
							<b-list-group-item>Honorarberechnung nach HOAI</b-list-group-item>
							<b-list-group-item>Kostenschätzung</b-list-group-item>
							<b-list-group-item>Formulare für Bauanträge / Bauvoranfragen</b-list-group-item>
							<b-list-group-item>Leistungsverzeichnisse</b-list-group-item>
							<b-list-group-item>Ausschreibungen</b-list-group-item>
							<b-list-group-item>Bauablaufplan</b-list-group-item>
							<b-list-group-item>Bautagebuch</b-list-group-item>
			</b-list-group>
		  </b-card-text>
    </b-card>

    <b-card
      header-tag="header"
	  class="mb-4"
    >
	  <template v-slot:header>
        <h5 class="mb-0">... für Handwerksunternehmen</h5>
      </template>

      <b-card-text>
			<b-list-group>
							<b-list-group-item>Angebote erstellen</b-list-group-item>

			</b-list-group>
		  </b-card-text>
    </b-card>


  <!-- </b-card-group> -->
</div>

		</div>
	</div>
</template>

<script>

export default {

    data(){
        return{
        }
    },
	methods:{

	},
	created(){

	}
	
	//
}
</script>

<style >
.start-cards{

	min-width: 350px;
	max-width: 650px;
}
</style>
<style scoped>

.welcome
{
	font-size: 1.3em;
	font-weight: 400;
	letter-spacing: 2px;
	color:#000;

}


.welcomeunterschrift
{
	font-size: 1.1em;
	font-weight: 100;
	letter-spacing: 1px;
	color:#3d3d3d;
}
</style>