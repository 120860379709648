<template>
	<div>
       <div class="kontainer">

				<h1>meine Einstellungen</h1>
				<p>Text</p>

				<!-- <b-form-file
                
                v-model="eingabe"
                :state="Boolean(eingabe)"
                placeholder="XYZ Datei auswählen"
                drop-placeholder="ziehe die Datei hier rüber..."
            ></b-form-file> -->

			<input type="file" id="file-input"><br>
			<p class="content"></p>

			<b-button v-if="ok" @click="berechnen" variant="outline-primary" >berechnen</b-button>


			<b-button @click="ausfuellen" variant="outline-primary" >ausfuellen</b-button>


<button v-if="ausgabe.length>0" type="button" class="btn btn-info action_btn" v-on:click="downloadCSVData">
      Download
</button>
{{coords[0]}}
<b-form-select class="mb-4" v-model="selectedEcke" :options="options"></b-form-select>
<!-- {{coords}}
{{selectedEcke}} -->
<!-- <b-input type="number" v-model="obenlinks1"></b-input> -->
<span v-if="selectedEcke">

	<b-row  class="my-1" v-for="rowsa  in corrds_linksoben" :key="rowsa.name">
		<b-col sm="3">
			<label :for="rowsa.name">{{rowsa.name}}</label>
		</b-col>
		<b-col sm="6">
			<b-input :id="rowsa.name" type="number" v-model="rowsa.value"></b-input>
		</b-col>
	</b-row>
	
</span>
		<!-- {{ok}} -->
			{{eingabe.substring(0,80)}}
				<!-- <b-input v-model="eingabe"></b-input>
				<b-input v-model="ausgabe"></b-input> -->
        </div>
	</div>
</template>

<script>


	  const reader = new FileReader();

export default {

    data(){
        return{

			corrds_linksoben : [{name:'obenlinks E waagerecht', value:0}, {name:'obenlinks N seknrecht', value:0}, {name:'untenrechts E', value:0}, {name:'untenrechts N', value:0}],
			corrds_rechtsoben : [{name:'obenrecht E waagerecht', value:0}, {name:'obenrecht N seknrecht', value:0}, {name:'untenlinks E', value:0}, {name:'untenlinks N', value:0}],

			ausgabe:[],
			eingabe:'',
			ok: false,
			selectedEcke: null,
        options: [
          { value: "linksoben", text: 'links oben und rechts unten' },
          { value: 'rechtsoben', text: 'rechts oben und links unten' },
        ],
        }
    },
	methods:{

	downloadCSVData() {
		let csv = '';

		this.ausgabe.forEach((row) => {
				csv += row;
				csv += "\n";
		});
	
		const anchor = document.createElement('a');
		anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
		anchor.target = '_blank';
		anchor.download = 'ausschnitt.xyz';
		anchor.click();
},


ausfuellen(){

this.corrds_linksoben = [{name:'obenlinks E waagerecht', value:361930}, {name:'obenlinks N seknrecht', value:5651150}, {name:'untenrechts E', value:361950}, {name:'untenrechts N', value:5651100}]


},
	berechnen(){

if(this.corrds_linksoben[0].value > this.corrds_linksoben[2].value)
alert("obenlinks E muss kleiner sein als untenrechts E")

// if(this.corrds_linksoben[1].value > this.corrds_linksoben[3].value)
// alert("obenlinks N muss kleiner sein als untenrechts N")

		const myArr = this.eingabe.split("\n");
		//console.log(myArr)
		//console.log(`${key}: ${value}`);
		var returnarray=[]
		for (const [key, value] of Object.entries(myArr)) {
			//value = value.replace('\r','');
			var smarry = value.split(" ");

			if(key<100){

			// 	console.log(this.corrds_linksoben)
			console.log(smarry[0])
			console.log(smarry[1])
			console.log(this.corrds_linksoben[0].value)
			console.log(this.corrds_linksoben[1].value)
			console.log(this.corrds_linksoben[2].value)
			console.log(this.corrds_linksoben[3].value)

				console.log(smarry[0]>=this.corrds_linksoben[0].value)
				console.log(smarry[0]<=this.corrds_linksoben[2].value)
				console.log(smarry[1]>=this.corrds_linksoben[1].value)
				console.log(smarry[1]<=this.corrds_linksoben[3].value)
			}
			// smarry[0] == 375 / smarry[1] == 565
			// corrds_linksoben[0] == 375 / corrds_linksoben[1] = 565

// 			s0 = 375000.00
// 			s1 = 5652999
// 375000 
// 5652494 
// 375955 
// 5652537
			if(smarry[0]>=this.corrds_linksoben[0].value && smarry[0]<=this.corrds_linksoben[2].value 
			&& smarry[1]<=this.corrds_linksoben[1].value && smarry[1]>=this.corrds_linksoben[3].value)
				
				this.ausgabe.push(value)
				//this.ausgabe.push(smarry[0] +" " + Math.abs(smarry[1] - this.corrds_linksoben[3].value) + " " + smarry[2])
			
		}

				console.log(this.ausgabe)

		},
	},
	computed:{

				

coords(){

	if(this.selectedEcke == 'linksoben')
	return [{name:'obenlinks E waagerecht', value:0}, {name:'obenlinks N seknrecht', value:0}, {name:'untenrechts E', value:0}, {name:'untenrechts N', value:0}]
	else
	return [{name:'obenrechts E waagerecht', value:0}, {name:'obenrechts N seknrecht', value:0}, {name:'untenlinks E', value:0}, {name:'untenlinks N', value:0}]

}
// ok(){

// 	if(reader.readyState == 2)
// 	return true
// 	else
// 	return false

// }


	},

  mounted() {



	  var inputF = ""
		inputF = document.getElementById('file-input');
		var self = this
		inputF.addEventListener(
			"change",
			(e) => {
			const file = e.target.files[0];
            
  			const content = document.querySelector('.content');
			  reader.addEventListener("load", () => {
					// this will then display a text file
					//content.innerText = reader.result;
					self.eingabe = reader.result;
				}, false);

			  if (file) {
				  
				//  reader.readAsArrayBuffer(file);
			    reader.readAsText(file);

				reader.onloadend = function () {
					self.ok = true
				};

			  }

    },
    false
  );
	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
