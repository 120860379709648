<template>
<span>


	<div  ref="divSubnavFormular" class="subnav">
    <hr class="my-1">

      <b-row >

            <b-col v-if="loading==false" cols="1" style="min-width:180px">
                Hintergrundfarbe:
                <b-form-input value="#caf4ff"  @input="changeBackground" v-model="backgroudColor" type="color"></b-form-input>
            </b-col>
            <b-col v-if="loading==false" cols="1">
               Sonne:
              <b-form-checkbox v-model="backgroudColor" ></b-form-checkbox>

              <!-- <div id="my-gui-container"></div> -->
          </b-col>

          <b-col cols="4" >
            Bedienung: <div > Maustaste = Modell drehen, rechte Maustaste = Modell verschieben, Mausrad = vergrößern / verkleinern
              </div>
            </b-col>

          <b-col style="min-width:180px" cols="2" >
            Datei wählen: 
            
            <b-form-file
                id="file-input"
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="IFC Datei auswählen"
                drop-placeholder="ziehe die Datei hier rüber..."
            ></b-form-file>

          </b-col>

        </b-row >
  </div>
<div ref="divSubnavShadowFormular" class="subnav_shadow"></div>

    <div :class="{'kontainer':!itsMobile}" :style="{ height: hereHeight }">
      <!-- {{hereHeight}} -->
      <!-- {{directionalLightPos}} -->
<!-- {{directionalLight.target}}
{{directionalLight.position.y}} -->


 <!-- <input type="file" id="file-input"> -->


      <span v-if="!itsMobile">
      

      </span>

    <b-row v-if="itsMobile" class="m-1 mb-2">
        <b-col cols="2">
          <span class="h4">3d - Modell</span>
        </b-col>


        <b-col cols="6">

						<b-button @click="erklarung = !erklarung" variant="success" >Erklärungen			
               <span v-if="erklarung">aus</span>
               <span v-else>ein</span>
               </b-button>	

        </b-col>

    </b-row>

      <b-row v-if="itsMobile && erklarung" class="m-1 mb-2">
        <b-col cols="12">
          	<p >1 Finger über Modell streichen = Modell drehen</p>
          	<p >2 Finger parallel über Modell streichen = Modell verschieben</p>
            <p >2 Finger zueinander / auseinander = vergrößern / verkleinern</p>         
        </b-col>
    </b-row>
    
<div class="card" v-draggable="draggableValue">
      <div :ref="handleId">
        <b-icon-arrows-move></b-icon-arrows-move>
    </div>

    Sonne 
    <b-row v-if="directionalLight">
          <!-- <b-col cols="2">
              <b-form-input step="0.1" min="0" max="1" @input="changeLight" 
              v-model="directionalLightPos.eins"  type="range"></b-form-input>
          </b-col>
          <b-col cols="2">
              <b-form-input step="0.1" min="0" max="1" @input="changeLight" 
              v-model="directionalLightPos.zwei"  type="range"></b-form-input>
          </b-col>

          <b-col cols="2">
              <b-form-input step="0.1" min="0" max="1" @input="changeLight" 
              v-model="directionalLightPos.drei"  type="range"></b-form-input>
          </b-col>
-->
          <b-col cols="2">
                <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
                v-model="directionalLight.position.x"  type="range"></b-form-input>
                 <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
                v-model="directionalLight.position.x"  type="number"></b-form-input>
          </b-col>

          <b-col cols="2">
              <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
              v-model="directionalLight.position.z"  type="range"></b-form-input>
                            <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
              v-model="directionalLight.position.z"  type="number"></b-form-input>
          </b-col> 

          <b-col cols="2">
              <b-form-input step="0.1" min="0" max="100" @input="updateLight" 
              v-model="directionalLight.position.y"  type="range"></b-form-input>
                            <b-form-input step="0.1" min="0" max="100" @input="updateLight" 
              v-model="directionalLight.position.y"  type="number"></b-form-input>
          </b-col>

          <b-col cols="2">
                <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
                v-model="directionalLight.target.position.x"  type="range"></b-form-input>
                          <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
                v-model="directionalLight.target.position.x"  type="number"></b-form-input>
          </b-col>

          <b-col cols="2">
              <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
              v-model="directionalLight.target.position.z"  type="range"></b-form-input>
                            <b-form-input step="0.1" min="-100" max="100" @input="updateLight" 
              v-model="directionalLight.target.position.z"  type="number"></b-form-input>
          </b-col> 

          <b-col cols="2">
              <b-form-input step="0.1" min="0" :max="directionalLight.position.y" @input="updateLight" 
              v-model="directionalLight.target.position.y"  type="range"></b-form-input>
                            <b-form-input step="0.1" min="0" max="100" @input="updateLight" 
              v-model="directionalLight.target.position.y"  type="number"></b-form-input>
          </b-col>

      </b-row >

 </div>


    <canvas style="margin-top:50px; border:1px solid black; height:100%; width:100%" id="three-canvas"></canvas>
    <!-- <div style="margin-top:50px; border:1px solid black; height:100%; width:100%" id="three-canvas"></div> -->

    	<!-- <div style="margin-top:50px; border:1px solid black; height:100%; width:100%" id="modell"> -->

        		<b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <span class="ml-4" v-if="loading">Das Modell wird geladen. Einen Moment Geduld bitte.</span>
  </div>
		 

</span>
</template>




<script>

			import * as THREE from 'three';

// import {
//   EdgesGeometry,
//   LineBasicMaterial,
//   LineSegments,
//     AmbientLight,
//     AxesHelper,
//     DirectionalLight,
//     GridHelper,
//     PerspectiveCamera,
//     Scene,
//     MeshLambertMaterial,
//     Raycaster,
//     Vector2,
//     WebGLRenderer,
//     Color
// } from "three";


// let   EdgesGeometry,
//   LineBasicMaterial,
//   LineSegments,
//     AmbientLight,
//     AxesHelper,
//     DirectionalLight,
//     GridHelper,
//     PerspectiveCamera,
//     Scene,
//     MeshLambertMaterial,
//     Raycaster,
//     Vector2,
//     WebGLRenderer,
//     Color;

    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
    import { IFCLoader } from "web-ifc-three/IFCLoader";
    import { GUI } from 'three/examples/jsm/libs/dat.gui.module'
  import { Draggable } from 'draggable-vue-directive'
    import {
        acceleratedRaycast,
        computeBoundsTree,
        disposeBoundsTree
    } from 'three-mesh-bvh';

//Sets up the IFC loading
const ifcLoader = new IFCLoader();
ifcLoader.ifcManager.setWasmPath("../files/");

// Sets up optimized picking
ifcLoader.ifcManager.setupThreeMeshBVH(
    computeBoundsTree,
    disposeBoundsTree,
    acceleratedRaycast);

const raycaster = new THREE.Raycaster();
raycaster.firstHitOnly = true;
const mouse = new THREE.Vector2();



// Creates subset materials
const preselectMat = new THREE.MeshLambertMaterial({
    transparent: true,
    opacity: 0.6,
    color: 0xff88ff,
    depthTest: false
})

const selectMat = new THREE.MeshLambertMaterial({
    transparent: true,
    opacity: 0.6,
    color: 0xff00ff,
    depthTest: false
})

const ifc = ifcLoader.ifcManager;
// References to the previous selections
const highlightModel = { id: - 1};
const selectModel = { id: - 1};


 class ColorGUIHelper {
    constructor(object, prop) {
      this.object = object;
      this.prop = prop;
    }
    get value() {
      return `#${this.object[this.prop].getHexString()}`;
    }
    set value(hexString) {
      this.object[this.prop].set(hexString);
    }
  }



  

  

    function modellLaden(){

        // modell laden
        ifcLoader.load("../static/Wilhem.ifc", (ifcModel) => {
            // this.modells.push(ifcModel.mesh);
            // this.scene.add(ifcModel.mesh)
        });

    }

export default {
  name: 'ThreeDModell',
  directives: {
            Draggable,
  },
  data() {
    return {
      handleId: "handle-id",
      draggableValue: {
          handle: true
      },
      file1: null,
      camera: null,
      scene: null,
      renderer: null,
      threeCanvas: null,
     	mesh: null,
		  loading: false,
		  backgroudColor:'#caf4ff',
      modells:[],
      modellsLocal:null,
      modell:null,
      erklarung:true,
      directionalLight:{position:{y:15, x:0, z:0}, target:{position:{y:0, x:0, z:0}}},
      // directionalLightPos:{
      //   eins:0.5,
      //   zwei:0.5,
      //   drei:0.5,
      // },
      // directionalLightTarg:{
      //   eins:0.5,
      //   zwei:0.5,
      //   drei:0.5,
      // },
      helper:null,
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },

  computed: {

	hereHeight() {
		  var hierHeight = "500 px";

		  if(this.itsMobile)
		  hierHeight = this.window.height - 50 + 'px'
		  else
		  hierHeight = this.window.height - 170 + 'px'

		  return hierHeight;
 	  },

  	hereWidth() {
		  var hereWidth = "500 px";
		  hereWidth = this.window.width + 'px'

		  return hereWidth;
 	  },

  },


  methods: {

    calculateHeight() {

            if(this.$refs.divSubnavFormular){
                this.$refs.divSubnavShadowFormular.style.height = this.$refs.divSubnavFormular.clientHeight+'px';
            }

		},

    // changeLight(){
    //     this.directionalLight.position.set(this.directionalLightPos.eins, this.directionalLightPos.zwei, this.directionalLightPos.drei);
    //     this.directionalLight.target.position.set(this.directionalLightTarg.eins, this.directionalLightTarg.zwei, this.directionalLightTarg.drei);
    // },
        changeBackground(){

       this.scene.background = new THREE.Color(this.backgroudColor);
    
    },

 cast(event) {

// console.log(event)

    // Computes the position of the mouse on the screen
    const bounds = this.threeCanvas.getBoundingClientRect();

// console.log(bounds)

    const x1 = event.clientX - bounds.left;
    const x2 = bounds.right - bounds.left;
    mouse.x = (x1 / x2) * 2 - 1;

    const y1 = event.clientY - bounds.top;
    const y2 = bounds.bottom - bounds.top;
    mouse.y = -(y1 / y2) * 2 + 1;

    // Places it on the camera pointing to the mouse
    raycaster.setFromCamera(mouse, this.camera);

// console.log(raycaster)

    // Casts a ray
    return raycaster.intersectObjects(this.modells);
},

highlight(event, material, model) {
  // console.log("highlight")
  // console.log(event)
  // console.log(material)
  // console.log(model)

    const found = this.cast(event)[0];

      // console.log(found)
    if (found) {

        // Gets model ID
        model.id = found.object.modelID;  

        // Gets Express ID
        const index = found.faceIndex;
        const geometry = found.object.geometry;
        const id = ifc.getExpressId(geometry, index);

        // Creates subset
        ifcLoader.ifcManager.createSubset({
            modelID: model.id,
            ids: [id],
            material: material,
            scene: this.scene,
            removePrevious: true
        })
    } else {
        // Remove previous highlight
        ifc.removeSubset(model.id, this.scene, material);
    }
},

    // const color = 0xFFFFFF;
    // const intensity = 1;
    // const light = new THREE.DirectionalLight(color, intensity);
    // light.position.set(0, 10, 0);
    // light.target.position.set(-5, 0, 0);
    // scene.add(light);
    // scene.add(light.target);

    // const helper = new THREE.DirectionalLightHelper(light);
    // scene.add(helper);

    updateLight() {
      if(this.directionalLight.position.y < this.directionalLight.target.position.y)
      this.directionalLight.target.position.y = this.directionalLight.position.y
      
      this.directionalLight.target.updateMatrixWorld();
      this.helper.update();
    },
    

  // makeXYZGUI(gui, vector3, name, onChangeFn) {
  //   const folder = gui.addFolder(name);
  //   folder.add(vector3, 'x', -10, 10).onChange(onChangeFn);
  //   folder.add(vector3, 'y', 0, 20).onChange(onChangeFn);
  //   folder.add(vector3, 'z', -10, 10).onChange(onChangeFn);
  //   folder.open();
  // },


    init: function() {
        

        this.scene = new THREE.Scene();

        this.threeCanvas = document.getElementById("three-canvas");

        this.camera = new THREE.PerspectiveCamera(75, this.threeCanvas.clientWidth/this.threeCanvas.clientHeight);
       
        this.camera.position.z = 30;
        this.camera.position.y = 13;
        this.camera.position.x = 8;

		    this.scene.background = new THREE.Color( this.backgroudColor );

        const lightColor = 0xffffff;

        const ambientLight = new THREE.AmbientLight(lightColor, 0.5);
        this.scene.add(ambientLight);



        this.directionalLight = new THREE.DirectionalLight(lightColor, 1);
        this.directionalLight.position.set(0, 15, 0);
        this.directionalLight.target.position.set(0, 0, 0);

        this.scene.add(this.directionalLight);
        this.scene.add(this.directionalLight.target);

        this.helper = new THREE.DirectionalLightHelper( this.directionalLight, 2, 0x000 );
        this.scene.add( this.helper );

        this.updateLight();

        // const gui = new GUI({ autoPlace: false });

        //gui.addColor(new ColorGUIHelper(this.directionalLight, 'color'), 'value').name('color');
       
      //  gui.add(this.directionalLight, 'intensity', 0, 2, 0.01);

        // this.makeXYZGUI(gui, this.directionalLight.position, 'position', this.updateLight);
        // this.makeXYZGUI(gui, this.directionalLight.target.position, 'target', this.updateLight);

      // var customContainer = document.getElementById('my-gui-container');
      // customContainer.appendChild(gui.domElement);


        this.renderer = new THREE.WebGLRenderer({canvas: this.threeCanvas, alpha: true});
        this.renderer.setSize(this.threeCanvas.clientWidth, this.threeCanvas.clientHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));



        //Creates grids and axes in the scene
        const grid = new THREE.GridHelper(100, 100);
        this.scene.add(grid);

        const axes = new THREE.AxesHelper();
        axes.material.depthTest = false;
        axes.renderOrder = 1;
        this.scene.add(axes);

        //Creates the orbit controls (to navigate the scene)
        const controls = new OrbitControls(this.camera, this.threeCanvas);
        controls.enableDamping = true;
        controls.target.set(-2, 0, 0);

      //Animation loop
      const animate = () => {
          controls.update();
          this.renderer.render(this.scene, this.camera);
          requestAnimationFrame(animate);
      };

      animate();

//  modellLaden();

    },

    // animate: function() {
    //      requestAnimationFrame(this.animate);
    //     // this.mesh.rotation.x += 0.01;
    //     // this.mesh.rotation.y += 0.02;
    //     this.renderer.render(this.scene, this.camera);
    // }
  },

  mounted() {

    		window.addEventListener('resize', this.calculateHeight)

this.draggableValue.handle = this.$refs[this.handleId];
//window.onmousemove = (event) => this.highlight(event, preselectMat, highlightModel);

window.onmousemove = (event) => this.highlight(event, preselectMat, highlightModel);

window.ondblclick = (event) => this.highlight(event, selectMat, selectModel);


    window.addEventListener("resize", () => {
    console.log("rezise")
      this.size.width = window.innerWidth
      this.size.height = window.innerHeight

      this.camera.aspect = this.threeCanvas.clientWidth / this.threeCanvas.clientHeight ;

      this.camera.updateProjectionMatrix();

      this.renderer.setSize(this.threeCanvas.clientWidth, this.threeCanvas.clientHeight);
    });

// window.addEventListener("resize", () => {

//   (size.width = window.innerWidth), (size.height = window.innerHeight);
//   camera.aspect = size.width / size.height;
//   camera.updateProjectionMatrix();
//   renderer.setSize(size.width, size.height);
// });

      this.init();
      //this.animate();
     

  var inputF = ""
  inputF = document.getElementById('file-input');
 var self = this
  inputF.addEventListener(
    "change",
    (changed) => {
      const ifcURL = URL.createObjectURL(changed.target.files[0]);

        ifcLoader.load(ifcURL, (ifcModel) => {
            this.modells.push(ifcModel.mesh);
            this.scene.add(ifcModel.mesh)

            // var geometry = new EdgesGeometry( ifcModel.mesh.geometry );

            // var material = new LineBasicMaterial( { color: 0xffffff } );

            // var wireframe = new LineSegments( geometry, material );

            // this.scene.add( wireframe );

        });

      //ifcLoader.load(ifcURL, (ifcModel) =>  ({self.scene.add(ifcModel.mesh) self.modells.push(ifcModel.mesh)})

     // );
    },
    false
  );

  },

  	destroyed() {
        window.removeEventListener('resize', this.calculateHeight)
	},

}
</script>