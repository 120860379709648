<template>

    <div :class="{'kontainer':!itsMobile}" :style="{ height: hereHeight }">
      <!-- {{hereHeight}} -->

 <!-- <input type="file" id="file-input"> -->
<b-form-file
        id="file-input"
        v-model="file1"
      :state="Boolean(file1)"
      placeholder="IFC Datei auswählen"
      drop-placeholder="ziehe die Datei hier rüber..."
    ></b-form-file>

      <span v-if="!itsMobile">
        <b-row >
      <b-col cols="2" class="h3">3d - Modell</b-col>

            <b-col v-if="loading==false" cols="3">
                Hintergrundfarbe:
                <b-form-input value="#caf4ff"  @input="changeBackground" v-model="backgroudColor" type="color"></b-form-input>
            </b-col>

          <b-col cols="7" class="h5">linke Maustaste = Modell drehen, rechte Maustaste = Modell verschieben, Mausrad = vergrößern / verkleinern</b-col>

        </b-row >

      </span>

    <b-row v-if="itsMobile" class="m-1 mb-2">
        <b-col cols="2">
          <span class="h4">3d - Modell</span>
        </b-col>


        <b-col cols="6">

						<b-button @click="erklarung = !erklarung" variant="success" >Erklärungen			
               <span v-if="erklarung">aus</span>
               <span v-else>ein</span>
               </b-button>	

        </b-col>

    </b-row>

      <b-row v-if="itsMobile && erklarung" class="m-1 mb-2">
        <b-col cols="12">
          	<p >1 Finger über Modell streichen = Modell drehen</p>
          	<p >2 Finger parallel über Modell streichen = Modell verschieben</p>
            <p >2 Finger zueinander / auseinander = vergrößern / verkleinern</p>         
        </b-col>
    </b-row>
    
    	<div style="margin-top:50px; border:1px solid black; height:100%; width:100%" id="modell">

        		<b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <span class="ml-4" v-if="loading">Das Modell wird geladen. Einen Moment Geduld bitte.</span>
      </div>
		 

	</div>


</template>


<script>
    import * as THREE from 'three'
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
    import { IFCLoader } from "web-ifc-three/IFCLoader";

const ifcLoader = new IFCLoader();
ifcLoader.ifcManager.setWasmPath("../files/");

export default {
  name: 'ThreeDModell',
  data() {
    return {
           file1: null,
      camera: null,
      scene: null,
      renderer: null,
     	mesh: null,
		  loading: false,
		  backgroudColor:'#caf4ff',
      modells:[],
      modellsLocal:null,
      modell:null,
      erklarung:true,
    }
  },

  computed: {

	hereHeight() {
		  var hierHeight = "500 px";

		  if(this.itsMobile)
		  hierHeight = this.window.height - 50 + 'px'
		  else
		  hierHeight = this.window.height - 170 + 'px'

		  return hierHeight;
 	  },
  },


  methods: {

        changeBackground(){

       this.scene.background = new THREE.Color(this.backgroudColor);
    
    },

    init: function() {
        let container = document.getElementById('modell');

        this.camera = new THREE.PerspectiveCamera(15, container.clientWidth/container.clientHeight, 1, 10000);
        this.camera.position.z = 100;

        this.scene = new THREE.Scene();

		    this.scene.background = new THREE.Color( this.backgroudColor );

        const AmbientLight = new THREE.AmbientLight( );
        this.scene.add( AmbientLight );

        this.renderer = new THREE.WebGLRenderer({antialias: true});

        const controls = new OrbitControls( this.camera, this.renderer.domElement );

        this.renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(this.renderer.domElement);


    },

    animate: function() {
         requestAnimationFrame(this.animate);
        // this.mesh.rotation.x += 0.01;
        // this.mesh.rotation.y += 0.02;
        this.renderer.render(this.scene, this.camera);

        
    }
  },

  mounted() {

// window.addEventListener("resize", () => {

//   (size.width = window.innerWidth), (size.height = window.innerHeight);
//   camera.aspect = size.width / size.height;
//   camera.updateProjectionMatrix();
//   renderer.setSize(size.width, size.height);
// });

      this.init();
      this.animate();

  var inputF = ""
  inputF = document.getElementById('file-input');
 var self = this
  inputF.addEventListener(
    "change",
    (changed) => {
      const ifcURL = URL.createObjectURL(changed.target.files[0]);
      ifcLoader.load(ifcURL, (ifcModel) =>  self.scene.add(ifcModel.mesh)
      );
    },
    false
  );

  }
}
</script>