import { forAllMixin } from './forAllMixin.js'

export const davMixin = {

    mixins: [forAllMixin],

    data() {
        return {

            
        }
    },

methods: {

    getDAVReturn(){
        console.log("getDAVReturn");
        return this.axios.post(this.$store.state.url_getdav, {
           was:'zugangsdaten',
           besonders: this.$store.state.dev_sub,
           }).then((response) => {
      
           // returning the data here allows the caller to get it through another .then(...)
           return response.data
         })
      
         },

    getDAV(){
        console.log("getDAV");
        this.axios.get(this.$store.state.url_getdav,
            {params:
                {besonders: this.$store.state.dev_sub,}

            }).then((response) => {
               // console.log(response.data);
                this.zugangsdaten = response.data
            })


    }



},

computed:{

    
    meineKalenderID(){
		var meinCal;
		
		var pos = this.eventSources.map(function(e) { return e.shareStatus.access; }).indexOf('1');

		if(pos>=0)
		meinCal = this.eventSources[pos].id

		return meinCal
	},

	getShareStatusCalender(){
		
		// console.log(this.activeEvent.calendarID);

        //activeEvent.calendarID

		if(this.activeEvent.calendarID)
		var pos = this.eventSources.map(function(e) { return e.id; }).indexOf(this.activeEvent.calendarID);
		
		// console.log(pos);

		var shareStatus

		if(pos>=0)
		shareStatus = this.eventSources[pos].shareStatus.access;

		// console.log(shareStatus);

				var returnValue = false

				if(shareStatus==1 || shareStatus==3){
                    returnValue = true
                    this.bearbeiten=true
                }
					
				else
					this.bearbeiten=false
				
				return returnValue;
	},
    

        eventSources:
        {
            get()
            {
                return this.$store.getters.eventSources
            },
            set(value)
            {
                this.$store.commit('seteventSources', value);
            }
        },
		activeEvent:
		{
			get()
			{
				return this.$store.getters.activeCalendarEvent
			},
			set(value)
			{
				this.$store.commit('setactiveCalendarEvent', value);
			}
		},

        zugangsdaten:
        {
            get()
            {
                return this.$store.getters.zugangsdaten
            },
            set(value)
            {
                this.$store.commit('setzugangsdaten', value);
            }
        },




    

},

created(){

    this.getDAVReturn().then(data => {
        this.zugangsdaten = data;
    })



},

}