<template>
	<div>
		<div class="kontainer">


<div class="start-cards">
  <!-- <b-card-group deck> -->

    <b-card
      header-tag="header"
	  class="mb-4"
    >
	  <template v-slot:header>
        <h5 class="mb-0">Architektur - und Ingenieurbüros</h5>
      </template>

      <b-card-text>
			<b-list-group>
				<b-list-group-item>Grundpreis*: 29,- € pro Monat</b-list-group-item>
				<b-list-group-item>jede weitere Benutzerlizenz: 6,- € pro Monat</b-list-group-item>
				<b-list-group-item>* der Grundpreis enthält 3 Benutzerlizenzen</b-list-group-item>

			</b-list-group>
		  </b-card-text>
    </b-card>



    <b-card
	class="mb-4"
      header-tag="header"
    >
	  <template v-slot:header>
        <h5 class="mb-0">Handwerksunternehmen</h5>
      </template>

      <b-card-text>
			<b-list-group>
							<b-list-group-item>Grundpreis**: 10,- € pro Monat</b-list-group-item>
							<b-list-group-item>jede weitere Benutzerlinzenz: 5,- € pro Monat</b-list-group-item>
							<b-list-group-item>** der Grundpreis enthält 1 Benutzerlizenz</b-list-group-item>

			</b-list-group>
		  </b-card-text>
    </b-card>

    <b-card
      header-tag="header"
	  class="mb-4"
    >
	  <template v-slot:header>
        <h5 class="mb-0">Bauherren</h5>
      </template>

      <b-card-text>
			<b-list-group>
				<b-list-group-item>kostenlos</b-list-group-item>
				<b-list-group-item>fragen Sie Ihren Architekten nach Ihren Zugangsdaten</b-list-group-item>
			</b-list-group>
		  </b-card-text>
    </b-card>


							<p class='addInfo'>Die Preise sind Netto-Preise. Das Angebot richtet sich ausschließlich an Unternehmer im Sinne des § 14 BGB.</p>

					<p class='addInfo'>Wenn Sie mehr als 20 Benutzerlizenzen benötigen, erstellen wir Ihnen gerne ein individuelles Angebot. Bitte kontaktieren Sie uns unter folgender E-Mail Adresse: <span style="margin-left:0.5em;"><a href="php/nachricht_anfrage.php">Nachricht</a></span></p>

</div>



			</div>

	</div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
    .separator{

		width: 50%;
		min-width: 400px;
		max-width: 750px;
	}
	ul li {
			font-size:1.0em;
			margin-bottom: 6px;
			}
		
	.addInfo{
	font-size: 0.9em;  
	color:#000;
	}
		
	h4{
			
		font-size: 1.3em;
		font-weight: 400;
		letter-spacing: 1px;
		color:#000;
	}
    
</style>
