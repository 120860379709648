<template>
	<div>
       <div class="kontainer">

<div id="button-container">

      <button onclick="test()">Run Test</button>

    </div>
    <div id="animation-target"></div>
    <iframe id="iframe"></iframe>

        </div>
	</div>
</template>




<script>

import fontkit from '@pdf-lib/fontkit';
import { PDFLib, PDFDocument, StandardFonts, rgb } from 'pdf-lib'

 // startFpsTracker('animation-target');

// function startFpsTracker(id) {
//   const element = document.getElementById(id);

//   const moveTo = (xCoord) =>
//     (element.style.transform = `translateX(${xCoord}px)`);

//   let xCoord = 0;
//   const delta = 7;

//   const slideRight = (timestamp) => {
//     moveTo(xCoord);
//     xCoord += delta;

//     if (xCoord > 100) {
//       requestAnimationFrame(slideLeft);
//     } else {
//       requestAnimationFrame(slideRight);
//     }
//   };

//   const slideLeft = (timestamp) => {
//     moveTo(xCoord);
//     xCoord -= delta;

//     if (xCoord < -100) {
//       requestAnimationFrame(slideRight);
//     } else {
//       requestAnimationFrame(slideLeft);
//     }
//   };

//   requestAnimationFrame(slideRight);
// }

//     const fetchBinaryAsset = (asset) =>
//       fetch(`/assets/${asset}`).then((res) => res.arrayBuffer());

//     const fetchStringAsset = (asset) =>
//       fetch(`/assets/${asset}`).then((res) => res.text());

//     const renderInIframe = (pdfBytes) => {
//       const blob = new Blob([pdfBytes], { type: 'application/pdf' });
//       const blobUrl = URL.createObjectURL(blob);
//       document.getElementById('iframe').src = blobUrl;
//     };

//     const ipsumLines = [
//       'Eligendi est pariatur quidem in non excepturi et.',
//       'Consectetur non tenetur magnam est corporis tempor.',
//       'Labore nisi officiis quia ipsum qui voluptatem omnis.',
//     ];

    // This test creates a new PDF document and inserts pages to it.
    // Each page is testing different features of pdf-lib.
    async function test() {
    //   const {
    //     clip,
    //     clipEvenOdd,
    //     closePath,
    //     cmyk,
    //     degrees,
    //     drawRectangle,
    //     endPath,
    //     grayscale,
    //     LineCapStyle,
    //     setLineJoin,
    //     LineJoinStyle,
    //     typedArrayFor,
    //     lineTo,
    //     moveTo,
    //     PDFDocument,
    //     popGraphicsState,
    //     pushGraphicsState,
    //     rgb,
    //     StandardFonts,
    //   } = PDFLib;

      const pdfDoc = await PDFDocument.create();

    //   pdfDoc.setTitle('🥚 The Life of an Egg 🍳');
    //   pdfDoc.setAuthor('Humpty Dumpty');
    //   pdfDoc.setSubject('📘 An Epic Tale of Woe 📖');
    //   pdfDoc.setKeywords(['eggs', 'wall', 'fall', 'king', 'horses', 'men']);
    //   pdfDoc.setProducer('PDF App 9000 🤖');
    //   pdfDoc.setCreator('PDF App 9000 🤖');
    //   pdfDoc.setCreationDate(new Date('2018-06-24T01:58:37.228Z'));
    //   pdfDoc.setModificationDate(new Date('2018-12-21T07:00:11.000Z'));

    //   pdfDoc.registerFontkit(fontkit);

    //   const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    //   const size = 750;

    //   /********************** Page 1 **********************/

    //   // This page tests different drawing operations as well as adding custom
    //   // operators to the page content.

    //   const page1 = pdfDoc.addPage([size, size]);

    //   // Upper-left Quadrant
    //   page1.moveTo(0, size / 2);
    //   page1.drawSquare({ size: size / 2, color: rgb(1, 0, 0) });
    //   page1.pushOperators(
    //     pushGraphicsState(),
    //     moveTo(0, size / 2),
    //     lineTo(0, size),
    //     lineTo(size / 2, size),
    //     closePath(),
    //     clip(),
    //     endPath(),
    //     ...drawRectangle({
    //       x: size / 8,
    //       y: size / 2 + size / 8,
    //       width: size / 4,
    //       height: size / 4,
    //       borderWidth: 50,
    //       borderColor: rgb(1, 1, 0),
    //       rotate: degrees(0),
    //       xSkew: degrees(0),
    //       ySkew: degrees(0),
    //       color: undefined,
    //     }),
    //     popGraphicsState(),
    //   );

    //   // Upper-right quadrant
    //   page1.pushOperators(pushGraphicsState());
    //   page1.moveTo(size / 2, size / 2);
    //   page1.drawSquare({ size: size / 2, color: rgb(0, 1, 0) });
    //   page1.drawEllipse({
    //     x: size / 2 + size / 4,
    //     y: size / 2 + size / 4,
    //     xScale: 25,
    //     yScale: 150,
    //     color: rgb(255 / 255, 153 / 255, 51 / 255),
    //     borderWidth: 2,
    //     borderColor: rgb(0, 1, 1),
    //     borderDashArray: [10],
    //   });
    //   page1.drawEllipse({
    //     x: size / 2 + size / 4,
    //     y: size / 2 + size / 4,
    //     xScale: 75,
    //     yScale: 50,
    //     color: undefined,
    //   });
    //   page1.drawEllipse({
    //     x: size / 2 + size / 4,
    //     y: size / 2 + size / 4,
    //     xScale: 150,
    //     yScale: 100,
    //     color: undefined,
    //   });
    //   page1.pushOperators(clipEvenOdd(), endPath());
    //   page1.setFont(timesRomanFont);
    //   page1.setFontColor(rgb(1, 0, 1));
    //   page1.setFontSize(32);
    //   page1.setLineHeight(32);
    //   page1.moveTo(size / 2 + 5, size - 5 - 25);
    //   page1.drawText(
    //     [...ipsumLines, ...ipsumLines, ...ipsumLines, ...ipsumLines].join('\n'),
    //   );
    //   page1.pushOperators(popGraphicsState());


    //   /********************** Print Metadata **********************/

    //   console.log('Title:', pdfDoc.getTitle());
    //   console.log('Author:', pdfDoc.getAuthor());
    //   console.log('Subject:', pdfDoc.getSubject());
    //   console.log('Creator:', pdfDoc.getCreator());
    //   console.log('Keywords:', pdfDoc.getKeywords());
    //   console.log('Producer:', pdfDoc.getProducer());
    //   console.log('Creation Date:', pdfDoc.getCreationDate());
    //   console.log('Modification Date:', pdfDoc.getModificationDate());

    //   /********************** Export PDF **********************/

      const pdfBytes = await pdfDoc.save();

     // renderInIframe(pdfBytes);
    }


export default {

    data(){
        return{
        }
    },
	methods:{

	},

	  mounted() {

  },

	created(){
//url_getkunden
	}
	
	//
}
</script>


<style>


</style>
