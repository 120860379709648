<template>
	<div v-if="projectDaten">
     
		<div v-if="projectDaten.ProjektBeteiligte" class="kontainer">


			<b-card class="mb-4 container" >	

						<b-row class="down-space-15">
							<b-col ><div class="ueberschrift">Bauherrschaft</div>
								<b-icon 
									v-if="bearbeiten" 
									v-b-tooltip.hover 
									title="Bitte suchen Sie die Bauherrschaft aus der unten stehenden Liste aus. 
									Mit einem Klick auf den entsprechenden Namen, weisen Sie diesem Bauvorhaben diese Bauherrschaft zu.
									Die Liste enthält alle Bauherrschaften aus dem Adressbuch.
									Sollte die Bauherrschaft noch nicht in der Liste stehen, können Sie diesen über das 'plus' Symbol hinzufügen." 
									class="ml-2" 
									icon="question-circle" 
									font-scale="1.5" 
									variant="primary">
								</b-icon>
							</b-col>
						</b-row>

					<hr class="my-1">
						<b-row >
						<b-col v-if="bearbeiten" style="max-width: 300px; min-width: 300px;">
						
						<b-row style="margin-left: 0px !important; margin-right: 0px !important;"  >
							<b-input-group style="float:left; max-width: 222px;">
							<b-form-input
								v-model="searchTermBauherr"
								type="text"
								placeholder="Bauherr(in) suchen">
							</b-form-input>
							<b-input-group-append>
							<b-btn v-b-tooltip.hover 
									title="Suche leeren" v-if="searchTermBauherr" @click="searchTermBauherr=''" :pressed="false"  variant="warning">X</b-btn>
							</b-input-group-append>
						</b-input-group>
						
						<b-button 
						style="float:left;"
						class="ml-2"
							@click="legeNeuenKontaktan('beteiligte')" 
							v-b-tooltip.hover 
							title="neuen Kontakt (Bauherrschaft) erstellen, falls nicht in unten stehender Liste aufgeführt" 
							variant="info" >
							<span class="fa fa-plus"></span>
					</b-button>
						</b-row>
						
							<div class="py-2"></div>

							<div style="clear: both;">

								<virtual-list
									class="listBet"    
									style="height: 350px;"
									:data-key="'uid'"
									:data-sources="bauherrenGefiltert"
									:data-component="itemComponent"
									:extra-props="{ variable: '' }"
									:estimate-size="bauherrenGefiltert.length"

								/>

							</div>
						</b-col>

						<b-col  >

							<b-row style="margin-left: 0px !important; margin-right: 0px !important;"  >
								<span class="mb-2" v-if="projectDaten.ProjektBeteiligte[1]">ausgewählte Bauherrschaft:</span>
								<span class="mb-2" v-else>Es wurde noch keine Bauherrschaft für dieses Bauvorhaben ausgewählt. Bitte wählen Sie die Bauherrschaft aus der links stehenden Liste.</span>
							</b-row>



							<b-row>
								<b-col>
									<b-list-group v-if="projectDaten.ProjektBeteiligte[1]" class="mb-1">
										<b-list-group-item >
											

											{{bauherrenKeys[projectDaten.ProjektBeteiligte[1]]}}
											

										</b-list-group-item>
									</b-list-group>
								</b-col>
							
							
								<b-col>
									<b-btn 	
									v-if="bearbeiten && projectDaten.ProjektBeteiligte[1]"
									class="mr-2"
										v-b-tooltip.hover 
										title="Verbindung zwischen dieser Bauherrschaft und dem Bauvorhaben trennen." 
										@click="projectDaten.ProjektBeteiligte[1]=[]" variant="danger">
											<b-icon icon="x"></b-icon>

									</b-btn>

									<b-btn 	
									v-if="projectDaten.ProjektBeteiligte[1]"
										v-b-tooltip.hover 
										title="Wechseln Sie zu diesem Kontakt. Der aktuelle Stand wird vorher automatischgespeichert." 
										@click="speicherAktuellesProjekt();clickOnName(projectDaten.ProjektBeteiligte[1]); " 
										variant="primary">
									
										<b-icon icon="box-arrow-up-right"></b-icon>

									</b-btn>
								</b-col>
							</b-row>
							
						</b-col>
						
					</b-row>

				</b-card>

				<b-card class="container" style="margin-bottom: 2rem;">	

					<b-row class="down-space-15">
						<b-col><div class="ueberschrift">Planer</div></b-col>
					</b-row>

				
					<b-row class="my-3">
						<b-col>
							<label class="eingabe-label">Entwurfsverfasser(in)  </label>
							<b-icon v-if="bearbeiten" v-b-tooltip.hover title="Die Kategorie 'Entwurfsverfasser(in)' kann im Adressbuch dem entsprechenden Kontakt hinzugefügt werden." class="ml-2" icon="question-circle" font-scale="1.5" variant="primary"></b-icon>	
						</b-col>
					</b-row>

					<b-row class="my-3">
						<b-col cols="8">
							<b-form-select 
							:disabled="!bearbeiten" 
							v-if="entwurfsverfasserListe.length>0" 
							v-model="projectDaten.ProjektBeteiligte.entwurfsverfasser" 
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							:options="entwurfsverfasserListe">
							<template v-slot:first>
								<option :value="null">bitte wählen / keine(r)</option>
							</template>

							</b-form-select>
						</b-col>
					</b-row>


			


					<!-- {{projectDaten.ProjektBeteiligte}} -->
					<b-row class="my-3">
						<b-col>
							<label class="eingabe-label">Bauvorlageberechtigte(r)  </label>
							<b-icon v-if="bearbeiten" v-b-tooltip.hover title="Die Kategorie 'Bauvorlageberechtigte(r)' kann im Adressbuch dem entsprechenden Kontakt hinzugefügt werden." class="ml-2" icon="question-circle" font-scale="1.5" variant="primary"></b-icon>	
						</b-col>
					</b-row>
					<b-row class="my-3">
						<b-col cols="8">
							<b-form-select 
							:disabled="!bearbeiten" 
							v-if="bauvorlageberechtigteListe.length>0" 
							v-model="projectDaten.ProjektBeteiligte[10]" 
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							:options="bauvorlageberechtigteListe">
							<template v-slot:first>
								<option :value="null">bitte wählen / keine(r)</option>
							</template>

							</b-form-select>
						</b-col>
					</b-row>

					<b-row class="my-3 mt-4">
						<b-col>
							<label class="eingabe-label">Leistungsphasen</label>
							<b-icon v-if="bearbeiten" v-b-tooltip.hover title="Zur Auswahl stehen Kontakte, die im Adressbuch die Kategorie 'Planer' oder 'Bauleiter' haben." class="ml-2" icon="question-circle" font-scale="1.5" variant="primary"></b-icon>
						</b-col>
					</b-row>

<!-- //{{projectDaten.ProjektBeteiligte}} -->

<!-- {{auswahlListen}} -->
					<b-row class="ml-2 mb-2" :key="index" v-for="(leistungsphase, index) in auswahlListen.leistungsphasen">
						<b-col style="min-width:65px;" cols="1">
							{{index}}
						</b-col>

						<b-col cols>
							<span :key="indexplaner" v-for="(planer, indexplaner) in projectDaten.ProjektBeteiligte[leistungsphase]">
								
								<!-- Planer: {{planer}} -->
								{{planerKeys[planer]}} 

								<span v-if="indexplaner+1 < projectDaten.ProjektBeteiligte[leistungsphase].length "> | </span>
								</span>
						</b-col>

						<b-col cols>
								  <b-button v-if="bearbeiten" variant="outline-primary" id="show-btn" 
	  								@click="openLPModal(leistungsphase)">auswählen</b-button>
<!-- 
							<b-form-select 
							:disabled="!bearbeiten" 
							v-if="BauleiterUndPlanerListe.length>0" 
							v-model="projectDaten.ProjektBeteiligte[leistungsphase]" 
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							:options="BauleiterUndPlanerListe">
	
							<template v-slot:first>
								<option :value="null">keine(r)</option>
							</template>
							</b-form-select> -->
						</b-col>
					</b-row>

				</b-card>


<b-modal size="lg" id="leistungsphasen_user" hide-footer>
    <template v-slot:modal-title>
     Mitarbeiter der Leistungsphase <b>{{aktuelleLeistungsphase-10}}</b> zuordnen
    </template>

    <div>
      
	<b-row>


		<b-col cols="12">
			<b-form-group label="zur Verfügung stehen:">
				<b-form-checkbox-group
				v-model="projectDaten.ProjektBeteiligte[aktuelleLeistungsphase]"
					:options="BauleiterUndPlanerListe"

					name="flavour-2a"
					stacked
				></b-form-checkbox-group>
	    </b-form-group>
		</b-col>

	</b-row>



    </div>

    <b-button @click="$bvModal.hide('leistungsphasen_user')">schließen</b-button>

  </b-modal>

			<b-card class="mb-4 container" >	

				<b-row class="down-space-15">
					<b-col ><div class="ueberschrift">Bauamt</div></b-col>
				</b-row>

			<hr class="my-1">

				<b-row class="my-3">
					<b-col cols="12">
						<b-form-select 
						:disabled="!bearbeiten" 
						v-if="bauaufsichtenListe.length>0" 
						v-model="projectDaten.ProjektBeteiligte[3]" 
						v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
						:options="bauaufsichtenListe"></b-form-select>
					</b-col>
				</b-row>

				<hr class="my-1">

			</b-card>

			<b-card class="mb-4 container" >	

				<b-row class="down-space-15">
					<b-col ><div class="ueberschrift">Gemeinde</div></b-col>
				</b-row>

			<hr class="my-1">

				<b-row class="my-3">
					<b-col cols="12">
						<!-- projectDaten.ProjektBeteiligte[5] = Gemeinde -->
						<b-form-select 
						:disabled="!bearbeiten" 
						v-if="bauaufsichtenListe.length>0" 
						v-model="projectDaten.ProjektBeteiligte[5]" 
						v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
						:options="bauaufsichtenListe"></b-form-select>
					</b-col>
				</b-row>

				<hr class="my-1">

			</b-card>




			<b-card class="mb-4 container" >	

				<b-row class="down-space-15">
					<b-col>
						<div class="ueberschrift">Fachplaner(in)</div>
						<!-- <b-button 
						style="float:left;"
						class="ml-2"
							@click="legeNeuenKontaktan('beteiligte')" 
							v-b-tooltip.hover 
							title="neuen Kontakt erstellen, falls nicht in unten stehender Liste aufgeführt" 
							variant="info" >
							<span class="fa fa-plus"></span>
					</b-button> -->
					</b-col>
				</b-row>

			<hr class="my-1">

			<b-button 
				class="my-2"
				v-if="bearbeiten"
				v-b-tooltip.hover 
				title="Fügt eine neue, leere Zeile hinzu."
				variant="outline-primary" 
				@click="pushFachplaner">hinzufügen
			</b-button>
<!-- {{taetigkeiten_fachplaner_select}} -->

				<b-row :key="index" v-for="(fachplan, index) in projectDaten.ProjektBeteiligte.fachplaner" class="mb-2">

					<b-col cols="4">
						<b-form-select 
						:disabled="!bearbeiten" 
						v-if="taetigkeiten_fachplaner_select.length>0" 
						v-model="fachplan.taetigkeitID" 
						v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
						:options="taetigkeiten_fachplaner_select">
						
							<template v-slot:first>
								<option :value="null">bitte wählen</option>
							</template>
						
						</b-form-select>
					</b-col>

					<b-col cols="6">
						<span v-if="fachplan.taetigkeitID">
							<span v-if="kontaktNachTaetigkeit(fachplan.taetigkeitID).length>0">
							<b-form-select 
							:disabled="!bearbeiten" 
							
							v-model="fachplan.kontaktID" 
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							:options="kontaktNachTaetigkeit(fachplan.taetigkeitID)">
							
								<template v-slot:first>
									<option :value="null">bitte wählen</option>
								</template>
							
							</b-form-select>
							</span>
							<span v-else>
								Kein passender Kontakt im Adressbuch vorhanden.<br>
								Falls es den Kontakt bereits im Adressbuch gibt, stellen Sie sicher, dass dieser 1.) unter "Kategorie des Eintrags" ein Häcken bei <b>Fachplaner</b> hat und unter "Tätigkeit" <b>{{auswahlListen.alle_taetigkeiten_key[fachplan.taetigkeitID]}}</b> ein Häkchen hat.<br>
								<router-link to="/adressbuch">zum Adressbuch</router-link> 
							
							</span>
						</span>
					</b-col>
					<b-col cols="2">
								<b-btn 	
									v-if="bearbeiten && fachplan.kontaktID"
									class="mr-2"
										v-b-tooltip.hover 
										title="Verbindung zwischen Fachplaner(in) und dem Bauvorhaben trennen." 
										@click="projectDaten.ProjektBeteiligte.fachplaner.splice(index, 1)" variant="danger">
											<b-icon icon="x"></b-icon>

								</b-btn>
								<b-btn 	
									v-if="fachplan.kontaktID"
										v-b-tooltip.hover 
										title="Wechseln Sie zu diesem Kontakt. Der aktuelle Stand wird vorher automatisch gespeichert." 
										@click="speicherAktuellesProjekt();clickOnName(fachplan.kontaktID); " 

										variant="primary">
										
										<b-icon icon="box-arrow-up-right"></b-icon>

									</b-btn>

					</b-col>	
				</b-row>

 			<!-- </transition> -->
			
			</b-card>


			<handwerker id="handwerker" />



		<div class="mb-5"> </div>
		<div style="height:150px; margin-bottom: 55px;"></div>
        
		</div>

		<kontaktModal woherKommstDu="beteiligte" ref="kontaktModalBeteiligte" ></kontaktModal>

	</div>
</template>

<script>
	import kontaktModal from '../kontaktModal.vue'

	import { projectMixin } from '../../mixins/projectMixin.js'
	import ItemForBauherr from '../BauherrItem.vue'

	import handwerker from './comp_handwerker.vue';


export default {

	mixins: [projectMixin],

 		components:{
			 kontaktModal,
			'item-for-bauherr':ItemForBauherr,
			'handwerker':handwerker,
			
		},

    data(){
        return{
			itemComponent:ItemForBauherr,
			aktuelleLeistungsphase:null,
        }
	},
	
	
	mounted() {
			this.$root.$on('openKontaktModalinBeteiligte', data => {
				console.log("openKontaktModalinBeteiligte")

				try {
					this.$refs.kontaktModalBeteiligte.$refs.neuer_adressbuch_eintrag.show()
				}
				catch (e) {
				// Anweisungen für jeden Fehler
				}


			})

		if(this.projectDaten.ProjektBeteiligte=='')
		{
			this.projectDaten.ProjektBeteiligte={};
		}


	  



	},

	computed:{
// 		handwerker_select(){
// var returnValue=[]

// 			if(this.Addressbook){
// //WEITER
// 				return this.Addressbook.filter(el => this.findCommonElements(el.art.summary, ['3']) && this.findCommonElements(el.taetigkeit.summary, this.artArray );
// 			//&& this.findCommonElements(el.art.summary, this.artArray)
// 			//taetigkeit.summary

// 			}
// 			else
// 			return returnValue

// 		},

		taetigkeiten_select(){
			var returnValue=[]

			if(this.auswahlListen.taetigkeiten_key){

				for(var prop in this.auswahlListen.taetigkeiten_key){
					// console.log(prop)
					returnValue.push({ value: prop, text: this.auswahlListen.taetigkeiten_key[prop] })
				}

			}

			return returnValue
			},

		entwurfsverfasser_select(){
			var returnValue=[]

			if(this.auswahlListen.taetigkeiten_fachplaner_key){
	
				for(var prop in this.auswahlListen.taetigkeiten_fachplaner_key){
					// console.log(prop)
					returnValue.push({ value: prop, text: this.auswahlListen.taetigkeiten_fachplaner_key[prop] })
				}

			}

			return returnValue
			},

		taetigkeiten_fachplaner_select(){
			var returnValue=[]

			if(this.auswahlListen.taetigkeiten_fachplaner_key){
				for(var prop in this.auswahlListen.taetigkeiten_fachplaner_key){
					// console.log(prop)
					returnValue.push({ value: prop, text: this.auswahlListen.taetigkeiten_fachplaner_key[prop] })
				}

			}

			return returnValue
			},


		bauaufsichtenListe(){

			var returnValue=[];
			for(var prop of this.bauaufsichten){
				returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
			}
			return returnValue
			
		},

		bauaufsichtenAnsprechpartner(){

			var kontaktIDLIste=[];
			if(this.projectDaten.length>0){

			
				if(this.projectDaten.ProjektBeteiligte[3]){
					var aktuelleBauaufsicht
					
					var targetValue = '';
					
					var targetValue = this.bauaufsichten.filter(item => item.basic.kontaktID === this.projectDaten.ProjektBeteiligte[3]);
					// console.log(targetValue[0]);

					for(var beteiligte of targetValue[0].ProjektBeteiligte){
						kontaktIDLIste.push({ value: beteiligte.basic.kontaktID, text: beteiligte.vollerName.anzeigeName })
					}

				}
			}

			return kontaktIDLIste

		},



	
	},

	methods:{

		openLPModal(lp){
			this.aktuelleLeistungsphase = lp;
			this.$bvModal.show('leistungsphasen_user');

		},


		kontaktNachTaetigkeit(taetigkeitID){
			 console.log(taetigkeitID)
			// console.log(this.Addressbook)
		if(this.Addressbook && taetigkeitID>0){
			// var tatArray = ['3'];
			// console.log(tatArray)
		//el.taetigkeit.summary.findIndex(item=>item  == taetigkeitID) > -1
			var tatArray = [taetigkeitID];
            var nachTaetigkeit = this.Addressbook.filter(el => el.status==1 && this.findCommonElements(el.taetigkeit.summary, tatArray ))

			var returnValue=[]

			for(var prop of nachTaetigkeit){
				returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
			}

			return returnValue


			//return this.Addressbook.filter(el => this.findCommonElements(el.taetigkeit, taetigkeitID))
			//return this.Addressbook.filter(el => this.findCommonElements(el.taetigkeit.summary, tatArray ))

		}
		else
			return [];

		},
		pushFachplaner()
		{
			// console.log(this.projectDaten.ProjektBeteiligte.fachplaner)
			this.projectDaten.ProjektBeteiligte.fachplaner.push({'taetigkeitID':null, 'kontaktID':null});
		},
		pushHandwerker()
		{
			// console.log(this.projectDaten.ProjektBeteiligte.handwerker)
			this.projectDaten.ProjektBeteiligte.handwerker.push({'taetigkeitID':null, 'kontaktID':null});
		},

	

	},

	created(){



	}
	
	//
}
</script>


<style>

  .listBet{

	width:30%;
	min-width:280px;
	overflow-x: hidden;
	}


</style>
