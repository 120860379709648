import { render, staticRenderFns } from "./AdressHeader.vue?vue&type=template&id=47a5ecee&scoped=true&"
import script from "./AdressHeader.vue?vue&type=script&lang=js&"
export * from "./AdressHeader.vue?vue&type=script&lang=js&"
import style0 from "./AdressHeader.vue?vue&type=style&index=0&id=47a5ecee&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a5ecee",
  null
  
)

export default component.exports