<template>

    <div>
      <b-row v-if="source.basic" :style="itemStyle">

        <div class="card_new" @click="addToX(source.basic.kontaktID)" v-if="source.vollerName"  >
          <label>
            <span >{{ source.vollerName.anzeigeName }}</span>

          </label>
        </div>
      </b-row>
  </div>

</template>

<script>

 import { kontaktMixin } from '../mixins/kontaktMixins.js'

export default {

   mixins: [kontaktMixin],

  name: 'ItemFor',

 props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      },
      variable:String
     
    },


  // props: {
  //   height: {
  //     type: Number,
  //     default: 0
  //   },
  //   index: {
  //     type: Number,
  //     default: 0
  //   },
  //   variable: String,
  //   source: {
  //     type: Object,

  //   }
  // },

  data () {
    return {

    }
  },

  computed: {

    itemStyle () {
      return {
        height: `60px`,
      }
      },


    
  },

  methods: {


    addToX(){

      console.log('addToX');
      if(this.variable==='addGroup'){
        var einsatz1 = "gruppe";
        var einsatz2 = "beteiligte";
      }
      else{ // add beteilgter
        var einsatz1 = "beteiligte";
        var einsatz2 = "gruppe";
      }


        this.KontaktDetail[einsatz1].push(this.source.basic.kontaktID);

        var	pos = this.Addressbook.map(function(e) { return e.basic.kontaktID; }).indexOf(this.source.basic.kontaktID);

        var	posKontaktIDinBeteiligte = this.Addressbook[pos][einsatz2].map(function(e) { return e; }).indexOf(this.KontaktDetail.basic.kontaktID);

          
          if(pos>=0 && posKontaktIDinBeteiligte==-1){

           if(this.backUpContacts.map(function(e) { return e.basic.kontaktID; }).indexOf(this.Addressbook[pos].basic.kontaktID)===-1)
            this.backUpContacts.push(JSON.parse(JSON.stringify(this.Addressbook[pos])));

            this.Addressbook[pos][einsatz2].push(this.KontaktDetail.basic.kontaktID)

            this.updateToSaveArray(this.Addressbook[pos]);

          }


    }


  }
}
</script>

<style scoped lang="less">

.row{

  padding-right: 0px !important;
  padding-left: 0px !important;
  width:270px;
}
	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
  }
  
.closeSelect {
  position: absolute;
  left: 12px;
  bottom: 11px;
  width: 60px;
  height: 60px;
  opacity: 0.3;

}
.closeSelect:hover {
  opacity: 1;
}
.closeSelect:before, .closeSelect:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 83px;
  width: 2px;
  background-color: #333;
}
.closeSelect:before {
  transform: rotate(45deg);
}
.closeSelect:after {
  transform: rotate(-45deg);
}


.highlightThis{
     border: 2px solid #3c39ff !important;
}

.marked{
     border: 2px solid #0804f1 !important;
       background-color: #c3d3ff;
}

.item_new{
  width:100%;
}

.card_new:hover{
  background-color: #cccccc;
  cursor: pointer;
}

.mehrfachMarkierung:hover {
  background-color: #cccccc;
}

.mehrfachMarkierung{
    position: relative;
    border: 0.5px solid #bfffe1;
    height: 100%;
    width: 60px !important;

    padding:5px;
    display: inline-block;
    cursor: pointer;
}

.card_details{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new span{
        vertical-align:bottom;
}

</style>
