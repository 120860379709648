import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Toasted from 'vue-toasted';

import VModal from 'vue-js-modal'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// import InfiniteLoading from 'vue-infinite-loading';

import VuejsDialog from 'vuejs-dialog';

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// import CroudGantt from 'croud-vue-gantt'
// Vue.use(CroudGantt)

import VirtualList from 'vue-virtual-scroll-list'
import VueOffline from 'vue-offline'

import vlf from 'vlf'
import localforage from 'localforage'

Vue.use(vlf, localforage)


import panZoom from 'vue-panzoom'
Vue.use(panZoom);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)


// import wlBimViewer from "wl-bim-viewer";
// import "wl-bim-viewer/lib/wl-bim-viewer.css"
// Vue.use(wlBimViewer);

import vb from 'vue-babylonjs';
Vue.use(vb);

Vue.component('virtual-list', VirtualList)

import 'leaflet/dist/leaflet.css';


// import VuexUndoRedo from 'vuex-undo-redo';
// Vue.use(VuexUndoRedo);

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.config.productionTip = false

Vue.config.performance = true;

Vue.use(VueOffline, {
	storage: false,
	mixin: false
})

Vue.use(Toasted);
Vue.use(VueAxios, axios);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.use(VModal);


// Vue.use(InfiniteLoading, { /* options */ });

Vue.use(VuejsDialog);

//Vue.use(PulseLoader);


import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import './registerServiceWorker'

// router.beforeEach(async (to, from, next) => {

// 	await Vue.nextTick();
// 	console.log("switch route")

// 	if(to.path!='/start' && to.path!='/preise' && to.path!='/kontakt')
// 	router.app.checkloggin(next)
// 	else
// 		next()

//   })

// Vue.forceUpdate();

Vue.mixin({
	data() {
		return {
	
			window: {
				width: 0,
				height: 0
			  },

		}
	},

	methods: {

		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
			},

			checkloggin(next){

				this.axios.get(store.state.url_getloggin,
				  {
						params: {
						besonders: store.state.dev_sub,
						}
					})
			
				.then((response) => {
					 console.log(response.data)
					store.commit('setLogStatus', response.data);
					
					if(response.data.loggedIn==true)
					next()
					else
					this.$root.$emit('login_modal');
			
					//alert("anmelden");
			})
			.catch(function (error) {
			  //return false
			  // handle error
			  // console.log(error);
			})
			
			
			},

	},

	mounted() {

	},
	
	computed:
	{

		itsMobile:
		{
			get()
			{
				return this.$store.getters.itsMobile
			},
			set(value)
			{
				this.$store.commit('setitsMobile', value);
			}
		}, 

		bearbeiten:
		{
			get()
			{
				return this.$store.getters.bearbeiten
			},

			set(value)
			{
				this.$store.commit('changeBearbeiten', value);
			}
		},



	},

	created(){
		window.addEventListener('resize', this.handleResize)
		this.handleResize();
	},

	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},


})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
