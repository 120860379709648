<template>
	<div  :class="{'footer-copyright':!$route.path.includes('Extern'), 'atBottom':$route.path.includes('Extern') }">
		<div :class="{'zentriert':!$route.path.includes('Extern')}" >
				<router-link to="/kontakt">Impressum</router-link>		
		</div>
	</div>
</template>

<script>

	export default {

		data(){
			return{
			}
		}
	}

</script>

<style scoped>

.atBottom{
	margin: 0 auto;
	position:fixed;
	bottom: 10px;
    left: 0;
	font-size:0.8rem;
	text-transform: uppercase;
	writing-mode: vertical-lr;
}


.footer-copyright {
	padding:5px 0;
	background:#337AB7;
	overflow:hidden;
	font-family:"Open Sans", sans-serif;
	margin: 0 auto;
	position:fixed;
	bottom: 0;
    left: 0;
	width: 100%;
	z-index: 1000;
}

.zentriert {
	text-align:center;

}
.zentriert a{
	color:#FFF;
	font-size:11px;
	text-transform: uppercase;
}


</style>
