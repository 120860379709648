<template>
	<div>
       <div class="kontainer">

				<b-container class="mb-4">
									<button class="btn btn-primary" @click="safeVorlagen">speichern</button>
				</b-container>

					<b-container class="mb-4">
                            <b-form-select v-model="gewaehlteVorlage" :options="vorlagenListe"></b-form-select>
					</b-container>

{{zugriffVorlagen}}
					<b-container v-if="gewaehlteVorlage">
						<b-list-group>
								<span >
									<div style="min-height:300px;" class="modal-body_custom">

										<div v-for="option in navigation" :key="option.navID">

											<b-form-checkbox
												v-model="zugriffVorlagen[gewaehlteVorlage].navigation"
												:value="option.navID"
											>
												<span class="text-uppercase font-weight-bold" >{{option.ordnerName}}</span>
											</b-form-checkbox>

											<div v-for="suboption in option['subnav']" :key="suboption.navID">

												<b-form-checkbox
												v-model="zugriffVorlagen[gewaehlteVorlage].navigation"
												:value="suboption.navID"
												>
												<span class="pl-4" >{{suboption.unterordnerName}}</span>
											</b-form-checkbox>

											</div>

										</div>

									</div>
								</span>
						</b-list-group>
					</b-container>					
				
			</div>

	</div>
</template>

<script>

import { einstellungenMixin } from '../../mixins/einstellungenMixin.js'

// console.log(einstellungenMixin)

export default {

	mixin:{einstellungenMixin},

    data(){
        return{
			gewaehlteVorlage:''
		}
    },
	

	computed: {

	navigation:
		{
			get()
			{
				return this.$store.getters.navigation
			},
			set(value)
			{
				this.$store.commit('setnavigation', value);
			}
        },

		zugriffVorlagen:
		{
			get()
			{
				return this.$store.getters.zugriffVorlagen
			},
			set(value)
			{
				this.$store.commit('setzugriffVorlagen', value);
			}
		},

	vorlagenListe(){
		var returnValue=[]

		var keyValue = Object.keys(this.zugriffVorlagen);

		for(var prop of keyValue)
		{
		console.log(prop)

		 returnValue.push({ value: prop, text: this.zugriffVorlagen[prop].zugriffrechteName });

		}
		return returnValue
    }
	
	},			
	methods:{


	safeVorlagen(){

		this.axios.post(this.$store.state.url_setEinstellungen, {
				besonders: this.$store.state.dev_sub,
				zugriffsrechte: this.zugriffVorlagen,
				was:'speicherVorlage'
				}).then((response) => {
					console.log(response.data)
					// this.vorlagen = response.data;
					
				})
	},
	        getBerechtigungenVorlage(){

            this.axios.post(this.$store.state.url_getEinstellungen, {
                    besonders: this.$store.state.dev_sub,
                    was:'getVorlagen'
                    }).then((response) => {
                            console.log(response.data)
                        this.zugriffVorlagen = response.data;
                    })
			},
			


	},
	
	created(){

		if(this.zugriffVorlagen.length===0 || !this.zugriffVorlagen){
			console.log(this.zugriffVorlagen)
			this.getBerechtigungenVorlage()
		}
		
	
	}
	
}
</script>



<style>


</style>
