<template>
    <div v-if="!$route.path.includes('Extern')" id="header">

        <b-navbar v-if="!itsMobile" fixed="top" toggleable="sm" >

    <b-spinner v-if="loader_loading" style="position:absolute; top 10px; left:28px;" variant="primary" label="Spinning"></b-spinner>


    <!-- hier {{logObject.loggedIn}}{{naviTop}}{{meineNavigation}} -->

    <span v-if="logObject.loggedIn === true" v-b-toggle.sidebar-1><div style="width: 0px; height: 0px; 
    position:absolute; left:5px; top:7px;
    border-style: solid; border-width: 18px 0px 18px 23px; 
    border-color: transparent transparent transparent #888888; 
    transition: border 250ms ease 0s;"></div></span>

        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <!-- <b-navbar-brand href="#">NavBar</b-navbar-brand> -->

            <b-collapse class="SansPro" is-nav id="nav_collapse">

                <b-navbar-nav v-if="logObject.loggedIn === true">
                    <b-nav-item  :to="nav.link" :key="nav.navID" v-for="nav in naviTop">{{nav.ordnerName}}</b-nav-item>
                    <b-nav-item to="/hilfe">Hilfe</b-nav-item>
                    <!-- <b-nav-item to="/wissen">Wissen</b-nav-item> -->
                
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav v-if="logObject.loggedIn === true" class="ml-auto">
                    <b-nav-item @click="abmelden">abmelden</b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav v-if="logObject.loggedIn !== true">
                    <b-nav-item to="/start">Startseite</b-nav-item>
                    <b-nav-item to="/preise">Preise</b-nav-item>
                    <b-nav-item to="/kontakt">Kontakt</b-nav-item>
                </b-navbar-nav>
                
                <b-navbar-nav v-if="logObject.loggedIn !== true" class="ml-auto">
                    <b-nav-item  to="/login">anmelden</b-nav-item>
                </b-navbar-nav>

            </b-collapse>

        </b-navbar>



<div v-if="!itsMobile" class="abstandhalter_leer"></div>

      <!-- {{$route}} -->
        <!-- {{subnav}} -->
    <span v-if="$route.params.id">
        <subnavi></subnavi>
    </span> 

   <span v-if="!itsMobile && $route.path.includes('/einstellungen')">

        <div class="subnav">

            <ul class="list-inline_scoped" >
                
                <li :key="sub.navID"  v-for="sub in suvNav">
                    <router-link  :to="'/'+sub.link">{{sub.unterordnerName}}</router-link>
                </li>
            </ul>

        </div>
        
        <div class="subnav_shadow">

            <ul class="list-inline_scoped" >
                <li :key="sub.navID"  v-for="sub in suvNav">
                    {{sub.unterordnerName}}
                </li>
            </ul>
        </div>
    </span>

			<div v-if="logObject.loggedIn != true" id="logo">
					<span style="display: block">
						<div class="logo_retz">ArchiApp</div>
						<div class="logo_retz_unterschrift">Die APP für den Bausektor</div>
					</span>
			</div>


<!-- AB HIER MOBILE -->

        <div v-if="itsMobile && logObject.loggedIn == true" class="navbar_mobile" id="myNavbar">

            <router-link class="ml-4" v-on:click.native="deactivate" :key="nav.id" v-for="nav in navi" :to="nav.link">{{nav.ordnerName}}</router-link>
            
            <span v-if="logObject.loggedIn === true" v-b-toggle.sidebar-1><div style="width: 0px; height: 0px; 
            position:absolute;
            left:5px;
            top:7px;

            border-style: solid; border-width: 18px 0px 18px 23px; 
            border-color: transparent transparent transparent #888888; 
            transition: border 250ms ease 0s;"></div></span>

            <a href="javascript:void(0);" style="font-size:15px;" class="iconNav" @click="toogleNav">&#9776;</a>
        </div>

        <div v-if="itsMobile && logObject.loggedIn != true" class="navbar_mobile" id="myNavbar">

            <router-link v-on:click.native="deactivate"  to="/start">Startseite</router-link>
            <router-link v-on:click.native="deactivate"  to="/preise">Preise</router-link>
            <router-link v-on:click.native="deactivate"  to="/kontakt">Kontakt</router-link>
            <router-link v-on:click.native="deactivate"  to="/login">anmelden</router-link>

            <a href="javascript:void(0);" style="font-size:15px;" class="iconNav" @click="toogleNav">&#9776;</a>
        </div>

<!-- BIS HIER MOBILE -->

<!-- {{navi}} -->

	</div>


</template>

<script>

/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */



import subnavi from './projekte/subNavi.vue'
import { forAllMixin } from '../mixins/forAllMixin.js'
import { VueOfflineStorage } from 'vue-offline'

export default {

    mixins: [forAllMixin],

        components: {
			subnavi
        },
	
	data(){
		return{
        polling: null,
		bis: Math.trunc(((new Date()).getTime() + 10*1000) / 1000),
		now: Math.trunc((new Date()).getTime() / 1000),
		collapser: false,
		windowWidth: 0,
		windowHeight: 0,
		isOpen:false,
		// navi:[1,2,3,4],
		// subnav:'',
//		styleObject: {height: '75px'},
		projectID:''
		}
	},
	
	computed: {

        navigation:
		{
			get()
			{
				return this.$store.getters.navigation
			},
			set(value)
			{
				this.$store.commit('setnavigation', value);
			}
        },
        meineNavigation:
		{
			get()
			{
				return this.$store.getters.meineNavigation
			},
			set(value)
			{
				this.$store.commit('setmeineNavigation', value);
			}
        },


			naviTop() {
                var returnValue = []
                    // console.log(this.meineNavigation)

                if(this.meineNavigation.length>0){
                    
                    for(var dat of this.navigation)
                    {

                        if(this.meineNavigation.includes(dat.navID))
                        returnValue.push(dat)
                    }
                }
                return returnValue
            },

		auswahlListen:
			{
				get()
				{
					return this.$store.getters.auswahlListen
				},
				set(value)
				{
					this.$store.commit('setauswahlListen', value);
				}
			},

        suvNav(){
                var returnValue = [];

                  var pos = this.navigation.map(function(e) { return e.ordnerName; }).indexOf('Einstellungen');
  
                  if(pos>=0 && this.meineNavigation.length>0){

                    for(var dat of this.navigation[pos].subnav)
                    {
                        if(this.meineNavigation.includes(dat.navID))
                        returnValue.push(dat)
                    }
                  }
               

                return returnValue

        },

        
		dateInMilliseconds() {
            return this.bis
		},
		seconds() {
            return (this.dateInMilliseconds - this.now) % 60;
		},
		minutes() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
		},
		hours() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
		},
		days() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
		},
		logObject:
		{
			get()
			{
				return this.$store.getters.logObject
			},
			set(value)
			{
				this.$store.commit('setlogObject', value);
			}
		},

	},
	
	mounted() {

this.pollData()


     if(Object.keys(this.meineNavigation).length ===0){
        // console.log("updateAlleProjekte length");
        this.checkSubNav();
    } 

        this.$root.$on('logEvent', () => {
            // console.log("logEvent fired");
            // your code goes here
          //  this.getAuswahllisten();

        //   	this.axios.get(this.$store.state.url_getAuswahllisten,
		// 	{
		// 		  params: {
		// 			  besonders: this.$store.state.dev_sub,
		// 		  }
		// 	  })
	  
		//   .then((response) => {
			
		// 	this.auswahlListen = response.data
	  	// 	// console.log(this.auswahlListen)
		//   })
		  
          
                //  this.checkloggin();
                //  this.checkSubNav();

        }),
			
			window.setInterval(() => {
				this.now = Math.trunc((new Date()).getTime() / 1000);
				
				if(Math.trunc((new Date()).getTime()) <= this.bis)
                 
                 console.log("ausloggen");
				
			},1000);
	
    },
    
    beforeDestroy () {
        clearInterval(this.polling)
    },

	methods: {

    pollData () {
		this.polling = setInterval(() => {
		    this.checkSubNav();
		}, 1000)
	},
    
     deactivate(){
         
        var x = document.getElementById("myNavbar");
        // console.log(x)
        x.className = "navbar_mobile"
    
     },

     toogleNav() {
        var x = document.getElementById("myNavbar");
        if (x.className === "navbar_mobile") {
            x.className += " responsive_mobile";
        } else {
            x.className = "navbar_mobile";
        }
     },


    abmelden(){
        // console.log("abmelden geklickt")
        // console.log(this.$store.state.url_logout)

        this.axios.post(this.$store.state.url_logout).then((response) => {
            if(response.data.logout===true){
                this.checkloggin();
                this.checkSubNav();
				this.$toasted.show("Sie haben sich erfolgreich abgemeldet.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})
                this.$router.push('/login');
              
                window.location.reload()
            }
           
        })
    },


  checkloggin(){

    this.axios.get(this.$store.state.url_getloggin,
      {
            params: {
            besonders: this.$store.state.dev_sub,
            }
        })

    .then((response) => {
        //  console.log(response.data)
        this.$store.commit('setLogStatus', response.data);

        if(response.data.loggedIn==true)
        VueOfflineStorage.set('onceTrueLogin', true)

    })

              	this.axios.get(this.$store.state.url_getAuswahllisten,
			{
				  params: {
					  besonders: this.$store.state.dev_sub,
				  }
			  })
	  
		  .then((response) => {
			
			this.auswahlListen = response.data
	  		// console.log(this.auswahlListen)
		  })

},

checkSubNav(){
    console.log("checkSubNav")
    this.axios.get(this.$store.state.url_subnav,
        {
          params: {
            besonders: this.$store.state.dev_sub,
          }
        })
        .then((response) => {

            this.meineNavigation = response.data;
            clearInterval(this.polling)
           
    })
},



    },


    created(){
            
    this.checkloggin();

    

		}
	}

</script>

<style>
    .subnav_shadow{
        height:auto;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5%;
        padding-right: 5%;
        left: 0;
        right: 0;
    }

    .subnav{
        position:fixed;
        height:auto;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5%;
        padding-right: 5%;
        border-bottom: 1px solid #6E6E6E;
        background-color: #F2F2F2;
        left: 0;
        right: 0;
        z-index: 880;
    }

    .list-inline_scoped>li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        line-height: 15px;
    }
    
</style>
    
<style scoped>

.SansPro{
    font-family: SourceSansPro; 
    font-size: 1.1em !important;
    font-weight: 500 !important;
}

.navbar_mobile {
  overflow: hidden;
  background-color: #333;  
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000000;
}

.navbar_mobile a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar_mobile a:hover {
  background-color: #ddd;
  color: black;
}

.navbar_mobile a.active {
  background-color: #4CAF50;
  color: white;
}

.navbar_mobile .iconNav {
  display: none;
  width: 60px;
  height: 60px;;
}

@media screen and (max-width: 600px) {

  .navbar_mobile a {display: none;} /*a:not(:first-child) */

  .navbar_mobile a.iconNav {
    float: right;
    display: block;

  }


  .navbar_mobile a.router-link-active {
    float: left;
    display: block;
     background-color:#333 !important;
     color:rgb(139, 215, 245) !important;
  }

}

@media screen and (max-width: 600px) {
  /* .navbar_mobile.responsive_mobile a.router-link-active {display: none;} */
  .navbar_mobile.responsive_mobile .iconNav {
    position: absolute;
    right: 0;
    bottom:0;
        
  }
  .navbar_mobile.responsive_mobile a {
    float: none;
    display: block;
    text-align: left;
  }

}



/* router-link-active */
.router-link-active{
	color:#337AB7 !important;
    border-bottom: 1px solid #000;
}

.router-link-exact-active{
	color:#337AB7 !important;
    border-bottom: 1px solid #000;
}

.router-link-exact-active:hover {
	cursor: default ;
    text-decoration:none;
}


.navbar
{
    padding: 0.5rem 0rem;
    height: 54px;
    background-color:#fff;
}

.navbar-nav{
    margin-left:4.5%;
    margin-right:4.5%;
}

.navbar-nav >  li{
    font-size:1.0em;
    font-weight:400;
	letter-spacing:0.1em;
	color: #000;
	display: block;
	text-transform: uppercase;
	border: 1px solid transparent;
}

a.nav-link.active{
    border-bottom: 11px solid #F2F2F2;
    color: #191970 !important;
}

.navbar-collapse{
        background-color:#fff;
}

.navbar-toggler{
        margin-left:4.5%;
}

.navbar-light .navbar-nav .nav-link{
    color:#000;
}

.project-details-liste{
        margin-right:1em;
        padding-right:1.2em;
        border-right: 1px solid #A4A4A4;
}

.hr_line
    {
        border-bottom: 1px solid #A4A4A4;
        margin-left:-10px;
        margin-right:-10px;
        margin-bottom:7px;
    }
    
.projectDetails
    {
        font-weight: 600;
        margin-top: 5px;
        padding-left: 5px;
        padding-top: 5px;
    }
    
.abstandhalter_leer{
       height:54px;
}
    
.subnav_scoped {
	background: #fff;
	padding: 0;
	position: relative;
	text-align: left;
	width: 100%;
	min-height: 35px;
	z-index: 9987;
	border-top: 1px solid #F2F2F2;
}

.untersicht_projects{

}

#logo {
    width:100%;
    text-align:center;
    margin: 0 auto;
    margin-bottom:60px;
    padding-top:15px;
}

.logo_retz
{
	font-size: 3.0em;
	font-weight: 50;
	letter-spacing: 4px;
	color:#000;
	text-shadow: 0.02em 0.02em 0 rgba(0,0,0,0.1);
    line-height: 1.6em;
}

/* #0 1DF3A*/
/* #E EC7BC*/

.logo_retz_unterschrift
{
	font-size: 1.3em;
	font-weight: 100;
	letter-spacing: 2px;
	color:#9e9e9e;
}

.logo{
    float:right;
    line-height: 53px;
    margin-right:5%;
    font-weight:600;
    font-size:1.2em
}

.logo p{
    margin-right:10px;
}

@media (max-width: 850px) {
    .logo{
    display:none;
    }
}
.leere_zeile{
	height:1em;
}





/* .loggedIn  .router-link-active{
}

.router-link-active{
	color:#337AB7 !important;
} */


</style>
