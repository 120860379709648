<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														position:'absolute', 
														left: spalte_breit_mm(6.6790799) + 'px', 
														top: gib_hoehe_in_mm(19.6119546) + 'px'}">
													Statistik der Baugenehmigungen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														position:'absolute', 
														left: spalte_breit_mm(6.6790799) + 'px', 
														top: gib_hoehe_in_mm(27.2489677) + 'px'}">
													Bitte lesen Sie vor dem Ausfüllen die dazugehörigen Erläuterungen.
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														position:'absolute', 
														left: spalte_breit_mm(90.6082773) + 'px', 
														top: gib_hoehe_in_mm(21.3497075) + 'px'}">
													BG Füllen Sie den Fragebogen aus bei ...
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.7620328) + 'px', 
														top: gib_hoehe_in_mm(25.767275) + 'px'}">
													... Neubau (für jedes Gebäude 
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8375768) + 'px', 
														top: gib_hoehe_in_mm(28.5962817) + 'px'}">
													1 Erhebungsbogen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.862905) + 'px', 
														top: gib_hoehe_in_mm(33.9082964) + 'px'}">
													... Baumaßnahmen an einem
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.139488) + 'px', 
														top: gib_hoehe_in_mm(36.7373031) + 'px'}">
													bestehenden Gebäude.
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.862905) + 'px', 
														top: gib_hoehe_in_mm(41.7682159) + 'px'}">
													... Änderung des Nutzungsschwer
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8626185) + 'px', 
														top: gib_hoehe_in_mm(44.7308959) + 'px'}">
													punkts zwischen Wohnbau und
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8626185) + 'px', 
														top: gib_hoehe_in_mm(48.00501) + 'px'}">
													Nichtwohnbau (bitte zusätzlich 
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8626185) + 'px', 
														top: gib_hoehe_in_mm(50.833664) + 'px'}">
													einen Abgangsbogen ausfüllen).
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(22.1683242) + 'px'}">
													Information und Technik
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(25.1655688) + 'px'}">
													NordrheinWestfalen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(28.1628134) + 'px'}">
													Geschäftsbereich Statistik
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(31.160058) + 'px'}">
													Referat 535 Paderborn
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(34.1569499) + 'px'}">
													40193 Düsseldorf
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(39.8008553) + 'px'}">
													Sie erreichen uns über
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(43.6272976) + 'px'}">
													Telefon 0211 9449 4664
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(47.1701691) + 'px'}">
													Telefax 0211 9449 4658
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.5903352) + 'px', 
														top: gib_hoehe_in_mm(50.6252183) + 'px'}">
													EMail bautaetigkeitit.nrw.de
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5630145) + 'px', 
														top: gib_hoehe_in_mm(66.8201442) + 'px'}">
													Kenntnisgabe, Anzeige bzw. Genehmigungs
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5630145) + 'px', 
														top: gib_hoehe_in_mm(70.2057115) + 'px'}">
													freistellung entspricht jeweiligem Landesrecht ............
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(188.2437494) + 'px', 
														top: gib_hoehe_in_mm(65.2206497) + 'px'}">
													Ja
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(196.4919916) + 'px', 
														top: gib_hoehe_in_mm(65.2206497) + 'px'}">
													Nein
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.landesrecht_ja!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(187.8769414) + 'px',top: gib_hoehe_in_mm(69.7857222) + 'px'}"
								v-model="formular.landesrecht_ja.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.landesrecht_nein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(198.5404732) + 'px',top: gib_hoehe_in_mm(69.7857222) + 'px'}"
								v-model="formular.landesrecht_nein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(51.9089752) + 'px', 
														top: gib_hoehe_in_mm(53.3367759) + 'px'}">
													(Blockschrift)
													</div>
<b-popover 
												v-if="formular.identnummer"
												:show="formular.identnummer.showPop==true"
												
												ref='identnummer' 
												target='identnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.identnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.identnummer.size}}</span>
												<b-button @click='plus("identnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("identnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="identnummer" type="text" 
												v-if="formular.identnummer!=undefined" 
												class='textfeld' v-model='formular.identnummer.text' 
												style='width:100%' 
												@focus='formular.identnummer.showPop=true'
												@blur ='formular.identnummer.showPop=false'
												:disabled='formular.identnummer.isDisabled==true || formular.identnummer.isDisabled==true' @input="nummerAndereSeiten"  
												@keypress="isNumber($event, formular.identnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(39.2768006) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(7.4246234) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(35.7575146) + 'px', 
		
														'font-size': formular.identnummer.size + 'px', 
														'text-align': formular.identnummer.schriftausrichtung, 
														'letter-spacing': formular.identnummer.letterSpacing + 'px',
														'font-weight': formular.identnummer.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauscheinnummer"
												:show="formular.bauscheinnummer.showPop==true"
												
												ref='bauscheinnummer' 
												target='bauscheinnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauscheinnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauscheinnummer.size}}</span>
												<b-button @click='plus("bauscheinnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauscheinnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauscheinnummer" type="text" 
												v-if="formular.bauscheinnummer!=undefined" 
												class='textfeld' v-model='formular.bauscheinnummer.text' 
												style='width:100%' 
												@focus='formular.bauscheinnummer.showPop=true'
												@blur ='formular.bauscheinnummer.showPop=false'
												:disabled='formular.bauscheinnummer.isDisabled==true || formular.bauscheinnummer.isDisabled==true' 
												@keypress="isNumber($event, formular.bauscheinnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.7312703) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(6.9276691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(43.7437007) + 'px', 
		
														'font-size': formular.bauscheinnummer.size + 'px', 
														'text-align': formular.bauscheinnummer.schriftausrichtung, 
														'letter-spacing': formular.bauscheinnummer.letterSpacing + 'px',
														'font-weight': formular.bauscheinnummer.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.6790799) + 'px', 
														top: gib_hoehe_in_mm(39.5656044) + 'px'}">
													Identifikationsnummer
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.6247641) + 'px', 
														top: gib_hoehe_in_mm(47.6046955) + 'px'}">
													Bauscheinnummer/Aktenzeichen
													</div>
<div :style="{
														fontSize:'15px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.6790799) + 'px', 
														top: gib_hoehe_in_mm(53.0584956) + 'px'}">
													1 Allgemeine Angaben
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6783452) + 'px', 
														top: gib_hoehe_in_mm(58.1825212) + 'px'}">
													Bauherr/Bauherrin
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6783452) + 'px', 
														top: gib_hoehe_in_mm(62.2371604) + 'px'}">
													Name/Firma
													</div>
<b-popover 
												v-if="formular.bauherr_name1"
												:show="formular.bauherr_name1.showPop==true"
												
												ref='bauherr_name1' 
												target='bauherr_name1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name1.size}}</span>
												<b-button @click='plus("bauherr_name1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name1" type="text" 
												v-if="formular.bauherr_name1!=undefined" 
												class='textfeld' v-model='formular.bauherr_name1.text' 
												style='width:100%' 
												@focus='formular.bauherr_name1.showPop=true'
												@blur ='formular.bauherr_name1.showPop=false'
												:disabled='formular.bauherr_name1.isDisabled==true || formular.bauherr_name1.isDisabled==true' @input="bauherrAndereSeiten"  
												@keypress="isNumber($event, formular.bauherr_name1.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1344574) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(33.0257056) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(65.8368287) + 'px', 
		
														'font-size': formular.bauherr_name1.size + 'px', 
														'text-align': formular.bauherr_name1.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name1.letterSpacing + 'px',
														'font-weight': formular.bauherr_name1.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(77.6783664) + 'px'}">
													Anschrift
													</div>
<b-popover 
												v-if="formular.bauherr_name2"
												:show="formular.bauherr_name2.showPop==true"
												
												ref='bauherr_name2' 
												target='bauherr_name2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name2.size}}</span>
												<b-button @click='plus("bauherr_name2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name2" type="text" 
												v-if="formular.bauherr_name2!=undefined" 
												class='textfeld' v-model='formular.bauherr_name2.text' 
												style='width:100%' 
												@focus='formular.bauherr_name2.showPop=true'
												@blur ='formular.bauherr_name2.showPop=false'
												:disabled='formular.bauherr_name2.isDisabled==true || formular.bauherr_name2.isDisabled==true' @input="bauherrAndereSeiten"  
												@keypress="isNumber($event, formular.bauherr_name2.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1344574) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(33.0257056) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(72.3356789) + 'px', 
		
														'font-size': formular.bauherr_name2.size + 'px', 
														'text-align': formular.bauherr_name2.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name2.letterSpacing + 'px',
														'font-weight': formular.bauherr_name2.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_anschrift1"
												:show="formular.bauherr_anschrift1.showPop==true"
												
												ref='bauherr_anschrift1' 
												target='bauherr_anschrift1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_anschrift1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_anschrift1.size}}</span>
												<b-button @click='plus("bauherr_anschrift1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_anschrift1", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_anschrift1" type="text" 
												v-if="formular.bauherr_anschrift1!=undefined" 
												class='textfeld' v-model='formular.bauherr_anschrift1.text' 
												style='width:100%' 
												@focus='formular.bauherr_anschrift1.showPop=true'
												@blur ='formular.bauherr_anschrift1.showPop=false'
												:disabled='formular.bauherr_anschrift1.isDisabled==true || formular.bauherr_anschrift1.isDisabled==true' @input="bauherrAndereSeiten"  
												@keypress="isNumber($event, formular.bauherr_anschrift1.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1344574) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(33.0257056) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(78.8341764) + 'px', 
		
														'font-size': formular.bauherr_anschrift1.size + 'px', 
														'text-align': formular.bauherr_anschrift1.schriftausrichtung, 
														'letter-spacing': formular.bauherr_anschrift1.letterSpacing + 'px',
														'font-weight': formular.bauherr_anschrift1.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_anschrift2"
												:show="formular.bauherr_anschrift2.showPop==true"
												
												ref='bauherr_anschrift2' 
												target='bauherr_anschrift2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_anschrift2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_anschrift2.size}}</span>
												<b-button @click='plus("bauherr_anschrift2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_anschrift2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_anschrift2" type="text" 
												v-if="formular.bauherr_anschrift2!=undefined" 
												class='textfeld' v-model='formular.bauherr_anschrift2.text' 
												style='width:100%' 
												@focus='formular.bauherr_anschrift2.showPop=true'
												@blur ='formular.bauherr_anschrift2.showPop=false'
												:disabled='formular.bauherr_anschrift2.isDisabled==true || formular.bauherr_anschrift2.isDisabled==true' @input="bauherrAndereSeiten"  
												@keypress="isNumber($event, formular.bauherr_anschrift2.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1344574) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(33.0257056) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(85.827512) + 'px', 
		
														'font-size': formular.bauherr_anschrift2.size + 'px', 
														'text-align': formular.bauherr_anschrift2.schriftausrichtung, 
														'letter-spacing': formular.bauherr_anschrift2.letterSpacing + 'px',
														'font-weight': formular.bauherr_anschrift2.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(92.7291335) + 'px'}">
													Anschrift des Baugrundstücks
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(96.7837727) + 'px'}">
													Straße,
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(100.3107727) + 'px'}">
													Nummer
													</div>
<b-popover 
												v-if="formular.baugrundstueck_strasse"
												:show="formular.baugrundstueck_strasse.showPop==true"
												
												ref='baugrundstueck_strasse' 
												target='baugrundstueck_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_strasse.size}}</span>
												<b-button @click='plus("baugrundstueck_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_strasse" type="text" 
												v-if="formular.baugrundstueck_strasse!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_strasse.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_strasse.showPop=true'
												@blur ='formular.baugrundstueck_strasse.showPop=false'
												:disabled='formular.baugrundstueck_strasse.isDisabled==true || formular.baugrundstueck_strasse.isDisabled==true' @input="grundstueckAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.2625754) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.8159446) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(99.4667737) + 'px', 
		
														'font-size': formular.baugrundstueck_strasse.size + 'px', 
														'text-align': formular.baugrundstueck_strasse.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(104.8641297) + 'px'}">
													Postleitzahl,
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(108.3911297) + 'px'}">
													Ort
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(116.4439761) + 'px'}">
													Lage des Baugrundstücks
													</div>
<b-popover 
												v-if="formular.baugrundstueck_plz"
												:show="formular.baugrundstueck_plz.showPop==true"
												
												ref='baugrundstueck_plz' 
												target='baugrundstueck_plz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_plz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_plz.size}}</span>
												<b-button @click='plus("baugrundstueck_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_plz", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_plz" type="text" 
												v-if="formular.baugrundstueck_plz!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_plz.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_plz.showPop=true'
												@blur ='formular.baugrundstueck_plz.showPop=false'
												:disabled='formular.baugrundstueck_plz.isDisabled==true || formular.baugrundstueck_plz.isDisabled==true' @input="grundstueckAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_plz.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4421648) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.8159446) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.546778) + 'px', 
		
														'font-size': formular.baugrundstueck_plz.size + 'px', 
														'text-align': formular.baugrundstueck_plz.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_plz.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_plz.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueck_ort"
												:show="formular.baugrundstueck_ort.showPop==true"
												
												ref='baugrundstueck_ort' 
												target='baugrundstueck_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_ort.size}}</span>
												<b-button @click='plus("baugrundstueck_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_ort" type="text" 
												v-if="formular.baugrundstueck_ort!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_ort.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_ort.showPop=true'
												@blur ='formular.baugrundstueck_ort.showPop=false'
												:disabled='formular.baugrundstueck_ort.isDisabled==true || formular.baugrundstueck_ort.isDisabled==true' @input="grundstueckAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.3073683) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(45.7373663) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.546778) + 'px', 
		
														'font-size': formular.baugrundstueck_ort.size + 'px', 
														'text-align': formular.baugrundstueck_ort.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_ort.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_ort.schriftart }" 
												/>

<b-popover 
												v-if="formular.ansprechpartnerTelefon"
												:show="formular.ansprechpartnerTelefon.showPop==true"
												
												ref='ansprechpartnerTelefon' 
												target='ansprechpartnerTelefon'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ansprechpartnerTelefon.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ansprechpartnerTelefon.size}}</span>
												<b-button @click='plus("ansprechpartnerTelefon", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ansprechpartnerTelefon", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ansprechpartnerTelefon" type="text" 
												v-if="formular.ansprechpartnerTelefon!=undefined" 
												class='textfeld' v-model='formular.ansprechpartnerTelefon.text' 
												style='width:100%' 
												@focus='formular.ansprechpartnerTelefon.showPop=true'
												@blur ='formular.ansprechpartnerTelefon.showPop=false'
												:disabled='formular.ansprechpartnerTelefon.isDisabled==true || formular.ansprechpartnerTelefon.isDisabled==true' 
												@keypress="isNumber($event, formular.ansprechpartnerTelefon.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.7497724) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(107.7325034) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.4028764) + 'px', 
		
														'font-size': formular.ansprechpartnerTelefon.size + 'px', 
														'text-align': formular.ansprechpartnerTelefon.schriftausrichtung, 
														'letter-spacing': formular.ansprechpartnerTelefon.letterSpacing + 'px',
														'font-weight': formular.ansprechpartnerTelefon.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5619564) + 'px', 
														top: gib_hoehe_in_mm(90.7762336) + 'px'}">
													Ansprechpartner/in für Rückfragen
													</div>
<b-popover 
												v-if="formular.baugrundstueck_nummer"
												:show="formular.baugrundstueck_nummer.showPop==true"
												
												ref='baugrundstueck_nummer' 
												target='baugrundstueck_nummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_nummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_nummer.size}}</span>
												<b-button @click='plus("baugrundstueck_nummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_nummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_nummer" type="text" 
												v-if="formular.baugrundstueck_nummer!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_nummer.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_nummer.showPop=true'
												@blur ='formular.baugrundstueck_nummer.showPop=false'
												:disabled='formular.baugrundstueck_nummer.isDisabled==true || formular.baugrundstueck_nummer.isDisabled==true' @input="grundstueckAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_nummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4421648) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(90.6025698) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(99.4667737) + 'px', 
		
														'font-size': formular.baugrundstueck_nummer.size + 'px', 
														'text-align': formular.baugrundstueck_nummer.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_nummer.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_nummer.schriftart }" 
												/>

<b-popover 
												v-if="formular.ansprechpartnerName"
												:show="formular.ansprechpartnerName.showPop==true"
												
												ref='ansprechpartnerName' 
												target='ansprechpartnerName'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ansprechpartnerName.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ansprechpartnerName.size}}</span>
												<b-button @click='plus("ansprechpartnerName", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ansprechpartnerName", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ansprechpartnerName" type="text" 
												v-if="formular.ansprechpartnerName!=undefined" 
												class='textfeld' v-model='formular.ansprechpartnerName.text' 
												style='width:100%' 
												@focus='formular.ansprechpartnerName.showPop=true'
												@blur ='formular.ansprechpartnerName.showPop=false'
												:disabled='formular.ansprechpartnerName.isDisabled==true || formular.ansprechpartnerName.isDisabled==true' 
												@keypress="isNumber($event, formular.ansprechpartnerName.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.7497724) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(107.7325034) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(97.1643481) + 'px', 
		
														'font-size': formular.ansprechpartnerName.size + 'px', 
														'text-align': formular.ansprechpartnerName.schriftausrichtung, 
														'letter-spacing': formular.ansprechpartnerName.letterSpacing + 'px',
														'font-weight': formular.ansprechpartnerName.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5630145) + 'px', 
														top: gib_hoehe_in_mm(101.1597216) + 'px'}">
													Name
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5630145) + 'px', 
														top: gib_hoehe_in_mm(111.3982499) + 'px'}">
													Telefon und/oder EMail
													</div>
<b-popover 
												v-if="formular.baugrundstueck_lage"
												:show="formular.baugrundstueck_lage.showPop==true"
												
												ref='baugrundstueck_lage' 
												target='baugrundstueck_lage'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_lage.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_lage.size}}</span>
												<b-button @click='plus("baugrundstueck_lage", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_lage", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_lage" type="text" 
												v-if="formular.baugrundstueck_lage!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_lage.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_lage.showPop=true'
												@blur ='formular.baugrundstueck_lage.showPop=false'
												:disabled='formular.baugrundstueck_lage.isDisabled==true || formular.baugrundstueck_lage.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_lage.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.0040463) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.8159446) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(123.5043368) + 'px', 
		
														'font-size': formular.baugrundstueck_lage.size + 'px', 
														'text-align': formular.baugrundstueck_lage.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_lage.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_lage.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueckNR"
												:show="formular.baugrundstueckNR.showPop==true"
												
												ref='baugrundstueckNR' 
												target='baugrundstueckNR'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueckNR.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueckNR.size}}</span>
												<b-button @click='plus("baugrundstueckNR", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueckNR", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueckNR" type="text" 
												v-if="formular.baugrundstueckNR!=undefined" 
												class='textfeld' v-model='formular.baugrundstueckNR.text' 
												style='width:100%' 
												@focus='formular.baugrundstueckNR.showPop=true'
												@blur ='formular.baugrundstueckNR.showPop=false'
												:disabled='formular.baugrundstueckNR.isDisabled==true || formular.baugrundstueckNR.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueckNR.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.9974763) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(89.8171069) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(123.5043368) + 'px', 
		
														'font-size': formular.baugrundstueckNR.size + 'px', 
														'text-align': formular.baugrundstueckNR.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueckNR.letterSpacing + 'px',
														'font-weight': formular.baugrundstueckNR.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueck_gemeinde"
												:show="formular.baugrundstueck_gemeinde.showPop==true"
												
												ref='baugrundstueck_gemeinde' 
												target='baugrundstueck_gemeinde'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_gemeinde.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_gemeinde.size}}</span>
												<b-button @click='plus("baugrundstueck_gemeinde", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_gemeinde", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_gemeinde" type="text" 
												v-if="formular.baugrundstueck_gemeinde!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_gemeinde.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_gemeinde.showPop=true'
												@blur ='formular.baugrundstueck_gemeinde.showPop=false'
												:disabled='formular.baugrundstueck_gemeinde.isDisabled==true || formular.baugrundstueck_gemeinde.isDisabled==true' @input="grundstueckLageAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_gemeinde.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.0040463) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.8159446) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.5025147) + 'px', 
		
														'font-size': formular.baugrundstueck_gemeinde.size + 'px', 
														'text-align': formular.baugrundstueck_gemeinde.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_gemeinde.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_gemeinde.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueckgemeindeNR"
												:show="formular.baugrundstueckgemeindeNR.showPop==true"
												
												ref='baugrundstueckgemeindeNR' 
												target='baugrundstueckgemeindeNR'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueckgemeindeNR.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueckgemeindeNR.size}}</span>
												<b-button @click='plus("baugrundstueckgemeindeNR", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueckgemeindeNR", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueckgemeindeNR" type="text" 
												v-if="formular.baugrundstueckgemeindeNR!=undefined" 
												class='textfeld' v-model='formular.baugrundstueckgemeindeNR.text' 
												style='width:100%' 
												@focus='formular.baugrundstueckgemeindeNR.showPop=true'
												@blur ='formular.baugrundstueckgemeindeNR.showPop=false'
												:disabled='formular.baugrundstueckgemeindeNR.isDisabled==true || formular.baugrundstueckgemeindeNR.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueckgemeindeNR.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.9974763) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(89.8171069) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.5025147) + 'px', 
		
														'font-size': formular.baugrundstueckgemeindeNR.size + 'px', 
														'text-align': formular.baugrundstueckgemeindeNR.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueckgemeindeNR.letterSpacing + 'px',
														'font-weight': formular.baugrundstueckgemeindeNR.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueck_gemeindeTeil"
												:show="formular.baugrundstueck_gemeindeTeil.showPop==true"
												
												ref='baugrundstueck_gemeindeTeil' 
												target='baugrundstueck_gemeindeTeil'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_gemeindeTeil.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_gemeindeTeil.size}}</span>
												<b-button @click='plus("baugrundstueck_gemeindeTeil", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_gemeindeTeil", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_gemeindeTeil" type="text" 
												v-if="formular.baugrundstueck_gemeindeTeil!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_gemeindeTeil.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_gemeindeTeil.showPop=true'
												@blur ='formular.baugrundstueck_gemeindeTeil.showPop=false'
												:disabled='formular.baugrundstueck_gemeindeTeil.isDisabled==true || formular.baugrundstueck_gemeindeTeil.isDisabled==true' @input="grundstueckLageAndereSeiten"  
												@keypress="isNumber($event, formular.baugrundstueck_gemeindeTeil.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.0040463) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.8159446) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(139.5010453) + 'px', 
		
														'font-size': formular.baugrundstueck_gemeindeTeil.size + 'px', 
														'text-align': formular.baugrundstueck_gemeindeTeil.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_gemeindeTeil.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_gemeindeTeil.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueckgemeindeTeilNR"
												:show="formular.baugrundstueckgemeindeTeilNR.showPop==true"
												
												ref='baugrundstueckgemeindeTeilNR' 
												target='baugrundstueckgemeindeTeilNR'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueckgemeindeTeilNR.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueckgemeindeTeilNR.size}}</span>
												<b-button @click='plus("baugrundstueckgemeindeTeilNR", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueckgemeindeTeilNR", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueckgemeindeTeilNR" type="text" 
												v-if="formular.baugrundstueckgemeindeTeilNR!=undefined" 
												class='textfeld' v-model='formular.baugrundstueckgemeindeTeilNR.text' 
												style='width:100%' 
												@focus='formular.baugrundstueckgemeindeTeilNR.showPop=true'
												@blur ='formular.baugrundstueckgemeindeTeilNR.showPop=false'
												:disabled='formular.baugrundstueckgemeindeTeilNR.isDisabled==true || formular.baugrundstueckgemeindeTeilNR.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueckgemeindeTeilNR.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.8281803) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(89.9864029) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(139.5010453) + 'px', 
		
														'font-size': formular.baugrundstueckgemeindeTeilNR.size + 'px', 
														'text-align': formular.baugrundstueckgemeindeTeilNR.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueckgemeindeTeilNR.letterSpacing + 'px',
														'font-weight': formular.baugrundstueckgemeindeTeilNR.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(132.4406846) + 'px'}">
													Gemeinde
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(140.4388625) + 'px'}">
													Gemeindeteil
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(148.4917089) + 'px'}">
													Datum der Baugenehmigung
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.6772871) + 'px', 
														top: gib_hoehe_in_mm(152.0187089) + 'px'}">
													bzw. Genehmigungsfreistellung ...................
													</div>
<b-popover 
												v-if="formular.BG_Monat"
												:show="formular.BG_Monat.showPop==true"
												
												ref='BG_Monat' 
												target='BG_Monat'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BG_Monat.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BG_Monat.size}}</span>
												<b-button @click='plus("BG_Monat", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BG_Monat", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BG_Monat" type="text" 
												v-if="formular.BG_Monat!=undefined" 
												class='textfeld' v-model='formular.BG_Monat.text' 
												style='width:100%' 
												@focus='formular.BG_Monat.showPop=true'
												@blur ='formular.BG_Monat.showPop=false'
												:disabled='formular.BG_Monat.isDisabled==true || formular.BG_Monat.isDisabled==true' 
												@keypress="isNumber($event, formular.BG_Monat.onylNumber) " 
												:style="{'width': spalte_breit_mm(8.4980824) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(74.9673788) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(151.080539) + 'px', 
		
														'font-size': formular.BG_Monat.size + 'px', 
														'text-align': formular.BG_Monat.schriftausrichtung, 
														'letter-spacing': formular.BG_Monat.letterSpacing + 'px',
														'font-weight': formular.BG_Monat.schriftart }" 
												/>

<b-popover 
												v-if="formular.BG_Jahr"
												:show="formular.BG_Jahr.showPop==true"
												
												ref='BG_Jahr' 
												target='BG_Jahr'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BG_Jahr.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BG_Jahr.size}}</span>
												<b-button @click='plus("BG_Jahr", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BG_Jahr", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BG_Jahr" type="text" 
												v-if="formular.BG_Jahr!=undefined" 
												class='textfeld' v-model='formular.BG_Jahr.text' 
												style='width:100%' 
												@focus='formular.BG_Jahr.showPop=true'
												@blur ='formular.BG_Jahr.showPop=false'
												:disabled='formular.BG_Jahr.isDisabled==true || formular.BG_Jahr.isDisabled==true' 
												@keypress="isNumber($event, formular.BG_Jahr.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4962603) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(86.0710802) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(151.080539) + 'px', 
		
														'font-size': formular.BG_Jahr.size + 'px', 
														'text-align': formular.BG_Jahr.schriftausrichtung, 
														'letter-spacing': formular.BG_Jahr.letterSpacing + 'px',
														'font-weight': formular.BG_Jahr.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(75.7095239) + 'px', 
														top: gib_hoehe_in_mm(155.924156) + 'px'}">
													Monat
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(91.6215844) + 'px', 
														top: gib_hoehe_in_mm(155.924156) + 'px'}">
													Jahr
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(165.4163711) + 'px'}">
													Art der Bautätigkeit2
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(4.1029591) + 'px', 
														top: gib_hoehe_in_mm(173.6021854) + 'px'}">
													nur
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(4.1029591) + 'px', 
														top: gib_hoehe_in_mm(175.9585741) + 'px'}">
													Neubau
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(171.0264173) + 'px'}">
													Errichtung eines neuen Gebäudes überwiegend
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.1985853) + 'px', 
														top: gib_hoehe_in_mm(176.5257157) + 'px'}">
													in konventioneller Bauart ...................................................
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(22.202465) + 'px', 
														top: gib_hoehe_in_mm(181.5241801) + 'px'}">
													im Fertigteilbau ....................................................................
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.konventionelle_bauart!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.2124893) + 'px',top: gib_hoehe_in_mm(176.105021) + 'px'}"
								v-model="formular.konventionelle_bauart.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.fertigbau!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.2124893) + 'px',top: gib_hoehe_in_mm(181.1038381) + 'px'}"
								v-model="formular.fertigbau.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bestehendesGebaude!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.2124893) + 'px',top: gib_hoehe_in_mm(186.6884899) + 'px'}"
								v-model="formular.bestehendesGebaude.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(187.1088319) + 'px'}">
													Baumaßnahme an bestehendem Gebäude ......................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(192.1623175) + 'px'}">
													Bei Baumaßnahme an bestehendem Gebäude
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(197.2158031) + 'px'}">
													Ändert sich der Nutzungsschwerpunkt des Ge
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.2003781) + 'px', 
														top: gib_hoehe_in_mm(200.7428031) + 'px'}">
													bäudes zwischen Wohnbau und Nichtwohnbau ? .....
													</div>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(89.5815676) + 'px', 
														top: gib_hoehe_in_mm(195.7577413) + 'px'}">
													Ja Nein
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nutzungsschwerpunkt_ja!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(89.2144069) + 'px',top: gib_hoehe_in_mm(200.3224611) + 'px'}"
								v-model="formular.nutzungsschwerpunkt_ja.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.nutzungsschwerpunkt_nein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.1285467) + 'px',top: gib_hoehe_in_mm(200.3224611) + 'px'}"
								v-model="formular.nutzungsschwerpunkt_nein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(69.1367353) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(110.7887132) + 'px',
										
										 top: gib_hoehe_in_mm(199.1098785) + 'px'}"><b-popover 
												v-if="formular.nutzungsartNichtWohnbau"
												:show="formular.nutzungsartNichtWohnbau.showPop==true"
												
												ref='nutzungsartNichtWohnbau' 
												target='nutzungsartNichtWohnbau'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nutzungsartNichtWohnbau.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nutzungsartNichtWohnbau.size}}</span>
												<b-button @click='plus("nutzungsartNichtWohnbau", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nutzungsartNichtWohnbau", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.nutzungsartNichtWohnbau!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.nutzungsartNichtWohnbau.text"
												
												@focus='formular.nutzungsartNichtWohnbau.showPop=true'
												@blur ='formular.nutzungsartNichtWohnbau.showPop=false'
												
												:disabled="formular.nutzungsartNichtWohnbau.isDisabled==true || formular.nutzungsartNichtWohnbau.isDisabled=='true'"
												:style="{'font-size': formular.nutzungsartNichtWohnbau.size + 'px',
														'height': formular.nutzungsartNichtWohnbau.height + 'px',
														'text-align': formular.nutzungsartNichtWohnbau.schriftausrichtung + 'px',
														'letter-spacing': formular.nutzungsartNichtWohnbau.letterSpacing + 'px',
														'font-weight': formular.nutzungsartNichtWohnbau.schriftart + 'px', }"

												id='nutzungsartNichtWohnbau'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5034082) + 'px', 
														top: gib_hoehe_in_mm(124.4051205) + 'px'}">
													3 Angaben zum Gebäude 3
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(130.0151667) + 'px'}">
													Bauherr
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(135.0150419) + 'px'}">
													Öffentlicher Bauherr .... 1
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(140.0674694) + 'px'}">
													Unternehmen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5619271) + 'px', 
														top: gib_hoehe_in_mm(144.0938926) + 'px'}">
													Wohnungsunternehmen .. 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5619271) + 'px', 
														top: gib_hoehe_in_mm(149.0934151) + 'px'}">
													Immobilienfonds .............. 3
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(154.1469007) + 'px'}">
													Land und Forstwirtschaft,
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(157.6739007) + 'px'}">
													Tierhaltung, Fischerei .... 4
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(162.7273863) + 'px'}">
													Produzierendes
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(166.2543863) + 'px'}">
													Gewerbe .......................... 5
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.oeffentlBauherr!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(134.5939945) + 'px'}"
								v-model="formular.oeffentlBauherr.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wohnungsunternehmen!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(143.6739033) + 'px'}"
								v-model="formular.wohnungsunternehmen.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.immobilienfond!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(148.6727204) + 'px'}"
								v-model="formular.immobilienfond.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.landUndForstwirtschaft!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(157.2539114) + 'px'}"
								v-model="formular.landUndForstwirtschaft.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.produzierendeGew!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(165.8340443) + 'px'}"
								v-model="formular.produzierendeGew.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(134.0687478) + 'px'}">
													Handel, Kreditinstitute und
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(137.5957478) + 'px'}">
													Versicherungsgewerbe,
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(141.1227478) + 'px'}">
													Dienstleistungen sowie
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(144.6497478) + 'px'}">
													Verkehr und Nachrich
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(148.1767478) + 'px'}">
													tenübermittlung ............... 6
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(153.7035568) + 'px'}">
													Privater Haushalt .......... 7
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(159.2300131) + 'px'}">
													Organisation ohne
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.551281) + 'px', 
														top: gib_hoehe_in_mm(162.7570131) + 'px'}">
													Erwerbszweck ............... 8
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.orgiOhneErwerbzweck!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(162.3370238) + 'px'}"
								v-model="formular.orgiOhneErwerbzweck.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.4053891) + 'px', 
														top: gib_hoehe_in_mm(159.5834185) + 'px'}">
													Bei allen
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.4053891) + 'px', 
														top: gib_hoehe_in_mm(161.9398072) + 'px'}">
													Baumaß
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.4053891) + 'px', 
														top: gib_hoehe_in_mm(164.2965486) + 'px'}">
													nahmen
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(173.3076809) + 'px'}">
													Wohngebäude 
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(144.3167279) + 'px', 
														top: gib_hoehe_in_mm(173.3076809) + 'px'}">
													(ohne Wohnheim) 
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.5601343) + 'px', 
														top: gib_hoehe_in_mm(178.8066266) + 'px'}">
													ohne Eigentumswohnungen ............................................... 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(114.5629559) + 'px', 
														top: gib_hoehe_in_mm(183.805091) + 'px'}">
													mit Eigentumswohnungen ................................................... 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(188.8042608) + 'px'}">
													Wohnheim ................................................................................. 3
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(193.8577464) + 'px'}">
													Nichtwohngebäude Bitte Nutzungsart angeben
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.handel!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(147.7571112) + 'px'}"
								v-model="formular.handel.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.privaterHaushalt!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(153.2828621) + 'px'}"
								v-model="formular.privaterHaushalt.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ohneEigentumswhg!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(178.3862846) + 'px'}"
								v-model="formular.ohneEigentumswhg.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.mitEigentumswhg!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(183.3851017) + 'px'}"
								v-model="formular.mitEigentumswhg.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wohnheim!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(192.1537816) + 'px',top: gib_hoehe_in_mm(188.3842715) + 'px'}"
								v-model="formular.wohnheim.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.1996727) + 'px', 
														top: gib_hoehe_in_mm(221.9397204) + 'px'}">
													Wurde ein Abgangsbogen ausgestellt ? ...............
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.1989673) + 'px', 
														top: gib_hoehe_in_mm(228.9916042) + 'px'}">
													Bei Wiederaufbau, Ersatzbau, Wiederherstellung
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.1989673) + 'px', 
														top: gib_hoehe_in_mm(234.0447371) + 'px'}">
													In welchem Jahr wurde das Gebäude
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.3929523) + 'px', 
														top: gib_hoehe_in_mm(237.7946435) + 'px'}">
													(Gebäudeteil) abgebrochen, zerstört o.Ä.?
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(75.8541309) + 'px', 
														top: gib_hoehe_in_mm(238.0619901) + 'px'}">
													........
													</div>
<b-popover 
												v-if="formular.Abbruch_Jahr"
												:show="formular.Abbruch_Jahr.showPop==true"
												
												ref='Abbruch_Jahr' 
												target='Abbruch_Jahr'  
												
												>
												<template slot='title'>
													<b-button @click="formular.Abbruch_Jahr.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.Abbruch_Jahr.size}}</span>
												<b-button @click='plus("Abbruch_Jahr", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("Abbruch_Jahr", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="Abbruch_Jahr" type="text" 
												v-if="formular.Abbruch_Jahr!=undefined" 
												class='textfeld' v-model='formular.Abbruch_Jahr.text' 
												style='width:100%' 
												@focus='formular.Abbruch_Jahr.showPop=true'
												@blur ='formular.Abbruch_Jahr.showPop=false'
												:disabled='formular.Abbruch_Jahr.isDisabled==true || formular.Abbruch_Jahr.isDisabled==true' 
												@keypress="isNumber($event, formular.Abbruch_Jahr.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.3848071) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(86.1437364) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(236.9725119) + 'px', 
		
														'font-size': formular.Abbruch_Jahr.size + 'px', 
														'text-align': formular.Abbruch_Jahr.schriftausrichtung, 
														'letter-spacing': formular.Abbruch_Jahr.letterSpacing + 'px',
														'font-weight': formular.Abbruch_Jahr.schriftart }" 
												/>

<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.2584336) + 'px', 
														top: gib_hoehe_in_mm(235.634356) + 'px'}">
													Bei
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.2584336) + 'px', 
														top: gib_hoehe_in_mm(237.9910974) + 'px'}">
													Baumaß
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.2584336) + 'px', 
														top: gib_hoehe_in_mm(240.3478388) + 'px'}">
													nahmen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.1996727) + 'px', 
														top: gib_hoehe_in_mm(246.6128489) + 'px'}">
													Wurde ein Abgangsbogen ausgestellt ? ..............
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(246.8064812) + 'px'}">
													Leichtbeton/Bims ............ 4
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(246.8064812) + 'px'}">
													Sonstiges ........................ 8
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.leichtbeton!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(246.3864919) + 'px'}"
								v-model="formular.leichtbeton.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.sonstigesMaterial!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(246.3864919) + 'px'}"
								v-model="formular.sonstigesMaterial.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.6148321) + 'px', 
														top: gib_hoehe_in_mm(206.6311296) + 'px'}">
													(z.B. Bankgebäude, Werkhalle, Kirche, Schule)
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(210.7591304) + 'px'}">
													Haustyp des Wohngebäudes
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(89.5713393) + 'px', 
														top: gib_hoehe_in_mm(216.9927502) + 'px'}">
													Ja Nein
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.abgangsbogen_ja!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(89.2144069) + 'px',top: gib_hoehe_in_mm(221.5197311) + 'px'}"
								v-model="formular.abgangsbogen_ja.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.abgangsbogen_nein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.1285467) + 'px',top: gib_hoehe_in_mm(221.5197311) + 'px'}"
								v-model="formular.abgangsbogen_nein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(215.7583002) + 'px'}">
													Einzelhaus ....................... 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(215.7583002) + 'px'}">
													Gereihtes Haus .............. 3
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.einzelhaus!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(215.3383109) + 'px'}"
								v-model="formular.einzelhaus.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.gereihtesHaus!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(215.3383109) + 'px'}"
								v-model="formular.gereihtesHaus.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(220.7571173) + 'px'}">
													Doppelhaushälfte ........... 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(220.7571173) + 'px'}">
													Sonstiger Haustyp ......... 4
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.doppelhaushaelfte!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(220.337128) + 'px'}"
								v-model="formular.doppelhaushaelfte.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.sonstigerHaustyp!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(220.337128) + 'px'}"
								v-model="formular.sonstigerHaustyp.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(226.8105074) + 'px'}">
													Überwiegend verwendeter Baustoff/Tragkonstruktion
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(231.8103826) + 'px'}">
													Ziegel ............................... 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(231.8093245) + 'px'}">
													Stahl ................................ 5
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ziegel!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(231.3896879) + 'px'}"
								v-model="formular.ziegel.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.stahl!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(231.3896879) + 'px'}"
								v-model="formular.stahl.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(236.8081416) + 'px'}">
													Kalksandstein .................. 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(236.8081416) + 'px'}">
													Stahlbeton ...................... 6
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.porenbeton!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(241.3866167) + 'px'}"
								v-model="formular.porenbeton.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.holz!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(241.3866167) + 'px'}"
								v-model="formular.holz.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.kalksandstein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(236.388505) + 'px'}"
								v-model="formular.kalksandstein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.stahlbeton!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(236.388505) + 'px'}"
								v-model="formular.stahlbeton.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(241.8069587) + 'px'}">
													Porenbeton ...................... 3
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(241.8069587) + 'px'}">
													Holz ................................. 7
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(197.6929351) + 'px', 
														top: gib_hoehe_in_mm(232.1588502) + 'px'}">
													Nur bei
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(197.6929351) + 'px', 
														top: gib_hoehe_in_mm(234.5152389) + 'px'}">
													Errichtung
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(197.6929351) + 'px', 
														top: gib_hoehe_in_mm(236.8719803) + 'px'}">
													eines
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(197.6929351) + 'px', 
														top: gib_hoehe_in_mm(239.2287217) + 'px'}">
													neuen
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(197.6929351) + 'px', 
														top: gib_hoehe_in_mm(241.5851104) + 'px'}">
													Gebäudes
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(89.5713393) + 'px', 
														top: gib_hoehe_in_mm(241.6849245) + 'px'}">
													Ja Nein
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wa_abgangsbogen_ja!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(89.2144069) + 'px',top: gib_hoehe_in_mm(246.1925069) + 'px'}"
								v-model="formular.wa_abgangsbogen_ja.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wa_abgangsbogen_nein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.1285467) + 'px',top: gib_hoehe_in_mm(246.1925069) + 'px'}"
								v-model="formular.wa_abgangsbogen_nein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(252.8595186) + 'px'}">
													Vorwiegende Art der Beheizung
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(257.8593938) + 'px'}">
													Fernheizung .................... 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(257.8586884) + 'px'}">
													Etagenheizung ............... 4
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ferneheizung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(257.4379937) + 'px'}"
								v-model="formular.ferneheizung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.etagenheizung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(257.4379937) + 'px'}"
								v-model="formular.etagenheizung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(262.8575055) + 'px'}">
													Blockheizung ................... 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(262.8575055) + 'px'}">
													Einzelraumheizung ........ 5
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.blockheizung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(262.4368108) + 'px'}"
								v-model="formular.blockheizung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.einzelraumhzg!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(262.4368108) + 'px'}"
								v-model="formular.einzelraumhzg.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(110.5612217) + 'px', 
														top: gib_hoehe_in_mm(267.8563226) + 'px'}">
													Zentralheizung ................ 3
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(155.9671143) + 'px', 
														top: gib_hoehe_in_mm(267.8563226) + 'px'}">
													Keine Heizung ................ 6
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.zentralheizung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(147.3637032) + 'px',top: gib_hoehe_in_mm(267.4359806) + 'px'}"
								v-model="formular.zentralheizung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.keinehzg!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(191.9700249) + 'px',top: gib_hoehe_in_mm(267.4359806) + 'px'}"
								v-model="formular.keinehzg.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(195.833148) + 'px', 
														top: gib_hoehe_in_mm(279.5924151) + 'px'}">
													Seite 1
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(154.9672098) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(32.1226911) + 'px', 
													top: gib_hoehe_in_mm(23.1185744) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(105.6227163) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(55.1412655) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1050482) + 'px', 
													width: spalte_breit_mm(1.0577473) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(122.420554) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.1627955) + 'px', 
													width: spalte_breit_mm(104.4193039) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(122.420554) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(197.0908762) + 'px', 
													width: spalte_breit_mm(12.4912232) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(208.7315345) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(197.0908762) + 'px', 
													width: spalte_breit_mm(12.4912232) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(271.3819882) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.6155668) + 'px', 
													width: spalte_breit_mm(90.0333763) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(101.0352949) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.6155668) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(100.0886481) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.5939219) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(100.08759) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.6155668) + 'px', 
													width: spalte_breit_mm(90.0333763) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.2741759) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.6155668) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(110.3271764) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.5939219) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.3261183) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(110.6148321) + 'px', 
													width: spalte_breit_mm(69.9844975) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(204.9354244) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(180.5993296) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.099427) + 'px', 
													top: gib_hoehe_in_mm(198.9359974) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(110.6148321) + 'px', 
													width: spalte_breit_mm(69.9844975) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(198.9359974) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(110.6148321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.099427) + 'px', 
													top: gib_hoehe_in_mm(198.9359974) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.1810917) + 'px', 
													top: gib_hoehe_in_mm(23.1185744) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.8968155) + 'px', 
													top: gib_hoehe_in_mm(27.2000188) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(11.1384519) + 'px', 
													top: gib_hoehe_in_mm(30.9968343) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(9.1389956) + 'px', 
													top: gib_hoehe_in_mm(42.0352862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(39.7279612) + 'px', 
													top: gib_hoehe_in_mm(51.0742818) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.1533901) + 'px', 
													top: gib_hoehe_in_mm(90.702243) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.1807097) + 'px', 
													top: gib_hoehe_in_mm(96.7556331) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.6805811) + 'px', 
													top: gib_hoehe_in_mm(104.8363428) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.1531991) + 'px', 
													top: gib_hoehe_in_mm(112.4169239) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.0981779) + 'px', 
													top: gib_hoehe_in_mm(120.470123) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.0981779) + 'px', 
													top: gib_hoehe_in_mm(128.4683009) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.0981779) + 'px', 
													top: gib_hoehe_in_mm(136.4664788) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(11.6794937) + 'px', 
													top: gib_hoehe_in_mm(144.4646567) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.7323376) + 'px', 
													width: spalte_breit_mm(40.7886969) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.4418831) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.7323376) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(38.4952363) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.7323376) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(38.4952363) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.5210345) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(38.4952363) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.6776691) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.2010173) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.4623387) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(23.7240128) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(27.9856869) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2470083) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.5086824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(40.7703565) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(45.0320306) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(49.293352) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.5550261) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(57.8167002) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(62.0783743) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.3396957) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.6013698) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.8630439) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.1243653) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(83.3860394) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.6477135) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.9093876) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(10.9393432) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.5343274) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.6776691) + 'px', 
													width: spalte_breit_mm(85.2317185) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.4813269) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1785845) + 'px', 
													width: spalte_breit_mm(68.9852984) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(76.1526626) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2314895) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.2056631) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0958118) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.2056631) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1785845) + 'px', 
													width: spalte_breit_mm(68.9852984) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(69.6541651) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2314895) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(68.7071656) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0958118) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(68.7071656) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1785845) + 'px', 
													width: spalte_breit_mm(68.9852984) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(89.1496576) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2314895) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(88.2030108) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0958118) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(88.2030108) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1785845) + 'px', 
													width: spalte_breit_mm(68.9852984) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.6511601) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2314895) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(81.7041606) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0958118) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(81.7041606) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9342589) + 'px', 
													width: spalte_breit_mm(56.9765688) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.2837574) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(102.3371106) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.854043) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(102.3371106) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.7543906) + 'px', 
													width: spalte_breit_mm(11.9015088) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.2837574) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.7635608) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(102.3371106) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.6558994) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(102.3371106) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.7759704) + 'px', 
													width: spalte_breit_mm(56.9197841) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.3641144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.8186471) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.4171149) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.6957545) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(110.4171149) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9677654) + 'px', 
													width: spalte_breit_mm(11.9135006) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.3641144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.4171149) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(42.8692742) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.4171149) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(56.6238688) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(127.4154914) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.5546007) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.5138492) + 'px', 
													width: spalte_breit_mm(12.0750372) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(127.4154914) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.5671069) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.5663722) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.5652848) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.5649028) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(126.4688446) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(56.6238688) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(135.4136693) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.5546007) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.5138492) + 'px', 
													width: spalte_breit_mm(12.0750372) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(135.4136693) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.5671069) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.5663722) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.5652848) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.5649028) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(134.4670225) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(56.6238688) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(143.4118472) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(30.9769356) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.5546007) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.6834979) + 'px', 
													width: spalte_breit_mm(12.0743318) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(143.4118472) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.7364029) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.7353155) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7345808) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.7338461) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(142.4652004) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.6644738) + 'px', 
													width: spalte_breit_mm(8.1043406) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(154.9913409) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.7173788) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.7162914) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.7155567) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(85.7678225) + 'px', 
													width: spalte_breit_mm(16.1028712) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(154.9913409) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(85.8210802) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8199928) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.8192581) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8181707) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.8177887) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(154.0446941) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(0.6098183) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(1.5275437) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(3.826795) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.126399) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(8.426003) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(10.725607) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(13.0248583) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.3244623) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.6240663) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.9233176) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(22.2229216) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(24.5225256) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(26.8217769) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(29.1213809) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.4209849) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(33.7205889) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.0198402) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(38.3194442) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(40.6190482) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(42.9182995) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(45.2179035) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.5175075) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(49.8167588) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(52.1163628) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.4159668) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(56.7152181) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.0148221) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(61.3144261) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(63.6140301) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(65.9132814) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.2128854) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.5124894) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(72.8117407) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(75.1113447) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(77.4109487) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.7102) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.009804) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(84.309408) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.6086593) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(88.9082633) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.2078673) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.5074713) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(95.8067226) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.1063266) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(100.4059306) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.7055346) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.0051386) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(107.3043899) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.6039939) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(111.9035979) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(114.2028492) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(116.5024532) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(118.8020572) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(121.1013085) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(123.4009125) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(125.7005165) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(128.0001205) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(130.2993718) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(132.5989758) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(134.8985798) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(137.1978311) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.4974351) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.7970391) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(144.0962904) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(146.3958944) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.6954984) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(150.9951024) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(153.2943537) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(155.5939577) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.8935617) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.192813) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.492417) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(164.792021) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.0912723) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(169.3908763) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6904803) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(173.9897316) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.2893356) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.5889396) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(180.8885436) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.1877949) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(185.4873989) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.7870029) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0862542) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(192.3858582) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(194.6854622) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.9847135) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(199.2843175) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.5839215) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.8835255) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(206.1827768) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.4823808) + 'px', 
													width: spalte_breit_mm(1.0997186) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6420127) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.1085017) + 'px', 
													top: gib_hoehe_in_mm(156.3400657) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4104422) + 'px', 
													top: gib_hoehe_in_mm(163.4765975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.3120389) + 'px', 
													top: gib_hoehe_in_mm(166.7870397) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.1531329) + 'px', 
													top: gib_hoehe_in_mm(169.9990786) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.598593) + 'px', 
													top: gib_hoehe_in_mm(175.0522115) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.0459121) + 'px', 
													top: gib_hoehe_in_mm(180.5508045) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(103.8415813) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(163.4314519) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5460407) + 'px', 
													top: gib_hoehe_in_mm(185.6885854) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.1538383) + 'px', 
													top: gib_hoehe_in_mm(191.1346261) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.6801329) + 'px', 
													top: gib_hoehe_in_mm(196.1884644) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.1539338) + 'px', 
													top: gib_hoehe_in_mm(204.7685973) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.2043406) + 'px', 
													top: gib_hoehe_in_mm(208.8225311) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(9.1389956) + 'px', 
													top: gib_hoehe_in_mm(216.9268717) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.1525892) + 'px', 
													top: gib_hoehe_in_mm(225.9658673) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.6801329) + 'px', 
													top: gib_hoehe_in_mm(233.0195146) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.1385474) + 'px', 
													top: gib_hoehe_in_mm(241.5996475) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.21415) + 'px', 
													top: gib_hoehe_in_mm(251.6381949) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.2137973) + 'px', 
													top: gib_hoehe_in_mm(256.7523449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.9593831) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(18.450981) + 'px', 
													top: gib_hoehe_in_mm(261.8661422) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(15.3554999) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(185.6356804) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(15.3554999) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(280.2171232) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.4733017) + 'px', 
													width: spalte_breit_mm(86.4813346) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(215.8740622) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.9546363) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.0987216) + 'px', 
													top: gib_hoehe_in_mm(209.8753406) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.4733017) + 'px', 
													width: spalte_breit_mm(86.4813346) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(209.8753406) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.4733017) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.0987216) + 'px', 
													top: gib_hoehe_in_mm(209.8753406) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(85.8937364) + 'px', 
													width: spalte_breit_mm(15.9970612) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(240.546838) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(85.8937364) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(239.5998385) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8930017) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(239.5998385) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.892267) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(239.5998385) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8911796) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(239.5998385) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.8907976) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0469995) + 'px', 
													top: gib_hoehe_in_mm(239.5998385) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(209.4642976) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(15.4060835) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.4733017) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(116.8405257) + 'px', 
													top: gib_hoehe_in_mm(163.4765975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.5820994) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(149.0085292) + 'px', 
													top: gib_hoehe_in_mm(122.473459) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(197.0908762) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(149.0085292) + 'px', 
													top: gib_hoehe_in_mm(122.473459) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(205.6868806) + 'px', 
													width: spalte_breit_mm(3.8952188) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.12359249999997) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.5820994) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4319569) + 'px', 
													top: gib_hoehe_in_mm(0.12359249999997) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'15px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(25.7333447) + 'px', 
														top: gib_hoehe_in_mm(18.1186228) + 'px'}">
													Angaben zum Gebäude
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.6047286) + 'px', 
														top: gib_hoehe_in_mm(18.1186228) + 'px'}">
													4 Größe des Bauvorhabens 4
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(23.728669) + 'px'}">
													Verwendete Energie (Bitte jeweils eine Position ankreuzen.)
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(23.728669) + 'px'}">
													Werte ohne Kommastellen angeben.
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(31.3071339) + 'px'}">
													Heizung
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(40.567554) + 'px', 
														top: gib_hoehe_in_mm(31.6069289) + 'px'}">
													Primär Sekundär
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(83.4678658) + 'px', 
														top: gib_hoehe_in_mm(31.3984832) + 'px'}">
													Primär Sekundär
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.613163) + 'px', 
														top: gib_hoehe_in_mm(29.3351882) + 'px'}">
													Warmwasser
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.613163) + 'px', 
														top: gib_hoehe_in_mm(32.8621882) + 'px'}">
													bereitung
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(29.587016) + 'px'}">
													Rauminhalt Brutto in cbm (DIN 277) ... 01
													</div>
<b-popover 
												v-if="formular.cbm"
												:show="formular.cbm.showPop==true"
												
												ref='cbm' 
												target='cbm'  
												
												>
												<template slot='title'>
													<b-button @click="formular.cbm.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.cbm.size}}</span>
												<b-button @click='plus("cbm", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("cbm", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="cbm" type="text" 
												v-if="formular.cbm!=undefined" 
												class='textfeld' v-model='formular.cbm.text' 
												style='width:100%' 
												@focus='formular.cbm.showPop=true'
												@blur ='formular.cbm.showPop=false'
												:disabled='formular.cbm.isDisabled==true || formular.cbm.isDisabled==true' 
												@keypress="isNumber($event, formular.cbm.onylNumber) " 
												:style="{'width': spalte_breit_mm(28.9934794) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(167.7444084) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(29.406093) + 'px', 
		
														'font-size': formular.cbm.size + 'px', 
														'text-align': formular.cbm.schriftausrichtung, 
														'letter-spacing': formular.cbm.letterSpacing + 'px',
														'font-weight': formular.cbm.schriftart }" 
												/>

<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(200.2062753) + 'px', 
														top: gib_hoehe_in_mm(28.3109474) + 'px'}">
													nur
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(200.2062753) + 'px', 
														top: gib_hoehe_in_mm(30.6673361) + 'px'}">
													Neubau
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg1!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(39.3579405) + 'px'}"
								v-model="formular.hzg1.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg13!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(39.3579405) + 'px'}"
								v-model="formular.hzg13.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg25!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(38.9406964) + 'px'}"
								v-model="formular.hzg25.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg37!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(38.9406964) + 'px'}"
								v-model="formular.hzg37.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(37.7255685) + 'px'}">
													Anzahl der Vollgeschosse (laut LBO ) ......................
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(180.0798025) + 'px', 
														top: gib_hoehe_in_mm(38.2821291) + 'px'}">
													02
													</div>
<b-popover 
												v-if="formular.vollgesch"
												:show="formular.vollgesch.showPop==true"
												
												ref='vollgesch' 
												target='vollgesch'  
												
												>
												<template slot='title'>
													<b-button @click="formular.vollgesch.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.vollgesch.size}}</span>
												<b-button @click='plus("vollgesch", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("vollgesch", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="vollgesch" type="text" 
												v-if="formular.vollgesch!=undefined" 
												class='textfeld' v-model='formular.vollgesch.text' 
												style='width:100%' 
												@focus='formular.vollgesch.showPop=true'
												@blur ='formular.vollgesch.showPop=false'
												:disabled='formular.vollgesch.isDisabled==true || formular.vollgesch.isDisabled==true' 
												@keypress="isNumber($event, formular.vollgesch.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(183.7848517) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(36.904495) + 'px', 
		
														'font-size': formular.vollgesch.size + 'px', 
														'text-align': formular.vollgesch.schriftausrichtung, 
														'letter-spacing': formular.vollgesch.letterSpacing + 'px',
														'font-weight': formular.vollgesch.schriftart }" 
												/>

<b-popover 
												v-if="formular.identnummer_Seite2"
												:show="formular.identnummer_Seite2.showPop==true"
												
												ref='identnummer_Seite2' 
												target='identnummer_Seite2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.identnummer_Seite2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.identnummer_Seite2.size}}</span>
												<b-button @click='plus("identnummer_Seite2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("identnummer_Seite2", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="identnummer_Seite2" type="text" 
												v-if="formular.identnummer_Seite2!=undefined" 
												class='textfeld' v-model='formular.identnummer_Seite2.text' 
												style='width:100%' 
												@focus='formular.identnummer_Seite2.showPop=true'
												@blur ='formular.identnummer_Seite2.showPop=false'
												:disabled='formular.identnummer_Seite2.isDisabled==true || formular.identnummer_Seite2.isDisabled==true' 
												@keypress="isNumber($event, formular.identnummer_Seite2.onylNumber) " 
												:style="{'width': spalte_breit_mm(39.2771533) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(163.003415) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(9.6704118) + 'px', 
		
														'font-size': formular.identnummer_Seite2.size + 'px', 
														'text-align': formular.identnummer_Seite2.schriftausrichtung, 
														'letter-spacing': formular.identnummer_Seite2.letterSpacing + 'px',
														'font-weight': formular.identnummer_Seite2.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(162.4864211) + 'px', 
														top: gib_hoehe_in_mm(13.7359726) + 'px'}">
													Identifikationsnummer
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg10!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.1749034) + 'px',top: gib_hoehe_in_mm(99.5920465) + 'px'}"
								v-model="formular.hzg10.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg22!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3273729) + 'px',top: gib_hoehe_in_mm(99.5920465) + 'px'}"
								v-model="formular.hzg22.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg34!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1165473) + 'px',top: gib_hoehe_in_mm(99.1751551) + 'px'}"
								v-model="formular.hzg34.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg46!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.6139574) + 'px',top: gib_hoehe_in_mm(99.1751551) + 'px'}"
								v-model="formular.hzg46.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg11!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(105.0913449) + 'px'}"
								v-model="formular.hzg11.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg23!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(105.0913449) + 'px'}"
								v-model="formular.hzg23.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg35!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(104.6733954) + 'px'}"
								v-model="formular.hzg35.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg47!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(104.6733954) + 'px'}"
								v-model="formular.hzg47.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(101.4912596) + 'px'}">
													1 Raum ......................
													</div>
<b-popover 
												v-if="formular.raum1neu"
												:show="formular.raum1neu.showPop==true"
												
												ref='raum1neu' 
												target='raum1neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum1neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum1neu.size}}</span>
												<b-button @click='plus("raum1neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum1neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum1neu" type="text" 
												v-if="formular.raum1neu!=undefined" 
												class='textfeld' v-model='formular.raum1neu.text' 
												style='width:100%' 
												@focus='formular.raum1neu.showPop=true'
												@blur ='formular.raum1neu.showPop=false'
												:disabled='formular.raum1neu.isDisabled==true || formular.raum1neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum1neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(100.6694807) + 'px', 
		
														'font-size': formular.raum1neu.size + 'px', 
														'text-align': formular.raum1neu.schriftausrichtung, 
														'letter-spacing': formular.raum1neu.letterSpacing + 'px',
														'font-weight': formular.raum1neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum1alt"
												:show="formular.raum1alt.showPop==true"
												
												ref='raum1alt' 
												target='raum1alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum1alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum1alt.size}}</span>
												<b-button @click='plus("raum1alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum1alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum1alt" type="text" 
												v-if="formular.raum1alt!=undefined" 
												class='textfeld' v-model='formular.raum1alt.text' 
												style='width:100%' 
												@focus='formular.raum1alt.showPop=true'
												@blur ='formular.raum1alt.showPop=false'
												:disabled='formular.raum1alt.isDisabled==true || formular.raum1alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum1alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(100.6694807) + 'px', 
		
														'font-size': formular.raum1alt.size + 'px', 
														'text-align': formular.raum1alt.schriftausrichtung, 
														'letter-spacing': formular.raum1alt.letterSpacing + 'px',
														'font-weight': formular.raum1alt.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg12!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(110.5902906) + 'px'}"
								v-model="formular.hzg12.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg24!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(110.5902906) + 'px'}"
								v-model="formular.hzg24.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(110.708016) + 'px'}">
													Sonst. Energie .. 12
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg36!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(110.1723411) + 'px'}"
								v-model="formular.hzg36.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg48!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(110.1723411) + 'px'}"
								v-model="formular.hzg48.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(108.9896616) + 'px'}">
													2 Räumen .................
													</div>
<b-popover 
												v-if="formular.raum2neu"
												:show="formular.raum2neu.showPop==true"
												
												ref='raum2neu' 
												target='raum2neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum2neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum2neu.size}}</span>
												<b-button @click='plus("raum2neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum2neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum2neu" type="text" 
												v-if="formular.raum2neu!=undefined" 
												class='textfeld' v-model='formular.raum2neu.text' 
												style='width:100%' 
												@focus='formular.raum2neu.showPop=true'
												@blur ='formular.raum2neu.showPop=false'
												:disabled='formular.raum2neu.isDisabled==true || formular.raum2neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum2neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(108.1678827) + 'px', 
		
														'font-size': formular.raum2neu.size + 'px', 
														'text-align': formular.raum2neu.schriftausrichtung, 
														'letter-spacing': formular.raum2neu.letterSpacing + 'px',
														'font-weight': formular.raum2neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum2alt"
												:show="formular.raum2alt.showPop==true"
												
												ref='raum2alt' 
												target='raum2alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum2alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum2alt.size}}</span>
												<b-button @click='plus("raum2alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum2alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum2alt" type="text" 
												v-if="formular.raum2alt!=undefined" 
												class='textfeld' v-model='formular.raum2alt.text' 
												style='width:100%' 
												@focus='formular.raum2alt.showPop=true'
												@blur ='formular.raum2alt.showPop=false'
												:disabled='formular.raum2alt.isDisabled==true || formular.raum2alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum2alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(108.1678827) + 'px', 
		
														'font-size': formular.raum2alt.size + 'px', 
														'text-align': formular.raum2alt.schriftausrichtung, 
														'letter-spacing': formular.raum2alt.letterSpacing + 'px',
														'font-weight': formular.raum2alt.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum3neu"
												:show="formular.raum3neu.showPop==true"
												
												ref='raum3neu' 
												target='raum3neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum3neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum3neu.size}}</span>
												<b-button @click='plus("raum3neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum3neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum3neu" type="text" 
												v-if="formular.raum3neu!=undefined" 
												class='textfeld' v-model='formular.raum3neu.text' 
												style='width:100%' 
												@focus='formular.raum3neu.showPop=true'
												@blur ='formular.raum3neu.showPop=false'
												:disabled='formular.raum3neu.isDisabled==true || formular.raum3neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum3neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(115.6662847) + 'px', 
		
														'font-size': formular.raum3neu.size + 'px', 
														'text-align': formular.raum3neu.schriftausrichtung, 
														'letter-spacing': formular.raum3neu.letterSpacing + 'px',
														'font-weight': formular.raum3neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum3alt"
												:show="formular.raum3alt.showPop==true"
												
												ref='raum3alt' 
												target='raum3alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum3alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum3alt.size}}</span>
												<b-button @click='plus("raum3alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum3alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum3alt" type="text" 
												v-if="formular.raum3alt!=undefined" 
												class='textfeld' v-model='formular.raum3alt.text' 
												style='width:100%' 
												@focus='formular.raum3alt.showPop=true'
												@blur ='formular.raum3alt.showPop=false'
												:disabled='formular.raum3alt.isDisabled==true || formular.raum3alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum3alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(115.6662847) + 'px', 
		
														'font-size': formular.raum3alt.size + 'px', 
														'text-align': formular.raum3alt.schriftausrichtung, 
														'letter-spacing': formular.raum3alt.letterSpacing + 'px',
														'font-weight': formular.raum3alt.schriftart }" 
												/>

<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(116.4309262) + 'px'}">
													Falls Sonstige Energie für Heizung, bitte hier erläutern
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(116.4880636) + 'px'}">
													3 Räumen .................
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.9243312) + 'px', 
														top: gib_hoehe_in_mm(121.4078759) + 'px'}">
													Nur bei
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.9243312) + 'px', 
														top: gib_hoehe_in_mm(123.7646173) + 'px'}">
													Errichtung
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.9243312) + 'px', 
														top: gib_hoehe_in_mm(126.1213587) + 'px'}">
													eines
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.9243312) + 'px', 
														top: gib_hoehe_in_mm(128.4777474) + 'px'}">
													neuen
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(1.9243312) + 'px', 
														top: gib_hoehe_in_mm(130.8344888) + 'px'}">
													Gebäudes
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1151202) + 'px', 
														top: gib_hoehe_in_mm(131.1501553) + 'px'}">
													Falls Sonstige Energie für Warmwasserbereitung, bitte hier erläutern
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(131.4845149) + 'px'}">
													5 Räumen .................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(53.2715264) + 'px'}">
													Nutzfläche
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(56.7985264) + 'px'}">
													( DIN 277 ohne
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(60.3255264) + 'px'}">
													Wohnfläche ) ............ 03
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(66.3785638) + 'px'}">
													Wohnfläche
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6029358) + 'px', 
														top: gib_hoehe_in_mm(69.9055638) + 'px'}">
													( Wo FlV ) der
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6057574) + 'px', 
														top: gib_hoehe_in_mm(73.4325638) + 'px'}">
													Wohnungen ............. 04
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(82.3851479) + 'px'}">
													Anzahl der
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(85.9121479) + 'px'}">
													Wohnungen mit
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(89.9660817) + 'px'}">
													(Räume, ein
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(93.4930817) + 'px'}">
													schließl. Küchen)
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(143.439563) + 'px', 
														top: gib_hoehe_in_mm(45.331544) + 'px'}">
													neuer Zustand
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.372359) + 'px', 
														top: gib_hoehe_in_mm(48.717464) + 'px'}">
													in vollen qm
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(173.5121758) + 'px', 
														top: gib_hoehe_in_mm(45.331544) + 'px'}">
													alter Zustand
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.5646326) + 'px', 
														top: gib_hoehe_in_mm(48.717464) + 'px'}">
													in vollen qm
													</div>
<b-popover 
												v-if="formular.nutzneu"
												:show="formular.nutzneu.showPop==true"
												
												ref='nutzneu' 
												target='nutzneu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nutzneu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nutzneu.size}}</span>
												<b-button @click='plus("nutzneu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nutzneu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="nutzneu" type="text" 
												v-if="formular.nutzneu!=undefined" 
												class='textfeld' v-model='formular.nutzneu.text' 
												style='width:100%' 
												@focus='formular.nutzneu.showPop=true'
												@blur ='formular.nutzneu.showPop=false'
												:disabled='formular.nutzneu.isDisabled==true || formular.nutzneu.isDisabled==true' 
												@keypress="isNumber($event, formular.nutzneu.onylNumber) " 
												:style="{'width': spalte_breit_mm(24.4944382) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(142.1500275) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(59.5041002) + 'px', 
		
														'font-size': formular.nutzneu.size + 'px', 
														'text-align': formular.nutzneu.schriftausrichtung, 
														'letter-spacing': formular.nutzneu.letterSpacing + 'px',
														'font-weight': formular.nutzneu.schriftart }" 
												/>

<b-popover 
												v-if="formular.nutzalt"
												:show="formular.nutzalt.showPop==true"
												
												ref='nutzalt' 
												target='nutzalt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nutzalt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nutzalt.size}}</span>
												<b-button @click='plus("nutzalt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nutzalt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="nutzalt" type="text" 
												v-if="formular.nutzalt!=undefined" 
												class='textfeld' v-model='formular.nutzalt.text' 
												style='width:100%' 
												@focus='formular.nutzalt.showPop=true'
												@blur ='formular.nutzalt.showPop=false'
												:disabled='formular.nutzalt.isDisabled==true || formular.nutzalt.isDisabled==true' 
												@keypress="isNumber($event, formular.nutzalt.onylNumber) " 
												:style="{'width': spalte_breit_mm(24.9945668) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(171.8512472) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(59.5041002) + 'px', 
		
														'font-size': formular.nutzalt.size + 'px', 
														'text-align': formular.nutzalt.schriftausrichtung, 
														'letter-spacing': formular.nutzalt.letterSpacing + 'px',
														'font-weight': formular.nutzalt.schriftart }" 
												/>

<b-popover 
												v-if="formular.wohnneu"
												:show="formular.wohnneu.showPop==true"
												
												ref='wohnneu' 
												target='wohnneu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.wohnneu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.wohnneu.size}}</span>
												<b-button @click='plus("wohnneu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("wohnneu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="wohnneu" type="text" 
												v-if="formular.wohnneu!=undefined" 
												class='textfeld' v-model='formular.wohnneu.text' 
												style='width:100%' 
												@focus='formular.wohnneu.showPop=true'
												@blur ='formular.wohnneu.showPop=false'
												:disabled='formular.wohnneu.isDisabled==true || formular.wohnneu.isDisabled==true' 
												@keypress="isNumber($event, formular.wohnneu.onylNumber) " 
												:style="{'width': spalte_breit_mm(24.668672) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(142.1708368) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(72.6111376) + 'px', 
		
														'font-size': formular.wohnneu.size + 'px', 
														'text-align': formular.wohnneu.schriftausrichtung, 
														'letter-spacing': formular.wohnneu.letterSpacing + 'px',
														'font-weight': formular.wohnneu.schriftart }" 
												/>

<b-popover 
												v-if="formular.wohnalt"
												:show="formular.wohnalt.showPop==true"
												
												ref='wohnalt' 
												target='wohnalt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.wohnalt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.wohnalt.size}}</span>
												<b-button @click='plus("wohnalt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("wohnalt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="wohnalt" type="text" 
												v-if="formular.wohnalt!=undefined" 
												class='textfeld' v-model='formular.wohnalt.text' 
												style='width:100%' 
												@focus='formular.wohnalt.showPop=true'
												@blur ='formular.wohnalt.showPop=false'
												:disabled='formular.wohnalt.isDisabled==true || formular.wohnalt.isDisabled==true' 
												@keypress="isNumber($event, formular.wohnalt.onylNumber) " 
												:style="{'width': spalte_breit_mm(24.9815169) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(171.8642971) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(72.6111376) + 'px', 
		
														'font-size': formular.wohnalt.size + 'px', 
														'text-align': formular.wohnalt.schriftausrichtung, 
														'letter-spacing': formular.wohnalt.letterSpacing + 'px',
														'font-weight': formular.wohnalt.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(143.4377995) + 'px', 
														top: gib_hoehe_in_mm(91.7295817) + 'px'}">
													neuer Zustand
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(173.5104123) + 'px', 
														top: gib_hoehe_in_mm(91.7295817) + 'px'}">
													alter Zustand
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9926346) + 'px', 
														top: gib_hoehe_in_mm(63.3076049) + 'px'}">
													Bei allen
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9926346) + 'px', 
														top: gib_hoehe_in_mm(65.6643463) + 'px'}">
													Baumaß
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9926346) + 'px', 
														top: gib_hoehe_in_mm(68.020735) + 'px'}">
													nahmen
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(78.4557172) + 'px'}">
													bei
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(80.8124586) + 'px'}">
													Neubau
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(83.1692) + 'px'}">
													ist nur
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(85.5255887) + 'px'}">
													der neue
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(87.8823301) + 'px'}">
													Zustand
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(90.2390715) + 'px'}">
													auszu
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.9700618) + 'px', 
														top: gib_hoehe_in_mm(92.5954602) + 'px'}">
													füllen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.1264468) + 'px', 
														top: gib_hoehe_in_mm(60.8817343) + 'px'}">
													05
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(168.1133969) + 'px', 
														top: gib_hoehe_in_mm(73.9891244) + 'px'}">
													06
													</div>
<b-popover 
												v-if="formular.kosten"
												:show="formular.kosten.showPop==true"
												
												ref='kosten' 
												target='kosten'  
												
												>
												<template slot='title'>
													<b-button @click="formular.kosten.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.kosten.size}}</span>
												<b-button @click='plus("kosten", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("kosten", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="kosten" type="text" 
												v-if="formular.kosten!=undefined" 
												class='textfeld' v-model='formular.kosten.text' 
												style='width:100%' 
												@focus='formular.kosten.showPop=true'
												@blur ='formular.kosten.showPop=false'
												:disabled='formular.kosten.isDisabled==true || formular.kosten.isDisabled==true' 
												@keypress="isNumber($event, formular.kosten.onylNumber) " 
												:style="{'width': spalte_breit_mm(36.4918814) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(159.7899653) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(193.8069697) + 'px', 
		
														'font-size': formular.kosten.size + 'px', 
														'text-align': formular.kosten.schriftausrichtung, 
														'letter-spacing': formular.kosten.letterSpacing + 'px',
														'font-weight': formular.kosten.schriftart }" 
												/>

<b-popover 
												v-if="formular.strassenschluessel"
												:show="formular.strassenschluessel.showPop==true"
												
												ref='strassenschluessel' 
												target='strassenschluessel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.strassenschluessel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.strassenschluessel.size}}</span>
												<b-button @click='plus("strassenschluessel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("strassenschluessel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="strassenschluessel" type="text" 
												v-if="formular.strassenschluessel!=undefined" 
												class='textfeld' v-model='formular.strassenschluessel.text' 
												style='width:100%' 
												@focus='formular.strassenschluessel.showPop=true'
												@blur ='formular.strassenschluessel.showPop=false'
												:disabled='formular.strassenschluessel.isDisabled==true || formular.strassenschluessel.isDisabled==true' 
												@keypress="isNumber($event, formular.strassenschluessel.onylNumber) " 
												:style="{'width': spalte_breit_mm(72.4838583) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(123.7979884) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(208.2507401) + 'px', 
		
														'font-size': formular.strassenschluessel.size + 'px', 
														'text-align': formular.strassenschluessel.schriftausrichtung, 
														'letter-spacing': formular.strassenschluessel.letterSpacing + 'px',
														'font-weight': formular.strassenschluessel.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6418739) + 'px', 
														top: gib_hoehe_in_mm(102.0478202) + 'px'}">
													15
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6214173) + 'px', 
														top: gib_hoehe_in_mm(109.5462222) + 'px'}">
													16
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6789074) + 'px', 
														top: gib_hoehe_in_mm(117.0446242) + 'px'}">
													17
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6337618) + 'px', 
														top: gib_hoehe_in_mm(124.5430262) + 'px'}">
													18
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6312929) + 'px', 
														top: gib_hoehe_in_mm(132.0410755) + 'px'}">
													19
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.5579313) + 'px', 
														top: gib_hoehe_in_mm(139.5394775) + 'px'}">
													20
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.6129525) + 'px', 
														top: gib_hoehe_in_mm(149.1198676) + 'px'}">
													21
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(174.5378274) + 'px', 
														top: gib_hoehe_in_mm(165.7542577) + 'px'}">
													22
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.4033966) + 'px', 
														top: gib_hoehe_in_mm(102.0478202) + 'px'}">
													07
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.3392052) + 'px', 
														top: gib_hoehe_in_mm(109.5462222) + 'px'}">
													08
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.3356782) + 'px', 
														top: gib_hoehe_in_mm(117.0446242) + 'px'}">
													09
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.428791) + 'px', 
														top: gib_hoehe_in_mm(124.5430262) + 'px'}">
													10
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.5434185) + 'px', 
														top: gib_hoehe_in_mm(132.0410755) + 'px'}">
													11
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.4584178) + 'px', 
														top: gib_hoehe_in_mm(139.5394775) + 'px'}">
													12
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.460534) + 'px', 
														top: gib_hoehe_in_mm(149.1198676) + 'px'}">
													13
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(145.4520692) + 'px', 
														top: gib_hoehe_in_mm(165.7542577) + 'px'}">
													14
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(123.9864656) + 'px'}">
													4 Räumen .................
													</div>
<b-popover 
												v-if="formular.raum4neu"
												:show="formular.raum4neu.showPop==true"
												
												ref='raum4neu' 
												target='raum4neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum4neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum4neu.size}}</span>
												<b-button @click='plus("raum4neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum4neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum4neu" type="text" 
												v-if="formular.raum4neu!=undefined" 
												class='textfeld' v-model='formular.raum4neu.text' 
												style='width:100%' 
												@focus='formular.raum4neu.showPop=true'
												@blur ='formular.raum4neu.showPop=false'
												:disabled='formular.raum4neu.isDisabled==true || formular.raum4neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum4neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(123.164334) + 'px', 
		
														'font-size': formular.raum4neu.size + 'px', 
														'text-align': formular.raum4neu.schriftausrichtung, 
														'letter-spacing': formular.raum4neu.letterSpacing + 'px',
														'font-weight': formular.raum4neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum4alt"
												:show="formular.raum4alt.showPop==true"
												
												ref='raum4alt' 
												target='raum4alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum4alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum4alt.size}}</span>
												<b-button @click='plus("raum4alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum4alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum4alt" type="text" 
												v-if="formular.raum4alt!=undefined" 
												class='textfeld' v-model='formular.raum4alt.text' 
												style='width:100%' 
												@focus='formular.raum4alt.showPop=true'
												@blur ='formular.raum4alt.showPop=false'
												:disabled='formular.raum4alt.isDisabled==true || formular.raum4alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum4alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(123.164334) + 'px', 
		
														'font-size': formular.raum4alt.size + 'px', 
														'text-align': formular.raum4alt.schriftausrichtung, 
														'letter-spacing': formular.raum4alt.letterSpacing + 'px',
														'font-weight': formular.raum4alt.schriftart }" 
												/>

<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(138.9829169) + 'px'}">
													6 Räumen .................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(145.036307) + 'px'}">
													7 Räumen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(112.3070867) + 'px', 
														top: gib_hoehe_in_mm(148.563307) + 'px'}">
													oder mehr ..............
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(154.6166971) + 'px'}">
													Anzahl der Räume
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(158.1436971) + 'px'}">
													in Wohnungen
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(161.6706971) + 'px'}">
													mit 7 oder mehr
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(165.1976971) + 'px'}">
													Räumen .....................
													</div>
<b-popover 
												v-if="formular.raum5neu"
												:show="formular.raum5neu.showPop==true"
												
												ref='raum5neu' 
												target='raum5neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum5neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum5neu.size}}</span>
												<b-button @click='plus("raum5neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum5neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum5neu" type="text" 
												v-if="formular.raum5neu!=undefined" 
												class='textfeld' v-model='formular.raum5neu.text' 
												style='width:100%' 
												@focus='formular.raum5neu.showPop=true'
												@blur ='formular.raum5neu.showPop=false'
												:disabled='formular.raum5neu.isDisabled==true || formular.raum5neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum5neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(130.662736) + 'px', 
		
														'font-size': formular.raum5neu.size + 'px', 
														'text-align': formular.raum5neu.schriftausrichtung, 
														'letter-spacing': formular.raum5neu.letterSpacing + 'px',
														'font-weight': formular.raum5neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum5alt"
												:show="formular.raum5alt.showPop==true"
												
												ref='raum5alt' 
												target='raum5alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum5alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum5alt.size}}</span>
												<b-button @click='plus("raum5alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum5alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum5alt" type="text" 
												v-if="formular.raum5alt!=undefined" 
												class='textfeld' v-model='formular.raum5alt.text' 
												style='width:100%' 
												@focus='formular.raum5alt.showPop=true'
												@blur ='formular.raum5alt.showPop=false'
												:disabled='formular.raum5alt.isDisabled==true || formular.raum5alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum5alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(130.662736) + 'px', 
		
														'font-size': formular.raum5alt.size + 'px', 
														'text-align': formular.raum5alt.schriftausrichtung, 
														'letter-spacing': formular.raum5alt.letterSpacing + 'px',
														'font-weight': formular.raum5alt.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum6neu"
												:show="formular.raum6neu.showPop==true"
												
												ref='raum6neu' 
												target='raum6neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum6neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum6neu.size}}</span>
												<b-button @click='plus("raum6neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum6neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum6neu" type="text" 
												v-if="formular.raum6neu!=undefined" 
												class='textfeld' v-model='formular.raum6neu.text' 
												style='width:100%' 
												@focus='formular.raum6neu.showPop=true'
												@blur ='formular.raum6neu.showPop=false'
												:disabled='formular.raum6neu.isDisabled==true || formular.raum6neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum6neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(138.161138) + 'px', 
		
														'font-size': formular.raum6neu.size + 'px', 
														'text-align': formular.raum6neu.schriftausrichtung, 
														'letter-spacing': formular.raum6neu.letterSpacing + 'px',
														'font-weight': formular.raum6neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum6alt"
												:show="formular.raum6alt.showPop==true"
												
												ref='raum6alt' 
												target='raum6alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum6alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum6alt.size}}</span>
												<b-button @click='plus("raum6alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum6alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum6alt" type="text" 
												v-if="formular.raum6alt!=undefined" 
												class='textfeld' v-model='formular.raum6alt.text' 
												style='width:100%' 
												@focus='formular.raum6alt.showPop=true'
												@blur ='formular.raum6alt.showPop=false'
												:disabled='formular.raum6alt.isDisabled==true || formular.raum6alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum6alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(138.161138) + 'px', 
		
														'font-size': formular.raum6alt.size + 'px', 
														'text-align': formular.raum6alt.schriftausrichtung, 
														'letter-spacing': formular.raum6alt.letterSpacing + 'px',
														'font-weight': formular.raum6alt.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum7neu"
												:show="formular.raum7neu.showPop==true"
												
												ref='raum7neu' 
												target='raum7neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum7neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum7neu.size}}</span>
												<b-button @click='plus("raum7neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum7neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum7neu" type="text" 
												v-if="formular.raum7neu!=undefined" 
												class='textfeld' v-model='formular.raum7neu.text' 
												style='width:100%' 
												@focus='formular.raum7neu.showPop=true'
												@blur ='formular.raum7neu.showPop=false'
												:disabled='formular.raum7neu.isDisabled==true || formular.raum7neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum7neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(147.6889758) + 'px', 
		
														'font-size': formular.raum7neu.size + 'px', 
														'text-align': formular.raum7neu.schriftausrichtung, 
														'letter-spacing': formular.raum7neu.letterSpacing + 'px',
														'font-weight': formular.raum7neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum7alt"
												:show="formular.raum7alt.showPop==true"
												
												ref='raum7alt' 
												target='raum7alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum7alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum7alt.size}}</span>
												<b-button @click='plus("raum7alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum7alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum7alt" type="text" 
												v-if="formular.raum7alt!=undefined" 
												class='textfeld' v-model='formular.raum7alt.text' 
												style='width:100%' 
												@focus='formular.raum7alt.showPop=true'
												@blur ='formular.raum7alt.showPop=false'
												:disabled='formular.raum7alt.isDisabled==true || formular.raum7alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum7alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(147.6889758) + 'px', 
		
														'font-size': formular.raum7alt.size + 'px', 
														'text-align': formular.raum7alt.schriftausrichtung, 
														'letter-spacing': formular.raum7alt.letterSpacing + 'px',
														'font-weight': formular.raum7alt.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum8neu"
												:show="formular.raum8neu.showPop==true"
												
												ref='raum8neu' 
												target='raum8neu'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum8neu.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum8neu.size}}</span>
												<b-button @click='plus("raum8neu", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum8neu", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum8neu" type="text" 
												v-if="formular.raum8neu!=undefined" 
												class='textfeld' v-model='formular.raum8neu.text' 
												style='width:100%' 
												@focus='formular.raum8neu.showPop=true'
												@blur ='formular.raum8neu.showPop=false'
												:disabled='formular.raum8neu.isDisabled==true || formular.raum8neu.isDisabled==true' 
												@keypress="isNumber($event, formular.raum8neu.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.4973477) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(149.0703542) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(164.3233659) + 'px', 
		
														'font-size': formular.raum8neu.size + 'px', 
														'text-align': formular.raum8neu.schriftausrichtung, 
														'letter-spacing': formular.raum8neu.letterSpacing + 'px',
														'font-weight': formular.raum8neu.schriftart }" 
												/>

<b-popover 
												v-if="formular.raum8alt"
												:show="formular.raum8alt.showPop==true"
												
												ref='raum8alt' 
												target='raum8alt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.raum8alt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.raum8alt.size}}</span>
												<b-button @click='plus("raum8alt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("raum8alt", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="raum8alt" type="text" 
												v-if="formular.raum8alt!=undefined" 
												class='textfeld' v-model='formular.raum8alt.text' 
												style='width:100%' 
												@focus='formular.raum8alt.showPop=true'
												@blur ='formular.raum8alt.showPop=false'
												:disabled='formular.raum8alt.isDisabled==true || formular.raum8alt.isDisabled==true' 
												@keypress="isNumber($event, formular.raum8alt.onylNumber) " 
												:style="{'width': spalte_breit_mm(12.496995) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.2640386) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(164.3233659) + 'px', 
		
														'font-size': formular.raum8alt.size + 'px', 
														'text-align': formular.raum8alt.schriftausrichtung, 
														'letter-spacing': formular.raum8alt.letterSpacing + 'px',
														'font-weight': formular.raum8alt.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.6047286) + 'px', 
														top: gib_hoehe_in_mm(180.2586925) + 'px'}">
													5 Veranschlagte Kosten des Bauwerks 5
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(185.0476531) + 'px'}">
													bzw. der Baumaßnahme (Kostengruppe 300, 400 DIN 276)
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6039939) + 'px', 
														top: gib_hoehe_in_mm(191.0999851) + 'px'}">
													Kosten in 1 000 Euro
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6011723) + 'px', 
														top: gib_hoehe_in_mm(194.6269851) + 'px'}">
													(einschließlich MwSt) ................... 23
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(120.1232714) + 'px', 
														top: gib_hoehe_in_mm(209.5976893) + 'px'}">
													24
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(123.4947307) + 'px', 
														top: gib_hoehe_in_mm(212.2217773) + 'px'}">
													Straßenschlüssel
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(40.0572682) + 'px'}">
													Keine .......... 00
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(45.5562139) + 'px'}">
													Öl ...............
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(51.0548069) + 'px'}">
													Gas .........
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(37.7438378) + 'px', 
														top: gib_hoehe_in_mm(45.8334361) + 'px'}">
													02
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(37.7417216) + 'px', 
														top: gib_hoehe_in_mm(51.3323818) + 'px'}">
													03
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(56.5537526) + 'px'}">
													Strom ................04
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(62.1951891) + 'px'}">
													Fernwärme/
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(65.6340141) + 'px'}">
													Fernkälte .......05
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(71.1319017) + 'px'}">
													Geothermie ......06
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(76.7743963) + 'px'}">
													Umweltthermie
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(80.3013963) + 'px'}">
													(Luft/Wasser)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(37.7156218) + 'px', 
														top: gib_hoehe_in_mm(80.4907962) + 'px'}">
													07
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(85.7114616) + 'px'}">
													Solarthermie ....08
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(91.2111127) + 'px'}">
													Holz ..................09
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(96.8529019) + 'px'}">
													Biogas/
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(100.2917269) + 'px'}">
													Biomethan ....... 10
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7209633) + 'px', 
														top: gib_hoehe_in_mm(105.8784949) + 'px'}">
													Sonst. Biomasse 11
													</div>
<div :style="{
														fontSize:'10.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(18.7199052) + 'px', 
														top: gib_hoehe_in_mm(111.3774406) + 'px'}">
													Sonst. Energie
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(37.7903942) + 'px', 
														top: gib_hoehe_in_mm(111.2882075) + 'px'}">
													 ..12
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg2!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(44.8568862) + 'px'}"
								v-model="formular.hzg2.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg14!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(44.8568862) + 'px'}"
								v-model="formular.hzg14.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg26!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(44.4396421) + 'px'}"
								v-model="formular.hzg26.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg38!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(44.4396421) + 'px'}"
								v-model="formular.hzg38.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg3!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(50.3554792) + 'px'}"
								v-model="formular.hzg3.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg15!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(50.3554792) + 'px'}"
								v-model="formular.hzg15.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg27!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(49.9382351) + 'px'}"
								v-model="formular.hzg27.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg39!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(49.9382351) + 'px'}"
								v-model="formular.hzg39.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg4!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(55.8544249) + 'px'}"
								v-model="formular.hzg4.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg16!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(55.8544249) + 'px'}"
								v-model="formular.hzg16.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg28!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(55.4371808) + 'px'}"
								v-model="formular.hzg28.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg40!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(55.4371808) + 'px'}"
								v-model="formular.hzg40.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg5!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.220049) + 'px',top: gib_hoehe_in_mm(64.9346864) + 'px'}"
								v-model="formular.hzg5.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg17!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3725185) + 'px',top: gib_hoehe_in_mm(64.9346864) + 'px'}"
								v-model="formular.hzg17.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg29!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1616929) + 'px',top: gib_hoehe_in_mm(64.5174423) + 'px'}"
								v-model="formular.hzg29.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg41!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.659103) + 'px',top: gib_hoehe_in_mm(64.5174423) + 'px'}"
								v-model="formular.hzg41.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg6!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.2239287) + 'px',top: gib_hoehe_in_mm(70.4336321) + 'px'}"
								v-model="formular.hzg6.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg18!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3763982) + 'px',top: gib_hoehe_in_mm(70.4336321) + 'px'}"
								v-model="formular.hzg18.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg30!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1655726) + 'px',top: gib_hoehe_in_mm(70.0167407) + 'px'}"
								v-model="formular.hzg30.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg42!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.6629827) + 'px',top: gib_hoehe_in_mm(70.0167407) + 'px'}"
								v-model="formular.hzg42.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg7!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.1749034) + 'px',top: gib_hoehe_in_mm(79.5142463) + 'px'}"
								v-model="formular.hzg7.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg19!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3273729) + 'px',top: gib_hoehe_in_mm(79.5142463) + 'px'}"
								v-model="formular.hzg19.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg31!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1165473) + 'px',top: gib_hoehe_in_mm(79.0966495) + 'px'}"
								v-model="formular.hzg31.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg43!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.6139574) + 'px',top: gib_hoehe_in_mm(79.0966495) + 'px'}"
								v-model="formular.hzg43.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg8!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.1749034) + 'px',top: gib_hoehe_in_mm(85.0128393) + 'px'}"
								v-model="formular.hzg8.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg20!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.3273729) + 'px',top: gib_hoehe_in_mm(85.0128393) + 'px'}"
								v-model="formular.hzg20.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg32!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.1165473) + 'px',top: gib_hoehe_in_mm(84.5959479) + 'px'}"
								v-model="formular.hzg32.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg44!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.6139574) + 'px',top: gib_hoehe_in_mm(84.5959479) + 'px'}"
								v-model="formular.hzg44.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg9!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(41.0803798) + 'px',top: gib_hoehe_in_mm(90.5121377) + 'px'}"
								v-model="formular.hzg9.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg21!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(51.2328493) + 'px',top: gib_hoehe_in_mm(90.5121377) + 'px'}"
								v-model="formular.hzg21.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg33!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(87.0220237) + 'px',top: gib_hoehe_in_mm(90.0945409) + 'px'}"
								v-model="formular.hzg33.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.hzg45!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.5194338) + 'px',top: gib_hoehe_in_mm(90.0945409) + 'px'}"
								v-model="formular.hzg45.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(39.3606857) + 'px'}">
													Keine ................ 00
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(44.8596314) + 'px'}">
													Öl ...................... 02
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(50.4735573) + 'px'}">
													Gas ................... 03
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(55.972503) + 'px'}">
													Strom ................ 04
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(61.5257645) + 'px'}">
													Fernwärme/
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(65.0527645) + 'px'}">
													Fernkälte .......... 05
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(70.5517102) + 'px'}">
													Geothermie ...... 06
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(76.1049717) + 'px'}">
													Umweltthermie
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(79.6319717) + 'px'}">
													(Luft/Wasser) .. 07
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(85.1312701) + 'px'}">
													Solarthermie .... 08
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(90.6302158) + 'px'}">
													Holz .................. 09
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(96.1834773) + 'px'}">
													Biogas/
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6144783) + 'px', 
														top: gib_hoehe_in_mm(99.7104773) + 'px'}">
													Biomethan ....... 10
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(62.6151837) + 'px', 
														top: gib_hoehe_in_mm(105.209423) + 'px'}">
													Sonst. Biomasse11
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.8751453) + 'px', 
														top: gib_hoehe_in_mm(40.3344904) + 'px'}">
													00
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9439218) + 'px', 
														top: gib_hoehe_in_mm(45.8334361) + 'px'}">
													13
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9396894) + 'px', 
														top: gib_hoehe_in_mm(51.3323818) + 'px'}">
													14
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9379259) + 'px', 
														top: gib_hoehe_in_mm(56.8309748) + 'px'}">
													15
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9276976) + 'px', 
														top: gib_hoehe_in_mm(65.911589) + 'px'}">
													16
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9562663) + 'px', 
														top: gib_hoehe_in_mm(71.410182) + 'px'}">
													17
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9336935) + 'px', 
														top: gib_hoehe_in_mm(80.4907962) + 'px'}">
													18
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9326354) + 'px', 
														top: gib_hoehe_in_mm(85.9897419) + 'px'}">
													19
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.8959546) + 'px', 
														top: gib_hoehe_in_mm(91.4886876) + 'px'}">
													20
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.9234652) + 'px', 
														top: gib_hoehe_in_mm(100.5689491) + 'px'}">
													21
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.8857263) + 'px', 
														top: gib_hoehe_in_mm(106.0678948) + 'px'}">
													22
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(47.8959546) + 'px', 
														top: gib_hoehe_in_mm(111.5664878) + 'px'}">
													23
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(147.2643129) + 'px'}">
													Einsatz von Lüftungs und Kühlungsanlagen
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(152.3177985) + 'px'}">
													Anlagen zur Lüftung
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.7775212) + 'px', 
														top: gib_hoehe_in_mm(152.3177985) + 'px'}">
													Anlagen zur Kühlung
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(156.8718609) + 'px'}">
													mit Wärmerück
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(160.3988609) + 'px'}">
													gewinnung ...................... 1
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(165.4519938) + 'px'}">
													ohne Wärmerück
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(168.9789938) + 'px'}">
													gewinnung ...................... 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(174.9777154) + 'px'}">
													keine Nutzung ............... 3
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg1!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(53.8932654) + 'px',top: gib_hoehe_in_mm(159.9785189) + 'px'}"
								v-model="formular.lftg1.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg2!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(53.8932654) + 'px',top: gib_hoehe_in_mm(168.5586518) + 'px'}"
								v-model="formular.lftg2.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg3!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(53.8932654) + 'px',top: gib_hoehe_in_mm(174.5580788) + 'px'}"
								v-model="formular.lftg3.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.7746996) + 'px', 
														top: gib_hoehe_in_mm(158.8159433) + 'px'}">
													elektrisch .......................
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(95.5940445) + 'px', 
														top: gib_hoehe_in_mm(159.3728566) + 'px'}">
													1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.7775212) + 'px', 
														top: gib_hoehe_in_mm(165.3151462) + 'px'}">
													thermisch ....................... 2
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(61.7775212) + 'px', 
														top: gib_hoehe_in_mm(171.8136437) + 'px'}">
													keine Nutzung ..............
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(95.5940445) + 'px', 
														top: gib_hoehe_in_mm(172.3702043) + 'px'}">
													3
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(182.5307859) + 'px'}">
													Art der Erfüllung des EEWärmeG
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(186.5854251) + 'px'}">
													Mehrfachnennungen möglich.
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(191.6389107) + 'px'}">
													Erneuerbare Energie (Wärme, 5)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8487069) + 'px', 
														top: gib_hoehe_in_mm(196.6387859) + 'px'}">
													Holz, Bioöl, Biogas, Biomethan ..................................
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8487069) + 'px', 
														top: gib_hoehe_in_mm(202.1356154) + 'px'}">
													Sonstige (z. B. Umwelt, Geo, Solarthermie) ............
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(207.6345611) + 'px'}">
													Erneuerbare Energie (Kälte, 5)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(65.432904) + 'px', 
														top: gib_hoehe_in_mm(207.9128414) + 'px'}">
													 .................................
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.3116273) + 'px', 
														top: gib_hoehe_in_mm(208.1911217) + 'px'}">
													03
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(213.1331541) + 'px'}">
													KraftWärme/KraftWärmeKälteKopplung (7) ....
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(218.6320998) + 'px'}">
													Wärmerückgewinnung ( 7) ..........................................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(224.1306928) + 'px'}">
													Sonstige Abwärme ( 7)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(54.7104713) + 'px', 
														top: gib_hoehe_in_mm(224.4089731) + 'px'}">
													 ...............................................
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2693033) + 'px', 
														top: gib_hoehe_in_mm(224.6872534) + 'px'}">
													06
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(229.6296385) + 'px'}">
													Energieeinsparung (Übererfüllung EnEV, 7) ..............
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(235.1285842) + 'px'}">
													Fernwärme oder Fernkälte ( 7) ..................................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8476781) + 'px', 
														top: gib_hoehe_in_mm(240.6818457) + 'px'}">
													Gemeinschaftliche Wärmeversorgung ( 6)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8448565) + 'px', 
														top: gib_hoehe_in_mm(244.2088457) + 'px'}">
													z. B. Quartierslösung .........................................................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(249.7081441) + 'px'}">
													Ausnahme(regelung) ( 9)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(57.7740235) + 'px', 
														top: gib_hoehe_in_mm(249.9864244) + 'px'}">
													 ...........................................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(255.2067371) + 'px'}">
													Befreiung ( 9)
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(41.6901981) + 'px', 
														top: gib_hoehe_in_mm(255.4850174) + 'px'}">
													 ................................................................
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.8473254) + 'px', 
														top: gib_hoehe_in_mm(260.7056828) + 'px'}">
													Sonstiges ..........................................................................
													</div>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg4!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.8234612) + 'px',top: gib_hoehe_in_mm(158.3966594) + 'px'}"
								v-model="formular.lftg4.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg5!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.8234612) + 'px',top: gib_hoehe_in_mm(164.8951569) + 'px'}"
								v-model="formular.lftg5.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lftg6!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.8234612) + 'px',top: gib_hoehe_in_mm(171.3940071) + 'px'}"
								v-model="formular.lftg6.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg1!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(196.2173858) + 'px'}"
								v-model="formular.eeg1.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg2!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(201.7152734) + 'px'}"
								v-model="formular.eeg2.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg3!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(207.2149245) + 'px'}"
								v-model="formular.eeg3.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg4!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(212.7128121) + 'px'}"
								v-model="formular.eeg4.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg5!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(218.2124632) + 'px'}"
								v-model="formular.eeg5.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg6!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(223.7103508) + 'px'}"
								v-model="formular.eeg6.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg7!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(229.2092965) + 'px'}"
								v-model="formular.eeg7.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg8!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(234.7078895) + 'px'}"
								v-model="formular.eeg8.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg9!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(243.7888564) + 'px'}"
								v-model="formular.eeg9.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg10!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(249.2878021) + 'px'}"
								v-model="formular.eeg10.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg11!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(254.7867478) + 'px'}"
								v-model="formular.eeg11.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.eeg12!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(97.7705562) + 'px',top: gib_hoehe_in_mm(260.2853408) + 'px'}"
								v-model="formular.eeg12.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.1620825) + 'px', 
														top: gib_hoehe_in_mm(39.9172463) + 'px'}">
													00
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.230859) + 'px', 
														top: gib_hoehe_in_mm(45.416192) + 'px'}">
													13
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2266266) + 'px', 
														top: gib_hoehe_in_mm(51.0301179) + 'px'}">
													14
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2248631) + 'px', 
														top: gib_hoehe_in_mm(56.5290636) + 'px'}">
													15
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2146348) + 'px', 
														top: gib_hoehe_in_mm(65.6093251) + 'px'}">
													16
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2432035) + 'px', 
														top: gib_hoehe_in_mm(71.1082708) + 'px'}">
													17
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2206307) + 'px', 
														top: gib_hoehe_in_mm(80.1885323) + 'px'}">
													18
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2195726) + 'px', 
														top: gib_hoehe_in_mm(85.6878307) + 'px'}">
													19
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.1828918) + 'px', 
														top: gib_hoehe_in_mm(91.1867764) + 'px'}">
													20
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2104024) + 'px', 
														top: gib_hoehe_in_mm(100.2670379) + 'px'}">
													21
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.1726635) + 'px', 
														top: gib_hoehe_in_mm(105.7659836) + 'px'}">
													22
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.1828918) + 'px', 
														top: gib_hoehe_in_mm(111.2645766) + 'px'}">
													23
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.3673539) + 'px', 
														top: gib_hoehe_in_mm(197.193583) + 'px'}">
													01
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.3158597) + 'px', 
														top: gib_hoehe_in_mm(202.692176) + 'px'}">
													02
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2760046) + 'px', 
														top: gib_hoehe_in_mm(213.6897147) + 'px'}">
													04
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2957558) + 'px', 
														top: gib_hoehe_in_mm(219.1886604) + 'px'}">
													05
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.3539513) + 'px', 
														top: gib_hoehe_in_mm(230.1861991) + 'px'}">
													07
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2897599) + 'px', 
														top: gib_hoehe_in_mm(235.6851448) + 'px'}">
													08
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.2858802) + 'px', 
														top: gib_hoehe_in_mm(244.765759) + 'px'}">
													09
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.3793457) + 'px', 
														top: gib_hoehe_in_mm(250.2647047) + 'px'}">
													10
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.4939732) + 'px', 
														top: gib_hoehe_in_mm(255.7632977) + 'px'}">
													11
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(94.4086198) + 'px', 
														top: gib_hoehe_in_mm(261.2622434) + 'px'}">
													12
													</div>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.6251168) + 'px', 
														top: gib_hoehe_in_mm(279.5536181) + 'px'}">
													Seite 2
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(199.4895889) + 'px', 
														top: gib_hoehe_in_mm(279.5536181) + 'px'}">
													BG
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.4733017) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(256.7834209) + 'px', 
													top: gib_hoehe_in_mm(18.7888292) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.6669213) + 'px', 
													top: gib_hoehe_in_mm(16.134409) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.1534856) + 'px', 
													top: gib_hoehe_in_mm(22.7013303) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(88.3815154) + 'px', 
													top: gib_hoehe_in_mm(27.7544632) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.1542865) + 'px', 
													top: gib_hoehe_in_mm(116.0349205) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.7041165) + 'px', 
													top: gib_hoehe_in_mm(120.089207) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5391484) + 'px', 
													top: gib_hoehe_in_mm(128.6933235) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.7030584) + 'px', 
													top: gib_hoehe_in_mm(136.13353) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.6535187) + 'px', 
													top: gib_hoehe_in_mm(144.7365884) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(28.8129543) + 'px', 
													top: gib_hoehe_in_mm(151.2901071) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.6535187) + 'px', 
													top: gib_hoehe_in_mm(180.0030614) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.1539338) + 'px', 
													top: gib_hoehe_in_mm(186.5565801) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.1531329) + 'px', 
													top: gib_hoehe_in_mm(190.6105139) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.0988171) + 'px', 
													top: gib_hoehe_in_mm(195.6636468) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(200.6624639) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.598593) + 'px', 
													top: gib_hoehe_in_mm(206.1614096) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(211.6600026) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.598593) + 'px', 
													top: gib_hoehe_in_mm(217.1589483) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(222.6575413) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.598593) + 'px', 
													top: gib_hoehe_in_mm(228.156487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(233.65508) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(9.1799088) + 'px', 
													top: gib_hoehe_in_mm(239.1550838) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(248.2349926) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.598593) + 'px', 
													top: gib_hoehe_in_mm(253.7339383) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.5989457) + 'px', 
													top: gib_hoehe_in_mm(259.2325313) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.2161487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.89262) + 'px', 
													top: gib_hoehe_in_mm(264.731477) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(15.6708137) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(275.524097) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.8529252) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.9923679) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(39.6635839) + 'px', 
													width: spalte_breit_mm(9.0509874) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(29.3074013) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(39.7161362) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.9923679) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(48.7142186) + 'px', 
													width: spalte_breit_mm(10.5506678) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(29.3074013) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.2119814) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.9923679) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(39.6635839) + 'px', 
													width: spalte_breit_mm(9.0509874) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(37.3055792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(48.7142186) + 'px', 
													width: spalte_breit_mm(10.5506678) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(37.3055792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.7512672) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.7512672) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(39.3579405) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(48.2502129) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(48.2502129) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.7488059) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.7488059) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.3554792) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(59.2477516) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(59.2477516) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.8544249) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(68.3280131) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(68.3280131) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(64.9346864) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.2239287) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.8273115) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6172554) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.2239287) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.2239287) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.8273115) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(70.4336321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.9072203) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.5682301) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.9079257) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(79.5142463) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(88.4065187) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.5682301) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(88.4065187) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(85.0128393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.0803798) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.9051117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.4737065) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.0803798) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.0803798) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.9051117) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(90.5121377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(102.9857259) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.5682301) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.1749034) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(102.9857259) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4936794) + 'px', 
													top: gib_hoehe_in_mm(99.5920465) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.4846716) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.4846716) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(105.0913449) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.9836173) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.6133757) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(41.220049) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.9836173) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.7658452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(51.3725185) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(110.5902906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.6097467) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.5568417) + 'px', 
													width: spalte_breit_mm(9.0509874) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(29.3074013) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.6074764) + 'px', 
													width: spalte_breit_mm(10.5510205) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(29.3074013) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.6128624) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.1055919) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(29.3603063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.5568417) + 'px', 
													width: spalte_breit_mm(9.0509874) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(36.8883351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.6074764) + 'px', 
													width: spalte_breit_mm(10.5510205) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(36.8883351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.3340231) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(42.3340231) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(38.9406964) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.8326161) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.8326161) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(44.4392894) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.4468947) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.4468947) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(50.053568) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(58.9458404) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(58.9458404) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(55.5525137) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(68.0261019) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(68.0261019) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(64.6331279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.5250476) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(73.5250476) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(70.1317209) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.6056618) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.6056618) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(79.2123351) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(88.1042548) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(88.1042548) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(84.7109281) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.6028478) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.6028478) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(90.2095211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(102.6838147) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(102.6838147) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(99.290488) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.1827604) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.5550196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.1616929) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(108.1827604) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(104.7883756) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.3669643) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.6813534) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.760291) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.3669643) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.3669643) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(113.6813534) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0527824) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(3.3936794) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.659103) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(110.2883794) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(127.640514) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.2695974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.0986261) + 'px', 
													top: gib_hoehe_in_mm(120.6418879) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(120.6418879) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.0986261) + 'px', 
													top: gib_hoehe_in_mm(120.6418879) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(143.6837789) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.2695974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.0982734) + 'px', 
													top: gib_hoehe_in_mm(136.6855055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(136.6855055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.0982734) + 'px', 
													top: gib_hoehe_in_mm(136.6855055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(163.3714929) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(57.2865921) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(159.9785189) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(159.9785189) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(159.9785189) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.9526839) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(57.2865921) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4940321) + 'px', 
													top: gib_hoehe_in_mm(168.5586518) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(168.5586518) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4940321) + 'px', 
													top: gib_hoehe_in_mm(168.5586518) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(177.9514055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(57.2865921) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(174.5570207) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(174.5570207) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.8932654) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4943848) + 'px', 
													top: gib_hoehe_in_mm(174.5570207) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(161.7899861) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.2167879) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(158.3966594) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(158.3966594) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(158.3966594) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(168.2884836) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.2167879) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(164.8951569) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(164.8951569) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(164.8951569) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(174.7869811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.2167879) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(171.3940071) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.3940071) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.8234612) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(171.3940071) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(199.6107125) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(196.2173858) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(196.2173858) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(196.2173858) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(205.1086001) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(201.7152734) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(201.7152734) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(201.7152734) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(210.6082512) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(207.2149245) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(207.2149245) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(207.2149245) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(216.1061388) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(212.7128121) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(212.7128121) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(212.7128121) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(221.6057899) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(218.2124632) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(218.2124632) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(218.2124632) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(227.1036775) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(223.7103508) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(223.7103508) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(223.7103508) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(232.6033286) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4940321) + 'px', 
													top: gib_hoehe_in_mm(229.2092965) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(229.2092965) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4940321) + 'px', 
													top: gib_hoehe_in_mm(229.2092965) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(238.1012162) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(234.7078895) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(234.7078895) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(234.7078895) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(247.1821831) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(243.7888564) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(243.7888564) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(243.7888564) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(252.6811288) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(249.2878021) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(249.2878021) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(249.2878021) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(258.1797218) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(254.7867478) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(254.7867478) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(254.7867478) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(263.6786675) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.1638829) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(260.2853408) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(260.2853408) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(97.7705562) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.4933267) + 'px', 
													top: gib_hoehe_in_mm(260.2853408) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(275.4722501) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.2695974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.0987216) + 'px', 
													top: gib_hoehe_in_mm(269.4735285) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(86.4809819) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(269.4735285) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.7886155) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(6.0987216) + 'px', 
													top: gib_hoehe_in_mm(269.4735285) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.4864211) + 'px', 
													width: spalte_breit_mm(40.0970522) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(13.6122513) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.5393261) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(12.6656045) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.5393261) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(12.6656045) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(202.5305683) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(12.6656045) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1050482) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.2758526) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.6047286) + 'px', 
													width: spalte_breit_mm(90.9803758) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.2758526) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.5851044) + 'px', 
													width: spalte_breit_mm(2.0523613) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.2758526) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.5316557) + 'px', 
													width: spalte_breit_mm(11.0504437) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(16.134409) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.5845607) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(258.5910084) + 'px', 
													top: gib_hoehe_in_mm(16.187314) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.5316557) + 'px', 
													width: spalte_breit_mm(11.0504437) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(43.7511717) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.5820994) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(258.6439134) + 'px', 
													top: gib_hoehe_in_mm(16.134409) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(198.5316557) + 'px', 
													width: spalte_breit_mm(11.0504437) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(274.6783224) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.4415034) + 'px', 
													width: spalte_breit_mm(28.099609) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(33.2005039) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.4944084) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.493321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(175.4925863) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(179.4918516) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.4907642) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.4900295) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.4889421) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.4882074) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(32.2538571) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.4819467) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(40.6989059) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.5348517) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(39.7522591) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.5337643) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(39.7522591) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.5330296) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(39.7522591) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.5322949) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(39.7522591) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0979215) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.4335956) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0446638) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.8039812) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.4335956) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.8039812) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.4849376) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.4335956) + 'px', 
													top: gib_hoehe_in_mm(44.8568862) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0446638) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.2433868) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.2433868) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.8467698) + 'px', 
													width: spalte_breit_mm(24.1010491) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(63.2985111) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.9000275) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(145.8989401) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(149.8982054) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(153.8974707) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.8963833) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(161.8956486) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(165.8945612) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.5483422) + 'px', 
													width: spalte_breit_mm(24.1006964) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(63.2985111) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6012472) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(175.6005125) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(179.5997778) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.5986904) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.5979557) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.5968683) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.5961336) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(62.3515116) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.8679318) + 'px', 
													width: spalte_breit_mm(24.1006964) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(76.4059012) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.9208368) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(145.9201021) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(149.9190147) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(153.91828) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.9175453) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(161.9164579) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(165.9157232) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.5613921) + 'px', 
													width: spalte_breit_mm(24.1006964) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(76.4059012) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6142971) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(175.6135624) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(179.6128277) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.6117403) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.6110056) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.6099182) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.6091835) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.4589017) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0979215) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(89.9911998) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0446638) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(89.9379421) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(89.9379421) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(89.9911998) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.4849376) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.5747711) + 'px', 
													top: gib_hoehe_in_mm(89.9911998) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(138.0446638) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(97.5188759) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.2912532) + 'px', 
													width: spalte_breit_mm(29.2465894) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(97.5188759) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(104.464597) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(104.464597) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(103.5175975) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.962999) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.962999) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(111.0159995) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(119.461401) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(119.461401) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(118.5144015) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(126.9594503) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(126.9594503) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(126.0128035) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.4578523) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.4578523) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(133.5112055) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(141.9562543) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(141.9562543) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(141.0096075) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(151.5362917) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(151.5362917) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(150.5892922) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7674492) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(168.1706818) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.8203542) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(152.8196195) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.8185321) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.8177974) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(177.9611336) + 'px', 
													width: spalte_breit_mm(12.1032532) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(168.1706818) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.0140386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(182.0133039) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(186.0122165) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0114818) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(167.2236823) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(159.4870603) + 'px', 
													width: spalte_breit_mm(36.0981396) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(197.6010279) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(159.5399653) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(163.5392306) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.5381432) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.5374085) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(175.5366738) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(179.5355864) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.5348517) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.5337643) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.5330296) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.5322949) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(196.6543811) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(123.5479884) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(131.5461663) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(135.5454316) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.5443442) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(143.5436095) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(147.5425221) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(151.5417874) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(155.5410527) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(159.5399653) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(163.5392306) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.5381432) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.5374085) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(175.5366738) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(179.5355864) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.5348517) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.5337643) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(191.5330296) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(195.5322949) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(127.546901) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(211.1510565) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(123.4950834) + 'px', 
													width: spalte_breit_mm(72.0901165) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(212.098056) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.8591892) + 'px', 
													width: spalte_breit_mm(5.7229102) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.5820994) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.577431) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(22.0219537) + 'px'}">
													Statistik der Baufertigstellungen
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(27.2101707) + 'px'}">
													Bitte lesen Sie vor dem Ausfüllen die dazugehörigen Erläuterungen.
													</div>
<div :style="{
														fontSize:'33px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(90.978965) + 'px', 
														top: gib_hoehe_in_mm(21.8068067) + 'px'}">
													BF
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.8872413) + 'px', 
														top: gib_hoehe_in_mm(22.1076598) + 'px'}">
													Füllen Sie den Fragebogen aus bei ...
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.8872413) + 'px', 
														top: gib_hoehe_in_mm(25.9129401) + 'px'}">
													... Neubau (für jedes Gebäude
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8862494) + 'px', 
														top: gib_hoehe_in_mm(29.4399401) + 'px'}">
													1 Erhebungsbogen).
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.8872413) + 'px', 
														top: gib_hoehe_in_mm(32.9669401) + 'px'}">
													... Baumaßnahmen an einem
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8862494) + 'px', 
														top: gib_hoehe_in_mm(36.4939401) + 'px'}">
													bestehenden Gebäude.
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(105.8872413) + 'px', 
														top: gib_hoehe_in_mm(40.0209401) + 'px'}">
													... Änderung des Nutzungsschwer
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8862494) + 'px', 
														top: gib_hoehe_in_mm(42.9836201) + 'px'}">
													punkts zwischen Wohnbau und
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8862494) + 'px', 
														top: gib_hoehe_in_mm(45.9463001) + 'px'}">
													Nichtwohnbau (bitte zusätzlich
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(108.8862494) + 'px', 
														top: gib_hoehe_in_mm(48.9089801) + 'px'}">
													einen Abgangsbogen ausfüllen).
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(21.407903) + 'px'}">
													Information und Technik
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(24.229503) + 'px'}">
													NorddrheinWestfalen
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(27.1399834) + 'px'}">
													Geschäftsbereich Statistik
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(30.3142834) + 'px'}">
													Referat 535 Paderborn
													</div>
<div :style="{
														fontSize:'12.12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(33.5552437) + 'px'}">
													40193 Düsseldorf
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(38.2514442) + 'px'}">
													Sie erreichen uns über
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(41.7202487) + 'px'}">
													Telefon 0211 9449 4664
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(45.1887005) + 'px'}">
													Telefax 0211 9449 4658
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(156.1378211) + 'px', 
														top: gib_hoehe_in_mm(48.657505) + 'px'}">
													EMail bautaetigkeitit.nrw.de
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(53.0196986) + 'px'}">
													1 Allgemeine Angaben 1 (Blockschrift)
													</div>
<b-popover 
												v-if="formular.identnummer_Seite3"
												:show="formular.identnummer_Seite3.showPop==true"
												
												ref='identnummer_Seite3' 
												target='identnummer_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.identnummer_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.identnummer_Seite3.size}}</span>
												<b-button @click='plus("identnummer_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("identnummer_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="identnummer_Seite3" type="text" 
												v-if="formular.identnummer_Seite3!=undefined" 
												class='textfeld' v-model='formular.identnummer_Seite3.text' 
												style='width:100%' 
												@focus='formular.identnummer_Seite3.showPop=true'
												@blur ='formular.identnummer_Seite3.showPop=false'
												:disabled='formular.identnummer_Seite3.isDisabled==true || formular.identnummer_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.identnummer_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(39.2768006) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(7.395702) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(35.7187176) + 'px', 
		
														'font-size': formular.identnummer_Seite3.size + 'px', 
														'text-align': formular.identnummer_Seite3.schriftausrichtung, 
														'letter-spacing': formular.identnummer_Seite3.letterSpacing + 'px',
														'font-weight': formular.identnummer_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauscheinnummer_Seite3"
												:show="formular.bauscheinnummer_Seite3.showPop==true"
												
												ref='bauscheinnummer_Seite3' 
												target='bauscheinnummer_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauscheinnummer_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauscheinnummer_Seite3.size}}</span>
												<b-button @click='plus("bauscheinnummer_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauscheinnummer_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauscheinnummer_Seite3" type="text" 
												v-if="formular.bauscheinnummer_Seite3!=undefined" 
												class='textfeld' v-model='formular.bauscheinnummer_Seite3.text' 
												style='width:100%' 
												@focus='formular.bauscheinnummer_Seite3.showPop=true'
												@blur ='formular.bauscheinnummer_Seite3.showPop=false'
												:disabled='formular.bauscheinnummer_Seite3.isDisabled==true || formular.bauscheinnummer_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.bauscheinnummer_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.7312703) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(6.9001585) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(43.7049037) + 'px', 
		
														'font-size': formular.bauscheinnummer_Seite3.size + 'px', 
														'text-align': formular.bauscheinnummer_Seite3.schriftausrichtung, 
														'letter-spacing': formular.bauscheinnummer_Seite3.letterSpacing + 'px',
														'font-weight': formular.bauscheinnummer_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(39.5268074) + 'px'}">
													Identifikationsnummer
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(47.5658985) + 'px'}">
													Bauscheinnummer/Aktenzeichen
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5965188) + 'px', 
														top: gib_hoehe_in_mm(58.1437242) + 'px'}">
													Bauherr/Bauherrin
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5965188) + 'px', 
														top: gib_hoehe_in_mm(62.1983634) + 'px'}">
													Name/Firma
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(77.6392167) + 'px'}">
													Anschrift
													</div>
<b-popover 
												v-if="formular.bauherr_name1_Seite3"
												:show="formular.bauherr_name1_Seite3.showPop==true"
												
												ref='bauherr_name1_Seite3' 
												target='bauherr_name1_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name1_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name1_Seite3.size}}</span>
												<b-button @click='plus("bauherr_name1_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name1_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name1_Seite3" type="text" 
												v-if="formular.bauherr_name1_Seite3!=undefined" 
												class='textfeld' v-model='formular.bauherr_name1_Seite3.text' 
												style='width:100%' 
												@focus='formular.bauherr_name1_Seite3.showPop=true'
												@blur ='formular.bauherr_name1_Seite3.showPop=false'
												:disabled='formular.bauherr_name1_Seite3.isDisabled==true || formular.bauherr_name1_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_name1_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1341047) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(32.9967842) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(65.7980317) + 'px', 
		
														'font-size': formular.bauherr_name1_Seite3.size + 'px', 
														'text-align': formular.bauherr_name1_Seite3.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name1_Seite3.letterSpacing + 'px',
														'font-weight': formular.bauherr_name1_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_name2_Seite3"
												:show="formular.bauherr_name2_Seite3.showPop==true"
												
												ref='bauherr_name2_Seite3' 
												target='bauherr_name2_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name2_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name2_Seite3.size}}</span>
												<b-button @click='plus("bauherr_name2_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name2_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_name2_Seite3" type="text" 
												v-if="formular.bauherr_name2_Seite3!=undefined" 
												class='textfeld' v-model='formular.bauherr_name2_Seite3.text' 
												style='width:100%' 
												@focus='formular.bauherr_name2_Seite3.showPop=true'
												@blur ='formular.bauherr_name2_Seite3.showPop=false'
												:disabled='formular.bauherr_name2_Seite3.isDisabled==true || formular.bauherr_name2_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_name2_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1341047) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(32.9967842) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(72.2968819) + 'px', 
		
														'font-size': formular.bauherr_name2_Seite3.size + 'px', 
														'text-align': formular.bauherr_name2_Seite3.schriftausrichtung, 
														'letter-spacing': formular.bauherr_name2_Seite3.letterSpacing + 'px',
														'font-weight': formular.bauherr_name2_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_anschrift1_Seite3"
												:show="formular.bauherr_anschrift1_Seite3.showPop==true"
												
												ref='bauherr_anschrift1_Seite3' 
												target='bauherr_anschrift1_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_anschrift1_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_anschrift1_Seite3.size}}</span>
												<b-button @click='plus("bauherr_anschrift1_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_anschrift1_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_anschrift1_Seite3" type="text" 
												v-if="formular.bauherr_anschrift1_Seite3!=undefined" 
												class='textfeld' v-model='formular.bauherr_anschrift1_Seite3.text' 
												style='width:100%' 
												@focus='formular.bauherr_anschrift1_Seite3.showPop=true'
												@blur ='formular.bauherr_anschrift1_Seite3.showPop=false'
												:disabled='formular.bauherr_anschrift1_Seite3.isDisabled==true || formular.bauherr_anschrift1_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_anschrift1_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1341047) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(32.9967842) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(78.7953794) + 'px', 
		
														'font-size': formular.bauherr_anschrift1_Seite3.size + 'px', 
														'text-align': formular.bauherr_anschrift1_Seite3.schriftausrichtung, 
														'letter-spacing': formular.bauherr_anschrift1_Seite3.letterSpacing + 'px',
														'font-weight': formular.bauherr_anschrift1_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_anschrift2_Seite3"
												:show="formular.bauherr_anschrift2_Seite3.showPop==true"
												
												ref='bauherr_anschrift2_Seite3' 
												target='bauherr_anschrift2_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_anschrift2_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_anschrift2_Seite3.size}}</span>
												<b-button @click='plus("bauherr_anschrift2_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_anschrift2_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_anschrift2_Seite3" type="text" 
												v-if="formular.bauherr_anschrift2_Seite3!=undefined" 
												class='textfeld' v-model='formular.bauherr_anschrift2_Seite3.text' 
												style='width:100%' 
												@focus='formular.bauherr_anschrift2_Seite3.showPop=true'
												@blur ='formular.bauherr_anschrift2_Seite3.showPop=false'
												:disabled='formular.bauherr_anschrift2_Seite3.isDisabled==true || formular.bauherr_anschrift2_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_anschrift2_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(67.1341047) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(32.9967842) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(85.788715) + 'px', 
		
														'font-size': formular.bauherr_anschrift2_Seite3.size + 'px', 
														'text-align': formular.bauherr_anschrift2_Seite3.schriftausrichtung, 
														'letter-spacing': formular.bauherr_anschrift2_Seite3.letterSpacing + 'px',
														'font-weight': formular.bauherr_anschrift2_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5965188) + 'px', 
														top: gib_hoehe_in_mm(92.6910419) + 'px'}">
													Anschrift des Baugrundstücks
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(96.744623) + 'px'}">
													Straße,
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5965188) + 'px', 
														top: gib_hoehe_in_mm(100.271623) + 'px'}">
													Nummer
													</div>
<b-popover 
												v-if="formular.baugrundstueck_strasse_Seite3"
												:show="formular.baugrundstueck_strasse_Seite3.showPop==true"
												
												ref='baugrundstueck_strasse_Seite3' 
												target='baugrundstueck_strasse_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_strasse_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_strasse_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_strasse_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_strasse_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_strasse_Seite3" type="text" 
												v-if="formular.baugrundstueck_strasse_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_strasse_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_strasse_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_strasse_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_strasse_Seite3.isDisabled==true || formular.baugrundstueck_strasse_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_strasse_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(52.683633) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(32.9967842) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(99.427624) + 'px', 
		
														'font-size': formular.baugrundstueck_strasse_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_strasse_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_strasse_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_strasse_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(104.8253327) + 'px'}">
													Postleitzahl,
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5965188) + 'px', 
														top: gib_hoehe_in_mm(108.3523327) + 'px'}">
													Ort
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(116.4051791) + 'px'}">
													Lage des Baugrundstücks
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(123.9035811) + 'px'}">
													Gemeinde
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5954607) + 'px', 
														top: gib_hoehe_in_mm(132.4018876) + 'px'}">
													Gemeindeteil
													</div>
<b-popover 
												v-if="formular.baugrundstueck_gemeinde_Seite3"
												:show="formular.baugrundstueck_gemeinde_Seite3.showPop==true"
												
												ref='baugrundstueck_gemeinde_Seite3' 
												target='baugrundstueck_gemeinde_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_gemeinde_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_gemeinde_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_gemeinde_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_gemeinde_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_gemeinde_Seite3" type="text" 
												v-if="formular.baugrundstueck_gemeinde_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_gemeinde_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_gemeinde_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_gemeinde_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_gemeinde_Seite3.isDisabled==true || formular.baugrundstueck_gemeinde_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_gemeinde_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.004399) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.4015221) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(123.5015152) + 'px', 
		
														'font-size': formular.baugrundstueck_gemeinde_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_gemeinde_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_gemeinde_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_gemeinde_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueck_gemeindeTeil_Seite3"
												:show="formular.baugrundstueck_gemeindeTeil_Seite3.showPop==true"
												
												ref='baugrundstueck_gemeindeTeil_Seite3' 
												target='baugrundstueck_gemeindeTeil_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_gemeindeTeil_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_gemeindeTeil_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_gemeindeTeil_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_gemeindeTeil_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_gemeindeTeil_Seite3" type="text" 
												v-if="formular.baugrundstueck_gemeindeTeil_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_gemeindeTeil_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_gemeindeTeil_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_gemeindeTeil_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_gemeindeTeil_Seite3.isDisabled==true || formular.baugrundstueck_gemeindeTeil_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_gemeindeTeil_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.004399) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.4015221) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.999469) + 'px', 
		
														'font-size': formular.baugrundstueck_gemeindeTeil_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_gemeindeTeil_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_gemeindeTeil_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_gemeindeTeil_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(138.4552777) + 'px'}">
													Datum der Baugenehmigung
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(141.9822777) + 'px'}">
													bzw. Genehmigungsfreistellung .....................
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(147.0766765) + 'px'}">
													Datum der
													</div>
<div :style="{
														fontSize:'10.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(150.6036765) + 'px'}">
													Bezugsfertigstellung .........................................
													</div>
<b-popover 
												v-if="formular.BG_Monat_Seite3"
												:show="formular.BG_Monat_Seite3.showPop==true"
												
												ref='BG_Monat_Seite3' 
												target='BG_Monat_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BG_Monat_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BG_Monat_Seite3.size}}</span>
												<b-button @click='plus("BG_Monat_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BG_Monat_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BG_Monat_Seite3" type="text" 
												v-if="formular.BG_Monat_Seite3!=undefined" 
												class='textfeld' v-model='formular.BG_Monat_Seite3.text' 
												style='width:100%' 
												@focus='formular.BG_Monat_Seite3.showPop=true'
												@blur ='formular.BG_Monat_Seite3.showPop=false'
												:disabled='formular.BG_Monat_Seite3.isDisabled==true || formular.BG_Monat_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.BG_Monat_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(8.4980824) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(75.1857001) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(141.3174503) + 'px', 
		
														'font-size': formular.BG_Monat_Seite3.size + 'px', 
														'text-align': formular.BG_Monat_Seite3.schriftausrichtung, 
														'letter-spacing': formular.BG_Monat_Seite3.letterSpacing + 'px',
														'font-weight': formular.BG_Monat_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.BG_Jahr_Seite3"
												:show="formular.BG_Jahr_Seite3.showPop==true"
												
												ref='BG_Jahr_Seite3' 
												target='BG_Jahr_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BG_Jahr_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BG_Jahr_Seite3.size}}</span>
												<b-button @click='plus("BG_Jahr_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BG_Jahr_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BG_Jahr_Seite3" type="text" 
												v-if="formular.BG_Jahr_Seite3!=undefined" 
												class='textfeld' v-model='formular.BG_Jahr_Seite3.text' 
												style='width:100%' 
												@focus='formular.BG_Jahr_Seite3.showPop=true'
												@blur ='formular.BG_Jahr_Seite3.showPop=false'
												:disabled='formular.BG_Jahr_Seite3.isDisabled==true || formular.BG_Jahr_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.BG_Jahr_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4959076) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(86.2894015) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(141.3174503) + 'px', 
		
														'font-size': formular.BG_Jahr_Seite3.size + 'px', 
														'text-align': formular.BG_Jahr_Seite3.schriftausrichtung, 
														'letter-spacing': formular.BG_Jahr_Seite3.letterSpacing + 'px',
														'font-weight': formular.BG_Jahr_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(75.9800448) + 'px', 
														top: gib_hoehe_in_mm(144.5792078) + 'px'}">
													Monat
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(91.6716678) + 'px', 
														top: gib_hoehe_in_mm(144.5792078) + 'px'}">
													Jahr
													</div>
<b-popover 
												v-if="formular.BZ_Monat_Seite3"
												:show="formular.BZ_Monat_Seite3.showPop==true"
												
												ref='BZ_Monat_Seite3' 
												target='BZ_Monat_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BZ_Monat_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BZ_Monat_Seite3.size}}</span>
												<b-button @click='plus("BZ_Monat_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BZ_Monat_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BZ_Monat_Seite3" type="text" 
												v-if="formular.BZ_Monat_Seite3!=undefined" 
												class='textfeld' v-model='formular.BZ_Monat_Seite3.text' 
												style='width:100%' 
												@focus='formular.BZ_Monat_Seite3.showPop=true'
												@blur ='formular.BZ_Monat_Seite3.showPop=false'
												:disabled='formular.BZ_Monat_Seite3.isDisabled==true || formular.BZ_Monat_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.BZ_Monat_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(8.4980824) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(75.1857001) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(149.9384964) + 'px', 
		
														'font-size': formular.BZ_Monat_Seite3.size + 'px', 
														'text-align': formular.BZ_Monat_Seite3.schriftausrichtung, 
														'letter-spacing': formular.BZ_Monat_Seite3.letterSpacing + 'px',
														'font-weight': formular.BZ_Monat_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.BZ_Jahr_Seite3"
												:show="formular.BZ_Jahr_Seite3.showPop==true"
												
												ref='BZ_Jahr_Seite3' 
												target='BZ_Jahr_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.BZ_Jahr_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.BZ_Jahr_Seite3.size}}</span>
												<b-button @click='plus("BZ_Jahr_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("BZ_Jahr_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="BZ_Jahr_Seite3" type="text" 
												v-if="formular.BZ_Jahr_Seite3!=undefined" 
												class='textfeld' v-model='formular.BZ_Jahr_Seite3.text' 
												style='width:100%' 
												@focus='formular.BZ_Jahr_Seite3.showPop=true'
												@blur ='formular.BZ_Jahr_Seite3.showPop=false'
												:disabled='formular.BZ_Jahr_Seite3.isDisabled==true || formular.BZ_Jahr_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.BZ_Jahr_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.4959076) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(86.2894015) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(149.9384964) + 'px', 
		
														'font-size': formular.BZ_Jahr_Seite3.size + 'px', 
														'text-align': formular.BZ_Jahr_Seite3.schriftausrichtung, 
														'letter-spacing': formular.BZ_Jahr_Seite3.letterSpacing + 'px',
														'font-weight': formular.BZ_Jahr_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(75.9800448) + 'px', 
														top: gib_hoehe_in_mm(153.2006066) + 'px'}">
													Monat
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(91.6716678) + 'px', 
														top: gib_hoehe_in_mm(153.2006066) + 'px'}">
													Jahr
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(155.6980753) + 'px'}">
													Haben sich seit Einreichung des
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(159.0839953) + 'px'}">
													Erhebungsbogens für Baugenehmigung
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(10.5968715) + 'px', 
														top: gib_hoehe_in_mm(162.6109953) + 'px'}">
													Änderungen ergeben ? ....................................................... 1
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(89.7568595) + 'px', 
														top: gib_hoehe_in_mm(157.6259335) + 'px'}">
													Ja
													</div>
<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(96.255357) + 'px', 
														top: gib_hoehe_in_mm(157.6259335) + 'px'}">
													Nein
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.aenderungseiteEinreichenja!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(89.8094118) + 'px',top: gib_hoehe_in_mm(162.191006) + 'px'}"
								v-model="formular.aenderungseiteEinreichenja.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.aenderungseiteEinreichennein!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'14px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(98.7235516) + 'px',top: gib_hoehe_in_mm(162.191006) + 'px'}"
								v-model="formular.aenderungseiteEinreichennein.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(96.4941349) + 'px', 
														top: gib_hoehe_in_mm(163.1675559) + 'px'}">
													2
													</div>

<div :style="{
														fontSize:'12px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5869981) + 'px', 
														top: gib_hoehe_in_mm(88.7725449) + 'px'}">
													Ansprechpartner/in für Rückfragen
													</div>
<b-popover 
												v-if="formular.baugrundstueck_nummer_Seite3"
												:show="formular.baugrundstueck_nummer_Seite3.showPop==true"
												
												ref='baugrundstueck_nummer_Seite3' 
												target='baugrundstueck_nummer_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_nummer_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_nummer_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_nummer_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_nummer_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_nummer_Seite3" type="text" 
												v-if="formular.baugrundstueck_nummer_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_nummer_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_nummer_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_nummer_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_nummer_Seite3.isDisabled==true || formular.baugrundstueck_nummer_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_nummer_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4421648) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(90.5736484) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(99.427624) + 'px', 
		
														'font-size': formular.baugrundstueck_nummer_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_nummer_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_nummer_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_nummer_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.ansprechpartnerName_Seite3"
												:show="formular.ansprechpartnerName_Seite3.showPop==true"
												
												ref='ansprechpartnerName_Seite3' 
												target='ansprechpartnerName_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ansprechpartnerName_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ansprechpartnerName_Seite3.size}}</span>
												<b-button @click='plus("ansprechpartnerName_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ansprechpartnerName_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ansprechpartnerName_Seite3" type="text" 
												v-if="formular.ansprechpartnerName_Seite3!=undefined" 
												class='textfeld' v-model='formular.ansprechpartnerName_Seite3.text' 
												style='width:100%' 
												@focus='formular.ansprechpartnerName_Seite3.showPop=true'
												@blur ='formular.ansprechpartnerName_Seite3.showPop=false'
												:disabled='formular.ansprechpartnerName_Seite3.isDisabled==true || formular.ansprechpartnerName_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.ansprechpartnerName_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.7497724) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(107.4485799) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(96.6462318) + 'px', 
		
														'font-size': formular.ansprechpartnerName_Seite3.size + 'px', 
														'text-align': formular.ansprechpartnerName_Seite3.schriftausrichtung, 
														'letter-spacing': formular.ansprechpartnerName_Seite3.letterSpacing + 'px',
														'font-weight': formular.ansprechpartnerName_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5337404) + 'px', 
														top: gib_hoehe_in_mm(100.1749832) + 'px'}">
													Name (z. B. Architekt/in, Planverfasser/in)
													</div>
<b-popover 
												v-if="formular.baugrundstueck_plz_Seite3"
												:show="formular.baugrundstueck_plz_Seite3.showPop==true"
												
												ref='baugrundstueck_plz_Seite3' 
												target='baugrundstueck_plz_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_plz_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_plz_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_plz_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_plz_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_plz_Seite3" type="text" 
												v-if="formular.baugrundstueck_plz_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_plz_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_plz_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_plz_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_plz_Seite3.isDisabled==true || formular.baugrundstueck_plz_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_plz_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(10.4421648) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(31.7870232) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.5090391) + 'px', 
		
														'font-size': formular.baugrundstueck_plz_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_plz_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_plz_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_plz_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrundstueck_ort_Seite3"
												:show="formular.baugrundstueck_ort_Seite3.showPop==true"
												
												ref='baugrundstueck_ort_Seite3' 
												target='baugrundstueck_ort_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrundstueck_ort_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrundstueck_ort_Seite3.size}}</span>
												<b-button @click='plus("baugrundstueck_ort_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrundstueck_ort_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrundstueck_ort_Seite3" type="text" 
												v-if="formular.baugrundstueck_ort_Seite3!=undefined" 
												class='textfeld' v-model='formular.baugrundstueck_ort_Seite3.text' 
												style='width:100%' 
												@focus='formular.baugrundstueck_ort_Seite3.showPop=true'
												@blur ='formular.baugrundstueck_ort_Seite3.showPop=false'
												:disabled='formular.baugrundstueck_ort_Seite3.isDisabled==true || formular.baugrundstueck_ort_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrundstueck_ort_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.3073683) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(45.7084449) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.5090391) + 'px', 
		
														'font-size': formular.baugrundstueck_ort_Seite3.size + 'px', 
														'text-align': formular.baugrundstueck_ort_Seite3.schriftausrichtung, 
														'letter-spacing': formular.baugrundstueck_ort_Seite3.letterSpacing + 'px',
														'font-weight': formular.baugrundstueck_ort_Seite3.schriftart }" 
												/>

<b-popover 
												v-if="formular.ansprechpartnerTelefon_Seite3"
												:show="formular.ansprechpartnerTelefon_Seite3.showPop==true"
												
												ref='ansprechpartnerTelefon_Seite3' 
												target='ansprechpartnerTelefon_Seite3'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ansprechpartnerTelefon_Seite3.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ansprechpartnerTelefon_Seite3.size}}</span>
												<b-button @click='plus("ansprechpartnerTelefon_Seite3", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ansprechpartnerTelefon_Seite3", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ansprechpartnerTelefon_Seite3" type="text" 
												v-if="formular.ansprechpartnerTelefon_Seite3!=undefined" 
												class='textfeld' v-model='formular.ansprechpartnerTelefon_Seite3.text' 
												style='width:100%' 
												@focus='formular.ansprechpartnerTelefon_Seite3.showPop=true'
												@blur ='formular.ansprechpartnerTelefon_Seite3.showPop=false'
												:disabled='formular.ansprechpartnerTelefon_Seite3.isDisabled==true || formular.ansprechpartnerTelefon_Seite3.isDisabled==true' 
												@keypress="isNumber($event, formular.ansprechpartnerTelefon_Seite3.onylNumber) " 
												:style="{'width': spalte_breit_mm(87.7497724) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(107.4485799) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(107.352793) + 'px', 
		
														'font-size': formular.ansprechpartnerTelefon_Seite3.size + 'px', 
														'text-align': formular.ansprechpartnerTelefon_Seite3.schriftausrichtung, 
														'letter-spacing': formular.ansprechpartnerTelefon_Seite3.letterSpacing + 'px',
														'font-weight': formular.ansprechpartnerTelefon_Seite3.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.5869981) + 'px', 
														top: gib_hoehe_in_mm(111.3933121) + 'px'}">
													Telefon und/oder EMail
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(6.5972535) + 'px', 
														top: gib_hoehe_in_mm(279.5536181) + 'px'}">
													BF
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(195.8042266) + 'px', 
														top: gib_hoehe_in_mm(279.5536181) + 'px'}">
													Seite 1
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.5972535) + 'px', 
													width: spalte_breit_mm(40.8969758) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.4030861) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.6505112) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(38.4564393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.6505112) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(38.4564393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.4392081) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(38.4564393) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.6501585) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.1735067) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.4351808) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(23.6965022) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(27.9581763) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.2198504) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.4811718) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(40.7428459) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(45.00452) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(49.2658414) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(53.5275155) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(57.7891896) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(62.0508637) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(66.3125378) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.5738592) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.8355333) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.0972074) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(83.3585288) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.6202029) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.881877) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(10.9118326) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(46.4955304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.5972535) + 'px', 
													width: spalte_breit_mm(85.3375285) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.4425299) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.0967581) + 'px', 
													width: spalte_breit_mm(68.9849457) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(76.1138656) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1496631) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.1668661) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0139854) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(75.1668661) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.0967581) + 'px', 
													width: spalte_breit_mm(68.9849457) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(69.6150154) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1496631) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(68.6683686) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0139854) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(68.6683686) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.0967581) + 'px', 
													width: spalte_breit_mm(68.9849457) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(89.1108606) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1496631) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1002572) + 'px', 
													top: gib_hoehe_in_mm(88.1638611) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0139854) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1002572) + 'px', 
													top: gib_hoehe_in_mm(88.1638611) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.0967581) + 'px', 
													width: spalte_breit_mm(68.9849457) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(82.6123631) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(32.1496631) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(81.6653636) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.0139854) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(81.6653636) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1091981) + 'px', 
													width: spalte_breit_mm(56.9765688) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.2449604) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1515221) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(102.2979609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(88.0289822) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(102.2979609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.9293298) + 'px', 
													width: spalte_breit_mm(11.9135006) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.2449604) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.9385) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(102.2979609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.8308386) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(102.2979609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.9068221) + 'px', 
													width: spalte_breit_mm(56.9769215) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.3260228) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(44.9494988) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(110.3783179) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.8266062) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(110.3783179) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1427046) + 'px', 
													width: spalte_breit_mm(11.9131479) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.3260228) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1518748) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(110.3783179) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(43.0442134) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1006099) + 'px', 
													top: gib_hoehe_in_mm(110.3783179) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.0986171) + 'px', 
													width: spalte_breit_mm(56.6771265) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(126.8765658) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1515221) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(125.929919) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.7295399) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(125.929919) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.0986171) + 'px', 
													width: spalte_breit_mm(56.6771265) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(135.3748723) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.1515221) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(134.4278728) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(87.7295399) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(134.4278728) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.8827951) + 'px', 
													width: spalte_breit_mm(8.1043406) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(144.9556151) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.9357001) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.9349654) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.933878) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0995518) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.0394015) + 'px', 
													width: spalte_breit_mm(15.9963558) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(144.9556151) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.0394015) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.0383141) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(94.0375794) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.036492) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.0357573) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0466468) + 'px', 
													top: gib_hoehe_in_mm(144.0089683) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.8827951) + 'px', 
													width: spalte_breit_mm(8.1043406) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(153.5766612) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(74.9357001) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.9349654) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.933878) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(85.9861438) + 'px', 
													width: spalte_breit_mm(16.1025185) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(153.5766612) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.0394015) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(90.0383141) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(94.0375794) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.036492) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.0357573) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(152.6300144) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8094118) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(165.58398) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.2027385) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8094118) + 'px', 
													width: spalte_breit_mm(3.3933267) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(89.8094118) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.7235516) + 'px', 
													width: spalte_breit_mm(3.392974) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(165.58398) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.1165256) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.7235516) + 'px', 
													width: spalte_breit_mm(3.392974) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.7235516) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(3.492974) + 'px', 
													top: gib_hoehe_in_mm(162.191006) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(30.1235875) + 'px', 
													top: gib_hoehe_in_mm(23.0801301) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(154.9911934) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(30.1235875) + 'px', 
													top: gib_hoehe_in_mm(23.0801301) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.0859951) + 'px', 
													top: gib_hoehe_in_mm(53.2095276) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.0761268) + 'px', 
													width: spalte_breit_mm(1.111005) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.1569753) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.1871318) + 'px', 
													width: spalte_breit_mm(1.3995136) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.1569753) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.5866454) + 'px', 
													width: spalte_breit_mm(48.404548) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.1569753) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(154.9911934) + 'px', 
													width: spalte_breit_mm(29.5784801) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.1569753) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(184.5696735) + 'px', 
													width: spalte_breit_mm(17.8610807) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(53.1569753) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(9.0980824) + 'px', 
													top: gib_hoehe_in_mm(63.1955227) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.1523027) + 'px', 
													top: gib_hoehe_in_mm(72.1939578) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(9.6525268) + 'px', 
													top: gib_hoehe_in_mm(82.2462605) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(10.9250684) + 'px', 
													top: gib_hoehe_in_mm(91.7987873) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.1290318) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(11.3387855) + 'px', 
													top: gib_hoehe_in_mm(102.6238557) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.5866454) + 'px', 
													width: spalte_breit_mm(90.0862813) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(111.2688854) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.6395504) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.3222386) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.6179055) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(110.3211805) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.5972535) + 'px', 
													width: spalte_breit_mm(1.0499879) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(8.447165) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(10.7464163) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(13.0460203) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.3456243) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.6448756) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.9444796) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(22.2440836) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(24.5436876) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(26.8429389) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(29.1425429) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.4421469) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(33.7413982) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(36.0410022) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(38.3406062) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(40.6398575) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(42.9394615) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(45.2390655) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.5383168) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(49.8379208) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(52.1375248) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.4371288) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(56.7363801) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.0359841) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(61.3355881) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(63.6348394) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(65.9344434) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.2340474) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.5332987) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(72.8329027) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(75.1325067) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(77.4321107) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.731362) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(82.030966) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(84.33057) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.6298213) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(88.9294253) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.2290293) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.5282806) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(95.8278846) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.1274886) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(100.4270926) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.7263439) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.0259479) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(107.3255519) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.6251559) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(111.9244072) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(114.2240112) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(116.5236152) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(118.8228665) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(121.1224705) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(123.4220745) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(125.7213258) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(128.0209298) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(130.3205338) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(132.6197851) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(134.9193891) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(137.2189931) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.5185971) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.8178484) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(144.1174524) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(146.4170564) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.7163077) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(151.0159117) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(153.3155157) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(155.614767) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.914371) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.213975) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.513579) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(164.8128303) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.1124343) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(169.4120383) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.7112896) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(174.0108936) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.3104976) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.6097489) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(180.9093529) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.2089569) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(185.5082082) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.8078122) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.1074162) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(192.4070202) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(194.7062715) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(197.0058755) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(199.3054795) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.6047308) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.9043348) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(206.2039388) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.5031901) + 'px', 
													width: spalte_breit_mm(1.0499879) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(0.5808969) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(1.4986223) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(3.7982263) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(6.0974776) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(8.3970816) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(10.6966856) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(12.9959369) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.2955409) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(17.5951449) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.8943962) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(22.1940002) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(24.4936042) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(26.7932082) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(29.0924595) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(31.3920635) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(33.6916675) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(35.9909188) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(38.2905228) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(40.5901268) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(42.8893781) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(45.1889821) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(47.4885861) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(49.7878374) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(52.0874414) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(54.3870454) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(56.6866494) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(58.9859007) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(61.2855047) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(63.5851087) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(65.88436) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.183964) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(70.483568) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(72.7828193) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(75.0824233) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(77.3820273) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.6812786) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(81.9808826) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(84.2804866) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(86.5800906) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(88.8793419) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(91.1789459) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(93.4785499) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(95.7778012) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(98.0774052) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(100.3770092) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(102.6766132) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(104.9758645) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(107.2754685) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.5750725) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(111.8746765) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(114.1739278) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(116.4735318) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(118.7731358) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(121.0723871) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(123.3719911) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(125.6715951) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(127.9708464) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(130.2704504) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(132.5700544) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(134.8693057) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(137.1689097) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.4685137) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(141.7681177) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(144.067369) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(146.366973) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.666577) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(150.9658283) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(153.2654323) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(155.5650363) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(157.8642876) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(160.1638916) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(162.4634956) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(164.7627469) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(167.0623509) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(169.3619549) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(171.6615589) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(173.9608102) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.2604142) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.5600182) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(180.8592695) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(183.1588735) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(185.4584775) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(187.7577288) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(190.0573328) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(192.3569368) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(194.6565408) + 'px', 
													width: spalte_breit_mm(1.4993277) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.9557921) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(199.2553961) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.5550001) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.8542514) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(206.1538554) + 'px', 
													width: spalte_breit_mm(1.4996804) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.4534594) + 'px', 
													width: spalte_breit_mm(1.0997186) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(115.6039211) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.5337404) + 'px', 
													width: spalte_breit_mm(90.0862813) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(100.5104773) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.5866454) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.1002572) + 'px', 
													top: gib_hoehe_in_mm(99.5631251) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(196.5650005) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.0999045) + 'px', 
													top: gib_hoehe_in_mm(99.5613616) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(0.1178018) + 'px', 
													width: spalte_breit_mm(209.4353762) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(20.5805452) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(203.6443949) + 'px', 
													width: spalte_breit_mm(5.9087831) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.553178) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.8840228) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "landesrecht_ja": {
        "text": "0",
        "size": null,
        "sichtbar": "true"
    },
    "landesrecht_nein": {
        "text": "0",
        "size": null,
        "sichtbar": "true"
    },
    "identnummer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "nummerAndereSeiten",
        "showPop": false
    },
    "bauscheinnummer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_name1": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "bauherrAndereSeiten",
        "showPop": false
    },
    "bauherr_name2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "bauherrAndereSeiten",
        "showPop": false
    },
    "bauherr_anschrift1": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "bauherrAndereSeiten",
        "showPop": false
    },
    "bauherr_anschrift2": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "bauherrAndereSeiten",
        "showPop": false
    },
    "baugrundstueck_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckAndereSeiten",
        "showPop": false
    },
    "baugrundstueck_plz": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.plz",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckAndereSeiten",
        "showPop": false
    },
    "baugrundstueck_ort": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckAndereSeiten",
        "showPop": false
    },
    "ansprechpartnerTelefon": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_nummer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckAndereSeiten",
        "showPop": false
    },
    "ansprechpartnerName": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_lage": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueckNR": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_gemeinde": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckLageAndereSeiten",
        "showPop": false
    },
    "baugrundstueckgemeindeNR": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_gemeindeTeil": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "grundstueckLageAndereSeiten",
        "showPop": false
    },
    "baugrundstueckgemeindeTeilNR": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BG_Monat": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BG_Jahr": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "konventionelle_bauart": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "fertigbau": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bestehendesGebaude": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "nutzungsschwerpunkt_ja": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "nutzungsschwerpunkt_nein": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "nutzungsartNichtWohnbau": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "oeffentlBauherr": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "wohnungsunternehmen": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "immobilienfond": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "landUndForstwirtschaft": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "produzierendeGew": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "orgiOhneErwerbzweck": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "handel": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "privaterHaushalt": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ohneEigentumswhg": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "mitEigentumswhg": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "wohnheim": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "Abbruch_Jahr": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "leichtbeton": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonstigesMaterial": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "abgangsbogen_ja": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "abgangsbogen_nein": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "einzelhaus": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "gereihtesHaus": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "doppelhaushaelfte": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonstigerHaustyp": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ziegel": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "stahl": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "porenbeton": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "holz": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "kalksandstein": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "stahlbeton": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "wa_abgangsbogen_ja": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "wa_abgangsbogen_nein": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ferneheizung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "etagenheizung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "blockheizung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "einzelraumhzg": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "zentralheizung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "keinehzg": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "cbm": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "hzg1": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg13": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg25": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg37": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "vollgesch": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "identnummer_Seite2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "hzg10": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg22": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg34": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg46": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg11": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg23": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg35": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg47": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "raum1neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum1alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "hzg12": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg24": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg36": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg48": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "raum2neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum2alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum3neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum3alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "nutzneu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "nutzalt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "wohnneu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "wohnalt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "kosten": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "strassenschluessel": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum4neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum4alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum5neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum5alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum6neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum6alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum7neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum7alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum8neu": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "raum8alt": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "hzg2": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg14": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg26": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg38": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg3": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg15": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg27": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg39": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg4": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg16": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg28": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg40": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg5": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg17": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg29": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg41": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg6": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg18": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg30": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg42": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg7": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg19": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg31": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg43": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg8": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg20": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg32": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg44": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg9": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg21": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg33": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "hzg45": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg1": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg2": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg3": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg4": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg5": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "lftg6": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg1": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg2": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg3": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg4": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg5": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg6": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg7": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg8": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg9": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg10": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg11": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "eeg12": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "identnummer_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauscheinnummer_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_name1_Seite3": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_name2_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_anschrift1_Seite3": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_anschrift2_Seite3": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_strasse_Seite3": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_gemeinde_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_gemeindeTeil_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BG_Monat_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "r_ls10",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BG_Jahr_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BZ_Monat_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "r_ls10",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "BZ_Jahr_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "10",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "aenderungseiteEinreichenja": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "aenderungseiteEinreichennein": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "baugrundstueck_nummer_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ansprechpartnerName_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_plz_Seite3": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.plz",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrundstueck_ort_Seite3": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ansprechpartnerTelefon_Seite3": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    }
}
var formulaName = "NRWStatistik"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
 

nummerAndereSeiten(){
	this.formular.identnummer_Seite2.text = this.formular.identnummer.text; 
	this.formular.identnummer_Seite3.text = this.formular.identnummer.text; 
},

bauherrAndereSeiten(){

	this.formular.bauherr_name1_Seite3.text = this.formular.bauherr_name1.text; 

	this.formular.bauherr_name2_Seite3.text = this.formular.bauherr_name2.text; 

	this.formular.bauherr_anschrift1_Seite3.text = this.formular.bauherr_anschrift1.text; 

	this.formular.bauherr_anschrift2_Seite3.text = this.formular.bauherr_anschrift2.text; 
},

grundstueckAndereSeiten(){

	this.formular.baugrundstueck_strasse_Seite3.text = this.formular.baugrundstueck_strasse_name1.text; 

	this.formular.baugrundstueck_nummer_Seite3.text = this.formular.baugrundstueck_nummer.text; 

	this.formular.baugrundstueck_plz_Seite3.text = this.formular.baugrundstueck_plz.text; 

	this.formular.baugrundstueck_ort_Seite3.text = this.formular.baugrundstueck_ort.text; 
},

grundstueckLageAndereSeiten(){

	this.formular.baugrundstueck_gemeinde_Seite3.text = this.formular.baugrundstueck_gemeinde.text; 

	this.formular.baugrundstueck_gemeindeTeil_Seite3.text = this.formular.baugrundstueck_gemeindeTeil.text; 
},



		},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>