<template  v-if="actualformular">
<!--   -->
<span v-if="actualformular.formularContent[this.getNameOfActualFormular]!=undefined" >
<!-- {{showFontPopover}} -->
	<b-popover 
    v-if="actualformular.formularContent[this.getNameOfActualFormular][url]"
    :show.sync="show"
    :placement='placement' 
    :ref="url" 
    :target="url"  
    >
    <template slot="title">
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Schriftgröße
      </template>

    <span style="font-size:1.0em; font-weight:700; margin-right:10px;">{{actualformular.formularContent[this.getNameOfActualFormular][url].size}}</span>
    <b-button @click="plus" style=" margin-right:10px;" size="sm">+</b-button>
    <b-button @click="minus" size="sm">-</b-button>

    <!-- <b-form-input type=number min=1 max=20 " placeholder="Schriftgröße"></b-form-input> -->

    </b-popover>
</span>
</template>


<script>

    export default {
        props: { 
            url: String,
            placement: String,
        },

        components: {
            
        },

        name: "popoverFontSize",


        data(){
            return{
               
            }
        },

	
   
        computed: {


        getNameOfActualFormular(){

            var returnValue = "";

            if(this.actualformular){
                    for (var prop in this.actualformular.formularContent) 
                            returnValue = prop;
            }

            return returnValue;

        },

        actualformular(){
            return this.$store.getters.actualformular;
        },

            show:
            {
                get()
                {
                    var returnValue = false

                    if(this.showFontPopover == this.url)
                    returnValue = true

                    return returnValue;

                },
                set(value)
                {
                   
                }
            },

            showFontPopover:
            {
                get()
                {
                    return this.$store.getters.showFontPopover
                },
                set(value)
                {
                    this.$store.commit('setshowFontPopover', value);
                }
            },


            
            projectDaten(){
                return this.$store.getters.actualProject;
            },
        
            store() {
                return this.$store.state
            },
        
        },

        methods: {

            onClose(){
                this.showFontPopover='';
            },

     		plus(){
                 if(this.actualformular.formularContent[this.getNameOfActualFormular][this.url].size<20)
                 this.actualformular.formularContent[this.getNameOfActualFormular][this.url].size++;

                setTimeout(()=>{
                    this.showFontPopover=this.url;	
                },200);
                                
            },

     		minus(){
                 if(this.actualformular.formularContent[this.getNameOfActualFormular][this.url].size>7)
                 this.actualformular.formularContent[this.getNameOfActualFormular][this.url].size--;

                setTimeout(()=>{
                    this.showFontPopover=this.url;	
                },100);

            },

        },

	created(){




    }
    }
</script>

<style scoped>

.popover{
    z-index:300 !important;
}

</style>