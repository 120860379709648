<template>
	<div>
     
<div style="border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

    <div style="left:100px;top:334px;" class="t size_15_fett">Vorhaben, für das das vereinfachte Genehmigungsverfahren durchgeführt wird.</div>
    <div style="left:100px;top:352px;" class="t size_15">(§ 68 Abs. 1 Satz 1 und 2 und § 67 Abs. 1 Satz 3 BauO NRW)) </div>
    <div style="left:78px;top:385px;" class="t size_13_fett">Bauherrin / Bauherr / Antragstellerin / Antragsteller</div>
    <div style="left:463px;top:385px;" class="t size_13_fett">Entwurfsverfasserin / Entwurfsverfasser </div>

    <div style="left:167px;top:554px;" class="t size_9">Name, Vorname, Anschrift (§ 69 Abs. 3 BauO NRW) </div>
    <div style="left:463px;top:551px;" class="t size_12">bauvorlageberechtigt: </div>
    <div style="left:584px;top:554px;" class="t size_9">Name, Vorname </div>
    <div style="left:656px;top:554px;" class="t size_9">(§ 70 Abs. 3 BauO NRW)</div>

    <div style="left:78px;top:820px;" class="t size_13_fett">Bezeichnung des Vorhabens (Errichtung, Änderung)</div>

    <div style="left:81px;top:978px;" class="t size_12">(zur planungsrechtlichen Zulässigkeit oder bauordnungsrechtlichen Zulässigkeit)</div>
    <div style="left:369px;top:1080px;" class="t size_12">Bescheid vom </div>
    <div style="left:479px;top:1080px;" class="t size_12">erteilt von (Behörde) </div>
    <div style="left:669px;top:1080px;" class="t size_12">Aktenzeichen </div>
    <div style="left:78px;top:1080px;" class="t size_13_fett">Bindungen zur Beurteilung des Vorhabens </div>

    <!-- End text definitions -->

    <div style="left:688px;top:51px;" class="t size_12_fett">Anlage I/1 zu VV BauPrüfVO </div>
    <div style="left:810px;top:65px;" class="t size_12">Blatt 1 </div>

    <div style="left:518px;top:80px;" class="t size_9">Eingangsstempel der Bauaufsichtsbehörde </div>
    <textarea readonly 	tabindex="1"  style="resize:none; read-only:true; z-index:2;	position: absolute;	left:515px;	top:93px;	width:330px;	height:129px;	color: rgb(0,0,0); 	text-align:left;border: solid 1px grey;"
    ></textarea>

    <div style="padding:10px; left:515px;top:221px;" class="t size_9">Aktenzeichen </div>
    <textarea readonly style="resize:none; read-only:true; 	z-index:2;	position: absolute;	left:515px;	top:222px;	width:330px;	height:48px;	color: rgb(0,0,0); 	text-align:left;	background: transparent;	border: solid 1px grey;" name="eingangsstempel"></textarea>


    <div style="left:78px;top:80px;" class="t size_9">An die untere Bauaufsichtsbehörde </div>
    <textarea 	tabindex="2" style="resize:none;	z-index:2;	position: absolute;	left:74px;	top:93px;	width:437px;	height:129px;" v-model="store.formular.daten.bauaufsicht.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauaufsicht.size + 'px'}" id="bauaufsicht"></textarea>

    <div style="z-index:2;	position: absolute;	left:74px;	top:222px;	width:435px;	height:48px; border: solid 1px grey;">
        <label style="margin-left:2px;" class="t size_9">PLZ, Ort </label><br>
        <input tabindex="3" id="bauaufsicht_plz" v-model="store.formular.daten.bauaufsicht_plz.text" style="margin-right:1%; margin-left:1%; width:98%; height:19px;"  ng-style="{'font-size': store.formular.daten.bauaufsicht_plz.size + 'px'}" type="text" />
    </div>
	
     <!-- @focus="setFocus($event)" -->
    <div style="left:115px;top:289px;" class="t size_18_fett">Bauantrag </div>
    <input type="checkbox" 	tabindex="4" true-value="1" false-value="0" v-model="store.formular.daten.ist_antrag.text"  style="position: absolute;	left:77px;	top:284px;	width:28px;	height:28px;" />

    <div style="left:275px;top:289px;" class="t size_18_fett">Antrag auf Vorbescheid</div>
    <input type="checkbox" 	tabindex="5" true-value="1" false-value="0" v-model="store.formular.daten.ist_voranfrage.text"  style="position: absolute;	left:237px;	top:284px;	width:29px;	height:28px;" />



<div  class="t size_17_fett" style=" background-color:lightgrey; position: absolute;	left:515px;	top:270px;	width:328px; height:60px;	text-align:center; border: solid 1px grey;">
<p style="margin-top:20px;" >
Vereinfachtes Genehmigungsverfahren
</p>
</div>

<div 					style="position: absolute;left:78px;top:407px;" class="t size_9">Name, Vorname, Firma</div>
<textarea 				tabindex="6"  style="resize:none; position: absolute;	left:75px;	top:420px;	width:381px;	height:51px;" v-model="store.formular.daten.bauherr_name.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_strasse.size + 'px'}" id="bauherr_name"></textarea>

<div 					style="position: absolute;left:463px;top:407px;" class="t size_9">Name, Vorname, Büro</div>
<textarea 				style="resize:none; position: absolute;	left:459px;	top:419px;	width:381px;	height:52px;" tabindex="12" 	v-model="store.formular.daten.antragsteller_name.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_name.size + 'px'}"  id="antragsteller_name"></textarea>

<div 					style="position: absolute; left:78px;top:480px;" class="t size_9">Straße, Hausnummer</div>
<input type="text"  	style="position: absolute;	left:74px;	top:492px;	width:382px;	height:19px;" tabindex="6"   v-model="store.formular.daten.bauherr_strasse.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_strasse.size + 'px'}" id="bauherr_strasse" value="" name="bauherr_strasse"  pdfFieldName="bauherr_strasse" />

<div 					style="position: absolute;	left:463px;top:480px;" class="t size_9">Straße, Hausnummer </div>
<input type="text"  	style="position: absolute;	left:459px;	top:492px;	width:382px;	height:19px;" tabindex="13"  	v-model="store.formular.daten.antragsteller_strasse.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_strasse.size + 'px'}" id="antragsteller_strasse" value="" name="antragsteller_strasse"  pdfFieldName="antragsteller_strasse" />

<div 					style="position: absolute; left:78px; top:515px;" class="t size_9">PLZ, Ort</div>
<input type="text"  	style="position: absolute;	left:74px; top:527px; width:382px; height:19px;" tabindex="7"   v-model="store.formular.daten.bauherr_ort.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_ort.size + 'px'}" id="bauherr_ort" value="" name="bauherr_ort"  pdfFieldName="bauherr_ort" />

<div 					style="position: absolute; left:463px;top:515px;" class="t size_9">PLZ, Ort </div>
<input type="text"  	style="position: absolute;	left:459px;	top:527px;	width:382px;height:19px;" tabindex="14"  	v-model="store.formular.daten.antragsteller_ort.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_ort.size + 'px'}" id="antragsteller_ort" value="" name="antragsteller_ort"  pdfFieldName="antragsteller_ort" />

<div 					style="position: absolute; left:78px;top:551px;" class="t size_12">vertreten durch: </div>
<textarea 				style="resize:none;	position: absolute;	left:74px;	top:567px;	width:382px;	height:83px;" tabindex="8" 	v-model="store.formular.daten.bauherr_vertreter.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_vertreter.size + 'px'}" id="bauherr_vertreter"></textarea>

<div 					style="position: absolute; left:78px;top:657px;" class="t size_9">Telefon mit Vorwahl</div>
<input type="text"  	style="position: absolute;	left:75px;	top:671px;	width:187px;	height:19px;" tabindex="9"   	v-model="store.formular.daten.bauherr_tel.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_befreiung_erteilt_von.size + 'px'}" id="bindung_befreiung_erteilt_von">

<div 					style="position: absolute; left:271px;top:657px;" class="t size_9">Telefax </div>
<input type="text"  	style="position: absolute;	left:266px;	top:671px;	width:188px;	height:19px;" tabindex="10"  	v-model="store.formular.daten.bauherr_fax.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_baulast_aktenzeichen.size + 'px'}" id="bindung_baulast_aktenzeichen">

<div 					style="position: absolute; left:463px; top:657px;" class="t size_9">Telefon mit Vorwahl</div>
<input type="text"  	style="position: absolute; left:459px; top:671px;	width:188px;	height:19px;" tabindex="17" 	v-model="store.formular.daten.antragsteller_tel.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_tel.size + 'px'}" id="antragsteller_tel">

<div 					style="position: absolute; left:656px; top:657px;" class="t size_9">Telefax </div>
<input type="text"  	style="position: absolute; left:651px; top:671px;	width:188px;	height:19px;" tabindex="18"  	v-model="store.formular.daten.antragsteller_fax.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_fax.size + 'px'}" id="antragsteller_fax">

<div 					style="position: absolute;	left:78px;top:690px;" class="t size_9">E-Mail </div>
<input type="text"  	style="position: absolute;	left:74px;	top:703px;	width:381px;	height:19px;" tabindex="11"  	v-model="store.formular.daten.bauherr_email.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_email.size + 'px'}" id="bauherr_email">

<div 					style="position: absolute;	left:463px; top:690px;" class="t size_9">E-Mail </div>
<input type="text"  	style="position: absolute;	left:459px;	top:703px;	width:381px;	height:19px;" tabindex="19"  	v-model="store.formular.daten.antragsteller_email.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_email.size + 'px'}" id="antragsteller_email">

<input type="text"  	style="position: absolute;	left:459px;	top:567px;	width:381px;	height:19px;" tabindex="15"  	v-model="store.formular.daten.antragsteller_vorlagenberechtigt.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_vorlagenberechtigt.size + 'px'}" id="antragsteller_vorlagenberechtigt"/>

<div 					style="position: absolute; left:463px;top:603px;" class="t size_9">Mitgliedsnummer der Architekten- oder der Ingenieurkammer des Landes </div>
<input type="text"  	style="position: absolute; left:459px;	top:617px;	width:381px;	height:19px;" tabindex="16"  	v-model="store.formular.daten.antragsteller_mitgliedsnummer.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.antragsteller_mitgliedsnummer.size + 'px'}" id="antragsteller_mitgliedsnummer" value="" name="antragsteller_mitgliedsnummer"  pdfFieldName="antragsteller_mitgliedsnummer" />

<div style="left:78px;top:729px;" class="t size_13_fett">Baugrundstück </div>
<div style="left:78px;top:748px;" class="t size_9">Ort, Straße, Hausnummer, ggf. Ortsteil</div>
<input type="text"  style="position: absolute;	left:75px;	top:761px;	width:764px;	height:19px;" 	tabindex="20"  	v-model="store.formular.daten.baugrund_strasse.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.baugrund_strasse.size + 'px'}" id="baugrund_strasse" value="" name="baugrund_strasse"  pdfFieldName="baugrund_strasse" />

<div style="left:78px;top:783px;" class="t size_9">Gemarkung(en)</div>
<input type="text"  style="position: absolute;	left:74px;	top:796px;	width:355px;	height:19px;"	tabindex="21"  	v-model="store.formular.daten.gemarkungen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.gemarkungen.size + 'px'}"  id="gemarkungen" value="" name="gemarkungen"  pdfFieldName="gemarkungen" />

<div style="left:436px;top:783px;" class="t size_9">Flur(e)</div>
<input type="text"  style="position: absolute;	left:433px;	top:796px;	width:129px;	height:19px;"	tabindex="22"  	v-model="store.formular.daten.flure.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.flure.size + 'px'}" id="flure" value="" name="flure"  pdfFieldName="flure" />

<div style="left:570px;top:783px;" class="t size_9">Flurstück(e)</div>
<input type="text"  style="position: absolute;	left:567px;	top:796px;	width:274px;	height:19px;"	tabindex="23"  	v-model="store.formular.daten.flurstuecke.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.flurstuecke.size + 'px'}" id="flurstuecke" value="" name="flurstuecke"  pdfFieldName="flurstuecke" />

<input type="checkbox"  v-model="store.formular.daten.wohngebaude_check.text"  tabindex="23"  style="border-style:none;	z-index:2;	position: absolute;	left:78px;	top:840px;	width:17px;	height:19px;	color: rgb(0,0,0); 	text-align:left;	background: transparent;	font:normal 15px Wingdings, 'Zapf Dingbats';"/>
<div style="left:100px;top:842px;" class="t size_13">Wohngebäude</div>

<input type="checkbox"  v-model="store.formular.daten.sonderbau_check.text"   tabindex="23" style="position: absolute;	left:278px;	top:840px;	width:17px;	height:19px;	color: rgb(0,0,0); 	text-align:left;	background: transparent;	font:normal 15px Wingdings, 'Zapf Dingbats';"/>
<div style="left:300px;top:842px;" class="t size_13">Sonderbau</div>

<textarea tabindex="24" v-model="store.formular.daten.bezeichnung_bauvorhaben.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bezeichnung_bauvorhaben.size + 'px'}" id="bezeichnung_bauvorhaben" style="resize:none; z-index:2;	position: absolute;	left:74px;	top:863px;	width:767px;	height:40px;	color: rgb(0,0,0); 	text-align:left;	background: transparent;	border: solid 1px grey;	font:normal 15px 'Courier New', Courier, monospace;"></textarea>

<div style="left:78px;top:908px;" class="t size_13_fett">Bei Nutzungsänderung </div>
<div style="left:78px;top:926px;" class="t size_9_fett">Beabsichtigte Nutzung </div>
<input type="text"  style="position: absolute;	left:74px;	top:939px;	width:767px;	height:19px;" 	tabindex="25"  	v-model="store.formular.daten.beabsichigte_nutzung.text" @focus="setFocus($event)" id="beabsichigte_nutzung" ng-style="{'font-size': store.formular.daten.beabsichigte_nutzung.size + 'px'}" />


<div style="left:78px;top:959px;" class="t size_13_fett">Genaue Fragestellung zum Vorbescheid</div>
<textarea tabindex="26"  	v-model="store.formular.daten.genaue_fragestellung.text" @focus="setFocus($event)" ng-style="{'font-size': store.genaue_fragestellung.bauherr_strasse.size + 'px'}"  id="bauherr_strasse" style="resize:none; z-index:2;	position: absolute;	left:74px;	top:992px;	width:767px;	height:80px;	color: rgb(0,0,0); 	text-align:left;	background: transparent;	border: solid 1px grey;	font:normal 15px 'Courier New', Courier, monospace;"></textarea>



<input type="checkbox" 	tabindex="27"  	style="left:78px; top:1093px; position: absolute; width:20px; height:20px;" class="text_feld" true-value="1" false-value="0" v-model="store.formular.daten.bindung_vorbescheid.text"/>
<div 									style="position:absolute; left:103px; top:1098px;" class="t size_13">Vorbescheid</div>
<input type="text"  	tabindex="28"  	style="position:absolute; left:365px; top:1098px; width:106px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_vorbescheid_bescheid_vom.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_vorbescheid_bescheid_vom.size + 'px'}" id="bindung_vorbescheid_bescheid_vom" value="" name="bindung_vorbescheid_bescheid_vom"  pdfFieldName="bindung_vorbescheid_bescheid_vom" />
<input type="text"  	tabindex="29"  	style="position:absolute; left:474px; top:1098px; width:187px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_vorbescheid_erteilt_von.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_vorbescheid_erteilt_von.size + 'px'}" id="bindung_vorbescheid_erteilt_von" value="" name="bindung_vorbescheid_erteilt_von"  pdfFieldName="bindung_vorbescheid_erteilt_von" />
<input type="text"  	tabindex="30"  	style="position:absolute; left:666px; top:1098px; width:174px;	height:19px;" class="text_feld" v-model="store.formular.daten.bindung_vorbescheid_aktenzeichen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_vorbescheid_aktenzeichen.size + 'px'}" id="bindung_vorbescheid_aktenzeichen" value="" name="bindung_vorbescheid_aktenzeichen"  pdfFieldName="bindung_vorbescheid_aktenzeichen" />


<input type="checkbox" 	tabindex="31"  	style="position: absolute; left:78px; top:1117px; width:20px; height:20px;" true-value="1" false-value="0" v-model="store.formular.daten.bindung_teilungsgen.text"/>
<div 									style="position: absolute; left:103px;top:1122px;" class="t size_13">Teilungsgenehmigung</div>
<input type="text"  	tabindex="32"  	style="position:absolute; left:365px; top:1122px; width:106px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_teilungsgen_bescheid_vom.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_teilungsgen_bescheid_vom.size + 'px'}" id="bindung_teilungsgen_bescheid_vom" value="" name="bindung_teilungsgen_bescheid_vom"/>
<input type="text"  	tabindex="33"  	style="position:absolute; left:474px; top:1122px; width:187px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_teilungsgen_erteilt_von.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_teilungsgen_erteilt_von.size + 'px'}" id="bindung_teilungsgen_erteilt_von" value="" name="bindung_teilungsgen_erteilt_von"/>
<input type="text"  	tabindex="34"  	style="position:absolute; left:665px; top:1122px; width:176px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_teilungsgen_aktenzeichen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_teilungsgen_aktenzeichen.size + 'px'}" id="bindung_teilungsgen_aktenzeichen" value="" name="bindung_teilungsgen_aktenzeichen"/>


<input type="checkbox" 	tabindex="35"  	style="position:absolute; left:78px; top:1142px; width:20px; height:20px;" true-value="1" false-value="0" v-model="store.formular.daten.bindung_befreiung.text" />
<div 									style="position:absolute; left:103px; top:1146px;" class="t size_13">Befreiungs-/Abweichungsbescheid</div>
<input type="text"  	tabindex="36"  	style="position:absolute; left:365px; top:1146px; width:106px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_befreiung_bescheid_vom.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_befreiung_bescheid_vom.size + 'px'}" id="bindung_befreiung_bescheid_vom" value="" name="bindung_befreiung_bescheid_vom"  pdfFieldName="bindung_befreiung_bescheid_vom" />
<input type="text"  	tabindex="37"  	style="position:absolute; left:474px; top:1146px; width:187px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_befreiung_erteilt_von.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_strasse.size + 'px'}" id="bindung_befreiung_erteilt_von" value="" name="bindung_befreiung_erteilt_von"  pdfFieldName="bindung_befreiung_erteilt_von" />
<input type="text"  	tabindex="38"  	style="position:absolute; left:665px; top:1146px; width:176px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_befreiung_aktenzeichen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_befreiung_aktenzeichen.size + 'px'}" id="bindung_befreiung_aktenzeichen" value="" name="bindung_befreiung_aktenzeichen"  pdfFieldName="bindung_befreiung_aktenzeichen" />


<input type="checkbox" 	tabindex="39"  	style="position: absolute; left:78px; 	top:1166px; width:20px; height:20px;" true-value="1" false-value="0" v-model="store.formular.daten.bindung_baulast.text" />
<div style="position: absolute; left:103px;	top:1170px; height:19px;" class="t size_13">Baulast Nr.</div>
<input type="text"  	tabindex="40"  	style="position:absolute; left:170px; top:1170px; width:190px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_baulast_nummer.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_baulast_nummer.size + 'px'}" id="bindung_baulast_nummer" value="" name="bindung_baulast_nummer"/>
<input type="text"  	tabindex="41"  	style="position:absolute; left:365px; top:1170px; width:106px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_baulast_bescheid_vom.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_baulast_bescheid_vom.size + 'px'}" id="bindung_baulast_bescheid_vom" value="" name="bindung_baulast_bescheid_vom" />
<input type="text"  	tabindex="42"  	style="position:absolute; left:475px; top:1170px; width:187px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_baulast_erteilt_von.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_baulast_erteilt_von.size + 'px'}" id="bindung_baulast_erteilt_von" value="" name="bindung_baulast_erteilt_von" />
<input type="text"  	tabindex="43"  	style="position:absolute; left:665px; top:1170px; width:176px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_baulast_aktenzeichen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bauherr_strasse.size + 'px'}" id="bindung_baulast_aktenzeichen" value="" name="bindung_baulast_aktenzeichen" />

<input type="checkbox" 	tabindex="44"  	style="position: absolute; left:78px; top:1189px; width:20px;  height:20px;" true-value="1" false-value="0" v-model="store.formular.daten.bindung_sonstiges.text"  />
<input type="text"  	tabindex="45"  	style="position:absolute; left:103px; top:1194px; width:257px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_sonstiges_text.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_sonstiges_text.size + 'px'}" id="bindung_sonstiges_text" value="" name="bindung_sonstiges_text"/>
<input type="text"  	tabindex="46"   style="position:absolute; left:365px; top:1194px; width:106px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_sonstiges_bescheid_vom.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_sonstiges_bescheid_vom.size + 'px'}" id="bindung_sonstiges_bescheid_vom" value="" name="bindung_sonstiges_bescheid_vom"/>
<input type="text"  	tabindex="47"  	style="position:absolute; left:474px; top:1194px; width:187px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_sonstiges_erteilt_von.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_sonstiges_erteilt_von.size + 'px'}" id="bindung_sonstiges_erteilt_von" value="" name="bindung_sonstiges_erteilt_von"/>
<input type="text"  	tabindex="48" 	style="position:absolute; left:665px; top:1194px; width:176px; height:19px;" class="text_feld" v-model="store.formular.daten.bindung_sonstiges_aktenzeichen.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.bindung_sonstiges_aktenzeichen.size + 'px'}" id="bindung_sonstiges_aktenzeichen" value="" name="bindung_sonstiges_aktenzeichen"/>

<div style="left:669px;top:1220px;" class="t size_12_fett">Fortsetzung Blatt 2 </div>



<!-- End Form Data -->

</div>
<!-- End output for page 1-->



<!-- Begin output for page 2-->
<div style="height: 20px;"> </div>

<div id="page2" style="border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

<!-- Begin text definitions (Positioned/styled in CSS) -->
<div style="left:680px;top:42px;" 	class="t size_12_fett">Anlage I/2 zu VV BauPrüfVO</div>
<div style="left:808px;top:56px;" 	class="t size_11">Blatt 2 </div>
<div style="left:78px;top:90px;" 	class="t size_18_fett">Die erforderlichen Bauvorlagen sind beigefügt: </div>
<div style="left:78px;top:115px;" 	class="t size_14_narrow">(einem Antrag auf Vorbescheid sind nur die für die Klärung der Fragestellung erforderlichen Unterlagen beizufügen) </div>
<div style="left:86px;top:163px;" 	class="t size_13">2. </div>
<div style="left:138px;top:163px;" 	class="t size_13">3-fach </div>
<div style="left:196px;top:163px;" 	class="t size_13">Berechnung des Maßes der baulichen Nutzung </div>
<div style="left:488px;top:165px;" 	class="t size_11">(§ 3 Abs. 2 BauPrüfVO) </div>
<div style="left:197px;top:183px;" 	class="t size_11">(nur im Bereich eines Bebauungsplanes oder einer Satzung nach BauGB)</div>
<div style="left:86px;top:201px;" 	class="t size_13">3. </div>
<div style="left:138px;top:201px;" 	class="t size_13">3-fach</div>
<div style="left:197px;top:201px;" 	class="t size_13">Beglaubigter Auszug aus der Liegenschaftskarte/Flurkarte </div>
<div style="left:197px;top:221px;" 	class="t size_11">(nur bei Vorhaben nach den §§ 34 oder 35 des Baugesetzbuches; Beglaubigung nicht erforderlich bei </div>
<div style="left:197px;top:235px;" 	class="t size_11">Vorlage eines amtlichen Lageplanes) </div>
<div style="left:86px;top:253px;" 	class="t size_13">4. </div>
<div style="left:138px;top:253px;" 	class="t size_13">3-fach </div>
<div style="left:197px;top:253px;" 	class="t size_13">Auszug aus der Deutschen Grundkarte 1:5000 </div>
<div style="left:197px;top:272px;" 	class="t size_11">(nur bei Vorhaben nach den §§ 34 oder 35 des Baugesetzbuches)</div>
<div style="left:86px;top:290px;" 	class="t size_13">5. </div>
<div style="left:138px;top:290px;" 	class="t size_13">3-fach </div>
<div style="left:196px;top:290px;" 	class="t size_13">Bauzeichnungen </div>
<div style="left:303px;top:292px;" 	class="t size_11">(§ 4 BauPrüfVO)</div>





<div style="left:78px;top:441px;" 	class="t size_15_fett">zusätzliche Bauvorlagen für Sonderbauten, die nicht in § 68 Abs. 1 Satz 3 BauO NRW aufgeführt sind </div>

<div style="left:86px;top:471px;" 	class="t size_13">8. </div>
<div style="left:138px;top:471px;" 	class="t size_13">3-fach </div>
<div style="left:196px;top:471px;" 	class="t size_13">Betriebsbeschreibung für gewerbliche oder landwirtschaftliche Betriebe auf amtlichem </div>
<div style="left:197px;top:488px;" 	class="t size_13">Vordruck </div>
<div style="left:256px;top:489px;" 	class="t size_11">(§ 5 Abs. 2 oder 3 BauPrüfVO) </div>
<div style="left:197px;top:507px;" 	class="t size_9">(ggf. mit Maschinenaufstellungsplan mit Rettungswegen und Notausgängen, falls nicht bereits in den Grundrisszeichnungen dargestellt)</div>
<div style="left:86px;top:521px;" 	class="t size_13">9. </div>
<div style="left:138px;top:521px;" 	class="t size_13">3-fach </div>
<div style="left:196px;top:521px;" 	class="t size_13">zusätzliche Angaben und Bauvorlagen für besondere Vorhaben </div>
<div style="left:587px;top:522px;" 	class="t size_11">(§ 12 BauPrüfVO)</div>
<div style="left:78px;top:557px;" 	class="t size_13">10.1 </div>
<div style="left:141px;top:557px;" 	class="t size_13">Spätestens bei Baubeginn werden gemäß § 68 Abs. 2 und 3 BauO NRW eingereicht: </div>
<div style="left:169px;top:578px;" 	class="t size_13">der Nachweis der Standsicherheit, soweit erforderlich geprüft durch eine/einen staatlich anerkannte/n </div>
<div style="left:169px;top:595px;" 	class="t size_13">Sachverständige/n </div>
<div style="left:169px;top:617px;" 	class="t size_13">der Nachweis des Schallschutzes, soweit erforderlich aufgestellt oder geprüft durch eine/einen staatlich </div>
<div style="left:169px;top:634px;" 	class="t size_13">anerkannte/n Sachverständige/n </div>
<div style="left:169px;top:655px;" 	class="t size_13">der Nachweis des Wärmeschutzes, soweit erforderlich aufgestellt oder geprüft durch eine/einen staatlich </div>
<div style="left:169px;top:672px;" 	class="t size_13">anerkannte/n Sachverständige/n </div>
<div style="left:169px;top:693px;" 	class="t size_13">die Bescheinigung einer/eines staatlich anerkannte/n Sachverständige/n, dass das Vorhaben den </div>
<div style="left:169px;top:710px;" 	class="t size_13">Anforderungen des Brandschutzes entspricht </div>
<div style="left:459px;top:711px;" 	class="t size_11">(gilt nicht für Wohngebäude geringer Höhe und Sonderbauten) </div>
<div style="left:142px;top:736px;" 	class="t size_13">Bei Vorhaben nach § 68 Abs. 4 BauO NRW sind die vorgenannten Nachweise nicht vorzulegen. </div>







<!-- End text definitions -->

<input type="checkbox"  style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:141px;	width:25px;	height:22px;" true-value="1" false-value="0"  tabindex="51"  v-model="store.formular.daten.bauvorl_lageplan"/>

<div 		style="position: absolute; left:86px; top:143px;" class="t size_13">1. </div>
<input 		style="position: absolute; left:106px; top:162px;	width:25px;	height:22px;" type="checkbox" true-value="1" false-value="0"  tabindex="52"  v-model="store.formular.daten.bauvorl_bauliche_nutzung"/>
<div 		style="position: absolute; left:138px; top:143px;" class="t size_13">3-fach </div>
<div 		style="position: absolute; left:196px; top:143px;" class="t size_13">Lageplan / amtlicher Lageplan</div>
<div 		style="position: absolute; left:381px; top:144px;" class="t size_11">(§ 3 BauPrüfVO; Anforderungen an Planersteller/in sind zu beachten) </div>


<input type="checkbox"  true-value="1" false-value="0"  tabindex="53"  v-model="store.formular.daten.bauvorl_flurkarte" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:199px;	width:25px;	height:22px;" />
<input type="checkbox"  true-value="1" false-value="0"  tabindex="54"  v-model="store.formular.daten.bauvorl_dt_grundkarte" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:249px;	width:25px;	height:22px;" />
<input type="checkbox"  true-value="1" false-value="0"  tabindex="55"  v-model="store.formular.daten.bauvorl_bauzeichnung" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:287px;	width:25px;	height:22px;	" />


<div style="left:86px;top:311px;" 	class="t size_13">6. </div>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="56"  v-model="store.formular.daten.bauvorl_baubeschreibung" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:309px;	width:25px;	height:22px;" />
<div style="left:138px;top:311px;" 	class="t size_13">3-fach </div>
<div style="left:196px;top:311px;" 	class="t size_13">Baubeschreibung auf amtlichem Vordruck </div>
<div style="left:456px;top:313px;" 	class="t size_11">(§ 5 Abs. 1 BauPrüfVO)</div>


    <div style="left:86px;top:332px;" class="t size_13">7.1 </div>
	<input type="checkbox"  true-value="1" false-value="0"  tabindex="57"  v-model="store.formular.daten.bauvorl_standsicherheit" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:329px;	width:25px;	height:22px;"/>
	<div style="left:138px;top:332px;" 	class="t size_13">2-fach </div>
	<div style="left:196px;top:332px;" 	class="t size_13">Bei Gebäuden: Berechnung des umbauten Raumes nach DIN 277 </div>
	<div style="left:604px;top:333px;" 	class="t size_11">(§ 6 Nr. 1 BauPrüfVO)</div>
	<div style="left:728px;top:332px;" 	class="t size_13">oder </div>


    <div style="position: absolute; left:86px;top:352px;" 	class="t size_13">7.2 </div>
	<input style="position: absolute; left:106px;	top:350px;	width:25px;	height:22px;" type="checkbox"  true-value="1" false-value="0" tabindex="58" v-model="store.formular.daten.landesdurchschnittlicheRohbauwerte" />
	<div style="position: absolute; left:138px;top:352px;" 	class="t size_13">2-fach </div>
	<div style="position: absolute; left:197px;top:352px;" 	class="t size_12">Bei Gebäuden, für die landesdurchschnittliche Rohbauwerte je m³ Bruttorauminhalt nicht festgelegt sind, die Berechnung der </div>
	<div style="position: absolute; left:197px;top:369px;" 	class="t size_12">Rohbaukosten einschließlich Umsatzsteuer </div>
	<div style="position: absolute; left:446px;top:370px;"><span class="t size_12_narrow">(§ 6 Nr. 1 BauPrüfVO)</span></div>
	<div style="position: absolute; left:565px;top:370px;" class="t size_13" >oder</div>


<div style="left:86px;top:390px;" 	class="t size_13">7.3 </div>
	<input type="checkbox"  true-value="1" false-value="0"  tabindex="59"  v-model="store.formular.daten.herstellungskosten" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:387px;	width:25px;	height:22px;"/>
	<div style="left:142px;top:390px;" 	class="t size_13">bei der Änderung von Gebäuden oder bei baulichen Anlagen, die nicht Gebäude sind: </div>
	<div style="left:142px;top:406px;" 	class="t size_13">Herstellungskosten einschließlich Umsatzsteuer</div>
	<input type="text"  	tabindex="60" id="HerstellungskosteninEuro" v-model="store.formular.daten.HerstellungskosteninEuro.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.HerstellungskosteninEuro.size + 'px'}" style="position: absolute;	left:637px;	top:391px;	width:187px;	height:17px;"/>
	<div style="left:830px;top:391px;" 	class="t size_14_fett">€</div>

<input type="checkbox"  true-value="1" false-value="0"  tabindex="61"  v-model="store.formular.daten.betriebsbeschreibung" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:469px;	width:25px;	height:22px;	"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="62"  v-model="store.formular.daten.zusatzlicheAngaben" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:520px;	width:25px;	height:22px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="63"  v-model="store.formular.daten.nachweisStandsicherheit" style="	border-style:none;	z-index:2;	position: absolute;	left:141px;	top:576px;	width:23px;	height:22px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="64"  v-model="store.formular.daten.nachweisSchallschutz" style="	border-style:none;	z-index:2;	position: absolute;	left:141px;	top:614px;	width:23px;	height:22px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="65"  v-model="store.formular.daten.nachweisWarmeschutz" style="border-style:none;	z-index:2;	position: absolute;	left:141px;	top:653px;	width:23px;	height:22px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="66"  v-model="store.formular.daten.nachweisBrandschutz" style="border-style:none;	z-index:2;	position: absolute;	left:142px;	top:689px;	width:22px;	height:23px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="67"  v-model="store.formular.daten.abweichendStandsicherheit" style="border-style:none;	z-index:2;	position: absolute;	left:141px;	top:781px;	width:23px;	height:23px;"/>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="68"  v-model="store.formular.daten.abweichendSchallschutz" style="border-style:none;	z-index:2;	position: absolute;	left:141px;	top:805px;	width:23px;	height:22px;" />
<input type="checkbox"  true-value="1" false-value="0"  tabindex="69"  v-model="store.formular.daten.abweichendWarmeschutz" style="border-style:none;	z-index:2;	position: absolute;	left:141px;	top:827px;	width:23px;	height:22px;" />
<input type="checkbox"  true-value="1" false-value="0"  tabindex="70"  v-model="store.formular.daten.abweichendBrandschutz" style="	border-style:none;	z-index:2;	position: absolute;	left:141px;	top:850px;	width:23px;	height:22px;" />


<div style="left:78px;top:763px;" 	class="t size_13">10.2 </div>
<div style="left:141px;top:763px;" 	class="t size_13">Abweichend von Nr. 10.1 wird - soweit erforderlich - eine Prüfung durch die Bauaufsichtsbehörde beantragt für: </div>
<div style="left:183px;top:784px;" 	class="t size_13">2-fach </div>
<div style="left:252px;top:784px;" 	class="t size_13">den Nachweis der Standsicherheit </div>
<div style="left:183px;top:807px;"	class="t size_13">2-fach </div>
<div style="left:252px;top:807px;" 	class="t size_13">den Nachweis des Schallschutzes </div>
<div style="left:183px;top:829px;" 	class="t size_13">2-fach </div>
<div style="left:252px;top:829px;" 	class="t size_13">den Nachweis des Wärmeschutzes </div>

<div style="left:295px;top:853px;" 	class="t size_11">(gilt nicht für Wohngebäude geringer Höhe und Sonderbauten)</div>
<div style="left:183px;top:851px;" 	class="t size_13">den Brandschutz </div>

<div style="left:78px;top:890px;" 	class="t size_13">11. </div>
<input type="checkbox"  true-value="1" false-value="0"  tabindex="71"  v-model="store.formular.daten.baustatistik" style="border-style:none;	z-index:2;	position: absolute;	left:106px;	top:886px;	width:25px;	height:22px;"/>
<div style="left:142px;top:890px;" 	class="t size_13">Erhebungsbogen für die Baustatistik</div>



<div style="left:78px;top:925px;" 	class="t size_13">12.</div>
<div style="left:142px;top:925px;" 	class="t size_14_fett">Erklärung der Entwurfsverfasserin/des Entwurfsverfassers nach § 68 Abs. 6 BauO NRW </div>


<div style="left:142px;top:942px;" 	class="t size_11">(nur bei Wohngebäuden geringer Höhe) </div>
<div style="left:141px;top:974px;" 	class="t size_13">Ich erkläre hiermit, dass das in den beigefügten Bauvorlagen dargestellte Bauvorhaben den Anforderungen an </div>
<div style="left:141px;top:990px;" 	class="t size_13">den Brandschutz entspricht und die hierzu in den Bauvorlagen gemachten Angaben vollständig und richtig sind. </div>



<div style="left:78px; top:1017px;" 	class="t size_11">Ort, Datum </div>
<input type="text"  	id="ortDatumBauherr" tabindex="72"  v-model="store.formular.daten.ortDatumBauherr.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.ortDatumBauherr.size + 'px'}" style="position: absolute;	left:74px;	top:1031px;	width:381px;	height:19px;"/>
<div style="left:463px; top:1017px;" class="t size_11">Ort, Datum </div>
<input type="text"  	id="ortDatumVerfasser" tabindex="73"  v-model="store.formular.daten.ortDatumVerfasser.text" @focus="setFocus($event)" ng-style="{'font-size': store.formular.daten.ortDatumVerfasser.size + 'px'}" style="position: absolute;	left:459px;	top:1031px;	width:382px;	height:19px;"/>

<div style="left:78px;top:1058px;" 	class="t size_13">Für den Bauherrn / die Bauherrin: </div>
<div style="left:463px;top:1058px;" class="t size_13">Der / die bauvorlageberechtigte (*) Entwurfsverfasser/in: </div>

<div style="left:78px;top:1171px;" 	class="t size_13">Unterschrift </div>
<div style="left:463px;top:1171px;" class="t size_13">Unterschrift </div>

<div style="left:73px;top:1203px;" 	class="t size_12_narrow">(*) Nach § 70 Abs. 2 BauO NRW oder Nr. 70.11 VV BauO NRW kann in bestimmten Fällen auf die Bauvorlageberechtigung verzichtet werden </div>


</div>
<!-- End output for page 2-->

	</div>
</template>

<script>

export default {

    name: "antrageinfach",

    data(){
        return{
        }
    },

    computed: {
        store() {
            return this.$store.state
        }
    },
	methods:{

	},
	created(){

	}
	
	//
}
</script>


<style scoped>

.t {
	position: absolute;
	-webkit-transform-origin: top left;
	-moz-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	z-index: 2;
	position:absolute;
	white-space:nowrap;
	overflow:visible;
	FONT-FAMILY: Arial, Helvetica, sans-serif;
	color: rgb(0,0,0);
	
}

.size_9{		
	FONT-SIZE	:9px;
}		

.size_9_fett{		
	FONT-SIZE	:9px;
	FONT-WEIGHT	: bold;
}		

.size_10{		
	FONT-SIZE	:10px;
}		

.size_10_fett{		
	FONT-SIZE	:10px;
	FONT-WEIGHT	: bold;
}	

.size_11{		
	FONT-SIZE	:11px;
}

.size_11_fett{		
	FONT-SIZE	:11px;
	FONT-WEIGHT	: bold;
}

.size_12_narrow{		
	FONT-SIZE	:12px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}	

.size_12{		
	FONT-SIZE	:12px;
}

.size_12_fett{		
	FONT-SIZE	:12px;
	FONT-WEIGHT	: bold;
}		

.size_13{		
	FONT-SIZE	:13px;
}

.size_13_fett{		
	FONT-SIZE	:13px;
	FONT-WEIGHT	: bold;
}

.size_14_fett{		
	FONT-SIZE	:14px;
	FONT-WEIGHT	: bold;
}		

.size_14_narrow{		
	FONT-SIZE	:14px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}		

.size_15{		
	FONT-SIZE	:15px;
}

.size_15_fett{		
	FONT-SIZE	:15px;
	FONT-WEIGHT	: bold;
}

.size_17_fett{		
	FONT-SIZE	:17px;
	FONT-WEIGHT	: bold;
}	

.size_18_fett{		
	FONT-SIZE	:18px;
	FONT-WEIGHT	: bold;
}		

.text_feld{
z-index:2;	
color: rgb(0,0,0); 	
text-align:left;	
background: transparent;	
border: solid 1px grey;	
font:normal 15px 'Courier New', Courier, monospace;
}

</style>
