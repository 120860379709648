export const forAllMixin = {
	
  data() {
    return {

      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      login:false
    }
},

methods: {

  checkSubNav(){
    this.axios.get(this.$store.state.url_subnav,
      {
      params: {
        besonders: this.$store.state.dev_sub,
      }
      })
      .then((response) => {
        this.meineNavigation = response.data;
      
    })
  },

  doCopy: function (text) {
    this.$copyText(text).then((response) => { 

     this.$toasted.show( "Link wurde erfolgreich in die Zwischenablage kopiert. Sie können ihn mit Strg-v woanders einfügen. Z.B. in einer E-Mail", { position: 'top-center',  duration:4000, type:'success', containerClass:'ganzVorne'})

    }, (response) => {
        this.$toasted.show("Beim Kopieren ist etwas schiefgegangen.", { position: 'top-center',  duration:2000, type:'error', containerClass:'ganzVorne'})

    })
  },

openLink(link){

   // window.open(this.$store.state.url_datei_download+"?dateiID=" + dateiID + "&besonders="+this.$store.state.dev_sub, "_blank");
  //console.log(link + "&besonders="+this.$store.state.dev_sub);
  window.open(link + "&besonders="+this.$store.state.dev_sub, "_blank");    
},

  toTop(){
   // console.log("scroll")
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  

  has(string, filters) {
    if(filters.length==0)
    return true
    else
    return filters.some(f => f == string)
},

hasNot(string, filters) {
  if(filters.length==0)
  return true
  else
  return filters.some(f => f != string)
},

  updateAdressbook () {

    // console.log("updateAdressbook")

    var url = this.$store.state.url_get_kontakte

      //console.log(url)

    this.axios.post(url, {			
        besonders: this.$store.state.dev_sub,
        welche:'alle',

        }).then((response) => {
          
          //console.log(response.data);
            this.loader_loading = false;
            this.Addressbook = response.data;

             if(!this.meineFirma && this.Addressbook.length>0)
             this.getMeineFirma();

             this.getMeineBenutzerID().then(data => {
              var meineID = data.benutzerID
              this.getMich(meineID);
            })

            

            //  console.log(this.$route);
             if( this.Addressbook.length>0 && this.$route.params.kontaktid){
              //  console.log(this.$route.params.kontaktid);

              var pos = this.Addressbook.map(function(e) { return e.basic.kontaktID; }).indexOf(this.$route.params.kontaktid);
              this.KontaktDetail = this.Addressbook[pos]
            }
        })
        .catch(function (error) {
                    // handle error
                    console.log(error);
        })

  },



  updatePapierkorb () {
      this.loader_loading = true;
      this.axios.post(this.$store.state.url_get_kontakte, {			
          besonders: this.$store.state.dev_sub,
          welche:'papierkorb',
          kindIN:[1,2,3]
          }).then((response) => {
              this.loader_loading = false;
              this.Papierkorb = response.data;

          })
          .catch(function (error) {
                      // handle error
                      //console.log(error);
          })

  },

updateThisKontaktDetail(val, targetIndex){ // aktualisert Kontakt auf detail = 1 => mehr Details

  return this.axios.post(this.$store.state.url_get_kontakte, {
     
     besonders: this.$store.state.dev_sub,
     welche:'einzeln',
     kontaktID:val,
     }).then((response) => {

     this.Addressbook.splice(targetIndex, 1, response.data);
     // returning the data here allows the caller to get it through another .then(...)
     return response.data
   })

   },


  findCommonElements(arr1, arr2) { 

    if(arr2.length>0)
    return arr1.some(item => arr2.includes(item)) 
    else
    return true
  },


getMeineBenutzerID(){

        return this.axios.post(this.$store.state.url_get_kontakte, {			
          besonders: this.$store.state.dev_sub,
          welche:'get_mich',
          }).then((response) => {
            return response.data
          })
          .catch(function (error) {
                // handle error
                 console.log(error);
          })
  },

  getMich(ID){
    
    var targetIndex = null

    if(this.Addressbook!=undefined && this.Addressbook.length>0)
		targetIndex = this.Addressbook.findIndex(item => item.basic.kontaktID == ID);
		
    //console.log(targetIndex);

		if(targetIndex>=0){
				
				if(this.Addressbook[targetIndex])
				this.userSelbst  = this.Addressbook[targetIndex]

    }
    else
    this.userSelbst  = {"nicht gefunden":true};

    //console.log(this.userSelbst);

   // return ichSelbst;

  },

  getMeineFirma(){

    this.axios.post(this.$store.state.url_get_kontakte, {			
      besonders: this.$store.state.dev_sub,
      welche:'get_meineFirma',
      }).then((response) => {

        this.meineFirma = response.data;

      })
      .catch(function (error) {
            // handle error
            // console.log(error);
      })
  },



  getAdmin(){
    this.axios.post(this.$store.state.url_getloggin, {			
      besonders: this.$store.state.dev_sub,
      }).then((response) => {
      //console.log(response.data);
        this.admin = response.data.admin;
      })
      .catch(function (error) {
            // handle error
            // console.log(error);
      })
  },






},


mounted() {



  if(!this.admin)
  this.getAdmin();

  if(Object.keys(this.auswahlListen).length ==0){

		this.axios.get(this.$store.state.url_getAuswahllisten,
			{
				  params: {
					  besonders: this.$store.state.dev_sub,
				  }
			  })
	  
		  .then((response) => {
			
			this.auswahlListen = response.data
		  })
         
    }

  var section = this.$router.currentRoute.hash.replace("#", "");

  if (section){

      const id = section;
      const yOffset = -200; 
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      this.$nextTick(()=>window.scrollTo({top: y, behavior: 'smooth'}));

  }

},


computed:{


		meineNavigation:
		{
			get()
			{
				return this.$store.getters.meineNavigation
			},
			set(value)
			{
				this.$store.commit('setmeineNavigation', value);
			}
        },

  meineBenutzerID:
  {
          get()
          {
                  return this.$store.getters.meineBenutzerID
          },
          set(value)
          {
                  this.$store.commit('setmeineBenutzerID', value);
          }
  },


  userSelbst:
  {
    get()
    {
      return this.$store.getters.userSelbst
    },
    set(value)
    {
      this.$store.commit('setuserSelbst', value);
    }
      },

  projektEinstellungen:
  {
      get()
      {
          return this.$store.getters.projektEinstellungen
      },
      set(value)
      {
          this.$store.commit('setprojektEinstellungen', value);
      }
  },
  
  benutzerKontakte(){
          
    var ma = this.Addressbook.filter(el => el.basic.benutzerID.length>0 );
    //var ehema = this.ehemMitarbeiter.filter(el => this.findCommonElements(el.art.summary, ["8","9"]));

    //ma.concat(ehema);

    return ma;

},


  KontaktDetail:
  {
      get()
      {
          return this.$store.getters.KontaktDetail
      },
      set(value)
      {
          this.$store.commit('setKontaktDetail', value);
      }
  },

  anzeigeNamegekurzt(){
    var returnValue=""

    if(this.KontaktDetail.vollerName.anzeigeName)
    {
      var maxLenght=20;
      if(this.window.width>1200)
      var maxLenght=40;
        if(this.KontaktDetail.vollerName.anzeigeName.length>maxLenght)
        {
          var anfag = this.KontaktDetail.vollerName.anzeigeName.indexOf(" ", maxLenght-5)
          //console.log(anfag)
          if(anfag==-1)
          anfag=maxLenght

          returnValue= this.KontaktDetail.vollerName.anzeigeName.substring(0, anfag)+'...';
        }
        else
        returnValue= this.KontaktDetail.vollerName.anzeigeName

    }

    return returnValue;

  },

  loader_color(){
    return "#007bff"
  },
  
  loader_size(){
    return "15px"
  },

  meineFirma:
  {
      get()
      {
          return this.$store.getters.meineFirma
      },
      set(value)
      {
          this.$store.commit('setmeineFirma', value);
      }
  }, 

  loader_loading:
  {
      get()
      {
          return this.$store.getters.loader_loading
      },
      set(value)
      {
          this.$store.commit('setloader_loading', value);
      }
  }, 
  Papierkorb:
  {
      get()
      {
          return this.$store.getters.Papierkorb
      },
      set(value)
      {
          this.$store.commit('setPapierkorb', value);
      }
  }, 

  Addressbook:
  {
      get()
      {
          return this.$store.getters.Addressbook
      },
      set(value)
      {
          this.$store.commit('setAddressbook', value);
      }
  }, 

 
	auswahlListen:
  {
      get()
      {
          return this.$store.getters.auswahlListen
      },
      set(value)
      {
          this.$store.commit('setauswahlListen', value);
      }
},
  
admin:
{
    get()
    {
      return this.$store.getters.admin
    },
    set(value)
    {
      this.$store.commit('setadmin', value);
    }
},



},

created(){


        

  },

}