<template>
	<div>


<div ></div>

        <b-container class="kontainer" fluid>

             <!-- <p class="h3 mb-4">Handbuch ArchiAPP</p> -->

    <b-row >
      <b-col cols="2">
                <!-- <b-navbar sticky style="top: 85px" v-b-scrollspy:scrollspy-nested class="flex-column"> -->

        <b-navbar sticky style=" min-height: calc(100vh - 120px); height:500px; top: 85px; overflow-y:auto" v-b-scrollspy="200" class="flex-column">
         
          <b-nav pills vertical>
            <b-nav-item href="#allgemeines">Allgemeines</b-nav-item>
            <b-nav pills vertical>
              <b-nav-item class="ml-3 my-1" href="#onlineMeeting">Online Meeting</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#internMeeting">Internes Meeting</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#externMeeting">Externes Meeting</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#steeringMeeting">Meeting - Steuerung</b-nav-item>
              
            </b-nav>

            <b-nav-item href="#projekt_allgemein">Projekte</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#erlauterung_allgemein">allgemeine Erläuterungen</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#stammdaten">Stammdaten</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#dateien">Dateien</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#formular">Formular</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#beteiligte">Beteiligte</b-nav-item>

            <b-nav-item href="#adressbuch_hilfe">Adressbuch</b-nav-item>
            <b-nav pills vertical>
              <b-nav-item class="ml-3 my-1" href="#adressbuch_allgemein">allgemeine Erläuterungen</b-nav-item>
              <b-nav-item class="ml-3 my-1" href="#listen">Listen</b-nav-item>
            </b-nav>

            <b-nav-item href="#kalender">Kalender</b-nav-item>


          </b-nav>
        </b-navbar>
      </b-col>

            <!-- <div id="scrollspy-nested" data-spy="scroll" data-target="#nav"  style="position:relative; overflow-y:auto"  > -->

      <b-col cols="10">
            <div>

              	<b-card class="ueberschriftHilfe1 anchor my-4 mx-4" id="allgemeines" title="Allgemeines">

          <!-- <h4 id="allgemeines" style="">Allgemeines</h4> -->
                <div class="p-4">

                    <p>Auf vielen Seiten finden Sie oben rechts den Button "bearbeiten". Mit einem Klick auf diesen Button, können Sie die jeweilige Seite bearbeiten oder vor unbeabsichtigeten Änderungen schützen</p>
                        <span>deaktiviert: 
                        <img class="mb-2 mr-4" style="max-width:150px" width="30%" src="../../public/hilfe/bearbeiten aus.jpg" alt="bearbeiten_0">
                        </span>
                        <span>aktiviert: </span>
                        <img class="mb-2" style="max-width:150px" width="30%" src="../../public/hilfe/bearbeiten ein.jpg" alt="bearbeiten_0">
                </div>

                	</b-card>


              	<b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="onlineMeeting" title="Online Meeting">

          <!-- <h5 id="onlineMeeting" style="">Online Meeting</h5> -->
              <div class="p-4">
                    <p id="">Um ein Videomeeting zu führen, brauchst du natürlich eine Kamera, ein Mikrofon und Lautsprecher / Kopfhörer. Tablets und Laptops haben das in der Regel schon eingebaut. An „normalen“ Büro-Computer brauchst du eine Web-Kamera mit Mikrofon und etwas zum hören (Lautsprecher / Kopfhörer). </p>
                    <p>Um den Bildschirm mit anderen zu teilen, benötigst du weder Kamera noch Mikrofon </p>
                    <p>Es gibt zwei Möglichkeiten eines Online-Meetings:
                        <br> 1. mit Kollegen innerhalb des Büros (internes Meeting)
                        <br>2. mit Bauherren / Handwerkern von außerhalb des Büros (externes Meeting)</p>
              </div>
	  </b-card>

    <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="internMeeting" title="Internes Meeting">

          <!-- <h5 id="internMeeting" style="">internMeeting</h5> -->
            <div class="p-4">
                <ul>
                        <li>•	Klicke auf „Projekte“ in der oberen Navigationszeile.</li>
                        <li>•	Hier werden alle in ArchiAPP vorhandenen Projekte aufgelistet. Finde das passende, zu besprechende Projekt und klicke darauf. Falls es das Projekt noch nicht gibt, aktiviere den „bearbeiten“-Button, dann links oben auf das Plus-Zeichen. Hier noch den Namen des Projektes eingeben und auf „speichern“ klicken. Fertig.</li>
                        <li>•	Im Projekt selbst klickst du auf „Meeting“ in der zweiten Navigationszeile. Das Programm startet automatisch.</li>
                        <li>•	Ggf. wird gefragt, ob du den Zugriff auf Kamera und Mikrofon erlauben möchtest. Erlaube das und machen am Besten ein Häkchen bei "Diese Entscheidung merken" o.ä.. Auch wenn du den Zugriff erlaubst, startet das Meeting grundsätzlich mit deaktivierter Kamera und Mikrofon. Du kannst diese mit den entsprechenden Buttons unten in der Mitte ein- und ausschalten.</li>
                        <li>•	Ggf. erscheint das Kästchen "Warten auf den Organisator". Dann auf "ich bin der Organisator" klicken und den oberhalb angegebenen Benutzernamen und Passwort eintragen.</li>
                        <li>•	es können sich beliebig viele Kollegen im gleichen Projekt dazu schalten.</li>

                </ul>

                    <p class="mt-2">
                    <b-button style="width:150px" class="mt-1" variant="primary" @click="internesMeetingMP4VolleBreite=!internesMeetingMP4VolleBreite">
                    <span v-if="!internesMeetingMP4VolleBreite">Video vergößern</span> 
                    <span v-else>Video verkleinern</span> 
                    </b-button>
                    </p>

                    <p>
                        <video :class="{'fullWidth' : internesMeetingMP4VolleBreite, 'halfWidth' : !internesMeetingMP4VolleBreite,}"  controls>
                        <source :src="internesMeetingMP4"  type='video/mp4' />
    
                        </video>
                    </p>
            </div>

      	</b-card>
          
              <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="externMeeting" title="Externes Meeting">


          <!-- <h4 id="externMeeting" style="">Externes Meeting</h4> -->

            <div class="p-4">
                          <ul>
                        <li>•	klicke auf den grauen Pfeil / Dreiecke oben links</li>
                        <li>•	klicke auf "Online Meeting" (1.)</li>
                        <li>•	Klicken Sie auf "neuen Meeting-Raum hinzufügen" (2.). In der Tabelle erscheint eine neue Zeile.</li>
                        <li>•	Geben Sie einen beliebigen Namen aus Buchstaben und Zahlen OHNE Leerzeichen in das erste Feld ("neuerRaum") (3.) ein. Beachten Sie, dass die Teilnehmer den gewählten Namen sehen können.</li>
                        <li>•	Nun können Sie auf "E-Mail schreiben" (7.) klicken und es öffnet sich eine vorgefertigte E-Mail. Diese beinhaltet bereits den Link. Diesen kann man erst anklicken, wenn die E-Mail verschickt wurde.</li>
                        <li>•	Alternativ können Sie mit einem Klick auf "Link kopieren" (6.), den Link kopieren und ihn anschließend per E-Mail an die Teilnehmer schicken.</li>
                        <li>•	WICHTIG: Öffnen Sie den Raum (4.) schon einige Minuten bevor das Meeting beginnt. Die Teilnehmer "klopfen dann an" und Sie können diese dann "rein lassen".</li>
                        <li>•	Wenn das Meeting vorbei ist, empfiehlt es sich für mehr Übersichtlichkeit, den Raum mit einem Klick auf das rote X (8.) zu löschen. Wenn Sie mit den gleichen Teilnehmern regelmäßig Meetings abhalten, können Sie den Raum auch bestehen lassen.</li>

                        </ul>
                            <p class="mt-2">
                            <b-button style="width:150px" class="mt-1" variant="primary" @click="meetingExternMP4VolleBreite=!meetingExternMP4VolleBreite">
                            <span v-if="!meetingExternMP4VolleBreite">Video vergößern</span> 
                            <span v-else>Video verkleinern</span> 
                            </b-button>
                            </p>
                            <p>
                            <video :class="{'fullWidth' : meetingExternMP4VolleBreite, 'halfWidth' : !meetingExternMP4VolleBreite,}"  controls>
                            <source :src="meetingExternMP4"  type='video/mp4' />
                            </video>
                            </p>
              </div>

	    </b-card>
      
      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="steeringMeeting" title="Meeting - Steuerung">

          <!-- <h5 id="steeringMeeting" style="">Meeting - Steuerung</h5> -->
            <div class="p-4">
             <p class="h4 mt-4">Buttons während des Meetings</p>
                        
                        <img class="mb-2" style="max-width:150px" width="30%" src="../../public/hilfe/meeting_links_unten.jpg" alt="meeting_links_unten">
                         <div class="p-4">Unten links in der Ecke sind diese Symbole. Das erste öffnet den Chat. Das zweite lässt euch den eigenen Bildschirm freigeben. Das dritte lässt euch „eine Hand heben“. Dies ist vor allem in großen Gruppen nützlich.</div>
                    
                        <img class="mb-2" style="max-width:150px" width="30%" src="../../public/hilfe/meeting_mitte.jpg" alt="meeting_links_unten">
                         <div class="p-4">Unten in der Mitte sind diese Symbole. Das erste schaltet das eigene Mikrofon ein oder aus. Das zweite beendet eure Teilnahme am Meeting. Das dritte schaltet die eigene Kamara ein oder aus.</div>
                        
                        <img class="mb-2" style="max-width:450px" width="30%" src="../../public/hilfe/meeting_rechts_unten.jpg" alt="meeting_links_unten">
                         <div class="p-4">Die drei senkrechten Punkten führen zu den Einstellungen. Diese sind weitgehend selbsterklärend.</div>
                
          </div>
      </b-card>

      <b-card class="ueberschriftHilfe1 anchor my-4 mx-4" id="projekt_allgemein" title="Projekte">
      
      
      </b-card>
          <!-- <h4 id="projekt_allgemein" style="">Projekte</h4> -->

      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="erlauterung_allgemein" title="allgemeine Erläuterungen">


          <!-- <h5 id="erlauterung_allgemein" style="">allgemeine Erläuterungen</h5> -->

            <div class="p-4">
                    <img class="mb-4 border border-primary" width="100%" src="../../public/hilfe/projekteneu.jpg" alt="projekte">
                            <p>1.	neues Projekt anlegen (beim Button "bearbeiten" 5. muss ein Häkchen sein)</p>
                            <p>2.	nach Projekten suchen</p>
                            <p>3.	Ansicht wählen</p>
                            <p>4.	wählen, welche Spalten angezeigt werden sollen</p>
                    <br>
                    Mit einem Klick auf den Namen des Projektes in der Liste in der linken Spalte, kommst du zu dessen Detailansicht.
            </div>
      </b-card>

      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="stammdaten" title="Stammdaten">

          <!-- <h5 id="stammdaten" style="">Stammdaten</h5> -->

          <div class="p-4">
              <p>Hier werden die grundlegenden Daten zum dem Projekt eingetragen. </p>
                Darunter die Bezeichnung des Projektes, der Status (aktuell, abgeschlossen etc.), die Adresse des Baugrundstückes, etc.
          </div>
      
      </b-card>

      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="dateien" title="Dateien">


          <!-- <h5 id="dateien" style="">Dateien</h5> -->

          <div class="p-4">
                    <img class="mb-4 border border-primary" style="width:100%" width="80%" src="../../public/hilfe/dateien.jpg" alt="projekte">
                        <p>1.	im Button "bearbeiten" muss ein Häkchen sein. </p>
                        <p>2.	um eine Datei hochzuladen, auf "durchsuchen" klicken. Anschließend geht ein Fenster aus, in dem man die Datei suchen kann.
                            Es ist möglich, maximal 20 Dateien gleichzeitig hochzuladen. Die Größe darf 128 MB nicht überschreiten.</p>
                        <p>3.	Wenn die hochzuladene Datei(en) ausgewählt wurde, muss diese mit dem Button "Hochladen", hochgeladen werden. Es erscheint eine Statusanzeige, wie lange das Hochladen noch braucht.</p>
                        <p>4.	Um eine Datei einem Externen zur Verfügung zu stellen, klicken Sie auf "Link erstellen". Es wird automatisch ein Link erstellt</p>
                        <p>5.	Dieser Link muss aus Sicherheitsgründen noch aktiviert werden.</p>
                        <p>6.	Wenn der Link aktiv ist, wird der Button grün. Wenn der Link später deaktiviert werden soll, einfach noch mal auf den Button klicken.</p>
                        <p>7.	Der Link kann mit einem Klick auf "kopieren" bequem in die Zwischenablage kopiert werden und von dort aus z.B. in eine E-Mail eingefügt werden.</p>
                        <p>8.	Mit einem Klick auf das rote X wird die Datei in den Papierkorb verschoben</p>
                        <p>9.	Die "gelöschten Dateien" / der Papierkorb enthält die gelöschten Dateien. Diese können von hier aus wieder hergestellt werden. Endgültig löschen können nur Benutzer mit speziellen Berechtigungen.</p>
                            
          </div>
      </b-card>

      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="formular" title="Formular">


          <!-- <h5 id="formular" style="">Formular</h5> -->

                    <div class="p-4">
                        Um ein neues Formular anzulegen, muss "bearbeiten" aktiviert sein (Nr. 12). Dann können Sie unter "neues Formular" (Nr. 1) oder "vorhandene Formulare" (Nr. 2) das gewünschte Formular auswählen.
                        Im Bereich "aktuelles Formular" (Nr. 3) sehen Sie, welches Formular gerade angezeigt wird.<br> <br>
                        <img class="mb-4 border border-primary" style="width:100%" src="../../public/hilfe/formulare.png" alt="Formular waehlen">
                        <br>
                        <b>weitere Schaltflächen:</b>
                        <ul>
                        <li>4.	Zoom ein- und ausschalten. Dadurch kann in das Formular mithilfe des Mausrades rein- und raus gezoomt werden. Mit der linken Maustaste kann der Bildausschnitt verschoben werden. Durch einen Doppelklick auf diesen Button, wird die Zommstufe wieder auf den Ausgangswert zurückgestellt.</li>
                        <li>5.	Formular umbennenen </li>
                        <li>6.	Formular löschen (mit einem Doppelklick wird das Formular ohne weitere Bestätigung direkt gelöscht</li>
                        <li>7.	Formular mit Stammdaten füllen</li>
                        <li>8.	Formular als PDF exportieren</li>
                        <li>10.	Zuletzt exportierte PDF anzeigen oder herunterladen</li>
                        <li>11.	Liste mit allen erstellten Formularen anzeigen</li>
                        <li>12.	bearbeiten ein- und ausschalten</li>
                        <li>13.	in die rosa hinterlegten Felder kann geschrieben werden</li>
                        </ul>
                    </div>
      </b-card>

      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="beteiligte" title="Beteiligte">


          <!-- <h5 id="beteiligte" style="">Beteiligte</h5> -->
                <div class="p-4">
                    <div class="p-2">
                        <b>Bauherrschaft:</b><br><br>
                        <img class="mb-4 border border-primary" style="width:100%" src="../../public/hilfe/beteiligte_bauherrschaft.jpg" alt="Formular waehlen">
                        <br>In der linken Spalte stehen alle Kontakte aus dem Adressbuch zu Verfügung, die der Kategorie "Bauherr(in)" angehören.
                        <br>Durch einen Klick auf den Namen, wird der Kontakt mit dem Projekt verknüpft.
                        <br>Wenn ein anderer Name geklickt wird, wird der vorherige Bauherr(in) ersetzt. Falls die Bauherrschaft komplett gelöscht werden soll, auf das rote X neben dem aktuellen Bauherr(in) klicken.
                    </div>

                    <div class="p-2">
                        <b>Planer / Bauamt:</b><br><br>
                        <img class="mb-4 border border-primary" style="width:100%" src="../../public/hilfe/beteiligte_planer.jpg" alt="Formular waehlen">
                        <br>Entwurfsverfasser(in), Bauvorlageberechtigte(r) und Bauamt können per Drop-Down Liste ausgewählt werden.
                        <br>Die Leistungsphasen können einzeln durch klick auf "auswählen", den Mitarbeitern des eigenen Büros zugeordnet werden.
                        <br>Hierbei können auch mehrere Mitarbeiter einer Leistungsphase zugeordnet werden.
                    </div>

                    <div class="p-2">
                        <b>Fachplaner(in) / Handwerker(innen):</b><br><br>
                        <img class="mb-4 border border-primary" style="width:100%" src="../../public/hilfe/beteiligte_handwerker.jpg" alt="Formular waehlen">
                        <br>Durch einen Klick auf "hinzufügen" erscheint eine neue Zeile. Der erste Eintrag ist eine Drop-Down Liste mit möglichen Tätigkeitsschwerpunkten.
                        <br>Der zweite Eintrag erscheint, nachdem der Tätigkeitsschwerpunkt ausgewählt wurde. Es ist eine Drop-Down Liste mit Kontakten, denen der gewählte Tätigkeitsschwerpunkt zugeordnet ist.
                    </div>
                  </div>

     </b-card>

      <b-card class="ueberschriftHilfe1 anchor my-4 mx-4" id="adressbuch_hilfe" title="Adressbuch">
        <div class="p-4">
        
          Im Adressbuch können Kontakte gespeichert werden. Sowohl von Einzelpersonen als auch Unternehmen / Behörden etc.
          <br>
          Zu jedem Kontakt können alle notwendigen Daten gespeichert werden.
          <br>
          <ul>

            <li>
              <b-row>
                <b-col cols="4">
                  Art des Eintrags ->
                </b-col>
                <b-col>
                  Gruppe von Personen oder Einzelperson
                </b-col>
              </b-row>
            </li>

             <li>
                <b-row>
                <b-col cols="4">
                 Status -> 
                </b-col>
                <b-col>
                  aktiv, dauerhaft geschlossen, Ruhestand, verstorben, unbekannt
                </b-col>
              </b-row>
            </li>

              <li>
                <b-row>
                <b-col cols="4">
                 Kategorie des Eintrags  -> 
                </b-col>
                <b-col>
                  Amt / Behörde, Architekt, Bauherr(in), Bauherr(in), jur. Person, Bauleiter(in), Bauvorlageberechtigte(r), Fachingenieur(in), Handwerker(in), Mieter, Planer(in), Sonstige, Versorger
                </b-col>
              </b-row>
            </li>


          </ul>
        </div>
      </b-card>
            
      <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="adressbuch_allgemein" title="allgemeine Erläuterungen">

                    <div class="p-4">
                        Es gibt grundsätzlich zwei Arten von Kontakten: <b>Einzelpersonen und Gruppen</b>. Einzelpersonen können beliebigen und auch beliebig vielen Gruppen zugeordnet werden.
                    
                        <img class="my-4 border border-primary" width="100%" src="../../public/hilfe/kontakte.jpg" alt="Adressbuch Kontakte Verbindungen">

                    </div>

                    <div class="p-4">

                        <img class="mb-4 border border-primary" width="100%" src="../../public/hilfe/Adressbuch oberste Zeile neu.jpg" alt="Adressbuch Bearbeitungs-Zeile">
                                <p>1.	Neuen Adressbucheintrag anlegen</p>
                                <p>2.	Zum Papierkorb wechseln, in dem sich die gelöschten Kontakte befinden</p>
                                <p>3.	Kontakte nach bestimmten Kriterien filtern</p>
                                <p>4.	Mehrere Kontakte markieren</p>
                                <p>5.	Kontakte nach bestimmten Kriterien sortieren</p>
                                <p>6.	Listenfunktion</p>
                                <p>7.	Suche nach Namen</p>
                                <p>8.	Den eingegebenen Suchnamen löschen</p>

                        
                        <hr class="my-4">

                            <img class="mb-4 border border-primary" width="100%" src="../../public/hilfe/Adressbuch komplett.jpg" alt="Adressbuch">
                                    <p>1.	alle verfügbaren, aktive (d.h. nicht gelöschte) Kontakte</p>
                                    <p>2.	aktuell ausgewählter Kontakt</p>
                                    <p>3.	Listenfunktion</p>
                                    <p>4.	den ausgewählten Kontakt bearbeiten</p>
                                    <p>5.	den ausgewählten Kontakt löschen</p>
                                    <p>6.	Telefonnummer und E-Mail Adresse des verbundenen Kontaktes anzeigen</p>
                                    <p>7.	zu diesem verbundenen Kontakt wechseln</p>
                                    <p>8.	hier tippen, um eine neue Liste zu erstellen. Anschließend auf "erstellen" klicken</p>
                                    <p>9.	drop-down Liste der vorhandenen Listen</p>

                            <br>

                            Mit einem Klick auf den Namen des Kontaktes in der Liste in der linken Spalte (aktive Kontakte), kommen Sie zu dessen Detailansicht.
                            <br>
                            "Art des Eintrags" Kontakte können eine Einzelperson oder eine Gruppe von Personen repräsentieren.
                        </div>
            </b-card>


            <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="listen" title="Listen">

                <div class="p-4">
                    Listen dienen dazu, mehrere Kontakte zusammen zufassen, um mit diesen anschließend eine Aktion ausführen zu können. Zum Beispiel in Form eines Serienbriefes.
                <br><br>
                </div>

                    <div class="p-4">

                        <img class="mb-4 border border-primary" style="max-height:22em;"  src="../../public/hilfe/Listen.jpg" alt="Adressbuch Listen">
                                <p>1.	neue Liste anlegen</p>
                                <p>2.	Namen der neuen Liste eingeben</p>
                                <p>3.	die neue Liste erstellen (wird automatisch gespeichert)</p>
                                <p>4.	Auswahl, welche LIste aktiviert werden soll</p>
                                <p>5.	den letzten Schritt rückgängig machen</p>
                                <p>6.	Anzahl der Einträge der aktiven Liste</p>
                                <p>7.	PDF erstellen</p>
                                <p>8.	Namen der Kontake auf der Liste</p>
                                <p>9.	wenn man auf das Kreuz klickt, wird der Kontakt aus der Liste entfernt</p>
                    </div>

                    <div class="p-4">

                        <img class="mb-4 border border-primary" style="max-height:22em;"  src="../../public/hilfe/KontaktListe.jpg" alt="Adressbuch Listen Spezialfunktionen">
                                <p>1.	Wenn der Schieber auf 'ein' steht, wird der "Anzeigename" als Name in der Liste verwendet. Andernsfalls werden Nachname und, falls vorhanden , der Vorname und Titel verwendet</p>
                                <p>2.	Wenn in der Liste eine andere Anrede verwendet werden soll, zum Beispiel "Familie" anstatt "Frau" oder "Herr", kann die gewünschte Anrede mittels Drop-Down-Feld gewählt werden.</p>
                                <p>3.	Wenn das Häkchen gesetzt ist, wird der Name des verbundenen Kontaktes in die erste Zeile der aktiven Liste geschrieben. </p>
                                <p>4.	Wenn der Kontakt mehrere Adressen hat, kann hier die für die aktive Liste gewünschte Adresse ausgewählt werden.</p>

                    </div>

            </b-card>

            <b-card class="ueberschriftHilfe2 anchor my-4 mx-4" id="kalender" title="Kalender">



            </b-card> 


          <div  :style="{height:hierHoehe}" class="p-4">
          </div>


         
        </div>
      </b-col>
    </b-row>


  </b-container>

 </div>


</template>

<script>


export default {

    data(){
        return{
// config : {
//   element: 'body',
//   offset: 150,
//   method: 'auto',
//   throttle: 100
// },

 text: 'Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla           tempor. Laborum consequat non elit enim exercitation cillum aliquaconsequat id aliqua. Esse ex consectetur mollit voluptate est in duis           laboris ad sit ipsum anim Lorem. Incididunt veniam velit elit elit veniamLorem aliqua quis ullamco deserunt sit enim elit aliqua esse irure. Laborum          nisi sit est tempor laborum mollit labore officia laborum excepteurcommodo non commodo dolor excepteur commodo. Ipsum fugiat ex est consectetur          ipsum commodo tempor sunt in proident.Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla           tempor. Laborum consequat non elit enim exercitation cillum aliquaconsequat id aliqua. Esse ex consectetur mollit voluptate est in duis           laboris ad sit ipsum anim Lorem. Incididunt veniam velit elit elit veniamLorem aliqua quis ullamco deserunt sit enim elit aliqua esse irure. Laborum          nisi sit est tempor laborum mollit labore officia laborum excepteurcommodo non commodo dolor excepteur commodo. Ipsum fugiat ex est consectetur          ipsum commodo tempor sunt in proident.',
            meetingExternMP4: this.$store.state.base_url + 'video_abfrage2.php?name=ArchiAPPMeetingExtern&besonders=' + this.$store.state.dev_sub,
            meetingExternMP4VolleBreite:false,
            internesMeetingMP4: this.$store.state.base_url + 'video_abfrage2.php?name=internesMeeting&besonders=' + this.$store.state.dev_sub,
            internesMeetingMP4VolleBreite:false,

            
        }
    },
     mounted() {
     

        },

	methods:{


    hideModal(welches) {
        this.$refs[welches].hide()
      },

	},

 computed:{

			hierHoehe(){
			    return this.window.height - 160 + 'px'
			},

        

    },

	created(){

	}
	
	//
}
</script>

<style>

    .anchor{
      margin-top: 50px !important;
      padding-top: 35px;
    }

		.ueberschriftHilfe1 .card-title
		{

		font-size: 1.6rem;
		text-transform: uppercase;
		font-weight: 700;
    letter-spacing: 0.0625em;
		}

    .ueberschriftHilfe2 .card-title
		{

		font-size: 1.3rem;
		text-transform: uppercase;
		font-weight: 600;
    letter-spacing: 0.0625em;
		}



</style>

<style scoped>

body {height: 100%; }

p:not(.h3):not(.h4) {font-size:1.0rem;}

video {

  height: auto   !important;
}

 .fullWidth{
  /* override other styles to make responsive */
  width: 100%    !important;

}

 .halfWidth{
  /* override other styles to make responsive */
  width: 50%    !important;

}


.card-header{

    font-weight: 700;
    letter-spacing:0.1em;
    font-size: 1.1em;
}
.haupt-header{
    background-color: lightskyblue;


}

.haupt{

    font-weight: 800;
    letter-spacing:0.1em;

}



</style>
