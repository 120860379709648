<template>
	<div v-if="projectDaten">
    <div class="kontainer">

  <b-card v-if="bearbeiten" class="text-center mb-4">
      <div class="bg-secondary text-light">
      Bitte beachten: Die maximale Dateigröße beträgt 256 MB und es können maximal 20 Dateien gleichzeitig hochgeladen werden.
      </div>
    </b-card>

 <!-- {{$route}} -->
           <!-- <b-button class="ml-2" variant="outline-primary" @click="read_filed()">read</b-button> -->
  
<b-row v-if="bearbeiten" class="mb-4">
  <!-- {{vorhandeneDateien}} -->
  <!-- 
      <b-col cols="6"> -->
        <label>Datei(en) auswählen:
          <input @input="checkAnzahl" class="btn btn-primary" type="file" name="file[]" multiple id="fileInput" ref="file">
        </label>
      <!-- </b-col> -->

      <!-- <b-col cols="2"> -->
        <span v-b-tooltip.hover :title="keinButtonGrund">
           <b-button v-bind:class="{ 'button-glow': glowsubmitButton}" :disabled="submitButton" class="ml-2" variant="outline-primary" v-on:click="submitForm()">Hochladen</b-button>
        </span>
      <!-- </b-col> -->

        <!-- <b-col v-if="submitButton==false"> -->
           <span v-if="submitButton==false" class="ml-2">
           <progress max="100" :value.prop="uploadPercentage"></progress>
          </span>
        <!-- </b-col> -->

      </b-row>

        
    <hr class="my-2 mb-4">

<b-card class="mt-4" no-body>
    <b-tabs card>
      <b-tab title="aktuelle Dateien" active>
        <span v-if="vorhandeneDateien.aktiv.length>0">
          <span :key="index" v-for="(datei, index) in vorhandeneDateien.aktiv">
            
              <!-- {{datei}} -->

            <!-- <b-row >
              <b-col>{{datei.basename}} D</b-col> 

              </b-row> -->

            <b-row class="mb-2" v-if="datei.ist_ordner!=true">

                <b-col style="max-width:55px" cols="1">
                <b-button v-b-tooltip.hover 
                v-if="datei.download" 
                  title="Lädt die Datei herunter." 
                  variant="success" @click="openLink($store.state.base_url + datei.download)">
                  <!-- <span class="d-none d-lg-block"> download <b-icon icon="download"></b-icon></span>
                  <span  class="d-lg-none">  <b-icon icon="download"></b-icon></span> -->

                <b-icon icon="download"></b-icon>
                  

              </b-button>
              </b-col>

              <b-col>
                {{datei.basename}} ({{datei.groesse_kb}} kB)
              </b-col>

              <b-col v-if="bearbeiten" cols="1"  style="max-width:70px; border-right: 5px solid grey;">

                <b-button v-b-tooltip.hover 
                v-if="datei.dateiID" 
                :id="datei.dateiID"
                  title="Datei löschen" 
                  variant="danger" @click="loesche1click(datei.dateiID)">X</b-button>
                  <!-- :show.sync="show"
                  :placement='placement'  -->

                <b-popover 
                  v-if="datei.dateiID" 
                  :show.sync="datei.delete"
                  :ref="'popover'+datei.dateiID" 
                  :target="datei.dateiID"  
                  >
            <template slot="title">
                <!-- <b-button @click="onClose" class="close" aria-label="Close">
                  <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button> -->
                Wirklich löschen?
              </template>

            <span style="font-size:1.0em; font-weight:700; margin-right:10px;"></span>
            <b-button @click="activateFile(datei.dateiID, 0)" class="mr-2" style="min-width:50px" size="sm">ja</b-button>
            <b-button @click="datei.delete=false" style="min-width:50px" size="sm">nein</b-button>

            <!-- <b-form-input type=number min=1 max=20 " placeholder="Schriftgröße"></b-form-input> -->

            </b-popover>
              </b-col>

                <b-col v-if="!datei.link_url && bearbeiten" cols="2">

                <b-button v-b-tooltip.hover 
                  title="Erzeugt einen Link, mit dem man diese Datei herunterladen kann. Dieser Link kann an externe Personen weitergegeben werden, damit diese Zugriff auf diese Datei erhalten." 
                  variant="outline-primary"
                  :disabled="datei.link_url.length>0"
                  @click="createDirektDowload(datei.dateiID, true, index)"
                type="button" >
                
                  <span class="d-none d-lg-block"> Link erstellen <b-icon icon="cloud-plus"></b-icon></span>

                  <span  class="d-lg-none"><b-icon icon="cloud-plus"></b-icon></span>

                </b-button>
              </b-col>

              <b-col v-if="datei.direktDownloadaktiv==1 && bearbeiten" cols="2">
                  
                <b-button v-b-tooltip.hover 
                  title="Dieser Link ist aktuell aktiviert. Klicken Sie hier, um ihn zu deaktivieren." 
                  variant="outline-success"
                  :disabled="datei.link_url.length==0"
                  @click="activateDirektDowload(datei.dateiID, '0', index)"
                type="button" >
                
                  <span class="d-none d-lg-block"> Link aktiv? <b-icon icon="emoji-smile"></b-icon></span>

                  <span  class="d-lg-none"><b-icon icon="emoji-smile"></b-icon></span>

                </b-button>
              </b-col>

              <b-col v-if="datei.direktDownloadaktiv!=1 && datei.link_url && bearbeiten" cols="2">
                  
                <b-button v-b-tooltip.hover 
                  title="Dieser Link ist aktuell deaktiviert. Klicken Sie hier, um ihn zu aktivieren." 
                  variant="outline-danger"

                  @click="activateDirektDowload(datei.dateiID, '1', index)"
                type="button" >
                
                  <span class="d-none d-lg-block"> Link aktiv? <b-icon icon="emoji-frown"></b-icon></span>

                  <span  class="d-lg-none"><b-icon icon="emoji-frown"></b-icon></span>

                </b-button>
              </b-col>

                <!-- //sm="auto" -->
              <b-col cols="2">

                  <b-button v-b-tooltip.hover 
                
                    title="kopiert den Link in die Zwischenablage, sodass er an anderer Stelle eingefügt werden kann." 
                    variant="outline-primary"
                    v-if="datei.link_url" 
                    type="button" 
                    @click="doCopy($store.state.datei_direkt_download +datei.link_url)">

                  
                    <span class="d-none d-lg-block"> kopieren <b-icon icon="clipboard-check"></b-icon></span>
                    <span  class="d-lg-none"><b-icon icon="clipboard-check"></b-icon></span>

                  </b-button>

              </b-col>

              <b-col>
                        <b-form-input 
                        v-b-tooltip.hover 
                
                        title="Rote Links sind NICHT aktiv" 
                        :class="{'black-color':datei.direktDownloadaktiv==1, 'red-color':datei.direktDownloadaktiv!=1}" :disabled="datei.direktDownloadaktiv==1" 
                        v-if="datei.link_url" class="form-control-plaintext" 
                        :value="$store.state.datei_direkt_download + datei.link_url"></b-form-input>
              </b-col>

              <!-- <b-col>
                  <b-button v-b-tooltip.hover 
                
                    title="beschränkt die Nutzbarkeit des Links auf E-Mail Adressen" 
                    variant="outline-primary"
                    v-if="datei.dateiID" 
                    type="button" 
                    @click="openModal">

                  
                    <span class="d-none d-lg-block"> schützen <b-icon icon="bricks"></b-icon></span>
                    <span  class="d-lg-none"><b-icon icon="bricks"></b-icon></span>

                  </b-button>

                <ul>
                  <li :key="indexMail" v-for="(mail, indexMail) in datei.zugriff_mails" >
                      {{mail}}
                  </li>
                  </ul>
              </b-col> -->


            </b-row>
            
             <hr class="my-1">
          
          </span>
        </span>
        <span v-else>Es sind keine Dateien vorhanden.</span>
      </b-tab>



          <!-- gelöschte Dateien -->


      <b-tab title="gelöschte Dateien">
          <span v-if="vorhandeneDateien.unaktiv.length>0">
          <span :key="index" v-for="(datei, index) in vorhandeneDateien.unaktiv">
            


            <b-row class="mb-2" v-if="datei.ist_ordner!=true">

                <b-col style="max-width:55px" cols="1">
                <b-button v-b-tooltip.hover 
                v-if="datei.download" 
                  title="Lädt die Datei herunter." 
                  variant="success" @click="openLink($store.state.base_url + datei.download)">
                  <!-- <span class="d-none d-lg-block"> download <b-icon icon="download"></b-icon></span>
                  <span  class="d-lg-none">  <b-icon icon="download"></b-icon></span> -->

                <b-icon icon="download"></b-icon>
                  

              </b-button>
              </b-col>

              <b-col style="max-width:55px" cols="1">

                <b-button v-b-tooltip.hover 
                v-if="datei.dateiID" 
                  title="Datei wiederherstellen" 
                  variant="primary" 
                  @click="activateFile(datei.dateiID, 1)">
                  <span class="fa fa-undo"></span>
                  </b-button>

              </b-col>

               <b-col v-if="admin==true" style="max-width:55px" cols="1">

                <b-button v-b-tooltip.hover 
                v-if="datei.dateiID" 
                  title="Datei endgültig löschen" 
                  variant="danger" 
                  @click="deleteFile(datei.dateiID)">
                  X
                  </b-button>

              </b-col>


              <b-col>
                {{datei.basename}} ({{datei.groesse_kb}} kB)
              </b-col>


            </b-row>
            
             <hr class="my-1">
          
          </span>
        </span>
        <span v-else>Es sind keine Dateien vorhanden.</span>
      </b-tab>
    </b-tabs>
  </b-card>

		</div>
	</div>
</template>

<script>

	import { projectMixin } from '../../mixins/projectMixin.js'
  import { ModelObj } from 'vue-3d-model';
  import { ModelStl } from 'vue-3d-model'
  import { ModelCollada } from 'vue-3d-model'
  import { ModelFbx } from 'vue-3d-model'

  import { forAllMixin } from '../../mixins/forAllMixin.js'

export default {

		mixins: [projectMixin, forAllMixin],
    components: { ModelObj, ModelStl, ModelCollada, ModelFbx },
    data(){
        return{
          light:[
                    {
                        type: 'HemisphereLight',
                        position: { x: 0, y: 1, z: 0 },
                        skyColor: 0xaaaaff,
                        groundColor: 0x806060,
                        intensity: 0.2
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: 1, z: 1 },
                        color: 0xffffff,
                        intensity: 0.8
                    }
                ],
          file: '',
          submitButton:true,
          keinButtonGrund:'Wählen Sie eine Datei aus',
          uploadPercentage: 0,
          glowsubmitButton:false,
          vorhandeneDateien:{aktiv:[], unaktiv:[]},


        }
	},

	computed:{

			logObject(){
				return this.$store.getters.logObject;
			},


    },
    
  mounted(){
      console.log(this.vorhandeneDateien.aktiv.length)

      if(this.vorhandeneDateien.aktiv.length==0)
      this.read_filed();

  },

	methods:{



		loesche1click(dateiID){

		 this.clicks++ 
          if(this.clicks === 1) {
            var self = this
            this.timer = setTimeout(function() {

                    var index_trg = self.vorhandeneDateien.aktiv.findIndex(item=>item.dateiID == dateiID)
                    if(index_trg>=0)
                     self.vorhandeneDateien.aktiv.delete = true

              	self.clicks = 0
            }, this.delay);
          } else{
             clearTimeout(this.timer);  
       //alert('dblclick');
       
			      this.activateFile(dateiID, 0);
            this.clicks = 0;
		  }         


    },
    
    deleteFile(dateiID){

    var projectID = this.$route.params.id

            this.axios.post(this.$store.state.dateien_abfrage, {
                    dateiID:dateiID,
                     projektID: projectID,
                    delete: true,
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {

                    if(response.data.success==true){
                      var index_trg = this.vorhandeneDateien.unaktiv.findIndex(item=>item.dateiID == dateiID)

                      if(index_trg>=0)
                        this.vorhandeneDateien.unaktiv.splice(index_trg, 1);
                    }
                })
                .catch((error) => {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })

      

    },
    activateFile(dateiID, aktivator){
      //  alert('activateFile');
      //  console.log(dateiID)
      var projectID = this.$route.params.id
                this.axios.post(this.$store.state.dateien_abfrage, {
                    projektID: projectID,
                    dateiID:dateiID,
                    activation: aktivator,
                    activator:true,
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {

                  if(aktivator==0){
                    var index_trg = this.vorhandeneDateien.aktiv.findIndex(item=>item.dateiID == dateiID)

                    if(index_trg>=0){
                      this.vorhandeneDateien.unaktiv.unshift( this.vorhandeneDateien.aktiv[index_trg]);
                      this.vorhandeneDateien.aktiv.splice(index_trg, 1);
                    }
                        
                  }
                  else if (aktivator==1){
                      
                      var index_trg = this.vorhandeneDateien.unaktiv.findIndex(item=>item.dateiID == dateiID)
                      if(index_trg>=0){
                        this.vorhandeneDateien.aktiv.unshift( this.vorhandeneDateien.unaktiv[index_trg]);
                        this.vorhandeneDateien.unaktiv.splice(index_trg, 1);
                      }
                  }
                        
                })
                .catch((error) => {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })





    },




    onCopy: function (e) {
    },


      randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
      },

	getSelectCalReturn(was){
        console.log("getDAVReturn");
        return this.axios.post(this.$store.state.url_getdav, {
		   was:was,
		   aktuellerFilter:this.filterText,
           besonders: this.$store.state.dev_sub,
           }).then((response) => {
      
           // returning the data here allows the caller to get it through another .then(...)
           return response.data
         })
      
     },

     activateDirektDowload(dateiID, direktDownloadaktiv, index){

            this.axios.post(this.$store.state.datei_direkt_dateien_create, {
                    dateiID: dateiID,
                    aktiv: direktDownloadaktiv,
                    besonders: this.$store.state.dev_sub,

                }).then((response) => {
                    if(response.data.success==true){

                      this.vorhandeneDateien.aktiv[index].direktDownloadaktiv = direktDownloadaktiv
                      if(direktDownloadaktiv==1)
                      this.$toasted.show( "Link wurde erfolgreich aktiviert.", { position: 'top-center',  duration:3000, type:'success', containerClass:'ganzVorne'})
                      else
                      this.$toasted.show( "Link wurde erfolgreich deaktiviert.", { position: 'top-center',  duration:3000, type:'success', containerClass:'ganzVorne'})

                    }
                })
                .catch((error) => {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })

     },

      createDirektDowload(dateiID, fromButton, IndexvorhandeneDateien){
        var projektID = this.$route.params.id
        var passwort = this.randomString(8, '0123456789abcdefghijklmnopqrstuvwxyz')
        
        var url = this.$store.state.base_url + "/datei_direkt_download.php"
        var url2 = "?1=" + projektID + "&2=" + passwort + "&3=" + dateiID
        url += url2

        var antwort = {'success':false, 'url':url}

            //return 
            this.axios.post(this.$store.state.datei_direkt_dateien_create, {
                    projektID: projektID,
                    passwort: passwort,
                    dateiID: dateiID,
                    create: true,
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {

                   //return 
                  //  response.data

                  // // console.log(response.data);
                  if(response.data.success==true && fromButton==true){
                    this.vorhandeneDateien.aktiv[IndexvorhandeneDateien].link_url = url2

                    antwort.success = true
                    console.log(antwort);
                    this.$toasted.show( "Link wurde erfolgreich erstellt.", { position: 'top-center',  duration:3000, type:'success', containerClass:'ganzVorne'})
                  }
                  // return antwort;
                })
                .catch((error) => {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })



      },

      checkAnzahl(){
        this.glowsubmitButton=false
        this.submitButton=true
        // alert("checkAnzahl")
        // console.log(this.$refs.file.files.length)
        if(this.$refs.file.files.length==0)
        this.keinButtonGrund = "Wählen Sie eine Datei aus";

        else if(this.$refs.file.files.length>0 && this.$refs.file.files.length<21){
            var FileSize = 0
             for( var i = 0; i < this.$refs.file.files.length; i++ )
                  FileSize += this.$refs.file.files[i].size / 1024 / 1024; // in MB

              if(FileSize>256)
                this.keinButtonGrund = "Die maximal Dateigröße von 256 MB wurde überschritten.";
              else{
                this.glowsubmitButton=true
                this.submitButton=false
              }
                

        }
        

        else if(this.$refs.file.files.length>20)
        this.keinButtonGrund = "Sie dürfen maximal 20 Dateien gleichzeitig hochladen. Wählen Sie weniger Dateien aus.";

      },

    read_filed(){
      var projectID = this.$route.params.id

                this.axios.post(this.$store.state.dateien_abfrage, {
                    projektID: projectID,
                    was: "getFiles",
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {

                  this.vorhandeneDateien=response.data

                })
                .catch((error) => {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })
      },

      submitForm(){

        let formData = new FormData();
        var projectID = this.$route.params.id
        formData.append('projectID', projectID);
        formData.append('besonders', this.$store.state.dev_sub);


            for( var i = 0; i < this.$refs.file.files.length; i++ ){
                let file = this.$refs.file.files[i];
               // console.log(file);
                formData.append('files[' + i + ']', file);
            }

            this.axios.post(this.$store.state.dateien_upload,

                formData,

                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                }.bind(this)

              	}).then((response) => {

                  this.uploadPercentage= 0
                  this.submitButton=true
                  this.glowsubmitButton=false
                  
                  console.log(response.data);

                  // if(response.data.dateiID)
                  // this.createDirektDowload(response.data.dateiID);

                  var inputFeld = document.getElementById("fileInput");
                  inputFeld.type = ''
                  inputFeld.type = 'file'


                        for (let i in response.data) {

                          if(response.data[i].error==0){
                            this.$toasted.show(response.data[i].dateiname + "  wurde erfolgreich hochgeladen.", { position: 'top-center',  duration:3000, type:'success', containerClass:'ganzVorne'})
             
                              // this.createDirektDowload(response.data.dateiID).then(data => {
                              //     //this.filterText = data;
                              // })
                          }

                          else if(response.data[i].error==1)
                            this.$toasted.show("Der Dateityp von " + response.data[i].dateiname + " ist NICHT zugelassen.", { position: 'top-center',  duration:10000, type:'error', containerClass:'ganzVorne'})
                          else if(response.data[i].error==2)
                            this.$toasted.show("Der Dateiname von " + response.data[i].dateiname + " ist NICHT zugelassen.", { position: 'top-center',  duration:10000, type:'error', containerClass:'ganzVorne'})
                          else
                            this.$toasted.show("Beim Hochladen ist ein unbekannter Fehler aufgetaucht.", { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                        }

                  this.read_filed();

               
                




              

            })

	          .catch((error) => {

              	this.$toasted.show("Beim Hochladen ist etwas schief gelaufen.", { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})


            });

      },

  
	  
	},
	created(){

	}
	
	//
}
</script>


<style scoped>

  .red-color{
     color: #ff0000 !important;
  }
    .black-color{
     color: #000 !important;
  }

</style>
