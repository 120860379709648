<template>
	<div>

    <div class="kontainer">

				<h1>Bautagebuch</h1>
				<p>Text</p>
				<div class="row">
    <div class="col-sm">
      One of three columns
    </div>
    <div class="col-sm">
      One of three columns
    </div>
    <div class="col-sm">
      One of three columns
    </div>
  </div>
  <!-- {{caldav}} -->
			</div>

            </div>
</template>

<script>



// import caldav from 'caldavjs-nextcloud'



export default {

    data(){
        return{

        }
    },
    
    methods:{

    },

  	mounted(){

 	  },

    created(){

    }
	
	//
}
</script>


<style>


</style>
