<template>
  <div>
       <div class="kontainer" style="height:600px">

 <b-card
      header=""
      header-tag="header"
      title="Online - Meetings mit externen Teilnehmern"
    >
      <b-card-text>
        
   <h5 class="border border-warning p-2" v-if="$browserDetect.isChrome!=true && $browserDetect.isEdge!=true">

                ArchiAPP empfiehlt für die Benutzung der Funktion "Bildschirm teilen" den Browser Chrome oder Edge, da in diesen die zugrunde liegenden Technologie am Besten umgesetzt wurde.
                
                <span v-if="$browserDetect.isChrome==true && $browserDetect.meta.version<72">
                        Bitte bringen Sie Ihren Browser Chrome auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
                </span> 
                 <span v-if="$browserDetect.isEdge==true && $browserDetect.meta.version<79">
                        Bitte bringen Sie Ihren Browser Edge auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
                </span>

    </h5>

    <div style="line-height: 1.6; font-size:1.2em;">
        <ul>
          <li>Sie können bereits im Vorfeld einen Meetingraum anlegen, um den entsprechenden Link mit den Teilnehmern zu teilen.</li>
        
          <li><b>WICHTIG:</b> Der Name darf nur aus Buchstaben bestehen! KEINE Leerzeichen und KEINE Sonderzeichen.</li>
          <li>Wenn Sie zur Eingabe eines Benutzernamens und Passwort als Organisator aufgefordert werden, ist der Benutzername: <u>RA</u> und das Passwort: <u>51515</u></li>
        
        </ul>
     </div>
     

        <!-- <p class="mt-1 h5">{{jitsiOptions}}</p> -->

 <b-button v-b-tooltip.hover 
                    title="neuer Raum" 
                    variant="outline-primary"
                    @click="addRoom">neuen Meeting-Raum hinzufügen</b-button>

<div class="mt-4">

 <b-row class="border-bottom mt-2 p-2" v-for="(item, index) in raeume" :key="index">

    <b-col>
          <b-form-input @blur="updateDB" v-model="raeume[index].raumName"></b-form-input>
         </b-col>

    <b-col cols="2">  

      <b-button
          variant="outline-primary"

          type="button" 
          @click="openRoom(item.raumName)">
          <span class="d-none d-lg-block"> Raum öffnen <b-icon icon="door-open"></b-icon></span>
          <span class="d-lg-none"><b-icon icon="door-open"></b-icon></span>

        </b-button>
      </b-col>

    <b-col cols="2">        

      <b-button v-b-tooltip.hover 
          title="kopiert den Link in die Zwischenablage, sodass er an anderer Stelle eingefügt werden kann." 
          variant="outline-primary"
          type="button" 
          @click="doCopy('https://archi-app.de/meetingExtern/' + item.raumName)">
          <span class="d-none d-lg-block"> Link kopieren <b-icon icon="clipboard-check"></b-icon></span>
          <span  class="d-lg-none"><b-icon icon="clipboard-check"></b-icon></span>

        </b-button>

      </b-col>

      <b-col  cols="2">
          <b-button
            variant="outline-primary"
            :href="'mailto:?subject=Einladung%20zum%20Online-Meeting%20mit%20'+ userSelbst.vollerName.anzeigeName +'&body=Guten%20Tag%20,%0A%0Aum%20am%20Online-Meeting%20mit%20'+ userSelbst.vollerName.anzeigeName +'%20teilzunehmen,%20klicken%20Sie%20bitte%20auf%20nachfolgenden%20Link:%0A%0Ahttps://archi-app.de/meetingExtern/'+ item.raumName +'%0A%0ABitte%20beachten%20Sie,%20dass%20das%20Online-Meeting%20in%20vollem%20Umfang%20nur%20auf%20einem%20normalen%20PC,%20einem%20Laptop%20oder%20Tablet%20m%C3%B6glich%20ist,%20auf%20Smartphones%20nur%20eingeschränkt.%0A%0ABitte%20beachten%20Sie,%20dass%20ein%20aktueller%20Browser,%20idealerweise%20%22Chrome%22%20oder%20%22Edge%22,%20notwendig%20sind.'"
          >
          
          <span class="d-none d-lg-block"> Einladung - E-Mail <b-icon icon="envelope"></b-icon></span>
          <span class="d-lg-none"><b-icon icon="envelope"></b-icon></span>

        </b-button>

           <!-- <a :href="'mailto:?subject=Einladung%20zum%20Online-Meeting%20mit%20'+ userSelbst.vollerName.anzeigeName +'&body=Guten%20Tag%20,%0A%0Aum%20am%20Online-Meeting%20mit%20'+ userSelbst.vollerName.anzeigeName +'%20teilzunehmen,%20klicken%20Sie%20bitte%20auf%20nachfolgenden%20Link:%0A%0Ahttps://archi-app.de/meetingExtern/'+ item.raumName +'%0A%0ABitte%20beachten%20Sie,%20dass%20das%20Online-Meeting%20in%20vollem%20Umfang%20nur%20auf%20einem%20normalen%20PC,%20einem%20Laptop%20oder%20Tablet%20m%C3%B6glich%20ist,%20auf%20Smartphones%20nur%20eingeschränkt.%0A%0ABitte%20beachten%20Sie,%20dass%20ein%20aktueller%20Browser,%20idealerweise%20%22Chrome%22%20oder%20%22Edge%22,%20notwendig%20sind.'">Einladung E-Mail schreiben</a> -->
      </b-col>

      <b-col cols="1">
          <b-button v-if="KontaktDetail" class="mr-2 btn-nav" @click="loesche(index)" v-b-tooltip.hover title="Raum löschen" variant="light" ><span style="color:red;font-weight:800;">X</span></b-button>			
      </b-col>

  </b-row>

</div>
        
        
        </b-card-text>


    </b-card>

        <!-- <h2>Online - Meetings</h2> -->

     

<b-modal @close="onClose" no-close-on-esc no-close-on-backdrop transition="nice-modal-fade" size="xl" id="jitsi" ref="jitsi" hide-footer>

		<b-container slot="modal-header">
				<b-row>
					<b-col cols="10">
							<div class="ueberschrift">
								<span> Raum: {{raum}} </span>
                      <!-- <b-button v-b-tooltip.hover 
                            
                      title="kopiert den Link in die Zwischenablage, sodass er an anderer Stelle eingefügt werden kann." 
                      variant="outline-primary"

                      type="button" 
                      @click="doCopy(raum)">
                      <span class="d-none d-lg-block"> Link kopieren <b-icon icon="clipboard-check"></b-icon></span>
                      <span  class="d-lg-none"><b-icon icon="clipboard-check"></b-icon></span>

                    </b-button> -->


                    <!-- <b-button class="ml-2" 
                              variant="outline-success"
                      @click="lobbyOn()">Lobby-Modus einschalten
                      </b-button> -->

							</div>
					</b-col>

					<b-col>
						<span style="float:right; display:block">
								<b-button id="popover-reactive-1" @click="onClose" variant="info" class="mr-1">
									<span>
										schließen
									</span>
								</b-button>
						</span>
					</b-col>
				</b-row>
			</b-container>

    <div :style="{ height: window.height - 150 + 'px' }">
    <vue-jitsi-meet
      v-if="showJitsi"
      ref="jitsiRef"
      domain="jitsi.archi-app.de"
      :options="jitsiOptions"
    ></vue-jitsi-meet>
  </div>

</b-modal> 


<!-- <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="jitsiShow"
      placement="auto"
      container="my-container"
      ref="jitsiPopover"
    >
      <template #title>
        Wirklich schließen?
      </template>

            <p style="font-size:1.0em; font-weight:500;">Sie können NICHT zu diesem Meeting zurückkehren!</p>

            <b-button @click="jitsiShow=false" style="min-width:50px" size="sm">nein</b-button>
            <b-button @click="onClose" class="ml-2" style="min-width:50px" size="sm">ja</b-button>

 
    </b-popover> -->
  </div>
    </div>
</template>

<script>
import { JitsiMeet } from '@mycure/vue-jitsi-meet';
import { forAllMixin } from '../../mixins/forAllMixin.js'

export default {

  mixins: [forAllMixin],

  components: {
    VueJitsiMeet: JitsiMeet
  },


    data(){

        return{

			  raum: '',
        raeume:[],
        jitsiShow:false,
        showJitsi:false,

	  	}
    },

  methods: {

    loesche(index){
      this.raeume.splice(index, 1);
      this.updateDB();
    },

	  lobbyOn(){
			this.$refs.jitsiRef.executeCommand('toggleLobby', false);
			//console.log(this.$refs.jitsiRef)
	  },

    onClose(){

      this.$refs.jitsiRef.executeCommand('toggleLobby', false);

			setTimeout(()=>{
        this.$bvModal.hide('jitsi')
			},1000);

    },

    openRoom(raum){
      this.raum = raum
      this.$bvModal.show('jitsi')
      this.showJitsi = true;
    },

    addRoom(){
      var datum = new Date()
      this.raeume.push({raumName:'neuerRaum', teilnehmer:[], datum:datum})
      this.updateDB();
    },

    updateDB(){
        this.axios.post(this.$store.state.url_get_rooms, {
        besonders: this.$store.state.dev_sub,
        was:'set',
        raeume:this.raeume,
          }).then((response) => {
                  console.log(response.data)
          })
    },

    getDB(){
        this.axios.post(this.$store.state.url_get_rooms, {
        besonders: this.$store.state.dev_sub,
        was:'get',
        }).then((response) => {
                if(response.data.logout==true)
                this.$bvToast.toast('Toast body content', {
                  title: `Log out`,
                  variant: 'primary',
                  solid: true
                })
                else
                this.raeume = response.data
        })
        .catch(function(error) {  console.log(error) });

    },

    openLink(link){
       // window.open(this.$store.state.url_datei_download+"?dateiID=" + dateiID + "&besonders="+this.$store.state.dev_sub, "_blank");
      console.log(link + "&besonders="+this.$store.state.dev_sub);
      window.open(link + "&besonders="+this.$store.state.dev_sub, "_blank");    
    },

    onIFrameLoad () {
      this.$refs.jitsiRef.addEventListener('participantJoined', this.onParticipantJoined);
      this.$refs.jitsiRef.addEventListener('readyToClose', this.canClose);
      
      var self = this;

      this.$refs.jitsiRef.addEventListener('participantRoleChanged', function (event) {
         console.log(event);
        if(event.role === 'moderator') {

        setTimeout(()=>{
          self.$refs.jitsiRef.executeCommand('toggleLobby', true);
          //self.$refs.jitsiRef.executeCommand('password', '1234');
			  },1500);


          }
      });

      // this.$refs.jitsiRef.addEventListener('participantRoleChanged', this.checkLobby(event));

    },

    checkLobby (event) {
      console.log(event);
          if(event.role === 'moderator') {
          this.$refs.jitsiRef.executeCommand('toggleLobby', true);
          }
    },

    canClose (e) {
      alert("everbody left")
    },
      onParticipantJoined (e) {
      // do stuff
    },

  },


  mounted() {

// this.$refs.jitsiRef.addEventListener('participantRoleChanged', function (event) {

//   console.log(event)

//     if(event.role === 'moderator') {
//         this.$refs.jitsiRef.executeCommand('toggleLobby', true);
//     }
// });
this.getDB();
    if(Object.keys(this.Addressbook).length ===0)
      this.updateAdressbook();
  },

  computed: {
    url(){

      return "https://jitsi.archi-app.de/" + this.raum;

    },

    jitsiOptions () {
      return {
        logLevel: 'error',
        roomName: this.raum,
        noSSL: false,
        userInfo: {
          email: 'user@email.com',
          displayName: this.userSelbst.vollerName.anzeigeName,
        },
        configOverwrite: {
          enableNoisyMicDetection: false,
          startWithVideoMuted:true,
          startWithAudioMuted:true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false
        },
        onload: this.onIFrameLoad
      };
    },
  },


};
</script>

<style scoped>
.card-title{

font-weight: 800;

}

.bv-example-row .row > .col:not(.header), .bv-example-row .row > [class^="col-"] {
	padding-top: .75rem;
	padding-bottom: .75rem;
	border: 1px solid rgba(86,61,124,.2);
}

</style>