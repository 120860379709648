<template>
    <div>
     
    <b-row style="height:60px">
      <div class="card_new" @click="loadProject(source.projectID, 'projekte')" :class="{'highlightThis': highlightThis}"  >
        <label> 
          <span>{{source.projectContent.stammdaten.allgemein.bv_name}}</span>
        </label>
      </div>
    </b-row>
</div>

</template>

<script>
  import { projectMixin } from '../mixins/projectMixin.js'
  import { kontaktMixin } from '../mixins/kontaktMixins.js'
  import { VueOfflineMixin } from 'vue-offline'

export default {

   mixins: [kontaktMixin, VueOfflineMixin, projectMixin],

  name: 'ItemProject',

 props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      },
      otherPropValue: String // here is: 'The Progressive JavaScript Framework'
    },


  data () {
    return {

      borderColor: "0.5px solid #cccccc",
      onlineStatus:0,

    }
  },

  computed: {


    admin:
      {
        get()
        {
          return this.$store.getters.admin
        },
        set(value)
        {
          this.$store.commit('setadmin', value);
        }
      },
      
      anzeigeNamekurz(){
        var returnValue=""
      // console.log(this.source)
        if(this.source.projectContent.allgemein.bv_name)
        {
            if(this.source.projectContent.allgemein.bv_name.length>50)
            {
              var anfag = this.source.projectContent.allgemein.bv_name.indexOf(" ", 40)
              console.log(anfag)
              if(anfag==-1)
              anfag=40

              returnValue= this.source.projectContent.allgemein.bv_name.substring(0, anfag)+'...';
            }
            else
            returnValue= this.source.projectContent.allgemein.bv_name

        }

        return returnValue;

      },


      itemStyle () {
        return {
          height: `60px`,

        }
      }
  },

  mounted(){
    // console.log(this.source)
  },

  methods: {
        highlightThis(){

      if(this.kontaktHighlight==this.source.projectID)
        return true;
      else
        return false;

    },
  }
}
</script>

<style>



</style>
<style scoped lang="less">



  .mehrfachMarkierung:hover {
    background-color: #cccccc;
  }

  .mehrfachMarkierung{
      position: relative;
      border: 0.5px solid #bfffe1;
      height: 100%;
      width: 60px !important;

      padding:5px;
      display: inline-block;
      cursor: pointer;
  }

  .closeSelect {
    position: absolute;
    left: 12px;
    bottom: 11px;
    width: 60px;
    height: 60px;
    opacity: 0.3;

  }
  .closeSelect:hover {
    opacity: 1;
  }
  .closeSelect:before, .closeSelect:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 83px;
    width: 2px;
    background-color: #333;
  }
  .closeSelect:before {
    transform: rotate(45deg);
  }
  .closeSelect:after {
    transform: rotate(-45deg);
  }
  
  .marked{
      border: 2px solid #0804f1 !important;
        background-color: #c3d3ff;
  }

	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
  }
  
.highlightThis{
     border: 2px solid #3c39ff !important;
}


.item_new{
  width:100%;
}

.card_new:hover{
  background-color: #cccccc;
  cursor: pointer;
}


.card_details{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new span{
        vertical-align:bottom;
}

</style>
