<template>
	<div v-if="actualformular.formularContent.antrageinfach2018!=undefined">

		<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

			<!-- oben rechts -->
			<div style="left:688px;top:31px;" class="t size_12_fett">Anlage I/2 zu VV BauPrüfVO </div>
			<div style="left:810px;top:45px;" class="t size_12">Blatt 1 </div>
			

			<div class="seitenabstand" :style="{ height: seitenrand_oben + 'px' }"></div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(1) + 'px', 
						top: gib_top(1) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">An die untere Bauaufsichtsbehörde </div>

				<popover-font-size ref="bauaufsicht" url="bauaufsicht"/>
				<formular-textarea v-bind:daten="{
										hoehe: '65',
										einsatz: 'bauaufsicht'
									}" />

			</div>



			<div class="rahmen rechter_rand rechte_breite" 
				:style="{height: gib_hoehe(1) + 'px', 
						top: gib_top(1) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

			<div class="size_9">Eingangsstempel der Bauaufsichtsbehörde</div>


			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(2) + 'px', 
						top: gib_top(2) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

			<div class="size_9">PLZ / Ort</div>
				<popover-font-size ref="bauaufsicht_plz" url="bauaufsicht_plz"/>
				<formular-input v-bind:daten="{einsatz: 'bauaufsicht_plz' }"/>
			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(2) + 'px', 
						top: gib_top(2) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

			<div class="size_9">Aktenzeichen</div>


			</div>


		<!-- Anfang 3. Zeile -->
			<div class="rahmen" 
				:style="{height: gib_hoehe(3) + 'px', 
						top: gib_top(3) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>


					<b-form-checkbox 
						class="size_18_fett"
						style=""
						id="ist_antrag"
						v-model="actualformular.formularContent.antrageinfach2018.ist_antrag.text"
				      	value="1"
						unchecked-value=""
					>
						Bauantrag
					</b-form-checkbox>  

					<b-form-checkbox 
						class="size_18_fett"
						style=""
						id="ist_voranfrage"
						v-model="actualformular.formularContent.antrageinfach2018.ist_voranfrage.text"
						value="1"
						unchecked-value="0">
						Antrag auf Vorbescheid
					</b-form-checkbox>  

					<b-form-checkbox 
						class="size_18_fett"
						style=""
						id="ist_referenzgeb"
						v-model="actualformular.formularContent.antrageinfach2018.ist_referenzgeb.text"
				      	value="1"
						unchecked-value="">
						Referenzgebäude
					</b-form-checkbox>
			</div>

			<div class="rahmen bg-light text-center align-middle size_18_fett" 
				:style="{height: gib_hoehe(3) + 'px', 
						top: gib_top(3) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}">

				Einfaches Baugenehmigungsverfahren<br/>
				§ 64 BauO NRW 2018
			</div>

		<!-- Anfang 4. Zeile -->
			<div class="rahmen" 
				:style="{height: gib_hoehe(4) + 'px', 
						top: gib_top(4) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_12_fett">
					Bauherrschaft (§ 53 BauO NRW 2018)
				</div>

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(4) + 'px', 
						top: gib_top(4) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_12_fett">
					Entwurfsverfasser (§ 54 BauO NRW 2018)
				</div>

			</div>


		<!-- Anfang 5. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(5) + 'px', 
						top: gib_top(5) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">Name, Vorname, Firma</div>

				<popover-font-size ref="bauherr_name" url="bauherr_name"/>

								<formular-textarea v-bind:daten="{
										hoehe: '45',
										einsatz: 'bauherr_name'
									}" />

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(5) + 'px', 
						top: gib_top(5) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_9">Name, Vorname, Firma</div>

				<popover-font-size ref="antragsteller_name" url="antragsteller_name"/>

								<formular-textarea v-bind:daten="{
										hoehe: '45',
										einsatz: 'antragsteller_name'
									}" />

			</div>

		<!-- Anfang 6. Zeile -->


		<div class="rahmen" 
				:style="{height: gib_hoehe(6) + 'px', 
						top: gib_top(6) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">Straße, Hausnummer</div>

				<popover-font-size ref="bauherr_strasse" url="bauherr_strasse"/>
				<formular-input v-bind:daten="{einsatz: 'bauherr_strasse' }"/>


			</div>



			<div class="rahmen" 
				:style="{height: gib_hoehe(6) + 'px', 
						top: gib_top(6) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_9">Straße, Hausnummer</div>

				<popover-font-size ref="antragsteller_strasse" url="antragsteller_strasse"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_strasse' }"/>

			</div>


		<!-- Anfang 7. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(7) + 'px', 
						top: gib_top(7) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">PLZ, Ort</div>

				<popover-font-size ref="bauherr_ort" url="bauherr_ort"/>
				<formular-input v-bind:daten="{einsatz: 'bauherr_ort' }"/>


			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(7) + 'px', 
						top: gib_top(7) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_9">PLZ, Ort</div>

				<popover-font-size ref="antragsteller_ort" url="antragsteller_ort"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_ort' }"/>

			</div>

		<!-- Anfang 8. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(8) + 'px', 
						top: gib_top(8) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<!--  -->
					<div>
					<span class="size_12">vertreten durch:</span>
					<span class="size_9">(§ 53 Absatz 3 BauO NRW 2018)</span>
					<div class="size_9">Name, Vorname, Anschrift</div> 
				</div>
		
				<popover-font-size ref="bauherr_vertreten_durch" url="bauherr_vertreten_durch"/>

				<formular-textarea v-bind:daten="{
						hoehe: '65',
						einsatz: 'bauherr_vertreten_durch'
					}" />

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(8) + 'px', 
						top: gib_top(8) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>
		<!-- -->
				<div>
					<span class="size_12">bauvorlageberechtigt</span>
					<span class="size_9"> (§ 67 Absatz 3 BauO NRW 2018) Name, Vorname</span>
				</div>

				<popover-font-size ref="antragsteller_vorlagenberechtigt" url="antragsteller_vorlagenberechtigt"/>
				
								<formular-input v-bind:daten="{einsatz: 'antragsteller_vorlagenberechtigt' }"/>
								<div class="mb-3"></div>
								<!-- <formular-textarea v-bind:daten="{
										hoehe: '35',
										einsatz: 'antragsteller_vorlagenberechtigt'
									}" /> -->

				<div class="size_9">Mitgliedsnummer der Architekten- oder der Ingenieurkammer des Landes</div>
				
				<popover-font-size ref="antragsteller_mitgliedsnummer" url="antragsteller_mitgliedsnummer"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_mitgliedsnummer' }"/>

			</div>

		<!-- Anfang 9. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(9) + 'px', 
						top: gib_top(9) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit / 2 + 'px'
					}"
					>

				<div class="size_9">Telefon (mit Vorwahl)</div>

				<popover-font-size ref="bauherr_tel" url="bauherr_tel"/>
				<formular-input v-bind:daten="{einsatz: 'bauherr_tel' }"/>



			</div>


		<div class="rahmen" 
				:style="{height: gib_hoehe(9) + 'px', 
						top: gib_top(9) + 'px',
						left: seitenrand_links + (linke_spalte_breit / 2) + 'px',
						width: linke_spalte_breit / 2 + 'px'
					}"
					>

				<div class="size_9">Telefax</div>

				<popover-font-size ref="bauherr_fax" url="bauherr_fax"/>
				<formular-input v-bind:daten="{einsatz: 'bauherr_fax' }"/>



			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(9) + 'px', 
						top: gib_top(9) + 'px',
						left: mittellinie + 'px',
						width: (rechte_spalte_breit / 2) + 'px'
					}"
			>

				<div class="size_9">Telefon (mit Vorwahl)</div>

				<popover-font-size ref="antragsteller_tel" url="antragsteller_tel"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_tel' }"/>


			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(9) + 'px', 
						top: gib_top(9) + 'px',
						left: mittellinie + (rechte_spalte_breit / 2) + 'px',
						width: (rechte_spalte_breit / 2) + 'px'
					}"
			>


				<div class="size_9">Telefax</div>

				<popover-font-size ref="antragsteller_fax" url="antragsteller_fax"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_fax' }"/>

			</div>

			<!-- Anfang 10. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(10) + 'px', 
						top: gib_top(10) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">E-Mail</div>

				<popover-font-size ref="bauherr_email" url="bauherr_email"/>
				<formular-input v-bind:daten="{einsatz: 'bauherr_email' }"/>

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(10) + 'px', 
						top: gib_top(10) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<div class="size_9">E-Mail</div>


				<popover-font-size ref="antragsteller_email" url="antragsteller_email"/>
				<formular-input v-bind:daten="{einsatz: 'antragsteller_email' }"/>


			</div>

			<!-- Anfang 11. Zeile -->

		<div class="rahmen" 
		style="vertical-align: middle;"
				:style="{height: gib_hoehe(11) + 'px', 
						top: gib_top(11) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div class="size_12_fett">Baugrundstück</div>


			</div>


			<!-- Anfang 12. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(12) + 'px', 
						top: gib_top(12) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'

					}"
					>

				<div class="size_9">Ort, Straße, Hausnummer, gegebenenfalls Ortsteil</div>

				<popover-font-size ref="baugrund_strasse" placement="topright" url="baugrund_strasse"/>
				<formular-input v-bind:daten="{einsatz: 'baugrund_strasse' }"/>

			</div>


			<!-- Anfang 13. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(13) + 'px', 
						top: gib_top(13) + 'px',
						left: seitenrand_links + 'px',
						width: gib_breite_mm(79.39) + 'px'
					}"
					>

				<div class="size_9">Gemarkung(en)</div>

				<popover-font-size ref="gemarkungen" url="gemarkungen"/>
				<formular-input v-bind:daten="{einsatz: 'gemarkungen' }"/>


			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(13) + 'px', 
						top: gib_top(13) + 'px',
						left: seitenrand_links + gib_breite_mm(79.39) + 'px',
						width: gib_breite_mm(30.9)  + 'px'
					}"
			>

				<div class="size_9">Flur(e)</div>
			
				<popover-font-size ref="flure" url="flure"/>
				<formular-input v-bind:daten="{einsatz: 'flure' }"/>

			</div>

		<div class="rahmen" 
				:style="{height: gib_hoehe(13) + 'px', 
						top: gib_top(13) + 'px',
						left: seitenrand_links + gib_breite_mm(79.39 + 30.9)  + 'px',
						width: gib_breite_mm(72.96)  - 1 + 'px'
					}"
			>

			<div class="size_9">Flurstück(e)</div>
				<popover-font-size ref="flurstuecke" url="flurstuecke"/>
				<formular-input v-bind:daten="{einsatz: 'flurstuecke' }"/>
			</div>
			<!-- Anfang 14. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(14) + 'px', 
						top: gib_top(14) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div>
					<span class="size_12_fett" style="float:left; margin-right:10px;">Gebäudeklassen (§ 2 Absatz 3 BauO NRW 2018):</span>
					<span style="float:left">
						
						<!-- gebaeudeklasse_1
        <b-form-checkbox value="orange">Orange</b-form-checkbox>
        <b-form-checkbox value="apple">Apple</b-form-checkbox>
        <b-form-checkbox value="pineapple">Pineapple</b-form-checkbox>
        <b-form-checkbox value="grape">Grape</b-form-checkbox> -->

						<b-form-radio-group v-model="actualformular.formularContent.antrageinfach2018.gebaeudeklasse">
							<b-form-radio value="1">1</b-form-radio>
							<b-form-radio value="2">2</b-form-radio>
							<b-form-radio value="3">3</b-form-radio>
							<b-form-radio value="4">4</b-form-radio>
							<b-form-radio value="5">5</b-form-radio>
						</b-form-radio-group>
					</span>
				</div>

			</div>



			<!-- Anfang 15. Zeile -->

		<div class="rahmen size_12" 
				:style="{height: gib_hoehe(15) + 'px', 
						top: gib_top(15) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

					<b-form-radio v-model="actualformular.formularContent.antrageinfach2018.wohngebaeudeOsonderbau" name="some-radios" value="wohngebaeude">Wohngebäude</b-form-radio>

			</div>

			<div class="rahmen size_12" 
				:style="{height: gib_hoehe(15) + 'px', 
						top: gib_top(15) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

				<b-form-radio style="float:left; margin-right:10px;" v-model="actualformular.formularContent.antrageinfach2018.wohngebaeudeOsonderbau" name="some-radios" value="sonderbau">Sonderbau</b-form-radio>
				<span style="float:left" class="size_9">(nicht § 50 Absatz 2 BauO NRW 2018)</span>

			</div>

			<!-- Anfang 16. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(16) + 'px', 
						top: gib_top(16) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div>
					<span class="size_12_fett">Bezeichnung des Vorhabens </span
					><span class="size_12">(Errichtung, Änderung, Nutzungsänderung gemäß § 60 BauO NRW 2018)</span>
				</div>

			</div>


			
			<!-- Anfang 17. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(17) + 'px', 
						top: gib_top(17) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<popover-font-size ref="bezeichnung_bauvorhaben" placement="topright" url="bezeichnung_bauvorhaben"/>
				
								<formular-textarea v-bind:daten="{
										hoehe: '',
										einsatz: 'bezeichnung_bauvorhaben'
									}" />


			</div>



			
			<!-- Anfang 18. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(18) + 'px', 
						top: gib_top(18) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div style="float:left; margin-right:10px;" class="size_12">Das Bauvorhaben bedarf einer
					</div>

		<div style="float:left">
					<b-form-checkbox 
					class="size_12 "
					style=""
						id="bedarf_ausnahme"
									v-model="actualformular.formularContent.antrageinfach2018.bedarf_ausnahme.text"
									
									value="1"
									unchecked-value="">
					Ausnahme  (§ 31 Absatz 1 BauGB)
					</b-form-checkbox>  
		

					<b-form-checkbox 
					class="size_12"
					style=""
						id="bedarf_befreiung"
									v-model="actualformular.formularContent.antrageinfach2018.bedarf_befreiung.text"
									
									value="1"
									unchecked-value="">
				Befreiung (§ 31 Absatz 2 BauGB)
					</b-form-checkbox> 

					<b-form-checkbox 
					class="size_12 "
					style=""
						id="bedarf_abweichung"
									v-model="actualformular.formularContent.antrageinfach2018.bedarf_abweichung.text"
									
									value="1"
									unchecked-value="">
					Abweichung (§ 69 BauO NRW 2018)
					</b-form-checkbox> 
		</div>
		<div style="clear: both"></div>

		<div class="size_9"><b>Hinweis:</b> Die Begründung ist separat als Anlage beizufügen.</div>
			</div>

			<!-- Anfang 19. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(19) + 'px', 
						top: gib_top(19) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div class="size_12_fett">Bei Vorbescheid (§ 77 BauO NRW 2018) </div>
				<div >

					<b-form-checkbox 
					style="display: inline; margin-right:10px;"
					class="size_12"
						id="planungsrechtlicheZulaessigkeit"
									v-model="actualformular.formularContent.antrageinfach2018.planungsrechtlicheZulaessigkeit.text"
									value="1"
									unchecked-value="">
							planungsrechtliche Zulässigkeit
					</b-form-checkbox> 

					<b-form-checkbox 
					style="display: inline"
					class="size_12"
						id="bauordnungsrechtlicheZulaessigkeit"
									v-model="actualformular.formularContent.antrageinfach2018.bauordnungsrechtlicheZulaessigkeit.text"
									value="1"
									unchecked-value="">
							bauordnungsrechtliche Zulässigkeit
					</b-form-checkbox>

				</div>

			</div>


		<div class="rahmen" 
				:style="{height: gib_hoehe(20) + 'px', 
						top: gib_top(20) + 'px',
						left: seitenrand_links + 'px',
						width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
					}"
					>

				<div class="size_12_fett">Fragestellung</div>
				<popover-font-size ref="genaue_fragestellung" placement="topright" url="genaue_fragestellung"/>

								<formular-textarea v-bind:daten="{
										hoehe: '43',
										einsatz: 'genaue_fragestellung'
									}" />
			</div>

			
			<!-- Anfang 21. Zeile -->

		<div class="rahmen" 
				:style="{height: gib_hoehe(21) + 'px', 
						top: gib_top(21) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

				<div class="size_12_fett">Bindungen zur Beurteilung des Vorhabens </div>

					<b-form-checkbox 
					style=""
					class="size_12"
						id="bindung_vorbescheid"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_vorbescheid.text"
									value="1"
									unchecked-value="">
							Vorbescheid
					</b-form-checkbox>

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(21) + 'px', 
						top: gib_top(21) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
			>

				<div style="border-bottom:1px solid black" class="size_12">Bescheid vom</div>
				<popover-font-size ref="bindung_vorbescheid_bescheid_vom" url="bindung_vorbescheid_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_vorbescheid_bescheid_vom' }"/>

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(21) + 'px', 
						top: gib_top(21) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<div style="border-bottom:1px solid black" class="size_12">erteilt von (Behörde) </div>
				<popover-font-size ref="bindung_vorbescheid_erteilt_von" url="bindung_vorbescheid_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_vorbescheid_erteilt_von' }"/>

			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(21) + 'px', 
						top: gib_top(21) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<div style="border-bottom:1px solid black" class="size_12">Aktenzeichen</div>
				<popover-font-size ref="bindung_vorbescheid_aktenzeichen" url="bindung_vorbescheid_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_vorbescheid_aktenzeichen' }"/>

			</div>
			
				<!-- Anfang 22. Zeile -->
		<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(22) + 'px', 
						top: gib_top(22) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

					<b-form-checkbox 
					style=""
					class="size_12"
						id="bindung_teilungsgen"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_teilungsgen.text"
									value="1"
									unchecked-value="">
							Teilungsgenehmigung
					</b-form-checkbox>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{
						height: gib_hoehe(22) + 'px', 
						top: gib_top(22) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
					style=""
			>


				<popover-font-size ref="bindung_teilungsgen_bescheid_vom" url="bindung_teilungsgen_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_teilungsgen_bescheid_vom' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(22) + 'px', 
						top: gib_top(22) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_teilungsgen_erteilt_von" url="bindung_teilungsgen_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_teilungsgen_erteilt_von' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(22) + 'px', 
						top: gib_top(22) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<popover-font-size ref="bindung_teilungsgen_aktenzeichen" url="bindung_teilungsgen_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_teilungsgen_aktenzeichen' }"/>

			</div>
			
			<!-- Anfang 23. Zeile -->
			
			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(23) + 'px', 
						top: gib_top(23) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

					<b-form-checkbox 
					style=""
					class="size_12"
						id="bindung_befreiung"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_befreiung.text"
									value="1"
									unchecked-value="">
							Befreiungs-/Abweichungsbescheid
					</b-form-checkbox>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(23) + 'px', 
						top: gib_top(23) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
			>
				<popover-font-size ref="bindung_befreiung_bescheid_vom" url="bindung_befreiung_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_befreiung_bescheid_vom' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(23) + 'px', 
						top: gib_top(23) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_befreiung_erteilt_von" url="bindung_befreiung_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_befreiung_erteilt_von' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(23) + 'px', 
						top: gib_top(23) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<popover-font-size ref="bindung_befreiung_aktenzeichen" url="bindung_befreiung_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_befreiung_aktenzeichen' }"/>

			</div>



			<!-- Anfang 24. Zeile -->
			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(24) + 'px', 
						top: gib_top(24) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

					<b-form-checkbox 
					style="display: inline; margin-right:10px;"
					class="size_12"
						id="bindung_baulast"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_baulast.text"
									value="1"
									unchecked-value="">
						Baulast Nr.
					</b-form-checkbox>

				<popover-font-size ref="bindung_baulast_nummer" url="bindung_baulast_nummer"/>
				<span style="display: inline; margin-right:10px;"><formular-input  v-bind:daten="{einsatz: 'bindung_baulast_nummer' }"/></span>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(24) + 'px', 
						top: gib_top(24) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_baulast_bescheid_vom" url="bindung_baulast_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_baulast_bescheid_vom' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(24) + 'px', 
						top: gib_top(24) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_baulast_erteilt_von" url="bindung_baulast_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_baulast_erteilt_von' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(24) + 'px', 
						top: gib_top(24) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<popover-font-size ref="bindung_baulast_aktenzeichen" url="bindung_baulast_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_baulast_aktenzeichen' }"/>

			</div>
			

				<!-- Anfang 25. Zeile -->
		<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(25) + 'px', 
						top: gib_top(25) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

					<b-form-checkbox 
					style=""
					class="size_12"
						id="bindung_denkmal"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_denkmal.text"
									value="1"
									unchecked-value="">
							Denkmalrechtliche Erlaubnis
					</b-form-checkbox>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(25) + 'px', 
						top: gib_top(25) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_denkmal_bescheid_vom" url="bindung_denkmal_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_denkmal_bescheid_vom' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(25) + 'px', 
						top: gib_top(25) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_denkmal_erteilt_von" url="bindung_denkmal_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_denkmal_erteilt_von' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(25) + 'px', 
						top: gib_top(25) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<popover-font-size ref="bindung_denkmal_aktenzeichen" url="bindung_denkmal_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_denkmal_aktenzeichen' }"/>

			</div>
			

				<!-- Anfang 26. Zeile -->
		<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(26) + 'px', 
						top: gib_top(26) + 'px',
						left: seitenrand_links + 'px',
						width: spalte_bindungen_breit_1_com + 'px'
					}"
					>

					<b-form-checkbox 
					style=""
					class="size_12"
						id="bindung_sonstiges"
									v-model="actualformular.formularContent.antrageinfach2018.bindung_sonstiges.text"
									value="1"
									unchecked-value="">
			
					</b-form-checkbox>
									<popover-font-size ref="bindung_sonstiges_text" url="bindung_sonstiges_text"/>
									<formular-input v-bind:daten="{einsatz: 'bindung_sonstiges_text' }"/>
			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(26) + 'px', 
						top: gib_top(26) + 'px',
						left: seitenrand_links+spalte_bindungen_breit_1_com - 1 + 'px',
						width: spalte_bindungen_breit_2_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_sonstiges_bescheid_vom" url="bindung_sonstiges_bescheid_vom"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_sonstiges_bescheid_vom' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(26) + 'px', 
						top: gib_top(26) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com - 2  +'px',
						width: spalte_bindungen_breit_3_com + 'px'
					}"
			>

				<popover-font-size ref="bindung_sonstiges_erteilt_von" url="bindung_sonstiges_erteilt_von"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_sonstiges_erteilt_von' }"/>

			</div>

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(26) + 'px', 
						top: gib_top(26) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<popover-font-size ref="bindung_sonstiges_aktenzeichen" url="bindung_sonstiges_aktenzeichen"/>
				<formular-input v-bind:daten="{einsatz: 'bindung_sonstiges_aktenzeichen' }"/>

			</div>

				<!-- Anfang 27. Zeile -->

			<div class="rahmen rahmen_zentriert" 
				:style="{height: gib_hoehe(27) + 'px', 
						top: gib_top(27) + 'px',
						left: seitenrand_links + spalte_bindungen_breit_1_com + spalte_bindungen_breit_2_com +spalte_bindungen_breit_3_com - 3 + 'px',
						width: spalte_bindungen_breit_4_com + 1 + 'px'
					}"
			>

				<div class="size_12_fett">Fortsetzung Blatt 2</div>

			</div>

		</div>
		<!-- End output for page 1-->



		<!-- Begin output for page 2-->
		<div style="height: 20px;"> </div>

		<div id="page2" style="border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

				<!-- oben rechts -->
				<div style="left:688px;top:31px;" class="t size_12_fett">Anlage I/2 zu VV BauPrüfVO </div>
				<div style="left:810px;top:45px;" class="t size_12">Blatt 2 </div>
				

				<div class="seitenabstand" :style="{ height: seitenrand_oben + 'px' }"></div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(1) + 'px', 
							top: gib_top(1) + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
						}"
						>
						<b-container>
							<b-row class="size_14_fett">Die erforderlichen Bauvorlagen sind beigefügt:</b-row>
							<b-row class="size_13">(einem Antrag auf Vorbescheid sind nur die für die Klärung der Fragestellung erforderlichen Unterlagen beizufügen) </b-row>
							<div style="height: 8px;"> </div>

							<b-row>
								<span class="nummern" >1.</span>

								<span class="nummern_checkbox">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.lageplan.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Lageplan/amtlicher Lageplan </span>
									<span class="size_11">(§ 3 BauPrüfVO; Anforderungen an Planersteller/in sind zu beachten) </span>
								</span>

							</b-row>

							<b-row>
								<span class="nummern" >2.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.berechnungMassBaulichenNutzung.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Berechnung des Maßes der baulichen Nutzung </span><span class="size_11">(§ 3 Absatz 2 BauPrüfVO) </span><br>
								</span>

							</b-row>
					<!-- // -->
							<b-row>
								
									<span  class="nummer_zweiteReihe">(nur im Bereich eines Bebauungsplanes oder einer Satzung nach BauGB)</span>
							
							</b-row>
					<!-- // -->
							<b-row>
								<span class="nummern" >3.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.beglaubigterAuszugFlurkarte.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Beglaubigter Auszug aus der Flurkarte  </span>
							</span>

							</b-row>
					<!-- // -->
							<b-row>
								
									<span  class="nummer_zweiteReihe">(nur bei Vorhaben nach den §§ 34 oder 35 des Baugesetzbuches; Auszug nicht erforderlich bei Vorlage eines amtlichen Lageplanes) </span>
							
							</b-row>
					<!-- // -->
							<b-row>
								<span class="nummern" >4.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.Basiskarte.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Auszug aus der amtlichen Basiskarte 1 : 5 000 </span>
								</span>

							</b-row>
					<!-- // -->
							<b-row>
									<span  class="nummer_zweiteReihe">(nur bei Vorhaben nach den §§ 34 oder 35 des Baugesetzbuches)</span>
							</b-row>
					<!-- // -->
							<b-row>
								<span class="nummern" >5.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.Bauzeichnungen.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Bauzeichnungen </span><span class="size_11">(§ 4 BauPrüfVO)</span>
								</span>

							</b-row>

					<!-- // -->

							<b-row>
								<span class="nummern" >6.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.Baubeschreibung.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Baubeschreibung auf amtlichem Vordruck </span><span class="size_11">(§ 5 Absatz 1 BauPrüfVO)</span>
								</span>

							</b-row>

					<!-- // -->

							<b-row>
								<span class="nummern" >7.1</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.umbauterRaum.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >bei Gebäuden: Berechnung des umbauten Raumes nach DIN 277  </span><span class="size_11">(§ 6 Nummer 1 BauPrüfVO) oder </span>
								</span>

							</b-row>
					<!-- // -->
							<b-row>
								<span class="nummern" >7.2</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.ohneRohbauwerteHerstellungssumme.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Bei Gebäuden, für die landesdurchschnittliche Rohbauwerte je m³ Bruttorauminhalt nicht festgelegt sind, die 
									Berechnung der Rohbaukosten einschließlich Umsatzsteuer </span><span class="size_11"> (§ 6 Nummer 1 BauPrüfVO)  </span>
								</span>

							</b-row>
					<!-- // -->
							<b-row>
									<span  class="size_11 nummer_zweiteReihe ">oder</span>
							</b-row>

							<b-row>
								<span class="nummern" >7.3</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.herstellungssummeCheck.text"
														value="1"
														unchecked-value="">
												<span style="color:white">'</span>
										</b-form-checkbox>
								</span>

								<span class="nummern_text_schmaler">
									<span >bei der Änderung von Gebäuden oder bei baulichen Anlagen, die nicht Gebäude sind:  
									Herstellungssumme einschließlich Umsatzsteuer gemäß Tarifstelle 2.1.3 AVerwGebO NRW  </span>
								</span>

								<span class="nummern_text_rest">
									<b-input 
									@focus="setFocus($event)"
									@blur = "looseFocus($event)" 
									:style="{'font-size': actualformular.formularContent.antrageinfach2018.herstellungssumme.size + 'px'}"
									v-model="actualformular.formularContent.antrageinfach2018.herstellungssumme.text" size="sm">

									</b-input>
								</span>

							</b-row>
					<!-- // -->
						
						</b-container>

				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(2) + 'px', 
							top: gib_top_2(2) + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
						}"
				>

			<b-container>
							<b-row class="size_14_fett">zusätzliche Bauvorlagen für Sonderbauten, die nicht in § 50 Absatz 2 BauO NRW 2018 aufgeführt sind </b-row>
							<div style="height: 8px;"> </div>

							<b-row>
								<span class="nummern" >8.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.BetriebsbeschreibungSonderbauten.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Betriebsbeschreibung für gewerbliche oder landwirtschaftliche Betriebe auf amtlichem Vordruck  </span>
									<span class="size_11"> (§ 5 Absatz 2 oder 3 BauPrüfVO)  </span>
								</span>

							</b-row>

							<b-row>
								<span class="nummern" >9.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.zusaetzlicheAngabenSonderbauten.text"
														value="1"
														unchecked-value="">
												3-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >zusätzliche Angaben und Bauvorlagen für besondere Vorhaben </span> <span class="size_11"> (§ 12 BauPrüfVO)</span><br>
								</span>
							</b-row>


					</b-container>

				</div>	

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(3) + 'px', 
							top: gib_top_2(3) + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
						}"
				>

			<b-container>
							<b-row class="size_13">Spätestens mit Anzeige des Baubeginns werden gemäß § 68 Absatz 1 und 2 BauO NRW 2018 eingereicht:  </b-row>
							<div style="height: 8px;"> </div>

							<b-row>
								<span class="nummern" >10.1.</span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisSchallschutzesSachverstaendiger.text"
														value="1"
														unchecked-value="">
												2-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Bescheinigung zusammen mit dem Nachweis des Schallschutzes, soweit erforderlich aufgestellt oder geprüft durch eine/einen staatlich anerkannte/n Sachverständige/n    </span>

								</span>

							</b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisWaermeschutzesSachverstaendiger.text"
														value="1"
														unchecked-value="">
												2-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span > Bescheinigung zusammen mit dem Nachweis des Wärmeschutzes, soweit erforderlich aufgestellt oder geprüft durch eine/einen staatlich anerkannte/n Sachverständige/n </span>
								</span>
							</b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisStandsicherheitSachverstaendiger.text"
														value="1"
														unchecked-value="">
												2-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span >Bescheinigung zusammen mit dem Nachweis der Standsicherheit, soweit erforderlich geprüft durch eine/einen staatlich anerkannte/n Sachverständige/n </span>
								</span>
							</b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" >
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisBrandschutzSachverstaendiger.text"
														value="1"
														unchecked-value="">
												2-fach
										</b-form-checkbox>
								</span>

								<span class="nummern_text">
									<span>die Bescheinigung einer/eines staatlich anerkannte/n Sachverständige/n, dass das Vorhaben den Anforderungen an den Brandschutz entspricht (gilt nicht für Wohngebäude der Gebäudeklasse 1 bis 3 und Sonderbauten)</span>
								</span>
							</b-row>

							<div style="height: 12px;"> </div>
							
							<b-row class="size_13">10.2 Abweichend von Nr. 10.1 wird – soweit erforderlich – eine Prüfung durch die Bauaufsichtsbehörde beantragt für:   </b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" style="width:500px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisSchallschutzes.text"
														value="1"
														unchecked-value="">
														den Nachweis des Schallschutzes
										</b-form-checkbox>
								</span>

							</b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" style="width:500px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisWaermeschutzes.text"
														value="1"
														unchecked-value="">
														den Nachweis des Wärmeschutzes
										</b-form-checkbox>
								</span>

							</b-row>

							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" style="width:500px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisStandsicherheit.text"
														value="1"
														unchecked-value="">
														den Nachweis der Standsicherheit 
										</b-form-checkbox>
								</span>

							</b-row>





							<b-row>
								<span class="nummern" ></span>

								<span class="nummern_checkbox" style="width:700px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.NachweisBrandschutz.text"
														value="1"
														unchecked-value="">
														den Nachweis des Brandschutzes (gilt nicht für Wohngebäude der Gebäudeklasse 1 bis 3 und Sonderbauten) 
										</b-form-checkbox>
								</span>

							</b-row>


					</b-container>
					
				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(4) + 'px', 
							top: gib_top_2(4) + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
						}"
				>
					<b-container>
							<b-row>

								<span class="nummern" >11.</span>

								<span class="nummern_checkbox" style="width:700px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.erhebungsbogen_statistik.text"
														value="1"
														unchecked-value="">
														Erhebungsbogen für die Baustatistik gemäß Hochbaustatistikgesetz
										</b-form-checkbox>
								</span>

							</b-row>
					</b-container>

				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(5) + 'px', 
							top: gib_top_2(5) + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px'
						}"
				>

					<b-container>
							<b-row>

								<span class="nummern" >12.</span>

								<span class="nummern_checkbox" style="width:700px;">
									<b-form-checkbox 
									class="align-center"
														v-model="actualformular.formularContent.antrageinfach2018.Artenschutz.text"
														value="1"
														unchecked-value="">
														Angaben zum Artenschutz gemäß § 44 BNatSchG
										</b-form-checkbox>
								</span>

							</b-row>
					</b-container>

				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(6) + 'px', 
							top: gib_top_2(6) + 'px',
							left: seitenrand_links + 'px',
							width: linke_spalte_breit - 1 + 'px'
						}"
				>

					<b-container>
						<b-row>
							<div class="size_9">Ort, Datum </div>
						</b-row>
						
						<b-row class="mt-1">
							<popover-font-size ref="ort_datum_bauherr" url="ort_datum_bauherr"/>
							<formular-input v-bind:daten="{einsatz: 'ort_datum_bauherr' }"/>
						</b-row>

					</b-container>

				</div>

							<div class="rahmen" 
							:style="{height: gib_hoehe_2(6) + 'px', 
							top: gib_top_2(6) + 'px',
							left: seitenrand_links + linke_spalte_breit - 2 + 'px',
							width: rechte_spalte_breit  + 1 + 'px'
						}"
				>

					<b-container>
						<b-row>
								<div class="size_9">Ort, Datum </div>
						</b-row>
						
						<b-row class="mt-1">
							<popover-font-size ref="ort_datum_entwurfverfasser" url="ort_datum_entwurfverfasser"/>
							<formular-input v-bind:daten="{einsatz: 'ort_datum_entwurfverfasser' }"/>
						</b-row>

					</b-container>

				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(7) + 'px', 
							top: gib_top_2(7) + 1 + 'px',
							left: seitenrand_links + 'px',
							width: linke_spalte_breit - 1 + 'px'
						}"
				>

					<b-container>
							<b-row>
								<div class="size_13">Für die Bauherrschaft:</div>
							</b-row>
					</b-container>

				</div>

				<div class="rahmen" 
					:style="{height: gib_hoehe_2(7) + 'px', 
							top: gib_top_2(7) + 1 + 'px',
							left: seitenrand_links + linke_spalte_breit - 2 + 'px',
							width: rechte_spalte_breit  + 1 + 'px'
						}"
				>

					<b-container>
							<b-row>
								<div class="size_13">Die/Der bauvorlageberechtigte (*) Entwurfsverfassende: </div>
							</b-row>
					</b-container>

				</div>
				
				<div class="size_13"
							:style="{height: gib_hoehe_2(8) + 'px', 
							top: gib_top_2(8) + 1 + 'px',
							left: seitenrand_links + 'px',
							width: rechte_spalte_breit + linke_spalte_breit - 1 + 'px',
							padding:'2px',
							background:'none',
							position: 'absolute',


						}"
						>
				
				(*) Nach § 67 Absatz. 2 BauO NRW 2018 kann in bestimmten Fällen auf die Bauvorlageberechtigung verzichtet werden.
				</div>

		</div>
		<!-- End output for page 2-->

	</div>
</template>

<script>

import { formularMixin } from '../../../mixins/formularMixin.js'


export default {

  mixins: [formularMixin],

    name: "antrageinfach2018",

    data(){
        return{

			spalte_bindungen_breit_1: 63.84,
			spalte_bindungen_breit_2: 24.45,
			spalte_bindungen_breit_3: 45.05,
			spalte_bindungen_breit_4: 50.05,

			// Höhen in Millimetern

			linkes_feld_breite_mm : 85.7,
			rechtes_feld_breite_mm : 97.6,
			hoehen_mm:[
			23.36,
			8.04,
			22.18,
			4.53,
			12.91,
			8.00,
			7.96,
			24.64,
			7.96,
			8.09,
			4.53,
			8.01,
			8.09,
			5.25,
			5.25,
			5.25,
			9.69,
			22.41,
			10.7,
			14.54,
			12.55,
			4.45,
			4.45,
			4.85,
			4.85,
			4.95
			],
			hoehen_mm_page2:[
			80.95,
			19.85,
			66.76,
			8.115,
			8.115,
			8.893,
			30.024,
			20

			],
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	
    computed: {


    },
	methods:{



	},

	created(){

	}
	
	//
}
</script>


<style >
.align-center{

	display: flex;
	align-items: center;

}

.nummern{
	display: flex;
	align-items: center;
	width: 35px;
	padding-left: 10px;
	margin-right:10px;
	font-size:0.82em;
}

.nummern_checkbox{	
	display: flex;
	align-items: center;
	width: 80px;
	font-size:0.82em;

}

.nummern_text{
	display: flex;
	align-items: center;
	width: 660px;
	font-size:0.82em;
	line-height: 1.0;
}

.nummern_text_schmaler{
	width: 570px;
	font-size:0.82em;
	line-height: 1.0;
}

.nummern_text_rest{
	width: 90px;
	font-size:0.82em;
	line-height: 1.0;
}


.nummer_zweiteReihe{

	font-size:0.7em;
	margin-left:125px;	
	line-height: 1.0;
	margin-bottom: 5px;

}



.seitenabstand{
    position: absolute;
}

.titel{
	display: flex;
	align-items: center;
	justify-content: center;
}

.links{
    left:75px; width:380px; 
}

.rechts{
    left:454px; width:420px;
}

.rechter_rand{
	left:454px; 
}

.rechte_breite{
	width:420px;
}

.linker_rand{
    left:75px; 
}

.linke_breite{
   width:380px; 
}




.t {
	position: absolute;
	-webkit-transform-origin: top left;
	-moz-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	z-index: 2;
	white-space:nowrap;
	overflow:visible;
	FONT-FAMILY: Arial, Helvetica, sans-serif;
	color: rgb(0,0,0);
	
}

.size_9{		
	font-size:9px;
	line-height:1.0;
}		

.size_9_fett{		
	font-size:9px;
	FONT-WEIGHT	: bold;
}		

.size_10{		
	font-size:10px;
}		

.size_10_fett{		
	font-size:10px;
	FONT-WEIGHT	: bold;
}	

.size_11{		
	font-size:11px;

}

.size_11_fett{		
	font-size:11px;
	FONT-WEIGHT	: bold;
}

.size_12_narrow{		
	font-size:12px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}	

.size_12{		
	font-size:12px;
}

.size_12_fett{		
	font-size:12px;
	FONT-WEIGHT	: bold;
}		

.size_13{		
	font-size:13px;
}

.size_13_fett{		
	font-size:13px;
	FONT-WEIGHT	: bold;
}

.size_14_fett{		
	font-size:14px;
	FONT-WEIGHT	: bold;
}		

.size_14_narrow{		
	font-size:14px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}		

.size_15{		
	font-size:15px;
}

.size_15_fett{		
	font-size:15px;
	FONT-WEIGHT	: bold;
}

.size_17_fett{		
	font-size:17px;
	FONT-WEIGHT	: bold;
}	

.size_18_fett{		
	font-size:18px;
	FONT-WEIGHT	: bold;
}		

.text_feld{
	z-index:2;	
	color: rgb(0,0,0); 	
	text-align:left;	
	background: transparent;	
	border: solid 1px grey;	
	font:normal 15px 'Courier New', Courier, monospace;
}

.linie_links {
  border-left: 1px solid #000;
}


</style>
