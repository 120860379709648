<template>
	<div>

    <div class="kontainer">

 <!-- @state="onState" -->
    <GSTC :config="config" />

{{config.chart.items}}

	</div>
	</div>
</template>

<script>
import GSTC from "vue-gantt-schedule-timeline-calendar";
//import ItemMovement from "gantt-schedule-timeline-calendar/dist/ItemMovement.plugin.js"
//import moment from 'moment'
// import moment from 'moment-business-days'

import moment from "moment-business-days";

console.log(moment);

console.log(moment('18-08-2021', 'DD-MM-YYYY').isBusinessDay());
console.log(moment('18-08-2021', 'DD-MM-YYYY').businessAdd(3)._d);

let subs = [];

export default {
  name: "app",
  components: {
    GSTC
  },
  data() {
    return {
      config: {
      //  plugins: [
      //     ItemMovement({
      //       moveable: true,
      //       resizeable: true,
      //       collisionDetection: true,
      //       snapStart,
      //       snapEnd
      //     }),
     
      //   ],
          
        height: 300,
        list: {
          rows: {
            "1": {
              id: "1",
              label: "Row 1"
            },
            "2": {
              id: "2",
              label: "Row 2"
            },
            "3": {
              id: "3",
              label: "Row 3"
            },
            "4": {
              id: "4",
              label: "Row 4"
            }
          },
          columns: {
            data: {
              id: {
                id: "id",
                data: "id",
                width: 50,
                header: {
                  content: "ID"
                }
              },
              label: {
                id: "label",
                data: "label",
                width: 200,
                header: {
                  content: "Label"
                }
              }
            }
          }
        },
        chart: {
          items: {
          
          }
        }
      }
    };
  },
  methods: {

    addDay(id, days){


    },
    addBusiDay(){


    },

    onState(state) {
      this.state = state;
      subs.push(
        state.subscribe("config.chart.items.1", item => {
          console.log("item 1 changed", item);
        })
      );
      subs.push(
        state.subscribe("config.list.rows.1", row => {
          console.log("row 1 changed", row);
        })
      );
    }
  },

  mounted() {

console.log(this.config.chart.items)


      this.$set(this.config.chart.items, "1", {
              id: "1",
              rowId: "1",
              label: "Item 1",
              time: {
                start: new Date().getTime(),
                end: new Date().getTime() + 24 * 60 * 60 * 1000
              }
            })

      this.$set(this.config.chart.items, "2", {
              id: "1",
              rowId: "1",
              label: "Item 1",
              time: {
                start: moment(this.config.chart.items['1'].time.start).businessAdd(3)._d,
                end: new Date().getTime() + 24 * 60 * 60 * 1000
              }
            })

console.log(this.config.chart.items)
    //  var zeiten =  "1": {
    //           id: "1",
    //           rowId: "1",
    //           label: "Item 1",
    //           time: {
    //             start: new Date().getTime(),
    //             end: new Date().getTime() + 24 * 60 * 60 * 1000
    //           }
    //         },
    //         "2": {
    //           id: "2",
    //           rowId: "2",
    //           label: "Item 2",
    //           time: {
    //             start: moment(this.config.chart.items['1'].time).businessAdd(3)._d,
    //             end: new Date().getTime() + 5 * 24 * 60 * 60 * 1000
    //           }
    //         }
        


    setTimeout(() => {
      const item1 = this.config.chart.items["1"];
      item1.label = "label changed dynamically";
      item1.time.end += 2 * 24 * 60 * 60 * 1000;
    }, 2000);
  },
  beforeDestroy() {
    subs.forEach(unsub => unsub());
  }
};
</script>

