<template  v-if="actualformular">
<!-- :class="{'textfeld':!formularInsert.isDisabled}" -->


    <textarea
    v-if="formularInsert!=undefined"
		tabindex="2" 
		:rows="styleRows"
		
        class="textfeld"
		style=" width:100%;line-height: 1.2;"
        v-model="formularInsert.text"
         @input="wennInput"
		@focus="setFocus($event)"
		@blur = "looseFocus($event)" 
        :disabled="formularInsert.isDisabled==true || formularInsert.isDisabled=='true'"
	    :style="{'font-size': formularInsert.size + 'px',
                'height': formularInsert.height + 'px',
                'text-align': formularInsert.schriftausrichtung + 'px',
                'font-weight': formularInsert.schriftart + 'px', }"

		:id="daten.einsatz">
    </textarea>

</template>

<script>

    export default {
        props: {
            daten: Object
            },


        components: {
            
        },

        name: "FormularTextarea",


        data(){
            return{
                url:"bauaufsicht",
                // formularInlay : 'antrageinfach2018'
            }
        },
//[this.formularInlay]
        computed: {

                funktionsName(){

                if(this.formularInsert.welchemethod)
                return this.formularInsert.welchemethod
                else
                return "leer"
            },
        
        showFontPopover:
		{
			get()
			{
				return this.$store.getters.showFontPopover
			},
			set(value)
			{
				this.$store.commit('setshowFontPopover', value);
			}
        },
        

            getNameOfActualFormular(){

            var returnValue = "";

            if(this.actualformular){
                    for (var prop in this.actualformular.formularContent) 
                            returnValue = prop;
            }

            return returnValue;

            },

            formularInsert(){
                return this.actualformular.formularContent[this.getNameOfActualFormular][this.daten.einsatz];
            },
            actualformular(){
                return this.$store.getters.actualformular;
            },

            projectDaten(){
                return this.$store.getters.actualProject;
            },
            
            store() {
                return this.$store.state
            },

            styleRows(){
                if(this.daten.rows)
                return this.daten.rows;
                else
                return 1;
            }
        
        },

        methods: {

            
             wennInput(){
                if(this.formularInsert.welchemethod!=undefined || this.formularInsert.welchemethod=='')
                 this[this.formularInsert.welchemethod]()

            },        

            leer(){

           
        },
            setFocus(einsatz){
                console.log(einsatz.target.id)
                this.showFontPopover = '';
                this.showFontPopover = einsatz.target.id;
                
            },
            
            looseFocus(){
                setTimeout(()=>{
                this.showFontPopover = '';
                },10000);
            },
        
            

        },

	created(){




    }
    }
</script>
