var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.formular!=undefined)?_c('div',[_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(24.8703589) + 'px'})},[_vm._v(" Bauantrag / Antrag auf Vorbescheid vom ")]),(_vm.formular.bauantragVom)?_c('b-popover',{ref:"bauantragVom",attrs:{"show":_vm.formular.bauantragVom.showPop==true,"target":"bauantragVom"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauantragVom.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauantragVom.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauantragVom!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauantragVom.text),expression:"formular.bauantragVom.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6161783) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(89.5733912) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(25.1045638) + 'px', 
		
														'font-size': _vm.formular.bauantragVom.size + 'px', 
														'text-align': _vm.formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauantragVom.letterSpacing + 'px',
														'font-weight': _vm.formular.bauantragVom.schriftart }),attrs:{"id":"bauantragVom","type":"text","disabled":_vm.formular.bauantragVom.isDisabled==true || _vm.formular.bauantragVom.isDisabled==true},domProps:{"value":(_vm.formular.bauantragVom.text)},on:{"focus":function($event){_vm.formular.bauantragVom.showPop=true},"blur":function($event){_vm.formular.bauantragVom.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauantragVom.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauantragVom, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(118.8136963) + 'px', 
														top: _vm.gib_hoehe_in_mm(22.8634959) + 'px'})},[_vm._v(" Betriebsbeschreibung für land und ")]),_c('div',{style:({
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(124.8237043) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.7307559) + 'px'})},[_vm._v(" forstwirtschaftliche Vorhaben ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(163.8993373) + 'px', 
														top: _vm.gib_hoehe_in_mm(11.6070754) + 'px'})},[_vm._v(" Anlage I/9 zur VV BauPrüfVO ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(194.6265613) + 'px', 
														top: _vm.gib_hoehe_in_mm(14.9083474) + 'px'})},[_vm._v(" Blatt 1 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(37.3220797) + 'px'})},[_vm._v(" Bauherrschaft ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(84.9288778) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(20.0890866) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(40.3208115) + 'px'})},[(_vm.formular.bauherrschaft)?_c('b-popover',{ref:"bauherrschaft",attrs:{"show":_vm.formular.bauherrschaft.showPop==true,"target":"bauherrschaft"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherrschaft.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherrschaft.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherrschaft", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherrschaft", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherrschaft!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherrschaft.text),expression:"formular.bauherrschaft.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.bauherrschaft.size + 'px',
														'height': _vm.formular.bauherrschaft.height + 'px',
														'text-align': _vm.formular.bauherrschaft.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.bauherrschaft.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherrschaft.schriftart + 'px', }),attrs:{"rows":"4","disabled":_vm.formular.bauherrschaft.isDisabled==true || _vm.formular.bauherrschaft.isDisabled=='true',"id":"bauherrschaft"},domProps:{"value":(_vm.formular.bauherrschaft.text)},on:{"focus":function($event){_vm.formular.bauherrschaft.showPop=true},"blur":function($event){_vm.formular.bauherrschaft.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherrschaft, "text", $event.target.value)}}}):_vm._e()],1),(_vm.formular.eigentuemer!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(108.3942329) + 'px',top: _vm.gib_hoehe_in_mm(42.0772575) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.eigentuemer.text),callback:function ($$v) {_vm.$set(_vm.formular.eigentuemer, "text", $$v)},expression:"formular.eigentuemer.text"}},[_vm._v(" Eigentümerin / Eigentümer ")]):_vm._e(),(_vm.formular.paechter!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(108.3942329) + 'px',top: _vm.gib_hoehe_in_mm(52.1926935) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.paechter.text),callback:function ($$v) {_vm.$set(_vm.formular.paechter, "text", $$v)},expression:"formular.paechter.text"}},[_vm._v(" Pächterin / Pächter ")]):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(61.9123237) + 'px'})},[_vm._v(" Grundstück (Ort, Straße, Hausnummer) ")]),(_vm.formular.baugrund_strasse)?_c('b-popover',{ref:"baugrund_strasse",attrs:{"show":_vm.formular.baugrund_strasse.showPop==true,"target":"baugrund_strasse"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.baugrund_strasse.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.baugrund_strasse.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("baugrund_strasse", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("baugrund_strasse", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.baugrund_strasse!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.baugrund_strasse.text),expression:"formular.baugrund_strasse.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(180.8728007) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(20.3390866) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.1610555) + 'px', 
		
														'font-size': _vm.formular.baugrund_strasse.size + 'px', 
														'text-align': _vm.formular.baugrund_strasse.schriftausrichtung, 
														'letter-spacing': _vm.formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': _vm.formular.baugrund_strasse.schriftart }),attrs:{"id":"baugrund_strasse","type":"text","disabled":_vm.formular.baugrund_strasse.isDisabled==true || _vm.formular.baugrund_strasse.isDisabled==true},domProps:{"value":(_vm.formular.baugrund_strasse.text)},on:{"focus":function($event){_vm.formular.baugrund_strasse.showPop=true},"blur":function($event){_vm.formular.baugrund_strasse.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.baugrund_strasse.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.baugrund_strasse, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(72.7589068) + 'px'})},[_vm._v(" Genaue Bezeichnung des beantragten Vorhabens ")]),(_vm.formular.bezeichnung_bauvorhaben)?_c('b-popover',{ref:"bezeichnung_bauvorhaben",attrs:{"show":_vm.formular.bezeichnung_bauvorhaben.showPop==true,"target":"bezeichnung_bauvorhaben"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bezeichnung_bauvorhaben.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bezeichnung_bauvorhaben.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bezeichnung_bauvorhaben", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bezeichnung_bauvorhaben", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bezeichnung_bauvorhaben!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bezeichnung_bauvorhaben.text),expression:"formular.bezeichnung_bauvorhaben.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(103.9337646) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(97.2781227) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(72.7204746) + 'px', 
		
														'font-size': _vm.formular.bezeichnung_bauvorhaben.size + 'px', 
														'text-align': _vm.formular.bezeichnung_bauvorhaben.schriftausrichtung, 
														'letter-spacing': _vm.formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': _vm.formular.bezeichnung_bauvorhaben.schriftart }),attrs:{"id":"bezeichnung_bauvorhaben","type":"text","disabled":_vm.formular.bezeichnung_bauvorhaben.isDisabled==true || _vm.formular.bezeichnung_bauvorhaben.isDisabled==true},domProps:{"value":(_vm.formular.bezeichnung_bauvorhaben.text)},on:{"focus":function($event){_vm.formular.bezeichnung_bauvorhaben.showPop=true},"blur":function($event){_vm.formular.bezeichnung_bauvorhaben.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bezeichnung_bauvorhaben.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bezeichnung_bauvorhaben, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.betriebsgebaude1)?_c('b-popover',{ref:"betriebsgebaude1",attrs:{"show":_vm.formular.betriebsgebaude1.showPop==true,"target":"betriebsgebaude1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.betriebsgebaude1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.betriebsgebaude1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsgebaude1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsgebaude1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.betriebsgebaude1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.betriebsgebaude1.text),expression:"formular.betriebsgebaude1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(80.1906606) + 'px', 
		
														'font-size': _vm.formular.betriebsgebaude1.size + 'px', 
														'text-align': _vm.formular.betriebsgebaude1.schriftausrichtung, 
														'letter-spacing': _vm.formular.betriebsgebaude1.letterSpacing + 'px',
														'font-weight': _vm.formular.betriebsgebaude1.schriftart }),attrs:{"id":"betriebsgebaude1","type":"text","disabled":_vm.formular.betriebsgebaude1.isDisabled==true || _vm.formular.betriebsgebaude1.isDisabled==true},domProps:{"value":(_vm.formular.betriebsgebaude1.text)},on:{"focus":function($event){_vm.formular.betriebsgebaude1.showPop=true},"blur":function($event){_vm.formular.betriebsgebaude1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.betriebsgebaude1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.betriebsgebaude1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.betriebsgebaude2)?_c('b-popover',{ref:"betriebsgebaude2",attrs:{"show":_vm.formular.betriebsgebaude2.showPop==true,"target":"betriebsgebaude2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.betriebsgebaude2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.betriebsgebaude2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsgebaude2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsgebaude2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.betriebsgebaude2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.betriebsgebaude2.text),expression:"formular.betriebsgebaude2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(87.5973606) + 'px', 
		
														'font-size': _vm.formular.betriebsgebaude2.size + 'px', 
														'text-align': _vm.formular.betriebsgebaude2.schriftausrichtung, 
														'letter-spacing': _vm.formular.betriebsgebaude2.letterSpacing + 'px',
														'font-weight': _vm.formular.betriebsgebaude2.schriftart }),attrs:{"id":"betriebsgebaude2","type":"text","disabled":_vm.formular.betriebsgebaude2.isDisabled==true || _vm.formular.betriebsgebaude2.isDisabled==true},domProps:{"value":(_vm.formular.betriebsgebaude2.text)},on:{"focus":function($event){_vm.formular.betriebsgebaude2.showPop=true},"blur":function($event){_vm.formular.betriebsgebaude2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.betriebsgebaude2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.betriebsgebaude2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.lagergebaude1)?_c('b-popover',{ref:"lagergebaude1",attrs:{"show":_vm.formular.lagergebaude1.showPop==true,"target":"lagergebaude1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.lagergebaude1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.lagergebaude1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("lagergebaude1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("lagergebaude1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.lagergebaude1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.lagergebaude1.text),expression:"formular.lagergebaude1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(94.9638528) + 'px', 
		
														'font-size': _vm.formular.lagergebaude1.size + 'px', 
														'text-align': _vm.formular.lagergebaude1.schriftausrichtung, 
														'letter-spacing': _vm.formular.lagergebaude1.letterSpacing + 'px',
														'font-weight': _vm.formular.lagergebaude1.schriftart }),attrs:{"id":"lagergebaude1","type":"text","disabled":_vm.formular.lagergebaude1.isDisabled==true || _vm.formular.lagergebaude1.isDisabled==true},domProps:{"value":(_vm.formular.lagergebaude1.text)},on:{"focus":function($event){_vm.formular.lagergebaude1.showPop=true},"blur":function($event){_vm.formular.lagergebaude1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.lagergebaude1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.lagergebaude1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.lagergebaude2)?_c('b-popover',{ref:"lagergebaude2",attrs:{"show":_vm.formular.lagergebaude2.showPop==true,"target":"lagergebaude2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.lagergebaude2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.lagergebaude2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("lagergebaude2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("lagergebaude2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.lagergebaude2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.lagergebaude2.text),expression:"formular.lagergebaude2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(102.2940169) + 'px', 
		
														'font-size': _vm.formular.lagergebaude2.size + 'px', 
														'text-align': _vm.formular.lagergebaude2.schriftausrichtung, 
														'letter-spacing': _vm.formular.lagergebaude2.letterSpacing + 'px',
														'font-weight': _vm.formular.lagergebaude2.schriftart }),attrs:{"id":"lagergebaude2","type":"text","disabled":_vm.formular.lagergebaude2.isDisabled==true || _vm.formular.lagergebaude2.isDisabled==true},domProps:{"value":(_vm.formular.lagergebaude2.text)},on:{"focus":function($event){_vm.formular.lagergebaude2.showPop=true},"blur":function($event){_vm.formular.lagergebaude2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.lagergebaude2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.lagergebaude2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.stallgebaude1)?_c('b-popover',{ref:"stallgebaude1",attrs:{"show":_vm.formular.stallgebaude1.showPop==true,"target":"stallgebaude1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.stallgebaude1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.stallgebaude1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("stallgebaude1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("stallgebaude1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.stallgebaude1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.stallgebaude1.text),expression:"formular.stallgebaude1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(109.7433936) + 'px', 
		
														'font-size': _vm.formular.stallgebaude1.size + 'px', 
														'text-align': _vm.formular.stallgebaude1.schriftausrichtung, 
														'letter-spacing': _vm.formular.stallgebaude1.letterSpacing + 'px',
														'font-weight': _vm.formular.stallgebaude1.schriftart }),attrs:{"id":"stallgebaude1","type":"text","disabled":_vm.formular.stallgebaude1.isDisabled==true || _vm.formular.stallgebaude1.isDisabled==true},domProps:{"value":(_vm.formular.stallgebaude1.text)},on:{"focus":function($event){_vm.formular.stallgebaude1.showPop=true},"blur":function($event){_vm.formular.stallgebaude1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.stallgebaude1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.stallgebaude1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.stallgebaude2)?_c('b-popover',{ref:"stallgebaude2",attrs:{"show":_vm.formular.stallgebaude2.showPop==true,"target":"stallgebaude2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.stallgebaude2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.stallgebaude2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("stallgebaude2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("stallgebaude2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.stallgebaude2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.stallgebaude2.text),expression:"formular.stallgebaude2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(117.1497409) + 'px', 
		
														'font-size': _vm.formular.stallgebaude2.size + 'px', 
														'text-align': _vm.formular.stallgebaude2.schriftausrichtung, 
														'letter-spacing': _vm.formular.stallgebaude2.letterSpacing + 'px',
														'font-weight': _vm.formular.stallgebaude2.schriftart }),attrs:{"id":"stallgebaude2","type":"text","disabled":_vm.formular.stallgebaude2.isDisabled==true || _vm.formular.stallgebaude2.isDisabled==true},domProps:{"value":(_vm.formular.stallgebaude2.text)},on:{"focus":function($event){_vm.formular.stallgebaude2.showPop=true},"blur":function($event){_vm.formular.stallgebaude2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.stallgebaude2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.stallgebaude2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.wohngebaude1)?_c('b-popover',{ref:"wohngebaude1",attrs:{"show":_vm.formular.wohngebaude1.showPop==true,"target":"wohngebaude1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.wohngebaude1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.wohngebaude1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wohngebaude1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wohngebaude1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.wohngebaude1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.wohngebaude1.text),expression:"formular.wohngebaude1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(124.5571463) + 'px', 
		
														'font-size': _vm.formular.wohngebaude1.size + 'px', 
														'text-align': _vm.formular.wohngebaude1.schriftausrichtung, 
														'letter-spacing': _vm.formular.wohngebaude1.letterSpacing + 'px',
														'font-weight': _vm.formular.wohngebaude1.schriftart }),attrs:{"id":"wohngebaude1","type":"text","disabled":_vm.formular.wohngebaude1.isDisabled==true || _vm.formular.wohngebaude1.isDisabled==true},domProps:{"value":(_vm.formular.wohngebaude1.text)},on:{"focus":function($event){_vm.formular.wohngebaude1.showPop=true},"blur":function($event){_vm.formular.wohngebaude1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.wohngebaude1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.wohngebaude1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.wohngebaude2)?_c('b-popover',{ref:"wohngebaude2",attrs:{"show":_vm.formular.wohngebaude2.showPop==true,"target":"wohngebaude2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.wohngebaude2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.wohngebaude2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wohngebaude2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wohngebaude2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.wohngebaude2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.wohngebaude2.text),expression:"formular.wohngebaude2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(131.9215223) + 'px', 
		
														'font-size': _vm.formular.wohngebaude2.size + 'px', 
														'text-align': _vm.formular.wohngebaude2.schriftausrichtung, 
														'letter-spacing': _vm.formular.wohngebaude2.letterSpacing + 'px',
														'font-weight': _vm.formular.wohngebaude2.schriftart }),attrs:{"id":"wohngebaude2","type":"text","disabled":_vm.formular.wohngebaude2.isDisabled==true || _vm.formular.wohngebaude2.isDisabled==true},domProps:{"value":(_vm.formular.wohngebaude2.text)},on:{"focus":function($event){_vm.formular.wohngebaude2.showPop=true},"blur":function($event){_vm.formular.wohngebaude2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.wohngebaude2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.wohngebaude2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.sonstgebaude1)?_c('b-popover',{ref:"sonstgebaude1",attrs:{"show":_vm.formular.sonstgebaude1.showPop==true,"target":"sonstgebaude1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstgebaude1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstgebaude1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstgebaude1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstgebaude1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstgebaude1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.sonstgebaude1.text),expression:"formular.sonstgebaude1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(139.3278696) + 'px', 
		
														'font-size': _vm.formular.sonstgebaude1.size + 'px', 
														'text-align': _vm.formular.sonstgebaude1.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstgebaude1.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstgebaude1.schriftart }),attrs:{"id":"sonstgebaude1","type":"text","disabled":_vm.formular.sonstgebaude1.isDisabled==true || _vm.formular.sonstgebaude1.isDisabled==true},domProps:{"value":(_vm.formular.sonstgebaude1.text)},on:{"focus":function($event){_vm.formular.sonstgebaude1.showPop=true},"blur":function($event){_vm.formular.sonstgebaude1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstgebaude1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.sonstgebaude1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.sonstgebaude2)?_c('b-popover',{ref:"sonstgebaude2",attrs:{"show":_vm.formular.sonstgebaude2.showPop==true,"target":"sonstgebaude2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstgebaude2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstgebaude2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstgebaude2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstgebaude2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstgebaude2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.sonstgebaude2.text),expression:"formular.sonstgebaude2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(144.5006132) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(146.6922456) + 'px', 
		
														'font-size': _vm.formular.sonstgebaude2.size + 'px', 
														'text-align': _vm.formular.sonstgebaude2.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstgebaude2.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstgebaude2.schriftart }),attrs:{"id":"sonstgebaude2","type":"text","disabled":_vm.formular.sonstgebaude2.isDisabled==true || _vm.formular.sonstgebaude2.isDisabled==true},domProps:{"value":(_vm.formular.sonstgebaude2.text)},on:{"focus":function($event){_vm.formular.sonstgebaude2.showPop=true},"blur":function($event){_vm.formular.sonstgebaude2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstgebaude2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.sonstgebaude2, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(83.8904715) + 'px'})},[_vm._v(" Betriebsgebäude ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(98.6721285) + 'px'})},[_vm._v(" Lagergebäude ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(113.4432045) + 'px'})},[_vm._v(" Stallgebäude ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(128.2142805) + 'px'})},[_vm._v(" Wohngebäude ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(141.1654245) + 'px'})},[_vm._v(" sonstige Gebäude / ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(144.8052885) + 'px'})},[_vm._v(" Anlagen ")]),_c('div',{style:({
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(154.9746876) + 'px'})},[_vm._v(" 1 ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(153.1431165) + 'px'})},[_vm._v(" Betriebsflächen ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(156.7829805) + 'px'})},[_vm._v(" (ha) ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(163.4349025) + 'px'})},[_vm._v(" Ackerland ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(170.0374465) + 'px'})},[_vm._v(" Grünland ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(176.6399905) + 'px'})},[_vm._v(" sonstige landwirt ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(180.2798545) + 'px'})},[_vm._v(" schaftliche Nutzflä ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(183.9620425) + 'px'})},[_vm._v(" che ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(190.2259945) + 'px'})},[_vm._v(" Summe landwirt ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(193.9081825) + 'px'})},[_vm._v(" schaftliche Nutzflä ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(197.5480465) + 'px'})},[_vm._v(" che ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(203.5580545) + 'px'})},[_vm._v(" Forstwirtschaftliche ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(207.2402425) + 'px'})},[_vm._v(" Nutzfläche ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(213.2502505) + 'px'})},[_vm._v(" Sonstige Flächen ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(220.0644145) + 'px'})},[_vm._v(" Summe ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.8643874) + 'px', 
														top: _vm.gib_hoehe_in_mm(223.7042785) + 'px'})},[_vm._v(" Betriebsfläche ")]),_c('div',{style:({
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(81.8154663) + 'px', 
														top: _vm.gib_hoehe_in_mm(153.2147146) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(139.5101323) + 'px', 
														top: _vm.gib_hoehe_in_mm(153.2147146) + 'px'})},[_vm._v(" Ziel ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(173.7608293) + 'px', 
														top: _vm.gib_hoehe_in_mm(153.0468294) + 'px'})},[_vm._v(" Prüfvermerke ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(57.2566126) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Eigentum ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(71.9112976) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Zupacht ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(86.0478663) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Verpacht. ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(100.4380263) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Bewirtsch. ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(115.7663683) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Eigentum ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.4262483) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Zupacht ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(145.6047883) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Verpacht. ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(160.1748253) + 'px', 
														top: _vm.gib_hoehe_in_mm(158.7605694) + 'px'})},[_vm._v(" Bewirtsch. ")]),(_vm.formular.acker1)?_c('b-popover',{ref:"acker1",attrs:{"show":_vm.formular.acker1.showPop==true,"target":"acker1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker1.size + 'px', 
														'text-align': _vm.formular.acker1.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker1.letterSpacing + 'px',
														'font-weight': _vm.formular.acker1.schriftart }),attrs:{"id":"acker1","precision":parseInt(_vm.formular.acker1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker1.isDisabled==true || _vm.formular.acker1.isDisabled==true},on:{"focus":function($event){_vm.formular.acker1.showPop=true},"blur":function($event){_vm.formular.acker1.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker1.onylNumber)}},model:{value:(_vm.formular.acker1.text),callback:function ($$v) {_vm.$set(_vm.formular.acker1, "text", $$v)},expression:"formular.acker1.text"}}):_vm._e(),(_vm.formular.acker2)?_c('b-popover',{ref:"acker2",attrs:{"show":_vm.formular.acker2.showPop==true,"target":"acker2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker2.size + 'px', 
														'text-align': _vm.formular.acker2.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker2.letterSpacing + 'px',
														'font-weight': _vm.formular.acker2.schriftart }),attrs:{"id":"acker2","precision":parseInt(_vm.formular.acker2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker2.isDisabled==true || _vm.formular.acker2.isDisabled==true},on:{"focus":function($event){_vm.formular.acker2.showPop=true},"blur":function($event){_vm.formular.acker2.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker2.onylNumber)}},model:{value:(_vm.formular.acker2.text),callback:function ($$v) {_vm.$set(_vm.formular.acker2, "text", $$v)},expression:"formular.acker2.text"}}):_vm._e(),(_vm.formular.acker3)?_c('b-popover',{ref:"acker3",attrs:{"show":_vm.formular.acker3.showPop==true,"target":"acker3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker3.size + 'px', 
														'text-align': _vm.formular.acker3.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker3.letterSpacing + 'px',
														'font-weight': _vm.formular.acker3.schriftart }),attrs:{"id":"acker3","precision":parseInt(_vm.formular.acker3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker3.isDisabled==true || _vm.formular.acker3.isDisabled==true},on:{"focus":function($event){_vm.formular.acker3.showPop=true},"blur":function($event){_vm.formular.acker3.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker3.onylNumber)}},model:{value:(_vm.formular.acker3.text),callback:function ($$v) {_vm.$set(_vm.formular.acker3, "text", $$v)},expression:"formular.acker3.text"}}):_vm._e(),(_vm.formular.acker4)?_c('b-popover',{ref:"acker4",attrs:{"show":_vm.formular.acker4.showPop==true,"target":"acker4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker4.size + 'px', 
														'text-align': _vm.formular.acker4.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker4.letterSpacing + 'px',
														'font-weight': _vm.formular.acker4.schriftart }),attrs:{"id":"acker4","precision":parseInt(_vm.formular.acker4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker4.isDisabled==true || _vm.formular.acker4.isDisabled==true},on:{"focus":function($event){_vm.formular.acker4.showPop=true},"blur":function($event){_vm.formular.acker4.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker4.onylNumber)}},model:{value:(_vm.formular.acker4.text),callback:function ($$v) {_vm.$set(_vm.formular.acker4, "text", $$v)},expression:"formular.acker4.text"}}):_vm._e(),(_vm.formular.acker5)?_c('b-popover',{ref:"acker5",attrs:{"show":_vm.formular.acker5.showPop==true,"target":"acker5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker5.size + 'px', 
														'text-align': _vm.formular.acker5.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker5.letterSpacing + 'px',
														'font-weight': _vm.formular.acker5.schriftart }),attrs:{"id":"acker5","precision":parseInt(_vm.formular.acker5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker5.isDisabled==true || _vm.formular.acker5.isDisabled==true},on:{"focus":function($event){_vm.formular.acker5.showPop=true},"blur":function($event){_vm.formular.acker5.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker5.onylNumber)}},model:{value:(_vm.formular.acker5.text),callback:function ($$v) {_vm.$set(_vm.formular.acker5, "text", $$v)},expression:"formular.acker5.text"}}):_vm._e(),(_vm.formular.acker6)?_c('b-popover',{ref:"acker6",attrs:{"show":_vm.formular.acker6.showPop==true,"target":"acker6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker6.size + 'px', 
														'text-align': _vm.formular.acker6.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker6.letterSpacing + 'px',
														'font-weight': _vm.formular.acker6.schriftart }),attrs:{"id":"acker6","precision":parseInt(_vm.formular.acker6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker6.isDisabled==true || _vm.formular.acker6.isDisabled==true},on:{"focus":function($event){_vm.formular.acker6.showPop=true},"blur":function($event){_vm.formular.acker6.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker6.onylNumber)}},model:{value:(_vm.formular.acker6.text),callback:function ($$v) {_vm.$set(_vm.formular.acker6, "text", $$v)},expression:"formular.acker6.text"}}):_vm._e(),(_vm.formular.acker7)?_c('b-popover',{ref:"acker7",attrs:{"show":_vm.formular.acker7.showPop==true,"target":"acker7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker7.size + 'px', 
														'text-align': _vm.formular.acker7.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker7.letterSpacing + 'px',
														'font-weight': _vm.formular.acker7.schriftart }),attrs:{"id":"acker7","precision":parseInt(_vm.formular.acker7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker7.isDisabled==true || _vm.formular.acker7.isDisabled==true},on:{"focus":function($event){_vm.formular.acker7.showPop=true},"blur":function($event){_vm.formular.acker7.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker7.onylNumber)}},model:{value:(_vm.formular.acker7.text),callback:function ($$v) {_vm.$set(_vm.formular.acker7, "text", $$v)},expression:"formular.acker7.text"}}):_vm._e(),(_vm.formular.acker8)?_c('b-popover',{ref:"acker8",attrs:{"show":_vm.formular.acker8.showPop==true,"target":"acker8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.acker8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.acker8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("acker8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.acker8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(163.8105401) + 'px', 
		
														'font-size': _vm.formular.acker8.size + 'px', 
														'text-align': _vm.formular.acker8.schriftausrichtung, 
														'letter-spacing': _vm.formular.acker8.letterSpacing + 'px',
														'font-weight': _vm.formular.acker8.schriftart }),attrs:{"id":"acker8","precision":parseInt(_vm.formular.acker8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.acker8.isDisabled==true || _vm.formular.acker8.isDisabled==true},on:{"focus":function($event){_vm.formular.acker8.showPop=true},"blur":function($event){_vm.formular.acker8.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.acker8.onylNumber)}},model:{value:(_vm.formular.acker8.text),callback:function ($$v) {_vm.$set(_vm.formular.acker8, "text", $$v)},expression:"formular.acker8.text"}}):_vm._e(),(_vm.formular.gruenland1)?_c('b-popover',{ref:"gruenland1",attrs:{"show":_vm.formular.gruenland1.showPop==true,"target":"gruenland1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland1.size + 'px', 
														'text-align': _vm.formular.gruenland1.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland1.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland1.schriftart }),attrs:{"id":"gruenland1","precision":parseInt(_vm.formular.gruenland1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland1.isDisabled==true || _vm.formular.gruenland1.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland1.showPop=true},"blur":function($event){_vm.formular.gruenland1.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland1.onylNumber)}},model:{value:(_vm.formular.gruenland1.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland1, "text", $$v)},expression:"formular.gruenland1.text"}}):_vm._e(),(_vm.formular.gruenland2)?_c('b-popover',{ref:"gruenland2",attrs:{"show":_vm.formular.gruenland2.showPop==true,"target":"gruenland2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland2.size + 'px', 
														'text-align': _vm.formular.gruenland2.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland2.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland2.schriftart }),attrs:{"id":"gruenland2","precision":parseInt(_vm.formular.gruenland2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland2.isDisabled==true || _vm.formular.gruenland2.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland2.showPop=true},"blur":function($event){_vm.formular.gruenland2.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland2.onylNumber)}},model:{value:(_vm.formular.gruenland2.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland2, "text", $$v)},expression:"formular.gruenland2.text"}}):_vm._e(),(_vm.formular.gruenland3)?_c('b-popover',{ref:"gruenland3",attrs:{"show":_vm.formular.gruenland3.showPop==true,"target":"gruenland3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland3.size + 'px', 
														'text-align': _vm.formular.gruenland3.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland3.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland3.schriftart }),attrs:{"id":"gruenland3","precision":parseInt(_vm.formular.gruenland3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland3.isDisabled==true || _vm.formular.gruenland3.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland3.showPop=true},"blur":function($event){_vm.formular.gruenland3.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland3.onylNumber)}},model:{value:(_vm.formular.gruenland3.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland3, "text", $$v)},expression:"formular.gruenland3.text"}}):_vm._e(),(_vm.formular.gruenland4)?_c('b-popover',{ref:"gruenland4",attrs:{"show":_vm.formular.gruenland4.showPop==true,"target":"gruenland4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland4.size + 'px', 
														'text-align': _vm.formular.gruenland4.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland4.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland4.schriftart }),attrs:{"id":"gruenland4","precision":parseInt(_vm.formular.gruenland4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland4.isDisabled==true || _vm.formular.gruenland4.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland4.showPop=true},"blur":function($event){_vm.formular.gruenland4.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland4.onylNumber)}},model:{value:(_vm.formular.gruenland4.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland4, "text", $$v)},expression:"formular.gruenland4.text"}}):_vm._e(),(_vm.formular.gruenland5)?_c('b-popover',{ref:"gruenland5",attrs:{"show":_vm.formular.gruenland5.showPop==true,"target":"gruenland5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland5.size + 'px', 
														'text-align': _vm.formular.gruenland5.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland5.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland5.schriftart }),attrs:{"id":"gruenland5","precision":parseInt(_vm.formular.gruenland5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland5.isDisabled==true || _vm.formular.gruenland5.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland5.showPop=true},"blur":function($event){_vm.formular.gruenland5.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland5.onylNumber)}},model:{value:(_vm.formular.gruenland5.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland5, "text", $$v)},expression:"formular.gruenland5.text"}}):_vm._e(),(_vm.formular.gruenland6)?_c('b-popover',{ref:"gruenland6",attrs:{"show":_vm.formular.gruenland6.showPop==true,"target":"gruenland6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland6.size + 'px', 
														'text-align': _vm.formular.gruenland6.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland6.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland6.schriftart }),attrs:{"id":"gruenland6","precision":parseInt(_vm.formular.gruenland6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland6.isDisabled==true || _vm.formular.gruenland6.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland6.showPop=true},"blur":function($event){_vm.formular.gruenland6.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland6.onylNumber)}},model:{value:(_vm.formular.gruenland6.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland6, "text", $$v)},expression:"formular.gruenland6.text"}}):_vm._e(),(_vm.formular.gruenland7)?_c('b-popover',{ref:"gruenland7",attrs:{"show":_vm.formular.gruenland7.showPop==true,"target":"gruenland7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland7.size + 'px', 
														'text-align': _vm.formular.gruenland7.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland7.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland7.schriftart }),attrs:{"id":"gruenland7","precision":parseInt(_vm.formular.gruenland7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland7.isDisabled==true || _vm.formular.gruenland7.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland7.showPop=true},"blur":function($event){_vm.formular.gruenland7.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland7.onylNumber)}},model:{value:(_vm.formular.gruenland7.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland7, "text", $$v)},expression:"formular.gruenland7.text"}}):_vm._e(),(_vm.formular.gruenland8)?_c('b-popover',{ref:"gruenland8",attrs:{"show":_vm.formular.gruenland8.showPop==true,"target":"gruenland8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gruenland8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gruenland8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gruenland8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gruenland8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(170.4631675) + 'px', 
		
														'font-size': _vm.formular.gruenland8.size + 'px', 
														'text-align': _vm.formular.gruenland8.schriftausrichtung, 
														'letter-spacing': _vm.formular.gruenland8.letterSpacing + 'px',
														'font-weight': _vm.formular.gruenland8.schriftart }),attrs:{"id":"gruenland8","precision":parseInt(_vm.formular.gruenland8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gruenland8.isDisabled==true || _vm.formular.gruenland8.isDisabled==true},on:{"focus":function($event){_vm.formular.gruenland8.showPop=true},"blur":function($event){_vm.formular.gruenland8.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gruenland8.onylNumber)}},model:{value:(_vm.formular.gruenland8.text),callback:function ($$v) {_vm.$set(_vm.formular.gruenland8, "text", $$v)},expression:"formular.gruenland8.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche1)?_c('b-popover',{ref:"sonstlwflaeche1",attrs:{"show":_vm.formular.sonstlwflaeche1.showPop==true,"target":"sonstlwflaeche1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche1.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche1.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche1.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche1.schriftart }),attrs:{"id":"sonstlwflaeche1","precision":parseInt(_vm.formular.sonstlwflaeche1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche1.isDisabled==true || _vm.formular.sonstlwflaeche1.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche1.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche1.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche1.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche1.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche1, "text", $$v)},expression:"formular.sonstlwflaeche1.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche2)?_c('b-popover',{ref:"sonstlwflaeche2",attrs:{"show":_vm.formular.sonstlwflaeche2.showPop==true,"target":"sonstlwflaeche2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche2.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche2.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche2.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche2.schriftart }),attrs:{"id":"sonstlwflaeche2","precision":parseInt(_vm.formular.sonstlwflaeche2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche2.isDisabled==true || _vm.formular.sonstlwflaeche2.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche2.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche2.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche2.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche2.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche2, "text", $$v)},expression:"formular.sonstlwflaeche2.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche3)?_c('b-popover',{ref:"sonstlwflaeche3",attrs:{"show":_vm.formular.sonstlwflaeche3.showPop==true,"target":"sonstlwflaeche3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche3.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche3.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche3.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche3.schriftart }),attrs:{"id":"sonstlwflaeche3","precision":parseInt(_vm.formular.sonstlwflaeche3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche3.isDisabled==true || _vm.formular.sonstlwflaeche3.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche3.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche3.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche3.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche3.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche3, "text", $$v)},expression:"formular.sonstlwflaeche3.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche4)?_c('b-popover',{ref:"sonstlwflaeche4",attrs:{"show":_vm.formular.sonstlwflaeche4.showPop==true,"target":"sonstlwflaeche4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche4.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche4.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche4.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche4.schriftart }),attrs:{"id":"sonstlwflaeche4","precision":parseInt(_vm.formular.sonstlwflaeche4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche4.isDisabled==true || _vm.formular.sonstlwflaeche4.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche4.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche4.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche4.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche4.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche4, "text", $$v)},expression:"formular.sonstlwflaeche4.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche5)?_c('b-popover',{ref:"sonstlwflaeche5",attrs:{"show":_vm.formular.sonstlwflaeche5.showPop==true,"target":"sonstlwflaeche5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche5.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche5.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche5.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche5.schriftart }),attrs:{"id":"sonstlwflaeche5","precision":parseInt(_vm.formular.sonstlwflaeche5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche5.isDisabled==true || _vm.formular.sonstlwflaeche5.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche5.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche5.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche5.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche5.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche5, "text", $$v)},expression:"formular.sonstlwflaeche5.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche6)?_c('b-popover',{ref:"sonstlwflaeche6",attrs:{"show":_vm.formular.sonstlwflaeche6.showPop==true,"target":"sonstlwflaeche6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche6.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche6.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche6.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche6.schriftart }),attrs:{"id":"sonstlwflaeche6","precision":parseInt(_vm.formular.sonstlwflaeche6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche6.isDisabled==true || _vm.formular.sonstlwflaeche6.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche6.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche6.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche6.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche6.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche6, "text", $$v)},expression:"formular.sonstlwflaeche6.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche7)?_c('b-popover',{ref:"sonstlwflaeche7",attrs:{"show":_vm.formular.sonstlwflaeche7.showPop==true,"target":"sonstlwflaeche7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche7.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche7.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche7.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche7.schriftart }),attrs:{"id":"sonstlwflaeche7","precision":parseInt(_vm.formular.sonstlwflaeche7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche7.isDisabled==true || _vm.formular.sonstlwflaeche7.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche7.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche7.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche7.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche7.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche7, "text", $$v)},expression:"formular.sonstlwflaeche7.text"}}):_vm._e(),(_vm.formular.sonstlwflaeche8)?_c('b-popover',{ref:"sonstlwflaeche8",attrs:{"show":_vm.formular.sonstlwflaeche8.showPop==true,"target":"sonstlwflaeche8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstlwflaeche8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstlwflaeche8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstlwflaeche8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstlwflaeche8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(177.0657115) + 'px', 
		
														'font-size': _vm.formular.sonstlwflaeche8.size + 'px', 
														'text-align': _vm.formular.sonstlwflaeche8.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstlwflaeche8.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstlwflaeche8.schriftart }),attrs:{"id":"sonstlwflaeche8","precision":parseInt(_vm.formular.sonstlwflaeche8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstlwflaeche8.isDisabled==true || _vm.formular.sonstlwflaeche8.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstlwflaeche8.showPop=true},"blur":function($event){_vm.formular.sonstlwflaeche8.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstlwflaeche8.onylNumber)}},model:{value:(_vm.formular.sonstlwflaeche8.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstlwflaeche8, "text", $$v)},expression:"formular.sonstlwflaeche8.text"}}):_vm._e(),(_vm.formular.summelwflaeche1)?_c('b-popover',{ref:"summelwflaeche1",attrs:{"show":_vm.formular.summelwflaeche1.showPop==true,"target":"summelwflaeche1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche1.size + 'px', 
														'text-align': _vm.formular.summelwflaeche1.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche1.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche1.schriftart }),attrs:{"id":"summelwflaeche1","precision":parseInt(_vm.formular.summelwflaeche1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche1.isDisabled==true || _vm.formular.summelwflaeche1.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche1.showPop=true},"blur":function($event){_vm.formular.summelwflaeche1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche1.onylNumber)}},model:{value:(_vm.formular.summelwflaeche1.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche1, "text", $$v)},expression:"formular.summelwflaeche1.text"}}):_vm._e(),(_vm.formular.summelwflaeche2)?_c('b-popover',{ref:"summelwflaeche2",attrs:{"show":_vm.formular.summelwflaeche2.showPop==true,"target":"summelwflaeche2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche2.size + 'px', 
														'text-align': _vm.formular.summelwflaeche2.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche2.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche2.schriftart }),attrs:{"id":"summelwflaeche2","precision":parseInt(_vm.formular.summelwflaeche2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche2.isDisabled==true || _vm.formular.summelwflaeche2.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche2.showPop=true},"blur":function($event){_vm.formular.summelwflaeche2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche2.onylNumber)}},model:{value:(_vm.formular.summelwflaeche2.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche2, "text", $$v)},expression:"formular.summelwflaeche2.text"}}):_vm._e(),(_vm.formular.summelwflaeche3)?_c('b-popover',{ref:"summelwflaeche3",attrs:{"show":_vm.formular.summelwflaeche3.showPop==true,"target":"summelwflaeche3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche3.size + 'px', 
														'text-align': _vm.formular.summelwflaeche3.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche3.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche3.schriftart }),attrs:{"id":"summelwflaeche3","precision":parseInt(_vm.formular.summelwflaeche3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche3.isDisabled==true || _vm.formular.summelwflaeche3.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche3.showPop=true},"blur":function($event){_vm.formular.summelwflaeche3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche3.onylNumber)}},model:{value:(_vm.formular.summelwflaeche3.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche3, "text", $$v)},expression:"formular.summelwflaeche3.text"}}):_vm._e(),(_vm.formular.summelwflaeche4)?_c('b-popover',{ref:"summelwflaeche4",attrs:{"show":_vm.formular.summelwflaeche4.showPop==true,"target":"summelwflaeche4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche4.size + 'px', 
														'text-align': _vm.formular.summelwflaeche4.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche4.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche4.schriftart }),attrs:{"id":"summelwflaeche4","precision":parseInt(_vm.formular.summelwflaeche4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche4.isDisabled==true || _vm.formular.summelwflaeche4.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche4.showPop=true},"blur":function($event){_vm.formular.summelwflaeche4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche4.onylNumber)}},model:{value:(_vm.formular.summelwflaeche4.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche4, "text", $$v)},expression:"formular.summelwflaeche4.text"}}):_vm._e(),(_vm.formular.summelwflaeche5)?_c('b-popover',{ref:"summelwflaeche5",attrs:{"show":_vm.formular.summelwflaeche5.showPop==true,"target":"summelwflaeche5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche5.size + 'px', 
														'text-align': _vm.formular.summelwflaeche5.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche5.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche5.schriftart }),attrs:{"id":"summelwflaeche5","precision":parseInt(_vm.formular.summelwflaeche5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche5.isDisabled==true || _vm.formular.summelwflaeche5.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche5.showPop=true},"blur":function($event){_vm.formular.summelwflaeche5.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche5.onylNumber)}},model:{value:(_vm.formular.summelwflaeche5.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche5, "text", $$v)},expression:"formular.summelwflaeche5.text"}}):_vm._e(),(_vm.formular.summelwflaeche6)?_c('b-popover',{ref:"summelwflaeche6",attrs:{"show":_vm.formular.summelwflaeche6.showPop==true,"target":"summelwflaeche6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche6.size + 'px', 
														'text-align': _vm.formular.summelwflaeche6.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche6.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche6.schriftart }),attrs:{"id":"summelwflaeche6","precision":parseInt(_vm.formular.summelwflaeche6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche6.isDisabled==true || _vm.formular.summelwflaeche6.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche6.showPop=true},"blur":function($event){_vm.formular.summelwflaeche6.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche6.onylNumber)}},model:{value:(_vm.formular.summelwflaeche6.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche6, "text", $$v)},expression:"formular.summelwflaeche6.text"}}):_vm._e(),(_vm.formular.summelwflaeche7)?_c('b-popover',{ref:"summelwflaeche7",attrs:{"show":_vm.formular.summelwflaeche7.showPop==true,"target":"summelwflaeche7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche7.size + 'px', 
														'text-align': _vm.formular.summelwflaeche7.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche7.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche7.schriftart }),attrs:{"id":"summelwflaeche7","precision":parseInt(_vm.formular.summelwflaeche7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche7.isDisabled==true || _vm.formular.summelwflaeche7.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche7.showPop=true},"blur":function($event){_vm.formular.summelwflaeche7.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche7.onylNumber)}},model:{value:(_vm.formular.summelwflaeche7.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche7, "text", $$v)},expression:"formular.summelwflaeche7.text"}}):_vm._e(),(_vm.formular.summelwflaeche8)?_c('b-popover',{ref:"summelwflaeche8",attrs:{"show":_vm.formular.summelwflaeche8.showPop==true,"target":"summelwflaeche8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summelwflaeche8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summelwflaeche8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summelwflaeche8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summelwflaeche8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.7448283) + 'px', 
		
														'font-size': _vm.formular.summelwflaeche8.size + 'px', 
														'text-align': _vm.formular.summelwflaeche8.schriftausrichtung, 
														'letter-spacing': _vm.formular.summelwflaeche8.letterSpacing + 'px',
														'font-weight': _vm.formular.summelwflaeche8.schriftart }),attrs:{"id":"summelwflaeche8","precision":parseInt(_vm.formular.summelwflaeche8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summelwflaeche8.isDisabled==true || _vm.formular.summelwflaeche8.isDisabled==true},on:{"focus":function($event){_vm.formular.summelwflaeche8.showPop=true},"blur":function($event){_vm.formular.summelwflaeche8.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summelwflaeche8.onylNumber)}},model:{value:(_vm.formular.summelwflaeche8.text),callback:function ($$v) {_vm.$set(_vm.formular.summelwflaeche8, "text", $$v)},expression:"formular.summelwflaeche8.text"}}):_vm._e(),(_vm.formular.fwflaeche1)?_c('b-popover',{ref:"fwflaeche1",attrs:{"show":_vm.formular.fwflaeche1.showPop==true,"target":"fwflaeche1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche1.size + 'px', 
														'text-align': _vm.formular.fwflaeche1.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche1.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche1.schriftart }),attrs:{"id":"fwflaeche1","precision":parseInt(_vm.formular.fwflaeche1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche1.isDisabled==true || _vm.formular.fwflaeche1.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche1.showPop=true},"blur":function($event){_vm.formular.fwflaeche1.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche1.onylNumber)}},model:{value:(_vm.formular.fwflaeche1.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche1, "text", $$v)},expression:"formular.fwflaeche1.text"}}):_vm._e(),(_vm.formular.fwflaeche2)?_c('b-popover',{ref:"fwflaeche2",attrs:{"show":_vm.formular.fwflaeche2.showPop==true,"target":"fwflaeche2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche2.size + 'px', 
														'text-align': _vm.formular.fwflaeche2.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche2.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche2.schriftart }),attrs:{"id":"fwflaeche2","precision":parseInt(_vm.formular.fwflaeche2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche2.isDisabled==true || _vm.formular.fwflaeche2.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche2.showPop=true},"blur":function($event){_vm.formular.fwflaeche2.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche2.onylNumber)}},model:{value:(_vm.formular.fwflaeche2.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche2, "text", $$v)},expression:"formular.fwflaeche2.text"}}):_vm._e(),(_vm.formular.fwflaeche3)?_c('b-popover',{ref:"fwflaeche3",attrs:{"show":_vm.formular.fwflaeche3.showPop==true,"target":"fwflaeche3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche3.size + 'px', 
														'text-align': _vm.formular.fwflaeche3.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche3.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche3.schriftart }),attrs:{"id":"fwflaeche3","precision":parseInt(_vm.formular.fwflaeche3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche3.isDisabled==true || _vm.formular.fwflaeche3.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche3.showPop=true},"blur":function($event){_vm.formular.fwflaeche3.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche3.onylNumber)}},model:{value:(_vm.formular.fwflaeche3.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche3, "text", $$v)},expression:"formular.fwflaeche3.text"}}):_vm._e(),(_vm.formular.fwflaeche4)?_c('b-popover',{ref:"fwflaeche4",attrs:{"show":_vm.formular.fwflaeche4.showPop==true,"target":"fwflaeche4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche4.size + 'px', 
														'text-align': _vm.formular.fwflaeche4.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche4.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche4.schriftart }),attrs:{"id":"fwflaeche4","precision":parseInt(_vm.formular.fwflaeche4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche4.isDisabled==true || _vm.formular.fwflaeche4.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche4.showPop=true},"blur":function($event){_vm.formular.fwflaeche4.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche4.onylNumber)}},model:{value:(_vm.formular.fwflaeche4.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche4, "text", $$v)},expression:"formular.fwflaeche4.text"}}):_vm._e(),(_vm.formular.fwflaeche5)?_c('b-popover',{ref:"fwflaeche5",attrs:{"show":_vm.formular.fwflaeche5.showPop==true,"target":"fwflaeche5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche5.size + 'px', 
														'text-align': _vm.formular.fwflaeche5.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche5.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche5.schriftart }),attrs:{"id":"fwflaeche5","precision":parseInt(_vm.formular.fwflaeche5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche5.isDisabled==true || _vm.formular.fwflaeche5.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche5.showPop=true},"blur":function($event){_vm.formular.fwflaeche5.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche5.onylNumber)}},model:{value:(_vm.formular.fwflaeche5.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche5, "text", $$v)},expression:"formular.fwflaeche5.text"}}):_vm._e(),(_vm.formular.fwflaeche6)?_c('b-popover',{ref:"fwflaeche6",attrs:{"show":_vm.formular.fwflaeche6.showPop==true,"target":"fwflaeche6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche6.size + 'px', 
														'text-align': _vm.formular.fwflaeche6.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche6.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche6.schriftart }),attrs:{"id":"fwflaeche6","precision":parseInt(_vm.formular.fwflaeche6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche6.isDisabled==true || _vm.formular.fwflaeche6.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche6.showPop=true},"blur":function($event){_vm.formular.fwflaeche6.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche6.onylNumber)}},model:{value:(_vm.formular.fwflaeche6.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche6, "text", $$v)},expression:"formular.fwflaeche6.text"}}):_vm._e(),(_vm.formular.fwflaeche7)?_c('b-popover',{ref:"fwflaeche7",attrs:{"show":_vm.formular.fwflaeche7.showPop==true,"target":"fwflaeche7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche7.size + 'px', 
														'text-align': _vm.formular.fwflaeche7.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche7.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche7.schriftart }),attrs:{"id":"fwflaeche7","precision":parseInt(_vm.formular.fwflaeche7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche7.isDisabled==true || _vm.formular.fwflaeche7.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche7.showPop=true},"blur":function($event){_vm.formular.fwflaeche7.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche7.onylNumber)}},model:{value:(_vm.formular.fwflaeche7.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche7, "text", $$v)},expression:"formular.fwflaeche7.text"}}):_vm._e(),(_vm.formular.fwflaeche8)?_c('b-popover',{ref:"fwflaeche8",attrs:{"show":_vm.formular.fwflaeche8.showPop==true,"target":"fwflaeche8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fwflaeche8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fwflaeche8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fwflaeche8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fwflaeche8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.2796908) + 'px', 
		
														'font-size': _vm.formular.fwflaeche8.size + 'px', 
														'text-align': _vm.formular.fwflaeche8.schriftausrichtung, 
														'letter-spacing': _vm.formular.fwflaeche8.letterSpacing + 'px',
														'font-weight': _vm.formular.fwflaeche8.schriftart }),attrs:{"id":"fwflaeche8","precision":parseInt(_vm.formular.fwflaeche8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.fwflaeche8.isDisabled==true || _vm.formular.fwflaeche8.isDisabled==true},on:{"focus":function($event){_vm.formular.fwflaeche8.showPop=true},"blur":function($event){_vm.formular.fwflaeche8.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.fwflaeche8.onylNumber)}},model:{value:(_vm.formular.fwflaeche8.text),callback:function ($$v) {_vm.$set(_vm.formular.fwflaeche8, "text", $$v)},expression:"formular.fwflaeche8.text"}}):_vm._e(),(_vm.formular.sonstflaeche1)?_c('b-popover',{ref:"sonstflaeche1",attrs:{"show":_vm.formular.sonstflaeche1.showPop==true,"target":"sonstflaeche1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche1.size + 'px', 
														'text-align': _vm.formular.sonstflaeche1.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche1.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche1.schriftart }),attrs:{"id":"sonstflaeche1","precision":parseInt(_vm.formular.sonstflaeche1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche1.isDisabled==true || _vm.formular.sonstflaeche1.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche1.showPop=true},"blur":function($event){_vm.formular.sonstflaeche1.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche1.onylNumber)}},model:{value:(_vm.formular.sonstflaeche1.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche1, "text", $$v)},expression:"formular.sonstflaeche1.text"}}):_vm._e(),(_vm.formular.sonstflaeche2)?_c('b-popover',{ref:"sonstflaeche2",attrs:{"show":_vm.formular.sonstflaeche2.showPop==true,"target":"sonstflaeche2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche2.size + 'px', 
														'text-align': _vm.formular.sonstflaeche2.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche2.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche2.schriftart }),attrs:{"id":"sonstflaeche2","precision":parseInt(_vm.formular.sonstflaeche2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche2.isDisabled==true || _vm.formular.sonstflaeche2.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche2.showPop=true},"blur":function($event){_vm.formular.sonstflaeche2.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche2.onylNumber)}},model:{value:(_vm.formular.sonstflaeche2.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche2, "text", $$v)},expression:"formular.sonstflaeche2.text"}}):_vm._e(),(_vm.formular.sonstflaeche3)?_c('b-popover',{ref:"sonstflaeche3",attrs:{"show":_vm.formular.sonstflaeche3.showPop==true,"target":"sonstflaeche3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche3.size + 'px', 
														'text-align': _vm.formular.sonstflaeche3.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche3.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche3.schriftart }),attrs:{"id":"sonstflaeche3","precision":parseInt(_vm.formular.sonstflaeche3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche3.isDisabled==true || _vm.formular.sonstflaeche3.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche3.showPop=true},"blur":function($event){_vm.formular.sonstflaeche3.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche3.onylNumber)}},model:{value:(_vm.formular.sonstflaeche3.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche3, "text", $$v)},expression:"formular.sonstflaeche3.text"}}):_vm._e(),(_vm.formular.sonstflaeche4)?_c('b-popover',{ref:"sonstflaeche4",attrs:{"show":_vm.formular.sonstflaeche4.showPop==true,"target":"sonstflaeche4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche4.size + 'px', 
														'text-align': _vm.formular.sonstflaeche4.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche4.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche4.schriftart }),attrs:{"id":"sonstflaeche4","precision":parseInt(_vm.formular.sonstflaeche4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche4.isDisabled==true || _vm.formular.sonstflaeche4.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche4.showPop=true},"blur":function($event){_vm.formular.sonstflaeche4.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche4.onylNumber)}},model:{value:(_vm.formular.sonstflaeche4.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche4, "text", $$v)},expression:"formular.sonstflaeche4.text"}}):_vm._e(),(_vm.formular.sonstflaeche5)?_c('b-popover',{ref:"sonstflaeche5",attrs:{"show":_vm.formular.sonstflaeche5.showPop==true,"target":"sonstflaeche5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche5.size + 'px', 
														'text-align': _vm.formular.sonstflaeche5.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche5.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche5.schriftart }),attrs:{"id":"sonstflaeche5","precision":parseInt(_vm.formular.sonstflaeche5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche5.isDisabled==true || _vm.formular.sonstflaeche5.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche5.showPop=true},"blur":function($event){_vm.formular.sonstflaeche5.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche5.onylNumber)}},model:{value:(_vm.formular.sonstflaeche5.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche5, "text", $$v)},expression:"formular.sonstflaeche5.text"}}):_vm._e(),(_vm.formular.sonstflaeche6)?_c('b-popover',{ref:"sonstflaeche6",attrs:{"show":_vm.formular.sonstflaeche6.showPop==true,"target":"sonstflaeche6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche6.size + 'px', 
														'text-align': _vm.formular.sonstflaeche6.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche6.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche6.schriftart }),attrs:{"id":"sonstflaeche6","precision":parseInt(_vm.formular.sonstflaeche6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche6.isDisabled==true || _vm.formular.sonstflaeche6.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche6.showPop=true},"blur":function($event){_vm.formular.sonstflaeche6.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche6.onylNumber)}},model:{value:(_vm.formular.sonstflaeche6.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche6, "text", $$v)},expression:"formular.sonstflaeche6.text"}}):_vm._e(),(_vm.formular.sonstflaeche7)?_c('b-popover',{ref:"sonstflaeche7",attrs:{"show":_vm.formular.sonstflaeche7.showPop==true,"target":"sonstflaeche7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche7.size + 'px', 
														'text-align': _vm.formular.sonstflaeche7.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche7.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche7.schriftart }),attrs:{"id":"sonstflaeche7","precision":parseInt(_vm.formular.sonstflaeche7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche7.isDisabled==true || _vm.formular.sonstflaeche7.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche7.showPop=true},"blur":function($event){_vm.formular.sonstflaeche7.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche7.onylNumber)}},model:{value:(_vm.formular.sonstflaeche7.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche7, "text", $$v)},expression:"formular.sonstflaeche7.text"}}):_vm._e(),(_vm.formular.sonstflaeche8)?_c('b-popover',{ref:"sonstflaeche8",attrs:{"show":_vm.formular.sonstflaeche8.showPop==true,"target":"sonstflaeche8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstflaeche8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstflaeche8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstflaeche8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstflaeche8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(213.7186482) + 'px', 
		
														'font-size': _vm.formular.sonstflaeche8.size + 'px', 
														'text-align': _vm.formular.sonstflaeche8.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonstflaeche8.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstflaeche8.schriftart }),attrs:{"id":"sonstflaeche8","precision":parseInt(_vm.formular.sonstflaeche8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonstflaeche8.isDisabled==true || _vm.formular.sonstflaeche8.isDisabled==true},on:{"focus":function($event){_vm.formular.sonstflaeche8.showPop=true},"blur":function($event){_vm.formular.sonstflaeche8.showPop=false},"input":_vm.betriebsfl_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonstflaeche8.onylNumber)}},model:{value:(_vm.formular.sonstflaeche8.text),callback:function ($$v) {_vm.$set(_vm.formular.sonstflaeche8, "text", $$v)},expression:"formular.sonstflaeche8.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche1)?_c('b-popover',{ref:"Betriebsflaeche1",attrs:{"show":_vm.formular.Betriebsflaeche1.showPop==true,"target":"Betriebsflaeche1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche1!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(56.7112741) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche1.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche1.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche1.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche1.schriftart }),attrs:{"id":"Betriebsflaeche1","precision":parseInt(_vm.formular.Betriebsflaeche1.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche1.isDisabled==true || _vm.formular.Betriebsflaeche1.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche1.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche1.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche1.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche1, "text", $$v)},expression:"formular.Betriebsflaeche1.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche3)?_c('b-popover',{ref:"Betriebsflaeche3",attrs:{"show":_vm.formular.Betriebsflaeche3.showPop==true,"target":"Betriebsflaeche3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(84.7403431) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche3.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche3.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche3.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche3.schriftart }),attrs:{"id":"Betriebsflaeche3","precision":parseInt(_vm.formular.Betriebsflaeche3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche3.isDisabled==true || _vm.formular.Betriebsflaeche3.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche3.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche3.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche3.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche3, "text", $$v)},expression:"formular.Betriebsflaeche3.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche5)?_c('b-popover',{ref:"Betriebsflaeche5",attrs:{"show":_vm.formular.Betriebsflaeche5.showPop==true,"target":"Betriebsflaeche5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(114.1629298) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche5.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche5.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche5.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche5.schriftart }),attrs:{"id":"Betriebsflaeche5","precision":parseInt(_vm.formular.Betriebsflaeche5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche5.isDisabled==true || _vm.formular.Betriebsflaeche5.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche5.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche5.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche5.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche5.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche5, "text", $$v)},expression:"formular.Betriebsflaeche5.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche7)?_c('b-popover',{ref:"Betriebsflaeche7",attrs:{"show":_vm.formular.Betriebsflaeche7.showPop==true,"target":"Betriebsflaeche7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.2545884) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche7.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche7.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche7.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche7.schriftart }),attrs:{"id":"Betriebsflaeche7","precision":parseInt(_vm.formular.Betriebsflaeche7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche7.isDisabled==true || _vm.formular.Betriebsflaeche7.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche7.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche7.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche7.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche7.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche7, "text", $$v)},expression:"formular.Betriebsflaeche7.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche2)?_c('b-popover',{ref:"Betriebsflaeche2",attrs:{"show":_vm.formular.Betriebsflaeche2.showPop==true,"target":"Betriebsflaeche2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche2!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(69.8422951) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche2.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche2.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche2.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche2.schriftart }),attrs:{"id":"Betriebsflaeche2","precision":parseInt(_vm.formular.Betriebsflaeche2.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche2.isDisabled==true || _vm.formular.Betriebsflaeche2.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche2.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche2.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche2.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche2, "text", $$v)},expression:"formular.Betriebsflaeche2.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche4)?_c('b-popover',{ref:"Betriebsflaeche4",attrs:{"show":_vm.formular.Betriebsflaeche4.showPop==true,"target":"Betriebsflaeche4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1279091) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(99.5960671) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche4.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche4.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche4.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche4.schriftart }),attrs:{"id":"Betriebsflaeche4","precision":parseInt(_vm.formular.Betriebsflaeche4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche4.isDisabled==true || _vm.formular.Betriebsflaeche4.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche4.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche4.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche4.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche4, "text", $$v)},expression:"formular.Betriebsflaeche4.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche6)?_c('b-popover',{ref:"Betriebsflaeche6",attrs:{"show":_vm.formular.Betriebsflaeche6.showPop==true,"target":"Betriebsflaeche6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(129.3988644) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche6.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche6.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche6.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche6.schriftart }),attrs:{"id":"Betriebsflaeche6","precision":parseInt(_vm.formular.Betriebsflaeche6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche6.isDisabled==true || _vm.formular.Betriebsflaeche6.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche6.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche6.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche6.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche6.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche6, "text", $$v)},expression:"formular.Betriebsflaeche6.text"}}):_vm._e(),(_vm.formular.Betriebsflaeche8)?_c('b-popover',{ref:"Betriebsflaeche8",attrs:{"show":_vm.formular.Betriebsflaeche8.showPop==true,"target":"Betriebsflaeche8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Betriebsflaeche8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Betriebsflaeche8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Betriebsflaeche8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Betriebsflaeche8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.1286145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.1632174) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(220.6181656) + 'px', 
		
														'font-size': _vm.formular.Betriebsflaeche8.size + 'px', 
														'text-align': _vm.formular.Betriebsflaeche8.schriftausrichtung, 
														'letter-spacing': _vm.formular.Betriebsflaeche8.letterSpacing + 'px',
														'font-weight': _vm.formular.Betriebsflaeche8.schriftart }),attrs:{"id":"Betriebsflaeche8","precision":parseInt(_vm.formular.Betriebsflaeche8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Betriebsflaeche8.isDisabled==true || _vm.formular.Betriebsflaeche8.isDisabled==true},on:{"focus":function($event){_vm.formular.Betriebsflaeche8.showPop=true},"blur":function($event){_vm.formular.Betriebsflaeche8.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Betriebsflaeche8.onylNumber)}},model:{value:(_vm.formular.Betriebsflaeche8.text),callback:function ($$v) {_vm.$set(_vm.formular.Betriebsflaeche8, "text", $$v)},expression:"formular.Betriebsflaeche8.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(20.0890866) + 'px', 
														top: _vm.gib_hoehe_in_mm(234.7190995) + 'px'})},[_vm._v(" Pachtdauer ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(56.3678086) + 'px', 
														top: _vm.gib_hoehe_in_mm(233.7590501) + 'px'})},[_vm._v(" bis 18 Jahre ")]),(_vm.formular.Pachtdauer1)?_c('b-popover',{ref:"Pachtdauer1",attrs:{"show":_vm.formular.Pachtdauer1.showPop==true,"target":"Pachtdauer1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer1.text),expression:"formular.Pachtdauer1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(234.2761204) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer1.size + 'px', 
														'text-align': _vm.formular.Pachtdauer1.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer1.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer1.schriftart }),attrs:{"id":"Pachtdauer1","type":"text","disabled":_vm.formular.Pachtdauer1.isDisabled==true || _vm.formular.Pachtdauer1.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer1.text)},on:{"focus":function($event){_vm.formular.Pachtdauer1.showPop=true},"blur":function($event){_vm.formular.Pachtdauer1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.Pachtdauer2)?_c('b-popover',{ref:"Pachtdauer2",attrs:{"show":_vm.formular.Pachtdauer2.showPop==true,"target":"Pachtdauer2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer2.text),expression:"formular.Pachtdauer2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(234.2761204) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer2.size + 'px', 
														'text-align': _vm.formular.Pachtdauer2.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer2.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer2.schriftart }),attrs:{"id":"Pachtdauer2","type":"text","disabled":_vm.formular.Pachtdauer2.isDisabled==true || _vm.formular.Pachtdauer2.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer2.text)},on:{"focus":function($event){_vm.formular.Pachtdauer2.showPop=true},"blur":function($event){_vm.formular.Pachtdauer2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer2, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(108.0986703) + 'px', 
														top: _vm.gib_hoehe_in_mm(234.0595505) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(115.2461358) + 'px', 
														top: _vm.gib_hoehe_in_mm(233.7590501) + 'px'})},[_vm._v(" bis 18 Jahre ")]),(_vm.formular.Pachtdauer5)?_c('b-popover',{ref:"Pachtdauer5",attrs:{"show":_vm.formular.Pachtdauer5.showPop==true,"target":"Pachtdauer5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer5!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer5.text),expression:"formular.Pachtdauer5.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(243.4181044) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer5.size + 'px', 
														'text-align': _vm.formular.Pachtdauer5.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer5.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer5.schriftart }),attrs:{"id":"Pachtdauer5","type":"text","disabled":_vm.formular.Pachtdauer5.isDisabled==true || _vm.formular.Pachtdauer5.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer5.text)},on:{"focus":function($event){_vm.formular.Pachtdauer5.showPop=true},"blur":function($event){_vm.formular.Pachtdauer5.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer5.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer5, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.Pachtdauer3)?_c('b-popover',{ref:"Pachtdauer3",attrs:{"show":_vm.formular.Pachtdauer3.showPop==true,"target":"Pachtdauer3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer3!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer3.text),expression:"formular.Pachtdauer3.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(243.4181044) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer3.size + 'px', 
														'text-align': _vm.formular.Pachtdauer3.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer3.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer3.schriftart }),attrs:{"id":"Pachtdauer3","type":"text","disabled":_vm.formular.Pachtdauer3.isDisabled==true || _vm.formular.Pachtdauer3.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer3.text)},on:{"focus":function($event){_vm.formular.Pachtdauer3.showPop=true},"blur":function($event){_vm.formular.Pachtdauer3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer3.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer3, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(56.3678086) + 'px', 
														top: _vm.gib_hoehe_in_mm(242.9010341) + 'px'})},[_vm._v(" über 18 Jahre ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(56.3678086) + 'px', 
														top: _vm.gib_hoehe_in_mm(247.9799141) + 'px'})},[_vm._v(" Verwandschafts ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(56.3678086) + 'px', 
														top: _vm.gib_hoehe_in_mm(252.5932301) + 'px'})},[_vm._v(" pacht ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(108.0986703) + 'px', 
														top: _vm.gib_hoehe_in_mm(243.2015345) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(115.2461358) + 'px', 
														top: _vm.gib_hoehe_in_mm(242.9010341) + 'px'})},[_vm._v(" über 18 Jahre ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(113.8617883) + 'px', 
														top: _vm.gib_hoehe_in_mm(247.9799141) + 'px'})},[_vm._v(" Verwandschafts ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(108.3949383) + 'px', 
														top: _vm.gib_hoehe_in_mm(252.8937305) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(113.8617883) + 'px', 
														top: _vm.gib_hoehe_in_mm(252.5932301) + 'px'})},[_vm._v(" pacht ")]),(_vm.formular.Pachtdauer6)?_c('b-popover',{ref:"Pachtdauer6",attrs:{"show":_vm.formular.Pachtdauer6.showPop==true,"target":"Pachtdauer6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer6!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer6.text),expression:"formular.Pachtdauer6.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6496848) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(90.2287078) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(252.5812504) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer6.size + 'px', 
														'text-align': _vm.formular.Pachtdauer6.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer6.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer6.schriftart }),attrs:{"id":"Pachtdauer6","type":"text","disabled":_vm.formular.Pachtdauer6.isDisabled==true || _vm.formular.Pachtdauer6.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer6.text)},on:{"focus":function($event){_vm.formular.Pachtdauer6.showPop=true},"blur":function($event){_vm.formular.Pachtdauer6.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer6.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer6, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.Pachtdauer4)?_c('b-popover',{ref:"Pachtdauer4",attrs:{"show":_vm.formular.Pachtdauer4.showPop==true,"target":"Pachtdauer4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Pachtdauer4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Pachtdauer4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Pachtdauer4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Pachtdauer4!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.Pachtdauer4.text),expression:"formular.Pachtdauer4.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.6493321) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.7700155) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(252.5812504) + 'px', 
		
														'font-size': _vm.formular.Pachtdauer4.size + 'px', 
														'text-align': _vm.formular.Pachtdauer4.schriftausrichtung, 
														'letter-spacing': _vm.formular.Pachtdauer4.letterSpacing + 'px',
														'font-weight': _vm.formular.Pachtdauer4.schriftart }),attrs:{"id":"Pachtdauer4","type":"text","disabled":_vm.formular.Pachtdauer4.isDisabled==true || _vm.formular.Pachtdauer4.isDisabled==true},domProps:{"value":(_vm.formular.Pachtdauer4.text)},on:{"focus":function($event){_vm.formular.Pachtdauer4.showPop=true},"blur":function($event){_vm.formular.Pachtdauer4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Pachtdauer4.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.Pachtdauer4, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(168.9358933) + 'px', 
														top: _vm.gib_hoehe_in_mm(234.0595505) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(168.9358933) + 'px', 
														top: _vm.gib_hoehe_in_mm(243.2015345) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(168.9358933) + 'px', 
														top: _vm.gib_hoehe_in_mm(252.8937305) + 'px'})},[_vm._v(" ha ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(172.6604053) + 'px', 
														top: _vm.gib_hoehe_in_mm(257.8706802) + 'px'})},[_vm._v(" Fortsetzung Blatt 2 ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(202.7594706) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(244.3877794) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(170.2475846) + 'px', 
													width: _vm.spalte_breit_mm(32.511886) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(263.1206961) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(170.2475846) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(5.8567694) + 'px', 
													top: _vm.gib_hoehe_in_mm(257.3639267) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(257.3639267) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(238.63101) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(37.1253495) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(61.7155935) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(71.2811702) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(78.7725182) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(147.576734) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(86.1788655) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.5453577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(147.576734) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(100.8762272) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(108.3245458) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(147.576734) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(115.7319512) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(123.1382985) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(147.576734) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(130.5026745) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(137.9093745) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(147.576734) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(145.2737505) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(183.813132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.8500992) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1404126) + 'px', 
													width: _vm.spalte_breit_mm(117.4346393) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(162.6678564) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(169.3211892) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(175.9237332) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(189.5520612) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(202.8421499) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(212.5339932) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(219.4324525) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(18.9463386) + 'px', 
													width: _vm.spalte_breit_mm(153.6287133) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(229.3810614) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1404126) + 'px', 
													width: _vm.spalte_breit_mm(117.4346393) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(238.5311575) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1404126) + 'px', 
													width: _vm.spalte_breit_mm(117.4346393) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(247.7161709) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(106.8285976) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(18.3924328) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(106.8285976) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(24.690244) + 'px', 
													top: _vm.gib_hoehe_in_mm(37.1253495) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(55.1827366) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(178.6935247) + 'px', 
													top: _vm.gib_hoehe_in_mm(78.7725182) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(68.3137576) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(112.6343923) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(104.6159437) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.8500992) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(68.3137576) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(83.2118056) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(98.0675296) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(127.8710323) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(142.7267563) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(157.6353853) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(70.9172222) + 'px', 
													top: _vm.gib_hoehe_in_mm(158.5638392) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(172.5750519) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(104.6138275) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.8500992) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(205.4558621) + 'px', 
													width: _vm.spalte_breit_mm(4.6260132) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(0.084795499999984) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(210.0818753) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(2.6750627) + 'px', 
													top: _vm.gib_hoehe_in_mm(0.084795499999984) + 'px'})})],1),_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4910691) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0091903) + 'px'})},[_vm._v(" Betriebsbeschreibung Blatt 2 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(60.8594431) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0229456) + 'px'})},[_vm._v(" Bauherrschaft ")]),(_vm.formular.bauherr_name)?_c('b-popover',{ref:"bauherr_name",attrs:{"show":_vm.formular.bauherr_name.showPop==true,"target":"bauherr_name"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_name.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_name.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_name!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_name.text),expression:"formular.bauherr_name.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': _vm.formular.bauherr_name.size + 'px', 
														'text-align': _vm.formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauherr_name.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_name.schriftart }),attrs:{"id":"bauherr_name","type":"text","disabled":_vm.formular.bauherr_name.isDisabled==true || _vm.formular.bauherr_name.isDisabled==true},domProps:{"value":(_vm.formular.bauherr_name.text)},on:{"focus":function($event){_vm.formular.bauherr_name.showPop=true},"blur":function($event){_vm.formular.bauherr_name.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauherr_name.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_name, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(67.2930438) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(23.1420578) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" 2 Bodennutzung (ha) ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(85.4606208) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" Ziel ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" Bodennutzung (ha) ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(137.1452788) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(158.5718038) + 'px', 
														top: _vm.gib_hoehe_in_mm(29.199046) + 'px'})},[_vm._v(" Ziel ")]),(_vm.formular.getreideIst)?_c('b-popover',{ref:"getreideIst",attrs:{"show":_vm.formular.getreideIst.showPop==true,"target":"getreideIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.getreideIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.getreideIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("getreideIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("getreideIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.getreideIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': _vm.formular.getreideIst.size + 'px', 
														'text-align': _vm.formular.getreideIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.getreideIst.letterSpacing + 'px',
														'font-weight': _vm.formular.getreideIst.schriftart }),attrs:{"id":"getreideIst","precision":parseInt(_vm.formular.getreideIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.getreideIst.isDisabled==true || _vm.formular.getreideIst.isDisabled==true},on:{"focus":function($event){_vm.formular.getreideIst.showPop=true},"blur":function($event){_vm.formular.getreideIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.getreideIst.onylNumber)}},model:{value:(_vm.formular.getreideIst.text),callback:function ($$v) {_vm.$set(_vm.formular.getreideIst, "text", $$v)},expression:"formular.getreideIst.text"}}):_vm._e(),(_vm.formular.getreideZiel)?_c('b-popover',{ref:"getreideZiel",attrs:{"show":_vm.formular.getreideZiel.showPop==true,"target":"getreideZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.getreideZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.getreideZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("getreideZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("getreideZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.getreideZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': _vm.formular.getreideZiel.size + 'px', 
														'text-align': _vm.formular.getreideZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.getreideZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.getreideZiel.schriftart }),attrs:{"id":"getreideZiel","precision":parseInt(_vm.formular.getreideZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.getreideZiel.isDisabled==true || _vm.formular.getreideZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.getreideZiel.showPop=true},"blur":function($event){_vm.formular.getreideZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.getreideZiel.onylNumber)}},model:{value:(_vm.formular.getreideZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.getreideZiel, "text", $$v)},expression:"formular.getreideZiel.text"}}):_vm._e(),(_vm.formular.obstIst)?_c('b-popover',{ref:"obstIst",attrs:{"show":_vm.formular.obstIst.showPop==true,"target":"obstIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.obstIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.obstIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("obstIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("obstIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.obstIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': _vm.formular.obstIst.size + 'px', 
														'text-align': _vm.formular.obstIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.obstIst.letterSpacing + 'px',
														'font-weight': _vm.formular.obstIst.schriftart }),attrs:{"id":"obstIst","precision":parseInt(_vm.formular.obstIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.obstIst.isDisabled==true || _vm.formular.obstIst.isDisabled==true},on:{"focus":function($event){_vm.formular.obstIst.showPop=true},"blur":function($event){_vm.formular.obstIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.obstIst.onylNumber)}},model:{value:(_vm.formular.obstIst.text),callback:function ($$v) {_vm.$set(_vm.formular.obstIst, "text", $$v)},expression:"formular.obstIst.text"}}):_vm._e(),(_vm.formular.obstZiel)?_c('b-popover',{ref:"obstZiel",attrs:{"show":_vm.formular.obstZiel.showPop==true,"target":"obstZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.obstZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.obstZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("obstZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("obstZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.obstZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.7080411) + 'px', 
		
														'font-size': _vm.formular.obstZiel.size + 'px', 
														'text-align': _vm.formular.obstZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.obstZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.obstZiel.schriftart }),attrs:{"id":"obstZiel","precision":parseInt(_vm.formular.obstZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.obstZiel.isDisabled==true || _vm.formular.obstZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.obstZiel.showPop=true},"blur":function($event){_vm.formular.obstZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.obstZiel.onylNumber)}},model:{value:(_vm.formular.obstZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.obstZiel, "text", $$v)},expression:"formular.obstZiel.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(166.4020965) + 'px', 
														top: _vm.gib_hoehe_in_mm(9.1857899) + 'px'})},[_vm._v(" Anlage I/9 zu VV BauPrüfVO ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(196.0288965) + 'px', 
														top: _vm.gib_hoehe_in_mm(12.4870619) + 'px'})},[_vm._v(" Blatt 2 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.2901365) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0229456) + 'px'})},[_vm._v(" Antrag vom ")]),(_vm.formular.bauantragVom)?_c('b-popover',{ref:"bauantragVom",attrs:{"show":_vm.formular.bauantragVom.showPop==true,"target":"bauantragVom"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauantragVom.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauantragVom.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauantragVom!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauantragVom.text),expression:"formular.bauantragVom.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(27.3340259) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(175.5404892) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': _vm.formular.bauantragVom.size + 'px', 
														'text-align': _vm.formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauantragVom.letterSpacing + 'px',
														'font-weight': _vm.formular.bauantragVom.schriftart }),attrs:{"id":"bauantragVom","type":"text","disabled":_vm.formular.bauantragVom.isDisabled==true || _vm.formular.bauantragVom.isDisabled==true},domProps:{"value":(_vm.formular.bauantragVom.text)},on:{"focus":function($event){_vm.formular.bauantragVom.showPop=true},"blur":function($event){_vm.formular.bauantragVom.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauantragVom.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauantragVom, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.2901365) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.7836609) + 'px'})},[_vm._v(" Prüfvermerke ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(35.9289147) + 'px'})},[_vm._v(" Obst, Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(43.2509667) + 'px'})},[_vm._v(" Gemüse, Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(50.5730187) + 'px'})},[_vm._v(" Sonstige, Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(57.894718) + 'px'})},[_vm._v(" Sonstige, Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(65.3864187) + 'px'})},[_vm._v(" Summe der ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(69.0262827) + 'px'})},[_vm._v(" Bodennutzung ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(74.612698) + 'px'})},[_vm._v(" davon unter Glas ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(87.6491954) + 'px'})},[_vm._v(" Tierhaltung ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(91.2890594) + 'px'})},[_vm._v(" (Anzahl) ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(96.6455143) + 'px'})},[_vm._v(" Mastgeflügel, Art ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(105.1526383) + 'px'})},[_vm._v(" davon Käfighaltung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(111.2472943) + 'px'})},[_vm._v(" Legehennen in ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(114.5062423) + 'px'})},[_vm._v(" Käfighaltung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(119.4158263) + 'px'})},[_vm._v(" Legehennen in ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(122.6747743) + 'px'})},[_vm._v(" Bodenhaltung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(128.8114016) + 'px'})},[_vm._v(" Legehennen freilaufen ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(134.9060576) + 'px'})},[_vm._v(" Sonstige Tiere, Art ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(142.2284623) + 'px'})},[_vm._v(" Sonstige Tiere, Art ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(149.7617816) + 'px'})},[_vm._v(" Hauptfutterfläche ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(157.3381303) + 'px'})},[_vm._v(" Zusatzfutterfläche ")]),(_vm.formular.OelfruechteIst)?_c('b-popover',{ref:"OelfruechteIst",attrs:{"show":_vm.formular.OelfruechteIst.showPop==true,"target":"OelfruechteIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.OelfruechteIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.OelfruechteIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("OelfruechteIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("OelfruechteIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.OelfruechteIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': _vm.formular.OelfruechteIst.size + 'px', 
														'text-align': _vm.formular.OelfruechteIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.OelfruechteIst.letterSpacing + 'px',
														'font-weight': _vm.formular.OelfruechteIst.schriftart }),attrs:{"id":"OelfruechteIst","precision":parseInt(_vm.formular.OelfruechteIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.OelfruechteIst.isDisabled==true || _vm.formular.OelfruechteIst.isDisabled==true},on:{"focus":function($event){_vm.formular.OelfruechteIst.showPop=true},"blur":function($event){_vm.formular.OelfruechteIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.OelfruechteIst.onylNumber)}},model:{value:(_vm.formular.OelfruechteIst.text),callback:function ($$v) {_vm.$set(_vm.formular.OelfruechteIst, "text", $$v)},expression:"formular.OelfruechteIst.text"}}):_vm._e(),(_vm.formular.OelfruechteZiel)?_c('b-popover',{ref:"OelfruechteZiel",attrs:{"show":_vm.formular.OelfruechteZiel.showPop==true,"target":"OelfruechteZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.OelfruechteZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.OelfruechteZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("OelfruechteZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("OelfruechteZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.OelfruechteZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': _vm.formular.OelfruechteZiel.size + 'px', 
														'text-align': _vm.formular.OelfruechteZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.OelfruechteZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.OelfruechteZiel.schriftart }),attrs:{"id":"OelfruechteZiel","precision":parseInt(_vm.formular.OelfruechteZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.OelfruechteZiel.isDisabled==true || _vm.formular.OelfruechteZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.OelfruechteZiel.showPop=true},"blur":function($event){_vm.formular.OelfruechteZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.OelfruechteZiel.onylNumber)}},model:{value:(_vm.formular.OelfruechteZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.OelfruechteZiel, "text", $$v)},expression:"formular.OelfruechteZiel.text"}}):_vm._e(),(_vm.formular.gemuseIst)?_c('b-popover',{ref:"gemuseIst",attrs:{"show":_vm.formular.gemuseIst.showPop==true,"target":"gemuseIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gemuseIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gemuseIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gemuseIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gemuseIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gemuseIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': _vm.formular.gemuseIst.size + 'px', 
														'text-align': _vm.formular.gemuseIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.gemuseIst.letterSpacing + 'px',
														'font-weight': _vm.formular.gemuseIst.schriftart }),attrs:{"id":"gemuseIst","precision":parseInt(_vm.formular.gemuseIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gemuseIst.isDisabled==true || _vm.formular.gemuseIst.isDisabled==true},on:{"focus":function($event){_vm.formular.gemuseIst.showPop=true},"blur":function($event){_vm.formular.gemuseIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gemuseIst.onylNumber)}},model:{value:(_vm.formular.gemuseIst.text),callback:function ($$v) {_vm.$set(_vm.formular.gemuseIst, "text", $$v)},expression:"formular.gemuseIst.text"}}):_vm._e(),(_vm.formular.gemuseZiel)?_c('b-popover',{ref:"gemuseZiel",attrs:{"show":_vm.formular.gemuseZiel.showPop==true,"target":"gemuseZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.gemuseZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.gemuseZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gemuseZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("gemuseZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.gemuseZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.9895326) + 'px', 
		
														'font-size': _vm.formular.gemuseZiel.size + 'px', 
														'text-align': _vm.formular.gemuseZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.gemuseZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.gemuseZiel.schriftart }),attrs:{"id":"gemuseZiel","precision":parseInt(_vm.formular.gemuseZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.gemuseZiel.isDisabled==true || _vm.formular.gemuseZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.gemuseZiel.showPop=true},"blur":function($event){_vm.formular.gemuseZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.gemuseZiel.onylNumber)}},model:{value:(_vm.formular.gemuseZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.gemuseZiel, "text", $$v)},expression:"formular.gemuseZiel.text"}}):_vm._e(),(_vm.formular.kartoffelnIst)?_c('b-popover',{ref:"kartoffelnIst",attrs:{"show":_vm.formular.kartoffelnIst.showPop==true,"target":"kartoffelnIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kartoffelnIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kartoffelnIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kartoffelnIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kartoffelnIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kartoffelnIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': _vm.formular.kartoffelnIst.size + 'px', 
														'text-align': _vm.formular.kartoffelnIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.kartoffelnIst.letterSpacing + 'px',
														'font-weight': _vm.formular.kartoffelnIst.schriftart }),attrs:{"id":"kartoffelnIst","precision":parseInt(_vm.formular.kartoffelnIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.kartoffelnIst.isDisabled==true || _vm.formular.kartoffelnIst.isDisabled==true},on:{"focus":function($event){_vm.formular.kartoffelnIst.showPop=true},"blur":function($event){_vm.formular.kartoffelnIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.kartoffelnIst.onylNumber)}},model:{value:(_vm.formular.kartoffelnIst.text),callback:function ($$v) {_vm.$set(_vm.formular.kartoffelnIst, "text", $$v)},expression:"formular.kartoffelnIst.text"}}):_vm._e(),(_vm.formular.kartoffelnZiel)?_c('b-popover',{ref:"kartoffelnZiel",attrs:{"show":_vm.formular.kartoffelnZiel.showPop==true,"target":"kartoffelnZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kartoffelnZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kartoffelnZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kartoffelnZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kartoffelnZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kartoffelnZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': _vm.formular.kartoffelnZiel.size + 'px', 
														'text-align': _vm.formular.kartoffelnZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.kartoffelnZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.kartoffelnZiel.schriftart }),attrs:{"id":"kartoffelnZiel","precision":parseInt(_vm.formular.kartoffelnZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.kartoffelnZiel.isDisabled==true || _vm.formular.kartoffelnZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.kartoffelnZiel.showPop=true},"blur":function($event){_vm.formular.kartoffelnZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.kartoffelnZiel.onylNumber)}},model:{value:(_vm.formular.kartoffelnZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.kartoffelnZiel, "text", $$v)},expression:"formular.kartoffelnZiel.text"}}):_vm._e(),(_vm.formular.sonst1Ist)?_c('b-popover',{ref:"sonst1Ist",attrs:{"show":_vm.formular.sonst1Ist.showPop==true,"target":"sonst1Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonst1Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonst1Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst1Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst1Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonst1Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': _vm.formular.sonst1Ist.size + 'px', 
														'text-align': _vm.formular.sonst1Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonst1Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.sonst1Ist.schriftart }),attrs:{"id":"sonst1Ist","precision":parseInt(_vm.formular.sonst1Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonst1Ist.isDisabled==true || _vm.formular.sonst1Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.sonst1Ist.showPop=true},"blur":function($event){_vm.formular.sonst1Ist.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonst1Ist.onylNumber)}},model:{value:(_vm.formular.sonst1Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.sonst1Ist, "text", $$v)},expression:"formular.sonst1Ist.text"}}):_vm._e(),(_vm.formular.sonst1Ziel)?_c('b-popover',{ref:"sonst1Ziel",attrs:{"show":_vm.formular.sonst1Ziel.showPop==true,"target":"sonst1Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonst1Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonst1Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst1Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst1Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonst1Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.1405251) + 'px', 
		
														'font-size': _vm.formular.sonst1Ziel.size + 'px', 
														'text-align': _vm.formular.sonst1Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonst1Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.sonst1Ziel.schriftart }),attrs:{"id":"sonst1Ziel","precision":parseInt(_vm.formular.sonst1Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonst1Ziel.isDisabled==true || _vm.formular.sonst1Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.sonst1Ziel.showPop=true},"blur":function($event){_vm.formular.sonst1Ziel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonst1Ziel.onylNumber)}},model:{value:(_vm.formular.sonst1Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.sonst1Ziel, "text", $$v)},expression:"formular.sonst1Ziel.text"}}):_vm._e(),(_vm.formular.zuckIst)?_c('b-popover',{ref:"zuckIst",attrs:{"show":_vm.formular.zuckIst.showPop==true,"target":"zuckIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuckIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuckIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuckIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuckIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuckIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': _vm.formular.zuckIst.size + 'px', 
														'text-align': _vm.formular.zuckIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuckIst.letterSpacing + 'px',
														'font-weight': _vm.formular.zuckIst.schriftart }),attrs:{"id":"zuckIst","precision":parseInt(_vm.formular.zuckIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.zuckIst.isDisabled==true || _vm.formular.zuckIst.isDisabled==true},on:{"focus":function($event){_vm.formular.zuckIst.showPop=true},"blur":function($event){_vm.formular.zuckIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuckIst.onylNumber)}},model:{value:(_vm.formular.zuckIst.text),callback:function ($$v) {_vm.$set(_vm.formular.zuckIst, "text", $$v)},expression:"formular.zuckIst.text"}}):_vm._e(),(_vm.formular.zuckZiel)?_c('b-popover',{ref:"zuckZiel",attrs:{"show":_vm.formular.zuckZiel.showPop==true,"target":"zuckZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuckZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuckZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuckZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuckZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuckZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': _vm.formular.zuckZiel.size + 'px', 
														'text-align': _vm.formular.zuckZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuckZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.zuckZiel.schriftart }),attrs:{"id":"zuckZiel","precision":parseInt(_vm.formular.zuckZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.zuckZiel.isDisabled==true || _vm.formular.zuckZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.zuckZiel.showPop=true},"blur":function($event){_vm.formular.zuckZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuckZiel.onylNumber)}},model:{value:(_vm.formular.zuckZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.zuckZiel, "text", $$v)},expression:"formular.zuckZiel.text"}}):_vm._e(),(_vm.formular.sonst2Ist)?_c('b-popover',{ref:"sonst2Ist",attrs:{"show":_vm.formular.sonst2Ist.showPop==true,"target":"sonst2Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonst2Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonst2Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst2Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst2Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonst2Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': _vm.formular.sonst2Ist.size + 'px', 
														'text-align': _vm.formular.sonst2Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonst2Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.sonst2Ist.schriftart }),attrs:{"id":"sonst2Ist","precision":parseInt(_vm.formular.sonst2Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonst2Ist.isDisabled==true || _vm.formular.sonst2Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.sonst2Ist.showPop=true},"blur":function($event){_vm.formular.sonst2Ist.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonst2Ist.onylNumber)}},model:{value:(_vm.formular.sonst2Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.sonst2Ist, "text", $$v)},expression:"formular.sonst2Ist.text"}}):_vm._e(),(_vm.formular.sonst2Ziel)?_c('b-popover',{ref:"sonst2Ziel",attrs:{"show":_vm.formular.sonst2Ziel.showPop==true,"target":"sonst2Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonst2Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonst2Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst2Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonst2Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonst2Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.2915176) + 'px', 
		
														'font-size': _vm.formular.sonst2Ziel.size + 'px', 
														'text-align': _vm.formular.sonst2Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.sonst2Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.sonst2Ziel.schriftart }),attrs:{"id":"sonst2Ziel","precision":parseInt(_vm.formular.sonst2Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.sonst2Ziel.isDisabled==true || _vm.formular.sonst2Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.sonst2Ziel.showPop=true},"blur":function($event){_vm.formular.sonst2Ziel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.sonst2Ziel.onylNumber)}},model:{value:(_vm.formular.sonst2Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.sonst2Ziel, "text", $$v)},expression:"formular.sonst2Ziel.text"}}):_vm._e(),(_vm.formular.akfuIst)?_c('b-popover',{ref:"akfuIst",attrs:{"show":_vm.formular.akfuIst.showPop==true,"target":"akfuIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.akfuIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.akfuIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("akfuIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("akfuIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.akfuIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': _vm.formular.akfuIst.size + 'px', 
														'text-align': _vm.formular.akfuIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.akfuIst.letterSpacing + 'px',
														'font-weight': _vm.formular.akfuIst.schriftart }),attrs:{"id":"akfuIst","precision":parseInt(_vm.formular.akfuIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.akfuIst.isDisabled==true || _vm.formular.akfuIst.isDisabled==true},on:{"focus":function($event){_vm.formular.akfuIst.showPop=true},"blur":function($event){_vm.formular.akfuIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.akfuIst.onylNumber)}},model:{value:(_vm.formular.akfuIst.text),callback:function ($$v) {_vm.$set(_vm.formular.akfuIst, "text", $$v)},expression:"formular.akfuIst.text"}}):_vm._e(),(_vm.formular.akfuZiel)?_c('b-popover',{ref:"akfuZiel",attrs:{"show":_vm.formular.akfuZiel.showPop==true,"target":"akfuZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.akfuZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.akfuZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("akfuZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("akfuZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.akfuZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': _vm.formular.akfuZiel.size + 'px', 
														'text-align': _vm.formular.akfuZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.akfuZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.akfuZiel.schriftart }),attrs:{"id":"akfuZiel","precision":parseInt(_vm.formular.akfuZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.akfuZiel.isDisabled==true || _vm.formular.akfuZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.akfuZiel.showPop=true},"blur":function($event){_vm.formular.akfuZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.akfuZiel.onylNumber)}},model:{value:(_vm.formular.akfuZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.akfuZiel, "text", $$v)},expression:"formular.akfuZiel.text"}}):_vm._e(),(_vm.formular.summeBodennutzungIst)?_c('b-popover',{ref:"summeBodennutzungIst",attrs:{"show":_vm.formular.summeBodennutzungIst.showPop==true,"target":"summeBodennutzungIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summeBodennutzungIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summeBodennutzungIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeBodennutzungIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeBodennutzungIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summeBodennutzungIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': _vm.formular.summeBodennutzungIst.size + 'px', 
														'text-align': _vm.formular.summeBodennutzungIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.summeBodennutzungIst.letterSpacing + 'px',
														'font-weight': _vm.formular.summeBodennutzungIst.schriftart }),attrs:{"id":"summeBodennutzungIst","precision":parseInt(_vm.formular.summeBodennutzungIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summeBodennutzungIst.isDisabled==true || _vm.formular.summeBodennutzungIst.isDisabled==true},on:{"focus":function($event){_vm.formular.summeBodennutzungIst.showPop=true},"blur":function($event){_vm.formular.summeBodennutzungIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summeBodennutzungIst.onylNumber)}},model:{value:(_vm.formular.summeBodennutzungIst.text),callback:function ($$v) {_vm.$set(_vm.formular.summeBodennutzungIst, "text", $$v)},expression:"formular.summeBodennutzungIst.text"}}):_vm._e(),(_vm.formular.summeBodennutzungZiel)?_c('b-popover',{ref:"summeBodennutzungZiel",attrs:{"show":_vm.formular.summeBodennutzungZiel.showPop==true,"target":"summeBodennutzungZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summeBodennutzungZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summeBodennutzungZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeBodennutzungZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeBodennutzungZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summeBodennutzungZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.6982176) + 'px', 
		
														'font-size': _vm.formular.summeBodennutzungZiel.size + 'px', 
														'text-align': _vm.formular.summeBodennutzungZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.summeBodennutzungZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.summeBodennutzungZiel.schriftart }),attrs:{"id":"summeBodennutzungZiel","precision":parseInt(_vm.formular.summeBodennutzungZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summeBodennutzungZiel.isDisabled==true || _vm.formular.summeBodennutzungZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.summeBodennutzungZiel.showPop=true},"blur":function($event){_vm.formular.summeBodennutzungZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summeBodennutzungZiel.onylNumber)}},model:{value:(_vm.formular.summeBodennutzungZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.summeBodennutzungZiel, "text", $$v)},expression:"formular.summeBodennutzungZiel.text"}}):_vm._e(),(_vm.formular.BodennutzungSelbstIst_check!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(130.7123835) + 'px',top: _vm.gib_hoehe_in_mm(69.8721337) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},on:{"input":_vm.bodennut_selbst},model:{value:(_vm.formular.BodennutzungSelbstIst_check.text),callback:function ($$v) {_vm.$set(_vm.formular.BodennutzungSelbstIst_check, "text", $$v)},expression:"formular.BodennutzungSelbstIst_check.text"}},[_vm._v(" selbst eing. ")]):_vm._e(),(_vm.formular.BodennutzungSelbstZiel_check!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(149.7680591) + 'px',top: _vm.gib_hoehe_in_mm(69.8721337) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},on:{"input":_vm.bodennut_selbst},model:{value:(_vm.formular.BodennutzungSelbstZiel_check.text),callback:function ($$v) {_vm.$set(_vm.formular.BodennutzungSelbstZiel_check, "text", $$v)},expression:"formular.BodennutzungSelbstZiel_check.text"}},[_vm._v(" selbst eing. ")]):_vm._e(),(_vm.formular.weideIst)?_c('b-popover',{ref:"weideIst",attrs:{"show":_vm.formular.weideIst.showPop==true,"target":"weideIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.weideIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.weideIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("weideIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("weideIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.weideIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': _vm.formular.weideIst.size + 'px', 
														'text-align': _vm.formular.weideIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.weideIst.letterSpacing + 'px',
														'font-weight': _vm.formular.weideIst.schriftart }),attrs:{"id":"weideIst","precision":parseInt(_vm.formular.weideIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.weideIst.isDisabled==true || _vm.formular.weideIst.isDisabled==true},on:{"focus":function($event){_vm.formular.weideIst.showPop=true},"blur":function($event){_vm.formular.weideIst.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.weideIst.onylNumber)}},model:{value:(_vm.formular.weideIst.text),callback:function ($$v) {_vm.$set(_vm.formular.weideIst, "text", $$v)},expression:"formular.weideIst.text"}}):_vm._e(),(_vm.formular.weideZiel)?_c('b-popover',{ref:"weideZiel",attrs:{"show":_vm.formular.weideZiel.showPop==true,"target":"weideZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.weideZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.weideZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("weideZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("weideZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.weideZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': _vm.formular.weideZiel.size + 'px', 
														'text-align': _vm.formular.weideZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.weideZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.weideZiel.schriftart }),attrs:{"id":"weideZiel","precision":parseInt(_vm.formular.weideZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.weideZiel.isDisabled==true || _vm.formular.weideZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.weideZiel.showPop=true},"blur":function($event){_vm.formular.weideZiel.showPop=false},"input":_vm.bodennut_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.weideZiel.onylNumber)}},model:{value:(_vm.formular.weideZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.weideZiel, "text", $$v)},expression:"formular.weideZiel.text"}}):_vm._e(),(_vm.formular.summeGlasIst)?_c('b-popover',{ref:"summeGlasIst",attrs:{"show":_vm.formular.summeGlasIst.showPop==true,"target":"summeGlasIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summeGlasIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summeGlasIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeGlasIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeGlasIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summeGlasIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': _vm.formular.summeGlasIst.size + 'px', 
														'text-align': _vm.formular.summeGlasIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.summeGlasIst.letterSpacing + 'px',
														'font-weight': _vm.formular.summeGlasIst.schriftart }),attrs:{"id":"summeGlasIst","precision":parseInt(_vm.formular.summeGlasIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summeGlasIst.isDisabled==true || _vm.formular.summeGlasIst.isDisabled==true},on:{"focus":function($event){_vm.formular.summeGlasIst.showPop=true},"blur":function($event){_vm.formular.summeGlasIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summeGlasIst.onylNumber)}},model:{value:(_vm.formular.summeGlasIst.text),callback:function ($$v) {_vm.$set(_vm.formular.summeGlasIst, "text", $$v)},expression:"formular.summeGlasIst.text"}}):_vm._e(),(_vm.formular.summeGlasZiel)?_c('b-popover',{ref:"summeGlasZiel",attrs:{"show":_vm.formular.summeGlasZiel.showPop==true,"target":"summeGlasZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.summeGlasZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.summeGlasZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeGlasZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("summeGlasZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.summeGlasZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(22.8790722) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(74.9707006) + 'px', 
		
														'font-size': _vm.formular.summeGlasZiel.size + 'px', 
														'text-align': _vm.formular.summeGlasZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.summeGlasZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.summeGlasZiel.schriftart }),attrs:{"id":"summeGlasZiel","precision":parseInt(_vm.formular.summeGlasZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.summeGlasZiel.isDisabled==true || _vm.formular.summeGlasZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.summeGlasZiel.showPop=true},"blur":function($event){_vm.formular.summeGlasZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.summeGlasZiel.onylNumber)}},model:{value:(_vm.formular.summeGlasZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.summeGlasZiel, "text", $$v)},expression:"formular.summeGlasZiel.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(67.2930438) + 'px', 
														top: _vm.gib_hoehe_in_mm(89.5110987) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(85.4606208) + 'px', 
														top: _vm.gib_hoehe_in_mm(89.5110987) + 'px'})},[_vm._v(" Ziel ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(137.1452788) + 'px', 
														top: _vm.gib_hoehe_in_mm(89.5110987) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(158.5718038) + 'px', 
														top: _vm.gib_hoehe_in_mm(89.5110987) + 'px'})},[_vm._v(" Ziel ")]),(_vm.formular.MiKuIst)?_c('b-popover',{ref:"MiKuIst",attrs:{"show":_vm.formular.MiKuIst.showPop==true,"target":"MiKuIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MiKuIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MiKuIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MiKuIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': _vm.formular.MiKuIst.size + 'px', 
														'text-align': _vm.formular.MiKuIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.MiKuIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MiKuIst.schriftart }),attrs:{"id":"MiKuIst","precision":parseInt(_vm.formular.MiKuIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MiKuIst.isDisabled==true || _vm.formular.MiKuIst.isDisabled==true},on:{"focus":function($event){_vm.formular.MiKuIst.showPop=true},"blur":function($event){_vm.formular.MiKuIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MiKuIst.onylNumber)}},model:{value:(_vm.formular.MiKuIst.text),callback:function ($$v) {_vm.$set(_vm.formular.MiKuIst, "text", $$v)},expression:"formular.MiKuIst.text"}}):_vm._e(),(_vm.formular.MiKuZiel)?_c('b-popover',{ref:"MiKuZiel",attrs:{"show":_vm.formular.MiKuZiel.showPop==true,"target":"MiKuZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MiKuZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MiKuZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MiKuZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': _vm.formular.MiKuZiel.size + 'px', 
														'text-align': _vm.formular.MiKuZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.MiKuZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MiKuZiel.schriftart }),attrs:{"id":"MiKuZiel","precision":parseInt(_vm.formular.MiKuZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MiKuZiel.isDisabled==true || _vm.formular.MiKuZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.MiKuZiel.showPop=true},"blur":function($event){_vm.formular.MiKuZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MiKuZiel.onylNumber)}},model:{value:(_vm.formular.MiKuZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.MiKuZiel, "text", $$v)},expression:"formular.MiKuZiel.text"}}):_vm._e(),(_vm.formular.GeflIst)?_c('b-popover',{ref:"GeflIst",attrs:{"show":_vm.formular.GeflIst.showPop==true,"target":"GeflIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.GeflIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.GeflIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("GeflIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("GeflIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.GeflIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': _vm.formular.GeflIst.size + 'px', 
														'text-align': _vm.formular.GeflIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.GeflIst.letterSpacing + 'px',
														'font-weight': _vm.formular.GeflIst.schriftart }),attrs:{"id":"GeflIst","precision":parseInt(_vm.formular.GeflIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.GeflIst.isDisabled==true || _vm.formular.GeflIst.isDisabled==true},on:{"focus":function($event){_vm.formular.GeflIst.showPop=true},"blur":function($event){_vm.formular.GeflIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.GeflIst.onylNumber)}},model:{value:(_vm.formular.GeflIst.text),callback:function ($$v) {_vm.$set(_vm.formular.GeflIst, "text", $$v)},expression:"formular.GeflIst.text"}}):_vm._e(),(_vm.formular.GeflZiel)?_c('b-popover',{ref:"GeflZiel",attrs:{"show":_vm.formular.GeflZiel.showPop==true,"target":"GeflZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.GeflZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.GeflZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("GeflZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("GeflZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.GeflZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(97.3308225) + 'px', 
		
														'font-size': _vm.formular.GeflZiel.size + 'px', 
														'text-align': _vm.formular.GeflZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.GeflZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.GeflZiel.schriftart }),attrs:{"id":"GeflZiel","precision":parseInt(_vm.formular.GeflZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.GeflZiel.isDisabled==true || _vm.formular.GeflZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.GeflZiel.showPop=true},"blur":function($event){_vm.formular.GeflZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.GeflZiel.onylNumber)}},model:{value:(_vm.formular.GeflZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.GeflZiel, "text", $$v)},expression:"formular.GeflZiel.text"}}):_vm._e(),(_vm.formular.RindIst)?_c('b-popover',{ref:"RindIst",attrs:{"show":_vm.formular.RindIst.showPop==true,"target":"RindIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.RindIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.RindIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("RindIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("RindIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.RindIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': _vm.formular.RindIst.size + 'px', 
														'text-align': _vm.formular.RindIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.RindIst.letterSpacing + 'px',
														'font-weight': _vm.formular.RindIst.schriftart }),attrs:{"id":"RindIst","precision":parseInt(_vm.formular.RindIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.RindIst.isDisabled==true || _vm.formular.RindIst.isDisabled==true},on:{"focus":function($event){_vm.formular.RindIst.showPop=true},"blur":function($event){_vm.formular.RindIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.RindIst.onylNumber)}},model:{value:(_vm.formular.RindIst.text),callback:function ($$v) {_vm.$set(_vm.formular.RindIst, "text", $$v)},expression:"formular.RindIst.text"}}):_vm._e(),(_vm.formular.RindZiel)?_c('b-popover',{ref:"RindZiel",attrs:{"show":_vm.formular.RindZiel.showPop==true,"target":"RindZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.RindZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.RindZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("RindZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("RindZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.RindZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': _vm.formular.RindZiel.size + 'px', 
														'text-align': _vm.formular.RindZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.RindZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.RindZiel.schriftart }),attrs:{"id":"RindZiel","precision":parseInt(_vm.formular.RindZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.RindZiel.isDisabled==true || _vm.formular.RindZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.RindZiel.showPop=true},"blur":function($event){_vm.formular.RindZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.RindZiel.onylNumber)}},model:{value:(_vm.formular.RindZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.RindZiel, "text", $$v)},expression:"formular.RindZiel.text"}}):_vm._e(),(_vm.formular.KaefigIst)?_c('b-popover',{ref:"KaefigIst",attrs:{"show":_vm.formular.KaefigIst.showPop==true,"target":"KaefigIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.KaefigIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.KaefigIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("KaefigIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("KaefigIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.KaefigIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': _vm.formular.KaefigIst.size + 'px', 
														'text-align': _vm.formular.KaefigIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.KaefigIst.letterSpacing + 'px',
														'font-weight': _vm.formular.KaefigIst.schriftart }),attrs:{"id":"KaefigIst","precision":parseInt(_vm.formular.KaefigIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.KaefigIst.isDisabled==true || _vm.formular.KaefigIst.isDisabled==true},on:{"focus":function($event){_vm.formular.KaefigIst.showPop=true},"blur":function($event){_vm.formular.KaefigIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.KaefigIst.onylNumber)}},model:{value:(_vm.formular.KaefigIst.text),callback:function ($$v) {_vm.$set(_vm.formular.KaefigIst, "text", $$v)},expression:"formular.KaefigIst.text"}}):_vm._e(),(_vm.formular.KaefigZiel)?_c('b-popover',{ref:"KaefigZiel",attrs:{"show":_vm.formular.KaefigZiel.showPop==true,"target":"KaefigZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.KaefigZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.KaefigZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("KaefigZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("KaefigZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.KaefigZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(104.7445765) + 'px', 
		
														'font-size': _vm.formular.KaefigZiel.size + 'px', 
														'text-align': _vm.formular.KaefigZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.KaefigZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.KaefigZiel.schriftart }),attrs:{"id":"KaefigZiel","precision":parseInt(_vm.formular.KaefigZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.KaefigZiel.isDisabled==true || _vm.formular.KaefigZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.KaefigZiel.showPop=true},"blur":function($event){_vm.formular.KaefigZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.KaefigZiel.onylNumber)}},model:{value:(_vm.formular.KaefigZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.KaefigZiel, "text", $$v)},expression:"formular.KaefigZiel.text"}}):_vm._e(),(_vm.formular.MiKuIst)?_c('b-popover',{ref:"MiKuIst",attrs:{"show":_vm.formular.MiKuIst.showPop==true,"target":"MiKuIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MiKuIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MiKuIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MiKuIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': _vm.formular.MiKuIst.size + 'px', 
														'text-align': _vm.formular.MiKuIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.MiKuIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MiKuIst.schriftart }),attrs:{"id":"MiKuIst","precision":parseInt(_vm.formular.MiKuIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MiKuIst.isDisabled==true || _vm.formular.MiKuIst.isDisabled==true},on:{"focus":function($event){_vm.formular.MiKuIst.showPop=true},"blur":function($event){_vm.formular.MiKuIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MiKuIst.onylNumber)}},model:{value:(_vm.formular.MiKuIst.text),callback:function ($$v) {_vm.$set(_vm.formular.MiKuIst, "text", $$v)},expression:"formular.MiKuIst.text"}}):_vm._e(),(_vm.formular.MiKuZiel)?_c('b-popover',{ref:"MiKuZiel",attrs:{"show":_vm.formular.MiKuZiel.showPop==true,"target":"MiKuZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MiKuZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MiKuZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MiKuZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MiKuZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': _vm.formular.MiKuZiel.size + 'px', 
														'text-align': _vm.formular.MiKuZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.MiKuZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MiKuZiel.schriftart }),attrs:{"id":"MiKuZiel","precision":parseInt(_vm.formular.MiKuZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MiKuZiel.isDisabled==true || _vm.formular.MiKuZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.MiKuZiel.showPop=true},"blur":function($event){_vm.formular.MiKuZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MiKuZiel.onylNumber)}},model:{value:(_vm.formular.MiKuZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.MiKuZiel, "text", $$v)},expression:"formular.MiKuZiel.text"}}):_vm._e(),(_vm.formular.LegeKaeIst)?_c('b-popover',{ref:"LegeKaeIst",attrs:{"show":_vm.formular.LegeKaeIst.showPop==true,"target":"LegeKaeIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeKaeIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeKaeIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeKaeIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeKaeIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeKaeIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': _vm.formular.LegeKaeIst.size + 'px', 
														'text-align': _vm.formular.LegeKaeIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeKaeIst.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeKaeIst.schriftart }),attrs:{"id":"LegeKaeIst","precision":parseInt(_vm.formular.LegeKaeIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeKaeIst.isDisabled==true || _vm.formular.LegeKaeIst.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeKaeIst.showPop=true},"blur":function($event){_vm.formular.LegeKaeIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeKaeIst.onylNumber)}},model:{value:(_vm.formular.LegeKaeIst.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeKaeIst, "text", $$v)},expression:"formular.LegeKaeIst.text"}}):_vm._e(),(_vm.formular.LegeKaeZiel)?_c('b-popover',{ref:"LegeKaeZiel",attrs:{"show":_vm.formular.LegeKaeZiel.showPop==true,"target":"LegeKaeZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeKaeZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeKaeZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeKaeZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeKaeZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeKaeZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(111.9848021) + 'px', 
		
														'font-size': _vm.formular.LegeKaeZiel.size + 'px', 
														'text-align': _vm.formular.LegeKaeZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeKaeZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeKaeZiel.schriftart }),attrs:{"id":"LegeKaeZiel","precision":parseInt(_vm.formular.LegeKaeZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeKaeZiel.isDisabled==true || _vm.formular.LegeKaeZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeKaeZiel.showPop=true},"blur":function($event){_vm.formular.LegeKaeZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeKaeZiel.onylNumber)}},model:{value:(_vm.formular.LegeKaeZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeKaeZiel, "text", $$v)},expression:"formular.LegeKaeZiel.text"}}):_vm._e(),(_vm.formular.MastIst)?_c('b-popover',{ref:"MastIst",attrs:{"show":_vm.formular.MastIst.showPop==true,"target":"MastIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MastIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MastIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MastIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MastIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MastIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': _vm.formular.MastIst.size + 'px', 
														'text-align': _vm.formular.MastIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.MastIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MastIst.schriftart }),attrs:{"id":"MastIst","precision":parseInt(_vm.formular.MastIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MastIst.isDisabled==true || _vm.formular.MastIst.isDisabled==true},on:{"focus":function($event){_vm.formular.MastIst.showPop=true},"blur":function($event){_vm.formular.MastIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MastIst.onylNumber)}},model:{value:(_vm.formular.MastIst.text),callback:function ($$v) {_vm.$set(_vm.formular.MastIst, "text", $$v)},expression:"formular.MastIst.text"}}):_vm._e(),(_vm.formular.MastZiel)?_c('b-popover',{ref:"MastZiel",attrs:{"show":_vm.formular.MastZiel.showPop==true,"target":"MastZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MastZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MastZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MastZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MastZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MastZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': _vm.formular.MastZiel.size + 'px', 
														'text-align': _vm.formular.MastZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.MastZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MastZiel.schriftart }),attrs:{"id":"MastZiel","precision":parseInt(_vm.formular.MastZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MastZiel.isDisabled==true || _vm.formular.MastZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.MastZiel.showPop=true},"blur":function($event){_vm.formular.MastZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MastZiel.onylNumber)}},model:{value:(_vm.formular.MastZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.MastZiel, "text", $$v)},expression:"formular.MastZiel.text"}}):_vm._e(),(_vm.formular.LegeBodIst)?_c('b-popover',{ref:"LegeBodIst",attrs:{"show":_vm.formular.LegeBodIst.showPop==true,"target":"LegeBodIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeBodIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeBodIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeBodIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeBodIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeBodIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': _vm.formular.LegeBodIst.size + 'px', 
														'text-align': _vm.formular.LegeBodIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeBodIst.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeBodIst.schriftart }),attrs:{"id":"LegeBodIst","precision":parseInt(_vm.formular.LegeBodIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeBodIst.isDisabled==true || _vm.formular.LegeBodIst.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeBodIst.showPop=true},"blur":function($event){_vm.formular.LegeBodIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeBodIst.onylNumber)}},model:{value:(_vm.formular.LegeBodIst.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeBodIst, "text", $$v)},expression:"formular.LegeBodIst.text"}}):_vm._e(),(_vm.formular.LegeBodZiel)?_c('b-popover',{ref:"LegeBodZiel",attrs:{"show":_vm.formular.LegeBodZiel.showPop==true,"target":"LegeBodZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeBodZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeBodZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeBodZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeBodZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeBodZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.2379821) + 'px', 
		
														'font-size': _vm.formular.LegeBodZiel.size + 'px', 
														'text-align': _vm.formular.LegeBodZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeBodZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeBodZiel.schriftart }),attrs:{"id":"LegeBodZiel","precision":parseInt(_vm.formular.LegeBodZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeBodZiel.isDisabled==true || _vm.formular.LegeBodZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeBodZiel.showPop=true},"blur":function($event){_vm.formular.LegeBodZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeBodZiel.onylNumber)}},model:{value:(_vm.formular.LegeBodZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeBodZiel, "text", $$v)},expression:"formular.LegeBodZiel.text"}}):_vm._e(),(_vm.formular.ZiSIst)?_c('b-popover',{ref:"ZiSIst",attrs:{"show":_vm.formular.ZiSIst.showPop==true,"target":"ZiSIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZiSIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZiSIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZiSIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZiSIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZiSIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': _vm.formular.ZiSIst.size + 'px', 
														'text-align': _vm.formular.ZiSIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZiSIst.letterSpacing + 'px',
														'font-weight': _vm.formular.ZiSIst.schriftart }),attrs:{"id":"ZiSIst","precision":parseInt(_vm.formular.ZiSIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZiSIst.isDisabled==true || _vm.formular.ZiSIst.isDisabled==true},on:{"focus":function($event){_vm.formular.ZiSIst.showPop=true},"blur":function($event){_vm.formular.ZiSIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZiSIst.onylNumber)}},model:{value:(_vm.formular.ZiSIst.text),callback:function ($$v) {_vm.$set(_vm.formular.ZiSIst, "text", $$v)},expression:"formular.ZiSIst.text"}}):_vm._e(),(_vm.formular.ZiSZiel)?_c('b-popover',{ref:"ZiSZiel",attrs:{"show":_vm.formular.ZiSZiel.showPop==true,"target":"ZiSZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZiSZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZiSZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZiSZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZiSZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZiSZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': _vm.formular.ZiSZiel.size + 'px', 
														'text-align': _vm.formular.ZiSZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZiSZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.ZiSZiel.schriftart }),attrs:{"id":"ZiSZiel","precision":parseInt(_vm.formular.ZiSZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZiSZiel.isDisabled==true || _vm.formular.ZiSZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.ZiSZiel.showPop=true},"blur":function($event){_vm.formular.ZiSZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZiSZiel.onylNumber)}},model:{value:(_vm.formular.ZiSZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.ZiSZiel, "text", $$v)},expression:"formular.ZiSZiel.text"}}):_vm._e(),(_vm.formular.LegeFreiIst)?_c('b-popover',{ref:"LegeFreiIst",attrs:{"show":_vm.formular.LegeFreiIst.showPop==true,"target":"LegeFreiIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeFreiIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeFreiIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeFreiIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeFreiIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeFreiIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': _vm.formular.LegeFreiIst.size + 'px', 
														'text-align': _vm.formular.LegeFreiIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeFreiIst.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeFreiIst.schriftart }),attrs:{"id":"LegeFreiIst","precision":parseInt(_vm.formular.LegeFreiIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeFreiIst.isDisabled==true || _vm.formular.LegeFreiIst.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeFreiIst.showPop=true},"blur":function($event){_vm.formular.LegeFreiIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeFreiIst.onylNumber)}},model:{value:(_vm.formular.LegeFreiIst.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeFreiIst, "text", $$v)},expression:"formular.LegeFreiIst.text"}}):_vm._e(),(_vm.formular.LegeFreiZiel)?_c('b-popover',{ref:"LegeFreiZiel",attrs:{"show":_vm.formular.LegeFreiZiel.showPop==true,"target":"LegeFreiZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.LegeFreiZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.LegeFreiZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeFreiZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("LegeFreiZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.LegeFreiZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(128.3193972) + 'px', 
		
														'font-size': _vm.formular.LegeFreiZiel.size + 'px', 
														'text-align': _vm.formular.LegeFreiZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.LegeFreiZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.LegeFreiZiel.schriftart }),attrs:{"id":"LegeFreiZiel","precision":parseInt(_vm.formular.LegeFreiZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.LegeFreiZiel.isDisabled==true || _vm.formular.LegeFreiZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.LegeFreiZiel.showPop=true},"blur":function($event){_vm.formular.LegeFreiZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.LegeFreiZiel.onylNumber)}},model:{value:(_vm.formular.LegeFreiZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.LegeFreiZiel, "text", $$v)},expression:"formular.LegeFreiZiel.text"}}):_vm._e(),(_vm.formular.ArbeitIst)?_c('b-popover',{ref:"ArbeitIst",attrs:{"show":_vm.formular.ArbeitIst.showPop==true,"target":"ArbeitIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ArbeitIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ArbeitIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ArbeitIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ArbeitIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ArbeitIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': _vm.formular.ArbeitIst.size + 'px', 
														'text-align': _vm.formular.ArbeitIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.ArbeitIst.letterSpacing + 'px',
														'font-weight': _vm.formular.ArbeitIst.schriftart }),attrs:{"id":"ArbeitIst","precision":parseInt(_vm.formular.ArbeitIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ArbeitIst.isDisabled==true || _vm.formular.ArbeitIst.isDisabled==true},on:{"focus":function($event){_vm.formular.ArbeitIst.showPop=true},"blur":function($event){_vm.formular.ArbeitIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ArbeitIst.onylNumber)}},model:{value:(_vm.formular.ArbeitIst.text),callback:function ($$v) {_vm.$set(_vm.formular.ArbeitIst, "text", $$v)},expression:"formular.ArbeitIst.text"}}):_vm._e(),(_vm.formular.ArbeitZiel)?_c('b-popover',{ref:"ArbeitZiel",attrs:{"show":_vm.formular.ArbeitZiel.showPop==true,"target":"ArbeitZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ArbeitZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ArbeitZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ArbeitZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ArbeitZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ArbeitZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': _vm.formular.ArbeitZiel.size + 'px', 
														'text-align': _vm.formular.ArbeitZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.ArbeitZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.ArbeitZiel.schriftart }),attrs:{"id":"ArbeitZiel","precision":parseInt(_vm.formular.ArbeitZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ArbeitZiel.isDisabled==true || _vm.formular.ArbeitZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.ArbeitZiel.showPop=true},"blur":function($event){_vm.formular.ArbeitZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ArbeitZiel.onylNumber)}},model:{value:(_vm.formular.ArbeitZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.ArbeitZiel, "text", $$v)},expression:"formular.ArbeitZiel.text"}}):_vm._e(),(_vm.formular.SonstTier1Ist)?_c('b-popover',{ref:"SonstTier1Ist",attrs:{"show":_vm.formular.SonstTier1Ist.showPop==true,"target":"SonstTier1Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.SonstTier1Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.SonstTier1Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier1Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier1Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.SonstTier1Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': _vm.formular.SonstTier1Ist.size + 'px', 
														'text-align': _vm.formular.SonstTier1Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.SonstTier1Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.SonstTier1Ist.schriftart }),attrs:{"id":"SonstTier1Ist","precision":parseInt(_vm.formular.SonstTier1Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.SonstTier1Ist.isDisabled==true || _vm.formular.SonstTier1Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.SonstTier1Ist.showPop=true},"blur":function($event){_vm.formular.SonstTier1Ist.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.SonstTier1Ist.onylNumber)}},model:{value:(_vm.formular.SonstTier1Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.SonstTier1Ist, "text", $$v)},expression:"formular.SonstTier1Ist.text"}}):_vm._e(),(_vm.formular.SonstTier1Ziel)?_c('b-popover',{ref:"SonstTier1Ziel",attrs:{"show":_vm.formular.SonstTier1Ziel.showPop==true,"target":"SonstTier1Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.SonstTier1Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.SonstTier1Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier1Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier1Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.SonstTier1Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(135.7257445) + 'px', 
		
														'font-size': _vm.formular.SonstTier1Ziel.size + 'px', 
														'text-align': _vm.formular.SonstTier1Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.SonstTier1Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.SonstTier1Ziel.schriftart }),attrs:{"id":"SonstTier1Ziel","precision":parseInt(_vm.formular.SonstTier1Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.SonstTier1Ziel.isDisabled==true || _vm.formular.SonstTier1Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.SonstTier1Ziel.showPop=true},"blur":function($event){_vm.formular.SonstTier1Ziel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.SonstTier1Ziel.onylNumber)}},model:{value:(_vm.formular.SonstTier1Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.SonstTier1Ziel, "text", $$v)},expression:"formular.SonstTier1Ziel.text"}}):_vm._e(),(_vm.formular.ZuchtIst)?_c('b-popover',{ref:"ZuchtIst",attrs:{"show":_vm.formular.ZuchtIst.showPop==true,"target":"ZuchtIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZuchtIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZuchtIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZuchtIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZuchtIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZuchtIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': _vm.formular.ZuchtIst.size + 'px', 
														'text-align': _vm.formular.ZuchtIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZuchtIst.letterSpacing + 'px',
														'font-weight': _vm.formular.ZuchtIst.schriftart }),attrs:{"id":"ZuchtIst","precision":parseInt(_vm.formular.ZuchtIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZuchtIst.isDisabled==true || _vm.formular.ZuchtIst.isDisabled==true},on:{"focus":function($event){_vm.formular.ZuchtIst.showPop=true},"blur":function($event){_vm.formular.ZuchtIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZuchtIst.onylNumber)}},model:{value:(_vm.formular.ZuchtIst.text),callback:function ($$v) {_vm.$set(_vm.formular.ZuchtIst, "text", $$v)},expression:"formular.ZuchtIst.text"}}):_vm._e(),(_vm.formular.ZuchtZiel)?_c('b-popover',{ref:"ZuchtZiel",attrs:{"show":_vm.formular.ZuchtZiel.showPop==true,"target":"ZuchtZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZuchtZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZuchtZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZuchtZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZuchtZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZuchtZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': _vm.formular.ZuchtZiel.size + 'px', 
														'text-align': _vm.formular.ZuchtZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZuchtZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.ZuchtZiel.schriftart }),attrs:{"id":"ZuchtZiel","precision":parseInt(_vm.formular.ZuchtZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZuchtZiel.isDisabled==true || _vm.formular.ZuchtZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.ZuchtZiel.showPop=true},"blur":function($event){_vm.formular.ZuchtZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZuchtZiel.onylNumber)}},model:{value:(_vm.formular.ZuchtZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.ZuchtZiel, "text", $$v)},expression:"formular.ZuchtZiel.text"}}):_vm._e(),(_vm.formular.SonstTier2Ist)?_c('b-popover',{ref:"SonstTier2Ist",attrs:{"show":_vm.formular.SonstTier2Ist.showPop==true,"target":"SonstTier2Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.SonstTier2Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.SonstTier2Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier2Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier2Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.SonstTier2Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': _vm.formular.SonstTier2Ist.size + 'px', 
														'text-align': _vm.formular.SonstTier2Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.SonstTier2Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.SonstTier2Ist.schriftart }),attrs:{"id":"SonstTier2Ist","precision":parseInt(_vm.formular.SonstTier2Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.SonstTier2Ist.isDisabled==true || _vm.formular.SonstTier2Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.SonstTier2Ist.showPop=true},"blur":function($event){_vm.formular.SonstTier2Ist.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.SonstTier2Ist.onylNumber)}},model:{value:(_vm.formular.SonstTier2Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.SonstTier2Ist, "text", $$v)},expression:"formular.SonstTier2Ist.text"}}):_vm._e(),(_vm.formular.SonstTier2Ziel)?_c('b-popover',{ref:"SonstTier2Ziel",attrs:{"show":_vm.formular.SonstTier2Ziel.showPop==true,"target":"SonstTier2Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.SonstTier2Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.SonstTier2Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier2Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("SonstTier2Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.SonstTier2Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(142.9663228) + 'px', 
		
														'font-size': _vm.formular.SonstTier2Ziel.size + 'px', 
														'text-align': _vm.formular.SonstTier2Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.SonstTier2Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.SonstTier2Ziel.schriftart }),attrs:{"id":"SonstTier2Ziel","precision":parseInt(_vm.formular.SonstTier2Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.SonstTier2Ziel.isDisabled==true || _vm.formular.SonstTier2Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.SonstTier2Ziel.showPop=true},"blur":function($event){_vm.formular.SonstTier2Ziel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.SonstTier2Ziel.onylNumber)}},model:{value:(_vm.formular.SonstTier2Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.SonstTier2Ziel, "text", $$v)},expression:"formular.SonstTier2Ziel.text"}}):_vm._e(),(_vm.formular.ReitIst)?_c('b-popover',{ref:"ReitIst",attrs:{"show":_vm.formular.ReitIst.showPop==true,"target":"ReitIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ReitIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ReitIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ReitIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ReitIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ReitIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': _vm.formular.ReitIst.size + 'px', 
														'text-align': _vm.formular.ReitIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.ReitIst.letterSpacing + 'px',
														'font-weight': _vm.formular.ReitIst.schriftart }),attrs:{"id":"ReitIst","precision":parseInt(_vm.formular.ReitIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ReitIst.isDisabled==true || _vm.formular.ReitIst.isDisabled==true},on:{"focus":function($event){_vm.formular.ReitIst.showPop=true},"blur":function($event){_vm.formular.ReitIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ReitIst.onylNumber)}},model:{value:(_vm.formular.ReitIst.text),callback:function ($$v) {_vm.$set(_vm.formular.ReitIst, "text", $$v)},expression:"formular.ReitIst.text"}}):_vm._e(),(_vm.formular.ReitZiel)?_c('b-popover',{ref:"ReitZiel",attrs:{"show":_vm.formular.ReitZiel.showPop==true,"target":"ReitZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ReitZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ReitZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ReitZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ReitZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ReitZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': _vm.formular.ReitZiel.size + 'px', 
														'text-align': _vm.formular.ReitZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.ReitZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.ReitZiel.schriftart }),attrs:{"id":"ReitZiel","precision":parseInt(_vm.formular.ReitZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ReitZiel.isDisabled==true || _vm.formular.ReitZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.ReitZiel.showPop=true},"blur":function($event){_vm.formular.ReitZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ReitZiel.onylNumber)}},model:{value:(_vm.formular.ReitZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.ReitZiel, "text", $$v)},expression:"formular.ReitZiel.text"}}):_vm._e(),(_vm.formular.HauptfutterIst)?_c('b-popover',{ref:"HauptfutterIst",attrs:{"show":_vm.formular.HauptfutterIst.showPop==true,"target":"HauptfutterIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.HauptfutterIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.HauptfutterIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("HauptfutterIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("HauptfutterIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.HauptfutterIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': _vm.formular.HauptfutterIst.size + 'px', 
														'text-align': _vm.formular.HauptfutterIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.HauptfutterIst.letterSpacing + 'px',
														'font-weight': _vm.formular.HauptfutterIst.schriftart }),attrs:{"id":"HauptfutterIst","precision":parseInt(_vm.formular.HauptfutterIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.HauptfutterIst.isDisabled==true || _vm.formular.HauptfutterIst.isDisabled==true},on:{"focus":function($event){_vm.formular.HauptfutterIst.showPop=true},"blur":function($event){_vm.formular.HauptfutterIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.HauptfutterIst.onylNumber)}},model:{value:(_vm.formular.HauptfutterIst.text),callback:function ($$v) {_vm.$set(_vm.formular.HauptfutterIst, "text", $$v)},expression:"formular.HauptfutterIst.text"}}):_vm._e(),(_vm.formular.HauptfutterZiel)?_c('b-popover',{ref:"HauptfutterZiel",attrs:{"show":_vm.formular.HauptfutterZiel.showPop==true,"target":"HauptfutterZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.HauptfutterZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.HauptfutterZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("HauptfutterZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("HauptfutterZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.HauptfutterZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.3733755) + 'px', 
		
														'font-size': _vm.formular.HauptfutterZiel.size + 'px', 
														'text-align': _vm.formular.HauptfutterZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.HauptfutterZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.HauptfutterZiel.schriftart }),attrs:{"id":"HauptfutterZiel","precision":parseInt(_vm.formular.HauptfutterZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.HauptfutterZiel.isDisabled==true || _vm.formular.HauptfutterZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.HauptfutterZiel.showPop=true},"blur":function($event){_vm.formular.HauptfutterZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.HauptfutterZiel.onylNumber)}},model:{value:(_vm.formular.HauptfutterZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.HauptfutterZiel, "text", $$v)},expression:"formular.HauptfutterZiel.text"}}):_vm._e(),(_vm.formular.PenIst)?_c('b-popover',{ref:"PenIst",attrs:{"show":_vm.formular.PenIst.showPop==true,"target":"PenIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.PenIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.PenIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("PenIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("PenIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.PenIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': _vm.formular.PenIst.size + 'px', 
														'text-align': _vm.formular.PenIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.PenIst.letterSpacing + 'px',
														'font-weight': _vm.formular.PenIst.schriftart }),attrs:{"id":"PenIst","precision":parseInt(_vm.formular.PenIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.PenIst.isDisabled==true || _vm.formular.PenIst.isDisabled==true},on:{"focus":function($event){_vm.formular.PenIst.showPop=true},"blur":function($event){_vm.formular.PenIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.PenIst.onylNumber)}},model:{value:(_vm.formular.PenIst.text),callback:function ($$v) {_vm.$set(_vm.formular.PenIst, "text", $$v)},expression:"formular.PenIst.text"}}):_vm._e(),(_vm.formular.PenZiel)?_c('b-popover',{ref:"PenZiel",attrs:{"show":_vm.formular.PenZiel.showPop==true,"target":"PenZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.PenZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.PenZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("PenZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("PenZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.PenZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.1658241) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': _vm.formular.PenZiel.size + 'px', 
														'text-align': _vm.formular.PenZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.PenZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.PenZiel.schriftart }),attrs:{"id":"PenZiel","precision":parseInt(_vm.formular.PenZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.PenZiel.isDisabled==true || _vm.formular.PenZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.PenZiel.showPop=true},"blur":function($event){_vm.formular.PenZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.PenZiel.onylNumber)}},model:{value:(_vm.formular.PenZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.PenZiel, "text", $$v)},expression:"formular.PenZiel.text"}}):_vm._e(),(_vm.formular.ZusatzfutterIst)?_c('b-popover',{ref:"ZusatzfutterIst",attrs:{"show":_vm.formular.ZusatzfutterIst.showPop==true,"target":"ZusatzfutterIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZusatzfutterIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZusatzfutterIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZusatzfutterIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZusatzfutterIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZusatzfutterIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': _vm.formular.ZusatzfutterIst.size + 'px', 
														'text-align': _vm.formular.ZusatzfutterIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZusatzfutterIst.letterSpacing + 'px',
														'font-weight': _vm.formular.ZusatzfutterIst.schriftart }),attrs:{"id":"ZusatzfutterIst","precision":parseInt(_vm.formular.ZusatzfutterIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZusatzfutterIst.isDisabled==true || _vm.formular.ZusatzfutterIst.isDisabled==true},on:{"focus":function($event){_vm.formular.ZusatzfutterIst.showPop=true},"blur":function($event){_vm.formular.ZusatzfutterIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZusatzfutterIst.onylNumber)}},model:{value:(_vm.formular.ZusatzfutterIst.text),callback:function ($$v) {_vm.$set(_vm.formular.ZusatzfutterIst, "text", $$v)},expression:"formular.ZusatzfutterIst.text"}}):_vm._e(),(_vm.formular.ZusatzfutterZiel)?_c('b-popover',{ref:"ZusatzfutterZiel",attrs:{"show":_vm.formular.ZusatzfutterZiel.showPop==true,"target":"ZusatzfutterZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ZusatzfutterZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ZusatzfutterZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZusatzfutterZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ZusatzfutterZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ZusatzfutterZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4165501) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(150.0180591) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(157.8647235) + 'px', 
		
														'font-size': _vm.formular.ZusatzfutterZiel.size + 'px', 
														'text-align': _vm.formular.ZusatzfutterZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.ZusatzfutterZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.ZusatzfutterZiel.schriftart }),attrs:{"id":"ZusatzfutterZiel","precision":parseInt(_vm.formular.ZusatzfutterZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.ZusatzfutterZiel.isDisabled==true || _vm.formular.ZusatzfutterZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.ZusatzfutterZiel.showPop=true},"blur":function($event){_vm.formular.ZusatzfutterZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ZusatzfutterZiel.onylNumber)}},model:{value:(_vm.formular.ZusatzfutterZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.ZusatzfutterZiel, "text", $$v)},expression:"formular.ZusatzfutterZiel.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(67.7159311) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.7722454) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(86.0954808) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.7722454) + 'px'})},[_vm._v(" Ziel ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.8099843) + 'px'})},[_vm._v(" Fischzucht ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(137.5688715) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.7722454) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(159.1646925) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.7722454) + 'px'})},[_vm._v(" Ziel ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(16.4161974) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(60.8597958) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(173.7962468) + 'px'})},[(_vm.formular.fisch1ArtIst)?_c('b-popover',{ref:"fisch1ArtIst",attrs:{"show":_vm.formular.fisch1ArtIst.showPop==true,"target":"fisch1ArtIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fisch1ArtIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fisch1ArtIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch1ArtIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch1ArtIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fisch1ArtIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fisch1ArtIst.text),expression:"formular.fisch1ArtIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.fisch1ArtIst.size + 'px',
														'height': _vm.formular.fisch1ArtIst.height + 'px',
														'text-align': _vm.formular.fisch1ArtIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.fisch1ArtIst.letterSpacing + 'px',
														'font-weight': _vm.formular.fisch1ArtIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.fisch1ArtIst.isDisabled==true || _vm.formular.fisch1ArtIst.isDisabled=='true',"id":"fisch1ArtIst"},domProps:{"value":(_vm.formular.fisch1ArtIst.text)},on:{"focus":function($event){_vm.formular.fisch1ArtIst.showPop=true},"blur":function($event){_vm.formular.fisch1ArtIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fisch1ArtIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(16.4165501) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(79.9158241) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(173.7962468) + 'px'})},[(_vm.formular.fisch1ArtZiel)?_c('b-popover',{ref:"fisch1ArtZiel",attrs:{"show":_vm.formular.fisch1ArtZiel.showPop==true,"target":"fisch1ArtZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fisch1ArtZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fisch1ArtZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch1ArtZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch1ArtZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fisch1ArtZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fisch1ArtZiel.text),expression:"formular.fisch1ArtZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.fisch1ArtZiel.size + 'px',
														'height': _vm.formular.fisch1ArtZiel.height + 'px',
														'text-align': _vm.formular.fisch1ArtZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.fisch1ArtZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.fisch1ArtZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.fisch1ArtZiel.isDisabled==true || _vm.formular.fisch1ArtZiel.isDisabled=='true',"id":"fisch1ArtZiel"},domProps:{"value":(_vm.formular.fisch1ArtZiel.text)},on:{"focus":function($event){_vm.formular.fisch1ArtZiel.showPop=true},"blur":function($event){_vm.formular.fisch1ArtZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fisch1ArtZiel, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(16.4161974) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(130.7123835) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(173.7962468) + 'px'})},[(_vm.formular.fisch2ArtIst)?_c('b-popover',{ref:"fisch2ArtIst",attrs:{"show":_vm.formular.fisch2ArtIst.showPop==true,"target":"fisch2ArtIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fisch2ArtIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fisch2ArtIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch2ArtIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch2ArtIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fisch2ArtIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fisch2ArtIst.text),expression:"formular.fisch2ArtIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.fisch2ArtIst.size + 'px',
														'height': _vm.formular.fisch2ArtIst.height + 'px',
														'text-align': _vm.formular.fisch2ArtIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.fisch2ArtIst.letterSpacing + 'px',
														'font-weight': _vm.formular.fisch2ArtIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.fisch2ArtIst.isDisabled==true || _vm.formular.fisch2ArtIst.isDisabled=='true',"id":"fisch2ArtIst"},domProps:{"value":(_vm.formular.fisch2ArtIst.text)},on:{"focus":function($event){_vm.formular.fisch2ArtIst.showPop=true},"blur":function($event){_vm.formular.fisch2ArtIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fisch2ArtIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(22.8790722) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(149.7680591) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(173.7962468) + 'px'})},[(_vm.formular.fisch2ArtZiel)?_c('b-popover',{ref:"fisch2ArtZiel",attrs:{"show":_vm.formular.fisch2ArtZiel.showPop==true,"target":"fisch2ArtZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fisch2ArtZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fisch2ArtZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch2ArtZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fisch2ArtZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fisch2ArtZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fisch2ArtZiel.text),expression:"formular.fisch2ArtZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.fisch2ArtZiel.size + 'px',
														'height': _vm.formular.fisch2ArtZiel.height + 'px',
														'text-align': _vm.formular.fisch2ArtZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.fisch2ArtZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.fisch2ArtZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.fisch2ArtZiel.isDisabled==true || _vm.formular.fisch2ArtZiel.isDisabled=='true',"id":"fisch2ArtZiel"},domProps:{"value":(_vm.formular.fisch2ArtZiel.text)},on:{"focus":function($event){_vm.formular.fisch2ArtZiel.showPop=true},"blur":function($event){_vm.formular.fisch2ArtZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fisch2ArtZiel, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(173.132389) + 'px'})},[_vm._v(" Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8454371) + 'px', 
														top: _vm.gib_hoehe_in_mm(87.6491954) + 'px'})},[_vm._v(" 3 Tierhaltung ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(91.2890594) + 'px'})},[_vm._v(" (Anzahl) ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(96.6455143) + 'px'})},[_vm._v(" Milchkühe ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(103.9675663) + 'px'})},[_vm._v(" Mastrinder, bullen ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(35.9289147) + 'px'})},[_vm._v(" Getreide ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(43.2509667) + 'px'})},[_vm._v(" Ölfrüchte ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(50.5730187) + 'px'})},[_vm._v(" Kartoffeln ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(57.894718) + 'px'})},[_vm._v(" Zuckerrüben ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(65.4710667) + 'px'})},[_vm._v(" Ackerfutter ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(74.697346) + 'px'})},[_vm._v(" Weide ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(111.2472943) + 'px'})},[_vm._v(" Zuchtsauen ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(119.4158263) + 'px'})},[_vm._v(" Mastschweine ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(127.5840056) + 'px'})},[_vm._v(" Ziegen, Schafe ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(134.9060576) + 'px'})},[_vm._v(" Arbeitspferde ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(142.2284623) + 'px'})},[_vm._v(" Zuchtpferde ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(149.8041056) + 'px'})},[_vm._v(" Reitpferde ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(157.3381303) + 'px'})},[_vm._v(" davon ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(160.5970783) + 'px'})},[_vm._v(" Pensionstiere ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(165.8099843) + 'px'})},[_vm._v(" Fischzucht ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(173.132389) + 'px'})},[_vm._v(" Art ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8454371) + 'px', 
														top: _vm.gib_hoehe_in_mm(196.5781215) + 'px'})},[_vm._v(" 4 Tierische Abgänge ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(205.3406003) + 'px'})},[_vm._v(" Festmist, Jahresmenge ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(213.5514563) + 'px'})},[_vm._v(" Lagerart ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(221.7199883) + 'px'})},[_vm._v(" Lagerkapazität ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(229.8991013) + 'px'})},[_vm._v(" Art der Verbringung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(246.278842) + 'px'})},[_vm._v(" Lagerart ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(254.4470213) + 'px'})},[_vm._v(" Lagerkapazität ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(262.6564665) + 'px'})},[_vm._v(" Art der Verbringung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(31.0227866) + 'px', 
														top: _vm.gib_hoehe_in_mm(184.67626) + 'px'})},[_vm._v(" Jahresproduktion ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(72.8480688) + 'px', 
														top: _vm.gib_hoehe_in_mm(185.0719894) + 'px'})},[_vm._v(" kg ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(92.0635175) + 'px', 
														top: _vm.gib_hoehe_in_mm(185.0719894) + 'px'})},[_vm._v(" kg ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(98.9623295) + 'px', 
														top: _vm.gib_hoehe_in_mm(184.67626) + 'px'})},[_vm._v(" Jahresproduktion ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(142.5207795) + 'px', 
														top: _vm.gib_hoehe_in_mm(185.0719894) + 'px'})},[_vm._v(" kg ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(168.6449158) + 'px', 
														top: _vm.gib_hoehe_in_mm(185.0719894) + 'px'})},[_vm._v(" kg ")]),(_vm.formular.Fisch1Ist)?_c('b-popover',{ref:"Fisch1Ist",attrs:{"show":_vm.formular.Fisch1Ist.showPop==true,"target":"Fisch1Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Fisch1Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Fisch1Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch1Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch1Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Fisch1Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.6939926) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': _vm.formular.Fisch1Ist.size + 'px', 
														'text-align': _vm.formular.Fisch1Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.Fisch1Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.Fisch1Ist.schriftart }),attrs:{"id":"Fisch1Ist","precision":parseInt(_vm.formular.Fisch1Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Fisch1Ist.isDisabled==true || _vm.formular.Fisch1Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.Fisch1Ist.showPop=true},"blur":function($event){_vm.formular.Fisch1Ist.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Fisch1Ist.onylNumber)}},model:{value:(_vm.formular.Fisch1Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.Fisch1Ist, "text", $$v)},expression:"formular.Fisch1Ist.text"}}):_vm._e(),(_vm.formular.Fisch1Ziel)?_c('b-popover',{ref:"Fisch1Ziel",attrs:{"show":_vm.formular.Fisch1Ziel.showPop==true,"target":"Fisch1Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Fisch1Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Fisch1Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch1Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch1Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Fisch1Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.6939926) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(80.0822342) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': _vm.formular.Fisch1Ziel.size + 'px', 
														'text-align': _vm.formular.Fisch1Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.Fisch1Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.Fisch1Ziel.schriftart }),attrs:{"id":"Fisch1Ziel","precision":parseInt(_vm.formular.Fisch1Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Fisch1Ziel.isDisabled==true || _vm.formular.Fisch1Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.Fisch1Ziel.showPop=true},"blur":function($event){_vm.formular.Fisch1Ziel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Fisch1Ziel.onylNumber)}},model:{value:(_vm.formular.Fisch1Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.Fisch1Ziel, "text", $$v)},expression:"formular.Fisch1Ziel.text"}}):_vm._e(),(_vm.formular.Fisch2Ist)?_c('b-popover',{ref:"Fisch2Ist",attrs:{"show":_vm.formular.Fisch2Ist.showPop==true,"target":"Fisch2Ist"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Fisch2Ist.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Fisch2Ist.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch2Ist", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch2Ist", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Fisch2Ist!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.6936399) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9623835) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': _vm.formular.Fisch2Ist.size + 'px', 
														'text-align': _vm.formular.Fisch2Ist.schriftausrichtung, 
														'letter-spacing': _vm.formular.Fisch2Ist.letterSpacing + 'px',
														'font-weight': _vm.formular.Fisch2Ist.schriftart }),attrs:{"id":"Fisch2Ist","precision":parseInt(_vm.formular.Fisch2Ist.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Fisch2Ist.isDisabled==true || _vm.formular.Fisch2Ist.isDisabled==true},on:{"focus":function($event){_vm.formular.Fisch2Ist.showPop=true},"blur":function($event){_vm.formular.Fisch2Ist.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Fisch2Ist.onylNumber)}},model:{value:(_vm.formular.Fisch2Ist.text),callback:function ($$v) {_vm.$set(_vm.formular.Fisch2Ist, "text", $$v)},expression:"formular.Fisch2Ist.text"}}):_vm._e(),(_vm.formular.Fisch2Ziel)?_c('b-popover',{ref:"Fisch2Ziel",attrs:{"show":_vm.formular.Fisch2Ziel.showPop==true,"target":"Fisch2Ziel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.Fisch2Ziel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.Fisch2Ziel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch2Ziel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("Fisch2Ziel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.Fisch2Ziel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(17.3420349) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(149.9341165) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(185.2529366) + 'px', 
		
														'font-size': _vm.formular.Fisch2Ziel.size + 'px', 
														'text-align': _vm.formular.Fisch2Ziel.schriftausrichtung, 
														'letter-spacing': _vm.formular.Fisch2Ziel.letterSpacing + 'px',
														'font-weight': _vm.formular.Fisch2Ziel.schriftart }),attrs:{"id":"Fisch2Ziel","precision":parseInt(_vm.formular.Fisch2Ziel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.Fisch2Ziel.isDisabled==true || _vm.formular.Fisch2Ziel.isDisabled==true},on:{"focus":function($event){_vm.formular.Fisch2Ziel.showPop=true},"blur":function($event){_vm.formular.Fisch2Ziel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.Fisch2Ziel.onylNumber)}},model:{value:(_vm.formular.Fisch2Ziel.text),callback:function ($$v) {_vm.$set(_vm.formular.Fisch2Ziel, "text", $$v)},expression:"formular.Fisch2Ziel.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.1182066) + 'px', 
														top: _vm.gib_hoehe_in_mm(238.1099573) + 'px'})},[_vm._v(" Flüssigmist, Jahresmenge ")]),(_vm.formular.FlMistIst)?_c('b-popover',{ref:"FlMistIst",attrs:{"show":_vm.formular.FlMistIst.showPop==true,"target":"FlMistIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(238.8933161) + 'px', 
		
														'font-size': _vm.formular.FlMistIst.size + 'px', 
														'text-align': _vm.formular.FlMistIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.FlMistIst.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistIst.schriftart }),attrs:{"id":"FlMistIst","precision":parseInt(_vm.formular.FlMistIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.FlMistIst.isDisabled==true || _vm.formular.FlMistIst.isDisabled==true},on:{"focus":function($event){_vm.formular.FlMistIst.showPop=true},"blur":function($event){_vm.formular.FlMistIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.FlMistIst.onylNumber)}},model:{value:(_vm.formular.FlMistIst.text),callback:function ($$v) {_vm.$set(_vm.formular.FlMistIst, "text", $$v)},expression:"formular.FlMistIst.text"}}):_vm._e(),(_vm.formular.FlMistZiel)?_c('b-popover',{ref:"FlMistZiel",attrs:{"show":_vm.formular.FlMistZiel.showPop==true,"target":"FlMistZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(238.8933161) + 'px', 
		
														'font-size': _vm.formular.FlMistZiel.size + 'px', 
														'text-align': _vm.formular.FlMistZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.FlMistZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistZiel.schriftart }),attrs:{"id":"FlMistZiel","precision":parseInt(_vm.formular.FlMistZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.FlMistZiel.isDisabled==true || _vm.formular.FlMistZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.FlMistZiel.showPop=true},"blur":function($event){_vm.formular.FlMistZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.FlMistZiel.onylNumber)}},model:{value:(_vm.formular.FlMistZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.FlMistZiel, "text", $$v)},expression:"formular.FlMistZiel.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(114.9251788) + 'px', 
														top: _vm.gib_hoehe_in_mm(196.9893697) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(152.2126228) + 'px', 
														top: _vm.gib_hoehe_in_mm(196.9893697) + 'px'})},[_vm._v(" Ziel ")]),(_vm.formular.MistIst)?_c('b-popover',{ref:"MistIst",attrs:{"show":_vm.formular.MistIst.showPop==true,"target":"MistIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(206.1624034) + 'px', 
		
														'font-size': _vm.formular.MistIst.size + 'px', 
														'text-align': _vm.formular.MistIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.MistIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MistIst.schriftart }),attrs:{"id":"MistIst","precision":parseInt(_vm.formular.MistIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MistIst.isDisabled==true || _vm.formular.MistIst.isDisabled==true},on:{"focus":function($event){_vm.formular.MistIst.showPop=true},"blur":function($event){_vm.formular.MistIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MistIst.onylNumber)}},model:{value:(_vm.formular.MistIst.text),callback:function ($$v) {_vm.$set(_vm.formular.MistIst, "text", $$v)},expression:"formular.MistIst.text"}}):_vm._e(),(_vm.formular.MistZiel)?_c('b-popover',{ref:"MistZiel",attrs:{"show":_vm.formular.MistZiel.showPop==true,"target":"MistZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(206.1624034) + 'px', 
		
														'font-size': _vm.formular.MistZiel.size + 'px', 
														'text-align': _vm.formular.MistZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.MistZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MistZiel.schriftart }),attrs:{"id":"MistZiel","precision":parseInt(_vm.formular.MistZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MistZiel.isDisabled==true || _vm.formular.MistZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.MistZiel.showPop=true},"blur":function($event){_vm.formular.MistZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MistZiel.onylNumber)}},model:{value:(_vm.formular.MistZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.MistZiel, "text", $$v)},expression:"formular.MistZiel.text"}}):_vm._e(),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(98.6639453) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(212.0278687) + 'px'})},[(_vm.formular.MistLagerArtIst)?_c('b-popover',{ref:"MistLagerArtIst",attrs:{"show":_vm.formular.MistLagerArtIst.showPop==true,"target":"MistLagerArtIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistLagerArtIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistLagerArtIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerArtIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerArtIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistLagerArtIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.MistLagerArtIst.text),expression:"formular.MistLagerArtIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.MistLagerArtIst.size + 'px',
														'height': _vm.formular.MistLagerArtIst.height + 'px',
														'text-align': _vm.formular.MistLagerArtIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.MistLagerArtIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MistLagerArtIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.MistLagerArtIst.isDisabled==true || _vm.formular.MistLagerArtIst.isDisabled=='true',"id":"MistLagerArtIst"},domProps:{"value":(_vm.formular.MistLagerArtIst.text)},on:{"focus":function($event){_vm.formular.MistLagerArtIst.showPop=true},"blur":function($event){_vm.formular.MistLagerArtIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.MistLagerArtIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(136.9280156) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(212.0278687) + 'px'})},[(_vm.formular.MistLagerArtZiel)?_c('b-popover',{ref:"MistLagerArtZiel",attrs:{"show":_vm.formular.MistLagerArtZiel.showPop==true,"target":"MistLagerArtZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistLagerArtZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistLagerArtZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerArtZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerArtZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistLagerArtZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.MistLagerArtZiel.text),expression:"formular.MistLagerArtZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.MistLagerArtZiel.size + 'px',
														'height': _vm.formular.MistLagerArtZiel.height + 'px',
														'text-align': _vm.formular.MistLagerArtZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.MistLagerArtZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MistLagerArtZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.MistLagerArtZiel.isDisabled==true || _vm.formular.MistLagerArtZiel.isDisabled=='true',"id":"MistLagerArtZiel"},domProps:{"value":(_vm.formular.MistLagerArtZiel.text)},on:{"focus":function($event){_vm.formular.MistLagerArtZiel.showPop=true},"blur":function($event){_vm.formular.MistLagerArtZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.MistLagerArtZiel, "text", $event.target.value)}}}):_vm._e()],1),(_vm.formular.MistLagerKapIst)?_c('b-popover',{ref:"MistLagerKapIst",attrs:{"show":_vm.formular.MistLagerKapIst.showPop==true,"target":"MistLagerKapIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistLagerKapIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistLagerKapIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerKapIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerKapIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistLagerKapIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(222.5417914) + 'px', 
		
														'font-size': _vm.formular.MistLagerKapIst.size + 'px', 
														'text-align': _vm.formular.MistLagerKapIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.MistLagerKapIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MistLagerKapIst.schriftart }),attrs:{"id":"MistLagerKapIst","precision":parseInt(_vm.formular.MistLagerKapIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MistLagerKapIst.isDisabled==true || _vm.formular.MistLagerKapIst.isDisabled==true},on:{"focus":function($event){_vm.formular.MistLagerKapIst.showPop=true},"blur":function($event){_vm.formular.MistLagerKapIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MistLagerKapIst.onylNumber)}},model:{value:(_vm.formular.MistLagerKapIst.text),callback:function ($$v) {_vm.$set(_vm.formular.MistLagerKapIst, "text", $$v)},expression:"formular.MistLagerKapIst.text"}}):_vm._e(),(_vm.formular.MistLagerKapZiel)?_c('b-popover',{ref:"MistLagerKapZiel",attrs:{"show":_vm.formular.MistLagerKapZiel.showPop==true,"target":"MistLagerKapZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistLagerKapZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistLagerKapZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerKapZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistLagerKapZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistLagerKapZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(222.5417914) + 'px', 
		
														'font-size': _vm.formular.MistLagerKapZiel.size + 'px', 
														'text-align': _vm.formular.MistLagerKapZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.MistLagerKapZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MistLagerKapZiel.schriftart }),attrs:{"id":"MistLagerKapZiel","precision":parseInt(_vm.formular.MistLagerKapZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.MistLagerKapZiel.isDisabled==true || _vm.formular.MistLagerKapZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.MistLagerKapZiel.showPop=true},"blur":function($event){_vm.formular.MistLagerKapZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.MistLagerKapZiel.onylNumber)}},model:{value:(_vm.formular.MistLagerKapZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.MistLagerKapZiel, "text", $$v)},expression:"formular.MistLagerKapZiel.text"}}):_vm._e(),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(98.6639453) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(228.3335424) + 'px'})},[(_vm.formular.MistVerbrinIst)?_c('b-popover',{ref:"MistVerbrinIst",attrs:{"show":_vm.formular.MistVerbrinIst.showPop==true,"target":"MistVerbrinIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistVerbrinIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistVerbrinIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistVerbrinIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistVerbrinIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistVerbrinIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.MistVerbrinIst.text),expression:"formular.MistVerbrinIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.MistVerbrinIst.size + 'px',
														'height': _vm.formular.MistVerbrinIst.height + 'px',
														'text-align': _vm.formular.MistVerbrinIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.MistVerbrinIst.letterSpacing + 'px',
														'font-weight': _vm.formular.MistVerbrinIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.MistVerbrinIst.isDisabled==true || _vm.formular.MistVerbrinIst.isDisabled=='true',"id":"MistVerbrinIst"},domProps:{"value":(_vm.formular.MistVerbrinIst.text)},on:{"focus":function($event){_vm.formular.MistVerbrinIst.showPop=true},"blur":function($event){_vm.formular.MistVerbrinIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.MistVerbrinIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(136.9280156) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(228.3335424) + 'px'})},[(_vm.formular.MistVerbrinIstZiel)?_c('b-popover',{ref:"MistVerbrinIstZiel",attrs:{"show":_vm.formular.MistVerbrinIstZiel.showPop==true,"target":"MistVerbrinIstZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.MistVerbrinIstZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.MistVerbrinIstZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistVerbrinIstZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("MistVerbrinIstZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.MistVerbrinIstZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.MistVerbrinIstZiel.text),expression:"formular.MistVerbrinIstZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.MistVerbrinIstZiel.size + 'px',
														'height': _vm.formular.MistVerbrinIstZiel.height + 'px',
														'text-align': _vm.formular.MistVerbrinIstZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.MistVerbrinIstZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.MistVerbrinIstZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.MistVerbrinIstZiel.isDisabled==true || _vm.formular.MistVerbrinIstZiel.isDisabled=='true',"id":"MistVerbrinIstZiel"},domProps:{"value":(_vm.formular.MistVerbrinIstZiel.text)},on:{"focus":function($event){_vm.formular.MistVerbrinIstZiel.showPop=true},"blur":function($event){_vm.formular.MistVerbrinIstZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.MistVerbrinIstZiel, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(98.6639453) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(244.8007527) + 'px'})},[(_vm.formular.FlMistLagerIst)?_c('b-popover',{ref:"FlMistLagerIst",attrs:{"show":_vm.formular.FlMistLagerIst.showPop==true,"target":"FlMistLagerIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistLagerIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistLagerIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistLagerIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistLagerIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistLagerIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.FlMistLagerIst.text),expression:"formular.FlMistLagerIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.FlMistLagerIst.size + 'px',
														'height': _vm.formular.FlMistLagerIst.height + 'px',
														'text-align': _vm.formular.FlMistLagerIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.FlMistLagerIst.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistLagerIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.FlMistLagerIst.isDisabled==true || _vm.formular.FlMistLagerIst.isDisabled=='true',"id":"FlMistLagerIst"},domProps:{"value":(_vm.formular.FlMistLagerIst.text)},on:{"focus":function($event){_vm.formular.FlMistLagerIst.showPop=true},"blur":function($event){_vm.formular.FlMistLagerIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.FlMistLagerIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(136.9280156) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(244.8007527) + 'px'})},[(_vm.formular.FlMistLagerZiel)?_c('b-popover',{ref:"FlMistLagerZiel",attrs:{"show":_vm.formular.FlMistLagerZiel.showPop==true,"target":"FlMistLagerZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistLagerZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistLagerZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistLagerZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistLagerZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistLagerZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.FlMistLagerZiel.text),expression:"formular.FlMistLagerZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.FlMistLagerZiel.size + 'px',
														'height': _vm.formular.FlMistLagerZiel.height + 'px',
														'text-align': _vm.formular.FlMistLagerZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.FlMistLagerZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistLagerZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.FlMistLagerZiel.isDisabled==true || _vm.formular.FlMistLagerZiel.isDisabled=='true',"id":"FlMistLagerZiel"},domProps:{"value":(_vm.formular.FlMistLagerZiel.text)},on:{"focus":function($event){_vm.formular.FlMistLagerZiel.showPop=true},"blur":function($event){_vm.formular.FlMistLagerZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.FlMistLagerZiel, "text", $event.target.value)}}}):_vm._e()],1),(_vm.formular.FlMistKapIst)?_c('b-popover',{ref:"FlMistKapIst",attrs:{"show":_vm.formular.FlMistKapIst.showPop==true,"target":"FlMistKapIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistKapIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistKapIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistKapIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistKapIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistKapIst!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.6162448) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(255.2684717) + 'px', 
		
														'font-size': _vm.formular.FlMistKapIst.size + 'px', 
														'text-align': _vm.formular.FlMistKapIst.schriftausrichtung, 
														'letter-spacing': _vm.formular.FlMistKapIst.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistKapIst.schriftart }),attrs:{"id":"FlMistKapIst","precision":parseInt(_vm.formular.FlMistKapIst.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.FlMistKapIst.isDisabled==true || _vm.formular.FlMistKapIst.isDisabled==true},on:{"focus":function($event){_vm.formular.FlMistKapIst.showPop=true},"blur":function($event){_vm.formular.FlMistKapIst.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.FlMistKapIst.onylNumber)}},model:{value:(_vm.formular.FlMistKapIst.text),callback:function ($$v) {_vm.$set(_vm.formular.FlMistKapIst, "text", $$v)},expression:"formular.FlMistKapIst.text"}}):_vm._e(),(_vm.formular.FlMistKapZiel)?_c('b-popover',{ref:"FlMistKapZiel",attrs:{"show":_vm.formular.FlMistKapZiel.showPop==true,"target":"FlMistKapZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistKapZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistKapZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistKapZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistKapZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistKapZiel!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.4161974) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(146.5390263) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(255.2684717) + 'px', 
		
														'font-size': _vm.formular.FlMistKapZiel.size + 'px', 
														'text-align': _vm.formular.FlMistKapZiel.schriftausrichtung, 
														'letter-spacing': _vm.formular.FlMistKapZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistKapZiel.schriftart }),attrs:{"id":"FlMistKapZiel","precision":parseInt(_vm.formular.FlMistKapZiel.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.FlMistKapZiel.isDisabled==true || _vm.formular.FlMistKapZiel.isDisabled==true},on:{"focus":function($event){_vm.formular.FlMistKapZiel.showPop=true},"blur":function($event){_vm.formular.FlMistKapZiel.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.FlMistKapZiel.onylNumber)}},model:{value:(_vm.formular.FlMistKapZiel.text),callback:function ($$v) {_vm.$set(_vm.formular.FlMistKapZiel, "text", $$v)},expression:"formular.FlMistKapZiel.text"}}):_vm._e(),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3505442) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(98.6639453) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(261.2192904) + 'px'})},[(_vm.formular.FlMistVerbringungIst)?_c('b-popover',{ref:"FlMistVerbringungIst",attrs:{"show":_vm.formular.FlMistVerbringungIst.showPop==true,"target":"FlMistVerbringungIst"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistVerbringungIst.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistVerbringungIst.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistVerbringungIst", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistVerbringungIst", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistVerbringungIst!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.FlMistVerbringungIst.text),expression:"formular.FlMistVerbringungIst.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.FlMistVerbringungIst.size + 'px',
														'height': _vm.formular.FlMistVerbringungIst.height + 'px',
														'text-align': _vm.formular.FlMistVerbringungIst.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.FlMistVerbringungIst.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistVerbringungIst.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.FlMistVerbringungIst.isDisabled==true || _vm.formular.FlMistVerbringungIst.isDisabled=='true',"id":"FlMistVerbringungIst"},domProps:{"value":(_vm.formular.FlMistVerbringungIst.text)},on:{"focus":function($event){_vm.formular.FlMistVerbringungIst.showPop=true},"blur":function($event){_vm.formular.FlMistVerbringungIst.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.FlMistVerbringungIst, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(35.3512496) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(136.9280156) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(261.2192904) + 'px'})},[(_vm.formular.FlMistVerbringungZiel)?_c('b-popover',{ref:"FlMistVerbringungZiel",attrs:{"show":_vm.formular.FlMistVerbringungZiel.showPop==true,"target":"FlMistVerbringungZiel"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.FlMistVerbringungZiel.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.FlMistVerbringungZiel.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistVerbringungZiel", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("FlMistVerbringungZiel", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.FlMistVerbringungZiel!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.FlMistVerbringungZiel.text),expression:"formular.FlMistVerbringungZiel.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.FlMistVerbringungZiel.size + 'px',
														'height': _vm.formular.FlMistVerbringungZiel.height + 'px',
														'text-align': _vm.formular.FlMistVerbringungZiel.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.FlMistVerbringungZiel.letterSpacing + 'px',
														'font-weight': _vm.formular.FlMistVerbringungZiel.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.FlMistVerbringungZiel.isDisabled==true || _vm.formular.FlMistVerbringungZiel.isDisabled=='true',"id":"FlMistVerbringungZiel"},domProps:{"value":(_vm.formular.FlMistVerbringungZiel.text)},on:{"focus":function($event){_vm.formular.FlMistVerbringungZiel.showPop=true},"blur":function($event){_vm.formular.FlMistVerbringungZiel.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.FlMistVerbringungZiel, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.6858355) + 'px', 
														top: _vm.gib_hoehe_in_mm(205.9377214) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.6858355) + 'px', 
														top: _vm.gib_hoehe_in_mm(222.3167567) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.6858355) + 'px', 
														top: _vm.gib_hoehe_in_mm(238.6644017) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.6858355) + 'px', 
														top: _vm.gib_hoehe_in_mm(255.0441424) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(169.9146358) + 'px', 
														top: _vm.gib_hoehe_in_mm(205.9377214) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(169.9146358) + 'px', 
														top: _vm.gib_hoehe_in_mm(222.3167567) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(169.9146358) + 'px', 
														top: _vm.gib_hoehe_in_mm(238.6644017) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(169.9146358) + 'px', 
														top: _vm.gib_hoehe_in_mm(255.0441424) + 'px'})},[_vm._v(" m ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(174.0627405) + 'px', 
														top: _vm.gib_hoehe_in_mm(269.2459606) + 'px'})},[_vm._v(" Fortsetzung Blatt 3 ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(183.809605) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(183.5595407) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5904577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(35.5508967) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(42.8729487) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(50.2020547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(57.3054327) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(64.7121327) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(73.9846157) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(81.4759637) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(96.1623917) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(103.5768511) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(110.8170767) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(119.0702567) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(127.1506137) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(134.5580191) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(141.7982447) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(149.204592) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(156.69594) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(165.2072964) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(172.701466) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.7743827) + 'px', 
													width: _vm.spalte_breit_mm(38.8968141) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(184.0820369) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(97.8619055) + 'px', 
													width: _vm.spalte_breit_mm(31.661879) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(184.2421627) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(189.5439491) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(195.2576891) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(203.3867187) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(211.5520764) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(219.7202557) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(227.9314644) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(236.0255767) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(244.2829891) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(252.4931397) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.7493367) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(260.6584974) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.5990908) + 'px', 
													width: _vm.spalte_breit_mm(183.559188) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(268.9116774) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(171.6502725) + 'px', 
													width: _vm.spalte_breit_mm(32.257942) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(274.2445014) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(250.1787607) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6711968) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(102.4542454) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6711968) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(62.743047) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(78.7279305) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(53.985506) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5904577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(97.6926095) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(53.985506) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5904577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(129.5237845) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(53.985506) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5904577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(148.4845838) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(53.985506) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5904577) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(174.0980105) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(250.1787607) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(203.9082145) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(255.5115847) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(171.6502725) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(5.4289443) + 'px', 
													top: _vm.gib_hoehe_in_mm(268.9155571) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(135.9993565) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(73.7511667) + 'px', 
													top: _vm.gib_hoehe_in_mm(195.2605107) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(97.7349335) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(73.7511667) + 'px', 
													top: _vm.gib_hoehe_in_mm(195.2605107) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(27.9317238) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(8.2290296) + 'px', 
													top: _vm.gib_hoehe_in_mm(195.2576891) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(78.7279305) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(102.4542454) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(97.8619055) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(102.4542454) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(129.5237845) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(102.4542454) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(148.6574068) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(102.4542454) + 'px', 
													top: _vm.gib_hoehe_in_mm(87.1897037) + 'px'})})],1),_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0162443) + 'px'})},[_vm._v(" Betriebsbeschreibung Blatt 3 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(60.8597958) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0296469) + 'px'})},[_vm._v(" Bauherrschaft ")]),(_vm.formular.bauherr_name)?_c('b-popover',{ref:"bauherr_name",attrs:{"show":_vm.formular.bauherr_name.showPop==true,"target":"bauherr_name"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_name.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_name.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_name!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_name.text),expression:"formular.bauherr_name.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2783787) + 'px', 
		
														'font-size': _vm.formular.bauherr_name.size + 'px', 
														'text-align': _vm.formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauherr_name.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_name.schriftart }),attrs:{"id":"bauherr_name","type":"text","disabled":_vm.formular.bauherr_name.isDisabled==true || _vm.formular.bauherr_name.isDisabled==true},domProps:{"value":(_vm.formular.bauherr_name.text)},on:{"focus":function($event){_vm.formular.bauherr_name.showPop=true},"blur":function($event){_vm.formular.bauherr_name.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauherr_name.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_name, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8457898) + 'px', 
														top: _vm.gib_hoehe_in_mm(28.4982311) + 'px'})},[_vm._v(" 5 Gefährliche Stoffe Art und Menge ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.3109295) + 'px', 
														top: _vm.gib_hoehe_in_mm(28.909832) + 'px'})},[_vm._v(" Ort der Lagerung und Schutzvorkehrungen ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(166.4020965) + 'px', 
														top: _vm.gib_hoehe_in_mm(9.1928439) + 'px'})},[_vm._v(" Anlage I/9 zu VV BauPrüfVO ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(196.0288965) + 'px', 
														top: _vm.gib_hoehe_in_mm(12.4941159) + 'px'})},[_vm._v(" Blatt 3 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.1631645) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0296469) + 'px'})},[_vm._v(" Antrag vom ")]),(_vm.formular.bauantragVom)?_c('b-popover',{ref:"bauantragVom",attrs:{"show":_vm.formular.bauantragVom.showPop==true,"target":"bauantragVom"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauantragVom.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauantragVom.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauantragVom!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauantragVom.text),expression:"formular.bauantragVom.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(27.3347313) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(175.5436635) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': _vm.formular.bauantragVom.size + 'px', 
														'text-align': _vm.formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauantragVom.letterSpacing + 'px',
														'font-weight': _vm.formular.bauantragVom.schriftart }),attrs:{"id":"bauantragVom","type":"text","disabled":_vm.formular.bauantragVom.isDisabled==true || _vm.formular.bauantragVom.isDisabled==true},domProps:{"value":(_vm.formular.bauantragVom.text)},on:{"focus":function($event){_vm.formular.bauantragVom.showPop=true},"blur":function($event){_vm.formular.bauantragVom.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauantragVom.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauantragVom, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.1631645) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.7907149) + 'px'})},[_vm._v(" Prüfvermerke ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(39.8005026) + 'px'})},[_vm._v(" 5.1 Düngemittel ")]),(_vm.formular.duenger2Art)?_c('b-popover',{ref:"duenger2Art",attrs:{"show":_vm.formular.duenger2Art.showPop==true,"target":"duenger2Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger2Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger2Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger2Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger2Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger2Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger2Art.text),expression:"formular.duenger2Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.8100083) + 'px', 
		
														'font-size': _vm.formular.duenger2Art.size + 'px', 
														'text-align': _vm.formular.duenger2Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger2Art.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger2Art.schriftart }),attrs:{"id":"duenger2Art","type":"text","disabled":_vm.formular.duenger2Art.isDisabled==true || _vm.formular.duenger2Art.isDisabled==true},domProps:{"value":(_vm.formular.duenger2Art.text)},on:{"focus":function($event){_vm.formular.duenger2Art.showPop=true},"blur":function($event){_vm.formular.duenger2Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger2Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger2Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger2Lager)?_c('b-popover',{ref:"duenger2Lager",attrs:{"show":_vm.formular.duenger2Lager.showPop==true,"target":"duenger2Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger2Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger2Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger2Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger2Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger2Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger2Lager.text),expression:"formular.duenger2Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(43.8100083) + 'px', 
		
														'font-size': _vm.formular.duenger2Lager.size + 'px', 
														'text-align': _vm.formular.duenger2Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger2Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger2Lager.schriftart }),attrs:{"id":"duenger2Lager","type":"text","disabled":_vm.formular.duenger2Lager.isDisabled==true || _vm.formular.duenger2Lager.isDisabled==true},domProps:{"value":(_vm.formular.duenger2Lager.text)},on:{"focus":function($event){_vm.formular.duenger2Lager.showPop=true},"blur":function($event){_vm.formular.duenger2Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger2Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger2Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger1Art)?_c('b-popover',{ref:"duenger1Art",attrs:{"show":_vm.formular.duenger1Art.showPop==true,"target":"duenger1Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger1Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger1Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger1Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger1Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger1Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger1Art.text),expression:"formular.duenger1Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.488309) + 'px', 
		
														'font-size': _vm.formular.duenger1Art.size + 'px', 
														'text-align': _vm.formular.duenger1Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger1Art.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger1Art.schriftart }),attrs:{"id":"duenger1Art","type":"text","disabled":_vm.formular.duenger1Art.isDisabled==true || _vm.formular.duenger1Art.isDisabled==true},domProps:{"value":(_vm.formular.duenger1Art.text)},on:{"focus":function($event){_vm.formular.duenger1Art.showPop=true},"blur":function($event){_vm.formular.duenger1Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger1Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger1Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger1Lager)?_c('b-popover',{ref:"duenger1Lager",attrs:{"show":_vm.formular.duenger1Lager.showPop==true,"target":"duenger1Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger1Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger1Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger1Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger1Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger1Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger1Lager.text),expression:"formular.duenger1Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(36.488309) + 'px', 
		
														'font-size': _vm.formular.duenger1Lager.size + 'px', 
														'text-align': _vm.formular.duenger1Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger1Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger1Lager.schriftart }),attrs:{"id":"duenger1Lager","type":"text","disabled":_vm.formular.duenger1Lager.isDisabled==true || _vm.formular.duenger1Lager.isDisabled==true},domProps:{"value":(_vm.formular.duenger1Lager.text)},on:{"focus":function($event){_vm.formular.duenger1Lager.showPop=true},"blur":function($event){_vm.formular.duenger1Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger1Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger1Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger3Art)?_c('b-popover',{ref:"duenger3Art",attrs:{"show":_vm.formular.duenger3Art.showPop==true,"target":"duenger3Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger3Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger3Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger3Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger3Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger3Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger3Art.text),expression:"formular.duenger3Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.132413) + 'px', 
		
														'font-size': _vm.formular.duenger3Art.size + 'px', 
														'text-align': _vm.formular.duenger3Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger3Art.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger3Art.schriftart }),attrs:{"id":"duenger3Art","type":"text","disabled":_vm.formular.duenger3Art.isDisabled==true || _vm.formular.duenger3Art.isDisabled==true},domProps:{"value":(_vm.formular.duenger3Art.text)},on:{"focus":function($event){_vm.formular.duenger3Art.showPop=true},"blur":function($event){_vm.formular.duenger3Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger3Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger3Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger3Lager)?_c('b-popover',{ref:"duenger3Lager",attrs:{"show":_vm.formular.duenger3Lager.showPop==true,"target":"duenger3Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger3Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger3Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger3Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger3Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger3Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger3Lager.text),expression:"formular.duenger3Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(51.132413) + 'px', 
		
														'font-size': _vm.formular.duenger3Lager.size + 'px', 
														'text-align': _vm.formular.duenger3Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger3Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger3Lager.schriftart }),attrs:{"id":"duenger3Lager","type":"text","disabled":_vm.formular.duenger3Lager.isDisabled==true || _vm.formular.duenger3Lager.isDisabled==true},domProps:{"value":(_vm.formular.duenger3Lager.text)},on:{"focus":function($event){_vm.formular.duenger3Lager.showPop=true},"blur":function($event){_vm.formular.duenger3Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger3Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger3Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger4Art)?_c('b-popover',{ref:"duenger4Art",attrs:{"show":_vm.formular.duenger4Art.showPop==true,"target":"duenger4Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger4Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger4Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger4Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger4Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger4Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger4Art.text),expression:"formular.duenger4Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.454465) + 'px', 
		
														'font-size': _vm.formular.duenger4Art.size + 'px', 
														'text-align': _vm.formular.duenger4Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger4Art.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger4Art.schriftart }),attrs:{"id":"duenger4Art","type":"text","disabled":_vm.formular.duenger4Art.isDisabled==true || _vm.formular.duenger4Art.isDisabled==true},domProps:{"value":(_vm.formular.duenger4Art.text)},on:{"focus":function($event){_vm.formular.duenger4Art.showPop=true},"blur":function($event){_vm.formular.duenger4Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger4Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger4Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.duenger4Lager)?_c('b-popover',{ref:"duenger4Lager",attrs:{"show":_vm.formular.duenger4Lager.showPop==true,"target":"duenger4Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.duenger4Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.duenger4Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger4Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("duenger4Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.duenger4Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.duenger4Lager.text),expression:"formular.duenger4Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(58.454465) + 'px', 
		
														'font-size': _vm.formular.duenger4Lager.size + 'px', 
														'text-align': _vm.formular.duenger4Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.duenger4Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.duenger4Lager.schriftart }),attrs:{"id":"duenger4Lager","type":"text","disabled":_vm.formular.duenger4Lager.isDisabled==true || _vm.formular.duenger4Lager.isDisabled==true},domProps:{"value":(_vm.formular.duenger4Lager.text)},on:{"focus":function($event){_vm.formular.duenger4Lager.showPop=true},"blur":function($event){_vm.formular.duenger4Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.duenger4Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.duenger4Lager, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(68.4115266) + 'px'})},[_vm._v(" 5.2 Pflanzenschutzmittel, ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(71.6281506) + 'px'})},[_vm._v(" Gifte o.ä. ")]),(_vm.formular.pflaSchu1Art)?_c('b-popover',{ref:"pflaSchu1Art",attrs:{"show":_vm.formular.pflaSchu1Art.showPop==true,"target":"pflaSchu1Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu1Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu1Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu1Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu1Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu1Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu1Art.text),expression:"formular.pflaSchu1Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.861165) + 'px', 
		
														'font-size': _vm.formular.pflaSchu1Art.size + 'px', 
														'text-align': _vm.formular.pflaSchu1Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu1Art.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu1Art.schriftart }),attrs:{"id":"pflaSchu1Art","type":"text","disabled":_vm.formular.pflaSchu1Art.isDisabled==true || _vm.formular.pflaSchu1Art.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu1Art.text)},on:{"focus":function($event){_vm.formular.pflaSchu1Art.showPop=true},"blur":function($event){_vm.formular.pflaSchu1Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu1Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu1Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu1Lager)?_c('b-popover',{ref:"pflaSchu1Lager",attrs:{"show":_vm.formular.pflaSchu1Lager.showPop==true,"target":"pflaSchu1Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu1Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu1Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu1Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu1Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu1Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu1Lager.text),expression:"formular.pflaSchu1Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(65.861165) + 'px', 
		
														'font-size': _vm.formular.pflaSchu1Lager.size + 'px', 
														'text-align': _vm.formular.pflaSchu1Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu1Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu1Lager.schriftart }),attrs:{"id":"pflaSchu1Lager","type":"text","disabled":_vm.formular.pflaSchu1Lager.isDisabled==true || _vm.formular.pflaSchu1Lager.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu1Lager.text)},on:{"focus":function($event){_vm.formular.pflaSchu1Lager.showPop=true},"blur":function($event){_vm.formular.pflaSchu1Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu1Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu1Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu2Art)?_c('b-popover',{ref:"pflaSchu2Art",attrs:{"show":_vm.formular.pflaSchu2Art.showPop==true,"target":"pflaSchu2Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu2Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu2Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu2Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu2Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu2Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu2Art.text),expression:"formular.pflaSchu2Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(73.183217) + 'px', 
		
														'font-size': _vm.formular.pflaSchu2Art.size + 'px', 
														'text-align': _vm.formular.pflaSchu2Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu2Art.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu2Art.schriftart }),attrs:{"id":"pflaSchu2Art","type":"text","disabled":_vm.formular.pflaSchu2Art.isDisabled==true || _vm.formular.pflaSchu2Art.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu2Art.text)},on:{"focus":function($event){_vm.formular.pflaSchu2Art.showPop=true},"blur":function($event){_vm.formular.pflaSchu2Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu2Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu2Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu2Lager)?_c('b-popover',{ref:"pflaSchu2Lager",attrs:{"show":_vm.formular.pflaSchu2Lager.showPop==true,"target":"pflaSchu2Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu2Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu2Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu2Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu2Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu2Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu2Lager.text),expression:"formular.pflaSchu2Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(73.183217) + 'px', 
		
														'font-size': _vm.formular.pflaSchu2Lager.size + 'px', 
														'text-align': _vm.formular.pflaSchu2Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu2Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu2Lager.schriftart }),attrs:{"id":"pflaSchu2Lager","type":"text","disabled":_vm.formular.pflaSchu2Lager.isDisabled==true || _vm.formular.pflaSchu2Lager.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu2Lager.text)},on:{"focus":function($event){_vm.formular.pflaSchu2Lager.showPop=true},"blur":function($event){_vm.formular.pflaSchu2Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu2Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu2Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu3Art)?_c('b-popover',{ref:"pflaSchu3Art",attrs:{"show":_vm.formular.pflaSchu3Art.showPop==true,"target":"pflaSchu3Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu3Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu3Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu3Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu3Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu3Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu3Art.text),expression:"formular.pflaSchu3Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(80.505269) + 'px', 
		
														'font-size': _vm.formular.pflaSchu3Art.size + 'px', 
														'text-align': _vm.formular.pflaSchu3Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu3Art.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu3Art.schriftart }),attrs:{"id":"pflaSchu3Art","type":"text","disabled":_vm.formular.pflaSchu3Art.isDisabled==true || _vm.formular.pflaSchu3Art.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu3Art.text)},on:{"focus":function($event){_vm.formular.pflaSchu3Art.showPop=true},"blur":function($event){_vm.formular.pflaSchu3Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu3Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu3Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu3Lager)?_c('b-popover',{ref:"pflaSchu3Lager",attrs:{"show":_vm.formular.pflaSchu3Lager.showPop==true,"target":"pflaSchu3Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu3Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu3Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu3Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu3Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu3Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu3Lager.text),expression:"formular.pflaSchu3Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(80.505269) + 'px', 
		
														'font-size': _vm.formular.pflaSchu3Lager.size + 'px', 
														'text-align': _vm.formular.pflaSchu3Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu3Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu3Lager.schriftart }),attrs:{"id":"pflaSchu3Lager","type":"text","disabled":_vm.formular.pflaSchu3Lager.isDisabled==true || _vm.formular.pflaSchu3Lager.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu3Lager.text)},on:{"focus":function($event){_vm.formular.pflaSchu3Lager.showPop=true},"blur":function($event){_vm.formular.pflaSchu3Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu3Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu3Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu4Art)?_c('b-popover',{ref:"pflaSchu4Art",attrs:{"show":_vm.formular.pflaSchu4Art.showPop==true,"target":"pflaSchu4Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu4Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu4Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu4Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu4Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu4Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu4Art.text),expression:"formular.pflaSchu4Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(87.827321) + 'px', 
		
														'font-size': _vm.formular.pflaSchu4Art.size + 'px', 
														'text-align': _vm.formular.pflaSchu4Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu4Art.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu4Art.schriftart }),attrs:{"id":"pflaSchu4Art","type":"text","disabled":_vm.formular.pflaSchu4Art.isDisabled==true || _vm.formular.pflaSchu4Art.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu4Art.text)},on:{"focus":function($event){_vm.formular.pflaSchu4Art.showPop=true},"blur":function($event){_vm.formular.pflaSchu4Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu4Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu4Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.pflaSchu4Lager)?_c('b-popover',{ref:"pflaSchu4Lager",attrs:{"show":_vm.formular.pflaSchu4Lager.showPop==true,"target":"pflaSchu4Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.pflaSchu4Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.pflaSchu4Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu4Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("pflaSchu4Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.pflaSchu4Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.pflaSchu4Lager.text),expression:"formular.pflaSchu4Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(87.827321) + 'px', 
		
														'font-size': _vm.formular.pflaSchu4Lager.size + 'px', 
														'text-align': _vm.formular.pflaSchu4Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.pflaSchu4Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.pflaSchu4Lager.schriftart }),attrs:{"id":"pflaSchu4Lager","type":"text","disabled":_vm.formular.pflaSchu4Lager.isDisabled==true || _vm.formular.pflaSchu4Lager.isDisabled==true},domProps:{"value":(_vm.formular.pflaSchu4Lager.text)},on:{"focus":function($event){_vm.formular.pflaSchu4Lager.showPop=true},"blur":function($event){_vm.formular.pflaSchu4Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.pflaSchu4Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.pflaSchu4Lager, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(98.3874996) + 'px'})},[_vm._v(" 5.3 Kraft, Betriebsstoffe ")]),(_vm.formular.kraftstoff2Art)?_c('b-popover',{ref:"kraftstoff2Art",attrs:{"show":_vm.formular.kraftstoff2Art.showPop==true,"target":"kraftstoff2Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff2Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff2Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff2Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff2Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff2Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff2Art.text),expression:"formular.kraftstoff2Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(102.429101) + 'px', 
		
														'font-size': _vm.formular.kraftstoff2Art.size + 'px', 
														'text-align': _vm.formular.kraftstoff2Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff2Art.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff2Art.schriftart }),attrs:{"id":"kraftstoff2Art","type":"text","disabled":_vm.formular.kraftstoff2Art.isDisabled==true || _vm.formular.kraftstoff2Art.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff2Art.text)},on:{"focus":function($event){_vm.formular.kraftstoff2Art.showPop=true},"blur":function($event){_vm.formular.kraftstoff2Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff2Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff2Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff2Lager)?_c('b-popover',{ref:"kraftstoff2Lager",attrs:{"show":_vm.formular.kraftstoff2Lager.showPop==true,"target":"kraftstoff2Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff2Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff2Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff2Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff2Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff2Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff2Lager.text),expression:"formular.kraftstoff2Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(102.429101) + 'px', 
		
														'font-size': _vm.formular.kraftstoff2Lager.size + 'px', 
														'text-align': _vm.formular.kraftstoff2Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff2Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff2Lager.schriftart }),attrs:{"id":"kraftstoff2Lager","type":"text","disabled":_vm.formular.kraftstoff2Lager.isDisabled==true || _vm.formular.kraftstoff2Lager.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff2Lager.text)},on:{"focus":function($event){_vm.formular.kraftstoff2Lager.showPop=true},"blur":function($event){_vm.formular.kraftstoff2Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff2Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff2Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff1Art)?_c('b-popover',{ref:"kraftstoff1Art",attrs:{"show":_vm.formular.kraftstoff1Art.showPop==true,"target":"kraftstoff1Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff1Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff1Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff1Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff1Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff1Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff1Art.text),expression:"formular.kraftstoff1Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(95.107049) + 'px', 
		
														'font-size': _vm.formular.kraftstoff1Art.size + 'px', 
														'text-align': _vm.formular.kraftstoff1Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff1Art.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff1Art.schriftart }),attrs:{"id":"kraftstoff1Art","type":"text","disabled":_vm.formular.kraftstoff1Art.isDisabled==true || _vm.formular.kraftstoff1Art.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff1Art.text)},on:{"focus":function($event){_vm.formular.kraftstoff1Art.showPop=true},"blur":function($event){_vm.formular.kraftstoff1Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff1Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff1Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff1Lager)?_c('b-popover',{ref:"kraftstoff1Lager",attrs:{"show":_vm.formular.kraftstoff1Lager.showPop==true,"target":"kraftstoff1Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff1Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff1Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff1Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff1Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff1Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff1Lager.text),expression:"formular.kraftstoff1Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(95.107049) + 'px', 
		
														'font-size': _vm.formular.kraftstoff1Lager.size + 'px', 
														'text-align': _vm.formular.kraftstoff1Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff1Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff1Lager.schriftart }),attrs:{"id":"kraftstoff1Lager","type":"text","disabled":_vm.formular.kraftstoff1Lager.isDisabled==true || _vm.formular.kraftstoff1Lager.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff1Lager.text)},on:{"focus":function($event){_vm.formular.kraftstoff1Lager.showPop=true},"blur":function($event){_vm.formular.kraftstoff1Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff1Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff1Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff3Art)?_c('b-popover',{ref:"kraftstoff3Art",attrs:{"show":_vm.formular.kraftstoff3Art.showPop==true,"target":"kraftstoff3Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff3Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff3Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff3Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff3Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff3Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff3Art.text),expression:"formular.kraftstoff3Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(109.7515057) + 'px', 
		
														'font-size': _vm.formular.kraftstoff3Art.size + 'px', 
														'text-align': _vm.formular.kraftstoff3Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff3Art.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff3Art.schriftart }),attrs:{"id":"kraftstoff3Art","type":"text","disabled":_vm.formular.kraftstoff3Art.isDisabled==true || _vm.formular.kraftstoff3Art.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff3Art.text)},on:{"focus":function($event){_vm.formular.kraftstoff3Art.showPop=true},"blur":function($event){_vm.formular.kraftstoff3Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff3Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff3Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff3Lager)?_c('b-popover',{ref:"kraftstoff3Lager",attrs:{"show":_vm.formular.kraftstoff3Lager.showPop==true,"target":"kraftstoff3Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff3Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff3Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff3Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff3Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff3Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff3Lager.text),expression:"formular.kraftstoff3Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(109.7515057) + 'px', 
		
														'font-size': _vm.formular.kraftstoff3Lager.size + 'px', 
														'text-align': _vm.formular.kraftstoff3Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff3Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff3Lager.schriftart }),attrs:{"id":"kraftstoff3Lager","type":"text","disabled":_vm.formular.kraftstoff3Lager.isDisabled==true || _vm.formular.kraftstoff3Lager.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff3Lager.text)},on:{"focus":function($event){_vm.formular.kraftstoff3Lager.showPop=true},"blur":function($event){_vm.formular.kraftstoff3Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff3Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff3Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff4Art)?_c('b-popover',{ref:"kraftstoff4Art",attrs:{"show":_vm.formular.kraftstoff4Art.showPop==true,"target":"kraftstoff4Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff4Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff4Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff4Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff4Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff4Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff4Art.text),expression:"formular.kraftstoff4Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(117.0728523) + 'px', 
		
														'font-size': _vm.formular.kraftstoff4Art.size + 'px', 
														'text-align': _vm.formular.kraftstoff4Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff4Art.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff4Art.schriftart }),attrs:{"id":"kraftstoff4Art","type":"text","disabled":_vm.formular.kraftstoff4Art.isDisabled==true || _vm.formular.kraftstoff4Art.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff4Art.text)},on:{"focus":function($event){_vm.formular.kraftstoff4Art.showPop=true},"blur":function($event){_vm.formular.kraftstoff4Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff4Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff4Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.kraftstoff4Lager)?_c('b-popover',{ref:"kraftstoff4Lager",attrs:{"show":_vm.formular.kraftstoff4Lager.showPop==true,"target":"kraftstoff4Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.kraftstoff4Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.kraftstoff4Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff4Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("kraftstoff4Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.kraftstoff4Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.kraftstoff4Lager.text),expression:"formular.kraftstoff4Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(117.0728523) + 'px', 
		
														'font-size': _vm.formular.kraftstoff4Lager.size + 'px', 
														'text-align': _vm.formular.kraftstoff4Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.kraftstoff4Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.kraftstoff4Lager.schriftart }),attrs:{"id":"kraftstoff4Lager","type":"text","disabled":_vm.formular.kraftstoff4Lager.isDisabled==true || _vm.formular.kraftstoff4Lager.isDisabled==true},domProps:{"value":(_vm.formular.kraftstoff4Lager.text)},on:{"focus":function($event){_vm.formular.kraftstoff4Lager.showPop=true},"blur":function($event){_vm.formular.kraftstoff4Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.kraftstoff4Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.kraftstoff4Lager, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(127.6333836) + 'px'})},[_vm._v(" 5.4 Abfallstoffe ")]),(_vm.formular.abfall1Art)?_c('b-popover',{ref:"abfall1Art",attrs:{"show":_vm.formular.abfall1Art.showPop==true,"target":"abfall1Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall1Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall1Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall1Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall1Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall1Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall1Art.text),expression:"formular.abfall1Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(124.4061907) + 'px', 
		
														'font-size': _vm.formular.abfall1Art.size + 'px', 
														'text-align': _vm.formular.abfall1Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall1Art.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall1Art.schriftart }),attrs:{"id":"abfall1Art","type":"text","disabled":_vm.formular.abfall1Art.isDisabled==true || _vm.formular.abfall1Art.isDisabled==true},domProps:{"value":(_vm.formular.abfall1Art.text)},on:{"focus":function($event){_vm.formular.abfall1Art.showPop=true},"blur":function($event){_vm.formular.abfall1Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall1Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall1Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall1Lager)?_c('b-popover',{ref:"abfall1Lager",attrs:{"show":_vm.formular.abfall1Lager.showPop==true,"target":"abfall1Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall1Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall1Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall1Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall1Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall1Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall1Lager.text),expression:"formular.abfall1Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(124.4061907) + 'px', 
		
														'font-size': _vm.formular.abfall1Lager.size + 'px', 
														'text-align': _vm.formular.abfall1Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall1Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall1Lager.schriftart }),attrs:{"id":"abfall1Lager","type":"text","disabled":_vm.formular.abfall1Lager.isDisabled==true || _vm.formular.abfall1Lager.isDisabled==true},domProps:{"value":(_vm.formular.abfall1Lager.text)},on:{"focus":function($event){_vm.formular.abfall1Lager.showPop=true},"blur":function($event){_vm.formular.abfall1Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall1Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall1Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall2Art)?_c('b-popover',{ref:"abfall2Art",attrs:{"show":_vm.formular.abfall2Art.showPop==true,"target":"abfall2Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall2Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall2Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall2Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall2Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall2Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall2Art.text),expression:"formular.abfall2Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(131.7275373) + 'px', 
		
														'font-size': _vm.formular.abfall2Art.size + 'px', 
														'text-align': _vm.formular.abfall2Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall2Art.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall2Art.schriftart }),attrs:{"id":"abfall2Art","type":"text","disabled":_vm.formular.abfall2Art.isDisabled==true || _vm.formular.abfall2Art.isDisabled==true},domProps:{"value":(_vm.formular.abfall2Art.text)},on:{"focus":function($event){_vm.formular.abfall2Art.showPop=true},"blur":function($event){_vm.formular.abfall2Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall2Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall2Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall2Lager)?_c('b-popover',{ref:"abfall2Lager",attrs:{"show":_vm.formular.abfall2Lager.showPop==true,"target":"abfall2Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall2Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall2Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall2Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall2Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall2Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall2Lager.text),expression:"formular.abfall2Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(131.7275373) + 'px', 
		
														'font-size': _vm.formular.abfall2Lager.size + 'px', 
														'text-align': _vm.formular.abfall2Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall2Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall2Lager.schriftart }),attrs:{"id":"abfall2Lager","type":"text","disabled":_vm.formular.abfall2Lager.isDisabled==true || _vm.formular.abfall2Lager.isDisabled==true},domProps:{"value":(_vm.formular.abfall2Lager.text)},on:{"focus":function($event){_vm.formular.abfall2Lager.showPop=true},"blur":function($event){_vm.formular.abfall2Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall2Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall2Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall3Art)?_c('b-popover',{ref:"abfall3Art",attrs:{"show":_vm.formular.abfall3Art.showPop==true,"target":"abfall3Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall3Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall3Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall3Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall3Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall3Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall3Art.text),expression:"formular.abfall3Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(139.049942) + 'px', 
		
														'font-size': _vm.formular.abfall3Art.size + 'px', 
														'text-align': _vm.formular.abfall3Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall3Art.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall3Art.schriftart }),attrs:{"id":"abfall3Art","type":"text","disabled":_vm.formular.abfall3Art.isDisabled==true || _vm.formular.abfall3Art.isDisabled==true},domProps:{"value":(_vm.formular.abfall3Art.text)},on:{"focus":function($event){_vm.formular.abfall3Art.showPop=true},"blur":function($event){_vm.formular.abfall3Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall3Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall3Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall3Lager)?_c('b-popover',{ref:"abfall3Lager",attrs:{"show":_vm.formular.abfall3Lager.showPop==true,"target":"abfall3Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall3Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall3Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall3Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall3Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall3Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall3Lager.text),expression:"formular.abfall3Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(139.049942) + 'px', 
		
														'font-size': _vm.formular.abfall3Lager.size + 'px', 
														'text-align': _vm.formular.abfall3Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall3Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall3Lager.schriftart }),attrs:{"id":"abfall3Lager","type":"text","disabled":_vm.formular.abfall3Lager.isDisabled==true || _vm.formular.abfall3Lager.isDisabled==true},domProps:{"value":(_vm.formular.abfall3Lager.text)},on:{"focus":function($event){_vm.formular.abfall3Lager.showPop=true},"blur":function($event){_vm.formular.abfall3Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall3Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall3Lager, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall4Art)?_c('b-popover',{ref:"abfall4Art",attrs:{"show":_vm.formular.abfall4Art.showPop==true,"target":"abfall4Art"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall4Art.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall4Art.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall4Art", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall4Art", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall4Art!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall4Art.text),expression:"formular.abfall4Art.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(40.8159834) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(146.3723467) + 'px', 
		
														'font-size': _vm.formular.abfall4Art.size + 'px', 
														'text-align': _vm.formular.abfall4Art.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall4Art.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall4Art.schriftart }),attrs:{"id":"abfall4Art","type":"text","disabled":_vm.formular.abfall4Art.isDisabled==true || _vm.formular.abfall4Art.isDisabled==true},domProps:{"value":(_vm.formular.abfall4Art.text)},on:{"focus":function($event){_vm.formular.abfall4Art.showPop=true},"blur":function($event){_vm.formular.abfall4Art.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall4Art.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall4Art, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.abfall4Lager)?_c('b-popover',{ref:"abfall4Lager",attrs:{"show":_vm.formular.abfall4Lager.showPop==true,"target":"abfall4Lager"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.abfall4Lager.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.abfall4Lager.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall4Lager", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("abfall4Lager", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.abfall4Lager!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.abfall4Lager.text),expression:"formular.abfall4Lager.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(67.4451861) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(105.7118851) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(146.3723467) + 'px', 
		
														'font-size': _vm.formular.abfall4Lager.size + 'px', 
														'text-align': _vm.formular.abfall4Lager.schriftausrichtung, 
														'letter-spacing': _vm.formular.abfall4Lager.letterSpacing + 'px',
														'font-weight': _vm.formular.abfall4Lager.schriftart }),attrs:{"id":"abfall4Lager","type":"text","disabled":_vm.formular.abfall4Lager.isDisabled==true || _vm.formular.abfall4Lager.isDisabled==true},domProps:{"value":(_vm.formular.abfall4Lager.text)},on:{"focus":function($event){_vm.formular.abfall4Lager.showPop=true},"blur":function($event){_vm.formular.abfall4Lager.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.abfall4Lager.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.abfall4Lager, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(157.1755356) + 'px'})},[_vm._v(" Art der Beseitigung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4914218) + 'px', 
														top: _vm.gib_hoehe_in_mm(170.8532416) + 'px'})},[_vm._v(" 5.5 Besonders zu behan ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(174.1125423) + 'px'})},[_vm._v(" delnde Abwässer ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(178.0486743) + 'px'})},[_vm._v(" Art, Menge pro ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(181.3076223) + 'px'})},[_vm._v(" Zeiteinheit ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(200.9036343) + 'px'})},[_vm._v(" Art und Ort der ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(204.1202583) + 'px'})},[_vm._v(" Behandlung ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(230.1600993) + 'px'})},[_vm._v(" Verbleib der ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(27.8484866) + 'px', 
														top: _vm.gib_hoehe_in_mm(233.4190473) + 'px'})},[_vm._v(" Rückstände ")]),(_vm.formular.artBeseitigung1)?_c('b-popover',{ref:"artBeseitigung1",attrs:{"show":_vm.formular.artBeseitigung1.showPop==true,"target":"artBeseitigung1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.artBeseitigung1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.artBeseitigung1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artBeseitigung1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artBeseitigung1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.artBeseitigung1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.artBeseitigung1.text),expression:"formular.artBeseitigung1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(153.6936933) + 'px', 
		
														'font-size': _vm.formular.artBeseitigung1.size + 'px', 
														'text-align': _vm.formular.artBeseitigung1.schriftausrichtung, 
														'letter-spacing': _vm.formular.artBeseitigung1.letterSpacing + 'px',
														'font-weight': _vm.formular.artBeseitigung1.schriftart }),attrs:{"id":"artBeseitigung1","type":"text","disabled":_vm.formular.artBeseitigung1.isDisabled==true || _vm.formular.artBeseitigung1.isDisabled==true},domProps:{"value":(_vm.formular.artBeseitigung1.text)},on:{"focus":function($event){_vm.formular.artBeseitigung1.showPop=true},"blur":function($event){_vm.formular.artBeseitigung1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.artBeseitigung1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.artBeseitigung1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.artBeseitigung1)?_c('b-popover',{ref:"artBeseitigung1",attrs:{"show":_vm.formular.artBeseitigung1.showPop==true,"target":"artBeseitigung1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.artBeseitigung1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.artBeseitigung1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artBeseitigung1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artBeseitigung1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.artBeseitigung1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.artBeseitigung1.text),expression:"formular.artBeseitigung1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(161.0993352) + 'px', 
		
														'font-size': _vm.formular.artBeseitigung1.size + 'px', 
														'text-align': _vm.formular.artBeseitigung1.schriftausrichtung, 
														'letter-spacing': _vm.formular.artBeseitigung1.letterSpacing + 'px',
														'font-weight': _vm.formular.artBeseitigung1.schriftart }),attrs:{"id":"artBeseitigung1","type":"text","disabled":_vm.formular.artBeseitigung1.isDisabled==true || _vm.formular.artBeseitigung1.isDisabled==true},domProps:{"value":(_vm.formular.artBeseitigung1.text)},on:{"focus":function($event){_vm.formular.artBeseitigung1.showPop=true},"blur":function($event){_vm.formular.artBeseitigung1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.artBeseitigung1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.artBeseitigung1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.behAbwasser1)?_c('b-popover',{ref:"behAbwasser1",attrs:{"show":_vm.formular.behAbwasser1.showPop==true,"target":"behAbwasser1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.behAbwasser1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.behAbwasser1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.behAbwasser1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.behAbwasser1.text),expression:"formular.behAbwasser1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(168.4294993) + 'px', 
		
														'font-size': _vm.formular.behAbwasser1.size + 'px', 
														'text-align': _vm.formular.behAbwasser1.schriftausrichtung, 
														'letter-spacing': _vm.formular.behAbwasser1.letterSpacing + 'px',
														'font-weight': _vm.formular.behAbwasser1.schriftart }),attrs:{"id":"behAbwasser1","type":"text","disabled":_vm.formular.behAbwasser1.isDisabled==true || _vm.formular.behAbwasser1.isDisabled==true},domProps:{"value":(_vm.formular.behAbwasser1.text)},on:{"focus":function($event){_vm.formular.behAbwasser1.showPop=true},"blur":function($event){_vm.formular.behAbwasser1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.behAbwasser1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.behAbwasser1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.behAbwasser2)?_c('b-popover',{ref:"behAbwasser2",attrs:{"show":_vm.formular.behAbwasser2.showPop==true,"target":"behAbwasser2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.behAbwasser2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.behAbwasser2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.behAbwasser2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.behAbwasser2.text),expression:"formular.behAbwasser2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(175.5829607) + 'px', 
		
														'font-size': _vm.formular.behAbwasser2.size + 'px', 
														'text-align': _vm.formular.behAbwasser2.schriftausrichtung, 
														'letter-spacing': _vm.formular.behAbwasser2.letterSpacing + 'px',
														'font-weight': _vm.formular.behAbwasser2.schriftart }),attrs:{"id":"behAbwasser2","type":"text","disabled":_vm.formular.behAbwasser2.isDisabled==true || _vm.formular.behAbwasser2.isDisabled==true},domProps:{"value":(_vm.formular.behAbwasser2.text)},on:{"focus":function($event){_vm.formular.behAbwasser2.showPop=true},"blur":function($event){_vm.formular.behAbwasser2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.behAbwasser2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.behAbwasser2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.behAbwasser3)?_c('b-popover',{ref:"behAbwasser3",attrs:{"show":_vm.formular.behAbwasser3.showPop==true,"target":"behAbwasser3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.behAbwasser3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.behAbwasser3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.behAbwasser3!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.behAbwasser3.text),expression:"formular.behAbwasser3.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(183.0312793) + 'px', 
		
														'font-size': _vm.formular.behAbwasser3.size + 'px', 
														'text-align': _vm.formular.behAbwasser3.schriftausrichtung, 
														'letter-spacing': _vm.formular.behAbwasser3.letterSpacing + 'px',
														'font-weight': _vm.formular.behAbwasser3.schriftart }),attrs:{"id":"behAbwasser3","type":"text","disabled":_vm.formular.behAbwasser3.isDisabled==true || _vm.formular.behAbwasser3.isDisabled==true},domProps:{"value":(_vm.formular.behAbwasser3.text)},on:{"focus":function($event){_vm.formular.behAbwasser3.showPop=true},"blur":function($event){_vm.formular.behAbwasser3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.behAbwasser3.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.behAbwasser3, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.behAbwasser4)?_c('b-popover',{ref:"behAbwasser4",attrs:{"show":_vm.formular.behAbwasser4.showPop==true,"target":"behAbwasser4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.behAbwasser4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.behAbwasser4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("behAbwasser4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.behAbwasser4!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.behAbwasser4.text),expression:"formular.behAbwasser4.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(190.1847407) + 'px', 
		
														'font-size': _vm.formular.behAbwasser4.size + 'px', 
														'text-align': _vm.formular.behAbwasser4.schriftausrichtung, 
														'letter-spacing': _vm.formular.behAbwasser4.letterSpacing + 'px',
														'font-weight': _vm.formular.behAbwasser4.schriftart }),attrs:{"id":"behAbwasser4","type":"text","disabled":_vm.formular.behAbwasser4.isDisabled==true || _vm.formular.behAbwasser4.isDisabled==true},domProps:{"value":(_vm.formular.behAbwasser4.text)},on:{"focus":function($event){_vm.formular.behAbwasser4.showPop=true},"blur":function($event){_vm.formular.behAbwasser4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.behAbwasser4.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.behAbwasser4, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.ortBehanlAbw1)?_c('b-popover',{ref:"ortBehanlAbw1",attrs:{"show":_vm.formular.ortBehanlAbw1.showPop==true,"target":"ortBehanlAbw1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ortBehanlAbw1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ortBehanlAbw1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ortBehanlAbw1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.ortBehanlAbw1.text),expression:"formular.ortBehanlAbw1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(197.5060873) + 'px', 
		
														'font-size': _vm.formular.ortBehanlAbw1.size + 'px', 
														'text-align': _vm.formular.ortBehanlAbw1.schriftausrichtung, 
														'letter-spacing': _vm.formular.ortBehanlAbw1.letterSpacing + 'px',
														'font-weight': _vm.formular.ortBehanlAbw1.schriftart }),attrs:{"id":"ortBehanlAbw1","type":"text","disabled":_vm.formular.ortBehanlAbw1.isDisabled==true || _vm.formular.ortBehanlAbw1.isDisabled==true},domProps:{"value":(_vm.formular.ortBehanlAbw1.text)},on:{"focus":function($event){_vm.formular.ortBehanlAbw1.showPop=true},"blur":function($event){_vm.formular.ortBehanlAbw1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ortBehanlAbw1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.ortBehanlAbw1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.ortBehanlAbw2)?_c('b-popover',{ref:"ortBehanlAbw2",attrs:{"show":_vm.formular.ortBehanlAbw2.showPop==true,"target":"ortBehanlAbw2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ortBehanlAbw2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ortBehanlAbw2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ortBehanlAbw2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.ortBehanlAbw2.text),expression:"formular.ortBehanlAbw2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(204.6595487) + 'px', 
		
														'font-size': _vm.formular.ortBehanlAbw2.size + 'px', 
														'text-align': _vm.formular.ortBehanlAbw2.schriftausrichtung, 
														'letter-spacing': _vm.formular.ortBehanlAbw2.letterSpacing + 'px',
														'font-weight': _vm.formular.ortBehanlAbw2.schriftart }),attrs:{"id":"ortBehanlAbw2","type":"text","disabled":_vm.formular.ortBehanlAbw2.isDisabled==true || _vm.formular.ortBehanlAbw2.isDisabled==true},domProps:{"value":(_vm.formular.ortBehanlAbw2.text)},on:{"focus":function($event){_vm.formular.ortBehanlAbw2.showPop=true},"blur":function($event){_vm.formular.ortBehanlAbw2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ortBehanlAbw2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.ortBehanlAbw2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.ortBehanlAbw3)?_c('b-popover',{ref:"ortBehanlAbw3",attrs:{"show":_vm.formular.ortBehanlAbw3.showPop==true,"target":"ortBehanlAbw3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ortBehanlAbw3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ortBehanlAbw3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ortBehanlAbw3!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.ortBehanlAbw3.text),expression:"formular.ortBehanlAbw3.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(212.1078673) + 'px', 
		
														'font-size': _vm.formular.ortBehanlAbw3.size + 'px', 
														'text-align': _vm.formular.ortBehanlAbw3.schriftausrichtung, 
														'letter-spacing': _vm.formular.ortBehanlAbw3.letterSpacing + 'px',
														'font-weight': _vm.formular.ortBehanlAbw3.schriftart }),attrs:{"id":"ortBehanlAbw3","type":"text","disabled":_vm.formular.ortBehanlAbw3.isDisabled==true || _vm.formular.ortBehanlAbw3.isDisabled==true},domProps:{"value":(_vm.formular.ortBehanlAbw3.text)},on:{"focus":function($event){_vm.formular.ortBehanlAbw3.showPop=true},"blur":function($event){_vm.formular.ortBehanlAbw3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ortBehanlAbw3.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.ortBehanlAbw3, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.ortBehanlAbw4)?_c('b-popover',{ref:"ortBehanlAbw4",attrs:{"show":_vm.formular.ortBehanlAbw4.showPop==true,"target":"ortBehanlAbw4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.ortBehanlAbw4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.ortBehanlAbw4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("ortBehanlAbw4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.ortBehanlAbw4!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.ortBehanlAbw4.text),expression:"formular.ortBehanlAbw4.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(219.2613287) + 'px', 
		
														'font-size': _vm.formular.ortBehanlAbw4.size + 'px', 
														'text-align': _vm.formular.ortBehanlAbw4.schriftausrichtung, 
														'letter-spacing': _vm.formular.ortBehanlAbw4.letterSpacing + 'px',
														'font-weight': _vm.formular.ortBehanlAbw4.schriftart }),attrs:{"id":"ortBehanlAbw4","type":"text","disabled":_vm.formular.ortBehanlAbw4.isDisabled==true || _vm.formular.ortBehanlAbw4.isDisabled==true},domProps:{"value":(_vm.formular.ortBehanlAbw4.text)},on:{"focus":function($event){_vm.formular.ortBehanlAbw4.showPop=true},"blur":function($event){_vm.formular.ortBehanlAbw4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.ortBehanlAbw4.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.ortBehanlAbw4, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.verbleRuecksAbw1)?_c('b-popover',{ref:"verbleRuecksAbw1",attrs:{"show":_vm.formular.verbleRuecksAbw1.showPop==true,"target":"verbleRuecksAbw1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.verbleRuecksAbw1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.verbleRuecksAbw1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.verbleRuecksAbw1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.verbleRuecksAbw1.text),expression:"formular.verbleRuecksAbw1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(226.794648) + 'px', 
		
														'font-size': _vm.formular.verbleRuecksAbw1.size + 'px', 
														'text-align': _vm.formular.verbleRuecksAbw1.schriftausrichtung, 
														'letter-spacing': _vm.formular.verbleRuecksAbw1.letterSpacing + 'px',
														'font-weight': _vm.formular.verbleRuecksAbw1.schriftart }),attrs:{"id":"verbleRuecksAbw1","type":"text","disabled":_vm.formular.verbleRuecksAbw1.isDisabled==true || _vm.formular.verbleRuecksAbw1.isDisabled==true},domProps:{"value":(_vm.formular.verbleRuecksAbw1.text)},on:{"focus":function($event){_vm.formular.verbleRuecksAbw1.showPop=true},"blur":function($event){_vm.formular.verbleRuecksAbw1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.verbleRuecksAbw1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.verbleRuecksAbw1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.verbleRuecksAbw2)?_c('b-popover',{ref:"verbleRuecksAbw2",attrs:{"show":_vm.formular.verbleRuecksAbw2.showPop==true,"target":"verbleRuecksAbw2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.verbleRuecksAbw2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.verbleRuecksAbw2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.verbleRuecksAbw2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.verbleRuecksAbw2.text),expression:"formular.verbleRuecksAbw2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(233.947404) + 'px', 
		
														'font-size': _vm.formular.verbleRuecksAbw2.size + 'px', 
														'text-align': _vm.formular.verbleRuecksAbw2.schriftausrichtung, 
														'letter-spacing': _vm.formular.verbleRuecksAbw2.letterSpacing + 'px',
														'font-weight': _vm.formular.verbleRuecksAbw2.schriftart }),attrs:{"id":"verbleRuecksAbw2","type":"text","disabled":_vm.formular.verbleRuecksAbw2.isDisabled==true || _vm.formular.verbleRuecksAbw2.isDisabled==true},domProps:{"value":(_vm.formular.verbleRuecksAbw2.text)},on:{"focus":function($event){_vm.formular.verbleRuecksAbw2.showPop=true},"blur":function($event){_vm.formular.verbleRuecksAbw2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.verbleRuecksAbw2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.verbleRuecksAbw2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.verbleRuecksAbw3)?_c('b-popover',{ref:"verbleRuecksAbw3",attrs:{"show":_vm.formular.verbleRuecksAbw3.showPop==true,"target":"verbleRuecksAbw3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.verbleRuecksAbw3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.verbleRuecksAbw3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.verbleRuecksAbw3!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.verbleRuecksAbw3.text),expression:"formular.verbleRuecksAbw3.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(241.396428) + 'px', 
		
														'font-size': _vm.formular.verbleRuecksAbw3.size + 'px', 
														'text-align': _vm.formular.verbleRuecksAbw3.schriftausrichtung, 
														'letter-spacing': _vm.formular.verbleRuecksAbw3.letterSpacing + 'px',
														'font-weight': _vm.formular.verbleRuecksAbw3.schriftart }),attrs:{"id":"verbleRuecksAbw3","type":"text","disabled":_vm.formular.verbleRuecksAbw3.isDisabled==true || _vm.formular.verbleRuecksAbw3.isDisabled==true},domProps:{"value":(_vm.formular.verbleRuecksAbw3.text)},on:{"focus":function($event){_vm.formular.verbleRuecksAbw3.showPop=true},"blur":function($event){_vm.formular.verbleRuecksAbw3.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.verbleRuecksAbw3.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.verbleRuecksAbw3, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.verbleRuecksAbw4)?_c('b-popover',{ref:"verbleRuecksAbw4",attrs:{"show":_vm.formular.verbleRuecksAbw4.showPop==true,"target":"verbleRuecksAbw4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.verbleRuecksAbw4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.verbleRuecksAbw4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("verbleRuecksAbw4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.verbleRuecksAbw4!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.verbleRuecksAbw4.text),expression:"formular.verbleRuecksAbw4.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(111.8324811) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.3245901) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(248.549184) + 'px', 
		
														'font-size': _vm.formular.verbleRuecksAbw4.size + 'px', 
														'text-align': _vm.formular.verbleRuecksAbw4.schriftausrichtung, 
														'letter-spacing': _vm.formular.verbleRuecksAbw4.letterSpacing + 'px',
														'font-weight': _vm.formular.verbleRuecksAbw4.schriftart }),attrs:{"id":"verbleRuecksAbw4","type":"text","disabled":_vm.formular.verbleRuecksAbw4.isDisabled==true || _vm.formular.verbleRuecksAbw4.isDisabled==true},domProps:{"value":(_vm.formular.verbleRuecksAbw4.text)},on:{"focus":function($event){_vm.formular.verbleRuecksAbw4.showPop=true},"blur":function($event){_vm.formular.verbleRuecksAbw4.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.verbleRuecksAbw4.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.verbleRuecksAbw4, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(174.7762526) + 'px', 
														top: _vm.gib_hoehe_in_mm(255.0335614) + 'px'})},[_vm._v(" Fortsetzung Blatt 4 ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(183.5595407) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(183.5595407) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5925739) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(34.8384427) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(42.1604947) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(49.4825467) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(56.8045987) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(64.2112987) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(71.4487027) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(78.7707547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(86.1774547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.4571827) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(100.7901684) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(108.0279251) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(115.3492717) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(122.7566771) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(129.9940811) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(137.3154277) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(144.6378324) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(151.9602371) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(159.3655263) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(166.6960431) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.8484464) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(181.2978231) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(188.4502264) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(195.7726311) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(203.0939777) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(210.4163824) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(217.7387871) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(153.4181514) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(225.0601337) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(232.3934721) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(239.7148187) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(114.3456927) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(247.0372234) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(183.5595407) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(254.5268079) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(174.0207692) + 'px', 
													width: _vm.spalte_breit_mm(29.8874453) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(260.2405479) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(204.1621585) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(241.5076312) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.6026178) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(235.7938912) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(104.0623715) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(124.5406721) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.519565) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(174.0207692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(242.8902152) + 'px', 
													top: _vm.gib_hoehe_in_mm(17.4503327) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6750765) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(235.7956547) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})})],1),_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(55.1574133) + 'px'})},[_vm._v(" mithelfende Familienangehörige ")]),(_vm.formular.AK1_4)?_c('b-popover',{ref:"AK1_4",attrs:{"show":_vm.formular.AK1_4.showPop==true,"target":"AK1_4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': _vm.formular.AK1_4.size + 'px', 
														'text-align': _vm.formular.AK1_4.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_4.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_4.schriftart }),attrs:{"id":"AK1_4","precision":parseInt(_vm.formular.AK1_4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_4.isDisabled==true || _vm.formular.AK1_4.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_4.showPop=true},"blur":function($event){_vm.formular.AK1_4.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_4.onylNumber)}},model:{value:(_vm.formular.AK1_4.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_4, "text", $$v)},expression:"formular.AK1_4.text"}}):_vm._e(),(_vm.formular.AK1_4_Prozent)?_c('b-popover',{ref:"AK1_4_Prozent",attrs:{"show":_vm.formular.AK1_4_Prozent.showPop==true,"target":"AK1_4_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_4_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_4_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_4_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_4_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_4_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': _vm.formular.AK1_4_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_4_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_4_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_4_Prozent.schriftart }),attrs:{"id":"AK1_4_Prozent","precision":parseInt(_vm.formular.AK1_4_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_4_Prozent.isDisabled==true || _vm.formular.AK1_4_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_4_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_4_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_4_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_4_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_4_Prozent, "text", $$v)},expression:"formular.AK1_4_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_4)?_c('b-popover',{ref:"AK2_4",attrs:{"show":_vm.formular.AK2_4.showPop==true,"target":"AK2_4"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_4.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_4.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_4", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_4", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_4!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': _vm.formular.AK2_4.size + 'px', 
														'text-align': _vm.formular.AK2_4.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_4.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_4.schriftart }),attrs:{"id":"AK2_4","precision":parseInt(_vm.formular.AK2_4.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_4.isDisabled==true || _vm.formular.AK2_4.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_4.showPop=true},"blur":function($event){_vm.formular.AK2_4.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_4.onylNumber)}},model:{value:(_vm.formular.AK2_4.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_4, "text", $$v)},expression:"formular.AK2_4.text"}}):_vm._e(),(_vm.formular.AK2_4_Prozent)?_c('b-popover',{ref:"AK2_4_Prozent",attrs:{"show":_vm.formular.AK2_4_Prozent.showPop==true,"target":"AK2_4_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_4_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_4_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_4_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_4_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_4_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(55.7573681) + 'px', 
		
														'font-size': _vm.formular.AK2_4_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_4_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_4_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_4_Prozent.schriftart }),attrs:{"id":"AK2_4_Prozent","precision":parseInt(_vm.formular.AK2_4_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_4_Prozent.isDisabled==true || _vm.formular.AK2_4_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_4_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_4_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_4_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_4_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_4_Prozent, "text", $$v)},expression:"formular.AK2_4_Prozent.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.1832521) + 'px', 
														top: _vm.gib_hoehe_in_mm(55.5552589) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(55.5552589) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(151.4084668) + 'px', 
														top: _vm.gib_hoehe_in_mm(55.5552589) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'10.44px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4910691) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0091903) + 'px'})},[_vm._v(" Betriebsbeschreibung Blatt 4 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(60.8594431) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0229456) + 'px'})},[_vm._v(" Bauherrschaft ")]),(_vm.formular.bauherr_name)?_c('b-popover',{ref:"bauherr_name",attrs:{"show":_vm.formular.bauherr_name.showPop==true,"target":"bauherr_name"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_name.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_name.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_name!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_name.text),expression:"formular.bauherr_name.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(112.0472754) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(61.1097958) + 'px', 
														'height': 19.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2716774) + 'px', 
		
														'font-size': _vm.formular.bauherr_name.size + 'px', 
														'text-align': _vm.formular.bauherr_name.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauherr_name.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_name.schriftart }),attrs:{"id":"bauherr_name","type":"text","disabled":_vm.formular.bauherr_name.isDisabled==true || _vm.formular.bauherr_name.isDisabled==true},domProps:{"value":(_vm.formular.bauherr_name.text)},on:{"focus":function($event){_vm.formular.bauherr_name.showPop=true},"blur":function($event){_vm.formular.bauherr_name.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauherr_name.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_name, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8454371) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.8873547) + 'px'})},[_vm._v(" 6 Arbeitskräfte ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(54.4685191) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.8873547) + 'px'})},[_vm._v(" Ausbildung als ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(35.3494286) + 'px'})},[_vm._v(" Betriebsleiter(in) ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(41.9523253) + 'px'})},[_vm._v(" Ehegatte ")]),(_vm.formular.betriebsleiter)?_c('b-popover',{ref:"betriebsleiter",attrs:{"show":_vm.formular.betriebsleiter.showPop==true,"target":"betriebsleiter"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.betriebsleiter.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.betriebsleiter.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsleiter", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsleiter", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.betriebsleiter!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.betriebsleiter.text),expression:"formular.betriebsleiter.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(26.5390401) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(54.287167) + 'px', 
														'height': 24.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(34.9868651) + 'px', 
		
														'font-size': _vm.formular.betriebsleiter.size + 'px', 
														'text-align': _vm.formular.betriebsleiter.schriftausrichtung, 
														'letter-spacing': _vm.formular.betriebsleiter.letterSpacing + 'px',
														'font-weight': _vm.formular.betriebsleiter.schriftart }),attrs:{"id":"betriebsleiter","type":"text","disabled":_vm.formular.betriebsleiter.isDisabled==true || _vm.formular.betriebsleiter.isDisabled==true},domProps:{"value":(_vm.formular.betriebsleiter.text)},on:{"focus":function($event){_vm.formular.betriebsleiter.showPop=true},"blur":function($event){_vm.formular.betriebsleiter.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.betriebsleiter.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.betriebsleiter, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.betriebsleiterEhegatte)?_c('b-popover',{ref:"betriebsleiterEhegatte",attrs:{"show":_vm.formular.betriebsleiterEhegatte.showPop==true,"target":"betriebsleiterEhegatte"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.betriebsleiterEhegatte.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.betriebsleiterEhegatte.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsleiterEhegatte", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("betriebsleiterEhegatte", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.betriebsleiterEhegatte!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.betriebsleiterEhegatte.text),expression:"formular.betriebsleiterEhegatte.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(26.5390401) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(54.287167) + 'px', 
														'height': 24.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(41.5823551) + 'px', 
		
														'font-size': _vm.formular.betriebsleiterEhegatte.size + 'px', 
														'text-align': _vm.formular.betriebsleiterEhegatte.schriftausrichtung, 
														'letter-spacing': _vm.formular.betriebsleiterEhegatte.letterSpacing + 'px',
														'font-weight': _vm.formular.betriebsleiterEhegatte.schriftart }),attrs:{"id":"betriebsleiterEhegatte","type":"text","disabled":_vm.formular.betriebsleiterEhegatte.isDisabled==true || _vm.formular.betriebsleiterEhegatte.isDisabled==true},domProps:{"value":(_vm.formular.betriebsleiterEhegatte.text)},on:{"focus":function($event){_vm.formular.betriebsleiterEhegatte.showPop=true},"blur":function($event){_vm.formular.betriebsleiterEhegatte.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.betriebsleiterEhegatte.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.betriebsleiterEhegatte, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.4294215) + 'px', 
														top: _vm.gib_hoehe_in_mm(48.9527149) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.5983648) + 'px', 
														top: _vm.gib_hoehe_in_mm(55.5552589) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.4294215) + 'px', 
														top: _vm.gib_hoehe_in_mm(62.2001269) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.4294215) + 'px', 
														top: _vm.gib_hoehe_in_mm(68.8033763) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.4294215) + 'px', 
														top: _vm.gib_hoehe_in_mm(75.4052149) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.4294215) + 'px', 
														top: _vm.gib_hoehe_in_mm(84.9707916) + 'px'})},[_vm._v(" Anzahl ")]),(_vm.formular.fremdarbeiten1)?_c('b-popover',{ref:"fremdarbeiten1",attrs:{"show":_vm.formular.fremdarbeiten1.showPop==true,"target":"fremdarbeiten1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fremdarbeiten1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fremdarbeiten1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fremdarbeiten1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fremdarbeiten1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fremdarbeiten1!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fremdarbeiten1.text),expression:"formular.fremdarbeiten1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(87.9903138) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(82.8121322) + 'px', 
														'height': 27.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(100.4783173) + 'px', 
		
														'font-size': _vm.formular.fremdarbeiten1.size + 'px', 
														'text-align': _vm.formular.fremdarbeiten1.schriftausrichtung, 
														'letter-spacing': _vm.formular.fremdarbeiten1.letterSpacing + 'px',
														'font-weight': _vm.formular.fremdarbeiten1.schriftart }),attrs:{"id":"fremdarbeiten1","type":"text","disabled":_vm.formular.fremdarbeiten1.isDisabled==true || _vm.formular.fremdarbeiten1.isDisabled==true},domProps:{"value":(_vm.formular.fremdarbeiten1.text)},on:{"focus":function($event){_vm.formular.fremdarbeiten1.showPop=true},"blur":function($event){_vm.formular.fremdarbeiten1.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.fremdarbeiten1.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fremdarbeiten1, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.fremdarbeiten2)?_c('b-popover',{ref:"fremdarbeiten2",attrs:{"show":_vm.formular.fremdarbeiten2.showPop==true,"target":"fremdarbeiten2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fremdarbeiten2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fremdarbeiten2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fremdarbeiten2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fremdarbeiten2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fremdarbeiten2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fremdarbeiten2.text),expression:"formular.fremdarbeiten2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(87.9903138) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(82.8121322) + 'px', 
														'height': 27.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(107.3647848) + 'px', 
		
														'font-size': _vm.formular.fremdarbeiten2.size + 'px', 
														'text-align': _vm.formular.fremdarbeiten2.schriftausrichtung, 
														'letter-spacing': _vm.formular.fremdarbeiten2.letterSpacing + 'px',
														'font-weight': _vm.formular.fremdarbeiten2.schriftart }),attrs:{"id":"fremdarbeiten2","type":"text","disabled":_vm.formular.fremdarbeiten2.isDisabled==true || _vm.formular.fremdarbeiten2.isDisabled==true},domProps:{"value":(_vm.formular.fremdarbeiten2.text)},on:{"focus":function($event){_vm.formular.fremdarbeiten2.showPop=true},"blur":function($event){_vm.formular.fremdarbeiten2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.fremdarbeiten2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fremdarbeiten2, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(68.4048253) + 'px'})},[_vm._v(" ständige Arbeitnehmer ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(76.4883566) + 'px'})},[_vm._v(" Teilzeitkräfte ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(103.3746776) + 'px'})},[_vm._v(" Arbeiten, die fremd vergeben werden ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(107.3111623) + 'px'})},[_vm._v(" (z. B. Lohnarbeit) ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(103.7449415) + 'px', 
														top: _vm.gib_hoehe_in_mm(28.564186) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.1832521) + 'px', 
														top: _vm.gib_hoehe_in_mm(35.7476269) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.1832521) + 'px', 
														top: _vm.gib_hoehe_in_mm(42.3508763) + 'px'})},[_vm._v(" Anteil je ")]),(_vm.formular.AK1_1_Prozent)?_c('b-popover',{ref:"AK1_1_Prozent",attrs:{"show":_vm.formular.AK1_1_Prozent.showPop==true,"target":"AK1_1_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_1_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_1_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_1_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_1_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_1_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(35.9567901) + 'px', 
		
														'font-size': _vm.formular.AK1_1_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_1_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_1_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_1_Prozent.schriftart }),attrs:{"id":"AK1_1_Prozent","precision":parseInt(_vm.formular.AK1_1_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_1_Prozent.isDisabled==true || _vm.formular.AK1_1_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_1_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_1_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_1_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_1_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_1_Prozent, "text", $$v)},expression:"formular.AK1_1_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_1_Prozent)?_c('b-popover',{ref:"AK2_1_Prozent",attrs:{"show":_vm.formular.AK2_1_Prozent.showPop==true,"target":"AK2_1_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_1_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_1_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_1_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_1_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_1_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(35.9567901) + 'px', 
		
														'font-size': _vm.formular.AK2_1_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_1_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_1_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_1_Prozent.schriftart }),attrs:{"id":"AK2_1_Prozent","precision":parseInt(_vm.formular.AK2_1_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_1_Prozent.isDisabled==true || _vm.formular.AK2_1_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_1_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_1_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_1_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_1_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_1_Prozent, "text", $$v)},expression:"formular.AK2_1_Prozent.text"}}):_vm._e(),(_vm.formular.AK1_2_Prozent)?_c('b-popover',{ref:"AK1_2_Prozent",attrs:{"show":_vm.formular.AK1_2_Prozent.showPop==true,"target":"AK1_2_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_2_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_2_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_2_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_2_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_2_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(42.5522801) + 'px', 
		
														'font-size': _vm.formular.AK1_2_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_2_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_2_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_2_Prozent.schriftart }),attrs:{"id":"AK1_2_Prozent","precision":parseInt(_vm.formular.AK1_2_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_2_Prozent.isDisabled==true || _vm.formular.AK1_2_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_2_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_2_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_2_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_2_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_2_Prozent, "text", $$v)},expression:"formular.AK1_2_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_2_Prozent)?_c('b-popover',{ref:"AK2_2_Prozent",attrs:{"show":_vm.formular.AK2_2_Prozent.showPop==true,"target":"AK2_2_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_2_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_2_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_2_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_2_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_2_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(42.5522801) + 'px', 
		
														'font-size': _vm.formular.AK2_2_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_2_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_2_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_2_Prozent.schriftart }),attrs:{"id":"AK2_2_Prozent","precision":parseInt(_vm.formular.AK2_2_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_2_Prozent.isDisabled==true || _vm.formular.AK2_2_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_2_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_2_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_2_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_2_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_2_Prozent, "text", $$v)},expression:"formular.AK2_2_Prozent.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(148.9117035) + 'px', 
														top: _vm.gib_hoehe_in_mm(28.564186) + 'px'})},[_vm._v(" Ziel ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(151.4084668) + 'px', 
														top: _vm.gib_hoehe_in_mm(35.7476269) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(151.4084668) + 'px', 
														top: _vm.gib_hoehe_in_mm(42.3508763) + 'px'})},[_vm._v(" Anteil je ")]),(_vm.formular.AK1_3)?_c('b-popover',{ref:"AK1_3",attrs:{"show":_vm.formular.AK1_3.showPop==true,"target":"AK1_3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': _vm.formular.AK1_3.size + 'px', 
														'text-align': _vm.formular.AK1_3.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_3.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_3.schriftart }),attrs:{"id":"AK1_3","precision":parseInt(_vm.formular.AK1_3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_3.isDisabled==true || _vm.formular.AK1_3.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_3.showPop=true},"blur":function($event){_vm.formular.AK1_3.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_3.onylNumber)}},model:{value:(_vm.formular.AK1_3.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_3, "text", $$v)},expression:"formular.AK1_3.text"}}):_vm._e(),(_vm.formular.AK1_3_Prozent)?_c('b-popover',{ref:"AK1_3_Prozent",attrs:{"show":_vm.formular.AK1_3_Prozent.showPop==true,"target":"AK1_3_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_3_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_3_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_3_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_3_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_3_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': _vm.formular.AK1_3_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_3_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_3_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_3_Prozent.schriftart }),attrs:{"id":"AK1_3_Prozent","precision":parseInt(_vm.formular.AK1_3_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_3_Prozent.isDisabled==true || _vm.formular.AK1_3_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_3_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_3_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_3_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_3_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_3_Prozent, "text", $$v)},expression:"formular.AK1_3_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_3)?_c('b-popover',{ref:"AK2_3",attrs:{"show":_vm.formular.AK2_3.showPop==true,"target":"AK2_3"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_3.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_3.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_3", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_3", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_3!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': _vm.formular.AK2_3.size + 'px', 
														'text-align': _vm.formular.AK2_3.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_3.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_3.schriftart }),attrs:{"id":"AK2_3","precision":parseInt(_vm.formular.AK2_3.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_3.isDisabled==true || _vm.formular.AK2_3.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_3.showPop=true},"blur":function($event){_vm.formular.AK2_3.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_3.onylNumber)}},model:{value:(_vm.formular.AK2_3.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_3, "text", $$v)},expression:"formular.AK2_3.text"}}):_vm._e(),(_vm.formular.AK2_3_Prozent)?_c('b-popover',{ref:"AK2_3_Prozent",attrs:{"show":_vm.formular.AK2_3_Prozent.showPop==true,"target":"AK2_3_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_3_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_3_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_3_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_3_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_3_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(49.1583511) + 'px', 
		
														'font-size': _vm.formular.AK2_3_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_3_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_3_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_3_Prozent.schriftart }),attrs:{"id":"AK2_3_Prozent","precision":parseInt(_vm.formular.AK2_3_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_3_Prozent.isDisabled==true || _vm.formular.AK2_3_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_3_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_3_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_3_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_3_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_3_Prozent, "text", $$v)},expression:"formular.AK2_3_Prozent.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.1832521) + 'px', 
														top: _vm.gib_hoehe_in_mm(48.9527149) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(48.9527149) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(151.4084668) + 'px', 
														top: _vm.gib_hoehe_in_mm(48.9527149) + 'px'})},[_vm._v(" Anteil je ")]),(_vm.formular.AK1_5)?_c('b-popover',{ref:"AK1_5",attrs:{"show":_vm.formular.AK1_5.showPop==true,"target":"AK1_5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': _vm.formular.AK1_5.size + 'px', 
														'text-align': _vm.formular.AK1_5.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_5.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_5.schriftart }),attrs:{"id":"AK1_5","precision":parseInt(_vm.formular.AK1_5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_5.isDisabled==true || _vm.formular.AK1_5.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_5.showPop=true},"blur":function($event){_vm.formular.AK1_5.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_5.onylNumber)}},model:{value:(_vm.formular.AK1_5.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_5, "text", $$v)},expression:"formular.AK1_5.text"}}):_vm._e(),(_vm.formular.AK2_5)?_c('b-popover',{ref:"AK2_5",attrs:{"show":_vm.formular.AK2_5.showPop==true,"target":"AK2_5"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_5.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_5.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_5", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_5", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_5!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': _vm.formular.AK2_5.size + 'px', 
														'text-align': _vm.formular.AK2_5.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_5.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_5.schriftart }),attrs:{"id":"AK2_5","precision":parseInt(_vm.formular.AK2_5.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_5.isDisabled==true || _vm.formular.AK2_5.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_5.showPop=true},"blur":function($event){_vm.formular.AK2_5.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_5.onylNumber)}},model:{value:(_vm.formular.AK2_5.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_5, "text", $$v)},expression:"formular.AK2_5.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(105.1832521) + 'px', 
														top: _vm.gib_hoehe_in_mm(62.2001269) + 'px'})},[_vm._v(" Anteil je ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(62.2001269) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(151.4084668) + 'px', 
														top: _vm.gib_hoehe_in_mm(62.2001269) + 'px'})},[_vm._v(" Anteil je ")]),(_vm.formular.AK1_5_Prozent)?_c('b-popover',{ref:"AK1_5_Prozent",attrs:{"show":_vm.formular.AK1_5_Prozent.showPop==true,"target":"AK1_5_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_5_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_5_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_5_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_5_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_5_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8549193) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(118.6439833) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': _vm.formular.AK1_5_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_5_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_5_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_5_Prozent.schriftart }),attrs:{"id":"AK1_5_Prozent","precision":parseInt(_vm.formular.AK1_5_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_5_Prozent.isDisabled==true || _vm.formular.AK1_5_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_5_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_5_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_5_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_5_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_5_Prozent, "text", $$v)},expression:"formular.AK1_5_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_5_Prozent)?_c('b-popover',{ref:"AK2_5_Prozent",attrs:{"show":_vm.formular.AK2_5_Prozent.showPop==true,"target":"AK2_5_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_5_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_5_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_5_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_5_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_5_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(163.1483747) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(62.4092901) + 'px', 
		
														'font-size': _vm.formular.AK2_5_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_5_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_5_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_5_Prozent.schriftart }),attrs:{"id":"AK2_5_Prozent","precision":parseInt(_vm.formular.AK2_5_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_5_Prozent.isDisabled==true || _vm.formular.AK2_5_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_5_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_5_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_5_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_5_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_5_Prozent, "text", $$v)},expression:"formular.AK2_5_Prozent.text"}}):_vm._e(),(_vm.formular.AK1_6)?_c('b-popover',{ref:"AK1_6",attrs:{"show":_vm.formular.AK1_6.showPop==true,"target":"AK1_6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(69.0047801) + 'px', 
		
														'font-size': _vm.formular.AK1_6.size + 'px', 
														'text-align': _vm.formular.AK1_6.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_6.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_6.schriftart }),attrs:{"id":"AK1_6","precision":parseInt(_vm.formular.AK1_6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_6.isDisabled==true || _vm.formular.AK1_6.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_6.showPop=true},"blur":function($event){_vm.formular.AK1_6.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_6.onylNumber)}},model:{value:(_vm.formular.AK1_6.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_6, "text", $$v)},expression:"formular.AK1_6.text"}}):_vm._e(),(_vm.formular.AK2_6)?_c('b-popover',{ref:"AK2_6",attrs:{"show":_vm.formular.AK2_6.showPop==true,"target":"AK2_6"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_6.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_6.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_6", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_6", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_6!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(69.0047801) + 'px', 
		
														'font-size': _vm.formular.AK2_6.size + 'px', 
														'text-align': _vm.formular.AK2_6.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_6.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_6.schriftart }),attrs:{"id":"AK2_6","precision":parseInt(_vm.formular.AK2_6.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_6.isDisabled==true || _vm.formular.AK2_6.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_6.showPop=true},"blur":function($event){_vm.formular.AK2_6.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_6.onylNumber)}},model:{value:(_vm.formular.AK2_6.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_6, "text", $$v)},expression:"formular.AK2_6.text"}}):_vm._e(),(_vm.formular.AK1_7)?_c('b-popover',{ref:"AK1_7",attrs:{"show":_vm.formular.AK1_7.showPop==true,"target":"AK1_7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(75.6365982) + 'px', 
		
														'font-size': _vm.formular.AK1_7.size + 'px', 
														'text-align': _vm.formular.AK1_7.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_7.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_7.schriftart }),attrs:{"id":"AK1_7","precision":parseInt(_vm.formular.AK1_7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_7.isDisabled==true || _vm.formular.AK1_7.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_7.showPop=true},"blur":function($event){_vm.formular.AK1_7.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_7.onylNumber)}},model:{value:(_vm.formular.AK1_7.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_7, "text", $$v)},expression:"formular.AK1_7.text"}}):_vm._e(),(_vm.formular.AK2_7)?_c('b-popover',{ref:"AK2_7",attrs:{"show":_vm.formular.AK2_7.showPop==true,"target":"AK2_7"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_7.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_7.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_7", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_7", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_7!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(75.6365982) + 'px', 
		
														'font-size': _vm.formular.AK2_7.size + 'px', 
														'text-align': _vm.formular.AK2_7.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_7.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_7.schriftart }),attrs:{"id":"AK2_7","precision":parseInt(_vm.formular.AK2_7.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_7.isDisabled==true || _vm.formular.AK2_7.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_7.showPop=true},"blur":function($event){_vm.formular.AK2_7.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_7.onylNumber)}},model:{value:(_vm.formular.AK2_7.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_7, "text", $$v)},expression:"formular.AK2_7.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(107.6810735) + 'px', 
														top: _vm.gib_hoehe_in_mm(74.0938763) + 'px'})},[_vm._v(" Jahresarbeitsstd. ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(153.6096675) + 'px', 
														top: _vm.gib_hoehe_in_mm(74.0938763) + 'px'})},[_vm._v(" Jahresarbeitsstd. ")]),(_vm.formular.AK1_6_Prozent)?_c('b-popover',{ref:"AK1_6_Prozent",attrs:{"show":_vm.formular.AK1_6_Prozent.showPop==true,"target":"AK1_6_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_6_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_6_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_6_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_6_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_6_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(14.6876147) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.8112879) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(78.5700041) + 'px', 
		
														'font-size': _vm.formular.AK1_6_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_6_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_6_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_6_Prozent.schriftart }),attrs:{"id":"AK1_6_Prozent","precision":parseInt(_vm.formular.AK1_6_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_6_Prozent.isDisabled==true || _vm.formular.AK1_6_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_6_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_6_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_6_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_6_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_6_Prozent, "text", $$v)},expression:"formular.AK1_6_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_6_Prozent)?_c('b-popover',{ref:"AK2_6_Prozent",attrs:{"show":_vm.formular.AK2_6_Prozent.showPop==true,"target":"AK2_6_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_6_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_6_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_6_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_6_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_6_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(13.7917567) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.2111846) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(78.5700041) + 'px', 
		
														'font-size': _vm.formular.AK2_6_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_6_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_6_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_6_Prozent.schriftart }),attrs:{"id":"AK2_6_Prozent","precision":parseInt(_vm.formular.AK2_6_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_6_Prozent.isDisabled==true || _vm.formular.AK2_6_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_6_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_6_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_6_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_6_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_6_Prozent, "text", $$v)},expression:"formular.AK2_6_Prozent.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(68.8033763) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(75.4052149) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(131.3045668) + 'px', 
														top: _vm.gib_hoehe_in_mm(84.9707916) + 'px'})},[_vm._v(" Anzahl ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(86.0535806) + 'px'})},[_vm._v(" nicht ständige Arbeitnehmer (z. B. Saison) ")]),(_vm.formular.AK1_8)?_c('b-popover',{ref:"AK1_8",attrs:{"show":_vm.formular.AK1_8.showPop==true,"target":"AK1_8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(85.2018222) + 'px', 
		
														'font-size': _vm.formular.AK1_8.size + 'px', 
														'text-align': _vm.formular.AK1_8.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_8.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_8.schriftart }),attrs:{"id":"AK1_8","precision":parseInt(_vm.formular.AK1_8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_8.isDisabled==true || _vm.formular.AK1_8.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_8.showPop=true},"blur":function($event){_vm.formular.AK1_8.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_8.onylNumber)}},model:{value:(_vm.formular.AK1_8.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_8, "text", $$v)},expression:"formular.AK1_8.text"}}):_vm._e(),(_vm.formular.AK2_8)?_c('b-popover',{ref:"AK2_8",attrs:{"show":_vm.formular.AK2_8.showPop==true,"target":"AK2_8"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_8.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_8.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_8", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_8", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_8!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(85.2018222) + 'px', 
		
														'font-size': _vm.formular.AK2_8.size + 'px', 
														'text-align': _vm.formular.AK2_8.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_8.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_8.schriftart }),attrs:{"id":"AK2_8","precision":parseInt(_vm.formular.AK2_8.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_8.isDisabled==true || _vm.formular.AK2_8.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_8.showPop=true},"blur":function($event){_vm.formular.AK2_8.showPop=false},"input":_vm.AK_neu_zaehlen,"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_8.onylNumber)}},model:{value:(_vm.formular.AK2_8.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_8, "text", $$v)},expression:"formular.AK2_8.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(107.6810735) + 'px', 
														top: _vm.gib_hoehe_in_mm(83.659453) + 'px'})},[_vm._v(" Jahresarbeitsstd. ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(153.6096675) + 'px', 
														top: _vm.gib_hoehe_in_mm(83.659453) + 'px'})},[_vm._v(" Jahresarbeitsstd. ")]),(_vm.formular.AK1_7_Prozent)?_c('b-popover',{ref:"AK1_7_Prozent",attrs:{"show":_vm.formular.AK1_7_Prozent.showPop==true,"target":"AK1_7_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_7_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_7_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_7_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_7_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_7_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(14.6876147) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(113.8112879) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(88.1352281) + 'px', 
		
														'font-size': _vm.formular.AK1_7_Prozent.size + 'px', 
														'text-align': _vm.formular.AK1_7_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_7_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_7_Prozent.schriftart }),attrs:{"id":"AK1_7_Prozent","precision":parseInt(_vm.formular.AK1_7_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_7_Prozent.isDisabled==true || _vm.formular.AK1_7_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_7_Prozent.showPop=true},"blur":function($event){_vm.formular.AK1_7_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_7_Prozent.onylNumber)}},model:{value:(_vm.formular.AK1_7_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_7_Prozent, "text", $$v)},expression:"formular.AK1_7_Prozent.text"}}):_vm._e(),(_vm.formular.AK2_7_Prozent)?_c('b-popover',{ref:"AK2_7_Prozent",attrs:{"show":_vm.formular.AK2_7_Prozent.showPop==true,"target":"AK2_7_Prozent"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_7_Prozent.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_7_Prozent.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_7_Prozent", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_7_Prozent", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_7_Prozent!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(13.7917567) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(159.2111846) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(88.1352281) + 'px', 
		
														'font-size': _vm.formular.AK2_7_Prozent.size + 'px', 
														'text-align': _vm.formular.AK2_7_Prozent.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_7_Prozent.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_7_Prozent.schriftart }),attrs:{"id":"AK2_7_Prozent","precision":parseInt(_vm.formular.AK2_7_Prozent.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_7_Prozent.isDisabled==true || _vm.formular.AK2_7_Prozent.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_7_Prozent.showPop=true},"blur":function($event){_vm.formular.AK2_7_Prozent.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_7_Prozent.onylNumber)}},model:{value:(_vm.formular.AK2_7_Prozent.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_7_Prozent, "text", $$v)},expression:"formular.AK2_7_Prozent.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.4144746) + 'px', 
														top: _vm.gib_hoehe_in_mm(94.3917613) + 'px'})},[_vm._v(" Anzahl der Arbeitskräfte insgesamt ")]),(_vm.formular.AK1_summe)?_c('b-popover',{ref:"AK1_summe",attrs:{"show":_vm.formular.AK1_summe.showPop==true,"target":"AK1_summe"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK1_summe.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK1_summe.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_summe", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK1_summe", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK1_summe!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.855272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(91.299505) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(95.0142889) + 'px', 
		
														'font-size': _vm.formular.AK1_summe.size + 'px', 
														'text-align': _vm.formular.AK1_summe.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK1_summe.letterSpacing + 'px',
														'font-weight': _vm.formular.AK1_summe.schriftart }),attrs:{"id":"AK1_summe","precision":parseInt(_vm.formular.AK1_summe.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK1_summe.isDisabled==true || _vm.formular.AK1_summe.isDisabled==true},on:{"focus":function($event){_vm.formular.AK1_summe.showPop=true},"blur":function($event){_vm.formular.AK1_summe.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK1_summe.onylNumber)}},model:{value:(_vm.formular.AK1_summe.text),callback:function ($$v) {_vm.$set(_vm.formular.AK1_summe, "text", $$v)},expression:"formular.AK1_summe.text"}}):_vm._e(),(_vm.formular.AKSelbstIst_check!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(105.5430061) + 'px',top: _vm.gib_hoehe_in_mm(94.7642889) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.AKSelbstIst_check.text),callback:function ($$v) {_vm.$set(_vm.formular.AKSelbstIst_check, "text", $$v)},expression:"formular.AKSelbstIst_check.text"}},[_vm._v(" selbst eing. ")]):_vm._e(),(_vm.formular.AK2_summe)?_c('b-popover',{ref:"AK2_summe",attrs:{"show":_vm.formular.AK2_summe.showPop==true,"target":"AK2_summe"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.AK2_summe.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.AK2_summe.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_summe", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("AK2_summe", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.AK2_summe!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(9.8545666) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(138.6357247) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(95.0142889) + 'px', 
		
														'font-size': _vm.formular.AK2_summe.size + 'px', 
														'text-align': _vm.formular.AK2_summe.schriftausrichtung, 
														'letter-spacing': _vm.formular.AK2_summe.letterSpacing + 'px',
														'font-weight': _vm.formular.AK2_summe.schriftart }),attrs:{"id":"AK2_summe","precision":parseInt(_vm.formular.AK2_summe.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.AK2_summe.isDisabled==true || _vm.formular.AK2_summe.isDisabled==true},on:{"focus":function($event){_vm.formular.AK2_summe.showPop=true},"blur":function($event){_vm.formular.AK2_summe.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.AK2_summe.onylNumber)}},model:{value:(_vm.formular.AK2_summe.text),callback:function ($$v) {_vm.$set(_vm.formular.AK2_summe, "text", $$v)},expression:"formular.AK2_summe.text"}}):_vm._e(),(_vm.formular.AKSelbstSoll_check!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'10px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(152.8795785) + 'px',top: _vm.gib_hoehe_in_mm(94.7642889) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.AKSelbstSoll_check.text),callback:function ($$v) {_vm.$set(_vm.formular.AKSelbstSoll_check, "text", $$v)},expression:"formular.AKSelbstSoll_check.text"}},[_vm._v(" selbst eing. ")]):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8454371) + 'px', 
														top: _vm.gib_hoehe_in_mm(119.9223035) + 'px'})},[_vm._v(" 7 Betriebsform ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(126.2719616) + 'px'})},[_vm._v(" Vollerwerbsbetrieb ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(103.7449415) + 'px', 
														top: _vm.gib_hoehe_in_mm(120.3758757) + 'px'})},[_vm._v(" Ist ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(148.9117035) + 'px', 
														top: _vm.gib_hoehe_in_mm(120.3758757) + 'px'})},[_vm._v(" Ziel ")]),(_vm.formular.vollerwerb_IST!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(83.4294215) + 'px',top: _vm.gib_hoehe_in_mm(127.1223977) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.vollerwerb_IST.text),callback:function ($$v) {_vm.$set(_vm.formular.vollerwerb_IST, "text", $$v)},expression:"formular.vollerwerb_IST.text"}}):_vm._e(),(_vm.formular.vollerwerb_SOLL!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(131.0474485) + 'px',top: _vm.gib_hoehe_in_mm(127.1223977) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.vollerwerb_SOLL.text),callback:function ($$v) {_vm.$set(_vm.formular.vollerwerb_SOLL, "text", $$v)},expression:"formular.vollerwerb_SOLL.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(132.8745056) + 'px'})},[_vm._v(" mit Zuerwerb aus ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(136.1338063) + 'px'})},[_vm._v(" (z. B. Fremdenzimmer, Lohnunterneh ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(139.3924016) + 'px'})},[_vm._v(" men, landwirtschaftliche Werkstätten, ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(142.6090256) + 'px'})},[_vm._v(" Handel mit Fremderzeugnissen) ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(83.0481528) + 'px', 
														top: _vm.gib_hoehe_in_mm(132.8074926) + 'px'})},[_vm._v(" Art der Tätigkeit ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(130.6700595) + 'px', 
														top: _vm.gib_hoehe_in_mm(132.8074926) + 'px'})},[_vm._v(" Art der Tätigkeit ")]),(_vm.formular.zuerwerbIST)?_c('b-popover',{ref:"zuerwerbIST",attrs:{"show":_vm.formular.zuerwerbIST.showPop==true,"target":"zuerwerbIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerbIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerbIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerbIST!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.zuerwerbIST.text),expression:"formular.zuerwerbIST.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(136.7446948) + 'px', 
		
														'font-size': _vm.formular.zuerwerbIST.size + 'px', 
														'text-align': _vm.formular.zuerwerbIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerbIST.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerbIST.schriftart }),attrs:{"id":"zuerwerbIST","type":"text","disabled":_vm.formular.zuerwerbIST.isDisabled==true || _vm.formular.zuerwerbIST.isDisabled==true},domProps:{"value":(_vm.formular.zuerwerbIST.text)},on:{"focus":function($event){_vm.formular.zuerwerbIST.showPop=true},"blur":function($event){_vm.formular.zuerwerbIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerbIST.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.zuerwerbIST, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.zuerwerbSOLL)?_c('b-popover',{ref:"zuerwerbSOLL",attrs:{"show":_vm.formular.zuerwerbSOLL.showPop==true,"target":"zuerwerbSOLL"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerbSOLL.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerbSOLL.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbSOLL", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbSOLL", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerbSOLL!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.zuerwerbSOLL.text),expression:"formular.zuerwerbSOLL.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(136.7446948) + 'px', 
		
														'font-size': _vm.formular.zuerwerbSOLL.size + 'px', 
														'text-align': _vm.formular.zuerwerbSOLL.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerbSOLL.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerbSOLL.schriftart }),attrs:{"id":"zuerwerbSOLL","type":"text","disabled":_vm.formular.zuerwerbSOLL.isDisabled==true || _vm.formular.zuerwerbSOLL.isDisabled==true},domProps:{"value":(_vm.formular.zuerwerbSOLL.text)},on:{"focus":function($event){_vm.formular.zuerwerbSOLL.showPop=true},"blur":function($event){_vm.formular.zuerwerbSOLL.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerbSOLL.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.zuerwerbSOLL, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.zuerwerbIST2)?_c('b-popover',{ref:"zuerwerbIST2",attrs:{"show":_vm.formular.zuerwerbIST2.showPop==true,"target":"zuerwerbIST2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerbIST2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerbIST2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbIST2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbIST2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerbIST2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.zuerwerbIST2.text),expression:"formular.zuerwerbIST2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(143.7221589) + 'px', 
		
														'font-size': _vm.formular.zuerwerbIST2.size + 'px', 
														'text-align': _vm.formular.zuerwerbIST2.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerbIST2.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerbIST2.schriftart }),attrs:{"id":"zuerwerbIST2","type":"text","disabled":_vm.formular.zuerwerbIST2.isDisabled==true || _vm.formular.zuerwerbIST2.isDisabled==true},domProps:{"value":(_vm.formular.zuerwerbIST2.text)},on:{"focus":function($event){_vm.formular.zuerwerbIST2.showPop=true},"blur":function($event){_vm.formular.zuerwerbIST2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerbIST2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.zuerwerbIST2, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.zuerwerbSOLL2)?_c('b-popover',{ref:"zuerwerbSOLL2",attrs:{"show":_vm.formular.zuerwerbSOLL2.showPop==true,"target":"zuerwerbSOLL2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerbSOLL2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerbSOLL2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbSOLL2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerbSOLL2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerbSOLL2!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.zuerwerbSOLL2.text),expression:"formular.zuerwerbSOLL2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(143.7221589) + 'px', 
		
														'font-size': _vm.formular.zuerwerbSOLL2.size + 'px', 
														'text-align': _vm.formular.zuerwerbSOLL2.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerbSOLL2.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerbSOLL2.schriftart }),attrs:{"id":"zuerwerbSOLL2","type":"text","disabled":_vm.formular.zuerwerbSOLL2.isDisabled==true || _vm.formular.zuerwerbSOLL2.isDisabled==true},domProps:{"value":(_vm.formular.zuerwerbSOLL2.text)},on:{"focus":function($event){_vm.formular.zuerwerbSOLL2.showPop=true},"blur":function($event){_vm.formular.zuerwerbSOLL2.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerbSOLL2.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.zuerwerbSOLL2, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.6684186) + 'px', 
														top: _vm.gib_hoehe_in_mm(149.7617816) + 'px'})},[_vm._v(" Anteil des Zuerwerbs am Gesamtbetrieb ")]),(_vm.formular.zuerwerGesamtProzIST)?_c('b-popover',{ref:"zuerwerGesamtProzIST",attrs:{"show":_vm.formular.zuerwerGesamtProzIST.showPop==true,"target":"zuerwerGesamtProzIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerGesamtProzIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerGesamtProzIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerGesamtProzIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerGesamtProzIST!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.4097164) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(117.907193) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.9902478) + 'px', 
		
														'font-size': _vm.formular.zuerwerGesamtProzIST.size + 'px', 
														'text-align': _vm.formular.zuerwerGesamtProzIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerGesamtProzIST.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerGesamtProzIST.schriftart }),attrs:{"id":"zuerwerGesamtProzIST","precision":parseInt(_vm.formular.zuerwerGesamtProzIST.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.zuerwerGesamtProzIST.isDisabled==true || _vm.formular.zuerwerGesamtProzIST.isDisabled==true},on:{"focus":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=true},"blur":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerGesamtProzIST.onylNumber)}},model:{value:(_vm.formular.zuerwerGesamtProzIST.text),callback:function ($$v) {_vm.$set(_vm.formular.zuerwerGesamtProzIST, "text", $$v)},expression:"formular.zuerwerGesamtProzIST.text"}}):_vm._e(),(_vm.formular.zuerwerGesamtProzIST)?_c('b-popover',{ref:"zuerwerGesamtProzIST",attrs:{"show":_vm.formular.zuerwerGesamtProzIST.showPop==true,"target":"zuerwerGesamtProzIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.zuerwerGesamtProzIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerGesamtProzIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("zuerwerGesamtProzIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.zuerwerGesamtProzIST!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(10.4086583) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(162.4115844) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(150.9902478) + 'px', 
		
														'font-size': _vm.formular.zuerwerGesamtProzIST.size + 'px', 
														'text-align': _vm.formular.zuerwerGesamtProzIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.zuerwerGesamtProzIST.letterSpacing + 'px',
														'font-weight': _vm.formular.zuerwerGesamtProzIST.schriftart }),attrs:{"id":"zuerwerGesamtProzIST","precision":parseInt(_vm.formular.zuerwerGesamtProzIST.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.zuerwerGesamtProzIST.isDisabled==true || _vm.formular.zuerwerGesamtProzIST.isDisabled==true},on:{"focus":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=true},"blur":function($event){_vm.formular.zuerwerGesamtProzIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.zuerwerGesamtProzIST.onylNumber)}},model:{value:(_vm.formular.zuerwerGesamtProzIST.text),callback:function ($$v) {_vm.$set(_vm.formular.zuerwerGesamtProzIST, "text", $$v)},expression:"formular.zuerwerGesamtProzIST.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(156.4066496) + 'px'})},[_vm._v(" Nebenerwerbsbetrieb ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(159.6236263) + 'px'})},[_vm._v(" (ankreuzen ob IST oder ZIEL) ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(164.582941) + 'px'})},[_vm._v(" Art des Haupterwerbs ")]),(_vm.formular.nebenerwerb_IST!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(83.4294215) + 'px',top: _vm.gib_hoehe_in_mm(157.2468574) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.nebenerwerb_IST.text),callback:function ($$v) {_vm.$set(_vm.formular.nebenerwerb_IST, "text", $$v)},expression:"formular.nebenerwerb_IST.text"}}):_vm._e(),(_vm.formular.nebenerwerb_SOLL!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(131.0474485) + 'px',top: _vm.gib_hoehe_in_mm(157.2468574) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.nebenerwerb_SOLL.text),callback:function ($$v) {_vm.$set(_vm.formular.nebenerwerb_SOLL, "text", $$v)},expression:"formular.nebenerwerb_SOLL.text"}}):_vm._e(),(_vm.formular.artHauptIST)?_c('b-popover',{ref:"artHauptIST",attrs:{"show":_vm.formular.artHauptIST.showPop==true,"target":"artHauptIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.artHauptIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.artHauptIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artHauptIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artHauptIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.artHauptIST!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.artHauptIST.text),expression:"formular.artHauptIST.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(45.0187566) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(83.2981528) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(165.4862178) + 'px', 
		
														'font-size': _vm.formular.artHauptIST.size + 'px', 
														'text-align': _vm.formular.artHauptIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.artHauptIST.letterSpacing + 'px',
														'font-weight': _vm.formular.artHauptIST.schriftart }),attrs:{"id":"artHauptIST","type":"text","disabled":_vm.formular.artHauptIST.isDisabled==true || _vm.formular.artHauptIST.isDisabled==true},domProps:{"value":(_vm.formular.artHauptIST.text)},on:{"focus":function($event){_vm.formular.artHauptIST.showPop=true},"blur":function($event){_vm.formular.artHauptIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.artHauptIST.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.artHauptIST, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.artHauptSOLL)?_c('b-popover',{ref:"artHauptSOLL",attrs:{"show":_vm.formular.artHauptSOLL.showPop==true,"target":"artHauptSOLL"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.artHauptSOLL.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.artHauptSOLL.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artHauptSOLL", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("artHauptSOLL", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.artHauptSOLL!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.artHauptSOLL.text),expression:"formular.artHauptSOLL.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(42.2214929) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9204122) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(165.4862178) + 'px', 
		
														'font-size': _vm.formular.artHauptSOLL.size + 'px', 
														'text-align': _vm.formular.artHauptSOLL.schriftausrichtung, 
														'letter-spacing': _vm.formular.artHauptSOLL.letterSpacing + 'px',
														'font-weight': _vm.formular.artHauptSOLL.schriftart }),attrs:{"id":"artHauptSOLL","type":"text","disabled":_vm.formular.artHauptSOLL.isDisabled==true || _vm.formular.artHauptSOLL.isDisabled==true},domProps:{"value":(_vm.formular.artHauptSOLL.text)},on:{"focus":function($event){_vm.formular.artHauptSOLL.showPop=true},"blur":function($event){_vm.formular.artHauptSOLL.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.artHauptSOLL.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.artHauptSOLL, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(171.185485) + 'px'})},[_vm._v(" Jahreseinkünfte aus Haupterwerb ")]),(_vm.formular.einkueftHauptIST)?_c('b-popover',{ref:"einkueftHauptIST",attrs:{"show":_vm.formular.einkueftHauptIST.showPop==true,"target":"einkueftHauptIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.einkueftHauptIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.einkueftHauptIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftHauptIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftHauptIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.einkueftHauptIST!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(20.3082419) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(83.2981528) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(172.8724612) + 'px', 
		
														'font-size': _vm.formular.einkueftHauptIST.size + 'px', 
														'text-align': _vm.formular.einkueftHauptIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.einkueftHauptIST.letterSpacing + 'px',
														'font-weight': _vm.formular.einkueftHauptIST.schriftart }),attrs:{"id":"einkueftHauptIST","precision":parseInt(_vm.formular.einkueftHauptIST.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.einkueftHauptIST.isDisabled==true || _vm.formular.einkueftHauptIST.isDisabled==true},on:{"focus":function($event){_vm.formular.einkueftHauptIST.showPop=true},"blur":function($event){_vm.formular.einkueftHauptIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.einkueftHauptIST.onylNumber)}},model:{value:(_vm.formular.einkueftHauptIST.text),callback:function ($$v) {_vm.$set(_vm.formular.einkueftHauptIST, "text", $$v)},expression:"formular.einkueftHauptIST.text"}}):_vm._e(),(_vm.formular.einkueftHauptSOLL)?_c('b-popover',{ref:"einkueftHauptSOLL",attrs:{"show":_vm.formular.einkueftHauptSOLL.showPop==true,"target":"einkueftHauptSOLL"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.einkueftHauptSOLL.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.einkueftHauptSOLL.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftHauptSOLL", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftHauptSOLL", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.einkueftHauptSOLL!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.7463246) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9204122) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(172.8724612) + 'px', 
		
														'font-size': _vm.formular.einkueftHauptSOLL.size + 'px', 
														'text-align': _vm.formular.einkueftHauptSOLL.schriftausrichtung, 
														'letter-spacing': _vm.formular.einkueftHauptSOLL.letterSpacing + 'px',
														'font-weight': _vm.formular.einkueftHauptSOLL.schriftart }),attrs:{"id":"einkueftHauptSOLL","precision":parseInt(_vm.formular.einkueftHauptSOLL.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.einkueftHauptSOLL.isDisabled==true || _vm.formular.einkueftHauptSOLL.isDisabled==true},on:{"focus":function($event){_vm.formular.einkueftHauptSOLL.showPop=true},"blur":function($event){_vm.formular.einkueftHauptSOLL.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.einkueftHauptSOLL.onylNumber)}},model:{value:(_vm.formular.einkueftHauptSOLL.text),callback:function ($$v) {_vm.$set(_vm.formular.einkueftHauptSOLL, "text", $$v)},expression:"formular.einkueftHauptSOLL.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(104.639036) + 'px', 
														top: _vm.gib_hoehe_in_mm(172.5193964) + 'px'})},[_vm._v(" EUR ")]),_c('div',{style:({
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(104.639036) + 'px', 
														top: _vm.gib_hoehe_in_mm(179.1173553) + 'px'})},[_vm._v(" EUR ")]),_c('div',{style:({
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(149.0457295) + 'px', 
														top: _vm.gib_hoehe_in_mm(172.5193964) + 'px'})},[_vm._v(" EUR ")]),_c('div',{style:({
														fontSize:'8.9925px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(149.0457295) + 'px', 
														top: _vm.gib_hoehe_in_mm(179.1173553) + 'px'})},[_vm._v(" EUR ")]),(_vm.formular.einkueftNebenIST)?_c('b-popover',{ref:"einkueftNebenIST",attrs:{"show":_vm.formular.einkueftNebenIST.showPop==true,"target":"einkueftNebenIST"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.einkueftNebenIST.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.einkueftNebenIST.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftNebenIST", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftNebenIST", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.einkueftNebenIST!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(20.3082419) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(83.2981528) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(179.2372854) + 'px', 
		
														'font-size': _vm.formular.einkueftNebenIST.size + 'px', 
														'text-align': _vm.formular.einkueftNebenIST.schriftausrichtung, 
														'letter-spacing': _vm.formular.einkueftNebenIST.letterSpacing + 'px',
														'font-weight': _vm.formular.einkueftNebenIST.schriftart }),attrs:{"id":"einkueftNebenIST","precision":parseInt(_vm.formular.einkueftNebenIST.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.einkueftNebenIST.isDisabled==true || _vm.formular.einkueftNebenIST.isDisabled==true},on:{"focus":function($event){_vm.formular.einkueftNebenIST.showPop=true},"blur":function($event){_vm.formular.einkueftNebenIST.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.einkueftNebenIST.onylNumber)}},model:{value:(_vm.formular.einkueftNebenIST.text),callback:function ($$v) {_vm.$set(_vm.formular.einkueftNebenIST, "text", $$v)},expression:"formular.einkueftNebenIST.text"}}):_vm._e(),(_vm.formular.einkueftNebenSOLL)?_c('b-popover',{ref:"einkueftNebenSOLL",attrs:{"show":_vm.formular.einkueftNebenSOLL.showPop==true,"target":"einkueftNebenSOLL"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.einkueftNebenSOLL.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.einkueftNebenSOLL.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftNebenSOLL", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("einkueftNebenSOLL", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.einkueftNebenSOLL!=undefined)?_c('vue-numeric',{staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(16.7463246) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(130.9204122) + 'px', 
														'height': 15.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(179.2372854) + 'px', 
		
														'font-size': _vm.formular.einkueftNebenSOLL.size + 'px', 
														'text-align': _vm.formular.einkueftNebenSOLL.schriftausrichtung, 
														'letter-spacing': _vm.formular.einkueftNebenSOLL.letterSpacing + 'px',
														'font-weight': _vm.formular.einkueftNebenSOLL.schriftart }),attrs:{"id":"einkueftNebenSOLL","precision":parseInt(_vm.formular.einkueftNebenSOLL.anzahlStellen),"currency":"","decimal-separator":",","separator":".","disabled":_vm.formular.einkueftNebenSOLL.isDisabled==true || _vm.formular.einkueftNebenSOLL.isDisabled==true},on:{"focus":function($event){_vm.formular.einkueftNebenSOLL.showPop=true},"blur":function($event){_vm.formular.einkueftNebenSOLL.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.einkueftNebenSOLL.onylNumber)}},model:{value:(_vm.formular.einkueftNebenSOLL.text),callback:function ($$v) {_vm.$set(_vm.formular.einkueftNebenSOLL, "text", $$v)},expression:"formular.einkueftNebenSOLL.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(177.788029) + 'px'})},[_vm._v(" Jahreseinkünfte aus ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(181.004653) + 'px'})},[_vm._v(" Nebenerwerbsbetrieb ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(185.9562083) + 'px'})},[_vm._v(" Wirtschaftlichkeitsrechnung ")]),(_vm.formular.wibebeig!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(83.4294215) + 'px',top: _vm.gib_hoehe_in_mm(186.795358) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.wibebeig.text),callback:function ($$v) {_vm.$set(_vm.formular.wibebeig, "text", $$v)},expression:"formular.wibebeig.text"}}):_vm._e(),(_vm.formular.wibenbeig!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(112.0016485) + 'px',top: _vm.gib_hoehe_in_mm(186.795358) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.wibenbeig.text),callback:function ($$v) {_vm.$set(_vm.formular.wibenbeig, "text", $$v)},expression:"formular.wibenbeig.text"}}):_vm._e(),(_vm.formular.wiBeText)?_c('b-popover',{ref:"wiBeText",attrs:{"show":_vm.formular.wiBeText.showPop==true,"target":"wiBeText"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.wiBeText.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.wiBeText.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wiBeText", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("wiBeText", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.wiBeText!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.wiBeText.text),expression:"formular.wiBeText.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(29.1744145) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(144.01052) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(187.1455248) + 'px', 
		
														'font-size': _vm.formular.wiBeText.size + 'px', 
														'text-align': _vm.formular.wiBeText.schriftausrichtung, 
														'letter-spacing': _vm.formular.wiBeText.letterSpacing + 'px',
														'font-weight': _vm.formular.wiBeText.schriftart }),attrs:{"id":"wiBeText","type":"text","disabled":_vm.formular.wiBeText.isDisabled==true || _vm.formular.wiBeText.isDisabled==true},domProps:{"value":(_vm.formular.wiBeText.text)},on:{"focus":function($event){_vm.formular.wiBeText.showPop=true},"blur":function($event){_vm.formular.wiBeText.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.wiBeText.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.wiBeText, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(87.6618215) + 'px', 
														top: _vm.gib_hoehe_in_mm(186.905324) + 'px'})},[_vm._v(" beigefügt ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(116.2372228) + 'px', 
														top: _vm.gib_hoehe_in_mm(186.905324) + 'px'})},[_vm._v(" nicht beigefügt ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(192.5587523) + 'px'})},[_vm._v(" Fortbestand des Betriebes gesichert ")]),(_vm.formular.fortbestandErb!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(83.4294215) + 'px',top: _vm.gib_hoehe_in_mm(193.397902) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.fortbestandErb.text),callback:function ($$v) {_vm.$set(_vm.formular.fortbestandErb, "text", $$v)},expression:"formular.fortbestandErb.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(87.6618215) + 'px', 
														top: _vm.gib_hoehe_in_mm(193.507868) + 'px'})},[_vm._v(" durch Erbfolge ")]),(_vm.formular.fortbestandX!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(112.0016485) + 'px',top: _vm.gib_hoehe_in_mm(193.6980497) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.fortbestandX.text),callback:function ($$v) {_vm.$set(_vm.formular.fortbestandX, "text", $$v)},expression:"formular.fortbestandX.text"}}):_vm._e(),(_vm.formular.fortbestandText)?_c('b-popover',{ref:"fortbestandText",attrs:{"show":_vm.formular.fortbestandText.showPop==true,"target":"fortbestandText"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.fortbestandText.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.fortbestandText.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fortbestandText", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("fortbestandText", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.fortbestandText!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.fortbestandText.text),expression:"formular.fortbestandText.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(50.0168683) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(123.1680662) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(193.4560332) + 'px', 
		
														'font-size': _vm.formular.fortbestandText.size + 'px', 
														'text-align': _vm.formular.fortbestandText.schriftausrichtung, 
														'letter-spacing': _vm.formular.fortbestandText.letterSpacing + 'px',
														'font-weight': _vm.formular.fortbestandText.schriftart }),attrs:{"id":"fortbestandText","type":"text","disabled":_vm.formular.fortbestandText.isDisabled==true || _vm.formular.fortbestandText.isDisabled==true},domProps:{"value":(_vm.formular.fortbestandText.text)},on:{"focus":function($event){_vm.formular.fortbestandText.showPop=true},"blur":function($event){_vm.formular.fortbestandText.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.fortbestandText.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.fortbestandText, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(116.2372228) + 'px', 
														top: _vm.gib_hoehe_in_mm(193.804136) + 'px'})},[_vm._v(" durch ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(199.1612963) + 'px'})},[_vm._v(" Betriebsnachfolger, Name und ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.5834179) + 'px', 
														top: _vm.gib_hoehe_in_mm(202.4202443) + 'px'})},[_vm._v(" Ausbildung als ")]),(_vm.formular.nachfolgerAusbil!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(83.4294215) + 'px',top: _vm.gib_hoehe_in_mm(201.6133431) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.nachfolgerAusbil.text),callback:function ($$v) {_vm.$set(_vm.formular.nachfolgerAusbil, "text", $$v)},expression:"formular.nachfolgerAusbil.text"}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(87.6618215) + 'px', 
														top: _vm.gib_hoehe_in_mm(201.718724) + 'px'})},[_vm._v(" Ausbildung ist ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(88.1697095) + 'px', 
														top: _vm.gib_hoehe_in_mm(204.3847833) + 'px'})},[_vm._v(" abgeschlossen ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(111.6172055) + 'px', 
														top: _vm.gib_hoehe_in_mm(199.094636) + 'px'})},[_vm._v(" Ausbildung wird abgeschlossen am ")]),(_vm.formular.nachfolgerAusbilAm!=undefined)?_c('b-form-checkbox',{staticClass:"size_10 input_formular",staticStyle:{"min-height":"1rem !important"},style:({position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: _vm.spalte_breit_mm(112.0016485) + 'px',top: _vm.gib_hoehe_in_mm(204.1485507) + 'px'}),attrs:{"size":"sm","disabled":false,"value":"1","unchecked-value":"0"},model:{value:(_vm.formular.nachfolgerAusbilAm.text),callback:function ($$v) {_vm.$set(_vm.formular.nachfolgerAusbilAm, "text", $$v)},expression:"formular.nachfolgerAusbilAm.text"}}):_vm._e(),(_vm.formular.nachfolgerAusbilText)?_c('b-popover',{ref:"nachfolgerAusbilText",attrs:{"show":_vm.formular.nachfolgerAusbilText.showPop==true,"target":"nachfolgerAusbilText"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.nachfolgerAusbilText.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.nachfolgerAusbilText.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("nachfolgerAusbilText", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("nachfolgerAusbilText", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.nachfolgerAusbilText!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.nachfolgerAusbilText.text),expression:"formular.nachfolgerAusbilText.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(50.0168683) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(123.1680662) + 'px', 
														'height': 18.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(203.4540201) + 'px', 
		
														'font-size': _vm.formular.nachfolgerAusbilText.size + 'px', 
														'text-align': _vm.formular.nachfolgerAusbilText.schriftausrichtung, 
														'letter-spacing': _vm.formular.nachfolgerAusbilText.letterSpacing + 'px',
														'font-weight': _vm.formular.nachfolgerAusbilText.schriftart }),attrs:{"id":"nachfolgerAusbilText","type":"text","disabled":_vm.formular.nachfolgerAusbilText.isDisabled==true || _vm.formular.nachfolgerAusbilText.isDisabled==true},domProps:{"value":(_vm.formular.nachfolgerAusbilText.text)},on:{"focus":function($event){_vm.formular.nachfolgerAusbilText.showPop=true},"blur":function($event){_vm.formular.nachfolgerAusbilText.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.nachfolgerAusbilText.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.nachfolgerAusbilText, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.8454371) + 'px', 
														top: _vm.gib_hoehe_in_mm(214.3118775) + 'px'})},[_vm._v(" 8 Sonstiges ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.0335586) + 'px', 
														top: _vm.gib_hoehe_in_mm(225.1235433) + 'px'})},[_vm._v(" Angaben und Hinweise, die zur ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.0335586) + 'px', 
														top: _vm.gib_hoehe_in_mm(227.5360113) + 'px'})},[_vm._v(" Beurteilung des Vorhabens ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(29.0335586) + 'px', 
														top: _vm.gib_hoehe_in_mm(230.0013843) + 'px'})},[_vm._v(" notwendig sind ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(109.8453693) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(63.295542) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(220.6919442) + 'px'})},[(_vm.formular.sonstiges1)?_c('b-popover',{ref:"sonstiges1",attrs:{"show":_vm.formular.sonstiges1.showPop==true,"target":"sonstiges1"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstiges1.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstiges1.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstiges1", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstiges1", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstiges1!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.sonstiges1.text),expression:"formular.sonstiges1.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.sonstiges1.size + 'px',
														'height': _vm.formular.sonstiges1.height + 'px',
														'text-align': _vm.formular.sonstiges1.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.sonstiges1.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstiges1.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.sonstiges1.isDisabled==true || _vm.formular.sonstiges1.isDisabled=='true',"id":"sonstiges1"},domProps:{"value":(_vm.formular.sonstiges1.text)},on:{"focus":function($event){_vm.formular.sonstiges1.showPop=true},"blur":function($event){_vm.formular.sonstiges1.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.sonstiges1, "text", $event.target.value)}}}):_vm._e()],1),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(109.8453693) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(63.295542) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(229.7461059) + 'px'})},[(_vm.formular.sonstiges2)?_c('b-popover',{ref:"sonstiges2",attrs:{"show":_vm.formular.sonstiges2.showPop==true,"target":"sonstiges2"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.sonstiges2.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.sonstiges2.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstiges2", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("sonstiges2", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.sonstiges2!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.sonstiges2.text),expression:"formular.sonstiges2.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.sonstiges2.size + 'px',
														'height': _vm.formular.sonstiges2.height + 'px',
														'text-align': _vm.formular.sonstiges2.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.sonstiges2.letterSpacing + 'px',
														'font-weight': _vm.formular.sonstiges2.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.sonstiges2.isDisabled==true || _vm.formular.sonstiges2.isDisabled=='true',"id":"sonstiges2"},domProps:{"value":(_vm.formular.sonstiges2.text)},on:{"focus":function($event){_vm.formular.sonstiges2.showPop=true},"blur":function($event){_vm.formular.sonstiges2.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.sonstiges2, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(166.4020965) + 'px', 
														top: _vm.gib_hoehe_in_mm(9.1857899) + 'px'})},[_vm._v(" Anlage I/9 zu VV BauPrüfVO ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(196.0288965) + 'px', 
														top: _vm.gib_hoehe_in_mm(12.4870619) + 'px'})},[_vm._v(" Blatt 4 ")]),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.1624591) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.0229456) + 'px'})},[_vm._v(" Antrag vom ")]),(_vm.formular.bauantragVom)?_c('b-popover',{ref:"bauantragVom",attrs:{"show":_vm.formular.bauantragVom.showPop==true,"target":"bauantragVom"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauantragVom.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauantragVom.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauantragVom", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauantragVom!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauantragVom.text),expression:"formular.bauantragVom.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(27.3340259) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(175.5404892) + 'px', 
														'height': 21.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(22.2752044) + 'px', 
		
														'font-size': _vm.formular.bauantragVom.size + 'px', 
														'text-align': _vm.formular.bauantragVom.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauantragVom.letterSpacing + 'px',
														'font-weight': _vm.formular.bauantragVom.schriftart }),attrs:{"id":"bauantragVom","type":"text","disabled":_vm.formular.bauantragVom.isDisabled==true || _vm.formular.bauantragVom.isDisabled==true},domProps:{"value":(_vm.formular.bauantragVom.text)},on:{"focus":function($event){_vm.formular.bauantragVom.showPop=true},"blur":function($event){_vm.formular.bauantragVom.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauantragVom.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauantragVom, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(175.1624591) + 'px', 
														top: _vm.gib_hoehe_in_mm(27.7836609) + 'px'})},[_vm._v(" Prüfvermerke ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(174.0831971) + 'px', 
														top: _vm.gib_hoehe_in_mm(240.0744963) + 'px'})},[_vm._v(" Fortsetzung Blatt 5 ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(21.3796159) + 'px', 
													width: _vm.spalte_breit_mm(182.7786629) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(183.809605) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5975117) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(34.0413407) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(40.6791547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.7630963) + 'px', 
													width: _vm.spalte_breit_mm(153.2118219) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(47.2746447) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(92.1185387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(53.8807157) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(92.1185387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(60.4797327) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(67.1316547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(73.7271447) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(83.2923687) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(92.8575927) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(99.7775667) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(92.1185387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(106.6340547) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(114.1155271) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(119.4483511) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(125.8389224) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(132.4414664) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(92.1185387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(142.0063377) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(149.3287424) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(155.9312864) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(164.1065197) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(170.7520931) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(177.3546371) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(185.5228164) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(185.5228164) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(192.1253604) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(198.7279044) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(208.4627771) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(213.7956011) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(153.6262444) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(220.1893467) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(62.8031728) + 'px', 
													width: _vm.spalte_breit_mm(111.1717454) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(229.2431557) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(183.8314724) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(238.4842484) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(171.6714345) + 'px', 
													width: _vm.spalte_breit_mm(32.5087117) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(244.2420759) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3486738) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(219.7513317) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(59.6676698) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(8.850487) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8364437) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(53.2696918) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(19.777133) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5975117) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(86.6180154) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5975117) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(102.1256958) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(58.916252) + 'px', 
													top: _vm.gib_hoehe_in_mm(34.0413407) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(129.4705268) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(72.7001207) + 'px', 
													top: _vm.gib_hoehe_in_mm(27.5975117) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(149.4622682) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(58.916252) + 'px', 
													top: _vm.gib_hoehe_in_mm(34.0413407) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(173.9749182) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(219.7478047) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8364437) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(171.6714345) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(5.8098603) + 'px', 
													top: _vm.gib_hoehe_in_mm(238.5322156) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(204.1801462) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(225.5091592) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(81.8563795) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(89.114426) + 'px', 
													top: _vm.gib_hoehe_in_mm(119.4483511) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(110.6017822) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(22.8639634) + 'px', 
													top: _vm.gib_hoehe_in_mm(185.6988137) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(129.4818132) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(66.1744653) + 'px', 
													top: _vm.gib_hoehe_in_mm(119.4483511) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(116.7733268) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(6.529721) + 'px', 
													top: _vm.gib_hoehe_in_mm(149.5015654) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(161.2318672) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(6.529721) + 'px', 
													top: _vm.gib_hoehe_in_mm(149.5015654) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(143.2254741) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(6.5265467) + 'px', 
													top: _vm.gib_hoehe_in_mm(185.6988137) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(62.7925918) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(18.2291327) + 'px', 
													top: _vm.gib_hoehe_in_mm(220.3551157) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(27.8858728) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(6.4937456) + 'px', 
													top: _vm.gib_hoehe_in_mm(213.7956011) + 'px'})})],1),_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(167.1889702) + 'px', 
														top: _vm.gib_hoehe_in_mm(9.1780305) + 'px'})},[_vm._v(" Anlage I/9 zu VV BauPrüfVO ")]),_c('div',{style:({
														fontSize:'12.06px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(196.9695474) + 'px', 
														top: _vm.gib_hoehe_in_mm(13.6559097) + 'px'})},[_vm._v(" Blatt 5 ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(139.9428952) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.5255431) + 'px'})},[_vm._v(" Genehmigungsvermerk ")]),_c('div',{style:({
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.4896583) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.4267871) + 'px'})},[_vm._v(" Die/Der Entwurfsverfassende ")]),_c('div',{style:({
														fontSize:'13.47px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.8529393) + 'px', 
														top: _vm.gib_hoehe_in_mm(26.5393353) + 'px'})},[_vm._v(" Name, Vorname, Büro ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(-0.5) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm() + 'px',
										
										 top: _vm.gib_hoehe_in_mm() + 'px'})},[(_vm.formular.antragsteller_name)?_c('b-popover',{ref:"antragsteller_name",attrs:{"show":_vm.formular.antragsteller_name.showPop==true,"target":"antragsteller_name"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_name.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_name.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_name", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_name", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_name!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_name.text),expression:"formular.antragsteller_name.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.antragsteller_name.size + 'px',
														'height': _vm.formular.antragsteller_name.height + 'px',
														'text-align': _vm.formular.antragsteller_name.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.antragsteller_name.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_name.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.antragsteller_name.isDisabled==true || _vm.formular.antragsteller_name.isDisabled=='true',"id":"antragsteller_name"},domProps:{"value":(_vm.formular.antragsteller_name.text)},on:{"focus":function($event){_vm.formular.antragsteller_name.showPop=true},"blur":function($event){_vm.formular.antragsteller_name.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_name, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'13.47px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.5679577) + 'px', 
														top: _vm.gib_hoehe_in_mm(47.3070167) + 'px'})},[_vm._v(" Straße, Hausnummer ")]),(_vm.formular.antragsteller_strasse)?_c('b-popover',{ref:"antragsteller_strasse",attrs:{"show":_vm.formular.antragsteller_strasse.showPop==true,"target":"antragsteller_strasse"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_strasse.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_strasse.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_strasse", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_strasse", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_strasse!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_strasse.text),expression:"formular.antragsteller_strasse.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(-0.5) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(0.25) + 'px', 
														'height': 0.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(0.25) + 'px', 
		
														'font-size': _vm.formular.antragsteller_strasse.size + 'px', 
														'text-align': _vm.formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': _vm.formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_strasse.schriftart }),attrs:{"id":"antragsteller_strasse","type":"text","disabled":_vm.formular.antragsteller_strasse.isDisabled==true || _vm.formular.antragsteller_strasse.isDisabled==true},domProps:{"value":(_vm.formular.antragsteller_strasse.text)},on:{"focus":function($event){_vm.formular.antragsteller_strasse.showPop=true},"blur":function($event){_vm.formular.antragsteller_strasse.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.antragsteller_strasse.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_strasse, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.467px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.7337267) + 'px', 
														top: _vm.gib_hoehe_in_mm(59.7432187) + 'px'})},[_vm._v(" PLZ, Ort ")]),(_vm.formular.antragsteller_ort)?_c('b-popover',{ref:"antragsteller_ort",attrs:{"show":_vm.formular.antragsteller_ort.showPop==true,"target":"antragsteller_ort"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_ort.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_ort.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_ort", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_ort", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_ort!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_ort.text),expression:"formular.antragsteller_ort.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(-0.5) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(0.25) + 'px', 
														'height': 0.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(0.25) + 'px', 
		
														'font-size': _vm.formular.antragsteller_ort.size + 'px', 
														'text-align': _vm.formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': _vm.formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_ort.schriftart }),attrs:{"id":"antragsteller_ort","type":"text","disabled":_vm.formular.antragsteller_ort.isDisabled==true || _vm.formular.antragsteller_ort.isDisabled==true},domProps:{"value":(_vm.formular.antragsteller_ort.text)},on:{"focus":function($event){_vm.formular.antragsteller_ort.showPop=true},"blur":function($event){_vm.formular.antragsteller_ort.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.antragsteller_ort.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_ort, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(21.6667137) + 'px', 
														top: _vm.gib_hoehe_in_mm(73.3623765) + 'px'})},[_vm._v(" Datum, Unterschrift ")]),_c('div',{style:({
														fontSize:'13.497px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(22.0158867) + 'px', 
														top: _vm.gib_hoehe_in_mm(83.0319997) + 'px'})},[_vm._v(" für elektronische Verfahren gelten die jeweiligen Bestimmungen ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(183.767281) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(118.2137536) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(25.6936371) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(118.2137536) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(47.0781908) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(118.2137536) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(59.3027728) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(118.2137536) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(71.5404047) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(183.767281) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(82.4818641) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(204.1621585) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(63.7489474) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(138.6086311) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(63.2287149) + 'px', 
													top: _vm.gib_hoehe_in_mm(19.1034376) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.3948775) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(63.7489474) + 'px', 
													top: _vm.gib_hoehe_in_mm(18.8329167) + 'px'})})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }