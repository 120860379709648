<template>
	<div>

		<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">

			<!-- oben rechts -->
			<div style="left:688px;top:31px;" class="t size_12_fett">Anlage I/2 zu VV BauPrüfVO </div>
			<div style="left:810px;top:45px;" class="t size_12">Blatt 1 </div>
			

			<div class="seitenabstand" :style="{ height: seitenrand_oben + 'px' }"></div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(1) + 'px', 
						top: gib_top(1) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

				<div class="size_9">An die untere Bauaufsichtsbehörde </div>

				<popover-font-size ref="bauaufsicht" url="bauaufsicht"/>
				<formular-textarea v-bind:daten="{
										hoehe: '65',
										einsatz: 'bauaufsicht'
									}" />

			</div>



			<div class="rahmen rechter_rand rechte_breite" 
				:style="{height: gib_hoehe(1) + 'px', 
						top: gib_top(1) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

			<div class="size_9">Eingangsstempel der Bauaufsichtsbehörde</div>


			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(2) + 'px', 
						top: gib_top(2) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>

			<div class="size_9">PLZ / Ort</div>
				<popover-font-size ref="bauaufsicht_plz" url="bauaufsicht_plz"/>
				<formular-input v-bind:daten="{einsatz: 'bauaufsicht_plz' }"/>
			</div>

			<div class="rahmen" 
				:style="{height: gib_hoehe(2) + 'px', 
						top: gib_top(2) + 'px',
						left: mittellinie + 'px',
						width: rechte_spalte_breit + 'px'
					}"
			>

			<div class="size_9">Aktenzeichen</div>


			</div>


		<!-- Anfang 3. Zeile -->
			<div class="rahmen" 
				:style="{height: gib_hoehe(3) + 'px', 
						top: gib_top(3) + 'px',
						left: seitenrand_links + 'px',
						width: linke_spalte_breit + 'px'
					}"
					>


		</div>
		<!-- End output for page 2-->

		</div>
	</div>

</template>

<script>

import { formularMixin } from '../../../mixins/formularMixin.js'


export default {

  mixins: [formularMixin],

    name: "antrageinfach2018",

    data(){
        return{

			spalte_bindungen_breit_1: 63.84,
			spalte_bindungen_breit_2: 24.45,
			spalte_bindungen_breit_3: 45.05,
			spalte_bindungen_breit_4: 50.05,

			// Höhen in Millimetern

			linkes_feld_breite_mm : 85.7,
			rechtes_feld_breite_mm : 97.6,
			hoehen_mm:[
			23.36,
			8.04,
			22.18,
			4.53,
			12.91,
			8.00,
			7.96,
			24.64,
			7.96,
			8.09,
			4.53,
			8.01,
			8.09,
			5.25,
			5.25,
			5.25,
			9.69,
			22.41,
			10.7,
			14.54,
			12.55,
			4.45,
			4.45,
			4.85,
			4.85,
			4.95
			],
			hoehen_mm_page2:[
			80.95,
			19.85,
			66.76,
			8.115,
			8.115,
			8.893,
			30.024,
			20

			],
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	
    computed: {


    },
	methods:{



	},

	created(){

	}
	
	//
}
</script>


<style >
.align-center{

	display: flex;
	align-items: center;

}

.nummern{
	display: flex;
	align-items: center;
	width: 35px;
	padding-left: 10px;
	margin-right:10px;
	font-size:0.82em;
}

.nummern_checkbox{	
	display: flex;
	align-items: center;
	width: 80px;
	font-size:0.82em;

}

.nummern_text{
	display: flex;
	align-items: center;
	width: 660px;
	font-size:0.82em;
	line-height: 1.0;
}

.nummern_text_schmaler{
	width: 570px;
	font-size:0.82em;
	line-height: 1.0;
}

.nummern_text_rest{
	width: 90px;
	font-size:0.82em;
	line-height: 1.0;
}


.nummer_zweiteReihe{

	font-size:0.7em;
	margin-left:125px;	
	line-height: 1.0;
	margin-bottom: 5px;

}



.seitenabstand{
    position: absolute;
}

.titel{
	display: flex;
	align-items: center;
	justify-content: center;
}

.links{
    left:75px; width:380px; 
}

.rechts{
    left:454px; width:420px;
}

.rechter_rand{
	left:454px; 
}

.rechte_breite{
	width:420px;
}

.linker_rand{
    left:75px; 
}

.linke_breite{
   width:380px; 
}




.t {
	position: absolute;
	-webkit-transform-origin: top left;
	-moz-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	z-index: 2;
	white-space:nowrap;
	overflow:visible;
	FONT-FAMILY: Arial, Helvetica, sans-serif;
	color: rgb(0,0,0);
	
}

.size_9{		
	font-size:9px;
	line-height:1.0;
}		

.size_9_fett{		
	font-size:9px;
	FONT-WEIGHT	: bold;
}		

.size_10{		
	font-size:10px;
}		

.size_10_fett{		
	font-size:10px;
	FONT-WEIGHT	: bold;
}	

.size_11{		
	font-size:11px;

}

.size_11_fett{		
	font-size:11px;
	FONT-WEIGHT	: bold;
}

.size_12_narrow{		
	font-size:12px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}	

.size_12{		
	font-size:12px;
}

.size_12_fett{		
	font-size:12px;
	FONT-WEIGHT	: bold;
}		

.size_13{		
	font-size:13px;
}

.size_13_fett{		
	font-size:13px;
	FONT-WEIGHT	: bold;
}

.size_14_fett{		
	font-size:14px;
	FONT-WEIGHT	: bold;
}		

.size_14_narrow{		
	font-size:14px;
	FONT-FAMILY	: ArialNarrow;
	color	: rgb(0,0,0);
}		

.size_15{		
	font-size:15px;
}

.size_15_fett{		
	font-size:15px;
	FONT-WEIGHT	: bold;
}

.size_17_fett{		
	font-size:17px;
	FONT-WEIGHT	: bold;
}	

.size_18_fett{		
	font-size:18px;
	FONT-WEIGHT	: bold;
}		

.text_feld{
	z-index:2;	
	color: rgb(0,0,0); 	
	text-align:left;	
	background: transparent;	
	border: solid 1px grey;	
	font:normal 15px 'Courier New', Courier, monospace;
}

.linie_links {
  border-left: 1px solid #000;
}


</style>
