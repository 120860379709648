var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{staticClass:"t size_12_fett",staticStyle:{"left":"688px","top":"31px"}},[_vm._v("Anlage I/2 zu VV BauPrüfVO ")]),_c('div',{staticClass:"t size_12",staticStyle:{"left":"810px","top":"45px"}},[_vm._v("Blatt 1 ")]),_c('div',{staticClass:"seitenabstand",style:({ height: _vm.seitenrand_oben + 'px' })}),_c('div',{staticClass:"rahmen",style:({height: _vm.gib_hoehe(1) + 'px', 
					top: _vm.gib_top(1) + 'px',
					left: _vm.seitenrand_links + 'px',
					width: _vm.linke_spalte_breit + 'px'
				})},[_c('div',{staticClass:"size_9"},[_vm._v("An die untere Bauaufsichtsbehörde ")]),_c('popover-font-size',{ref:"bauaufsicht",attrs:{"url":"bauaufsicht"}}),_c('formular-textarea',{attrs:{"daten":{
									hoehe: '65',
									einsatz: 'bauaufsicht'
								}}})],1),_c('div',{staticClass:"rahmen rechter_rand rechte_breite",style:({height: _vm.gib_hoehe(1) + 'px', 
					top: _vm.gib_top(1) + 'px',
					left: _vm.mittellinie + 'px',
					width: _vm.rechte_spalte_breit + 'px'
				})},[_c('div',{staticClass:"size_9"},[_vm._v("Eingangsstempel der Bauaufsichtsbehörde")])]),_c('div',{staticClass:"rahmen",style:({height: _vm.gib_hoehe(2) + 'px', 
					top: _vm.gib_top(2) + 'px',
					left: _vm.seitenrand_links + 'px',
					width: _vm.linke_spalte_breit + 'px'
				})},[_c('div',{staticClass:"size_9"},[_vm._v("PLZ / Ort")]),_c('popover-font-size',{ref:"bauaufsicht_plz",attrs:{"url":"bauaufsicht_plz"}}),_c('formular-input',{attrs:{"daten":{einsatz: 'bauaufsicht_plz' }}})],1),_c('div',{staticClass:"rahmen",style:({height: _vm.gib_hoehe(2) + 'px', 
					top: _vm.gib_top(2) + 'px',
					left: _vm.mittellinie + 'px',
					width: _vm.rechte_spalte_breit + 'px'
				})},[_c('div',{staticClass:"size_9"},[_vm._v("Aktenzeichen")])]),_c('div',{staticClass:"rahmen",style:({height: _vm.gib_hoehe(3) + 'px', 
					top: _vm.gib_top(3) + 'px',
					left: _vm.seitenrand_links + 'px',
					width: _vm.linke_spalte_breit + 'px'
				})})])])
}
var staticRenderFns = []

export { render, staticRenderFns }