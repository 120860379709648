<template>
	<div >
			<b-card class="mb-4 container" >	

				<b-row class="down-space-15">
					<b-col>
						<div class="ueberschrift">Handwerker(innen)</div>
				
					</b-col>
				</b-row>

			<hr class="my-1">

			<b-button 
				class="my-2"
				v-if="bearbeiten"
				v-b-tooltip.hover 
				title="Fügt eine neue, leere Zeile hinzu."
				variant="outline-primary" 
				@click="pushHandwerker">hinzufügen
			</b-button>


				<b-row :key="index" v-for="(handwerk, index) in projectDaten.ProjektBeteiligte.handwerker" class="mb-2">

					<b-col cols="4">
						<b-form-select 
						:disabled="!bearbeiten" 
						v-if="taetigkeiten_select.length>0" 
						v-model="handwerk.taetigkeitID" 
						v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
						:options="taetigkeiten_select">
						
							<template v-slot:first>
								<option :value="null">bitte wählen</option>
							</template>
						
						</b-form-select>
					</b-col>

					<!-- {{kontaktNachTaetigkeit(fachplan.taetigkeitID)}} -->

					<b-col cols="6">

						<span v-if="handwerk.taetigkeitID">
							<span v-if="kontaktNachTaetigkeit(handwerk.taetigkeitID).length>0">

								<b-form-select 
								:disabled="!bearbeiten" 
								v-if="kontaktNachTaetigkeit(handwerk.taetigkeitID).length>0" 
								v-model="handwerk.kontaktID" 
								v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
								:options="kontaktNachTaetigkeit(handwerk.taetigkeitID)">
								
									<template v-slot:first>
										<option :value="null">bitte wählen</option>
									</template>
								
								</b-form-select>


							</span>
							<span v-else>
								Kein passender Kontakt mit dieser Tätigkeit im Adressbuch vorhanden.<br>
								Falls es den Kontakt bereits im Adressbuch gibt, stellen Sie sicher, dass dieser <br>
								1.) unter "Kategorie des Eintrags" ein Häcken bei <b>Handwerker</b> hat und 
								<br>
								2.) unter "Tätigkeit" <b>{{auswahlListen.alle_taetigkeiten_key[handwerk.taetigkeitID]}}</b> ein Häkchen hat.<br>
								<router-link to="/adressbuch">zum Adressbuch</router-link> <br>

								oder Sie erstellen einen neuen Kontakt:
														<b-button 

						class="ml-2"
							@click="legeNeuenKontaktan('beteiligte')" 
							v-b-tooltip.hover 
							title="neuen Kontakt erstellen, falls nicht in unten stehender Liste aufgeführt" 
							variant="info" >
							<span class="fa fa-plus"></span>
					</b-button>
							</span>
						</span>
					</b-col>
					<b-col cols="2">
								<b-btn 	
									v-if="bearbeiten && handwerk.kontaktID"
									class="mr-2"
										v-b-tooltip.hover 
										title="Verbindung zwischen Fachplaner(in) und dem Bauvorhaben trennen." 
										@click="projectDaten.ProjektBeteiligte.handwerker.splice(index, 1)" variant="danger">
											<b-icon icon="x"></b-icon>

								</b-btn>
								<b-btn 	
									v-if="handwerk.kontaktID"
										v-b-tooltip.hover 
										title="Wechseln Sie zu diesem Kontakt. Der aktuelle Stand wird vorher automatischgespeichert." 
										@click="speicherAktuellesProjekt();clickOnName(handwerk.kontaktID); " 

										variant="primary">
										
										<b-icon icon="box-arrow-up-right"></b-icon>

								</b-btn>

					</b-col>	
				</b-row>
			
			</b-card>

		</div>




</template>

<script>

	import kontaktModal from '../kontaktModal.vue'
	import { projectMixin } from '../../mixins/projectMixin.js'

export default {

	mixins: [projectMixin],

 		components:{
			 kontaktModal,
		},

    data(){
        return{

        }
	},
	
	
	mounted() {

	},

	computed:{

		taetigkeiten_select(){
			var returnValue=[]

			if(this.auswahlListen.taetigkeiten_key){

				for(var prop in this.auswahlListen.taetigkeiten_key){
					// console.log(prop)
					returnValue.push({ value: prop, text: this.auswahlListen.taetigkeiten_key[prop] })
				}

			}

			return returnValue
			},


	
	},

	methods:{

		kontaktNachTaetigkeit(taetigkeitID){
			 console.log(taetigkeitID)
			// console.log(this.Addressbook)
		if(this.Addressbook && taetigkeitID>0){
			// var tatArray = ['3'];
			// console.log(tatArray)
		//el.taetigkeit.summary.findIndex(item=>item  == taetigkeitID) > -1
			var tatArray = [taetigkeitID];
            var nachTaetigkeit = this.Addressbook.filter(el => el.status==1 && this.findCommonElements(el.taetigkeit.summary, tatArray ))

			var returnValue=[]

			for(var prop of nachTaetigkeit){
				returnValue.push({ value: prop.basic.kontaktID, text: prop.vollerName.anzeigeName })
			}

			return returnValue


			//return this.Addressbook.filter(el => this.findCommonElements(el.taetigkeit, taetigkeitID))
			//return this.Addressbook.filter(el => this.findCommonElements(el.taetigkeit.summary, tatArray ))

		}
		else
			return [];

		},

		pushHandwerker()
		{
			// console.log(this.projectDaten.ProjektBeteiligte.handwerker)
			this.projectDaten.ProjektBeteiligte.handwerker.push({'taetigkeitID':null, 'kontaktID':null});
		},

	

	},

	created(){



	}
	
	//
}
</script>


<style>

  .listBet{

	width:30%;
	min-width:280px;
	overflow-x: hidden;
	}


</style>
