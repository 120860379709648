<template>
  <div :style="{ height: window.height - 150 + 'px' }">

		<div class="kontainer" style="min-height:500px">

			<div class="mt-1 h5" v-if="! showJitsi && $browserDetect.isChrome!=true && $browserDetect.isEdge!=true">

					ArchiAPP empfiehlt für Online-Meetings den Browser Chrome oder Edge, da in diesen die zugrunde liegenden Technologie am Besten umgesetzt wurde.
					
					<span v-if="$browserDetect.isChrome==true && $browserDetect.meta.version<72">
							Bitte bringen Sie Ihren Browser Chrome auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
					</span> 
					<span v-if="$browserDetect.isEdge==true && $browserDetect.meta.version<79">
							Bitte bringen Sie Ihren Browser Edge auf den aktuellen Stand, damit diese Funktion reibungslos funktioniert.
					</span>
			</div>

			<p v-if="! showJitsi" class="mt-1 h5">Wenn Sie zur Eingabe eines Benutzernamens und Passwort aufgefordert werden, ist der Benutzername: <u>RA</u> und das Passwort: <u>51515</u></p>

			<b-row class="mb-4 mt-1"
			>
				<b-col cols="3">
					<b-button  v-b-tooltip.hover 
							title="Meeting starten" 
							variant="outline-primary"
							@click="showJitsi = !showJitsi">

								<span v-if="showJitsi">Meeting beenden</span>
								<span v-if="! showJitsi">Meeting starten</span>

					</b-button>
				</b-col>
				<b-col>
					<b-link  v-b-tooltip.hover                
						title="Öffnet dieses Projekt in ArchiAPP in einem neuem Tab. Dies ist nützlich, wenn Sie mit 'Bildschirm teilen' etwas in ArchiAPP zeigen möchten." 
						target="_blank" 
						:href="'/projekte/' + $route.params.id + '/stammdaten'">ArchiAPP in neuem Tab öffnen
					</b-link>
					</b-col>
				</b-row>

				<vue-jitsi-meet
					v-if="showJitsi"
					ref="jitsiRef"
					domain="jitsi.archi-app.de"
					:options="jitsiOptions"
				></vue-jitsi-meet>

		</div>
    </div>
</template>

<script>

import { JitsiMeet } from '@mycure/vue-jitsi-meet';
import { forAllMixin } from '../../mixins/forAllMixin.js'

export default {

  mixins: [forAllMixin],

  components: {
    VueJitsiMeet: JitsiMeet
  },

    data(){
        return{
			  raum: "RetzArchitekten".concat(this.$route.params.id),
			  showJitsi:false,
			  passwort:'',

	  	}
    },

  mounted() {

        if(Object.keys(this.Addressbook).length ===0)
        this.updateAdressbook();

		this.axios.post(this.$store.state.url_get_rooms, {
        besonders: this.$store.state.dev_sub,
        was:'getRoom',
		raum:this.raum,
        }).then((response) => {

			//console.log(response.data)

				if(response.data.passwort)
                this.passwort = response.data.passwort
				
        })


  },


  computed: {

    urlConnect(){

      return "https://archi-app.de/meetingExtern/" + this.raum;

    },

// {{userSelbst.vollerName.anzeigeName}}
// {{userSelbst.basic.kontaktID}}

    jitsiOptions () {
      return {
        roomName: this.raum,
        noSSL: false,
		password:this.passwort,
        userInfo: {
          email: 'user@email.com',
          displayName: this.userSelbst.vollerName.anzeigeName,
        },
        configOverwrite: {
          enableNoisyMicDetection: false,
		startWithVideoMuted:true,
          startWithAudioMuted:true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false
        },
        onload: this.onIFrameLoad
      };
    },
  },


  methods: {

	  lobbyOn(){
			this.$refs.jitsiRef.executeCommand('toggleLobby', true);
			console.log(this.$refs.jitsiRef)
	  },


    onIFrameLoad () {

		var self = this;

		this.$refs.jitsiRef.addEventListener('passwordRequired', function ()
		{
			 self.$refs.jitsiRef.executeCommand('password', self.passwort);
		});

		this.$refs.jitsiRef.addEventListener('participantRoleChanged', function (event) {
         //console.log(event);

        if(event.role === 'moderator') {
			self.$refs.jitsiRef.executeCommand('password', self.passwort);
          }
      });



    },
  },
};
</script>