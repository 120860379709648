<template>
	<div v-if="projectDaten">

    <div v-if="projectDaten.stammdaten" class="kontainer">

       <b-card class="container" style="margin-bottom: 2rem;">	

			<b-row class="down-space-15">
				 <b-col ><div class="ueberschrift">Allgemein</div></b-col>
			</b-row>

			<hr class="my-1">

				<b-row v-if="projectDaten.stammdaten.allgemein.bv_name || bearbeiten" class="my-3">

					<b-col cols="12" >
						<label class="eingabe-label">Projekttitel (kurz)</label>
					</b-col>
					<b-col cols="12">
						<b-form-input v-model="projectDaten.stammdaten.allgemein.bv_name" 
								:readonly="bearbeiten==false" 
								v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"  type="text"/>
					</b-col>

				</b-row>

				<b-row v-if="projectDaten.stammdaten.allgemein.bv_titel || bearbeiten"  class="my-3">
								
					<b-col cols="12">
								<label class="eingabe-label">Bauvorhaben (lang)</label>
								<b-icon 
									v-if="bearbeiten" 
									v-b-tooltip.hover 
									title="Wird in Bauantragsformularen verwendet." 
									class="ml-2" 
									icon="question-circle" 
									font-scale="1.5" 
									variant="primary">
								</b-icon>	

							</b-col>
							<b-col cols="12">
								  <b-form-textarea
									v-model="projectDaten.stammdaten.allgemein.bv_titel" 
									:readonly="bearbeiten==false" 
									style="height: 65px;"
									v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }" 
									rows="2"
									max-rows="2"
									no-resize
								></b-form-textarea>

								<!-- <textarea v-model="projectDaten.stammdaten.allgemein.bv_titel" :readonly="bearbeiten==false" v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }" /> -->
							</b-col>

				</b-row>

				<b-row v-if="projectDaten.stammdaten.allgemein.bv_status || bearbeiten"  class="my-3">
								
					<b-col cols="12">
						<label class="eingabe-label">Status</label>
					</b-col>
	
					<b-col cols="12" sm="6">
							<b-form-select 
							:disabled="bearbeiten===false" 
							v-bind:class="{ 'select-ohne-rand': !bearbeiten, 'form-control-archiapp': bearbeiten }" 
							v-model="projectDaten.stammdaten.allgemein.bv_status"
							 :options="auswahlListen.bv_status"
							 >
							</b-form-select>
					</b-col>
				</b-row>


				<b-row v-if="projectDaten.stammdaten.allgemein.projekt_kat || bearbeiten" class="my-3">
					<b-col cols="12">
						<label class="eingabe-label">Projektkategorie</label>
					</b-col>
					
					<b-col cols="6">
						<b-form-select 
							:disabled="!bearbeiten"
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							v-model="projectDaten.stammdaten.allgemein.projekt_kat"
							
							:options="projekt_typ_options"
							
						></b-form-select>

					</b-col>
				</b-row>

				<b-row v-if="projectDaten.stammdaten.allgemein.bv_typ || bearbeiten" class="my-3">
					<b-col cols="12">
						<label class="eingabe-label">Gebäudekategorie</label>
					</b-col>
					
					<b-col cols="6">
						<b-form-select 
							:disabled="!bearbeiten"
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							v-model="projectDaten.stammdaten.allgemein.bv_typ"
							
							:options="bv_typ_options"
							
						></b-form-select>

					</b-col>
				</b-row>

				<b-row v-if="projectDaten.stammdaten.allgemein.bv_typ_sub && projectDaten.stammdaten.allgemein.bv_typ || bearbeiten" class="my-3">
					<b-col cols="12">
						<label class="eingabe-label">Gebäudeunterkategorie</label>
					</b-col>
			
		
					
					<b-col cols="6">
						<b-form-select 
							:disabled="!bearbeiten"
							v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
							v-model="projectDaten.stammdaten.allgemein.bv_typ_sub"
							
							:options="bv_typ_suboptions[projectDaten.stammdaten.allgemein.bv_typ]"
							
							></b-form-select>
							
					</b-col>
				</b-row>

				<b-row v-if="projectDaten.stammdaten.allgemein.aktenz || bearbeiten" class="my-3">
					<b-col cols="12">
						<label class="eingabe-label">Aktenzeichen</label>
					</b-col>
				
		
					
					<b-col cols="6">
						<b-form-input v-model="projectDaten.stammdaten.allgemein.aktenz" 
								:readonly="bearbeiten==false" 
								v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"  type="text"/>

						
					</b-col>
				</b-row>
				

		</b-card>	


        <b-card  v-if="projectDaten.stammdaten.allgemein.anmerkungen" class="container" style="margin-bottom: 2rem;">	

			<b-row class="down-space-15 ">
				 <b-col ><div class="ueberschrift">Anmerkungen</div></b-col>
			</b-row>

 			<hr v-if="projectDaten.stammdaten.allgemein.anmerkungen || bearbeiten" class="my-2">

			<b-row v-if="projectDaten.stammdaten.allgemein.anmerkungen || bearbeiten" class="my-2">
				<b-col cols="12">
					<textarea v-model="projectDaten.stammdaten.allgemein.anmerkungen" 
					:readonly="!bearbeiten" 
					v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"/>
				</b-col>

			</b-row>

		 </b-card>
		
        <b-card class="container" style="margin-bottom: 2rem;">	
			<b-col cols="12" sm="6">			
				<b-row class="down-space-15">
					<div class="ueberschrift">Adresse des Baugrundstücks</div>
				</b-row>
			</b-col>

 			<hr class="my-1">

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.strasse || bearbeiten" cols="12" sm="6">			
				<b-row class="down-space-5">
					<label class="eingabe-label"> Straße, Hausnummer </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.strasse"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.strasse" class="form-control" type="text"/>
				</b-row>
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.plz || bearbeiten" cols="12" sm="6">
				<b-row class="down-space-5">
					<label class="eingabe-label"> PLZ </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.plz"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.plz" class="form-control" type="text"/>
				</b-row>
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.ort || bearbeiten" cols="12" sm="6">					
				<b-row class="down-space-5">
					<label class="eingabe-label"> Ort </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.ort"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.ort" class="form-control" type="text"/>
				</b-row>					
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.gemeindeteil || bearbeiten" cols="12" sm="6">	
				<b-row class="down-space-5">
					<label class="eingabe-label"> Gemeindeteil </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.gemeindeteil"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.gemeindeteil" class="form-control" type="text"/>
				</b-row>	
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.kreis || bearbeiten" cols="12" sm="6">	
				<b-row class="down-space-5">
					<label class="eingabe-label"> Kreis </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.kreis"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.kreis" class="form-control" type="text"/>
				</b-row>	
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.gemarkung || bearbeiten" cols="12" sm="6">	
				<b-row class="down-space-5">
					<label class="eingabe-label"> Gemarkung </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.gemarkung"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.gemarkung" class="form-control" type="text"/>
				</b-row>
			</b-col>

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.flur || bearbeiten" cols="12" sm="6">	
				<b-row class="down-space-5">
					<label class="eingabe-label"> Flur </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.flur"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.flur" class="form-control" type="text"/>
				</b-row>	
			</b-col>	

			<b-col class="my-3" v-if="projectDaten.stammdaten.adresse.flurstueck || bearbeiten" cols="12" sm="6">	
				<b-row class="down-space-5">
					<label class="eingabe-label"> Flurstück </label>
					<input readonly v-if="bearbeiten==false" class="form-control-plaintext" v-model="projectDaten.stammdaten.adresse.flurstueck"/>
					<input v-else v-model="projectDaten.stammdaten.adresse.flurstueck" class="form-control" type="text"/>
				</b-row>
			</b-col>
		</b-card>

        <div class="container" style="height:100px; min-height:100px">	

		 </div>
    </div>

		<kontaktModal woherKommstDu="stammdaten" ref="kontaktModalStammdaten" ></kontaktModal>

	</div>
</template>

<script>

	import kontaktModal from '../kontaktModal.vue'
	import { kontaktMixin } from '../../mixins/kontaktMixins.js'
	import { projectMixin } from '../../mixins/projectMixin.js'
	import ItemFor from '../BauherrItem.vue'



export default {

		mixins: [kontaktMixin, projectMixin],

 		components:{
			kontaktModal,
		},
		
    data(){
        return{


			itemComponent:ItemFor,
			selected: null,
			id:this.$route.params.id, //hier weiter, Project holen
			aenderungen:false,
		//projectDaten:{bv_status:'', haus_typ:'', anmerkungen:''}
	
        }
	},

//WEITER


// beforeRouteEnter (to, from, next) {
// 	console.log(from.path);

// 		this.toTop();
// 	next();

// },

beforeRouteEnter(to, from, next) {
  next(vm => {
	vm.prevRoute = from
	if(vm.prevRoute.path.includes('/projekte')===true && !vm.prevRoute.params.id){
		vm.toTop();
	}

	
  })
},



	methods:{

		confirmLeave() {
			return window.confirm('Sie haben ungespeicherte Änderungen')
		},

		openKontakt(kontaktID){

			this.makeKontakModalByID(kontaktID);
			this.showBModal();
		},

		showBModal() {
        	this.$refs.kontaktModalStammdaten.$refs.neuer_adressbuch_eintrag.show()
		},


	},

	computed:{


	},

	mounted() {


    if(Object.keys(this.Addressbook).length ===0)
        this.updateAdressbook();
       
    if(Object.keys(this.Papierkorb).length ===0)
        this.updatePapierkorb();


			this.$root.$on('openKontaktModalinStammdaten', data => {
				console.log("openKontaktModalinStammdaten")

				try {
					this.$refs.kontaktModalStammdaten.$refs.neuer_adressbuch_eintrag.show()
				}
				catch (e) {
				// Anweisungen für jeden Fehler
				}
			})

	


	},

	created(){


	}
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-control-plaintext{
	font-size: 1.0em !important;
	background-color:transparent !important;
	color:black !important;
	background:none !important;
	border:none !important;
	-webkit-box-shadow: none !important;
  	box-shadow: none !important;
	 cursor: default;
	 resize: none;
}

.form-control-plaintext:focus {

border:none !important;

  outline: 0;

}

.select-ohne-rand {
	font-size: 1.0em !important;
	/* padding: 6px 12px !important; */
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
	border: none !important;
	box-shadow: none !important;

	background-color:transparent !important;
	color:black !important;
	background:none !important;
}


/* .ueberschrift{
	font-size:1.4em;
	font-weight:600;
} */


.text-projekte{

	font-weight:400;
}


.down-space-15 {
	margin-bottom: 15px;
}

.down-space-5 {
	margin-bottom: 5px;
}

.form-control-archiapp {
	margin-top: 0;
	display: block;
	width: 100%;
	letter-spacing: 1px;

  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control-archiapp:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}
.form-control-archiapp::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.form-control-archiapp:-ms-input-placeholder {
  color: #777;
}
.form-control-archiapp::-webkit-input-placeholder {
  color: #777;
}

textarea.form-control-archiapp[readonly]{
	width: 100%;
	font-weight: normal;
	padding: 6px 8px;
	font-size: 14px;
	letter-spacing: 1px;
	color: #000;
	border:none;
	border-bottom: 1px solid #DDD;
}


.form-control-archiapp[disabled],
.form-control-archiapp[readonly],
fieldset[disabled] .form-control-archiapp {
	color:#000;
	-webkit-box-shadow: none;
  box-shadow: none;
 
  border: none;
 border-bottom: 1px solid #DDD;
  background-color: #fff;
  opacity: 1;
    border-radius: 0px;
	
}
textarea.form-control-archiapp {
  height: auto;
}

.form-control-archiapp[readonly]:focus {
	border: none;
	border-color:#fff;;
	background-color: #fff;
}

.form-control_readonly {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control_readonly::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control_readonly:-ms-input-placeholder {
  color: #fff;
}
.form-control_readonly::-webkit-input-placeholder {
  color: #fff;
}
.form-control_readonly[disabled],
.form-control_readonly[readonly],
fieldset[disabled] .form-control_readonly {
  background-color: #fff;
  opacity: 1;
}

.form-control_readonly[disabled],
fieldset[disabled] .form-control_readonly {
  cursor: not-allowed;
}

.eingabe-label {
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	margin: 0;
	letter-spacing: 0.5px;
	font-weight:600;
}

.ueberschrift{
	color: #777;
	letter-spacing: 1px;
	float: left;
	font-size: 1.2em;
	font-weight: bold;
	margin-right:15px;

}

.abstand_nach_unten{
margin-bottom:10px;
min-height:25px;
}
</style>