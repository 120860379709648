<template>
	<div>

	<kontakt-subnav></kontakt-subnav>
		<!-- <div style="background-color:orange;height:100px; width:100px;">


		</div> -->
       <div class="kontainer">

				<!-- <pulse-loader :loading="loader_loading" :color="loader_color" :size="loader_size"></pulse-loader> -->
<!-- {{mitarbeiterKontaktIDSelf}} -->
				<div class="flexed">

					<div class="kontaktListe" >


						<div class="zeile_oben">
							<span style="display:inline-block; min-width:245px; font-weight:700">
								<span v-if="showInaktivTabelle==false">aktive Kontakte</span>
								<span v-if="showInaktivTabelle==true">inaktive Kontakte</span>
							</span>
							<span v-if="Mehrfachauswahl">
								<b-button class="ml-2 btn-nav" 
										@click="unselectAddressbook" 
										v-b-tooltip.hover 
										title="alle Markierungen aufheben" >
										<span class="fa fa-check-square"></span>
								</b-button>	
							</span>
						</div>


						<virtual-list
							class="list"    
							v-if="mitarbeiterGefiltert.length>0 && showInaktivTabelle==false"
							:style="{ height: window.height - 350 + 'px' }"
							:data-key="'uid'"
							:data-sources="mitarbeiterGefiltert"
							:data-component="itemComponent"
							:extra-props="{ variable: '' }"
							:estimate-size="mitarbeiterGefiltert.length"
						/>

						<virtual-list
							class="list"    
							v-if="ehemmitarbeiterGefiltert.length>0 && showInaktivTabelle==true"
							:style="{ height: window.height - 350 + 'px' }"
							:data-key="'uid'"
							:data-sources="ehemmitarbeiterGefiltert"
							:data-component="itemComponent"
							:extra-props="{ variable: '' }"
							:estimate-size="ehemmitarbeiterGefiltert.length"
						/>

					</div>
			
					
					
					<div class="kontaktDetail" >

						<div class="zeile_oben">

						</div>


		<b-container v-if=" Mehrfachauswahl">
			<b-row>
				<b-col>
					<label class="eingabe-label"> Mehrere Kontakte bearbeiten </label>

				</b-col>
			</b-row>
			<b-row class="mt-3">

				<b-col v-if="showInaktivTabelle==false">
					<span v-if="selectedAddressbook.length==0">
						kein Kontakt ausgewählt
					</span>
<!-- admin {{admin}} -->
					<span v-if="selectedAddressbook.length>0">
						<b-card >
							{{selectedAddressbook.length}} ausgewählte(n) Kontakt(e) löschen <b-button v-if="KontaktDetail" class="mr-2 btn-nav" @click="kontakteloesche(selectedAddressbook)" v-b-tooltip.hover title="markierte Kontakte löschen" variant="info" ><span style="color:red;font-weight:800;">X</span></b-button>
						</b-card>

						<b-card v-if="admin==1 || admin==true">
							<b-row>{{selectedAddressbook.length}} ausgewählte(n) Kontakt(e) Zugriff festlegen </b-row>
							<b-row>
								<b-container class="mb-4">
									<b-form-select @input="mehrereKontakteZugriffrechte" :options="vorlagenListe"></b-form-select>
								</b-container>
							</b-row>
							
						</b-card>
					</span>
				</b-col>

				<b-col v-if="showInaktivTabelle==true">
					<span v-if="selectedTrash.length>0">
						{{selectedTrash.length}} ausgewählte Kontakte wiederherstellen <b-button 
						class="mr-2 btn-nav"
						@click="kontakteloesche(selectedTrash)" 
						v-b-tooltip.hover 
						title="markierte Kontakte wiederherstellen" 
						variant="info" >
						<span class="fa fa-undo"></span>
						</b-button>			
					</span>
					

				</b-col>

			</b-row>

			<b-row class="mt-5">
				<b-col>

Während die Mehrfachauswahl aktiv ist, können keine einzelnen Kontakte angezeigt werden. Sie können die Mehrfachauswahl deaktivieren. 

						<b-button @click="Mehrfachauswahl = !Mehrfachauswahl" variant="success" >deaktivieren</b-button>				

				</b-col>
			</b-row>

		</b-container>

						<kontakt-detail woherKommstDu="mitarbeiter" v-if="!Mehrfachauswahl" ></kontakt-detail>


					</div>
				</div>
		
        </div>


		<kontaktModal woherKommstDu="mitarbeiter" ref="kontaktModalMitarbeiter" ></kontaktModal>

		<modal-zugriffsrechte ></modal-zugriffsrechte>


	</div>
</template>


<script>
	import { kontaktMixin } from '../../mixins/kontaktMixins.js'
	
	import kontaktModal from '../kontaktModal.vue'
	import kontaktDetail from '../kontaktDetail.vue'
	import modalZugriffrechte from './modal_zugriffsrechte.vue'

	import ItemFor from '../AdressesItem.vue'
	import AdressHeader from '../AdressHeader.vue'
	import PulseLoader from 'vue-spinner/src/PulseLoader'
	import { einstellungenMixin } from '../../mixins/einstellungenMixin.js'
	// import { updateKontaktMixin } from '../../mixins/updateKontaktMixins.js'

const remain = 6
const itemSize = 60
const userInfoList = []


export default {

mixins: [kontaktMixin, einstellungenMixin],

  name: 'App',
  components: {

	PulseLoader,

	'kontakt-detail':kontaktDetail,
	'kontakt-subnav':AdressHeader,
	'modal-zugriffsrechte':modalZugriffrechte,
	kontaktModal
	
  },



  data () {
    return {
	itemComponent:ItemFor,
	aktuelleBerechtigungen:[],

		start: 0,
		remain,
		size: itemSize,

    }
  },


	computed:{

		zugriffVorlagen:
		{
			get()
			{
				return this.$store.getters.zugriffVorlagen
			},
			set(value)
			{
				this.$store.commit('setzugriffVorlagen', value);
			}
		},

	vorlagenListe(){
		var returnValue=[]

		var keyValue = Object.keys(this.zugriffVorlagen);

		for(var prop of keyValue)
		{
		console.log(prop)

		 returnValue.push({ value: prop, text: this.zugriffVorlagen[prop].zugriffrechteName });

		}
		return returnValue
	},
	
     selectedAddressbook:
    {
        get()
        {
            return this.$store.getters.selectedAddressbook
        },
        set(value)
        {
            this.$store.commit('setselectedAddressbook', value);
        }
    }, 
	
	KontaktDetail:
		{
			get()
			{
				return this.$store.getters.KontaktDetail
			},
			set(value)
			{
				this.$store.commit('setKontaktDetail', value);
			}
		},

	kindIN:
		{
			get()
			{
				return this.$store.getters.kindIN
			},
			set(value)
			{
				this.$store.commit('setkindIN', value);
			}
		},

		bearbeiten:
		{
			get()
			{
				return this.$store.getters.bearbeiten
			},
			set(value)
			{
				this.$store.commit('changeBearbeiten', value);
			}
		},



	ehemmitarbeiterGefiltert(){
		const search = this.searchTermKontakte.toLowerCase().trim();

		let filtered = this.ehemMitarbeiter;
		var globalSearch = {'suchfeld':search, "kind": this.kindArray, "art":this.artArray};

		if(search || this.kindArray.length>0 || this.artArray.length>0)
		filtered = this.filterItems(this.ehemMitarbeiter, globalSearch);

		return filtered;

	},



	mitarbeiterGefiltert(){
		const search = this.searchTermKontakte.toLowerCase().trim();

		let filtered = this.mitarbeiter;
		var globalSearch = {'suchfeld':search, "kind": this.kindArray, "art":this.artArray};

		if(search || this.kindArray.length>0 || this.artArray.length>0)
		filtered = this.filterItems(this.mitarbeiter, globalSearch);

		return filtered;

	},



	logObject(){
			return this.$store.state.logObject;
		},

	},



  methods: {

	mehrereKontakteZugriffrechte(value) {
		var kontaktID;
		var zugriffrechte

		for(var prop in this.selectedAddressbook){
			kontaktID = this.selectedAddressbook[prop].kontaktID;

			zugriffrechte = this.zugriffVorlagen[value];
			
			this.save_Zugriff_kontakt(kontaktID, zugriffrechte);
		}
		

  	},


	showBModal(welches) {
    	this.$refs[welches].show()
  	},

  	hideBModal(welches) {
		this.$bvModal.hide(welches);
	},


	openBerechtigungen(){

		console.log(this.KontaktDetail)
		var kontaktID = this.KontaktDetail.basic.kontaktID;
		
		//this.$store.commit('setoffenes_kontakt_modal', JSON.parse(JSON.stringify(row.item)));

			this.axios.post(this.$store.state.url_einstellungen_mitarbeiter, {
					besonders: this.$store.state.dev_sub,
					kontaktID: kontaktID,
					was:'zugriffrechte_user'
					}).then((response) => {

				this.aktuelleBerechtigungen=response.data;

				 if(this.aktuelleBerechtigungen){
					 console.log(this.aktuelleBerechtigungen);
					setTimeout(()=>{
					this.showBModal('ZugriffrechteEinzel');
					},150);

				 }
						
						

					})

		},

	filterItems(arr, query) {
		return arr.filter(el => el.vollerName.anzeigeName.toLowerCase().indexOf(query.suchfeld.toLowerCase()) > -1 && this.has(el.basic.kind, query.kind) && this.findCommonElements(el.art.summary, this.artArray) );
	},



	render(){
		this.$refs.liste.forceRender();
	},


	// toTop(){

	// document.body.scrollTop = 0; // For Safari
	// document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

	// },

	eintragBearbeiten(){
			console.log("eintragBearbeiten in mitarbeiter")
		this.bearbeiten = true;
		this.showModalFromMixin('neuer_adressbuch_eintrag');
	},

    getItemProps (itemIndex) {
      return {
        key: itemIndex,
        props: {
          height: itemSize,
          index: itemIndex,
          info: userInfoList[itemIndex] || {}
        }
      }
	},

    onHeaderDataChange (type, value) {
      if (type === 'start') {
        this.start = value
      }
	},

  },

  	mounted() {

		
                if(Object.keys(this.Addressbook).length ===0)
                this.updateAdressbook();

	            if(Object.keys(this.Papierkorb).length ===0)
                this.updatePapierkorb();


			this.$root.$on('openKontaktModalinMitarbeiter', data => {
			//	console.log("openKontaktModalinMitarbeiter")

				try {
					this.$refs.kontaktModalMitarbeiter.$refs.neuer_adressbuch_eintrag.show()

				}
				catch (e) {
				// Anweisungen für jeden Fehler
				}


			})


	},


created(){


		


	},
}
</script>

<style>
	.inhalt
	{
		margin:5px;
	}

	.zeile_oben{

	min-height: 40px;
}




</style>

<style scoped>



.btn-nav{
	width:40px;

}
.flexed { 
   display: flex; 
   	margin-bottom:50px;
	   	height: 100vh;
}


.kontaktListe{
 	position: fixed; 
	width: 380px;
	vertical-align: top;
	height: 100vh;

	margin-bottom:50px;

}

	.scroller {
		height: 100%;
	}

	.user {
		height: 32%;
		padding: 0 12px;
		display: flex;
		align-items: center;
	}

	.input-group-text{
		padding: 0px 5px !important;
	}

	.select-xs {
		margin:0px !important;
		height:28px !important;
		width:100px !important;
	}



	.form-group
	{
		margin:0px;
	}

	.highlight{
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	}
	.text-center{
		text-align: center;
	}

	.unsichtbar{
		display:none;
	}

	.fixed-top
	{
		z-index: 90 !important;
	}

	.modal {
		width: 90% !important;
	}

	.telefonClass{
		display: block;
	}

	.mehrTelefone{
		font-size:0.9em;
		color:grey;
		cursor:pointer;
	}

	.roterRahmen{
		border: 1px solid blue; margin: 10px -5px; padding: 5px;
		box-shadow: 5px 5px 5px grey;
		height:85%;
	}

	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
	}

	.einzug_links{
		margin-left:-10px
	}

	.unterueberschrift{
		color: #777;
		letter-spacing: 1px;
		float: left;
		font-size: 1.05em;
		font-weight: bold;
		margin-right: 15px;

	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.readonlynumber{
		pointer-events: none;
		padding: 0.375rem 0.75rem;
		background-color:white !important;
		border:none;
	}

	.readonlytext{
		pointer-events: none;
		/* padding: 0.375rem 0.75rem; */
		background-color:white !important;
	}

	select.readonlytext{
		background: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.readonlytext:focus {
		border:none;
	}

 /* @import './demos/common/app.less'; */

</style>