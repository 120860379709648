<template>
	<div class="kontainer" style="height:600px">

		<b-spinner v-if="loading" style="position:absolute; top 10px; left:28px;" variant="primary" label="Spinning"></b-spinner>

		<div style="border:1px solid black; height:600px; width:100%" id="modell"></div>
		
	</div>


</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';


export default {
  name: 'ThreeDModell',
  data() {
    return {
      	camera: null,
      	scene: null,
      	renderer: null,
     	 mesh: null,
		loading: true,
		backgroudColor:'skyblue',

    }
  },

  methods: {
    init: function() {
        let container = document.getElementById('modell');

        this.camera = new THREE.PerspectiveCamera(15, container.clientWidth/container.clientHeight, 1, 10000);
        this.camera.position.z = 100;

        this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color( this.backgroudColor );

		const loader = new ColladaLoader();
		var self = this

		loader.load( 'https://account.archi-app.de/api/php/get_file.php?id=4&besonders=' + this.$store.state.dev_sub , function ( object ) {

			self.scene.add( object.scene );
			self.loading = false

		} );


		const AmbientLight = new THREE.AmbientLight( );
		this.scene.add( AmbientLight );

		this.renderer = new THREE.WebGLRenderer({antialias: true});

		const controls = new OrbitControls( this.camera, this.renderer.domElement );

		this.renderer.setSize(container.clientWidth, container.clientHeight);
		container.appendChild(this.renderer.domElement);

 

    },

    animate: function() {
         requestAnimationFrame(this.animate);
        // this.mesh.rotation.x += 0.01;
        // this.mesh.rotation.y += 0.02;
        this.renderer.render(this.scene, this.camera);
    }
  },

  mounted() {
      this.init();
      this.animate();
  }
}
</script>

<style scoped>
  
</style>