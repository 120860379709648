<template>
	<div>
       <div class="kontainer">

			<!-- {{auswahlListen}} -->
		<div>Art:</div>
		<span v-for="(item, index) in auswahlListen.art" :key="index" >
			{{item.text}}
		</span>
        </div>
	</div>
</template>

<script>

import { forAllMixin } from '../../mixins/forAllMixin.js'

// import Vue from 'vue'

export default {

	mixins: [forAllMixin],
	
    data(){
        return{
		
		}
    },
	
	methods:{


	},
	
	created(){
	


	
	}
	
}
</script>



<style>


</style>
