<template>

	<div>
		<div class="kontainer">
hello
<div class="start-cards">
			<b-card>
			
				<b-form-group
				id="fieldset-1"

				label="Benutzername:"
				label-for="input-1"
				>
				<b-form-input id="input-1" v-model="anmeldedaten.username" trim maxlength="50"></b-form-input>

				</b-form-group>

				<b-form-group
				id="fieldset-1"

				label="Passwort:"
				label-for="input-pw"
				>
				<b-form-input @keyup.enter="login" id="input-pw" type="password" v-model="anmeldedaten.password" trim maxlength="29"></b-form-input>

				</b-form-group>

				<b-row>

					<b-col cols="3" style="min-width:105px;">
						<b-btn :disabled="loadLogin" v-on:click="login">anmelden</b-btn>
					</b-col>

					<b-col>
						<b-spinner v-if="loadLogin" variant="primary" label="Spinning"></b-spinner>					
					</b-col>

				</b-row>
			

				</b-card>
			</div>
		</div>

	</div>

</template>

<script>
	import Vue from 'vue'

	export default {

		data(){
			return{
			anmeldedaten:{username:'', password:''},
			registrieren:false,
			 loadLogin:false
			}
		},

		methods:{

			login: function(){

			this.loadLogin=true;

			this.axios.post(this.$store.state.url_login_script, this.anmeldedaten).then((response) => {
			  
			  
			  	if(response.data.login!=true){
						Vue.toasted.show('Die Daten sind nicht korrekt. Bitte überprüfen Sie die Eingaben.', {position: 'top-center', duration:2000, type:'error'});
					  	this.loadLogin=false;
				  }
				else if(response.data.login===true){

					Vue.toasted.show('Sie haben sich erfolgreich angemeldet.', {position: 'top-center', duration:1000, type:'success'});

					// this.axios.get(this.$store.state.url_create_new_sabre_DB)
					// .then(function (antwort) {
					// 	// handle success
					// 	console.log(antwort);
					// })
					
					if(response.data.user)
					this.$store.commit('setuserSelbst', response.data.user);

					this.loadLogin=false;

					this.$root.$emit('logEvent'); //like this
					this.$router.push('projekte');
				}
				
			})

			},

			kontoAnlegen: function(){
				this.registrieren=true;
				this.isActive=true;
			},
			abbrechen: function(){
				this.registrieren=false;
				this.isActive=false;
			},
		}
	}
</script>

<style scoped>


.abstand_oben{
margin-top:20px}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.text{
	font-size:1.1em;
	margin-bottom:3px;
}

.active  {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zentriert{
margin: 0 auto;
font-weight:600;}


.form-control {
	display: block;
	width:95%;
	height: 30px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.2;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

@media (min-width: 960px) {
	.kasten{
width:400px;
margin-right:20px;
float:left;
	}

	.form-control {
	width:92%;

}

}


@media (min-width: 768px) and (max-width: 960px) {
	.kasten{
		width:650px;
		max-width:650px;
		margin: 0 auto;
		margin-bottom:20px;
	}
}

@media (max-width: 767px) {

.form-control {
	width:90%;

}

	.kasten{
		width:400px;
		max-width:400px;
		margin: 0 auto;
				margin-bottom:20px;
	}
}


.btn {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	margin-right: 8px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;

}

.btn:hover {
	color: #fff;
	background-color: #286090;
	border-color: #204d74;
}

</style>
