import { forAllMixin } from './forAllMixin.js'

export const wissenMixin = {

    mixins: [forAllMixin],

    data() {
        return {


			
        }
    },

    methods: {



    },

    computed:{


    },

    created(){



    },

}