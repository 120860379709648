<template>
	<div v-if="actualformular != undefined">

	<b-popover
      target="popover-delete"
      triggers="click"
      :show.sync="popoverDeleteShow"
      placement="auto"
      container="my-container"
      ref="popoverDelete"

    >
      <template v-slot:title>
        <b-button @click="onCloseDelete" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Wirklich löschen?
      </template>

		<b-row align-h="between">
			<b-col cols="6"><b-btn style="min-width:65px" size="xs" @click="onCloseDelete" variant="outline-secondary">nein</b-btn></b-col>
			<b-col cols="6"><b-btn style="min-width:65px" size="xs" @click="formularDelete" variant="outline-secondary">ja</b-btn></b-col>
		</b-row>


    </b-popover>

	<b-popover
      target="popover-daten"
      triggers="click"
      :show.sync="popoverDatenUebernehmenShow"
      placement="auto"
      container="my-datencontainer"
      ref="popoverDatenUebernehmen"

    >
      <template v-slot:title>
        <b-button @click="popoverDatenUebernehmenShow=false" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Was tun, wenn in Feld bereits Text steht?
      </template>

		<b-row align-h="between">
			<b-col cols="6"><b-btn style="min-width:65px" size="xs" @click="DatenUebernehmen('ueberschreiben')" variant="outline-secondary">überschreiben</b-btn></b-col>
			<b-col cols="6"><b-btn style="min-width:65px" size="xs" @click="DatenUebernehmen('behalten')" variant="outline-secondary">belassen</b-btn></b-col>
			<!-- <b-col cols="6"><b-btn style="min-width:65px" size="xs" @click="DatenUebernehmen()" variant="outline-secondary">einzeln entscheiden</b-btn></b-col> -->
		</b-row>


    </b-popover>


	<div  ref="divSubnavFormular" class="subnav">
			<hr class="my-1">
				<!-- {{actualformular}} -->

<!-- {{options_formulare_array}} -->

		<b-row v-if="actualformular != undefined">
		<!-- {{popoverDeleteShow}} -->
        <!-- {{selected_form}} -->
				<b-col cols="2"><span class="ueberschrift_formular">neues Formular:</span></b-col>
				<b-col cols="2"><span class="ueberschrift_formular">vorhand. Formulare:</span></b-col>

				<b-col>
					<span class="ueberschrift_formular">aktuelles Formular:</span>
					<!-- <span :style="{width:formularNameWith[0]+'%'}" style="float:left;  margin-right:5px" class="ueberschrift_formular">aktuelles Formular:</span> -->
				</b-col>

			<b-col v-if="actualformular.formularContent.bauauftrag != undefined">
					
					<span class="ueberschrift_formular">Gewerk:</span>
				
					<b-button class="ml-1" size="sm" style="padding: 0.2rem 0.25rem !important;" id="popover-beteiligte" variant="primary" ref="button">
					
						<b-icon icon="question-circle"></b-icon>
					
					</b-button> 
<!-- {{popoverBeteiligteShow}} -->
					<b-popover
						target="popover-beteiligte"
						triggers="click"
						:show.sync="popoverBeteiligteShow"
						placement="auto"
						container="my-container"
						ref="popover"
						>
						<template v-slot:title>
							<b-button @click="onClose" class="close" aria-label="Close">
							<span class="d-inline-block" aria-hidden="true">&times;</span>
							</b-button>
							beteiligte Gewerke
						</template>

						<div>																			      <!--	"{ path: '/', hash: '#technology' }" -->
							Sie können dem Bauvorhaben unter dem Navigationspunkt <router-link :to="{path: '/projekte/'+$route.params.id+'/beteiligte#handwerker'}" >"Beteiligte"</router-link> beteiligte Gewerke samt Firmenname zuordnen.
						</div>

					</b-popover>

			</b-col>

			<b-col><span class="ueberschrift_formular"></span></b-col><!-- Tools -->

			<b-col cols="2"><span class="ueberschrift_formular">PDF</span></b-col>

			<div class="my-1 w-100"></div>

			<b-col cols="2">	<!-- neues Formular: -->
            <!-- :value="selected_form" -->
				<b-form-select 
						:disabled="!bearbeiten"
						v-on:change="loadFreshForm($event)" 
						v-if="selected_alt_or_neu==='neu'" 
						 
                        v-model="selected_form" 
						:options="options_formulare_neu" 
						class="mb-3" />
			</b-col> <!-- ENDE neues Formular: -->

			<b-col cols="2"> <!-- vorhand. Formulare -->
				<b-form-select size="150px"  
						v-on:change="loadForm($event)" 
						v-model="selected_exist_form" 
						:options="options_formulare_vorhanden_local" 
						class="mb-3" />
			</b-col><!-- ENDE vorhand. Formulare -->

		
			<b-col>         <!-- aktuelles Formular -->  
				<b-input-group v-if="actualformular">

					<b-form-input v-if="actualformular.formularName!=undefined" v-model="actualformular.formularName" 
								v-b-tooltip.hover :title="actualformular.formularName"
								:readonly="FormularUmbenennenValue==false" 
								v-bind:class="{ 'form-control': FormularUmbenennenValue, 
								'form-control-plaintext': !FormularUmbenennenValue }" 
								type="text"/>
						
					<b-input-group-append>
						<b-btn v-show="FormularUmbenennenValue" @click="umbennenen" variant="info"><i class="fa fa-check-circle"></i></b-btn>
					</b-input-group-append>

				</b-input-group>
				<span v-if="!actualformular.formularName">kein Formular ausgewählt</span>

			</b-col> <!-- ENDE aktuelles Formular -->  

			<!-- Gewerke Bauauftrag -->  
			<b-col v-if="actualformular.formularContent.bauauftrag != undefined">					
				
				<span >
					<b-form-select 
						@input="gewerkUpdate"
						:disabled="!bearbeiten" 
						v-if="verfuegbareGewerke.length>0 && actualformular.formularContent.bauauftrag != undefined" 
						v-model="actualformular.formularContent.bauauftrag.gewerkID" 
						v-bind:class="{ 'select-ohne-rand': !bearbeiten,  'form-control-archiapp': bearbeiten}" 
						:options="verfuegbareGewerke">
					
						<template v-slot:first>
							<option :value="null">bitte wählen</option>
						</template>
					
					</b-form-select>
				</span>
							
			</b-col>

			<b-col> <!-- Tools -->
				<b-button-group  v-if="actualformular.formularID>=0 && bearbeiten">
					
					<b-button 
						v-b-tooltip.hover title="Die Zoom-Möglichkeit (mit dem Mausrad) für das Formular aktivieren / deaktivieren. Mit einem Doppelklick wird der Zoom wieder auf 0 gestellt." 
						:variant="alertVariant" 
						@click="zoomPause" 
						:disabled="actualformular.formularName===null" 
						
						size="sm"><b-icon icon="zoom-in"></b-icon>
					</b-button>
                        <!-- :disabled="actualformular.formularID===null" -->
					<b-button 
						@click="FormularUmbenennen" 
						:disabled="actualformular.formularName===null"  
						v-b-tooltip.hover 
						title="umbenennen"  
						variant="info" 
						size="sm"><span class="fa fa-exchange"></span>
					</b-button>			
					<b-button 
						@click="loesche1click" 
						id="popover-delete"
						:disabled="actualformular.formularID===null" 
						v-b-tooltip.hover title="aktuelles Formular löschen" variant="danger" size="sm">
						<span class="fa fa-window-close"></span>
					</b-button>

					<b-button 
						@click="popoverDatenUebernehmenShow=true"  
						id="popover-daten"
						:disabled="actualformular.formularName===null" 
						v-b-tooltip.hover 
						title="von Stammdaten übernehmen" 
						variant="warning" size="sm"><span class="fa fa-forward"></span>
					</b-button>
					<b-button 
						@click="generatePDF" 
						:disabled="actualformular.formularName===null"  
						v-b-tooltip.hover title="PDF erstellen" 
						variant="info" 
						size="sm"><span class="fa fa-file-pdf-o"></span></b-button>
				
				</b-button-group>
			</b-col><!-- Tools -->



			<b-col cols="2">    <!-- PDF -->           
				<span v-if="pdf_vorhanden[0]">
						<b-button-group>
						<span v-if="pdf_vorhanden[0]">
							<b-button variant="danger" 
							v-if="pdf_vorhanden[0].formularID===actualformular.formularID" 
							@click="openPDF(pdf_vorhanden[0].dateiID)" size="sm" v-b-tooltip.hover title="zuletzt erstellte PDF öffnen/ speichern">
							<span class="fa fa-file-pdf-o"></span>
							</b-button>
						</span> 


						<b-button  variant="success" 
							v-if="pdf_vorhanden[0]" @click="showPDFList" size="sm" v-b-tooltip.hover title="alle PDF anzeigen">
							<span class="fa fa-eye"></span>
						</b-button>
						</b-button-group>
					</span>

					<span v-else style="width: 10%">
						Er wurde noch keine PDF erstellt.
					</span>
			</b-col> <!-- ENDE PDF --> 

		</b-row>

	</div>

		<div ref="divSubnavShadowFormular" class="subnav_shadow"></div>
    

	<div class="kontainer">

<!-- {{projectDaten.formular[0].formularContent.NRWbauantrag64.bauaufsicht}} -->
			<b-row v-if="pdfListe" class="down-space-15">

					<h4 class="mb-4">Vorhandene PDF</h4>

						<b-table
						v-if="pdf_vorhanden"
							show-empty
							:items="pdf_vorhanden"
							:fields="fields"
						>

								<template slot="empty">
									<h4>Es sind noch keine Einträge vorhanden.</h4>
								</template>

								<template v-slot:cell(download)="row" >
								<!-- <template slot="download" slot-scope="row"> -->
													
									<b-button size="sm" variant="outline-primary" 
									@click="openPDF(row.item.dateiID)"
									><span class="fa fa-download"></span>
									</b-button>

								</template>
									
								<template v-slot:cell(delete)="row">
													
									<b-button size="sm" v-if="pdfloeschen!=row.index" variant="outline-danger" 
										@click="deletePDF(row)"
										>
									<span class="fa fa-trash"></span></b-button>
									
									<b-input-group-append>
										<b-input-group-text class="mr-1" v-if="pdfloeschen==row.index">sicher?</b-input-group-text>

										<b-btn size="sm" v-if="pdfloeschen===row.index" @click="pdfloeschen=null" 
										variant="outline-secondary">nein</b-btn>
										<b-btn size="sm" v-if="pdfloeschen===row.index" @click="deletePDFdirect(row.item.dateiID)" variant="outline-secondary">ja</b-btn>
									</b-input-group-append>
								</template>

						</b-table>

						<div class="mb-4"> </div>

			</b-row>
	<!-- <ul>
		<li v-for="(detail, index) in actualformular.xyKorrektur" :key="index">{{index}} {{detail}}</li>
	</ul> -->
	
			<b-row v-if="actualformular.formularContent" class="down-space-15">
				 <b-col>
					 <!-- {{actualformular.formularContent}} -->

					<panZoom @init="onInit" id="zoomer" ref="panZoomItem" :options="{minZoom: 0.5, maxZoom: 5}">

						<fieldset :disabled="!bearbeiten">

							<keep-alive>
								<component v-if="!pdfListe" v-bind:is="currentTabComponent"></component>
							</keep-alive>

						</fieldset>
					</panZoom>
				 </b-col>
			</b-row>

			<!-- {{options_formulare_neu}} -->

        </div>

	<b-modal v-if="actualformular" no-close-on-backdrop size="lg" transition="nice-modal-fade" ref="dateiNameExistiert" scrollable>

				<b-container slot="modal-header">
					<b-row>
						<b-col>
							<div class="ueberschrift">
								<span> Formularnamen existiert bereits </span>
							</div>
						</b-col>
							<b-col>

							<span style="float:right; display:block" >
								<b-button-group>
									
                                    <b-button variant="info" @click="hideBModal('dateiNameExistiert')">abbrechen</b-button>
									
								</b-button-group>
							</span>
						</b-col>

					</b-row>
				</b-container>

				<b-container style="min-height:200px;" class="modal-body_custom">
                    <b-row class="my-1">
                        <b-col cols="12">
                                <b-alert variant="danger" show>
                                    Der Name der Formulars existiert bereits. Sie können:
                                </b-alert>
                        </b-col>
                    </b-row>

					<b-row>
                        <b-col cols="3">
                            den Namen ändern:
                        </b-col>

						<b-col cols="6">
							<b-form-input v-model="alternativerName"></b-form-input>
						</b-col>

                        <b-col cols="3">
                            <b-button variant="primary" @click="namen_aendern_save();">ändern und PDF erstellen</b-button>
                        </b-col>  

					</b-row>

                    <b-row class="my-2">
                        <b-col>
                        <h4>ODER</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="7">
                            dem Namen das aktuelle Datum mit Uhrzeit hinzufügen:
                        </b-col>  

						<b-col cols="2">
						</b-col>
                        
                        <b-col cols="3">
                            <b-button variant="primary" @click="UhrzeitHinzufuegen();">Datum hinzufügen und PDF erstellen</b-button>
                        </b-col>  

					</b-row>

                    <b-row class=" mt-4">

                            <b-col cols="4"><h4>ODER</h4></b-col>
                            <b-col cols="8" class="text-right"><b-button variant="info" @click="hideBModal('dateiNameExistiert')">abbrechen</b-button>
                        </b-col>
                    </b-row>




			</b-container>


			<div slot="modal-footer" class="w-100"></div>
		</b-modal>

			
	</div>
</template>

<script>

import AntragEinfach from './formulare/antrageinfach.vue'
import antrageinfach2018 from './formulare/2018_antrag_einfach.vue'
import baubeschreibung from './formulare/baubeschreibung.vue'


// NRW
import NRWbaubeschreibung from './formulare/NRWbaubeschreibung.vue'
import NRWbauantragSonderbau65 from './formulare/NRWbauantragSonderbau65.vue'
import NRWbauantrag64 from './formulare/NRWbauantrag64.vue'
import NRWbauantrag64_2022 from './formulare/NRWbauantrag64_2022.vue'
import NRWBetriebsbeschreibungGew from './formulare/NRWBetriebsbeschreibungGew.vue'
import NRWBetriebsbeschreibungLandw from './formulare/NRWBetriebsbeschreibungLandw.vue'


import NRWStatistik from './formulare/NRWStatistik.vue'
import NRWFreistellung from './formulare/NRWFreistellung.vue'
import bauauftrag from './formulare/bauauftrag.vue'
import vollmachtWeb from './formulare/vollmachtWeb.vue'
import NRWAntragaufAbweichung from './formulare/NRWAntragaufAbweichung.vue'

// import bauantrag from './formulare/2018_bauantrag.vue'
import { projectMixin } from '../../mixins/projectMixin.js'
import { kontaktMixin } from '../../mixins/kontaktMixins.js'
import { formularMixin } from '../../mixins/formularMixin.js'

export default {

  components: {
	AntragEinfach,
	antrageinfach2018,
	baubeschreibung,
	NRWbaubeschreibung,
	NRWBetriebsbeschreibungGew,
	NRWBetriebsbeschreibungLandw,
	NRWStatistik,
	NRWbauantragSonderbau65,
	NRWbauantrag64,
	NRWbauantrag64_2022,
	NRWFreistellung,
	bauauftrag,
	vollmachtWeb,
	NRWAntragaufAbweichung,
  },

  mixins: [projectMixin, kontaktMixin, formularMixin],

    data(){
    return{
		// result: [],

		datenZumUebernehmen:[],
        // delay: 250,
        // clicks: 0,
		// timer: null,
		aktuelletaetigkeitID:null,

		nurBeimErstenMal : true,
		alertVariant:"warning",
		zoomable:true,
		instanceZoom:null,
		popoverBeteiligteShow: false,
		popoverDatenUebernehmenShow: false,
		popoverDeleteShow: false,
		alternativerName:'',
		FormularUmbenennenValue:false,
		loescheWirklich:null,
		selected_alt_or_neu:"neu",
		pdfloeschen:null,
		kompletterBauherr : {name:'', strasse:'', plz:''},

		selectedFreshForm:null,
		vorhandeneFormulare:null,


			pre_size:12,

        }
	},


	computed:{

	formularNameWith(){
		var returnValue=[60, 0]

		if(this.actualformular.formularContent){
			if(this.actualformular.formularContent.bauauftrag)
			returnValue=[25, 35] 
		}


		return returnValue
	},

		verfuegbareGewerke(){

			var returnValue=[]

			if(this.auswahlListen.taetigkeiten_key && this.projectDaten.ProjektBeteiligte.handwerker){

				for(var prop of this.projectDaten.ProjektBeteiligte.handwerker){
					// console.log(prop)
					//prop.taetigkeitID
					returnValue.push({ value: prop.taetigkeitID, text: this.auswahlListen.taetigkeiten_key[prop.taetigkeitID] })
				}

			}

			return returnValue



		},

				// this.projectDaten.handwerker.taetigkeitID
				// "" kontaktID



		    fields(){
				var field=[];

				field.push(        	{
				key: "formularName",
				label: "Name",
				sortable: true
			}, 
	        {
				key: "download",
				label: "download"
			}, 		
        	{
				key: "formularTyp",
				label: "Formulartyp",
				sortable: true
			}, 
			{
				key: "createDate",
				label: "erstellt am",
				sortable: true
			});

			if(this.bearbeiten)
			field.push({
					key: "delete",
					label: "löschen"
				})

		return field;
			},



        options_formulare_vorhanden_local()
		{
			//console.log();
			let returnValue=[{ value: null, text: 'bitte wählen' }];
			for(let index in this.projectDaten.formular)
			{
				if(!this.projectDaten.formular[index].formularID)
                this.projectDaten.formular[index].formularID =Math.floor(Math.random() * 100) + 10; 
                //this.projectDaten.formular[index].formularID = this.findID();
				
				returnValue.push({ value: this.projectDaten.formular[index].formularID, text: this.projectDaten.formular[index].formularName })
			}
			return returnValue;

	 },

		pdfListe: {
				get() {
					return this.$store.getters.pdfListe;
				},
				set(values) {
					this.$store.commit('setpdfListe', values);
				}
			},

		pdf_vorhanden: {
				get() {
					return this.$store.getters.pdf_vorhanden;
				},
				set(values) {
					this.$store.commit('setpdf_vorhanden', values);
				}
			},

		selected_form: {
				get() {
					return this.$store.getters.selected_form;
				},
				set(values) {
					this.$store.commit('setselected_form', values);
				}
			},

			selected_exist_form: {
				get() {
					return this.$store.getters.selected_exist_form;
				},
				set(values) {
					this.$store.commit('setselected_exist_form', values);
				}
			},

			glowFormularBearbeiten: {
				get() {
					return this.$store.getters.glowFormularBearbeiten;
				},
				set(values) {
					this.$store.commit('setglowFormularBearbeiten', values);
				}
			},



			store() {
					return this.$store.state
			},

			
			actualformular: {
				get() {
					return this.$store.getters.actualformular;
				},
				set(values) {
					this.$store.commit('setactualformular', values);
				}
			},

	},


watch: {
    // currentTabComponent (newTab, oldTab) {
            

    //   },
  },

	mounted() {


		window.addEventListener('resize', this.calculateHeight)
		// var returnValue =''

		// for(var prop in this.$store.getters.options_formulare_neu){
		// 	options_formulare_array[prop.value] = 
		// }

		// return returnValue;

	

		this.getPDF();

		// console.log(this.actualformular)


            if(this.actualformular==null || !this.actualformular){
            this.actualformular = {formularID:null, formularContent:{}, formularName:null, formularArt:null};
            this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(this.actualformular));

        
        }
		// console.log(this.actualformular)

		//this.actualformular.formularContent.bauauftrag = ''


		this.$root.$on('setFocus', data => {
			this.setFocus(data)
		})

		this.$root.$on('isNumber', data => {
			this.isNumber(data)
		})

		// var element = document.getElementById('zoomer')

		// this.$refs['panZoomItem'].$panZoomInstance.isPaused()

		// this.instanceZoom = this.$panZoomInstance(element);

		// console.log(this.instanceZoom);

            	setTimeout(()=>{
			        this.calculateHeight();
                },100);



	},

	methods:{

        checkUngesicherteChange(){
            
            if(Object.compareObjects(this.actualformular, this.projektEinstellungen.formular.actualformularBlanc)===true)
            return false
            else
            return true

        },

        calculateHeight() {

            if(this.$refs.divSubnavFormular){
                this.$refs.divSubnavShadowFormular.style.height = this.$refs.divSubnavFormular.clientHeight+'px';
            }

		},
		
		CloseFrageDatenUber() {
        this.popoverDatenUebernehmenShow = false
	  },
      onCloseDelete() {
        this.popoverDeleteShow = false
	  },
	  
      onClose() {
        this.popoverBeteiligteShow = false
      },

        setFocus(einsatz){
			console.log(einsatz.target.id)
			this.showFontPopover = '';
			this.showFontPopover = einsatz.target.id;
		},
		
		looseFocus(){
			this.showFontPopover = '';
        },
        

        isNumber: function(evt, onylNumber) {
            if(onylNumber==true){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
            }
            else
             return true;
			},
			
		gewerkUpdate(event){


			if(this.actualformular.formularContent){

				if(this.actualformular.formularContent.bauauftrag.gewerk && this.actualformular.formularContent.bauauftrag.auftragnehmer){
							if(event>=0){

								this.actualformular.formularContent.bauauftrag.gewerk.text = this.auswahlListen.taetigkeiten_key[event] 

								var index_trgHandwerker = this.projectDaten.ProjektBeteiligte.handwerker.findIndex(item=>item.taetigkeitID == event)

								console.log(index_trgHandwerker)
								console.log(this.projectDaten.ProjektBeteiligte.handwerker)


								if(index_trgHandwerker>=0){
									var handwerkerKontakt = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte.handwerker[index_trgHandwerker].kontaktID)
									this.actualformular.formularContent.bauauftrag.auftragnehmer.text= handwerkerKontakt.anzeigeName + "\n" + handwerkerKontakt.adresse.strasse + "\n" +  handwerkerKontakt.adresse.plz + " " +  handwerkerKontakt.adresse.ort

								}

							}
							else{

								this.actualformular.formularContent.bauauftrag.gewerk.text = ''
								this.actualformular.formularContent.bauauftrag.auftragnehmer.text= ''
							}
								
				}


			}



		},

		loesche1click(row){

		 this.clicks++ 
          if(this.clicks === 1) {
            var self = this
            this.timer = setTimeout(function() {
				//self.loescheWirklich=true
				self.popoverDeleteShow = true

              	self.clicks = 0
            }, this.delay);
          } else{
             clearTimeout(this.timer);  
			 //alert('dblclick');
			 this.formularDelete();
			this.$root.$emit('bv::hide::tooltip')
			 this.clicks = 0;
			  this.popoverDeleteShow = false
		  }         


		},


		onInit(){
			// console.log("onInit")
			this.$refs['panZoomItem'].$panZoomInstance.pause()
		},

	resetZoom(panZoomInstance) {
		this.$refs['panZoomItem'].$panZoomInstance.moveTo(0, 0);
		this.$refs['panZoomItem'].$panZoomInstance.zoomAbs(0, 0, 1);
	},


//https://github.com/anvaka/panzoom
	zoomPause(){

		 this.clicks++ 
          if(this.clicks === 1) {
            var self = this
            this.timer = setTimeout(function() {


			if(self.$refs['panZoomItem'].$panZoomInstance.isPaused()==false){
				self.$refs['panZoomItem'].$panZoomInstance.pause()
				self.alertVariant="warning"
			}
			else{
				self.$refs['panZoomItem'].$panZoomInstance.resume()
				self.alertVariant="success"
			}

              	self.clicks = 0
            }, this.delay);
          } else{
             clearTimeout(this.timer);  
			 //alert('dblclick');
			 this.resetZoom();

             this.clicks = 0;
		  }   



	},

 	namen_aendern_save(){
        this.generatePDF();
		this.hideBModal('dateiNameExistiert');
    },


    UhrzeitHinzufuegen(){

        var zeit = new Date().toLocaleString();
        this.alternativerName = this.actualformular.formularName + "-" + zeit;
        
        this.generatePDF();
		this.hideBModal('dateiNameExistiert');
    },

    
	showBModal(welches) {
            this.$refs[welches].show();
  	},

	hideBModal(welches) {
		 this.$refs[welches].hide();
    },
	
	
    openPDF(dateiID){
		console.log(this.$store.state.url_datei_download+"?dateiID=" + dateiID + "&besonders="+this.$store.state.dev_sub, "_blank")
        window.open(this.$store.state.url_datei_download+"?dateiID=" + dateiID + "&besonders="+this.$store.state.dev_sub, "_blank");
    },

    showPDFList(){
        //this.currentTabComponent=null;
        this.pdfListe = true;
        //this.actualformular =  {formularID:null, formularContent:{}, formularName:null};
		this.$root.$emit('bv::hide::tooltip')
	},
	
	umbennenen(){
		this.FormularUmbenennenValue=false;
	},

	FormularUmbenennen(){
		this.FormularUmbenennenValue = !this.FormularUmbenennenValue
	},


	formularDelete(){

        this.currentTabComponent=null;

        console.log("formularDelete");
        
			var ID = this.actualformular.formularID

			console.log(ID);

			var pos = this.projectDaten.formular.map(function(e) { return e.formularID; }).indexOf(ID);

			console.log(pos);

			this.projectDaten.formular.splice(pos, 1);
			
			this.actualformular =  {formularID:null, formularContent:{}, formularName:null};
            this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(this.actualformular));


			this.loescheWirklich=null;
			this.popoverDeleteShow = false

			var answer = this.speicherAktuellesProjekt('','deleteForm');


						if(answer==true){
						this.popoverDeleteShow = false
						//this.onCloseDelete()
						
			            this.$toasted.show('Erfolgreich gelöscht.', {
                        position: 'top-center',
                        type:'success',
                        duration:1500,
						containerClass:'ganzVorne'})
						}
	},


	generatePDF(){

		this.speicherAktuellesProjekt();

		//console.log("generatePDF");

        var projektID = this.$route.params.id;
        
		//this.actualformular.formularArt = this.getNameOfActualFormular;

		console.log(this.actualformular);
		this.axios.post(this.$store.state.url_createFormularPDF, {
		actualformular: this.actualformular,
		alternativerName: this.alternativerName,
		projektID: projektID,
		besonders: this.$store.state.dev_sub

		}).then((response) => {
				if(response.data.nameExistiert===true){
					this.alternativerName = this.actualformular.formularName;
					this.showBModal('dateiNameExistiert');
				}
					// this.$toasted.show("Der Name existiert bereits.", { position: 'top-center',  duration:2500, type:'danger', containerClass:'ganzVorne'})
				
				else if(response.data.angelegt===true)
					this.$toasted.show("Erfolgreich angelegt.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})

				this.getPDF();

		})


	},
	

    getDiesenKontakt(id){
		// console.log(getDiesenKontakt);
		var targetIndex = null
		
		//if(this.projectDaten.ProjektBeteiligte[1])
		targetIndex = this.Addressbook.findIndex(item => item.basic.kontaktID == id);
		
		//  console.log("targetIndex" + targetIndex)

		var returnKontakt={anzeigeName:'', email:'', fax:'', telefon:'', adresse:{strasse:"", plz:"", ort:""} , vertreter:{anzeigeName:'', email:'', fax:'', telefon:'',adresse:{strasse:"", plz:"", ort:""}}};

			if(targetIndex>=0){
				
				if(this.Addressbook[targetIndex])
				var bauherr  = this.Addressbook[targetIndex]
				else
				var bauherr  = {}
				// console.log(bauherr);

				if(bauherr.vollerName.anzeigeName)
					returnKontakt.anzeigeName = bauherr.vollerName.anzeigeName;
				else returnKontakt.anzeigeName = "";

				if(bauherr.adressePreferred>=0){
					const PreferredIndex = bauherr.adresse.findIndex(item => item.id == bauherr.adressePreferred);
					if(PreferredIndex>=0)
					var aktuelleAdresse = bauherr.adresse[PreferredIndex]
				}
				else if (bauherr.adresse[0])
					var aktuelleAdresse = bauherr.adresse[0];

				if(aktuelleAdresse){

					if(aktuelleAdresse.strasse)
						returnKontakt.adresse.strasse = aktuelleAdresse.strasse;
					else returnKontakt.adresse.strasse="";

					if(aktuelleAdresse.plz)
						returnKontakt.adresse.plz = aktuelleAdresse.plz;
					else returnKontakt.adresse.plz="";

					if(aktuelleAdresse.ort)
						returnKontakt.adresse.ort = aktuelleAdresse.ort;
					else returnKontakt.adresse.ort="";
				}



		// Rufnummer Haupt ANFANG
				if(bauherr.rufnummerPreferred>=0){

					const RufnummerIndex = bauherr.telefone.findIndex(item => item.id == bauherr.rufnummerPreferred);

					if(RufnummerIndex>=0)
					var aktuellerufnummer = bauherr.telefone[RufnummerIndex].telefonNummer
				
				}
				else if (bauherr.telefone[0])
					var aktuellerufnummer = bauherr.telefone[0].telefonNummer;

		// Rufnummer Haupt ENDE

		// Fax Haupt ANFANG

			var aktuellefaxnummer = this.findeFax(bauherr)


		// Fax Haupt ENDE

		// E-Mail Haupt ANFANG
				if(bauherr.emailPreferred>=0){

					const RufnummerIndex = bauherr.emails.findIndex(item => item.id == bauherr.emailPreferred);

					if(RufnummerIndex>=0)
					var aktuelleEmail = bauherr.emails[RufnummerIndex].emailAdresse
				
				}
				else if (bauherr.emails[0])
					var aktuelleEmail = bauherr.emails[0];

		// E-Mail Haupt ENDE

		// Vertreter ANFANG
				if(bauherr.beteiligterPreferred>=0){

					const PreferredIndex = this.Addressbook.findIndex(item => item.basic.kontaktID == bauherr.beteiligterPreferred);

					if(PreferredIndex>=0)
					var aktuelleVertreter = this.Addressbook[PreferredIndex]
				

					if(aktuelleVertreter){
						// Rufnummer Vertreter ANFANG
						if(aktuelleVertreter.rufnummerPreferred>=0){

							const RufnummerIndex = aktuelleVertreter.telefone.findIndex(item => item.id == aktuelleVertreter.rufnummerPreferred);

							if(RufnummerIndex>=0)
							var aktuellerufnummer = aktuelleVertreter.telefone[RufnummerIndex].telefonNummer
						
						}
						else if (aktuelleVertreter.telefone[0])
							var aktuellerufnummer = aktuelleVertreter.telefone[0].telefonNummer;

						// Rufnummer Vertreter ENDE

						// E-Mail Vertreter ANFANG
						if(aktuelleVertreter.emailPreferred>=0){

							const RufnummerIndex = aktuelleVertreter.emails.findIndex(item => item.id == aktuelleVertreter.emailPreferred);

							if(RufnummerIndex>=0)
							var aktuelleEmail = aktuelleVertreter.emails[RufnummerIndex].emailAdresse
						
						}
						else if (aktuelleVertreter.emails[0])
							var aktuelleEmail = aktuelleVertreter.emails[0];
						// E-Mail Vertreter ENDE

						if(aktuelleVertreter.vollerName.anzeigeName)
						returnKontakt.vertreter.anzeigeName = aktuelleVertreter.vollerName.anzeigeName;

						if(aktuelleVertreter.adressePreferred>=0){
							const PreferredIndex = aktuelleVertreter.adresse.findIndex(item => item.id == aktuelleVertreter.adressePreferred);
							if(PreferredIndex>=0)
							var aktuelleAdresseVertreter = aktuelleVertreter.adresse[PreferredIndex]
						}
						else if (aktuelleVertreter.adresse[0])
							var aktuelleAdresseVertreter = aktuelleVertreter.adresse[0];

						if(aktuelleAdresseVertreter){
							if(aktuelleAdresseVertreter.strasse){

								returnKontakt.vertreter.adresse.strasse = aktuelleAdresseVertreter.strasse;

								if(!returnKontakt.adresse.strasse)
								returnKontakt.adresse.strasse = aktuelleAdresseVertreter.strasse;

							}
							else returnKontakt.vertreter.adresse.strasse="";

							if(aktuelleAdresseVertreter.plz){
								returnKontakt.vertreter.adresse.plz = aktuelleAdresseVertreter.plz;
								if(!returnKontakt.adresse.plz)
									returnKontakt.adresse.plz = aktuelleAdresseVertreter.plz;
							}
							else returnKontakt.vertreter.adresse.plz="";

							if(aktuelleAdresseVertreter.ort){
								returnKontakt.vertreter.adresse.ort = aktuelleAdresseVertreter.ort;
								if(!returnKontakt.adresse.ort)
									returnKontakt.adresse.ort = aktuelleAdresseVertreter.ort;
							}
							else returnKontakt.vertreter.adresse.ort="";
						}
						else{
							if(aktuelleAdresse)
								returnKontakt.vertreter.adresse.strasse="Anschrift siehe oben"
							else
								returnKontakt.vertreter.adresse	=[];
						}
				
				}
			}
		// Vertreter ENDE


				// E-Mail kann von Haupteintrag oder von Vertreter kommen. Wenn beide einen Eintrag haben, gewinnt Vertreter
					if(aktuelleEmail)
						returnKontakt.email=aktuelleEmail
					else
						returnKontakt.email	='';

				// Rufnummer kann von Haupteintrag oder von Vertreter kommen. Wenn beide einen Eintrag haben, gewinnt Vertreter

					if(aktuellerufnummer)
						returnKontakt.telefon=aktuellerufnummer
					else
						returnKontakt.telefon	='';

					if(aktuellefaxnummer)
						returnKontakt.fax=aktuellefaxnummer
					else
						returnKontakt.fax	='';


					if(bauherr.vollerName.AK_Mitgliedsnummer)
						returnKontakt.AK_Mitgliedsnummer=bauherr.vollerName.AK_Mitgliedsnummer
					else
						returnKontakt.AK_Mitgliedsnummer	='';

				
			}


				var obj = this.convertUndefinedInObject(returnKontakt);
				console.log(obj);
				return obj;

		},

	convertUndefinedInObject(obj){

	Object.keys(obj).forEach(key => {

		var value = obj[key];
		
		if(typeof value !== 'object'){
			if(value==undefined)
			obj[key]='';
			}else if(typeof value === 'object'){
			this.convertUndefinedInObject(value);
		}
	});
	return obj;

	},

 recursiveSearch (obj, searchKey, results = []) {
   var r = results;
   Object.keys(obj).forEach(key => {
      const value = obj[key];
      if(key === searchKey && typeof value !== 'object'){
         r.push(value);
      }else if(typeof value === 'object'){
         this.recursiveSearch(value, searchKey, r);
      }
   });
   return r;
},



	SetBauvorlage(NameOfActualFormular, daten){
		console.log("SetBauvorlage")
		console.log(daten)

	},



					// projectDaten.ProjektBeteiligte[1] = Bauherr
					// projectDaten.ProjektBeteiligte[2] = Handwerker
					// projectDaten.ProjektBeteiligte[3] = Bauamt
 					// projectDaten.ProjektBeteiligte[4] = Fachplaner
					// projectDaten.ProjektBeteiligte[10] = Bauvorlageberechtigter

					// projectDaten.ProjektBeteiligte[11]= // LP 1
					// projectDaten.ProjektBeteiligte[12]= // LP 2
					// projectDaten.ProjektBeteiligte[13]=
					// projectDaten.ProjektBeteiligte[14]=
					// projectDaten.ProjektBeteiligte[15]=
					// projectDaten.ProjektBeteiligte[16]=
					// projectDaten.ProjektBeteiligte[17]=
					// projectDaten.ProjektBeteiligte[18]= // LP 8
					// projectDaten.ProjektBeteiligte[19]= // bis LP 9


// 		// Bezeichnung des Vorhabens 

// 		this.actualformular.formularContent[NameOfActualFormular].bezeichnung_bauvorhaben.text = this.projectDaten.stammdaten.allgemein.bv_titel;


    getBaugrundstueck(){

		var returnGrundstueck={}

			if(this.projectDaten.stammdaten.adresse.strasse)
			returnGrundstueck.strasse=this.projectDaten.stammdaten.adresse.strasse
			else
			returnGrundstueck.strasse=''

			if(this.projectDaten.stammdaten.adresse.ort)
			returnGrundstueck.ort=this.projectDaten.stammdaten.adresse.ort
			else
			returnGrundstueck.ort=''

			if(this.projectDaten.stammdaten.adresse.plz)
			returnGrundstueck.plz=this.projectDaten.stammdaten.adresse.plz
			else
			returnGrundstueck.plz=''

			if(this.projectDaten.stammdaten.adresse.gemeindeteil)
			returnGrundstueck.gemeindeteil=this.projectDaten.stammdaten.adresse.gemeindeteil
			else
			returnGrundstueck.gemeindeteil=''

			if(this.projectDaten.stammdaten.adresse.kreis)
			returnGrundstueck.kreis=this.projectDaten.stammdaten.adresse.kreis
			else
			returnGrundstueck.kreis=''

			if(this.projectDaten.stammdaten.adresse.gemarkung)
			returnGrundstueck.gemarkung=this.projectDaten.stammdaten.adresse.gemarkung
			else
			returnGrundstueck.gemarkung=''

			if(this.projectDaten.stammdaten.adresse.flur)
			returnGrundstueck.flur=this.projectDaten.stammdaten.adresse.flur
			else
			returnGrundstueck.flur=''

			if(this.projectDaten.stammdaten.adresse.flurstueck)
			returnGrundstueck.flurstueck=this.projectDaten.stammdaten.adresse.flurstueck
			else
			returnGrundstueck.flurstueck=''
			
			return returnGrundstueck

	},
    getBezeichnung(){
			if(this.projectDaten.stammdaten.allgemein.bv_titel)
				return this.projectDaten.stammdaten.allgemein.bv_titel
			else
				return ''
	},

	fetchFromObject(obj, prop){
		//property not found
		if(typeof obj === 'undefined') return false;
		
		//index of next property split
		var _index = prop.indexOf('.')

		//property split found; recursive call
		if(_index > -1){
			//get object at property (before split), pass on remainder
			return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index+1));
		}
		
		//no split; get property
		return obj[prop];
	},

    DatenUebernehmen(beiBelegung){

        //console.log("DatenUebernehmen");

		//console.log(this.projectDaten.ProjektBeteiligte);

		var NameOfActualFormular = this.currentTabComponent;
		//console.log(NameOfActualFormular)
	
		var antw_bauherr = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte[1])
		if(antw_bauherr)
		this.datenZumUebernehmen['bauherr'] = antw_bauherr;

		var antw_gemeinde = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte[5])
		if(antw_gemeinde)
		this.datenZumUebernehmen['gemeinde'] = antw_gemeinde;

		var antw_entwurfsverfasser = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte.entwurfsverfasser)
		if(antw_entwurfsverfasser)
		this.datenZumUebernehmen['entwurfsverfasser'] = antw_entwurfsverfasser;

		var antw_bauamt = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte[3])
		if(antw_bauamt)
		this.datenZumUebernehmen['bauamt'] = antw_bauamt;

		var antw_bauvorlageberechtigter = this.getDiesenKontakt(this.projectDaten.ProjektBeteiligte[10])
		if(antw_bauvorlageberechtigter)
		this.datenZumUebernehmen['bauvorlageberechtigter'] = antw_bauvorlageberechtigter;

		this.datenZumUebernehmen['baugrundstueck'] = this.getBaugrundstueck();
		this.datenZumUebernehmen['bezeichnung_bauvorhaben'] = this.getBezeichnung();

		if(this.actualformular)
		{
			if(this.actualformular.formularArt){

				var inhalt = this.actualformular.formularContent[this.actualformular.formularArt]
				console.log(inhalt)

				Object.keys(inhalt).forEach(key => {
					if(inhalt[key]){
						if(inhalt[key].datenuebernehmen)
						{
							const parts = inhalt[key].datenuebernehmen.split('<nl>');
							//console.log(parts);
							var gesamt = parts.length

							var aktuellerInhalt = JSON.parse(JSON.stringify(inhalt[key].text));
						
								var neuerInhalt = '';

								for (let i in parts) {
										
										const subparts = parts[i].split('<&>');
										var gesamtSub = subparts.length
										for (let y in subparts) {

											neuerInhalt += this.fetchFromObject(this.datenZumUebernehmen,subparts[y])

											if(y < gesamtSub-1 && gesamtSub>1)
											neuerInhalt = neuerInhalt +' ';
										}

										if(i < gesamt-1 && gesamt>1)
										neuerInhalt = neuerInhalt +'\n';
								}
							

							if(beiBelegung=='ueberschreiben'){

							//console.log(neuerInhalt)

							if(neuerInhalt.length>0 && neuerInhalt!='\n' && neuerInhalt!=' ')
							inhalt[key].text = neuerInhalt;

							}
							else if(beiBelegung=='behalten'){

								if(aktuellerInhalt.length==0)
								inhalt[key].text = neuerInhalt;
							}

						}
					}
				});

				

			}
			
		}

	console.log(this.popoverDatenUebernehmenShow)
		this.popoverDatenUebernehmenShow=false
	console.log(this.popoverDatenUebernehmenShow)

	},
	
	findeRufnummer(kontakt)
	{

		var aktuellerufnummer="";

				if(kontakt.rufnummerPreferred>=0){

					const RufnummerIndex = kontakt.telefone.findIndex(item => item.id == kontakt.rufnummerPreferred);

					if(RufnummerIndex>=0)
					aktuellerufnummer = kontakt.telefone[RufnummerIndex].telefonNummer
				
				}
				else if (kontakt.telefone[0])
					aktuellerufnummer = kontakt.telefone[0].telefonNummer;

			return aktuellerufnummer;

	},

	findeEmail(kontakt)
	{
		var aktuelleemail="";

				if(kontakt.emailPreferred>=0){

					const RufnummerIndex = kontakt.emails.findIndex(item => item.id == kontakt.emailPreferred);

					if(RufnummerIndex>=0)
					aktuelleemail = kontakt.emails[RufnummerIndex].emailAdresse
				
				}
				else if (kontakt.emails[0])
					aktuelleemail = kontakt.emails[0].emailAdresse;

			return aktuelleemail;

	},

	findeFax(kontakt)
	{
		var aktuelleFax="";

					const RufnummerIndex = kontakt.telefone.findIndex(item => item.telefonArt == 3);
					console.log(RufnummerIndex)

					if (RufnummerIndex>=0)
					aktuelleFax = kontakt.telefone[RufnummerIndex].telefonNummer;

			return aktuelleFax;

	},


		deletePDFdirect(dateiID){

			  this.axios.post(this.$store.state.url_getFormularPDF, {
					besonders : this.$store.state.dev_sub,
					dateiID:dateiID,
					was:'deletePDF'

        		}).then((response) => {
						if(response.data.delete===true)
						this.$toasted.show("Erfolgreich gelöscht.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})
						// this.pdf_vorhanden

						var item = this.pdf_vorhanden.find(x => x.dateiID === dateiID);
						console.log(item)

						var pos = 	this.pdf_vorhanden.map(function(e) { return e.dateiID; }).indexOf(dateiID);
						console.log(pos)
						this.pdf_vorhanden.splice(pos, 1);
						this.pdfloeschen=null;
				})

		},

		deletePDF(row){

		var dateiID = row.item.dateiID

		 this.clicks++ 
          if(this.clicks === 1) {
            var self = this
            this.timer = setTimeout(function() {
			  self.pdfloeschen=row.index
			  console.log(row.index)
              self.clicks = 0
            }, this.delay);
          } else{
             clearTimeout(this.timer);  
			 //alert('dblclick');
			 this.deletePDFdirect(dateiID)


             this.clicks = 0;
		  }         


		},


		getTextToValue(check){
			for(let i of this.options_formulare_neu){
			
			if(i.value===check)
			return i.text;
			}
		},

        loadExistForm(myarg){
                    console.log("loadExistForm")
					console.log(myarg)
                    var pos = this.projectDaten.formular.map(function(e) { return e.formularID; }).indexOf(myarg);

                    //this.actualformular = JSON.parse(JSON.stringify(this.projectDaten.formular[pos]));
                    this.actualformular = this.projectDaten.formular[pos];

                    this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(this.projectDaten.formular[pos]));
                                
                    this.projektEinstellungen.formular.neu=false;
                    this.projektEinstellungen.formular.aktuelleID = myarg;
                    
                    this.currentTabComponent = this.actualformular.formularArt;

                    setTimeout(()=>{
						this.selected_exist_form = null;
						this.selected_form = 'keins';

                    },500);
                    
        },

        loadForm: function(myarg) { // Just a regular js function that takes 1 arg
            
			// aus Datenbank geladenes Formular mit BlancForm vergleichen und neu hinzugekommene Felder mit vue.set ergänzen
			        console.log("loadForm")
					console.log(myarg)

            // myarg ist die FormularID
                this.calculateHeight();
                this.pdfListe = null;

        if((myarg || myarg===0)){

            var aenderung = this.checkUngesicherteChange();

            if(aenderung==true){

                var self = this
                  this.confirmLeave()
                        .then(function() {

                        console.log("abbrechen zum Speichern");
                        
                    })
                    .catch(function() {
                        self.loadExistForm(myarg)
                    });


                // if (this.confirmLeave()==true)
                // this.loadExistForm(myarg)
            }
            else
             this.loadExistForm(myarg)
     
        }

						this.selected_exist_form = null;
						this.selected_form = 'keins';

			},

		cleanForm(myarg){

			        console.log("cleanForm")
					console.log(myarg)

			console.log(this.options_formulare_neu)

			var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(myarg);
			console.log(pos)

			if(pos>=0){
				var zeit = new Date().toLocaleString();
				
				var NameDiesesForm = this.options_formulare_neu[pos].text + " - " + zeit;
				var leeresFormular = this.options_formulare_neu[pos].blanc;

				var neuesFormular = {formularID:null, xyKorrektur:{5:{x:1, y:2}}, formularContent: {[myarg] : JSON.parse(JSON.stringify(leeresFormular))}, formularName:NameDiesesForm, formularArt:myarg};

				this.pushBlancForm(neuesFormular);
			}
		},


        loadFreshFormDirect(myarg){

					this.currentTabComponent = myarg;
					this.projektEinstellungen.formular.neu=true;


					setTimeout(()=>{
						this.selected_exist_form = null;
						this.selected_form = 'keins';
						this.pdfListe = null;
						this.cleanForm(myarg);

                    },500);
        },


			loadFreshForm: function(myarg) { // Just a regular js function that takes 1 arg
                    this.calculateHeight();
                    this.pdfListe = null;

                    if(this.projectDaten.formular=='')
                    this.projectDaten.formular=[];
                    
				if(myarg || myarg===0){

            var aenderung = this.checkUngesicherteChange();
                if(aenderung==true){

                    var self = this
                    this.confirmLeave()
                            .then(function() {
                        })
                        .catch(function() {
                            self.loadFreshFormDirect(myarg)
                        });


                    // if (this.confirmLeave()==true)
                    // this.loadExistForm(myarg)
                }
                else
                this.loadFreshFormDirect(myarg)
                    
                
				}


					

			},

	 			
			getPDF() {

				var projectid = this.$route.params.id;

					this.axios.get(this.$store.state.url_getFormularPDF,
					
						{params:{
									projectid:projectid,
									besonders: this.$store.state.dev_sub,
									was: 'getPDF',
								}
						}).then((response) => {
							this.pdf_vorhanden = response.data;
							// console.log(this.pdf_vorhanden);
						})
            },	 
            
        confirmLeave() {

                let options = {
                html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
                loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
                reverse: false, // switch the button positions (left to right, and vise versa)
                okText: 'zurück',
                cancelText: 'okay, NICHT speichern und weiter',
                animation: 'zoom', // Available: "zoom", "bounce", "fade"
                type: 'basic', // coming soon: 'soft', 'hard'
                verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
                backdropClose: true, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
                customClass: '' // Custom class to be injected into the parent node for the current dialog instance
            };

            let message = {
                title: 'Ungespeicherte Änderungen',
                body: 'Wenn Sie die Änderungen nicht speichern, gehen diese verloren. Wollen Sie diese Seite wirklich verlassen, ohne zu speichern?'
            };


        var self = this;

        return this.$dialog
        .confirm(message, options)

			//return window.confirm('Sie haben ungespeicherte Änderungen. Klicken Sie auf abbrechen und anschließend auf das Speichern-Symbol, wenn Sie die Änderungen behalten möchten.')
        },
        

	},

	created(){

	},

	destroyed() {
        window.removeEventListener('resize', this.calculateHeight)
	},

	beforeRouteLeave (to, from, next) {

        var aenderung = this.checkUngesicherteChange();
        

        if(aenderung==true){

                var self = this
                this.confirmLeave()
                    .then(function() {
                        next(false)
                        })
                        .catch(function() {

                        // if(to.params.id != self.$route.params.id){
                            self.actualformular = {formularID:null, formularContent:{}, formularName:null, formularArt:null};
                            self.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(self.actualformular));
 						// next()
                // }
                next()

                });

        }
        else{
            if(to.params.id != this.$route.params.id){
                this.actualformular = {formularID:null, formularContent:{}, formularName:null, formularArt:null};
                this.projektEinstellungen.formular.actualformularBlanc = JSON.parse(JSON.stringify(this.actualformular));

            }
            
            next();
        }
	

},


}
</script>

<style>

.rahmen { padding:2px;
	border: solid 1px grey;
	background:none;
    position: absolute;
	transform-origin: top left;
	-webkit-transform-origin: top left;
	-moz-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
    z-index: 1;
}

.ohne_rahmen { padding:2px;
	background:none;
    position: absolute;
	transform-origin: top left;
	-webkit-transform-origin: top left;
	-moz-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
    z-index: 1;
}

.rahmen_zentriert{
	display: flex;align-items: center;
}

    .textfeld {
        overflow:hidden;
        resize:none;
        border:none;
        padding:2px;
        background:none;
        background-color: rgb(243, 239, 241);
		transform-origin: top left;
        -webkit-transform-origin: top left;
        -moz-transform-origin: top left;
        -o-transform-origin: top left;
        -ms-transform-origin: top left;
        z-index: 1;
        line-height: 1.0em;
		margin-bottom: 2px;
    }

        .textfeld:focus {
            background-color:rgb(255, 255, 255);
            border:lightgrey 1px solid;
           } 

		   .textfeld:disabled {
            background-color:rgb(255, 255, 255);
			color: black;
           }

    .col{

        margin-top:-2px;
    }

    .input_formular{
        z-index:2;
}


</style>




<style scoped>

.ergaenzungen{

	margin:3em 5em;
}

ol, ul, li {
	list-style: decimal;
}

.subnav{
	padding-top:3px;

}
.subnav_shadow{
	padding-top:3px;
}


.btn{
	margin-right:5px !important;
}
</style>
