<template>
	<div>
       <div class="kontainer">
<!-- https://github.com/Hopding/pdf-lib -->
				<h1>meine Einstellungen</h1>
				<p>Text</p>
	<b-btn @click="start">create PDF</b-btn>
	<b-btn :disabled="!pdfBytes" @click="save">download</b-btn>


<!-- <iframe id="iframe"></iframe> -->



<div @click="clickEvent($event)" style="border: 1px solid; height:2000px; width:100%;">

	<div  id="example1"></div>

</div>

        </div>

	</div>
</template>

<script>


		
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import  download  from 'downloadjs'
import  PDFObject  from 'pdfobject/pdfobject.min.js'

// console.log(PDFObject)
// console.log(download)



// console.log(PDFDocument)



export default {

    data(){
        return{
			pdfBytes:null
        }
    },
	methods:{

		clickEvent(e) {
			console.log(e)
			console.log("klcik")
		// e = Mouse click event.
		var rect = e.target.getBoundingClientRect();

		var x = e.clientX - rect.left; //x position within the element.
		var y = e.clientY - rect.top;  //y position within the element.
		console.log(y, x)
	},

	someMethod(event) {
	console.log("click");
        // clientX/Y gives the coordinates relative to the viewport in CSS pixels.
        console.log(event.clientX); // x coordinate
        console.log(event.clientY); // y coordinate

        // pageX/Y gives the coordinates relative to the <html> element in CSS pixels.
        console.log(event.pageX); 
        console.log(event.pagey); 

        // screenX/Y gives the coordinates relative to the screen in device pixels.
        console.log(event.screenX);
        console.log(event.screenY);
	},
	
  renderInIframe (pdfBytes) {
	  console.log(pdfBytes)

	//  const blob = new Blob([pdfBytes], { type: 'application/pdf;base64' });
	  
	  //const blobUrl = URL.createObjectURL(blob);

	var blob = new Blob([pdfBytes], {type : 'application/pdf'});
	var newurl = window.URL.createObjectURL(blob);


var binaryData = [];
binaryData.push(pdfBytes);

var bloburl = window.URL.createObjectURL(new Blob(binaryData,{type: "application/pdf"})) ;
	// document.getElementById("iframe").src = bloburl;

	PDFObject.embed(bloburl, "#example1");

	//  document.getElementById('iframe').src = bloburl;

	  
    },

  

   save() {
		//this.downloadItem(this.pdfBytes, "pdf-lib_creation_example.pdf");
		download(this.pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");

   },
        async start() {
			const pdfDoc = await PDFDocument.create()
			console.log(pdfDoc)

			// Embed the Times Roman font
const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

// Add a blank page to the document
const page = pdfDoc.addPage()

// Get the width and height of the page
const { width, height } = page.getSize()

// Draw a string of text toward the top of the page
const fontSize = 30
page.drawText('Creating PDFs in JavaScript is awesome!', {
  x: 50,
  y: height - 4 * fontSize,
  size: fontSize,
  font: timesRomanFont,
  color: rgb(0, 0.53, 0.71),
})

// Serialize the PDFDocument to bytes (a Uint8Array)
	this.pdfBytes = await pdfDoc.save()

	this.renderInIframe(this.pdfBytes);

		}

	},

	created(){

	}
	
	//
}
</script>


<style>


</style>
