<template>
  <span>
    <div class="subnav">
        <b-container fluid>
          <b-row>

            <b-col cols="2" style="max-width:300px !important;min-width:300px !important; padding-left:0px; padding-right:0px;">
              
              <b-button class="mr-2 btn-nav" 
                        @click="neuerEintrag" 
                        v-b-tooltip.hover 
                        title="neuer Eintrag" 
                        variant="info" >
                        <span class="fa fa-plus"></span>
              </b-button>			
            
              <b-button v-if="!showInaktivTabelle" 
                        class="mr-2 btn-nav"
                        @click="papierkorbAnzeigen"
                        v-b-tooltip.hover
                        title="inaktive Kontakte anzeigen"
                        variant="primary">
                        <span class="fa fa-trash"></span>
              </b-button>			

              <b-button v-else
                        class="mr-2 btn-nav"
                        @click="papierkorbAnzeigen" 
                        v-b-tooltip.hover 
                        title="aktive Kontakte anzeigen" 
                        variant="primary" >
                        <span class="fa fa-list"></span>
              </b-button>			
            	  <!-- style="color:blue; " -->
              <b-button v-if="$route.path.includes('/adressbuch')" 
                        class="mr-2 btn-nav" id="popover-reactive-1" 
                        v-b-tooltip.hover 
                         style="width:39px"
                        title="Filter" 
                        ref="button" 
                        variant="success" >

                        <span :style="{ color: searchColor }">
                          <b-icon icon="filter" ></b-icon>
                        </span>

              </b-button>				
              
              <b-button class="mr-2 btn-nav" 
                        v-if="!itsMobile"
                        @click="activateMehrfachauswahl"
                        v-b-tooltip.hover 
                        title="Mehrfachauswahl" 
                        style="width:39px"
                        ref="button" 
                        variant="success" >
                        <span :style="{ color: mehrfachColor }">
                          <b-icon icon="check" ></b-icon>
                        </span>
                        <!-- <span class="fa fa-check"></span> -->
              </b-button>				
              
              <b-button class="mr-2 btn-nav"
                        id="popover-sort" 
                        v-b-tooltip.hover 
                        title="sortieren" 
                        ref="button" >
                        <span class="fa fa-sort"></span>
              </b-button>		

              <b-button variant="outline-primary"
                        @click="ListeAnzeigen" 
                        v-b-tooltip.hover 
                        title="Liste(n) bearbeiten" 
                        style="width:39px"
                         >
                        <b-icon icon="list-check" ></b-icon>

              </b-button>	

            </b-col>

            <b-col style="min-width:240px !important; overflow:hidden; max-width:400px" >
              <b-input-group prepend="Suche">
                <b-form-input
                  v-model="searchTermKontakte"
                  type="text"
                  @focus.native="$event.target.select()"
                  placeholder="Sucheingabe">
                </b-form-input>
                <b-input-group-append>
                  <b-btn v-b-tooltip.hover 
                        title="Mehrfachauswahl"  v-show="searchTermKontakte" :pressed="false" @click="resetSearch" variant="warning">X</b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col style="min-width:240px !important; overflow:hidden; max-width:400px" >
                    <b-button   
                    variant="outline-info" :to="{ path: '/hilfe', hash: '#adressbuch' } ">Hilfe
                        <!--  <b-icon icon="check" ></b-icon> -->
                    </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="subnav_shadow">
        <b-container fluid>
      <b-row>

            <b-col cols="2" style="max-width:250px !important;min-width:250px !important; padding-left:0px; padding-right:0px;">
              
              <b-button class="mr-2 btn-nav" 
                      
                        variant="info" >
                        <span class="fa fa-plus"></span>
              </b-button>			
            
              <b-button v-if="!showInaktivTabelle" 
                        class="mr-2 btn-nav"
                      

                        variant="primary">
                        <span class="fa fa-trash"></span>
              </b-button>			
              
              <b-button v-else
                        class="mr-2 btn-nav"
                      

                        variant="primary" >
                        <span class="fa fa-list"></span>
              </b-button>			
            	  <!-- style="color:blue; " -->
              <b-button v-if="$route.path.includes('/adressbuch')" 
                        class="mr-2 btn-nav" 
                        style="width:39px"
                        ref="button" 
                        variant="success" >

                        <span :style="{ color: searchColor }">
                          <b-icon icon="search" ></b-icon>
                        </span>

              </b-button>				
              
              <b-button class="mr-2 btn-nav" 
                        v-if="!itsMobile"

                        style="width:39px"
                        ref="button" 
                        variant="success" >
                        <span :style="{ color: mehrfachColor }">
                          <b-icon icon="check" ></b-icon>
                        </span>
                        <!-- <span class="fa fa-check"></span> -->
              </b-button>				
              
              <b-button class="btn-nav"
                        ref="button" >
                        <span class="fa fa-sort"></span>
              </b-button>				
            </b-col>

            <b-col style="min-width:240px !important; overflow:hidden; max-width:400px" >
              <b-input-group prepend="Suche">
                <b-form-input
                  type="text"
                  placeholder="Sucheingabe">
                </b-form-input>
                <b-input-group-append>
                  <b-btn v-show="searchTermKontakte" :pressed="false" variant="warning">X</b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-col>

          </b-row>
        </b-container>
      </div>

      
	<b-popover
			target="popover-sort"
			triggers="click"
			:show.sync="sortPopoverShow"
			placement="auto"
			container="my-container"
			ref="popover"

		>	
		<template v-slot:title>
      Adressbuch sortieren
			<b-button  
      size="sm"
      v-b-tooltip.hover 
      title="schließen" 
      variant="primary" 
      @click="onCloseSort" 
      class="ml-2" >
        <b-icon icon="x-circle"></b-icon>
			</b-button>
			
		</template>


		<div>
			<b-row class="mb-2">
				<b-col >
					<b-btn variant="primary"  @click="sortAdressbook">nach Namen 
           
            <b-icon v-if="sortDirection" icon="arrow-up"></b-icon>
            <b-icon v-else icon="arrow-down"></b-icon>

          </b-btn>
				</b-col>
			</b-row>

			<b-row v-if="Mehrfachauswahl" >
				<b-col>
					<b-btn variant="primary" @click="sortSelected">nach Auswahl
            <b-icon v-if="sortCheckedDirection" icon="arrow-up"></b-icon>
            <b-icon v-else icon="arrow-down"></b-icon>
            </b-btn>
        </b-col>
			</b-row>

			<!-- <b-row>
				<b-col>
					<b-btn @click="onCloseSort" size="sm" variant="primary">fertig</b-btn>
				</b-col>
			</b-row> -->

		</div>
  </b-popover>

	<b-popover
      v-if="$route.path.includes('/adressbuch')"
			target="popover-reactive-1"
			triggers="click"
			:show.sync="popoverFilter"
			placement="auto"
			container="my-container"
			ref="popover"
		>	

		<template v-slot:title>
			<b-button @click="onClose" class="close" aria-label="Close">
			<span class="d-inline-block" aria-hidden="true">&times;</span>
			</b-button>
			Adressbuch filtern
		</template>

      <div>
        <b-form-checkbox-group :options="kontaktKind" id="kindArray" stacked v-model="kindArray" name="kindArray"></b-form-checkbox-group>
          <hr class="my-1">
        <b-form-checkbox-group :options="kontaktArt" id="kontaktArt" stacked v-model="artArray" name="kontaktArt"></b-form-checkbox-group>
         
          <hr class="my-1">

        <b-button @click="onClose" size="sm" variant="primary">Auswahl übernehmen</b-button>

      </div>
    </b-popover>


  </span> 
</template>

<script>


import { kontaktMixin } from '../mixins/kontaktMixins.js'

export default {

  mixins: [kontaktMixin],

  name: 'AdressSubnav',

  data () {
    return {

      sortDirection:false,
      sortCheckedDirection:false,
      popoverFilter: false,
      sortPopoverShow: false,
  
      kontaktKind:[
        {'value':1, 'text':'Einzelperson'},
        {'value':2, 'text':'Gruppe'},
      ],

    }
  },

	mounted() {

    if(this.kontaktArt.length==0){
        this.axios.post(this.$store.state.url_get_kontakte, {			
            besonders: this.$store.state.dev_sub,
            welche:'kontaktArtListe',
            }).then((response) => {

                this.kontaktArt = response.data;

            })
            .catch(function (error) {
                        // handle error
                        // console.log(error);
            })
    }
    
			this.$root.$on('legeNeuenKontaktan', daten => {
          console.log('legeNeuenKontaktan')
          this.neuerEintrag();

			})
			
  },
  
  methods: {


    ListeAnzeigen(){

      this.kontaktEinstellungen.showList=!this.kontaktEinstellungen.showList

      if(this.kontaktEinstellungen.showList)
      this.Mehrfachauswahl=false;
    
    },


    refreshListeAdressbuch(){
      this.$root.$emit("refreshListeAdressbuch");
    },


    activateMehrfachauswahl(){

      this.kontaktHighlight=null;
      this.Mehrfachauswahl = !this.Mehrfachauswahl
      if(this.Mehrfachauswahl)
      this.kontaktEinstellungen.showList=false
    },
    
    neuerEintrag(){

      if(this.Addressbook){
        this.bearbeiten=true;

        this.KontaktDetail = JSON.parse(JSON.stringify(this.blancVcard));
        this.KontaktDetail_backup = JSON.parse(JSON.stringify(this.blancVcard));

        this.$root.$emit('openKontaktModal');


        }
		
    },
    
    papierkorbAnzeigen(){
      this.showInaktivTabelle = !this.showInaktivTabelle;

    },

    sortAdressbook() {

      this.sortDirection = !this.sortDirection;

      function compare(a, b) {

        //	return (a.vollerName.anzeigeName === b.vollerName.anzeigeName)? 0 : a? -1 : 1;

        if (a.vollerName.anzeigeName.toLowerCase() < b.vollerName.anzeigeName.toLowerCase())
          return -1;
        if (a.vollerName.anzeigeName.toLowerCase() > b.vollerName.anzeigeName.toLowerCase())
          return 1;
        return 0;
        }

        if(this.sortDirection)
          this.wasSortieren = this.wasSortieren.sort(compare);
        else
          this.wasSortieren = this.wasSortieren.sort(compare).reverse();
      

    },

  	sortSelected() {

      this.sortCheckedDirection = !this.sortCheckedDirection;

      function compare(x, y) {
        //return (x.markiert === y.markiert)? 0 : y? -1 : 1;
        return y.markiert - x.markiert;
      }

        if(this.sortCheckedDirection)
          this.wasSortieren = this.wasSortieren.sort(compare);
        else
          this.wasSortieren = this.wasSortieren.sort(compare).reverse();

    },
  
  	onClose() {
        this.popoverFilter = false
	  },

    onCloseSort() {
          this.sortPopoverShow = false
    },
  
  	resetSearch(){
      this.searchTermKontakte='';
      this.refreshListeAdressbuch();

    },
  

  },

  computed: {

      mehrfachColor(){

        if(this.Mehrfachauswahl)
          return "blue"
        else
          return "white"
      },

      searchColor(){

        if(this.kindArray.length>0 || this.artArray.length>0)
          return "blue"
        else
          return "white"
      },
      

    kontaktArt:
    {
        get()
        {
            return this.$store.getters.kontaktArt
        },
        set(value)
        {
            this.$store.commit('setkontaktArt', value);
        }
    },

    wasSortieren: {

 // getter
    get: function () {
      var wasSortieren = '';
      if(this.showInaktivTabelle===false)
        wasSortieren = this.Addressbook
      else if(this.showInaktivTabelle===true)
        wasSortieren = this.Papierkorb

     return wasSortieren   
    },
    // setter
    set: function (newValue) {

    }

    
    }

  },

created(){

  


  },

  
}
</script>

<style scoped lang="less">


	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
  }
  


.highlightThis{
     border: 2px solid #3c39ff !important;
}

.marked{
     border: 2px solid #0804f1 !important;
       background-color: #c3d3ff;
}

.item_new{
  width:100%;
}

.card_new:hover{
  background-color: #cccccc;
  cursor: pointer;
}

.mehrfachMarkierung:hover {
  background-color: #cccccc;
}

.mehrfachMarkierung{
    position: relative;
    border: 0.5px solid #bfffe1;
    height: 100%;
    width: 60px !important;

    padding:5px;
    display: inline-block;
    cursor: pointer;
}

.card_new{

    position: relative;
    border: 0.5px solid #cccccc;
    height: 100%;
    margin-left: 15px;
    padding:5px;
    display: inline-block;
    width:245px;

}

.card_new span{
        vertical-align:bottom;
}
// .item {
//   box-sizing: border-box;
//   display: flex;
//   @media (max-width: 640px) {
//     -webkit-user-select: none;
//     user-select: none;
//   }
  // &:hover {
  //   background-color: #f0f8ff;
  // }
//   .index {
//     flex: 1;
//     text-align: left;
//   }
//   .card {
//     position: relative;
//     // flex: 4;
//     // display: flex;
//     // align-items: left;
//     // border-bottom: 1px dashed #cecece;
//     @media (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
//       border-bottom: 0.5px solid #cccccc;
//     }
//     &-avatar {
//       width: 40px;
//       height: 40px;
//       background: #efefef;
//       color: #4169e1;
//       border-radius: 50%;
//       text-align: left;
//       line-height: 40px;
//       &.no-avatar {
//         background: #ff6347;
//         color: #ffffff;
//       }
//       &-img {
//         display: block;
//         width: 100%;
//         height: 100%;
//         border-radius: 50%;
//       }
//     }
//     &-info {
//       display: flex;
//       flex-direction: row;
//       // height: 100%;
//       padding-left: 40px;
//       @media (max-width: 640px) {
//         padding-left: 20px;
//       }
//       &-item {
//         flex: 1;
//         height: 50%;
//         line-height: 1;
//         position: relative;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         max-width: 300px;
//         overflow: hidden;
//         @media (max-width: 640px) {
//           max-width: 180px;
//         }
//         &.name {
//           padding-bottom: 3px;
//         }
//         &.time {
//           padding-top: 3px;
//           color: #a9a9a9;
//         }
//       }
//     }
//     &-height {
//       position: absolute;
//       right: 30px;
//       font-style: italic;
//       color: #999;
//       font-weight: 100;
//       font-family: sans-serif;
//       font-size: 12px;
//       @media (max-width: 375px) {
//         right: 10px;
//       }
//     }
//   }
// }
</style>
