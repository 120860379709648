<template>
	<div v-if="formular!=undefined" >
	<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
	position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(16.1551419) + 'px'}">
											An die untere Bauaufsichtsbehörde
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(16.1551419) + 'px'}">
											Eingangsstempel der Bauaufsichtsbehörde
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(160.319785) + 'px', 
												top: gib_hoehe_in_mm(9.0263695) + 'px'}">
											Anlage I/2 zur VV BauPrüfVO
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(191.050536) + 'px', 
												top: gib_hoehe_in_mm(12.3276415) + 'px'}">
											Blatt 1
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(82.5157044) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(19.1538737) + 'px'}"><b-popover 
										v-if="formular.bauaufsicht"
										:show="formular.bauaufsicht.showPop==true"
										
										ref='bauaufsicht' 
										target='bauaufsicht'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauaufsicht.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht.size}}</span>
										<b-button @click='plus("bauaufsicht", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauaufsicht", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.bauaufsicht!=undefined"
										
										rows='4'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.bauaufsicht.text"
										
										@focus='formular.bauaufsicht.showPop=true'
										@blur ='formular.bauaufsicht.showPop=false'
										
										:disabled="formular.bauaufsicht.isDisabled==true || formular.bauaufsicht.isDisabled=='true'"
										:style="{'font-size': formular.bauaufsicht.size + 'px',
												'height': formular.bauaufsicht.height + 'px',
												'text-align': formular.bauaufsicht.schriftausrichtung + 'px',
												'letter-spacing': formular.bauaufsicht.letterSpacing + 'px',
												'font-weight': formular.bauaufsicht.schriftart + 'px', }"

										id='bauaufsicht'>
								</textarea>


								 
								 
								 </span>
							


								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(82.5157044) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(19.1538737) + 'px'}"><b-popover 
										v-if="formular.bauaufsicht"
										:show="formular.bauaufsicht.showPop==true"
										
										ref='bauaufsicht' 
										target='bauaufsicht'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauaufsicht.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht.size}}</span>
										<b-button @click='plus("bauaufsicht", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauaufsicht", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.bauaufsicht!=undefined"
										
										rows='4'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.bauaufsicht.text"
										
										@focus='formular.bauaufsicht.showPop=true'
										@blur ='formular.bauaufsicht.showPop=false'
										
										:disabled="formular.bauaufsicht.isDisabled==true || formular.bauaufsicht.isDisabled=='true'"
										:style="{'font-size': formular.bauaufsicht.size + 'px',
												'height': formular.bauaufsicht.height + 'px',
												'text-align': formular.bauaufsicht.schriftausrichtung + 'px',
												'letter-spacing': formular.bauaufsicht.letterSpacing + 'px',
												'font-weight': formular.bauaufsicht.schriftart + 'px', }"

										id='bauaufsicht'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(39.4333419) + 'px'}">
											PLZ, Ort
											</div>
<b-popover 
										v-if="formular.bauaufsicht_plz"
										:show="formular.bauaufsicht_plz.showPop==true"
										
										ref='bauaufsicht_plz' 
										target='bauaufsicht_plz'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauaufsicht_plz.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht_plz.size}}</span>
										<b-button @click='plus("bauaufsicht_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauaufsicht_plz", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauaufsicht_plz" type="text" 
										v-if="formular.bauaufsicht_plz!=undefined" 
										class='textfeld' v-model='formular.bauaufsicht_plz.text' 
										style='width:100%' 
										@focus='formular.bauaufsicht_plz.showPop=true'
										@blur ='formular.bauaufsicht_plz.showPop=false'
										:disabled='formular.bauaufsicht_plz.isDisabled==true || formular.bauaufsicht_plz.isDisabled==true' 
										@keypress="isNumber($event, formular.bauaufsicht_plz.onylNumber) " 
										:style="{'width': spalte_breit_mm(82.5157044) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3873403) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(43.0756869) + 'px', 

												'font-size': formular.bauaufsicht_plz.size + 'px', 
												'text-align': formular.bauaufsicht_plz.schriftausrichtung, 
												'letter-spacing': formular.bauaufsicht_plz.letterSpacing + 'px',
												'font-weight': formular.bauaufsicht_plz.schriftart }" 
										/>

<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.ist_antrag!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'20px',
												fontWeight:'bold',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(50.1925318) + 'px'}"
						v-model="formular.ist_antrag.text" 
						value='1' 
						unchecked-value='0'> Bauantrag
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.ist_voranfrage!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'20px',
												fontWeight:'bold',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(56.4589527) + 'px'}"
						v-model="formular.ist_voranfrage.text" 
						value='1' 
						unchecked-value='0'> Antrag auf Vorbescheid
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.ist_referenzgeb!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'20px',
												fontWeight:'bold',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(62.778984) + 'px'}"
						v-model="formular.ist_referenzgeb.text" 
						value='1' 
						unchecked-value='0'> Referenzgebäude
						</b-form-checkbox>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(39.4333419) + 'px'}">
											Aktenzeichen
											</div>
<div :style="{
												fontSize:'18px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(111.012325) + 'px', 
												top: gib_hoehe_in_mm(53.8432531) + 'px'}">
											Einfaches Baugenehmigungsverfahren
											</div>
<div :style="{
												fontSize:'18px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(128.407489) + 'px', 
												top: gib_hoehe_in_mm(58.7105131) + 'px'}">
											 64 BauO NRW 2018
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(69.787762) + 'px'}">
											Bauherrschaft ( 53 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(74.1813459) + 'px'}">
											Name, Vorname, Firma
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(69.787762) + 'px'}">
											Entwurfsverfassende ( 54 Absatz 1 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(74.1813459) + 'px'}">
											Name, Vorname, Büro
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(82.3799149) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(77.7235884) + 'px'}"><b-popover 
										v-if="formular.bauherr_name"
										:show="formular.bauherr_name.showPop==true"
										
										ref='bauherr_name' 
										target='bauherr_name'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
										<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.bauherr_name!=undefined"
										
										rows='2'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.bauherr_name.text"
										
										@focus='formular.bauherr_name.showPop=true'
										@blur ='formular.bauherr_name.showPop=false'
										
										:disabled="formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled=='true'"
										:style="{'font-size': formular.bauherr_name.size + 'px',
												'height': formular.bauherr_name.height + 'px',
												'text-align': formular.bauherr_name.schriftausrichtung + 'px',
												'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
												'font-weight': formular.bauherr_name.schriftart + 'px', }"

										id='bauherr_name'>
								</textarea>


								 
								 
								 </span>
							


								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(94.2941209) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(102.8046433) + 'px',
								
								 top: gib_hoehe_in_mm(77.7235884) + 'px'}"><b-popover 
										v-if="formular.antragsteller_name"
										:show="formular.antragsteller_name.showPop==true"
										
										ref='antragsteller_name' 
										target='antragsteller_name'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_name.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_name.size}}</span>
										<b-button @click='plus("antragsteller_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_name", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.antragsteller_name!=undefined"
										
										rows='2'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.antragsteller_name.text"
										
										@focus='formular.antragsteller_name.showPop=true'
										@blur ='formular.antragsteller_name.showPop=false'
										
										:disabled="formular.antragsteller_name.isDisabled==true || formular.antragsteller_name.isDisabled=='true'"
										:style="{'font-size': formular.antragsteller_name.size + 'px',
												'height': formular.antragsteller_name.height + 'px',
												'text-align': formular.antragsteller_name.schriftausrichtung + 'px',
												'letter-spacing': formular.antragsteller_name.letterSpacing + 'px',
												'font-weight': formular.antragsteller_name.schriftart + 'px', }"

										id='antragsteller_name'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(87.0869916) + 'px'}">
											Straße, Hausnummer
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(87.0869916) + 'px'}">
											Straße, Hausnummer
											</div>
<b-popover 
										v-if="formular.bauherr_strasse"
										:show="formular.bauherr_strasse.showPop==true"
										
										ref='bauherr_strasse' 
										target='bauherr_strasse'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_strasse.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_strasse.size}}</span>
										<b-button @click='plus("bauherr_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_strasse", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauherr_strasse" type="text" 
										v-if="formular.bauherr_strasse!=undefined" 
										class='textfeld' v-model='formular.bauherr_strasse.text' 
										style='width:100%' 
										@focus='formular.bauherr_strasse.showPop=true'
										@blur ='formular.bauherr_strasse.showPop=false'
										:disabled='formular.bauherr_strasse.isDisabled==true || formular.bauherr_strasse.isDisabled==true' 
										@keypress="isNumber($event, formular.bauherr_strasse.onylNumber) " 
										:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(90.7885902) + 'px', 

												'font-size': formular.bauherr_strasse.size + 'px', 
												'text-align': formular.bauherr_strasse.schriftausrichtung, 
												'letter-spacing': formular.bauherr_strasse.letterSpacing + 'px',
												'font-weight': formular.bauherr_strasse.schriftart }" 
										/>

<b-popover 
										v-if="formular.antragsteller_strasse"
										:show="formular.antragsteller_strasse.showPop==true"
										
										ref='antragsteller_strasse' 
										target='antragsteller_strasse'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
										<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_strasse" type="text" 
										v-if="formular.antragsteller_strasse!=undefined" 
										class='textfeld' v-model='formular.antragsteller_strasse.text' 
										style='width:100%' 
										@focus='formular.antragsteller_strasse.showPop=true'
										@blur ='formular.antragsteller_strasse.showPop=false'
										:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
										:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(103.0546433) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(90.7885902) + 'px', 

												'font-size': formular.antragsteller_strasse.size + 'px', 
												'text-align': formular.antragsteller_strasse.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
												'font-weight': formular.antragsteller_strasse.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(95.0897546) + 'px'}">
											PLZ, Ort
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(95.0897546) + 'px'}">
											PLZ, Ort
											</div>
<b-popover 
										v-if="formular.bauherr_ort"
										:show="formular.bauherr_ort.showPop==true"
										
										ref='bauherr_ort' 
										target='bauherr_ort'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_ort.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_ort.size}}</span>
										<b-button @click='plus("bauherr_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_ort", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauherr_ort" type="text" 
										v-if="formular.bauherr_ort!=undefined" 
										class='textfeld' v-model='formular.bauherr_ort.text' 
										style='width:100%' 
										@focus='formular.bauherr_ort.showPop=true'
										@blur ='formular.bauherr_ort.showPop=false'
										:disabled='formular.bauherr_ort.isDisabled==true || formular.bauherr_ort.isDisabled==true' 
										@keypress="isNumber($event, formular.bauherr_ort.onylNumber) " 
										:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(98.8682418) + 'px', 

												'font-size': formular.bauherr_ort.size + 'px', 
												'text-align': formular.bauherr_ort.schriftausrichtung, 
												'letter-spacing': formular.bauherr_ort.letterSpacing + 'px',
												'font-weight': formular.bauherr_ort.schriftart }" 
										/>

<b-popover 
										v-if="formular.antragsteller_ort"
										:show="formular.antragsteller_ort.showPop==true"
										
										ref='antragsteller_ort' 
										target='antragsteller_ort'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
										<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_ort" type="text" 
										v-if="formular.antragsteller_ort!=undefined" 
										class='textfeld' v-model='formular.antragsteller_ort.text' 
										style='width:100%' 
										@focus='formular.antragsteller_ort.showPop=true'
										@blur ='formular.antragsteller_ort.showPop=false'
										:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
										:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(103.0546433) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(98.8682418) + 'px', 

												'font-size': formular.antragsteller_ort.size + 'px', 
												'text-align': formular.antragsteller_ort.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
												'font-weight': formular.antragsteller_ort.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(103.1552982) + 'px'}">
											vertreten durch ( 53 Absatz 3 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(107.0251226) + 'px'}">
											Name, Vorname, Anschrift
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(82.3799149) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(110.7200842) + 'px'}"><b-popover 
										v-if="formular.bauherr_vertreter"
										:show="formular.bauherr_vertreter.showPop==true"
										
										ref='bauherr_vertreter' 
										target='bauherr_vertreter'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_vertreter.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_vertreter.size}}</span>
										<b-button @click='plus("bauherr_vertreter", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_vertreter", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.bauherr_vertreter!=undefined"
										
										rows='4'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.bauherr_vertreter.text"
										
										@focus='formular.bauherr_vertreter.showPop=true'
										@blur ='formular.bauherr_vertreter.showPop=false'
										
										:disabled="formular.bauherr_vertreter.isDisabled==true || formular.bauherr_vertreter.isDisabled=='true'"
										:style="{'font-size': formular.bauherr_vertreter.size + 'px',
												'height': formular.bauherr_vertreter.height + 'px',
												'text-align': formular.bauherr_vertreter.schriftausrichtung + 'px',
												'letter-spacing': formular.bauherr_vertreter.letterSpacing + 'px',
												'font-weight': formular.bauherr_vertreter.schriftart + 'px', }"

										id='bauherr_vertreter'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(103.1552982) + 'px'}">
											bauvorlageberechtigt ( 67 Absatz 3 BauO NRW 2018) Name, Vorname
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(94.2941209) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(102.8046433) + 'px',
								
								 top: gib_hoehe_in_mm(107.4498498) + 'px'}"><b-popover 
										v-if="formular.antragsteller_vorlagenberechtigt"
										:show="formular.antragsteller_vorlagenberechtigt.showPop==true"
										
										ref='antragsteller_vorlagenberechtigt' 
										target='antragsteller_vorlagenberechtigt'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_vorlagenberechtigt.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_vorlagenberechtigt.size}}</span>
										<b-button @click='plus("antragsteller_vorlagenberechtigt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_vorlagenberechtigt", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.antragsteller_vorlagenberechtigt!=undefined"
										
										rows='2'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.antragsteller_vorlagenberechtigt.text"
										
										@focus='formular.antragsteller_vorlagenberechtigt.showPop=true'
										@blur ='formular.antragsteller_vorlagenberechtigt.showPop=false'
										
										:disabled="formular.antragsteller_vorlagenberechtigt.isDisabled==true || formular.antragsteller_vorlagenberechtigt.isDisabled=='true'"
										:style="{'font-size': formular.antragsteller_vorlagenberechtigt.size + 'px',
												'height': formular.antragsteller_vorlagenberechtigt.height + 'px',
												'text-align': formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
												'letter-spacing': formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
												'font-weight': formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }"

										id='antragsteller_vorlagenberechtigt'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(115.7438666) + 'px'}">
											Mitgliedsnummer der Architekten oder der Ingenieurkammer des Landes
											</div>
<b-popover 
										v-if="formular.antragsteller_mitgliedsnummer"
										:show="formular.antragsteller_mitgliedsnummer.showPop==true"
										
										ref='antragsteller_mitgliedsnummer' 
										target='antragsteller_mitgliedsnummer'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_mitgliedsnummer.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_mitgliedsnummer.size}}</span>
										<b-button @click='plus("antragsteller_mitgliedsnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_mitgliedsnummer", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_mitgliedsnummer" type="text" 
										v-if="formular.antragsteller_mitgliedsnummer!=undefined" 
										class='textfeld' v-model='formular.antragsteller_mitgliedsnummer.text' 
										style='width:100%' 
										@focus='formular.antragsteller_mitgliedsnummer.showPop=true'
										@blur ='formular.antragsteller_mitgliedsnummer.showPop=false'
										:disabled='formular.antragsteller_mitgliedsnummer.isDisabled==true || formular.antragsteller_mitgliedsnummer.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_mitgliedsnummer.onylNumber) " 
										:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(103.0546433) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(119.8493067) + 'px', 

												'font-size': formular.antragsteller_mitgliedsnummer.size + 'px', 
												'text-align': formular.antragsteller_mitgliedsnummer.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_mitgliedsnummer.letterSpacing + 'px',
												'font-weight': formular.antragsteller_mitgliedsnummer.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(127.6792346) + 'px'}">
											Telefon (mit Vorwahl)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(60.3113473) + 'px', 
												top: gib_hoehe_in_mm(127.6792346) + 'px'}">
											Telefax
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(127.6792346) + 'px'}">
											Telefon (mit Vorwahl)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(147.791881) + 'px', 
												top: gib_hoehe_in_mm(127.6792346) + 'px'}">
											Telefax
											</div>
<b-popover 
										v-if="formular.bauherr_tel"
										:show="formular.bauherr_tel.showPop==true"
										
										ref='bauherr_tel' 
										target='bauherr_tel'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_tel.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_tel.size}}</span>
										<b-button @click='plus("bauherr_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_tel", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauherr_tel" type="text" 
										v-if="formular.bauherr_tel!=undefined" 
										class='textfeld' v-model='formular.bauherr_tel.text' 
										style='width:100%' 
										@focus='formular.bauherr_tel.showPop=true'
										@blur ='formular.bauherr_tel.showPop=false'
										:disabled='formular.bauherr_tel.isDisabled==true || formular.bauherr_tel.isDisabled==true' 
										@keypress="isNumber($event, formular.bauherr_tel.onylNumber) " 
										:style="{'width': spalte_breit_mm(40.755319) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(131.4735933) + 'px', 

												'font-size': formular.bauherr_tel.size + 'px', 
												'text-align': formular.bauherr_tel.schriftausrichtung, 
												'letter-spacing': formular.bauherr_tel.letterSpacing + 'px',
												'font-weight': formular.bauherr_tel.schriftart }" 
										/>

<b-popover 
										v-if="formular.bauherr_fax"
										:show="formular.bauherr_fax.showPop==true"
										
										ref='bauherr_fax' 
										target='bauherr_fax'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_fax.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_fax.size}}</span>
										<b-button @click='plus("bauherr_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_fax", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauherr_fax" type="text" 
										v-if="formular.bauherr_fax!=undefined" 
										class='textfeld' v-model='formular.bauherr_fax.text' 
										style='width:100%' 
										@focus='formular.bauherr_fax.showPop=true'
										@blur ='formular.bauherr_fax.showPop=false'
										:disabled='formular.bauherr_fax.isDisabled==true || formular.bauherr_fax.isDisabled==true' 
										@keypress="isNumber($event, formular.bauherr_fax.onylNumber) " 
										:style="{'width': spalte_breit_mm(39.2059079) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(60.5613473) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(131.4735933) + 'px', 

												'font-size': formular.bauherr_fax.size + 'px', 
												'text-align': formular.bauherr_fax.schriftausrichtung, 
												'letter-spacing': formular.bauherr_fax.letterSpacing + 'px',
												'font-weight': formular.bauherr_fax.schriftart }" 
										/>

<b-popover 
										v-if="formular.antragsteller_tel"
										:show="formular.antragsteller_tel.showPop==true"
										
										ref='antragsteller_tel' 
										target='antragsteller_tel'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_tel.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_tel.size}}</span>
										<b-button @click='plus("antragsteller_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_tel", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_tel" type="text" 
										v-if="formular.antragsteller_tel!=undefined" 
										class='textfeld' v-model='formular.antragsteller_tel.text' 
										style='width:100%' 
										@focus='formular.antragsteller_tel.showPop=true'
										@blur ='formular.antragsteller_tel.showPop=false'
										:disabled='formular.antragsteller_tel.isDisabled==true || formular.antragsteller_tel.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_tel.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.4994205) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(103.0546433) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(131.4735933) + 'px', 

												'font-size': formular.antragsteller_tel.size + 'px', 
												'text-align': formular.antragsteller_tel.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_tel.letterSpacing + 'px',
												'font-weight': formular.antragsteller_tel.schriftart }" 
										/>

<b-popover 
										v-if="formular.antragsteller_fax"
										:show="formular.antragsteller_fax.showPop==true"
										
										ref='antragsteller_fax' 
										target='antragsteller_fax'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_fax.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_fax.size}}</span>
										<b-button @click='plus("antragsteller_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_fax", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_fax" type="text" 
										v-if="formular.antragsteller_fax!=undefined" 
										class='textfeld' v-model='formular.antragsteller_fax.text' 
										style='width:100%' 
										@focus='formular.antragsteller_fax.showPop=true'
										@blur ='formular.antragsteller_fax.showPop=false'
										:disabled='formular.antragsteller_fax.isDisabled==true || formular.antragsteller_fax.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_fax.onylNumber) " 
										:style="{'width': spalte_breit_mm(38.9343289) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(148.0457607) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(131.4735933) + 'px', 

												'font-size': formular.antragsteller_fax.size + 'px', 
												'text-align': formular.antragsteller_fax.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_fax.letterSpacing + 'px',
												'font-weight': formular.antragsteller_fax.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(135.6326196) + 'px'}">
											EMail
											</div>
<b-popover 
										v-if="formular.bauherr_email"
										:show="formular.bauherr_email.showPop==true"
										
										ref='bauherr_email' 
										target='bauherr_email'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bauherr_email.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_email.size}}</span>
										<b-button @click='plus("bauherr_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bauherr_email", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bauherr_email" type="text" 
										v-if="formular.bauherr_email!=undefined" 
										class='textfeld' v-model='formular.bauherr_email.text' 
										style='width:100%' 
										@focus='formular.bauherr_email.showPop=true'
										@blur ='formular.bauherr_email.showPop=false'
										:disabled='formular.bauherr_email.isDisabled==true || formular.bauherr_email.isDisabled==true' 
										@keypress="isNumber($event, formular.bauherr_email.onylNumber) " 
										:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(139.6160255) + 'px', 

												'font-size': formular.bauherr_email.size + 'px', 
												'text-align': formular.bauherr_email.schriftausrichtung, 
												'letter-spacing': formular.bauherr_email.letterSpacing + 'px',
												'font-weight': formular.bauherr_email.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(102.804996) + 'px', 
												top: gib_hoehe_in_mm(135.6326196) + 'px'}">
											EMail
											</div>
<b-popover 
										v-if="formular.antragsteller_email"
										:show="formular.antragsteller_email.showPop==true"
										
										ref='antragsteller_email' 
										target='antragsteller_email'  
										
										>
										<template slot='title'>
											<b-button @click="formular.antragsteller_email.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_email.size}}</span>
										<b-button @click='plus("antragsteller_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("antragsteller_email", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="antragsteller_email" type="text" 
										v-if="formular.antragsteller_email!=undefined" 
										class='textfeld' v-model='formular.antragsteller_email.text' 
										style='width:100%' 
										@focus='formular.antragsteller_email.showPop=true'
										@blur ='formular.antragsteller_email.showPop=false'
										:disabled='formular.antragsteller_email.isDisabled==true || formular.antragsteller_email.isDisabled==true' 
										@keypress="isNumber($event, formular.antragsteller_email.onylNumber) " 
										:style="{'width': spalte_breit_mm(83.9254463) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(103.0546433) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(139.6160255) + 'px', 

												'font-size': formular.antragsteller_email.size + 'px', 
												'text-align': formular.antragsteller_email.schriftausrichtung, 
												'letter-spacing': formular.antragsteller_email.letterSpacing + 'px',
												'font-weight': formular.antragsteller_email.schriftart }" 
										/>

<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(143.8551147) + 'px'}">
											Baugrundstück
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(148.2486986) + 'px'}">
											Ort, Straße, Hausnummer, gegebenenfalls Ortsteil
											</div>
<b-popover 
										v-if="formular.baugrund_strasse"
										:show="formular.baugrund_strasse.showPop==true"
										
										ref='baugrund_strasse' 
										target='baugrund_strasse'  
										
										>
										<template slot='title'>
											<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
										<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="baugrund_strasse" type="text" 
										v-if="formular.baugrund_strasse!=undefined" 
										class='textfeld' v-model='formular.baugrund_strasse.text' 
										style='width:100%' 
										@focus='formular.baugrund_strasse.showPop=true'
										@blur ='formular.baugrund_strasse.showPop=false'
										:disabled='formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled==true' 
										@keypress="isNumber($event, formular.baugrund_strasse.onylNumber) " 
										:style="{'width': spalte_breit_mm(179.5723958) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(152.1802576) + 'px', 

												'font-size': formular.baugrund_strasse.size + 'px', 
												'text-align': formular.baugrund_strasse.schriftausrichtung, 
												'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
												'font-weight': formular.baugrund_strasse.schriftart }" 
										/>

<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(156.2479346) + 'px'}">
											Gemarkung(en)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(96.495193) + 'px', 
												top: gib_hoehe_in_mm(156.2479346) + 'px'}">
											Flur(e)
											</div>
<div :style="{
												fontSize:'9px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(127.349389) + 'px', 
												top: gib_hoehe_in_mm(156.2479346) + 'px'}">
											Flurstück(e)
											</div>
<b-popover 
										v-if="formular.gemarkungen"
										:show="formular.gemarkungen.showPop==true"
										
										ref='gemarkungen' 
										target='gemarkungen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.gemarkungen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemarkungen.size}}</span>
										<b-button @click='plus("gemarkungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("gemarkungen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="gemarkungen" type="text" 
										v-if="formular.gemarkungen!=undefined" 
										class='textfeld' v-model='formular.gemarkungen.text' 
										style='width:100%' 
										@focus='formular.gemarkungen.showPop=true'
										@blur ='formular.gemarkungen.showPop=false'
										:disabled='formular.gemarkungen.isDisabled==true || formular.gemarkungen.isDisabled==true' 
										@keypress="isNumber($event, formular.gemarkungen.onylNumber) " 
										:style="{'width': spalte_breit_mm(73.3232843) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.3954524) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(160.2944738) + 'px', 

												'font-size': formular.gemarkungen.size + 'px', 
												'text-align': formular.gemarkungen.schriftausrichtung, 
												'letter-spacing': formular.gemarkungen.letterSpacing + 'px',
												'font-weight': formular.gemarkungen.schriftart }" 
										/>

<b-popover 
										v-if="formular.flure"
										:show="formular.flure.showPop==true"
										
										ref='flure' 
										target='flure'  
										
										>
										<template slot='title'>
											<b-button @click="formular.flure.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flure.size}}</span>
										<b-button @click='plus("flure", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("flure", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="flure" type="text" 
										v-if="formular.flure!=undefined" 
										class='textfeld' v-model='formular.flure.text' 
										style='width:100%' 
										@focus='formular.flure.showPop=true'
										@blur ='formular.flure.showPop=false'
										:disabled='formular.flure.isDisabled==true || formular.flure.isDisabled==true' 
										@keypress="isNumber($event, formular.flure.onylNumber) " 
										:style="{'width': spalte_breit_mm(27.3026356) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(96.2041512) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(160.2944738) + 'px', 

												'font-size': formular.flure.size + 'px', 
												'text-align': formular.flure.schriftausrichtung, 
												'letter-spacing': formular.flure.letterSpacing + 'px',
												'font-weight': formular.flure.schriftart }" 
										/>

<b-popover 
										v-if="formular.flurstuecke"
										:show="formular.flurstuecke.showPop==true"
										
										ref='flurstuecke' 
										target='flurstuecke'  
										
										>
										<template slot='title'>
											<b-button @click="formular.flurstuecke.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flurstuecke.size}}</span>
										<b-button @click='plus("flurstuecke", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("flurstuecke", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="flurstuecke" type="text" 
										v-if="formular.flurstuecke!=undefined" 
										class='textfeld' v-model='formular.flurstuecke.text' 
										style='width:100%' 
										@focus='formular.flurstuecke.showPop=true'
										@blur ='formular.flurstuecke.showPop=false'
										:disabled='formular.flurstuecke.isDisabled==true || formular.flurstuecke.isDisabled==true' 
										@keypress="isNumber($event, formular.flurstuecke.onylNumber) " 
										:style="{'width': spalte_breit_mm(69.4076089) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(127.5602393) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(160.2944738) + 'px', 

												'font-size': formular.flurstuecke.size + 'px', 
												'text-align': formular.flurstuecke.schriftausrichtung, 
												'letter-spacing': formular.flurstuecke.letterSpacing + 'px',
												'font-weight': formular.flurstuecke.schriftart }" 
										/>

<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											Gebäudeklassen ( 2 Absatz 3 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(93.916956) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											1
											</div>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(96.9251343) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='96.9251343|165.531075'> 
						</b-form-radio>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(107.71458) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='107.71458|165.531075'> 
						</b-form-radio>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(104.663725) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											2
											</div>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(120.203687) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='120.203687|165.531075'> 
						</b-form-radio>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(117.1524793) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											3
											</div>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(132.7308856) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='132.7308856|165.531075'> 
						</b-form-radio>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(129.680736) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											4
											</div>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(145.2132913) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='145.2132913|165.531075'> 
						</b-form-radio>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(142.1624363) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											5
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(154.1284892) + 'px', 
												top: gib_hoehe_in_mm(164.8090217) + 'px'}">
											Sonstige bauliche Anlage
											</div>
<b-form-radio size='sm'  
						
						v-if="formular.g_klasse!=undefined"	
						
						@change="recalKlasse"
						:disabled="false"
						class='size_10 input_formular'
						:style="{position:'absolute', 
						
						left: spalte_breit_mm(191.8670365) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"

						name="'g_klasse_radio'"
						v-model="formular.g_klasse.text" 
						
						value='191.8670365|165.531075'> 
						</b-form-radio>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.wohngebaude_check!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(170.7760767) + 'px'}"
						v-model="formular.wohngebaude_check.text" 
						value='1' 
						unchecked-value='0'> Wohngebäude
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.sonderbau_check!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(96.8792833) + 'px',top: gib_hoehe_in_mm(170.4381901) + 'px'}"
						v-model="formular.sonderbau_check.text" 
						value='1' 
						unchecked-value='0'> Sonderbau (nicht § 50 Absatz 2 BauO NRW 2018)
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(175.2630497) + 'px'}">
											Bezeichnung des Vorhabens (Errichtung, Änderung, Nutzungsänderung gemäß 60 BauO NRW 2018)
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(180.2453474) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(180.9877998) + 'px'}"><b-popover 
										v-if="formular.bezeichnung_bauvorhaben"
										:show="formular.bezeichnung_bauvorhaben.showPop==true"
										
										ref='bezeichnung_bauvorhaben' 
										target='bezeichnung_bauvorhaben'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
										<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.bezeichnung_bauvorhaben!=undefined"
										
										rows='2'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.bezeichnung_bauvorhaben.text"
										
										@focus='formular.bezeichnung_bauvorhaben.showPop=true'
										@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
										
										:disabled="formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled=='true'"
										:style="{'font-size': formular.bezeichnung_bauvorhaben.size + 'px',
												'height': formular.bezeichnung_bauvorhaben.height + 'px',
												'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung + 'px',
												'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
												'font-weight': formular.bezeichnung_bauvorhaben.schriftart + 'px', }"

										id='bezeichnung_bauvorhaben'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(189.8613027) + 'px'}">
											Das Bauvorhaben bedarf einer
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bedarf_ausnahme!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(190.5837087) + 'px'}"
						v-model="formular.bedarf_ausnahme.text" 
						value='1' 
						unchecked-value='0'> Ausnahme (§ 31 Absatz 1 BauGB)
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bedarf_befreiung!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(195.0245544) + 'px'}"
						v-model="formular.bedarf_befreiung.text" 
						value='1' 
						unchecked-value='0'> Befreiung (§ 31 Absatz 2 BauGB)
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bedarf_abweichung!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(199.3878061) + 'px'}"
						v-model="formular.bedarf_abweichung.text" 
						value='1' 
						unchecked-value='0'> Abweichung (§ 69 BauO NRW 2018)
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(203.7989486) + 'px'}">
											HinweisDer Antrag ist hinreichend bestimmt auf separater Anlage schriftlich zu begründen.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.abweichung_check!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.2420922) + 'px',top: gib_hoehe_in_mm(208.1739158) + 'px'}"
						v-model="formular.abweichung_check.text" 
						value='1' 
						unchecked-value='0'> Es liegt eine Abweichung (§ 69 Absatz 1a BauO NRW 2018) vor
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(20.0767421) + 'px', 
												top: gib_hoehe_in_mm(211.7696159) + 'px'}">
											(Bescheinigung durch Sachverständige/n für die Prüfung des Brandschutz)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(216.6982457) + 'px'}">
											Bei Vorbescheid ( 77 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(221.6395727) + 'px'}">
											planungsrechtliche Zulässigkeit
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.planungsrechtlicheZulaessigkeit!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(62.5132534) + 'px',top: gib_hoehe_in_mm(222.9615687) + 'px'}"
						v-model="formular.planungsrechtlicheZulaessigkeit.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(79.3776039) + 'px', 
												top: gib_hoehe_in_mm(221.6395727) + 'px'}">
											bauordnungsrechtliche Zulässigkeit
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bauordnungsrechtlicheZulaessigkeit!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'16px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(130.6545407) + 'px',top: gib_hoehe_in_mm(222.9615687) + 'px'}"
						v-model="formular.bauordnungsrechtlicheZulaessigkeit.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'14.94px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(227.5589368) + 'px'}">
											Fragestellung
											</div>

								 <span class='input_formular'  
								 :style="{width: spalte_breit_mm(180.2453474) + 'px', 
								 position:'absolute', 
								 left: spalte_breit_mm(17.1373403) + 'px',
								
								 top: gib_hoehe_in_mm(232.7715665) + 'px'}"><b-popover 
										v-if="formular.genaue_fragestellung"
										:show="formular.genaue_fragestellung.showPop==true"
										
										ref='genaue_fragestellung' 
										target='genaue_fragestellung'  
										
										>
										<template slot='title'>
											<b-button @click="formular.genaue_fragestellung.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.genaue_fragestellung.size}}</span>
										<b-button @click='plus("genaue_fragestellung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("genaue_fragestellung", "-")' size='sm'>-</b-button>
									
									</b-popover>
									<textarea
										v-if="formular.genaue_fragestellung!=undefined"
										
										rows='2'
										
										class='textfeld'
										style=' width:100%;line-height: 1.2;'
										v-model="formular.genaue_fragestellung.text"
										
										@focus='formular.genaue_fragestellung.showPop=true'
										@blur ='formular.genaue_fragestellung.showPop=false'
										
										:disabled="formular.genaue_fragestellung.isDisabled==true || formular.genaue_fragestellung.isDisabled=='true'"
										:style="{'font-size': formular.genaue_fragestellung.size + 'px',
												'height': formular.genaue_fragestellung.height + 'px',
												'text-align': formular.genaue_fragestellung.schriftausrichtung + 'px',
												'letter-spacing': formular.genaue_fragestellung.letterSpacing + 'px',
												'font-weight': formular.genaue_fragestellung.schriftart + 'px', }"

										id='genaue_fragestellung'>
								</textarea>


								 
								 
								 </span>
							

<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(242.0044707) + 'px'}">
											Bindungen zur Beurteilung des
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.1373403) + 'px', 
												top: gib_hoehe_in_mm(245.6870114) + 'px'}">
											Vorhabens
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bindung_vorbescheid!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(250.4266704) + 'px'}"
						v-model="formular.bindung_vorbescheid.text" 
						value='1' 
						unchecked-value='0'> Vorbescheid
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bindung_teilungsgen!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(255.3644704) + 'px'}"
						v-model="formular.bindung_teilungsgen.text" 
						value='1' 
						unchecked-value='0'> Teilungsgenehmigung
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bindung_baulast!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(259.9763756) + 'px'}"
						v-model="formular.bindung_baulast.text" 
						value='1' 
						unchecked-value='0'>  Baulast Nr.
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bindung_denkmal!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(264.7625146) + 'px'}"
						v-model="formular.bindung_denkmal.text" 
						value='1' 
						unchecked-value='0'> Denkmalrechtliche Erlaubnis
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.bindung_sonstiges!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(269.6855012) + 'px'}"
						v-model="formular.bindung_sonstiges.text" 
						value='1' 
						unchecked-value='0'> sonstiges
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(80.9619323) + 'px', 
												top: gib_hoehe_in_mm(242.0926457) + 'px'}">
											Bescheid vom
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(113.160268) + 'px', 
												top: gib_hoehe_in_mm(242.0926457) + 'px'}">
											erteilt von (Behörde)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(150.292524) + 'px', 
												top: gib_hoehe_in_mm(242.0926457) + 'px'}">
											Aktenzeichen
											</div>
<b-popover 
										v-if="formular.bindung_vorbescheid_bescheid_vom"
										:show="formular.bindung_vorbescheid_bescheid_vom.showPop==true"
										
										ref='bindung_vorbescheid_bescheid_vom' 
										target='bindung_vorbescheid_bescheid_vom'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_vorbescheid_bescheid_vom.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_bescheid_vom.size}}</span>
										<b-button @click='plus("bindung_vorbescheid_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_vorbescheid_bescheid_vom", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_vorbescheid_bescheid_vom" type="text" 
										v-if="formular.bindung_vorbescheid_bescheid_vom!=undefined" 
										class='textfeld' v-model='formular.bindung_vorbescheid_bescheid_vom.text' 
										style='width:100%' 
										@focus='formular.bindung_vorbescheid_bescheid_vom.showPop=true'
										@blur ='formular.bindung_vorbescheid_bescheid_vom.showPop=false'
										:disabled='formular.bindung_vorbescheid_bescheid_vom.isDisabled==true || formular.bindung_vorbescheid_bescheid_vom.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_vorbescheid_bescheid_vom.onylNumber) " 
										:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(80.9946691) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(250.3306717) + 'px', 

												'font-size': formular.bindung_vorbescheid_bescheid_vom.size + 'px', 
												'text-align': formular.bindung_vorbescheid_bescheid_vom.schriftausrichtung, 
												'letter-spacing': formular.bindung_vorbescheid_bescheid_vom.letterSpacing + 'px',
												'font-weight': formular.bindung_vorbescheid_bescheid_vom.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_vorbescheid_erteilt_von"
										:show="formular.bindung_vorbescheid_erteilt_von.showPop==true"
										
										ref='bindung_vorbescheid_erteilt_von' 
										target='bindung_vorbescheid_erteilt_von'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_vorbescheid_erteilt_von.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_erteilt_von.size}}</span>
										<b-button @click='plus("bindung_vorbescheid_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_vorbescheid_erteilt_von", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_vorbescheid_erteilt_von" type="text" 
										v-if="formular.bindung_vorbescheid_erteilt_von!=undefined" 
										class='textfeld' v-model='formular.bindung_vorbescheid_erteilt_von.text' 
										style='width:100%' 
										@focus='formular.bindung_vorbescheid_erteilt_von.showPop=true'
										@blur ='formular.bindung_vorbescheid_erteilt_von.showPop=false'
										:disabled='formular.bindung_vorbescheid_erteilt_von.isDisabled==true || formular.bindung_vorbescheid_erteilt_von.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_vorbescheid_erteilt_von.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(105.382816) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(250.3306717) + 'px', 

												'font-size': formular.bindung_vorbescheid_erteilt_von.size + 'px', 
												'text-align': formular.bindung_vorbescheid_erteilt_von.schriftausrichtung, 
												'letter-spacing': formular.bindung_vorbescheid_erteilt_von.letterSpacing + 'px',
												'font-weight': formular.bindung_vorbescheid_erteilt_von.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_vorbescheid_aktenzeichen"
										:show="formular.bindung_vorbescheid_aktenzeichen.showPop==true"
										
										ref='bindung_vorbescheid_aktenzeichen' 
										target='bindung_vorbescheid_aktenzeichen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_vorbescheid_aktenzeichen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_aktenzeichen.size}}</span>
										<b-button @click='plus("bindung_vorbescheid_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_vorbescheid_aktenzeichen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_vorbescheid_aktenzeichen" type="text" 
										v-if="formular.bindung_vorbescheid_aktenzeichen!=undefined" 
										class='textfeld' v-model='formular.bindung_vorbescheid_aktenzeichen.text' 
										style='width:100%' 
										@focus='formular.bindung_vorbescheid_aktenzeichen.showPop=true'
										@blur ='formular.bindung_vorbescheid_aktenzeichen.showPop=false'
										:disabled='formular.bindung_vorbescheid_aktenzeichen.isDisabled==true || formular.bindung_vorbescheid_aktenzeichen.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_vorbescheid_aktenzeichen.onylNumber) " 
										:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(150.2490776) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(250.3306717) + 'px', 

												'font-size': formular.bindung_vorbescheid_aktenzeichen.size + 'px', 
												'text-align': formular.bindung_vorbescheid_aktenzeichen.schriftausrichtung, 
												'letter-spacing': formular.bindung_vorbescheid_aktenzeichen.letterSpacing + 'px',
												'font-weight': formular.bindung_vorbescheid_aktenzeichen.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_teilungsgen_bescheid_vom"
										:show="formular.bindung_teilungsgen_bescheid_vom.showPop==true"
										
										ref='bindung_teilungsgen_bescheid_vom' 
										target='bindung_teilungsgen_bescheid_vom'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_teilungsgen_bescheid_vom.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_bescheid_vom.size}}</span>
										<b-button @click='plus("bindung_teilungsgen_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_teilungsgen_bescheid_vom", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_teilungsgen_bescheid_vom" type="text" 
										v-if="formular.bindung_teilungsgen_bescheid_vom!=undefined" 
										class='textfeld' v-model='formular.bindung_teilungsgen_bescheid_vom.text' 
										style='width:100%' 
										@focus='formular.bindung_teilungsgen_bescheid_vom.showPop=true'
										@blur ='formular.bindung_teilungsgen_bescheid_vom.showPop=false'
										:disabled='formular.bindung_teilungsgen_bescheid_vom.isDisabled==true || formular.bindung_teilungsgen_bescheid_vom.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_teilungsgen_bescheid_vom.onylNumber) " 
										:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(80.9946691) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(255.2476624) + 'px', 

												'font-size': formular.bindung_teilungsgen_bescheid_vom.size + 'px', 
												'text-align': formular.bindung_teilungsgen_bescheid_vom.schriftausrichtung, 
												'letter-spacing': formular.bindung_teilungsgen_bescheid_vom.letterSpacing + 'px',
												'font-weight': formular.bindung_teilungsgen_bescheid_vom.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_baulast_bescheid_vom"
										:show="formular.bindung_baulast_bescheid_vom.showPop==true"
										
										ref='bindung_baulast_bescheid_vom' 
										target='bindung_baulast_bescheid_vom'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_baulast_bescheid_vom.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_bescheid_vom.size}}</span>
										<b-button @click='plus("bindung_baulast_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_baulast_bescheid_vom", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_baulast_bescheid_vom" type="text" 
										v-if="formular.bindung_baulast_bescheid_vom!=undefined" 
										class='textfeld' v-model='formular.bindung_baulast_bescheid_vom.text' 
										style='width:100%' 
										@focus='formular.bindung_baulast_bescheid_vom.showPop=true'
										@blur ='formular.bindung_baulast_bescheid_vom.showPop=false'
										:disabled='formular.bindung_baulast_bescheid_vom.isDisabled==true || formular.bindung_baulast_bescheid_vom.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_baulast_bescheid_vom.onylNumber) " 
										:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(80.9946691) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(259.9085929) + 'px', 

												'font-size': formular.bindung_baulast_bescheid_vom.size + 'px', 
												'text-align': formular.bindung_baulast_bescheid_vom.schriftausrichtung, 
												'letter-spacing': formular.bindung_baulast_bescheid_vom.letterSpacing + 'px',
												'font-weight': formular.bindung_baulast_bescheid_vom.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_teilungsgen_erteilt_von"
										:show="formular.bindung_teilungsgen_erteilt_von.showPop==true"
										
										ref='bindung_teilungsgen_erteilt_von' 
										target='bindung_teilungsgen_erteilt_von'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_teilungsgen_erteilt_von.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_erteilt_von.size}}</span>
										<b-button @click='plus("bindung_teilungsgen_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_teilungsgen_erteilt_von", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_teilungsgen_erteilt_von" type="text" 
										v-if="formular.bindung_teilungsgen_erteilt_von!=undefined" 
										class='textfeld' v-model='formular.bindung_teilungsgen_erteilt_von.text' 
										style='width:100%' 
										@focus='formular.bindung_teilungsgen_erteilt_von.showPop=true'
										@blur ='formular.bindung_teilungsgen_erteilt_von.showPop=false'
										:disabled='formular.bindung_teilungsgen_erteilt_von.isDisabled==true || formular.bindung_teilungsgen_erteilt_von.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_teilungsgen_erteilt_von.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(105.382816) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(255.2476624) + 'px', 

												'font-size': formular.bindung_teilungsgen_erteilt_von.size + 'px', 
												'text-align': formular.bindung_teilungsgen_erteilt_von.schriftausrichtung, 
												'letter-spacing': formular.bindung_teilungsgen_erteilt_von.letterSpacing + 'px',
												'font-weight': formular.bindung_teilungsgen_erteilt_von.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_baulast_erteilt_von"
										:show="formular.bindung_baulast_erteilt_von.showPop==true"
										
										ref='bindung_baulast_erteilt_von' 
										target='bindung_baulast_erteilt_von'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_baulast_erteilt_von.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_erteilt_von.size}}</span>
										<b-button @click='plus("bindung_baulast_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_baulast_erteilt_von", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_baulast_erteilt_von" type="text" 
										v-if="formular.bindung_baulast_erteilt_von!=undefined" 
										class='textfeld' v-model='formular.bindung_baulast_erteilt_von.text' 
										style='width:100%' 
										@focus='formular.bindung_baulast_erteilt_von.showPop=true'
										@blur ='formular.bindung_baulast_erteilt_von.showPop=false'
										:disabled='formular.bindung_baulast_erteilt_von.isDisabled==true || formular.bindung_baulast_erteilt_von.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_baulast_erteilt_von.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(105.382816) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(259.9085929) + 'px', 

												'font-size': formular.bindung_baulast_erteilt_von.size + 'px', 
												'text-align': formular.bindung_baulast_erteilt_von.schriftausrichtung, 
												'letter-spacing': formular.bindung_baulast_erteilt_von.letterSpacing + 'px',
												'font-weight': formular.bindung_baulast_erteilt_von.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_denkmal_bescheid_vom"
										:show="formular.bindung_denkmal_bescheid_vom.showPop==true"
										
										ref='bindung_denkmal_bescheid_vom' 
										target='bindung_denkmal_bescheid_vom'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_denkmal_bescheid_vom.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_bescheid_vom.size}}</span>
										<b-button @click='plus("bindung_denkmal_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_denkmal_bescheid_vom", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_denkmal_bescheid_vom" type="text" 
										v-if="formular.bindung_denkmal_bescheid_vom!=undefined" 
										class='textfeld' v-model='formular.bindung_denkmal_bescheid_vom.text' 
										style='width:100%' 
										@focus='formular.bindung_denkmal_bescheid_vom.showPop=true'
										@blur ='formular.bindung_denkmal_bescheid_vom.showPop=false'
										:disabled='formular.bindung_denkmal_bescheid_vom.isDisabled==true || formular.bindung_denkmal_bescheid_vom.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_denkmal_bescheid_vom.onylNumber) " 
										:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(80.9946691) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(264.7116615) + 'px', 

												'font-size': formular.bindung_denkmal_bescheid_vom.size + 'px', 
												'text-align': formular.bindung_denkmal_bescheid_vom.schriftausrichtung, 
												'letter-spacing': formular.bindung_denkmal_bescheid_vom.letterSpacing + 'px',
												'font-weight': formular.bindung_denkmal_bescheid_vom.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_denkmal_erteilt_von"
										:show="formular.bindung_denkmal_erteilt_von.showPop==true"
										
										ref='bindung_denkmal_erteilt_von' 
										target='bindung_denkmal_erteilt_von'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_denkmal_erteilt_von.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_erteilt_von.size}}</span>
										<b-button @click='plus("bindung_denkmal_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_denkmal_erteilt_von", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_denkmal_erteilt_von" type="text" 
										v-if="formular.bindung_denkmal_erteilt_von!=undefined" 
										class='textfeld' v-model='formular.bindung_denkmal_erteilt_von.text' 
										style='width:100%' 
										@focus='formular.bindung_denkmal_erteilt_von.showPop=true'
										@blur ='formular.bindung_denkmal_erteilt_von.showPop=false'
										:disabled='formular.bindung_denkmal_erteilt_von.isDisabled==true || formular.bindung_denkmal_erteilt_von.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_denkmal_erteilt_von.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(105.382816) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(264.7116615) + 'px', 

												'font-size': formular.bindung_denkmal_erteilt_von.size + 'px', 
												'text-align': formular.bindung_denkmal_erteilt_von.schriftausrichtung, 
												'letter-spacing': formular.bindung_denkmal_erteilt_von.letterSpacing + 'px',
												'font-weight': formular.bindung_denkmal_erteilt_von.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_sonstiges_text"
										:show="formular.bindung_sonstiges_text.showPop==true"
										
										ref='bindung_sonstiges_text' 
										target='bindung_sonstiges_text'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_sonstiges_text.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_text.size}}</span>
										<b-button @click='plus("bindung_sonstiges_text", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_sonstiges_text", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_sonstiges_text" type="text" 
										v-if="formular.bindung_sonstiges_text!=undefined" 
										class='textfeld' v-model='formular.bindung_sonstiges_text.text' 
										style='width:100%' 
										@focus='formular.bindung_sonstiges_text.showPop=true'
										@blur ='formular.bindung_sonstiges_text.showPop=false'
										:disabled='formular.bindung_sonstiges_text.isDisabled==true || formular.bindung_sonstiges_text.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_sonstiges_text.onylNumber) " 
										:style="{'width': spalte_breit_mm(56.4571703) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(22.1893508) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(269.619482) + 'px', 

												'font-size': formular.bindung_sonstiges_text.size + 'px', 
												'text-align': formular.bindung_sonstiges_text.schriftausrichtung, 
												'letter-spacing': formular.bindung_sonstiges_text.letterSpacing + 'px',
												'font-weight': formular.bindung_sonstiges_text.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_sonstiges_bescheid_vom"
										:show="formular.bindung_sonstiges_bescheid_vom.showPop==true"
										
										ref='bindung_sonstiges_bescheid_vom' 
										target='bindung_sonstiges_bescheid_vom'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_sonstiges_bescheid_vom.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_bescheid_vom.size}}</span>
										<b-button @click='plus("bindung_sonstiges_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_sonstiges_bescheid_vom", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_sonstiges_bescheid_vom" type="text" 
										v-if="formular.bindung_sonstiges_bescheid_vom!=undefined" 
										class='textfeld' v-model='formular.bindung_sonstiges_bescheid_vom.text' 
										style='width:100%' 
										@focus='formular.bindung_sonstiges_bescheid_vom.showPop=true'
										@blur ='formular.bindung_sonstiges_bescheid_vom.showPop=false'
										:disabled='formular.bindung_sonstiges_bescheid_vom.isDisabled==true || formular.bindung_sonstiges_bescheid_vom.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_sonstiges_bescheid_vom.onylNumber) " 
										:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(80.9946691) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(269.619482) + 'px', 

												'font-size': formular.bindung_sonstiges_bescheid_vom.size + 'px', 
												'text-align': formular.bindung_sonstiges_bescheid_vom.schriftausrichtung, 
												'letter-spacing': formular.bindung_sonstiges_bescheid_vom.letterSpacing + 'px',
												'font-weight': formular.bindung_sonstiges_bescheid_vom.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_sonstiges_erteilt_von"
										:show="formular.bindung_sonstiges_erteilt_von.showPop==true"
										
										ref='bindung_sonstiges_erteilt_von' 
										target='bindung_sonstiges_erteilt_von'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_sonstiges_erteilt_von.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_erteilt_von.size}}</span>
										<b-button @click='plus("bindung_sonstiges_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_sonstiges_erteilt_von", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_sonstiges_erteilt_von" type="text" 
										v-if="formular.bindung_sonstiges_erteilt_von!=undefined" 
										class='textfeld' v-model='formular.bindung_sonstiges_erteilt_von.text' 
										style='width:100%' 
										@focus='formular.bindung_sonstiges_erteilt_von.showPop=true'
										@blur ='formular.bindung_sonstiges_erteilt_von.showPop=false'
										:disabled='formular.bindung_sonstiges_erteilt_von.isDisabled==true || formular.bindung_sonstiges_erteilt_von.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_sonstiges_erteilt_von.onylNumber) " 
										:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(105.382816) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(269.619482) + 'px', 

												'font-size': formular.bindung_sonstiges_erteilt_von.size + 'px', 
												'text-align': formular.bindung_sonstiges_erteilt_von.schriftausrichtung, 
												'letter-spacing': formular.bindung_sonstiges_erteilt_von.letterSpacing + 'px',
												'font-weight': formular.bindung_sonstiges_erteilt_von.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_teilungsgen_aktenzeichen"
										:show="formular.bindung_teilungsgen_aktenzeichen.showPop==true"
										
										ref='bindung_teilungsgen_aktenzeichen' 
										target='bindung_teilungsgen_aktenzeichen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_teilungsgen_aktenzeichen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_aktenzeichen.size}}</span>
										<b-button @click='plus("bindung_teilungsgen_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_teilungsgen_aktenzeichen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_teilungsgen_aktenzeichen" type="text" 
										v-if="formular.bindung_teilungsgen_aktenzeichen!=undefined" 
										class='textfeld' v-model='formular.bindung_teilungsgen_aktenzeichen.text' 
										style='width:100%' 
										@focus='formular.bindung_teilungsgen_aktenzeichen.showPop=true'
										@blur ='formular.bindung_teilungsgen_aktenzeichen.showPop=false'
										:disabled='formular.bindung_teilungsgen_aktenzeichen.isDisabled==true || formular.bindung_teilungsgen_aktenzeichen.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_teilungsgen_aktenzeichen.onylNumber) " 
										:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(150.2490776) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(255.2476624) + 'px', 

												'font-size': formular.bindung_teilungsgen_aktenzeichen.size + 'px', 
												'text-align': formular.bindung_teilungsgen_aktenzeichen.schriftausrichtung, 
												'letter-spacing': formular.bindung_teilungsgen_aktenzeichen.letterSpacing + 'px',
												'font-weight': formular.bindung_teilungsgen_aktenzeichen.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_baulast_aktenzeichen"
										:show="formular.bindung_baulast_aktenzeichen.showPop==true"
										
										ref='bindung_baulast_aktenzeichen' 
										target='bindung_baulast_aktenzeichen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_baulast_aktenzeichen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_aktenzeichen.size}}</span>
										<b-button @click='plus("bindung_baulast_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_baulast_aktenzeichen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_baulast_aktenzeichen" type="text" 
										v-if="formular.bindung_baulast_aktenzeichen!=undefined" 
										class='textfeld' v-model='formular.bindung_baulast_aktenzeichen.text' 
										style='width:100%' 
										@focus='formular.bindung_baulast_aktenzeichen.showPop=true'
										@blur ='formular.bindung_baulast_aktenzeichen.showPop=false'
										:disabled='formular.bindung_baulast_aktenzeichen.isDisabled==true || formular.bindung_baulast_aktenzeichen.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_baulast_aktenzeichen.onylNumber) " 
										:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(150.2490776) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(259.9085929) + 'px', 

												'font-size': formular.bindung_baulast_aktenzeichen.size + 'px', 
												'text-align': formular.bindung_baulast_aktenzeichen.schriftausrichtung, 
												'letter-spacing': formular.bindung_baulast_aktenzeichen.letterSpacing + 'px',
												'font-weight': formular.bindung_baulast_aktenzeichen.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_denkmal_aktenzeichen"
										:show="formular.bindung_denkmal_aktenzeichen.showPop==true"
										
										ref='bindung_denkmal_aktenzeichen' 
										target='bindung_denkmal_aktenzeichen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_denkmal_aktenzeichen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_aktenzeichen.size}}</span>
										<b-button @click='plus("bindung_denkmal_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_denkmal_aktenzeichen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_denkmal_aktenzeichen" type="text" 
										v-if="formular.bindung_denkmal_aktenzeichen!=undefined" 
										class='textfeld' v-model='formular.bindung_denkmal_aktenzeichen.text' 
										style='width:100%' 
										@focus='formular.bindung_denkmal_aktenzeichen.showPop=true'
										@blur ='formular.bindung_denkmal_aktenzeichen.showPop=false'
										:disabled='formular.bindung_denkmal_aktenzeichen.isDisabled==true || formular.bindung_denkmal_aktenzeichen.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_denkmal_aktenzeichen.onylNumber) " 
										:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(150.2490776) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(264.7116615) + 'px', 

												'font-size': formular.bindung_denkmal_aktenzeichen.size + 'px', 
												'text-align': formular.bindung_denkmal_aktenzeichen.schriftausrichtung, 
												'letter-spacing': formular.bindung_denkmal_aktenzeichen.letterSpacing + 'px',
												'font-weight': formular.bindung_denkmal_aktenzeichen.schriftart }" 
										/>

<b-popover 
										v-if="formular.bindung_sonstiges_aktenzeichen"
										:show="formular.bindung_sonstiges_aktenzeichen.showPop==true"
										
										ref='bindung_sonstiges_aktenzeichen' 
										target='bindung_sonstiges_aktenzeichen'  
										
										>
										<template slot='title'>
											<b-button @click="formular.bindung_sonstiges_aktenzeichen.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_aktenzeichen.size}}</span>
										<b-button @click='plus("bindung_sonstiges_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("bindung_sonstiges_aktenzeichen", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="bindung_sonstiges_aktenzeichen" type="text" 
										v-if="formular.bindung_sonstiges_aktenzeichen!=undefined" 
										class='textfeld' v-model='formular.bindung_sonstiges_aktenzeichen.text' 
										style='width:100%' 
										@focus='formular.bindung_sonstiges_aktenzeichen.showPop=true'
										@blur ='formular.bindung_sonstiges_aktenzeichen.showPop=false'
										:disabled='formular.bindung_sonstiges_aktenzeichen.isDisabled==true || formular.bindung_sonstiges_aktenzeichen.isDisabled==true' 
										@keypress="isNumber($event, formular.bindung_sonstiges_aktenzeichen.onylNumber) " 
										:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(150.2490776) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(269.619482) + 'px', 

												'font-size': formular.bindung_sonstiges_aktenzeichen.size + 'px', 
												'text-align': formular.bindung_sonstiges_aktenzeichen.schriftausrichtung, 
												'letter-spacing': formular.bindung_sonstiges_aktenzeichen.letterSpacing + 'px',
												'font-weight': formular.bindung_sonstiges_aktenzeichen.schriftart }" 
										/>

<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(169.080853) + 'px', 
												top: gib_hoehe_in_mm(273.6243784) + 'px'}">
											Fortsetzung Blatt 2
											</div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(16.1647412) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(199.0483612) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(262.5518294) + 'px', 
											top: gib_hoehe_in_mm(16.2934767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(148.9762476) + 'px', 
											width: spalte_breit_mm(50.0721136) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(278.7453061) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(257.6436562) + 'px', 
											top: gib_hoehe_in_mm(16.1647412) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(39.5716767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(47.6943577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(69.8756607) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(74.3196807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(95.2277367) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(103.1846487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(127.8175694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(135.7748341) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(143.9430134) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(148.3870334) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(164.5544487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(169.7183294) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(175.0091821) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(180.1688304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(189.8627899) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(216.4401457) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(227.5325607) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(242.0923694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(79.5652403) + 'px', 
											width: spalte_breit_mm(119.4831209) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(246.5762445) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(254.5356254) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(254.5356254) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(258.9796454) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(263.8465527) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(268.6686671) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(101.4887196) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(127.7495367) + 'px', 
											top: gib_hoehe_in_mm(16.2934767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(95.1827963) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(8.2681793) + 'px', 
											top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(126.0835487) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(8.2681793) + 'px', 
											top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(79.5652403) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(31.716028) + 'px', 
											top: gib_hoehe_in_mm(242.0923694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(103.990068) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(31.716028) + 'px', 
											top: gib_hoehe_in_mm(242.0923694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(148.9762476) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(36.7529367) + 'px', 
											top: gib_hoehe_in_mm(242.0923694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(59.0359841) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(8.0572647) + 'px', 
											top: gib_hoehe_in_mm(127.8175694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(146.6099833) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(7.2947273) + 'px', 
											top: gib_hoehe_in_mm(128.4951061) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(15.8693838) + 'px', 
											width: spalte_breit_mm(183.1789774) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(273.7083974) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
		position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(16.6221167) + 'px'}">
											Die erforderlichen Bauvorlagen sind beigefügt
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(21.4047287) + 'px'}">
											(einem Antrag auf Vorbescheid sind nur die für die Klärung der Fragestellung erforderlichen Unterlagen beizufügen)
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(161.6868502) + 'px', 
												top: gib_hoehe_in_mm(9.1011419) + 'px'}">
											Anlage I/2 zu VV BauPrüfVO
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(191.3136502) + 'px', 
												top: gib_hoehe_in_mm(12.4024139) + 'px'}">
											Blatt 2
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(27.8344497) + 'px'}">
											 1.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(32.1035305) + 'px'}">
											 2.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(39.3042537) + 'px'}">
											 3.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.lageplan!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(27.7322431) + 'px'}"
						v-model="formular.lageplan.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.berechnungMassBaulichenNutzung!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(32.0528181) + 'px'}"
						v-model="formular.berechnungMassBaulichenNutzung.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(27.8344497) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(32.1035305) + 'px'}">
											3fach
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.beglaubigterAuszugFlurkarte!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(39.4066131) + 'px'}"
						v-model="formular.beglaubigterAuszugFlurkarte.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(39.3042537) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(50.1935135) + 'px'}">
											 4.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.Basiskarte!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(50.6200042) + 'px'}"
						v-model="formular.Basiskarte.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(50.1935135) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(57.2848997) + 'px'}">
											 5.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(61.2227952) + 'px'}">
											 6.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(65.3518541) + 'px'}">
											 7.1
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(69.1673627) + 'px'}">
											 7.2
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.Bauzeichnungen!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(57.8708108) + 'px'}"
						v-model="formular.Bauzeichnungen.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.Baubeschreibung!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(61.9265081) + 'px'}"
						v-model="formular.Baubeschreibung.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.umbauterRaum!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(65.9120181) + 'px'}"
						v-model="formular.umbauterRaum.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.ohneRohbauwerteHerstellungssumme!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.9718654) + 'px',top: gib_hoehe_in_mm(69.8093531) + 'px'}"
						v-model="formular.ohneRohbauwerteHerstellungssumme.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(57.2848997) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(61.2227952) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(65.2432225) + 'px'}">
											2fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(69.3003306) + 'px'}">
											2fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(77.3641107) + 'px'}">
											 7.3
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.herstellungssummeCheck!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(25.0424054) + 'px',top: gib_hoehe_in_mm(77.5867408) + 'px'}"
						v-model="formular.herstellungssummeCheck.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(76.9556841) + 'px'}">
											1fach
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(27.8344497) + 'px'}">
											Lageplan/amtlicher Lageplan ( 3 BauPrüfVO Anforderungen an Planersteller/in sind zu beachten)
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(32.1035305) + 'px'}">
											Berechnung des Maßes der baulichen Nutzung ( 3 Absatz 2 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(35.5391812) + 'px'}">
											(nur im Bereich eines Bebauungsplanes oder einer Satzung nach BauGB)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(39.3042537) + 'px'}">
											Beglaubigter Auszug aus der Flurkarte
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(42.9740972) + 'px'}">
											(nur bei Vorhaben nach den 34 oder 35 des Baugesetzbuches Auszug nicht erforderlich bei
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(46.2365722) + 'px'}">
											Vorlage eines amtlichen Lageplanes)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(50.1935135) + 'px'}">
											Auszug aus der amtlichen Basiskarte 1 5 000
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(53.870411) + 'px'}">
											(nur bei Vorhaben nach den 34 oder 35 des Baugesetzbuches)
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(57.2848997) + 'px'}">
											Bauzeichnungen ( 4 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(61.2227952) + 'px'}">
											Baubeschreibung auf amtlichem Vordruck
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(104.5917742) + 'px', 
												top: gib_hoehe_in_mm(63.3114846) + 'px'}">
											( 5 Absatz 1 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(65.2432225) + 'px'}">
											bei Gebäuden Berechnung des umbauten Raumes nach DIN 277
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(69.3003306) + 'px'}">
											bei Gebäuden, für die landesdurchschnittliche Rohbauwerte je m Bruttorauminhalt nicht festgelegt sind, die
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(73.0671666) + 'px'}">
											Berechnung der Rohbaukosten einschließlich Umsatzsteuer ( 6 Nummer 1 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(43.748908) + 'px', 
												top: gib_hoehe_in_mm(77.3641107) + 'px'}">
											 bei der Änderung von Gebäuden oder bei baulichen Anlagen, die nicht Gebäude sind, ist die voraussichtliche
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.4066935) + 'px', 
												top: gib_hoehe_in_mm(80.7066486) + 'px'}">
											Herstellungssumme einschließlich Umsatzsteuer gemäß Tarifstelle 2.1.3 AVerwGebO NRW in einer
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.4066935) + 'px', 
												top: gib_hoehe_in_mm(84.3443964) + 'px'}">
											separaten Anlage zwingend aufzuführen
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(138.4897712) + 'px', 
												top: gib_hoehe_in_mm(67.6870808) + 'px'}">
											( 6 Nummer 1 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(175.5264455) + 'px', 
												top: gib_hoehe_in_mm(67.5209591) + 'px'}">
											oder
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.Betriebsbeschreibung!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(25.0600404) + 'px',top: gib_hoehe_in_mm(97.7788158) + 'px'}"
						v-model="formular.Betriebsbeschreibung.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'bold',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(93.0444473) + 'px'}">
											zusätzliche Bauvorlagen für Sonderbauten, die nicht in 50 Absatz 2 BauO NRW 2018 aufgeführt sind
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(96.8924043) + 'px'}">
											 8.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7463436) + 'px', 
												top: gib_hoehe_in_mm(96.8924043) + 'px'}">
											Betriebsbeschreibung für gewerbliche oder landwirtschaftliche Betriebe auf amtlichem
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7452855) + 'px', 
												top: gib_hoehe_in_mm(100.6592403) + 'px'}">
											Vordruck ( 5 Absatz 2 oder 3 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(44.7459909) + 'px', 
												top: gib_hoehe_in_mm(104.6218248) + 'px'}">
											zusätzliche Angaben und Bauvorlagen für besondere Vorhaben ( 12 BauPrüfVO)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(104.6218248) + 'px'}">
											 9.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.zusaeztlicheAngaben!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(25.1482154) + 'px',top: gib_hoehe_in_mm(105.4853108) + 'px'}"
						v-model="formular.zusaeztlicheAngaben.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(104.6218248) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1977258) + 'px', 
												top: gib_hoehe_in_mm(96.8924043) + 'px'}">
											3fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(111.5622554) + 'px'}">
											Spätestens mit Anzeige des Baubeginns werden gemäß 68 Absatz 1 und 2 BauO NRW 2018 eingereicht
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisSchallschutzesSachverstaendiger!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.7683575) + 'px',top: gib_hoehe_in_mm(118.3373461) + 'px'}"
						v-model="formular.NachweisSchallschutzesSachverstaendiger.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(117.9108554) + 'px'}">
											10.1
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(117.9108554) + 'px'}">
											2fach
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisWaermeschutzesSachverstaendiger!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8836904) + 'px',top: gib_hoehe_in_mm(125.6248335) + 'px'}"
						v-model="formular.NachweisWaermeschutzesSachverstaendiger.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(124.7433598) + 'px'}">
											2fach
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisStandsicherheitSachverstaendiger!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8836904) + 'px',top: gib_hoehe_in_mm(132.8192081) + 'px'}"
						v-model="formular.NachweisStandsicherheitSachverstaendiger.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(132.0001623) + 'px'}">
											2fach
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisBrandschutzSachverstaendiger!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8836904) + 'px',top: gib_hoehe_in_mm(140.7725931) + 'px'}"
						v-model="formular.NachweisBrandschutzSachverstaendiger.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.1980785) + 'px', 
												top: gib_hoehe_in_mm(139.9094598) + 'px'}">
											2fach
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1233799) + 'px', 
												top: gib_hoehe_in_mm(117.9108554) + 'px'}">
											Bescheinigung zusammen mit dem Nachweis des Schallschutzes, soweit erforderlich aufgestellt oder geprüft
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1226745) + 'px', 
												top: gib_hoehe_in_mm(121.7200154) + 'px'}">
											durch eine/einen staatlich anerkannte/n Sachverständige/n
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1233799) + 'px', 
												top: gib_hoehe_in_mm(124.7433598) + 'px'}">
											Bescheinigung zusammen mit dem Nachweis des Wärmeschutzes, soweit erforderlich aufgestellt oder geprüft
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1226745) + 'px', 
												top: gib_hoehe_in_mm(128.5101958) + 'px'}">
											durch eine/einen staatlich anerkannte/n Sachverständige/n
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1233799) + 'px', 
												top: gib_hoehe_in_mm(132.0001623) + 'px'}">
											Bescheinigung zusammen mit dem Nachweis der Standsicherheit, soweit erforderlich geprüft durch eine/einen
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1226745) + 'px', 
												top: gib_hoehe_in_mm(135.7282013) + 'px'}">
											staatlich anerkannte/n Sachverständige/n
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1233799) + 'px', 
												top: gib_hoehe_in_mm(139.9094598) + 'px'}">
											die Bescheinigung einer/eines staatlich anerkannte/n Sachverständige/n, dass das Vorhaben den
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1371352) + 'px', 
												top: gib_hoehe_in_mm(143.6311502) + 'px'}">
											Anforderungen an den Brandschutz entspricht (gilt für Wohngebäude der Gebäudeklasse 4 und 5
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(45.1371352) + 'px', 
												top: gib_hoehe_in_mm(147.314749) + 'px'}">
											sowie Garagen mit einer Nutzungsfläche über 100 m bis 1000 m)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(153.1466435) + 'px'}">
											10.2 Abweichend von Nr. 10.1 wird soweit erforderlich eine Prüfung durch die Bauaufsichtsbehörde beantragt für
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisSchallschutzes!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.7718845) + 'px',top: gib_hoehe_in_mm(158.5917025) + 'px'}"
						v-model="formular.NachweisSchallschutzes.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisWaermeschutzes!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(162.8237498) + 'px'}"
						v-model="formular.NachweisWaermeschutzes.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisStandsicherheit!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8353705) + 'px',top: gib_hoehe_in_mm(167.3026871) + 'px'}"
						v-model="formular.NachweisStandsicherheit.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.NachweisBrandschutz!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8836904) + 'px',top: gib_hoehe_in_mm(171.6239675) + 'px'}"
						v-model="formular.NachweisBrandschutz.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(30.4820975) + 'px', 
												top: gib_hoehe_in_mm(157.856952) + 'px'}">
											den Nachweis des Schallschutzes
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(30.4820975) + 'px', 
												top: gib_hoehe_in_mm(162.0138742) + 'px'}">
											den Nachweis des Wärmeschutzes
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(30.4820975) + 'px', 
												top: gib_hoehe_in_mm(166.4395538) + 'px'}">
											den Nachweis der Standsicherheit
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(30.4820975) + 'px', 
												top: gib_hoehe_in_mm(170.7601288) + 'px'}">
											den Nachweis des Brandschutzes
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(179.7395181) + 'px'}">
											11.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.erhebungsbogen_statistik!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(24.8480677) + 'px',top: gib_hoehe_in_mm(180.5998298) + 'px'}"
						v-model="formular.erhebungsbogen_statistik.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(32.0480855) + 'px', 
												top: gib_hoehe_in_mm(179.7395181) + 'px'}">
											Erhebungsbogen für die Baustatistik gemäß Hochbaustatistikgesetz
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(187.9119298) + 'px'}">
											12.
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(193.7491148) + 'px'}">
											13.
											</div>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.Artenschutz!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(25.0600404) + 'px',top: gib_hoehe_in_mm(188.7750631) + 'px'}"
						v-model="formular.Artenschutz.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<b-form-checkbox size='sm'  
						style='min-height:1rem !important' 
						
						:disabled="false"
						class='size_10 input_formular'
						
						v-if="formular.BrandschutzErkl!=undefined"
						
						:style="{position:'absolute', 
												fontSize:'12px',
												fontWeight:'normal',
												paddingBottom :'5px',
												left: spalte_breit_mm(25.0600404) + 'px',top: gib_hoehe_in_mm(194.6129535) + 'px'}"
						v-model="formular.BrandschutzErkl.text" 
						value='1' 
						unchecked-value='0'>  
						</b-form-checkbox>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(204.741363) + 'px'}">
											Ort, Datum
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.7518175) + 'px', 
												top: gib_hoehe_in_mm(187.8621991) + 'px'}">
											Angaben zum Artenschutz gemäß 44 BNatSchG
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.947566) + 'px', 
												top: gib_hoehe_in_mm(194.0859433) + 'px'}">
											Erklärung der/des Entwurfsverfassenden ( 68 Absatz 4 Satz 2 BauO NRW 2018)
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(31.947566) + 'px', 
												top: gib_hoehe_in_mm(197.7360356) + 'px'}">
											Ich erkläre hiermit, dass das Vorhaben den Anforderungen an den Brandschutz entspricht.
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(106.2812072) + 'px', 
												top: gib_hoehe_in_mm(204.741363) + 'px'}">
											Ort, Datum
											</div>
<b-popover 
										v-if="formular.ort_datum_bauherr"
										:show="formular.ort_datum_bauherr.showPop==true"
										
										ref='ort_datum_bauherr' 
										target='ort_datum_bauherr'  
										
										>
										<template slot='title'>
											<b-button @click="formular.ort_datum_bauherr.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_bauherr.size}}</span>
										<b-button @click='plus("ort_datum_bauherr", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("ort_datum_bauherr", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="ort_datum_bauherr" type="text" 
										v-if="formular.ort_datum_bauherr!=undefined" 
										class='textfeld' v-model='formular.ort_datum_bauherr.text' 
										style='width:100%' 
										@focus='formular.ort_datum_bauherr.showPop=true'
										@blur ='formular.ort_datum_bauherr.showPop=false'
										:disabled='formular.ort_datum_bauherr.isDisabled==true || formular.ort_datum_bauherr.isDisabled==true' 
										@keypress="isNumber($event, formular.ort_datum_bauherr.onylNumber) " 
										:style="{'width': spalte_breit_mm(85.8677652) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(17.6504545) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(208.9815345) + 'px', 

												'font-size': formular.ort_datum_bauherr.size + 'px', 
												'text-align': formular.ort_datum_bauherr.schriftausrichtung, 
												'letter-spacing': formular.ort_datum_bauherr.letterSpacing + 'px',
												'font-weight': formular.ort_datum_bauherr.schriftart }" 
										/>

<b-popover 
										v-if="formular.ort_datum_entwurfverfasser"
										:show="formular.ort_datum_entwurfverfasser.showPop==true"
										
										ref='ort_datum_entwurfverfasser' 
										target='ort_datum_entwurfverfasser'  
										
										>
										<template slot='title'>
											<b-button @click="formular.ort_datum_entwurfverfasser.showPop=false;" class="close" aria-label="Close">
											  <span class="d-inline-block" aria-hidden="true">&times;</span>
											</b-button>
											Schriftgröße
										  </template>

										<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_entwurfverfasser.size}}</span>
										<b-button @click='plus("ort_datum_entwurfverfasser", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
										<b-button @click='plus("ort_datum_entwurfverfasser", "-")' size='sm'>-</b-button>
									
									</b-popover><input id="ort_datum_entwurfverfasser" type="text" 
										v-if="formular.ort_datum_entwurfverfasser!=undefined" 
										class='textfeld' v-model='formular.ort_datum_entwurfverfasser.text' 
										style='width:100%' 
										@focus='formular.ort_datum_entwurfverfasser.showPop=true'
										@blur ='formular.ort_datum_entwurfverfasser.showPop=false'
										:disabled='formular.ort_datum_entwurfverfasser.isDisabled==true || formular.ort_datum_entwurfverfasser.isDisabled==true' 
										@keypress="isNumber($event, formular.ort_datum_entwurfverfasser.onylNumber) " 
										:style="{'width': spalte_breit_mm(85.8681179) + 'px', 
												'position':'absolute', 
												'left': spalte_breit_mm(106.5308545) + 'px', 
												'height': 16.5 + 'px', 
												'top': gib_hoehe_in_mm(208.9815345) + 'px', 

												'font-size': formular.ort_datum_entwurfverfasser.size + 'px', 
												'text-align': formular.ort_datum_entwurfverfasser.schriftausrichtung, 
												'letter-spacing': formular.ort_datum_entwurfverfasser.letterSpacing + 'px',
												'font-weight': formular.ort_datum_entwurfverfasser.schriftart }" 
										/>

<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(213.5916641) + 'px'}">
											Für die Bauherrschaft
											</div>
<div :style="{
												fontSize:'13.5px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(106.2812072) + 'px', 
												top: gib_hoehe_in_mm(213.5916641) + 'px'}">
											Die/Der bauvorlageberechtigte () Entwurfsverfassende
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(17.4004545) + 'px', 
												top: gib_hoehe_in_mm(239.6466712) + 'px'}">
											Unterschrift
											</div>
<div :style="{
												fontSize:'12.045px',
												fontWeight:'normal',
												
												 position:'absolute', 
												left: spalte_breit_mm(106.2812072) + 'px', 
												top: gib_hoehe_in_mm(239.6466712) + 'px'}">
											Unterschrift
											</div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(185.5466525) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(201.6798559) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(223.1919202) + 'px', 
											top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(185.5466525) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(213.494748) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(227.7717297) + 'px', 
											top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.2612335) + 'px', 
											width: spalte_breit_mm(185.4186224) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(91.1498193) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(185.5466525) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(177.808562) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(185.5466525) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(185.9217201) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(105.0069021) + 'px', 
											width: spalte_breit_mm(0) + 'px', 
											height:gib_hoehe_in_mm(43.8291568) + 'px', 
											top: gib_hoehe_in_mm(200.1817338) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.5515056) + 'px', 
											width: spalte_breit_mm(185.1283503) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(204.6906506) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.1332034) + 'px', 
											width: spalte_breit_mm(185.5466525) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(243.9108906) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
											position:'absolute',
											left: spalte_breit_mm(16.2612335) + 'px', 
											width: spalte_breit_mm(185.4186224) + 'px', 
											height:gib_hoehe_in_mm(0.1) + 'px', 
											top: gib_hoehe_in_mm(111.0241116) + 'px'}"></div>
</div>
</div>
</template>
<script>

var leeresFormular = 

{
"bauaufsicht": {
"text": "",
"datenuebernehmen": "bauamt.anzeigeName<nl>bauamt.adresse.strasse",
"height": 68,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": null,
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 17.1373403,
	"top": 19.153873699999963,
	"right": 100.15304470000001,
	"bottom": 38.313243099999966,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"bauaufsicht_plz": {
"text": "",
"datenuebernehmen": "bauamt.adresse.plz<&>bauamt.adresse.ort",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": null,
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1373403,
	"top": 42.825686899999994,
	"right": 100.15304470000001,
	"bottom": 46.4507375,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"ist_antrag": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.521783300000003,
	"top": 50.19253180000001,
	"right": 21.5838292,
	"bottom": 54.25810469999999,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"ist_voranfrage": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.521783300000003,
	"top": 56.45895269999997,
	"right": 21.5845346,
	"bottom": 60.50830139999999,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"ist_referenzgeb": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.521783300000003,
	"top": 62.77898399999998,
	"right": 21.5845346,
	"bottom": 66.82833269999998,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bauherr_name": {
"text": "",
"datenuebernehmen": "bauherr.anzeigeName",
"height": 34,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 17.1373403,
	"top": 77.72358840000001,
	"right": 100.01725520000001,
	"bottom": 86.0388436,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_name": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.anzeigeName",
"height": 34,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 102.8046433,
	"top": 77.72358840000001,
	"right": 197.5987642,
	"bottom": 86.0388436,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_strasse": {
"text": "",
"datenuebernehmen": "bauherr.adresse.strasse",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 90.53859019999999,
	"right": 100.01725520000001,
	"bottom": 94.1636408,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_strasse": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.adresse.strasse",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 102.8046433,
	"top": 90.53859019999999,
	"right": 197.5987642,
	"bottom": 94.1636408,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_ort": {
"text": "",
"datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 98.61824179999999,
	"right": 100.01725520000001,
	"bottom": 102.2429397,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_ort": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 102.8046433,
	"top": 98.61824179999999,
	"right": 197.5987642,
	"bottom": 102.2429397,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_vertreter": {
"text": "",
"datenuebernehmen": "bauherr.vertreter.anzeigeName<nl>bauherr.vertreter.adresse.strasse<nl>bauherr.vertreter.adresse.plz<&>bauherr.vertreter.adresse.ort",
"height": 68,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 17.1373403,
	"top": 110.7200842,
	"right": 100.01725520000001,
	"bottom": 126.71679270000001,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_vorlagenberechtigt": {
"text": "",
"datenuebernehmen": "bauvorlageberechtigter.anzeigeName",
"height": 34,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 102.8046433,
	"top": 107.44984979999998,
	"right": 197.5987642,
	"bottom": 113.7635325,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_mitgliedsnummer": {
"text": "",
"datenuebernehmen": "bauvorlageberechtigter.AK_Mitgliedsnummer",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 102.8046433,
	"top": 119.5993067,
	"right": 197.5987642,
	"bottom": 123.22435729999998,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_tel": {
"text": "",
"datenuebernehmen": "bauherr.telefon",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 131.22359329999998,
	"right": 58.400771399999996,
	"bottom": 134.8489966,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_fax": {
"text": "",
"datenuebernehmen": "bauherr.fax",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 60.3113473,
	"top": 131.22359329999998,
	"right": 100.01725520000001,
	"bottom": 134.8489966,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_tel": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.telefon",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 102.8046433,
	"top": 131.22359329999998,
	"right": 145.8040638,
	"bottom": 134.8489966,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_fax": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.fax",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 147.79576070000002,
	"top": 131.22359329999998,
	"right": 187.23008959999999,
	"bottom": 134.8489966,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bauherr_email": {
"text": "",
"datenuebernehmen": "bauherr.email",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 139.3660255,
	"right": 100.01725520000001,
	"bottom": 142.9910761,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"antragsteller_email": {
"text": "",
"datenuebernehmen": "entwurfsverfasser.email",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 102.8046433,
	"top": 139.3660255,
	"right": 187.23008959999999,
	"bottom": 142.9910761,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"baugrund_strasse": {
"text": "",
"datenuebernehmen": "baugrundstueck.strasse<&>baugrundstueck.plz<&>baugrundstueck.ort",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 151.9302576,
	"right": 197.21784820000002,
	"bottom": 155.55530819999998,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"gemarkungen": {
"text": "",
"datenuebernehmen": "baugrundstueck.gemarkung",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.1454524,
	"top": 160.0444738,
	"right": 90.9687367,
	"bottom": 163.6695244,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"flure": {
"text": "",
"datenuebernehmen": "baugrundstueck.flur",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 95.9541512,
	"top": 160.0444738,
	"right": 123.75678680000001,
	"bottom": 163.6695244,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"flurstuecke": {
"text": "",
"datenuebernehmen": "baugrundstueck.flurstueck",
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 127.3102393,
	"top": 160.0444738,
	"right": 197.21784820000002,
	"bottom": 163.6695244,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"g_klasse": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 96.92513430000001,
	"top": 165.531075,
	"right": 99.7573153,
	"bottom": 168.3636087,
	"typ": "radio",
	"pageNo": "1"
}
},
"wohngebaude_check": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.521783300000003,
	"top": 170.77607669999998,
	"right": 20.3564332,
	"bottom": 173.6082577,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"sonderbau_check": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 96.8792833,
	"top": 170.43819009999999,
	"right": 99.7139332,
	"bottom": 173.269313,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bezeichnung_bauvorhaben": {
"text": "",
"datenuebernehmen": "bezeichnung_bauvorhaben",
"height": 34,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 17.1373403,
	"top": 180.9877998,
	"right": 197.88268770000002,
	"bottom": 189.0829702,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"bedarf_ausnahme": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 72.03509530000001,
	"top": 190.5837087,
	"right": 74.86974520000001,
	"bottom": 193.41588969999998,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bedarf_befreiung": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 72.03509530000001,
	"top": 195.0245544,
	"right": 74.86974520000001,
	"bottom": 197.8570881,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bedarf_abweichung": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 72.03509530000001,
	"top": 199.38780609999998,
	"right": 74.86974520000001,
	"bottom": 202.2192817,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"abweichung_check": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.242092200000002,
	"top": 208.17391579999997,
	"right": 20.0767421,
	"bottom": 211.0060968,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"planungsrechtlicheZulaessigkeit": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 62.513253399999996,
	"top": 222.9615687,
	"right": 65.3479033,
	"bottom": 225.79410239999999,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bauordnungsrechtlicheZulaessigkeit": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 130.65454069999998,
	"top": 222.9615687,
	"right": 133.4888379,
	"bottom": 225.79410239999999,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"genaue_fragestellung": {
"text": "",
"datenuebernehmen": null,
"height": 34,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_m",
"coords": {
	"left": 17.1373403,
	"top": 232.7715665,
	"right": 197.88268770000002,
	"bottom": 241.4486919,
	"typ": "textarea",
	"pageNo": "1"
},
"showPop": false
},
"bindung_vorbescheid": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.5193144,
	"top": 250.4266704,
	"right": 20.3539643,
	"bottom": 253.2588514,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bindung_teilungsgen": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.5193144,
	"top": 255.3644704,
	"right": 20.3539643,
	"bottom": 258.1970041,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bindung_baulast": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.5193144,
	"top": 259.9763756,
	"right": 20.3539643,
	"bottom": 262.8089093,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bindung_denkmal": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.5193144,
	"top": 264.7625146,
	"right": 20.3539643,
	"bottom": 267.5946956,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bindung_sonstiges": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 17.5193144,
	"top": 269.6855012,
	"right": 20.3539643,
	"bottom": 272.5176822,
	"typ": "checkbox",
	"pageNo": "1"
}
},
"bindung_vorbescheid_bescheid_vom": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 80.7446691,
	"top": 250.08067169999998,
	"right": 103.369316,
	"bottom": 253.7057223,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_vorbescheid_erteilt_von": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 105.132816,
	"top": 250.08067169999998,
	"right": 147.8814668,
	"bottom": 253.7057223,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_vorbescheid_aktenzeichen": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 149.99907760000002,
	"top": 250.08067169999998,
	"right": 197.8347205,
	"bottom": 253.7057223,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_teilungsgen_bescheid_vom": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 80.7446691,
	"top": 254.9976624,
	"right": 103.369316,
	"bottom": 258.622713,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_baulast_bescheid_vom": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 80.7446691,
	"top": 259.6585929,
	"right": 103.369316,
	"bottom": 263.2836435,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_teilungsgen_erteilt_von": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 105.132816,
	"top": 254.9976624,
	"right": 147.8814668,
	"bottom": 258.622713,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_baulast_erteilt_von": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 105.132816,
	"top": 259.6585929,
	"right": 147.8814668,
	"bottom": 263.2836435,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_denkmal_bescheid_vom": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 80.7446691,
	"top": 264.4616615,
	"right": 103.369316,
	"bottom": 268.085654,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_denkmal_erteilt_von": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 105.132816,
	"top": 264.4616615,
	"right": 147.8814668,
	"bottom": 268.085654,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_sonstiges_text": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 21.9393508,
	"top": 269.369482,
	"right": 78.8965211,
	"bottom": 272.9945326,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_sonstiges_bescheid_vom": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 80.7446691,
	"top": 269.369482,
	"right": 103.369316,
	"bottom": 272.9945326,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_sonstiges_erteilt_von": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 105.132816,
	"top": 269.369482,
	"right": 147.8814668,
	"bottom": 272.9945326,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_teilungsgen_aktenzeichen": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 149.99907760000002,
	"top": 254.9976624,
	"right": 197.8347205,
	"bottom": 258.622713,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_baulast_aktenzeichen": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 149.99907760000002,
	"top": 259.6585929,
	"right": 197.8347205,
	"bottom": 263.2836435,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_denkmal_aktenzeichen": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 149.99907760000002,
	"top": 264.4616615,
	"right": 197.8347205,
	"bottom": 268.085654,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"bindung_sonstiges_aktenzeichen": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 149.99907760000002,
	"top": 269.369482,
	"right": 197.8347205,
	"bottom": 272.9945326,
	"typ": "text",
	"pageNo": "1"
},
"showPop": false
},
"lageplan": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 27.732243100000005,
	"right": 28.1814354,
	"bottom": 31.012353099999984,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"berechnungMassBaulichenNutzung": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 32.05281809999997,
	"right": 28.1814354,
	"bottom": 35.36220220000001,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"beglaubigterAuszugFlurkarte": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 39.406613100000015,
	"right": 28.1814354,
	"bottom": 42.56292539999998,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"Basiskarte": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 50.62000420000001,
	"right": 28.1814354,
	"bottom": 53.70859809999999,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"Bauzeichnungen": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 57.87081079999999,
	"right": 28.1814354,
	"bottom": 61.15056809999999,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"Baubeschreibung": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 61.92650810000001,
	"right": 28.1814354,
	"bottom": 65.20026949999999,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"umbauterRaum": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 65.91201809999998,
	"right": 28.1814354,
	"bottom": 69.0337658,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"ohneRohbauwerteHerstellungssumme": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.971865400000002,
	"top": 69.80935309999998,
	"right": 28.1814354,
	"bottom": 73.0898158,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"herstellungssummeCheck": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 25.0424054,
	"top": 77.5867408,
	"right": 28.251975400000003,
	"bottom": 80.88413310000001,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"Betriebsbeschreibung": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 25.060040400000002,
	"top": 97.77881579999999,
	"right": 27.9987368,
	"bottom": 100.56549849999996,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"zusaeztlicheAngaben": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 25.148215400000005,
	"top": 105.48531080000001,
	"right": 27.9803964,
	"bottom": 108.31784449999998,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisSchallschutzesSachverstaendiger": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.7683575,
	"top": 118.33734609999999,
	"right": 27.603007400000003,
	"bottom": 121.16987979999999,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisWaermeschutzesSachverstaendiger": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.883690400000003,
	"top": 125.6248335,
	"right": 27.7183403,
	"bottom": 128.4563091,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisStandsicherheitSachverstaendiger": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.883690400000003,
	"top": 132.8192081,
	"right": 27.7183403,
	"bottom": 135.6517418,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisBrandschutzSachverstaendiger": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.883690400000003,
	"top": 140.7725931,
	"right": 27.7183403,
	"bottom": 143.6051268,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisSchallschutzes": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.771884500000002,
	"top": 158.5917025,
	"right": 27.606534400000005,
	"bottom": 161.4231781,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisWaermeschutzes": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.7694156,
	"top": 162.8237498,
	"right": 27.6040655,
	"bottom": 165.6562835,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisStandsicherheit": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.835370500000003,
	"top": 167.3026871,
	"right": 27.670020400000002,
	"bottom": 170.13522079999998,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"NachweisBrandschutz": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.883690400000003,
	"top": 171.6239675,
	"right": 27.7183403,
	"bottom": 174.45614849999998,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"erhebungsbogen_statistik": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 24.848067699999998,
	"top": 180.5998298,
	"right": 27.6827176,
	"bottom": 183.4323635,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"Artenschutz": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 25.060040400000002,
	"top": 188.7750631,
	"right": 27.8946903,
	"bottom": 191.6075968,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"BrandschutzErkl": {
"text": "0",
"size": "13",
"sichtbar": "true",
"coords": {
	"left": 25.060040400000002,
	"top": 194.6129535,
	"right": 27.8946903,
	"bottom": 197.4451345,
	"typ": "checkbox",
	"pageNo": "2"
}
},
"ort_datum_bauherr": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 17.400454500000002,
	"top": 208.7315345,
	"right": 103.7682197,
	"bottom": 212.3569378,
	"typ": "text",
	"pageNo": "2"
},
"showPop": false
},
"ort_datum_entwurfverfasser": {
"text": "",
"datenuebernehmen": null,
"height": 0,
"size": "13",
"schriftausrichtung": "left",
"letterSpacing": "normal",
"sichtbar": "true",
"anzahlStellen": 0,
"schriftart": "normal",
"isDisabled": "false",
"zahl": false,
"welchemethod": "leer",
"art": "_e",
"coords": {
	"left": 106.2808545,
	"top": 208.7315345,
	"right": 192.6489724,
	"bottom": 212.3569378,
	"typ": "text",
	"pageNo": "2"
},
"showPop": false
}
}
var formulaName = "NRWbauantrag64_2022"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

mixins: [formularMixin],

name: formulaName,

data(){
return{
	
	 
	gesamthoehe_px : 1286, // in px
	gesamtbreite_px : 909, // in px
	gesamthoehe_mm : 297, // in mm
	gesamtbreite_mm : 210, // in mm

}
},

methods:{

wennZahl(zahl){
	
	var dieZahl = this.formular[zahl].text
	if(parseInt(zahl)>0)
	return parseInt(zahl)
	else
	return 0
},

//liegt in formular.vue
isNumber(einsatz){
	this.$root.$emit('isNumber', einsatz);
},

//liegt in formular.vue
setFocus(einsatz){
	this.$root.$emit('setFocus', einsatz);
},



recalKlasse(){

const myArray = this.formular.g_klasse.text.split("|");
this.formular.g_klasse.coords.left = Number(myArray[0]);
this.formular.g_klasse.coords.top = Number(myArray[1]);

},

},

mounted() {

	  setTimeout(()=>{
		this.$root.$emit("bv::disable::popover")
	},150);
	

},

computed:{

formular(){
	if(this.actualformular.formularContent[formulaName]!=undefined)
	return this.actualformular.formularContent[formulaName]
	else
	return [];
},},


created(){

var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

if(pos>=0){
	 this.options_formulare_neu[pos].blanc = leeresFormular
}
},


}
</script>