<template>
	<div>
        <div class="kontainer">
                <h1>Berechnungen</h1>
                <p>Text</p>
                {{projectDaten}}
        </div>
	</div>
</template>

<script>

    import { projectMixin } from '../../mixins/projectMixin.js'
    
export default {

    mixins: [projectMixin],
    
    data(){
        return{
        }
    },
	methods:{

	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
