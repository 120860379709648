<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(79.5461945) + 'px', 
														top: gib_hoehe_in_mm(16.1939389) + 'px'}">
													Eingang bei der Gemeinde
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(138.8001472) + 'px', 
														top: gib_hoehe_in_mm(16.1939389) + 'px'}">
													Eingang bei der Bauaufsichtsbehörde
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(162.8013822) + 'px', 
														top: gib_hoehe_in_mm(9.1110175) + 'px'}">
													Anlage I/3 zur VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.5250792) + 'px', 
														top: gib_hoehe_in_mm(12.4087625) + 'px'}">
													Blatt 1
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(16.1939389) + 'px'}">
													An die Gemeinde
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(55.9111907) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(20.4170976) + 'px',
										
										 top: gib_hoehe_in_mm(19.7358287) + 'px'}"><b-popover 
												v-if="formular.bauaufsicht"
												:show="formular.bauaufsicht.showPop==true"
												
												ref='bauaufsicht' 
												target='bauaufsicht'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht.size}}</span>
												<b-button @click='plus("bauaufsicht", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauaufsicht!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauaufsicht.text"
												
												@focus='formular.bauaufsicht.showPop=true'
												@blur ='formular.bauaufsicht.showPop=false'
												
												:disabled="formular.bauaufsicht.isDisabled==true || formular.bauaufsicht.isDisabled=='true'"
												:style="{'font-size': formular.bauaufsicht.size + 'px',
														'height': formular.bauaufsicht.height + 'px',
														'text-align': formular.bauaufsicht.schriftausrichtung + 'px',
														'letter-spacing': formular.bauaufsicht.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht.schriftart + 'px', }"

												id='bauaufsicht'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(33.0776879) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.bauaufsicht_plz"
												:show="formular.bauaufsicht_plz.showPop==true"
												
												ref='bauaufsicht_plz' 
												target='bauaufsicht_plz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht_plz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht_plz.size}}</span>
												<b-button @click='plus("bauaufsicht_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht_plz", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauaufsicht_plz" type="text" 
												v-if="formular.bauaufsicht_plz!=undefined" 
												class='textfeld' v-model='formular.bauaufsicht_plz.text' 
												style='width:100%' 
												@focus='formular.bauaufsicht_plz.showPop=true'
												@blur ='formular.bauaufsicht_plz.showPop=false'
												:disabled='formular.bauaufsicht_plz.isDisabled==true || formular.bauaufsicht_plz.isDisabled==true' 
												@keypress="isNumber($event, formular.bauaufsicht_plz.onylNumber) " 
												:style="{'width': spalte_breit_mm(55.9111907) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6670976) + 'px', 
														'height': 17.5 + 'px', 
														'top': gib_hoehe_in_mm(36.8699304) + 'px', 
		
														'font-size': formular.bauaufsicht_plz.size + 'px', 
														'text-align': formular.bauaufsicht_plz.schriftausrichtung, 
														'letter-spacing': formular.bauaufsicht_plz.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht_plz.schriftart }" 
												/>

<b-popover 
												v-if="formular.baugrund_strasse"
												:show="formular.baugrund_strasse.showPop==true"
												
												ref='baugrund_strasse' 
												target='baugrund_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
												<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrund_strasse" type="text" 
												v-if="formular.baugrund_strasse!=undefined" 
												class='textfeld' v-model='formular.baugrund_strasse.text' 
												style='width:100%' 
												@focus='formular.baugrund_strasse.showPop=true'
												@blur ='formular.baugrund_strasse.showPop=false'
												:disabled='formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrund_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(179.1100061) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(158.5948125) + 'px', 
		
														'font-size': formular.baugrund_strasse.size + 'px', 
														'text-align': formular.baugrund_strasse.schriftausrichtung, 
														'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrund_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(162.8469516) + 'px'}">
													Gemarkung(en)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(142.2351636) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.bauherr_email"
												:show="formular.bauherr_email.showPop==true"
												
												ref='bauherr_email' 
												target='bauherr_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_email.size}}</span>
												<b-button @click='plus("bauherr_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_email" type="text" 
												v-if="formular.bauherr_email!=undefined" 
												class='textfeld' v-model='formular.bauherr_email.text' 
												style='width:100%' 
												@focus='formular.bauherr_email.showPop=true'
												@blur ='formular.bauherr_email.showPop=false'
												:disabled='formular.bauherr_email.isDisabled==true || formular.bauherr_email.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(84.4608449) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(146.0316385) + 'px', 
		
														'font-size': formular.bauherr_email.size + 'px', 
														'text-align': formular.bauherr_email.schriftausrichtung, 
														'letter-spacing': formular.bauherr_email.letterSpacing + 'px',
														'font-weight': formular.bauherr_email.schriftart }" 
												/>


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(179.1181182) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(20.4170976) + 'px',
										
										 top: gib_hoehe_in_mm(181.2286939) + 'px'}"><b-popover 
												v-if="formular.bezeichnung_bauvorhaben"
												:show="formular.bezeichnung_bauvorhaben.showPop==true"
												
												ref='bezeichnung_bauvorhaben' 
												target='bezeichnung_bauvorhaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
												<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bezeichnung_bauvorhaben!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bezeichnung_bauvorhaben.text"
												
												@focus='formular.bezeichnung_bauvorhaben.showPop=true'
												@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
												
												:disabled="formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled=='true'"
												:style="{'font-size': formular.bezeichnung_bauvorhaben.size + 'px',
														'height': formular.bezeichnung_bauvorhaben.height + 'px',
														'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung + 'px',
														'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': formular.bezeichnung_bauvorhaben.schriftart + 'px', }"

												id='bezeichnung_bauvorhaben'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(202.0894117) + 'px'}">
													1.2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(206.3641357) + 'px'}">
													1.3
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(219.8231677) + 'px'}">
													2.1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(224.1825397) + 'px'}">
													2.2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(228.5419117) + 'px'}">
													2.3
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(232.8166357) + 'px'}">
													2.4
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(237.1760077) + 'px'}">
													2.5.1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(241.5353797) + 'px'}">
													2.5.2
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b1_2_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(202.8968184) + 'px'}"
								v-model="formular.b1_2_check.text" 
								value='1' 
								unchecked-value='0'>  2-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b1_3_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(207.1711897) + 'px'}"
								v-model="formular.b1_3_check.text" 
								value='1' 
								unchecked-value='0'>  2-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_1_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(220.5462791) + 'px'}"
								v-model="formular.b2_1_check.text" 
								value='1' 
								unchecked-value='0'>  3-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_2_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(224.9052984) + 'px'}"
								v-model="formular.b2_2_check.text" 
								value='1' 
								unchecked-value='0'>  3-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_3_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(229.2643177) + 'px'}"
								v-model="formular.b2_3_check.text" 
								value='1' 
								unchecked-value='0'>  3-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_4_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(233.6243951) + 'px'}"
								v-model="formular.b2_4_check.text" 
								value='1' 
								unchecked-value='0'>  3-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_5_1_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(237.9834144) + 'px'}"
								v-model="formular.b2_5_1_check.text" 
								value='1' 
								unchecked-value='0'>  2-fach
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_5_2_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(242.3424337) + 'px'}"
								v-model="formular.b2_5_2_check.text" 
								value='1' 
								unchecked-value='0'>  2-fach
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(253.4323034) + 'px'}">
													2.5.3
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b2_5_3_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(254.2326561) + 'px'}"
								v-model="formular.b2_5_3_check.text" 
								value='1' 
								unchecked-value='0'> 
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(79.5461945) + 'px', 
														top: gib_hoehe_in_mm(33.0776879) + 'px'}">
													Aktenzeichen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(138.8001472) + 'px', 
														top: gib_hoehe_in_mm(33.0776879) + 'px'}">
													Aktenzeichen
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(36.6719825) + 'px', 
														top: gib_hoehe_in_mm(45.3784531) + 'px'}">
													Vorlage bei der Gemeinde
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(127.2492222) + 'px', 
														top: gib_hoehe_in_mm(44.3203531) + 'px'}">
													Genehmigungsfreistellung
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(132.7478152) + 'px', 
														top: gib_hoehe_in_mm(49.1840861) + 'px'}">
													 63 BauO NRW 2018
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(56.960063) + 'px'}">
													Weiterbehandlung als Bauantrag, wenn die Gemeinde erklärt, dass ein Genehmigungsverfahren durchgeführt werden
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(60.599927) + 'px'}">
													soll
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.weiterbehandlungJa_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(20.6770375) + 'px',top: gib_hoehe_in_mm(66.4862137) + 'px'}"
								v-model="formular.weiterbehandlungJa_check.text" 
								value='1' 
								unchecked-value='0'>   ja (bitte Nummer 2 und 3 ausfüllen)
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.weiterbehandlungNein_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(83.1052902) + 'px',top: gib_hoehe_in_mm(66.4862137) + 'px'}"
								v-model="formular.weiterbehandlungNein_check.text" 
								value='1' 
								unchecked-value='0'>   nein (bitte Nummer 1 und 3 ausfüllen)
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(72.62347) + 'px'}">
													Bauherrschaft ( 53 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(76.9712029) + 'px'}">
													Name, Vorname, Firma
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(72.62347) + 'px'}">
													Entwurfsverfassende ( 54 Absatz 1 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(76.9712029) + 'px'}">
													Name, Vorname, Büro
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(84.468957) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(20.4170976) + 'px',
										
										 top: gib_hoehe_in_mm(80.5134454) + 'px'}"><b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_name.text"
												
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												
												:disabled="formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_name.size + 'px',
														'height': formular.bauherr_name.height + 'px',
														'text-align': formular.bauherr_name.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart + 'px', }"

												id='bauherr_name'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(91.7546809) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(107.7805349) + 'px',
										
										 top: gib_hoehe_in_mm(80.5134454) + 'px'}"><b-popover 
												v-if="formular.antragsteller_name"
												:show="formular.antragsteller_name.showPop==true"
												
												ref='antragsteller_name' 
												target='antragsteller_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_name.size}}</span>
												<b-button @click='plus("antragsteller_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_name.text"
												
												@focus='formular.antragsteller_name.showPop=true'
												@blur ='formular.antragsteller_name.showPop=false'
												
												:disabled="formular.antragsteller_name.isDisabled==true || formular.antragsteller_name.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_name.size + 'px',
														'height': formular.antragsteller_name.height + 'px',
														'text-align': formular.antragsteller_name.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_name.letterSpacing + 'px',
														'font-weight': formular.antragsteller_name.schriftart + 'px', }"

												id='antragsteller_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(89.7498766) + 'px'}">
													Straße, Hausnummer
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(89.7498766) + 'px'}">
													Straße, Hausnummer
													</div>
<b-popover 
												v-if="formular.bauherr_strasse"
												:show="formular.bauherr_strasse.showPop==true"
												
												ref='bauherr_strasse' 
												target='bauherr_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_strasse.size}}</span>
												<b-button @click='plus("bauherr_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_strasse" type="text" 
												v-if="formular.bauherr_strasse!=undefined" 
												class='textfeld' v-model='formular.bauherr_strasse.text' 
												style='width:100%' 
												@focus='formular.bauherr_strasse.showPop=true'
												@blur ='formular.bauherr_strasse.showPop=false'
												:disabled='formular.bauherr_strasse.isDisabled==true || formular.bauherr_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(84.4608449) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(93.5784472) + 'px', 
		
														'font-size': formular.bauherr_strasse.size + 'px', 
														'text-align': formular.bauherr_strasse.schriftausrichtung, 
														'letter-spacing': formular.bauherr_strasse.letterSpacing + 'px',
														'font-weight': formular.bauherr_strasse.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_strasse"
												:show="formular.antragsteller_strasse.showPop==true"
												
												ref='antragsteller_strasse' 
												target='antragsteller_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
												<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_strasse" type="text" 
												v-if="formular.antragsteller_strasse!=undefined" 
												class='textfeld' v-model='formular.antragsteller_strasse.text' 
												style='width:100%' 
												@focus='formular.antragsteller_strasse.showPop=true'
												@blur ='formular.antragsteller_strasse.showPop=false'
												:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(91.7546809) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(108.0305349) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(93.5784472) + 'px', 
		
														'font-size': formular.antragsteller_strasse.size + 'px', 
														'text-align': formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': formular.antragsteller_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(97.7067886) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.bauherr_ort"
												:show="formular.bauherr_ort.showPop==true"
												
												ref='bauherr_ort' 
												target='bauherr_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_ort.size}}</span>
												<b-button @click='plus("bauherr_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_ort" type="text" 
												v-if="formular.bauherr_ort!=undefined" 
												class='textfeld' v-model='formular.bauherr_ort.text' 
												style='width:100%' 
												@focus='formular.bauherr_ort.showPop=true'
												@blur ='formular.bauherr_ort.showPop=false'
												:disabled='formular.bauherr_ort.isDisabled==true || formular.bauherr_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(84.4608449) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(101.6580988) + 'px', 
		
														'font-size': formular.bauherr_ort.size + 'px', 
														'text-align': formular.bauherr_ort.schriftausrichtung, 
														'letter-spacing': formular.bauherr_ort.letterSpacing + 'px',
														'font-weight': formular.bauherr_ort.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_ort"
												:show="formular.antragsteller_ort.showPop==true"
												
												ref='antragsteller_ort' 
												target='antragsteller_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
												<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_ort" type="text" 
												v-if="formular.antragsteller_ort!=undefined" 
												class='textfeld' v-model='formular.antragsteller_ort.text' 
												style='width:100%' 
												@focus='formular.antragsteller_ort.showPop=true'
												@blur ='formular.antragsteller_ort.showPop=false'
												:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(91.7546809) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(108.0305349) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(101.6580988) + 'px', 
		
														'font-size': formular.antragsteller_ort.size + 'px', 
														'text-align': formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': formular.antragsteller_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(105.7758592) + 'px'}">
													vertreten durch ( 53 Absatz 3 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(109.6421566) + 'px'}">
													Name, Vorname, Anschrift
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(84.468957) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(20.4170976) + 'px',
										
										 top: gib_hoehe_in_mm(113.5106466) + 'px'}"><b-popover 
												v-if="formular.bauherr_vertreter"
												:show="formular.bauherr_vertreter.showPop==true"
												
												ref='bauherr_vertreter' 
												target='bauherr_vertreter'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_vertreter.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_vertreter.size}}</span>
												<b-button @click='plus("bauherr_vertreter", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_vertreter", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_vertreter!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_vertreter.text"
												
												@focus='formular.bauherr_vertreter.showPop=true'
												@blur ='formular.bauherr_vertreter.showPop=false'
												
												:disabled="formular.bauherr_vertreter.isDisabled==true || formular.bauherr_vertreter.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_vertreter.size + 'px',
														'height': formular.bauherr_vertreter.height + 'px',
														'text-align': formular.bauherr_vertreter.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_vertreter.letterSpacing + 'px',
														'font-weight': formular.bauherr_vertreter.schriftart + 'px', }"

												id='bauherr_vertreter'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(105.7758592) + 'px'}">
													bauvorlageberechtigt ( 67 Absatz 3 BauO NRW 2018) Name, Vorname
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(122.3393566) + 'px'}">
													Mitgliedsnummer der Architekten oder der Ingenieurkammer des Landes
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(134.2782516) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(64.0132865) + 'px', 
														top: gib_hoehe_in_mm(134.2782516) + 'px'}">
													Telefax
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(134.2782516) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(152.8940392) + 'px', 
														top: gib_hoehe_in_mm(134.2782516) + 'px'}">
													Telefax
													</div>
<b-popover 
												v-if="formular.bauherr_tel"
												:show="formular.bauherr_tel.showPop==true"
												
												ref='bauherr_tel' 
												target='bauherr_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_tel.size}}</span>
												<b-button @click='plus("bauherr_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_tel" type="text" 
												v-if="formular.bauherr_tel!=undefined" 
												class='textfeld' v-model='formular.bauherr_tel.text' 
												style='width:100%' 
												@focus='formular.bauherr_tel.showPop=true'
												@blur ='formular.bauherr_tel.showPop=false'
												:disabled='formular.bauherr_tel.isDisabled==true || formular.bauherr_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.755319) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(137.8881482) + 'px', 
		
														'font-size': formular.bauherr_tel.size + 'px', 
														'text-align': formular.bauherr_tel.schriftausrichtung, 
														'letter-spacing': formular.bauherr_tel.letterSpacing + 'px',
														'font-weight': formular.bauherr_tel.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_fax"
												:show="formular.bauherr_fax.showPop==true"
												
												ref='bauherr_fax' 
												target='bauherr_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_fax.size}}</span>
												<b-button @click='plus("bauherr_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_fax" type="text" 
												v-if="formular.bauherr_fax!=undefined" 
												class='textfeld' v-model='formular.bauherr_fax.text' 
												style='width:100%' 
												@focus='formular.bauherr_fax.showPop=true'
												@blur ='formular.bauherr_fax.showPop=false'
												:disabled='formular.bauherr_fax.isDisabled==true || formular.bauherr_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(41.29495) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(63.8411046) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(137.8881482) + 'px', 
		
														'font-size': formular.bauherr_fax.size + 'px', 
														'text-align': formular.bauherr_fax.schriftausrichtung, 
														'letter-spacing': formular.bauherr_fax.letterSpacing + 'px',
														'font-weight': formular.bauherr_fax.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_tel"
												:show="formular.antragsteller_tel.showPop==true"
												
												ref='antragsteller_tel' 
												target='antragsteller_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_tel.size}}</span>
												<b-button @click='plus("antragsteller_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_tel" type="text" 
												v-if="formular.antragsteller_tel!=undefined" 
												class='textfeld' v-model='formular.antragsteller_tel.text' 
												style='width:100%' 
												@focus='formular.antragsteller_tel.showPop=true'
												@blur ='formular.antragsteller_tel.showPop=false'
												:disabled='formular.antragsteller_tel.isDisabled==true || formular.antragsteller_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.4994205) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(108.0305349) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(137.8881482) + 'px', 
		
														'font-size': formular.antragsteller_tel.size + 'px', 
														'text-align': formular.antragsteller_tel.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_tel.letterSpacing + 'px',
														'font-weight': formular.antragsteller_tel.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_fax"
												:show="formular.antragsteller_fax.showPop==true"
												
												ref='antragsteller_fax' 
												target='antragsteller_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_fax.size}}</span>
												<b-button @click='plus("antragsteller_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_fax" type="text" 
												v-if="formular.antragsteller_fax!=undefined" 
												class='textfeld' v-model='formular.antragsteller_fax.text' 
												style='width:100%' 
												@focus='formular.antragsteller_fax.showPop=true'
												@blur ='formular.antragsteller_fax.showPop=false'
												:disabled='formular.antragsteller_fax.isDisabled==true || formular.antragsteller_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(46.7646216) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(153.0205942) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(137.8881482) + 'px', 
		
														'font-size': formular.antragsteller_fax.size + 'px', 
														'text-align': formular.antragsteller_fax.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_fax.letterSpacing + 'px',
														'font-weight': formular.antragsteller_fax.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(97.7067886) + 'px'}">
													PLZ, Ort
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(91.7546809) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(107.7805349) + 'px',
										
										 top: gib_hoehe_in_mm(110.2393541) + 'px'}"><b-popover 
												v-if="formular.antragsteller_vorlagenberechtigt"
												:show="formular.antragsteller_vorlagenberechtigt.showPop==true"
												
												ref='antragsteller_vorlagenberechtigt' 
												target='antragsteller_vorlagenberechtigt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_vorlagenberechtigt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_vorlagenberechtigt.size}}</span>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_vorlagenberechtigt!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_vorlagenberechtigt.text"
												
												@focus='formular.antragsteller_vorlagenberechtigt.showPop=true'
												@blur ='formular.antragsteller_vorlagenberechtigt.showPop=false'
												
												:disabled="formular.antragsteller_vorlagenberechtigt.isDisabled==true || formular.antragsteller_vorlagenberechtigt.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_vorlagenberechtigt.size + 'px',
														'height': formular.antragsteller_vorlagenberechtigt.height + 'px',
														'text-align': formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
														'font-weight': formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }"

												id='antragsteller_vorlagenberechtigt'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.antragsteller_mitgliedsnummer"
												:show="formular.antragsteller_mitgliedsnummer.showPop==true"
												
												ref='antragsteller_mitgliedsnummer' 
												target='antragsteller_mitgliedsnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_mitgliedsnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_mitgliedsnummer.size}}</span>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_mitgliedsnummer" type="text" 
												v-if="formular.antragsteller_mitgliedsnummer!=undefined" 
												class='textfeld' v-model='formular.antragsteller_mitgliedsnummer.text' 
												style='width:100%' 
												@focus='formular.antragsteller_mitgliedsnummer.showPop=true'
												@blur ='formular.antragsteller_mitgliedsnummer.showPop=false'
												:disabled='formular.antragsteller_mitgliedsnummer.isDisabled==true || formular.antragsteller_mitgliedsnummer.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_mitgliedsnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(91.7546809) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(108.0305349) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(126.2649197) + 'px', 
		
														'font-size': formular.antragsteller_mitgliedsnummer.size + 'px', 
														'text-align': formular.antragsteller_mitgliedsnummer.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_mitgliedsnummer.letterSpacing + 'px',
														'font-weight': formular.antragsteller_mitgliedsnummer.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(107.7801822) + 'px', 
														top: gib_hoehe_in_mm(142.2351636) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.antragsteller_email"
												:show="formular.antragsteller_email.showPop==true"
												
												ref='antragsteller_email' 
												target='antragsteller_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_email.size}}</span>
												<b-button @click='plus("antragsteller_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_email" type="text" 
												v-if="formular.antragsteller_email!=undefined" 
												class='textfeld' v-model='formular.antragsteller_email.text' 
												style='width:100%' 
												@focus='formular.antragsteller_email.showPop=true'
												@blur ='formular.antragsteller_email.showPop=false'
												:disabled='formular.antragsteller_email.isDisabled==true || formular.antragsteller_email.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(91.7546809) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(108.0305349) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(146.0316385) + 'px', 
		
														'font-size': formular.antragsteller_email.size + 'px', 
														'text-align': formular.antragsteller_email.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_email.letterSpacing + 'px',
														'font-weight': formular.antragsteller_email.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(150.4999827) + 'px'}">
													Baugrundstück
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(154.8441886) + 'px'}">
													Ort, Straße, Hausnummer, gegebenenfalls Ortsteil
													</div>
<b-popover 
												v-if="formular.gemarkungen"
												:show="formular.gemarkungen.showPop==true"
												
												ref='gemarkungen' 
												target='gemarkungen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemarkungen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemarkungen.size}}</span>
												<b-button @click='plus("gemarkungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemarkungen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gemarkungen" type="text" 
												v-if="formular.gemarkungen!=undefined" 
												class='textfeld' v-model='formular.gemarkungen.text' 
												style='width:100%' 
												@focus='formular.gemarkungen.showPop=true'
												@blur ='formular.gemarkungen.showPop=false'
												:disabled='formular.gemarkungen.isDisabled==true || formular.gemarkungen.isDisabled==true' 
												@keypress="isNumber($event, formular.gemarkungen.onylNumber) " 
												:style="{'width': spalte_breit_mm(73.3232843) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.6752097) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(166.5030519) + 'px', 
		
														'font-size': formular.gemarkungen.size + 'px', 
														'text-align': formular.gemarkungen.schriftausrichtung, 
														'letter-spacing': formular.gemarkungen.letterSpacing + 'px',
														'font-weight': formular.gemarkungen.schriftart }" 
												/>

<b-popover 
												v-if="formular.flure"
												:show="formular.flure.showPop==true"
												
												ref='flure' 
												target='flure'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flure.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flure.size}}</span>
												<b-button @click='plus("flure", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flure", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flure" type="text" 
												v-if="formular.flure!=undefined" 
												class='textfeld' v-model='formular.flure.text' 
												style='width:100%' 
												@focus='formular.flure.showPop=true'
												@blur ='formular.flure.showPop=false'
												:disabled='formular.flure.isDisabled==true || formular.flure.isDisabled==true' 
												@keypress="isNumber($event, formular.flure.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.3026356) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(96.9599873) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(166.5030519) + 'px', 
		
														'font-size': formular.flure.size + 'px', 
														'text-align': formular.flure.schriftausrichtung, 
														'letter-spacing': formular.flure.letterSpacing + 'px',
														'font-weight': formular.flure.schriftart }" 
												/>

<b-popover 
												v-if="formular.flurstuecke"
												:show="formular.flurstuecke.showPop==true"
												
												ref='flurstuecke' 
												target='flurstuecke'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flurstuecke.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flurstuecke.size}}</span>
												<b-button @click='plus("flurstuecke", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flurstuecke", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flurstuecke" type="text" 
												v-if="formular.flurstuecke!=undefined" 
												class='textfeld' v-model='formular.flurstuecke.text' 
												style='width:100%' 
												@focus='formular.flurstuecke.showPop=true'
												@blur ='formular.flurstuecke.showPop=false'
												:disabled='formular.flurstuecke.isDisabled==true || formular.flurstuecke.isDisabled==true' 
												@keypress="isNumber($event, formular.flurstuecke.onylNumber) " 
												:style="{'width': spalte_breit_mm(72.0401617) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(127.7450541) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(166.5030519) + 'px', 
		
														'font-size': formular.flurstuecke.size + 'px', 
														'text-align': formular.flurstuecke.schriftausrichtung, 
														'letter-spacing': formular.flurstuecke.letterSpacing + 'px',
														'font-weight': formular.flurstuecke.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(96.4761472) + 'px', 
														top: gib_hoehe_in_mm(162.8469516) + 'px'}">
													Flur(e)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(127.3303432) + 'px', 
														top: gib_hoehe_in_mm(162.8469516) + 'px'}">
													Flurstück(e)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													Gebäudeklasse ( 2 Absatz 3 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(175.8520587) + 'px'}">
													Bezeichnung des Vorhabens (Errichtung, Änderung, Nutzungsänderung gemäß 60 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(97.6682732) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													Wohngebäude 1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(143.9248782) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													 sonstige Gebäude 1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(129.0691542) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(137.6150752) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													3
													</div>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(124.6247815) + 'px',top: gib_hoehe_in_mm(171.9188247) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='1'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(132.1200092) + 'px',top: gib_hoehe_in_mm(171.9188247) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='2'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(140.6662829) + 'px',top: gib_hoehe_in_mm(171.9188247) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='3'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(177.6571062) + 'px',top: gib_hoehe_in_mm(171.9188247) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='4'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(185.6563422) + 'px',top: gib_hoehe_in_mm(171.9188247) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='5'> 
								</b-form-radio>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(182.6090142) + 'px', 
														top: gib_hoehe_in_mm(171.1082437) + 'px'}">
													2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(190.4115147) + 'px'}">
													1. Bauvorlagen in der Genehmigungsfreistellung
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(194.3864437) + 'px'}">
													1.1
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b1_1_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.6343022) + 'px',top: gib_hoehe_in_mm(195.1977301) + 'px'}"
								v-model="formular.b1_1_check.text" 
								value='1' 
								unchecked-value='0'>  2-fach
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.5384123) + 'px', 
														top: gib_hoehe_in_mm(194.3864437) + 'px'}">
													Lageplan/amtlicher Lageplan ( 3 BauPrüfVO insbesondere mit Festsetzungen des Bebauungsplanes,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.5370015) + 'px', 
														top: gib_hoehe_in_mm(198.1144827) + 'px'}">
													besondere Anforderungen an Planersteller/in sind zu beachten)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.5384123) + 'px', 
														top: gib_hoehe_in_mm(202.0894117) + 'px'}">
													Berechnung des Maßes der baulichen Nutzung ( 3 Absatz 2 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.5384123) + 'px', 
														top: gib_hoehe_in_mm(206.3641357) + 'px'}">
													Bauzeichnungen ( 4 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.2929472) + 'px', 
														top: gib_hoehe_in_mm(211.7428107) + 'px'}">
													2. Bauvorlagen im einfachen Genehmigungsverfahren, wenn die Gemeinde erklärt, dass ein Genehmigungsverfahren
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(24.3172542) + 'px', 
														top: gib_hoehe_in_mm(215.4214717) + 'px'}">
													durchgeführt werden soll und die Weiterbehandlung als Bauantrag gewünscht wird
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(219.8231677) + 'px'}">
													Lageplan/amtlicher Lageplan ( 3 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(224.1825397) + 'px'}">
													Berechnung des Maßes der baulichen Nutzung ( 3 Absatz 2 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(228.5419117) + 'px'}">
													Bauzeichnungen ( 4 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(232.8166357) + 'px'}">
													Baubeschreibung auf amtlichem Vordruck ( 5 Absatz 1 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(237.1760077) + 'px'}">
													Bei Gebäuden Berechnung des umbauten Raumes nach DIN 277 ( 6 Nummer 1 BauPrüfVO) oder
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4922086) + 'px', 
														top: gib_hoehe_in_mm(241.5353797) + 'px'}">
													bei Gebäuden. für die landesdurchschnittliche Rohbauwerte je m Bruttorauminhalt nicht festgelegt sind,
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4911505) + 'px', 
														top: gib_hoehe_in_mm(245.2637714) + 'px'}">
													die Berechnung der Rohbaukosten einschließlich Umsatzsteuer ( 6 Nummer 1 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4911505) + 'px', 
														top: gib_hoehe_in_mm(248.9424324) + 'px'}">
													oder
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4911505) + 'px', 
														top: gib_hoehe_in_mm(253.4323034) + 'px'}">
													bei der Änderung von Gebäuden oder bei baulichen Anlagen, die nicht Gebäude sind
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4911505) + 'px', 
														top: gib_hoehe_in_mm(257.1532884) + 'px'}">
													Herstellungssumme einschließlich Umsatzsteuer gemäß Tarifstelle 2.1.3 AVerwGebO
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(46.4911505) + 'px', 
														top: gib_hoehe_in_mm(260.7931524) + 'px'}">
													NRW
													</div>
<b-popover 
												v-if="formular.b2_5_3_text"
												:show="formular.b2_5_3_text.showPop==true"
												
												ref='b2_5_3_text' 
												target='b2_5_3_text'  
												
												>
												<template slot='title'>
													<b-button @click="formular.b2_5_3_text.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.b2_5_3_text.size}}</span>
												<b-button @click='plus("b2_5_3_text", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("b2_5_3_text", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="b2_5_3_text" type="text" 
												v-if="formular.b2_5_3_text!=undefined" 
												class='textfeld' v-model='formular.b2_5_3_text.text' 
												style='width:100%' 
												@focus='formular.b2_5_3_text.showPop=true'
												@blur ='formular.b2_5_3_text.showPop=false'
												:disabled='formular.b2_5_3_text.isDisabled==true || formular.b2_5_3_text.isDisabled==true' 
												@keypress="isNumber($event, formular.b2_5_3_text.onylNumber) " 
												:style="{'width': spalte_breit_mm(16.8701223) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(180.1806104) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(254.7676377) + 'px', 
		
														'font-size': formular.b2_5_3_text.size + 'px', 
														'text-align': formular.b2_5_3_text.schriftausrichtung, 
														'letter-spacing': formular.b2_5_3_text.letterSpacing + 'px',
														'font-weight': formular.b2_5_3_text.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(198.6131294) + 'px', 
														top: gib_hoehe_in_mm(254.2445715) + 'px'}">
													E
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(171.5624502) + 'px', 
														top: gib_hoehe_in_mm(265.7450604) + 'px'}">
													Fortsetzung Blatt 2
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(16.2070652) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.5507677) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(254.6739222) + 'px', 
													top: gib_hoehe_in_mm(16.2070652) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(249.5061618) + 'px', 
													top: gib_hoehe_in_mm(16.2070652) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(33.1772257) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(41.2173749) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(56.539721) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(72.4884623) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(77.1095377) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(89.8878587) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(97.8482977) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(105.847181) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.415881) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(142.3731457) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(150.541325) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(154.985345) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(162.984581) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(171.1569927) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(175.8517824) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(180.3377737) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(190.1146177) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(211.4430921) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.8809752) + 'px', 
													width: spalte_breit_mm(22.6697925) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(253.4739984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(178.8809752) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(12.2392286) + 'px', 
													top: gib_hoehe_in_mm(253.4739984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.6605225) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(5.2677604) + 'px', 
													top: gib_hoehe_in_mm(265.613227) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(156.6605225) + 'px', 
													width: spalte_breit_mm(44.8902452) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(270.7809874) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(19.0239326) + 'px', 
													width: spalte_breit_mm(182.5268351) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(265.613227) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(151.7086145) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.972264) + 'px', 
													top: gib_hoehe_in_mm(134.4201134) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.4258142) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(15.121493) + 'px', 
													top: gib_hoehe_in_mm(41.3492847) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(78.2345032) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(24.85954) + 'px', 
													top: gib_hoehe_in_mm(16.3322737) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(137.4877505) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(24.85954) + 'px', 
													top: gib_hoehe_in_mm(16.3322737) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(106.5065825) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(77.7260957) + 'px', 
													top: gib_hoehe_in_mm(72.6655177) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(62.8285672) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.972264) + 'px', 
													top: gib_hoehe_in_mm(134.4201134) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(95.1641032) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.972264) + 'px', 
													top: gib_hoehe_in_mm(163.0307847) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(126.0645029) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.972264) + 'px', 
													top: gib_hoehe_in_mm(163.0307847) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.9842729) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.5206756) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(22.808122) + 'px'}">
													3.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(31.361097) + 'px'}">
													4.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b3_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.5397786) + 'px',top: gib_hoehe_in_mm(23.2346127) + 'px'}"
								v-model="formular.b3_check.text" 
								value='1' 
								unchecked-value='0'> Erhebungsbogen für die Baustatistik gemäß Hochbaustatistikgesetz
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.b4_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'13px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(28.5397786) + 'px',top: gib_hoehe_in_mm(31.7875877) + 'px'}"
								v-model="formular.b4_check.text" 
								value='1' 
								unchecked-value='0'> Angaben zum Artenschutz gemäß § 44 BNatSchG
								</b-form-checkbox>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(89.6962662) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(95.7362537) + 'px'}">
													Hinweis
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(136.2403217) + 'px'}">
													Antrag
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(157.1448507) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.ort_datum_antrag"
												:show="formular.ort_datum_antrag.showPop==true"
												
												ref='ort_datum_antrag' 
												target='ort_datum_antrag'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_antrag.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_antrag.size}}</span>
												<b-button @click='plus("ort_datum_antrag", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_antrag", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_antrag" type="text" 
												v-if="formular.ort_datum_antrag!=undefined" 
												class='textfeld' v-model='formular.ort_datum_antrag.text' 
												style='width:100%' 
												@focus='formular.ort_datum_antrag.showPop=true'
												@blur ='formular.ort_datum_antrag.showPop=false'
												:disabled='formular.ort_datum_antrag.isDisabled==true || formular.ort_datum_antrag.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_antrag.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.8681179) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.4484236) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(163.0656377) + 'px', 
		
														'font-size': formular.ort_datum_antrag.size + 'px', 
														'text-align': formular.ort_datum_antrag.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_antrag.letterSpacing + 'px',
														'font-weight': formular.ort_datum_antrag.schriftart }" 
												/>

<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(163.8041083) + 'px', 
														top: gib_hoehe_in_mm(9.0722205) + 'px'}">
													Anlage I/3 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(193.4309083) + 'px', 
														top: gib_hoehe_in_mm(12.3699655) + 'px'}">
													Blatt 2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(28.2011866) + 'px', 
														top: gib_hoehe_in_mm(37.790818) + 'px'}">
													Erklärung der / des Entwurfsverfassenden ( 13 Absatz 1 Satz 3 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(37.790818) + 'px'}">
													5.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(42.238365) + 'px'}">
													 Ich erkläre hiermit, dass das in den beigefügten Bauvorlagen dargestellte Bauvorhaben den Anforderungen an den
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(46.597737) + 'px'}">
													 Brandschutz entspricht und die hierzu in den Bauvorlagen gemachten Angaben vollständig und richtig sind.
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(51.1426292) + 'px'}">
													Ort, Datum
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.0791763) + 'px', 
														top: gib_hoehe_in_mm(51.1426292) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.ort_datum_bauherr"
												:show="formular.ort_datum_bauherr.showPop==true"
												
												ref='ort_datum_bauherr' 
												target='ort_datum_bauherr'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_bauherr.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_bauherr.size}}</span>
												<b-button @click='plus("ort_datum_bauherr", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_bauherr", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_bauherr" type="text" 
												v-if="formular.ort_datum_bauherr!=undefined" 
												class='textfeld' v-model='formular.ort_datum_bauherr.text' 
												style='width:100%' 
												@focus='formular.ort_datum_bauherr.showPop=true'
												@blur ='formular.ort_datum_bauherr.showPop=false'
												:disabled='formular.ort_datum_bauherr.isDisabled==true || formular.ort_datum_bauherr.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_bauherr.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.8681179) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(20.4484236) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(56.7078946) + 'px', 
		
														'font-size': formular.ort_datum_bauherr.size + 'px', 
														'text-align': formular.ort_datum_bauherr.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_bauherr.letterSpacing + 'px',
														'font-weight': formular.ort_datum_bauherr.schriftart }" 
												/>

<b-popover 
												v-if="formular.ort_datum_entwurfverfasser"
												:show="formular.ort_datum_entwurfverfasser.showPop==true"
												
												ref='ort_datum_entwurfverfasser' 
												target='ort_datum_entwurfverfasser'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_entwurfverfasser.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_entwurfverfasser.size}}</span>
												<b-button @click='plus("ort_datum_entwurfverfasser", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_entwurfverfasser", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_entwurfverfasser" type="text" 
												v-if="formular.ort_datum_entwurfverfasser!=undefined" 
												class='textfeld' v-model='formular.ort_datum_entwurfverfasser.text' 
												style='width:100%' 
												@focus='formular.ort_datum_entwurfverfasser.showPop=true'
												@blur ='formular.ort_datum_entwurfverfasser.showPop=false'
												:disabled='formular.ort_datum_entwurfverfasser.isDisabled==true || formular.ort_datum_entwurfverfasser.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_entwurfverfasser.onylNumber) " 
												:style="{'width': spalte_breit_mm(90.0800613) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(109.3291763) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(56.7078946) + 'px', 
		
														'font-size': formular.ort_datum_entwurfverfasser.size + 'px', 
														'text-align': formular.ort_datum_entwurfverfasser.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_entwurfverfasser.letterSpacing + 'px',
														'font-weight': formular.ort_datum_entwurfverfasser.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(63.696633) + 'px'}">
													Für die Bauherrschaft
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.0791763) + 'px', 
														top: gib_hoehe_in_mm(63.696633) + 'px'}">
													Der / die bauvorlageberechtigte Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(101.8697067) + 'px'}">
													Nach 63 Absatz 3 Satz 4 BauO NRW 2018 darf einen Monat nach Vorlage der erforderlichen Unterlagen bei der Gemeinde
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(105.5095707) + 'px'}">
													mit dem Vorhaben begonnen werden. Mit dem Vorhaben kann unverzüglich begonnen werden, wenn die Gemeinde der
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(109.2340827) + 'px'}">
													Bauherrschaft vor Ablauf dieser Frist schriftlich mitteilt, dass kein Genehmigungsverfahren durchgeführt werden soll.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(115.2864147) + 'px'}">
													Der Versand der schriftlichen Mitteilung über einen unverzüglichen Beginn kann von der Gemeinde selbst oder aber durch
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(118.9262787) + 'px'}">
													Antrag der Bauherrschaft veranlasst werden nur bei Antragstellung ist sie gebührenpflichtig (Tarifstelle 2.4.9.1 AVerwGebO
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(122.6119937) + 'px'}">
													NRW). Der Antrag kann formlos zusammen mit der Vorlage bei der Gemeinde oder aber zu einem anderen Zeitpunkt innerhalb
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(126.2941817) + 'px'}">
													des Monats nach Abgabe der Bauvorlagen gestellt werden.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(142.4196257) + 'px'}">
													Es wird beantragt, dass die Gemeinde nach 63 Absatz 3 Satz 5 BauO NRW 2018 vorzeitig mitteilt, dass kein
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(20.1984236) + 'px', 
														top: gib_hoehe_in_mm(146.1018137) + 'px'}">
													Genehmigungsverfahren durchgeführt werden soll.
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.0791763) + 'px', 
														top: gib_hoehe_in_mm(89.6962662) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.0791763) + 'px', 
														top: gib_hoehe_in_mm(156.8097857) + 'px'}">
													Für die Bauherrschaft
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.0791763) + 'px', 
														top: gib_hoehe_in_mm(183.1935092) + 'px'}">
													Unterschrift
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(208.8893966) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(1.4818786) + 'px', 
													top: gib_hoehe_in_mm(0.084795499999984) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(20.5946532) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(167.2226626) + 'px', 
													top: gib_hoehe_in_mm(20.5946532) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(29.1687902) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(37.6939019) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(51.0538252) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(63.5898413) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(93.8102354) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(134.3830799) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(156.6239892) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.9332887) + 'px', 
													width: spalte_breit_mm(182.5134325) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(187.7173158) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.4467212) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(167.2226626) + 'px', 
													top: gib_hoehe_in_mm(20.5946532) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(107.9392499) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(31.1933266) + 'px', 
													top: gib_hoehe_in_mm(156.6239892) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(107.9392499) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(42.8564102) + 'px', 
													top: gib_hoehe_in_mm(51.0538252) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "bauaufsicht": {
        "text": "",
        "datenuebernehmen": "bauamt.anzeigeName<nl>bauamt.adresse.strasse",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauaufsicht_plz": {
        "text": "",
        "datenuebernehmen": "bauamt.adresse.plz<&>bauamt.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrund_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse<&>baugrundstueck.plz<&>baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_email": {
        "text": "",
        "datenuebernehmen": "bauherr.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bezeichnung_bauvorhaben": {
        "text": "",
        "datenuebernehmen": "bezeichnung_bauvorhaben",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "b1_2_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b1_3_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_1_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_2_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_3_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_4_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_5_1_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_5_2_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_5_3_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "weiterbehandlungJa_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "weiterbehandlungNein_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bauherr_name": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_name": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_strasse": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_strasse": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_ort": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_ort": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_vertreter": {
        "text": "",
        "datenuebernehmen": "bauherr.vertreter.anzeigeName<nl>bauherr.vertreter.adresse.strasse<nl>bauherr.vertreter.adresse.plz<&>bauherr.vertreter.adresse.ort",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_tel": {
        "text": "",
        "datenuebernehmen": "bauherr.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_fax": {
        "text": "",
        "datenuebernehmen": "bauherr.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_tel": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_fax": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_vorlagenberechtigt": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_mitgliedsnummer": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.AK_Mitgliedsnummer",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_email": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gemarkungen": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.gemarkung",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "flure": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flur",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "flurstuecke": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flurstueck",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "g_klasse": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b1_1_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b2_5_3_text": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "b3_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "b4_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ort_datum_antrag": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ort_datum_bauherr": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ort_datum_entwurfverfasser": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    }
}
var formulaName = "NRWFreistellung"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>