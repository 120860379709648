<template>
	<span v-if="logObject.loggedIn === true">
        
        <div class="subnav" v-if="!itsMobile">
            <b-container fluid>
                <b-row>
                    <b-col style="min-width:70px;max-width:70px;" cols="1">
                        <b-button 
                            class="mr-2 btn-nav" 
                            :disabled="!bearbeiten"
                            @click="neuerEintrag" 
                            v-b-tooltip.hover 
                            title="neuer Eintrag" 
                            variant="info" >
                            <span class="fa fa-plus"></span>
                        </b-button>	
                    </b-col>

                    <b-col cols="4">
                        <b-input-group prepend="Suche">
                            <b-form-input
                                v-model="projektEinstellungen.main.filter"
                                type="text"
                                placeholder="Sucheingabe">
                            </b-form-input>

                            <b-input-group-append>
                                <b-btn v-b-tooltip.hover title="Sucheingabe löschen" v-show="projektEinstellungen.main.filter" :pressed="false" @click="clearSearch" variant="warning">X</b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>


                    <!-- <b-col cols="3">
                        <span style="margin-top:6px; min-width:120px; float:left">
                            Einträge / Seite:
                        </span>

                        <span style="margin-top:4px; width:60px; float:left">
                            <b-form-select @input="safeSpalten" id="input-sm" size="sm" v-model="projektEinstellungen.main.perPage" :options="pageOptions"></b-form-select>
                        </span> 
                        <span style="clear:both"> </span>

                    </b-col> -->

							<!-- <b-col cols="1">
								<b-button v-b-tooltip.hover title="manuell synchronieren" variant="primary" @click="updateAlleProjekte">
									<b-icon icon="arrow-repeat"></b-icon>
								</b-button>
							</b-col> -->

                    <b-col  v-b-tooltip.hover title="Ansicht wählen" cols="3">
 <!-- size="lg" -->
                        <b-form-radio-group
                            id="btn-radios-2"
                            v-model="projektEinstellungen.main.projektAnsicht"
                            :options="optionsMap"
                            button-variant="outline-primary"
                           
                            name="radio-btn-outline"
                            buttons
                        ></b-form-radio-group>

                    </b-col>

                    <b-col v-if="projektEinstellungen.main.projektAnsicht!='map'" cols="1">

                        <b-button v-b-tooltip.hover title="Ansicht" variant="outline-primary" @click="openModalSpalten">
                            <b-icon icon="layout-three-columns" ></b-icon>
                        </b-button>

                    </b-col>

                    <b-col class="float-right text-right" style="padding-right:0px" cols="3">          <!--v-bind:class="{ 'button-glow': glowFormularBearbeiten && !bearbeiten}"  -->
                       
                        <b-form-checkbox class="float-right" style="margin-top:5px;" v-model="bearbeiten" name="check-button" switch>
                            bearbeiten
                        </b-form-checkbox>

                        <!-- <b-button v-b-tooltip.hover title="bearbeiten de-/ aktivieren" style="min-width:126px" variant="outline-primary" @click="bearbeiten=!bearbeiten">
                            bearbeiten <b-icon v-if="bearbeiten" icon="check" ></b-icon>
                        </b-button> -->
                    </b-col>

                    </b-row>
            </b-container>
        </div>

           <div class="subnav" v-if="itsMobile">
            <b-container fluid>
                <b-row>
                    <b-col style="min-width:70px;max-width:70px;" >
                        <b-button 
                            class="mr-2 btn-nav" 
                            :disabled="!bearbeiten"
                            @click="neuerEintrag" 
                            v-b-tooltip.hover 
                            title="neuer Eintrag" 
                            variant="info" >
                            <span class="fa fa-plus"></span>
                        </b-button>	
                    </b-col>

                    <b-col style="min-width:70px;max-width:70px;" >

                        <b-button variant="outline-primary" @click="openModalSpalten">
                            <b-icon icon="layout-three-columns" ></b-icon>
                        </b-button>

                    </b-col>

                    
                    <b-col class="text-right" style="padding-right:0px" >          <!--v-bind:class="{ 'button-glow': glowFormularBearbeiten && !bearbeiten}"  -->
                        <b-button  style="min-width:126px" variant="outline-primary" @click="bearbeiten=!bearbeiten">
                            bearbeiten <b-icon v-if="bearbeiten" icon="check" ></b-icon>
                        </b-button>
                    </b-col>

            </b-row>


            <b-row class="my-2">
                <b-col >
                    <b-input-group prepend="Suche">
                        <b-form-input
                            v-model="projektEinstellungen.main.filter"
                            type="text"
                            placeholder="Sucheingabe">
                        </b-form-input>

                        <b-input-group-append>
                            <b-btn v-b-tooltip.hover title="Sucheingabe löschen" v-show="projektEinstellungen.main.filter" :pressed="false" @click="projektEinstellungen.main.filter=''" variant="warning">X</b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>


            <!-- <b-row>
                <b-col>
                    <span style="margin-top:6px; min-width:120px; float:left">
                        Einträge / Seite:
                    </span>

                    <span style="margin-top:4px; width:60px; float:left">
                        <b-form-select @input="safeSpalten" id="input-sm" size="sm" v-model="projektEinstellungen.main.perPage" :options="pageOptions"></b-form-select>
                    </span> 
                    <span style="clear:both"> </span>

                </b-col>
            </b-row> -->
            </b-container>
        </div>

        
    <div class="subnav_shadow">
      <b-container fluid>
                <b-row>
                    <b-col>
                        <b-button 
                            class="mr-2 btn-nav" 
                        
                            variant="info" >
                            <span class="fa fa-plus"></span>
                        </b-button>	
                    </b-col>

                    <b-col cols="4">
                        <b-input-group prepend="Suche">
                            <b-form-input
                           
                                type="text"
                                placeholder="Sucheingabe">
                            </b-form-input>

                            <b-input-group-append>
                                <b-btn v-b-tooltip.hover title="Sucheingabe löschen" v-show="projektEinstellungen.main.filter" :pressed="false" variant="warning">X</b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    
                    <!-- <b-col align-v="center" cols="4">
                        <span style="margin-top:6px; min-width:120px; float:left">
                            Einträge / Seite:
                        </span>
                        <span style="margin-top:4px; width:60px; float:left">
                        </span> 

                    </b-col> -->

                    <b-col class="text-right" style="padding-right:0px" cols="2">          <!--v-bind:class="{ 'button-glow': glowFormularBearbeiten && !bearbeiten}"  -->
                        <b-button  style="min-width:126px" variant="outline-primary">
                            bearbeiten <b-icon v-if="bearbeiten" icon="check" ></b-icon>
                        </b-button>
                    </b-col>

                    </b-row>
            </b-container>
        </div>
        

    <div v-if="logObject.loggedIn == true" class="kontainer">

        <b-card v-if="projektEinstellungen.main.projektAnsicht=='map'" no-body class="overflow-hidden" style="width: 100%;">
            <b-row no-gutters>
                <b-col md="3">
                                <virtual-list
                                    class="list"    
                                    ref="ProjektListe"
                                    :style="{ height: window.height - 260 + 'px' }"
                                    :data-key="'projectID'"
                                    :data-sources="filterProjects"
                                    :data-component="itemComponent"
                                    :estimate-size="filterProjects.length"
                                >
                                </virtual-list>
                </b-col>

                <b-col md="9">

                    <l-map
                            ref="myMap" 
                            :zoom="zoom"
                            :center="center"
                            :options="mapOptions"
                            style="height: 100%"
                            @update:center="centerUpdate"
                            @update:zoom="zoomUpdate"
                            >
                            <l-tile-layer
                                :url="url"
                                :attribution="attribution"
                            />

                            <l-marker v-bind:key="index" v-for="(coord, index) in alleProjekteCoords" :lat-lng="coord.coord">
                                {{coord}}
                                    <l-tooltip :options="{ permanent: true, interactive: true }">
                                <div @click="innerClick(coord.id)">
                                    {{coord.name}}

                                </div>
                                </l-tooltip>

                            </l-marker>

                        <!-- :icon-url="require('leaflet/dist/images/marker-icon-2x-red.png')"> -->
                            <l-marker :lat-lng="withTooltip"
                                :icon="getIcon({color:'red', strokeColor:'black'})">
                            
                                <l-tooltip :options="{ permanent: true, interactive: true }">
                                <div @click="innerClick">
                                    Retz Architekten
                                </div>
                                </l-tooltip>
                            </l-marker>

                            </l-map>
                    </b-col>
            </b-row>
        </b-card>

                 <!-- {{welcheFelder}} 
                 class="list" -->

 <b-container v-if="projectsLoaded==true && projektEinstellungen.main.projektAnsicht!='map'" class="abstand_nach_unten_50" fluid >

                            <b-table
                                    show-empty
                                    sort-null-last
                                    :items="alleProjekte"
                                    :fields="projektEinstellungen.main.meineFelder"
                                    :sort-by.sync="projektEinstellungen.main.sortBy"
                                    :sort-desc.sync="projektEinstellungen.main.sortDesc"
                                    :sort-direction="projektEinstellungen.main.sortDirection"
                                    @sort-changed="safeSpalten"
                                    :filter="projektEinstellungen.main.filter"
                                    @filtered="onFiltered"
                                >

                                <template v-slot:empty="scope">
                                    <h4>Es sind noch keine Einträge vorhanden.</h4>
                                </template>

                                <template v-slot:emptyfiltered="scope">
                                    <h4>Die Suche brachte kein Ergebnis.</h4>
                                </template>

                                <template #cell(projectContent.stammdaten.allgemein.bv_name)="data">
                                    <span class="projectTitle" @click="loadProject(data.item.projectID, 'projekte')" style="height:100%; width:100%">
                                        {{data.value}}
                                    </span>
                               </template>

                                <template v-slot:cell(delete)="data">

                                           <b-form-group 
                                                v-show="eintragLoeschen===data.index"
                                                v-b-popover.hover.top="'sicher?'"
                                            >

                                                <b-button-group>
                                                    <b-button @click="eintragLoeschen=null" variant="outline-secondary">nein</b-button>
                                                    <b-button @click="loescheEintrag(data)" variant="outline-secondary">ja</b-button>
                                                </b-button-group>

                                            </b-form-group>

                                            <b-form-group v-if="eintragLoeschen!==data.index">
                                                    <b-btn size="sm" :disabled="!bearbeiten" v-if="eintragLoeschen!=data.index" @click="eintragLoeschen=data.index" variant="danger">X</b-btn>
                                            </b-form-group>

                                </template>

                            </b-table>
                            
                                <!-- <b-row>
                                    <b-col md="6" class="my-1">
                                        <b-pagination
                                        v-model="projektEinstellungen.main.currentPage"
                                        :total-rows="totalRows"
                                        :per-page="projektEinstellungen.main.perPage"
                                        class="my-0"
                                        ></b-pagination>
                                    </b-col>
                                </b-row> -->
                    </b-container>

        <!-- <b-container v-if="projectsLoaded==true && projektEinstellungen.main.projektAnsicht!='map'" class="abstand_nach_unten_50" fluid >

			<div class="zeile_oben">

                <b-row>

                    <b-col :key="option" v-for="option in projektEinstellungen.main.myFields" class="card_new"    >

                        {{spaltenName(option)}}

                    </b-col>
                </b-row>


			</div>


                        <keep-alive>
                         <virtual-list
                                  
                                ref="ProjektListe"
                                :style="{ height: window.height - 260 + 'px' }"
                                :data-key="'projectID'"
                                :data-sources="filterProjects"
                                :data-component="ProjectsItemTabelle"
                                :estimate-size="filterProjects.length"
                            >
                            </virtual-list>
                        </keep-alive>
                                                     
                    </b-container> -->





        <div style="height:500px;"></div>
    </div> <!--kontainer -->
   
    <b-modal 
        ref="spaltenWaehlen" 
        id="spaltenWaehlen" 
        scrollable
    >

            <b-container slot="modal-header">
                    <b-row>
                        <b-col cols="5">
                                <div class="ueberschrift">
                                    <span > Spalten auswählen </span>
                                </div>
                        </b-col>


<!-- 
 -->
                        <b-col>
                            <b-button class="mr-2" variant="outline-danger" @click="closeModalSpalten">abbrechen</b-button>
                            <b-button variant="outline-success" @click="safeSpalten">speichern</b-button>
                        </b-col>
                    </b-row>
            </b-container>


            <div style="min-height:300px;" class="modal-body_custom">
                <div class="col-md-12 abstand_nach_unten">
                  
                 <!-- possibleFields:  {{possibleFields}} -->

                    <!-- value-field="item"
                    text-field="name" -->
                <b-form-checkbox-group
                v-if="projektEinstellungen.main.myFields"
                    v-model="projektEinstellungen.main.myFields"
                    :options="spaltenOptions"
                    class="mb-3"
                    stacked
                    disabled-field="notEnabled"
                    ></b-form-checkbox-group>
           </div>
            </div>

        	<div slot="modal-footer" class="w-100"></div>

    </b-modal>

    <b-modal no-close-on-esc no-close-on-backdrop 
       v-if="projectDaten"
        transition="nice-modal-fade" 
        ref="neuer_projekte_eintrag" 
        id="neuer_projekte_eintrag" 
        scrollable
        @shown="focusMyElement"
    >

            <div slot="modal-header">
                    <b-row>
                        <b-col>
                                <div class="ueberschrift">
                                    <span > neuer Eintrag </span>
                                </div>
                        </b-col>
                    </b-row>
            </div>


            <div v-if="projectDaten.stammdaten" style="min-height:300px;" class="modal-body_custom">
                <b-row>
                    <b-col class="mb-3">
                        <label class="eingabe-label mb-1"> Name des neuen Projektes: </label>
                        <input @keyup.enter="safeNewProject" @keyup.esc="newProjectAbbruch" ref="focusThis" v-model="projectDaten.stammdaten.allgemein.bv_name" class="form-control-archiapp" type="text">
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-button class="mr-2" variant="outline-danger" type="button" @click="newProjectAbbruch">abbrechen</b-button>
                        <b-button variant="outline-success" @click="safeNewProject">speichern</b-button>
                    </b-col>
                </b-row>

            </div>

        	<div slot="modal-footer" class="w-100"></div>

    </b-modal>


	</span>
</template>

<script>

    // Object.byString = function(o, s) {
    //     s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    //     s = s.replace(/^\./, '');           // strip a leading dot
    //     var a = s.split('.');
    //     for (var i = 0, n = a.length; i < n; ++i) {
    //         var k = a[i];
    //         if (k in o) {
    //             o = o[k];
    //         } else {
    //             return;
    //         }
    //     }
    //     return o;
    // }

import L from "leaflet";

import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

import { projectMixin } from '../mixins/projectMixin.js'
import PulseLoader from 'vue-spinner/src/PulseLoader'

import ItemProject from './ProjectsItem.vue'
import ProjectsItemTabelle from './ProjectsItemTabelle.vue'

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

//     var greenIcon = new LMap.Icon({
//   iconUrl: require('leaflet/dist/images/marker-icon-2x-red.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowSize: [41, 41]
// });


export default {

		components:{
            PulseLoader,
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LTooltip,
            'item-project': ItemProject,

        },
            
        mixins: [projectMixin],
        
    data(){
        return{
            isOnline:true,
            optionsMap: [
                { text: 'Tabelle', value: 'table' },
                { text: 'Karte', value: 'map' },
            ],
            zoom: 12,
            center: L.latLng(51.019760, 7.206181),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            withTooltip: L.latLng(51.019760, 7.206181),
            currentZoom: 12,
            currentCenter: L.latLng(51.019760, 7.206181),
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },

	        itemComponent:ItemProject,
        ProjectsItemTabelle:ProjectsItemTabelle,



            indexAB:0,
            eintragLoeschen:null,
            emptyText:"leer",
            pageOptions: [25, 50, 100],

            modalInfo: { title: '', content: '' },
            transProps: {
                // Transition name
                name: 'list'
            },
            

            neuesProject:{name:''},
            subnavi:null,
            totalRows:0,
 
         
            
                //             {
                //     key: "projectContent.ProjektBeteiligte[5]",
                //     label: "Gemeinde",
                //     sortable: true,
                //     formatter: 'for_gemeinde',
                //     sortByFormatted: true,
                //     filterByFormatted: true,
                //     id:22
                // },
            }

    },

    computed:{
    welcheFelder(){

        var leererArray=[];
  
        // var obj = {a:{b:{etc:5}}};

        // console.log('a.b.etc'.split('.').reduce(this.index, obj))

        if(this.projektEinstellungen.main.myFields.length>0){
  
              for(var value of this.projektEinstellungen.main.myFields){
             //      console.log(value)
                 // var index = this.possibleFields.id.indexOf(value)
                var	pos = this.possibleFields.map(function(e) { return e.id; }).indexOf(value);
  
                  if(pos>=0 && this.source!=undefined)
                  leererArray.push({ label:this.possibleFields[pos].label, name: this.possibleFields[pos].key.split('.').reduce(this.indexer, this.source), width:this.possibleFields[pos].width } )

//                  leererArray.push({ label:this.possibleFields[pos].label, name: this.byString(this.source, this.possibleFields[pos].key), width:this.possibleFields[pos].width } )
                  // console.log(returnValue)
              }
        }
      //  leererArray = this.projektEinstellungen.main.myFields
      console.log(leererArray)
         return leererArray
      },
	filterProjects() {

		const search = this.projektEinstellungen.main.filter.toLowerCase().trim();

		if(search || this.kindArray.length>0 || this.artArray.length>0){

			// if(this.$refs.ProjektListe==undefined)
			// this.refreshListe();
		
			return this.alleProjekte.filter(el => el.projectContent.stammdaten.allgemein.bv_name.toLowerCase().indexOf(search) > -1 && this.has(el.projectContent.stammdaten.allgemein.bv_status, this.statusArray) && this.findCommonElements(el.projectContent.stammdaten.allgemein.bv_typ, this.bv_typArray) );
			}
		else
			return this.alleProjekte

	},

        alleProjekteCoords(){
            var returnValue=[];


        if(this.filterProjects.length>0){

                for(var pro of this.filterProjects)
                {
                    if(pro.projectContent.stammdaten!=undefined){
                       // console.log(pro.projectContent)
                        if(pro.projectContent.stammdaten.adresse.lat!=undefined && pro.projectContent.stammdaten.adresse.lon!=undefined)
                        returnValue.push({name:pro.projectContent.stammdaten.allgemein.bv_name, id:pro.projectID, coord: L.latLng(pro.projectContent.stammdaten.adresse.lat, pro.projectContent.stammdaten.adresse.lon), lat:pro.projectContent.stammdaten.adresse.lat, lon:pro.projectContent.stammdaten.adresse.lon})
                    }
                }
            }

            return returnValue
        },

    
        spaltenOptions(){
            var returnValue=[];

            for(var value of this.possibleFields){
                // console.log(value)

                returnValue.push({ text: value.label, value:value.id } )
                // console.log(returnValue)
            }
            

            return returnValue
        },



		alleProjekte:
		{
			get()
			{
				return this.$store.getters.alleProjekte
			},
			set(value)
			{
				this.$store.commit('setalleProjekte', value);
			}
        },

        logObject(){
            return this.$store.state.logObject;
        },
        
            
    },

    mounted() {

    
        console.log("mounted")
        
        this.checkSubNav();

        this.pollData()

        setTimeout(() => {
            self.pollData()

          this.axios.get(this.$store.state.url_getAuswahllisten,
			{
				  params: {
					  besonders: this.$store.state.dev_sub,
				  }
			  })
	  
		  .then((response) => {
			
			this.auswahlListen = response.data
	  		// console.log(this.auswahlListen)
		  })


        }, 1000);

        setTimeout(() => {
            self.pollData()
              // this.searchAlleProjectForContacts();
        }, 2000);



        // setTimeout(() => {
        //     self.pollData()
        // }, 3000);

        // setTimeout(() => {
        //     self.pollData()
        // }, 4000);

      // Set the initial number of items


        //    if(this.$route.params.login) {

        //     this.$store.commit('setuserSelbst', this.$route.params.login);

        //   	this.axios.get(this.$store.state.url_getAuswahllisten,
		// 	{
		// 		  params: {
		// 			  besonders: this.$store.state.dev_sub,
		// 		  }
		// 	  })
	  
		//   .then((response) => {
			
		// 	this.auswahlListen = response.data

		//   })
		  
          
        //         this.checkloggin();
        //         this.checkSubNav();

        //         if(this.logObject.loggedIn == true)
        //         this.$router.push('projekte');

        // }

  

            var self = this;
            console.log(this.projektEinstellungen.main.letzteAufgerufen.length)
            if(this.projektEinstellungen.main.letzteAufgerufen.length==0){
                this.updateprojektEinstellungen()
                .then(function (results) {
                     console.log("updateprojektEinstellungen");
                    self.projektEinstellungen = results
                    self.projektEinstellungen.main.meineFelder = self.selectSpalten();

                });
            }

            // clearInterval(self.polling)
            // console.log(`clearInterval`)
   // clearInterval(meinIntervall);

    },



    methods:{
        clearSearch()
        {
            console.log("clearSearch")
            this.projektEinstellungen.main.filter=''

            this.axios.post(this.$store.state.url_projektEinstellungen, {			
          besonders: this.$store.state.dev_sub,
          was:'setProjektEinstellungen',
          projektEinstellungen:this.$store.state.projektEinstellungen,

          }).then((response) => {
              // console.log(response)
          })
          .catch(function (error) {

          })

        },

        spaltenName(nummer){
            var returnValue = ''
            for(var value of this.spaltenOptions){
            if(value.value == nummer)
            returnValue = value.text
            }
            return returnValue
        },
    
    pollData () {
        console.log("polling")
        var self = this;
		// this.polling = setInterval(() => {
			//console.log(self.alleProjekte)

                if(self.alleProjekte.logout==true || self.alleProjekte==undefined || Object.keys(self.alleProjekte).length < 1){
                    console.log("echter request")
                   self.updateAlleProjekte();
                } 
            
            
            // }, 3000)
	},

    getIcon(item) {
    return L.divIcon({
        className: "my-custom-pin",
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="45" width="30">
    <g transform="translate(-814.59595,-274.38623)">
        <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
        <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${item.color};stroke:${item.strokeColor};"/>
        <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:${item.circleColor};"/>
        </g>
    </g>
    </svg>`
    });
    },

        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },

        centerUpdate(center) {
            this.currentCenter = center;
        },

        showLongText() {
            this.showParagraph = !this.showParagraph;
        },

        innerClick(id) {
            this.loadProject(id, 'projekte')
        },



        gomycell(key) {
            return `cell(projectContent.ProjektBeteiligte.${key})`; // simple string interpolation
        },

        safeSpalten(){

            
            if(this.isOnline){

                this.projektEinstellungen.main.meineFelder = this.selectSpalten();
                console.log(this.projektEinstellungen)
                this.setProjektEinstellungenMeth(this.projektEinstellungen);

                this.closeModalSpalten()
            }
        
        },

        openModalSpalten(){

            this.$bvModal.show('spaltenWaehlen')

        },

    closeModalSpalten(){

        this.$bvModal.hide('spaltenWaehlen')

    },

    focusMyElement() {
      this.$refs.focusThis.focus()
    },

        // changeBearbeiten: function(checked) {
        // this.$store.commit('changeBearbeiten', checked);
        // },
    
        loescheEintrag(data) {
            //  console.log('loescheEintrag');
            var projectID = data.item.projectID;
            var bvname = data.item.projectContent.stammdaten.allgemein.bv_name;
// console.log(projectID);
                this.axios.post(this.$store.state.url_set_project, {
                    projektID: projectID,
                    activate:"0",
                    delete:true,
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {
                        if(response.data.activate){
                               
                            const targetIndex = this.alleProjekte.findIndex(item => item.projectID === projectID);
                            
                            if(targetIndex)
							this.alleProjekte.splice(targetIndex, 1);
                            // this.Papierkorb.unshift(this.Addressbook[targetIndex]);

                           var was

                           if(response.data.activate===1)
                            was = "wiederhergestellt";
                           else
                            was = "gelöscht";

                           this.$toasted.show("Das Bauvorhaben "+bvname+" wurde erfolgreich "+ was +".", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})

                        }
                })
                .catch(function (error) {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })
                    

        },

        info(item, index, button) {
            this.modalInfo.title = `Row index: ${index}`
            this.modalInfo.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', 'modalInfo', button)
        },

        resetModal() {
            this.modalInfo.title = ''
            this.modalInfo.content = ''
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering

            if(this.alleProjekte)
                    this.totalRows = filteredItems.length
                else
                     this.totalRows =  1;

           
            this.projektEinstellungen.main.currentPage = 1
        },

        neuerEintrag(){
                this.projectDaten = JSON.parse(JSON.stringify(this.blancProject)); 
            this.projectDatenBackup =  JSON.parse(JSON.stringify(this.projectDaten));

            if(this.projectDaten)
            this.showBModal('neuer_projekte_eintrag');
        },

        newProjectAbbruch() {
           this.hideBModal('neuer_projekte_eintrag');
           this.projectDaten = JSON.parse(JSON.stringify(this.blancProject)); 
           this.projectDatenBackup =  JSON.parse(JSON.stringify(this.projectDaten));
        },

        showBModal(welches) {
            //console.log(this.$refs[welches]);
            this.$refs[welches].show()
        },

        hideBModal(welches) {
            this.$root.$emit('bv::hide::modal', welches);
        },
    
        safeNewProject(){
            //
                   this.axios.post(this.$store.state.url_set_project, {
                    aktuellesProjekt: this.projectDaten,
                    neu:true,
                    besonders: this.$store.state.dev_sub,
                }).then((response) => {
                        if(response.data.projectID){

                            this.projectDatenBackup =  JSON.parse(JSON.stringify(this.projectDaten));

                            this.$toasted.show("Das neue Bauvorhaben wurde erfolgreich erstellt.", { position: 'top-center',  duration:2500, type:'success', containerClass:'ganzVorne'})
                            var BV_ID = response.data.projectID;
                            
                            this.alleProjekte.push({'projectID':BV_ID, 'projectContent': this.projectDaten});

                            var ziel = 'projekte/' + BV_ID + '/stammdaten';
                            //this.updateCurrentProject (BV_ID);
                            this.hideBModal('neuer_projekte_eintrag');

                            this.$router.push(ziel);
                        }
                })
                .catch(function (error) {
                            // handle error
                            var	ErrorAlert = 'Ups. Etwas ist schief gelaufen.  Hier die genaue Fehlerbeschreibung:';
                            this.$toasted.show(ErrorAlert + error, { position: 'top-center',  duration:2500, type:'error', containerClass:'ganzVorne'})
                            //console.log(error);
                })
                        

            //alert(this.neuesProject.name);
        },

        // updateCurrentProject (projectid) {
        //     this.$root.$emit('updateCurrentProject', projectid);
        // },

	},
    
	created(){
      


            // else if(this.zaehlen==0){
            //      console.log("updateAlleProjekte zaehlen");
            //     this.updateAlleProjekte();
            //     this.zaehlen=1;
            // }

	},
	
}
</script>


<style>
.dg-btn{
    width: 100%;
    margin-top: 10px !important;
}
.dg-btn:hover{
border-color: darkblue;
}
.thClass {
    font-weight: 900;
}

.btn-space {
    margin-right: 5px;
}
</style>
<style scoped>

.custom-select
{
    padding:0
}
.container-fluid{
padding-left:0px;
}
.projectTitle{

    color:#007bff;;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.1em;
}
</style>