import Vue from 'vue'
import Router from 'vue-router'

import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/store'
// import {store} from '../store/store'

import startseite from './components/oeffentlich/start.vue'
import preise from './components/oeffentlich/preise.vue'

import meetingExtern from './components/oeffentlich/meeting.vue'
import modellExtern from './components/oeffentlich/modell.vue'

import kontakt from './components/oeffentlich/kontakt.vue'

import login from './components/oeffentlich/Login.vue'
import LoginExtern from './components/oeffentlich/Login_extern.vue'

import uebersicht from './components/uebersicht.vue'
import adressbuch from './components/adressbuch.vue'

import kalender from './components/kalender.vue'

import projekte from './components/projekte.vue'
import einstellungen from './components/einstellungen.vue'
import hilfe from './components/hilfe.vue'
import wissen from './components/wissen.vue'
import stammdaten from './components/projekte/stammdaten.vue'
import dateien from './components/projekte/dateien.vue'
import kosten from './components/projekte/kosten.vue'
import modell from './components/projekte/modell.vue'

import honorar from './components/projekte/honorar.vue'
import formular from './components/projekte/formular.vue'
import berechnungen from './components/projekte/berechnungen.vue'
import briefe from './components/projekte/briefe.vue'
import ausschreibung from './components/projekte/ausschreibung.vue'
import meeting from './components/projekte/meeting.vue'
import bautagebuch from './components/projekte/bautagebuch.vue'
import fotos from './components/projekte/fotos.vue'
import aufgaben from './components/projekte/aufgaben.vue'
import bauablaufplan from './components/projekte/bauablaufplan.vue'
import beteiligte from './components/projekte/beteiligte.vue'
import ordnerruecken from './components/projekte/ordnerruecken.vue'
import gewerke from './components/projekte/gewerke.vue'
import gantt from './components/projekte/Gantt.vue'
import bauauftraege from './components/projekte/bauauftraege/bauauftraege.vue'
import meineEinstellungen from './components/einstellungen/meineEinstellungen.vue'
import meineProjekte from './components/einstellungen/meineProjekte.vue'
import meinProfil from './components/einstellungen/meinProfil.vue'
import mitarbeiter from './components/einstellungen/mitarbeiter.vue'
import unternehmen from './components/einstellungen/unternehmen.vue'
import sabre from './components/einstellungen/sabre.vue'
import zugriffsrechte from './components/einstellungen/zugriffsrechte.vue'
import weihnachtskarten from './components/einstellungen/weihnachtskarten.vue'
import admin from './components/einstellungen/admin.vue'
import serienbriefe from './components/einstellungen/serienbriefe.vue'
import kalendereinstellungen from './components/einstellungen/kalendereinstellungen.vue'
import auswahl from './components/einstellungen/auswahl.vue'
import sharescreen from './components/einstellungen/sharescreen.vue'

import ifcViewer from './components/ifcViewer.vue'




Vue.use(Router)

// export default new Router({
  
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
	{ path:'/start', component:startseite},
	{ path:'/', redirect: '/start'},
	{ path:'/preise', component:preise},
	{ path:'/meetingExtern/:raum', component:meetingExtern},
  { path:'/modellExtern/:modell', component:modellExtern}, 
  { path:'/modellExtern', component:modellExtern}, 
	{ path:'/kontakt', component:kontakt},
	{ path:'/login', component:login},
	{ path:'/loginExtern', component:LoginExtern},


    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.

    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // },
    { path:'/adressbuch', name: 'adressbuchpur', meta:{requiresAuth: true}, component:adressbuch},

    { path:'/adressbuch/:kontaktid', name: 'adressbuch', meta:{requiresAuth: true}, component:adressbuch},
    
    { path:'/uebersicht', meta:{requiresAuth: true}, component:uebersicht},
    { path:'/projekte', meta:{requiresAuth: true}, component:projekte},
    { path:'/projekte/:login', component:projekte},
    { path:'/kalender', meta:{requiresAuth: true}, component:kalender},

    { path:'/einstellungen', meta:{requiresAuth: true}, component:einstellungen},
    { path:'/hilfe', meta:{requiresAuth: true}, component:hilfe},
    { path:'/wissen', meta:{requiresAuth: true}, component:wissen},
    
    { path:'/projekte/:id(\\d+)/stammdaten', name: 'einzelprojekte', meta:{requiresAuth: true}, component:stammdaten},

    { path:'/projekte/:id(\\d+)/stammdaten', name: 'Stammdaten', component:stammdaten},
    { path:'/projekte/:id(\\d+)/dateien', name: 'Dateien', component:dateien},
    { path:'/projekte/:id(\\d+)/kosten', name: 'Kosten', component:kosten},
    { path:'/projekte/:id(\\d+)/modell', name: 'Modell', component:modell},
    { path:'/projekte/:id(\\d+)/modell/:modell', name: 'ModellNr', component:modell},
    { path:'/projekte/:id(\\d+)/honorar', name: 'Honorar', component:honorar},
    { path:'/projekte/:id(\\d+)/formular', name: 'Formular', component:formular},
    { path:'/projekte/:id(\\d+)/berechnungen', name: 'Berechnungen', component:berechnungen},
    { path:'/projekte/:id(\\d+)/briefe', name: 'Briefe', component:briefe},
    { path:'/projekte/:id(\\d+)/ausschreibung', name: 'Ausschreibung', component:ausschreibung},
    { path:'/projekte/:id(\\d+)/meeting', name: 'Meeting', component:meeting},
    { path:'/projekte/:id(\\d+)/bautagebuch', name: 'Bautagebuch', component:bautagebuch},
    { path:'/projekte/:id(\\d+)/fotos', name: 'Fotos', component:fotos},
    { path:'/projekte/:id(\\d+)/aufgaben', name: 'Aufgaben', component:aufgaben},
    { path:'/projekte/:id(\\d+)/bauablaufplan', name: 'Bauablaufplan', component:bauablaufplan},
    { path:'/projekte/:id(\\d+)/beteiligte', name: 'Beteiligte', component:beteiligte},
    { path:'/projekte/:id(\\d+)/ordnerruecken', name: 'Ordnerruecken', component:ordnerruecken},
    { path:'/projekte/:id(\\d+)/gantt', name: 'gantt', component:gantt},
    { path:'/projekte/:id(\\d+)/gewerke', name: 'Gewerke', component:gewerke},
    { path:'/projekte/:id(\\d+)/gewerke/bauauftrag', name: 'Bauauftrag', component:bauauftraege},

    { path:'/sharescreen', component:sharescreen},
    { path:'/ifcViewer', component:ifcViewer},
    

    { path:'/einstellungen/auswahl', component:auswahl},
    { path:'/einstellungen/sabre', component:sabre},
    { path:'/einstellungen/zugriffsrechte', component:zugriffsrechte},
    { path:'/einstellungen/meineEinstellungen', component:meineEinstellungen},
    { path:'/einstellungen/meineProjekte', component:meineProjekte},
    { path:'/einstellungen/meinProfil', component:meinProfil},
    { path:'/einstellungen/mitarbeiter', component:mitarbeiter},

    { path:'/einstellungen/mitarbeiter/:kontaktid', name: 'mitarbeiter', component:mitarbeiter},

    { path:'/einstellungen/unternehmen', component:unternehmen},
    { path:'/einstellungen/unternehmen/:kontaktid', name: 'unternehmen', component:unternehmen},

    { path:'/einstellungen/weihnachtskarten', component:weihnachtskarten},
    { path:'/einstellungen/admin', component:admin},
    { path:'/einstellungen/serienbriefe', component:serienbriefe},
    { path:'/einstellungen/kaleinstellungen', component:kalendereinstellungen},

  ],

  scrollBehavior: function (to) {    if (to.hash) {      return {        selector: to.hash      }    }  },
  
})

router.beforeEach((to, from, next)=>{

  //console.log(to)

  if(to.path.includes('/stammdaten')){

        var index_trgKontakt =  store.state.projektEinstellungen.main.letzteAufgerufen.findIndex(item=>item.id == to.params.id)

       // console.log(store.state.actualProject)
        var name = ""

        if(store.state.actualProject.stammdaten!=undefined){
          //console.log(store.state.actualProject.stammdaten)
          name = store.state.actualProject.stammdaten.allgemein.bv_name
        }


        if(index_trgKontakt==-1)
        store.state.projektEinstellungen.main.letzteAufgerufen.push({id:to.params.id, name:name})
        else if(store.state.projektEinstellungen.main.letzteAufgerufen[index_trgKontakt].name=='' && name.length>0){
          store.state.projektEinstellungen.main.letzteAufgerufen[index_trgKontakt].name = name

        }

        store.state.projektEinstellungen.main.letzteAufgerufen = store.state.projektEinstellungen.main.letzteAufgerufen.slice(-5)

        axios.post(store.state.url_projektEinstellungen, {			
          besonders: store.state.dev_sub,
          was:'setProjektEinstellungen',
          projektEinstellungen:store.state.projektEinstellungen,

          }).then((response) => {
              // console.log(response)
          })
          .catch(function (error) {

          })

       // this.setProjektEinstellungenMeth(this.projektEinstellungen);

  }

  //console.log("beforeEach");
  //console.log(to)

  axios.post(store.state.url_log, {
    besonders: store.state.dev_sub,
      was:'log',
      step:to.fullPath,
    }).then((response) => {
     // console.log(response.data);

      //  this.kontaktArt = response.data;

    })
    .catch(function (error) {
                // handle error
                // console.log(error);
    })

  if(to.meta.requiresAuth){
    
    next()
  }
  else next();
  
});

export default router;
