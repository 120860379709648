<template>
	<div>
       <div class="kontainer">

				<h1>mein Profil</h1>
				<p>{{userSelbst}}</p>

        </div>
	</div>
</template>

<script>

export default {

    data(){
        return{
        }
	},
	computed:{

		userSelbst() {
			return this.$store.state.userSelbst;
		},

	},

	methods:{

	},
	created(){
		
	}
	
	//
}
</script>


<style>


</style>
