import { forAllMixin } from './forAllMixin.js'

export const kontaktMixin = {
    mixins: [forAllMixin],

    data() {
        return {
 
            optionen_art_eintrag : { 1:'Einzelperson', 2:'Gruppe von Personen', 3:'Einzelperson'},


            offeneKarteIndex:null,
            blancVcard : {
               "basic": {   "kontaktID": "", 
                            "kind": "1" }, 
                            "markiert": false, 
                            "beteiligte":  
                                [
                                //    { "basic": { "kontaktID": "" }, 
                                //             "vollerName": { "anhang": "", "anrede": "", "anzeigeName": "", "funktion": "", "spitzname": null, "mittelname": "", "nachname": "", "titel": "", "vorname": "" }, 
                                //             "gruppe": [], 
                                //             "adresse": [], 
                                //             "notizen": [], 
                                //             "telefone": [], 
                                //             "emails": [], 
                                //             "www": [], 
                                //             "art": [], 
                                //             "taetigkeit": [] } 
                            ],
                            
                            "gruppe": [], 
                            "vollerName": { "anhang": "", "anrede": "", "anzeigeName": "", "funktion": "", "spitzname": null, "mittelname": "", "nachname": "", "titel": "", "vorname": "" }, 
                            "adresse": [], 
                            "notizen": [], 
                            "telefone": [],
                            "emails": [], 
                            "www": [], 
                            "art": { "details": [], "summary": [] }, 
                            "taetigkeit": { "details": [], "summary": [] }, 
                            "status":1,
                            "aktiv": 1,
                            "uid":'' },

            
        }
    },


methods: {

    partetMe(kontaktID){
        console.log("partetMe")
		// console.log(this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe])

			var index_trgKontakt = this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].findIndex(item=>item.kontaktID == kontaktID)
            

            this.kontaktEinstellungen.backupListen = JSON.parse(JSON.stringify(this.kontaktEinstellungen.listen)); 
            

            if(index_trgKontakt>=0)
				this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].splice(index_trgKontakt, 1);
			else
				this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe].push({"kontaktID":kontaktID, "aktiv":1})

			this.listeSpeichern();
	},


    undoLast(){

        console.log("undo")

        if(this.kontaktEinstellungen.backupListen){
          this.kontaktEinstellungen.listen = this.kontaktEinstellungen.backupListen;
        }
        this.kontaktEinstellungen.backupListen=null
  

        this.$root.$emit('bv::hide::tooltip', 'disabled-wrapper')

      },


    listeSpeichern(){
            this.axios.post(this.$store.state.url_listen, {
            besonders : this.$store.state.dev_sub,
            was: 'set',
            content:this.kontaktEinstellungen.listen,

        }).then((response) => {

        })

    },

    getKontaktByUserID(benutzerID){
        var returnValue=""

		var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.benutzerID == benutzerID)
		// console.log(trgKontakt.vollerName.anzeigeName)
		// console.log(index_trgKontakt)

		if(index_trgKontakt>=0){
            returnValue = this.Addressbook[index_trgKontakt];
        }
        return returnValue

    },   
    
    getKontaktByID(kontaktID){
        var returnValue=""

		var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == kontaktID)
		// console.log(trgKontakt.vollerName.anzeigeName)
		// console.log(index_trgKontakt)

		if(index_trgKontakt>=0){
            returnValue = this.Addressbook[index_trgKontakt];
        }
        return returnValue

    },


	speicher_kontakt_einzeln(karte, woher){ // aktualisert Kontakt auf detail = 1 => mehr Details
        console.log("ich wurde von" + woher + "gerufen")
        console.log(karte.vollerName.anzeigeName+" wird gespeichert");

             return   this.axios.post(this.$store.state.url_set_kontakt, {
                       besonders: this.$store.state.dev_sub,							
                       kontakt: karte,
                       what:"speichern"
           }).then((response) => {
            console.log(response.data)
            return response.data
            
         })
            .catch(function (error) {
                // handle error
                return error
                //console.log(error);
            })
		 },


    updateToSaveArray(kontakt){

        var onToSaveArray = this.toSaveArray.findIndex(item=>item.basic.kontaktID == kontakt.basic.kontaktID)
            
        if(onToSaveArray==-1)
            this.toSaveArray.push(kontakt);
        else if(onToSaveArray>=0)
            this.toSaveArray.splice(onToSaveArray, 1, kontakt);
    
    },

    clickOnName(kontaktID){

        console.log("clickOnName in KontaktMix");


        if(!this.Mehrfachauswahl || this.Mehrfachauswahl===false){
            //this.bearbeiten=false;
            this.kontaktHighlight = kontaktID;

            var pfad='adressbuch';
    
                if(this.$route.path.includes('mitarbeiter') || this.$route.path.includes('unternehmen'))
                pfad='mitarbeiter';
         
// einkommentieren            
            if(pfad.length>0 && this.$route.params.kontaktid!=kontaktID)
            
            this.$router.push({ name: pfad, params: { kontaktid: kontaktID } });
    
            this.$root.$emit('scrollToTopAdressbuch', '');
         
// einkommentieren  
        }
    },

    has(string, filters) {
		if(filters.length==0)
		return true
		else
		return filters.some(f => f == string)
    },
    
	inaktive_person_de_aktivieren(kontaktID){

            console.log("inaktive_person_de_aktivieren");
            console.log(kontaktID);
            var was;
            this.userLoeschen = null;
    
                    setTimeout(()=>{
                        this.popoverShowLoeschen=false;
                    },500);
    

    
                if((typeof kontaktID)=='string' || (typeof kontaktID)=='number')
                    var kontaktIDArray = [{'kontaktID':kontaktID, 'aktiv':this.KontaktDetail.aktiv}];
                else 
                    var kontaktIDArray = kontaktID;

                    console.log(kontaktIDArray);

                    if(kontaktIDArray){
                for(var singleKontaktID of kontaktIDArray){

                    if(singleKontaktID.aktiv == 0)
                    was = "reactivateKontakt"
                    else if(singleKontaktID.aktiv == 1)
                    was = "deleteKontakt"
                    
                    var KontakIDActual;



                    this.axios.post(this.$store.state.url_set_kontakt, {
                        what: was,
                        besonders: this.$store.state.dev_sub,
                        kontaktID: singleKontaktID.kontaktID,
                        }).then((response) => {
    
                        if(response.data.success){

                            this.kontaktHighlight='';

                            KontakIDActual = null;
                            KontakIDActual = response.data.kontaktID

                            if(was==='deleteKontakt'){
                                ////console.log('deleteKontakt');
                                ////console.log(KontakIDActual);

                                    var pos = this.Addressbook.map(function(e) { return e.basic.kontaktID; }).indexOf(KontakIDActual);
                                    this.Addressbook[pos].aktiv=0;
                                    //findIndex(item=>item.basic.kontaktID == data.basic.kontaktID)
                                    // suche in allen Beteiligten und Gruppen nach der KontaktID und setze auf aktiv = 0


                                    // console.log(gruppen);
                                    var einsatz

                                    if(this.Addressbook[pos].basic.kind==1)
                                        einsatz = 'beteiligte'
                                    else
                                        einsatz = 'gruppe'

                                        console.log(einsatz);

                                    var beteiligte =  this.Addressbook.filter(el => el[einsatz].findIndex(item=>item.basic.kontaktID == KontakIDActual) > -1)
                                    console.log(beteiligte);
                                    
                                    for(var beteiligteMitglied of beteiligte){
                                        var kontaktIDhier = beteiligteMitglied.basic.kontaktID
                                        var indexbasisKontalt =  this.Addressbook.findIndex(item=>item.basic.kontaktID == kontaktIDhier)
                                        
                                        if(indexbasisKontalt>=0){
                                            var indexbeteiligte = this.Addressbook[indexbasisKontalt][einsatz].findIndex(item=>item.basic.kontaktID == KontakIDActual)
                                           
                                           if(indexbeteiligte>=0){
                                            this.Addressbook[indexbasisKontalt][einsatz][indexbeteiligte].aktiv=0;
                                            this.speicher_kontakt_einzeln(this.Addressbook[indexbasisKontalt], 'inaktive_person_de_aktivieren / kontaktMixin');
                                            }
                                        }


                                     }

                                    //  console.log(beteiligte);
                                    console.log(this.Addressbook[pos].aktiv);

                                    if(this.Papierkorb && this.Addressbook && pos>=0){
                                        this.Addressbook[pos].markiert = false;
                                        this.Papierkorb.unshift(this.Addressbook[pos]);
                                        this.Addressbook.splice(pos, 1);
                                    }
                                
                            }
                            
                            if(was==='reactivateKontakt'){
                                ////console.log('reactivateKontakt');
                                ////console.log(KontakIDActual);

                                    var pos = this.Papierkorb.map(function(e) { return e.basic.kontaktID; }).indexOf(KontakIDActual);


                                    var einsatz

                                    if(this.Papierkorb[pos].basic.kind==1)
                                     einsatz = 'beteiligte'
                                    else
                                        einsatz = 'gruppe'

                                    var beteiligte =  this.Addressbook.filter(el => el[einsatz].findIndex(item=>item.basic.kontaktID == KontakIDActual) > -1)
                                    console.log(beteiligte);
                                    
                                    for(var beteiligteMitglied of beteiligte){
                                        var kontaktIDhier = beteiligteMitglied.basic.kontaktID
                                        var indexbasisKontalt =  this.Addressbook.findIndex(item=>item.basic.kontaktID == kontaktIDhier)
                                        
                                        if(indexbasisKontalt>=0){
                                            var indexbeteiligte = this.Addressbook[indexbasisKontalt][einsatz].findIndex(item=>item.basic.kontaktID == KontakIDActual)
                                           
                                           if(indexbeteiligte>=0){
                                            this.Addressbook[indexbasisKontalt][einsatz][indexbeteiligte].aktiv=1;
                                            this.speicher_kontakt_einzeln(this.Addressbook[indexbasisKontalt], 'inaktive_person_de_aktivieren / kontaktMixin');
                                            }
                                        }

 
                                     }


                                    if(this.Papierkorb && this.Addressbook && pos>=0){

                                        this.Papierkorb[pos].aktiv=1;
                                        console.log(this.Papierkorb[pos].aktiv);

                                        this.Papierkorb[pos].markiert = false;
                                        this.Addressbook.push(this.Papierkorb[pos]);
                                        this.Papierkorb.splice(pos, 1);
                                    }
                                
                            }

                       
                            this.$router.push({ name: 'adressbuchpur' });

                            //this.KontaktDetail = [{}];

                            this.popoverShowLoeschen = false;

                            this.$toasted.show('Der Benutzer wurde erfolgreich ' + response.data.success, {
                            position: 'top-center',
                            type:'success',
                            duration:2500,
                            containerClass:'ganzVorne'})


                        }
                        else
                        {
                        this.$toasted.show('Es ist etwas schief gegangen', {
                            position: 'top-center',
                            type:'error',
                            duration:2500,
                            containerClass:'ganzVorne'})
                        }


                })
            } // foreach in Kontakt Array
        } // if kontaktIDArray
        else
        {
            this.$toasted.show('kein kontaktIDArray ', {
                position: 'top-center',
                type:'error',
                duration:2500,
                containerClass:'ganzVorne'})

        }


        },


    openModalMixin(){
        this.$root.$emit('openModalKontakt');
    },


    
    UpdateEintrag(kontakt){
        const targetIndex = this.Addressbook.findIndex(item => item.basic.kontaktID === kontakt.basic.kontaktID);
        if(targetIndex>=0){
            this.Addressbook[targetIndex].vollerName = kontakt.vollerName;
        }
    },

    loescheEintragEndgueltig(){

    },

    loescheEintrag(kontaktID){
		////console.log("loesche");
            if((typeof kontaktID)=='string')
            kontaktID = [kontaktID];

				this.axios.post(this.$store.state.url_set_kontakt, {

					besonders: this.$store.state.dev_sub,							
					what: 'deleteKontakt',
					kontaktID: kontaktID,

				}).then((response) => {
					////console.log(response.data);
					if(response.data.success==true)
					{
						const targetIndex = this.Addressbook.findIndex(item => item.basic.kontaktID === this.KontaktDetail.basic.kontaktID);
						if(targetIndex>=0){
							this.Papierkorb.unshift(this.Addressbook[targetIndex]);
							this.Addressbook.splice(targetIndex, 1);
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				})



    },
    
    CloseModalFromMixin(welches) {
        //console.log(welches);
        //console.log(this.$refs.kontaktModal);
        if(this.$refs.kontaktModal.$refs[welches])
           this.$refs.kontaktModal.$refs[welches].hide()
       else
           this.$toasted.show("Diese Modal gibt es nicht.", { position: 'top-center',  duration:10000, type:'success', containerClass:'ganzVorne'})

   },

    showModalFromMixin(welches) {
        //console.log(welches);
        //console.log(this.$refs.kontaktModal);
        if(this.$refs.kontaktModal.$refs[welches])
           this.$refs.kontaktModal.$refs[welches].show()
       else
           this.$toasted.show("Diese Modal gibt es nicht.", { position: 'top-center',  duration:10000, type:'success', containerClass:'ganzVorne'})

   },

    makeKontakModalByID(kontaktID){

        this.axios.post(this.$store.state.url_get_kontakte, {
			
			besonders: this.$store.state.dev_sub,
            welche:'einzeln',
            kontaktID:kontaktID,
			}).then((response) => {

                this.KontaktDetail = response.data;
                this.KontaktDetail_backup = JSON.parse(JSON.stringify(response.data));

			})
			.catch(function (error) {
						// handle error
						//console.log(error);
            })
    },




	

    unselectAddressbook () {
        //console.log('unselectAddressbook');
		for(var i = 0; i < this.Addressbook.length; i++) {
			this.Addressbook[i].markiert=false 
        }
        
        for(var i = 0; i < this.Papierkorb.length; i++) {
			this.Papierkorb[i].markiert=false 
        }

        this.selectedAddressbook=[];

        
    },
    
    kontakteloesche(what){ // mehrere
		this.inaktive_person_de_aktivieren(what);
    },

},

computed:{
    
    anreden(){
		return this.$store.state.anreden;
    },
    
    kontaktGruppe(){
        // WEITER für Gruppen und Beteiligte
                var gruppe  = []
        
                if(this.KontaktDetail.gruppe!=undefined){
        
                    for(var prop of this.KontaktDetail.gruppe)
                    gruppe.push(this.getKontaktByID(prop));
        
                }
                return gruppe
        
            },
            
            kontaktBeteiligte(){
        // WEITER für Gruppen und Beteiligte
                var gruppe  = []
        
                if(this.KontaktDetail.beteiligte!=undefined){
        
                    for(var prop of this.KontaktDetail.beteiligte)
                    gruppe.push(this.getKontaktByID(prop));
        
                }
                return gruppe
        
            },

    kontaktEinstellungen:
    {
        get()
        {
            return this.$store.getters.kontaktEinstellungen
        },
        set(value)
        {
            this.$store.commit('setkontaktEinstellungen', value);
        }
    },

    toSaveArray:
    {
        get()
        {
            return this.$store.getters.toSaveArray
        },
        set(value)
        {
            this.$store.commit('settoSaveArray', value);
        }
    },

    backUpContacts:
    {
        get()
        {
            return this.$store.getters.backUpContacts
        },
        set(value)
        {
            this.$store.commit('setbackUpContacts', value);
        }
    }, 

    kontaktHighlight:
    {
        get()
        {
            return this.$store.getters.kontaktHighlight
        },
        set(value)
        {
            this.$store.commit('setkontaktHighlight', value);
        }
    }, 

    BauleiterUndPlaner(){
          

        var ma = this.mitarbeiter.filter(el => this.findCommonElements(el.art.summary, ["8","9"]));
        var ehema = this.ehemMitarbeiter.filter(el => this.findCommonElements(el.art.summary, ["8","9"]));

        ma.concat(ehema);

        return ma;

    },

	filterItemsByID(arr, query) {

		return arr.filter(el =>  this.has(el.basic.kontaktID, query) );
    },

    mitarbeiterKontaktIDSelf(){
        var mitarbeiterKontakt=[];
        //console.log(this.meineFirma);
        var meineFirmaID = this.meineFirma
        if(this.meineFirma>0 && this.Addressbook.length>0)
		{

            var pos = this.Addressbook.findIndex(kontakt => kontakt.basic.kontaktID==meineFirmaID);
            var meineFirma= this.Addressbook[pos];

            //console.log(meineFirma);

            for(var item of meineFirma.beteiligte){
              //  console.log(item.basic.kontaktID)
                mitarbeiterKontakt.push(item)
            }
        }
 
        return mitarbeiterKontakt;
    },

    mitarbeiter(){

		if(this.mitarbeiterKontaktIDSelf)
			{
				let filtered = this.Addressbook;
				filtered = this.Addressbook.filter(el => this.has(el.basic.kontaktID, this.mitarbeiterKontaktIDSelf));

				return filtered;
        }

		 else
		return [];

    },

    ehemMitarbeiter(){

		if(this.ehemMitarbeiterKontaktID)
			{
                let filtered = this.Addressbook;
                filtered = this.Papierkorb.filter(el => this.has(el.basic.kontaktID, this.ehemMitarbeiterKontaktID));
			//	filtered = this.filterItemsByID(this.Papierkorb, this.ehemMitarbeiterKontaktID);

				return filtered;
			}
		else
		return [];

    },



    verfuegbareEinzelpersonen(){
        return this.Addressbook.filter(el => el.basic.kind == 1);
    },

    verfuegbareGruppen(){
        return this.Addressbook.filter(el => el.basic.kind == 2);
    },

    selectedTrash:
    {
        get()
        {
            return this.$store.getters.selectedTrash 
        },
        set(value)
        {
            this.$store.commit('setselectedTrash', value);
        }
    }, 

    selectedAddressbook:
    {
        get()
        {
            return this.$store.getters.selectedAddressbook
        },
        set(value)
        {
            this.$store.commit('setselectedAddressbook', value);
        }
    }, 


    kindArray:
    {
        get()
        {
            return this.$store.getters.kindArray
        },
        set(value)
        {
            this.$store.commit('setkindArray', value);
        }
    },

    artArray:
    {
        get()
        {
            return this.$store.getters.artArray
        },
        set(value)
        {
            this.$store.commit('setartArray', value);
        }
    },

    searchTermKontakte:
    {
        get()
        {
            return this.$store.getters.searchTermKontakte
        },
        set(value)
        {
            this.$store.commit('setsearchTermKontakte', value);
        }
    },

    Mehrfachauswahl:
    {
        get()
        {
            return this.$store.getters.Mehrfachauswahl
        },
        set(value)
        {
            this.$store.commit('setMehrfachauswahl', value);
        }
    },

    showInaktivTabelle:
    {
        get()
        {
            return this.$store.getters.showInaktivTabelle
        },
        set(value)
        {
            this.$store.commit('setshowInaktivTabelle', value);
        }
    },


    
    mitarbeiterKontaktID:
    {
        get()
        {
            return this.$store.getters.mitarbeiterKontaktID
        },
        set(value)
        {
            this.$store.commit('setmitarbeiterKontaktID', value);
        }
    },

    ehemMitarbeiterKontaktID:
    {
        get()
        {
            return this.$store.getters.ehemMitarbeiterKontaktID
        },
        set(value)
        {
            this.$store.commit('setehemMitarbeiterKontaktID', value);
        }
    },

    adressbookSelection:
    {
        get()
        {
            return this.$store.getters.adressbookSelection
        },
        set(value)
        {
            this.$store.commit('setadressbookSelection', value);
        }
    }, 

	aktuelleKontaktIDurl(){
		if(this.$route.params.kontaktid){
			return this.$route.params.kontaktid
		}
		else return null;

    },
    
    KontaktDetail:
    {
        get()
        {
            return this.$store.getters.KontaktDetail
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail', value);
        }
    },

    KontaktDetail_backup:
    {
        get()
        {
            return this.$store.getters.KontaktDetail_backup
        },
        set(value)
        {
            this.$store.commit('setKontaktDetail_backup', value);
        }
    },


    

},


created(){

},

}