<template>
	<div>

        <div class="kontainer">
				<h1>Fotos</h1>

					

				 <p>Text</p>
	{{tasks}}
				<div class="gantt-target"></div>
		    <b-form-input v-model="tasks[0].start"></b-form-input>
			<b-card>
		
			</b-card>
		</div>
		

    </div>
</template>


<script>

import  FrappeGantt  from '@symbat/vue-frappe-gantt'
//import  FrappeGantt2  from '@symbat/vue-frappe-gantt/dist/frappe-gantt.min.js'

export default {



    data(){
        return{



		tasks : [
			{
				start: '2021-04-01',
				end: '2021-04-08',
				name: 'Redesign website',
				id: "Task 0",
				progress: 20
			},
			{
				start: '2021-04-03',
				end: '2021-04-06',
				name: 'Write new content',
				id: "Task 1",
				progress: 5,
				dependencies: 'Task 0'
			},
			{
				start: '2021-04-04',
				end: '2021-04-08',
				name: 'Apply new styles',
				id: "Task 2",
				progress: 10,
				dependencies: 'Task 1'
			},
			{
				start: '2021-04-08',
				end: '2021-04-09',
				name: 'Review',
				id: "Task 3",
				progress: 5,
				dependencies: 'Task 2'
			},
			{
				start: '2021-04-08',
				end: '2021-04-10',
				name: 'Deploy',
				id: "Task 4",
				progress: 0,
				dependencies: 'Task 2'
			},
			{
				start: '2021-04-11',
				end: '2021-04-11',
				name: 'Go Live!',
				id: "Task 5",
				progress: 0,
				dependencies: 'Task 4',
				custom_class: 'bar-milestone'
			},
			{
				start: '2021-04-05',
				end: '2021-04-12',
				name: 'Long term task',
				id: "Task 6",
				progress: 0
			}
		]

        }
    },
	methods:{

	},

  mounted() {

	var gantt = new FrappeGantt(".gantt-target", this.tasks, {
	
	on_click: function (task) {
		console.log(task);
	},
	on_date_change: function(task, start, end) {
		console.log(task, start, end);
	},
	on_progress_change: function(task, progress) {
		console.log(task, progress);
	},
	on_view_change: function(mode) {
		console.log(mode);
	},

    header_height: 50,
    column_width: 30,
    step: 24,
    view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
    bar_height: 20,
    bar_corner_radius: 3,
    arrow_curve: 5,
    padding: 18,
    view_mode: 'Day',   
    date_format: 'YYYY-MM-DD',
    custom_popup_html: null
});
		console.log(gantt)
		console.log(FrappeGantt)
	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
