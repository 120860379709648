<template>
			<div v-if="formular!=undefined" >
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'23.325px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(92.939977) + 'px', 
														top: gib_hoehe_in_mm(13.9743978) + 'px'}">
													Bauvertrag
													</div>
<div :style="{
														fontSize:'8.7465px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8152004) + 'px', 
														top: gib_hoehe_in_mm(28.7437103) + 'px'}">
													zwischen
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8152004) + 'px', 
														top: gib_hoehe_in_mm(31.6474894) + 'px'}">
													Auftraggeber(in)
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(47.0111589) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(53.264754) + 'px',
										
										 top: gib_hoehe_in_mm(31.8814059) + 'px'}"><b-popover 
												v-if="formular.auftraggeber"
												:show="formular.auftraggeber.showPop==true"
												
												ref='auftraggeber' 
												target='auftraggeber'  
												
												>
												<template slot='title'>
													<b-button @click="formular.auftraggeber.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.auftraggeber.size}}</span>
												<b-button @click='plus("auftraggeber", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("auftraggeber", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.auftraggeber!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.auftraggeber.text"
												
												@focus='formular.auftraggeber.showPop=true'
												@blur ='formular.auftraggeber.showPop=false'
												
												:disabled="formular.auftraggeber.isDisabled==true || formular.auftraggeber.isDisabled=='true'"
												:style="{'font-size': formular.auftraggeber.size + 'px',
														'height': formular.auftraggeber.height + 'px',
														'text-align': formular.auftraggeber.schriftausrichtung + 'px',
														'letter-spacing': formular.auftraggeber.letterSpacing + 'px',
														'font-weight': formular.auftraggeber.schriftart + 'px', }"

												id='auftraggeber'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6956959) + 'px', 
														top: gib_hoehe_in_mm(31.6474894) + 'px'}">
													Projekt
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(59.4868633) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(135.7820933) + 'px',
										
										 top: gib_hoehe_in_mm(31.8814059) + 'px'}"><b-popover 
												v-if="formular.bezeichnung_bauvorhaben"
												:show="formular.bezeichnung_bauvorhaben.showPop==true"
												
												ref='bezeichnung_bauvorhaben' 
												target='bezeichnung_bauvorhaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
												<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bezeichnung_bauvorhaben!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bezeichnung_bauvorhaben.text"
												
												@focus='formular.bezeichnung_bauvorhaben.showPop=true'
												@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
												
												:disabled="formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled=='true'"
												:style="{'font-size': formular.bezeichnung_bauvorhaben.size + 'px',
														'height': formular.bezeichnung_bauvorhaben.height + 'px',
														'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung + 'px',
														'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': formular.bezeichnung_bauvorhaben.schriftart + 'px', }"

												id='bezeichnung_bauvorhaben'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'8.7465px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8152004) + 'px', 
														top: gib_hoehe_in_mm(48.1559656) + 'px'}">
													und
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.8152004) + 'px', 
														top: gib_hoehe_in_mm(50.9729805) + 'px'}">
													Auftragnehmer(in)
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(47.0111589) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(53.264754) + 'px',
										
										 top: gib_hoehe_in_mm(51.1956106) + 'px'}"><b-popover 
												v-if="formular.auftragnehmer"
												:show="formular.auftragnehmer.showPop==true"
												
												ref='auftragnehmer' 
												target='auftragnehmer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.auftragnehmer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.auftragnehmer.size}}</span>
												<b-button @click='plus("auftragnehmer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("auftragnehmer", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.auftragnehmer!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.auftragnehmer.text"
												
												@focus='formular.auftragnehmer.showPop=true'
												@blur ='formular.auftragnehmer.showPop=false'
												
												:disabled="formular.auftragnehmer.isDisabled==true || formular.auftragnehmer.isDisabled=='true'"
												:style="{'font-size': formular.auftragnehmer.size + 'px',
														'height': formular.auftragnehmer.height + 'px',
														'text-align': formular.auftragnehmer.schriftausrichtung + 'px',
														'letter-spacing': formular.auftragnehmer.letterSpacing + 'px',
														'font-weight': formular.auftragnehmer.schriftart + 'px', }"

												id='auftragnehmer'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6956959) + 'px', 
														top: gib_hoehe_in_mm(45.8524819) + 'px'}">
													Projektnr
													</div>
<b-popover 
												v-if="formular.projektnummer"
												:show="formular.projektnummer.showPop==true"
												
												ref='projektnummer' 
												target='projektnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.projektnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.projektnummer.size}}</span>
												<b-button @click='plus("projektnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("projektnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="projektnummer" type="text" 
												v-if="formular.projektnummer!=undefined" 
												class='textfeld' v-model='formular.projektnummer.text' 
												style='width:100%' 
												@focus='formular.projektnummer.showPop=true'
												@blur ='formular.projektnummer.showPop=false'
												:disabled='formular.projektnummer.isDisabled==true || formular.projektnummer.isDisabled==true' 
												@keypress="isNumber($event, formular.projektnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(59.4868633) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(136.0320933) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(45.7438624) + 'px', 
		
														'font-size': formular.projektnummer.size + 'px', 
														'text-align': formular.projektnummer.schriftausrichtung, 
														'letter-spacing': formular.projektnummer.letterSpacing + 'px',
														'font-weight': formular.projektnummer.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6956959) + 'px', 
														top: gib_hoehe_in_mm(51.9485487) + 'px'}">
													Planung
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(59.4868633) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(135.7820933) + 'px',
										
										 top: gib_hoehe_in_mm(51.69715) + 'px'}"><b-popover 
												v-if="formular.planung"
												:show="formular.planung.showPop==true"
												
												ref='planung' 
												target='planung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.planung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.planung.size}}</span>
												<b-button @click='plus("planung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("planung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.planung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.planung.text"
												
												@focus='formular.planung.showPop=true'
												@blur ='formular.planung.showPop=false'
												
												:disabled="formular.planung.isDisabled==true || formular.planung.isDisabled=='true'"
												:style="{'font-size': formular.planung.size + 'px',
														'height': formular.planung.height + 'px',
														'text-align': formular.planung.schriftausrichtung + 'px',
														'letter-spacing': formular.planung.letterSpacing + 'px',
														'font-weight': formular.planung.schriftart + 'px', }"

												id='planung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(109.6956959) + 'px', 
														top: gib_hoehe_in_mm(65.1194248) + 'px'}">
													Objektüberw.
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(59.4868633) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(135.7820933) + 'px',
										
										 top: gib_hoehe_in_mm(65.4256448) + 'px'}"><b-popover 
												v-if="formular.objektueberwachung"
												:show="formular.objektueberwachung.showPop==true"
												
												ref='objektueberwachung' 
												target='objektueberwachung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.objektueberwachung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.objektueberwachung.size}}</span>
												<b-button @click='plus("objektueberwachung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("objektueberwachung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.objektueberwachung!=undefined"
												
												rows='3'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.objektueberwachung.text"
												
												@focus='formular.objektueberwachung.showPop=true'
												@blur ='formular.objektueberwachung.showPop=false'
												
												:disabled="formular.objektueberwachung.isDisabled==true || formular.objektueberwachung.isDisabled=='true'"
												:style="{'font-size': formular.objektueberwachung.size + 'px',
														'height': formular.objektueberwachung.height + 'px',
														'text-align': formular.objektueberwachung.schriftausrichtung + 'px',
														'letter-spacing': formular.objektueberwachung.letterSpacing + 'px',
														'font-weight': formular.objektueberwachung.schriftart + 'px', }"

												id='objektueberwachung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(84.2526944) + 'px'}">
													Sie haben am
													</div>
<b-popover 
												v-if="formular.datum_angebot"
												:show="formular.datum_angebot.showPop==true"
												
												ref='datum_angebot' 
												target='datum_angebot'  
												
												>
												<template slot='title'>
													<b-button @click="formular.datum_angebot.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.datum_angebot.size}}</span>
												<b-button @click='plus("datum_angebot", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("datum_angebot", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="datum_angebot" type="text" 
												v-if="formular.datum_angebot!=undefined" 
												class='textfeld' v-model='formular.datum_angebot.text' 
												style='width:100%' 
												@focus='formular.datum_angebot.showPop=true'
												@blur ='formular.datum_angebot.showPop=false'
												:disabled='formular.datum_angebot.isDisabled==true || formular.datum_angebot.isDisabled==true' 
												@keypress="isNumber($event, formular.datum_angebot.onylNumber) " 
												:style="{'width': spalte_breit_mm(18.7627105) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(45.4460361) + 'px', 
														'height': 20.5 + 'px', 
														'top': gib_hoehe_in_mm(83.5966845) + 'px', 
		
														'font-size': formular.datum_angebot.size + 'px', 
														'text-align': formular.datum_angebot.schriftausrichtung, 
														'letter-spacing': formular.datum_angebot.letterSpacing + 'px',
														'font-weight': formular.datum_angebot.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(65.9051693) + 'px', 
														top: gib_hoehe_in_mm(84.2526944) + 'px'}">
													zu oben genannten Projekt ein Angebot abgegeben für das Gewerk 
													</div>
<b-popover 
												v-if="formular.gewerk"
												:show="formular.gewerk.showPop==true"
												
												ref='gewerk' 
												target='gewerk'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gewerk.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gewerk.size}}</span>
												<b-button @click='plus("gewerk", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gewerk", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gewerk" type="text" 
												v-if="formular.gewerk!=undefined" 
												class='textfeld' v-model='formular.gewerk.text' 
												style='width:100%' 
												@focus='formular.gewerk.showPop=true'
												@blur ='formular.gewerk.showPop=false'
												:disabled='formular.gewerk.isDisabled==true || formular.gewerk.isDisabled==true' 
												@keypress="isNumber($event, formular.gewerk.onylNumber) " 
												:style="{'width': spalte_breit_mm(53.3050904) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(82.0312544) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(91.1571617) + 'px', 
		
														'font-size': formular.gewerk.size + 'px', 
														'text-align': formular.gewerk.schriftausrichtung, 
														'letter-spacing': formular.gewerk.letterSpacing + 'px',
														'font-weight': formular.gewerk.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(99.5496461) + 'px'}">
													Dieses Angebot wird vom Auftraggeber angenommen. Sie erhalten damit den Bauauftrag.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(110.3845901) + 'px'}">
													Dieser Bauvertrag ist ein 
													</div>

								
								<b-form-select 
								
							v-if="formular.vertagsart!=undefined"
								
								:style="{position:'absolute', width: spalte_breit_mm(88.1838175) + 'px', left: spalte_breit_mm(65.5404775) + 'px',top: gib_hoehe_in_mm(109.1636191) + 'px'}"
								
								:disabled="false"
								style="font-size:16px !important" 
								
								
								v-model="formular.vertagsart.text" class='form-control'><option :value=null>Vertragsart  wählen</option><option value="Einheitspreisvertrag">Einheitspreisvertrag</option><option value="Stundenlohnvertrag">Stundenlohnvertrag</option><option value="Pauschalpreisvertrag">Pauschalpreisvertrag</option><option value="Selbstkostenerstattungsvertrag">Selbstkostenerstattungsvertrag</option></b-form-select>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(40.8684071) + 'px', 
														top: gib_hoehe_in_mm(124.212899) + 'px'}">
													mit einer NettoVertragssumme von
													</div>
<b-popover 
												v-if="formular.nettosumme_1"
												:show="formular.nettosumme_1.showPop==true"
												
												ref='nettosumme_1' 
												target='nettosumme_1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nettosumme_1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nettosumme_1.size}}</span>
												<b-button @click='plus("nettosumme_1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nettosumme_1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="nettosumme_1" 
																				v-bind:precision="parseInt(formular.nettosumme_1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.nettosumme_1!=undefined" 
												class='textfeld' v-model='formular.nettosumme_1.text' 
												style='width:100%' 
												@focus='formular.nettosumme_1.showPop=true'
												@blur ='formular.nettosumme_1.showPop=false'
												:disabled='formular.nettosumme_1.isDisabled==true || formular.nettosumme_1.isDisabled==true' @input="neurechnen"  
												@keypress="isNumber($event, formular.nettosumme_1.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(124.7662974) + 'px', 
		
														'font-size': formular.nettosumme_1.size + 'px', 
														'text-align': formular.nettosumme_1.schriftausrichtung, 
														'letter-spacing': formular.nettosumme_1.letterSpacing + 'px',
														'font-weight': formular.nettosumme_1.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(56.9113193) + 'px', 
														top: gib_hoehe_in_mm(129.545723) + 'px'}">
													abzgl.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(77.9509324) + 'px', 
														top: gib_hoehe_in_mm(129.545723) + 'px'}">
													 Nachlass
													</div>
<b-popover 
												v-if="formular.nachlass_prozent"
												:show="formular.nachlass_prozent.showPop==true"
												
												ref='nachlass_prozent' 
												target='nachlass_prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nachlass_prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nachlass_prozent.size}}</span>
												<b-button @click='plus("nachlass_prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nachlass_prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="nachlass_prozent" 
																				v-bind:precision="parseInt(formular.nachlass_prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.nachlass_prozent!=undefined" 
												class='textfeld' v-model='formular.nachlass_prozent.text' 
												style='width:100%' 
												@focus='formular.nachlass_prozent.showPop=true'
												@blur ='formular.nachlass_prozent.showPop=false'
												:disabled='formular.nachlass_prozent.isDisabled==true || formular.nachlass_prozent.isDisabled==true' @input="neurechnen"  
												@keypress="isNumber($event, formular.nachlass_prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.3036492) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(67.3081456) + 'px', 
														'height': 17.5 + 'px', 
														'top': gib_hoehe_in_mm(129.0833454) + 'px', 
		
														'font-size': formular.nachlass_prozent.size + 'px', 
														'text-align': formular.nachlass_prozent.schriftausrichtung, 
														'letter-spacing': formular.nachlass_prozent.letterSpacing + 'px',
														'font-weight': formular.nachlass_prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.nachlass"
												:show="formular.nachlass.showPop==true"
												
												ref='nachlass' 
												target='nachlass'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nachlass.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nachlass.size}}</span>
												<b-button @click='plus("nachlass", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nachlass", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="nachlass" 
																				v-bind:precision="parseInt(formular.nachlass.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.nachlass!=undefined" 
												class='textfeld' v-model='formular.nachlass.text' 
												style='width:100%' 
												@focus='formular.nachlass.showPop=true'
												@blur ='formular.nachlass.showPop=false'
												:disabled='formular.nachlass.isDisabled==true || formular.nachlass.isDisabled==true' 
												@keypress="isNumber($event, formular.nachlass.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(130.0991214) + 'px', 
		
														'font-size': formular.nachlass.size + 'px', 
														'text-align': formular.nachlass.schriftausrichtung, 
														'letter-spacing': formular.nachlass.letterSpacing + 'px',
														'font-weight': formular.nachlass.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(70.3692932) + 'px', 
														top: gib_hoehe_in_mm(134.878547) + 'px'}">
													Zwischensumme
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(66.8659241) + 'px', 
														top: gib_hoehe_in_mm(140.211371) + 'px'}">
													zzgl.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(85.8140262) + 'px', 
														top: gib_hoehe_in_mm(140.211371) + 'px'}">
													 USt
													</div>
<b-popover 
												v-if="formular.umsatzsteuer_prozent"
												:show="formular.umsatzsteuer_prozent.showPop==true"
												
												ref='umsatzsteuer_prozent' 
												target='umsatzsteuer_prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.umsatzsteuer_prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.umsatzsteuer_prozent.size}}</span>
												<b-button @click='plus("umsatzsteuer_prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("umsatzsteuer_prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="umsatzsteuer_prozent" 
																				v-bind:precision="parseInt(formular.umsatzsteuer_prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.umsatzsteuer_prozent!=undefined" 
												class='textfeld' v-model='formular.umsatzsteuer_prozent.text' 
												style='width:100%' 
												@focus='formular.umsatzsteuer_prozent.showPop=true'
												@blur ='formular.umsatzsteuer_prozent.showPop=false'
												:disabled='formular.umsatzsteuer_prozent.isDisabled==true || formular.umsatzsteuer_prozent.isDisabled==true' @input="neurechnen"  
												@keypress="isNumber($event, formular.umsatzsteuer_prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.3209315) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(75.1539571) + 'px', 
														'height': 13.5 + 'px', 
														'top': gib_hoehe_in_mm(140.7206819) + 'px', 
		
														'font-size': formular.umsatzsteuer_prozent.size + 'px', 
														'text-align': formular.umsatzsteuer_prozent.schriftausrichtung, 
														'letter-spacing': formular.umsatzsteuer_prozent.letterSpacing + 'px',
														'font-weight': formular.umsatzsteuer_prozent.schriftart }" 
												/>

<b-popover 
												v-if="formular.ust"
												:show="formular.ust.showPop==true"
												
												ref='ust' 
												target='ust'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ust.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ust.size}}</span>
												<b-button @click='plus("ust", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ust", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="ust" 
																				v-bind:precision="parseInt(formular.ust.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.ust!=undefined" 
												class='textfeld' v-model='formular.ust.text' 
												style='width:100%' 
												@focus='formular.ust.showPop=true'
												@blur ='formular.ust.showPop=false'
												:disabled='formular.ust.isDisabled==true || formular.ust.isDisabled==true' 
												@keypress="isNumber($event, formular.ust.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(140.7647694) + 'px', 
		
														'font-size': formular.ust.size + 'px', 
														'text-align': formular.ust.schriftausrichtung, 
														'letter-spacing': formular.ust.letterSpacing + 'px',
														'font-weight': formular.ust.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(60.9377425) + 'px', 
														top: gib_hoehe_in_mm(145.544195) + 'px'}">
													BruttoVertragssumme
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(59.6465078) + 'px', 
														top: gib_hoehe_in_mm(150.877019) + 'px'}">
													abzgl.
													</div>
<b-popover 
												v-if="formular.skonto_prozent"
												:show="formular.skonto_prozent.showPop==true"
												
												ref='skonto_prozent' 
												target='skonto_prozent'  
												
												>
												<template slot='title'>
													<b-button @click="formular.skonto_prozent.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.skonto_prozent.size}}</span>
												<b-button @click='plus("skonto_prozent", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("skonto_prozent", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="skonto_prozent" 
																				v-bind:precision="parseInt(formular.skonto_prozent.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.skonto_prozent!=undefined" 
												class='textfeld' v-model='formular.skonto_prozent.text' 
												style='width:100%' 
												@focus='formular.skonto_prozent.showPop=true'
												@blur ='formular.skonto_prozent.showPop=false'
												:disabled='formular.skonto_prozent.isDisabled==true || formular.skonto_prozent.isDisabled==true' @input="neurechnen"  
												@keypress="isNumber($event, formular.skonto_prozent.onylNumber) " 
												:style="{'width': spalte_breit_mm(9.3128194) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(70.0341639) + 'px', 
														'height': 13.5 + 'px', 
														'top': gib_hoehe_in_mm(151.3782178) + 'px', 
		
														'font-size': formular.skonto_prozent.size + 'px', 
														'text-align': formular.skonto_prozent.schriftausrichtung, 
														'letter-spacing': formular.skonto_prozent.letterSpacing + 'px',
														'font-weight': formular.skonto_prozent.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(80.6857682) + 'px', 
														top: gib_hoehe_in_mm(150.877019) + 'px'}">
													 Skonto
													</div>
<b-popover 
												v-if="formular.nettosumme_2"
												:show="formular.nettosumme_2.showPop==true"
												
												ref='nettosumme_2' 
												target='nettosumme_2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.nettosumme_2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.nettosumme_2.size}}</span>
												<b-button @click='plus("nettosumme_2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("nettosumme_2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="nettosumme_2" 
																				v-bind:precision="parseInt(formular.nettosumme_2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.nettosumme_2!=undefined" 
												class='textfeld' v-model='formular.nettosumme_2.text' 
												style='width:100%' 
												@focus='formular.nettosumme_2.showPop=true'
												@blur ='formular.nettosumme_2.showPop=false'
												:disabled='formular.nettosumme_2.isDisabled==true || formular.nettosumme_2.isDisabled==true' 
												@keypress="isNumber($event, formular.nettosumme_2.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(135.4319454) + 'px', 
		
														'font-size': formular.nettosumme_2.size + 'px', 
														'text-align': formular.nettosumme_2.schriftausrichtung, 
														'letter-spacing': formular.nettosumme_2.letterSpacing + 'px',
														'font-weight': formular.nettosumme_2.schriftart }" 
												/>

<b-popover 
												v-if="formular.bruttosumme_1"
												:show="formular.bruttosumme_1.showPop==true"
												
												ref='bruttosumme_1' 
												target='bruttosumme_1'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bruttosumme_1.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bruttosumme_1.size}}</span>
												<b-button @click='plus("bruttosumme_1", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bruttosumme_1", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="bruttosumme_1" 
																				v-bind:precision="parseInt(formular.bruttosumme_1.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.bruttosumme_1!=undefined" 
												class='textfeld' v-model='formular.bruttosumme_1.text' 
												style='width:100%' 
												@focus='formular.bruttosumme_1.showPop=true'
												@blur ='formular.bruttosumme_1.showPop=false'
												:disabled='formular.bruttosumme_1.isDisabled==true || formular.bruttosumme_1.isDisabled==true' 
												@keypress="isNumber($event, formular.bruttosumme_1.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(146.0975934) + 'px', 
		
														'font-size': formular.bruttosumme_1.size + 'px', 
														'text-align': formular.bruttosumme_1.schriftausrichtung, 
														'letter-spacing': formular.bruttosumme_1.letterSpacing + 'px',
														'font-weight': formular.bruttosumme_1.schriftart }" 
												/>

<b-popover 
												v-if="formular.skonto"
												:show="formular.skonto.showPop==true"
												
												ref='skonto' 
												target='skonto'  
												
												>
												<template slot='title'>
													<b-button @click="formular.skonto.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.skonto.size}}</span>
												<b-button @click='plus("skonto", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("skonto", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="skonto" 
																				v-bind:precision="parseInt(formular.skonto.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.skonto!=undefined" 
												class='textfeld' v-model='formular.skonto.text' 
												style='width:100%' 
												@focus='formular.skonto.showPop=true'
												@blur ='formular.skonto.showPop=false'
												:disabled='formular.skonto.isDisabled==true || formular.skonto.isDisabled==true' 
												@keypress="isNumber($event, formular.skonto.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 15.5 + 'px', 
														'top': gib_hoehe_in_mm(151.4304174) + 'px', 
		
														'font-size': formular.skonto.size + 'px', 
														'text-align': formular.skonto.schriftausrichtung, 
														'letter-spacing': formular.skonto.letterSpacing + 'px',
														'font-weight': formular.skonto.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(124.212899) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(129.545723) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(134.878547) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(140.211371) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(145.544195) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(150.877019) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(71.756815) + 'px', 
														top: gib_hoehe_in_mm(164.4750148) + 'px'}">
													Auftragssumme
													</div>
<b-popover 
												v-if="formular.bruttosumme_2"
												:show="formular.bruttosumme_2.showPop==true"
												
												ref='bruttosumme_2' 
												target='bruttosumme_2'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bruttosumme_2.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bruttosumme_2.size}}</span>
												<b-button @click='plus("bruttosumme_2", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bruttosumme_2", "-")' size='sm'>-</b-button>
											
											</b-popover><vue-numeric id="bruttosumme_2" 
																				v-bind:precision="parseInt(formular.bruttosumme_2.anzahlStellen)"
																				currency="" 
																				decimal-separator="," 
																				separator="." 
												v-if="formular.bruttosumme_2!=undefined" 
												class='textfeld' v-model='formular.bruttosumme_2.text' 
												style='width:100%' 
												@focus='formular.bruttosumme_2.showPop=true'
												@blur ='formular.bruttosumme_2.showPop=false'
												:disabled='formular.bruttosumme_2.isDisabled==true || formular.bruttosumme_2.isDisabled==true' 
												@keypress="isNumber($event, formular.bruttosumme_2.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.6977844) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.4028244) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(164.7892826) + 'px', 
		
														'font-size': formular.bruttosumme_2.size + 'px', 
														'text-align': formular.bruttosumme_2.schriftausrichtung, 
														'letter-spacing': formular.bruttosumme_2.letterSpacing + 'px',
														'font-weight': formular.bruttosumme_2.schriftart }" 
												/>

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(131.0499174) + 'px', 
														top: gib_hoehe_in_mm(164.3938938) + 'px'}">
													EUR
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(180.8039667) + 'px'}">
													Vertragsgrundlage bilden die im Angebot angegebenen Unterlagen sowie das Leistungsverzeichnis und ggf.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(184.6145375) + 'px'}">
													weitere Unterlagen, die Sie im Rahmen der Ausschreibung erhalten haben.
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(193.6140307) + 'px'}">
													Sollten Sie Ihrem Angebot allgemeine Geschäftsbedingungen, insbesondere Zahlungs und
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(197.4249542) + 'px'}">
													Lieferungsbedingungen beigegeben haben, werden diese nicht Gegenstand des Bauvertrages.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								@input="ergaenzendToogle"
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ergaenzende_vereinbarung_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(21.6000534) + 'px',top: gib_hoehe_in_mm(201.2814524) + 'px'}"
								v-model="formular.ergaenzende_vereinbarung_check.text" 
								value='1' 
								unchecked-value='0'> ergänzende Vereinbarungen
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(175.691285) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(21.119676) + 'px',
										
										 top: gib_hoehe_in_mm(205.4313206) + 'px'}"><b-popover 
												v-if="formular.ergaenzende_vereinbarung"
												:show="formular.ergaenzende_vereinbarung.showPop==true"
												
												ref='ergaenzende_vereinbarung' 
												target='ergaenzende_vereinbarung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ergaenzende_vereinbarung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ergaenzende_vereinbarung.size}}</span>
												<b-button @click='plus("ergaenzende_vereinbarung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ergaenzende_vereinbarung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.ergaenzende_vereinbarung!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.ergaenzende_vereinbarung.text"
												
												@focus='formular.ergaenzende_vereinbarung.showPop=true'
												@blur ='formular.ergaenzende_vereinbarung.showPop=false'
												
												:disabled="formular.ergaenzende_vereinbarung.isDisabled==true || formular.ergaenzende_vereinbarung.isDisabled=='true'"
												:style="{'font-size': formular.ergaenzende_vereinbarung.size + 'px',
														'height': formular.ergaenzende_vereinbarung.height + 'px',
														'text-align': formular.ergaenzende_vereinbarung.schriftausrichtung + 'px',
														'letter-spacing': formular.ergaenzende_vereinbarung.letterSpacing + 'px',
														'font-weight': formular.ergaenzende_vereinbarung.schriftart + 'px', }"

												id='ergaenzende_vereinbarung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(227.5515301) + 'px'}">
													Der Auftraggeber / Bauherr
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(165.7718216) + 'px', 
														top: gib_hoehe_in_mm(227.5515301) + 'px'}">
													Der Auftragnehmer
													</div>
<div :style="{
														fontSize:'12.363px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(245.5004331) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'12.363px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(21.6000534) + 'px', 
														top: gib_hoehe_in_mm(259.2606709) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.ort_datum_geber"
												:show="formular.ort_datum_geber.showPop==true"
												
												ref='ort_datum_geber' 
												target='ort_datum_geber'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_geber.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_geber.size}}</span>
												<b-button @click='plus("ort_datum_geber", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_geber", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_geber" type="text" 
												v-if="formular.ort_datum_geber!=undefined" 
												class='textfeld' v-model='formular.ort_datum_geber.text' 
												style='width:100%' 
												@focus='formular.ort_datum_geber.showPop=true'
												@blur ='formular.ort_datum_geber.showPop=false'
												:disabled='formular.ort_datum_geber.isDisabled==true || formular.ort_datum_geber.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_geber.onylNumber) " 
												:style="{'width': spalte_breit_mm(57.543839) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(21.8500534) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(254.9305851) + 'px', 
		
														'font-size': formular.ort_datum_geber.size + 'px', 
														'text-align': formular.ort_datum_geber.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_geber.letterSpacing + 'px',
														'font-weight': formular.ort_datum_geber.schriftart }" 
												/>

<b-popover 
												v-if="formular.ort_datum_nehmer"
												:show="formular.ort_datum_nehmer.showPop==true"
												
												ref='ort_datum_nehmer' 
												target='ort_datum_nehmer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_nehmer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_nehmer.size}}</span>
												<b-button @click='plus("ort_datum_nehmer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_nehmer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_nehmer" type="text" 
												v-if="formular.ort_datum_nehmer!=undefined" 
												class='textfeld' v-model='formular.ort_datum_nehmer.text' 
												style='width:100%' 
												@focus='formular.ort_datum_nehmer.showPop=true'
												@blur ='formular.ort_datum_nehmer.showPop=false'
												:disabled='formular.ort_datum_nehmer.isDisabled==true || formular.ort_datum_nehmer.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_nehmer.onylNumber) " 
												:style="{'width': spalte_breit_mm(57.5441917) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(139.9974994) + 'px', 
														'height': 18.5 + 'px', 
														'top': gib_hoehe_in_mm(254.9305851) + 'px', 
		
														'font-size': formular.ort_datum_nehmer.size + 'px', 
														'text-align': formular.ort_datum_nehmer.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_nehmer.letterSpacing + 'px',
														'font-weight': formular.ort_datum_nehmer.schriftart }" 
												/>

<div :style="{
														fontSize:'12.363px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(180.6219024) + 'px', 
														top: gib_hoehe_in_mm(245.5004331) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'12.363px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(181.2225505) + 'px', 
														top: gib_hoehe_in_mm(259.2606709) + 'px'}">
													Ort, Datum
													</div>
<div :style="{
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(42.1614053) + 'px', 
														top: gib_hoehe_in_mm(278.833052) + 'px'}">
													Diese Ausfertigung ist für den/die Auftraggeber(in) / Auftragnehmer(in) / Planer(in)
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.6960486) + 'px', 
													width: spalte_breit_mm(86.6269997) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.6815943) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(92.6218416) + 'px', 
													width: spalte_breit_mm(34.7494148) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(21.0782049) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(68.6244863) + 'px', 
													width: spalte_breit_mm(8.2373085) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(132.8202662) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(76.4875801) + 'px', 
													width: spalte_breit_mm(8.2373085) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(143.5409354) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(71.3593221) + 'px', 
													width: spalte_breit_mm(8.2376612) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(154.2097577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(21.6000534) + 'px', 
													width: spalte_breit_mm(58.043839) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(245.5604685) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.7474994) + 'px', 
													width: spalte_breit_mm(58.0441917) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(245.5604685) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(21.6000534) + 'px', 
													width: spalte_breit_mm(58.043839) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(259.3207063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(139.7474994) + 'px', 
													width: spalte_breit_mm(58.0441917) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(259.3207063) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.6960486) + 'px', 
													width: spalte_breit_mm(86.6269997) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(51.1137842) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(109.6960486) + 'px', 
													width: spalte_breit_mm(86.6269997) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(64.2846603) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(21.8152004) + 'px', 
													width: spalte_breit_mm(79.6414235) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(44.6815943) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.479875) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(281.2562158) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(289.7068694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(281.2562158) + 'px', 
													top: gib_hoehe_in_mm(8.5506536) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(120.8221176) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(173.7902509) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(81.6459651) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(18.805964) + 'px', 
													width: spalte_breit_mm(182.673911) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(221.4668261) + 'px'}"></div>
</div> 
	
	<div v-if="formular.ergaenzende_vereinbarung_check.text==1" style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;">
	
	  <b-button style="position:absolute; top: 0.0em; left:1em;
			box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
			backdrop-filter: blur( 12.0px );
			-webkit-backdrop-filter: blur( 12.0px );
			border-radius: 10px;
			border: 1px solid rgba( 255, 255, 255, 0.18 );" variant="outline-primary" id="show-btn" 
	  @click="$bvModal.show('vereinbarungen_choose')">Ergänzende Vereinbarungen wählen</b-button>


		<div style="border: 1px solid grey; position: absolute; left: 81px; width: 791px; height: 1px; top: 38px;"></div>
		<div style="border: 1px solid grey; position: absolute; left: 872px; width: 0px; height: 1219px; top: 38px;"></div>
		<div style="border: 1px solid grey; position: absolute; left: 81px; width: 791px; height: 1px; top: 1255px;"></div>
		<div style="border: 1px solid grey; position: absolute; left: 81px; width: 0px; height: 1219px; top: 38px;"></div>

<div style="position:absolute; top: 50px; left:90px; width: 780px; text-decoration: underline; font-size:1.1rem;">
Ergänzende Vereinbarung zum Bauvertrag:
</div>

			<div style="position:absolute; top: 90px; left:90px; width: 780px;">

				<b-row  :key="index" v-for="(ergaenzung, index) in formular.ergaenzungen.output">
					
					<b-col cols="1">
						{{index+1}}.
					</b-col>  
					
					<b-col cols="11">
						{{ergaenzung.text}}
					</b-col> 
					
				</b-row>

				<div style="float:left;">Unterschrift</div>
				<div style="float:right;">Unterschrift</div>

			</div>

	</div>
	
	<b-modal v-if="formular.ergaenzungen!=undefined" size="lg" id="vereinbarungen_choose" hide-footer>
		<template v-slot:modal-title>
		 Ergänzende Vereinbarungen
		</template>

		<div>
			<b-row>
				<b-col cols="12">
					<!-- {{formular.ergaenzungen}} -->
					{{formular.ergaenzungen.arr}}
					<b-form-group label="zur Verfügung stehen:">
					<b-form-checkbox-group
					v-model="formular.ergaenzungen.arr"
					:options="options_ergaenzungen"
					@input="changeErgaenzungen()"
					name="flavour-2a"
					stacked
					></b-form-checkbox-group>
					</b-form-group>
				</b-col>
			</b-row>
		</div>

		<b-button @click="$bvModal.hide('vereinbarungen_choose')">schließen</b-button>
  </b-modal>
  
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "auftraggeber": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName<nl>bauherr.adresse.strasse<nl>bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 53.264754,
            "top": 31.881405900000004,
            "right": 100.7759129,
            "bottom": 43.811483400000014,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bezeichnung_bauvorhaben": {
        "text": "",
        "datenuebernehmen": "bezeichnung_bauvorhaben",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 135.7820933,
            "top": 31.881405900000004,
            "right": 195.7689566,
            "bottom": 43.811483400000014,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "auftragnehmer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 53.264754,
            "top": 51.19561059999998,
            "right": 100.7759129,
            "bottom": 63.12568809999999,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "projektnummer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 135.7820933,
            "top": 45.49386240000001,
            "right": 195.7689566,
            "bottom": 49.66559799999999,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "planung": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName<nl>entwurfsverfasser.adresse.strasse<nl>entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 135.7820933,
            "top": 51.69714999999999,
            "right": 195.7689566,
            "bottom": 63.627227500000004,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "objektueberwachung": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName<nl>entwurfsverfasser.adresse.strasse<nl>entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 51,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 135.7820933,
            "top": 65.42564479999999,
            "right": 195.7689566,
            "bottom": 77.3557223,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "datum_angebot": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "center",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 45.1960361,
            "top": 83.34668450000001,
            "right": 64.45874660000001,
            "bottom": 88.0763915,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gewerk": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "16",
        "schriftausrichtung": "center",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "bold",
        "isDisabled": "true",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 81.78125440000001,
            "top": 90.90716170000002,
            "right": 135.5863448,
            "bottom": 95.0566772,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "vertagsart": {
        "text": null,
        "size": "16"
    },
    "nettosumme_1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "neurechnen",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 124.51629739999998,
            "right": 129.3506088,
            "bottom": 128.0023842,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "nachlass_prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "center",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "neurechnen",
        "art": "_e",
        "coords": {
            "left": 67.0581456,
            "top": 128.83334539999998,
            "right": 76.8617948,
            "bottom": 132.8202662,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "nachlass": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 129.8491214,
            "right": 129.3506088,
            "bottom": 133.33520819999998,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "umsatzsteuer_prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "center",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "neurechnen",
        "art": "_e",
        "coords": {
            "left": 74.9039571,
            "top": 140.4706819,
            "right": 84.7248886,
            "bottom": 143.5409354,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "ust": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 140.5147694,
            "right": 129.3506088,
            "bottom": 144.0008562,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "skonto_prozent": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "center",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "neurechnen",
        "art": "_e",
        "coords": {
            "left": 69.7841639,
            "top": 151.1282178,
            "right": 79.5969833,
            "bottom": 154.2097577,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "nettosumme_2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 135.1819454,
            "right": 129.3506088,
            "bottom": 138.6680322,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bruttosumme_1": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 145.8475934,
            "right": 129.3506088,
            "bottom": 149.3336802,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "skonto": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 151.1804174,
            "right": 129.3506088,
            "bottom": 154.6665042,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "bruttosumme_2": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "14",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": "2",
        "schriftart": "bold",
        "isDisabled": "false",
        "zahl": true,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 106.1528244,
            "top": 164.53928259999998,
            "right": 129.3506088,
            "bottom": 168.3406832,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "ergaenzende_vereinbarung_check": {
        "text": "0",
        "size": "14",
        "sichtbar": "false"
    },
    "ergaenzende_vereinbarung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "14",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_m",
        "coords": {
            "left": 21.119676000000002,
            "top": 205.4313206,
            "right": 197.310961,
            "bottom": 214.5923504,
            "typ": "'textarea'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "ort_datum_geber": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "12",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 21.6000534,
            "top": 254.6805851,
            "right": 79.64389240000001,
            "bottom": 258.830806,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "ort_datum_nehmer": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "12",
        "schriftausrichtung": "right",
        "letterSpacing": "normal",
        "sichtbar": "false",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "art": "_e",
        "coords": {
            "left": 139.7474994,
            "top": 254.6805851,
            "right": 197.7916911,
            "bottom": 258.830806,
            "typ": "'text'",
            "pageNo": "1"
        },
        "showPop": false
    },
    "gewerkID": null,
    "ergaenzungen": {
        "output": [],
        "arr": []
    },
    "ergaenzend": "Eine von beiden Vertragsparteien unterschriebene \"Erg\u00e4nzende Vereinbarung zum Bauvertrag\" ist angeheftet und Vertragsbestandteil."
}
var formulaName = "bauauftrag"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 	options_ergaenzungen : [
				{value:"1", text:"Grundlage des Auftrages, der Ausführung und der Abrechnung ist die VOB, neueste Fassung."},
				{value:"2", text:"Für die Dauer von 4 Jahren behält sich der Bauherr die Möglichkeit offen, 5 % der Abrechnungssumme als Sicherheit einzubehalten. Dieser Sicherheitseinbehalt kann gegen Vorlage einer Bankbürgschaft ausgezahlt werden. "},
				{value:"3", text:"Die Vorbemerkungen des Angebotes, sowie die Leistungsbeschreibung sind vollinhaltlich Vertragsbestandteil."},
				{value:"4", text:"Sollte sich während der Durchführung der Bauarbeiten herausstellen, dass Sonderleistungen, die nicht im Leistungsverzeichnis beschrieben sind, erforderlich werden, so ist die Bauleitung sofort zu verständigen. Vor Ausführung dieser Arbeiten ist ein Preis zu vereinbaren. Weiterhin sind evtl. vom Bauherrn verlangte Mehr- oder Minderleistungen auf Basis der bestehenden Einheitspreise fortzuschreiben, so dass die neue Auftragshöhe ständig bekannt ist. Diese Arbeiten sind erst nach erfolgtem schriftlichem Auftrag auszuführen."},
				{value:"5", text:"Rechnungen und Abschlagszahlungen werden in einfacher Ausführung an den Auftraggeber erbeten. Darüber hinaus wird der Versand der Rechnung per E-Mail an den Bauleiter erbeten. Die Abschlagszahlungen orientieren sich am Baufortschritt. Es werden Abschlagszahlungen nach Fertigstellung einzelner Bauabschnitte, unter Berücksichtigung der Sicherheitsbeträge (5 % ) fällig."},
				{value:"6", text:"Die Kosten für Wasser, Strom und Toilette müssen grundsätzlich vom Auftragnehmer getragen werden. Eine entsprechende Umlage (0,30 %) wird nach Abschluss der Arbeiten bei Rechnungslegung in Abzug gebracht."},
				{value:"7", text:"Die Baustelle ist aufgeräumt nach Ausführung der Arbeiten zu übergeben. Etwa anfallender Schutt von Verpackungsmaterialien, Baustoffen, Abbruch o.ä. ist abzutransportieren. Sollte nicht eindeutig zu ermitteln sein, welchem Gewerk Schutt etc. zuzuordnen ist, werden grundsätzlich die anfallenden Kosten für die Entsorgung auf alle beteiligten Firmen umgelegt."},
				{value:"8", text:"Es wurde ein Nachlass in Höhe von 2% vereinbart."},
				{value:"9", text:"Es wurde ein Skonto von 3% vereinbart, bei Zahlung innerhalb von 8 Kalendertagen nach Rechnungseingang."},
				{value:"10", text:"Sämtliche während der Bauzeit bzw. Laufzeit bis zur Fertigstellung Ihres Gewerkes anfallenden Materialpreis- und Lohnerhöhungen sind mit den Einheitspreisen Ihres Angebotes abgegolten und können nicht gesondert nachberechnet werden."},
				{value:"11", text:"Die Arbeiten haben sich an den Erfordernissen der Gesamtbaustellenabwicklung und den Weisungen der Bauleitung zu orientieren. Im Sinne eines reibungslosen Ablaufs der Arbeiten sind entsprechenden Anordnungen der Bauleitung Folge zu leisten. Mehr- oder Minderungen der Massen lt. Leistungsverzeichnis berechtigen nicht zur vereinbarten Vergütungsänderung."},
				{value:"12", text:"Alle auszuführenden Leistungen sind mit den Planunterlagen auf Übereinstimmung zu prüfen, evtl. Abweichungen sind mit der Bauleitung oder dem Fachplaner zu klären."},
				{value:"13", text:"Ausführungsfristen entsprechend VOB B § 5."}
				],
		 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
 
	changeErgaenzungen(){

			this.formular.ergaenzungen.output = [];

			for(var prop of this.formular.ergaenzungen.arr){
				console.log(prop)
				var index_trg = this.options_ergaenzungen.findIndex(item=>item.value == prop)
				console.log(index_trg)
				if(index_trg>=0)
				this.formular.ergaenzungen.output.push(this.options_ergaenzungen[index_trg])

			}
		},

		ergaenzendToogle(event){

					if(this.formular){
						if(event==1)
							this.formular.ergaenzende_vereinbarung.text = this.formular.ergaenzend;
						else
							this.formular.ergaenzende_vereinbarung.text = "";
					}

				console.log(event)
		},

		neurechnen(event){

					var nachlass = this.formular.nettosumme_1.text * this.formular.nachlass_prozent.text/100

					console.log(nachlass)

					this.formular.nachlass.text = nachlass;
		
					this.formular.nettosumme_2.text = this.formular.nettosumme_1.text-nachlass;

					var umsatzsteuer = this.formular.nettosumme_2.text * this.formular.umsatzsteuer_prozent.text/100
					this.formular.ust.text = umsatzsteuer;

					this.formular.bruttosumme_1.text = this.formular.nettosumme_2.text+umsatzsteuer;

					var skonto = this.formular.bruttosumme_1.text * this.formular.skonto_prozent.text/100
					this.formular.skonto.text = skonto;
					this.formular.bruttosumme_2.text = this.formular.bruttosumme_1.text-skonto;

		},



		},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>