
<template>

  <div id="app">

    <b-sidebar :backdrop="true" id="sidebar-1" title="weitere Funktionen" shadow>

      <!-- <div class="mt-2">

		<span v-b-toggle.sidebar-1><div 
		style=" 
				position:absolute;
				left:15px;
				top:7px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 18px 23px 18px 0;
				border-color: transparent #888888 transparent transparent;"></div></span>
		</div> -->
      <div class="mt-2 px-3 py-2 ">

			<b-button variant="outline-primary" :to="'/sharescreen'" >Online Meeting, externe Teilnehmer</b-button>
		<!-- <router-link :to="{path: '/sharescreen'}" >Online Meeting</router-link> -->

    
			<div style="font-weight:700; font: size 1.5em;" class="mt-4 text-center">zuletzt geöffnete Projekte:</div>

			<ul v-if="store.projektEinstellungen.main!=undefined" class="mt-2 text-center">

				<li class="ml-3 mb-1 button-weite" v-bind:key="index" v-for="(projekt, index) in store.projektEinstellungen.main.letzteAufgerufen">
						<b-button style="width:100%;" variant="outline-primary" @click="loadProject(projekt.id, 'projekte')" >
							{{truncateString(projekt.name,28)}}
						</b-button>
				</li>
			</ul>

			<b-button variant="outline-primary" :to="'/ifcViewer'" >IFC - Viewer</b-button>




      </div>

  <!-- 
        <p>
				
			this.$set(this.Addressbook[index_trgKontakt].mitgabe, liste.target.value, {'adresse':default_address, 'welcherName':null, 'verbundenMit':null, 'vorname':null, 'name':null, 'anrede':null, 'telefon':null, 'email':null});


			Zeiterfassung
        </p> -->

    </b-sidebar>


 <b-sidebar id="sidebar-right" title="Sidebar" right shadow>
      <div class="px-3 py-2">
        <p>
ToDo
        </p>
    
      </div>
    </b-sidebar>

	<b-modal ref="login">

	</b-modal>

	<app-header v-if="!$route.path.includes('loginExtern')" />
  <!-- <b-button v-b-toggle.sidebar-right>Toggle Sidebar</b-button> -->

    <router-view  />
<!-- :key="$route.fullPath" -->
	<app-footer v-if="!itsMobile && !$route.path.includes('loginExtern')" />

  </div>
</template>


<script>


// var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// var type = connection.effectiveType;

// function updateConnectionStatus() {
//   console.log("Connection type changed from " + type + " to " + connection.effectiveType);
//   type = connection.effectiveType;
// }

// connection.addEventListener('change', updateConnectionStatus);


// Imports
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'

import { projectMixin } from '@/mixins/projectMixin.js'

export default {

	mixins: [projectMixin],

    components: {
        'app-header': Header,
        'app-footer': Footer,
	},



	methods: {

		truncateString(str, num) {
		// If the length of str is less than or equal to num
		// just return str--don't truncate it.
		if (str.length <= num) {
			return str
		}
		// Return str truncated with '...' concatenated to the end of str.
		return str.slice(0, num) + '...'
		},

		stayConnected() {

		this.axios.get(this.$store.state.url_getloggin,
			{
				  params: {
				  }
			  })
	  
		  .then((response) => {

	  
		  })
		},
	},

	computed: {
		    projektEinstellungen:
    {
        get()
        {
            return this.$store.getters.projektEinstellungen
        },
        set(value)
        {
            this.$store.commit('setprojektEinstellungen', value);
        }
    },

		itsMobile:
		{
			get()
			{
				return this.$store.getters.itsMobile
			},
			set(value)
			{
				this.$store.commit('setitsMobile', value);
			}
		}, 

		bearbeiten:
		{
			get()
			{
				return this.$store.getters.bearbeiten
			},

			set(value)
			{
				this.$store.commit('changeBearbeiten', value);
			}
		},

		// letzteProjekte(){
		// 	var returnVal = []


		// 	for(var mst of this.store.projektEinstellungen.main.letzteAufgerufen) {
		// 		mst.name = this.truncateString(mst.name, 40)
		// 		returnVal.push(mst)
		// 		//console.log(mst)
		// 	}
			
		// 	return returnVal;
		// },

		store() {
			return this.$store.state;
		},

	},
	
	
	mounted() {
		


		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			this.itsMobile = true
		  } else {
			this.itsMobile = false
		  }



 		//console.log(this.itsMobile)

		this.$root.$on('login_modal', data => {
			this.$refs['login'].show()
				// console.log("open")
		})

		window.setInterval(() => {
			this.stayConnected()
		}, 500000)

	},
	
	created(){


	},



}

	Object.compareObjects = function (obj1, obj2) {


		if(JSON.stringify(obj1) == JSON.stringify(obj2)===true)
		return true
		else 
		return false
	};

</script>

<style scoped>

	.button-weite li {
		width:1020px;
	}
</style>


<style>


 /* Import Font Awesome Icons Set */

  /* $fa-font-path: '~font-awesome/fonts/'; */
  @import '~font-awesome/css/font-awesome.css';

    .kontainer {
        width:90%;
        margin: 0px 5%;
    	margin: 30px auto;
		margin-bottom: 34px;
		min-height: 100px;
		height: calc(100% - 200px);
        }

    .abstand_nach_unten_25 {
    	margin-bottom: 25px;
        }
	.abstand_nach_unten_50 {
    	margin-bottom: 50px;
        }
    .abstand_nach_oben_25 {
    	margin-top: 25px;
        }
	.abstand_nach_oben_50 {
    	margin-top: 50px;
        }
	.rechtsbuendig{
			float:right;
		}

.h4 {
  font-family: "SourceSansProSemi" !important;
  /* font-size: 200px !important;
  font-weight: normal;
  color: #222222; */
}

.h5 {
  font-family: "SourceSansPro" !important;
  /* font-size: 200px !important;
  font-weight: normal;
  color: #222222; */
}

*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, label, fieldset, input, p, blockquote, th, td { margin:0; padding:0 }
table { border-collapse:collapse; border-spacing:0 }
fieldset, img { border:0 }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal }
ol, ul, li { list-style:none }
caption, th { text-align:left }
h1, h2, h3, h4, h5, h6 { font-size:100%; font-family:'SourceSansProSemi' }
q:before, q:after { content:''}
/* Global reset-RESET */
strong { font-weight: bold }
em { font-style: italic }
a img { border:none } /* #D0EF8C Gets rid of IE's blue borders */
body#layout #header {
margin-bottom: 80px;
clear:both;
}

html {height: 100.5%; }

@font-face {
	font-family: 'Open Sans'; 
	src: url('../fonts/OpenSans-Regular.ttf') format('truetype'); 
	}

@font-face {
  font-family: 'SourceSansProSemi';
  src: url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}


#layout:before {    content: 'This is the property of ThemeXpose.com';
text-transform: uppercase;
font-size: 20px;
color: #999;
margin: 0 0 20px 0;
display: inline-block;
font-weight: 700;
padding: 20px;
font-family: sans-serif;
border: 4px double #FD856E;}
#Attribution1 {height:0px;visibility:hidden;display:none;}
#b-navbar {height:0px;visibility:hidden;display:none;}
.widget-item-control {display:none;}
body#layout {
width: 1000px;
}


body#layout #navigation {
height: auto;
margin-bottom: 60px;
margin-top: 30px;
position: relative;
width: 100%;
z-index: 999;
}
body#layout div#navigation-wrapper {
width: 42%;    float: left;
}
body#layout #top-social {
height: auto;
width:42%;    float: right;
}
body#layout #widget-area .footerwidget {
width:30%;
margin-right: 0px;
}
body#layout #sidebar .widget {
width:100%;
}
body#layout div#main {
width: 60%;
}
.preload{display:none!important}

body {
color: #343434;
font:normal normal 14px Open Sans;

letter-spacing: 0px;
text-transform: none;    font-weight: 400;
background:#ffffff;
}
p {
font-size:14px;
}
a {
text-decoration:none;
color:#337AB7;
font-weight: 600;
}
a:hover {
color:#04B404
}

h1,h2,h3,h4,h5,h6 {
font-family:"Open Sans", sans-serif;
}

#main {
width:100%;
}



ul.children { margin: 0 0 0 55px; }
ul.children li.thecomment { margin:0 0 8px 0; }

.post-entry blockquote p { border-left:3px solid #cea525; }

#main .widget {
width: 100%;
}
#navigation .widget ul li {
border:none;
margin-bottom:0px;
padding-bottom:0px;
}
#navigation .widget ul li:last-child:after {
content: "";
}
#navigation .widget a:hover {
text-decoration: none;
}

.container.sp_sidebar {
overflow: hidden;
margin: 0px auto;
padding-left:10px;
}


.meta-date {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: #9e9e9e;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.meta-date a {
      color: #aaaaaa;
font-weight:400;
}


@media only screen and (min-width: 961px) and (max-width: 1080px) {

article:nth-child(2n+2) {
    margin-right: 29px;
}

#sidebar .widget {
      width: 100%;}
  }


/*************************************************
*	10. Tablet									 *
*************************************************/
@media only screen and (min-width: 768px) and (max-width: 960px) {

	#navigation-wrapper {
		display:none;
	}
	
	#logo img {
		max-width:726px;
		height:auto;
	}

	.post-image-new img {
		width:726px;
		height:auto;
	}
	
	.post-image-new.audio iframe {
		width:726px;
		height:auto;
	}
	
	.item-related {
		width:230px;
		margin-right:17px;
	}
	
	.post-pagination .prev-post {
		width:383px;
	}
	
	.post-pagination .next-post {
		width:383px;
	}
	
	 #sidebar .widget {
		width:230px;
		margin-right:17px;
	}
	
	 #sidebar .widget img {
		max-width:230px;
		height:auto;
	}
	
	 #sidebar .widget iframe {
		max-width:230px !important;
		height:auto;
	}

	.container.sp_sidebar #main {
		width:99%;
		
	}
	


	
}

/*************************************************
*	Mobile Portrait								 *
*************************************************/

@media only screen and (max-width: 767px) {

	#navigation-wrapper {
		display:none;
	}

	#logo img {
		max-width:280px;
		height:auto;
	}
	
	.post-header h1 a, .post-header h2 a, .post-header h1 {
		font-size:22px;
		letter-spacing:2px;
	}


}


/*************************************************
*	10. Landscape								 *
*************************************************/

@media only screen and (min-width: 480px) and (max-width: 767px) {
	
	#sidebar {
	 
		float: left;
	}
	
	#logo img {
		max-width:480px;
		height:auto;
	}

	.post {
		width: 100%;
	}

}

</style>