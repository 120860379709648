import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

if(window.location.hostname=='localhost'){
  var base_url = 'https://www.archi-komplett.de/api/php/'
  var base_url = 'https://www.archi-app.de/api/php/'
  var base_url = 'https://account.archi-app.de/api/php/'
}
else{
  var base_url = 'https://'+ window.location.hostname +'/api/php/'
}

  //var base_url = 'https://archi-app.com/api/php/'

export default new Vuex.Store({
    state: {

      // base_url:base_url,
      dev_sub : "", // production
      //dev_sub : "jd893noxie2d9832d328dj3298rvg58f73021", // develope
      

        url_benutzer:                 base_url + 'benutzer.php',  
        url_listen:                   base_url + 'listen.php',        
        url_get_rooms:                base_url + 'get_rooms.php',    
        dateien_upload:               base_url + 'dateien_upload.php',
        dateien_abfrage:              base_url + 'dateien_abfrage.php',
        datei_direkt_dateien_create:  base_url + 'datei_direkt_dateien_create.php',
        datei_direkt_download:        base_url + 'datei_direkt_download.php',
        url_wissen:                   base_url + 'wissen.php',
        url_subnav :                  base_url + 'get_subnav.php',
        url_create_new_sabre_DB :     base_url + 'create_new_sabre_DB.php',
        url_login_script :            base_url + 'login_script.php',
        url_getloggin :               base_url + 'get_log_status.php',
        url_log :                     base_url + 'set_log.php',
        url_getdav :                  base_url + 'get_dav.php',
        url_cal_dav :                 base_url + 'cal_dav.php',
        url_get_kontakte :            base_url + 'get_kontakte.php', 
        url_writeDav :                base_url + 'writeDav.php',
        url_getprojekte :             base_url + 'get_projekte.php',    
        // url_get_einzel_project :      base_url + 'get_einzel_project_alles.php',
        url_get_formulare:            base_url + 'get_formular.php',
        url_set_formulare:            base_url + 'set_formular.php',
        url_set_project:              base_url + 'set_project.php',
        url_set_kontakt:              base_url + 'setKontakt.php',
        url_check_verbindung:         base_url + 'check_verbindung.php',
        //url_get_personen:             base_url + 'get_personen.php',
        url_createFormularPDF:        base_url + 'formularPDF/make_new_pdf.php',
        url_createWeihnachtsPDF:      base_url + 'formularPDF/make_new_weihnachtskarte.php',
        url_getkunden:                base_url + 'get_kunden.php',
        url_einstellungen_mitarbeiter:base_url + 'einstellungen_mitarbeiter.php',
        url_getFormularPDF:           base_url + 'formularPDF/get_pdf.php',
        url_getProject:               base_url + 'getProject.php',
        url_getEinstellungen:         base_url + 'getEinstellungen.php',
        url_setEinstellungen:         base_url + 'setEinstellungen.php',
        url_get_welcher_calendar:     base_url + 'get_welche_calendar.php',
        url_getAuswahllisten:         base_url + 'getAuswahllisten.php', 
        
        url_datei_download:           base_url + 'datei_download.php',
        url_logout:                   base_url + 'logout.php',
        url_projektEinstellungen:     base_url + 'projektEinstellungen.php',

        url_cardObject :          'https://dav.archi-app.de/cardObject.php',
        url_calObject :           'https://cal.archi-app.de/calObject.php',
        //url_calObject :           'https://dav.archi-app.de/calObject.php',

        url_get_post_userID_abfrage:  base_url + 'userID_abfrage.php',
        url_save_new_bv:              base_url + 'save_projekte.php',
        

        daily: {choice:null, interval:null},
        byweekday: null,
        repeatIntervalType: null,
        repeatIntervalValue: 1,
        repeatIntervalText: null,
        repeatIntervalPanel: null,
        rruleWeeklyPanel: null,
        rruleWeeklyButtons: null,
        rruleMonthlyPanel: null,
        rruleMonthlyDateMonthDay: null,
        rruleMonthlyDayMonthDayPos: null,
        rruleMonthlyDayMonthDays: null,
        rruleYearlyPanel: null,
        rruleYearlyDateMonth: null,
        rruleYearlyDateMonthDay: null,
        rruleYearlyDayMonthDays: null,
        rruleYearlyDayMonthDayPos: null,
        rruleYearlyDayMonth: null,
        rruleEndAction: null,
        rruleEndSessionsCount: null,
        rruleEndDate: null,
        rruleEndSessionPanel: null,
        rruleEndDatePanel: null,
        rruleResult: null,
        endTypes:[
          {
            "text": "nie"
          },
          {
            "text": "nach x Mal"
          },
          {
            "text": "an Datum"
          },
        ],
        repeatTypes: [
            //   {
            //     "type": "hourly",
            //     "unit": "Stunde",
            //     "label": "stündlich"
            // },
            {
                "type": 3,
                "unit": "Tag",
                "label": "täglich"
            },
            {
                "type": 2,
                "unit": "Woche",
                "label": "wöchentlich"
            },
            {
                "type": 1,
                "unit": "Monat",
                "label": "monatlich"
            },
            {
                "type": 0,
                "unit": "Jahr",
                "label": "jährlich"
            },


        ],
// status = gibt es das noch
      statusListe: [
          { value: 1, text: 'aktiv' },
          { value: 2, text: 'dauerhaft geschlossen' },
          { value: 3, text: 'Ruhestand' },
          { value: 4, text: 'verstorben' },
          { value: 5, text: 'unbekannt' },
      ],

      statusListeKey: 
        {1: 'aktiv' ,
         2: 'dauerhaft geschlossen' ,
         3: 'Ruhestand' ,
         4: 'verstorben' ,
         5: 'unbekannt' }
    ,
        anreden: [
          { value: null, text: 'Bitte wählen' },
          { value: 'Frau', text: 'Frau' },
          { value: 'Herr', text: 'Herr' },
          { value: 'Familie', text: 'Familie' },
          { value: 'Eheleute', text: 'Eheleute' },
      ],
    
      zugriffVorlagen:'',
      gewaehlteVorlage:'',
    
      activeCalendarEvent:{

      },

    ownDAV:{},
      admin:false, 
      artArray:[],
      kindArray:[],
      statusArray:[],
      bv_typArray:[],

      showInaktivTabelle:false,
    searchTermKontakte:'',
    mitarbeiterKontaktID:'',
    ehemMitarbeiterKontaktID:'',
    Mehrfachauswahl:false,
    glowFormularBearbeiten:null,
    value: 0,
    subnav:'',
    url_switch: 'dev',
    auswahlListen:'',
    actualformular:null,
    formular:{},
    userSelbst:[],
		logObject:{loggedIn: false},
    actualProject:{id: null, name:null},
    projectDatenBackup:null,


		bearbeiten:false,
    activePerson: null,
    KompletteCompanyBenutzer:{},
    EditBenutzer:{},
    coords: [],
    allgemeinesAdressBuch: [],
    BauherrenListe: [],
    BauaufsichtenListe: [],
    PlanerListe: [],
    BauleiterUndPlanerListe: [],
    kindIN:'2,3',
    kontaktHighlight:'',
    KontaktDetail:[{}],
    KontaktDetail_backup:[],
    Addressbook:[],
    Papierkorb:[],
    adressbookSelection:[],
    loader_loading:false,
    meineFirma:'',
    meineBenutzerID:'',
    BauvorlageberechtigtListe: [],
    currentTabComponent:null,
		toSaveArray:[],
    itsMobile:false,
    pdf_vorhanden:'',
    pdfListe:null,
    options_formulare_array:{},

    options_formulare_neu: [
      { value: 'keins', text: 'bitte wählen' },
      //{ value: 'antrageinfach2018', text: 'Einfaches Baugenehmigungsverfahren', blanc:'' },

      // { value: 'NRWbauantrag64', text: 'Einfaches Baugenehmigungsverfahren § 64', blanc:'' },
      { value: 'NRWbauantrag64_2022', text: 'Einfaches Baugenehmigungsverfahren § 64', blanc:'' },
      { value: 'NRWbauantragSonderbau65', text: 'Baugenehmigungsverfahren großer Sonderbau § 65', blanc:'' },
      { value: 'NRWbaubeschreibung', text: 'Baubeschreibung (Anlage I-7 zur VV BauPrüfVO)', blanc:''},
      { value: 'NRWBetriebsbeschreibungGew', text: 'Betriebsbeschreibung Gewerbe (Anlage I-8 zur VV BauPrüfVO)', blanc:''},
      { value: 'NRWBetriebsbeschreibungLandw', text: 'Betriebsbeschreibung für land- und forstwirtschaftliche Vorhaben (Anlage I-9 zur VV BauPrüfVO)', blanc:''},
      { value: 'NRWStatistik', text: 'Statistik Formular NRW', blanc:'' },
      { value: 'NRWFreistellung', text: 'Freistellung Formular NRW', blanc:'' },
      { value: 'bauauftrag', text: 'Bauauftrag', blanc:'' },
      { value: 'vollmachtWeb', text: 'Vollmacht Akteneinsicht', blanc:'' },
      { value: 'NRWAntragaufAbweichung', text: 'Antrag auf Abweichung', blanc:'' },      
      
      // {
      //   label: 'NRW',
      //   options: [

      //   ],
      // },

      // { value: 3, text: 'Genehmigungsfreistellung' },
      // { value: 4, text: 'Bauantrag Werbeanlagen' },
      // { value: 5, text: 'Grundstücksteilung'},
      // { value: 6, text: 'Antrag auf Abbruchgenehmigung'},
       
      
      // { value: 8, text: 'Betriebsbeschreibung Gewerbe'},
      // { value: 'betriebsbeschreibung_land', text: 'Betriebsbeschreibung Land-/ Forstwirtschaft'},
      //  { value: 'bauauftrag', text: 'Bauauftrag', blanc:''},
      
      // { value: 10, text: 'Statistikbogen Bauabgang'},
      // { value: 11, text: 'Vollmacht zum Bauantrag'},
    ],

    selected_form:'keins',

    options_formulare_vorhanden:[],
    selected_exist_form:'keins',

    offenes_kontakt_modal:[],
    offenes_kontakt_modal_backup:null,
    urlKontaktID:null,
    popoverShowProSpeichern:false,
    alleProjekte:[],

    glowSpeichern:false,
    selectedAddressbook:[],
    selectedTrash:[],
    showFontPopover:'',
    // preferredBeteiligte:'',

    eventSources: [],
    zugangsdaten:'',
    kontaktArt: [],
    backUpContacts:[],
    navi:[],
    projektBeteiligteOnOpen:'',

    shareScreenStore:{

      benutzerGewaehlt:[],

    },
    meineNavigation:[1],
    navigation:
    [
      {
           "navID":"1",
           "ordnerName":"Übersicht",
           "link":"/uebersicht",
           "subnav":[]
      },
      {
           "navID":"2",
           "ordnerName":"Projekte",
           "link":"/projekte",
           "subnav":[
              {
                 "unterordnerName":"Stammdaten",
                 "link":"projekte/stammdaten",
                 "navID":"3",
              },
              {
                 "unterordnerName":"Dateien",
                 "link":"projekte/dateien",
                 "navID":"4",
              },
              {
                 "unterordnerName":"Kosten",
                 "link":"projekte/kosten",
                 "navID":"5",
              },
              {
                 "unterordnerName":"Honorar",
                 "link":"projekte/honorar",
                 "navID":"6",
              },
              {
                 "unterordnerName":"Formular",
                 "link":"projekte/formular",
                 "navID":"7",
              },
              {
                 "unterordnerName":"Berechnungen",
                 "link":"projekte/berechnungen",
                 "navID":"8",
  
              },
              {
                 "unterordnerName":"Briefe",
                 "link":"projekte/briefe",
                 "navID":"9",
              },
              {
                 "unterordnerName":"Ausschreibung",
                 "link":"projekte/ausschreibung",
                 "navID":"10",
              },
              {
                 "unterordnerName":"Bautagebuch",
                 "link":"projekte/bautagebuch",
                 "navID":"11",
              },
              {
                 "unterordnerName":"Fotos",
                 "link":"projekte/fotos",
                 "navID":"12",
              },
              {
                 "unterordnerName":"Aufgaben",
                 "link":"projekte/aufgaben",
                 "navID":"13",
              },
              {
                 "unterordnerName":"Bauablaufplan",
                 "link":"projekte/bauablaufplan",
                 "navID":"14",
              },
              {
                 "unterordnerName":"Beteiligte",
                 "link":"projekte/ProjektBeteiligte",
                 "navID":"15",
              },
              {
                 "unterordnerName":"Ordnerruecken",
                 "link":"projekte/ordnerruecken",
                 "navID":"16",
              },
              {
                 "unterordnerName":"Gewerke",
                 "link":"projekte/gewerke",
                 "navID":"17",
              },
              {
                 "unterordnerName":"Meeting",
                 "link":"projekte/meeting",
                 "navID":"18",
              },
              {
                 "unterordnerName":"Modell",
                 "link":"projekte/modell",
                 "navID":"19",
              }
           ]
        },
        {
           "navID":"20",
           "ordnerName":"Adressbuch",
           "link":"/adressbuch",
           "subnav":[]
        },
        {
           "navID":"21",
           "ordnerName":"Kalender",
           "link":"/kalender",
           "subnav":[]
        },
        {
           "navID":"22",
           "ordnerName":"Einstellungen",
           "link":"/einstellungen",
           "subnav":[
              {
                 "unterordnerName":"Unternehmen",
                 "link":"einstellungen/unternehmen",
                 "navID":"23",
              },
              {
                 "unterordnerName":"Mitarbeiter ",
                 "link":"einstellungen/mitarbeiter",
                 "navID":"24",
              },
              {
                 "unterordnerName":"mein Profil",
                 "link":"einstellungen/meinProfil",
                 "navID":"25",
              },
              {
                 "unterordnerName":"mein Projekte",
                 "link":"einstellungen/meineProjekte",
                 "navID":"26",
              },
              {
                 "unterordnerName":"meine Einstellungen ",
                 "link":"einstellungen/meineEinstellungen",
                 "navID":"27",
              },
              {
                 "unterordnerName":"Zugriffsrechte",
                 "link":"einstellungen/zugriffsrechte",
                 "navID":"28",
              },
              {
                 "unterordnerName":"Serienbriefe",
                 "link":"einstellungen/serienbriefe",
                 "navID":"29",
              },
              {
                 "unterordnerName":"Weihnachtskarten",
                 "link":"einstellungen/weihnachtskarten",
                 "navID":"30",
              },
              {
                 "unterordnerName":"Sabre Manager",
                 "link":"einstellungen/sabre",
                 "navID":"31",
              },
              {
                 "unterordnerName":"Kalender",
                 "link":"einstellungen/kaleinstellungen",
                 "navID":"32",
              },
              {
                 "unterordnerName":"Admin",
                 "link":"einstellungen/admin",
                 "navID":"33",
              },
              {
                 "unterordnerName":"Auswahllisten",
                 "link":"einstellungen/auswahl",
                 "navID":"34",
              }
           ]
        },
        {
         "navID":"35",
         "ordnerName":"Wissen",
         "link":"/wissen",
         "subnav":[]
        },
  
    ],
    possibleFields:
    [
        {
            key: "projectContent.stammdaten.allgemein.bv_name",
            label: "Name",
            sortable: true,
            id:0,
            width:150,
        }, 
        {
            key: "projectContent.ProjektBeteiligte[1]",
            label: "Bauherr",
            sortable: true,
            formatter: 'for_bauherren',
            sortByFormatted: true,
            filterByFormatted: true,
            id:1,
            width:100,
        },

        {
            key: "projectContent.stammdaten.allgemein.bv_typ",
            label: "Typ",
            sortable: true,
            formatter: 'for_bv_typ_options_key',
            sortByFormatted: true,
            filterByFormatted: true,
            id:2,
            width:100,
        }, 
        {
            key: "projectContent.stammdaten.allgemein.bv_status",
            label: "Status",
            sortable: true,
            formatter: 'for_bv_status_key',
            sortByFormatted: true,
            filterByFormatted: true,
            id:3,
            width:100,
        }, 
        {
            key: "projectContent.stammdaten.allgemein.projekt_kat",
            label: "Kategorie",
            sortable: true,
            formatter: 'for_projekt_typ_options',
            sortByFormatted: true,
            filterByFormatted: true,
            id:4,
            width:100,
        }, 

        {
            key: "projectContent.stammdaten.adresse.ort",
            label: "Ort",
            sortable: true,
            id:16,
            width:100,
        }, 

        {
            key: "projectContent.stammdaten.adresse.plz",
            label: "PLZ",
            sortable: true,
            id:17,
            width:100,
        }, 

        {
            key: "projectContent.stammdaten.adresse.strasse",
            label: "Straße",
            sortable: true,
            id:18,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.10",
            label: "Verantwortl.",
            sortable: true,
            formatter: 'for_Verantwortl',
            sortByFormatted: true,
            filterByFormatted: true,
            id:5,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.11",
            label: "LP 1",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:6,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.12",
            label: "LP 2",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:7,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.13",
            label: "LP 3",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:8,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.14",
            label: "LP 4",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:9,
            width:100,
        }, 
        {
            key: "projectContent.ProjektBeteiligte.15",
            label: "LP 5",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:11,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.16",
            label: "LP 6",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:12,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.17",
            label: "LP 7",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:13,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.18",
            label: "LP 8",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:14,
            width:100,
        }, 

        {
            key: "projectContent.ProjektBeteiligte.19",
            label: "LP 9",
            sortable: true,
            formatter: 'for_Planer',
            sortByFormatted: true,
            filterByFormatted: true,
            id:15,
            width:100,
        }, 

        {
        key: "delete",
        label: "löschen",
        id:1000,
            width:100,
        }
    ],
    


    projektEinstellungen:
      {
        main:{
          myFields:'',
          sortBy: null,
          sortDesc: false,
          sortDirection: 'asc',
          meineFelder:[],
          filter:'',
          currentPage: 1,
          perPage: 50,
          letzteAufgerufen:[],
          projektAnsicht:'table',
        // weiteres
        },
        formular:{
          neu:true,
          aktuelleID:null,
          actualformularBlanc:{},
        }   

      },

      kontaktEinstellungen:
      {
        showList:false,
        listen:{},
        aktuelleListe:'',
        backupListen:null,

      },
      ichSelbst:
      {

        benutzerID:true,
        kontaktID:null,
        todo:{},
      },

      blancToDo:{title:null, content:null, active:true, until:null, created:null},
      
      blancFormular:{},
      projectsLoaded:false,


    },

  mutations: {
    
    
    setmeineNavigation(state, data) {
      state.meineNavigation = data
    }, 
    setnavigation(state, data) {
      state.navigation = data
    }, 

    setpossibleFields(state, data) {
      state.possibleFields = data
    }, 
    setprojectsLoaded(state, data) {
      state.projectsLoaded = data
    }, 
    setitsMobile(state, data) {
      state.itsMobile = data
    }, 
    setoptions_formulare_array(state, data) {
      state.currentTabComponent = data
    },   

    setcurrentTabComponent(state, data) {
      state.currentTabComponent = data
    },   
    setmeineBenutzerID(state, data) {
      state.meineBenutzerID = data
    }, 

    setshareScreenStore(state, data) {
      state.shareScreenStore = data
    },   

    
    setkontaktEinstellungen(state, data) {
      state.kontaktEinstellungen = data
    },    
    setprojektEinstellungen(state, data) {
      state.projektEinstellungen = data
    },

    setprojektBeteiligteOnOpen(state, data) {
      state.projektBeteiligteOnOpen = data
    },

    setnavi(state, data) {
      state.navi = data
    },

    setbackUpContacts(state, data) {
      state.backUpContacts = data
    },

    settoSaveArray(state, data) {
      state.toSaveArray = data
    },

    setkontaktArt(state, data) {
      state.kontaktArt = data
    },

    setzugangsdaten(state, data) {
      state.zugangsdaten = data
    },
    seteventSources(state, data) {
      state.eventSources = data
    },

    setzugriffVorlagen(state, data) {
      state.zugriffVorlagen = data
    },

    setgewaehlteVorlage(state, data) {
      state.gewaehlteVorlage = data
    },

    // setpreferredBeteiligte(state, data) {
    //   state.preferredBeteiligte = data
    // },

    setmeineFirma(state, data) {
      state.meineFirma = data
    },

    setshowFontPopover(state, data) {
      state.showFontPopover = data
    },

    setselectedTrash(state, data) {
      state.selectedTrash = data
    },

    setselectedAddressbook(state, data) {
      state.selectedAddressbook = data
    },

    setglowSpeichern(state, data) {
      state.glowSpeichern = data
    },

    setprojectDatenBackup(state, data) {
      state.projectDatenBackup = data
    },

    setalleProjekte(state, data) {
      state.alleProjekte = data
    },

    setpopoverShowProSpeichern(state, data) {
      state.popoverShowProSpeichern = data
    },

    seturlKontaktID(state, data) {
      state.urlKontaktID = data
    },

    setownDAV(state, data) {
      state.ownDAV = data
    },

    setadmin(state, data) {
      state.admin = data
    },

    setartArray(state, data) {
      state.artArray = data
    },

    setkindArray(state, data) {
      state.kindArray = data
    },  

    setstatusArray(state, data) {
      state.statusArray = data
    },

    setbv_typArray(state, data) {
      state.bv_typArray = data
    },  

    setsearchTermKontakte(state, data) {
      state.searchTermKontakte = data
    },  

    setshowInaktivTabelle(state, data) {
      state.showInaktivTabelle = data
    },  

    setmitarbeiterKontaktID(state, data) {
      state.mitarbeiterKontaktID = data
    },  

    setehemMitarbeiterKontaktID(state, data) {
      state.ehemMitarbeiterKontaktID = data
    }, 
    
    setMehrfachauswahl(state, data) {
      state.Mehrfachauswahl = data
    },

    setadressbookSelection(state, data) {
      state.kontaktHigadressbookSelectionhlight = data
    },

    setkontaktHighlight(state, data) {
      state.kontaktHighlight = data
    },

    setloader_loading(state, data) {
      state.loader_loading = data
    },

    setAddressbook(state, data) {
      state.Addressbook = data
    },

    setPapierkorb(state, data) {
      state.Papierkorb = data
    },
    
    setKontaktDetail_backup(state, data) {
      state.KontaktDetail_backup = data
    },   

    setKontaktDetail(state, data) {
      if(!data)
      state.KontaktDetail = [{}]
      else
      state.KontaktDetail = data
    },

    setkindIN(state, data) {
      state.kindIN = data
    },   

    setglowFormularBearbeiten(state, data) {
      state.glowFormularBearbeiten = data
    }, 

    setoffenes_kontakt_modal(state, data) {
      state.offenes_kontakt_modal = data
    },  

    setoffenes_kontakt_modal_backup(state, data) {
      state.offenes_kontakt_modal_backup = data
    },

    setpdfListe(state, data) {
      state.pdfListe = data
    },   

    setpdf_vorhanden(state, data) {
      state.pdf_vorhanden = data
    },   

    setoptions_formulare_vorhanden(state, data) {
      state.options_formulare_vorhanden = data
    },

    setselected_exist_form(state, data) {
      state.selected_exist_form = data
    },

    setselected_form(state, data) {

      state.selected_form = data
    },

    setPlanerListe(state, data) {
      state.PlanerListe = data
    },

    setBauleiterUndPlanerListe(state, data) {
      state.BauleiterUndPlanerListe = data
    },

    setBauvorlageberechtigtListe(state, data) {
      state.BauvorlageberechtigtListe = data
    },
  
    setBauaufsichtenListe(state, data) {
      state.BauaufsichtenListe = data
    },

    setBauherrenListe(state, data) {
      state.BauherrenListe = data
    },

    setuserSelbst(state, data) {
      state.userSelbst = data
    },

    setactiveCalendarEvent(state, data) {
      state.activeCalendarEvent = data
    },

    setallgemeinesAdressBuch(state, data) {
      state.allgemeinesAdressBuch = Object.freeze(data);
    },

    setEditBenutzer(state, payload) {
        state.EditBenutzer=payload;
        return true;
      },
    
    clearEditBenutzer(state) {
      state.EditBenutzer={};
    },

    clearCoords(state) {
      state.coords=[];
    },

    clearKompletteCompanyBenutzer(state) {
      state.KompletteCompanyBenutzer=[];
    },

    setKompletteCompanyBenutzer(state, data) {
      state.KompletteCompanyBenutzer = data
    },

    setRruleResult(state, rruleResult) {
        state.rruleResult = rruleResult
    },

    setActualProject(state, actualProject) {
      state.actualProject = actualProject    
    },

    setactualformular(state, formData) {
      state.actualformular = formData;
    },


    setauswahlListen(state, auswahlListen) {
      state.auswahlListen = auswahlListen    
    },
    
    setlogObject(state, logObject) {
      state.logObject = logObject    
    },
    setLogStatus(state, logObject) {
      state.logObject = logObject    
    },

    changeVorbild(state, flavor) {
      state.flavor = flavor
    },

    changeBearbeiten(state, checked) {
      state.bearbeiten = checked
    },

    
  },

  getters: {
    
    logObject: state => state.logObject, 

    meineNavigation: state => state.meineNavigation, 

    navigation: state => state.navigation, 

    possibleFields: state => state.possibleFields, 
    itsMobile: state => state.itsMobile, 
    userSelbst: state => state.userSelbst, 
    options_formulare_array: state => state.options_formulare_array,    
    currentTabComponent: state => state.currentTabComponent,  
    meineBenutzerID: state => state.meineBenutzerID,  
    shareScreenStore: state => state.shareScreenStore,  
    
    kontaktEinstellungen: state => state.kontaktEinstellungen,  

    projektEinstellungen: state => state.projektEinstellungen,  
    projektBeteiligteOnOpen: state => state.projektBeteiligteOnOpen,  
    navi: state => state.navi,  

    backUpContacts: state => state.backUpContacts,  
    toSaveArray: state => state.toSaveArray,
    kontaktArt: state => state.kontaktArt,
    zugangsdaten: state => state.zugangsdaten,
    eventSources: state => state.eventSources,
    activeCalendarEvent: state => state.activeCalendarEvent,
    zugriffVorlagen: state => state.zugriffVorlagen,
    gewaehlteVorlage: state => state.gewaehlteVorlage,  
    // preferredBeteiligte: state => state.preferredBeteiligte,
    meineFirma: state => state.meineFirma,
    showFontPopover: state => state.showFontPopover,
    selectedTrash: state => state.selectedTrash,
    selectedAddressbook: state => state.selectedAddressbook,
    glowSpeichern: state => state.glowSpeichern,
    projectDatenBackup: state => state.projectDatenBackup,
    alleProjekte: state => state.alleProjekte,
    projectsLoaded: state => state.projectsLoaded,
    
    popoverShowProSpeichern: state => state.popoverShowProSpeichern,
    urlKontaktID: state => state.urlKontaktID,
    ownDAV: state => state.ownDAV,
    admin: state => state.admin,
    kindArray: state => state.kindArray,
    artArray: state => state.artArray,

    statusArray: state => state.statusArray,
    bv_typArray: state => state.bv_typArray,


    searchTermKontakte: state => state.searchTermKontakte,
    showInaktivTabelle: state => state.showInaktivTabelle,
    ehemMitarbeiterKontaktID: state => state.ehemMitarbeiterKontaktID,
    mitarbeiterKontaktID: state => state.mitarbeiterKontaktID,
    Mehrfachauswahl: state => state.Mehrfachauswahl,
    adressbookSelection: state => state.adressbookSelection,
    kontaktHighlight: state => state.kontaktHighlight,

    loader_loading: state => state.loader_loading,
    Addressbook: state => state.Addressbook,
    Papierkorb: state => state.Papierkorb,
    KontaktDetail: state => state.KontaktDetail,
    KontaktDetail_backup: state => state.KontaktDetail_backup,
    kindIN: state => state.kindIN,


    offenes_kontakt_modal: state => state.offenes_kontakt_modal,
    offenes_kontakt_modal_backup: state => state.offenes_kontakt_modal_backup,
    
    glowFormularBearbeiten: state => state.glowFormularBearbeiten,   
    pdfListe: state => state.pdfListe,
    pdf_vorhanden: state => state.pdf_vorhanden,
    options_formulare_vorhanden: state => state.options_formulare_vorhanden,
    selected_exist_form: state => state.selected_exist_form,

    selected_form: state => state.selected_form,
    options_formulare_neu: state => state.options_formulare_neu,
    actualformular: state => state.actualformular,
    BauherrenListe: state => state.BauherrenListe,
    BauaufsichtenListe: state => state.BauaufsichtenListe,
    PlanerListe: state => state.PlanerListe,  
    BauleiterUndPlanerListe: state => state.BauleiterUndPlanerListe,  
    
    BauvorlageberechtigtListe: state => state.BauvorlageberechtigtListe,  
    allgemeinesAdressBuch: state => state.allgemeinesAdressBuch,
    logObject: state => state.logObject,
    actualProject: state => state.actualProject,
    bearbeiten: state => state.bearbeiten,
    auswahlListen: state => state.auswahlListen,
    
    myVal: state => state.myVal,
    formular: state => state.formular,
    
    },

  actions: {
  //   GET_ALL_EXERCISES: function ({ commit }) {

  //  axios.get('/api/v1/exercises').then((response) => {
  //      commit('SET_EXERCISES', { exercises: response.data })
  //    }, (err) => {
  //      // @todo handle this error
  //    })

  //   }
  },

 

})
