<template>

<div v-if="projectDaten.stammdaten">
        <div ref="divSubnav" v-if="!itsMobile" class="subnav">
<!-- {{suvNav}} -->
            <ul style="margin-bottom:0.5em !important" class="list-inline_scoped " >
                <li :key="sub.navID" v-for="sub in suvNav">
                <router-link class="subnavBVname" :to="{ name: sub.unterordnerName, params: {id: $route.params.id } }">{{sub.unterordnerName}}</router-link>
                </li>
            </ul>


            <hr class="my-1">

                <b-row align-h="between" class="mt-2 d-flex align-items-center">

                    <b-col style="line-height:28px; padding-left:20px;" cols="8">
                        <span  class="subnavBVname">{{projectDaten.stammdaten.allgemein.bv_name}}</span>
                    </b-col>

                            <b-col class="" cols="1">
                                <b-button v-if="$route.path.includes('/projekte')===true"  
                                variant="outline-info" :to="{ path: '/hilfe', hash: '#'+ unterPfad } ">Hilfe
                                </b-button>
                            </b-col>

                            <b-col class="" cols="1">
                                  <transition name="fade">
                                    <b-button  v-if="bearbeiten && $route.path.includes('/projekte')===true" 
                                    v-bind:class="{ 'button-glow': glowSpeichern}" 
                                    id="projectSpeichern" 
                                    :disabled="loader_loading"
                                    v-b-tooltip.hover title="speichern - strg + s"
                                        @click="speicherAktuellesProjekt" variant="success"><span class="fa fa-save"></span>
                                    </b-button>
                                  </transition>
                             </b-col>

                             <b-col class="" cols="2">
                                <b-form-checkbox v-model="bearbeiten" name="check-button" switch>bearbeiten</b-form-checkbox>
                            </b-col>
                </b-row>
        </div>

        <div ref="divSubnavShadow" v-if="!itsMobile" class="subnav_shadow"></div> 

    </div>
</template>


<script>

import { projectMixin } from '../../mixins/projectMixin.js'

    export default {
    
        mixins: [projectMixin],
        
    components: {
    
    },

    name: "subnavi",


    data(){
            return{

                glow:null,
                saveToolTipShow: false
  


            }
    },


    computed: {
        
        navigation:
		{
			get()
			{
				return this.$store.getters.navigation
			},
			set(value)
			{
				this.$store.commit('setnavigation', value);
			}
        },
        meineNavigation:
		{
			get()
			{
				return this.$store.getters.meineNavigation
			},
			set(value)
			{
				this.$store.commit('setmeineNavigation', value);
			}
        },

        suvNav(){
                var returnValue = [];

                  var pos = this.navigation.map(function(e) { return e.ordnerName; }).indexOf('Projekte');
  
                  if(pos>=0 && this.meineNavigation.length>0){

                    for(var dat of this.navigation[pos].subnav)
                    {
                        if(this.meineNavigation.includes(dat.navID))
                        returnValue.push(dat)
                    }
                  }
               

                return returnValue

        },

        unterPfad(){
           // console.log(this.$route.path)
            if(this.$route.path.includes('projekte')){
            this.calculateHeight();
            var navTeile = this.$route.path.split("/");

            var laenge = navTeile.length-1;

            return navTeile[laenge];

            }
            else 
            return null

        },
        
        glowSpeichern:
        {
            get()
            {
                return this.$store.getters.glowSpeichern
            },
            set(value)
            {
                this.$store.commit('setglowSpeichern', value);
            }
        }, 

        popoverShowProSpeichern: 
        {
            get() {
                return this.$store.getters.popoverShowProSpeichern;
            },
            set(values) {
                this.$store.commit('setpopoverShowProSpeichern', values);
            }
        },


            
        zeige_foumular_navi()
        {
            if(this.$route.params.id && this.$route.path.includes('/formular'))
            return true;
            else
            return false;
        },


		selected_form: {
				get() {
					return this.$store.getters.selected_form;
				},
				set(values) {
					this.$store.commit('setselected_form', values);
				}
			},

			options_formulare_vorhanden: {
				
				get() {
					return this.$store.getters.options_formulare_vorhanden;
				},

				set(values) {
					this.$store.commit('setoptions_formulare_vorhanden', values);
				}
			},

			selected_exist_form: {
				get() {
					return this.$store.getters.selected_exist_form;
				},
				set(values) {
					this.$store.commit('setselected_exist_form', values);
				}
            },
 
 			navi: {
				get() {
					return this.$store.getters.navi;
				},
				set(values) {
					this.$store.commit('setnavi', values);
				}
            },

            
            			
			glowFormularBearbeiten: {
				get() {
					return this.$store.getters.glowFormularBearbeiten;
				},
				set(values) {
					this.$store.commit('setglowFormularBearbeiten', values);
				}
			},


            options_formulare_neu(){
				return this.$store.getters.options_formulare_neu;
            },

            store() {
                return this.$store.state
            },

		    actualformular(){
				return this.$store.getters.actualformular;
            },


		    subnav(){

                    if(this.navi.length>0){
                        if(this.navi[0].subnav)
                            return this.navi[0].subnav;
                        else
                            return [];
                    }
                        
                    else
                        return [];


            },

  },

  	mounted() {
                
        if(Object.keys(this.meineNavigation).length ===0){
        // console.log("updateAlleProjekte length");
        this.checkSubNav();
    } 

               window.addEventListener('resize', this.calculateHeight)
               document.addEventListener("keydown", this.doSave);
                
            	setTimeout(()=>{
			        this.calculateHeight();
                },200);

             	setTimeout(()=>{
			        this.calculateHeight();
                },1000);       
             

    },



  

    methods: {

        calculateHeight() {

            if(this.$refs.divSubnav){
                this.$refs.divSubnavShadow.style.height = this.$refs.divSubnav.clientHeight-3+'px';
            }

        },

        doSave(e) {
            if (!(e.keyCode === 83 && e.ctrlKey)) {
                return;
            }

            e.preventDefault();

            this.speicherAktuellesProjekt();

            console.log("speicherAktuellesProjekt");
            },

        filterItems(arr, query) {
            return arr.filter(el => el.vollerName.anzeigeName.toLowerCase().indexOf(query.suchfeld.toLowerCase()) > -1 && this.has(el.basic.kind, query.kind) && this.findCommonElements(el.art.summary, this.artArray) );
        },

        getSpezialistenListe(spezialist){

			return this.axios.post(this.$store.state.url_get_kontakte, {
				
				besonders: this.$store.state.dev_sub,
				welche:spezialist,

				}).then((response) => {
                    return response.data;
				})

        },

        checkSubNav(){
            this.axios.get(this.$store.state.url_subnav,
                {
                params: {
                    besonders: this.$store.state.dev_sub,
                }
                })
                .then((response) => {
                    this.meineNavigation = response.data;
                
            })
        },

    },

        
    beforeDestroy() {
        document.removeEventListener("keydown", this.doSave);
        window.removeEventListener('resize', this.calculateHeight)
    },

    
	created(){


            if(!this.projectDaten.stammdaten)
                this.projectDaten = JSON.parse(JSON.stringify(this.blancProject)); 


    
    },


    }
</script>


<style>
@keyframes glowing {
  0% { box-shadow: 0 0 -10px #008dc4; }
  40% { box-shadow: 0 0 20px #008dc4; }
  60% { box-shadow: 0 0 20px #008dc4; }
  100% { box-shadow: 0 0 -10px #008dc4; }
  60% { box-shadow: 0 0 20px #008dc4; }
  40% { box-shadow: 0 0 20px #008dc4; }
}

.button-glow {
  animation: glowing 3000ms infinite;
}
</style>



<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.subnavBVname{
    font-size: 1.05em;
    font-weight: 600;
}

.form-control{
    font-size: 1.0em;
    font-weight: 600;

}
.form-control-plaintext{
    font-size: 1.1em !important;
    font-weight: 600;

}


.ueberschrift_formular {

    color: #666;
    font-size: 1.0em;
    font-weight: 600;
    }

.btn{
	margin-right:5px !important;
}

.router-link-exact-active{
	color:#337AB7 !important;
    border-bottom: 1px solid #000;
}

.router-link-exact-active:hover {
	cursor: default ;
text-decoration:none;
}


.navbar
{
    padding: 0.5rem 0rem;
    height: 54px;
    background-color:#fff;
}

.navbar-nav{
    margin-left:4.5%;
    margin-right:4.5%;
}

.navbar-nav >  li{
    font-weight:400;
	letter-spacing:2px;
	color: #000;
	-webkit-transition: 0.3s;
	letter-spacing: 0.09em;
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: 1px solid transparent;
    font-size:1.2em;
}

a.nav-link.active{
    border-bottom: 11px solid #F2F2F2;
    color: #191970 !important;
}

.navbar-collapse{
        background-color:#fff;
}

.navbar-toggler{
        margin-left:4.5%;
}

.navbar-light .navbar-nav .nav-link{
    color:#000;
}

.project-details-liste{
        margin-right:1em;
        padding-right:1.2em;
        border-right: 1px solid #A4A4A4;
}

.hr_line
    {
        border-bottom: 1px solid #A4A4A4;
        margin-left:-10px;
        margin-right:-10px;
        margin-bottom:7px;
    }
    
.projectDetails
    {
        font-weight: 600;
        margin-top: 5px;
        padding-left: 5px;
        padding-top: 5px;
    }
    
.abstandhalter_leer{
       height:54px;
}
    
.subnav_scoped {
	background: #fff;
	padding: 0;
	position: relative;
	text-align: left;
	width: 100%;
	min-height: 35px;
	z-index: 9987;
	border-top: 1px solid #F2F2F2;
}

.untersicht_projects{

}


#logo {
    width:100%;
    text-align:center;
    margin: 0 auto;
    margin-bottom:60px;
    padding-top:15px;
}

.logo_retz
{
	font-size: 4.0em;
	font-weight: 50;
	letter-spacing: 4px;
	color:#000;
	text-shadow: 0.02em 0.02em 0 rgba(0,0,0,0.1);
    line-height: 2em;
}

/* #0 1DF3A*/
/* #E EC7BC*/

.logo_retz_unterschrift
{
	font-size: 1.3em;
	font-weight: 100;
	letter-spacing: 2px;
	color:#9e9e9e;
}

.logo{
    float:right;
    line-height: 53px;
    margin-right:5%;
    font-weight:600;
    font-size:1.2em
}

.logo p{
    margin-right:10px;
}

@media (max-width: 850px) {
    .logo{
    display:none;
    }
}
.leere_zeile{
	height:1em;
}





/* .loggedIn  .router-link-active{
}

.router-link-active{
	color:#337AB7 !important;
} */


</style>