<template>
	<div>

        <div class="kontainer">
			<h1>Kosten</h1>
			<p>Text</p>
		</div>
    </div>
</template>

<script>



export default {

    data(){
        return{
        }

    },


	

}
</script>


<style>


</style>
