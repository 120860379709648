<template>
			<div v-if="formular!=undefined" >

	{{ formular }}
			<div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
			position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(16.1551419) + 'px'}">
													An die untere Bauaufsichtsbehörde
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(16.1551419) + 'px'}">
													Eingangsstempel der Bauaufsichtsbehörde
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(160.319785) + 'px', 
														top: gib_hoehe_in_mm(9.0263695) + 'px'}">
													Anlage I/2 zur VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(191.050536) + 'px', 
														top: gib_hoehe_in_mm(12.3276415) + 'px'}">
													Blatt 1
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(82.5157044) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(17.1373403) + 'px',
										
										 top: gib_hoehe_in_mm(19.1538737) + 'px'}"><b-popover 
												v-if="formular.bauaufsicht"
												:show="formular.bauaufsicht.showPop==true"
												
												ref='bauaufsicht' 
												target='bauaufsicht'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht.size}}</span>
												<b-button @click='plus("bauaufsicht", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauaufsicht!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauaufsicht.text"
												
												@focus='formular.bauaufsicht.showPop=true'
												@blur ='formular.bauaufsicht.showPop=false'
												
												:disabled="formular.bauaufsicht.isDisabled==true || formular.bauaufsicht.isDisabled=='true'"
												:style="{'font-size': formular.bauaufsicht.size + 'px',
														'height': formular.bauaufsicht.height + 'px',
														'text-align': formular.bauaufsicht.schriftausrichtung + 'px',
														'letter-spacing': formular.bauaufsicht.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht.schriftart + 'px', }"

												id='bauaufsicht'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(39.4333419) + 'px'}">
													PLZ, Ort
													</div>
<b-popover 
												v-if="formular.bauaufsicht_plz"
												:show="formular.bauaufsicht_plz.showPop==true"
												
												ref='bauaufsicht_plz' 
												target='bauaufsicht_plz'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauaufsicht_plz.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauaufsicht_plz.size}}</span>
												<b-button @click='plus("bauaufsicht_plz", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauaufsicht_plz", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauaufsicht_plz" type="text" 
												v-if="formular.bauaufsicht_plz!=undefined" 
												class='textfeld' v-model='formular.bauaufsicht_plz.text' 
												style='width:100%' 
												@focus='formular.bauaufsicht_plz.showPop=true'
												@blur ='formular.bauaufsicht_plz.showPop=false'
												:disabled='formular.bauaufsicht_plz.isDisabled==true || formular.bauaufsicht_plz.isDisabled==true' 
												@keypress="isNumber($event, formular.bauaufsicht_plz.onylNumber) " 
												:style="{'width': spalte_breit_mm(82.5157044) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3873403) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(43.0756869) + 'px', 
		
														'font-size': formular.bauaufsicht_plz.size + 'px', 
														'text-align': formular.bauaufsicht_plz.schriftausrichtung, 
														'letter-spacing': formular.bauaufsicht_plz.letterSpacing + 'px',
														'font-weight': formular.bauaufsicht_plz.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ist_antrag!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'20px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(50.1925318) + 'px'}"
								v-model="formular.ist_antrag.text" 
								value='1' 
								unchecked-value='0'> Bauantrag
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ist_voranfrage!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'20px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(56.4589527) + 'px'}"
								v-model="formular.ist_voranfrage.text" 
								value='1' 
								unchecked-value='0'> Antrag auf Vorbescheid
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ist_referenzgeb!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'20px',
														fontWeight:'bold',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(62.778984) + 'px'}"
								v-model="formular.ist_referenzgeb.text" 
								value='1' 
								unchecked-value='0'> Referenzgebäude
								</b-form-checkbox>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(95.0897546) + 'px'}">
													PLZ, Ort
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(135.6326196) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.bauherr_email"
												:show="formular.bauherr_email.showPop==true"
												
												ref='bauherr_email' 
												target='bauherr_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_email.size}}</span>
												<b-button @click='plus("bauherr_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_email" type="text" 
												v-if="formular.bauherr_email!=undefined" 
												class='textfeld' v-model='formular.bauherr_email.text' 
												style='width:100%' 
												@focus='formular.bauherr_email.showPop=true'
												@blur ='formular.bauherr_email.showPop=false'
												:disabled='formular.bauherr_email.isDisabled==true || formular.bauherr_email.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(139.6160255) + 'px', 
		
														'font-size': formular.bauherr_email.size + 'px', 
														'text-align': formular.bauherr_email.schriftausrichtung, 
														'letter-spacing': formular.bauherr_email.letterSpacing + 'px',
														'font-weight': formular.bauherr_email.schriftart }" 
												/>

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.wohngebaude_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5217833) + 'px',top: gib_hoehe_in_mm(170.7760767) + 'px'}"
								v-model="formular.wohngebaude_check.text" 
								value='1' 
								unchecked-value='0'> Wohngebäude
								</b-form-checkbox>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(180.2453474) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(17.1373403) + 'px',
										
										 top: gib_hoehe_in_mm(180.9877998) + 'px'}"><b-popover 
												v-if="formular.bezeichnung_bauvorhaben"
												:show="formular.bezeichnung_bauvorhaben.showPop==true"
												
												ref='bezeichnung_bauvorhaben' 
												target='bezeichnung_bauvorhaben'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bezeichnung_bauvorhaben.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bezeichnung_bauvorhaben.size}}</span>
												<b-button @click='plus("bezeichnung_bauvorhaben", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bezeichnung_bauvorhaben", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bezeichnung_bauvorhaben!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bezeichnung_bauvorhaben.text"
												
												@focus='formular.bezeichnung_bauvorhaben.showPop=true'
												@blur ='formular.bezeichnung_bauvorhaben.showPop=false'
												
												:disabled="formular.bezeichnung_bauvorhaben.isDisabled==true || formular.bezeichnung_bauvorhaben.isDisabled=='true'"
												:style="{'font-size': formular.bezeichnung_bauvorhaben.size + 'px',
														'height': formular.bezeichnung_bauvorhaben.height + 'px',
														'text-align': formular.bezeichnung_bauvorhaben.schriftausrichtung + 'px',
														'letter-spacing': formular.bezeichnung_bauvorhaben.letterSpacing + 'px',
														'font-weight': formular.bezeichnung_bauvorhaben.schriftart + 'px', }"

												id='bezeichnung_bauvorhaben'>
										</textarea>
	

										 
										 
										 </span>
									

<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_vorbescheid!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(245.9826504) + 'px'}"
								v-model="formular.bindung_vorbescheid.text" 
								value='1' 
								unchecked-value='0'> Vorbescheid
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_teilungsgen!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(250.9204504) + 'px'}"
								v-model="formular.bindung_teilungsgen.text" 
								value='1' 
								unchecked-value='0'> Teilungsgenehmigung
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_befreiung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(255.2706522) + 'px'}"
								v-model="formular.bindung_befreiung.text" 
								value='1' 
								unchecked-value='0'> Befreiungs-/Abweichungsbescheid
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_baulast!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(259.9763756) + 'px'}"
								v-model="formular.bindung_baulast.text" 
								value='1' 
								unchecked-value='0'>  Baulast Nr.
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_denkmal!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(264.7625146) + 'px'}"
								v-model="formular.bindung_denkmal.text" 
								value='1' 
								unchecked-value='0'> Denkmalrechtliche Erlaubnis
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bindung_sonstiges!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(17.5193144) + 'px',top: gib_hoehe_in_mm(269.6855012) + 'px'}"
								v-model="formular.bindung_sonstiges.text" 
								value='1' 
								unchecked-value='0'> sonstiges
								</b-form-checkbox>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(111.012325) + 'px', 
														top: gib_hoehe_in_mm(53.8432531) + 'px'}">
													Einfaches Baugenehmigungsverfahren
													</div>
<div :style="{
														fontSize:'18px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(128.407489) + 'px', 
														top: gib_hoehe_in_mm(58.7105131) + 'px'}">
													 64 BauO NRW 2018
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(69.787762) + 'px'}">
													Bauherrschaft ( 53 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(74.1813459) + 'px'}">
													Name, Vorname, Firma
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(69.787762) + 'px'}">
													Entwurfsverfassende ( 54 Absatz 1 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(74.1813459) + 'px'}">
													Name, Vorname, Büro
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(82.3799149) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(17.1373403) + 'px',
										
										 top: gib_hoehe_in_mm(77.7235884) + 'px'}"><b-popover 
												v-if="formular.bauherr_name"
												:show="formular.bauherr_name.showPop==true"
												
												ref='bauherr_name' 
												target='bauherr_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_name.size}}</span>
												<b-button @click='plus("bauherr_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_name.text"
												
												@focus='formular.bauherr_name.showPop=true'
												@blur ='formular.bauherr_name.showPop=false'
												
												:disabled="formular.bauherr_name.isDisabled==true || formular.bauherr_name.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_name.size + 'px',
														'height': formular.bauherr_name.height + 'px',
														'text-align': formular.bauherr_name.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_name.letterSpacing + 'px',
														'font-weight': formular.bauherr_name.schriftart + 'px', }"

												id='bauherr_name'>
										</textarea>
	

										 
										 
										 </span>
									


										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(94.2941209) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(102.8046433) + 'px',
										
										 top: gib_hoehe_in_mm(77.7235884) + 'px'}"><b-popover 
												v-if="formular.antragsteller_name"
												:show="formular.antragsteller_name.showPop==true"
												
												ref='antragsteller_name' 
												target='antragsteller_name'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_name.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_name.size}}</span>
												<b-button @click='plus("antragsteller_name", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_name", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_name!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_name.text"
												
												@focus='formular.antragsteller_name.showPop=true'
												@blur ='formular.antragsteller_name.showPop=false'
												
												:disabled="formular.antragsteller_name.isDisabled==true || formular.antragsteller_name.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_name.size + 'px',
														'height': formular.antragsteller_name.height + 'px',
														'text-align': formular.antragsteller_name.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_name.letterSpacing + 'px',
														'font-weight': formular.antragsteller_name.schriftart + 'px', }"

												id='antragsteller_name'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(87.0869916) + 'px'}">
													Straße, Hausnummer
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(87.0869916) + 'px'}">
													Straße, Hausnummer
													</div>
<b-popover 
												v-if="formular.bauherr_strasse"
												:show="formular.bauherr_strasse.showPop==true"
												
												ref='bauherr_strasse' 
												target='bauherr_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_strasse.size}}</span>
												<b-button @click='plus("bauherr_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_strasse" type="text" 
												v-if="formular.bauherr_strasse!=undefined" 
												class='textfeld' v-model='formular.bauherr_strasse.text' 
												style='width:100%' 
												@focus='formular.bauherr_strasse.showPop=true'
												@blur ='formular.bauherr_strasse.showPop=false'
												:disabled='formular.bauherr_strasse.isDisabled==true || formular.bauherr_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(90.7885902) + 'px', 
		
														'font-size': formular.bauherr_strasse.size + 'px', 
														'text-align': formular.bauherr_strasse.schriftausrichtung, 
														'letter-spacing': formular.bauherr_strasse.letterSpacing + 'px',
														'font-weight': formular.bauherr_strasse.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_strasse"
												:show="formular.antragsteller_strasse.showPop==true"
												
												ref='antragsteller_strasse' 
												target='antragsteller_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_strasse.size}}</span>
												<b-button @click='plus("antragsteller_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_strasse" type="text" 
												v-if="formular.antragsteller_strasse!=undefined" 
												class='textfeld' v-model='formular.antragsteller_strasse.text' 
												style='width:100%' 
												@focus='formular.antragsteller_strasse.showPop=true'
												@blur ='formular.antragsteller_strasse.showPop=false'
												:disabled='formular.antragsteller_strasse.isDisabled==true || formular.antragsteller_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(103.0546433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(90.7885902) + 'px', 
		
														'font-size': formular.antragsteller_strasse.size + 'px', 
														'text-align': formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': formular.antragsteller_strasse.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_ort"
												:show="formular.bauherr_ort.showPop==true"
												
												ref='bauherr_ort' 
												target='bauherr_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_ort.size}}</span>
												<b-button @click='plus("bauherr_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_ort" type="text" 
												v-if="formular.bauherr_ort!=undefined" 
												class='textfeld' v-model='formular.bauherr_ort.text' 
												style='width:100%' 
												@focus='formular.bauherr_ort.showPop=true'
												@blur ='formular.bauherr_ort.showPop=false'
												:disabled='formular.bauherr_ort.isDisabled==true || formular.bauherr_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(82.3718028) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(98.8682418) + 'px', 
		
														'font-size': formular.bauherr_ort.size + 'px', 
														'text-align': formular.bauherr_ort.schriftausrichtung, 
														'letter-spacing': formular.bauherr_ort.letterSpacing + 'px',
														'font-weight': formular.bauherr_ort.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_ort"
												:show="formular.antragsteller_ort.showPop==true"
												
												ref='antragsteller_ort' 
												target='antragsteller_ort'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_ort.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_ort.size}}</span>
												<b-button @click='plus("antragsteller_ort", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_ort", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_ort" type="text" 
												v-if="formular.antragsteller_ort!=undefined" 
												class='textfeld' v-model='formular.antragsteller_ort.text' 
												style='width:100%' 
												@focus='formular.antragsteller_ort.showPop=true'
												@blur ='formular.antragsteller_ort.showPop=false'
												:disabled='formular.antragsteller_ort.isDisabled==true || formular.antragsteller_ort.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_ort.onylNumber) " 
												:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(103.0546433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(98.8682418) + 'px', 
		
														'font-size': formular.antragsteller_ort.size + 'px', 
														'text-align': formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': formular.antragsteller_ort.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(103.1552982) + 'px'}">
													vertreten durch ( 53 Absatz 3 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(107.0251226) + 'px'}">
													Name, Vorname, Anschrift
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(82.3799149) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(17.1373403) + 'px',
										
										 top: gib_hoehe_in_mm(110.7200842) + 'px'}"><b-popover 
												v-if="formular.bauherr_vertreter"
												:show="formular.bauherr_vertreter.showPop==true"
												
												ref='bauherr_vertreter' 
												target='bauherr_vertreter'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_vertreter.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_vertreter.size}}</span>
												<b-button @click='plus("bauherr_vertreter", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_vertreter", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.bauherr_vertreter!=undefined"
												
												rows='4'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.bauherr_vertreter.text"
												
												@focus='formular.bauherr_vertreter.showPop=true'
												@blur ='formular.bauherr_vertreter.showPop=false'
												
												:disabled="formular.bauherr_vertreter.isDisabled==true || formular.bauherr_vertreter.isDisabled=='true'"
												:style="{'font-size': formular.bauherr_vertreter.size + 'px',
														'height': formular.bauherr_vertreter.height + 'px',
														'text-align': formular.bauherr_vertreter.schriftausrichtung + 'px',
														'letter-spacing': formular.bauherr_vertreter.letterSpacing + 'px',
														'font-weight': formular.bauherr_vertreter.schriftart + 'px', }"

												id='bauherr_vertreter'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(103.1552982) + 'px'}">
													bauvorlageberechtigt ( 67 Absatz 3 BauO NRW 2018) Name, Vorname
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(115.7438666) + 'px'}">
													Mitgliedsnummer der Architekten oder der Ingenieurkammer des Landes
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(127.6792346) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(60.3113473) + 'px', 
														top: gib_hoehe_in_mm(127.6792346) + 'px'}">
													Telefax
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(127.6792346) + 'px'}">
													Telefon (mit Vorwahl)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(147.791881) + 'px', 
														top: gib_hoehe_in_mm(127.6792346) + 'px'}">
													Telefax
													</div>
<b-popover 
												v-if="formular.bauherr_tel"
												:show="formular.bauherr_tel.showPop==true"
												
												ref='bauherr_tel' 
												target='bauherr_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_tel.size}}</span>
												<b-button @click='plus("bauherr_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_tel" type="text" 
												v-if="formular.bauherr_tel!=undefined" 
												class='textfeld' v-model='formular.bauherr_tel.text' 
												style='width:100%' 
												@focus='formular.bauherr_tel.showPop=true'
												@blur ='formular.bauherr_tel.showPop=false'
												:disabled='formular.bauherr_tel.isDisabled==true || formular.bauherr_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(40.755319) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.4735933) + 'px', 
		
														'font-size': formular.bauherr_tel.size + 'px', 
														'text-align': formular.bauherr_tel.schriftausrichtung, 
														'letter-spacing': formular.bauherr_tel.letterSpacing + 'px',
														'font-weight': formular.bauherr_tel.schriftart }" 
												/>

<b-popover 
												v-if="formular.bauherr_fax"
												:show="formular.bauherr_fax.showPop==true"
												
												ref='bauherr_fax' 
												target='bauherr_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bauherr_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bauherr_fax.size}}</span>
												<b-button @click='plus("bauherr_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bauherr_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bauherr_fax" type="text" 
												v-if="formular.bauherr_fax!=undefined" 
												class='textfeld' v-model='formular.bauherr_fax.text' 
												style='width:100%' 
												@focus='formular.bauherr_fax.showPop=true'
												@blur ='formular.bauherr_fax.showPop=false'
												:disabled='formular.bauherr_fax.isDisabled==true || formular.bauherr_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.bauherr_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(39.2059079) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(60.5613473) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.4735933) + 'px', 
		
														'font-size': formular.bauherr_fax.size + 'px', 
														'text-align': formular.bauherr_fax.schriftausrichtung, 
														'letter-spacing': formular.bauherr_fax.letterSpacing + 'px',
														'font-weight': formular.bauherr_fax.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_tel"
												:show="formular.antragsteller_tel.showPop==true"
												
												ref='antragsteller_tel' 
												target='antragsteller_tel'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_tel.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_tel.size}}</span>
												<b-button @click='plus("antragsteller_tel", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_tel", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_tel" type="text" 
												v-if="formular.antragsteller_tel!=undefined" 
												class='textfeld' v-model='formular.antragsteller_tel.text' 
												style='width:100%' 
												@focus='formular.antragsteller_tel.showPop=true'
												@blur ='formular.antragsteller_tel.showPop=false'
												:disabled='formular.antragsteller_tel.isDisabled==true || formular.antragsteller_tel.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_tel.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.4994205) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(103.0546433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.4735933) + 'px', 
		
														'font-size': formular.antragsteller_tel.size + 'px', 
														'text-align': formular.antragsteller_tel.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_tel.letterSpacing + 'px',
														'font-weight': formular.antragsteller_tel.schriftart }" 
												/>

<b-popover 
												v-if="formular.antragsteller_fax"
												:show="formular.antragsteller_fax.showPop==true"
												
												ref='antragsteller_fax' 
												target='antragsteller_fax'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_fax.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_fax.size}}</span>
												<b-button @click='plus("antragsteller_fax", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_fax", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_fax" type="text" 
												v-if="formular.antragsteller_fax!=undefined" 
												class='textfeld' v-model='formular.antragsteller_fax.text' 
												style='width:100%' 
												@focus='formular.antragsteller_fax.showPop=true'
												@blur ='formular.antragsteller_fax.showPop=false'
												:disabled='formular.antragsteller_fax.isDisabled==true || formular.antragsteller_fax.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_fax.onylNumber) " 
												:style="{'width': spalte_breit_mm(38.9343289) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(148.0457607) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(131.4735933) + 'px', 
		
														'font-size': formular.antragsteller_fax.size + 'px', 
														'text-align': formular.antragsteller_fax.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_fax.letterSpacing + 'px',
														'font-weight': formular.antragsteller_fax.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(39.4333419) + 'px'}">
													Aktenzeichen
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(95.0897546) + 'px'}">
													PLZ, Ort
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(94.2941209) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(102.8046433) + 'px',
										
										 top: gib_hoehe_in_mm(107.4498498) + 'px'}"><b-popover 
												v-if="formular.antragsteller_vorlagenberechtigt"
												:show="formular.antragsteller_vorlagenberechtigt.showPop==true"
												
												ref='antragsteller_vorlagenberechtigt' 
												target='antragsteller_vorlagenberechtigt'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_vorlagenberechtigt.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_vorlagenberechtigt.size}}</span>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_vorlagenberechtigt", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.antragsteller_vorlagenberechtigt!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.antragsteller_vorlagenberechtigt.text"
												
												@focus='formular.antragsteller_vorlagenberechtigt.showPop=true'
												@blur ='formular.antragsteller_vorlagenberechtigt.showPop=false'
												
												:disabled="formular.antragsteller_vorlagenberechtigt.isDisabled==true || formular.antragsteller_vorlagenberechtigt.isDisabled=='true'"
												:style="{'font-size': formular.antragsteller_vorlagenberechtigt.size + 'px',
														'height': formular.antragsteller_vorlagenberechtigt.height + 'px',
														'text-align': formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
														'letter-spacing': formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
														'font-weight': formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }"

												id='antragsteller_vorlagenberechtigt'>
										</textarea>
	

										 
										 
										 </span>
									

<b-popover 
												v-if="formular.antragsteller_mitgliedsnummer"
												:show="formular.antragsteller_mitgliedsnummer.showPop==true"
												
												ref='antragsteller_mitgliedsnummer' 
												target='antragsteller_mitgliedsnummer'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_mitgliedsnummer.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_mitgliedsnummer.size}}</span>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_mitgliedsnummer", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_mitgliedsnummer" type="text" 
												v-if="formular.antragsteller_mitgliedsnummer!=undefined" 
												class='textfeld' v-model='formular.antragsteller_mitgliedsnummer.text' 
												style='width:100%' 
												@focus='formular.antragsteller_mitgliedsnummer.showPop=true'
												@blur ='formular.antragsteller_mitgliedsnummer.showPop=false'
												:disabled='formular.antragsteller_mitgliedsnummer.isDisabled==true || formular.antragsteller_mitgliedsnummer.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_mitgliedsnummer.onylNumber) " 
												:style="{'width': spalte_breit_mm(94.2941209) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(103.0546433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(119.8493067) + 'px', 
		
														'font-size': formular.antragsteller_mitgliedsnummer.size + 'px', 
														'text-align': formular.antragsteller_mitgliedsnummer.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_mitgliedsnummer.letterSpacing + 'px',
														'font-weight': formular.antragsteller_mitgliedsnummer.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(102.804996) + 'px', 
														top: gib_hoehe_in_mm(135.6326196) + 'px'}">
													EMail
													</div>
<b-popover 
												v-if="formular.antragsteller_email"
												:show="formular.antragsteller_email.showPop==true"
												
												ref='antragsteller_email' 
												target='antragsteller_email'  
												
												>
												<template slot='title'>
													<b-button @click="formular.antragsteller_email.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.antragsteller_email.size}}</span>
												<b-button @click='plus("antragsteller_email", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("antragsteller_email", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="antragsteller_email" type="text" 
												v-if="formular.antragsteller_email!=undefined" 
												class='textfeld' v-model='formular.antragsteller_email.text' 
												style='width:100%' 
												@focus='formular.antragsteller_email.showPop=true'
												@blur ='formular.antragsteller_email.showPop=false'
												:disabled='formular.antragsteller_email.isDisabled==true || formular.antragsteller_email.isDisabled==true' 
												@keypress="isNumber($event, formular.antragsteller_email.onylNumber) " 
												:style="{'width': spalte_breit_mm(83.9254463) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(103.0546433) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(139.6160255) + 'px', 
		
														'font-size': formular.antragsteller_email.size + 'px', 
														'text-align': formular.antragsteller_email.schriftausrichtung, 
														'letter-spacing': formular.antragsteller_email.letterSpacing + 'px',
														'font-weight': formular.antragsteller_email.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(143.8551147) + 'px'}">
													Baugrundstück
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(148.2486986) + 'px'}">
													Ort, Straße, Hausnummer, gegebenenfalls Ortsteil
													</div>
<b-popover 
												v-if="formular.baugrund_strasse"
												:show="formular.baugrund_strasse.showPop==true"
												
												ref='baugrund_strasse' 
												target='baugrund_strasse'  
												
												>
												<template slot='title'>
													<b-button @click="formular.baugrund_strasse.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.baugrund_strasse.size}}</span>
												<b-button @click='plus("baugrund_strasse", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("baugrund_strasse", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="baugrund_strasse" type="text" 
												v-if="formular.baugrund_strasse!=undefined" 
												class='textfeld' v-model='formular.baugrund_strasse.text' 
												style='width:100%' 
												@focus='formular.baugrund_strasse.showPop=true'
												@blur ='formular.baugrund_strasse.showPop=false'
												:disabled='formular.baugrund_strasse.isDisabled==true || formular.baugrund_strasse.isDisabled==true' 
												@keypress="isNumber($event, formular.baugrund_strasse.onylNumber) " 
												:style="{'width': spalte_breit_mm(179.5723958) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(152.1802576) + 'px', 
		
														'font-size': formular.baugrund_strasse.size + 'px', 
														'text-align': formular.baugrund_strasse.schriftausrichtung, 
														'letter-spacing': formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': formular.baugrund_strasse.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(156.2479346) + 'px'}">
													Gemarkung(en)
													</div>
<b-popover 
												v-if="formular.gemarkungen"
												:show="formular.gemarkungen.showPop==true"
												
												ref='gemarkungen' 
												target='gemarkungen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.gemarkungen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.gemarkungen.size}}</span>
												<b-button @click='plus("gemarkungen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("gemarkungen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="gemarkungen" type="text" 
												v-if="formular.gemarkungen!=undefined" 
												class='textfeld' v-model='formular.gemarkungen.text' 
												style='width:100%' 
												@focus='formular.gemarkungen.showPop=true'
												@blur ='formular.gemarkungen.showPop=false'
												:disabled='formular.gemarkungen.isDisabled==true || formular.gemarkungen.isDisabled==true' 
												@keypress="isNumber($event, formular.gemarkungen.onylNumber) " 
												:style="{'width': spalte_breit_mm(73.3232843) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.3954524) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(160.2944738) + 'px', 
		
														'font-size': formular.gemarkungen.size + 'px', 
														'text-align': formular.gemarkungen.schriftausrichtung, 
														'letter-spacing': formular.gemarkungen.letterSpacing + 'px',
														'font-weight': formular.gemarkungen.schriftart }" 
												/>

<b-popover 
												v-if="formular.flure"
												:show="formular.flure.showPop==true"
												
												ref='flure' 
												target='flure'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flure.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flure.size}}</span>
												<b-button @click='plus("flure", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flure", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flure" type="text" 
												v-if="formular.flure!=undefined" 
												class='textfeld' v-model='formular.flure.text' 
												style='width:100%' 
												@focus='formular.flure.showPop=true'
												@blur ='formular.flure.showPop=false'
												:disabled='formular.flure.isDisabled==true || formular.flure.isDisabled==true' 
												@keypress="isNumber($event, formular.flure.onylNumber) " 
												:style="{'width': spalte_breit_mm(27.3026356) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(96.2041512) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(160.2944738) + 'px', 
		
														'font-size': formular.flure.size + 'px', 
														'text-align': formular.flure.schriftausrichtung, 
														'letter-spacing': formular.flure.letterSpacing + 'px',
														'font-weight': formular.flure.schriftart }" 
												/>

<b-popover 
												v-if="formular.flurstuecke"
												:show="formular.flurstuecke.showPop==true"
												
												ref='flurstuecke' 
												target='flurstuecke'  
												
												>
												<template slot='title'>
													<b-button @click="formular.flurstuecke.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.flurstuecke.size}}</span>
												<b-button @click='plus("flurstuecke", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("flurstuecke", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="flurstuecke" type="text" 
												v-if="formular.flurstuecke!=undefined" 
												class='textfeld' v-model='formular.flurstuecke.text' 
												style='width:100%' 
												@focus='formular.flurstuecke.showPop=true'
												@blur ='formular.flurstuecke.showPop=false'
												:disabled='formular.flurstuecke.isDisabled==true || formular.flurstuecke.isDisabled==true' 
												@keypress="isNumber($event, formular.flurstuecke.onylNumber) " 
												:style="{'width': spalte_breit_mm(69.4076089) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(127.5602393) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(160.2944738) + 'px', 
		
														'font-size': formular.flurstuecke.size + 'px', 
														'text-align': formular.flurstuecke.schriftausrichtung, 
														'letter-spacing': formular.flurstuecke.letterSpacing + 'px',
														'font-weight': formular.flurstuecke.schriftart }" 
												/>

<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(96.495193) + 'px', 
														top: gib_hoehe_in_mm(156.2479346) + 'px'}">
													Flur(e)
													</div>
<div :style="{
														fontSize:'9px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(127.349389) + 'px', 
														top: gib_hoehe_in_mm(156.2479346) + 'px'}">
													Flurstück(e)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													Gebäudeklassen ( 2 Absatz 3 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(93.916956) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(104.663725) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													2
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(117.1524793) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													3
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(129.680736) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													4
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(142.1624363) + 'px', 
														top: gib_hoehe_in_mm(164.8090217) + 'px'}">
													5
													</div>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(96.9251343) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='1'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(107.71458) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='2'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(120.203687) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='3'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(132.7308856) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='4'> 
								</b-form-radio>
<b-form-radio size='sm'  
								
								v-if="formular.g_klasse!=undefined"	
								
								
								:disabled="false"
								class='size_10 input_formular'
								:style="{position:'absolute', 
								
								left: spalte_breit_mm(145.2132913) + 'px',top: gib_hoehe_in_mm(165.531075) + 'px'}"
								v-model="formular.g_klasse.text" 
								name="'g_klasse_radio'"
								
								value='5'> 
								</b-form-radio>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.sonderbau_check!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(96.8792833) + 'px',top: gib_hoehe_in_mm(170.4381901) + 'px'}"
								v-model="formular.sonderbau_check.text" 
								value='1' 
								unchecked-value='0'> Sonderbau (nicht § 50 Absatz 2 BauO NRW 2018)
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(175.2630497) + 'px'}">
													Bezeichnung des Vorhabens (Errichtung, Änderung, Nutzungsänderung gemäß 60 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(189.8613027) + 'px'}">
													Das Bauvorhaben bedarf einer
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bedarf_ausnahme!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(190.5837087) + 'px'}"
								v-model="formular.bedarf_ausnahme.text" 
								value='1' 
								unchecked-value='0'> Ausnahme (§ 31 Absatz 1 BauGB)
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bedarf_befreiung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(195.0245544) + 'px'}"
								v-model="formular.bedarf_befreiung.text" 
								value='1' 
								unchecked-value='0'> Befreiung (§ 31 Absatz 2 BauGB)
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bedarf_abweichung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(72.0350953) + 'px',top: gib_hoehe_in_mm(199.3878061) + 'px'}"
								v-model="formular.bedarf_abweichung.text" 
								value='1' 
								unchecked-value='0'> Abweichung (§ 69 BauO NRW 2018)
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(207.3023177) + 'px'}">
													Hinweis Die Begründung ist separat als Anlage beizufügen.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(212.2542257) + 'px'}">
													Bei Vorbescheid ( 77 BauO NRW 2018)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(217.1955527) + 'px'}">
													planungsrechtliche Zulässigkeit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(79.3776039) + 'px', 
														top: gib_hoehe_in_mm(217.1955527) + 'px'}">
													bauordnungsrechtliche Zulässigkeit
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.planungsrechtlicheZulaessigkeit!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(62.5132534) + 'px',top: gib_hoehe_in_mm(218.5175487) + 'px'}"
								v-model="formular.planungsrechtlicheZulaessigkeit.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.bauordnungsrechtlicheZulaessigkeit!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'16px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(130.6545407) + 'px',top: gib_hoehe_in_mm(218.5175487) + 'px'}"
								v-model="formular.bauordnungsrechtlicheZulaessigkeit.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'14.94px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(223.1149168) + 'px'}">
													Fragestellung
													</div>

										 <span class='input_formular'  
										 :style="{width: spalte_breit_mm(180.2453474) + 'px', 
										 position:'absolute', 
										 left: spalte_breit_mm(17.1373403) + 'px',
										
										 top: gib_hoehe_in_mm(228.3275465) + 'px'}"><b-popover 
												v-if="formular.genaue_fragestellung"
												:show="formular.genaue_fragestellung.showPop==true"
												
												ref='genaue_fragestellung' 
												target='genaue_fragestellung'  
												
												>
												<template slot='title'>
													<b-button @click="formular.genaue_fragestellung.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.genaue_fragestellung.size}}</span>
												<b-button @click='plus("genaue_fragestellung", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("genaue_fragestellung", "-")' size='sm'>-</b-button>
											
											</b-popover>
											<textarea
												v-if="formular.genaue_fragestellung!=undefined"
												
												rows='2'
												
												class='textfeld'
												style=' width:100%;line-height: 1.2;'
												v-model="formular.genaue_fragestellung.text"
												
												@focus='formular.genaue_fragestellung.showPop=true'
												@blur ='formular.genaue_fragestellung.showPop=false'
												
												:disabled="formular.genaue_fragestellung.isDisabled==true || formular.genaue_fragestellung.isDisabled=='true'"
												:style="{'font-size': formular.genaue_fragestellung.size + 'px',
														'height': formular.genaue_fragestellung.height + 'px',
														'text-align': formular.genaue_fragestellung.schriftausrichtung + 'px',
														'letter-spacing': formular.genaue_fragestellung.letterSpacing + 'px',
														'font-weight': formular.genaue_fragestellung.schriftart + 'px', }"

												id='genaue_fragestellung'>
										</textarea>
	

										 
										 
										 </span>
									

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(237.5604507) + 'px'}">
													Bindungen zur Beurteilung des
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.1373403) + 'px', 
														top: gib_hoehe_in_mm(241.2426387) + 'px'}">
													Vorhabens
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(80.9619323) + 'px', 
														top: gib_hoehe_in_mm(237.6486257) + 'px'}">
													Bescheid vom
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(113.160268) + 'px', 
														top: gib_hoehe_in_mm(237.6486257) + 'px'}">
													erteilt von (Behörde)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(150.292524) + 'px', 
														top: gib_hoehe_in_mm(237.6486257) + 'px'}">
													Aktenzeichen
													</div>
<b-popover 
												v-if="formular.bindung_vorbescheid_bescheid_vom"
												:show="formular.bindung_vorbescheid_bescheid_vom.showPop==true"
												
												ref='bindung_vorbescheid_bescheid_vom' 
												target='bindung_vorbescheid_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_vorbescheid_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_vorbescheid_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_vorbescheid_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_vorbescheid_bescheid_vom" type="text" 
												v-if="formular.bindung_vorbescheid_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_vorbescheid_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_vorbescheid_bescheid_vom.showPop=true'
												@blur ='formular.bindung_vorbescheid_bescheid_vom.showPop=false'
												:disabled='formular.bindung_vorbescheid_bescheid_vom.isDisabled==true || formular.bindung_vorbescheid_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_vorbescheid_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(245.8866517) + 'px', 
		
														'font-size': formular.bindung_vorbescheid_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_vorbescheid_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_vorbescheid_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_vorbescheid_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_vorbescheid_erteilt_von"
												:show="formular.bindung_vorbescheid_erteilt_von.showPop==true"
												
												ref='bindung_vorbescheid_erteilt_von' 
												target='bindung_vorbescheid_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_vorbescheid_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_vorbescheid_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_vorbescheid_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_vorbescheid_erteilt_von" type="text" 
												v-if="formular.bindung_vorbescheid_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_vorbescheid_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_vorbescheid_erteilt_von.showPop=true'
												@blur ='formular.bindung_vorbescheid_erteilt_von.showPop=false'
												:disabled='formular.bindung_vorbescheid_erteilt_von.isDisabled==true || formular.bindung_vorbescheid_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_vorbescheid_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(245.8866517) + 'px', 
		
														'font-size': formular.bindung_vorbescheid_erteilt_von.size + 'px', 
														'text-align': formular.bindung_vorbescheid_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_vorbescheid_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_vorbescheid_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_vorbescheid_aktenzeichen"
												:show="formular.bindung_vorbescheid_aktenzeichen.showPop==true"
												
												ref='bindung_vorbescheid_aktenzeichen' 
												target='bindung_vorbescheid_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_vorbescheid_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_vorbescheid_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_vorbescheid_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_vorbescheid_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_vorbescheid_aktenzeichen" type="text" 
												v-if="formular.bindung_vorbescheid_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_vorbescheid_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_vorbescheid_aktenzeichen.showPop=true'
												@blur ='formular.bindung_vorbescheid_aktenzeichen.showPop=false'
												:disabled='formular.bindung_vorbescheid_aktenzeichen.isDisabled==true || formular.bindung_vorbescheid_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_vorbescheid_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(245.8866517) + 'px', 
		
														'font-size': formular.bindung_vorbescheid_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_vorbescheid_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_vorbescheid_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_vorbescheid_aktenzeichen.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_teilungsgen_bescheid_vom"
												:show="formular.bindung_teilungsgen_bescheid_vom.showPop==true"
												
												ref='bindung_teilungsgen_bescheid_vom' 
												target='bindung_teilungsgen_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_teilungsgen_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_teilungsgen_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_teilungsgen_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_teilungsgen_bescheid_vom" type="text" 
												v-if="formular.bindung_teilungsgen_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_teilungsgen_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_teilungsgen_bescheid_vom.showPop=true'
												@blur ='formular.bindung_teilungsgen_bescheid_vom.showPop=false'
												:disabled='formular.bindung_teilungsgen_bescheid_vom.isDisabled==true || formular.bindung_teilungsgen_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_teilungsgen_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(250.8036424) + 'px', 
		
														'font-size': formular.bindung_teilungsgen_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_teilungsgen_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_teilungsgen_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_teilungsgen_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_teilungsgen_aktenzeichen"
												:show="formular.bindung_teilungsgen_aktenzeichen.showPop==true"
												
												ref='bindung_teilungsgen_aktenzeichen' 
												target='bindung_teilungsgen_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_teilungsgen_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_teilungsgen_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_teilungsgen_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_teilungsgen_aktenzeichen" type="text" 
												v-if="formular.bindung_teilungsgen_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_teilungsgen_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_teilungsgen_aktenzeichen.showPop=true'
												@blur ='formular.bindung_teilungsgen_aktenzeichen.showPop=false'
												:disabled='formular.bindung_teilungsgen_aktenzeichen.isDisabled==true || formular.bindung_teilungsgen_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_teilungsgen_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(250.8036424) + 'px', 
		
														'font-size': formular.bindung_teilungsgen_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_teilungsgen_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_teilungsgen_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_teilungsgen_aktenzeichen.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_teilungsgen_erteilt_von"
												:show="formular.bindung_teilungsgen_erteilt_von.showPop==true"
												
												ref='bindung_teilungsgen_erteilt_von' 
												target='bindung_teilungsgen_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_teilungsgen_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_teilungsgen_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_teilungsgen_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_teilungsgen_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_teilungsgen_erteilt_von" type="text" 
												v-if="formular.bindung_teilungsgen_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_teilungsgen_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_teilungsgen_erteilt_von.showPop=true'
												@blur ='formular.bindung_teilungsgen_erteilt_von.showPop=false'
												:disabled='formular.bindung_teilungsgen_erteilt_von.isDisabled==true || formular.bindung_teilungsgen_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_teilungsgen_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(250.8036424) + 'px', 
		
														'font-size': formular.bindung_teilungsgen_erteilt_von.size + 'px', 
														'text-align': formular.bindung_teilungsgen_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_teilungsgen_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_teilungsgen_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_befreiung_bescheid_vom"
												:show="formular.bindung_befreiung_bescheid_vom.showPop==true"
												
												ref='bindung_befreiung_bescheid_vom' 
												target='bindung_befreiung_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_befreiung_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_befreiung_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_befreiung_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_befreiung_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_befreiung_bescheid_vom" type="text" 
												v-if="formular.bindung_befreiung_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_befreiung_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_befreiung_bescheid_vom.showPop=true'
												@blur ='formular.bindung_befreiung_bescheid_vom.showPop=false'
												:disabled='formular.bindung_befreiung_bescheid_vom.isDisabled==true || formular.bindung_befreiung_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_befreiung_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(255.1944047) + 'px', 
		
														'font-size': formular.bindung_befreiung_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_befreiung_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_befreiung_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_befreiung_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_befreiung_erteilt_von"
												:show="formular.bindung_befreiung_erteilt_von.showPop==true"
												
												ref='bindung_befreiung_erteilt_von' 
												target='bindung_befreiung_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_befreiung_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_befreiung_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_befreiung_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_befreiung_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_befreiung_erteilt_von" type="text" 
												v-if="formular.bindung_befreiung_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_befreiung_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_befreiung_erteilt_von.showPop=true'
												@blur ='formular.bindung_befreiung_erteilt_von.showPop=false'
												:disabled='formular.bindung_befreiung_erteilt_von.isDisabled==true || formular.bindung_befreiung_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_befreiung_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(255.1944047) + 'px', 
		
														'font-size': formular.bindung_befreiung_erteilt_von.size + 'px', 
														'text-align': formular.bindung_befreiung_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_befreiung_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_befreiung_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_befreiung_aktenzeichen"
												:show="formular.bindung_befreiung_aktenzeichen.showPop==true"
												
												ref='bindung_befreiung_aktenzeichen' 
												target='bindung_befreiung_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_befreiung_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_befreiung_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_befreiung_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_befreiung_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_befreiung_aktenzeichen" type="text" 
												v-if="formular.bindung_befreiung_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_befreiung_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_befreiung_aktenzeichen.showPop=true'
												@blur ='formular.bindung_befreiung_aktenzeichen.showPop=false'
												:disabled='formular.bindung_befreiung_aktenzeichen.isDisabled==true || formular.bindung_befreiung_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_befreiung_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(255.1944047) + 'px', 
		
														'font-size': formular.bindung_befreiung_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_befreiung_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_befreiung_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_befreiung_aktenzeichen.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_baulast_bescheid_vom"
												:show="formular.bindung_baulast_bescheid_vom.showPop==true"
												
												ref='bindung_baulast_bescheid_vom' 
												target='bindung_baulast_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_baulast_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_baulast_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_baulast_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_baulast_bescheid_vom" type="text" 
												v-if="formular.bindung_baulast_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_baulast_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_baulast_bescheid_vom.showPop=true'
												@blur ='formular.bindung_baulast_bescheid_vom.showPop=false'
												:disabled='formular.bindung_baulast_bescheid_vom.isDisabled==true || formular.bindung_baulast_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_baulast_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(259.9085929) + 'px', 
		
														'font-size': formular.bindung_baulast_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_baulast_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_baulast_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_baulast_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_baulast_erteilt_von"
												:show="formular.bindung_baulast_erteilt_von.showPop==true"
												
												ref='bindung_baulast_erteilt_von' 
												target='bindung_baulast_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_baulast_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_baulast_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_baulast_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_baulast_erteilt_von" type="text" 
												v-if="formular.bindung_baulast_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_baulast_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_baulast_erteilt_von.showPop=true'
												@blur ='formular.bindung_baulast_erteilt_von.showPop=false'
												:disabled='formular.bindung_baulast_erteilt_von.isDisabled==true || formular.bindung_baulast_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_baulast_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(259.9085929) + 'px', 
		
														'font-size': formular.bindung_baulast_erteilt_von.size + 'px', 
														'text-align': formular.bindung_baulast_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_baulast_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_baulast_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_denkmal_bescheid_vom"
												:show="formular.bindung_denkmal_bescheid_vom.showPop==true"
												
												ref='bindung_denkmal_bescheid_vom' 
												target='bindung_denkmal_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_denkmal_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_denkmal_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_denkmal_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_denkmal_bescheid_vom" type="text" 
												v-if="formular.bindung_denkmal_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_denkmal_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_denkmal_bescheid_vom.showPop=true'
												@blur ='formular.bindung_denkmal_bescheid_vom.showPop=false'
												:disabled='formular.bindung_denkmal_bescheid_vom.isDisabled==true || formular.bindung_denkmal_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_denkmal_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(264.7116615) + 'px', 
		
														'font-size': formular.bindung_denkmal_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_denkmal_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_denkmal_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_denkmal_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_denkmal_erteilt_von"
												:show="formular.bindung_denkmal_erteilt_von.showPop==true"
												
												ref='bindung_denkmal_erteilt_von' 
												target='bindung_denkmal_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_denkmal_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_denkmal_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_denkmal_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_denkmal_erteilt_von" type="text" 
												v-if="formular.bindung_denkmal_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_denkmal_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_denkmal_erteilt_von.showPop=true'
												@blur ='formular.bindung_denkmal_erteilt_von.showPop=false'
												:disabled='formular.bindung_denkmal_erteilt_von.isDisabled==true || formular.bindung_denkmal_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_denkmal_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(264.7116615) + 'px', 
		
														'font-size': formular.bindung_denkmal_erteilt_von.size + 'px', 
														'text-align': formular.bindung_denkmal_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_denkmal_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_denkmal_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_sonstiges_text"
												:show="formular.bindung_sonstiges_text.showPop==true"
												
												ref='bindung_sonstiges_text' 
												target='bindung_sonstiges_text'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_sonstiges_text.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_text.size}}</span>
												<b-button @click='plus("bindung_sonstiges_text", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_sonstiges_text", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_sonstiges_text" type="text" 
												v-if="formular.bindung_sonstiges_text!=undefined" 
												class='textfeld' v-model='formular.bindung_sonstiges_text.text' 
												style='width:100%' 
												@focus='formular.bindung_sonstiges_text.showPop=true'
												@blur ='formular.bindung_sonstiges_text.showPop=false'
												:disabled='formular.bindung_sonstiges_text.isDisabled==true || formular.bindung_sonstiges_text.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_sonstiges_text.onylNumber) " 
												:style="{'width': spalte_breit_mm(56.4571703) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(22.1893508) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(269.619482) + 'px', 
		
														'font-size': formular.bindung_sonstiges_text.size + 'px', 
														'text-align': formular.bindung_sonstiges_text.schriftausrichtung, 
														'letter-spacing': formular.bindung_sonstiges_text.letterSpacing + 'px',
														'font-weight': formular.bindung_sonstiges_text.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_sonstiges_bescheid_vom"
												:show="formular.bindung_sonstiges_bescheid_vom.showPop==true"
												
												ref='bindung_sonstiges_bescheid_vom' 
												target='bindung_sonstiges_bescheid_vom'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_sonstiges_bescheid_vom.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_bescheid_vom.size}}</span>
												<b-button @click='plus("bindung_sonstiges_bescheid_vom", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_sonstiges_bescheid_vom", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_sonstiges_bescheid_vom" type="text" 
												v-if="formular.bindung_sonstiges_bescheid_vom!=undefined" 
												class='textfeld' v-model='formular.bindung_sonstiges_bescheid_vom.text' 
												style='width:100%' 
												@focus='formular.bindung_sonstiges_bescheid_vom.showPop=true'
												@blur ='formular.bindung_sonstiges_bescheid_vom.showPop=false'
												:disabled='formular.bindung_sonstiges_bescheid_vom.isDisabled==true || formular.bindung_sonstiges_bescheid_vom.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_sonstiges_bescheid_vom.onylNumber) " 
												:style="{'width': spalte_breit_mm(22.1246469) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(80.9946691) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(269.619482) + 'px', 
		
														'font-size': formular.bindung_sonstiges_bescheid_vom.size + 'px', 
														'text-align': formular.bindung_sonstiges_bescheid_vom.schriftausrichtung, 
														'letter-spacing': formular.bindung_sonstiges_bescheid_vom.letterSpacing + 'px',
														'font-weight': formular.bindung_sonstiges_bescheid_vom.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_sonstiges_erteilt_von"
												:show="formular.bindung_sonstiges_erteilt_von.showPop==true"
												
												ref='bindung_sonstiges_erteilt_von' 
												target='bindung_sonstiges_erteilt_von'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_sonstiges_erteilt_von.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_erteilt_von.size}}</span>
												<b-button @click='plus("bindung_sonstiges_erteilt_von", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_sonstiges_erteilt_von", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_sonstiges_erteilt_von" type="text" 
												v-if="formular.bindung_sonstiges_erteilt_von!=undefined" 
												class='textfeld' v-model='formular.bindung_sonstiges_erteilt_von.text' 
												style='width:100%' 
												@focus='formular.bindung_sonstiges_erteilt_von.showPop=true'
												@blur ='formular.bindung_sonstiges_erteilt_von.showPop=false'
												:disabled='formular.bindung_sonstiges_erteilt_von.isDisabled==true || formular.bindung_sonstiges_erteilt_von.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_sonstiges_erteilt_von.onylNumber) " 
												:style="{'width': spalte_breit_mm(42.2486508) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(105.382816) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(269.619482) + 'px', 
		
														'font-size': formular.bindung_sonstiges_erteilt_von.size + 'px', 
														'text-align': formular.bindung_sonstiges_erteilt_von.schriftausrichtung, 
														'letter-spacing': formular.bindung_sonstiges_erteilt_von.letterSpacing + 'px',
														'font-weight': formular.bindung_sonstiges_erteilt_von.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_baulast_aktenzeichen"
												:show="formular.bindung_baulast_aktenzeichen.showPop==true"
												
												ref='bindung_baulast_aktenzeichen' 
												target='bindung_baulast_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_baulast_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_baulast_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_baulast_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_baulast_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_baulast_aktenzeichen" type="text" 
												v-if="formular.bindung_baulast_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_baulast_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_baulast_aktenzeichen.showPop=true'
												@blur ='formular.bindung_baulast_aktenzeichen.showPop=false'
												:disabled='formular.bindung_baulast_aktenzeichen.isDisabled==true || formular.bindung_baulast_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_baulast_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(259.9085929) + 'px', 
		
														'font-size': formular.bindung_baulast_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_baulast_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_baulast_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_baulast_aktenzeichen.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_denkmal_aktenzeichen"
												:show="formular.bindung_denkmal_aktenzeichen.showPop==true"
												
												ref='bindung_denkmal_aktenzeichen' 
												target='bindung_denkmal_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_denkmal_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_denkmal_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_denkmal_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_denkmal_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_denkmal_aktenzeichen" type="text" 
												v-if="formular.bindung_denkmal_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_denkmal_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_denkmal_aktenzeichen.showPop=true'
												@blur ='formular.bindung_denkmal_aktenzeichen.showPop=false'
												:disabled='formular.bindung_denkmal_aktenzeichen.isDisabled==true || formular.bindung_denkmal_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_denkmal_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(264.7116615) + 'px', 
		
														'font-size': formular.bindung_denkmal_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_denkmal_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_denkmal_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_denkmal_aktenzeichen.schriftart }" 
												/>

<b-popover 
												v-if="formular.bindung_sonstiges_aktenzeichen"
												:show="formular.bindung_sonstiges_aktenzeichen.showPop==true"
												
												ref='bindung_sonstiges_aktenzeichen' 
												target='bindung_sonstiges_aktenzeichen'  
												
												>
												<template slot='title'>
													<b-button @click="formular.bindung_sonstiges_aktenzeichen.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.bindung_sonstiges_aktenzeichen.size}}</span>
												<b-button @click='plus("bindung_sonstiges_aktenzeichen", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("bindung_sonstiges_aktenzeichen", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="bindung_sonstiges_aktenzeichen" type="text" 
												v-if="formular.bindung_sonstiges_aktenzeichen!=undefined" 
												class='textfeld' v-model='formular.bindung_sonstiges_aktenzeichen.text' 
												style='width:100%' 
												@focus='formular.bindung_sonstiges_aktenzeichen.showPop=true'
												@blur ='formular.bindung_sonstiges_aktenzeichen.showPop=false'
												:disabled='formular.bindung_sonstiges_aktenzeichen.isDisabled==true || formular.bindung_sonstiges_aktenzeichen.isDisabled==true' 
												@keypress="isNumber($event, formular.bindung_sonstiges_aktenzeichen.onylNumber) " 
												:style="{'width': spalte_breit_mm(47.3356429) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(150.2490776) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(269.619482) + 'px', 
		
														'font-size': formular.bindung_sonstiges_aktenzeichen.size + 'px', 
														'text-align': formular.bindung_sonstiges_aktenzeichen.schriftausrichtung, 
														'letter-spacing': formular.bindung_sonstiges_aktenzeichen.letterSpacing + 'px',
														'font-weight': formular.bindung_sonstiges_aktenzeichen.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(169.080853) + 'px', 
														top: gib_hoehe_in_mm(273.6243784) + 'px'}">
													Fortsetzung Blatt 2
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(16.1647412) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(199.0483612) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(262.5518294) + 'px', 
													top: gib_hoehe_in_mm(16.2934767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.9762476) + 'px', 
													width: spalte_breit_mm(50.0721136) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(278.7453061) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(257.6436562) + 'px', 
													top: gib_hoehe_in_mm(16.1647412) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(39.5716767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(47.6943577) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(69.8756607) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(74.3196807) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(95.2277367) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(103.1846487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(127.8175694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(135.7748341) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(143.9430134) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(148.3870334) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(164.5544487) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(169.7183294) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(175.0091821) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(180.1688304) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(189.8627899) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(211.9961257) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(223.0885407) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(237.6483494) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.5652403) + 'px', 
													width: spalte_breit_mm(119.4831209) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(242.1322245) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(250.0916054) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(254.5356254) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(258.9796454) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(263.8465527) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(268.6686671) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(101.4887196) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(127.7495367) + 'px', 
													top: gib_hoehe_in_mm(16.2934767) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(95.1827963) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.2681793) + 'px', 
													top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(126.0835487) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.2681793) + 'px', 
													top: gib_hoehe_in_mm(156.3862694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(79.5652403) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(36.160048) + 'px', 
													top: gib_hoehe_in_mm(237.6483494) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(103.990068) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(36.160048) + 'px', 
													top: gib_hoehe_in_mm(237.6483494) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(148.9762476) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(41.1969567) + 'px', 
													top: gib_hoehe_in_mm(237.6483494) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(59.0359841) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.0572647) + 'px', 
													top: gib_hoehe_in_mm(127.8175694) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(146.6099833) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(7.2947273) + 'px', 
													top: gib_hoehe_in_mm(128.4951061) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(15.8693838) + 'px', 
													width: spalte_breit_mm(183.1789774) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(273.7083974) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(209.0033187) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(4.9302265) + 'px', 
													top: gib_hoehe_in_mm(0.074919899999941) + 'px'}"></div>
</div><div style="font-family:Arial, Verdana, sans-serif; border: solid 1px black; 
				position:relative; width:909px; height:1286px; overflow: hidden; background-color: white; margin: 0 auto 10px;"><div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(16.6221167) + 'px'}">
													Die erforderlichen Bauvorlagen sind beigefügt
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(21.4047287) + 'px'}">
													(einem Antrag auf Vorbescheid sind nur die für die Klärung der Fragestellung erforderlichen Unterlagen beizufügen)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(161.6868502) + 'px', 
														top: gib_hoehe_in_mm(9.1011419) + 'px'}">
													Anlage I/2 zu VV BauPrüfVO
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(191.3136502) + 'px', 
														top: gib_hoehe_in_mm(12.4024139) + 'px'}">
													Blatt 2
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(27.8344497) + 'px'}">
													Lageplan/amtlicher Lageplan ( 3 BauPrüfVO Anforderungen an Planersteller/in sind zu beachten)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(32.5747377) + 'px'}">
													Berechnung des Maßes der baulichen Nutzung ( 3 Absatz 2 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(36.9961849) + 'px'}">
													(nur im Bereich eines Bebauungsplanes oder einer Satzung nach BauGB)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(41.2934817) + 'px'}">
													Beglaubigter Auszug aus der Flurkarte
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(45.6690779) + 'px'}">
													(nur bei Vorhaben nach den 34 oder 35 des Baugesetzbuches Auszug nicht erforderlich bei
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(48.9315529) + 'px'}">
													Vorlage eines amtlichen Lageplanes)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(53.2711737) + 'px'}">
													Auszug aus der amtlichen Basiskarte 1 5 000
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(57.6502969) + 'px'}">
													(nur bei Vorhaben nach den 34 oder 35 des Baugesetzbuches)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(61.9899177) + 'px'}">
													Bauzeichnungen ( 4 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(66.7302057) + 'px'}">
													Baubeschreibung auf amtlichem Vordruck ( 5 Absatz 1 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(27.8344497) + 'px'}">
													 1.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(32.5747377) + 'px'}">
													 2.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.lageplan!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(28.2612931) + 'px'}"
								v-model="formular.lageplan.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.berechnungMassBaulichenNutzung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(33.0012284) + 'px'}"
								v-model="formular.berechnungMassBaulichenNutzung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(27.8344497) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(32.5747377) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(41.2934817) + 'px'}">
													 3.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.beglaubigterAuszugFlurkarte!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(41.7203251) + 'px'}"
								v-model="formular.beglaubigterAuszugFlurkarte.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(41.2934817) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(53.2711737) + 'px'}">
													 4.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.Basiskarte!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(53.6980171) + 'px'}"
								v-model="formular.Basiskarte.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(53.2711737) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(61.9899177) + 'px'}">
													 5.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(66.7302057) + 'px'}">
													 6.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(71.5128177) + 'px'}">
													 7.1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(76.2531057) + 'px'}">
													 7.2
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.Bauzeichnungen!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(62.4167611) + 'px'}"
								v-model="formular.Bauzeichnungen.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.Baubeschreibung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(67.1570491) + 'px'}"
								v-model="formular.Baubeschreibung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.umbauterRaum!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(71.9396611) + 'px'}"
								v-model="formular.umbauterRaum.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.ohneRohbauwerteHerstellungssumme!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(76.6799491) + 'px'}"
								v-model="formular.ohneRohbauwerteHerstellungssumme.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(61.9899177) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(66.7302057) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(71.5128177) + 'px'}">
													2fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(76.2531057) + 'px'}">
													2fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(71.5128177) + 'px'}">
													bei Gebäuden Berechnung des umbauten Raumes nach DIN 277 ( 6 Nummer 1 BauPrüfVO) oder
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(76.2531057) + 'px'}">
													Bei Gebäuden, für die landesdurchschnittliche Rohbauwerte je m Bruttorauminhalt nicht festgelegt sind, die
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(80.0199417) + 'px'}">
													Berechnung der Rohbaukosten einschließlich Umsatzsteuer ( 6 Nummer 1 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(83.6601584) + 'px'}">
													oder
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(88.5732694) + 'px'}">
													 7.3
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.herstellungssummeCheck!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(88.9997601) + 'px'}"
								v-model="formular.herstellungssummeCheck.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.4066935) + 'px', 
														top: gib_hoehe_in_mm(88.5732694) + 'px'}">
													bei der Änderung von Gebäuden oder bei baulichen Anlagen, die nicht Gebäude sind
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.4066935) + 'px', 
														top: gib_hoehe_in_mm(92.2977814) + 'px'}">
													Herstellungssumme einschließlich Umsatzsteuer gemäß Tarifstelle 2.1.3 AVerwGebO NRW
													</div>
<b-popover 
												v-if="formular.herstellungssumme"
												:show="formular.herstellungssumme.showPop==true"
												
												ref='herstellungssumme' 
												target='herstellungssumme'  
												
												>
												<template slot='title'>
													<b-button @click="formular.herstellungssumme.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.herstellungssumme.size}}</span>
												<b-button @click='plus("herstellungssumme", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("herstellungssumme", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="herstellungssumme" type="text" 
												v-if="formular.herstellungssumme!=undefined" 
												class='textfeld' v-model='formular.herstellungssumme.text' 
												style='width:100%' 
												@focus='formular.herstellungssumme.showPop=true'
												@blur ='formular.herstellungssumme.showPop=false'
												:disabled='formular.herstellungssumme.isDisabled==true || formular.herstellungssumme.isDisabled==true' 
												@keypress="isNumber($event, formular.herstellungssumme.onylNumber) " 
												:style="{'width': spalte_breit_mm(19.2596648) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(178.0912156) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(89.2187225) + 'px', 
		
														'font-size': formular.herstellungssumme.size + 'px', 
														'text-align': formular.herstellungssumme.schriftausrichtung, 
														'letter-spacing': formular.herstellungssumme.letterSpacing + 'px',
														'font-weight': formular.herstellungssumme.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(97.5882814) + 'px'}">
													zusätzliche Bauvorlagen für Sonderbauten, die nicht in 50 Absatz 2 BauO NRW 2018 aufgeführt sind
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(102.6636344) + 'px'}">
													 8.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.Betriebsbeschreibung!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8530055) + 'px',top: gib_hoehe_in_mm(103.0897724) + 'px'}"
								v-model="formular.Betriebsbeschreibung.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1977258) + 'px', 
														top: gib_hoehe_in_mm(102.6636344) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(110.7051944) + 'px'}">
													 9.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.zusaeztlicheAngaben!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(25.0223015) + 'px',top: gib_hoehe_in_mm(111.5161281) + 'px'}"
								v-model="formular.zusaeztlicheAngaben.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(110.7051944) + 'px'}">
													3fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7463436) + 'px', 
														top: gib_hoehe_in_mm(102.6636344) + 'px'}">
													Betriebsbeschreibung für gewerbliche oder landwirtschaftliche Betriebe auf amtlichem
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7452855) + 'px', 
														top: gib_hoehe_in_mm(106.4304704) + 'px'}">
													Vordruck ( 5 Absatz 2 oder 3 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(44.7459909) + 'px', 
														top: gib_hoehe_in_mm(110.7051944) + 'px'}">
													zusätzliche Angaben und Bauvorlagen für besondere Vorhaben ( 12 BauPrüfVO)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(117.5652094) + 'px'}">
													Spätestens mit Anzeige des Baubeginns werden gemäß 68 Absatz 1 und 2 BauO NRW 2018 eingereicht
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisSchallschutzesSachverstaendiger!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7683575) + 'px',top: gib_hoehe_in_mm(124.3403001) + 'px'}"
								v-model="formular.NachweisSchallschutzesSachverstaendiger.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(123.9138094) + 'px'}">
													10.1
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(123.9138094) + 'px'}">
													2fach
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisWaermeschutzesSachverstaendiger!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(132.7592491) + 'px'}"
								v-model="formular.NachweisWaermeschutzesSachverstaendiger.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(131.9553694) + 'px'}">
													2fach
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisStandsicherheitSachverstaendiger!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(140.8046888) + 'px'}"
								v-model="formular.NachweisStandsicherheitSachverstaendiger.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(139.9934024) + 'px'}">
													2fach
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisBrandschutzSachverstaendiger!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(148.7996924) + 'px'}"
								v-model="formular.NachweisBrandschutzSachverstaendiger.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.1980785) + 'px', 
														top: gib_hoehe_in_mm(147.9926384) + 'px'}">
													2fach
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1233799) + 'px', 
														top: gib_hoehe_in_mm(123.9138094) + 'px'}">
													Bescheinigung zusammen mit dem Nachweis des Schallschutzes, soweit erforderlich aufgestellt oder geprüft
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1226745) + 'px', 
														top: gib_hoehe_in_mm(127.7229694) + 'px'}">
													durch eine/einen staatlich anerkannte/n Sachverständige/n
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1233799) + 'px', 
														top: gib_hoehe_in_mm(131.9553694) + 'px'}">
													Bescheinigung zusammen mit dem Nachweis des Wärmeschutzes, soweit erforderlich aufgestellt oder geprüft
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1226745) + 'px', 
														top: gib_hoehe_in_mm(135.7222054) + 'px'}">
													durch eine/einen staatlich anerkannte/n Sachverständige/n
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1233799) + 'px', 
														top: gib_hoehe_in_mm(139.9934024) + 'px'}">
													Bescheinigung zusammen mit dem Nachweis der Standsicherheit, soweit erforderlich geprüft durch eine/einen
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1226745) + 'px', 
														top: gib_hoehe_in_mm(143.7214414) + 'px'}">
													staatlich anerkannte/n Sachverständige/n
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1233799) + 'px', 
														top: gib_hoehe_in_mm(147.9926384) + 'px'}">
													die Bescheinigung einer/eines staatlich anerkannte/n Sachverständige/n, dass das Vorhaben den
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1226745) + 'px', 
														top: gib_hoehe_in_mm(151.7206774) + 'px'}">
													Anforderungen an den Brandschutz entspricht (gilt nicht für Wohngebäude der Gebäudeklasse 1 bis 3
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(45.1226745) + 'px', 
														top: gib_hoehe_in_mm(155.3993384) + 'px'}">
													und Sonderbauten)
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(161.4939944) + 'px'}">
													10.2 Abweichend von Nr. 10.1 wird soweit erforderlich eine Prüfung durch die Bauaufsichtsbehörde beantragt für
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.4820975) + 'px', 
														top: gib_hoehe_in_mm(165.7722454) + 'px'}">
													den Nachweis des Schallschutzes
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.4820975) + 'px', 
														top: gib_hoehe_in_mm(170.1280904) + 'px'}">
													den Nachweis des Wärmeschutzes
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.4820975) + 'px', 
														top: gib_hoehe_in_mm(174.4451384) + 'px'}">
													den Nachweis der Standsicherheit
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(30.4820975) + 'px', 
														top: gib_hoehe_in_mm(178.8080374) + 'px'}">
													den Nachweis des Brandschutzes (gilt nicht für Wohngebäude der Gebäudeklasse 1 bis 3 und Sonderbauten)
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisSchallschutzes!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7718845) + 'px',top: gib_hoehe_in_mm(166.5757724) + 'px'}"
								v-model="formular.NachweisSchallschutzes.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisWaermeschutzes!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(170.9390241) + 'px'}"
								v-model="formular.NachweisWaermeschutzes.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisStandsicherheit!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(175.2528978) + 'px'}"
								v-model="formular.NachweisStandsicherheit.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.NachweisBrandschutz!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.7694156) + 'px',top: gib_hoehe_in_mm(179.6119171) + 'px'}"
								v-model="formular.NachweisBrandschutz.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.erhebungsbogen_statistik!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8480677) + 'px',top: gib_hoehe_in_mm(186.4405418) + 'px'}"
								v-model="formular.erhebungsbogen_statistik.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(185.5798774) + 'px'}">
													11.
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(32.0480855) + 'px', 
														top: gib_hoehe_in_mm(185.5798774) + 'px'}">
													Erhebungsbogen für die Baustatistik gemäß Hochbaustatistikgesetz
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(193.7025584) + 'px'}">
													12.
													</div>
<b-form-checkbox size='sm'  
								style='min-height:1rem !important' 
								
								:disabled="false"
								class='size_10 input_formular'
								
								v-if="formular.Artenschutz!=undefined"
								
								:style="{position:'absolute', 
														fontSize:'12px',
														fontWeight:'normal',
														paddingBottom :'5px',
														left: spalte_breit_mm(24.8505366) + 'px',top: gib_hoehe_in_mm(194.5138448) + 'px'}"
								v-model="formular.Artenschutz.text" 
								value='1' 
								unchecked-value='0'>  
								</b-form-checkbox>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(31.7518175) + 'px', 
														top: gib_hoehe_in_mm(193.7025584) + 'px'}">
													Angaben zum Artenschutz gemäß 44 BNatSchG
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(200.1097066) + 'px'}">
													Ort, Datum
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.2812072) + 'px', 
														top: gib_hoehe_in_mm(200.1097066) + 'px'}">
													Ort, Datum
													</div>
<b-popover 
												v-if="formular.ort_datum_bauherr"
												:show="formular.ort_datum_bauherr.showPop==true"
												
												ref='ort_datum_bauherr' 
												target='ort_datum_bauherr'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_bauherr.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_bauherr.size}}</span>
												<b-button @click='plus("ort_datum_bauherr", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_bauherr", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_bauherr" type="text" 
												v-if="formular.ort_datum_bauherr!=undefined" 
												class='textfeld' v-model='formular.ort_datum_bauherr.text' 
												style='width:100%' 
												@focus='formular.ort_datum_bauherr.showPop=true'
												@blur ='formular.ort_datum_bauherr.showPop=false'
												:disabled='formular.ort_datum_bauherr.isDisabled==true || formular.ort_datum_bauherr.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_bauherr.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.8677652) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(17.6504545) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(204.3505835) + 'px', 
		
														'font-size': formular.ort_datum_bauherr.size + 'px', 
														'text-align': formular.ort_datum_bauherr.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_bauherr.letterSpacing + 'px',
														'font-weight': formular.ort_datum_bauherr.schriftart }" 
												/>

<b-popover 
												v-if="formular.ort_datum_entwurfverfasser"
												:show="formular.ort_datum_entwurfverfasser.showPop==true"
												
												ref='ort_datum_entwurfverfasser' 
												target='ort_datum_entwurfverfasser'  
												
												>
												<template slot='title'>
													<b-button @click="formular.ort_datum_entwurfverfasser.showPop=false;" class="close" aria-label="Close">
													  <span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Schriftgröße
												  </template>

												<span style='font-size:1.0em; font-weight:700; margin-right:10px;'>{{formular.ort_datum_entwurfverfasser.size}}</span>
												<b-button @click='plus("ort_datum_entwurfverfasser", "+")' style=' margin-right:10px;' size='sm'>+</b-button>
												<b-button @click='plus("ort_datum_entwurfverfasser", "-")' size='sm'>-</b-button>
											
											</b-popover><input id="ort_datum_entwurfverfasser" type="text" 
												v-if="formular.ort_datum_entwurfverfasser!=undefined" 
												class='textfeld' v-model='formular.ort_datum_entwurfverfasser.text' 
												style='width:100%' 
												@focus='formular.ort_datum_entwurfverfasser.showPop=true'
												@blur ='formular.ort_datum_entwurfverfasser.showPop=false'
												:disabled='formular.ort_datum_entwurfverfasser.isDisabled==true || formular.ort_datum_entwurfverfasser.isDisabled==true' 
												@keypress="isNumber($event, formular.ort_datum_entwurfverfasser.onylNumber) " 
												:style="{'width': spalte_breit_mm(85.8681179) + 'px', 
														'position':'absolute', 
														'left': spalte_breit_mm(106.5308545) + 'px', 
														'height': 16.5 + 'px', 
														'top': gib_hoehe_in_mm(204.3505835) + 'px', 
		
														'font-size': formular.ort_datum_entwurfverfasser.size + 'px', 
														'text-align': formular.ort_datum_entwurfverfasser.schriftausrichtung, 
														'letter-spacing': formular.ort_datum_entwurfverfasser.letterSpacing + 'px',
														'font-weight': formular.ort_datum_entwurfverfasser.schriftart }" 
												/>

<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(208.9815224) + 'px'}">
													Für die Bauherrschaft
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.2812072) + 'px', 
														top: gib_hoehe_in_mm(208.9815224) + 'px'}">
													Die/Der bauvorlageberechtigte () Entwurfsverfassende
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(17.4004545) + 'px', 
														top: gib_hoehe_in_mm(235.0270066) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'12.045px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(106.2812072) + 'px', 
														top: gib_hoehe_in_mm(235.0270066) + 'px'}">
													Unterschrift
													</div>
<div :style="{
														fontSize:'13.5px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: spalte_breit_mm(16.1342615) + 'px', 
														top: gib_hoehe_in_mm(242.2058624) + 'px'}">
													() Nach 67 Absatz. 2 BauO NRW 2018 kann in bestimmten Fällen auf die Bauvorlageberechtigung verzichtet werden.
													</div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(185.5466525) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(201.6798559) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(223.1919202) + 'px', 
													top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(185.5466525) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(208.9847731) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(223.1919202) + 'px', 
													top: gib_hoehe_in_mm(16.2391609) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.2612335) + 'px', 
													width: spalte_breit_mm(185.4186224) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(97.2917371) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.6691935) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(8.9933305) + 'px', 
													top: gib_hoehe_in_mm(88.2731981) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(176.6691935) + 'px', 
													width: spalte_breit_mm(25.0106624) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(88.2731981) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(185.5466525) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(183.8869938) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(185.5466525) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(191.7624321) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(105.0069021) + 'px', 
													width: spalte_breit_mm(0) + 'px', 
													height:gib_hoehe_in_mm(39.2493473) + 'px', 
													top: gib_hoehe_in_mm(200.1817338) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.5515056) + 'px', 
													width: spalte_breit_mm(185.1283503) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(200.1817338) + 'px'}"></div>
<div :style="{	border: 'solid 1px grey',
													position:'absolute',
													left: spalte_breit_mm(16.1332034) + 'px', 
													width: spalte_breit_mm(185.5466525) + 'px', 
													height:gib_hoehe_in_mm(0.1) + 'px', 
													top: gib_hoehe_in_mm(239.3310811) + 'px'}"></div>
</div>
		</div>
		</template>
		<script>

		var leeresFormular = 
	
		{
    "bauaufsicht": {
        "text": "",
        "datenuebernehmen": "bauamt.anzeigeName<nl>bauamt.adresse.strasse",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauaufsicht_plz": {
        "text": "",
        "datenuebernehmen": "bauamt.adresse.plz<&>bauamt.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": null,
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ist_antrag": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ist_voranfrage": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ist_referenzgeb": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bauherr_email": {
        "text": "",
        "datenuebernehmen": "bauherr.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "wohngebaude_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bezeichnung_bauvorhaben": {
        "text": "",
        "datenuebernehmen": "bezeichnung_bauvorhaben",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_vorbescheid": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bindung_teilungsgen": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bindung_befreiung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bindung_baulast": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bindung_denkmal": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bindung_sonstiges": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bauherr_name": {
        "text": "",
        "datenuebernehmen": "bauherr.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_name": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_strasse": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_strasse": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.strasse",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_ort": {
        "text": "",
        "datenuebernehmen": "bauherr.adresse.plz<&>bauherr.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_ort": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.adresse.plz<&>entwurfsverfasser.adresse.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_vertreter": {
        "text": "",
        "datenuebernehmen": "bauherr.vertreter.anzeigeName<nl>bauherr.vertreter.adresse.strasse<nl>bauherr.vertreter.adresse.plz<&>bauherr.vertreter.adresse.ort",
        "height": 68,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_tel": {
        "text": "",
        "datenuebernehmen": "bauherr.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bauherr_fax": {
        "text": "",
        "datenuebernehmen": "bauherr.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_tel": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.telefon",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_fax": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.fax",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_vorlagenberechtigt": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.anzeigeName",
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_mitgliedsnummer": {
        "text": "",
        "datenuebernehmen": "bauvorlageberechtigter.AK_Mitgliedsnummer",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "antragsteller_email": {
        "text": "",
        "datenuebernehmen": "entwurfsverfasser.email",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "baugrund_strasse": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.strasse<&>baugrundstueck.plz<&>baugrundstueck.ort",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "gemarkungen": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.gemarkung",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "flure": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flur",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "flurstuecke": {
        "text": "",
        "datenuebernehmen": "baugrundstueck.flurstueck",
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "g_klasse": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "sonderbau_check": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bedarf_ausnahme": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bedarf_befreiung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bedarf_abweichung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "planungsrechtlicheZulaessigkeit": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "bauordnungsrechtlicheZulaessigkeit": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "genaue_fragestellung": {
        "text": "",
        "datenuebernehmen": null,
        "height": 34,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_vorbescheid_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_vorbescheid_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_vorbescheid_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_teilungsgen_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_teilungsgen_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_teilungsgen_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_befreiung_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_befreiung_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_befreiung_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_baulast_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_baulast_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_denkmal_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_denkmal_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_sonstiges_text": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_sonstiges_bescheid_vom": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_sonstiges_erteilt_von": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_baulast_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_denkmal_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "bindung_sonstiges_aktenzeichen": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "lageplan": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "berechnungMassBaulichenNutzung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "beglaubigterAuszugFlurkarte": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "Basiskarte": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "Bauzeichnungen": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "Baubeschreibung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "umbauterRaum": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ohneRohbauwerteHerstellungssumme": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "herstellungssummeCheck": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "herstellungssumme": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "Betriebsbeschreibung": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "zusaeztlicheAngaben": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisSchallschutzesSachverstaendiger": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisWaermeschutzesSachverstaendiger": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisStandsicherheitSachverstaendiger": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisBrandschutzSachverstaendiger": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisSchallschutzes": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisWaermeschutzes": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisStandsicherheit": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "NachweisBrandschutz": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "erhebungsbogen_statistik": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "Artenschutz": {
        "text": "0",
        "size": "13",
        "sichtbar": "true"
    },
    "ort_datum_bauherr": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    },
    "ort_datum_entwurfverfasser": {
        "text": "",
        "datenuebernehmen": null,
        "height": 0,
        "size": "13",
        "schriftausrichtung": "left",
        "letterSpacing": "normal",
        "sichtbar": "true",
        "anzahlStellen": 0,
        "schriftart": "normal",
        "isDisabled": "false",
        "zahl": false,
        "welchemethod": "leer",
        "showPop": false
    }
}
var formulaName = "NRWbauantrag64"

import { formularMixin } from '../../../mixins/formularMixin.js'



export default {

	mixins: [formularMixin],

	name: formulaName,

    data(){
        return{
			
			 
			gesamthoehe_px : 1286, // in px
			gesamtbreite_px : 909, // in px
			gesamthoehe_mm : 297, // in mm
			gesamtbreite_mm : 210, // in mm

        }
	},

	methods:{

		//liegt in formular.vue
		isNumber(einsatz){
			this.$root.$emit('isNumber', einsatz);
		},

		//liegt in formular.vue
		setFocus(einsatz){
			this.$root.$emit('setFocus', einsatz);
		},
		
	},

  mounted() {
	  
	  		setTimeout(()=>{
				this.$root.$emit("bv::disable::popover")
			},150);
			
		
	},
	
	computed:{

		formular(){
			if(this.actualformular.formularContent[formulaName]!=undefined)
			return this.actualformular.formularContent[formulaName]
			else
			return [];
		},},


	created(){
		
		var	pos = this.options_formulare_neu.map(function(e) { return e.value; }).indexOf(formulaName);

		if(pos>=0){
 			this.options_formulare_neu[pos].blanc = leeresFormular
		}
	},

	
}
</script>