<template>

    <div :class="{'kontainer':!itsMobile}" :style="{ height: hereHeight }">
      <!-- {{hereHeight}} -->

<!--  -->
      <b-row v-if="admin==true">
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightPos.eins" ></b-input>
          </b-col>
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightPos.zwei" ></b-input>
          </b-col>
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightPos.drei" ></b-input>
          </b-col>
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightTarg.eins" ></b-input>
          </b-col>
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightTarg.zwei" ></b-input>
          </b-col>
          <b-col cols="2">
            <b-input @input="changeLight" v-model="directionalLightTarg.drei" ></b-input>
          </b-col>
      </b-row >

      <span v-if="!itsMobile">
        <b-row >
          <b-col cols="2" class="h3">3d - Modell</b-col>

              <b-col v-if="loading==false" cols="3">
                Hintergrundfarbe:

                <b-form-input value="#caf4ff"  @input="changeBackground" v-model="backgroudColor" type="color"></b-form-input>
              </b-col>

          <b-col cols="7" class="h5">linke Maustaste = Modell drehen, rechte Maustaste = Modell verschieben, Mausrad = vergrößern / verkleinern</b-col>

        </b-row >

      </span>

    <b-row v-if="itsMobile" class="m-1 mb-2">
        <b-col cols="2">
          <span class="h4">3d - Modell</span>
        </b-col>


        <b-col cols="6">

						<b-button @click="erklarung = !erklarung" variant="success" >Erklärungen			
               <span v-if="erklarung">aus</span>
               <span v-else>ein</span>
               </b-button>	

        </b-col>

    </b-row>

      <b-row v-if="itsMobile && erklarung" class="m-1 mb-2">
        <b-col cols="12">
          	<p >1 Finger über Modell streichen = Modell drehen</p>
          	<p >2 Finger parallel über Modell streichen = Modell verschieben</p>
            <p >2 Finger zueinander / auseinander = vergrößern / verkleinern</p>         
        </b-col>
    </b-row>
    
    	<div style="margin-top:50px; border:1px solid black; height:100%; width:100%" id="modell">
        		<b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <span class="ml-4" v-if="loading">Das Modell wird geladen. Einen Moment Geduld bitte.</span>
      </div>
		 

	</div>


</template>


<script>
  import * as THREE from 'three'
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';
  //import { IFCLoader } from 'three/examples/jsm/loaders/IFCLoader.js';

  import { IFCLoader } from "web-ifc-three/IFCLoader";

// window.addEventListener("resize", () => {
//   (size.width = window.innerWidth), (size.height = window.innerHeight);
//   camera.aspect = size.width / size.height;
//   camera.updateProjectionMatrix();
//   renderer.setSize(size.width, size.height);
// });



const ifcLoader = new IFCLoader();

//ifcLoader.ifcManager.setWasmPath = "https://account.archi-app.de/static/web-ifc.wasm/"

ifcLoader.ifcManager.setWasmPath("../files/");

//ifcLoader.setWasmPath = "https://account.archi-app.de/static/web-ifc.wasm/"


// ifcLoader.load(
//     "/Wilhem.ifc",
//     (ifcModel) => scene.add(ifcModel.mesh));


  const loaderCollada = new ColladaLoader();
  // const loaderIFC = new IFCLoader();

 //console.log(loaderIFC.ifcManager)

export default {
  name: 'ThreeDModell',
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      container: null,
     	mesh: null,
		  loading: false,
		  // backgroudColor:'#caf4ff',
      backgroudColor:'#000',
      modells:[],
      modellsLocal:null,
      modell:null,
      erklarung:true,
      directionalLight:null,
      
      directionalLightPos:{
        eins:1,
        zwei:10,
        drei:1
      },
      
      directionalLightTarg:{
        eins:0,
        zwei:0,
        drei:0
      },
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },

    }
  },

  computed: {



	hereHeight() {
		  var hierHeight = "500 px";

		  if(this.itsMobile)
		  hierHeight = this.window.height - 50 + 'px'
		  else
		  hierHeight = this.window.height - 170 + 'px'

		  return hierHeight;
 	  },
  },


  methods: {

        changeBackground(){

       this.scene.background = new THREE.Color(this.backgroudColor);
    
    },

  laodAnderes(){

// 				loaderIFC.ifcManager.setWasmPath( '/static/web-ifc.wasm' );



// var model = "/static/Wilhem.ifc";

// console.log(model)
// 				  loaderIFC.load( '/static/Wilhem.ifc', function ( model ) {

// 				//  	this.scene.add( model.mesh );
// 				// // 	render();

// 				  } );


				// loaderIFC.ifcManager.setWasmPath( 'three/examples/jsm/loaders/ifc/' );

				// loaderIFC.load( '/static/Wilhem.ifc', function ( model ) {

				// 	scene.add( model.mesh );
				// 	render();

				// } );


            //         var modellPfad = '/static/Wilhem.ifc';

            //         loaderIFC.load( modellPfad , function ( object ) {
                  
            //  //         console.log(object)

            //         self.scene.add( object.scene );
            //         self.loading = false


            //         } ); 



  },

      changeLight(){
        this.directionalLight.position.set(this.directionalLightPos.eins, this.directionalLightPos.zwei, this.directionalLightPos.drei);
        this.directionalLight.target.position.set(this.directionalLightTarg.eins, this.directionalLightTarg.zwei, this.directionalLightTarg.drei);
    },

    loadModell(){

    if(this.$route.params.modell)
    this.modell = this.$route.params.modell

    //console.log(this.modell)
      var self = this
      var index
      self.loading = true


       var modellPfad = 'https://account.archi-app.de/api/php/get_file.php?id='+ this.modell +'&besonders=' + this.$store.state.dev_sub
        
                    loaderCollada.load( modellPfad , function ( object ) {
                  
             //         console.log(object)

                    self.scene.add( object.scene );
                    self.loading = false


                    } ); 


    },



    init: function() {

        this.camera = new THREE.PerspectiveCamera(15, this.container.clientWidth/this.container.clientHeight, 1, 10000);
        this.camera.position.z = 100;

        this.scene = new THREE.Scene();

		    this.scene.background = new THREE.Color( this.backgroudColor );

        const lightColor = 0xffffff;
        const ambientLight = new THREE.AmbientLight(lightColor, 0.8);
        this.scene.add(ambientLight);

        this.directionalLight = new THREE.DirectionalLight(lightColor, 0.3);
        this.directionalLight.position.set(this.directionalLightPos.eins, this.directionalLightPos.zwei, this.directionalLightPos.drei);
        this.directionalLight.target.position.set(this.directionalLightTarg.eins, this.directionalLightTarg.zwei, this.directionalLightTarg.drei);

        this.scene.add(this.directionalLight);
        this.scene.add(this.directionalLight.target);

        this.renderer = new THREE.WebGLRenderer({antialias: true});

        const controls = new OrbitControls( this.camera, this.renderer.domElement );

        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
        this.container.appendChild(this.renderer.domElement);


    },

    animate: function() {
         requestAnimationFrame(this.animate);
        // this.mesh.rotation.x += 0.01;
        // this.mesh.rotation.y += 0.02;
        this.renderer.render(this.scene, this.camera);

        
    }
  },

  mounted() {

        this.container = document.getElementById('modell');

      window.addEventListener("resize", () => {
        
        this.size.width = window.innerWidth
        this.size.height = window.innerHeight

        this.camera.aspect = this.size.width / this.size.height ;

        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      });

      this.init();
      this.animate();
      if(this.$route.params.modell)
      this.loadModell(); 
      // else
      // this.laodAnderes();

  }
}
</script>