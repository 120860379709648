<template>
<span>

    <b-card style="margin-bottom:200px;" v-if="pureRule.origOptions && !getShareStatusCalender">
{{pureRule.toText()}}
    </b-card>

    <b-card style="margin-bottom:200px;" v-if="pureRule.origOptions && getShareStatusCalender">
<!-- {{pureRule}} -->


    <h4>Termin</h4>


        <b-card class="bv-example-row">
            <b-row> <!-- große row -->
                <b-col cols="8">

                        <b-row> 
                        <b-col cols="3">
                                Beginn
                            </b-col>

                            <b-col cols>
                                    <!-- <date-picker v-on:input="updateDuration" :editable=false :clearable=false :lang="lang" :format="format_uhrzeit" v-model="localRRuleStart" type="time" :width="160" :time-picker-options="timePickerOptions"></date-picker> -->
<!-- {{localRRuleStart}} -->
                        <vue-timepicker format="HH:mm" v-model="localStartZeit" :minute-interval="5" v-on:input="ChangeStartZeit(); updateDauer();" hide-clear-button></vue-timepicker>

                        </b-col>
                        </b-row>

                         <b-row> 
                        <b-col cols="3">
                                Ende
                        </b-col>

                            <b-col cols>

<!-- {{localRRuleEnd}} -->
                                 <vue-timepicker format="HH:mm" v-model="localEndZeit" :minute-interval="5" v-on:input="ChangeEndZeit(); updateDuration();" hide-clear-button></vue-timepicker>

                                <!-- <date-picker v-on:input="updateDuration" :editable=false :clearable=false :lang="lang" :format="format_uhrzeit" v-model="localRRuleEnd" type="time" :width="160" :time-picker-options="timePickerOptions"></date-picker> -->
                  

                       </b-col>
                </b-row>

             </b-col>
            
             <!-- <b-col cols="4">
                       Genauigkeit der Zeitauswahl <b-form-select v-model="timepickerSteps" :options="zeitSteps" size="sm" class="mt-3"></b-form-select> 
            </b-col> -->

        </b-row> <!-- große row -->

            <b-row>
              <b-col cols="2">
                Dauer
               </b-col>
               
               <b-col cols>


<!-- {{localRRuleEnd}}
{{localStartDatum}} -->
                            <!-- <b-input min=0 v-on:input="updateIntervall" v-model="localinterval" type="number"></b-input> -->
                <b-row>
                    <b-col cols="3">
                    <b-input min=0 max=1000 v-on:input="updateDauer" v-model="localDauerTage" type="number"></b-input>
                    </b-col>
                    <b-col cols>
                        Tag(e)
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col cols="3">
                        <b-input min=0 max=23 v-on:input="updateDauer" v-model="localDauerStunden" type="number"></b-input>
                    </b-col>
                    <b-col cols>
                        Stunde(n)
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col cols="3">
                        <b-input min=0 max=59 v-on:input="updateDauer" v-model="localDauerMinuten" type="number"></b-input>
                    </b-col>
                    <b-col cols>
                        Minute(n)
                    </b-col>
                </b-row>

                   
                  
                        <!-- {{localDuration.locale("de").humanize()}} -->
               </b-col>

             </b-row>

            
    </b-card>
            <hr class="block-separator">

<h4>Serienmuster</h4>

    <b-container class="bv-example-row">
        <b-row>
            <b-col cols="3">
                <b-form-group>
                    <b-form-radio :key="option.id" v-for="option in store.repeatTypes" 
                    v-model="pureRule.origOptions.freq" :value="option.type">{{option.label}}</b-form-radio>
                </b-form-group>
            </b-col>

            <b-col v-if="pureRule.origOptions.freq===3" cols>
   
                <b-form-group>

                    <b-row>
                        <b-col style="border:none;" cols="5">
                            <b-form-radio @change="tagChoiche" v-model="store.daily.choice" value="interval"> alle:</b-form-radio>
                        </b-col>

                        <b-col style="border:none;" cols="4">

                            <!-- <b-form-input @change="tagIntervall" type="number" :disabled="store.daily.choice!=='interval'" v-model="interval"></b-form-input> -->
                            <b-input :disabled="store.daily.choice!='interval'" min=0 v-on:input="updateIntervall" v-model="localinterval" type="number"></b-input>

                        </b-col>

                        <b-col style="border:none;" cols="3">
                            Tag(e)
                        </b-col>
                    </b-row>
                    
                    <b-form-radio @change="tagChoiche" v-model="store.daily.choice" value="wochentag">jeden Wochentag</b-form-radio>

                </b-form-group>

            </b-col>

            <b-col v-if="pureRule.origOptions.freq===2" cols>
 
                    <b-row>
                        <b-col style="border:none;" cols="5">
                            <span > jede / alle:</span>
                        </b-col>

                        <b-col style="border:none;" cols="4">
                            <b-input min=0 v-on:input="updateIntervall" v-model="localinterval" type="number"></b-input>
                        </b-col>

                        <b-col style="border:none;" cols="3">
                            Woche(n) am:
                        </b-col>
                    </b-row>
                    <!-- {{localbyweekday}}
                    {{pureRule.origOptions.byweekday}} -->
                    <b-form-group>

                    <b-form-checkbox-group @input="updateByweekday" v-model="localbyweekday">
                        <b-form-checkbox value='0'>Montag</b-form-checkbox>
                        <b-form-checkbox value='1'>Dienstag</b-form-checkbox>
                        <b-form-checkbox value='2'>Mittwoch</b-form-checkbox>
                        <b-form-checkbox value='3'>Donnerstag</b-form-checkbox>
                        <b-form-checkbox value='4'>Freitag</b-form-checkbox>
                        <b-form-checkbox value='5'>Samstag</b-form-checkbox>
                        <b-form-checkbox value='6'>Sonntag</b-form-checkbox>
                    </b-form-checkbox-group>

                    </b-form-group>

            </b-col>

            <b-col v-if="pureRule.origOptions.freq===1" cols>
              <b-form-group>

                    <b-row>
                        <b-col style="border:none;" cols="2">
                            <b-form-radio @change="monthChoiche" v-model="monthly.choice" value="amTag"> am:</b-form-radio>
                        </b-col>

                        <b-col style="border:none;" cols="3">
                            <b-input :disabled="monthly.choice!='amTag'" min=0 v-model="localbymonthday" type="number"></b-input>
                        </b-col>

                        <b-col style="border:none;" cols="3">
                         . Tag jedes
                        </b-col>

                        <b-col style="border:none;" cols="3">
                            <!-- <b-form-input @change="tagIntervall" type="number" :disabled="store.daily.choice!=='interval'" v-model="interval"></b-form-input> -->
                            <b-input :disabled="store.daily.choice!='interval'" min=0 v-on:input="updateIntervall" v-model="localinterval" type="number"></b-input>
                        </b-col>

                        <b-col style="border:none;" cols="3">
                            Monat(e)
                        </b-col>
                    </b-row>
                       <b-row>

                        <b-col style="border:none;" cols="2">
                            <b-form-radio @change="monthChoiche" v-model="monthly.choice" value="jeden"> am:</b-form-radio>
                        </b-col>

                        <b-col style="border:none;" cols="3">
                            <!-- <b-input :disabled="monthly.choice!='jeden'" min=-1 max=4 v-model="localbysetpos" type="number"></b-input> -->
                            <b-form-select v-model="localbysetpos" :options="optionsMonthsetpos"></b-form-select>
                            {{localbysetpos}}
                        </b-col>

                        <b-col style="border:none;" cols="3">
                   

                                <b-form-select v-model="localMonthbyweekday" :options="optionsMonthDay"></b-form-select>
                                {{localMonthbyweekday}}
                        </b-col>
                        
                        <b-col style="border:none;" cols="3">
                            Monat(e)
                        </b-col>
                    </b-row>


                </b-form-group>

            </b-col>


            <b-col v-if="pureRule.origOptions.freq===0" cols>
                    yearly
            </b-col>

        </b-row>
    </b-container>


            

            <hr class="block-separator">

<h4>Seriendauer</h4>

    <!-- <b-card> -->
    <b-container class="bv-example-row">
    <b-row>

        <b-col cols="3">
            <label class="">Beginn:</label>
        </b-col>

        <b-col cols>
            <vuejs-datepicker v-on:input="ChangeStartDatum(); updateDauer();" :language="de" v-model="localStartDatum"></vuejs-datepicker>
        </b-col>

    </b-row>

    <b-row>
        <b-col cols="3">
            <label class="">endet:</label>
        </b-col>

        <b-col cols="3">
            <select @change="ChangeActiveEnd" class="form-control-archiapp" v-model="activeEnd">
                <option v-for="option in activeEndPossibilities" :key="option.id" :value="option.value">
                    {{ option.text }}
                </option>
            </select>
        </b-col>

        <b-col v-if="activeEnd==='count'" cols="3">
            <b-input v-on:input="CountChange" v-model="localCount" type="number"></b-input>
        </b-col>

        <b-col v-if="activeEnd==='count'" cols="3">
            <label for="input-count"> Mal</label>
        </b-col>

        <b-col v-if="activeEnd==='date'" cols="6">
           

            <vuejs-datepicker v-on:input="UntilChange" :disabled-dates="disabledDates" :language="de" v-model="localEndDate"></vuejs-datepicker>
                {{disabledDates.to}}
            <!-- <date-picker v-on:input="UntilChange" :lang="lang" :format="format_date" v-model="localEndDate" type="date" :width="150" :time-picker-options="timePickerOptions"></date-picker> -->
        </b-col>

<!-- //this.pureRule.options.until -->

    </b-row>
    </b-container>

    <!-- </b-card> -->

            <!-- <hr class="block-separator">
            <div class="form-group">
                <label class="control-label col-md-2 col-sm-2 col-md-offset-1 col-sm-offset-1">RRULE</label>
                <div class="col-md-17 col-sm-17">
                    <textarea id="rruleResult" v-model="$store.state.rruleResult" class="form-control"
                              readonly="readonly"></textarea>
                </div>
                <button @click="rruleGenerate">Update</button>
            </div> -->
    

</b-card>
</span>
</template>


<script>

import { RRule, RRuleSet, rrulestr } from 'rrule'
    //import DatePicker from 'vue2-datepicker'
    import moment from 'moment'
    import VueTimepicker from 'vue2-timepicker'
    import vuejsDatepicker from 'vuejs-datepicker'
    import {en, de} from 'vuejs-datepicker/dist/locale'
    import { davMixin } from '../mixins/davMixin.js'

    export default {
    
    mixins: [davMixin],

        components: {
            vuejsDatepicker,
            VueTimepicker,
        },

        name: "recurrence",


        data(){
            return{


 optionsMonthDay: [
          { value: [0, 1, 2, 3, 4, 5, 6], text: 'Tag' },
          { value: [0, 1, 2, 3, 4], text: 'Arbeitstag' },
          { value: [5, 6], text: 'Wochenendtag' },
          { value: [0], text: 'Montag' },
          { value: [1], text: 'Dienstag' },
          { value: [2], text: 'Mittwoch' },
          { value: [3], text: 'Donnerstag' },
          { value: [4], text: 'Freitag' },
          { value: [5], text: 'Samstag' },
          { value: [6], text: 'Sonntag' }

        ],

 optionsMonthsetpos: [
          { value: [1], text: 'ersten' },
          { value: [2], text: 'zweiten' },
          { value: [3], text: 'dritten' },
          { value: [4], text: 'vierten' },
          { value: [-1], text: 'letzten' }

        ],




  disabledDates: {
    to: new Date(2019, 5, 5), // Disable all dates up to specific date
    // from: new Date(2019, 5, 26), // Disable all dates after specific date
    // days: [6, 0], // Disable Saturday's and Sunday's
    // daysOfMonth: [29, 30, 31], // Disable 29th, 30th and 31st of each month
    // dates: [ // Disable an array of dates
    //   new Date(2016, 9, 16),
    //   new Date(2016, 9, 17),
    //   new Date(2016, 9, 18)
    // ],
    // ranges: [{ // Disable dates in given ranges (exclusive).
    //   from: new Date(2016, 11, 25),
    //   to: new Date(2016, 11, 30)
    // }, {
    //   from: new Date(2017, 1, 12),
    //   to: new Date(2017, 2, 25)
    // }],
    // // a custom function that returns true if the date is disabled
    // // this can be used for wiring you own logic to disable a date if none
    // // of the above conditions serve your purpose
    // // this function should accept a date and return true if is disabled
    // customPredictor: function(date) {
    //   // disables the date if it is a multiple of 5
    //   if(date.getDate() % 5 == 0){
    //     return true
    //   }
    // }
  },
                localMonthbyweekday: null,
                localbysetpos: null,
                localbymonthday: null,
                localbyyearday: null,
                localbyweekno: null,
                localbyhour: null,
                localbyminute: null,
                localbysecond: null,
                localbyeaster: null,

                localDauerTage : null,
                localDauerStunden : null,
                localDauerMinuten : null,

                de: de,
                timepickerSteps:5,
                localStartZeit:{"HH": "08",  "mm": "00"},
                localEndZeit:{"HH": "08",  "mm": "00"},
                localStartDatum:null,
                localEndDatum:null,

                localDuration:null,
                localRRuleStart:null,
                localRRuleEnd:null,

                restVonRRUle:null,

                pureRule:null,
                rruleSet:null,
                monthly:{choice:null},
                localbyweekday:null,
                localCount:1,
                localEndDate: new Date(), 
                localinterval:1,
                activeEnd:'',
                activeEndPossibilities:  [{
                                "text": "nie", value:''
                            },
                            {
                                "text": "nach x Mal", value:'count'
                            },
                            {
                                "text": "an Datum", value:'date'
                            },
                            ],
                lang:'de',
                format_date:"DD.MM.YYYY",
                format_zeit:"DD.MM.YYYY HH:mm",
                format_uhrzeit:"HH:mm",                
                timePickerOptions:{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                },
                zeitSteps:[
          { value: 30, text: 'halbstündlich' },
          { value: '15', text: 'viertelstündlich' },
          { value: '5', text: '5 Minuten' },
          { value: '1', text: 'jede Minute' },
        ]


            }
        },

        computed: {

        
            store() {
                return this.$store.state
            },
        
    
        },

        methods: {

            resetPureRule(){
                this.pureRule.origOptions.byweekday = null;
                this.pureRule.origOptions.bysetpos = null;
                this.pureRule.origOptions.bymonthday = null;
                this.pureRule.origOptions.byyearday = null;
                this.pureRule.origOptions.byweekno = null;
                this.pureRule.origOptions.byhour = null;
                this.pureRule.origOptions.byminute = null;
                this.pureRule.origOptions.bysecond = null;
                this.pureRule.origOptions.byeaster = null;
            },


            updateBymonthday(){


            },

            updateDauer(val){
                
                this.localRRuleEnd = moment(this.localRRuleStart).add( this.localDauerTage, 'days').add( this.localDauerStunden, 'hours').add( this.localDauerMinuten, 'minutes'); // with chaining
              
                this.makeStringRrule();

            //  this.localRRuleEnd.set({'hour': this.localDauerStunden, 'minute': this.localDauerMinuten});
            //  this.localRRuleEnd.set({'date': this.localDauerTage});
                //this.localEndZeit = null;

                        var stunden = this.localRRuleEnd.hour();
                        if(stunden<10)
                            stunden = "0" + stunden;


                        var minuten = this.localRRuleEnd.minute();

                         if(minuten<10)
                            minuten = "0" + minuten;



                this.localEndZeit = {"HH" : stunden, "mm" : minuten};
              
              
              // alert(this.localEndZeit.HH );
                //this.localEndZeit.mm = this.localRRuleEnd.minutes();

            },

            checklocalEndDate(){
                var datum = moment(this.localStartDatum);
                var jahr = datum.year();
                var monat = datum.month();
                var tagEndDate = datum.add( 1, 'days').date();    

                var hierlocalEndDate = moment(this.localEndDate);

                var unterschied = moment.duration(hierlocalEndDate.diff(datum));


                if(unterschied.as('milliseconds') < 0)
                this.localEndDate = new Date(jahr, monat, tagEndDate);

            },

            ChangeStartDatum(){

                var datum = moment(this.localStartDatum);
                var jahr = datum.year();
                var monat = datum.month();
                var tag = datum.date();

                var tagEndDate = datum.add( 1, 'days').date();

                    this.localRRuleStart.set({'year': jahr, 'month': monat, 'date': tag});
                    this.makeStringRrule();

                    this.disabledDates.to = new Date(jahr, monat, tagEndDate);

                    this.checklocalEndDate();

                //this.localRRuleStart.set({'hour': stunden, 'minute': minuten});

             },

            ChangeStartZeit(){

                //this.localRRuleStart.minute(5);
                var stunden = this.localStartZeit.HH
                var minuten = this.localStartZeit.mm

                this.localRRuleStart.set({'hour': stunden, 'minute': minuten});

                // console.log(this.localRRuleStart);
            },

            ChangeEndZeit(){

                //this.localRRuleStart.minute(5);
                var stunden = this.localEndZeit.HH
                var minuten = this.localEndZeit.mm

                this.localDauerStunden = stunden;
                this.localDauerMinuten = minuten;

                this.localRRuleEnd.set({'hour': stunden, 'minute': minuten});

            },

            updateDuration(val) {
                
            //  this.localRRuleEnd.set({'hour': this.localDauerStunden, 'minute': this.localDauerMinuten});
            //  this.localRRuleEnd.set({'date': this.localDauerTage});

                 this.localDuration = moment.duration(this.localRRuleEnd.diff(this.localRRuleStart));

                 console.log(this.localDuration.seconds());
                //alert(this.localDuration.hours());

                 if(this.localDuration.minutes()<0){
                     this.localRRuleEnd.add( 1, 'days');
                                      this.localDuration = moment.duration(this.localRRuleEnd.diff(this.localRRuleStart));

                 }

                 if(this.localDuration){
                    this.localDauerTage = this.localDuration.days();
                    this.localDauerStunden = this.localDuration.hours();
                    this.localDauerMinuten = this.localDuration.minutes();
                }
                
            //     this.activeEvent.duration = this.localDuration.hours() + ":" +this.localDuration.minutes();

            },

            stepChange(val) {
                    if(val=='halb')
                        this.timePickerOptions.step = '00:30';
                    else if(val=='viertel')
                        this.timePickerOptions.step = '00:15';
                    else if(val=='five')
                        this.timePickerOptions.step = '00:05';
                    else if(val=='minute')
                        this.timePickerOptions.step = '00:01';
            },
            UntilChange() {
                this.pureRule.origOptions.count = null;
                this.pureRule.origOptions.until = this.localEndDate;
                this.makeStringRrule();
            },

            CountChange() {
                this.pureRule.origOptions.until = null;
                this.pureRule.origOptions.count = this.localCount;
                this.makeStringRrule();
            },

            ChangeActiveEnd(val) {

                this.checklocalEndDate();
                
                this.pureRule.origOptions.count=null;

                // console.log(val.target.value);

                if(val.target.value==='count')   {
                    this.pureRule.origOptions.until=null;
                    this.CountChange();
                }

                if(val.target.value==='date')   {

                    this.pureRule.origOptions.count=null;
                    this.UntilChange();
                }

                if(val.target.value===''){
                    this.pureRule.origOptions.count=null;
                    this.pureRule.origOptions.until=null;
                }
                this.makeStringRrule();
            },

            updateIntervall() {
                 this.pureRule.origOptions.interval = this.localinterval;
                  this.makeStringRrule();
            },

            updateByweekday() {

                        for (var index = 0; index <  this.localByweekday.length; ++index) {

                             this.localByweekday[index] =  parseInt(this.localByweekday[index], 10);
                        }

                    this.pureRule.origOptions.byweekday = this.localByweekday;


                    this.makeStringRrule();
            },
            

              monthChoiche(val) {

                    if(val==='amTag'){
                        if(!this.pureRule.origOptions.bymonthday){
                            var tagbymonthday = moment().date();
                            this.pureRule.origOptions.bymonthday=tagbymonthday;
                        }
                       
                     }
                     else if(val==='jeden')
                     {
                         this.pureRule.origOptions.interval=this.localinterval;
                         this.localByweekday=null;
                         this.pureRule.origOptions.byweekday=null;
                     }
            },

            tagChoiche(val) {

                    if(val==='wochentag'){
                        //this.localByweekday= [RRule.MO,RRule.TU, RRule.WE,RRule.TH,RRule.FR];
                       this.localByweekday= [0, 1, 2, 3, 4 ];
                    //    console.log(this.localByweekday);
                        this.pureRule.origOptions.byweekday=[ 0, 1, 2, 3, 4 ];
                        this.pureRule.origOptions.interval=null;
                     }
                     else if(val==='interval')
                     {
                         this.pureRule.origOptions.interval=this.localinterval;
                         this.localByweekday=null;
                         this.pureRule.origOptions.byweekday=null;
                     }
            },

            // rruleGenerate() {
            //     console.log("rruleGenerate");
            //     let options = {};
            //     console.log(this.pureRule.options.freq);

            //     switch (this.pureRule.options.freq) {

            //         case 3:
            //             options.freq = RRule.DAILY;
            //             break;
            //         case 2:
            //             options.freq = RRule.WEEKLY;
            //             break;
            //         case 1:
            //             options.freq = RRule.MONTHLY;
            //             break;
            //         case 1:
            //             options.freq = RRule.YEARLY;
            //             break;
            //     }

            //     options.interval = (this.pureRule.options.interval >= 1 ? this.pureRule.options.interval : 1);

            //     //options.byweekday = ( this.store.byweekday ? this.store.repeatIntervalValue : 1);

            //    if(this.pureRule.options.byweekday){
            //     options.byweekday=[];

            //     this.pureRule.options.byweekday.forEach(function(element) {
            //     //console.log(element);

            //     if(element==='mo')
            //      options.byweekday.push(RRule.MO);
            //     if(element==='tu')
            //      options.byweekday.push(RRule.TU);
            //     if(element==='we')
            //      options.byweekday.push(RRule.WE);
            //     if(element==='th')
            //      options.byweekday.push(RRule.TH);
            //     if(element==='fr')
            //      options.byweekday.push(RRule.FR);
            //     if(element==='sa')
            //      options.byweekday.push(RRule.SA);
            //     if(element==='su')
            //      options.byweekday.push(RRule.SU);

            //     });

            //console.log(options.byweekday);
               

            //    }
            //     else

            //     options.byweekday=null;

            //     if(this.activeEnd==='count')
            //     options.count = (this.pureRule.options.count >= 1 ? this.pureRule.options.count : 1);            
 
            //      if(this.activeEnd==='date')
            //     options.until = (moment(this.pureRule.options.until).isValid()===true ? this.pureRule.options.until : 1);  

            //     let rule = new RRule(options);

            //     // this.$store.commit('setRruleResult', rule.toString());

            //    // this.pureRule.optionsPure = rule.toString().replace('RRULE:','');

            // },

            makeStringRrule() {

                this.resetPureRule();

                switch (this.pureRule.origOptions.freq) {  

                    case 3: //täglich
                    this.pureRule.origOptions.interval = this.localinterval;
                    this.pureRule.origOptions.byweekday = this.localbyweekday;
                    break;

                    case 2:
                        this.pureRule.origOptions.interval = this.localinterval;
                        this.pureRule.origOptions.byweekday = this.localbyweekday;
                    break;

                    case 1:
                        this.pureRule.origOptions.interval = this.localinterval;
                        this.pureRule.origOptions.bymonthday = this.localbymonthday;
                        this.pureRule.options.bysetpos = this.localbysetpos;
                        this.pureRule.origOptions.byweekday = this.localMonthbyweekday;
                    break;

                    case 0:
                        this.pureRule.origOptions.interval = this.localinterval;
                        this.pureRule.origOptions.byweekday = this.localbyweekday;
                    break;

                }
               

                this.activeEvent.allDay = false;
                var RuleAsString = this.pureRule.toString();
                var ruleArray =  RuleAsString.split('RRULE:');
                RuleAsString = ruleArray[1];
                RuleAsString += this.restVonRRUle;

               
                if(this.localDauerTage>0 && this.localDauerStunden===0 && this.localDauerMinuten===0)
                this.activeEvent.allDay = true;

                 this.activeEvent.duration = moment.duration(this.localRRuleEnd.diff(this.localRRuleStart)).asMilliseconds();
               
                this.activeEvent.dtstart = this.localRRuleStart;
                
                this.activeEvent.dtend = this.localRRuleEnd;

                this.activeEvent.rrule = 'DTSTART:' + this.localRRuleStart.format("YYYYMMDDTHHmmss") + 'Z\nRRULE:' + JSON.parse(JSON.stringify(RuleAsString));
               
                this.activeEvent.pureRule = JSON.parse(JSON.stringify(RuleAsString)); 


            },

            checkRrule() {

                    // if( this.pureRule.origOptions.freq){
                    //     switch (this.pureRule.origOptions.freq) {
                    //     case 0:
                        
                    //     break;

                    //     }
                    // }

                    if( this.pureRule.origOptions.count>0){
                        this.activeEnd='count';
                        this.localCount=this.pureRule.origOptions.count;
                    }
                    
                    if(this.pureRule.origOptions.until)
                    this.activeEnd='date';
                    
                    if( this.pureRule.origOptions.byweekday===[ "mo", "tu", "we", "th", "fr" ])
                        this.store.daily.choice = "wochentag";

                    if( this.pureRule.origOptions.interval>0)
                        this.store.daily.choice = "interval";

                    if( this.pureRule.origOptions.byweekday)
                        this.localbyweekday = this.pureRule.options.byweekday;
                    
                    if( this.pureRule.origOptions.interval)
                        this.localinterval = this.pureRule.options.interval;

                    if( this.pureRule.origOptions.until)
                        this.localEndDate = this.pureRule.options.until;
                   
                   if( this.pureRule.origOptions.bymonthday){
                            this.monthly.choice = "amTag";
                            this.localbymonthday = this.pureRule.options.bymonthday;
                   }

                   if( this.pureRule.origOptions.bymonthday){
                            this.monthly.choice = "amTag";
                            this.localbymonthday = this.pureRule.options.bymonthday;
                   }

                   if( this.pureRule.origOptions.bysetpos){

                       this.monthly.choice = "jeden";

                       this.localbysetpos = this.pureRule.options.bysetpos;

                       if( this.pureRule.origOptions.byweekday)
                       this.localMonthbyweekday = this.pureRule.options.byweekday;

                   }
                        

                   if( this.pureRule.origOptions.bysetpos)
                        this.localbyyearday = this.pureRule.options.bysetpos;

                   if( this.pureRule.origOptions.byweekno)
                        this.localbyweekno = this.pureRule.options.byweekno;

                   if( this.pureRule.origOptions.byhour)
                        this.localbyhour = this.pureRule.options.byhour;

                   if( this.pureRule.origOptions.byminute)
                        this.localbyminute = this.pureRule.options.byminute;

                   if( this.pureRule.origOptions.bysecond)
                        this.localbysecond = this.pureRule.options.bysecond;

                   if( this.pureRule.origOptions.byeaster)
                        this.localbyeaster = this.pureRule.options.byeaster;

                        this.checklocalEndDate();

                    if( this.activeEvent.dtstart){

                        // console.log(this.activeEvent.dtstart);

                       // this.localRRuleStart = moment(this.activeEvent.dtstart).utc();
                        this.localRRuleStart = moment(this.activeEvent.dtstart);
                        var jahr = this.localRRuleStart.year();
                            // console.log(jahr);
                        var monat = this.localRRuleStart.month();
                            // console.log(monat);
                        var tag = this.localRRuleStart.date();
                            // console.log(tag);

                        // localStartDatum:null,
                        // localEndDatum:null,

                        this.localStartDatum =  new Date(jahr, monat, tag);

                       // this.localStartDatum = this.localRRuleStart.format('DD-MM-YYYY');
                      
                        var stunden = this.localRRuleStart.hour();
                        if(stunden<10)
                            this.localStartZeit.HH = "0" + stunden;
                        else
                            this.localStartZeit.HH = stunden;

                        var minuten = this.localRRuleStart.minute();

                         if(minuten<10)
                            this.localStartZeit.mm = "0" + minuten;
                        else
                            this.localStartZeit.mm = minuten;

                    }

                   if( this.activeEvent.dtend){

                        // console.log(this.activeEvent.dtstart);

                       // this.localRRuleStart = moment(this.activeEvent.dtstart).utc();
                        this.localRRuleEnd = moment(this.activeEvent.dtend);

                        var stunden = this.localRRuleEnd.hour();
                        if(stunden<10)
                            this.localEndZeit.HH = "0" + stunden;
                        else
                            this.localEndZeit.HH = stunden;

                        var minuten = this.localRRuleEnd.minute();

                         if(minuten<10)
                            this.localEndZeit.mm = "0" + minuten;
                        else
                            this.localEndZeit.mm = minuten;

                    }

                    if(this.localRRuleEnd && this.localRRuleStart)
                        this.localDuration = moment.duration(this.localRRuleEnd.diff(this.localRRuleStart));
                    
                    if(this.localDuration){
                        this.localDauerTage = this.localDuration.days();
                        this.localDauerStunden = this.localDuration.hours();
                        this.localDauerMinuten = this.localDuration.minutes();
                    }
                
            },
        },

	created(){

               if(this.activeEvent.rrule){

                        //this.rruleSet = rrulestr(this.activeEvent.rrule, {forceset: true});

                        var splitted = this.activeEvent.rrule.split("\n");  
                        
                        var pureRuleStr = splitted[0]+'\n'+splitted[1];
                    
                        // console.log(this.pureRuleStr);

                        this.pureRule = rrulestr(pureRuleStr);

                        // console.log(this.pureRule);
                        
                        //this.pureRule = rrulestr(this.activeEvent.rrule, {forceset: true});


                        if( this.pureRule)
                            this.checkRrule();

                


                    this.restVonRRUle='';

                        if(splitted.length>2){

                            splitted.forEach(function(item, index){

                        
                                if(index>1){

                                if(index!=splitted.length)
                                    this.restVonRRUle += '\n'+ item;
                                    else
                                    this.restVonRRUle += item;
                                }

                            });
                        
                        }

                }

        },


    }
</script>

<style scoped>

.bv-example-row .row > .col:not(.header), .bv-example-row .row > [class^="col-"] {
	padding-top: .75rem;
	padding-bottom: .75rem;
	border: 1px solid rgba(86,61,124,.2);
}

input[type="time"]::-webkit-clear-button {
    display: none;
}

.withBorder
{
    	border: 1px solid rgba(86,61,124,.2);
}

</style>