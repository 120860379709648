<template>
	<div>
        <!-- v-if="logObject.loggedIn === true" -->

    <div class="subnav" >
            <b-container fluid>
                <b-row>

                    <b-col>


                    </b-col>
                    

                    <b-col class="text-right" cols="2">
                    
                        <span class="mr-2">

                            <b-button v-if="bearbeiten" 
                           
                            id="projectSpeichern" 
                            :disabled="loader_loading"
                            v-b-tooltip.hover title="speichern - strg + s"
                                @click="speicherWissen" variant="success"><span class="fa fa-save"></span>
                            </b-button>
                        </span>

                    </b-col>

                    <b-col style="min-height:40px" class="text-right" cols="2">


                        <b-form-checkbox style="margin-top:5px;" v-model="bearbeiten" name="check-button" switch>
                            bearbeiten
                        </b-form-checkbox>

                    </b-col>

                    </b-row>
            </b-container>
        </div>
        
        <div class="subnav_shadow">
      <b-container fluid>
                <b-row>
                    <b-col>


                    </b-col>

                    <b-col cols="2">
                        <span class="mr-2">
                            <b-button v-if="bearbeiten" 
                           
                            id="projectSpeichern" 
                            :disabled="loader_loading"
                            v-b-tooltip.hover title="speichern - strg + s"
                                variant="success"><span class="fa fa-save"></span>
                            </b-button>
                        </span>

                    </b-col>

                    <b-col cols="2">


                        <b-form-checkbox class=""  name="check-button" switch>
                            bearbeiten
                        </b-form-checkbox>

                    </b-col>


                </b-row>
            </b-container>
        </div>

        <div  class="kontainer">
            <b-card>
                <div class="p-4">

                    <h5>Derzeit können nur Internetlinks eingetragen werden. zukünftig sollen auch Dateien wie PDF abgespeichert werden können.</h5>

                   	<b-button  v-if="bearbeiten" @click="wissenItems.unshift(JSON.parse(JSON.stringify(blancWissen)))" variant="outline-primary">		
						<span>eine weitere Zeile hinzufügen</span>
					</b-button>

                    <b-table 
                    show-empty
                    :items="wissenItems" 
                     :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                     >

<!-- //  @row-clicked="myRowClickHandler" -->


                    <template v-slot:head(link)="data">
                        <div>{{data.label}}
                            <b-icon 
                            v-if="bearbeiten" 
                            v-b-tooltip.hover 
                            title="Internetlinks müssen mit 'http://' oder 'https://' beginnen, um zu funktioneren" 
                            class="ml-2" 
                            icon="question-circle" 
                            font-scale="1.5" 
                            variant="primary">
                            </b-icon>	


                        </div>
                    </template>

                        <template v-slot:empty="data">
                        <h4>keine Einträge vorhanden</h4>
                        </template>

                            <template v-slot:cell(delete)="data">
                                <b-button 
                                    @click="loescheEintrag(data)" 
                                    
                                    :disabled="!bearbeiten" 
                                    v-b-tooltip.hover title="Zeile löschen" variant="danger" size="sm">
                                    <span class="fa fa-window-close"></span>
							    </b-button>
                            </template>

                              <template v-slot:cell()="data">
                                <!-- <i>{{ data }}</i> -->

                           <b-row> 
                               <b-col v-if="data.field.key=='link'" style="max-width:60px">
                               <b-btn
                               
								size="sm"
									target="_blank"
									:href="data.value"
									v-b-tooltip.hover 
									title="Rufen Sie diesen Link auf." 
									variant="primary">
									<b-icon icon="box-arrow-up-right"></b-icon>
								</b-btn>
                                </b-col>

                                <b-col>
                                 <b-form-input v-bind:class="{ 'form-control': bearbeiten, 'form-control-plaintext': !bearbeiten }"
                                 v-model="data.item[data.field.key]"></b-form-input>
                                 </b-col>
                            </b-row>

                                <!-- <b-form-input v-model="data.item[data.field.key]" ></b-form-input> -->
                            </template>



                    </b-table>

                </div>
                </b-card>
        </div>

	</div>
</template>

<script>

import { wissenMixin } from '../mixins/wissenMixin.js'

export default {

        mixins: [wissenMixin],

    data(){
        return{

            blancWissen : { 
                "titel":"",
                "link":"",
                "bereich":"",
            },
        fields: [
          {
            key: 'titel',
            label: 'Titel',
            sortable: true
          },
          {
            key: 'link',
            label: 'Adresse',
            sortable: true
          },
          {
            key: 'bereich',
            label: 'Bereich',
            sortable: true,

          },
            {
            key: 'delete',
            label: 'X',


          }

        ],

        
        wissenItems: [
          
        ]


        }
    },

    computed:{

    },    

	methods:{

        loescheEintrag(data) {
            //  console.log('loescheEintrag');

        this.wissenItems.splice(data.index, 1);
        

        },

        getWissen(){

            this.axios.post(this.$store.state.url_wissen, {
                    besonders: this.$store.state.dev_sub,
                    was:'get'
                    }).then((response) => {
                            console.log(response.data)
                        this.wissenItems = response.data.wissen
                    })
            },

        speicherWissen(){

                this.axios.post(this.$store.state.url_wissen, {
                        besonders: this.$store.state.dev_sub,
                        was:'post',
                        data:this.wissenItems,
                        }).then((response) => {
                                console.log(response.data)
                                if(response.data.success == true)
                                							this.$toasted.show('Erfolgreich gespeichert.', {
							position: 'top-center',
							type:'success',
							duration:1500,
							containerClass:'ganzVorne'})
                                
                        })
                },


    myRowClickHandler(record, index) {
        // 'record' will be the row data from items
        // `index` will be the visible row number (available in the v-model 'shownItems')
        console.log(record); // This will be the item data for the row
    }

	},
	created(){
        this.getWissen();

	}
	
	//
}
</script>


<style>


</style>
