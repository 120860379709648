<template>
	<div>
       <div class="kontainer">

				<h1>sabre Server</h1>
				<p>Text</p>

        </div>
	</div>
</template>

<script>

// import Vue from 'vue'

export default {

    data(){
        return{
		sabre:{}
		}
    },
	
	methods:{


	},
	
	created(){
	


	
	}
	
}
</script>



<style>


</style>
