<template>
	<div class="leer">
		<kontakt-subnav></kontakt-subnav>

       <div class="kontainer">

			<!-- <pulse-loader :loading="loader_loading" :color="loader_color" :size="loader_size"></pulse-loader> -->

<!-- <button @click="getOffset">klick</button> -->

				<div class="flexed">

					<div class="kontaktListe">

						<div class="zeile_oben">

							<span id="headerKontakte" style="display:inline-block; min-width:245px; font-weight:700">
								<span v-if="showInaktivTabelle==false">aktive Kontakte</span>
								<span v-if="showInaktivTabelle==true">inaktive Kontakte</span>
							</span>

							<span v-if="Mehrfachauswahl">
								<b-button class="ml-2 btn-nav" 
										@click="unselectAddressbook" 
										v-b-tooltip.hover 
										title="alle Markierungen aufheben" >
										<span class="fa fa-check-square"></span>
								</b-button>	
							</span>


						</div>

						<div v-if="filterSuche.length==0 && (searchTermKontakte || kindArray.length>0 || artArray.length>0)">
							<div class="mb-2">Es entspricht kein Kontakt der Suche.</div>

							<div class="mb-2" v-if="searchTermKontakte"> 
								<span class="h3 mb-2"><b-icon icon="exclamation-circle"></b-icon></span>
								Sie haben noch ein Wort in der Suchfunktion stehen.
							</div>

							<div v-if="kindArray.length>0 || artArray.length>0">
								<span class="h3 mb-2"><b-icon icon="exclamation-circle"></b-icon></span>
								Sie haben noch einen Filter aktiv.
							</div>
						</div>

				<!-- <button @click="pfadwechsel">klic</button> -->
				
				<keep-alive>
					<virtual-list
						class="list"    
						ref="AdressListe"
						:style="{ height: window.height - 260 + 'px' }"
						:data-key="'uid'"
						:data-sources="filterSuche"
						:data-component="itemComponent"
						:extra-props="{ otherPropValue: otherDataAssginToItemComponet }"
						:estimate-size="filterSuche.length"
					>
					</virtual-list>
				</keep-alive>

		</div>
						

		<div v-if="!itsMobile" class="kontaktDetail" >
		<!-- {{selectedAddressbook}} -->
				<b-container v-if=" Mehrfachauswahl">
					<b-row>
						<b-col>
							<label class="eingabe-label"> Mehrere Kontakte bearbeiten </label>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col v-if="showInaktivTabelle==false">
							<span v-if="selectedAddressbook.length==0">
								kein Kontakt ausgewählt
							</span>

							<span v-if="selectedAddressbook.length>0">
								{{selectedAddressbook.length}} ausgewählte Kontakte löschen <b-button v-if="KontaktDetail" class="mr-2 btn-nav" @click="kontakteloesche(selectedAddressbook)" v-b-tooltip.hover title="markierte Kontakte löschen" variant="info" ><span style="color:red;font-weight:800;">X</span></b-button>			
							</span>

						</b-col>

						<b-col v-if="showInaktivTabelle==true">
							<span v-if="selectedTrash.length>0">
								{{selectedTrash.length}} ausgewählte Kontakte wiederherstellen <b-button 
								class="mr-2 btn-nav"
								@click="kontakteloesche(selectedTrash)" 
								v-b-tooltip.hover 
								title="markierte Kontakte wiederherstellen" 
								variant="info" >
								<span class="fa fa-undo"></span>
								</b-button>			
							</span>
							
						</b-col>

					</b-row>

					<b-row class="mt-5">
						<b-col>

							Während die Mehrfachauswahl aktiv ist, können keine einzelnen Kontakte angezeigt werden. Sie können die Mehrfachauswahl deaktivieren. 

								<b-button @click="Mehrfachauswahl = !Mehrfachauswahl" variant="success" >deaktivieren</b-button>				

						</b-col>
					</b-row>

				</b-container>

					<h4 class="mb-3" v-if="!Mehrfachauswahl">ausgewählter Kontakt</h4>

					<!-- {{KontaktDetail.mitgabe}} -->

					<keep-alive><!-- my-component will be intentionally kept in memory even when removed -->
						<kontakt-detail style="min-width:500px;max-width:500px;" woherKommstDu="adressBuch" v-if="!Mehrfachauswahl" ></kontakt-detail>
					</keep-alive>

			</div>
			<!-- {{aktuelleListeMitName}} -->
				<div :style="{ height: window.height - 60 + 'px' }" v-if="!itsMobile && kontaktEinstellungen.showList" class="listen" >
							
						<span style="max-width: 700px;" class="position-fixed pr-2">

						<span style="height:50x;">
							<h4 class="float-left pt-1">Liste(n)</h4>
							<b-button class="float-left ml-3 mb-2 btn-nav" 
							@click="neueListeShow = !neueListeShow" 
							v-b-tooltip.hover 
							title="neue Liste anlegen" 
							variant="info" >
							<span class="fa fa-plus"></span>
							</b-button>	

							<b-button   
							class="float-left ml-3 mb-2" 
                    		variant="outline-info" :to="{ path: '/hilfe', hash: '#listen' } ">Hilfe
                        <!--  <b-icon icon="check" ></b-icon> -->
                    </b-button>




						</span>
						<!-- {{aktuelleListeMitName}} -->
						<b-input-group v-if="neueListeShow" prepend="neue Liste" class="mt-3 mr-2">
							<b-form-input placeholder="Name der Liste" v-model="neueListeName"></b-form-input>
							<b-input-group-append>
							<b-button :disabled="!neueListeName"
								 @click="neueListeErstellen" 
								variant="outline-success">erstellen</b-button>
							</b-input-group-append>
						</b-input-group>

						<hr v-if="neueListeShow" class="my-1 mt-3">

						<b-input-group prepend="aktive Liste" class="mt-3 mr-2">

							<b-form-select  @input.native="searchSingleMitgabe" v-model="kontaktEinstellungen.aktuelleListe" >

								<b-form-select-option v-bind:key="index" v-for="(liste, index) in kontaktEinstellungen.listen" :value="index">{{index}}</b-form-select-option>

							</b-form-select>

							<b-input-group-append>
								
							<!-- {{kontaktEinstellungen}} -->
							<span id="disabled-wrapper" class="d-inline-block" tabindex="0">
								<b-button   
									:disabled="!kontaktEinstellungen.backupListen"
									@click="undoLast" 

									variant="success"><b-icon icon="arrow-counterclockwise"></b-icon>
								</b-button>
							</span>
							<b-tooltip target="disabled-wrapper">letzten Schritt rückgängig machen</b-tooltip>

							<!-- <b-button   
                                @click="listeSpeichern" variant="success"><span class="fa fa-save"></span>
                            </b-button> -->
							
							</b-input-group-append>
						</b-input-group>

						<b-card class="mt-3">
							<div class="ml-1" v-if="aktuelleListeMitName.length">Diese Liste enthält {{aktuelleListeMitName.length}} Einträge </div>
								
								<b-button   
									class="ml-1 mt-1"
									:disabled="aktuelleListeMitName.length==0"
									@click="createPDF" 
									v-b-tooltip.hover 
									title="PDF von dieser Liste erstellen" 
									variant="info">
									<span class="fa fa-file-pdf-o"></span>
								</b-button>

						</b-card>


								<!-- v-bind:class="{ 'button-glow': glowSpeichern}" 
								id="projectSpeichern" 
								:disabled="loader_loading"
								v-b-tooltip.hover title="speichern - strg + s" -->

								<!-- <b-button @click="listeSpeichern">speichern</b-button> -->
								<!-- {{neueListeName}} -->

	<!-- {{kontaktEinstellungen.aktuelleListe}} -->

					<div class="my-3">

					<virtual-list
						class="list"   
						ref="listeList" 
						:style="{ height: window.height - 450 + 'px' }"
						:data-key="'uid'"
						:data-sources="aktuelleListeMitName"
						:data-component="itemComponent"
						:extra-props="{ otherPropValue: otherDataAssginToItemComponet }"
						:estimate-size="aktuelleListeMitName.length"
					>
					</virtual-list>
<!-- 
								<ul v-if="kontaktEinstellungen.listen[kontaktEinstellungen.aktuelleListe]!=undefined">
									<li v-bind:key="index" v-for="(list, index) in aktuelleListeMitName">
											{{list}}
									</li>
								</ul> -->
							
								
							</div>
						</span>

								<!-- {{verfuegbareListen}}
								{{fullName}} -->
						</div>

				</div>
										
						
					<!-- <div v-for="(item, index) in filterSuche" :key="index">
						{{item}}<br><br>
					</div> -->

        </div>

		<keep-alive>				
			<kontaktModal woherKommstDu="adressBuch" ref="kontaktModaladressBuch" ></kontaktModal>
		</keep-alive>

	</div>
</template>




<script>
	import { kontaktMixin } from '../mixins/kontaktMixins.js'
	// import { updateKontaktMixin } from '../mixins/updateKontaktMixins.js'

	import kontaktModal from './kontaktModal.vue'
	import kontaktDetail from './kontaktDetail.vue'
	import AdressHeader from './AdressHeader.vue'

	import ItemFor from './AdressesItem.vue'

	import PulseLoader from 'vue-spinner/src/PulseLoader'


const remain = 6
const itemSize = 60
const userInfoList = []

		function compare(a, b) {

		//	return (a.vollerName.anzeigeName === b.vollerName.anzeigeName)? 0 : a? -1 : 1;

		if (a.vollerName.anzeigeName.toLowerCase() < b.vollerName.anzeigeName.toLowerCase())
			return -1;
		if (a.vollerName.anzeigeName.toLowerCase() > b.vollerName.anzeigeName.toLowerCase())
			return 1;
		return 0;
	}


export default {
	
mixins: [kontaktMixin],

  name: 'App',
  components: {
	PulseLoader,

	'item-for':ItemFor,
	'kontakt-detail':kontaktDetail,
	'kontakt-subnav':AdressHeader,
	kontaktModal, 
	
	
  },

	
  data () {
    return {
	otherDataAssginToItemComponet: 'The Progressive JavaScript Framework',
	itemComponent:ItemFor,
	sortDirection:false,
	sortCheckedDirection:false,
	popoverShow: false,
	sortPopoverShow: false,
	liste:false,
	neueListe:false,
	neueListeName:'',
	neueListeShow:false,
	// kontaktKind:[
	// 	{'value':1, 'name':'Einzelperson'},
	// 	{'value':2, 'name':'Gruppe'},
	// ],


	
		start: 0,
		remain,
		size: itemSize,

    }
  },

  watch: {

    showInaktivTabelle (val) {
		this.refreshListe();
    }
  },


beforeRouteLeave (to, from, next) {
	console.log(to.path);

	if(to.path.indexOf('adressbuch')==-1){
		this.searchTermKontakte = '';
		this.kindArray = [];
		this.artArray = [];
	}
	next();
  // If the form is not dirty or the user confirmed they want to lose unsaved changes,
  // continue to next view
// this.popoverShowProSpeichern=true;

//   if (!this.form_dirty || this.confirmLeave()){
//     next()
//   } else {
//     // Cancel navigation
//     next(false)
//   }
},



	computed:{

	aktuelleListeMitName(){

		let returnArray = []
	
		if(this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe]!=undefined){
			for(var prop of this.kontaktEinstellungen.listen[this.kontaktEinstellungen.aktuelleListe])
			{
				var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == prop.kontaktID)
				
				
				if(index_trgKontakt>=0){
					var einsatz = this.Addressbook[index_trgKontakt];
					


					returnArray.push(this.Addressbook[index_trgKontakt])
				}
			}
		}

		// console.log(returnArray);
		returnArray = returnArray.sort(compare);

		 //returnArray.vollerName.anzeigeName.sort((a, b) => a - b);


		return returnArray




	},
	


    selectedAddressbook:
    {
        get()
        {
            return this.$store.getters.selectedAddressbook
        },
        set(value)
        {
            this.$store.commit('setselectedAddressbook', value);
        }
	}, 
	
	filterSuche() {

		if(this.showInaktivTabelle===true)
			return this.filterSuchePapierkorb;
		else
			return this.filterSucheAddressbook;

 		}, 

	filterSucheAddressbook() {

		const search = this.searchTermKontakte.toLowerCase().trim();

		if(search || this.kindArray.length>0 || this.artArray.length>0){

			if(this.$refs.AdressListe!=undefined)
			this.refreshListe();
		
			return this.Addressbook.filter(el => el.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1 && this.has(el.basic.kind, this.kindArray) && this.findCommonElements(el.art.summary, this.artArray) );
			}
		else
			return this.Addressbook

	},


	filterSuchePapierkorb() {

		const search = this.searchTermKontakte.toLowerCase().trim();

		if(search || this.kindArray.length>0 || this.artArray.length>0)
			return this.Papierkorb.filter(el => el.vollerName.anzeigeName.toLowerCase().indexOf(search) > -1 && this.has(el.basic.kind, this.kindArray) && this.findCommonElements(el.art.summary, this.artArray) );
		else
			return this.Papierkorb

	},


	kindIN:
		{
			get()
			{
				return this.$store.getters.kindIN
			},
			set(value)
			{
				this.$store.commit('setkindIN', value);
			}
		},

		bearbeiten:
		{
			get()
			{
				return this.$store.getters.bearbeiten
			},
			set(value)
			{
				this.$store.commit('changeBearbeiten', value);
			}
		},


		logObject(){
			return this.$store.state.logObject;
		},

		auswahlListen:
			{
				get()
				{
					return this.$store.getters.auswahlListen
				},
				set(value)
				{
					this.$store.commit('setauswahlListen', value);
				}
			},


		kontaktEinstellungen:
		{
			get()
			{
				return this.$store.getters.kontaktEinstellungen
			},
			set(value)
			{
				this.$store.commit('setkontaktEinstellungen', value);
			}
		},




	},



	mounted() {

	// this.$nextTick(()=>this.searchMitgabe());
	
		
	// console.log(this.auswahlListen)

		// if(Object.keys(this.auswahlListen).length ==0){

		// this.axios.get(this.$store.state.url_getAuswahllisten,
		// 	{
		// 		  params: {
		// 			  besonders: this.$store.state.dev_sub,
		// 		  }
		// 	  })
	  
		//   .then((response) => {
			
		// 	this.auswahlListen = response.data
	  	// 	//console.log(this.auswahlListen)
		//   })
		//  }

		if(Object.keys(this.kontaktEinstellungen.listen).length ===0)
		this.listeHolen();


		// var self = this

		// this.$root.$on('scrollToAddress', data => {
		// 	console.log("scrollToAddress")
		// 	console.log(data)
		// 	console.log(self.$refs)
		// 	self.$refs.AdressListe.scrollToIndex(data)
		// })

		

		this.$root.$on('refreshListeAdressbuch', data => {
		//	this.refreshListe()
	})


			this.$root.$on('openKontaktModalinAdressBuch', data => {
				console.log("openKontaktModalinAdressBuch")

				try {
					this.$refs.kontaktModaladressBuch.$refs.neuer_adressbuch_eintrag.show()
				}
				catch (e) {
				// Anweisungen für jeden Fehler
				}


			})

			this.$root.$on('scrollToTopAdressbuch', data => {
				this.toTop();
			})

	},



  methods: {


	getOffset(){


		// this.$refs.AdressListe.$el.scrollTop = 500
		console.log(this.$refs.AdressListe.getOffset());

		// this.$refs.AdressListe.scrollToOffset(1000)

		this.$refs.AdressListe.scrollToIndex(5)

	},

	whereDoWeGo(a, b){
		alert("hi");
		console.log(a);
		console.log(b);
	},

	  searchSingleMitgabe(liste){
		  console.log("searchSingleMitgabe");
		  console.log(liste);

			if(this.kontaktEinstellungen.listen[liste.target.value]!=undefined){

					for(var prop of this.kontaktEinstellungen.listen[liste.target.value])
					{
						var index_trgKontakt = this.Addressbook.findIndex(item=>item.basic.kontaktID == prop.kontaktID)
						if(index_trgKontakt>=0){
							if(this.Addressbook[index_trgKontakt].mitgabe[liste.target.value]==undefined){
								console.log("mitgabe von dieser Liste und diesem Kontakt gibt es noch nicht");

								var default_address = ''

								var alleAdressen = this.setMitgabe(this.Addressbook[index_trgKontakt]);

								//console.log(alleAdressen);

								if(alleAdressen[0])
								default_address = alleAdressen[0].identifier;
							
								if(this.Addressbook[index_trgKontakt].mitgabe=='{}')
								this.Addressbook[index_trgKontakt].mitgabe={};

								this.$set(this.Addressbook[index_trgKontakt].mitgabe, liste.target.value, {'adresse':default_address, 'welcherName':null, 'verbundenMit':null, 'vorname':null, 'name':null, 'anrede':null, 'telefon':null, 'email':null});
						
								this.speicher_kontakt_einzeln(this.Addressbook[index_trgKontakt], "searchSingleMitgabe / Adressbusch");

								//console.log(index_trgKontakt);

								//console.log(this.Addressbook[index_trgKontakt].mitgabe);

								//Vue.set(object_name, new_property, new_value)
							}
							else
							{
								console.log("mitgabe gibt schon");
								var geaendert = 0;

								if(!this.Addressbook[index_trgKontakt].mitgabe[liste.target.value].adresse)
								{
									var alleAdressen = this.setMitgabe(this.Addressbook[index_trgKontakt]);

									if(alleAdressen[0])
										this.Addressbook[index_trgKontakt].mitgabe[liste.target.value].adresse = alleAdressen[0].identifier;
									geaendert = 1
								}

								if(!this.Addressbook[index_trgKontakt].mitgabe[liste.target.value].anrede)
								{
									this.$set(this.Addressbook[index_trgKontakt].mitgabe[liste.target.value], 'anrede', null);
									geaendert = 1
								}

								if(!this.Addressbook[index_trgKontakt].mitgabe[liste.target.value].verbundenMit)
								{
									this.$set(this.Addressbook[index_trgKontakt].mitgabe[liste.target.value], 'verbundenMit', null);
									geaendert = 1
								}
								
								if(!this.Addressbook[index_trgKontakt].mitgabe[liste.target.value].welcherName)
								{
									this.$set(this.Addressbook[index_trgKontakt].mitgabe[liste.target.value], 'welcherName', null);
									geaendert = 1
								}


								if(geaendert == 1)
								this.speicher_kontakt_einzeln(this.Addressbook[index_trgKontakt], "searchSingleMitgabe / Adressbusch");

							}
						}
					}
				}
	  },

	//   searchMitgabe(){

	// 	  console.log(this.kontaktEinstellungen.listen)
	// 		if(this.kontaktEinstellungen.listen!=undefined){

	// 			for(var liste of this.kontaktEinstellungen.listen){

	// 			this.searchSingleMitgabe(liste)

	// 			}
	// 		}
	//   },

	  setMitgabe(kontakt){

		  var alleAdressen = this.getAllAdresse(kontakt);
		  return alleAdressen;

	  },

		getAllAdresse(kontakt){


		var leerArray = [];
		
			for(var single of kontakt.adresse){
				single.identifier= kontakt.basic.kontaktID+"_"+single.id;
				leerArray.push(JSON.parse(JSON.stringify(single)));
			}
			

			for(var prop of this.kontaktGruppeManual(kontakt)){
				if(prop.adresse!=undefined){
					for(var single of prop.adresse){
					single = JSON.parse(JSON.stringify(single))
					// console.log(single)
					single.herkunft= 'Gruppe';
					single.identifier= prop.basic.kontaktID+"_"+single.id;
					leerArray.push(single);
					}
				}
			}

			if(this.KontaktDetail.adresse!=undefined){
				if(this.KontaktDetail.adresse.length==0){
					for(var prop of this.kontaktBeteiligtManuale(kontakt)){
						if(prop.adresse!=undefined){
							for(var single of prop.adresse){
								single.herkunft= 'Einzelkontakt';
								single.identifier= prop.basic.kontaktID+"_"+single.id;
								leerArray.push(JSON.parse(JSON.stringify(single)));
							}
						}
					}
				}
			}
			return leerArray
	  },

	      kontaktGruppeManual(kontakt){
        // WEITER für Gruppen und Beteiligte
                var gruppe  = []
        
                if(kontakt.gruppe!=undefined){
        
                    for(var prop of kontakt.gruppe)
                    gruppe.push(this.getKontaktByID(prop));
        
                }
                return gruppe
        
            },
            
            kontaktBeteiligtManuale(kontakt){
        // WEITER für Gruppen und Beteiligte
                var gruppe  = []
        
                if(kontakt.beteiligte!=undefined){
        
                    for(var prop of kontakt.beteiligte)
                    gruppe.push(this.getKontaktByID(prop));
        
                }
                return gruppe
        
			},
			

	createPDF(){

				var mitgabeZuPDF=[];
				var adresse=null;
				var identifier

				var kontaktIDAdresse
				var kontaktAdresseID
				var kontaktAdresse
				var index_trgKontakt
				var kontaktAdresseMitgabe
				var anrede
				var verbundenMitMitgabe
				var welcherName

				if(this.kontaktEinstellungen.aktuelleListe!=undefined){

				for(var prop of this.aktuelleListeMitName){
						adresse=null
						identifier=null
						kontaktIDAdresse=null
						kontaktAdresseID=null
						kontaktAdresse=null
						index_trgKontakt=null
						kontaktAdresseMitgabe=null
						anrede=null
						verbundenMitMitgabe=null
						welcherName=null

						identifier = prop.mitgabe[this.kontaktEinstellungen.aktuelleListe]

						if(identifier.verbundenMit){
							verbundenMitMitgabe = identifier.verbundenMit
						}

						if(identifier.welcherName){
							welcherName = identifier.welcherName
						}

						if(prop.mitgabe[this.kontaktEinstellungen.aktuelleListe]!=undefined){
							if(prop.mitgabe[this.kontaktEinstellungen.aktuelleListe].anrede!=undefined)
							anrede = prop.mitgabe[this.kontaktEinstellungen.aktuelleListe].anrede
						}

						if(identifier.adresse){

							identifier = identifier.adresse.split("_");
							
							kontaktIDAdresse=identifier[0]
							kontaktAdresseID=identifier[1]

							if(prop.basic.kontaktID==kontaktIDAdresse){
								kontaktAdresse = prop
							}
							else
							{
								// console.log("externe Adresse")
								kontaktAdresse = this.getKontaktByID(kontaktIDAdresse)
								// console.log(kontaktAdresse)
							}

							
							index_trgKontakt = kontaktAdresse.adresse.findIndex(item=>item.id == kontaktAdresseID)

							if(index_trgKontakt>=0)
							kontaktAdresseMitgabe = kontaktAdresse.adresse[index_trgKontakt]
							

						}
						

						mitgabeZuPDF.push({'kind':prop.basic.kind, 'anrede':anrede, 'welcherName':welcherName, 'verbundenMit':verbundenMitMitgabe,'vollerName': prop.vollerName, 'adresse': kontaktAdresseMitgabe})

				}
			}
var zeit = new Date().toLocaleString();


var PDFName = this.kontaktEinstellungen.aktuelleListe + "-" + zeit + '.pdf';

var data =  {besonders : this.$store.state.dev_sub,
			liste: mitgabeZuPDF,PDFName:PDFName}


this.axios.post(this.$store.state.url_createWeihnachtsPDF,
data,
        {
			
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
			link.href = url;
			
			
			
		
            link.setAttribute('download', PDFName); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => console.log(error));

// this.axios.post(this.$store.state.url_createWeihnachtsPDF,
//         data,
//         {
//             responseType: 'arraybuffer',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/pdf'
//             }
//         })
//         .then((response) => {
//             const url = window.URL.createObjectURL(new Blob([response.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'file.pdf'); //or any other extension
//             document.body.appendChild(link);
//             link.click();
//         })
// 		.catch((error) => console.log(error));
		


// 			this.axios.get(this.$store.state.url_createWeihnachtsPDF,
// 			{
// 				        data:
//        		 {
//             responseType: 'arraybuffer',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/pdf'
//             }
//         	},
// 				  params: {
// 					  bekannte: 1,
// 				  }
// 			  })
	  
// 		  .then((response) => {
			
			
// 		  })

				// this.axios.post(this.$store.state.url_createWeihnachtsPDF, {
				// besonders : this.$store.state.dev_sub,
				// liste: mitgabe,
				// }).then((response) => {
				// //	console.log(response.data)

				// })


	},


	  listeHolen(){

				this.axios.post(this.$store.state.url_listen, {
				besonders : this.$store.state.dev_sub,
				was: 'get',

				}).then((response) => {
				//	console.log(response.data)
						this.kontaktEinstellungen.listen = response.data;

						if(Object.keys(this.kontaktEinstellungen.listen).length ===0)
						this.kontaktEinstellungen.aktuelleListe = Object.keys(this.kontaktEinstellungen.listen)[0];


				})



	  },



	
	neueListeErstellen(){
		
		if(this.neueListeName){
		// var obj = {};
		// obj[this.neueListeName] = [];

		// this.kontaktEinstellungen.listen.push(obj);
		console.log(this.kontaktEinstellungen)
		this.$set(this.kontaktEinstellungen.listen, this.neueListeName, []);

		//this.kontaktEinstellungen.listen[this.neueListeName]=[];
		
		this.kontaktEinstellungen.aktuelleListe = this.neueListeName
		this.neueListeName=null;
		//console.log(this.kontaktEinstellungen)
		}

	},

	refreshListe(){

		// if(this.$refs.Adressliste!=undefined)
			// this.$refs.Adressliste.forceRender()
	},

	sortAdressbook() {

		this.sortDirection = !this.sortDirection;


		
		if(this.sortDirection)
			this.Addressbook = this.Addressbook.sort(compare);
		else
			this.Addressbook = this.Addressbook.sort(compare).reverse();
	},

	sortSelected() {

		this.sortCheckedDirection = !this.sortCheckedDirection;

		function compare(x, y) {
			//return (x.markiert === y.markiert)? 0 : y? -1 : 1;
			return y.markiert - x.markiert;
		}

		if(this.sortCheckedDirection)
			this.Addressbook = this.Addressbook.sort(compare);
		else
			this.Addressbook = this.Addressbook.sort(compare).reverse();
	},

	onClose() {
        this.popoverShow = false
	},

	onCloseSort() {
        this.sortPopoverShow = false
	},

    getItemProps (itemIndex) {
      return {
        key: itemIndex,
        props: {
          height: itemSize,
          index: itemIndex,
          info: userInfoList[itemIndex] || {}
        }
      }
	},
	
    onHeaderDataChange (type, value) {
      if (type === 'start') {
        this.start = value
      }
    }
  },

created(){


		if(Object.keys(this.Addressbook).length ===0)
		this.updateAdressbook();
		//console.log("updateAdressbook from AB")
		
		if(Object.keys(this.Papierkorb).length ===0)
		this.updatePapierkorb();
	},
}
</script>

<style>
	.inhalt
	{
		margin:5px;
	}

	.kontaktDetail{
	min-width: 500px;
	vertical-align: top;
	margin-left:345px;
	}

	.list-item-fixed {
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 60px;
    border-bottom: 1px solid;
    border-color: lightgray;
  }

  .list{

	width:30%;
	min-width:320px;
	overflow-x: hidden;
	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}


.flexed { 
   display: flex; 
   margin-bottom:50px;
   height: 500px;
	/* height:80% !important; */
}


</style>

<style scoped>


.listen{
	
   margin-left:30px;
   padding-left:20px;
   border-left:1px solid;
    border-color: lightgray;
	height:100%;

}

h4{

   font-weight:700;
   font-size: 1rem;
   line-height: 1.5;
   font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

}


.btn-nav{
	width:40px;

}


.kontaktListe{
 	position: fixed; 
	width: 320px;
	vertical-align: top;
	height: 100vh;
	margin-bottom:50px;

}






	.scroller {
		height: 100%;
	}

	.user {
		height: 32%;
		padding: 0 12px;
		display: flex;
		align-items: center;
	}

	.input-group-text{
		padding: 0px 5px !important;
	}

	.select-xs {
		margin:0px !important;
		height:28px !important;
		width:100px !important;
	}



	.form-group
	{
		margin:0px;
	}

	.highlight{
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	}
	.text-center{
		text-align: center;
	}

	.unsichtbar{
		display:none;
	}

	.fixed-top
	{
		z-index: 90 !important;
	}

	.modal {
		width: 90% !important;
	}

	.telefonClass{
		display: block;
	}

	.mehrTelefone{
		font-size:0.9em;
		color:grey;
		cursor:pointer;
	}

	.roterRahmen{
		border: 1px solid blue; margin: 10px -5px; padding: 5px;
		box-shadow: 5px 5px 5px grey;
		height:85%;
	}

	.list-enter-active, .list-leave-active {
		transition: all 1s;
	}

	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(30px);
	}

	.einzug_links{
		margin-left:-10px
	}

	.unterueberschrift{
		color: #777;
		letter-spacing: 1px;
		float: left;
		font-size: 1.05em;
		font-weight: bold;
		margin-right: 15px;

	}



	.readonlynumber{
		pointer-events: none;
		padding: 0.375rem 0.75rem;
		background-color:white !important;
		border:none;
	}

	.readonlytext{
		pointer-events: none;
		/* padding: 0.375rem 0.75rem; */
		background-color:white !important;
	}

	select.readonlytext{
		background: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.readonlytext:focus {
		border:none;
	}

 /* @import './demos/common/app.less'; */

</style>