var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.formular!=undefined)?_c('div',[_c('div',{staticStyle:{"font-family":"Arial, Verdana, sans-serif","border":"solid 1px black","position":"relative","width":"909px","height":"1286px","overflow":"hidden","background-color":"white","margin":"0 auto 10px"}},[_c('div',{style:({
														fontSize:'18.66px',
														fontWeight:'bold',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(47.7368869) + 'px', 
														top: _vm.gib_hoehe_in_mm(19.982995) + 'px'})},[_vm._v(" Vollmacht zum Antrag auf Akteneinsicht bzw. Kopien aus Bauakten ")]),_c('div',{style:({
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(43.7542696) + 'px'})},[_vm._v(" Grundstück ")]),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(52.9752584) + 'px'})},[_vm._v(" Ort, Straße, Hausnummer ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(157.5152432) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(38.2877012) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(57.6888176) + 'px'})},[(_vm.formular.baugrund_strasse)?_c('b-popover',{ref:"baugrund_strasse",attrs:{"show":_vm.formular.baugrund_strasse.showPop==true,"target":"baugrund_strasse"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.baugrund_strasse.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.baugrund_strasse.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("baugrund_strasse", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("baugrund_strasse", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.baugrund_strasse!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.baugrund_strasse.text),expression:"formular.baugrund_strasse.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.baugrund_strasse.size + 'px',
														'height': _vm.formular.baugrund_strasse.height + 'px',
														'text-align': _vm.formular.baugrund_strasse.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.baugrund_strasse.letterSpacing + 'px',
														'font-weight': _vm.formular.baugrund_strasse.schriftart + 'px', }),attrs:{"rows":"3","disabled":_vm.formular.baugrund_strasse.isDisabled==true || _vm.formular.baugrund_strasse.isDisabled=='true',"id":"baugrund_strasse"},domProps:{"value":(_vm.formular.baugrund_strasse.text)},on:{"focus":function($event){_vm.formular.baugrund_strasse.showPop=true},"blur":function($event){_vm.formular.baugrund_strasse.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.baugrund_strasse, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(85.3809817) + 'px'})},[_vm._v(" Eigentümer/in ")]),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(94.4351434) + 'px'})},[_vm._v(" Name, Vorname ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(157.5152432) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(38.2877012) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(98.8714804) + 'px'})},[(_vm.formular.bauherr_name)?_c('b-popover',{ref:"bauherr_name",attrs:{"show":_vm.formular.bauherr_name.showPop==true,"target":"bauherr_name"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_name.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_name.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_name", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_name!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_name.text),expression:"formular.bauherr_name.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.bauherr_name.size + 'px',
														'height': _vm.formular.bauherr_name.height + 'px',
														'text-align': _vm.formular.bauherr_name.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.bauherr_name.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_name.schriftart + 'px', }),attrs:{"rows":"3","disabled":_vm.formular.bauherr_name.isDisabled==true || _vm.formular.bauherr_name.isDisabled=='true',"id":"bauherr_name"},domProps:{"value":(_vm.formular.bauherr_name.text)},on:{"focus":function($event){_vm.formular.bauherr_name.showPop=true},"blur":function($event){_vm.formular.bauherr_name.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_name, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(144.3471312) + 'px'})},[_vm._v(" Bevollmächtigte/r ")]),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(153.3998821) + 'px'})},[_vm._v(" Name, Vorname ")]),_c('span',{staticClass:"input_formular",style:({width: _vm.spalte_breit_mm(157.3364243) + 'px', 
										 position:'absolute', 
										 left: _vm.spalte_breit_mm(38.2877012) + 'px',
										
										 top: _vm.gib_hoehe_in_mm(158.3165965) + 'px'})},[(_vm.formular.antragsteller_vorlagenberechtigt)?_c('b-popover',{ref:"antragsteller_vorlagenberechtigt",attrs:{"show":_vm.formular.antragsteller_vorlagenberechtigt.showPop==true,"target":"antragsteller_vorlagenberechtigt"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_vorlagenberechtigt.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_vorlagenberechtigt.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_vorlagenberechtigt", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_vorlagenberechtigt", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_vorlagenberechtigt!=undefined)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_vorlagenberechtigt.text),expression:"formular.antragsteller_vorlagenberechtigt.text"}],staticClass:"textfeld",staticStyle:{"width":"100%","line-height":"1.2"},style:({'font-size': _vm.formular.antragsteller_vorlagenberechtigt.size + 'px',
														'height': _vm.formular.antragsteller_vorlagenberechtigt.height + 'px',
														'text-align': _vm.formular.antragsteller_vorlagenberechtigt.schriftausrichtung + 'px',
														'letter-spacing': _vm.formular.antragsteller_vorlagenberechtigt.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_vorlagenberechtigt.schriftart + 'px', }),attrs:{"rows":"2","disabled":_vm.formular.antragsteller_vorlagenberechtigt.isDisabled==true || _vm.formular.antragsteller_vorlagenberechtigt.isDisabled=='true',"id":"antragsteller_vorlagenberechtigt"},domProps:{"value":(_vm.formular.antragsteller_vorlagenberechtigt.text)},on:{"focus":function($event){_vm.formular.antragsteller_vorlagenberechtigt.showPop=true},"blur":function($event){_vm.formular.antragsteller_vorlagenberechtigt.showPop=false},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_vorlagenberechtigt, "text", $event.target.value)}}}):_vm._e()],1),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(115.2246922) + 'px'})},[_vm._v(" Straße, Hausnummer ")]),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(119.5254449) + 'px', 
														top: _vm.gib_hoehe_in_mm(115.2246922) + 'px'})},[_vm._v(" Postleitzahl, Ort ")]),(_vm.formular.bauherr_strasse)?_c('b-popover',{ref:"bauherr_strasse",attrs:{"show":_vm.formular.bauherr_strasse.showPop==true,"target":"bauherr_strasse"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_strasse.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_strasse.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_strasse", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_strasse", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_strasse!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_strasse.text),expression:"formular.bauherr_strasse.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(68.7237236) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(38.5377012) + 'px', 
														'height': 16.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.392112) + 'px', 
		
														'font-size': _vm.formular.bauherr_strasse.size + 'px', 
														'text-align': _vm.formular.bauherr_strasse.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauherr_strasse.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_strasse.schriftart }),attrs:{"id":"bauherr_strasse","type":"text","disabled":_vm.formular.bauherr_strasse.isDisabled==true || _vm.formular.bauherr_strasse.isDisabled==true},domProps:{"value":(_vm.formular.bauherr_strasse.text)},on:{"focus":function($event){_vm.formular.bauherr_strasse.showPop=true},"blur":function($event){_vm.formular.bauherr_strasse.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauherr_strasse.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_strasse, "text", $event.target.value)}}}):_vm._e(),(_vm.formular.bauherr_ort)?_c('b-popover',{ref:"bauherr_ort",attrs:{"show":_vm.formular.bauherr_ort.showPop==true,"target":"bauherr_ort"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.bauherr_ort.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.bauherr_ort.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_ort", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("bauherr_ort", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.bauherr_ort!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.bauherr_ort.text),expression:"formular.bauherr_ort.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(76.0133272) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(120.0396172) + 'px', 
														'height': 16.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(120.392112) + 'px', 
		
														'font-size': _vm.formular.bauherr_ort.size + 'px', 
														'text-align': _vm.formular.bauherr_ort.schriftausrichtung, 
														'letter-spacing': _vm.formular.bauherr_ort.letterSpacing + 'px',
														'font-weight': _vm.formular.bauherr_ort.schriftart }),attrs:{"id":"bauherr_ort","type":"text","disabled":_vm.formular.bauherr_ort.isDisabled==true || _vm.formular.bauherr_ort.isDisabled==true},domProps:{"value":(_vm.formular.bauherr_ort.text)},on:{"focus":function($event){_vm.formular.bauherr_ort.showPop=true},"blur":function($event){_vm.formular.bauherr_ort.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.bauherr_ort.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.bauherr_ort, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(174.0444712) + 'px'})},[_vm._v(" Straße, Hausnummer ")]),(_vm.formular.antragsteller_strasse)?_c('b-popover',{ref:"antragsteller_strasse",attrs:{"show":_vm.formular.antragsteller_strasse.showPop==true,"target":"antragsteller_strasse"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_strasse.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_strasse.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_strasse", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_strasse", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_strasse!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_strasse.text),expression:"formular.antragsteller_strasse.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(68.7237236) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(38.5377012) + 'px', 
														'height': 16.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(179.2111856) + 'px', 
		
														'font-size': _vm.formular.antragsteller_strasse.size + 'px', 
														'text-align': _vm.formular.antragsteller_strasse.schriftausrichtung, 
														'letter-spacing': _vm.formular.antragsteller_strasse.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_strasse.schriftart }),attrs:{"id":"antragsteller_strasse","type":"text","disabled":_vm.formular.antragsteller_strasse.isDisabled==true || _vm.formular.antragsteller_strasse.isDisabled==true},domProps:{"value":(_vm.formular.antragsteller_strasse.text)},on:{"focus":function($event){_vm.formular.antragsteller_strasse.showPop=true},"blur":function($event){_vm.formular.antragsteller_strasse.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.antragsteller_strasse.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_strasse, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'12.246px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(119.5254449) + 'px', 
														top: _vm.gib_hoehe_in_mm(174.0444712) + 'px'})},[_vm._v(" Postleitzahl, Ort ")]),(_vm.formular.antragsteller_ort)?_c('b-popover',{ref:"antragsteller_ort",attrs:{"show":_vm.formular.antragsteller_ort.showPop==true,"target":"antragsteller_ort"}},[_c('template',{slot:"title"},[_c('b-button',{staticClass:"close",attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.formular.antragsteller_ort.showPop=false;}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Schriftgröße ")],1),_c('span',{staticStyle:{"font-size":"1.0em","font-weight":"700","margin-right":"10px"}},[_vm._v(_vm._s(_vm.formular.antragsteller_ort.size))]),_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_ort", "+")}}},[_vm._v("+")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.plus("antragsteller_ort", "-")}}},[_vm._v("-")])],2):_vm._e(),(_vm.formular.antragsteller_ort!=undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formular.antragsteller_ort.text),expression:"formular.antragsteller_ort.text"}],staticClass:"textfeld",staticStyle:{"width":"100%"},style:({'width': _vm.spalte_breit_mm(76.0143853) + 'px', 
														'position':'absolute', 
														'left': _vm.spalte_breit_mm(119.8597402) + 'px', 
														'height': 16.5 + 'px', 
														'top': _vm.gib_hoehe_in_mm(179.2111856) + 'px', 
		
														'font-size': _vm.formular.antragsteller_ort.size + 'px', 
														'text-align': _vm.formular.antragsteller_ort.schriftausrichtung, 
														'letter-spacing': _vm.formular.antragsteller_ort.letterSpacing + 'px',
														'font-weight': _vm.formular.antragsteller_ort.schriftart }),attrs:{"id":"antragsteller_ort","type":"text","disabled":_vm.formular.antragsteller_ort.isDisabled==true || _vm.formular.antragsteller_ort.isDisabled==true},domProps:{"value":(_vm.formular.antragsteller_ort.text)},on:{"focus":function($event){_vm.formular.antragsteller_ort.showPop=true},"blur":function($event){_vm.formular.antragsteller_ort.showPop=false},"keypress":function($event){return _vm.isNumber($event, _vm.formular.antragsteller_ort.onylNumber)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formular.antragsteller_ort, "text", $event.target.value)}}}):_vm._e(),_c('div',{style:({
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(206.7566908) + 'px'})},[_vm._v(" Als Eigentümer/in des o. a. Grundstückes bevollmächtige ich hiermit die genannte Bevollmächtigte / den ")]),_c('div',{style:({
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(210.3887954) + 'px'})},[_vm._v(" genannten Bevollmächtigten in die Bauakte des o. a. Grundstücks einzusehen bzw. Kopien von den vorhandenen ")]),_c('div',{style:({
														fontSize:'13.482px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(214.0212527) + 'px'})},[_vm._v(" Unterlagen fertigen zu lassen. ")]),_c('div',{style:({
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(224.6438713) + 'px'})},[_vm._v(" Unterschrift Eigentümer/in ")]),_c('div',{style:({
														fontSize:'13.4115px',
														fontWeight:'normal',
														
														 position:'absolute', 
														left: _vm.spalte_breit_mm(38.2877012) + 'px', 
														top: _vm.gib_hoehe_in_mm(233.2603323) + 'px'})},[_vm._v(" Datum, Unterschrift(en) ")]),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.9553178) + 'px', 
													width: _vm.spalte_breit_mm(182.6742637) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(8.5506536) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(203.6295815) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(281.2562158) + 'px', 
													top: _vm.gib_hoehe_in_mm(8.5506536) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.9553178) + 'px', 
													width: _vm.spalte_breit_mm(182.6742637) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(289.7068694) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(20.9553178) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(281.2562158) + 'px', 
													top: _vm.gib_hoehe_in_mm(8.5506536) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.3058387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(220.9458882) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.7736079) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(32.9959763) + 'px', 
													top: _vm.gib_hoehe_in_mm(220.9458882) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.3058387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(253.8418645) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(32.9959763) + 'px', 
													top: _vm.gib_hoehe_in_mm(220.9458882) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(190.7685235) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(17.4531927) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.4153308) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.4153308) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(17.4531927) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.4153308) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.4153308) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(20.8465194) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.6688114) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.6688114) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(20.8465194) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.6688114) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(152.6688114) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.2032532) + 'px', 
													top: _vm.gib_hoehe_in_mm(140.5655582) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(140.5655582) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.2032532) + 'px', 
													top: _vm.gib_hoehe_in_mm(140.5655582) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(131.8044902) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(17.4112214) + 'px', 
													top: _vm.gib_hoehe_in_mm(114.4932688) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.3058387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(114.4932688) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(114.4932688) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(38.2004175) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.7040727) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.7040727) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(20.8891961) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.7040727) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(93.7040727) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.2067802) + 'px', 
													top: _vm.gib_hoehe_in_mm(81.5972925) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(81.5972925) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.2067802) + 'px', 
													top: _vm.gib_hoehe_in_mm(81.5972925) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(72.8362245) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(20.83876) + 'px', 
													top: _vm.gib_hoehe_in_mm(52.0974645) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(20.83876) + 'px', 
													top: _vm.gib_hoehe_in_mm(52.0974645) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(52.0974645) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.246988) + 'px', 
													top: _vm.gib_hoehe_in_mm(39.9504765) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.474782) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(39.9504765) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(198.9425512) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(12.246988) + 'px', 
													top: _vm.gib_hoehe_in_mm(39.9504765) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(36.4677692) + 'px', 
													width: _vm.spalte_breit_mm(162.3058387) + 'px', 
													height:_vm.gib_hoehe_in_mm(0.1) + 'px', 
													top: _vm.gib_hoehe_in_mm(233.0494941) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(117.7051602) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(17.4112214) + 'px', 
													top: _vm.gib_hoehe_in_mm(114.4932688) + 'px'})}),_c('div',{style:({	border: 'solid 1px grey',
													position:'absolute',
													left: _vm.spalte_breit_mm(117.7051602) + 'px', 
													width: _vm.spalte_breit_mm(0) + 'px', 
													height:_vm.gib_hoehe_in_mm(17.4531927) + 'px', 
													top: _vm.gib_hoehe_in_mm(173.4153308) + 'px'})})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }