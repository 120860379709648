<template>
	<div>
        <div class="kontainer">
				<h1>Ordnerrücken</h1>
				<p>Text</p>
        </div>
	</div>
</template>

<script>

// import xeogl from 'xeogl';

// console.log(xeogl);

export default {

    data(){
        return{
        }
    },
	methods:{

	},

	mounted(){
// var geometry = new xeogl.TorusGeometry({
//     radius: 1.0,
//     tube: 0.3
// });

	},
	created(){

	}
	
	//
}
</script>


<style>


</style>
